import { useIntl } from 'react-intl';
import { Form, Input, Popover } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

interface LicenseTypeAndIssuingAuthority {
    isIssuingAuthorityDisabled: boolean;
    isLicenseTypeDisabled: boolean;
}
export const LicenseTypeAndIssuingAuthority: React.FC<LicenseTypeAndIssuingAuthority> = ({
    isIssuingAuthorityDisabled,
    isLicenseTypeDisabled,
}) => {
    const intl = useIntl();

    return (
        <>
            <Form.Item
                name="license_type"
                rules={[
                    {
                        required: true,
                        message: intl.formatMessage({
                            id: '74e6c.AddOptions.notBlank',
                            defaultMessage: 'Please do not leave blank',
                        }),
                    },
                ]}
            >
                <Input
                    disabled={isLicenseTypeDisabled}
                    placeholder={intl.formatMessage({
                        id: '74e6c.AddOptions.acicLicenseType',
                        defaultMessage: 'License Type',
                    })}
                    addonBefore={
                        <Popover
                            content={intl.formatMessage({
                                id: '74e6c.AddOptions.acicLicenseType',
                                defaultMessage: 'License Type',
                            })}
                        >
                            <InfoCircleOutlined />
                        </Popover>
                    }
                />
            </Form.Item>
            <Form.Item
                name="issuing_authority"
                rules={[
                    {
                        required: true,
                        message: intl.formatMessage({
                            id: '74e6c.AddOptions.notBlank',
                            defaultMessage: 'Please do not leave blank',
                        }),
                    },
                ]}
            >
                <Input
                    disabled={isIssuingAuthorityDisabled}
                    placeholder={intl.formatMessage({
                        id: '74e6c.AddOptions.acicIssuingAuthority',
                        defaultMessage: 'Issuing Authority',
                    })}
                    addonBefore={
                        <Popover
                            content={intl.formatMessage({
                                id: '74e6c.AddOptions.acicIssuingAuthority',
                                defaultMessage: 'Issuing Authority',
                            })}
                        >
                            <InfoCircleOutlined />
                        </Popover>
                    }
                />
            </Form.Item>
        </>
    );
};
