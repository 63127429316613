export const queryKeys = {
    team: 'team',
    teams: 'teams',
    currentUser: 'currentUser',
    countries: 'countries',
    application: 'application',
    postings: 'postings',
    orderStatus: 'orderStatus',
    adjudicators: 'adjudicators',
    adjudicationReviewStatuses: 'adjudicationReviewStatuses',
    paymentInfo: 'paymentInfo',
    userApplicants: 'userApplicants',
    teamId: 'teamId',
};
