// Libraries
import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect, useSelector } from 'react-redux'; // useDispatch in 7.1+ release
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';
import styled from 'styled-components/macro';
import { Form as AntdForm } from '@ant-design/compatible';
import { message } from 'antd';
import { FormattedMessage } from 'react-intl';

// Components
import Form from 'certnd/Form';
import Typography from 'certnd/Typography';
import { ErrorAlertAPI } from 'certn-ui/ErrorAlert';
import ToggleIntegrationButton from './components/ToggleIntegrationButton';

// Modules
import Auth from 'modules/Auth';

// Actions & Selectors
import { getIntegrations } from 'views/manager/views/settings/SettingsSelectors';
import { enableJobAdder } from 'views/manager/views/settings/SettingsActions';
import { getTeam } from 'views/manager/views/admin/AdminSelectors';

const { Heading } = Typography;

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            enableJobAdder,
        },
        dispatch
    );

const IntegrationOwnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    > div:not(:last-child) {
        margin-bottom: 15px;
    }
`;

const IntegrationOwnerColumn = styled.div`
    width: 150px;
`;

const JobAdder = (props) => {
    const [loading, setLoading] = useState(false);
    const integrations = useSelector((state) => getIntegrations(state));
    const teamId = useSelector((state) => getTeam(state).id);
    const canEdit = Auth.isPermissionLevel('manager');

    // JobAdder
    const jobAdderActive = get(integrations, ['jobadder', 'active']);
    const jobAdderEmail = get(integrations, ['jobadder', 'owner', 'email']);

    const handleSubmit = () => {
        setLoading(true);
        props
            .enableJobAdder({ teamId })
            .then(() => {
                setLoading(false);
                message.success('Redirected to JobAdder Connection');
            })
            .catch((error) => {
                setLoading(false);
                ErrorAlertAPI(error);
            });
    };

    return (
        <AntdForm
            onSubmit={(e) => {
                e.preventDefault();
                if (jobAdderActive)
                    return props.handleDisableIntegration({
                        name: 'JobAdder',
                        text: (
                            <FormattedMessage
                                id="7131b.JobAdder.ConfirmDisable"
                                defaultMessage="Disabling this integration will delete all data from JobAdder. Be sure you know what you are doing."
                            />
                        ),
                    });
                handleSubmit();
            }}
        >
            <Form.Block>
                <Heading.H4>
                    <FormattedMessage
                        id="7131b.JobAdder.Instructions"
                        defaultMessage="Please contact your Customer Support Representative for JobAdder integration."
                    />
                </Heading.H4>
                <ToggleIntegrationButton canEdit={canEdit} loading={loading} active={jobAdderActive} />

                {jobAdderActive && (
                    <IntegrationOwnerContainer>
                        <IntegrationOwnerColumn>
                            <div style={{ fontWeight: 'bolder' }}>
                                <FormattedMessage id="common.owner" defaultMessage="Owner" />
                            </div>
                            <div>{jobAdderEmail}</div>
                        </IntegrationOwnerColumn>
                        <IntegrationOwnerColumn>
                            <div style={{ fontWeight: 'bolder' }}>
                                <FormattedMessage id="7131b.JobAdder.Status" defaultMessage="Status" />
                            </div>
                            <div>
                                <FormattedMessage id="7131b.JobAdder.Active" defaultMessage="Active" />
                            </div>
                        </IntegrationOwnerColumn>
                    </IntegrationOwnerContainer>
                )}
            </Form.Block>
        </AntdForm>
    );
};

export default AntdForm.create()(withRouter(connect(null, mapDispatchToProps)(JobAdder)));
