export default {
    adverseActionStatus: {
        NONE: 'NONE',
        PAUSED: 'PAUSED',
        SCHEDULED: 'SCHEDULED',
        COMPLETE: 'COMPLETE',
        IN_DISPUTE: 'IN_DISPUTE',
        CANCELLED: 'CANCELLED',
        UNDELIVERABLE: 'UNDELIVERABLE',
    },
    applicationStatus: {
        OFFER_ACCEPTED: 'OFFER_ACCEPTED',
        OFFER_PENDING: 'OFFER_PENDING',
        NONE: 'NONE',
        PROSPECT: 'PROSPECT',
        ARCHIVED: 'ARCHIVED',
    },
    applicantStatus: {
        PENDING: 'PENDING',
        ARCHIVED: 'ARCHIVED',
    },
    referenceResultStatus: {
        UNRESPONSIVE: 'UNRESPONSIVE',
    },
    checkExecutionStatus: {
        WAITING_ON_CANDIDATE: 'WAITING_ON_CANDIDATE',
        IN_PROGRESS: 'IN_PROGRESS',
        REQUIRES_MORE_INFORMATION: 'REQUIRES_MORE_INFORMATION',
        COMPLETE: 'COMPLETE',
        UNRESPONSIVE: 'UNRESPONSIVE',
        CANCELLED: 'CANCELLED',
        ACTION_REQUIRED: 'ACTION_REQUIRED',
        IN_DISPUTE: 'IN_DISPUTE',
        WAITING_ON_ADJUDICATOR: 'WAITING_ON_ADJUDICATOR',
        get complete_or_adjudicable() {
            return [this.COMPLETE, this.CANCELLED, this.WAITING_ON_ADJUDICATOR];
        },
    },
    reportStatus: {
        SENT: 'SENT',
        WAITING_ON_CANDIDATE: 'WAITING_ON_CANDIDATE',
        WAITING_ON_REFERENCE: 'WAITING_ON_REFERENCE',
        AWAITING_ACTION: 'AWAITING_ACTION',
        IN_PROGRESS: 'IN_PROGRESS',
        ANALYZING: 'ANALYZING',
        COMPLETE: 'COMPLETE',
        PARTIAL: 'PARTIAL',
        NONE: 'NONE',
        UNRESPONSIVE: 'UNRESPONSIVE',
        ACTION_REQUIRED: 'ACTION_REQUIRED',
        get active() {
            return [this.ACTION_REQUIRED, this.COMPLETE, this.PARTIAL, this.AWAITING_ACTION, this.ANALYZING];
        },
    },
    orderStatus: {
        WAITING_ON_CANDIDATE: 'WAITING_ON_CANDIDATE',
        COMPLETE: 'COMPLETE',
        ACTION_REQUIRED: 'ACTION_REQUIRED',
        IN_PROGRESS: 'IN_PROGRESS',
        CANCELLED: 'CANCELLED',
        APPLICATION_EXPIRED: 'APPLICATION_EXPIRED',
        IN_DISPUTE: 'IN_DISPUTE',
        get active() {
            return [this.ACTION_REQUIRED, this.IN_PROGRESS, this.COMPLETE, this.IN_DISPUTE];
        },
        get faded() {
            return [this.WAITING_ON_CANDIDATE];
        },
    },
    serviceStatus: {
        UNRESPONSIVE: 'UNRESPONSIVE',
        AWAITING_ACTION: 'AWAITING_ACTION',
        IN_PROGRESS: 'IN PROGRESS',
        PENDING: 'PENDING',
        VERIFICATION_PENDING: 'VERIFICATION PENDING',
        PARTIAL: 'PARTIAL',
        ANALYZING: 'ANALYZING',
        RETURNED: 'RETURNED',
        NONE: 'NONE',
        ACTION_REQUIRED: 'ACTION_REQUIRED',
    },
    verificationStatus: {
        VERIFIED: 'VERIFIED',
        SYSTEM_UNABLE_TO_VERIFY: 'SYSTEM UNABLE TO VERIFY',
        VERIFICATION_PENDING: 'VERIFICATION PENDING',
        UPGRADE_TO_VERIFY: 'UPGRADE TO VERIFY',
        PARTIALLY_VERIFIED: 'PARTIALLY VERIFIED',
        UNVERIFIED: 'UNVERIFIED',
        DO_NOT_CONTACT: 'DO NOT CONTACT',
        NONE: 'NONE',
    },
    reportResult: {
        CLEARED: 'CLEARED',
        REVIEW: 'REVIEW',
        NONE: 'NONE',
    },
    certnScore: {
        PASS: 'PASS',
        WARN: 'WARN',
        FAIL: 'FAIL',
        NONE: 'NONE',
    },
    gaTrafficTrackerIds: {
        PROD: 'G-THB94K48WC',
        DEV: 'G-04QNM12DYB',
    },
    reportSection: {
        EQUIFAX: 'EQUIFAX',
        RCMP: 'RCMP',
        SOQUIJ: 'SOQUIJ',
        VACCINATION: 'VACCINATION',
        SOFTCHECK: 'SOFTCHECK',
        US_CRIMINAL_RECORD: 'US_CRIMINAL_RECORD',
        INTERNATIONAL_CRIMINAL_RECORD: 'INTERNATIONAL_CRIMINAL_RECORD',
        MOTOR_VEHICLE_RECORD: 'MOTOR_VEHICLE_RECORD',
        ID_VERIFICATION: 'ID_VERIFICATION',
        VERIFICATION: 'VERIFICATION',
        INSTANT_VERIFY: 'INSTANT_VERIFY',
        REFERENCES: 'REFERENCES',
        INFORMATION: 'INFORMATION',
        AUSTRALIAN_CRIMINAL_INTELLIGENCE_COMMISSION_CHECK: 'AUSTRALIAN_CRIMINAL_INTELLIGENCE_COMMISSION_CHECK',
        SOCIAL_MEDIA: 'SOCIAL_MEDIA',
        UK_BASIC_DBS_CHECK: 'UK_BASIC_DBS_CHECK',
        UK_BASIC_DS_CHECK: 'UK_BASIC_DS_CHECK',
        UK_RIGHT_TO_WORK_CHECK: 'UK_RIGHT_TO_WORK_CHECK',
    },
    adjudicationLifeCycleStatus: {
        NEEDS_REVIEW: 'NEEDS_REVIEW',
        IN_PROGRESS: 'IN_PROGRESS',
        CLOSED: 'CLOSED',
        NONE: 'NONE',
        COMPLETE: 'COMPLETE',
        get active() {
            return [this.NEEDS_REVIEW, this.IN_PROGRESS, this.NONE];
        },
    },
    scoreResult: {
        REVIEW: 'REVIEW',
        CLEARED: 'CLEARED',
        DIDNOTPASS: 'DIDNOTPASS',
        NONE: 'NONE',
    },
    scoringSource: {
        MANUAL: 'MANUAL',
        ALWAYSREVIEW: 'ALWAYSREVIEW',
        PIPELINE_SCORE: 'PIPELINE_SCORE',
        CLIENT: 'CLIENT',
        US_COMPLIANCE: 'US_COMPLIANCE',
        ACIC: 'ACIC',
        RIGHT_TO_WORK: 'RIGHT_TO_WORK',
        SOCIAL_MEDIA: 'SOCIAL_MEDIA',
        VERIFICATION: 'VERIFICATION',
        UK_BASIC_DBS: 'UK_BASIC_DBS',
        UK_BASIC_DS: 'UK_BASIC_DS',
    },
    apiUserRole: {
        SUPPORT: 0,
        ADMIN: 1,
    },
};

export const LANGUAGE_STORAGE_KEY = 'locale';

export const LANGUAGE_EN = 'en';
export const LANGUAGE_FR = 'fr';
export const LANGUAGE_ES = 'es';

export const SUPPORTED_LANGUAGES = [LANGUAGE_EN, LANGUAGE_FR, LANGUAGE_ES];

// data-testid that is used by QA team for E2E testing
export const QA_TEST_ID = {
    ApplicantInfoSectionWrapper: 'applicant_info_section_wrapper',
    MicroServicesLayout: 'MicroServicesLayout',
    QuickScreenConvictionsSectionWrapper: 'quickscreen_convictions_section_wrapper',
    QuickScreenApplicantAddressSectionWrapper: 'quickscreen_applicant_address_section_wrapper',
    QuickScreenWorkLocationSectionWrapper: 'quickscreen_work_location_section_wrapper',
    RightSideBox: 'RightSideBox',
    Subtotal: 'subtotal',
    Tax: 'tax',
    Total: 'total',
};

export const CHECK_INFO = {
    CRIMINAL_RECORD_CHECK: 'criminal_record_check',
    AUSTRALIAN_CRIMINAL_INTELLIGENCE_COMMISSION_CHECK: 'australian_criminal_intelligence_commission_check',
    VOLUNTEER_ACIC_CHECK: 'volunteer_acic_check',
};

export const REPORT_ID_COLUMN_KEY = 'short_uid';
