// Libraries
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from 'styles/styles';

const MailUnverified = (props) => (
    <svg width={234} height={155} {...props}>
        <title>
            <FormattedMessage id="welcome.Submit.emailUnverified" defaultMessage="Email Unverified" />
        </title>
        <g fill="none" fillRule="evenodd">
            <g transform="translate(2)">
                <circle fill={styles.color.certnGray100} cx={115} cy={92} r={92} />
                <circle fill={styles.color.certnGray200} cx={114.5} cy={92.5} r={57.5} />
            </g>
            <path
                d="M2 153h230"
                stroke={styles.color.certnGreen700}
                strokeWidth={3}
                strokeLinecap="round"
                strokeLinejoin="bevel"
            />
            <path
                d="M102.091 129.52h60.417c3.074 0 5.591-2.527 5.591-5.614V60.57c0-3.088-2.517-5.615-5.59-5.615H71.422c-3.075 0-5.593 2.527-5.593 5.615v12.639"
                fill={styles.color.certnWhite}
            />
            <path
                d="M148.838 129.52H71.421c-3.073 0-5.59-2.527-5.59-5.614V60.57c0-3.088 2.517-5.615 5.59-5.615h91.085c3.076 0 5.593 2.527 5.593 5.615v24.639"
                stroke={styles.color.certnGreen700}
                strokeWidth={3}
                fill={styles.color.certnWhite}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M92.165 98.977l-23.102 27.757M67.82 56.968l49.296 38.203L166 56.655"
                stroke={styles.color.certnGreen700}
                strokeWidth={3}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M195.21 112.71c0 15.304-12.36 27.709-27.609 27.709-15.239 0-27.601-12.405-27.601-27.708C140 97.407 152.365 85 167.601 85c15.249 0 27.609 12.407 27.609 27.71z"
                stroke={styles.color.certnGreen700}
                strokeWidth={3}
                fill={styles.color.certnOrange400}
            />
            <g transform="translate(160 100)">
                <circle fill={styles.color.certnGreen700} fillRule="nonzero" cx={6.8} cy={22.5} r={1.5} />
                <path
                    d="M.894 6.097C.894 2.9 3.85.308 7.5.308c3.647 0 6.605 2.592 6.605 5.789 0 0 .145 2.636-3.287 4.987-2.287 1.78-4.067 4.575-4.067 7.752"
                    stroke={styles.color.certnGreen700}
                    strokeWidth={3}
                />
            </g>
        </g>
    </svg>
);

export default MailUnverified;
