import { useIntl } from 'react-intl';
import { Form, Button } from 'antd';
import { CountryCode } from 'countries-and-timezones';
import { AppearRight } from 'certn-ui/Animate';
import Title from 'certn-ui/Title';
import FormLayout from 'certn-form/FormLayout';
import Password from 'certn-ui/Password';
import { ButtonLine } from 'certn-ui/Button';
import { EmailInput } from './EmailInput';
import { Legal } from './Legal';
import HubspotTracking from '../components/hubspot';

type SignUpFormProps = {
    onSubmit: () => void;
    submitting: boolean;
    isLime: boolean;
    email: string;
    countryCode?: CountryCode;
};

export const SignUpForm: React.FC<SignUpFormProps> = ({ onSubmit, submitting, isLime, email, countryCode }) => {
    const intl = useIntl();
    const [form] = Form.useForm();

    return (
        <AppearRight>
            <HubspotTracking countryCode={countryCode} />
            <Form form={form} layout="horizontal" onFinish={onSubmit} initialValues={{ email }}>
                <Title>
                    {intl.formatMessage({
                        id: 'welcome.SignUp.title',
                        defaultMessage: 'Get started with your account',
                    })}
                </Title>
                <FormLayout width="650px">
                    <EmailInput form={form} isLime={isLime} />
                    <Password form={form} />
                    <Legal />
                    <ButtonLine>
                        <Button
                            type="primary"
                            size="large"
                            htmlType="submit"
                            style={{ width: '100%' }}
                            disabled={submitting}
                            loading={submitting}
                        >
                            {intl.formatMessage({
                                id: 'common.agree',
                                defaultMessage: 'I Agree',
                            })}
                        </Button>
                    </ButtonLine>
                </FormLayout>
            </Form>
        </AppearRight>
    );
};
