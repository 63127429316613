// Libraries
import React from 'react';
import { FormattedMessage } from 'react-intl';

// Components
import { MultiFormConvictionsLegacy } from 'components/ConvictionsDeclaration';
import Title from 'certn-ui/Title';
import { AppearRight } from 'certn-ui/Animate';
import { Box } from 'certn-ui/Layout';
import Text from 'certn-ui/Text';

const ConvictionsForm = (props) => (
    <AppearRight>
        <Title>
            <FormattedMessage
                id="welcome.History.ConvictionsForm.title"
                defaultMessage="You mentioned you had a conviction, what happened?"
            />
        </Title>
        <Text align="center" color="certnGray600" margin="0 0 20px 0">
            <FormattedMessage
                id="welcome.allConvictionsIncluded"
                defaultMessage="All declared convictions are included in your report."
            />
        </Text>
        <Box size="75%">
            <MultiFormConvictionsLegacy handleSubmit={props.handleSubmit} />
        </Box>
    </AppearRight>
);

export default ConvictionsForm;
