// Libraries
import React from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

// Actions & Selectors
import { getTeamAccountManager } from 'views/manager/views/admin/AdminSelectors';

import Typography from 'certnd/Typography';
import Form from 'certnd/Form';

const { Heading } = Typography;

const Support = () => {
    const accountManager = useSelector(getTeamAccountManager);

    // Displays link to support docs and a id card for Account Manager
    return (
        <>
            <Heading.H1>
                <FormattedMessage id="744e7.Support.PageTitle" defaultMessage="Certn Support" />
            </Heading.H1>
            <Form.Block>
                <Heading.H3>
                    <FormattedMessage id="744e7.Support.NeedHelp" defaultMessage="Need Help? " />
                    <a href="https://certn.zendesk.com/hc/en-us" target="_blank" rel="noreferrer noopener">
                        <FormattedMessage
                            id="744e7.Support.ClickHere"
                            defaultMessage=" Click Here to visit the Support Docs"
                        />
                    </a>
                </Heading.H3>
                <Heading.H4>
                    <FormattedMessage
                        id="744e7.Support.SupportRep"
                        defaultMessage="Or contact your Customer Support Representative"
                    />
                </Heading.H4>
                {accountManager && (
                    <table>
                        <tbody>
                            <tr>
                                <th>
                                    {accountManager.first_name} {accountManager.last_name}
                                </th>
                                <td />
                            </tr>
                            <tr>
                                <th>
                                    <FormattedMessage id="744e7.Support.Email" defaultMessage="Email:" />
                                </th>
                                <td>
                                    <a href={`mailto:${accountManager.email}`}>{accountManager.email}</a>
                                </td>
                            </tr>
                            {accountManager.office_phone_number && (
                                <tr>
                                    <th>
                                        <FormattedMessage
                                            id="744e7.Support.OfficePhone"
                                            defaultMessage="Office Phone:"
                                        />
                                    </th>
                                    <td>{accountManager.office_phone_number}</td>
                                </tr>
                            )}
                            {accountManager.cell_phone_number && (
                                <tr>
                                    <th>
                                        <FormattedMessage id="744e7.Support.CellPhone" defaultMessage="Cell Phone:" />
                                    </th>
                                    <td>{accountManager.cell_phone_number}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                )}
                {!accountManager && (
                    <Heading.H3>
                        <a href="mailto:support@certn.co">support@certn.co</a>
                    </Heading.H3>
                )}
            </Form.Block>
        </>
    );
};

export default withRouter(Support);
