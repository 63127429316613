import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Button } from 'antd';
import { get } from 'lodash';
import { injectIntl, FormattedMessage } from 'react-intl';

import { ErrorAlertAPI, ErrorAlertNoTranslate } from 'certn-ui/ErrorAlert';
import { useAuth0 } from 'components/Auth0Provider';
import config from 'auth_config.json';

import { loginSSO } from 'base/BaseActions';
import { getSSOEnabled } from 'base/BaseSelectors';

const SSO = ({ intl }) => {
    const dispatch = useDispatch();
    const auth0 = useAuth0();
    const { loading, isAuthenticated } = auth0;
    const [triedLogin, setTriedLogin] = useState(false);
    const history = useHistory();
    const location = useLocation();
    const ssoEnabled = useSelector(getSSOEnabled, shallowEqual);

    useEffect(() => {
        if (loading) return;
        if (triedLogin && !isAuthenticated) {
            ErrorAlertNoTranslate({
                title: intl.formattedMessage({
                    id: '',
                    defaultMessage: intl.formattedMessage({
                        id: '5e80f.SSO.notAuthenticated',
                        defaultMessage: 'User Could Not be Authenticated with SSO Identity Provider',
                    }),
                }),
            });
            setTriedLogin(false);
        }
    }, [intl, isAuthenticated, loading, triedLogin]);

    const handleLogIn = () => {
        const fromUrl = get(location, ['state', 'from', 'pathname']);

        auth0
            .getTokenWithPopup({
                prompt: 'login',
                audience: config.audience,
                scope: 'openid profile email',
            })
            .then((access_token) => {
                dispatch(loginSSO(access_token))
                    .then(() => {
                        setTriedLogin(true);
                        history.push(fromUrl || '/hr'); // FIXME: improve, was pulled from existing login logic
                    })
                    .catch((error) => {
                        ErrorAlertAPI(error);
                    });
            })
            .catch((error) => console.error('error', error));
    };

    if (!ssoEnabled) return null;

    return (
        <Button id="sso_login" size="large" disabled={auth0.loading} loading={auth0.loading} onClick={handleLogIn}>
            <FormattedMessage id="5e80f.SSO.login" defaultMessage="Log in with SSO" />
        </Button>
    );
};
export default injectIntl(SSO);
