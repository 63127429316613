import { useState } from 'react';
import { Form, Input, Button } from 'antd';
import { useIntl } from 'react-intl';
import styled from 'styled-components/macro';

import { Regex } from 'modules';

export const ButtonBar = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
`;

type FormValues = {
    accessCode: string;
};

type DownloadVerifyFormProps = {
    handleSubmit: (values: FormValues) => void;
    loading: boolean;
    buttonText: string;
};

const DownloadVerifyForm = ({ handleSubmit, loading, buttonText }: DownloadVerifyFormProps) => {
    const intl = useIntl();
    const [hasSubmitted, setSubmitted] = useState(false);
    const validateTrigger = ['onSubmit'];
    // Validate onChange only after a submit has happened
    if (hasSubmitted) {
        validateTrigger.push('onChange');
    }
    return (
        <Form onFinish={handleSubmit}>
            <Form.Item
                name="accessCode"
                validateTrigger={validateTrigger}
                rules={[
                    {
                        pattern: Regex.alphaNumeric,
                        message: intl.formatMessage({
                            id: 'DownloadVerifyForm.accessCode.onlyAlpha',
                            defaultMessage: 'Please enter letters and numbers only',
                        }),
                    },
                    {
                        required: true,
                        message: intl.formatMessage({
                            id: 'DownloadVerifyForm.accessCode.required',
                            defaultMessage: 'Please enter your 6 character code',
                        }),
                    },
                    {
                        min: 6,
                        max: 6,
                        message: intl.formatMessage({
                            id: 'DownloadVerifyForm.accessCode.required',
                            defaultMessage: 'Please enter your 6 character code',
                        }),
                    },
                ]}
            >
                <Input
                    type="text"
                    maxLength={6}
                    data-testid="accessCode"
                    placeholder={intl.formatMessage({
                        id: 'DownloadVerifyForm.accessCode.placeholder',
                        defaultMessage: 'Enter 6 character code',
                    })}
                    autoFocus
                />
            </Form.Item>
            <ButtonBar>
                <Button
                    type="primary"
                    size="large"
                    data-testid="submit"
                    htmlType="submit"
                    onClick={() => setSubmitted(true)}
                    style={{ flex: 1 }}
                    loading={loading}
                >
                    {buttonText}
                </Button>
            </ButtonBar>
        </Form>
    );
};

export default DownloadVerifyForm;
