import React from 'react';
import styles from 'styles/styles';

const Evicted = ({ scale }) => (
    <svg width={75} height={61} style={{ transform: `scale3d(${scale}, ${scale}, ${scale})` }}>
        <title>hand truck_delivery_logistics_shipping</title>
        <g
            transform="translate(1 1)"
            stroke={styles.color.certnGreen700}
            strokeWidth={1.525}
            fill="none"
            fillRule="evenodd"
        >
            <path d="M25.878 43.162L.101 28.931 15.867.369 45.305 16.62l-13.78 24.898" />
            <path d="M33.732 10.684l-5.426 9.83-6.347-3.504 5.426-9.83" />
            <path d="M16.221 45.137L1.119 36.801" strokeLinecap="round" />
            <path d="M22.045 48.42L6.604 39.828M37.713 43.523l17.353-31.355h5.45" />
            <circle cx={31.968} cy={52.505} r={6.125} />
            <path
                d="M72.898 13.512a2.275 2.275 0 0 1-2.268 2.268H63a2.275 2.275 0 0 1-2.268-2.268v-2.465A2.275 2.275 0 0 1 63 8.779h7.632a2.276 2.276 0 0 1 2.269 2.268l-.003 2.465z"
                strokeLinecap="round"
            />
        </g>
    </svg>
);

export default Evicted;
