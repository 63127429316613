// Libraries
import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { FormattedMessage } from 'react-intl';

import BulkActionItems from './BulkActionItems';

// UI Components
import { Dropdown as AntDropdown, Button } from 'antd';

const Dropdown = styled(AntDropdown)`
    margin: 8px 0px;
`;

const BulkActions = ({ applications }) => {
    const [isDownloading, setIsDownloading] = useState(false);
    return (
        <Dropdown
            trigger={['click']}
            placement="bottomRight"
            disabled={applications.length === 0}
            overlay={<BulkActionItems applications={applications} setIsDownloading={setIsDownloading} />}
        >
            <Button loading={isDownloading}>
                <FormattedMessage id="9492f.BulkActions.primaryButton" defaultMessage="Bulk Actions" />
            </Button>
        </Dropdown>
    );
};

export default BulkActions;
