// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get } from 'lodash';
import { HomeOutlined, LinkOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, message, Input, Tooltip } from 'antd';
import { intl } from 'components/GlobalProvider';

// UI Components
import { Box, CenterLine } from 'certn-ui/Layout';
import { Fade } from 'certn-ui/Animate';
import Searchbar from 'certn-ui/Searchbar';
import Loader from 'certn-ui/Loader';
import Titlebar from 'certn-ui/Titlebar';
import { ErrorAlertCustom } from 'certn-ui/ErrorAlert';

// Modules & Utils
import { CopyToClipboard, Path } from 'modules';

// External Libraries
import queryString from 'query-string';
import copy from 'copy-to-clipboard';
import Cookies from 'js-cookie';

// Other Components

// Actions & Selectors
import {
    resetListAndFetch,
    resetListAndFilter,
    clearErrors,
    fetchListings,
    deleteListing,
    toggleListingInactive,
    mergeApplicants,
    setSearchBy,
    setIsActive,
    setPageSize,
    setFilterBy,
    clearFilters,
} from 'views/manager/views/pm/views/listings/ListingsActions';
import {
    getIsFetching,
    getIsFetchingSilent,
    getError,
    getListings,
    getFilterBy,
    getSearchBy,
    getFilterByEnabled,
    getIsActive,
    getNumListings,
    getNumAllListings,
} from 'views/manager/views/pm/views/listings/ListingsSelectors';
import { getUserMode, getUser } from 'base/BaseSelectors';
import { SendApplicationsForm, FilterForm, Table } from './components';
import { submitApplications } from 'views/manager/sharedActions/ApplicationActions';

const mapStateToProps = (state) => ({
    userMode: getUserMode(state),
    isFetching: getIsFetching(state),
    isFetchingSilent: getIsFetchingSilent(state),
    error: getError(state),
    listings: getListings(state),
    searchBy: getSearchBy(state),
    filterBy: getFilterBy(state),
    filterByEnabled: getFilterByEnabled(state),
    isActive: getIsActive(state),
    numListings: getNumListings(state),
    numAllListings: getNumAllListings(state),
    user: getUser(state),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            resetListAndFetch,
            resetListAndFilter,
            clearErrors,
            fetchListings,
            deleteListing,
            toggleListingInactive,
            submitApplications,
            mergeApplicants,
            setSearchBy,
            setIsActive,
            setPageSize,
            setFilterBy,
            clearFilters,
        },
        dispatch
    );

const propTypes = {
    // Redux Actions
    resetListAndFetch: PropTypes.func.isRequired,
    resetListAndFilter: PropTypes.func.isRequired,
    clearErrors: PropTypes.func.isRequired,
    fetchListings: PropTypes.func.isRequired,
    deleteListing: PropTypes.func.isRequired,
    toggleListingInactive: PropTypes.func.isRequired,
    submitApplications: PropTypes.func.isRequired,
    mergeApplicants: PropTypes.func.isRequired,
    setSearchBy: PropTypes.func.isRequired,
    setIsActive: PropTypes.func.isRequired,
    setFilterBy: PropTypes.func.isRequired,
    clearFilters: PropTypes.func.isRequired,
    // Redux State
    isFetching: PropTypes.bool.isRequired,
    isFetchingSilent: PropTypes.bool.isRequired,
    error: PropTypes.object.isRequired,
    listings: PropTypes.array.isRequired,
    searchBy: PropTypes.string,
    filterBy: PropTypes.object.isRequired,
    filterByEnabled: PropTypes.object,
    numAllListings: PropTypes.number.isRequired,
    settings: PropTypes.object,
};

const defaultProps = {
    filterByEnabled: {},
    settings: {},
    searchBy: null,
};

class ListingsList extends React.Component {
    constructor(props) {
        super(props);
        const { location } = props;

        this.state = {
            sendApplicationsVisible: false,
            trialLimitReachedVisible: false,
            listingId: undefined,
            listing: undefined,
            teamId: Cookies.get('team_id'),
        };

        // Legacy code, refactoring but no time to rewrite
        if (location?.search) {
            const parsed = queryString.parse(location.search);
            // In case you're coming from Listings page and filtering List by listing_id
            if (parsed.property_id) {
                props.resetListAndFilter({ property_id: parsed.property_id });
            } else {
                props.resetListAndFetch();
            }
        } else props.resetListAndFetch();

        if (location && location.search) {
            const parsed = queryString.parse(location.search);
            if (parsed.listing_created) message.success('Listing was successfully created!');
        }
    }

    copyToClipboard = (urlCode = null) => {
        let copySuccess;
        if (urlCode) {
            const copyText = `${Path.getPath()}/apply/${urlCode}`;
            copySuccess = copy(copyText);
        } else {
            const copyText = `${Path.getPath()}/browse/listings/${this.state.teamId}`;
            copySuccess = copy(copyText);
        }
        if (copySuccess) {
            message.success('Apply Link was copied to clipboard.');
        } else {
            message.error('Apply Link could not be copied.');
        }
    };

    buildData = (listings) => listings.map((listing, index) => ({ ...listing, key: index }));

    showSendApplications = (listingId, listing) => this.setState({ sendApplicationsVisible: true, listingId, listing });

    handleSendApplicationsOk = (emails, listingId) => {
        let upgrades = get(this.state, ['listing', 'test_collection']) || {};
        upgrades = Object.entries(upgrades)
            .filter(([key, value]) => !['created', 'modified', 'id'].includes(String(key)) && value !== null)
            .reduce((acc, [key, value]) => ({ [key]: value, ...acc }), {});

        const data = {
            listing_id: listingId,
            ...upgrades,
        };

        // Need to reformat into object for action and request
        const contactInfoList = emails.map((email) => ({ email }));

        return this.props
            .submitApplications({ data, contactInfoList, hr: false })
            .then((result) => {
                const count = result.payload.sentApplication.applicants.length;
                message.success(
                    intl.formatMessage(
                        {
                            id: '5b34c.ListingsList.applicationsSent',
                            defaultMessage:
                                '{count, plural, =0 {No Applications Sent} one {1 Application Sent} other {# Applications Sent}}',
                        },
                        { count }
                    )
                );
                this.setState(
                    {
                        sendApplicationsVisible: false,
                    },
                    () => {
                        this.props.fetchListings();
                    }
                );
            })
            .catch(() => ErrorAlertCustom({ title: 'Error sending application' }));
    };

    showTrialLimitReached = () => this.setState({ trialLimitReachedVisible: true });

    handleModalsCancel = () =>
        this.setState({ sendApplicationsVisible: false, trialLimitReachedVisible: false }, this.props.clearErrors);

    render() {
        const data = this.props.listings && this.buildData(this.props.listings);
        return (
            <>
                {this.state.sendApplicationsVisible && (
                    <SendApplicationsForm
                        listing={this.state.listing}
                        onOk={this.handleSendApplicationsOk}
                        onCancel={this.handleModalsCancel}
                        confirmLoading={this.props.isFetchingSilent}
                        mergeApplicants={this.props.mergeApplicants}
                        error={this.props.error}
                    />
                )}
                <Titlebar title="Listings">
                    <Button type="primary" onClick={() => this.props.history.push('/pm/listings/new')}>
                        ADD A LISTING
                    </Button>
                </Titlebar>
                {(this.props.isFetching || this.props.isFetchingSilent) && data && data.length === 0 ? (
                    <Loader />
                ) : (
                    <Fade>
                        <>
                            <Table.Nav isActive={this.props.isActive} setIsActive={this.props.setIsActive} />
                            {this.props.isActive && (
                                <CenterLine style={{ marginTop: 4, padding: 0 }}>
                                    <Input
                                        disabled
                                        addonBefore={
                                            <>
                                                <span>Apply Page </span>
                                                <Tooltip title="This link will allow applicants to select any active Listing to apply for">
                                                    <QuestionCircleOutlined />
                                                </Tooltip>
                                            </>
                                        }
                                        value={`${Path.getPath()}/listings/${this.state.teamId}`}
                                        style={{ maxWidth: '100%', width: 300, marginRight: 8 }}
                                    />
                                    <Button
                                        icon={<LinkOutlined />}
                                        onClick={() => CopyToClipboard({ route: 'listings', type: 'pm' })}
                                    >
                                        copy
                                    </Button>
                                </CenterLine>
                            )}
                            <Searchbar
                                icon={<HomeOutlined />}
                                value={this.props.searchBy}
                                onSearch={this.props.setSearchBy}
                                showDot={Object.keys(this.props.filterByEnabled).length > 0}
                                filterCount={Object.keys(this.props.filterByEnabled).length}
                            >
                                <FilterForm onSubmit={this.props.setFilterBy} clearFilters={this.props.clearFilters} />
                            </Searchbar>
                            {!this.props.isFetching && this.props.numListings === 0 && (
                                // {!this.props.isFetching && this.props.numListings === 0 && this.props.numAllListings === 0 ? (
                                <Box center border>
                                    <Titlebar
                                        size="lrg"
                                        title="Create your first listing"
                                        center
                                        subtitle="Create your first listing so you can generate a custom link for your website or classifieds ads."
                                    >
                                        <Button
                                            size="large"
                                            type="primary"
                                            onClick={() => this.props.history.push('/pm/listings/new')}
                                        >
                                            ADD A LISTING
                                        </Button>
                                    </Titlebar>
                                </Box>
                            )}
                            {this.props.numListings > 0 && (
                                <Table
                                    data={data}
                                    showSendApplications={this.showSendApplications}
                                    showTrialLimitReached={this.showTrialLimitReached}
                                />
                            )}
                        </>
                    </Fade>
                )}
            </>
        );
    }
}

ListingsList.propTypes = propTypes;
ListingsList.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(ListingsList);
