import { withRouter } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import withNavigation from 'views/welcome/modules/WithNavigation';
import { setTrackPageOrder } from 'views/welcome/WelcomeActions';

import useS3GetUploadLinkAndUpload from './hooks/useS3GetUploadLinkAndUpload';
import SelfieForm from './SelfieForm';

const propTypes = {
    // Redux Store:
    documents: PropTypes.array,
};
const defaultProps = {
    documents: [],
};

const Selfie = ({ intl, trackPageLocation, handleNextTrack }) => {
    const dispatch = useDispatch();

    const { s3GetUploadLinkAndUpload, selfie, uploadProgress } = useS3GetUploadLinkAndUpload(intl);

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(setTrackPageOrder(['base'])); /* WithNavigation */
    }, [dispatch]);

    const handleSubmit = (e) => {
        e.preventDefault();
        handleNextTrack();
    };

    const pages = {
        base: (
            <SelfieForm
                handleSubmit={handleSubmit}
                onUpload={s3GetUploadLinkAndUpload}
                file={selfie}
                fileProgress={uploadProgress}
                uploadComplete={uploadProgress === 100}
            />
        ),
    };
    return pages[trackPageLocation]; /* WithNavigation */
};

Selfie.propTypes = propTypes;
Selfie.defaultProps = defaultProps;

export default withRouter(injectIntl(withNavigation(Selfie)));
