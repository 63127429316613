import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Form } from '@ant-design/compatible';
import { Radio } from 'antd';
import Typography from 'certnd/Typography';
import { formPropTypes, formOptionsPropTypes } from './formPropTypes';
import { snakeCase } from 'lodash';

const { Text } = Typography;
import styles from 'styles/styles';

const FormRadioGroupWrapper = styled.div`
    border-bottom: ${(props) => (props.hideBorder ? 'none' : `solid ${styles.color.certnGray400} 1px`)};
    width: 100%;
    margin-bottom: 20px;
`;

const FormRadioWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;

    & > *:not(:last-child) {
        margin-right: 10px;
    }
`;

const FormRadioContent = styled.div`
    /* display: block; ant design 4x broke styling need inline-table or inline-grid */
    display: inline-table;
    margin-top: ${(props) => (props.centerHeading ? '4px' : '0px')};
    margin-bottom: ${(props) => (props.last ? '0px' : props.centerHeading ? '16px' : '20px')};
    margin-right: 16px; /* 16px - size of radio button */
    white-space: normal;
    text-align: left;
`;

const FormRadio = ({ hideBorder, form, fieldName, options, radioOptions, ...rest }) => (
    <FormRadioGroupWrapper hideBorder={hideBorder}>
        <Form.Item>
            {form.getFieldDecorator(
                fieldName,
                options
            )(
                <Radio.Group style={{ lineHeight: 1.5 }} {...rest}>
                    {radioOptions.map(({ title, description, key, value, ...restRadioOptions }, index) => (
                        <FormRadioWrapper key={key}>
                            <Radio
                                data-testid={snakeCase(title)}
                                key={key}
                                value={value !== undefined && value !== null ? value : key}
                                {...restRadioOptions}
                            >
                                <FormRadioContent centerHeading={!description} last={index === radioOptions.length - 1}>
                                    <div>{title}</div>
                                    {description ? <Text.Secondary>{description}</Text.Secondary> : null}
                                </FormRadioContent>
                            </Radio>
                        </FormRadioWrapper>
                    ))}
                </Radio.Group>
            )}
        </Form.Item>
    </FormRadioGroupWrapper>
);

FormRadio.propTypes = {
    hideBorder: PropTypes.bool,
    fieldName: PropTypes.string.isRequired,
    form: formPropTypes.isRequired,
    options: formOptionsPropTypes,
    radioOptions: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            description: PropTypes.string,
            key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired, // key is the value if not provided
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
        })
    ).isRequired,
};

FormRadio.defaultProps = {
    hideBorder: false,
    options: {},
};

export default FormRadio;
