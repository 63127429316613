import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

// UI Components
import { ListCellLink } from 'certn-ui/List';
import { CellWrapper, CellText } from 'views/manager/components';
import { FormattedMessage } from 'react-intl';

// Selectors
import { getUserMode } from 'base/BaseSelectors';

const mapStateToProps = (state) => ({
    userMode: getUserMode(state),
});

const propTypes = {
    // Redux State
    userMode: PropTypes.string.isRequired,
};

const InfoCell = ({ record, userMode, history }) => (
    <CellWrapper col>
        <CellText>{record.unit && `${record.unit} - `}</CellText>
        <CellText>{record.owner.email}</CellText>
        {!record.applicant_count || record.applicant_count === 0 ? (
            <CellText highlight={!record.is_viewed}>
                <FormattedMessage id="d9a5c.InfoCell.zero" defaultMessage="0 Applications" />
            </CellText>
        ) : (
            <ListCellLink
                onClick={() => history.push(`/${userMode.toLowerCase()}/applications?listing_id=${record.id}`)}
            >
                {record.applicant_count} <FormattedMessage id="common.applications" defaultMessage="Applications" />{' '}
                {record.new_applicant_count > 0 && (
                    <strong>
                        {' '}
                        ({record.new_applicant_count || 0}{' '}
                        <FormattedMessage id="d9a5c.InfoCell.new" defaultMessage="New" />)
                    </strong>
                )}
            </ListCellLink>
        )}
    </CellWrapper>
);

InfoCell.propTypes = propTypes;

export default withRouter(connect(mapStateToProps, {})(InfoCell));
