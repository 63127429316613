// Libraries
import React, { useState } from 'react';
import { get } from 'lodash';
import { useSelector } from 'react-redux';

import { FormattedMessage, useIntl } from 'react-intl';

// Components
import { AppearRight } from 'certn-ui/Animate';
import Title from 'certn-ui/Title';
import { InfoCircleOutlined } from '@ant-design/icons';
import { AutoComplete, Popover, Select, Checkbox, Form } from 'antd';
import FormLayout from 'certn-form/FormLayout';
import { TrackNavigation } from 'views/welcome/components';
import { getLanguage } from 'base/BaseSelectors';

// Modules
import { endDateIsToday, getEndDateBeforeStartDateError, isUUID } from 'modules/validation';
import DateInput from 'certn-form/DateInput';
import { OTHER } from 'modules/Countries';
import { CountrySelect, getCountryInstitutions, InstitutionsDropdown } from 'components';
import { WorkLocationAddressInput } from 'certn-form';

const EducationDetails = (props) => {
    const {
        handleSubmit,
        educations,
        degrees,
        currentEducationIndex,
        updateAddressError,
        highestLevelOnly,
        updateInstitutions,
        institutions,
    } = props;
    const intl = useIntl();
    const [form] = Form.useForm();
    const [country, setCountry] = useState(educations[currentEducationIndex]?.address?.country);
    const [educationType, setEducationType] = useState(educations[currentEducationIndex]?.current);
    const [institution, setInstitution] = useState({
        name: educations[currentEducationIndex]?.institution,
        value: educations[currentEducationIndex]?.institution_external_id || '',
    });

    const [showInstitutionSelect, setShowInstitutionSelect] = useState(
        country ? ['CA', 'US'].includes(country) : false
    );
    const [educationInstitutions, setEducationInstitutions] = useState(institutions);

    const formatFields = (formValues) => {
        // institution external ID is used if the institution came from the dropdown
        const hasExternalID = hasInstitutionExternalID();

        let chosenSchool = [];
        let schoolFromDropdown = false;
        let institution_external_id;
        // School comes from dropdown
        if (hasExternalID && isUUID(institution.value)) {
            schoolFromDropdown = true;
            chosenSchool = educationInstitutions[country].filter((school) => school.id === institution.value);
            institution_external_id = chosenSchool[0].external_id;
        } else {
            // is not UUID when being edited per pre-population on line 46
            institution_external_id = hasExternalID ? institution.value : '';
        }
        if (formValues.province_state === undefined) formValues.province_state = OTHER;

        // Set default patch values
        const values = {
            province_state: OTHER,
            other_province_state: null,
            institution_external_id,
            institution: institution.name,
            ...formValues,
        };

        // if school comes from dropdown, set specific data to be patched
        if (schoolFromDropdown) {
            values.institution = chosenSchool[0].basic_name;
            values.province_state = chosenSchool[0].province_state || OTHER;
        }

        const dateCompareError = getEndDateBeforeStartDateError(values.end_date, values.start_date);
        if (dateCompareError) {
            form.setFields({
                start_date: {
                    value: values.start_date,
                    errors: [new Error(dateCompareError)],
                },
            });
        }
        if (!country) {
            updateAddressError(true);
        } else {
            values.address = {
                province_state: values.province_state,
                other_province_state: values.other_province_state,
                city: values.city,
                country,
            };
        }

        if (!dateCompareError) {
            if (values.degree) values.degree = { degree: values.degree };
            if (values.specializations) {
                values.specializations = [{ specialization: values.specializations }];
            } else if (values.specializations === '') {
                values.specializations = [];
            } else {
                delete values.specializations;
            }

            if (values.current) {
                values.end_date = null;
            }

            const patchValues = educations;
            if (patchValues[currentEducationIndex]) {
                patchValues[currentEducationIndex] = {
                    ...patchValues[currentEducationIndex],
                    ...values,
                };
            } else patchValues.push(values);

            handleSubmit(patchValues);
        } else {
            console.error('form error', dateCompareError);
        }
    };

    const highestLevelEntryExists = () => educations?.some((education) => education.highest_level);

    const language = useSelector(getLanguage);
    const degreeList = degrees.map((degree) =>
        language === 'fr' && degree.degree_fr ? degree.degree_fr : degree.degree
    );

    const handleCountryChange = async (newCountry) => {
        setCountry(newCountry);

        if (newCountry === 'CA' || newCountry === 'US') {
            if (!(newCountry in educationInstitutions)) {
                getCountryInstitutions(setEducationInstitutions, educationInstitutions, newCountry, updateInstitutions);
            }
            setShowInstitutionSelect(true);
        } else {
            setShowInstitutionSelect(false);
        }
    };

    const hasInstitutionExternalID = () => {
        if (showInstitutionSelect && institution.value && institution.name !== institution.value) {
            return true;
        }
        return false;
    };

    return (
        <AppearRight>
            <Title>
                <FormattedMessage
                    id="welcome.Education.EducationDetails.title"
                    defaultMessage="Can you give me a few details about your education?"
                />
            </Title>
            <Form form={form} layout="horizontal" onFinish={(e) => formatFields(e)}>
                <FormLayout>
                    <Form.Item
                        hasFeedback
                        name="educationCountry"
                        initialValue={country || undefined}
                        validateTrigger="onFinish"
                        rules={[
                            {
                                required: true,
                                type: 'string',
                                message: intl.formatMessage({
                                    id: 'error.validation.countryError',
                                    defaultMessage: 'Select a country!',
                                }),
                            },
                            {
                                validator: (rule, value, callback) => {
                                    const countryField = form.getFieldsValue().country;
                                    if (countryField !== value && !hasInstitutionExternalID()) {
                                        callback(
                                            intl.formatMessage({
                                                id: 'error.validation.countriesDoNotMach',
                                                defaultMessage:
                                                    'This country does not match the one given below. Please make sure they match.',
                                            })
                                        );
                                    }
                                    callback();
                                },
                            },
                        ]}
                        style={{ display: 'block', marginBottom: '10px' }}
                    >
                        <CountrySelect
                            autoFocus
                            initialValue={country || undefined}
                            placeholder={intl.formatMessage({
                                id: 'common.country',
                                defaultMessage: 'Country',
                            })}
                            onChange={handleCountryChange}
                        />
                    </Form.Item>
                    {country && (
                        <Form.Item
                            hasFeedback
                            key="current"
                            name="current"
                            initialValue={get(educations, [currentEducationIndex, 'current'])}
                            rules={[
                                {
                                    required: true,
                                    message: intl.formatMessage({
                                        id: 'error.validation.notBlank',
                                        defaultMessage: 'Please do not leave blank',
                                    }),
                                },
                            ]}
                            style={{ display: 'block', marginBottom: '5px' }}
                        >
                            <Select
                                size="large"
                                data-testid="current"
                                placeholder={intl.formatMessage({
                                    id: 'welcome.Education.EducationDetails.current',
                                    defaultMessage: 'Are you currently attending?',
                                })}
                                onChange={setEducationType}
                            >
                                <Select.Option data-testid="yes_current" value>
                                    <FormattedMessage
                                        id="welcome.Education.EducationDetails.thisIsCurrent"
                                        defaultMessage="This education is current / ongoing"
                                    />
                                </Select.Option>
                                <Select.Option data-testid="not_current" value={false}>
                                    <FormattedMessage
                                        id="welcome.Education.EducationDetails.thisIsNotCurrent"
                                        defaultMessage="I have completed / finished with this education"
                                    />
                                </Select.Option>
                            </Select>
                        </Form.Item>
                    )}
                    {educationType !== undefined && (
                        <Form.Item
                            hasFeedback
                            name="start_date"
                            initialValue={get(educations, [currentEducationIndex, 'start_date'])}
                            rules={[
                                {
                                    required: true,
                                    message: intl.formatMessage({
                                        id: 'error.validation.notBlank',
                                        defaultMessage: 'Please do not leave blank',
                                    }),
                                },
                            ]}
                            style={{ display: 'block', marginBottom: '5px' }}
                        >
                            <DateInput
                                size="large"
                                data-testid="start_date"
                                disableFutureDates
                                placeholder={intl.formatMessage({
                                    id: 'form.label.startDate',
                                    defaultMessage: 'Start Date',
                                })}
                                addonBefore={
                                    <Popover
                                        content={intl.formatMessage({
                                            id: 'welcome.Education.EducationDetails.startDateTip',
                                            defaultMessage: 'The date that your studies began',
                                        })}
                                    >
                                        <InfoCircleOutlined />
                                    </Popover>
                                }
                            />
                        </Form.Item>
                    )}
                    {/* If education is completed, educationType is set to false */}
                    {educationType !== undefined && !form.getFieldValue('current') && (
                        <Form.Item
                            hasFeedback
                            name="end_date"
                            initialValue={get(educations, [currentEducationIndex, 'end_date'])}
                            rules={[
                                {
                                    required: true,
                                    message: intl.formatMessage({
                                        id: 'error.validation.notBlank',
                                        defaultMessage: 'Please do not leave blank',
                                    }),
                                },
                                {
                                    validator: (rule, value, callback) => {
                                        const result = endDateIsToday(value);
                                        callback(result);
                                    },
                                },
                            ]}
                            style={{ display: 'block', marginBottom: '5px' }}
                        >
                            <DateInput
                                size="large"
                                data-testid="end_date"
                                disableFutureDates
                                placeholder={intl.formatMessage({
                                    id: 'form.label.dateAwarded',
                                    defaultMessage: 'Date Awarded',
                                })}
                                addonBefore={
                                    <Popover
                                        content={intl.formatMessage({
                                            id: 'welcome.Education.EducationDetails.dateAwardedTip',
                                            defaultMessage: 'The date you were awarded your degree or diploma',
                                        })}
                                    >
                                        <InfoCircleOutlined />
                                    </Popover>
                                }
                            />
                        </Form.Item>
                    )}
                    {educationType !== undefined && (
                        <InstitutionsDropdown
                            showInstitutionSelect={showInstitutionSelect}
                            form={form}
                            educationInstitutions={educationInstitutions}
                            institution={institution}
                            setInstitution={setInstitution}
                            country={country}
                        />
                    )}
                    {institution.name && !hasInstitutionExternalID() && (
                        <WorkLocationAddressInput
                            currentAddress={get(educations, [currentEducationIndex, 'address'])}
                            form={form}
                            hideLabels
                            bypassStreetAddress
                            bypassPostal
                            bypassUnit
                            bypassCounty
                        />
                    )}
                    {institution.name && (
                        <Form.Item
                            hasFeedback
                            name="degree"
                            initialValue={get(educations, [currentEducationIndex, 'degree', 'degree'])}
                            rules={[
                                {
                                    required: true,
                                    message: intl.formatMessage({
                                        id: 'error.validation.notBlank',
                                        defaultMessage: 'Please do not leave blank',
                                    }),
                                },
                            ]}
                            style={{ display: 'block', marginBottom: '5px' }}
                        >
                            <AutoComplete
                                size="large"
                                data-testid="degree"
                                dataSource={degreeList}
                                placeholder={intl.formatMessage({
                                    id: 'welcome.Education.EducationDetails.degreeTitle',
                                    defaultMessage: 'Enter your degree title',
                                })}
                                filterOption={(inputValue, option) =>
                                    option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                }
                            />
                        </Form.Item>
                    )}

                    {highestLevelOnly &&
                        (!highestLevelEntryExists() || get(educations, [currentEducationIndex, 'highest_level'])) && (
                            <Form.Item
                                key="highest_level"
                                name="highest_level"
                                valuePropName="checked"
                                initialValue={get(educations, [currentEducationIndex, 'highest_level']) || false}
                                rules={[
                                    {
                                        required: true,
                                        message: intl.formatMessage({
                                            id: 'error.validation.notBlank',
                                            defaultMessage: 'Please do not leave blank',
                                        }),
                                    },
                                ]}
                                style={{ display: 'block', marginBottom: '5px' }}
                            >
                                <Checkbox data-testid="highest_level" id="tos">
                                    <FormattedMessage
                                        id="welcome.Education.highestLevelEdu"
                                        defaultMessage="Highest level of education"
                                    />
                                </Checkbox>
                            </Form.Item>
                        )}
                </FormLayout>
                <TrackNavigation />
            </Form>
        </AppearRight>
    );
};

export default EducationDetails;
