export const baseConfig = {
    desktop: [
        'Updated',
        'Email',
        'Short uid',
        'First Name',
        'Last Name',
        'Unit',
        'Addresses',
        'Co-applicants',
        'Co-signers',
        'Team',
        'Owner',
        'Application Status',
        'Adjudication Status',
        'Adverse Action Status',
        'Score',
        'Actions',
    ],
    mobile: ['Info', 'Score', 'Actions'],
    clientConfiguredColumns: true,
};
