// Libraries
import React from 'react';
import { useSelector } from 'react-redux';
import { Form } from 'antd';

// Components
import { RequiredText } from 'certn-ui';
import { intl } from 'components/GlobalProvider';
import DateInput from 'certn-form/DateInput';

// Actions & Selectors
import { getSelectedCheckKeys } from 'views/manager/features/ScreenApplicant/ScreenApplicantSelectors';

// Modules
import isVisibleOrRequired from './isVisibleOrRequired';

const DateOfBirth = () => {
    const selectedServices = useSelector(getSelectedCheckKeys);
    const label = intl.formatMessage({
        id: 'common.dateOfBirth',
        defaultMessage: 'Date Of Birth',
    });

    // Checks to display field and set required, true in brackets for all services
    const visibleList = [true];
    const requiredList = [true];

    if (!isVisibleOrRequired(visibleList, selectedServices)) return null;
    return (
        <>
            <RequiredText>{label}</RequiredText>
            <Form.Item
                name="date_of_birth"
                validateTrigger="onChange"
                initialValue={null}
                rules={[
                    {
                        required: isVisibleOrRequired(requiredList, selectedServices),
                        message: intl.formatMessage({
                            id: 'error.validation.notBlank',
                            defaultMessage: 'Please do not leave blank',
                        }),
                    },
                ]}
            >
                <DateInput
                    placeholder={intl.formatMessage({
                        id: 'common.dateOfBirth',
                        defaultMessage: 'Date Of Birth',
                    })}
                    isBirthDate
                />
            </Form.Item>
        </>
    );
};

export default DateOfBirth;
