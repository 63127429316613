// Libraries
import { Button, message } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { setInitialProcessingStatus } from './utils';

// Components
import { AppearRight } from 'certn-ui/Animate';
import { ErrorAlertAPI } from 'certn-ui/ErrorAlert';
import Title from 'certn-ui/Title';
import { useTracking } from 'hooks/trackingHooks';
import { BackButton, NextButtonNoButtonLine, TrackNavigation } from 'views/welcome/components';
import { ButtonLine } from 'certn-ui/Button';
import { SubmissionResponse } from 'views/welcome/components/SubmissionResponse';
import OneIDByPassModal from './OneIDByPassModal';
import Text from 'certn-ui/Text';
import Image from 'certn-ui/Image';

// Modules
import withNavigation from 'views/welcome/modules/WithNavigation';

// Actions & Selectors
import {
    getApplicantStatus,
    getIsUkDbsOrDsCheck,
    getIsUkRtwCheck,
    getEnhancedIdentity,
    getEnhancedIdentitySubmitted,
    getEnhancedIdentityPending,
    getInformation,
    getOneIDProvider,
    getPrevTrack,
    getTeam,
} from 'views/welcome/WelcomeSelectors';

import OneIDProviderWrapper from 'components/OneIdProviderWrapper/OneIDProviderWrapper';
import config from 'utils/config';
import {
    bypassOneID,
    demoSkipOneID,
    patchInformation,
    setTrackPageOrder,
    submitIdentityVerification,
    submitWelcomeSession,
    patchWelcomeSession,
} from 'views/welcome/WelcomeActions';
import { ONEID_PROVIDERS } from 'views/welcome/WelcomeConstants';

import ONEID_LARGE from 'images/oneID_assets/oneid-large.png';
import ONEID_SMALL from 'images/oneID_assets/oneid-small@2x.png';
import UK_ONEID_LARGE_LICENSE from 'images/oneID_assets/oneid-large-uk-license.jpg';
import UK_ONEID_SMALL_LICENSE from 'images/oneID_assets/oneid-small-uk-license.jpg';
import UK_ONEID_LARGE_PASSPORT from 'images/oneID_assets/oneid-large-uk-passport.jpg';
import UK_ONEID_SMALL_PASSPORT from 'images/oneID_assets/oneid-small-uk-passport.jpg';
import ONEID_SUCCESS from 'images/oneID_assets/oneid-success.svg';

/**
 * OneID - Used to render various identity verification providers
 * identity_verification_provider in welcome.enhancedIdentityVerification object
 */
const OneID = ({ handleNextTrackAndAutoSubmit, history }) => {
    const HideOnMobile = styled.div`
        @media (max-width: ${(props) => props.theme.width.med}) {
            display: none;
        }
    `;
    const HideOnDesktop = styled.div`
        @media (min-width: ${(props) => props.theme.width.med}) {
            display: none;
        }
    `;
    const intl = useIntl();
    const dispatch = useDispatch();
    const enhancedIdentitySubmitted = useSelector(getEnhancedIdentitySubmitted);
    const enhancedIdentityPending = useSelector(getEnhancedIdentityPending);
    const oneIdId = useSelector(getEnhancedIdentity).id;
    const applicantStatus = useSelector(getApplicantStatus);
    const team = useSelector(getTeam);
    const information = useSelector(getInformation);
    const oneIDProvider = useSelector(getOneIDProvider);
    const prevTrack = useSelector(getPrevTrack);
    const { sendTrackingEvent } = useTracking();
    const isUkDbsOrDsCheck = useSelector(getIsUkDbsOrDsCheck);
    const isUkRtwCheck = useSelector(getIsUkRtwCheck);
    const [submitted, setSubmitted] = useState(false);
    const [processing, setProcessing] = useState(
        setInitialProcessingStatus(isUkRtwCheck, isUkDbsOrDsCheck, enhancedIdentityPending)
    );
    const [isOneIDBypassModalVisible, setIsOneIDBypassModalVisible] = useState(false);

    const isYoti = oneIDProvider === ONEID_PROVIDERS.YOTI;
    const toggleIsOneIDBypassVisible = () => {
        setIsOneIDBypassModalVisible(!isOneIDBypassModalVisible);
    };

    const handleOneIDBypassSubmit = async () => {
        try {
            await dispatch(bypassOneID());
            toggleIsOneIDBypassVisible();
            handleNextTrackAndAutoSubmit(dispatchWelcomeSession);
        } catch {
            message.error('Error with OneID bypass');
        }
    };

    useEffect(() => {
        dispatch(setTrackPageOrder(['base']));
    }, [dispatch]);

    const handleDemoSkip = (options) => {
        dispatch(demoSkipOneID(options))
            .then(() => handleNextTrackAndAutoSubmit(dispatchWelcomeSession))
            .catch(() => message.error('Error with OneID bypass'));
    };

    const handleSubmit = () => {
        dispatch(patchInformation()).then(() => handleNextTrackAndAutoSubmit(dispatchWelcomeSession));
    };

    const handleOneIdSubmit = useCallback(() => {
        setSubmitted(true); // initial success text change
        if (!isYoti || !isUkDbsOrDsCheck) {
            dispatch(submitIdentityVerification());
        }
    }, [isUkDbsOrDsCheck, dispatch, isYoti]);

    const handleProcessing = useCallback((value) => {
        setProcessing(value);
    }, []);

    const handleAwaitingResultsBackButton = () => {
        dispatch(patchWelcomeSession(prevTrack));
        history.push(`/welcome/${prevTrack}`);
    };

    const dispatchWelcomeSession = async () => {
        try {
            await dispatch(submitWelcomeSession());
            sendTrackingEvent({
                googleAnalyticsKey: team?.google_analytics_key,
                bingKey: team?.bing_key,
                adwordsKey: team?.adwords_conversion_key,
                information,
            });
        } catch (error) {
            ErrorAlertAPI(error);
        }
    };

    function getOneIdImages() {
        if (isYoti) {
            return isUkDbsOrDsCheck
                ? { oneIdImageLarge: UK_ONEID_LARGE_LICENSE, oneIdImageSmall: UK_ONEID_SMALL_LICENSE }
                : { oneIdImageLarge: UK_ONEID_LARGE_PASSPORT, oneIdImageSmall: UK_ONEID_SMALL_PASSPORT };
        }
        return { oneIdImageLarge: ONEID_LARGE, oneIdImageSmall: ONEID_SMALL };
    }
    const oneIdImages = getOneIdImages();

    // when this track is successfully auto-submitted (see TRI-940 for context) we show the success screen
    if (applicantStatus === 'submitted') {
        return <SubmissionResponse history={history} />;
    }

    const enableProcessingScreen = isUkDbsOrDsCheck;
    const ukCheckProcessing = isYoti && enhancedIdentityPending && enableProcessingScreen;

    const ukSubmittedText = isUkDbsOrDsCheck ? (
        <FormattedMessage
            id="welcome.IdentityEnhanced.documentsUploadedTitleYoti.Dbs"
            defaultMessage="Your identity verification details have been processed. Please continue to the next step."
        />
    ) : (
        <FormattedMessage
            id="welcome.IdentityEnhanced.documentsUploadedTitleYoti.Rtw"
            defaultMessage="Your passport verification details have been submitted. Please continue to the next step."
        />
    );

    const renderTitle = () => {
        // Flow has already been submitted
        if (enhancedIdentitySubmitted && !ukCheckProcessing) {
            return (
                <>
                    {isYoti ? (
                        ukSubmittedText
                    ) : (
                        <FormattedMessage
                            id="welcome.IdentityEnhanced.youHaveAlreadySubmitted"
                            defaultMessage="Your verification details have already been submitted. Please continue to the next step."
                        />
                    )}
                    <Image
                        src={ONEID_SUCCESS}
                        size="xxlrg"
                        style={{ marginTop: '30px' }}
                        data-testid="oneid-image-success"
                    />
                </>
            );
        }

        if (processing && !submitted && enableProcessingScreen) {
            return (
                <>
                    <FormattedMessage
                        id="welcome.IdentityEnhanced.awaitingResults"
                        defaultMessage="Your identity verification details have been submitted for processing. This can take up to 3 minutes to complete. Please wait for completion before proceeding to the next step."
                    />
                    <Image
                        src={ONEID_SUCCESS}
                        size="xxlrg"
                        style={{ marginTop: '90px' }}
                        data-testid="oneid-image-success"
                    />
                    <ButtonLine dualButtons width="fit-content">
                        <BackButton dualButtons type="secondary" onClick={handleAwaitingResultsBackButton} />
                        <NextButtonNoButtonLine
                            dualButtons
                            loading
                            disabled
                            onClick={null}
                            nextButtonTitle={intl.formatMessage({
                                id: 'common.processing',
                                defaultMessage: 'Processing',
                            })}
                        />
                    </ButtonLine>
                </>
            );
        }

        if (!submitted) {
            return (
                <>
                    {isYoti && isUkRtwCheck ? (
                        <FormattedMessage
                            id="welcome.OneID.titleYotiRtw"
                            defaultMessage="We need to validate your passport to continue your background check"
                        />
                    ) : (
                        <FormattedMessage
                            id="welcome.OneID.title"
                            defaultMessage="We need to validate your identity to continue your background check"
                        />
                    )}
                    <br />
                    {[...ONEID_PROVIDERS.VERIFF, ONEID_PROVIDERS.YOTI, ONEID_PROVIDERS.TRUSTMATIC].includes(
                        oneIDProvider
                    ) && (
                        <>
                            <HideOnMobile>
                                <Text align="center" style={{ marginTop: '20px', fontSize: '1rem' }}>
                                    {isYoti ? (
                                        <FormattedMessage
                                            id="welcome.OneID.descriptionUK"
                                            defaultMessage="Please make sure you have your identity documents such as a passport or drivers licence ready. This can be completed with a <b>smartphone</b> or your <b>current device</b>."
                                            values={{
                                                b: (chunks) => <strong>{chunks}</strong>,
                                            }}
                                        />
                                    ) : (
                                        <FormattedMessage
                                            id="welcome.OneID.description"
                                            defaultMessage="This can be completed with a <b>smartphone</b> or your <b>current device</b>"
                                            values={{
                                                b: (chunks) => <strong>{chunks}</strong>,
                                            }}
                                        />
                                    )}
                                </Text>
                                <Image
                                    src={oneIdImages.oneIdImageLarge}
                                    size="xxlrg"
                                    style={{ marginTop: '50px' }}
                                    data-testid="oneid-image-large"
                                />
                            </HideOnMobile>
                            <HideOnDesktop>
                                <Image
                                    src={oneIdImages.oneIdImageSmall}
                                    size="xlrg"
                                    style={{ marginTop: '35px' }}
                                    data-testid="oneid-image-small"
                                />
                            </HideOnDesktop>
                        </>
                    )}
                </>
            );
        }

        // Successful submit
        if (submitted) {
            return (
                <>
                    {isYoti ? (
                        ukSubmittedText
                    ) : (
                        <FormattedMessage
                            id="welcome.IdentityEnhanced.documentsUploadedTitle"
                            defaultMessage="Your verification details have been successfully submitted. Please continue to the next step."
                        />
                    )}
                    {oneIDProvider !== ONEID_PROVIDERS.VOUCHED && (
                        <Image
                            src={ONEID_SUCCESS}
                            size="xxlrg"
                            style={{ marginTop: '90px' }}
                            data-testid="oneid-image-success"
                        />
                    )}
                </>
            );
        }
    };

    // Once API submitted remove mounted element + move Next button into AppearRight
    if (enhancedIdentitySubmitted && !ukCheckProcessing)
        return (
            <AppearRight>
                <Title>{renderTitle()}</Title>
                <TrackNavigation handleSubmit={handleSubmit} />
            </AppearRight>
        );

    return (
        <>
            <AppearRight>
                <Title>{renderTitle()}</Title>
            </AppearRight>

            <OneIDProviderWrapper
                handleSubmit={handleOneIdSubmit}
                oneIDProvider={oneIDProvider}
                oneIdId={oneIdId}
                handleProcessing={handleProcessing}
            />

            <OneIDByPassModal
                isVisible={isOneIDBypassModalVisible}
                handleClose={toggleIsOneIDBypassVisible}
                handleSubmit={handleOneIDBypassSubmit}
            />
            {!processing && submitted && <TrackNavigation handleSubmit={handleSubmit} />}
            {/* Demo Skip only available in non-production builds */}
            {config.isNotProduction && !submitted && (
                <ButtonLine dualButtons width="fit-content">
                    <Button size="large" type="dashed" onClick={() => handleDemoSkip({ result: 'CLEAR' })}>
                        <FormattedMessage id="button.demoSkip" defaultMessage="Demo Skip" />
                    </Button>
                    {isYoti && (
                        <Button
                            size="large"
                            type="dashed"
                            style={{ borderColor: 'orange' }}
                            onClick={() => handleDemoSkip({ result: 'REVIEW' })}
                        >
                            <FormattedMessage id="button.demoSkipReview" defaultMessage="Demo Skip Review" />
                        </Button>
                    )}
                </ButtonLine>
            )}
            <Button type="link" onClick={toggleIsOneIDBypassVisible}>
                <FormattedMessage
                    id="welcome.IdentityEnhanced.bypass"
                    defaultMessage="Having trouble with this step?"
                />
            </Button>
        </>
    );
};

export default withNavigation(OneID);
