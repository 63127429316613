import styled from 'styled-components';

export const CheckBoxWrapper = styled.div`
    .ant-checkbox {
        margin-top: 4px;
        margin-right: 6px;
        float: left;
    }
    .ant-checkbox + span {
        display: flex;
    }
`;
