import { queryKeys } from '../../utils/reactQuery/queryKeys';
import { useQuery } from '@tanstack/react-query';
import { Country } from 'types';
import { getRequest } from 'utils/http';
import { useUserTeam } from 'hooks/useUserTeam';

export const useCountries = () => {
    const { team } = useUserTeam();
    const { data, isLoading, isError } = useQuery<Country[]>([queryKeys.countries], () =>
        getRequest<Country[]>({
            version: 'v2',
            endpoint: `/country_prices?teamId=${team?.id}`,
        })
    );

    return {
        countries: data,
        countriesLoading: isLoading,
        countriesError: isError,
    };
};
