import { useMemo } from 'react';
import { ContentLink } from 'certn-ui/Text';
import { FormattedMessage } from 'react-intl';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useSelector } from 'react-redux';
import { Path } from 'modules';
import { getLanguage } from 'base/BaseSelectors';

type TermsOfUseURLSProps = Record<DomainProps, LocaleProps>;
type LocaleProps = Record<string, string>;
type DomainProps = 'MYCRC' | 'DEFAULT';

const TERMS_OF_USE_URLS: TermsOfUseURLSProps = {
    MYCRC: {
        en: 'https://mycrc.ca/terms-of-use/',
        fr: 'https://mycrc.ca/fr/terms-of-use/',
        es: 'https://mycrc.ca/es/terms-of-use/',
        default: 'https://mycrc.ca/terms-of-use/',
    },
    DEFAULT: {
        en: 'https://certn.co/terms-of-use/',
        fr: 'https://certn.co/fr/terms-of-use/',
        es: 'https://certn.co/es/terms-of-use/',
        default: 'https://certn.co/terms-of-use/',
    },
};

export const TermsOfUseLink = () => {
    const isMyCRC = Path.isMyCRCSubdomain();
    const language: string = useSelector(getLanguage);
    const { isEnableMyCRCTOSLink } = useFlags();

    const termsOfUseURL = useMemo(() => {
        if (isEnableMyCRCTOSLink && isMyCRC) {
            return TERMS_OF_USE_URLS.MYCRC[language] || TERMS_OF_USE_URLS.MYCRC.default;
        }
        return TERMS_OF_USE_URLS.DEFAULT[language] || TERMS_OF_USE_URLS.DEFAULT.default;
    }, [isMyCRC, language, isEnableMyCRCTOSLink]);

    return (
        <ContentLink href={termsOfUseURL}>
            <FormattedMessage id="welcome.Basics.termsOfUse" defaultMessage="Terms of Use" />
        </ContentLink>
    );
};
