import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Layout } from 'antd';
import AssignedDropdown from './components/AssignedDropdown';
import ApplicantAdjudicationChecks from './components/CheckDropdown';
import AdjudicationButton from './components/AdjudicationButton';
import { AdjudicationLayout, InfoBar } from './styled';
import PropTypes from 'prop-types';
import {
    getAdjudicationReviewStatuses,
    getApplicantAdjudication,
    getApplicantAdjudicationChecks,
} from './AdjudicationRequests';

import { ErrorAlertAPI } from 'certn-ui/ErrorAlert';
import OverallResultDropdown from './components/OverallResultDropdown';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Constants from 'utils/constants';

const AdjudicationSidebar = ({ applicantId, usersTeam }) => {
    const [applicantAdjudication, setApplicantAdjudication] = useState();
    const [applicantChecks, setApplicantChecks] = useState([]);
    const [statusOptions, setStatusOptions] = useState([]);
    const [scrollTop, setScrollTop] = useState(0);

    const overallResultSet = !['NONE', 'REVIEW'].includes(applicantAdjudication?.overall_result);
    const { webFeatureAdjudicationAllowPartialAdjudication } = useFlags();
    const sidebartopposition = scrollTop >= 100 ? '70px' : '160px';
    const adjudicatorAssigned = !!applicantAdjudication?.adjudicator;
    const adjudicationComplete = !Constants.adjudicationLifeCycleStatus.active.includes(
        applicantAdjudication?.life_cycle_status
    );

    useEffect(() => {
        getApplicantAdjudication(applicantId)
            .then((response) => {
                setApplicantAdjudication(response);
            })
            .catch((error) => {
                ErrorAlertAPI(error);
                throw error;
            });

        getApplicantAdjudicationChecks(applicantId)
            .then((response) => {
                setApplicantChecks(response);
            })
            .catch((error) => {
                ErrorAlertAPI(error);
                throw error;
            });

        getAdjudicationReviewStatuses()
            .then((response) => setStatusOptions(response))
            .catch((error) => {
                ErrorAlertAPI(error);
                throw error;
            });
    }, [applicantId, setApplicantAdjudication]);

    useEffect(() => {
        const onScroll = (event) => {
            setScrollTop(event.target.documentElement.scrollTop);
        };
        window.addEventListener('scroll', onScroll);

        return () => window.removeEventListener('scroll', onScroll);
    }, [scrollTop]);

    const allChecksScored = () => {
        let allScored = true;

        Object.values(applicantChecks).map((check) => {
            if (check.score_result) {
                if (['none', 'review'].includes(check.score_result.toLowerCase())) {
                    allScored = false;
                }
            }
        });
        return allScored;
    };

    const canSubmitAdjudication =
        (allChecksScored() ||
            (usersTeam.superteam.enable_partial_adjudication && webFeatureAdjudicationAllowPartialAdjudication)) &&
        adjudicatorAssigned &&
        overallResultSet &&
        !adjudicationComplete;
    const fieldsDisabled = adjudicationComplete || !adjudicatorAssigned;

    return (
        <AdjudicationLayout data-testid="adjudication_sidebar" hasSider sidebartopposition={sidebartopposition}>
            <Layout.Sider theme="light" width="235px">
                <InfoBar>
                    <FormattedMessage id="6cb79.ApplicationsView.adjudication" defaultMessage="Adjudication" />
                </InfoBar>
                <AssignedDropdown
                    setApplicantAdjudication={setApplicantAdjudication}
                    applicantId={applicantId}
                    adjudicator={applicantAdjudication?.adjudicator}
                    applicantAdjudication={applicantAdjudication}
                />
                <br />
                <ApplicantAdjudicationChecks
                    checkDisabled={fieldsDisabled}
                    applicantChecks={applicantChecks}
                    setApplicantChecks={setApplicantChecks}
                    statuses={statusOptions}
                />
                <OverallResultDropdown
                    applicantAdjudication={applicantAdjudication}
                    setApplicantAdjudication={setApplicantAdjudication}
                    selectDisabled={fieldsDisabled}
                    statuses={statusOptions}
                />
                <AdjudicationButton
                    applicantId={applicantId}
                    setApplicantAdjudication={setApplicantAdjudication}
                    applicantChecks={applicantChecks}
                    applicantAdjudication={applicantAdjudication}
                    canSubmitAdjudication={canSubmitAdjudication}
                    allChecksScored={allChecksScored}
                    statusOptions={statusOptions}
                />
            </Layout.Sider>
        </AdjudicationLayout>
    );
};

AdjudicationSidebar.propTypes = {
    applicantId: PropTypes.string.isRequired,
    usersTeam: PropTypes.object.isRequired,
};

AdjudicationSidebar.defaultProps = {};

export default AdjudicationSidebar;
