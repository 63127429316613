import { Button } from 'antd';
import styled from 'styled-components';

export const FilterFrame = styled.div<{ mobile: boolean }>`
    display: flex;
    flex-direction: ${(props) => (props.mobile ? 'column' : 'row')};
    align-items: ${(props) => !props.mobile && 'flex-end'};
    width: 100%;
    margin-bottom: 5px;
    > * {
        margin: 5px;
    }
    > :first-child {
        margin-left: 0;
        flex: 2;
    }
    > :last-child {
        margin-right: 0;
    }
`;

export const FilterItem = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
`;

export const FilterTitle = styled.span`
    font-size: ${(props) => props.theme.font.xxxs};
`;

export const FilterClear = styled(Button)`
    align-self: flex-end;
    margin-left: 5px;
`;
