// Libraries
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

// Components
import { PageOneForm } from './components';

// Modules
import withNavigation from 'views/welcome/modules/WithNavigation';

// Actions & Selectors
import { setTrackPageOrder, patchInformation } from 'views/welcome/WelcomeActions';
import { getInformation } from 'views/welcome/WelcomeSelectors';

const mapStateToProps = (state) => ({
    information: getInformation(state),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            patchInformation,
            setTrackPageOrder,
        },
        dispatch
    );

const propTypes = {
    // Redux Actions:
    patchInformation: PropTypes.func.isRequired,
    setTrackPageOrder: PropTypes.func.isRequired,
    // Redux Store:
    information: PropTypes.object.isRequired,
};
const defaultProps = {};

class CoverLetter extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.setTrackPageOrder(['base']);
    }

    handleSubmit = (e, validateFields) => {
        e.preventDefault();
        const { handleNextTrack } = this.props; /* WithNavigation */

        validateFields((err, values) => {
            if (!err) {
                this.props.patchInformation(values).then(handleNextTrack);
            } else {
                // provide failure error
            }
        });
    };

    pages = () => ({
        base: (
            <PageOneForm handleSubmit={this.handleSubmit} information={this.props.information} intl={this.props.intl} />
        ),
    });

    render() {
        return this.pages()[this.props.trackPageLocation]; /* WithNavigation */
    }
}

CoverLetter.propTypes = propTypes;
CoverLetter.defaultProps = defaultProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(withNavigation(CoverLetter))));
