function subtractYears(numOfYears, date = new Date()) {
    date.setFullYear(date.getFullYear() - numOfYears);

    return date;
}
export const yearsValidationError = (employerReferences, numYears) => {
    if (employerReferences.length === 0) return true;
    const cutOffDate = subtractYears(numYears);
    const errorPresent = employerReferences.map((reference) => {
        const start_date = new Date(reference.start_date);
        const end_date = reference.end_date ? new Date(reference.end_date) : null;
        if (start_date > cutOffDate) return false;
        if (start_date < cutOffDate && !end_date) return false;
        if (end_date && end_date < cutOffDate) return true;
        return false;
    });
    return errorPresent.indexOf(false) === -1;
};
