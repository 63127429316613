// Libraries
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, Row, Col } from 'antd';
import { useSelector } from 'react-redux';

// Components
import { AppearRight } from 'certn-ui/Animate';
import { Box } from 'certn-ui/Layout';
import Text from 'certn-ui/Text';
import Title from 'certn-ui/Title';
import { TrackNavigation } from 'views/welcome/components';
import BirthPlaceSelect from 'components/BirthPlaceSelect';
import FormLayout from 'certn-form/FormLayout';

// Selectors
import { getInformation, getApplicant } from 'views/welcome/WelcomeSelectors';

// Constants
import { CHECK_REQUEST } from 'base/BaseConstants';

const BirthplaceForm = ({ handleSubmit }) => {
    const intl = useIntl();
    const [form] = Form.useForm();
    const information = useSelector(getInformation);

    const applicant = useSelector(getApplicant);

    const generateTitleText = useCallback(() => {
        if (applicant) {
            if (applicant[CHECK_REQUEST.INTERNATIONAL_CRIMINAL_RECORD_CHECK]) {
                return intl.formatMessage({
                    id: 'welcome.Birthplace.BackgroundCheckInternational',
                    defaultMessage: 'A background check requires you to specify your birthplace:',
                });
            }

            if (
                applicant[CHECK_REQUEST.STANDARD_ACIC_CHECK] ||
                applicant[CHECK_REQUEST.VOLUNTEER_ACIC_CHECK] ||
                applicant[CHECK_REQUEST.AUSTRALIAN_NATIONAL_POLICE_CHECK]
            ) {
                return intl.formatMessage({
                    id: 'welcome.Birthplace.BackgroundCheckAustralia',
                    defaultMessage: 'A background check requires you to specify your birthplace:',
                });
            }

            if (applicant[CHECK_REQUEST.UK_BASIC_DBS_CHECK] || applicant[CHECK_REQUEST.UK_BASIC_DS_CHECK]) {
                return intl.formatMessage({
                    id: 'welcome.Birthplace.BackgroundCheckUk',
                    defaultMessage: 'A background check requires you to specify your birthplace:',
                });
            }
        }

        return intl.formatMessage({
            id: 'welcome.Birthplace.BackgroundCheck',
            defaultMessage:
                'A Canadian Criminal Record Check requires you to specify your birthplace in order to search their records:',
        });
    }, [applicant, intl]);

    return (
        <AppearRight>
            <Form form={form} layout="horizontal" onFinish={handleSubmit}>
                <Title>{generateTitleText()}</Title>
                <FormLayout>
                    <BirthPlaceSelect
                        form={form}
                        information={information}
                        isUKCheck={
                            applicant &&
                            (applicant[CHECK_REQUEST.UK_BASIC_DBS_CHECK] || applicant[CHECK_REQUEST.UK_BASIC_DS_CHECK])
                        }
                    />
                    <Row gutter={16}>
                        <Col sm={24} lg={{ span: 20, offset: 2 }}>
                            <Box size="xxs">
                                <Text align="center" color="certnGray600">
                                    <FormattedMessage
                                        id="welcome.Birthplace.helpIdentifyYou"
                                        defaultMessage="These fields help to identify you over other people that may share your basic information."
                                    />
                                </Text>
                            </Box>
                        </Col>
                    </Row>
                    <TrackNavigation />
                </FormLayout>
            </Form>
        </AppearRight>
    );
};

BirthplaceForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    isInternational: PropTypes.bool,
};
BirthplaceForm.defaultProps = {
    isInternational: false,
};

export default BirthplaceForm;
