// Libraries
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from 'styles/styles';

const Trophy = (props) => (
    <svg width={234} height={155} {...props}>
        <title>
            <FormattedMessage id="welcome.Submit.applicationSubmitted" defaultMessage="Application Submitted" />
        </title>
        <g fill="none" fillRule="evenodd">
            <g transform="translate(-21)">
                <circle fill={styles.color.certnGray100} cx={138} cy={92} r={92} />
                <circle fill={styles.color.certnGray200} cx={137.5} cy={92.5} r={57.5} />
            </g>
            <path
                d="M148.593 148.31c0 2.516-1.765 4.569-3.927 4.569H90.334c-2.162 0-3.927-2.053-3.927-4.57v-17.705c0-2.517 1.765-4.57 3.927-4.57h54.332c2.162 0 3.927 2.053 3.927 4.57v17.706z"
                stroke={styles.color.certnGreen700}
                strokeWidth={3}
                fill={styles.color.certnOrange400}
                strokeLinejoin="bevel"
            />
            <path
                d="M104.222 139.002h26.554"
                stroke={styles.color.certnGreen700}
                strokeWidth={3}
                strokeLinecap="round"
                strokeLinejoin="bevel"
            />
            <path
                d="M86.842 49.004H58.21s14.915 48.67 59.288 48.67c44.37 0 59.29-48.67 59.29-48.67h-28.632"
                stroke={styles.color.certnGreen700}
                strokeWidth={3}
                strokeLinejoin="round"
            />
            <path
                d="M109.339 98.541h17c-.08 5.842.275 10.079 1.065 12.71.79 2.631 3.168 7.502 7.135 14.613H99.283c3.985-6.36 6.581-11.181 7.788-14.461 1.207-3.28 1.963-7.568 2.268-12.862z"
                fill={styles.color.certnOrange400}
            />
            <path
                d="M109.585 97.84s1.366 14.241-10.639 28.225M125.415 97.84s-1.364 14.241 10.639 28.225"
                stroke={styles.color.certnGreen700}
                strokeWidth={3}
            />
            <path
                d="M117.078 97.549c-32.573-3.053-32.096-65.428-32.096-65.428h65.038s.48 62.375-32.094 65.428h-.848z"
                stroke={styles.color.certnGreen700}
                strokeWidth={3}
                fill={styles.color.certnOrange400}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M2 153h230"
                stroke={styles.color.certnGreen700}
                strokeWidth={3}
                strokeLinecap="round"
                strokeLinejoin="bevel"
            />
        </g>
    </svg>
);

export default Trophy;
