// Libraries
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchTeamListings, fetchTeam } from 'views/guest/GuestActions';
import { getTeamListings, getIsFetching, getTeam } from 'views/guest/GuestSelectors';
import { getParams } from 'base/BaseSelectors';
import { get } from 'lodash';
import { withRouter } from 'react-router-dom';
import { ErrorAlertAPI } from 'certn-ui/ErrorAlert';
import Loader from 'certn-ui/Loader';
import Titlebar from 'certn-ui/Titlebar';
import { CenterLine } from 'certn-ui/Layout';
import styled from 'styled-components/macro';
import SearchInput from '../components/SearchInput';
import List from '../components/List';
import { FormattedMessage } from 'react-intl';
import { intl } from 'components/GlobalProvider';
import { Button } from 'antd';

const LoaderWrapper = styled.div`
    padding: 20px;
    display: flex;
    justify-content: center;
    width: 100%;
`;

const mapStateToProps = (state) => ({
    listings: getTeamListings(state),
    params: getParams(state),
    isFetching: getIsFetching(state),
    team: getTeam(state),
});
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchTeamListings,
            fetchTeam,
        },
        dispatch
    );

class Postings extends Component {
    constructor(props) {
        super(props);
        this.state = { search: '', numVisiblePostings: 10 };
        const teamId = get(this.props, ['match', 'params', 'teamId']);
        if (teamId) {
            this.props.fetchTeamListings('HR', teamId).catch((e) => ErrorAlertAPI(e));
            this.props.fetchTeam(teamId);
        }
    }

    setSearch = (searchTerm) => {
        this.setState({ search: searchTerm });
    };

    showTenMore = () => {
        this.setState((prevState) => ({ numVisiblePostings: prevState.numVisiblePostings + 10 }));
    };

    render() {
        const { numVisiblePostings } = this.state;
        const allPostings = this.props.listings.sort(
            (a, b) => (a.position_name?.toLowerCase() > b.position_name?.toLowerCase() ? 1 : -1) // alphabetizes based on position name case insensitive.
        );

        const teamName = get(this.props, ['team', 'name']);
        const titleText = teamName ? (
            <FormattedMessage
                id="f29b9.Postings.teamNameWelcome"
                defaultMessage="Start your background check with {teamName}"
                values={{ teamName }}
            />
        ) : (
            <FormattedMessage id="f29b9.Postings.welcome" defaultMessage="Welcome to Certn" />
        );

        const getSearchResult = (search) =>
            allPostings.filter((posting) => posting.position_name.toLowerCase().includes(search.toLowerCase()));

        const currentPostings = this.state.search
            ? getSearchResult(this.state.search).slice(0, numVisiblePostings)
            : allPostings.slice(0, numVisiblePostings);

        const displayPostings = (postings) => {
            if (postings.length === 0)
                return this.state.search ? (
                    <FormattedMessage
                        id="f29b9.Postings.noPostingsMatch"
                        defaultMessage="Nothing matched your search."
                    />
                ) : (
                    <FormattedMessage id="f29b9.Postings.noPostingsMade" defaultMessage="Nothing exists here yet." />
                );

            return postings.map((posting, i) => (
                <List.PackageItem
                    noHighLight
                    key={i}
                    onClick={() => this.props.history.push(`/hr/apply/${posting.url_code}`)}
                >
                    <div>
                        <List.ItemText style={{ fontWeight: 900, fontSize: '20px' }}>
                            {posting.position_name}
                        </List.ItemText>
                        {posting.address && (
                            <List.ItemText style={{ fontSize: '12px' }}>
                                {`${posting.address.city}, ${posting.address.province_state}`}
                            </List.ItemText>
                        )}
                    </div>

                    <Button float-right type="primary">
                        <FormattedMessage
                            id="f29b9.Postings.startBackgroundCheckButton"
                            defaultMessage="Start your background check"
                        />
                    </Button>
                </List.PackageItem>
            ));
        };

        const canDisplayShowMore = () => {
            if (this.state.search) {
                const searchLength = getSearchResult(this.state.search).length;
                if (currentPostings.length === searchLength) {
                    // if there's a search and we're showing all the postings that were included in the search then no need for show  more
                    return false;
                }
                return true; // otherwise, yeah we might want to show mow
            }
            if (currentPostings.length === allPostings.length) {
                // if there's no search and we're showing all the postings then no need for show  more
                return false;
            }
            return true;
        };

        return (
            <>
                <Titlebar
                    size="xlrg"
                    title={titleText}
                    subtitle={intl.formatMessage(
                        {
                            id: 'f29b9.Postings.postingSubtitle',
                            defaultMessage:
                                'We have partnered with Certn, leading provider of background checks. {br}For your next steps, you will need to complete a background check as a part of the screening process, select your location from the list below and follow the simple steps provided.',
                        },
                        {
                            br: (
                                <>
                                    <br />
                                    <br />
                                </>
                            ),
                        }
                    )}
                    center
                />
                <CenterLine />
                {allPostings.length > 3 && (
                    <SearchInput
                        type="text"
                        onChange={this.setSearch}
                        placeholder={intl.formatMessage({
                            id: 'f29b9.Postings.searchInputPlaceholder',
                            defaultMessage: 'Search',
                        })}
                    />
                )}
                <List.Container noBorder spaceBetween>
                    {this.props.isFetching ? (
                        <LoaderWrapper>
                            <Loader />
                        </LoaderWrapper>
                    ) : (
                        displayPostings(currentPostings)
                    )}

                    {canDisplayShowMore() && (
                        <List.Container center>
                            <Button type="primary" onClick={() => this.showTenMore()}>
                                {intl.formatMessage({
                                    id: 'f29b9.Postings.showMore',
                                    defaultMessage: 'Show more',
                                })}
                            </Button>
                        </List.Container>
                    )}
                </List.Container>
            </>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Postings));
