import { QueryOptions } from 'utils/reactQuery';
import { usePostings } from './queryHooks/usePostings';

export const useAllPostings = (options?: QueryOptions) => {
    const { postings, isPostingsLoading, hasPostingsError, refetchPostings } = usePostings(options);

    return {
        allPostings: postings?.results ?? [],
        isAllPostingsLoading: isPostingsLoading,
        hasAllPostingsError: hasPostingsError,
        refetchAllPostings: refetchPostings,
    };
};
