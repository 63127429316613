import { useParams, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import DownloadVerify from 'views/applicant/components/DownloadVerify/DownloadVerify';

// Utils
import { postRequest } from 'utils/http';

type ValidationResponseType = {
    document_url: string;
};

type ParamsType = {
    applicantId: string;
};

const AdverseActionVerify = () => {
    const intl = useIntl();
    const location = useLocation();
    const { applicantId } = useParams() as ParamsType;
    const isPreAdverse = location?.pathname?.includes('/pre-adverse-action');

    const onSubmit = async (accessCode: string) => {
        const response: ValidationResponseType = await postRequest({
            version: 'v2',
            internal: true,
            endpoint: `adverse-action/${applicantId}/${isPreAdverse ? 'pa' : 'aa'}/validate-code`,
            body: JSON.stringify({ access_code: accessCode }),
        });
        if (response?.document_url) {
            window.open(response.document_url, '_blank');
        } else {
            throw new Error();
        }
        return response;
    };

    return (
        <DownloadVerify
            onSubmit={onSubmit}
            title={intl.formatMessage({
                id: 'AdverseActionVerify.title',
                defaultMessage: 'Verify your identity',
            })}
            description={intl.formatMessage({
                id: 'AdverseActionVerify.content',
                defaultMessage:
                    'To securely access your details, enter the 6 character code found in our previous email.',
            })}
            buttonText={intl.formatMessage({
                id: 'AdverseActionVerify.confirmButton',
                defaultMessage: 'Access details',
            })}
        />
    );
};

export default AdverseActionVerify;
