// Libraries
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Checkbox, Form } from 'antd';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components/macro';

// Components
import Text from 'certn-ui/Text';
import { GrayFrame } from 'certnd';
import { Box } from 'certn-ui/Layout';
import TrackNavigation from 'views/welcome/components/TrackNavigation';
import Title from 'certn-ui/Title';
import { PrivacyPolicyLink } from 'certn-ui';

// Welcome Selectors

const CheckboxWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: fit-content;
    margin: auto;
`;

const propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    mainTitle: PropTypes.object.isRequired,
    mainBody: PropTypes.object.isRequired,
    isAPIConsentGiven: PropTypes.bool,
    mainSubTitle: PropTypes.object,
    warningTitle: PropTypes.object,
    warningBody: PropTypes.object,
    privacyAgreeText: PropTypes.object,
};
const defaultProps = {
    isAPIConsentGiven: false,
    warningTitle: (
        <FormattedMessage
            id="welcome.yesNoConsent.warningTitle"
            defaultMessage="Consent to run a criminal record check is required in order to move forward."
        />
    ),
    warningBody: (
        <FormattedMessage
            id="welcome.BackgroundConsent.warningBody"
            defaultMessage="If you do not consent, you may close this window and exit your application. For further assistance please reach out to Certn Support at {email}."
            values={{ email: <a href="mailto:support@certn.co">support@certn.co</a> }}
        />
    ),
    mainSubTitle: undefined,
    privacyAgreeText: (
        <FormattedMessage
            id="welcome.general.privacyAgree"
            defaultMessage="By clicking “Yes, I agree” you consent to Certn collecting and disclosing your personal information pursuant to our {privacyPolicy}."
            values={{
                privacyPolicy: <PrivacyPolicyLink />,
            }}
        />
    ),
};

const YesNoConsent = ({
    handleSubmit,
    isAPIConsentGiven,
    warningTitle,
    warningBody,
    mainTitle,
    mainSubTitle,
    mainBody,
    privacyAgreeText,
}) => {
    const [isConsentGiven, setConsentGiven] = useState(isAPIConsentGiven);
    const [isConsentRejected, setConsentRejected] = useState(undefined); // we never save false
    const [isWarningVisible, setIsWarningVisible] = useState(false);
    const isNextDisabled = !(isConsentGiven || isConsentRejected);

    const handleToggleCheckbox = (value, consent) => {
        resetCheckboxes();
        if (consent) setConsentGiven(value);
        else setConsentRejected(value);
    };

    const resetCheckboxes = () => {
        setConsentGiven(false);
        setConsentRejected(false);
    };

    const handleInternalSubmit = () => {
        if (isConsentGiven) {
            handleSubmit();
        } else setIsWarningVisible(true);
    };

    return (
        <>
            <Modal
                closable={false}
                width="600px"
                title={<FormattedMessage id="common.warning" defaultMessage="Warning" />}
                visible={isWarningVisible}
                footer={[
                    <Button
                        style={{ textTransform: 'uppercase' }}
                        key="submit"
                        type="primary"
                        htmlType="submit"
                        onClick={() => setIsWarningVisible(false)}
                    >
                        <FormattedMessage id="common.ok" defaultMessage="OK" />
                    </Button>,
                ]}
            >
                <div style={{ textAlign: 'center' }}>
                    {warningTitle}
                    <br />
                    <br />
                    {warningBody}
                </div>
            </Modal>
            <Form>
                <Title>{mainTitle}</Title>
                <Box size="sm">
                    {mainSubTitle}
                    <GrayFrame
                        style={{
                            textAlign: 'left',
                            margin: '20px 0px',
                            height: 'auto',
                            maxHeight: '180px',
                            overflowY: 'auto',
                        }}
                        data-testid="grayframe"
                    >
                        {mainBody}
                    </GrayFrame>
                    <CheckboxWrapper>
                        <Checkbox
                            checked={isConsentGiven}
                            disabled={isAPIConsentGiven}
                            onChange={(e) => handleToggleCheckbox(e.target.checked, true)}
                        >
                            <FormattedMessage id="welcome.general.agree" defaultMessage="Yes, I agree" />
                        </Checkbox>
                        <br />
                        <Checkbox
                            checked={isConsentRejected}
                            disabled={isAPIConsentGiven}
                            onChange={(e) => handleToggleCheckbox(e.target.checked)}
                        >
                            <FormattedMessage id="welcome.general.disagree" defaultMessage="No, I do not agree" />
                        </Checkbox>
                    </CheckboxWrapper>
                    <TrackNavigation disabled={isNextDisabled} handleSubmit={handleInternalSubmit} />
                </Box>
                <Box size="xs">
                    <Text align="center" size="xxxs" color="certnGray500">
                        {privacyAgreeText}
                    </Text>
                </Box>
            </Form>
        </>
    );
};

YesNoConsent.propTypes = propTypes;
YesNoConsent.defaultProps = defaultProps;

export default YesNoConsent;
