import styled from 'styled-components';
import { Typography } from 'antd';

const { Text } = Typography;

export const SectionWrapper = styled.div`
    margin: 16px;
    background: #fff;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
`;
export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 16px;
`;

export const FileWrapper = styled.div<{ hasDelete?: boolean }>`
    display: flex;
    justify-content: ${({ hasDelete }) => (hasDelete ? 'space-between' : 'flex-start')};
    align-items: center;
    width: 100%;
    border-bottom: 1px solid #e3e3e3;
    padding-bottom: 12px;
    margin-bottom: 12px;
    line-height: 125%;
`;

export const ContentTitle = styled(Text)`
    font-weight: 600;
    font-size: 16px;
`;

export const ContentText = styled(Text)`
    font-size: 14px;
    line-height: 125%;
    opacity: 0.45;
`;

export const DeleteText = styled.a`
    margin-left: 16px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 12px;
    opacity: 0.45;
    text-decoration: underline;
`;

export const FileName = styled.div`
    word-break: break-all;
`;
