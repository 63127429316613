import React from 'react';
import styled from 'styled-components/macro';
import SkeletonLoader from 'certn-ui/SkeletonLoader';

const NotificationSkeleton = ({ marginLeft }) => (
    <NotificationContainerLarge style={{ marginLeft: marginLeft ? '20px' : '0px' }}>
        <SkeletonLoader width="65px" height="65px" radius="50%" style={{ marginLeft: '7px', marginRight: '20px' }} />
        <SkeletonContainer>
            <SkeletonLoader width="80px" height="14px" />
            <SkeletonLoader width="300px" />
            <SkeletonLoader height="14px" />
            <SkeletonLoader height="14px" />
        </SkeletonContainer>
    </NotificationContainerLarge>
);

export default NotificationSkeleton;

export const NotificationContainerLarge = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 900px;
    min-height: 130px;
    flex-shrink: 0;
    display: flex;
    padding: 20px;
    box-sizing: border-box;
    border: 1px solid ${(props) => props.theme.color.certnGray400};
    border-radius: 4px;
    margin-top: 10px;
    @media (max-width: ${({ theme }) => theme.width.tablet}) {
        width: 100%;
    }
`;

export const SkeletonContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;
