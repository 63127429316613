import Typography from 'certnd/Typography';
import Form from 'certnd/Form';
import { FormattedMessage } from 'react-intl';
import { FormInstance } from 'antd/lib/form';

const { Heading } = Typography;

interface IntlCrimRecordCheckSettingsProps {
    form: FormInstance;
    settings: {
        required_address_history_years: number;
    };
}
const requiredAddressHistoryYearsOptions = [
    { title: '5 - Default', key: 0, value: 5 },
    { title: '7', key: 1, value: 7 },
    { title: '10', key: 2, value: 10 },
];

const InternationalCriminalRecordCheckSettings = ({ form, settings }: IntlCrimRecordCheckSettingsProps) => (
    <Form.Block>
        <Heading.H3>
            <FormattedMessage
                id="settings.InternationalCriminalRecordCheckSettings.title"
                defaultMessage="International Criminal Record Check"
            />
        </Heading.H3>
        <Form.Select
            form={form}
            title={
                <FormattedMessage
                    id="settings.InternationalCriminalRecordCheckSettings.selectTitle"
                    defaultMessage="Years of Address History Required"
                />
            }
            description={
                <FormattedMessage
                    id="settings.InternationalCriminalRecordCheckSettings.selectDescription"
                    defaultMessage="Set the number of years of address history to be provided by an applicant. This overrides other address history requirements (years) when an International Criminal Record Check is ordered."
                />
            }
            options={{ initialValue: settings.required_address_history_years || 5 }}
            selectOptions={requiredAddressHistoryYearsOptions}
            hideBorder
            fieldName="required_address_history_years"
            headerLeft
        />
        <Form.Button>
            <FormattedMessage id="common.save" defaultMessage="Save" />
        </Form.Button>
    </Form.Block>
);

export default InternationalCriminalRecordCheckSettings;
