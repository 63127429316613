// Libraries
import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Form, Button, Tooltip, Space } from 'antd';
import { DownloadOutlined, InfoCircleOutlined } from '@ant-design/icons';

// Components
import { UploadBase64 } from 'components';
import {
    SectionWrapper,
    SectionTopBar,
    SectionBottomPanel,
    BottomPanelRow,
    WarningAlert,
} from 'views/manager/features/ScreenApplicant/styled';

// Actions & Selectors
import { getSelectedCheckKeys } from 'views/manager/features/ScreenApplicant/ScreenApplicantSelectors';
import { RCMP_REQUESTS } from 'views/manager/features/ScreenApplicant/ScreenApplicantConstants';
import { getLanguage, getTeam } from 'base/BaseSelectors';

// Modules
import isVisibleOrRequired from './isVisibleOrRequired';

// Constants
import { CERTN_IMAGES_S3, CHECK_REQUEST } from 'base/BaseConstants';

const generateToolTip = (isMetroSoquij) => {
    if (isMetroSoquij) {
        return (
            <FormattedMessage
                id="f5ca1.QuickScreen.consentButtonTooltipMetro"
                defaultMessage="Consent must be given by the applicant to conduct a SOQUIJ check. Please upload the signed document when completed. Please also save this document for your own records."
            />
        );
    }
    return (
        <FormattedMessage
            id="f5ca1.QuickScreen.consentButtonTooltip"
            defaultMessage="Consent must be given by the applicant to conduct a Canadian Criminal Record Check. You can download a blank version of our consent form here. Please upload the signed document once it is completed."
        />
    );
};

const isSoquijFlow = (services) => {
    if (services.includes('request_soquij')) {
        if (!services.some((service) => RCMP_REQUESTS.includes(service))) {
            return true;
        }
    }
    return false;
};

const ConsentUpload = ({ form }) => {
    const selectedServices = useSelector(getSelectedCheckKeys);
    const language = useSelector(getLanguage);
    const forceSoquijConsent = useSelector(getTeam).force_soquij_consent && isSoquijFlow(selectedServices);
    const visibleList = forceSoquijConsent ? [CHECK_REQUEST.SOQUIJ] : RCMP_REQUESTS;
    const requiredList = forceSoquijConsent ? [CHECK_REQUEST.SOQUIJ] : RCMP_REQUESTS;

    // Language specific Canadian Criminal Record Check form hosted on s3
    const consentFormURL = `${CERTN_IMAGES_S3}/canadian-criminal-record-check-consent-${language}.pdf`;

    if (!isVisibleOrRequired(visibleList, selectedServices)) return null;
    return (
        <SectionWrapper>
            <SectionTopBar>
                <FormattedMessage id="f5ca1.QuickScreen.consent" defaultMessage="Upload Consent Document" />
                <Tooltip trigger="hover" title={generateToolTip(forceSoquijConsent)}>
                    <InfoCircleOutlined style={{ marginLeft: '10px' }} />
                </Tooltip>
            </SectionTopBar>
            <SectionBottomPanel style={{ paddingBottom: '0px' }}>
                <BottomPanelRow>
                    <WarningAlert>
                        <FormattedMessage
                            id="f5ca1.QuickScreen.consentAlert"
                            defaultMessage="You must obtain written consent from your applicant"
                        />
                    </WarningAlert>
                </BottomPanelRow>
                <BottomPanelRow id="rcmp_consent_form" style={{ marginTop: '10px' }}>
                    <Form.Item
                        name="rcmp_consent_form"
                        initialValue={null}
                        rules={[
                            {
                                required: isVisibleOrRequired(requiredList, selectedServices),
                                message: (
                                    <FormattedMessage
                                        id="f5ca1.QuickScreen.consentButton"
                                        defaultMessage="Upload Signed Consent PDF"
                                    />
                                ),
                            },
                        ]}
                    >
                        <>
                            <UploadBase64 setDocument={(base64) => form.setFieldsValue({ rcmp_consent_form: base64 })}>
                                <Button type="secondary">
                                    <Space>
                                        <DownloadOutlined />
                                        <FormattedMessage
                                            id="f5ca1.QuickScreen.consentButton"
                                            defaultMessage="Upload Signed Consent PDF"
                                        />
                                    </Space>
                                </Button>
                            </UploadBase64>
                            {!form.getFieldValue('consent_document') && !forceSoquijConsent && (
                                <a href={consentFormURL} target="_blank" rel="noreferrer noopener">
                                    <FormattedMessage
                                        id="f5ca1.QuickScreen.consentBlankDownload"
                                        defaultMessage="Download Blank Consent PDF"
                                    />
                                </a>
                            )}
                        </>
                    </Form.Item>
                </BottomPanelRow>
            </SectionBottomPanel>
        </SectionWrapper>
    );
};

export default ConsentUpload;
