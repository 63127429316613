// Libraries
import React from 'react';
import { FormattedMessage } from 'react-intl';

// Components
import Title from 'certn-ui/Title';
import Subtitle from 'views/welcome/components/Subtitle';

const NoCanadianAddress = () => (
    <>
        <Title>
            <FormattedMessage
                id="welcome.Identity.nonCadTitle"
                defaultMessage="Identity verification via credit-related questions is not available unless you have a Canadian address."
            />
        </Title>
        <Subtitle>
            <FormattedMessage
                id="welcome.Identity.nonCadSubTitle"
                defaultMessage="Please return to the Addresses step to add a Canadian address. If you have any questions, please contact support@certn.co"
            />
        </Subtitle>
    </>
);

export default NoCanadianAddress;
