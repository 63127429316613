import { useParams, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import DownloadVerify from 'views/applicant/components/DownloadVerify/DownloadVerify';

// Utils
import { postRequest } from 'utils/http';

type ValidationResponseType = {
    report_url: string;
};

type ParamsType = {
    applicantId: string;
};

const ReportVerify = () => {
    const intl = useIntl();
    const { applicantId } = useParams() as ParamsType;
    const { search } = useLocation();

    let applicantName = '';
    // Extract Base64 encoded applicant name from the query string
    const base64Name = new URLSearchParams(search).get('n');
    if (base64Name) {
        try {
            applicantName = atob(base64Name);
        } catch (ex) {
            console.error('Failed to parse encoded applicant name', ex.message);
        }
    }

    const onSubmit = async (accessCode: string) => {
        const response: ValidationResponseType = await postRequest({
            version: 'v2',
            internal: true,
            endpoint: `applicants/${applicantId}/report`,
            body: JSON.stringify({ report_access_code: accessCode }),
        });
        if (response?.report_url) {
            window.location.href = response.report_url;
        } else {
            throw new Error();
        }
        return response;
    };

    return (
        <DownloadVerify
            onSubmit={onSubmit}
            title={intl.formatMessage({
                id: '52b23.ReportVerify.title',
                defaultMessage: 'Verify your identity',
            })}
            description={
                applicantName
                    ? intl.formatMessage(
                          {
                              id: '52b23.ReportVerify.contentWithName',
                              defaultMessage:
                                  'To access the report of {applicantName}, enter the 6 character code found in our previous email. Once entered, we’ll automatically download their report for you.',
                          },
                          { applicantName }
                      )
                    : intl.formatMessage({
                          id: '52b23.ReportVerify.content',
                          defaultMessage:
                              'To access the report enter the 6 character code found in our previous email. Once entered, we’ll automatically download the report for you.',
                      })
            }
            buttonText={intl.formatMessage({
                id: '52b23.ReportVerify.accessReport',
                defaultMessage: 'Access report',
            })}
        />
    );
};

export default ReportVerify;
