// Libraries
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

// Components
import { YesNoConsent } from 'views/welcome/components';
import { AppearRight } from 'certn-ui/Animate';

// Modules
import withNavigation from 'views/welcome/modules/WithNavigation';

// Actions & Selectors
import { setTrackPageOrder, patchInformation } from 'views/welcome/WelcomeActions';
import { getInformation } from 'views/welcome/WelcomeSelectors';
import { PrivacyPolicyLink } from 'certn-ui';

const BackgroundConsentInternational = ({ handleNextTrack }) => {
    const dispatch = useDispatch();
    const isAPIConsentGiven = useSelector(getInformation)?.international_criminal_consent_given || undefined;

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(setTrackPageOrder(['base']));
    }, [dispatch]);

    const handleSubmit = () => {
        dispatch(patchInformation({ international_criminal_consent_given: true })).then(handleNextTrack);
    };

    return (
        <AppearRight>
            <YesNoConsent
                isAPIConsentGiven={isAPIConsentGiven}
                handleSubmit={handleSubmit}
                mainTitle={
                    <FormattedMessage
                        id="welcome.BackgroundConsentInternational.title"
                        defaultMessage="We need your consent to run an international record check"
                    />
                }
                mainBody={
                    <FormattedMessage
                        id="welcome.BackgroundConsentInternational.backgroundConsentInternational"
                        defaultMessage="I understand and acknowledge that I am agreeing to this consent electronically in connection with the written screening request provided to me on behalf of my current or prospective employer, as identified therein (“Employer”) and by doing so, to the extent permitted by applicable law:
                        {br}
                        (1) I hereby authorize Certn (and its designated representatives, as applicable) to collect and independently verify only the necessary Personal Information which may include, but is not limited to, my name, address, email address, date of birth, my criminal history, my public criminal history and professional credentials (collectively, “Personal Information”) for the purposes of delivering a background screening report to my Employer in relation to my current or prospective employment.
                        {br}
                        (2) I also authorize Certn to (i) contact the relevant government departments, law enforcement agencies, data repositories, public websites, as well as all other accessible private and public sector archives on my behalf to perform a criminal records check on me; and (ii) receive copies of verified results through any reasonable means including in writing, electronic transmission, over the phone or at their postal address, and communicate the results to my prospective employer.
                        {br}
                        (3) I understand my background check may include the processing of sensitive Personal Information and acknowledge and agree that where my personal history requires verifications to be carried out in countries where I have worked and/or resided, my data may be sent and processed in those relevant countries for the delivery of the requested verification/s.
                        {br}
                        (4) I agree that an electronic copy of this consent is equally effective as an original signature for the purposes of validity, enforceability, and admissibility and authorize Certn to export this Background Screening Consent in a PDF document and present it to any of my referees which may request a proof of my consent for the purposes set out above.
                        {br}
                        (5) I acknowledge that I have read and understood Certn’s {privacyPolicy} and hereby confirm that my consent is fully informed and freely given and I am aware that I can withdraw it at any time."
                        values={{
                            br: (
                                <>
                                    <br />
                                    <br />
                                </>
                            ),
                            privacyPolicy: <PrivacyPolicyLink />,
                        }}
                    />
                }
            />
        </AppearRight>
    );
};

export default withNavigation(BackgroundConsentInternational);
