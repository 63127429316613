import React from 'react';
import styled from 'styled-components/macro';
import Text from 'certn-ui/Text';

const Container = styled.div`
    color: #819697;
    text-align: left;
    font-weight: 600;
    font-size: 15px;
    line-height: 27px;
    padding-bottom: 16px;
    width: 105%;
`;

const DBSFieldHeading = ({ children, ...props }) => (
    <Container {...props}>
        <Text align="left">{children}</Text>
    </Container>
);

DBSFieldHeading.Container = Container;
export default DBSFieldHeading;
