// Libraries
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { message, Modal } from 'antd';
import { injectIntl, FormattedMessage } from 'react-intl';
import { omit } from 'lodash';

// Components
import { AustralianNPCDocumentsForm } from './components';

// Modules
import withNavigation from 'views/welcome/modules/WithNavigation';

// Actions & Selectors
import {
    setTrackPageOrder,
    patchInformation,
    fetchSignedS3Link,
    confirmUploadSuccessful,
    deleteDocument,
    fetchAustraliaNpcDocumentTypesAndPoints,
} from 'views/welcome/WelcomeActions';
import {
    getInformation,
    getS3UploadLink,
    getS3UploadFields,
    getPreparedDocument,
    getDefaultFileList,
    getOnboardingType,
    getApplicant,
    getDocuments,
} from 'views/welcome/WelcomeSelectors';

const mapStateToProps = (state) => ({
    information: getInformation(state),
    s3UploadLink: getS3UploadLink(state),
    s3UploadFields: getS3UploadFields(state),
    preparedDocument: getPreparedDocument(state),
    fileList: getDefaultFileList(state),
    onboardingType: getOnboardingType(state),
    applicant: getApplicant(state),
    documents: getDocuments(state),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setTrackPageOrder,
            patchInformation,
            fetchSignedS3Link,
            confirmUploadSuccessful,
            deleteDocument,
            fetchAustraliaNpcDocumentTypesAndPoints,
        },
        dispatch
    );

const propTypes = {
    // Redux Actions:
    patchInformation: PropTypes.func.isRequired,
    setTrackPageOrder: PropTypes.func.isRequired,
    fetchSignedS3Link: PropTypes.func.isRequired,
    confirmUploadSuccessful: PropTypes.func.isRequired,
    deleteDocument: PropTypes.func.isRequired,
    fetchAustraliaNpcDocumentTypesAndPoints: PropTypes.func.isRequired,
    // Redux Store:
    information: PropTypes.object.isRequired,
    s3UploadLink: PropTypes.string,
    s3UploadFields: PropTypes.object,
    preparedDocument: PropTypes.object,
    fileList: PropTypes.array,
    onboardingType: PropTypes.string.isRequired,
    documents: PropTypes.array,
};

const defaultProps = {
    s3UploadLink: undefined,
    s3UploadFields: undefined,
    preparedDocument: undefined,
    fileList: [],
    documents: [],
};

const MINIMUM_REQUIRED_DOCUMENTS = {
    primary: 1,
};

const MINIMUM_IDENTITY_DOCUMENT_POINTS = 100;

class AustralianNPCDocuments extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            settingsErrors: null,
            error: false,
            fileList: [],
            fileProgress: {},
            isUploading: false,
            isIncompleteWarningOpen: false,
            totalPoints: 0,
            isPointsValid: false,
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        const { fileList } = this.props;

        this.props.fetchAustraliaNpcDocumentTypesAndPoints().then((documentTypes) => {
            const documentPoints = [...documentTypes.primary, ...documentTypes.secondary].reduce((acc, item) => {
                acc[item.key] = item.points;
                return acc;
            }, {});

            this.setState({ documentTypes, documentPoints });
        });

        this.props.setTrackPageOrder(['base']);

        this.setState({ fileList, fileProgress: fileList.reduce((acc, file) => ({ ...acc, [file.type]: 100 }), {}) });
    }

    hasRTWFile = () => {
        const { fileList } = this.state;
        return fileList.filter((file) => file.subtype === 'right_to_work').length > 0;
    };

    showCommencement = () => {
        const { information } = this.props;
        const isAUCitizen = information.citizenship && information.citizenship.includes('AU');
        return !this.hasRTWFile() || isAUCitizen;
    };

    confirmUploadSuccessful = async (document, file) => {
        const { intl } = this.props;
        try {
            await this.props.confirmUploadSuccessful(document.id);
        } catch (e) {
            message.error(
                intl.formatMessage(
                    {
                        id: 'welcome.Documents.errorUploadingDocument',
                        defaultMessage: 'Error uploading document: {name}',
                    },
                    { name: document.file_name }
                )
            );
            await this.handleDeleteDocument(file);
            return;
        }
        message.success(
            intl.formatMessage(
                { id: 'welcome.Documents.uploadSuccessful', defaultMessage: '{fileName} uploaded successfully!' },
                { fileName: this.truncateFileName(document.file_name) }
            )
        );
    };

    onUpload = () => {
        const { fileList } = this.state;
        const { preparedDocument } = this.props;

        if (preparedDocument) {
            if (fileList.some((item) => item.name === preparedDocument.file_name)) {
                return;
            }

            const newFile = {
                uid: preparedDocument.id,
                name: preparedDocument.file_name,
                status: 'done',
                url: preparedDocument.url,
                type: preparedDocument.document_type,
                subtype: preparedDocument.document_subtype,
            };

            this.setState({ fileList: [...fileList, newFile] }, () => this.props.patchInformation());
        }
    };

    truncateFileName = (name) => (name.length > 20 ? `${name.substring(0, 20)}...` : name);

    // called periodically as upload progresses
    handleProgress = async ({ event, eventFile, doc }) => {
        const { fileProgress } = this.state;
        const { preparedDocument } = this.props;
        const percent = Math.round(event.percent);
        if (percent === 100) {
            await this.confirmUploadSuccessful(preparedDocument, eventFile);
        }

        const updatedFileProgress = { ...fileProgress, [doc]: percent };
        const isUploading = Object.keys(updatedFileProgress).some((key) => updatedFileProgress[key] !== 100);

        this.setState({ fileProgress: updatedFileProgress, isUploading });
    };

    handleSubmit = () => {
        const { fileList, isIncompleteWarningOpen } = this.state;
        const { handleNextTrack } = this.props;

        debugger;

        const totalPoints = fileList.reduce((acc, file) => acc + this.state.documentPoints[file.type], 0);
        this.setState({ totalPoints });

        const isPointsValid = totalPoints >= MINIMUM_IDENTITY_DOCUMENT_POINTS;

        const minRequiredDocs = !this.hasRTWFile()
            ? MINIMUM_REQUIRED_DOCUMENTS
            : omit(MINIMUM_REQUIRED_DOCUMENTS, 'commencement');

        const isComplete =
            Object.entries(minRequiredDocs).every(
                ([subtype, min]) => fileList.filter((file) => file.subtype === subtype).length >= min
            ) && isPointsValid;

        if (!isComplete && !isIncompleteWarningOpen) {
            this.setState({ isIncompleteWarningOpen: true, isPointsValid });
            return;
        }

        this.props.patchInformation().then(handleNextTrack);
    };

    documentTypeValid = (fileType) => /jpg|jpeg|png|pdf|doc|docx|odt/.test(fileType);

    // For document upload
    s3GetUploadLink = (file, documentType, subtype) => {
        const { fileList } = this.state;
        const { intl } = this.props;

        if (fileList.map((item) => item.name).includes(file.name)) {
            message.error(
                intl.formatMessage({ id: 'welcome.Documents.duplicateName', defaultMessage: 'Duplicate file name' })
            );
            return false;
        }

        if (this.documentTypeValid(file.type)) return this.props.fetchSignedS3Link(file, documentType, subtype);

        message.error(
            intl.formatMessage({
                id: 'welcome.Documents.errorUploadingDocumentType',
                defaultMessage: 'Invalid file type',
            })
        );
        return false;
    };

    handleDeleteDocument = async (file) => {
        const { fileProgress, fileList } = this.state;
        const { intl } = this.props;
        try {
            await this.props.deleteDocument(file);
            await this.props.patchInformation();

            const updatedFileProgress = { ...fileProgress };
            delete updatedFileProgress[file.type];

            const index = fileList.indexOf(file);
            const newFileList = [...fileList];
            newFileList.splice(index, 1);
            this.setState({ fileList: newFileList, fileProgress: { ...updatedFileProgress } }, () =>
                message.warn(
                    intl.formatMessage(
                        {
                            id: 'welcome.Documents.documentRemoved',
                            defaultMessage: '{fileName} removed',
                        },
                        { fileName: this.truncateFileName(file.name) }
                    )
                )
            );
        } catch (err) {
            message.error('Failed to delete');
        }
    };

    renderModal() {
        const { isIncompleteWarningOpen, isPointsValid, totalPoints } = this.state;
        const { intl } = this.props;
        return (
            <Modal
                title={intl.formatMessage({
                    id: 'welcome.AustralianNPCDocuments.documentsIncomplete',
                    defaultMessage: 'Your Identification Documents are incomplete',
                })}
                visible={isIncompleteWarningOpen}
                onOk={this.handleSubmit}
                onCancel={() => this.setState({ isIncompleteWarningOpen: false })}
                okText={intl.formatMessage({
                    id: 'welcome.AustralianNPCDocuments.continue',
                    defaultMessage: 'Continue',
                })}
                cancelText={intl.formatMessage({
                    id: 'welcome.AustralianNPCDocuments.goBack',
                    defaultMessage: 'Go back',
                })}
            >
                <>
                    {isPointsValid ? (
                        <p>
                            <FormattedMessage
                                id="welcome.AustralianNPCDocuments.documentsIncompleteWarning"
                                defaultMessage="You are about to proceed without uploading at least one primary identification document. Meeting the requirements will produce quicker results."
                            />
                        </p>
                    ) : (
                        <p>
                            <FormattedMessage
                                id="welcome.AustralianNPCDocuments.documentsIncompleteWarningPoints"
                                defaultMessage="You are about to proceed with {totalPoints} of the minimum {minPoints} points of identification. Meeting the requirements will produce quicker results."
                                values={{ totalPoints, minPoints: MINIMUM_IDENTITY_DOCUMENT_POINTS }}
                            />
                        </p>
                    )}
                    <p>
                        <FormattedMessage
                            id="welcome.AustralianNPCDocuments.documentsIncompleteSkip"
                            defaultMessage="If you’d like to skip this step click 'Continue' and our team will contact you to help complete your check after submission."
                        />
                    </p>
                </>
            </Modal>
        );
    }

    pages = () => {
        const { fileList, error, fileProgress, isUploading, documentTypes } = this.state;
        const { s3UploadFields, s3UploadLink, onboardingType } = this.props;

        return {
            base: (
                <>
                    {this.renderModal()}
                    <AustralianNPCDocumentsForm
                        handleSubmit={this.handleSubmit}
                        s3GetUploadLink={this.s3GetUploadLink}
                        handleDeleteDocument={this.handleDeleteDocument}
                        onUpload={this.onUpload}
                        handleProgress={this.handleProgress}
                        s3UploadLink={s3UploadLink}
                        s3UploadFields={s3UploadFields}
                        fileList={fileList}
                        fileProgress={fileProgress}
                        isUploading={isUploading}
                        onboardingType={onboardingType}
                        error={error}
                        documentTypes={documentTypes}
                        showCommencement={this.showCommencement()}
                    />
                </>
            ),
        };
    };

    render() {
        return this.pages()[this.props.trackPageLocation]; /* WithNavigation */
    }
}

AustralianNPCDocuments.propTypes = propTypes;
AustralianNPCDocuments.defaultProps = defaultProps;

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(injectIntl(withNavigation(AustralianNPCDocuments)))
);
