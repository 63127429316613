// Libraries
import React from 'react';
import styled from 'styled-components/macro';
import { FormattedMessage, useIntl } from 'react-intl';

// Components
import Form from 'certnd/Form';

// Modules & Utils
import Countries from 'modules/Countries';

const OptionsWrapper = styled.div`
    display: flex;
    flex: auto;
    padding: 0 20px 0 20px;
    @media (max-width: ${(props) => props.theme.width.tablet}) {
        width: 100%;
    }
    @media (min-width: ${(props) => props.theme.width.desktop}) {
        ${({ marginRight }) => marginRight && 'margin-right: 15px'}
        ${({ marginLeft }) => marginLeft && 'margin-left: 15px'}
    }
`;

const CountryOptions = (props) => {
    // No Intl Criminal Checks are available from these countries so filter them from the list.
    let countryList = Countries.all.filter(
        (country) =>
            !['Canada', 'United States', 'Antarctica', 'United Kingdom of Great Britain & Northern Ireland'].includes(
                country.name
            )
    );
    const intl = useIntl();
    const provinces = Countries.getUKProvinces(intl);
    countryList = countryList.concat(provinces).sort((country1, country2) => {
        if (country1.name === country2.name) {
            return 0;
        }
        return country1.name < country2.name ? -1 : 1;
    });

    return (
        <OptionsWrapper>
            <Form.Select
                form={props.form}
                title={
                    <FormattedMessage
                        id="22572.Packages.CountriesTitleNewFuF"
                        defaultMessage="Select countries to screen (Optional)"
                    />
                }
                fieldName="requested_countries"
                mode="multiple"
                style={{ width: '100%' }}
                placeholder={
                    <FormattedMessage id="22572.Packages.CountriesPlaceholderNewFuF" defaultMessage="Please select" />
                }
                selectOptions={countryList.map((country) => ({
                    key: country.alpha2,
                    title: country.name,
                    value: country.alpha2,
                }))}
                hideBorder
                data-testid="select_countries"
            />
        </OptionsWrapper>
    );
};

export default CountryOptions;
