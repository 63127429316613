import styled from 'styled-components';

const GrayFrame = styled.div`
    display: flex;
    justify-content: ${({ center }) => (center ? 'center' : 'space-between')};
    width: 100%;
    padding: 15px;
    min-height: 55px;
    margin: 5px 0px;
    border: 1px solid ${({ theme }) => theme.color.certnGray200};
    background-color: ${({ theme }) => theme.color.certnGray100};
    box-sizing: border-box;
    border-radius: 5px;
`;

export default GrayFrame;
