// Libraries
import React, { useState } from 'react';
import { message, Row } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import FormLayout from 'certn-form/FormLayout';
import { Form as AntdForm } from '@ant-design/compatible';
import Text, { TextLink } from 'certn-ui/Text';
import { deleteVaccinationDocument } from 'views/welcome/WelcomeActions';

// Components
import { AppearRight } from 'certn-ui/Animate';
import Title from 'certn-ui/Title';
import config from 'utils/config';

import { TrackNavigation } from 'views/welcome/components';
import 'antd/dist/antd.css';
import UploadRow from '../../../../components/UploadRow';
import PropTypes from 'prop-types';

const VACCINATION_QR_CODE_HOW_TO =
    'https://www.canada.ca/en/immigration-refugees-citizenship/services/canadian-covid-19-proof-vaccination/get-proof.html';

import { getOnboardingId } from 'views/welcome/WelcomeSelectors';
import { useDispatch, useSelector } from 'react-redux';

const VaccinationDocumentUpload = ({
    handleSubmit,
    isNextDisabled,
    setIsNextDisabled,
    vaccinationDocument,
    setVaccinationDocument,
}) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const onboardingId = useSelector(getOnboardingId);
    const [errorMessage, setErrorMessage] = useState('');

    const uploadVaccinationDocument = (info) => {
        if (info.file.status === 'done') {
            setErrorMessage('');
            message.success(
                intl.formatMessage({
                    id: 'e2d10.UploadFlow.uploadSuccess',
                    defaultMessage: `File uploaded successfully`,
                })
            );
            setVaccinationDocument(info.file.response.vaccination_document);
            setIsNextDisabled(false);
        } else if (info.file.status === 'error') {
            setErrorMessage(info.file.response?.vaccination_document[0]);
            setVaccinationDocument(undefined);
            setIsNextDisabled(true);
        }
    };

    const handleVaccinationDocumentDelete = () => {
        setVaccinationDocument(undefined);
        dispatch(deleteVaccinationDocument());
    };

    return (
        <AppearRight>
            <AntdForm layout="horizontal">
                <Title>
                    <FormattedMessage
                        id="welcome.VaccinationInfo.vaccinationUploadTitle"
                        defaultMessage="Upload your proof of Vaccination"
                    />
                </Title>
                <FormLayout center>
                    <Text size="xs" align="center">
                        <FormattedMessage
                            id="welcome.VaccinationInfo.documentUpload"
                            defaultMessage="Please upload your most recent Canadian-issued proof of vaccination. We accept both provincial and federal proofs, and request that you upload proof containing a QR code if available. "
                        />
                    </Text>
                    <br />
                    <Text size="xs" align="center">
                        <FormattedMessage
                            id="welcome.VaccinationInfo.QRCodeHowTo"
                            defaultMessage="To obtain your Canadian proof of vaccination, see the Canadian government website for instructions "
                        />
                        <TextLink target="_blank" href={VACCINATION_QR_CODE_HOW_TO}>
                            <FormattedMessage id="welcome.VaccinationInfo.QRCodeHowToLink" defaultMessage="HERE." />
                        </TextLink>
                    </Text>
                    <br />
                    <Row gutter={16}>
                        <UploadRow.UploadsWrapper>
                            <UploadRow.ReqUploadBox error={!!errorMessage}>
                                <UploadRow
                                    doc="VACCINATION_DOC"
                                    name={intl.formatMessage({
                                        id: 'welcome.VaccinationInfo.proofOfVaccination',
                                        defaultMessage: 'Proof of Vaccination',
                                    })}
                                    fileName="vaccination_document"
                                    file={{ url: vaccinationDocument }}
                                    s3UploadLink={`${config.url}/hr/v1/onboarding/${onboardingId}/vaccination/document/upload/`}
                                    requestMethod="PATCH"
                                    acceptedFileTypes=".jpg,.jpeg,.png,.pdf"
                                    onUpload={uploadVaccinationDocument}
                                    handleDeleteDocument={handleVaccinationDocumentDelete}
                                    complete
                                    error={!!errorMessage}
                                    fileProgress={{ VACCINATION_DOC: undefined }}
                                    missing={!vaccinationDocument}
                                />
                            </UploadRow.ReqUploadBox>
                        </UploadRow.UploadsWrapper>
                    </Row>

                    <Text size="xs" align="center" color="certnRed500">
                        {errorMessage}
                    </Text>
                    <TrackNavigation disabled={isNextDisabled} handleSubmit={handleSubmit} />
                </FormLayout>
            </AntdForm>
        </AppearRight>
    );
};

const propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    isNextDisabled: PropTypes.bool,
    setIsNextDisabled: PropTypes.func.isRequired,
    vaccinationDocument: PropTypes.string,
    setVaccinationDocument: PropTypes.func.isRequired,
};

const defaultProps = {
    isNextDisabled: true,
    vaccinationDocument: undefined,
};

VaccinationDocumentUpload.propTypes = propTypes;
VaccinationDocumentUpload.defaultProps = defaultProps;

export default VaccinationDocumentUpload;
