// Libraries
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { PrivacyPolicyLink } from 'certn-ui';
import PrivacyNotice from './components/PrivacyNotice';

// Components
import { YesNoConsent } from 'views/welcome/components';
import { AppearRight } from 'certn-ui/Animate';

// Modules
import withNavigation from 'views/welcome/modules/WithNavigation';

// Actions & Selectors
import { setTrackPageOrder, patchInformation } from 'views/welcome/WelcomeActions';
import { getInformation } from 'views/welcome/WelcomeSelectors';

const UKGenericConsent = ({ handleNextTrack }) => {
    const dispatch = useDispatch();
    const isAPIConsentGiven = useSelector(getInformation).uk_generic_consent_given;

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(setTrackPageOrder(['base']));
    }, [dispatch]);

    const handleSubmit = () => {
        dispatch(patchInformation({ uk_generic_consent_given: true })).then(handleNextTrack);
    };

    return (
        <AppearRight>
            <YesNoConsent
                signatureConsent
                isAPIConsentGiven={isAPIConsentGiven}
                handleSubmit={handleSubmit}
                mainTitle={
                    <FormattedMessage
                        id="welcome.UKGenericConsent.title"
                        defaultMessage="We need to obtain your consent before we can perform the requested background check services"
                    />
                }
                mainBody={
                    <FormattedMessage
                        id="welcome.UKGenericConsent.ukGenericConsent"
                        defaultMessage='I understand and acknowledge that I am agreeing to this consent electronically and by doing so, to the extent permitted by applicable law:
                            {br}
                            (1) I hereby authorize Certn and its designated representatives, as applicable, to collect, use, disclose and independently verify my Personal Information which may include, but is not limited to, my name, address, email address, date of birth, my criminal history, and my right to work (collectively, "Personal Information") for the purposes of delivering a background screening report in relation to my application for employment with the Company.
                            {br}
                            (2) I acknowledge and agree that the Company may collect, use and disclose the Personal Information for the purposes of evaluating my suitability for employment with the Company;
                            {br}
                            (3) I also authorize Certn to (i) contact the relevant law enforcement agencies, data repositories, as well as all other accessible private and public sector archives (including any other person, organization, or agency) and (ii) receive copies of verified results on me through any reasonable means including in writing, electronic transmission or over the phone;
                            {br}
                            (4) I understand and agree that, where my personal history requires background verifications to be carried out in countries where I have worked, studied, or resided, my Personal Information may be sent and verified in those relevant countries.
                            {br}
                            (5) I understand that I have the right to be informed about the nature and scope of data processing, file a complaint, ask for correction, deletion, or a copy of the information Certn holds about me by contacting Certn’s Privacy Officer at +44 (0)1732 748 900 and dpo@credence.co.uk for UK-based applicants or +1-844-987-0690 and privacy@certn.co for any generic queries and non-UK application queries.
                            {br}
                            (6) I acknowledge that I have read and understood Certn’s Privacy Policy and hereby confirm that my electronic consent is fully informed and freely given and I am aware that I can withdraw it at any time.'
                        values={{
                            br: (
                                <>
                                    <br />
                                    <br />
                                </>
                            ),
                            privacyPolicy: <PrivacyPolicyLink />,
                            privacyEmail: (
                                <a style={{ display: 'contents' }} href="mailto:privacy@certn.co">
                                    privacy@certn.co
                                </a>
                            ),
                        }}
                    />
                }
                mainSubTitle={<PrivacyNotice />}
            />
        </AppearRight>
    );
};

export default withNavigation(UKGenericConsent);
