import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button, Descriptions, message } from 'antd';
import { Form as AntdForm } from '@ant-design/compatible';
import { intl } from 'components/GlobalProvider';
import { get } from 'lodash';

import Typography from 'certnd/Typography';

import { FormBlock } from './styled';
import ToggleIntegrationButton from './components/ToggleIntegrationButton';
import Auth from 'modules/Auth';
import { ErrorAlertAPI } from 'certn-ui/ErrorAlert';
import { getTeam } from 'views/manager/views/admin/AdminSelectors';
import {
    enableSuccessFactors,
    updateSuccessFactors,
    fetchSuperteamAdmins,
    fetchIntegrationPackages,
} from 'views/manager/views/settings/SettingsActions';
import { getIntegrations, getSuccessFactorsPackages } from 'views/manager/views/settings/SettingsSelectors';
import SuccessFactorsForm from './forms/SuccessFactorsForm';
import Packages from './packages/Packages';

const { Heading } = Typography;

const INTEGRATION_TYPE = 'SUCCESS_FACTORS';
const INTEGRATION_NAME = 'success_factors';
const userInputModes = {
    IS_EDITING: 0,
    IS_VIEWING: 1,
    IS_ENABLING: 2,
};

const SuccessFactors = (props) => {
    const [loading, setLoading] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [userInputMode, setUserInputMode] = useState(userInputModes.IS_VIEWING);

    const dispatch = useDispatch();

    const teamId = useSelector((state) => getTeam(state).id);
    const integrations = useSelector((state) => getIntegrations(state));
    const userHasEditPermission = Auth.isPermissionLevel('admin');
    const successFactorsIsActive = get(integrations, [INTEGRATION_NAME, 'is_active']);
    const successFactorsIntegration = get(integrations, [INTEGRATION_NAME]);
    const superTeamId = useSelector((state) => getTeam(state).superteam);
    const packages = useSelector(getSuccessFactorsPackages);

    const toggleEdit = () => setIsEditing(!isEditing);

    useEffect(() => {
        dispatch(fetchIntegrationPackages({ teamId, integrationType: INTEGRATION_TYPE }));
        dispatch(fetchSuperteamAdmins(superTeamId));
    }, [dispatch, superTeamId, teamId]);

    useEffect(() => {
        if (isEditing && successFactorsIsActive) setUserInputMode(userInputModes.IS_EDITING);
        else if (!isEditing && successFactorsIsActive) setUserInputMode(userInputModes.IS_VIEWING);
        else setUserInputMode(userInputModes.IS_ENABLING);
    }, [isEditing, successFactorsIsActive]);

    const handleSaveChanges = (e) => {
        e.preventDefault();
        props.form.validateFields((err, values) => {
            if (err) return;
            setLoading(true);
            const successFactorsData = {
                superteam: superTeamId,
                owner: values.success_factors_owner,
                success_factors_api_url: values.success_factors_api_url.trim(),
                success_factors_company_id: values.success_factors_company_id.trim(),
                success_factors_username: values.success_factors_username.trim(),
                success_factors_password: values.success_factors_password,
            };
            dispatch(updateSuccessFactors({ teamId, data: successFactorsData }))
                .then(() => {
                    message.success(
                        intl.formatMessage({
                            id: 'a7880.SuccessFactors.UpdateSuccess',
                            defaultMessage: 'Success Factors successfully updated.',
                        })
                    );
                    setLoading(false);
                    setIsEditing(false);
                })
                .catch((error) => {
                    setLoading(false);
                    ErrorAlertAPI(error);
                });
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!successFactorsIsActive) {
            props.form.validateFields((err, values) => {
                if (err) return;
                setLoading(true);
                const successFactorsDataEnable = {
                    superteam: superTeamId,
                    owner: values.success_factors_owner,
                    success_factors_api_url: values.success_factors_api_url.trim(),
                    success_factors_company_id: values.success_factors_company_id.trim(),
                    success_factors_username: values.success_factors_username.trim(),
                    success_factors_password: values.success_factors_password,
                };
                dispatch(enableSuccessFactors({ teamId, data: successFactorsDataEnable }))
                    .then(() => {
                        setLoading(false);
                        message.success(
                            intl.formatMessage({
                                id: 'a7880.SuccessFactors.EnableSuccess',
                                defaultMessage: 'Success Factors successfully enabled.',
                            })
                        );
                    })
                    .catch((error) => {
                        setLoading(false);
                        ErrorAlertAPI(error);
                    });
            });
            return;
        }
        const successFactorsDataDisable = {
            superteam: superTeamId,
            owner: successFactorsIntegration.owner.id,
            is_active: false,
        };
        dispatch(updateSuccessFactors({ teamId, data: successFactorsDataDisable }))
            .then(() => {
                message.success(
                    intl.formatMessage({
                        id: 'a7880.SuccessFactors.DisableSuccess',
                        defaultMessage: 'Success Factors successfully disabled.',
                    })
                );
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                ErrorAlertAPI(error);
            });
    };

    return (
        <AntdForm onSubmit={handleSubmit}>
            <FormBlock>
                <Heading.H3>
                    <FormattedMessage
                        id="a7880.SuccessFactors.IntegrationDetails"
                        defaultMessage="Success Factors Information:"
                    />
                </Heading.H3>
                {userInputMode === userInputModes.IS_ENABLING && <SuccessFactorsForm form={props.form} />}
                {userInputMode === userInputModes.IS_VIEWING && (
                    <>
                        <Descriptions column={1} style={{ paddingLeft: '10px' }}>
                            <Descriptions.Item
                                label={intl.formatMessage({
                                    id: 'a7880.SuccessFactors.ApiUrlLabel',
                                    defaultMessage: 'API URL',
                                })}
                            >
                                {successFactorsIntegration.success_factors_api_url}
                            </Descriptions.Item>
                            <Descriptions.Item
                                label={intl.formatMessage({
                                    id: 'a7880.SuccessFactors.CompanyIdLabel',
                                    defaultMessage: 'Company ID',
                                })}
                            >
                                {successFactorsIntegration.success_factors_company_id}
                            </Descriptions.Item>
                            <Descriptions.Item
                                label={intl.formatMessage({
                                    id: 'a7880.SuccessFactors.UsernameLabel',
                                    defaultMessage: 'Username',
                                })}
                            >
                                {successFactorsIntegration.success_factors_username}
                            </Descriptions.Item>
                            <Descriptions.Item
                                label={intl.formatMessage({
                                    id: 'a7880.SuccessFactors.WebhookUsernameLabel',
                                    defaultMessage: 'Webhook Username',
                                })}
                            >
                                {successFactorsIntegration.webhook_username}
                            </Descriptions.Item>
                            <Descriptions.Item
                                label={intl.formatMessage({
                                    id: 'a7880.SuccessFactors.WebhookPasswordLabel',
                                    defaultMessage: 'Webhook Password',
                                })}
                            >
                                {successFactorsIntegration.webhook_password}
                            </Descriptions.Item>
                        </Descriptions>
                        <Button onClick={toggleEdit}>
                            <FormattedMessage id="a7880.Lever.EditButton" defaultMessage="Edit" />
                        </Button>
                    </>
                )}
                {userInputMode === userInputModes.IS_EDITING && (
                    <>
                        <SuccessFactorsForm form={props.form} successFactorsData={successFactorsIntegration} />
                        <Button type="primary" onClick={handleSaveChanges} style={{ marginRight: '10px' }}>
                            <FormattedMessage id="a7880.Lever.SaveChanges" defaultMessage="Save Changes" />
                        </Button>
                        <Button onClick={toggleEdit}>
                            <FormattedMessage id="common.cancel" defaultMessage="Cancel" />
                        </Button>
                    </>
                )}
            </FormBlock>
            <Packages
                integrationType={INTEGRATION_TYPE}
                integrationName={INTEGRATION_NAME}
                packages={packages}
                active={successFactorsIsActive}
                form={props.form}
                allowQuickscreen
            />
            {userInputMode !== userInputModes.IS_EDITING && (
                <ToggleIntegrationButton
                    canEdit={userHasEditPermission}
                    loading={loading}
                    active={successFactorsIsActive}
                />
            )}
        </AntdForm>
    );
};

SuccessFactors.propTypes = {
    form: PropTypes.object.isRequired,
};

export default AntdForm.create()(SuccessFactors);
