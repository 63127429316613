// Libraries
import React from 'react';
import styled from 'styled-components';
import { intl } from 'components/GlobalProvider';

// Components
import { CellWrapper, CellText } from 'views/manager/components';

const Assignee = styled.span`
    color: ${(props) => (props.faded ? props.theme.color.certnGray400 : 'inherit')};
`;

const getAdjudicatorName = (adjudicator) => `${adjudicator.first_name} ${adjudicator.last_name}`;

const AssignedCell = ({ record }) => {
    const hasAdjudicator = !!record?.applicant_adjudication?.adjudicator;
    let adjudicator = intl.formatMessage({ id: '57dcf.Table.AssignedCell', defaultMessage: 'Unassigned' });

    if (hasAdjudicator) {
        adjudicator = getAdjudicatorName(record.applicant_adjudication.adjudicator);
    }

    return (
        <CellWrapper col>
            <CellText>
                <Assignee data-testid="assigned_cell" faded={!hasAdjudicator}>
                    {adjudicator}
                </Assignee>
            </CellText>
        </CellWrapper>
    );
};

AssignedCell.propTypes = {};

export default AssignedCell;
