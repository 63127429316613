import { CaretUpOutlined, DeleteOutlined, EditOutlined, LockOutlined } from '@ant-design/icons';
import { Popconfirm } from 'antd';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { CheckConfig } from 'types';
import { toggleCheck, toggleCheckIsOpen } from 'views/manager/features/ScreenApplicant/ScreenApplicantActions';

export const CartItemIcon: React.FC<{ check: CheckConfig }> = ({ check }) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const { requestFlag, isInFollowUpFlow, isTeamDefault, checkAdditionalOptions, isPurchased, isOpen } = check;
    const hasAdditionalOptions = !!checkAdditionalOptions?.length;

    const handleConfirm = useCallback(() => {
        dispatch(toggleCheck(requestFlag));
    }, [dispatch, requestFlag]);

    const handleCancel = useCallback(() => {
        dispatch(toggleCheckIsOpen(requestFlag));
    }, [dispatch, requestFlag]);

    if (isPurchased || isInFollowUpFlow || isTeamDefault) {
        return <LockOutlined style={{ cursor: 'not-allowed' }} />;
    }

    if (isOpen) {
        return <CaretUpOutlined onClick={handleConfirm} />;
    }

    if (hasAdditionalOptions) {
        return (
            <Popconfirm
                title={intl.formatMessage({
                    id: 'acce0.ScreenApplicantCart.confirmTitle',
                    defaultMessage: 'This service has additional options:',
                })}
                onConfirm={handleConfirm}
                onCancel={handleCancel}
                okText={intl.formatMessage({
                    id: 'common.delete',
                    defaultMessage: 'Delete',
                })}
                cancelText={intl.formatMessage({
                    id: 'acce0.ScreenApplicantCart.cancelText',
                    defaultMessage: 'Edit',
                })}
            >
                <EditOutlined />
            </Popconfirm>
        );
    }

    return <DeleteOutlined onClick={handleConfirm} />;
};
