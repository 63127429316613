// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { intl } from 'components/GlobalProvider';

// UI Components
import Titlebar from 'certn-ui/Titlebar';
import Button, { ButtonLine } from 'certn-ui/Button';

// Actions & Selectors
import { requestForgotPassword } from 'views/guest/GuestActions';
import { getIsFetchingSilent, getForgotRequestSent } from 'views/guest/GuestSelectors';

// Other Components
import { ForgotPasswordForm } from './components';

const mapStateToProps = (state) => ({
    forgotRequestSent: getForgotRequestSent(state),
    isFetchingSilent: getIsFetchingSilent(state),
});
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            requestForgotPassword,
        },
        dispatch
    );

const propTypes = {
    // Redux Actions
    requestForgotPassword: PropTypes.func.isRequired,
    // Redux State
    isFetchingSilent: PropTypes.bool.isRequired,
    forgotRequestSent: PropTypes.bool.isRequired,
};
const defaultProps = {};

const ForgotPassword = (props) => {
    // Talent Hub specific password reset prompt.
    const isTalentHub = localStorage.getItem('talentHubParams');

    if (isTalentHub) {
        if (!props.forgotRequestSent) {
            return (
                <>
                    <Titlebar
                        size="xlrg"
                        center
                        title={intl.formatMessage({
                            id: '13eb3.ForgotPassword.passwordTitle',
                            defaultMessage: 'Forgot Password',
                        })}
                        subtitle={intl.formatMessage({
                            id: '13eb3.ForgotPassword.passwordSubtitle',
                            defaultMessage:
                                'Enter the email address associated with your account. You will soon receive an email with a link with instructions to reset your password.',
                        })}
                    />
                    <ForgotPasswordForm onSubmit={props.requestForgotPassword} loading={props.isFetchingSilent} />
                </>
            );
        }
        return (
            <>
                <Titlebar
                    size="xlrg"
                    center
                    title={intl.formatMessage({
                        id: '13eb3.ForgotPassword.emailTitle',
                        defaultMessage: 'Email Sent',
                    })}
                    subtitle={intl.formatMessage({
                        id: '13eb3.ForgotPassword.emailSubtitle',
                        defaultMessage: 'A link to reset your password has been sent to your inbox.',
                    })}
                />
                <ButtonLine>
                    <Button
                        data-testid="button"
                        type="primary"
                        size="large"
                        certncolor="primary"
                        onClick={() => props.history.push('/integrate/talenthub')}
                    >
                        <FormattedMessage id="common.back" defaultMessage="Back" />
                    </Button>
                </ButtonLine>
            </>
        );
    }
    // End Talent Hub Specific password reset.

    if (!props.forgotRequestSent) {
        return (
            <>
                <Titlebar
                    size="xlrg"
                    center
                    title={intl.formatMessage({
                        id: '13eb3.ForgotPassword.passwordTitle',
                        defaultMessage: 'Forgot Password',
                    })}
                    subtitle={intl.formatMessage({
                        id: '13eb3.ForgotPassword.passwordSubtitle',
                        defaultMessage:
                            'Enter the email address associated with your account. You will soon receive an email with a link with instructions to reset your password.',
                    })}
                />
                <ForgotPasswordForm onSubmit={props.requestForgotPassword} loading={props.isFetchingSilent} />
            </>
        );
    }
    return (
        <>
            <Titlebar
                size="xlrg"
                center
                title={intl.formatMessage({
                    id: '13eb3.ForgotPassword.emailTitle',
                    defaultMessage: 'Email Sent',
                })}
                subtitle={intl.formatMessage({
                    id: '13eb3.ForgotPassword.emailSubtitle',
                    defaultMessage: 'A link to reset your password has been sent to your inbox.',
                })}
            />
            <ButtonLine>
                <Button
                    data-testid="button"
                    type="primary"
                    size="large"
                    certncolor="primary"
                    onClick={() => props.history.push('/login')}
                >
                    <FormattedMessage id="common.back" defaultMessage="Back" />
                </Button>
            </ButtonLine>
        </>
    );
};

ForgotPassword.propTypes = propTypes;
ForgotPassword.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
