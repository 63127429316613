import { intl } from 'components/GlobalProvider';
import styled from 'styled-components/macro';

const Red = styled.span`
    color: ${(props) => props.theme.color.certnRed500};
`;

const buildRequestObject = (applications, adjudication_status) => {
    if (adjudication_status === undefined) {
        return applications.reduce((acc, applicant) => [...acc, { id: applicant.applicant_id }], []);
    }
    return applications.reduce((acc, applicant) => [...acc, { id: applicant.applicant_id, adjudication_status }], []);
};

const actionSets = (flow, canViewReports, bulkActionType) => [
    {
        type: bulkActionType.APPLICATION,
        actions: flow.move.actions,
        action: flow.move.action,
        buildRequestObject,
        actionLabel: (title) =>
            intl.formatMessage(
                {
                    id: '9492f.BulkActions.moveApplicationLabel',
                    defaultMessage: 'Move Applications to {title}',
                },
                { title }
            ),
        success: (title) =>
            intl.formatMessage(
                {
                    id: '9492f.BulkActions.moveApplicationSuccessfull',
                    defaultMessage: 'Applications successfully moved to {title}',
                },
                { title }
            ),
        error: (title) =>
            intl.formatMessage(
                {
                    id: '9492f.moveApplicationError',
                    defaultMessage: 'Error moving applications to {title}',
                },
                { title }
            ),
        show: true,
    },
    {
        type: bulkActionType.APPLICATION,
        actions: flow.remove.actions,
        action: flow.remove.action,
        buildRequestObject,
        actionLabel: () =>
            intl.formatMessage({
                id: '9492f.BulkActions.removeApplicationLabel',
                defaultMessage: 'Remove Applications from groups',
            }),
        error: () =>
            intl.formatMessage({
                id: '9492f.BulkActions.removeApplicationError',
                defaultMessage: 'Error removing applications from groups',
            }),
        success: () =>
            intl.formatMessage({
                id: '9492f.BulkActions.removeApplicationSuccess',
                defaultMessage: 'Applications successfully removed from groups',
            }),
        show: true,
    },
    {
        type: bulkActionType.APPLICATION,
        actions: flow.delete.actions,
        action: flow.delete.action,
        buildRequestObject,
        actionLabel: () => (
            <Red>
                {intl.formatMessage({
                    id: '9492f.BulkActions.deleteApplicationLabel',
                    defaultMessage: 'Delete Archived applications',
                })}
            </Red>
        ),
        success: () =>
            intl.formatMessage({
                id: '9492f.BulkActions.deleteApplicationSuccess',
                defaultMessage: 'Applications successfully deleted',
            }),
        error: () =>
            intl.formatMessage({
                id: '9492f.BulkActions.deleteApplicationError',
                defaultMessage: 'Error deleting applications',
            }),
        show: flow.appView === 'Archive',
    },
    {
        type: bulkActionType.DIVIDER,
        key: 'divider-1',
        show: canViewReports,
    },
    {
        type: bulkActionType.DOWNLOAD,
        key: 'bulkPDF',
        action: flow.bulkDownloadPDF.action,
        buildRequestObject,
        actionLabel: () =>
            intl.formatMessage({
                id: '9492f.BulkActions.retrievePDFLabel',
                defaultMessage: 'Download PDF Reports (.zip)',
            }),
        success: () =>
            intl.formatMessage({
                id: '9492f.BulkActions.retrievePDFSuccess',
                defaultMessage: 'PDF reports successfully retrieved',
            }),
        error: () =>
            intl.formatMessage({
                id: '9492f.BulkActions.retrievePDFError',
                defaultMessage: 'Error retrieving PDF reports',
            }),
        show: canViewReports,
    },
    {
        type: bulkActionType.DOWNLOAD,
        key: 'bulkCSV',
        action: flow.bulkDownloadCSV.action,
        buildRequestObject,
        actionLabel: () =>
            intl.formatMessage({
                id: '9492f.BulkActions.retrieveCSVLabel',
                defaultMessage: 'Download CSV Reports (.csv)',
            }),
        success: () =>
            intl.formatMessage({
                id: '9492f.BulkActions.retrieveCSVSuccess',
                defaultMessage: 'CSV reports successfully retrieved',
            }),
        error: () =>
            intl.formatMessage({
                id: '9492f.BulkActions.retrieveCSVError',
                defaultMessage: 'Error retrieving CSV reports',
            }),
        show: canViewReports,
    },
    {
        type: bulkActionType.DOWNLOAD,
        key: 'bulkConsentDocuments',
        action: flow.bulkDownloadConsentDocuments.action,
        buildRequestObject,
        actionLabel: () =>
            intl.formatMessage({
                id: '9492f.BulkActions.retrieveConsentDocumentsLabel',
                defaultMessage: 'Download Consent Documents (.zip)',
            }),
        success: () =>
            intl.formatMessage({
                id: '9492f.BulkActions.retrieveConsentDocumentsSuccess',
                defaultMessage: 'Consent Documents successfully retrieved',
            }),
        error: () =>
            intl.formatMessage({
                id: '9492f.BulkActions.retrieveConsentDocumentsError',
                defaultMessage: 'Error retrieving Consent Documents',
            }),
        show: canViewReports,
    },
];

export default actionSets;
