import styled from 'styled-components/macro';

const FlexLayout = styled.div`
    display: flex;
    justify-content: ${({ justify }) => justify || 'inherit'};
    align-items: ${({ align }) => align || 'inherit'};
    width: ${({ width }) => width || '100%'};
    height: ${({ height }) => height || '100%'};
    padding: ${({ padding }) => padding || 'none'};
    margin: ${({ margin }) => margin || 'none'};
    background: ${({ theme, color }) => (color ? theme.color[color] : 'none')};
`;

const Horizontal = styled(FlexLayout)`
    flex-direction: row;
`;

const Vertical = styled(FlexLayout)`
    flex-direction: column;
`;

const Layout = {
    Horizontal,
    Vertical,
};
export default Layout;
