import { Form, Radio } from 'antd';
import { FormProps } from 'antd/lib/form';
import { FormLayout } from 'certn-form';
import Title from 'certn-ui/Title';
import { FormattedMessage } from 'react-intl';
import { TrackNavigation } from 'views/welcome/components';
import { useSelector } from 'react-redux';
import { getInformation } from 'views/welcome/WelcomeSelectors';
import DBSFieldHeading from 'views/welcome/components/DBSFieldHeading';
import Subtitle from 'views/welcome/components/Subtitle';
import { UKSelfDeclarationFormValues } from 'views/welcome/views/ukSelfDeclaration/types';
import { boolToYesNo } from 'views/welcome/yesNoHelpers';

interface Props extends FormProps<UKSelfDeclarationFormValues> {
    handleSubmit: (values: UKSelfDeclarationFormValues) => void;
    handleForward: () => void;
    ukSelfDeclaration: UKSelfDeclarationFormValues;
}

const UKSelfDeclarationForm: React.FC<Props> = (props: Props) => {
    const [form] = Form.useForm<UKSelfDeclarationFormValues>();
    const information = useSelector(getInformation);

    return (
        <Form<UKSelfDeclarationFormValues> layout="horizontal" form={form} {...props} onFinish={props.handleSubmit}>
            <Title>
                <FormattedMessage id="welcome.UKSelfDeclaration.title" defaultMessage="UK Right to Work" />
            </Title>
            <Subtitle>
                <FormattedMessage
                    id="welcome.UKSelfDeclaration.subtitle"
                    defaultMessage="In order to process a UK right to work check, we need to verify your documentation."
                />
            </Subtitle>
            <FormLayout>
                <>
                    <DBSFieldHeading>
                        <FormattedMessage
                            id="welcome.UKSelfDeclaration.heading"
                            defaultMessage="Do you have a UK or Irish passport?"
                        />
                    </DBSFieldHeading>
                    <Form.Item
                        name="has_valid_uk_or_irish_passport_yesno"
                        initialValue={boolToYesNo(information.has_valid_uk_or_irish_passport)}
                        rules={[{ required: true, message: 'Please select an option!' }]}
                    >
                        <Radio.Group>
                            <Radio data-testid="radioYES" value="YES" style={{ marginBottom: '25px' }}>
                                <FormattedMessage
                                    id="welcome.UKSelfDeclaration.Yes"
                                    defaultMessage="I have a valid UK or Irish Passport or Irish Passport Card"
                                />
                            </Radio>
                            <Radio
                                data-testid="radioNO"
                                value="NO"
                                style={{
                                    display: 'flex',
                                    textAlign: 'initial',
                                    maxWidth: '376px',
                                    whiteSpace: 'normal',
                                }}
                            >
                                <FormattedMessage
                                    id="welcome.UKSelfDeclaration.No"
                                    defaultMessage="I have an expired UK or Irish passport, or my passport is from another country, or I don’t have a passport"
                                />
                            </Radio>
                        </Radio.Group>
                    </Form.Item>
                </>
            </FormLayout>
            <TrackNavigation />
        </Form>
    );
};

export default UKSelfDeclarationForm;
