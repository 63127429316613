import { getRequest, postRequest, putRequest, deleteRequest } from 'utils/http';
import { simpleStringifyJSON } from 'modules/Format';

// Libraries & Modules
import Auth from 'modules/Auth';

import {
    NAMESPACE as PARENT_NAMESPACE,
    STATEPATH as PARENT_STATEPATH,
} from 'views/manager/views/pm/PropertyManagerConstants';

// Actions & Selectors
import {
    getIsActive,
    getSearchBy,
    getOrdering,
    getFilterBy,
    getPageSize,
    getReverseOrder,
    getCurrentPage,
} from './PropertiesSelectors';

// Constants
export const NAMESPACE = `${PARENT_NAMESPACE}PROPERTIES/`;
export const STATEPATH = `${PARENT_STATEPATH}.properties`;

// Actions Types
export const GET_PROPERTIES_REQ = `${NAMESPACE}GET_PROPERTIES_REQ`;
export const GET_PROPERTIES_RES = `${NAMESPACE}GET_PROPERTIES_RES`;
export const GET_PROPERTIES_ERR = `${NAMESPACE}GET_PROPERTIES_ERR`;
export const GET_ALL_PROPERTIES_REQ = `${NAMESPACE}GET_ALL_PROPERTIES_REQ`;
export const GET_ALL_PROPERTIES_RES = `${NAMESPACE}GET_ALL_PROPERTIES_RES`;
export const GET_ALL_PROPERTIES_ERR = `${NAMESPACE}GET_ALL_PROPERTIES_ERR`;
export const GET_PROPERTY_REQ = `${NAMESPACE}GET_PROPERTY_REQ`;
export const GET_PROPERTY_RES = `${NAMESPACE}GET_PROPERTY_RES`;
export const GET_PROPERTY_ERR = `${NAMESPACE}GET_PROPERTY_ERR`;
export const POST_PROPERTIES_REQ = `${NAMESPACE}POST_PROPERTIES_REQ`;
export const POST_PROPERTIES_RES = `${NAMESPACE}POST_PROPERTIES_RES`;
export const POST_PROPERTIES_ERR = `${NAMESPACE}POST_PROPERTIES_ERR`;
export const PUT_PROPERTIES_REQ = `${NAMESPACE}PUT_PROPERTIES_REQ`;
export const PUT_PROPERTIES_RES = `${NAMESPACE}PUT_PROPERTIES_RES`;
export const PUT_PROPERTIES_ERR = `${NAMESPACE}PUT_PROPERTIES_ERR`;
export const DEL_PROPERTIES_REQ = `${NAMESPACE}DEL_PROPERTIES_REQ`;
export const DEL_PROPERTIES_RES = `${NAMESPACE}DEL_PROPERTIES_RES`;
export const DEL_PROPERTIES_ERR = `${NAMESPACE}DEL_PROPERTIES_ERR`;

// List Actions Types
export const SET_IS_ACTIVE = `${NAMESPACE}SET_IS_ACTIVE`;
export const SET_SEARCH_BY = `${NAMESPACE}SET_SEARCH_BY`;
export const SET_ORDERING = `${NAMESPACE}SET_ORDERING`;
export const SET_FILTER_BY = `${NAMESPACE}SET_FILTER_BY`;
export const SET_PAGE_SIZE = `${NAMESPACE}SET_PAGE_SIZE`;
export const RESET_LIST = `${NAMESPACE}RESET_LIST`;

// Action Creators
export const fetchProperties = (page = null) => (dispatch, getState) => {
    const state = getState();
    const isActive = getIsActive(state) ? 'True' : 'False';
    const searchBy = getSearchBy(state);
    const ordering = getOrdering(state);
    const reverseOrder = getReverseOrder(state);
    const filterBy = getFilterBy(state);
    const pageSize = getPageSize(state);
    const currentPage = getCurrentPage(state);

    const nextPage = page || currentPage;

    let queryParams = `?is_active=${isActive || false}&page=${nextPage || 1}&page_size=${pageSize || 10}`;
    if (searchBy) queryParams += `&search=${searchBy}`;
    if (ordering) queryParams += `&ordering=${reverseOrder ? `-${ordering}` : ordering}`;
    if (filterBy) queryParams += `&filter=${filterBy}`;

    dispatch({
        type: GET_PROPERTIES_REQ,
    });
    return getRequest({
        internal: false,
        version: 'v2',
        endpoint: `/properties/${queryParams}`,
    }).then(
        (response) => {
            dispatch({
                type: GET_PROPERTIES_RES,
                payload: {
                    properties: response.results,
                    numProperties: response.count,
                    previousPage: response.previous,
                    nextPage: response.next,
                    currentPage: nextPage,
                },
            });
        },
        (error) => {
            dispatch({
                type: GET_PROPERTIES_ERR,
                payload: { error },
            });
        }
    );
};

export const deleteProperty = (propertyId) => (dispatch) => {
    dispatch({
        type: DEL_PROPERTIES_REQ,
    });
    return deleteRequest({
        internal: false,
        version: 'v2',
        endpoint: `/properties/${propertyId}/`,
    }).then(
        () => {
            dispatch({
                type: DEL_PROPERTIES_RES,
            });
            return dispatch(fetchProperties(null));
        },
        (error) => {
            dispatch({
                type: DEL_PROPERTIES_ERR,
                payload: { error },
            });
        }
    );
};

export const fetchAllProperties = () => (dispatch) => {
    const queryParams = '?is_active=true&page=1&page_size=9001&ordering=building,address';
    dispatch({
        type: GET_ALL_PROPERTIES_REQ,
    });
    return getRequest({
        internal: false,
        version: 'v2',
        endpoint: `/properties/${queryParams}`,
    }).then(
        (response) => {
            dispatch({
                type: GET_ALL_PROPERTIES_RES,
                payload: {
                    allProperties: response.results,
                    numAllProperties: response.count,
                },
            });
        },
        (error) => {
            dispatch({
                type: GET_ALL_PROPERTIES_ERR,
                payload: { error },
            });
        }
    );
};

export const fetchProperty = (propertyId) => (dispatch) => {
    dispatch({
        type: GET_PROPERTY_REQ,
    });
    return getRequest({
        internal: false,
        version: 'v2',
        endpoint: `/properties/${propertyId}/`,
    }).then(
        (response) => {
            dispatch({
                type: GET_PROPERTY_RES,
                payload: {
                    property: response,
                },
            });
        },
        (error) => {
            dispatch({
                type: GET_PROPERTY_ERR,
                payload: error,
            });
        }
    );
};

export const createProperty = (formData) => (dispatch) => {
    formData.owner_id = Auth.getUserID();
    // formData.country = 'CA'; // TODO: ACTUALLY submit country codes instead of string
    dispatch({
        type: POST_PROPERTIES_REQ,
    });
    return postRequest({
        internal: false,
        version: 'v2',
        endpoint: '/properties/',
        body: JSON.stringify(simpleStringifyJSON(formData)),
    }).then(
        (response) => {
            dispatch({
                type: POST_PROPERTIES_RES,
                payload: {
                    newProperty: response,
                },
            });
            return response;
        },
        (error) => {
            dispatch({
                type: POST_PROPERTIES_ERR,
                payload: error,
            });
            throw error;
        }
    );
};

export const editProperty = (propertyId, formData) => (dispatch) => {
    dispatch({
        type: PUT_PROPERTIES_REQ,
    });
    return putRequest({
        internal: false,
        version: 'v2',
        endpoint: `/properties/${propertyId}/`,
        body: JSON.stringify(simpleStringifyJSON(formData)),
    }).then(
        (response) => {
            dispatch({
                type: PUT_PROPERTIES_RES,
                payload: {
                    property: response,
                },
            });
        },
        (error) => {
            dispatch({
                type: PUT_PROPERTIES_ERR,
                payload: error,
            });
        }
    );
};

// List Action Creators
export const setIsActive = (isActive) => (dispatch, getState) => {
    const currentIsActive = getIsActive(getState());
    dispatch({
        type: SET_IS_ACTIVE,
        payload: {
            isActive,
        },
    });
    if (currentIsActive === isActive) return dispatch(fetchProperties());
    return dispatch(fetchProperties(1));
};

export const setSearchBy = (searchBy) => (dispatch) => {
    dispatch({
        type: SET_SEARCH_BY,
        payload: {
            searchBy,
        },
    });
    return dispatch(fetchProperties(1));
};

export const setOrdering = (ordering) => (dispatch, getState) => {
    const currentOrdering = getOrdering(getState());
    const reverseOrder = currentOrdering === ordering ? !getReverseOrder(getState()) : false;
    dispatch({
        type: SET_ORDERING,
        payload: {
            ordering,
            reverseOrder,
        },
    });
    return dispatch(fetchProperties(1));
};

export const setFilterBy = (filterBy) => (dispatch) => {
    dispatch({
        type: SET_FILTER_BY,
        payload: {
            filterBy,
        },
    });
    return dispatch(fetchProperties(1));
};

export const setPageSize = (pageSize) => (dispatch) => {
    dispatch({
        type: SET_PAGE_SIZE,
        payload: {
            pageSize,
        },
    });
};

export const resetListAndFetch = () => (dispatch) => {
    dispatch({
        type: RESET_LIST,
        payload: {},
    });
    return dispatch(fetchProperties(1));
};
