// Libraries
import React from 'react';

// Components
import { CellWrapper, CellText } from 'views/manager/components';

const InfoCell = ({ row }) => (
    <CellWrapper col>
        <CellText>{`${row.first_name} ${row.last_name}`}</CellText>
        <CellText>{row.email}</CellText>
        <CellText>{`${row.permission_level_display} - ${row.is_active ? 'Active' : 'Inactive'}`}</CellText>
    </CellWrapper>
);

export default InfoCell;
