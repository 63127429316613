/* eslint-disable no-shadow */
export enum OneIdVerificationStatus {
    ApprovedVerified = 'APPROVED_VERIFIED',
    DeniedFraud = 'DENIED_FRAUD',
    DeniedUnsupportedIdType = 'DENIED_UNSUPPORTED_ID_TYPE',
    DeniedUnsupportedIdCountry = 'DENIED_UNSUPPORTED_ID_COUNTRY',
    ErrorNotReadableId = 'ERROR_NOT_READABLE_ID',
    ErrorExpiredId = 'ERROR_EXPIRED_ID',
    InformationComparisonFailure = 'INFORMATION_COMPARISON_FAILURE',
    CreateSessionFailure = 'CREATE_SESSION_FAILURE',
    NoIdUploaded = 'NO_ID_UPLOADED',
    Bypass = 'BYPASS',
    Other = 'OTHER',
}

export enum OneIdStatus {
    PENDING = 'P',
    ACCEPTED = 'A',
    REJECTED = 'R',
    CANCELLED = 'C',
    NONE = 'N',
}
