import { useAdjudicators } from 'hooks/queryHooks/useAdjudicators';
import { FormattedMessage } from 'react-intl';
import { StaticResult } from '../AdvancedFilteringLayout';
import { FilterItem, FilterTitle } from '../styled';

type AdjudicatorFilterProps = {
    value?: string;
    onChange: (value: string) => void;
};

export const AdjudicatorFilter: React.FC<AdjudicatorFilterProps> = ({ value, onChange }) => {
    const { adjudicators } = useAdjudicators();

    if (!adjudicators) {
        return null;
    }

    return (
        <FilterItem>
            <FilterTitle>
                <FormattedMessage id="225d8.AdvancedFiltering.assigned" defaultMessage="Assigned" />
            </FilterTitle>
            <StaticResult
                data-testid="advancedFilteringAdjudicator"
                selected={value}
                allowClear
                mode="default"
                handleChange={onChange}
            >
                {adjudicators.map(({ id, name }) => ({ request: id, title: name }))}
            </StaticResult>
        </FilterItem>
    );
};
