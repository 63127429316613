import styled from 'styled-components';
import { Form } from '@ant-design/compatible';

export const FieldToggleContainer = styled.div<{ placeAbove?: boolean }>`
    display: flex;
    align-items: center;
    padding-left: 12px;
    transform: translateY(${(props) => !props.placeAbove && '-25px'});
    height: 40px;
    background-color: ${({ theme }) => theme.color.certnGray100};
    border-color: ${({ theme }) => theme.color.certnGray300};
    border-width: ${(props) => (props.placeAbove ? '1px' : '0 1px 1px')};
    border-style: solid;
`;

/**
 * We need to adjust the error message position because of the
 * checkbox element
 */
export const FormItemMiddleName = styled(Form.Item)`
    [class$='form-explain'] {
        transform: translateY(40px);
    }
`;
