import Auth from 'modules/Auth';
import { Application } from 'types/types';
import Constants from 'utils/constants';
import { CheckExecution, ApplicantAdjudication } from 'types';
import { FormattedMessage } from 'react-intl';

export const areSomeChecksReturned = (checkExecutions: CheckExecution[]): boolean =>
    checkExecutions.some((check) => Constants.checkExecutionStatus.complete_or_adjudicable.includes(check.status));

export const areAllChecksReturned = (checkExecutions: CheckExecution[]): boolean =>
    checkExecutions.every((check) => Constants.checkExecutionStatus.complete_or_adjudicable.includes(check.status));

export const currentUserIsAdjudicator = (): boolean => Auth.isAdjudicatorUser();

export const doesCurrentUserOwnApplication = (application: Partial<Application>): boolean =>
    Auth.getEmail() === application?.application?.owner?.email;

export const doesAdjudicationHaveOverallResult = (applicantAdjudication: ApplicantAdjudication | null): boolean =>
    ![Constants.scoreResult.NONE, Constants.scoreResult.REVIEW, undefined].includes(
        applicantAdjudication?.overall_result
    );

export const getReportIsCurrentlyViewable = (
    partialAdjudicationEnabled: boolean,
    adjudicationEnabled: boolean,
    checkExecutions: CheckExecution[],
    applicantAdjudication: ApplicantAdjudication
): boolean => {
    const allChecksReturned = areAllChecksReturned(checkExecutions);
    const someChecksReturned = areSomeChecksReturned(checkExecutions);
    const adjudicationHasOverallResult = doesAdjudicationHaveOverallResult(applicantAdjudication);

    if (partialAdjudicationEnabled) {
        // PA enabled - if no checks have been returned then the report is not viewable.
        if (!someChecksReturned) {
            return false;
        }
        // PA enabled - if at least one check has returned and the application needs review, and if the user is an adjudicator then the report is viewable.
        if (someChecksReturned && !adjudicationHasOverallResult && currentUserIsAdjudicator()) {
            return true;
        }
        // PA enabled - if at least one check has returned and the application is partially adjudicated then the report is viewable.
        if (someChecksReturned && adjudicationHasOverallResult) {
            return true;
        }
        // PA enabled - if all checks have returned and the application needs review, and if the user is an adjudicator then the report is viewable.
        if (allChecksReturned && !adjudicationHasOverallResult && currentUserIsAdjudicator()) {
            return true;
        }
        // PA enabled - if all checks have returned, application is adjudicated
        // Allow all users to access report
        if (allChecksReturned && adjudicationHasOverallResult) {
            return true;
        }
    }
    if (!partialAdjudicationEnabled && adjudicationEnabled) {
        // PA disabled - if no checks have been returned then the report is not viewable.
        if (!someChecksReturned && currentUserIsAdjudicator()) {
            return true;
        }
        // PA disabled - if at least one check has returned and the application needs review, and if the user is an adjudicator then the report is viewable.
        if (someChecksReturned && !adjudicationHasOverallResult && currentUserIsAdjudicator()) {
            return true;
        }
        // PA disabled - if all checks have returned and the application is fully adjudicated
        if (allChecksReturned && adjudicationHasOverallResult) {
            return true;
        }
    }
    // If adjudication is not enabled and any checks have returned then report is accessible to permitted users else
    // report is not accessible.
    return !adjudicationEnabled && someChecksReturned;
};

// Define enum for adverseActionStatus
export enum AdverseActionStatus { // eslint-disable-line  no-shadow
    NONE = 'NONE',
    PAUSED = 'PAUSED',
    SCHEDULED = 'SCHEDULED',
    COMPLETE = 'COMPLETE',
    IN_DISPUTE = 'IN_DISPUTE',
    CANCELLED = 'CANCELLED',
    UNDELIVERABLE = 'UNDELIVERABLE',
}

// Define messages for each adverseActionStatus value
const messages = {
    [AdverseActionStatus.NONE]: <FormattedMessage id="adverseActionStatusCell.None" defaultMessage="None" />,
    [AdverseActionStatus.PAUSED]: <FormattedMessage id="adverseActionStatusCell.Paused" defaultMessage="Paused" />,
    [AdverseActionStatus.SCHEDULED]: (
        <FormattedMessage id="adverseActionStatusCell.Scheduled" defaultMessage="Scheduled" />
    ),
    [AdverseActionStatus.COMPLETE]: (
        <FormattedMessage id="adverseActionStatusCell.Complete" defaultMessage="Complete" />
    ),
    [AdverseActionStatus.IN_DISPUTE]: (
        <FormattedMessage id="adverseActionStatusCell.InDispute" defaultMessage="In Dispute" />
    ),
    [AdverseActionStatus.CANCELLED]: (
        <FormattedMessage id="adverseActionStatusCell.Cancelled" defaultMessage="Cancelled" />
    ),
    [AdverseActionStatus.UNDELIVERABLE]: (
        <FormattedMessage id="adverseActionStatusCell.Undeliverable" defaultMessage="Undeliverable" />
    ),
};

// Define default message
const defaultMessage = <FormattedMessage id="adverseActionStatusCell.None" defaultMessage="None" />;

// Define function that returns message for given status
export function getAdverseActionStatusMessage(status: AdverseActionStatus): React.ReactNode {
    return messages[status] || defaultMessage;
}
