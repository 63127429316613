import { createNamespacedFetchActionTypes } from 'utils/autoredux';
import { NAMESPACE as PARENT_NAMESPACE, STATEPATH as PARENT_STATEPATH } from 'views/manager/ManagerConstants';

// Constants
import { CHECK_REQUEST } from 'base/BaseConstants';

// Constants
export const NAMESPACE = `${PARENT_NAMESPACE}FEATURES/SCREENAPPLICANT/`;
export const STATEPATH = `${PARENT_STATEPATH}.features.ScreenApplicant`;

export const INDIVIDUALLY = 'INDIVIDUALLY';
export const CANDIDATE = 'candidate';
export const MYSELF = 'myself';
export const NEW = 'new';

export const US_CRIM_REQUESTS = [
    CHECK_REQUEST.US_CRIMINAL_RECORD_CHECK_TIER_1,
    CHECK_REQUEST.US_CRIMINAL_RECORD_CHECK_TIER_2,
    CHECK_REQUEST.US_CRIMINAL_RECORD_CHECK_TIER_3,
];

export const RCMP_REQUESTS = [
    CHECK_REQUEST.CRIMINAL_RECORD_CHECK,
    CHECK_REQUEST.ENHANCED_CRIMINAL_RECORD_CHECK,
    CHECK_REQUEST.VULNERABLE_SECTOR_CRIMINAL_RECORD_CHECK,
];

// Actions Types
const createFetchActionTypes = createNamespacedFetchActionTypes(NAMESPACE);
export const ActionTypes = {
    GET_GENERIC_CHECKS: createFetchActionTypes('GET_GENERIC_CHECKS'),
};

export const CHECK_CONFIGURATION_STEPS = {
    MISSING_DATA_PROVIDER_CREDENTIALS: 'missing_data_provider_credentials',
    CHECK_NOT_ENABLED: 'check_not_enabled',
};

export const TEAM_CONFIGURATION_STEPS = {
    PAYMENT_VERIFICATION: 'payment_verification',
    LIME_CONTRACT_SIGNED: 'lime_contract_signed',
};
