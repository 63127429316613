import styled from 'styled-components';

export const CombinedCheckboxWrapper = styled.div<{ color: string; disabled?: boolean }>`
    & .ps-contract {
        padding-bottom: 0 !important;
        position: relative;
    }

    & input[type='checkbox'] {
        opacity: 0;
        cursor: ${({ disabled }) => (disabled ? `not-allowed` : `pointer`)} !important;
    }

    & .ps-contract-label {
        margin-left: 8px !important;
        color: ${({ disabled }) => (disabled ? `rgba(0,0,0,0.25)` : `rgba(0, 0, 0, 0.85)`)};
        cursor: ${({ disabled }) => (disabled ? `not-allowed` : `pointer`)} !important;
    }

    & .ps-checkbox-container {
        margin-top: 2px !important;
    }

    & .certn-checkbox {
        position: absolute;
        top: 0.19em;
        left: 0;
        display: block;
        width: 16px;
        height: 16px;
        direction: ltr;
        background-color: ${({ disabled }) => (disabled ? ` #f5f5f5` : `#fff`)};
        border: 1px solid #d9d9d9 !important;
        border-radius: 2px;
        border-collapse: separate;
        border-color: ${({ disabled }) => disabled && `rgba(0, 0, 0, 0.25)`};
        cursor: ${({ disabled }) => (disabled ? `not-allowed` : `pointer`)};
        transition: all 0.3s;
        pointer-events: none;
    }

    & .certn-checkbox::after {
        position: absolute;
        display: table;
        border: 2px solid #fff;
        border-top: 0;
        border-left: 0;
        border-color: ${({ disabled }) => disabled && `rgba(0, 0, 0, 0.25)`};
        -webkit-transform: rotate(45deg) scale(1) translate(-50%, -50%);
        transform: rotate(45deg) scale(1) translate(-50%, -50%);
        opacity: 1;
        -webkit-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
        transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
        content: ' ';
        width: 5.71428571px;
        height: 9.14285714px;
        top: 50%;
        left: 22%;
    }

    & .ps-checkbox-container:hover .certn-checkbox {
        border-color: ${({ disabled, color }) => !disabled && `${color}`} !important;
    }

    & div[data-all-agreed='true'] .certn-checkbox {
        background-color: ${({ disabled, color }) => !disabled && `${color}`};
        border-color: ${({ disabled, color }) => !disabled && `${color}`} !important;
    }
`;
