import React from 'react';
import { Spring, config } from 'react-spring/renderprops.cjs';
import { withTheme } from 'styled-components/macro';

// May need later, snappier animation configuration
const fast = { ...config.stiff, restSpeedThreshold: 1, restDisplacementThreshold: 0.01 };

export const GreenExpand = ({ theme, key, active, SVG, onSelect = null }) => (
    <Spring
        key={key}
        from={{
            color: active() ? theme['primary-color'] : 'rgba(23,160,133,0.0)',
            scale: active() ? 0.8 : 0.65,
        }}
        to={{
            color: active() ? theme['primary-color'] : 'rgba(23,160,133,0.0)',
            scale: active() ? 0.8 : 0.65,
        }}
        config={fast}
        toggle={onSelect}
        // eslint-disable-next-line
        children={SVG}
    />
);
// Default
const ToggleAnimate = (props) => <GreenExpand {...props} />;

export default withTheme(ToggleAnimate);
