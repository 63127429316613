import { Card } from 'antd';
import { FormattedMessage } from 'react-intl';

// Components
import styles from 'styles/styles';
import { TextLabel, SubText, SecondaryLink } from 'certn-ui/Text';

// Modules & Utils
import { OnboardingStepsWrapper } from './styled';
import { OnboardingPaymentInfo } from './OnboardingPaymentInfo';

export const OnboardingSteps = () => (
    <Card
        bodyStyle={{ padding: '20px', backgroundColor: styles.color.certnGray100 }}
        style={{ borderTop: `1px solid ${styles.color.certnGray200}`, borderRadius: '0px' }}
    >
        <TextLabel>
            <FormattedMessage id="onBoarding.beforeFirstCheck" defaultMessage="Before you send your first check" />
        </TextLabel>
        <SubText>
            <FormattedMessage
                id="onBoarding.completeSteps"
                defaultMessage="Complete these steps to start running background checks"
            />
        </SubText>
        <OnboardingStepsWrapper>
            <OnboardingPaymentInfo />
        </OnboardingStepsWrapper>
        <SubText>
            <FormattedMessage
                id="onBoarding.stripeDisclaimer"
                defaultMessage="View Stripe's terms and privacy policies {stripeLink}"
                values={{
                    stripeLink: (
                        <SecondaryLink target="_blank" href="https://stripe.com/en-ca/privacy">
                            <FormattedMessage id="common.here" defaultMessage="here" />
                        </SecondaryLink>
                    ),
                }}
            />
        </SubText>
    </Card>
);
