// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { HomeOutlined, LinkOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Modal, message, Input, Tooltip } from 'antd';

// UI Components
import Searchbar from 'certn-ui/Searchbar';
import { Fade } from 'certn-ui/Animate';
import { Box, CenterLine } from 'certn-ui/Layout';
import Loader from 'certn-ui/Loader';
import Titlebar from 'certn-ui/Titlebar';

import Path from 'modules/Path';
import { get } from 'lodash';
import copy from 'copy-to-clipboard';
// Table Components

// External Libraries
import queryString from 'query-string';

// Actions & Selectors
import {
    fetchProperties,
    resetListAndFetch,
    setSearchBy,
    setFilterBy,
    setIsActive,
} from 'views/manager/views/pm/views/properties/PropertiesActions';
import {
    getIsFetching,
    getProperties,
    getFilterBy,
    getSearchBy,
    getIsActive,
    getNumProperties,
    getNumAllProperties,
} from 'views/manager/views/pm/views/properties/PropertiesSelectors';
import { getUserMode, getUser } from 'base/BaseSelectors';
import { Table } from './components';

const mapStateToProps = (state) => ({
    userMode: getUserMode(state),
    isFetching: getIsFetching(state),
    properties: getProperties(state),
    filterBy: getFilterBy(state),
    searchBy: getSearchBy(state),
    isActive: getIsActive(state),
    numProperties: getNumProperties(state),
    numAllProperties: getNumAllProperties(state),
    user: getUser(state),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            resetListAndFetch,
            fetchProperties,
            setSearchBy,
            setFilterBy,
            setIsActive,
        },
        dispatch
    );

const propTypes = {
    // Redux Actions
    fetchProperties: PropTypes.func.isRequired,
    resetListAndFetch: PropTypes.func.isRequired,
    setOrdering: PropTypes.func.isRequired,
    setSearchBy: PropTypes.func.isRequired,
    setFilterBy: PropTypes.func.isRequired,
    setIsActive: PropTypes.func.isRequired,
    // Redux State
    isFetching: PropTypes.bool.isRequired,
    properties: PropTypes.array.isRequired,
    filterBy: PropTypes.object,
    searchBy: PropTypes.string,
    numProperties: PropTypes.number,
    numAllProperties: PropTypes.number,
};

const defaultProps = {
    filterBy: null,
    searchBy: null,
    numProperties: undefined,
    numAllProperties: undefined,
};

class PropertiesList extends React.Component {
    constructor(props) {
        super(props);
        const { location } = this.props;

        // Legacy code, refactoring but no time to rewrite
        if (location && location.search) {
            const parsed = queryString.parse(location.search);
            // In case you're coming from Listings page and filtering List by listing_id
            if (parsed.property_id) {
                props.resetListAndFilter({ property_id: parsed.property_id });
            } else {
                props.resetListAndFetch();
            }
        } else props.resetListAndFetch();

        if (location && location.search) {
            const parsed = queryString.parse(location.search);
            if (parsed.listing_created) message.success('Listing was successfully created!');
        }
    }

    copyToClipboard = (urlCode = null) => {
        let copySuccess = false;
        if (urlCode) {
            const copyText = `${Path.getPath()}/browse/building/${urlCode}`;
            copySuccess = copy(copyText);
        } else {
            const copyText = `${Path.getPath()}/browse/buildings/${get(this.props.user, ['team', 'id'])}`;
            copySuccess = copy(copyText);
        }
        if (copySuccess) {
            message.success('Apply Link was copied to clipboard.');
        } else {
            message.error('Apply Link could not be copied.');
        }
    };

    showConfirm = () => {
        Modal.confirm({
            title: 'Do you want to set this building to Inactive?',
            content:
                "All listings and applicants for those listings associated with this building will also be set to Inactive. If you would like to close a specific listing, first Cancel this message and do so from the building's Listings page. Otherwise select Okay",
            onOk() {
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => {});
            },
            onCancel() {},
        });
    };

    buildData = (properties) => properties.map((property, index) => ({ ...property, key: index }));

    expandedRowRender = (record) => <p>{`record data = ${JSON.stringify(record)}`}</p>;

    render() {
        const data = this.props.properties && this.buildData(this.props.properties);

        return (
            <>
                <Titlebar title="Buildings">
                    <Button type="primary" onClick={() => this.props.history.push('/pm/properties/new')}>
                        ADD A BUILDING
                    </Button>
                </Titlebar>

                {(this.props.isFetching || this.props.isFetchingSilent) && data && data.length === 0 ? (
                    <Loader />
                ) : (
                    <Fade>
                        <>
                            <CenterLine style={{ marginTop: 4, padding: 0 }}>
                                <Input
                                    disabled
                                    addonBefore={
                                        <>
                                            <span>Apply Page </span>
                                            <Tooltip title="This link will allow applicants to select any active Listing to apply for">
                                                <QuestionCircleOutlined />
                                            </Tooltip>
                                        </>
                                    }
                                    value={`${Path.getPath()}/browse/buildings/${get(this.props.user, ['team', 'id'])}`}
                                    style={{ maxWidth: '100%', width: 300, marginRight: 8 }}
                                />
                                <Button icon={<LinkOutlined />} onClick={() => this.copyToClipboard(null)}>
                                    copy
                                </Button>
                            </CenterLine>
                            <Searchbar
                                icon={<HomeOutlined />}
                                value={this.props.searchBy}
                                onSearch={this.props.setSearchBy}
                                onFilter={this.props.setFilterBy}
                                noFilter
                                filterOptions={null}
                            />
                            {!this.props.isFetching && this.props.numProperties === 0 ? (
                                <Box center border>
                                    <Titlebar
                                        size="lrg"
                                        title="Create your first building"
                                        center
                                        subtitle="Create your first building so you can keep all your listings organized in an efficient manner."
                                    >
                                        <Button
                                            size="large"
                                            type="primary"
                                            onClick={() => this.props.history.push('/pm/properties/new')}
                                        >
                                            ADD A BUILDING
                                        </Button>
                                    </Titlebar>
                                </Box>
                            ) : (
                                this.props.numProperties > 0 && <Table data={data} />
                            )}
                        </>
                    </Fade>
                )}
            </>
        );
    }
}

PropertiesList.propTypes = propTypes;
PropertiesList.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(PropertiesList);
