// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// React Router & Routes
import { withRouter } from 'react-router-dom';

// External Library
import queryString from 'query-string';
import { injectIntl } from 'react-intl';

// UI Components
import { CenterLine } from 'certn-ui/Layout';
import { ButtonLine } from 'certn-ui/Button';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import { Input, Button, Tooltip, Switch, Select, Alert } from 'antd';

// Shared Components
import RentRange from 'certn-form/RentRange';
import { Regex } from 'modules';

// Actions & Selectors
import { fetchTeamMembers } from 'views/manager/views/pm/views/listings/ListingsActions';
import { getTeamMembers, getTeamMemberDict } from 'views/manager/views/pm/views/listings/ListingsSelectors';
import {
    getAllProperties,
    getAllPropertyNames,
    getAllPropertyIds,
} from 'views/manager/views/pm/views/properties/PropertiesSelectors';
import { fetchAllProperties } from 'views/manager/views/pm/views/properties/PropertiesActions';
import {
    getPropertyId,
    getShouldPersistScreenApplicantState,
} from 'views/manager/features/ScreenApplicant/ScreenApplicantSelectors';
import DateInput from 'certn-form/DateInput';

const mapStateToProps = (state) => ({
    teamMembers: getTeamMembers(state),
    teamMemberDict: getTeamMemberDict(state),
    allProperties: getAllProperties(state),
    allPropertyNames: getAllPropertyNames(state),
    allPropertyIds: getAllPropertyIds(state),
    screeningPropertyId: getPropertyId(state),
    shouldPersistScreenApplicantState: getShouldPersistScreenApplicantState(state),
});
const mapDispatchToProps = (dispatch) => bindActionCreators({ fetchTeamMembers, fetchAllProperties }, dispatch);

const propTypes = {
    // Redux Actions
    fetchTeamMembers: PropTypes.func.isRequired,
    fetchAllProperties: PropTypes.func.isRequired,
    // Redux Store
    shouldPersistScreenApplicantState: PropTypes.bool.isRequired,
    teamMembers: PropTypes.array,
    teamMemberDict: PropTypes.object,
    allProperties: PropTypes.array,
    allPropertyNames: PropTypes.array,
    allPropertyIds: PropTypes.array,
    screeningPropertyId: PropTypes.string,
    // Parent Props
    onSubmit: PropTypes.func.isRequired,
    listing: PropTypes.object,
    listingsNew: PropTypes.bool,
};
const defaultProps = {
    listing: {},
    teamMembers: [],
    teamMemberDict: {},
    allProperties: [],
    allPropertyNames: [],
    allPropertyIds: [],
    listingsNew: false,
    screeningPropertyId: null,
};

class ListingsForm extends React.Component {
    constructor(props) {
        super(props);
        const { location } = props;
        props.fetchTeamMembers();
        props.fetchAllProperties();
        let parsed;
        if (location?.search) {
            parsed = queryString.parse(location.search);
        }
        this.state = {
            rentRange: !!props.listing.rent_range,
            moveInDateExists: !!props.listing.move_in_date,
            leaseLengthExists: !!props.listing.length_of_lease,
            propertyId: parsed?.propertyId || undefined,
        };
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const newValues = values;

                // Have to replace emails with ids (so that emails can be searched)
                try {
                    newValues.notification_list_ids = newValues.notification_list_ids
                        .map((email) => this.props.teamMemberDict[email])
                        .filter((teamMemberEmail) => teamMemberEmail);
                } catch (tryErr) {
                    newValues.notification_list_ids = [];
                }

                delete newValues.specifyMoveInDate;
                delete newValues.move_in_year;
                delete newValues.move_in_month;
                delete newValues.move_in_day;

                newValues.open_listing = true; // set manually because I removed the toggle
                if (!newValues.move_in_date) {
                    newValues.move_in_date = null;
                }

                this.props.onSubmit(newValues);
            }
        });
    };

    handleValueSwap = (value) => {
        const currentValue = this.state[value];
        this.setState({ [value]: !currentValue });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const { intl } = this.props;

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 14 },
            },
        };

        return (
            <Form onSubmit={this.handleSubmit}>
                {this.props.listingsNew && (
                    <>
                        <Form.Item {...formItemLayout} label="Target building" style={{ marginBottom: 0 }}>
                            {getFieldDecorator('property_id', {
                                initialValue:
                                    (this.props.shouldPersistScreenApplicantState && this.props.screeningPropertyId) ||
                                    this.props.listing.propertyId ||
                                    this.props.listing.property?.id ||
                                    this.state.propertyId ||
                                    null,
                                rules: [
                                    {
                                        required: true,
                                        message: 'Please select an existing (active) building',
                                    },
                                    {
                                        type: 'enum',
                                        enum: this.props.allPropertyIds,
                                        message: 'Please select a building from the list',
                                    },
                                ],
                            })(
                                <Select
                                    showSearch
                                    optionLabelProp="label"
                                    placeholder="select a building"
                                    filterOption={(inputValue, option) =>
                                        option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                    }
                                >
                                    {this.props.allProperties.map((property) => {
                                        const label = property.building
                                            ? `${property.building} - ${property.address}`
                                            : property.address;
                                        return (
                                            <Select.Option key={property.id} text={property.id} label={label}>
                                                {label}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            )}
                        </Form.Item>
                        <CenterLine>
                            <Alert
                                type="info"
                                showIcon
                                message={
                                    <>
                                        <span>Don't see your building in the list? </span>
                                        <Button
                                            size="small"
                                            onClick={() => this.props.history.push('/pm/properties/new')}
                                        >
                                            Add New Building
                                        </Button>
                                    </>
                                }
                            />
                        </CenterLine>
                    </>
                )}
                <Form.Item {...formItemLayout} label="Unit name/number">
                    {getFieldDecorator('unit', {
                        initialValue: this.props.listing.unit || null,
                        rules: [
                            {
                                type: 'string', // FIXME: Add custom suite/unit validation
                                message: 'Input must be regular text',
                            },
                        ],
                    })(<Input type="text" />)}
                </Form.Item>
                <RentRange
                    formItemLayout={formItemLayout}
                    rent_range={this.props.listing.rent_range}
                    rentRange={this.state.rentRange}
                    rent={this.props.listing.rent}
                    getFieldDecorator={getFieldDecorator}
                    handleValueSwap={this.handleValueSwap}
                />
                <Form.Item
                    {...formItemLayout}
                    label={
                        <span>
                            Notification contacts&nbsp;
                            <Tooltip title="These contacts will be sent email updates about the listing.">
                                <QuestionCircleOutlined />
                            </Tooltip>
                        </span>
                    }
                >
                    {getFieldDecorator('notification_list_ids', {
                        initialValue: this.props.listing.notification_list
                            ? this.props.listing.notification_list.map((entry) => entry.email)
                            : undefined,
                        rules: [
                            {
                                type: 'array',
                                message: 'The input is not a valid E-mail!',
                            },
                        ],
                    })(
                        <Select mode="multiple" type="email" placeholder="Select all that apply">
                            {this.props.teamMembers.map((member) => (
                                <Select.Option key={member.id} value={member.email}>
                                    {member.email}
                                </Select.Option>
                            ))}
                        </Select>
                    )}
                </Form.Item>
                <Form.Item
                    {...formItemLayout}
                    label={
                        <span>
                            Specify a custom link?&nbsp;
                            <Tooltip title="This customizes the link that applicants will follow (ie: certn.co/apply/custom-link). It will be generated automatically if you do not specify something.">
                                <QuestionCircleOutlined />
                            </Tooltip>
                        </span>
                    }
                >
                    {getFieldDecorator('url_code', {
                        initialValue: (!this.props.relist && this.props.listing.url_code) || null,
                        rules: [
                            {
                                pattern: Regex.urlCode,
                                message: intl.formatMessage({
                                    id: 'error.validation.urlCode',
                                    defaultMessage: 'Please use only numbers, letters, dashes, and underscores',
                                }),
                            },
                            {
                                min: 6,
                                message: intl.formatMessage({
                                    id: 'error.validation.6char',
                                    defaultMessage: 'URL code must be at least 6 characters long',
                                }),
                            },
                            {
                                required: !this.props.relist && !!this.props.listing.url_code,
                                message: intl.formatMessage({
                                    id: 'error.validation.notBlank',
                                    defaultMessage: 'Please do not leave blank',
                                }),
                            },
                        ],
                    })(<Input type="text" placeholder="(ie: 432-park-avenue)" />)}
                </Form.Item>
                {/* <Form.Item {...formItemLayout} label="Pet Deposit?">
                    {getFieldDecorator('pet_deposit', {
                        initialValue: this.props.listing.pet_deposit || false,
                        valuePropName: 'checked',
                    })(<Switch onClick={() => this.handleValueSwap('petDeposit')} />)}
                </Form.Item>
                {this.state.petDeposit && (
                    <Form.Item {...formItemLayout} label="Deposit Amount ($)">
                        {getFieldDecorator('pet_deposit_amount', {
                            initialValue: this.props.listing.pet_deposit_amount || null,
                            rules: [
                                {
                                    type: 'string', // FIXME: Add custom validation for dollar
                                    message: 'The input is not a valid number (no symbols)!',
                                },
                                {
                                    required: true,
                                    message: 'Please input a valid dollar amount (no symbols)!',
                                },
                            ],
                        })(<Input type="text" />)}
                    </Form.Item>
                )} */}
                <Form.Item
                    {...formItemLayout}
                    label={
                        <span>
                            Specify a move-in date?&nbsp;
                            <Tooltip title="Leave this option disabled if the successful tenant could move in immediately.">
                                <QuestionCircleOutlined />
                            </Tooltip>
                        </span>
                    }
                >
                    {getFieldDecorator('specifyMoveInDate', {
                        initialValue: this.state.moveInDateExists,
                        valuePropName: 'checked',
                    })(<Switch onClick={() => this.handleValueSwap('moveInDateExists')} />)}
                </Form.Item>
                {this.state.moveInDateExists && (
                    <Form.Item
                        {...formItemLayout}
                        label={
                            <span>
                                {intl.formatMessage({
                                    id: 'form.label.moveInDate',
                                    defaultMessage: 'Move-in Date',
                                })}
                                &nbsp;
                            </span>
                        }
                    >
                        {getFieldDecorator('move_in_date', {
                            initialValue: this.props.listing.move_in_date,
                            rules: [
                                {
                                    required: true,
                                    message: intl.formatMessage({
                                        id: 'error.validation.notBlank',
                                        defaultMessage: 'Please do not leave blank',
                                    }),
                                },
                            ],
                        })(
                            <DateInput
                                placeholder={intl.formatMessage({
                                    id: 'form.label.moveInDate',
                                    defaultMessage: 'Move-in Date',
                                })}
                            />
                        )}
                    </Form.Item>
                )}
                <Form.Item
                    {...formItemLayout}
                    label={
                        <span>
                            Specify the length of lease?&nbsp;
                            <Tooltip title="Leave this option disabled if there is no lease.">
                                <QuestionCircleOutlined />
                            </Tooltip>
                        </span>
                    }
                >
                    {getFieldDecorator('specifyLeaseLength', {
                        initialValue: this.state.leaseLengthExists,
                        valuePropName: 'checked',
                    })(<Switch onClick={() => this.handleValueSwap('leaseLengthExists')} />)}
                </Form.Item>
                {this.state.leaseLengthExists && (
                    <Form.Item {...formItemLayout} label={<span>Lease Length&nbsp;</span>}>
                        {getFieldDecorator('length_of_lease', {
                            initialValue: this.props.listing.length_of_lease,
                            rules: [
                                {
                                    pattern: Regex.numbers,
                                    message: intl.formatMessage({
                                        id: 'error.validation.numbers',
                                        defaultMessage: 'Please only use numbers',
                                    }),
                                },
                            ],
                        })(<Input placeholder="Length of Lease (Months)" />)}
                    </Form.Item>
                )}
                <Form.Item>
                    <ButtonLine>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </ButtonLine>
                </Form.Item>
            </Form>
        );
    }
}

ListingsForm.propTypes = propTypes;
ListingsForm.defaultProps = defaultProps;

export default Form.create()(withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(ListingsForm))));
