// Libraries
import PropTypes from 'prop-types';
import { MessageOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { connect, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { FormattedMessage } from 'react-intl';

// Routes & Router
import Routes from 'Routes';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';

// Components
import Text from 'certn-ui/Text';
import Layout from 'certn-ui/Layout';
import RouteErrorBoundary from 'certn-ui/RouteErrorBoundary';
import Navbar, { NavMain, NavLeft, NavRight } from 'certn-ui/Navbar';
import { NavLogo } from 'certn-ui/NavLogo';
import { intl } from 'components/GlobalProvider';
import { LanguageSelect } from 'base/components/';

// Modules & Utils
import Auth from 'modules/Auth';
import { BrowserNotSupported } from 'modules/';
import { Path } from 'modules';

// Actions & Selectors
import { getTeamLogo, getTeamIsUK, getTeamIsAUS } from 'base/BaseSelectors';

// Views
import { SignIn } from './views';
import { Review } from './views/Review';
import { ErrorAlertNoTranslate } from '../../certn-ui/ErrorAlert';

const HideMobile = styled.div`
    @media (max-width: ${(props) => props.theme.width.med}) {
        display: none;
    }
`;

const mapStateToProps = (state) => ({
    logo: getTeamLogo(state),
});

const propTypes = {
    logo: PropTypes.string.isRequired,
};

const handleLogout = () => {
    Auth.deauthenticateApplicant();
    window.location.reload();
};

const Applicant = ({ logo }) => {
    useEffect(() => {
        // Check for supported browser types/versions, warn user to update for best experience
        if (BrowserNotSupported) {
            ErrorAlertNoTranslate({
                title: intl.formatMessage({
                    id: '1d2c3.Manager.errorOutdatedBrowserTitle',
                    defaultMessage: 'Outdated browser detected',
                }),
                description: intl.formatMessage({
                    id: '1d2c3.Manager.errorOutdateBrowserDescription',
                    defaultMessage:
                        'Please use an up to date version of your browser for the best user experience. Internet Explorer is no longer supported.',
                }),
            });
        }
    }, []);
    const altTag = Path.getAltTagFromSubdomain();
    const isTeamUK = useSelector(getTeamIsUK);
    const isTeamAUS = useSelector(getTeamIsAUS);

    return (
        <Layout hideOverflow>
            {window === window.top && (
                <Navbar fixed>
                    <NavMain fixed>
                        <NavLeft>
                            <NavLogo
                                alt={altTag}
                                src={logo}
                                title={intl.formatMessage({
                                    id: 'common.Logo',
                                    defaultMessage: 'Logo',
                                })}
                            />
                        </NavLeft>
                        <NavRight onboarding>
                            <HideMobile>
                                <Text align="right" margin="0 10px 0 0">
                                    <FormattedMessage id="applicant.Applicant.navTitle" defaultMessage="Review Check" />
                                </Text>
                            </HideMobile>
                            {window.fcWidget && (
                                <Button
                                    id="industry-toggle-button"
                                    shape="circle"
                                    size="large"
                                    onClick={() => {
                                        window.fcWidget.open();
                                    }}
                                >
                                    <MessageOutlined style={{ marginTop: '4px', fontSize: '24px' }} />
                                </Button>
                            )}
                            {Auth.isApplicantAuthenticated() && (
                                <a>
                                    <Text align="center" size="xxs" onClick={handleLogout}>
                                        <FormattedMessage id="applicant.Review.logout" defaultMessage="Logout" />
                                    </Text>
                                </a>
                            )}
                            {!(isTeamUK || isTeamAUS) && <LanguageSelect />}
                        </NavRight>
                    </NavMain>
                </Navbar>
            )}
            <RouteErrorBoundary>
                <Switch>
                    <Route path="/applicant/signin" component={SignIn} />
                    <Routes.ApplicantAuthed path="/applicant/review" component={Review} />

                    {/* FALL BACK */}
                    <Redirect to="/applicant/signin" />
                </Switch>
            </RouteErrorBoundary>
        </Layout>
    );
};

Applicant.propTypes = propTypes;

export default withRouter(connect(mapStateToProps)(Applicant));
