export const setInitialProcessingStatus = (
    ukRtwCheck: boolean,
    ukBasicCrimeCheck: boolean,
    enhancedIdentityPending: boolean
) => {
    if (ukRtwCheck && !ukBasicCrimeCheck) {
        return false;
    }
    return enhancedIdentityPending;
};
