import styled from 'styled-components/macro';
import { Card } from 'antd';

export const FlexCardRow = styled.div`
    display: flex;
    justify-content: center;
    > * {
        margin: 4px !important;
    }

    @media screen and (max-width: 850px) {
        flex-direction: column;
        align-items: center;
        > * {
            flex: 1 46% !important;
        }
    }
`;

export const FlexCardList = styled.ul`
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: 10px;
    overflow: hidden;
    > * {
        width: ${(props) => (props.large ? '100%' : '48%')};
        margin: 4px !important;
    }
    @media screen and (max-width: 850px) {
        flex-wrap: wrap;
        > * {
            flex: 1 50% !important;
        }
    }
`;

export const SpacerCardDiv = styled.div`
    height: ${(props) => (props.small ? '100px' : '200px')};
`;

export const EditingCardDiv = styled.div``;

export const CardWithShadow = styled(Card)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 4px 4px 20px 0px hsla(0, 0%, 0%, 0.035);
    width: 100%;
    max-width: 270px;
`;
