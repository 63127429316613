import styled from 'styled-components';

export const CartItemsWrapper = styled.div`
    padding: 20px;
`;

export const CartItemList = styled.div`
    display: flex;
    flex-direction: column;
`;
