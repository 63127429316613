import React from 'react';
import { CertnUIProvider, GlobalStyles as CertnUiGlobalStyles } from '@certn/ui';
import { useFlags } from 'launchdarkly-react-client-sdk';

type CertnUiWrapperProps = {
    children: React.ReactNode | React.ReactNode[];
};

const CertnUiWrapper = ({ children }: CertnUiWrapperProps): JSX.Element => {
    const { webFeatureEnableCertnUiStylesIntegration } = useFlags();

    if (webFeatureEnableCertnUiStylesIntegration) {
        return (
            <div data-testid="certn-ui-wrapper">
                <CertnUIProvider>
                    <>
                        <CertnUiGlobalStyles />
                        {children}
                    </>
                </CertnUIProvider>
            </div>
        );
    }

    return <div data-testid="certn-ui-not-wrapped">{children}</div>;
};

export default CertnUiWrapper;
