// Libraries
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl, FormattedMessage } from 'react-intl';

// Actions & Selectors
import { getSettings, getCurrentTeam } from 'views/manager/views/settings/SettingsSelectors';
import { updateSettings } from 'views/manager/views/settings/SettingsActions';
import { getTeam } from 'views/manager/views/admin/AdminSelectors';

import { Form } from '@ant-design/compatible';

// UI Components
import { message } from 'antd';
import { ErrorAlertAPI, ErrorAlertCustom } from 'certn-ui/ErrorAlert';
import Typography from 'certnd/Typography';
import {
    ApplicationSettings,
    General,
    DocumentUpload,
    AddressHistory,
    EmploymentHistory,
    EmploymentReferences,
    EmploymentVerification,
    PersonalReferences,
    TenancyReferences,
    Rcmp,
    CredentialVerification,
    EducationVerification,
    ApplicationExpirationSettings,
    InternationalCriminalRecordCheckSettings,
} from './components';
import { getLaunchDarklyFlags } from 'base/BaseSelectors';

const { Heading } = Typography;

const mapStateToProps = (state) => ({
    settings: getSettings(state),
    currentTeam: getCurrentTeam(state),
    team: getTeam(state),
    webFeatureEnableNewEmployerReferenceTrack: getLaunchDarklyFlags(state)?.webFeatureEnableNewEmployerReferenceTrack,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            updateSettings,
        },
        dispatch
    );

class Applications extends React.Component {
    handleSubmit = (e) => {
        const { webFeatureEnableNewEmployerReferenceTrack } = this.props;
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            let newErr = err;
            if (
                !webFeatureEnableNewEmployerReferenceTrack &&
                (values.employer_references_min !== values.employment_verification_min ||
                    values.employer_references_max !== values.employment_verification_max ||
                    values.employer_references_years !== values.employment_verification_years)
            ) {
                newErr = true;
                return ErrorAlertCustom({
                    title: this.props.intl.formatMessage({
                        id: 'f2d78.Applications.ErrorTitle',
                        defaultMessage: 'Employer Reference setting error',
                    }),
                    description: this.props.intl.formatMessage({
                        id: 'f2d78.Applications.ErrorDescription',
                        defaultMessage:
                            'Running both Employment Verification and Employer Reference Check requires their additional option values to be the same.',
                    }),
                });
            }
            if (!newErr) {
                this.props
                    .updateSettings(values, this.props.team.id)
                    .then(() =>
                        message.success(
                            this.props.intl.formatMessage({
                                id: 'common.settingsSavedSuccessfully',
                                defaultMessage: 'Settings saved successfully.',
                            })
                        )
                    )
                    .catch((error) => ErrorAlertAPI(error));
            }
        });
    };

    render() {
        const { currentTeam, form } = this.props;
        return (
            <Form onSubmit={this.handleSubmit}>
                <Heading.H1 data-testid="title">
                    <FormattedMessage id="common.applications" defaultMessage="Applications" />
                </Heading.H1>
                <ApplicationSettings settings={currentTeam} form={form} />
                <General settings={currentTeam} form={form} />
                <DocumentUpload settings={currentTeam} form={form} />
                <AddressHistory settings={currentTeam} form={form} />
                <TenancyReferences settings={currentTeam} form={form} />
                <EmploymentHistory settings={currentTeam} form={form} />
                <EmploymentReferences settings={currentTeam} form={form} />
                <EmploymentVerification settings={currentTeam} form={form} />
                <CredentialVerification settings={currentTeam} form={form} />
                <EducationVerification settings={currentTeam} form={form} />
                <PersonalReferences settings={currentTeam} form={form} />
                <Rcmp settings={currentTeam} form={form} />
                <ApplicationExpirationSettings settings={currentTeam} form={form} />
                <InternationalCriminalRecordCheckSettings settings={currentTeam} form={form} />
            </Form>
        );
    }
}

export default Form.create()(connect(mapStateToProps, mapDispatchToProps)(injectIntl(Applications)));
