// Libraries
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';

// Components
import SummaryItem from './SummaryItem';
import SummaryHeader from './SummaryHeader';
import { FlexRow, SummarySection, SummaryContainer } from './SummaryStyles';
import { CHECK_REQUEST } from 'base/BaseConstants';

// Actions & Selectors
import { getApplicant } from 'views/welcome/WelcomeSelectors';

const Gender = ({ information, goToTrack }) => {
    const applicant = useSelector(getApplicant);
    const isUkCheck =
        applicant && (applicant[CHECK_REQUEST.UK_BASIC_DBS_CHECK] || applicant[CHECK_REQUEST.UK_BASIC_DS_CHECK]);
    return (
        <SummaryContainer>
            <SummaryHeader
                title={<FormattedMessage id="welcome.Gender.sex" defaultMessage="Gender" />}
                onClick={goToTrack}
            />
            <SummarySection>
                <FlexRow>
                    {isUkCheck && (
                        <SummaryItem
                            title={<FormattedMessage id="welcome.Gender.sex" defaultMessage="Gender" />}
                            text={information.gender}
                        />
                    )}
                </FlexRow>
            </SummarySection>
        </SummaryContainer>
    );
};

export default injectIntl(Gender);
