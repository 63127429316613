/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components/macro';
import styles from 'styles/styles';
import { StyledProps } from 'types/styled-components';

const Text = styled.span<Partial<StyledProps>>`
    display: ${(props) => props.display || 'block'};
    width: ${(props) => props.width || '100%'};
    color: ${(props) => (props.color ? props.theme.color[props.color] : 'inherit')};
    font-size: ${(props) => (props.size ? props.theme.font[props.size] : 'inherit')};
    font-weight: ${(props) => (props.weight ? props.weight : 'inherit')};
    font-style: ${(props) => (props.fontStyle ? props.fontStyle : 'normal')};
    text-align: ${(props) => (props.align ? props.align : 'left')};
    margin: ${(props) => props.margin || 'none'};
    @media (min-width: ${(props) => props.theme.width.sm}) {
        ${(props) => props.half && 'width: 50%'};
        ${(props) => props.half && 'margin: 0 auto'};
    }
`;
export default Text;

export const TextBox = styled.div<Partial<StyledProps>>`
    width: ${(props) => (props.width ? props.width : 'inherit')};
    padding: ${(props) => (props.padding ? props.padding : 'inherit')};
    box-sizing: border-box;
    @media (max-width: ${(props) => props.theme.width.lrg}) {
        width: 100%;
    }
`;

export const TextLink = styled.a`
    display: contents;
    text-decoration: none;
    cursor: pointer;
    color: ${(props) => props.theme.color.primary};
    white-space: nowrap;
`;

export const SecondaryLink = styled.a`
    color: ${(props) => props.theme.color.certnGray500};
    text-decoration: underline;
`;

export const TextLinkGray = styled.a`
    color: ${(props) => props.theme.color.certnGray900};
    text-decoration: underline;
`;

export const CertnFormLabel = styled.div`
    text-align: left;
    margin-bottom: 10px;
    text-transform: uppercase;
    color: ${({ theme }) => theme.color.primary};
`;

export const TextLabel = styled.p<{ completed?: boolean }>`
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: ${({ theme }) => theme.color.certnGray700};
    margin-bottom: 0px;
    text-decoration: ${({ completed }) => (completed ? 'line-through' : 'none')};
`;

export const SubText = styled.p<{ completed?: boolean }>`
    font-size: 14px;
    line-height: 22px;
    color: ${styles.color.certnGray500};
    margin-bottom: 0px;
    text-decoration: ${({ completed }) => (completed ? 'line-through' : 'none')};
`;

export const ContentLink = ({ children, href }: { children: any; href: string }) => (
    <a style={{ display: 'contents' }} rel="noopener noreferrer" target="_blank" href={href}>
        {children}
    </a>
);
