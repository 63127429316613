// Libraries
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { intl } from 'components/GlobalProvider';

// React Router & Routes
import { withRouter } from 'react-router-dom';

// Other Components
import { ErrorAlertAPI } from 'certn-ui/ErrorAlert';
import Loader from 'certn-ui/Loader';
import { get } from 'lodash';
import Titlebar from 'certn-ui/Titlebar';
import { CenterLine } from 'certn-ui/Layout';
import ApplySVG from 'images/svgs/svg-components/Apply';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

// Actions & Selectors
import { fetchTeamListings, fetchTeam } from 'views/guest/GuestActions';
import { getTeamListings, getIsFetching, getTeam } from 'views/guest/GuestSelectors';
import SearchInput from '../components/SearchInput';
import List from '../components/List';
import styles from 'styles/styles';

const mapStateToProps = (state) => ({
    listings: getTeamListings(state),
    isFetching: getIsFetching(state),
    team: getTeam(state),
});
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchTeamListings,
            fetchTeam,
        },
        dispatch
    );

const propTypes = {
    // Redux Actions
    fetchTeam: PropTypes.func.isRequired,
    fetchTeamListings: PropTypes.func.isRequired,
    // Redux Store
    team: PropTypes.object,
    listings: PropTypes.array,
};

const defaultProps = {
    team: {},
    listings: [],
};

const Row = styled.div`
    display: flex;
    > * {
        margin-right: 10px;
    }
`;

class Listings extends Component {
    constructor(props) {
        super(props);
        this.state = { search: '' };
        const teamId = get(props, ['match', 'params', 'teamId']);
        if (teamId) {
            this.props.fetchTeamListings('PM', teamId).catch((e) => ErrorAlertAPI(e));
            this.props.fetchTeam(teamId);
        }
    }

    setSearch = (searchTerm) => {
        this.setState({ search: searchTerm });
    };

    render() {
        const teamName = get(this.props, ['team', 'name']);
        const titleText = teamName ? (
            <FormattedMessage
                id="20f67.Listings.teamNameWelcome"
                defaultMessage={"Apply to one of {teamName}'s listings using Certn"}
                values={{ teamName }}
            />
        ) : (
            <FormattedMessage id="20f67.Listings.Welcome" defaultMessage="Welcome to Certn" />
        );

        return (
            <>
                <Titlebar
                    size="xlrg"
                    title={titleText}
                    subtitle={intl.formatMessage({
                        id: '20f67.Listings.listingsSubtitle',
                        defaultMessage:
                            "If you're ready to find your new home, browse and apply to all of our rental listings that are currently being advertised.",
                    })}
                    center
                />
                <CenterLine>
                    <ApplySVG color={styles.color.certnOrange400} />
                </CenterLine>
                <SearchInput
                    type="text"
                    onChange={this.setSearch}
                    placeholder={intl.formatMessage({
                        id: '20f67.Listings.searchInputMessage',
                        defaultMessage: 'Search for listings by address, unit or city',
                    })}
                />
                <List.Container>
                    {this.props.isFetching ? (
                        <Loader />
                    ) : (
                        this.props.listings.map((listing, i) => {
                            const search = this.state.search.toLowerCase();
                            if (
                                search === '' ||
                                listing.property.address.toLowerCase().includes(search) ||
                                listing.property.city.toLowerCase().includes(search) ||
                                (listing.unit && listing.unit.toLowerCase().includes(search))
                            ) {
                                return (
                                    <List.Item
                                        key={i}
                                        noBorder={this.props.listings.length < i + 1}
                                        onClick={() => this.props.history.push(`/apply/${listing.url_code}`)}
                                    >
                                        <Row>
                                            <List.ItemText>{listing.property.building}</List.ItemText>
                                            <List.ItemText light>{listing.property.address}, </List.ItemText>
                                            {listing.unit && <List.ItemText light>{listing.unit}</List.ItemText>}
                                        </Row>
                                        {listing.rent_range ? (
                                            <List.ItemText light>
                                                {`$${listing.rent}-$${listing.rent_range}/mo`}
                                            </List.ItemText>
                                        ) : (
                                            <List.ItemText light>{`$${listing.rent}/mo`}</List.ItemText>
                                        )}
                                    </List.Item>
                                );
                            }
                            return null;
                        })
                    )}
                </List.Container>
            </>
        );
    }
}

Listings.propTypes = propTypes;
Listings.defaultProps = defaultProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Listings));
