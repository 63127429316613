import styled from 'styled-components/macro';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

const Wrapper = styled.div<{ full?: boolean; grow?: boolean }>`
    margin: 12px;
    text-align: left;
    width: 100%;
    flex-basis: ${(props) => (props.full ? 'auto' : `30%`)};
    flex-grow: ${(props) => (props.grow ? '1' : '0')};
    overflow: hidden;
    flex-shrink: 1;
    @media (max-width: ${({ theme }) => theme.width.tablet}) {
        flex-basis: ${(props) => (props.full ? 'auto' : `42%`)};
        flex-grow: 1;
    }
`;

const Title = styled.div`
    font-size: 14px;
    font-weight: lighter;
    opacity: 0.6;
`;

const Text = styled.div<{ truncate?: boolean }>`
    font-size: 18px;
    font-weight: bold;
    ${(props) =>
        props.truncate
            ? 'white-space: nowrap; overflow: hidden; text-overflow: ellipsis;'
            : 'overflow-wrap: break-word'}
`;

const NotProvided = styled.div`
    font-size: 16px;
    font-weight: lighter;
    opacity: 0.4;
`;

interface Props {
    title: React.ReactNode;
    text?: React.ReactNode;
    empty?: boolean;
    full?: boolean;
    grow?: boolean;
    truncate?: boolean;
}

const SummaryItem = ({ title, text, empty, full, grow, truncate }: Props) => (
    <Wrapper full={full} grow={grow}>
        <Title>{title}</Title>
        {text || empty ? (
            <Text truncate={truncate}>{text}</Text>
        ) : (
            <NotProvided>
                <FormattedMessage id="welcome.Summary.notProvided" defaultMessage="Not provided" />
            </NotProvided>
        )}
    </Wrapper>
);

SummaryItem.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    empty: PropTypes.bool,
    full: PropTypes.bool,
    grow: PropTypes.bool,
    truncate: PropTypes.bool,
};

SummaryItem.defaultProps = {
    text: '',
    empty: false,
    full: false,
    grow: false,
    truncate: false,
};

export default SummaryItem;
