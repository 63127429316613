import { useCallback, useEffect } from 'react';
import { Clickwrap } from 'components/Clickwrap';
import { useSelector } from 'react-redux';
import { getSkinColour } from 'base/BaseSelectors';
import { CombinedCheckboxWrapper } from './styled';
import { ErrorAlertAPI } from 'certn-ui/ErrorAlert';

type CombinedCheckboxClickwrapProps = {
    groupKey: string;
    onValid?: () => void;
    onInvalid?: () => void;
    setSigned?: (signed: boolean) => void;
    signerId: string;
    disabled?: boolean;
    allowDisagreed?: boolean;
};

export const CombinedCheckboxClickwrap: React.FC<CombinedCheckboxClickwrapProps> = ({
    groupKey,
    onValid,
    onInvalid,
    setSigned,
    signerId,
    disabled = false,
    allowDisagreed = true,
}) => {
    const color = useSelector(getSkinColour);

    const appendStyledCheckbox = useCallback(() => {
        // Add <span class="certn-checkbox" /> for styling the default checkbox
        const span = document.createElement('span');
        span.classList.add('certn-checkbox');
        document.getElementById(groupKey)?.getElementsByClassName('ps-checkbox-container')[0]?.appendChild(span);
    }, [groupKey]);

    useEffect(() => {
        if (!groupKey) {
            ErrorAlertAPI();
            console.error('Unable to initialize contracts. Missing Clickwrap Group Key');
        }
    }, [groupKey]);

    if (!groupKey) {
        return null;
    }

    return (
        <CombinedCheckboxWrapper color={color} disabled={disabled}>
            <Clickwrap
                groupKey={groupKey}
                clickWrapStyle="combined"
                containerId={groupKey}
                signerId={signerId}
                onValid={onValid}
                onInvalid={onInvalid}
                onRendered={appendStyledCheckbox}
                setSigned={setSigned}
                allowDisagreed={allowDisagreed}
            />
        </CombinedCheckboxWrapper>
    );
};
