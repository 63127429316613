const ukProvinceTranslate = (other_province_state) => {
    const ukCountries = {
        England: 'ENG',
        Wales: 'WLS',
        'Northern Ireland': 'NIR',
        Scotland: 'SCT',
    };
    return ukCountries[other_province_state];
};

export default ukProvinceTranslate;
