import React, { useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { CloseOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from 'antd';

// Components
import { DrawerBody, DrawerHeader, DrawerFooter, DrawerTitleDiv, DrawerWrapper } from './styled';
import { NameSection } from './NameSection';
import { StatusSection } from './StatusSection';
import { WaitingPeriodSection } from './WaitingPeriodSection';
import { ContentSection } from './ContentSection';
import { PreviewSection } from './PreviewSection';
import { SendButton } from './SendButton';

// Actions & Selectors
import { toggleDrawer } from './DrawerActions';
import { isAdverseActionDrawerOpen, adverseActionDetails } from './DrawerSelectors';
import { fetchAdverseActionDetails } from 'views/manager/features/AdverseActionDrawer/DrawerActions';

// Utils
import { isHRApplication } from 'utils/adverseActionUtils';
import Constants from 'utils/constants';

// Types
import { AdverseActionStatusType } from './types';

const fmBackdrop = {
    visible: { opacity: 1, animationTimingFunction: 'cubic-bezier(0.1, 0.7, 1, 0.1)' },
    hidden: { opacity: 0, animationTimingFunction: 'cubic-bezier(0.1, 0.7, 1, 0.1)' },
};

const StyledCloseOutlined = styled(CloseOutlined)`
    border: none !important;
`;

const AdverseActionDrawer: React.FC = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const isDrawerOpenFlag: boolean = useSelector(isAdverseActionDrawerOpen);
    const adverseAction = useSelector(adverseActionDetails);
    const adverseActionStatus = adverseAction?.adverse_action_status;
    const adverseActionWaitingPeriod = adverseAction?.adverse_action_waiting_period;
    const applicantId = adverseAction?.applicant_id;
    const isHR = isHRApplication(location.pathname);
    const isPreAdverse = isHR && adverseActionStatus === Constants.adverseActionStatus.NONE;
    const showWaitingPeriodSection =
        adverseActionWaitingPeriod &&
        isHR &&
        [
            Constants.adverseActionStatus.NONE as AdverseActionStatusType,
            Constants.adverseActionStatus.SCHEDULED as AdverseActionStatusType,
        ].includes(adverseActionStatus);

    useEffect(() => {
        // removes body scroll while drawer is open
        isDrawerOpenFlag && (document.body.style.overflow = 'hidden');
        !isDrawerOpenFlag && (document.body.style.overflow = 'unset');
    }, [isDrawerOpenFlag]);

    const handleDrawerClose = (event: React.MouseEvent<HTMLDivElement> | React.MouseEvent<HTMLLIElement>) => {
        event.stopPropagation();
        dispatch(toggleDrawer(false));
    };

    return (
        <AnimatePresence>
            {isDrawerOpenFlag && (
                <motion.div
                    className="backdrop"
                    data-testid="adverse-action-drawer-backdrop"
                    variants={fmBackdrop}
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        background: 'rgba(0,0,0,0.5)',
                        zIndex: 100,
                    }}
                    onClick={handleDrawerClose}
                >
                    <DrawerWrapper
                        onClick={(e) => e.stopPropagation()}
                        initial={{ right: -450 }}
                        animate={{
                            right: 0,
                            transition: { ease: 'easeInOut', duration: 0.25 },
                            animationTimingFunction: 'cubic-bezier(0.1, 0.7, 1, 0.1)',
                        }}
                        exit={{
                            right: -450,
                            transition: { ease: 'easeInOut', duration: 0.25 },
                            animationTimingFunction: 'cubic-bezier(0.1, 0.7, 1, 0.1)',
                        }}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <DrawerHeader>
                            <DrawerTitleDiv>
                                <FormattedMessage
                                    id="applicant.AdverseAction.Drawer.Title"
                                    defaultMessage="Adverse Action Process"
                                />
                                <Button
                                    style={{ border: 'none', top: '2px' }}
                                    onClick={handleDrawerClose}
                                    icon={<StyledCloseOutlined />}
                                />
                            </DrawerTitleDiv>
                            <NameSection
                                adverseActionStatus={adverseActionStatus}
                                isHR={isHR}
                                adverseAction={adverseAction}
                            />
                            {adverseActionStatus && adverseActionStatus !== Constants.adverseActionStatus.NONE && (
                                <StatusSection adverseAction={adverseAction} />
                            )}
                        </DrawerHeader>
                        <DrawerBody>
                            {showWaitingPeriodSection && (
                                <WaitingPeriodSection waitingPeriod={adverseActionWaitingPeriod} />
                            )}
                            {adverseAction && (
                                <>
                                    <ContentSection
                                        adverseAction={adverseAction}
                                        isPreAdverse={isPreAdverse}
                                        reload={() => dispatch(fetchAdverseActionDetails(applicantId))}
                                    />
                                    <PreviewSection isPreAdverse={isPreAdverse} applicantId={applicantId} />
                                </>
                            )}
                        </DrawerBody>
                        {adverseActionStatus && adverseActionStatus === Constants.adverseActionStatus.NONE && (
                            <DrawerFooter>
                                <SendButton
                                    isPreAdverse={isPreAdverse}
                                    applicantId={applicantId}
                                    reload={() => dispatch(fetchAdverseActionDetails(applicantId))}
                                />
                            </DrawerFooter>
                        )}
                    </DrawerWrapper>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default AdverseActionDrawer;
