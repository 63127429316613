// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// UI Components
import Loader from 'certn-ui/Loader';
import Titlebar from 'certn-ui/Titlebar';
import { BackButton } from 'certn-ui/Button';
import { message } from 'antd';

// Actions & Selectors
import { fetchListing, editListing } from 'views/manager/views/pm/views/listings/ListingsActions';
import { getIsFetching, getListing } from 'views/manager/views/pm/views/listings/ListingsSelectors';

// Other Components
import { ListingsForm } from 'views/manager/views/pm/views/listings/components';

const mapStateToProps = (state) => ({ isFetching: getIsFetching(state), listing: getListing(state) });
const mapDispatchToProps = (dispatch) => bindActionCreators({ fetchListing, editListing }, dispatch);

const propTypes = {
    // Redux Actions
    fetchListing: PropTypes.func.isRequired,
    editListing: PropTypes.func.isRequired,
    // Redux State
    isFetching: PropTypes.bool.isRequired,
    listing: PropTypes.object,
};

const defaultProps = {
    listing: {},
};

class ListingsEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = { loading: true };
        if (props.match && props.match.params) {
            props.fetchListing(props.match.params.listingId).then(() => this.setState({ loading: false }));
        }
    }

    onSubmitListing = (listingData) => {
        if (this.props.match && this.props.match.params) {
            Promise.resolve(this.props.editListing(this.props.match.params.listingId, listingData))
                .then(this.props.history.push('/pm/listings'))
                .then(() => message.success('Listing Saved'))
                .catch(() => message.error('There was an error editing your listing.'));
        } else {
            message.error('Listing Not Saved');
        }
    };

    render() {
        return (
            <>
                <BackButton label="LISTINGS" onClick={() => this.props.history.push('/pm/listings/')} />
                <Titlebar title="Edit Listing" />
                {this.props.isFetching || this.state.loading ? (
                    <Loader />
                ) : (
                    <ListingsForm listing={this.props.listing} onSubmit={this.onSubmitListing} />
                )}
            </>
        );
    }
}

ListingsEdit.propTypes = propTypes;
ListingsEdit.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(ListingsEdit);
