// Libraries
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Alert } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components/macro';
import { useIntl } from 'react-intl';

// Styles
import styles from 'styles/styles';

// Components
import { SignInForm } from './components';
import { Box, LayoutBody } from 'certn-ui/Layout';
import { ErrorAlertAPI } from 'certn-ui/ErrorAlert';
import { QuestionEnclosure } from '../../components';

// Modules
import Auth from 'modules/Auth';
import { Path } from 'modules';

// Actions & Selectors
import { loginBasic } from 'base/BaseActions';

const StyledAlert = styled(Alert)`
    text-align: left !important;
    max-width: 600px;
    margin: 30px auto !important;
`;

const SignIn = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const intl = useIntl();
    const [loading, setLoading] = useState(false);

    const handleSubmit = (e, validateFields) => {
        e.preventDefault();

        validateFields((err, values) => {
            if (!err) {
                setLoading(true);
                dispatch(loginBasic({ loginForm: values, isApplicantLogin: true }))
                    .then(() => history.push('review'))
                    .catch((error) => {
                        setLoading(false);
                        ErrorAlertAPI(error);
                    })
                    .finally(() => setLoading(false));
            } else {
                // provide failure error
            }
        });
    };

    useEffect(() => {
        // Redirects to Review page if already logged in
        if (Auth.isApplicantAuthenticated()) {
            return history.push('review');
        }
    }, [history]);

    const subdomain = Path.getCurrentSubdomain();
    const showAlert = subdomain === 'mycrc' || subdomain === 'staging-app';

    return (
        <LayoutBody>
            <QuestionEnclosure>
                {showAlert && (
                    <Box size="xs">
                        <StyledAlert
                            banner
                            message={intl.formatMessage({
                                id: 'signin.alertTitle',
                                defaultMessage: 'Having problems signing in?',
                            })}
                            type="warning"
                            description={intl.formatMessage({
                                id: 'signin.alertDescription',
                                defaultMessage:
                                    "Can't access your account from before September 18, 2020? Please connect with a member of our customer service team at support@mycrc.ca to re-gain access to your application or report!",
                            })}
                            showIcon
                            icon={<InfoCircleOutlined style={{ color: styles.color.certnOrange500 }} />}
                        />
                    </Box>
                )}
                <SignInForm handleSubmit={handleSubmit} loading={loading} />
            </QuestionEnclosure>
        </LayoutBody>
    );
};

export default SignIn;
