// Libraries
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Row, Col } from 'antd';

// Components
import Title from 'certn-ui/Title';
import Text from 'certn-ui/Text';
import { AppearRight } from 'certn-ui/Animate';
import { TrackNavigation } from 'views/welcome/components';
import UploadSection from './UploadSection';

const AustralianNPCDocumentsForm = ({ handleSubmit, isUploading, ...props }) => (
    <>
        <AppearRight>
            <Title>
                <FormattedMessage
                    id="welcome.AustralianNPCDocuments.documentsTitle"
                    defaultMessage="Identification Documents"
                />
            </Title>
            <Row gutter={[16, 16]}>
                <Col sm={24} md={{ span: 16, offset: 4 }} lg={{ span: 14, offset: 5 }} xl={{ span: 12, offset: 6 }}>
                    <Text align="center" color="certnGray600">
                        <FormattedMessage
                            id="welcome.AustralianNPCDocuments.description"
                            defaultMessage="To run your National Police Check (NPC) and meet the Australian Federal Police (AFP) requirements, we require a minimum of 100 points of identification documents to be provided. At least one primary document is required."
                        />
                    </Text>
                    <Text align="center" color="certnGray600">
                        <b>
                            <FormattedMessage
                                id="welcome.AustralianNPCDocuments.info"
                                defaultMessage="File types: jpg, png, or pdf, with a max file size of 10MB."
                            />
                        </b>
                    </Text>
                </Col>
            </Row>
            <UploadSection category="primary" index={0} {...props} />
            <UploadSection category="primary" index={1} {...props} optional />
            <UploadSection category="secondary" index={0} {...props} optional />
            <UploadSection category="secondary" index={1} {...props} optional />
            <TrackNavigation loading={isUploading} handleSubmit={handleSubmit} />
        </AppearRight>
    </>
);

export default AustralianNPCDocumentsForm;
