import { useCountries } from 'hooks/queryHooks/useCountries';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { CheckConfig } from 'types';
import { getRequestedCountries } from 'views/manager/features/ScreenApplicant/ScreenApplicantSelectors';
import { RequestedCountry } from './RequestedCountry';
import { LineItemList } from './styled';

export const RequestedCountries: React.FC<{ check: CheckConfig }> = ({ check }) => {
    const requestedCountries: string[] = useSelector(getRequestedCountries);
    const { countries: intCountries } = useCountries();

    // Grab country data for all requested countries
    const countries = useMemo(
        () =>
            (requestedCountries ?? [])
                .map((countryCode) => intCountries?.find((item) => item.country_code === countryCode))
                .filter(Boolean),
        [intCountries, requestedCountries]
    );

    return (
        <LineItemList>
            {countries.map((country) => (
                <RequestedCountry key={country.country_code} country={country} check={check} />
            ))}
        </LineItemList>
    );
};
