/* Common place for dealing with environment variables. */

declare global {
    interface Window {
        env: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    }
}
export const ServerEnv = {
    PREVIEW: 'develop-api',
    LOCAL: 'local',
    DEV: 'develop-api',
    STAGING: 'staging-api',
    DEMO: 'demo-api',
    PROD: 'api',
};

export const API_URL_LOCAL = 'http://localhost:8000';

/** This environment variable controls which API server the web app communicates with. */
const SERVER_ENV = process.env.REACT_APP_ENV ?? ServerEnv.LOCAL;

// If you want to change this SERVER_ENV at runtime, use this line instead
// to point to a different server (and refresh your browser):
// const SERVER_ENV = ServerEnv.STAGING;

/**
 * Builds an object containing the 5 environment variables.
 * Exposed to make unit testing easier.
 * @param env the server environment ("local", "develop-api", "staging-api", "api", etc)
 * @returns {object} an object containing env, url, isProd, isDev etc
 */
export function getServerEnvironment(env: string) {
    const isLocal = env === ServerEnv.LOCAL;
    const isProduction = env === ServerEnv.PROD;
    const isProductionOrDemo = isProduction || env === ServerEnv.DEMO;

    let apiUrl = isLocal ? API_URL_LOCAL : `https://${env}.certn.co`;
    if (window?.env?.API_URL) {
        apiUrl = window?.env?.API_URL;
    }

    return {
        env,
        url: apiUrl,
        isLocal,
        isProduction,
        isNotProduction: !isProduction,
        isProductionOrDemo,
        isDevelopment: !isProductionOrDemo,
    };
}

export default getServerEnvironment(SERVER_ENV);
