// Libraries
import React from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import { Select, Input, Popover, Checkbox } from 'antd';
import { get } from 'lodash';
import { FormattedMessage } from 'react-intl';

// Components
import { AppearRight } from 'certn-ui/Animate';
import Title from 'certn-ui/Title';
import FormLayout from 'certn-form/FormLayout';
import { TrackNavigation } from 'views/welcome/components';

// Modules
import DateInput from 'certn-form/DateInput';

const { TextArea } = Input;

class CredentialDetails extends React.Component {
    formatFields = (e, validateFields) => {
        e.preventDefault();
        validateFields((err, values) => {
            const { credentials, currentCredentialIndex, handleSubmit } = this.props;
            if (!err) {
                if (values.current) {
                    values.date_of_certification = null;
                }

                const patchValues = credentials;
                if (patchValues[currentCredentialIndex]) {
                    patchValues[currentCredentialIndex] = {
                        ...patchValues[currentCredentialIndex],
                        ...values,
                    };
                } else patchValues.push(values);
                handleSubmit(patchValues);
            }
        });
    };

    highestLevelEntryExists = () => this.props.credentials?.some((credential) => credential.highest_level);

    render = () => {
        const { form, credentials, currentCredentialIndex, intl, highestLevelOnly } = this.props;

        const { getFieldDecorator, validateFields, setFields, getFieldValue } = form;

        const handleSubmit = (e) => this.formatFields(e, validateFields, setFields);
        return (
            <AppearRight>
                <Title>
                    <FormattedMessage
                        id="welcome.Education.CredentialDetails.title"
                        defaultMessage="Can you give me a few details about your credential"
                    />
                </Title>
                <Form layout="horizontal">
                    <FormLayout>
                        <Form.Item hasFeedback>
                            {getFieldDecorator('current', {
                                initialValue: get(credentials, [currentCredentialIndex, 'current']),
                                rules: [],
                            })(
                                <Select
                                    size="large"
                                    autoFocus
                                    data-testid="current"
                                    showSearch
                                    type="text"
                                    placeholder={intl.formatMessage({
                                        id: 'welcome.Education.CredentialDetails.currentPast',
                                        defaultMessage: 'Current or Past?',
                                    })}
                                >
                                    <Select.Option value>
                                        <FormattedMessage
                                            id="welcome.Education.CredentialDetails.thisIsCurrent"
                                            defaultMessage="I am currently working on this credential"
                                        />
                                    </Select.Option>
                                    <Select.Option value={false}>
                                        <FormattedMessage
                                            id="welcome.Education.CredentialDetails.thisIsNotCurrent"
                                            defaultMessage="I have obtained / finished with this credential"
                                        />
                                    </Select.Option>
                                </Select>
                            )}
                        </Form.Item>
                        <Form.Item hasFeedback>
                            {getFieldDecorator('institution', {
                                initialValue: get(credentials, [currentCredentialIndex, 'institution']),
                                rules: [
                                    {
                                        required: true,
                                        message: intl.formatMessage({
                                            id: 'error.validation.notBlank',
                                            defaultMessage: 'Please do not leave blank',
                                        }),
                                    },
                                ],
                            })(
                                <Input
                                    size="large"
                                    data-testid="institution"
                                    placeholder={intl.formatMessage({
                                        id: 'welcome.Education.CredentialDetails.institution',
                                        defaultMessage: 'Institution',
                                    })}
                                    addonBefore={
                                        <Popover
                                            content={intl.formatMessage({
                                                id: 'welcome.Education.CredentialDetails.institution',
                                                defaultMessage: 'Institution',
                                            })}
                                        >
                                            <InfoCircleOutlined />
                                        </Popover>
                                    }
                                />
                            )}
                        </Form.Item>
                        <Form.Item hasFeedback>
                            {getFieldDecorator('certification', {
                                initialValue: get(credentials, [currentCredentialIndex, 'certification']),
                                rules: [
                                    {
                                        required: true,
                                        message: intl.formatMessage({
                                            id: 'error.validation.notBlank',
                                            defaultMessage: 'Please do not leave blank',
                                        }),
                                    },
                                ],
                            })(
                                <Input
                                    size="large"
                                    data-testid="certification"
                                    placeholder={intl.formatMessage({
                                        id: 'welcome.Education.CredentialDetails.credDetail',
                                        defaultMessage: 'Enter your credential detail',
                                    })}
                                />
                            )}
                        </Form.Item>
                        <Form.Item hasFeedback>
                            {getFieldDecorator('description', {
                                initialValue: get(credentials, [currentCredentialIndex, 'description']),
                            })(
                                <TextArea
                                    data-testid="description"
                                    placeholder={intl.formatMessage({
                                        id: 'welcome.Education.CredentialDetails.credDesc',
                                        defaultMessage: 'Credential description',
                                    })}
                                    autosize
                                />
                            )}
                        </Form.Item>
                        {!getFieldValue('current') && (
                            <Form.Item hasFeedback>
                                {getFieldDecorator('date_of_certification', {
                                    initialValue: get(credentials, [currentCredentialIndex, 'date_of_certification']),
                                    rules: [
                                        {
                                            required: getFieldValue('current'), // 'current' - Current is true, Past is false
                                            message: intl.formatMessage({
                                                id: 'error.validation.notBlank',
                                                defaultMessage: 'Please do not leave blank',
                                            }),
                                        },
                                    ],
                                })(
                                    <DateInput
                                        size="large"
                                        data-testid="date_of_certification"
                                        disableFutureDates
                                        placeholder={intl.formatMessage({
                                            id: 'welcome.Education.CredentialDetails.date',
                                            defaultMessage: 'Date Received',
                                        })}
                                        addonBeforeUsePlaceholder
                                    />
                                )}
                            </Form.Item>
                        )}
                        {highestLevelOnly &&
                            (!this.highestLevelEntryExists() ||
                                get(credentials, [currentCredentialIndex, 'highest_level'])) && (
                                <Form.Item key="highest_level">
                                    {getFieldDecorator('highest_level', {
                                        valuePropName: 'checked',
                                        initialValue:
                                            get(credentials, [currentCredentialIndex, 'highest_level']) || false,
                                        rules: [
                                            {
                                                required: true,
                                                message: intl.formatMessage({
                                                    id: 'error.validation.notBlank',
                                                    defaultMessage: 'Please do not leave blank',
                                                }),
                                            },
                                        ],
                                    })(
                                        <Checkbox data-testid="highest_level" id="tos">
                                            <FormattedMessage
                                                id="welcome.Education.highestLevelCred"
                                                defaultMessage="Highest level of credential"
                                            />
                                        </Checkbox>
                                    )}
                                </Form.Item>
                            )}
                    </FormLayout>
                    <TrackNavigation handleSubmit={handleSubmit} />
                </Form>
            </AppearRight>
        );
    };
}

export default Form.create()(CredentialDetails);
