// Libraries
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { intl } from 'components/GlobalProvider';
import { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

// UI Components
import Form from 'certnd/Form';
import Typography from 'certnd/Typography';

const { Heading } = Typography;

interface PropTypes {
    form: WrappedFormUtils<unknown>;
    defaultWaitingPeriod?: number;
    enableFollowup?: boolean;
    followupWaitingPeriod?: number;
}

const Notifications: React.FC<PropTypes> = ({ form, defaultWaitingPeriod, enableFollowup, followupWaitingPeriod }) => {
    const [isFollowupChecked, setIsFollowupChecked] = useState(enableFollowup);

    return (
        <>
            <Heading.H3>
                <FormattedMessage id="settings.AdverseAction.Notifications" defaultMessage="Notifications" />
            </Heading.H3>
            <Form.Checkbox
                data-testid="adverse-action-manual-followup"
                form={form}
                fieldName="enable_adverse_action_manual_followup"
                title={intl.formatMessage({
                    id: 'settings.AdverseAction.ManualFollowupTitle',
                    defaultMessage: 'Send me email updates for unopened Pre-Adverse or Adverse Action letters',
                })}
                description={intl.formatMessage({
                    id: 'settings.AdverseAction.ManualFollowupDescription',
                    defaultMessage:
                        'Get notified when an applicant does not access their Adverse Action letters (via account verification code) within the set waiting period. The email will include a PDF copy of the Adverse letter(s).',
                })}
                options={{ initialValue: enableFollowup }}
                hideBorder={false}
                hideBorderOnly
                onChange={(event: CheckboxChangeEvent) => setIsFollowupChecked(event.target.checked)}
            />
            <Form.Select
                data-testid="manual-followup-waiting-period"
                form={form}
                fieldName="adverse_action_manual_followup_waiting_period"
                title={intl.formatMessage({
                    id: 'settings.AdverseAction.ManualFollowupWaitingPeriodTitle',
                    defaultMessage: 'Unopened email notification (days)',
                })}
                description={intl.formatMessage({
                    id: 'settings.AdverseAction.ManualFollowupWaitingPeriodDescription',
                    defaultMessage:
                        'Set the number of days you wish to wait before being notified of unopened Adverse Action letter(s).',
                })}
                options={{ initialValue: followupWaitingPeriod }}
                selectOptions={[...Array(defaultWaitingPeriod).keys()].map((i) => ({
                    title: i + 1,
                    value: i + 1,
                    'data-testid': `followup-waiting-period-option-${i + 1}`,
                    key: `followup-waiting-period-option-${i + 1}`,
                }))}
                hideBorder
                headerLeft
                listHeight={164}
                disabled={!isFollowupChecked}
            />
        </>
    );
};

export default Notifications;
