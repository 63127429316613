import { OnboardingSession } from 'types';

export const getEnhancedConversionTrackingAddress = (information: OnboardingSession['information']) => {
    if (!information.first_name || !information.last_name) {
        console.error(
            `Enhanced Conversion Tracking error: invalid first (${information.first_name}) or last (${information.last_name}) name.`
        );
    }

    const data = {
        first_name: information.first_name,
        last_name: information.last_name,
    };

    const address = information.addresses?.find((addr) => addr.current);

    if (!address) {
        return data;
    }

    if (address.country?.length !== 2) {
        console.error(`Enhanced Conversion Tracking error: invalid country code (${address.country})`);
        return data;
    }

    if (!address.postal_code) {
        console.error(`Enhanced Conversion Tracking error: invalid postal code (${address.postal_code})`);
        return data;
    }

    return {
        ...data,
        street: address.address,
        city: address.city,
        region: address.province_state,
        postal_code: address.postal_code,
        country: address.country,
    };
};
