// Libraries
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';

// Components
import BirthplaceForm from 'views/welcome/views/birthplace/components/BirthplaceForm';

// Modules
import withNavigation from 'views/welcome/modules/WithNavigation';
import { OTHER } from 'modules/Countries';

// Actions & Selectors
import { setTrackPageOrder, patchInformation } from 'views/welcome/WelcomeActions';

const propTypes = {
    // withNavigation
    trackPageLocation: PropTypes.string,
    handleNextTrack: PropTypes.func.isRequired,
    trackOrder: PropTypes.array,
};
const defaultProps = {
    trackPageLocation: 'base',
    trackOrder: [],
};

const Birthplace = ({ trackPageLocation, handleNextTrack }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(setTrackPageOrder(['base']));
    }, [dispatch]);

    const handleSubmit = (values) => {
        // Make sure the province & other values are always set
        // values will contain one of them
        const valuesWithDefaults = {
            birth_province_state: OTHER,
            birth_other_province_state: null,
            ...values,
        };
        dispatch(patchInformation(valuesWithDefaults)).then(handleNextTrack);
    };

    const pages = {
        base: <BirthplaceForm handleSubmit={handleSubmit} />,
    };

    return pages[trackPageLocation];
};

Birthplace.propTypes = propTypes;
Birthplace.defaultProps = defaultProps;

export default withRouter(withNavigation(Birthplace));
