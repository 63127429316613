import React from 'react';
import styles from 'styles/styles';

const Apply = ({ color }) => (
    <svg width="85px" height="85px">
        <g stroke={styles.color.certnGray600} strokeWidth={2.0} fill={color} fillRule="evenodd" strokeLinejoin="round">
            <polyline
                fill="none"
                strokeMiterlimit="10"
                points="68.494,46.482 68.494,71.602
                12.507,71.602 12.507,39.819 47.391,39.819 	"
            />
            <line
                fill="none"
                strokeLinecap="round"
                strokeMiterlimit="10"
                x1="20.507"
                y1="48.283"
                x2="41.507"
                y2="48.283"
            />
            <line
                fill="none"
                strokeLinecap="round"
                strokeMiterlimit="10"
                x1="20.507"
                y1="55.266"
                x2="41.507"
                y2="55.266"
            />
            <line
                fill="none"
                strokeLinecap="round"
                strokeMiterlimit="10"
                x1="44.67"
                y1="63.762"
                x2="58.141"
                y2="63.762"
            />
            <line
                fill="none"
                strokeLinecap="round"
                strokeMiterlimit="10"
                x1="52.723"
                y1="54.721"
                x2="51.207"
                y2="58.225"
            />
            <g>
                <path
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    d="M51.562,47.328
                    c1.795-12.298,7.121-23.821,15.371-33.154c0.738-0.755,2.205-0.997,3.223-0.558c0.176,0.076,0.354,0.154,0.529,0.229
                    c1.018,0.438,1.852,1.669,1.807,2.723c-1.139,12.35-5.904,24.213-13.58,33.93L51.562,47.328z"
                />
            </g>
            <g>
                <polyline
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="
                51.967,48.072 51.225,54.059 54.217,55.348 58.084,50.686 		"
                />
            </g>
            <path
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                d="
                M63.746,17.741L58.85,15.28c0,0-4.646,6.274-7.463,13.01"
            />
            <line
                fill="none"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                x1="57.6"
                y1="28.477"
                x2="68.467"
                y2="33.167"
            />
        </g>
    </svg>
);

export default Apply;
