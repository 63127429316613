import { Card, Divider } from 'antd';
import { FormattedMessage } from 'react-intl';
import { InfoCol, Row, Subtitle } from './styled';
import Text from 'certn-ui/Text';
import { titleCase } from 'modules/Format';
import { OrderStatuses, UserApplicant } from 'types';
import { ShareReport } from './ShareReport';
import { DownloadReport } from './DownloadReport';
import { CheckInfo } from './CheckInfo';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslateCheckStatus } from 'hooks/useTranslateCheckStatus';

type ApplicationCardProps = {
    applicant: UserApplicant;
};

export const ApplicationCard: React.FC<ApplicationCardProps> = ({ applicant }) => {
    const { webFeatureEnableNewStatusSystem } = useFlags();
    const { translateCheckStatus } = useTranslateCheckStatus();
    let applicationReturned = ['Revenu', 'Returned'].includes(applicant.status);
    if (webFeatureEnableNewStatusSystem) {
        applicationReturned = applicant.order_status === OrderStatuses.COMPLETE;
    }

    return (
        <Card>
            <Row>
                <div>
                    <Subtitle>
                        <FormattedMessage id="applicant.Review.detailsSubtitle2" defaultMessage="Check Status" />
                    </Subtitle>
                    <Text size="med" weight="600">
                        {webFeatureEnableNewStatusSystem
                            ? translateCheckStatus(applicant.order_status)
                            : titleCase(applicant.status)}
                    </Text>
                </div>
            </Row>
            <div style={{ height: 20 }} />
            {applicationReturned && (
                <>
                    <ShareReport applicant={applicant} />
                    <Divider />
                </>
            )}
            <Row>
                <InfoCol>
                    <CheckInfo applicant={applicant} />
                    {applicationReturned && (
                        <div style={{ marginTop: '8px' }}>
                            <DownloadReport applicant={applicant} />
                        </div>
                    )}
                </InfoCol>
                <InfoCol>
                    <Subtitle>
                        <FormattedMessage
                            id="applicant.Review.detailsSubtitle3"
                            defaultMessage="Individual Information"
                        />
                    </Subtitle>
                    <Text size="xs">{applicant.applicant_account.email}</Text>
                </InfoCol>
            </Row>
        </Card>
    );
};
