import React from 'react';
import { Row, Col } from 'antd';

const R1N = ({ children, size, offset, center = false, width = '600px' }) => (
    <>
        {React.Children.map(children, (item, index) => (
            <Row
                key={index}
                style={
                    center
                        ? { display: 'flex', justifyContent: 'center' }
                        : { width, maxWidth: '100%', margin: '0 auto' }
                }
            >
                <Col xs={24} lg={size === 'lrg' ? 24 : { span: size || 16, offset: center ? 0 : offset || 4 }}>
                    {item}
                </Col>
            </Row>
        ))}
    </>
);

const R1NDefault = ({ children }) => (
    <div style={{ marginBottom: '10px' }}>
        {React.Children.map(children, (item, index) => (
            <Row key={index}>
                <Col sm={24}>{item}</Col>
            </Row>
        ))}
    </div>
);

const ConvictionLayout = ({ children, size, offset, mutateable = [...children], doubles = [] }) => {
    while (mutateable.length > 0) doubles.push(mutateable.splice(0, 2));
    return (
        <>
            {doubles.map((item, index) => (
                <Row key={index} gutter={0}>
                    <Col sm={24} lg={{ span: size || 24, offset: offset || 0 }}>
                        {item[0]}
                    </Col>
                    <Col sm={24} lg={{ span: size || 24, offset: offset || 0 }}>
                        {item[1]}
                    </Col>
                    <Col sm={24} lg={{ span: size || 24, offset: offset || 0 }}>
                        {item[2]}
                    </Col>
                </Row>
            ))}
        </>
    );
};

const R2N = ({ children, size, offset, mutateable = [...children], doubles = [] }) => {
    while (mutateable.length > 0) doubles.push(mutateable.splice(0, 2));
    return (
        <>
            {doubles.map((item, index) =>
                item.length === 2 ? (
                    <Row key={index} gutter={16}>
                        <Col sm={24} lg={{ span: size || 10, offset: offset || 2 }}>
                            {item[0]}
                        </Col>
                        <Col sm={24} lg={{ span: size || 10 }}>
                            {item[1]}
                        </Col>
                    </Row>
                ) : (
                    <Row key={index}>
                        <Col sm={24} lg={{ span: 8, offset: 8 }}>
                            {item[0]}
                        </Col>
                    </Row>
                )
            )}
        </>
    );
};

const R2R3 = ({ children, size, thirdSize = (size * 2) / 3, center = false }) => (
    <>
        <R2 size={size} center={center}>
            {children.slice(0, 2)}
        </R2>
        <R3 size={thirdSize} center={center}>
            {children.slice(2, 5)}
        </R3>
    </>
);

const R3R2 = ({ children, size, thirdSize = (size * 2) / 3, center = false }) => (
    <>
        <R3 size={thirdSize} center={center}>
            {children.slice(0, 3)}
        </R3>
        <R2 size={size} center={center}>
            {children.slice(3, 5)}
        </R2>
    </>
);

const R3 = ({ children, size, center }) => (
    <>
        <Row gutter={16} style={center ? { display: 'flex', justifyContent: 'center' } : null}>
            <Col sm={24} lg={{ span: size || 8 }}>
                {children[0]}
            </Col>
            <Col sm={24} lg={{ span: size || 8 }}>
                {children[1]}
            </Col>
            <Col sm={24} lg={{ span: size || 8 }}>
                {children[2]}
            </Col>
        </Row>
    </>
);

const R2 = ({ children, size, center }) => (
    <>
        <Row gutter={16} style={center ? { display: 'flex', justifyContent: 'center' } : null}>
            <Col sm={24} lg={{ span: size || 12 }}>
                {children[0]}
            </Col>
            <Col sm={24} lg={{ span: size || 12 }}>
                {children[1]}
            </Col>
        </Row>
    </>
);

// Default R1N
const FormLayout = (props) => <R1N {...props}>{props.children}</R1N>;

FormLayout.ConvictionLayout = ConvictionLayout;
FormLayout.R2N = R2N;
FormLayout.R2R3 = R2R3;
FormLayout.R3R2 = R3R2;
FormLayout.R3 = R3;
FormLayout.R1NDefault = R1NDefault;

export default FormLayout;
