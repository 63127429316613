// Libraries
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Form, Button, Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import SoftCheckAlert from '../QuickScreen/components/SoftCheckAlert';

// Actions & Selectors
import {
    getAllProperties,
    getAllPropertyIds,
    getProperty,
} from 'views/manager/views/pm/views/properties/PropertiesSelectors';
import {
    handleBuildingSelect,
    SET_SHOULD_PERSIST_SCREEN_APPLICANT_STATE,
} from 'views/manager/features/ScreenApplicant/ScreenApplicantActions';
import { getPropertyId, getSelectedCheckKeys } from 'views/manager/features/ScreenApplicant/ScreenApplicantSelectors';
import { fetchProperty } from 'views/manager/views/pm/views/properties/PropertiesActions';

// Constants
import { CHECK_REQUEST } from 'base/BaseConstants';

const ApplicationsScreenBuildingSection = ({ setFieldsValue }) => {
    const allProperties = useSelector(getAllProperties);
    const allPropertyIds = useSelector(getAllPropertyIds);
    const propertyId = useSelector(getPropertyId);
    const selectedServiceKeys = useSelector(getSelectedCheckKeys);
    const propertyCountry = useSelector(getProperty).country;
    const history = useHistory();
    const dispatch = useDispatch();
    return (
        <>
            <Form.Item
                name="property_id"
                initialValue={propertyId || null}
                rules={[
                    {
                        required: true,
                        message: 'Please select an existing building',
                    },
                    {
                        type: 'enum',
                        enum: allPropertyIds,
                        message: 'Please select a building from the list',
                    },
                ]}
            >
                <Select
                    showArrow
                    allowClear
                    showSearch
                    placeholder="Select a Building"
                    data-testid="select_a_building"
                    onChange={(id) => {
                        dispatch(handleBuildingSelect(id));
                        dispatch(fetchProperty(id));
                        setFieldsValue({ listing_id: undefined });
                    }}
                    filterOption={(inputValue, option) =>
                        option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                    }
                >
                    {allProperties.map((property) => (
                        <Select.Option key={property.id} text={property.id}>
                            {property.building ? `${property.building} - ${property.address}` : property.address}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item>
                <Button
                    type="dashed"
                    onClick={() => {
                        dispatch({ type: SET_SHOULD_PERSIST_SCREEN_APPLICANT_STATE, payload: true });
                        history.push('/pm/properties/new');
                    }}
                    style={{ display: 'block', width: '100%' }}
                >
                    <PlusOutlined />
                    Add Building
                </Button>
                <div style={{ marginTop: 10 }}>
                    <SoftCheckAlert
                        country={propertyCountry}
                        hasSoftcheck={selectedServiceKeys.includes(CHECK_REQUEST.SOFTCHECK)}
                    />
                </div>
            </Form.Item>
        </>
    );
};

export default ApplicationsScreenBuildingSection;
