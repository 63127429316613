// Libraries
import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Input, Form } from 'antd';

// Components
import {
    SectionWrapper,
    SectionTopBar,
    SectionBottomPanel,
    BottomPanelRow,
    BottomPanelRowInner,
    Instructions,
} from '../styled';
import FieldLabel from 'certn-form/FieldLabel';
import { MobileComponent } from 'components';
import { useSelector } from 'react-redux';
import { getCurrentTeam } from 'views/manager/views/settings/SettingsSelectors';

const HalfInput = MobileComponent(Input);

const Tag = () => {
    const intl = useIntl();

    const currentTeamSettings = useSelector(getCurrentTeam).require_applicant_tag;
    return (
        <SectionWrapper>
            <SectionTopBar>
                <FormattedMessage id="f5cd8.Applicant.tag" defaultMessage="Tag" />
            </SectionTopBar>
            <SectionBottomPanel>
                <BottomPanelRow>
                    <Instructions>
                        <FormattedMessage
                            id="f5cd8.Applicant.tagInstructions"
                            defaultMessage="Used to group related applications for billing/invoice purposes (ex. departments, office locations, job postings)."
                        />
                    </Instructions>
                </BottomPanelRow>
                <BottomPanelRow style={{ marginTop: '15px', marginBottom: '-20px' }}>
                    <BottomPanelRowInner>
                        <FieldLabel htmlFor="tag">
                            <FormattedMessage id="f5cd8.Applicant.tag" defaultMessage="Tag" />
                        </FieldLabel>
                        <Form.Item
                            name="tag"
                            rules={[
                                currentTeamSettings && {
                                    required: true,
                                    message: intl.formatMessage({
                                        id: 'error.validation.tagIsBlank',
                                        defaultMessage: 'You need at least 1 tag',
                                    }),
                                },
                            ]}
                        >
                            <HalfInput
                                placeholder={intl.formatMessage({
                                    id: 'f5cd8.Applicant.tag',
                                    defaultMessage: 'Tag',
                                })}
                            />
                        </Form.Item>
                    </BottomPanelRowInner>
                </BottomPanelRow>
            </SectionBottomPanel>
        </SectionWrapper>
    );
};
export default Tag;
