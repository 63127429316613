import { intl } from 'components/GlobalProvider';
import React from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import styled from 'styled-components';

import {
    getUserMode,
    getUserModeIsPM,
    getUserModeIsHR,
    getTeamIsCA,
    getTeamIsUS,
    getTeamIsUKorGB,
    getTeamCountry,
    getPotatoCheckTitles,
    getLaunchDarklyFlags,
} from 'base/BaseSelectors';

// Constants
import { ACTIVE_SERVICES, CHECK_REQUEST } from 'base/BaseConstants';
import { CHECK_CONFIGURATION_STEPS } from 'views/manager/features/ScreenApplicant/ScreenApplicantConstants';

const Note = styled.span`
    font-weight: 600;
    color: ${({ theme }) => theme.color.certnGray600};
`;

// Time in minutes
const ONE_DAY = 24 * 60;
const TWO_DAYS = 2 * ONE_DAY;
const THREE_DAYS = 3 * ONE_DAY;
const ONE_WEEK = 7 * ONE_DAY;
const TWO_WEEKS = 2 * ONE_WEEK;
const THREE_WEEKS = 3 * ONE_WEEK;

/**
 * Returns an object containing the turnaround time in seconds and the title string.
 * @param {number} minutes the estimated number of minutes
 * @param {string} [label] the optional title, if not defined then "X minutes" is used
 * @returns {{time: number, title}}
 */
export const turnaroundTime = (minutes, label) => {
    let title = label;
    if (!title) {
        if (minutes <= 0) {
            title = intl.formatMessage({
                id: 'common.instant',
                defaultMessage: 'instant',
            });
        } else {
            title = intl.formatMessage(
                {
                    id: 'common.xMinutes',
                    defaultMessage: '{minutes, plural, one {# minute} other {# minutes}}',
                },
                {
                    minutes,
                }
            );
        }
    }
    return {
        time: minutes * 60,
        title,
    };
};

const getCreditCheckDescription = (options) => {
    if (options?.teamIsUS)
        return options?.userModeIsHR
            ? intl.formatMessage(
                  {
                      id: '3f746.MicroServiceContent.equifaxDescriptionHR',
                      defaultMessage:
                          "A USA Credit Report includes information on an individual's credit activity and overall financial health. Reports may include tradelines, bankruptcies, public record and collection information, and a *credit score. A credit report request will appear as an inquiry on the individual’s credit file but will not impact the individual's credit score. {note}",
                  },
                  {
                      note: (
                          <>
                              <br />
                              <br />
                              <Note>
                                  {intl.formatMessage({
                                      id: '3f746.MicroServiceContent.equifaxNote',
                                      defaultMessage:
                                          '*Note: Credit scores do not appear on reports related to employment.',
                                  })}
                              </Note>
                          </>
                      ),
                  }
              )
            : intl.formatMessage(
                  {
                      id: '3f746.MicroServiceContent.equifaxDescriptionPM',
                      defaultMessage:
                          "A USA Credit Report includes information on an individual's credit activity and overall financial health. Reports may include tradelines, bankruptcies, public record and collection information, and a *credit score. A credit report request will appear as an inquiry on the individual’s credit file but will not impact the individual's credit score. {note}",
                  },
                  {
                      note: (
                          <>
                              <br />
                              <br />
                              <Note>
                                  {intl.formatMessage({
                                      id: '3f746.MicroServiceContent.equifaxNote',
                                      defaultMessage:
                                          '*Note: Credit scores do not appear on reports related to employment.',
                                  })}
                              </Note>
                          </>
                      ),
                  }
              );
    if (options?.teamIsCA)
        return options?.userModeIsHR
            ? intl.formatMessage({
                  id: '3f746.MicroServiceContent.canadianCreditReportDescriptionHR',
                  defaultMessage:
                      "This Equifax Credit Report includes information on an individual's credit activity and current credit situation. Reports may include tradelines, bankruptcies, public records, collection information, and a credit score. A credit report request for employment purposes will appear as an inquiry on the individual's credit file. Note that this type of inquiry will not impact the individual's credit score.",
              })
            : intl.formatMessage({
                  id: '3f746.MicroServiceContent.canadianCreditReportDescriptionPM',
                  defaultMessage:
                      "This Equifax Credit Report includes information on an individual's credit activity and current credit situation. Reports may include tradelines, bankruptcies, public records, collection information, and a credit score. A credit report request for tenancy purposes will appear as an inquiry on the individual's credit file. Note that this type of inquiry will have a small impact on the individual's credit score.",
              });
};

export const getOptions = createSelector(
    getUserModeIsPM,
    getUserModeIsHR,
    getTeamIsCA,
    getTeamIsUS,
    getTeamIsUKorGB,
    getLaunchDarklyFlags,
    (userModeIsPM, userModeIsHR, teamIsCA, teamIsUS, teamIsUKorGB, launchDarklyFlags) => ({
        userModeIsPM,
        userModeIsHR,
        teamIsCA,
        teamIsUS,
        teamIsUKorGB,
        launchDarklyFlags,
    })
);

export function getCheckRequestsToTitles(options) {
    const content = getCheckContent(undefined, options);
    return Object.entries(content).reduce((acc, [key, check]) => ({ ...acc, [key]: check.title }), {});
}

export const selectCheckRequestsToTitles = createSelector(getOptions, (options) => getCheckRequestsToTitles(options));

export function getBackwardsCompatibleRequestAndTitles(services, options) {
    // if the service is included (Active Services), build request to title pairings
    const content = getCheckContent(undefined, options);
    return Object.entries(content).reduce(
        (acc, [key, check]) => (services.includes(key) ? [...acc, { request: key, title: check.title }] : acc),
        []
    );
}

export const getAdditionalOptionsKeys = (requestFlag, settingsConfig, configurablePotatoChecks) => {
    const services = {
        [CHECK_REQUEST.EMPLOYER_REFERENCES]: [
            'employer_references_min',
            'employer_references_max',
            'employer_references_years',
            'employer_references_years_or_individually',
            'employer_questionaire_id',
        ],
        [CHECK_REQUEST.EMPLOYER_PHONE_REFERENCES]: [
            'employer_references_min',
            'employer_references_max',
            'employer_references_years',
            'employer_references_years_or_individually',
            'employer_questionaire_id',
        ],
        [CHECK_REQUEST.ADDRESS_REFERENCES]: [
            'address_references_min',
            'address_references_max',
            'address_references_years',
            'address_references_years_or_individually',
            'address_questionaire_id',
        ],
        [CHECK_REQUEST.ADDRESS_PHONE_REFERENCES]: [
            'address_references_min',
            'address_references_max',
            'address_references_years',
            'address_references_years_or_individually',
            'address_questionaire_id',
        ],
        [CHECK_REQUEST.EMPLOYMENT_VERIFICATION]: [
            'employment_verification_min',
            'employment_verification_max',
            'employment_verification_years',
            'employment_verification_years_or_individually',
        ],
        [CHECK_REQUEST.CREDENTIAL_VERIFICATION]: ['credential_verification_level'],
        [CHECK_REQUEST.EDUCATION_VERIFICATION]: ['education_verification_level'],
        [CHECK_REQUEST.INTERNATIONAL_CRIMINAL_RECORD_CHECK]: [
            'requested_countries',
            'international_follow_up_applicant_country_select',
        ],
        [CHECK_REQUEST.UK_BASIC_DBS_CHECK]: ['employment_sector', 'role_or_position_title'],
    };

    // Only enable ACIC options if it is not MyCRC. Otherwise, applicant will fill those fields in.
    if (!settingsConfig.my_crc_region) {
        services[CHECK_REQUEST.STANDARD_ACIC_CHECK] = [
            'purpose_of_check',
            'position_title',
            'place_of_work',
            'license_type',
            'issuing_authority',
            'reason_for_check',
            'location_of_work',
            'state_or_territory',
        ];
        services[CHECK_REQUEST.VOLUNTEER_ACIC_CHECK] = ['volunteer_role', 'volunteer_organization', 'purpose_of_check'];
    }

    // Some additional options that require specific settings to be enabled
    if (settingsConfig?.enable_ten_year_search) {
        services[CHECK_REQUEST.US_CRIMINAL_RECORD_CHECK_TIER_3] = ['us_criminal_record_check_years'];
    }

    if (configurablePotatoChecks?.filter((check) => check.request === requestFlag).length > 0) {
        services[requestFlag] = ['potato_check_configuration'];
    }
    return services[requestFlag] || [];
};

export function getCheckContent(requestFlag, options) {
    const instant = turnaroundTime(0);
    const referenceDependent = turnaroundTime(
        0,
        intl.formatMessage({
            id: 'common.timeDependsOnReference',
            defaultMessage: 'reference dependent',
        })
    );
    const moreInformation = intl.formatMessage({
        id: '3f746.MicroServiceContent.moreInfo',
        defaultMessage: 'More information.',
    });

    const content = {
        [CHECK_REQUEST.BASE]: {
            title: intl.formatMessage({
                id: '3f746.MicroServiceContent.baseTitle',
                defaultMessage: 'Base Scan',
            }),
            description: intl.formatMessage({
                id: '3f746.MicroServiceContent.baseDescription',
                defaultMessage:
                    'Score and rank applicants based on the information provided on their application and on any connected social media profiles. Applicants must connect their social profiles or provide cover letters to receive behavioural scores.',
            }),
            estimatedTurnaroundTime: instant,
        },
        [CHECK_REQUEST.SOFTCHECK]: {
            title: intl.formatMessage({
                id: '3f746.MicroServiceContent.softcheckTitle',
                defaultMessage: 'Softcheck™ *',
            }),
            description: intl.formatMessage(
                {
                    id: '3f746.MicroServiceContent.softcheckDescription',
                    defaultMessage:
                        '{note} Softcheck is a search of thousands of international data sources for identification of potential illegal behavior, criminal activity, incidents of fraud, regulatory violations, and negative media information. This service also includes searches of country sanction lists, exposed persons lists, sex offender registries, terrorist registries, and most wanted lists from around the globe. This service is not available in the USA.',
                },
                {
                    note: (
                        <>
                            <Note>
                                {intl.formatMessage({
                                    id: '3f746.MicroServiceContent.softcheckDescriptionNote',
                                    defaultMessage: 'DO NOT ORDER on candidates located in the United States.',
                                })}
                            </Note>
                            <br />
                            <br />
                        </>
                    ),
                }
            ),
            estimatedTurnaroundTime: turnaroundTime(1),
        },
        [CHECK_REQUEST.SOCIAL_MEDIA]: {
            title: intl.formatMessage({
                id: 'services.socialMedia',
                defaultMessage: 'Social Media Check',
            }),
            description: intl.formatMessage({
                id: '3f746.MicroServiceContent.socialMediaDescription',
                defaultMessage:
                    'A Social Media Check analyzes publicly available social media posts by or about a candidate. Using machine learning, this check identifies content with possible risk indicators, such as hate speech, insults, bullying, offensive language, violence, drug use, and potentially illegal behaviors.',
            }),

            estimatedTurnaroundTime: turnaroundTime(
                THREE_DAYS,
                intl.formatMessage({
                    id: 'common.timeOneToThreeBusinessDays',
                    defaultMessage: '1-3 business days',
                })
            ),
        },
        [CHECK_REQUEST.IDENTITY_VERIFICATION]: {
            title: options?.launchDarklyFlags?.webFeatureDisableEquifaxIdentityVerification
                ? intl.formatMessage({
                      id: '3fd95.MicroServiceContent.clientManualIdVerificationTitle',
                      defaultMessage: 'Client Manual Identity Verification',
                  })
                : intl.formatMessage({
                      id: 'services.identityVerification',
                      defaultMessage: 'Identity Verification',
                  }),
            description: options?.launchDarklyFlags?.webFeatureDisableEquifaxIdentityVerification
                ? intl.formatMessage(
                      {
                          id: '9d459.MicroServiceContent.clientManualIdVerificationDescription',
                          defaultMessage: "Applicant's identity verification must be manually provided. {note}",
                      },
                      {
                          note: (
                              <>
                                  <br />
                                  <br />
                                  <Note>
                                      {intl.formatMessage({
                                          id: 'b051b.MicroServiceContent.clientManualIdVerificationNote',
                                          defaultMessage:
                                              'Note: This service can only be paired with (Enhanced) Canadian Criminal Record Check. You may not order it as a stand-alone check or in combination with any other check.',
                                      })}
                                  </Note>
                              </>
                          ),
                      }
                  )
                : intl.formatMessage(
                      {
                          id: '3f746.MicroServiceContent.idVerificationDescription',
                          defaultMessage:
                              "Certn uses credit data combined with questions from the applicant's credit file to verify the applicant's identity. Identity verification is required to complete a Canadian criminal record check. {note}",
                      },
                      {
                          note: (
                              <>
                                  <br />
                                  <br />
                                  <Note>
                                      {intl.formatMessage({
                                          id: '3f746.MicroServiceContent.idVerificationDescriptionNote',
                                          defaultMessage:
                                              'Note: This service is only eligible for Canadian residents. If your applicant does not have a Canadian address, please choose the OneID service for identity verification instead.',
                                      })}
                                  </Note>
                              </>
                          ),
                      }
                  ),
            estimatedTurnaroundTime: instant,
        },
        [CHECK_REQUEST.CRIMINAL_RECORD_CHECK]: {
            title: intl.formatMessage({
                id: 'services.canadianCriminalRecordCheck',
                defaultMessage: 'Canadian Criminal Record Check',
            }),
            description: intl.formatMessage(
                {
                    id: '3f746.MicroServiceContent.crimRecCheckDescription',
                    defaultMessage:
                        "The Basic Canadian Criminal Record Check searches the standard federal Canadian criminal records database. Reported results are based on the candidate's name and date of birth. The check verifies the accuracy of disclosed criminal convictions for indictable, hybrid and summary offences for which a pardon has not been granted. Note that not all summary convictions are reported and therefore cannot always be confirmed with this check. This check requires a successful identification verification prior to fulfillment of this service. {disclaimer}",
                },
                {
                    disclaimer: (
                        <>
                            <br />
                            <br />
                            <span>
                                {intl.formatMessage({
                                    id: '3f746.MicroServiceContent.crimRecCheckDescriptionNote',
                                    defaultMessage:
                                        "*Notwithstanding this paragraph, Certn's police partners have the right to exercise their own discretion in extraordinary circumstances.",
                                })}
                            </span>
                        </>
                    ),
                }
            ),
            estimatedTurnaroundTime: turnaroundTime(
                240,
                intl.formatMessage({
                    id: '3f746.MicroServiceContent.crimRecCheckTurnaroundTime',
                    defaultMessage: '4 hours',
                })
            ),
        },
        [CHECK_REQUEST.ENHANCED_CRIMINAL_RECORD_CHECK]: {
            title: intl.formatMessage({
                id: '3f746.MicroServiceContent.enhancedCrimRecCheckTitle',
                defaultMessage: 'Enhanced Canadian Criminal Record Check',
            }),
            description: intl.formatMessage(
                {
                    id: '3f746.MicroServiceContent.enhancedCrimRecCheckDescription',
                    defaultMessage:
                        'The Enhanced Canadian Criminal Record Check (in Ontario, this is referred to as a Judicial Matters Check) includes the Basic Canadian Criminal Record Check and additional searches of the CPIC investigative Data Bank and/or the Police Information Portal (PIP). Reported results are based on the candidate\'s name and date of birth. In addition to verifying the accuracy of disclosed criminal convictions, this check includes a response of "clear" or "not clear" with respect to disclosable record information regarding summary convictions, outstanding warrants, pending court charges, indictable offences, peace bonds, and any prohibitions or probation orders in effect from both the CPIC Investigative Data Bank and the PIP. This check requires a successful identification verification prior to fulfillment of this service. {disclaimer}',
                },
                {
                    disclaimer: (
                        <>
                            <br />
                            <br />
                            <span>
                                {intl.formatMessage({
                                    id: '3f746.MicroServiceContent.crimRecCheckDescriptionNote',
                                    defaultMessage:
                                        "*Notwithstanding this paragraph, Certn's police partners have the right to exercise their own discretion in extraordinary circumstances.",
                                })}
                            </span>
                        </>
                    ),
                }
            ),
            estimatedTurnaroundTime: turnaroundTime(
                240,
                intl.formatMessage({
                    id: '3f746.MicroServiceContent.enhancedCrimRecCheckTurnaroundTime',
                    defaultMessage: '4 hours',
                })
            ),
        },
        [CHECK_REQUEST.VULNERABLE_SECTOR_CRIMINAL_RECORD_CHECK]: {
            title: intl.formatMessage({
                id: '3f746.MicroServiceContent.vscTitle',
                defaultMessage: 'Vulnerable Sector Check',
            }),
            description: intl.formatMessage({
                id: '3f746.MicroServiceContent.vscDescription',
                defaultMessage: 'Add Vulnerable Sector Check (Add for $9.99)',
            }),
            estimatedTurnaroundTime: turnaroundTime(15),
            missingConfigurationStepInfo: {
                [CHECK_CONFIGURATION_STEPS.CHECK_NOT_ENABLED]: {
                    message: intl.formatMessage({
                        id: 'checks.configureEnableVulnerableSector',
                        defaultMessage: 'Vulnerable sector checks must be turned on by Certn support.',
                    }),
                },
            },
        },
        [CHECK_REQUEST.ENHANCED_IDENTITY_VERIFICATION]: {
            title: intl.formatMessage({
                id: '3f746.MicroServiceContent.enhancedIdTitle',
                defaultMessage: 'OneID',
            }),
            description: intl.formatMessage({
                id: '3f746.MicroServiceContent.enhancedIdDescription',
                defaultMessage:
                    'OneID is an online, instant, secure, biometric authentication identity verification tool that compares a real-time self-photo to a government-issued identification document. The advanced software also scans the machine-readable zone of the identity document for format discrepancies.',
            }),
            link: (
                <a href="https://certn.co/id-verification/" target="_blank" rel="noopener noreferrer">
                    {moreInformation}
                </a>
            ),
            estimatedTurnaroundTime: instant,
        },
        [CHECK_REQUEST.MOTOR_VEHICLE_RECORDS]: {
            title: options?.teamIsUS
                ? intl.formatMessage({
                      id: '3f746.MicroServiceContent.mvrTitle',
                      defaultMessage: 'Motor Vehicle Records',
                  })
                : intl.formatMessage({
                      id: '3f746.MicroServiceContent.driversAbstractTitle',
                      defaultMessage: 'Drivers Abstracts Canada',
                  }),
            description: options?.teamIsUS
                ? intl.formatMessage({
                      id: '3f746.MicroServiceContent.mvrDescription',
                      defaultMessage:
                          "A motor vehicle record report shows a copy of a candidate's driving record which includes license status, traffic citations and accident history through a state's Department of Motor Vehicles (DMV). Additional state fees apply.",
                  })
                : intl.formatMessage({
                      id: '3f746.MicroServiceContent.driversAbstractDescription',
                      defaultMessage:
                          'The Driver Abstract Check provides information from the selected province relating to license status, driving history, infractions, accidents, and merit/demerit points. Information available varies by province. This check is not available in Alberta and is limited in Quebec.',
                  }),
            estimatedTurnaroundTime: turnaroundTime(
                0,
                intl.formatMessage({
                    id: '3f746.MicroServiceContent.mvrTurnaroundTime',
                    defaultMessage: 'varying by location',
                })
            ),
            missingConfigurationStepInfo: {
                [CHECK_CONFIGURATION_STEPS.CHECK_NOT_ENABLED]: {
                    message: intl.formatMessage({
                        id: 'checks.configureEnableMotorVehicle',
                        defaultMessage: 'Motor vehicle checks must be turned on by Certn support.',
                    }),
                },
            },
        },
        [CHECK_REQUEST.ADDRESS_REFERENCES]: {
            title: intl.formatMessage({
                id: '3f746.MicroServiceContent.addressRefTitle',
                defaultMessage: 'Tenancy Email Reference Check',
            }),
            description: intl.formatMessage({
                id: '3f746.MicroServiceContent.addressRefDescription',
                defaultMessage:
                    'A Tenancy Digital Reference Check provides information regarding the personal character of a candidate. Responses to questions are obtained via email from supplied personal references utilizing a preset (or custom) list of questions and are returned in the consumer report. The standard process includes three (3) attempts to obtain the requested information per source.',
            }),
            estimatedTurnaroundTime: referenceDependent,
        },
        [CHECK_REQUEST.ADDRESS_PHONE_REFERENCES]: {
            title: intl.formatMessage({
                id: '3f746.MicroServiceContent.addressPhoneRefTitle',
                defaultMessage: 'Tenancy Phone Reference Check',
            }),
            description: intl.formatMessage({
                id: '3f746.MicroServiceContent.addressPhoneRefDescription',
                defaultMessage:
                    'A Tenancy Phone Reference Check provides information regarding the personal character of a candidate. Responses to questions are obtained via phone from supplied personal references utilizing a preset (or custom) list of questions and are returned in the consumer report. The standard process includes three (3) attempts to obtain the requested information per source. ',
            }),
            estimatedTurnaroundTime: referenceDependent,
        },
        [CHECK_REQUEST.EMPLOYER_REFERENCES]: {
            title: intl.formatMessage({
                id: '3f746.MicroServiceContent.employerRefTitle',
                defaultMessage: 'Employment Digital Reference Check',
            }),
            description: intl.formatMessage({
                id: '3f746.MicroServiceContent.employerRefDescription',
                defaultMessage:
                    'An Employment Digital Reference Check provides information regarding the personal character and work performance of a candidate. Responses to questions are obtained via email from supplied personal references utilizing a preset (or custom) list of questions and are returned in the consumer report. The standard process includes three (3) attempts to obtain the requested information per source. ',
            }),
            estimatedTurnaroundTime: referenceDependent,
        },
        [CHECK_REQUEST.EMPLOYER_PHONE_REFERENCES]: {
            title: intl.formatMessage({
                id: '3f746.MicroServiceContent.employerPhoneRefTitle',
                defaultMessage: 'Employment Phone Reference Check',
            }),
            description: intl.formatMessage({
                id: '3f746.MicroServiceContent.employerPhoneRefDescription',
                defaultMessage:
                    'An Employment Phone Reference Check provides information regarding the personal character and work performance of a candidate. Responses to questions are obtained via phone from supplied personal references utilizing a preset (or custom) list of questions and are returned in the consumer report. The standard process includes three (3) attempts to obtain the requested information per source. ',
            }),
            estimatedTurnaroundTime: referenceDependent,
        },
        [CHECK_REQUEST.EQUIFAX]: {
            title: options?.teamIsUS
                ? intl.formatMessage({
                      id: '3f746.MicroServiceContent.equifaxTitle',
                      defaultMessage: 'US Credit Report',
                  })
                : intl.formatMessage({
                      id: '3f746.MicroServiceContent.canadianCreditReportTitle',
                      defaultMessage: 'Canadian Credit Report',
                  }),
            description: getCreditCheckDescription(options),
            estimatedTurnaroundTime: turnaroundTime(1),
            missingConfigurationStepInfo: {
                [CHECK_CONFIGURATION_STEPS.MISSING_DATA_PROVIDER_CREDENTIALS]: {
                    message: intl.formatMessage({
                        id: 'checks.dataProviderCredentialsRequired',
                        defaultMessage: 'Equifax credentials need to be setup on your team.',
                    }),
                },
            },
        },
        [CHECK_REQUEST.EDUCATION_VERIFICATION]: {
            title: intl.formatMessage({
                id: 'common.educationVerification',
                defaultMessage: 'Education Verification',
            }),
            description: intl.formatMessage({
                id: '3f746.MicroServiceContent.educationVerificationDescription',
                defaultMessage:
                    'An Education Verification confirms the educational history of an individual. Information is obtained from the educational institution or their designated third-party provider. This check typically verifies degree received, course of study and dates of attendance as available. The standard process includes at least three (3) attempts to obtain the requested information per institution. Note that an additional fee may apply directly from the institution or the respective third-party data custodian.',
            }),
            link: (
                <a href="https://certn.co/education-verification-services/" target="_blank" rel="noopener noreferrer">
                    {moreInformation}
                </a>
            ),
            estimatedTurnaroundTime: turnaroundTime(
                THREE_DAYS,
                intl.formatMessage({
                    id: 'common.timeOneToThreeBusinessDays',
                    defaultMessage: '1-3 business days',
                })
            ),
        },
        [CHECK_REQUEST.CREDENTIAL_VERIFICATION]: {
            title: intl.formatMessage({
                id: 'services.credentialVerification',
                defaultMessage: 'Credential Verification',
            }),
            description: intl.formatMessage({
                id: '3f746.MicroServiceContent.credentialVerificationDescription',
                defaultMessage:
                    'A Credential Verification validates professional licenses, certifications, designations, and other professional credentials as disclosed by a candidate. The check generally includes the credential status and its relevant dates (as available) with the issuing organization. The standard process includes three (3) attempts to obtain this information through the appropriate source. Note that an additional fee may apply directly from the entity issuing the credential or the respective third-party data custodian.',
            }),
            estimatedTurnaroundTime: turnaroundTime(
                THREE_DAYS,
                intl.formatMessage({
                    id: 'common.timeOneToThreeBusinessDays',
                    defaultMessage: '1-3 business days',
                })
            ),
        },
        [CHECK_REQUEST.EMPLOYMENT_VERIFICATION]: {
            title: intl.formatMessage({
                id: 'common.employmentVerification',
                defaultMessage: 'Employment Verification',
            }),
            description: intl.formatMessage({
                id: '3f746.MicroServiceContent.employmentVerificationDescription',
                defaultMessage:
                    "An Employment Verification validates the details of disclosed employment history of an individual. Current employers are contacted only if given permission to do so. Information verified typically includes position held and dates of employment and will depend on availability of data within an entity's records. Income verification can be obtained for non-employment purposes only. The standard process includes at least three (3) attempts to obtain the requested information per employer. Note that an additional fee may apply directly from the employer or their third party data custodian.",
            }),
            link: (
                <a href="https://certn.co/employment-verification-services/" target="_blank" rel="noopener noreferrer">
                    {moreInformation}
                </a>
            ),
            estimatedTurnaroundTime: turnaroundTime(
                0,
                intl.formatMessage({
                    id: 'common.timeDependsOnEmployer',
                    defaultMessage: 'employer dependent',
                })
            ),
        },
        [CHECK_REQUEST.US_CRIMINAL_RECORD_CHECK_TIER_1]: {
            title: intl.formatMessage({
                id: '3f746.MicroServiceContent.usCrimTier1Title',
                defaultMessage: 'US Base Criminal',
            }),
            description: intl.formatMessage({
                id: '3f746.MicroServiceContent.usCrimTier1Description',
                defaultMessage:
                    'US Base Criminal Check package includes the following products: US Social Security Number Trace, US National Criminal Record Database Check (with Alias), US National Sex Offender Registry Check (with Alias) and Global Sanctions and Terrorist Watchlist Check (with Alias). The default look-back period for each product is seven years.  Any results returned for criminal searches will be limited to felony and misdemeanor convictions and pending cases for this timeframe. Any county searches performed to verify information are at the Predominantly Used Index (PUI) in a specified county which houses the majority of felony and misdemeanor records for the county. Note that additional fees for searches of primary source jurisdictions may apply.',
            }),
            link: (
                <a
                    href="https://help.certn.co/hc/en-us/articles/26851518432659-US-criminal-record-check-package-descriptions"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {moreInformation}
                </a>
            ),
            estimatedTurnaroundTime: turnaroundTime(
                ONE_WEEK,
                intl.formatMessage({
                    id: 'common.timeDependsOnCounty',
                    defaultMessage: 'county dependent (instant - 7 business days)',
                })
            ),
        },
        [CHECK_REQUEST.US_CRIMINAL_RECORD_CHECK_TIER_2]: {
            title: intl.formatMessage({
                id: '3f746.MicroServiceContent.usCrimTier2Title',
                defaultMessage: 'US Single County Criminal',
            }),
            description: intl.formatMessage({
                id: '3f746.MicroServiceContent.usCrimTier2Description',
                defaultMessage:
                    "US Single County Criminal Check package includes the following products: US Social Security Number Trace, US National Criminal Record Database Check (with Alias), National Sex Offender Registry Check (with Alias), Global Sanctions and Terrorist Watchlist Check (with Alias), County Criminal Court Record Check in the candidate's residential jurisdiction and a Federal Court Criminal Record Check in the candidate's current state of residence. The default look-back period for each product is seven years. Any results returned for criminal searches will be limited to felony and misdemeanor convictions and pending cases for this timeframe. Any county searches performed to verify information are at the Predominantly Used Index (PUI) in a specified county which houses the majority of felony and misdemeanor records for the county. Note that additional fees for searches of primary source jurisdictions may apply.",
            }),
            link: (
                <a
                    href="https://help.certn.co/hc/en-us/articles/26851518432659-US-criminal-record-check-package-descriptions"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {moreInformation}
                </a>
            ),
            estimatedTurnaroundTime: turnaroundTime(
                ONE_WEEK,
                intl.formatMessage({
                    id: 'common.timeDependsOnCounty',
                    defaultMessage: 'county dependent (instant - 7 business days)',
                })
            ),
        },
        [CHECK_REQUEST.US_CRIMINAL_RECORD_CHECK_TIER_3]: {
            title: intl.formatMessage({
                id: '3f746.MicroServiceContent.usCrimTier3Title',
                defaultMessage: 'US Unlimited County Criminal',
            }),
            description: intl.formatMessage({
                id: '3f746.MicroServiceContent.usCrimTier3Description',
                defaultMessage:
                    'The US Unlimited County Criminal package includes the following products: US Social Security Number Trace, US National Criminal Record Database Check (with Alias), National Sex Offender Registry Check (with Alias), Global Sanctions and Terrorist Watchlist Check (with Alias), County Criminal Court Record Checks in counties of residence for the last seven years and Federal Court Criminal Record Check in states of residence for the last seven years. The default look-back period for each product is seven years. Any results returned for criminal searches will be limited to felony and misdemeanor convictions and pending cases for this timeframe. Any county searches performed to verify information are at the Predominantly Used Index (PUI) in a specified county which houses the majority of felony and misdemeanor records for the county. Note that additional fees for searches of primary source jurisdictions may apply.',
            }),
            link: (
                <a
                    href="https://help.certn.co/hc/en-us/articles/26851518432659-US-criminal-record-check-package-descriptions"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {moreInformation}
                </a>
            ),
            estimatedTurnaroundTime: turnaroundTime(
                ONE_WEEK,
                intl.formatMessage({
                    id: 'common.timeDependsOnCounty',
                    defaultMessage: 'county dependent (instant - 7 business days)',
                })
            ),
        },
        [CHECK_REQUEST.INTERNATIONAL_CRIMINAL_RECORD_CHECK]: {
            title: intl.formatMessage({
                id: 'services.intlCriminalRecordCheck',
                defaultMessage: 'International Criminal Record Check',
            }),
            description: intl.formatMessage({
                id: '3f746.MicroServiceContent.intCrimRecCheckDescription',
                defaultMessage:
                    "An International Criminal Check returns accessible public information related to an individual's criminal or court history. The scope, cost and content of returned information varies by country. Request individual country list for additional details.",
            }),
            estimatedTurnaroundTime: turnaroundTime(
                TWO_WEEKS,
                intl.formatMessage({
                    id: '3f746.MicroServiceContent.intCrimRecCheckTurnaroundTime',
                    defaultMessage: '10-14 business days',
                })
            ),
        },
        [CHECK_REQUEST.STANDARD_ACIC_CHECK]: {
            title: intl.formatMessage({
                id: '3f746.MicroServiceContent.ausAcicCheckTitle',
                defaultMessage: 'Standard Australian Criminal Intelligence Commission Check',
            }),
            description: intl.formatMessage({
                id: '3f746.MicroServiceContent.ausAcicCheckDescription',
                defaultMessage:
                    "InterCheck Global Pty Ltd (part of Certn group of companies) is accredited by the Australian Criminal Intelligence Commission (ACIC) to process Nationally Coordinated Criminal History Checks (NCCHC). A NCCHC is a nationally coordinated criminal history check that reports applicant information that has been determined as releasable by Australia's police agencies. Reported information from the ACIC can include court convictions, court appearances, charges, findings of guilt with no conviction, good behaviour bonds, matters awaiting court hearings and traffic offenses throughout all states and territories in Australia.",
            }),
            estimatedTurnaroundTime: turnaroundTime(
                ONE_WEEK,
                intl.formatMessage({
                    id: 'common.timeDependsOnApplicant',
                    defaultMessage: 'applicant dependent (1 - 7 business days)',
                })
            ),
        },
        [CHECK_REQUEST.VOLUNTEER_ACIC_CHECK]: {
            title: intl.formatMessage({
                id: '3f746.MicroServiceContent.ausVolunteerAcicCheckTitle',
                defaultMessage: 'Volunteer Australian Criminal Intelligence Commission Check',
            }),
            description: intl.formatMessage({
                id: '3f746.MicroServiceContent.ausVolunteerAcicCheckDescription',
                defaultMessage:
                    "InterCheck Global Pty Ltd (part of Certn group of companies) is accredited by the Australian Criminal Intelligence Commission (ACIC) to process Nationally Coordinated Criminal History Checks (NCCHC). A NCCHC is a nationally coordinated criminal history check that reports applicant information that has been determined as releasable by Australia's police agencies. Reported information from the ACIC can include court convictions, court appearances, charges, findings of guilt with no conviction, good behaviour bonds, matters awaiting court hearings and traffic offenses throughout all states and territories in Australia.\n\nVolunteer checks should only be used in special circumstances such as: volunteering with a registered charity, not for profit or community-based organisation; or a student undertaking a compulsory unpaid vocational placement.",
            }),
            estimatedTurnaroundTime: turnaroundTime(
                ONE_WEEK,
                intl.formatMessage({
                    id: 'common.timeDependsOnApplicant',
                    defaultMessage: 'applicant dependent (1 - 7 business days)',
                })
            ),
        },
        [CHECK_REQUEST.RIGHT_TO_WORK]: {
            title: intl.formatMessage({
                id: '3f746.MicroServiceContent.RtwCheckTitle',
                defaultMessage: 'Right To Work Check',
            }),
            description: intl.formatMessage({
                id: '3f746.MicroServiceContent.RtwCheckDescription',
                defaultMessage:
                    "A Right to Work (RTW) Check verifies an individual's right to work status in Australia, either by; confirming they are an Australian citizen, or running a visa check through the Department of Home Affairs' Visa Entitlement Verification Online (VEVO) service. The latter includes a search of the VEVO database for visa details pertaining to the candidate being screened, matching database results to candidate documentation provided. A RTW Check result will include the type of visa the individual holds, the visa grant and expiry date (if applicable), and any work conditions or restrictions that may apply, flagging discrepancies where necessary.",
            }),
            estimatedTurnaroundTime: turnaroundTime(
                ONE_WEEK,
                intl.formatMessage({
                    id: 'common.timeDependsOnApplicant',
                    defaultMessage: 'applicant dependent (1 - 7 business days)',
                })
            ),
        },
        [CHECK_REQUEST.UK_BASIC_DBS_CHECK]: {
            title: intl.formatMessage({
                id: '3f746.MicroServiceContent.ukBasicDbsCheckTitle',
                defaultMessage: 'Basic DBS - For Employment in England & Wales',
            }),
            description: intl.formatMessage({
                id: '3f746.MicroServiceContent.ukBasicDbsCheckDescription',
                defaultMessage:
                    'A Basic DBS check is a UK criminal record check and can be obtained for a person with address history in the UK, who will be working in England and Wales. Basic DBS can include a digital identity verification service. The issued Basic Disclosure certificate will contain details of convictions and conditional cautions considered to be ‘unspent’ under the terms of the Rehabilitation of Offenders Act 1974. ',
            }),
            estimatedTurnaroundTime: turnaroundTime(
                TWO_DAYS,
                intl.formatMessage({
                    id: '3f746.MicroServiceContent.ukBasicDbsCheckTurnaroundTime',
                    defaultMessage: '24 - 48 hours',
                })
            ),
        },
        [CHECK_REQUEST.UK_BASIC_DS_CHECK]: {
            title: !options?.teamIsUKorGB
                ? intl.formatMessage({
                      id: '3f746.MicroServiceContent.ukBasicDsCheckTitleIntl',
                      defaultMessage: 'UK Criminal Record Check',
                  })
                : intl.formatMessage({
                      id: '3f746.MicroServiceContent.ukBasicDsCheckTitle',
                      defaultMessage:
                          'Basic Disclosure Scotland - For Employment in Scotland or Countries Outside of the UK',
                  }),
            description: !options?.teamIsUKorGB
                ? intl.formatMessage(
                      {
                          id: '3f746.MicroServiceContent.ukBasicDsCheckDescriptionIntl',
                          defaultMessage:
                              '{note} A UK criminal record check can be obtained for a person living/working in the UK or for somebody who has previously lived/worked in the UK. This check includes a digital identity verification service and the report will only contain details of any unspent convictions the candidate has.',
                      },
                      {
                          note: (
                              <>
                                  <Note>
                                      {intl.formatMessage({
                                          id: '3f746.MicroServiceContent.ukBasicDsCheckNoteIntl',
                                          defaultMessage:
                                              'Candidate requires a current or previous UK address. For Northern Ireland candidates, please order an International Criminal Check.',
                                      })}
                                  </Note>
                                  <br />
                                  <br />
                              </>
                          ),
                      }
                  )
                : intl.formatMessage({
                      id: '3f746.MicroServiceContent.ukBasicDsCheckDescription',
                      defaultMessage:
                          'A Basic Disclosure Scotland (Basic DS) check is a UK criminal record check and can be obtained for a person with address history in the UK, who will be working in Scotland or outside the UK. This is the criminal check that should be ordered for any candidates applying for employment outside the UK. Basic DS may include a digital identity verification service. Reported results will contain details of any ‘unspent’ convictions the person has.',
                  }),
            estimatedTurnaroundTime: turnaroundTime(
                THREE_WEEKS,
                intl.formatMessage({
                    id: '3f746.MicroServiceContent.ukBasicDsCheckTurnaroundTime',
                    defaultMessage: '1 - 3 weeks',
                })
            ),
        },
        [CHECK_REQUEST.UK_RIGHT_TO_WORK_CHECK]: {
            title: intl.formatMessage({
                id: '3f746.MicroServiceContent.ukRightToWorkCheckTitle',
                defaultMessage: 'UK Right to Work check',
            }),
            description: intl.formatMessage({
                id: '3f746.MicroServiceContent.ukRighToWorkCheckDescription',
                defaultMessage:
                    'A UK Right to Work check enables employers to verify an individual’s right to work in the UK. This covers individuals with a UK or Irish passport (or Irish passport card). Reported results  will include a copy of the applicant’s identification document and their photograph allowing the employer to check that the photograph on the online Right to Work check is of the individual presenting themselves for work.',
            }),
            estimatedTurnaroundTime: instant,
        },
        [CHECK_REQUEST.INSTANT_VERIFY_EMPLOYMENT]: {
            title: intl.formatMessage({
                id: '3f746.MicroServiceContent.instantVerifyEmploymentTitle',
                defaultMessage: 'Instant Employment Verification',
            }),
            description: intl.formatMessage({
                id: '3f746.MicroServiceContent.instantVerifyEmploymentDescription',
                defaultMessage:
                    'Certn uses credible public sources to instantly verify the employment of an individual with no manual intervention. This service will attempt to verify the disclosed employment history of an individual including their company, position, and dates of employment when available. This is NOT verified directly with the institution(s) listed and is not guaranteed to deliver a result.',
            }),
            estimatedTurnaroundTime: instant,
        },
        [CHECK_REQUEST.INSTANT_VERIFY_CREDENTIAL]: {
            title: intl.formatMessage({
                id: '3f746.MicroServiceContent.instantVerifyCredentialsTitle',
                defaultMessage: 'Instant Credential Verification',
            }),
            description: intl.formatMessage({
                id: '3f746.MicroServiceContent.instantVerifyCredentialsDescription',
                defaultMessage:
                    'Certn uses credible public sources to instantly verify the credentials of an individual with no manual intervention. This service will attempt to verify the disclosed credentials of an individual including their credential, and key dates. This is NOT verified directly with the institution(s) listed and is not guaranteed to deliver a result.',
            }),
            estimatedTurnaroundTime: instant,
        },
        [CHECK_REQUEST.INSTANT_VERIFY_EDUCATION]: {
            title: intl.formatMessage({
                id: '3f746.MicroServiceContent.instantVerifyEducationTitle',
                defaultMessage: 'Instant Education Verification',
            }),
            description: intl.formatMessage({
                id: '3f746.MicroServiceContent.instantVerifyEducationDescription',
                defaultMessage:
                    'Certn uses credible public sources to instantly verify the education of an individual with no manual intervention. This service will attempt to verify the disclosed education of an individual including their degree(s) received, course of study, and dates of attendance when available. This is NOT verified directly with the institution(s) listed and is not guaranteed to deliver a result.',
            }),
            estimatedTurnaroundTime: instant,
        },
        [CHECK_REQUEST.SOQUIJ]: {
            title: intl.formatMessage({
                id: '3f746.MicroServiceContent.SOQUIJTitle',
                defaultMessage: 'Quebec Record Check (SOQUIJ)',
            }),
            description: intl.formatMessage({
                id: '3f746.MicroServiceContent.SOQUIJDescription',
                defaultMessage:
                    'The Quebec Court Record Check (SOQUIJ) search provides access to criminal record information from penal court records for cases filed in Quebec courthouses.',
            }),
            estimatedTurnaroundTime: instant,
        },
        [CHECK_REQUEST.VACCINATION_CHECK]: {
            title: intl.formatMessage({
                id: '3f746.MicroServiceContent.vaccinationTitle',
                defaultMessage: 'COVID-19 Vaccination Status',
            }),
            description: intl.formatMessage({
                id: '3f746.MicroServiceContent.vaccinationDescription',
                defaultMessage:
                    'This check asks the applicant to declare their COVID-19 vaccination status, and if applicable, to upload proof of vaccination for verification purposes.',
            }),
            estimatedTurnaroundTime: instant,
        },
    };

    // Error handling feedback in UI for services added with missing content
    if (requestFlag && !Object.keys(content).includes(requestFlag)) {
        console.error(`service key "${requestFlag} not found in content generator`);
        return {
            title: null,
            description: intl.formatMessage({
                id: '3f746.MicroServiceContent.serviceNotFoundDescription',
                defaultMessage: 'Check needs content added to MicroServiceContent',
            }),
        };
    }

    return content[requestFlag] || content;
}

// Content used to generate check data, ETT are referenced in seconds
function useCheckContent(requestFlag) {
    const options = useSelector(getOptions);
    return getCheckContent(requestFlag, options);
}

export default useCheckContent;

export const selectBackwardsCompatibleRequestAndTitles = createSelector(
    getUserMode,
    getTeamCountry,
    getOptions,
    getPotatoCheckTitles,
    (userMode, teamCountry, options, potatoTitles) => {
        const modeAndCountry = `${userMode || 'HR'}_${teamCountry}`;
        const services = ACTIVE_SERVICES[modeAndCountry] || [];
        const checkTitles = getBackwardsCompatibleRequestAndTitles(services, options);
        return potatoTitles ? checkTitles.concat(potatoTitles) : checkTitles;
    }
);
