import * as React from 'react';
import { useSelector } from 'react-redux';
import { Modal, Button, Row, Col } from 'antd';
import { defineMessages, useIntl } from 'react-intl';
import Title from 'certn-ui/Title';
import styled from 'styled-components/macro';
import { getApplicant } from 'views/welcome/WelcomeSelectors';
import { InfoCircleOutlined } from '@ant-design/icons';

const ModalLink = styled.span`
    color: ${(props) => props.theme.color.certnGray400};
    text-decoration: underline;
    cursor: pointer;
`;

const StyledInfoCircleOutlined = styled(InfoCircleOutlined)`
    font-size: 22px;
    && {
        color: ${(props) => props.theme.color.primary};
    }
`;

const Heading = styled.h6`
    font-size: ${(props) => props.theme.font.xs};
`;

const Description = styled.p`
    color: ${(props) => props.theme.color.certnGray500};
    font-size: ${(props) => props.theme.font.xs};
`;

const verificationMessages = defineMessages({
    dateRange: {
        id: 'welcome.Employers.employmentHistoryDateRangeHelperText',
        defaultMessage:
            'Please provide at least {minEmploymentHistoryYears} years of employment history, and have permission to contact at least {minVerifications} of your employers for <modal>verification</modal>',
    },
    individually: {
        id: 'welcome.Employers.employmentHistoryIndividuallyHelperText',
        defaultMessage:
            'Please provide at least {minVerifications} employers, and have permission to contact at least {minVerifications} of your employers for <modal>verification</modal>.',
    },
});

const getVerificationMessage = (isDateRange) => verificationMessages[isDateRange ? 'dateRange' : 'individually'];

const useEmploymentRequirements = () => {
    const {
        employment_verification_years,
        employment_verification_min,
        employment_verification_years_or_individually,
    } = useSelector(getApplicant);

    const minVerifications = employment_verification_min;
    return {
        minVerifications,
        yearsRequired: employment_verification_years,
        isEmploymentVerificationDateRange: employment_verification_years_or_individually === 'YEARS',
    };
};

const EmploymentHistoryHelperText = () => {
    const intl = useIntl();
    const { minVerifications, yearsRequired, isEmploymentVerificationDateRange } = useEmploymentRequirements();
    const [isModalVisible, setIsModalVisible] = React.useState(false);

    const message = getVerificationMessage(isEmploymentVerificationDateRange);

    let msgOptions = {
        minVerifications,
        modal: (str) => (
            <ModalLink data-testid="verification-modal" onClick={() => setIsModalVisible(true)}>
                {str}
            </ModalLink>
        ),
    };

    // If not 'INDIVIDUALLY', it's 'YEARS'.
    if (isEmploymentVerificationDateRange) {
        msgOptions = { ...msgOptions, minEmploymentHistoryYears: yearsRequired };
    }

    return (
        <>
            <Title size="xs" color="certnGray400">
                {intl.formatMessage(message, msgOptions)}
            </Title>
            <Modal
                title={intl.formatMessage({
                    id: 'common.verification',
                    defaultMessage: 'Verification',
                })}
                closable={false}
                visible={isModalVisible}
                footer={[
                    <Button type="primary" css="text-transform: uppercase;" onClick={() => setIsModalVisible(false)}>
                        {intl.formatMessage({
                            id: 'common.ok',
                            defaultMessage: 'OK',
                        })}
                    </Button>,
                ]}
            >
                <Row wrap={false}>
                    <Col flex="40px">
                        <StyledInfoCircleOutlined />
                    </Col>
                    <Col flex="auto">
                        <Heading>
                            {intl.formatMessage({
                                id: 'welcome.employment.whatIsVerification',
                                defaultMessage: 'What is a verification?',
                            })}
                        </Heading>
                        <Description>
                            {intl.formatMessage({
                                id: 'welcome.employment.verificationDefinition',
                                defaultMessage:
                                    'Certn will contact the HR department of your previous and current employers (if applicable) to verify your employment dates and position with their records.',
                            })}
                        </Description>
                    </Col>
                </Row>
            </Modal>
        </>
    );
};

export default EmploymentHistoryHelperText;
