// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { message, Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import { intl } from 'components/GlobalProvider';
import { isEmpty } from 'lodash';

// Components
import { Fade } from 'certn-ui/Animate';
import { Box } from 'certn-ui/Layout';
import Loader from 'certn-ui/Loader';
import Titlebar from 'certn-ui/Titlebar';
import { LimeBanner } from 'views/manager/components';
import { AdvancedFiltering } from 'views/manager/components/AdvancedFiltering';
import { Table } from './components';

// Actions & Selectors
import { getUserMode, getUser, getTeamCountry } from 'base/BaseSelectors';
import { baseConfig, clientAdjudicationConfig } from './tableColumnsConfig.js';

import {
    resetListAndFetch,
    resetListAndFilter,
    fetchApplications,
    setFilterBy,
    setApplicationsView,
} from 'views/manager/views/hr/views/applications/ApplicationsActions';
import { setFilters } from 'views/manager/sharedActions/ApplicationActions';
import {
    getIsFetching,
    getApplication,
    getApplications,
    getFilterBy,
    getSearchBy,
    getApplicationsView,
    getNumApplications,
    getTotalNumApplications,
    getSentApplication,
    getIsFetchingApplications,
    getError,
    getFilterQuery,
    getFilters,
} from 'views/manager/views/hr/views/applications/ApplicationsSelectors';

const mapStateToProps = (state) => ({
    userMode: getUserMode(state),
    teamCountry: getTeamCountry(state),
    isFetching: getIsFetching(state),
    fetchingError: getError(state),
    application: getApplication(state),
    applications: getApplications(state),
    searchBy: getSearchBy(state),
    filterBy: getFilterBy(state),
    applicationsView: getApplicationsView(state),
    numApplications: getNumApplications(state),
    totalNumApplications: getTotalNumApplications(state),
    sentApplication: getSentApplication(state),
    user: getUser(state),
    isFetchingApplications: getIsFetchingApplications(state),
    filterQuery: getFilterQuery(state),
    filters: getFilters(state),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            resetListAndFetch,
            resetListAndFilter,
            fetchApplications,
            setFilterBy,
            setApplicationsView,
            setFilters,
        },
        dispatch
    );

const propTypes = {
    // Redux Actions
    resetListAndFetch: PropTypes.func.isRequired,
    resetListAndFilter: PropTypes.func.isRequired,
    fetchApplications: PropTypes.func.isRequired,
    setFilterBy: PropTypes.func.isRequired,
    setApplicationsView: PropTypes.func.isRequired,
    // Redux State
    teamCountry: PropTypes.string.isRequired,
    isFetching: PropTypes.bool.isRequired,
    fetchingError: PropTypes.bool.isRequired,
    applicationsView: PropTypes.string.isRequired,
    application: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    applications: PropTypes.array.isRequired,
    filterBy: PropTypes.object.isRequired,
    searchBy: PropTypes.string,
    numApplications: PropTypes.number,
    totalNumApplications: PropTypes.number,
    user: PropTypes.object.isRequired,
    sentApplication: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

const defaultProps = {
    searchBy: null,
    numApplications: null,
    totalNumApplications: null,
    sentApplication: [],
};

class ApplicationsList extends React.Component {
    state = {
        filterIndex: 0,
        filterIndexForAA: 0,
    };

    constructor(props) {
        super(props);
        const { location } = props;
        // Legacy code, refactoring but no time to rewrite
        if (location && location.search) {
            const parsed = queryString.parse(props.location.search);
            if (parsed.listing_id) {
                // In case you're coming from Listings page and filtering List by listing_id
                props.setFilterBy({ application__listing_id: parsed.listing_id });
            } else if (parsed.application_created) {
                props.resetListAndFetch();
                message.success(
                    intl.formatMessage({
                        id: '1dae4.ApplicationsList.success1',
                        defaultMessage: 'Application was successfully created!',
                    })
                );
            } else {
                // this.props.fetchApplications();
            }
        } else {
            // this.props.fetchApplications();
        }
    }

    componentDidUpdate(prevProps) {
        const currentTab = prevProps.applicationsView;
        const newTab = this.props.applicationsView;
        if (currentTab !== newTab) {
            const tabsSet = new Set([currentTab, newTab]);
            if (tabsSet.has('Needs Review')) {
                // clear filters if switching TO or FROM "Needs Review" tab
                this.setState((prevState) => ({
                    filterIndex: prevState.filterIndex + 1,
                }));
            } else if (tabsSet.has('Adverse Action')) {
                // clear Adverse Action Status filter if switching TO or FROM "Adverse Action" tab
                this.setState((prevState) => ({
                    filterIndexForAA: prevState.filterIndexForAA + 1,
                }));
            } else if (tabsSet.size === 2) {
                this.props.fetchApplications(1);
            }
        }
    }

    handleFilterChange = (filters, query) => {
        this.props.setFilters({
            filters,
            query,
        });
        this.props.fetchApplications(1);
    };

    buildData = (applications) => applications.map((applicant, index) => ({ ...applicant, key: index }));

    hasFetchingError = () => !isEmpty(this.props.fetchingError);

    render() {
        const data = this.props.applications && this.buildData(this.props.applications);
        const { user, numApplications } = this.props;
        let config = baseConfig;
        if (this.props.applicationsView === 'Needs Review') {
            config = clientAdjudicationConfig;
        }

        const hasNoMatchingApplications = !this.props.isFetching && numApplications === 0 && this.props.filterQuery;

        return (
            <>
                <LimeBanner
                    isTeamBlockedWithApplications={user.team.applicant_invites_blocked && numApplications > 0}
                />
                <Titlebar
                    title={intl.formatMessage({
                        id: 'common.applications',
                        defaultMessage: 'Applications',
                    })}
                />
                <Fade>
                    <>
                        <Table.Nav
                            setApplicationsView={this.props.setApplicationsView}
                            applicationsView={this.props.applicationsView}
                        />
                        <AdvancedFiltering
                            filters={this.props.filters}
                            resetIndex={this.state.filterIndex}
                            resetIndexForAA={this.state.filterIndexForAA}
                            onChange={this.handleFilterChange}
                        />
                        {this.props.isFetchingApplications && data && data.length === 0 ? (
                            <Loader />
                        ) : (
                            <>
                                {(hasNoMatchingApplications || this.hasFetchingError()) && (
                                    <Box center border>
                                        <Titlebar
                                            size="lrg"
                                            title={
                                                <FormattedMessage
                                                    id="1dae4.ApplicationsList.noMatch"
                                                    defaultMessage="No matching applications"
                                                />
                                            }
                                            center
                                            subtitle={
                                                <FormattedMessage
                                                    id="1dae4.ApplicationsList.noMatchSubtitle"
                                                    defaultMessage="Clear your current filters to see all the applications"
                                                />
                                            }
                                        />
                                    </Box>
                                )}
                                {!this.props.isFetching &&
                                    this.props.totalNumApplications > 0 &&
                                    numApplications === 0 &&
                                    !this.props.filterQuery &&
                                    !this.props.searchBy && (
                                        <Box center border>
                                            <Titlebar
                                                size="lrg"
                                                title={
                                                    <FormattedMessage
                                                        id="1dae4.ApplicationsList.noApplicants"
                                                        defaultMessage="There are no applicants in this category"
                                                    />
                                                }
                                                center
                                                subtitle={
                                                    <FormattedMessage
                                                        id="1dae4.ApplicationsList.noApplicantsSubtitle"
                                                        defaultMessage="You have not added any applicants to this category yet. Click the button below to go view all applicants."
                                                    />
                                                }
                                            >
                                                <Button
                                                    data-testid="applications_list_view_all_applicant"
                                                    size="large"
                                                    type="primary"
                                                    onClick={() => this.props.setApplicationsView('All')}
                                                >
                                                    <FormattedMessage
                                                        id="1dae4.ApplicationsList.viewAllApplicants"
                                                        defaultMessage="VIEW ALL APPLICANTS"
                                                    />
                                                </Button>
                                            </Titlebar>
                                        </Box>
                                    )}
                                {!this.props.isFetching &&
                                    numApplications === 0 &&
                                    this.props.totalNumApplications === 0 &&
                                    !this.props.filterQuery &&
                                    !this.props.searchBy && (
                                        <Box center border>
                                            <Titlebar
                                                size="lrg"
                                                title={
                                                    <FormattedMessage
                                                        id="1dae4.ApplicationsList.screenApplicants"
                                                        defaultMessage="Screen your first applicant"
                                                    />
                                                }
                                                center
                                                subtitle={
                                                    <FormattedMessage
                                                        id="1dae4.ApplicationsList.screenApplicantsSubtitle"
                                                        defaultMessage="Screen your first applicant so you can quickly generate a report and find out details about a prospect."
                                                    />
                                                }
                                            >
                                                <Button
                                                    data-testid="applications_list_screen_applicant"
                                                    size="large"
                                                    type="primary"
                                                    onClick={() => this.props.history.push('/hr/screen')}
                                                >
                                                    <FormattedMessage
                                                        id="1dae4.ApplicationsList.screenApplicant"
                                                        defaultMessage="SCREEN APPLICANT"
                                                    />
                                                </Button>
                                            </Titlebar>
                                        </Box>
                                    )}
                                {numApplications > 0 && !this.hasFetchingError() && (
                                    <Table data={data} fetchingError={this.hasFetchingError()} config={config} />
                                )}
                            </>
                        )}
                    </>
                </Fade>
            </>
        );
    }
}

ApplicationsList.propTypes = propTypes;
ApplicationsList.defaultProps = defaultProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ApplicationsList));
