import { InfoCircleOutlined } from '@ant-design/icons';
import { Checkbox, DatePicker, Form, Input, Popover, Select } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { RangePickerProps } from 'antd/lib/date-picker';
import { FormInstance } from 'antd/lib/form';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { Regex } from 'modules';
import moment from 'moment';
import { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { flexCenter } from 'styles/Mixins';
import { ValidationRule } from 'types/types';
import { FieldToggleContainer } from '../../basics/components/styled';
import { FieldHeading } from '../styles';
import { UkCheckType } from '../types';
import TitleField from './TitleField';
import { getPrepopulatedFields, getEnhancedIdentity } from 'views/welcome/WelcomeSelectors';

const Delete = styled.a`
    float: right;
    text-decoration: underline;
    font-size: 14px;
    color: #c3001a;
    padding: 0;
    background: none;
    border: none;
`;

const DatePickerWrapper = styled.div`
    display: flex;
    flex-direction: row;

    .ant-row {
        width: 100%;
    }

    .ant-form-item-control-input-content {
        display: flex;
    }

    .ant-picker {
        flex: 1;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .ant-input-group-addon {
        ${flexCenter};
        width: 34px;
        height: 40px;
        border-color: #d9d9d9;
    }
`;

interface Props {
    index: number;
    fieldData: FormListFieldData;
    onDelete: (name: number | number[]) => void;
    form: FormInstance;
    toggleHelpOpen: () => void;
    showAdditionalFields: {
        showDateFields: boolean;
        showTitleField: boolean;
        showMiddleNameField: boolean;
        showAllowAdditionalNamesCheckbox: boolean;
        showAddPreviousNamesButton: boolean;
        showPreviousNameTypesDropdown: boolean;
    };
    dateFormat: string;
    typeOfUkCheck: UkCheckType;
    toggleShowPreviousNamesButtonChange: (value: boolean) => void;
}

const PreviousNameField: React.FC<Props> = ({
    index,
    fieldData,
    onDelete,
    form,
    showAdditionalFields,
    dateFormat,
    typeOfUkCheck,
    toggleHelpOpen,
    toggleShowPreviousNamesButtonChange,
}) => {
    const intl = useIntl();
    const prepopulatedFields = useSelector(getPrepopulatedFields);
    const { key, name, ...restField } = fieldData;
    const applicantNames = form.getFieldValue('applicantNames');
    const applicantName = applicantNames[name];
    const enhancedID = useSelector(getEnhancedIdentity);

    const ValidationRules: Record<string, Record<string, ValidationRule>> = {
        FIRST_NAME: {
            DEFAULT: {
                pattern: Regex.name,
                message: intl.formatMessage({
                    id: 'error.validation.letters',
                    defaultMessage: 'Please only use letters',
                }),
            },
            UK_BASIC_DBS_CHECK: {
                pattern: Regex.ukDbsName,
                message: intl.formatMessage({
                    id: 'error.validation.lettersAndApostrophesOrHyphensOrSpacesInMiddle',
                    defaultMessage:
                        'Please only use letters. Apostrophes, hyphens or spaces can appear in the middle of the name.',
                }),
            },
            UK_BASIC_DS_CHECK: {
                pattern: Regex.ukDsName,
                message: intl.formatMessage({
                    id: 'error.validation.lettersNumbersSpacesHyphensApostrophesPeriods',
                    defaultMessage: 'Please only use letters, numbers, spaces, hyphens, apostrophes or periods',
                }),
            },
        },
        MIDDLE_NAME: {
            DEFAULT: {
                pattern: Regex.middleName,
                message: intl.formatMessage({
                    id: 'error.validation.lettersAndApostrophesOrHyphens',
                    defaultMessage: 'Please only use letters, apostrophes or hyphens',
                }),
            },
            UK_BASIC_DBS_CHECK: {
                pattern: Regex.ukDbsName,
                message: intl.formatMessage({
                    id: 'error.validation.lettersAndApostrophesOrHyphensOrSpacesInMiddle',
                    defaultMessage:
                        'Please only use letters. Apostrophes, hyphens or spaces can appear in the middle of the name.',
                }),
            },
        },
        LAST_NAME: {
            DEFAULT: {
                pattern: Regex.name,
                message: intl.formatMessage({
                    id: 'error.validation.letters',
                    defaultMessage: 'Please only use letters',
                }),
            },
            UK_BASIC_DBS_CHECK: {
                pattern: Regex.ukDbsName,
                message: intl.formatMessage({
                    id: 'error.validation.lettersAndApostrophesOrHyphensOrSpacesInMiddle',
                    defaultMessage:
                        'Please only use letters. Apostrophes, hyphens or spaces can appear in the middle of the name.',
                }),
            },
            UK_BASIC_DS_CHECK: {
                pattern: Regex.ukDsName,
                message: intl.formatMessage({
                    id: 'error.validation.lettersNumbersSpacesHyphensApostrophesPeriods',
                    defaultMessage: 'Please only use letters, numbers, spaces, hyphens, apostrophes or periods',
                }),
            },
        },
    };

    const [noMiddleName, setNoMiddleName] = useState(
        !applicantName.middle_name && applicantName.first_name && applicantName.last_name
    );
    const [previousNameCheckboxValue, setPreviousameCheckboxValue] = useState(applicantNames.length > 1);
    toggleShowPreviousNamesButtonChange(previousNameCheckboxValue);

    const otherTitle = applicantName.other_title;

    const handleSetNoMiddleName = useCallback(
        (event: CheckboxChangeEvent) => {
            setNoMiddleName(event.target.checked);

            const applicantnames = form.getFieldValue('applicantNames');

            if (event.target.checked) {
                applicantnames[name].middle_name = '';
                form.setFieldsValue({
                    applicantnames,
                });
            }
        },
        [form, name]
    );

    const handleShowPreviousNamesChange = useCallback(
        (e: CheckboxChangeEvent) => {
            setPreviousameCheckboxValue(e.target.checked);
            toggleShowPreviousNamesButtonChange(e.target.checked);
        },
        [toggleShowPreviousNamesButtonChange]
    );

    const disableFutureDates: RangePickerProps['disabledDate'] = (current) =>
        current && current > moment().endOf('day');

    const MiddleNameToggleComponent = useCallback(
        () => (
            <FieldToggleContainer placeAbove={typeOfUkCheck === 'UK_BASIC_DBS_CHECK'}>
                <Checkbox
                    id={`applicantName_${index}_middle_name_checkbox`}
                    onChange={handleSetNoMiddleName}
                    checked={noMiddleName}
                >
                    <FormattedMessage id="welcome.Basics.noMiddleName" defaultMessage="I don't have a middle name" />
                </Checkbox>
            </FieldToggleContainer>
        ),
        [handleSetNoMiddleName, index, noMiddleName, typeOfUkCheck]
    );

    const MiddleNameFormItem = useCallback(
        () => (
            <Form.Item
                {...restField}
                name={[name, 'middle_name']}
                initialValue={enhancedID?.middle_name}
                rules={[
                    typeOfUkCheck === 'UK_BASIC_DBS_CHECK'
                        ? ValidationRules.MIDDLE_NAME.UK_BASIC_DBS_CHECK
                        : ValidationRules.MIDDLE_NAME.DEFAULT,
                    {
                        required: !noMiddleName,
                        message: intl.formatMessage({
                            id: 'error.validation.notBlank',
                            defaultMessage: 'Please do not leave blank',
                        }),
                    },
                ]}
            >
                <Input
                    disabled={noMiddleName || (index === 0 && prepopulatedFields.includes('middle_name'))}
                    maxLength={typeOfUkCheck === 'UK_BASIC_DBS_CHECK' ? 60 : undefined}
                    size="large"
                    placeholder={intl.formatMessage({
                        id: 'welcome.Basics.middleName',
                        defaultMessage: 'Middle Name(s)',
                    })}
                    addonBefore={
                        <Popover
                            content={intl.formatMessage({
                                id: 'welcome.Basics.middleName',
                                defaultMessage: 'Middle Name(s)',
                            })}
                        >
                            <InfoCircleOutlined />
                        </Popover>
                    }
                />
            </Form.Item>
        ),
        [
            ValidationRules.MIDDLE_NAME,
            index,
            intl,
            name,
            noMiddleName,
            prepopulatedFields,
            restField,
            typeOfUkCheck,
            enhancedID,
        ]
    );

    return (
        <>
            <FieldHeading>
                {index < 1
                    ? intl.formatMessage({ id: 'welcome.Basics.personalDetails', defaultMessage: 'Personal Details' })
                    : `${intl.formatMessage({
                          id: 'welcome.Basics.previousName',
                          defaultMessage: 'Previous Name',
                      })} ${index}`}
                {index >= 1 && (
                    <Delete onClick={() => onDelete(name)} data-testid="delete_previous_name_button">
                        <FormattedMessage id="welcome.Basics.delete" defaultMessage="Delete" />
                    </Delete>
                )}
            </FieldHeading>
            {showAdditionalFields.showTitleField && index < 1 ? (
                <TitleField fieldData={fieldData} titleFieldType={typeOfUkCheck} otherTitle={otherTitle} />
            ) : undefined}
            <Form.Item
                {...restField}
                name={[name, 'first_name']}
                initialValue={enhancedID?.first_name}
                rules={[
                    typeOfUkCheck === 'UK_BASIC_DS_CHECK'
                        ? ValidationRules.FIRST_NAME.UK_BASIC_DS_CHECK
                        : typeOfUkCheck === 'UK_BASIC_DBS_CHECK'
                        ? ValidationRules.FIRST_NAME.UK_BASIC_DBS_CHECK
                        : ValidationRules.FIRST_NAME.DEFAULT,
                    {
                        required: true,
                        message: intl.formatMessage({
                            id: 'error.validation.notBlank',
                            defaultMessage: 'Please do not leave blank',
                        }),
                    },
                ]}
            >
                <Input
                    maxLength={
                        typeOfUkCheck === 'UK_BASIC_DBS_CHECK'
                            ? 60
                            : typeOfUkCheck === 'UK_BASIC_DS_CHECK'
                            ? 64
                            : undefined
                    }
                    autoFocus={index === 0 && !showAdditionalFields.showTitleField}
                    size="large"
                    placeholder={
                        typeOfUkCheck === 'UK_BASIC_DS_CHECK'
                            ? intl.formatMessage({
                                  id: 'welcome.general.legalForenames',
                                  defaultMessage: 'Legal Forename(s)',
                              })
                            : intl.formatMessage({
                                  id: 'welcome.general.legalFirstName',
                                  defaultMessage: 'Legal First Name',
                              })
                    }
                    addonBefore={
                        <Popover
                            content={
                                typeOfUkCheck === 'UK_BASIC_DS_CHECK'
                                    ? intl.formatMessage({
                                          id: 'welcome.general.legalForenames',
                                          defaultMessage: 'Legal Forename(s)',
                                      })
                                    : intl.formatMessage({
                                          id: 'welcome.general.legalFirstName',
                                          defaultMessage: 'Legal First Name',
                                      })
                            }
                        >
                            <InfoCircleOutlined />
                        </Popover>
                    }
                    disabled={index === 0 && prepopulatedFields.includes('first_name')}
                />
            </Form.Item>
            {showAdditionalFields.showMiddleNameField ? (
                <>
                    {typeOfUkCheck === 'UK_BASIC_DBS_CHECK' && <MiddleNameToggleComponent />}
                    <MiddleNameFormItem />
                    {typeOfUkCheck !== 'UK_BASIC_DBS_CHECK' && <MiddleNameToggleComponent />}
                </>
            ) : undefined}

            <Form.Item
                {...restField}
                name={[name, 'last_name']}
                initialValue={enhancedID?.last_name}
                rules={[
                    typeOfUkCheck === 'UK_BASIC_DS_CHECK'
                        ? ValidationRules.LAST_NAME.UK_BASIC_DS_CHECK
                        : typeOfUkCheck === 'UK_BASIC_DBS_CHECK'
                        ? ValidationRules.LAST_NAME.UK_BASIC_DBS_CHECK
                        : ValidationRules.LAST_NAME.DEFAULT,
                    {
                        required: true,
                        message: intl.formatMessage({
                            id: 'error.validation.notBlank',
                            defaultMessage: 'Please do not leave blank',
                        }),
                    },
                ]}
            >
                <Input
                    maxLength={
                        typeOfUkCheck === 'UK_BASIC_DBS_CHECK'
                            ? 50
                            : typeOfUkCheck === 'UK_BASIC_DS_CHECK'
                            ? 32
                            : undefined
                    }
                    size="large"
                    data-testid="last_name"
                    placeholder={intl.formatMessage({
                        id: 'welcome.general.legalLastName',
                        defaultMessage: 'Legal Last Name',
                    })}
                    addonBefore={
                        <Popover
                            content={intl.formatMessage({
                                id: 'welcome.general.legalLastName',
                                defaultMessage: 'Legal Last Name',
                            })}
                        >
                            <InfoCircleOutlined />
                        </Popover>
                    }
                    disabled={index === 0 && prepopulatedFields.includes('last_name')}
                />
            </Form.Item>

            {index >= 1 ? (
                <>
                    {showAdditionalFields.showPreviousNameTypesDropdown ? (
                        <Form.Item
                            {...restField}
                            name={[name, 'type']}
                            rules={[
                                {
                                    required: true,
                                    message: intl.formatMessage({
                                        id: 'error.validation.notBlank',
                                        defaultMessage: 'Please do not leave blank',
                                    }),
                                },
                            ]}
                        >
                            <Select
                                placeholder={intl.formatMessage({
                                    id: 'welcome.Basics.nameType',
                                    defaultMessage: 'Name Type',
                                })}
                                data-testid="name-type-select"
                                size="large"
                            >
                                <Select.Option value="PRIMARY" disabled>
                                    <FormattedMessage id="welcome.Basics.nameType.primary" defaultMessage="Primary" />
                                </Select.Option>
                                <Select.Option value="FORMER">
                                    <FormattedMessage id="welcome.Basics.nameType.former" defaultMessage="Former" />
                                </Select.Option>
                                <Select.Option value="ALIAS">
                                    <FormattedMessage id="welcome.Basics.nameType.alias" defaultMessage="Alias" />
                                </Select.Option>
                                <Select.Option value="MAIDEN">
                                    <FormattedMessage id="welcome.Basics.nameType.maiden" defaultMessage="Maiden" />
                                </Select.Option>
                            </Select>
                        </Form.Item>
                    ) : undefined}

                    {showAdditionalFields.showDateFields ? (
                        <DatePickerWrapper>
                            <span className="ant-input-group-addon">
                                <Popover
                                    content={intl.formatMessage({
                                        id: 'form.label.endDate',
                                        defaultMessage: 'End Date',
                                    })}
                                >
                                    <InfoCircleOutlined />
                                </Popover>
                            </span>
                            <Form.Item
                                {...restField}
                                name={[name, 'name_end_date']}
                                rules={[
                                    {
                                        required: true,
                                        message: intl.formatMessage({
                                            id: 'error.validation.notBlank',
                                            defaultMessage: 'Please do not leave blank',
                                        }),
                                    },
                                ]}
                            >
                                <DatePicker
                                    size="large"
                                    disabledDate={disableFutureDates}
                                    placeholder={`${intl.formatMessage({
                                        id: 'form.label.endDate',
                                        defaultMessage: 'End Date',
                                    })} (${dateFormat})`}
                                    format={dateFormat}
                                    data-testid="end_date_picker"
                                />
                            </Form.Item>
                        </DatePickerWrapper>
                    ) : undefined}
                </>
            ) : showAdditionalFields.showAllowAdditionalNamesCheckbox ? (
                <div style={{ display: 'flex', width: '100%', flexDirection: 'row', marginBottom: '24px' }}>
                    <Checkbox
                        onChange={handleShowPreviousNamesChange}
                        checked={previousNameCheckboxValue}
                        data-testid="previous_name_checkbox"
                    >
                        {intl.formatMessage({
                            id: 'welcome.Basics.previousNameQuestion',
                            defaultMessage: 'I have been known by another name',
                        })}{' '}
                    </Checkbox>

                    <a onClick={toggleHelpOpen} style={{ textDecoration: 'underline', color: 'black' }}>
                        <FormattedMessage id="welcome.Basics.whatsThis" defaultMessage="What's this?" />
                    </a>
                </div>
            ) : undefined}
        </>
    );
};

export default PreviousNameField;
