// Libraries
import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

// Components
import { RequiredText } from 'certn-ui';
import GenderSelect from 'views/welcome/components/Fields/GenderSelect';

// Actions & Selectors
import { getSelectedCheckKeys } from 'views/manager/features/ScreenApplicant/ScreenApplicantSelectors';

// Modules
import isVisibleOrRequired from './isVisibleOrRequired';

// Constants
import { RCMP_REQUESTS } from 'views/manager/features/ScreenApplicant/ScreenApplicantConstants';

const Gender = () => {
    const intl = useIntl();
    const selectedServices = useSelector(getSelectedCheckKeys);
    const label = intl.formatMessage({ id: 'f5ca1.QuickScreen.sex', defaultMessage: 'Sex At Birth' });

    // Checks to display field and set required, true in brackets for all services
    const visibleList = RCMP_REQUESTS;

    if (!isVisibleOrRequired(visibleList, selectedServices)) return null;
    return (
        <>
            <RequiredText>{label}</RequiredText>
            <GenderSelect size="medium" includeGenderX />
        </>
    );
};

export default Gender;
