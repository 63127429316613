import { useIntl } from 'react-intl';
import { Form, Select } from 'antd';

interface PurposeOfCheck {
    setPurpose: (value: string) => void;
    isPurposeOfCheckDisabled: boolean;
}

export const PurposeOfCheck: React.FC<PurposeOfCheck> = ({ setPurpose, isPurposeOfCheckDisabled }) => {
    const intl = useIntl();

    return (
        <Form.Item
            labelCol={{ span: 24 }}
            id="purposeOfCheck"
            label={
                <label htmlFor="purposeOfCheck" style={{ color: '#819697' }}>
                    {intl.formatMessage({
                        id: '74e6c.AddOptions.acicPurposeOfCheckTitle',
                        defaultMessage: 'Purpose of check',
                    })}
                </label>
            }
            name="purpose_of_check"
            rules={[
                {
                    required: true,
                    message: intl.formatMessage({
                        id: 'error.validation.notBlankPurpose',
                        defaultMessage: 'Please provide the check purpose',
                    }),
                },
            ]}
        >
            <Select
                disabled={isPurposeOfCheckDisabled}
                onChange={(value: string) => setPurpose(value)}
                placeholder={intl.formatMessage({
                    id: '74e6c.AddOptions.PleaseSelect',
                    defaultMessage: 'Select purpose',
                })}
                options={[
                    {
                        value: 'EMPLOYMENT',
                        label: intl.formatMessage({
                            id: '74e6c.AddOptions.employment',
                            defaultMessage: 'Employment',
                        }),
                    },
                    {
                        value: 'LICENSE',
                        label: intl.formatMessage({
                            id: '74e6c.AddOptions.License',
                            defaultMessage: 'License',
                        }),
                    },
                    {
                        value: 'OTHER',
                        label: intl.formatMessage({
                            id: '74e6c.AddOptions.Other',
                            defaultMessage: 'Other (Position of Trust/Probity)',
                        }),
                    },
                ]}
            />
        </Form.Item>
    );
};
