// Libraries
import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Form } from '@ant-design/compatible';

// Modules
import { dateBefore18Yrs } from 'modules/validation';

// Actions & Selectors
import { getInformation, getPrepopulatedFields, getSettings } from 'views/welcome/WelcomeSelectors';

// Components
import DateInput from 'certn-form/DateInput';

const propTypes = {
    isUkCheck: PropTypes.bool,
};

const defaultProps = {
    isUkCheck: false,
};

const DateOfBirthInput = ({ getFieldDecorator, isUkCheck }) => {
    const intl = useIntl();
    const information = useSelector(getInformation);
    const { get_org_country: country } = useSelector(getSettings);
    const prepopulatedFields = useSelector(getPrepopulatedFields);
    const dateString = information?.date_of_birth;
    const placeholder = intl.formatMessage({
        id: 'common.dateOfBirth',
        defaultMessage: 'Date Of Birth',
    });

    return (
        <Form.Item>
            {getFieldDecorator('date_of_birth', {
                initialValue: dateString,
                validateTrigger: 'onChange',
                rules: [
                    !isUkCheck && {
                        validator: (rule, value, callback) => {
                            const result = dateBefore18Yrs(value);
                            callback(result);
                        },
                    },
                    {
                        required: true,
                        message: intl.formatMessage({
                            id: 'error.validation.notBlank',
                            defaultMessage: 'Please do not leave blank',
                        }),
                    },
                ],
            })(
                <DateInput
                    size="large"
                    placeholder={placeholder}
                    addonBeforeUsePlaceholder
                    // Disable future dates and ones over 120 years ago
                    isBirthDate
                    country={country}
                    disabled={prepopulatedFields.includes('date_of_birth')}
                />
            )}
        </Form.Item>
    );
};

DateOfBirthInput.propTypes = propTypes;
DateOfBirthInput.defaultProps = defaultProps;

DateOfBirthInput.propTypes = {
    ...propTypes,
    getFieldDecorator: PropTypes.func.isRequired,
};

export default DateOfBirthInput;
