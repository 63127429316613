// Libraries
import React from 'react';
import { get } from 'lodash';
import { FormattedMessage, injectIntl } from 'react-intl';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import { Input, Row, Col, Popover, Select } from 'antd';

// Components
import Title from 'certn-ui/Title';
import { ButtonLine } from 'certn-ui/Button';
import { AppearRight } from 'certn-ui/Animate';
import FormLayout from 'certn-form/FormLayout';
import { TextLink } from 'certn-ui/Text';
import { TrackNavigation } from 'views/welcome/components';
import { InternationalPhone } from 'components';

// Modules
import { Regex } from 'modules';

class ReferenceForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = { phone: '' };
    }

    componentDidMount = () => {
        const phone = get(this.props, [
            'addresses',
            this.props.currentAddressIndex,
            'address_reference',
            'phone_number',
            'number',
        ]);
        this.setState({ phone });
    };

    render() {
        const {
            form: { getFieldDecorator, validateFields, setFields },
            handleSubmit,
            currentAddressIndex,
            addresses,
            returnToBase,
            intl,
            errorStatus,
            applicantEmail,
            emailReferenceRequired,
            phoneReferenceRequired,
            referenceRequired = emailReferenceRequired || phoneReferenceRequired,
        } = this.props;
        const { phone } = this.state;
        return (
            <AppearRight>
                <Title>
                    {referenceRequired ? (
                        <FormattedMessage
                            id="welcome.Addresses.referenceTitleMandatory"
                            defaultMessage="Please provide a landlord reference."
                        />
                    ) : (
                        <FormattedMessage
                            id="welcome.Addresses.referenceTitle"
                            defaultMessage="Who would you like to use as a landlord reference?"
                        />
                    )}
                </Title>
                <Form layout="horizontal" onSubmit={(e) => handleSubmit(e, validateFields, setFields, true, phone)}>
                    <FormLayout>
                        <Form.Item hasFeedback>
                            {getFieldDecorator('landlords_first_name', {
                                initialValue: get(addresses, [currentAddressIndex, 'landlords_first_name']),
                                rules: [
                                    {
                                        pattern: Regex.name,
                                        message: intl.formatMessage({
                                            id: 'error.validation.name',
                                            defaultMessage: 'Please enter a valid name',
                                        }),
                                    },
                                    {
                                        required: true,
                                        message: intl.formatMessage({
                                            id: 'error.validation.notBlank',
                                            defaultMessage: 'Please do not leave blank',
                                        }),
                                    },
                                ],
                            })(
                                <Input
                                    size="large"
                                    placeholder={intl.formatMessage({
                                        id: 'common.firstName',
                                        defaultMessage: 'First Name',
                                    })}
                                    addonBefore={
                                        <Popover
                                            content={intl.formatMessage({
                                                id: 'common.firstName',
                                                defaultMessage: 'First Name',
                                            })}
                                        >
                                            <InfoCircleOutlined />
                                        </Popover>
                                    }
                                />
                            )}
                        </Form.Item>
                        <Form.Item hasFeedback>
                            {getFieldDecorator('landlords_last_name', {
                                initialValue: get(addresses, [currentAddressIndex, 'landlords_last_name']),
                                rules: [
                                    {
                                        pattern: Regex.name,
                                        message: intl.formatMessage({
                                            id: 'error.validation.name',
                                            defaultMessage: 'Please enter a valid name',
                                        }),
                                    },
                                    {
                                        required: true,
                                        message: intl.formatMessage({
                                            id: 'error.validation.notBlank',
                                            defaultMessage: 'Please do not leave blank',
                                        }),
                                    },
                                ],
                            })(
                                <Input
                                    size="large"
                                    placeholder={intl.formatMessage({
                                        id: 'common.lastName',
                                        defaultMessage: 'Last Name',
                                    })}
                                    addonBefore={
                                        <Popover
                                            content={intl.formatMessage({
                                                id: 'common.lastName',
                                                defaultMessage: 'Last Name',
                                            })}
                                        >
                                            <InfoCircleOutlined />
                                        </Popover>
                                    }
                                />
                            )}
                        </Form.Item>
                        <Form.Item hasFeedback>
                            {getFieldDecorator('landlords_email', {
                                initialValue: get(addresses, [currentAddressIndex, 'landlords_email']),
                                rules: [
                                    {
                                        pattern: Regex.email,
                                        message: intl.formatMessage({
                                            id: 'error.validation.email',
                                            defaultMessage: 'Please provide a valid email',
                                        }),
                                    },
                                    {
                                        required: emailReferenceRequired,
                                        message: intl.formatMessage({
                                            id: 'error.validation.notBlank',
                                            defaultMessage: 'Please do not leave blank',
                                        }),
                                    },
                                    {
                                        validator: (rule, value, callback) => {
                                            if (value === applicantEmail) {
                                                callback(
                                                    intl.formatMessage({
                                                        id: 'error.validation.email',
                                                        defaultMessage: 'Please provide a valid email',
                                                    })
                                                );
                                            } else callback();
                                        },
                                    },
                                ],
                            })(
                                <Input
                                    size="large"
                                    placeholder={intl.formatMessage({
                                        id: 'common.email',
                                        defaultMessage: 'Email',
                                    })}
                                    addonBefore={
                                        <Popover
                                            content={intl.formatMessage({
                                                id: 'common.email',
                                                defaultMessage: 'Email',
                                            })}
                                        >
                                            <InfoCircleOutlined />
                                        </Popover>
                                    }
                                />
                            )}
                        </Form.Item>
                        <Form.Item key="phone_type" hasFeedback>
                            {getFieldDecorator('phone_type', {
                                initialValue: get(addresses, [
                                    currentAddressIndex,
                                    'address_reference',
                                    'phone_number',
                                    'phone_type',
                                ]),
                                rules: [
                                    {
                                        required: phoneReferenceRequired,
                                        message: intl.formatMessage({
                                            id: 'error.validation.notBlank',
                                            defaultMessage: 'Please do not leave blank',
                                        }),
                                    },
                                ],
                            })(
                                <Select
                                    size="large"
                                    data-testid="phone_type"
                                    placeholder={intl.formatMessage({
                                        id: 'welcome.general.phoneType',
                                        defaultMessage: 'Phone Type',
                                    })}
                                >
                                    <Select.Option data-testid="NONE" value="NONE">
                                        <FormattedMessage id="common.none" defaultMessage="None" />
                                    </Select.Option>
                                    <Select.Option data-testid="CELL" value="CELL">
                                        <FormattedMessage id="welcome.general.phoneTypeCell" defaultMessage="Cell" />
                                    </Select.Option>
                                    <Select.Option data-testid="HOME" value="HOME">
                                        <FormattedMessage id="welcome.general.phoneTypeHome" defaultMessage="Home" />
                                    </Select.Option>
                                    <Select.Option data-testid="WORK" value="WORK">
                                        <FormattedMessage id="welcome.general.phoneTypeWork" defaultMessage="Work" />
                                    </Select.Option>
                                    <Select.Option data-testid="OTHER" value="OTHER">
                                        <FormattedMessage id="common.other" defaultMessage="Other" />
                                    </Select.Option>
                                </Select>
                            )}
                        </Form.Item>
                        <InternationalPhone
                            key="int-phone"
                            value={this.state.phone} // This is required but is buggy and doesn't work all the time, we getDerivedStateFromProps @ customValue instead
                            customValue={this.state.phone}
                            errorStatus={errorStatus}
                            onChange={(num) => this.setState({ phone: num })}
                        />
                    </FormLayout>
                    <TrackNavigation />
                    {!referenceRequired && (
                        <Row gutter={16}>
                            <Col sm={24} lg={{ span: 16, offset: 4 }}>
                                <ButtonLine>
                                    <TextLink data-testid="no-thanks" id="next" onClick={() => returnToBase()}>
                                        <FormattedMessage id="welcome.general.noThanks" defaultMessage="No thanks" />
                                    </TextLink>
                                </ButtonLine>
                            </Col>
                        </Row>
                    )}
                </Form>
            </AppearRight>
        );
    }
}

export default Form.create()(injectIntl(ReferenceForm));
