import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

const Label = styled.label`
    padding-top: 10px;
    color: ${({ theme }) => theme.color.certnGray800};
    font-size: 14px;
    @media (min-width: ${(props) => props.theme.width.desktop}) {
        flex: 2.5;
    }
`;

const Optional = styled.span`
    display: inline-block;
    padding-left: 5px;
    color: ${({ theme }) => theme.color.certnGray400};
`;

const propTypes = {
    htmlFor: PropTypes.string,
    optional: PropTypes.bool,
    label: PropTypes.string,
    children: PropTypes.node,
};

const defaultProps = {
    htmlFor: null,
    optional: false,
    label: '',
    children: null,
};

const FieldLabel = ({ htmlFor, optional, label, children }) => {
    const intl = useIntl();
    return (
        <Label htmlFor={htmlFor}>
            {children || label}
            {optional && (
                <Optional>
                    {intl.formatMessage({
                        id: 'form.label.optional',
                        defaultMessage: '(optional)',
                    })}
                </Optional>
            )}
        </Label>
    );
};

FieldLabel.propTypes = propTypes;
FieldLabel.defaultProps = defaultProps;

export default FieldLabel;
