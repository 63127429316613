// Libraries
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, Redirect, useParams, useLocation, useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useLDClient, useFlags } from 'launchdarkly-react-client-sdk';
import { injectIntl, useIntl } from 'react-intl';
import { Result } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { get } from 'lodash';

// Components
import { TrackPathBar, QuestionEnclosure, Navbar, SessionNotFound, Helmet } from './components';
import { PhoneWrapper } from 'components';
import Layout, { LayoutBody } from 'certn-ui/Layout';
import Loader from 'certn-ui/Loader';
import { ErrorAlertCustom } from 'certn-ui/ErrorAlert';
import RouteErrorBoundary from 'certn-ui/RouteErrorBoundary';

// Modules & Utils
import { BrowserNotSupported } from 'modules';
import usePrevHook from 'utils/hooks/usePrevHook';

// Actions & Selectors
import {
    fetchWelcomeSession,
    patchSessionLanguage,
    verifyWelcomeSession,
    fetchHrCategories,
    fetchListingOrPosting,
    fetchPositionPropertyLocation,
    fetchSocialMediaDetails,
} from './WelcomeActions';
import {
    getApplicantAccount,
    getTrackOrder,
    getFurthestTrack,
    getIsSubmitted,
    getOnboardingId,
    getTeam,
    getIsExpired,
    getIsOrderStatusExpired,
    getInformation,
} from './WelcomeSelectors';

// Views
import {
    AcicDocuments,
    Addresses,
    AustralianNPCDocuments,
    AustralianLicenses,
    Authorization,
    BackgroundConsent,
    BackgroundConsentInternational,
    PoliceCheckDisclosureConsent,
    Basics,
    DBSPaperCriminalRecordCertificate,
    DBSProfileNumber,
    UKSelfDeclaration,
    NewBasics,
    Birthplace,
    CoverLetter,
    Disclosure,
    AcicDisclosure,
    ConsentAndDisclosure,
    MouseSignature,
    Documents,
    CustomQuestions,
    ProofOfAddress,
    Passport,
    InternationalFields,
    Education,
    Credential,
    Employers,
    Gender,
    History,
    Identity,
    RcmpReflow,
    Driver,
    MoveInDate,
    MoreBasics,
    MyCRCConsent,
    NonCriminalConsent,
    Personal,
    EmergencyContact,
    Supplemental,
    Selfie,
    SignUp,
    Skills,
    Submit,
    Summary,
    WelcomeBack,
    Payment,
    PaymentNew,
    BiometricConsent,
    OneID,
    VaccinationInfo,
    EmployerReference,
    PositionPropertyLocation,
    EmailVerify,
    SocialMediaDetails,
    UKGenericConsent,
    RightToWork,
} from './views';
import { Purpose } from './views/purpose/Purpose';
import { Email } from './views/email';
import { ErrorAlertNoTranslate } from '../../certn-ui/ErrorAlert';
import { useTracking } from 'hooks/trackingHooks';

const Welcome = () => {
    const { webFeatureEnableNewStatusSystem } = useFlags();
    const dispatch = useDispatch();
    const { session, token } = useParams();
    const location = useLocation();
    const history = useHistory();
    const intl = useIntl();
    const listingOrPostingUrlCode = Cookies.get('listingUrlCode');
    const passwordToken = token || Cookies.get('passwordToken') || null;

    const onboardingId = useSelector(getOnboardingId) || session || null;
    const applicantAccount = useSelector(getApplicantAccount);
    const team = useSelector(getTeam);
    const trackOrder = useSelector(getTrackOrder);
    const furthestTrack = useSelector(getFurthestTrack);
    const isSubmitted = useSelector(getIsSubmitted);
    const information = useSelector(getInformation);

    // LDFlag webFeatureEnableNewStatusSystem
    const isReportStatusExpired = useSelector(getIsExpired);
    const isOrderStatusExpired = useSelector(getIsOrderStatusExpired);
    let isExpired;
    if (webFeatureEnableNewStatusSystem) isExpired = isOrderStatusExpired;
    if (!webFeatureEnableNewStatusSystem) isExpired = isReportStatusExpired;

    const [error, setError] = useState(false);
    const [noId, setNoId] = useState(false);
    const [initialLoadComplete, setInitialLoadComplete] = useState(false);

    const isTesting = applicantAccount.email?.indexOf('cypress.testing') > -1;
    const prevTeam = usePrevHook(team);

    const ldClient = useLDClient();

    const { initializeTracking } = useTracking();

    useEffect(() => {
        if (!onboardingId && !listingOrPostingUrlCode) {
            if (!location.pathname.includes('/welcome/signUp')) setNoId(true);
            setInitialLoadComplete(true);
        }
    }, [location.pathname, onboardingId, listingOrPostingUrlCode]);

    useEffect(() => {
        const init = async () => {
            try {
                if (onboardingId) {
                    dispatch(fetchHrCategories());
                    await dispatch(fetchWelcomeSession());
                    if (passwordToken) dispatch(verifyWelcomeSession(onboardingId, passwordToken));
                    if (Cookies.get('is_submitted') === 'true') history.push(`/welcome/payment`);
                    else if (isTesting) history.push(`/welcome/email`);

                    setInitialLoadComplete(true);
                } else if (listingOrPostingUrlCode) {
                    const listingOrPosting = await dispatch(fetchListingOrPosting(listingOrPostingUrlCode));
                    let redirectPathName = 'listings';

                    if (Cookies.get('onboardingType') === 'HR') {
                        dispatch(fetchHrCategories());
                        redirectPathName = 'packages';
                    }

                    if (listingOrPosting.is_active === false) {
                        history.push(`/${redirectPathName}/${get(listingOrPosting, ['owner', 'team', 'id'])}/`);
                    }

                    setInitialLoadComplete(true);
                }
            } catch (error) {
                setError(true);
                ErrorAlertCustom({
                    title: 'Issue loading application',
                    description:
                        'There appears to be an issue with loading a previous application of yours. Please contact support@certn.co or use our live chat support.',
                });
                setInitialLoadComplete(true);
            }
        };

        if (BrowserNotSupported)
            ErrorAlertNoTranslate({
                title: intl.formatMessage({
                    id: '1d2c3.Manager.errorOutdatedBrowserTitle',
                    defaultMessage: 'Outdated browser detected',
                }),
                description: intl.formatMessage({
                    id: '1d2c3.Manager.errorOutdateBrowserDescription',
                    defaultMessage:
                        'Please use an up to date version of your browser for the best user experience. Internet Explorer is no longer supported.',
                }),
            });

        if (information && information.position_or_property_location_id) {
            dispatch(fetchPositionPropertyLocation(information.position_or_property_location_id));
        }
        if (information && information.id && trackOrder.includes('socialMediaDetails')) {
            dispatch(fetchSocialMediaDetails(information.id));
        }

        if (!initialLoadComplete) init();
        /* eslint-disable react-hooks/exhaustive-deps */
    }, [dispatch, history, initialLoadComplete, isTesting, listingOrPostingUrlCode, onboardingId, passwordToken, intl]);

    // Identify LaunchDarkly user
    useEffect(() => {
        const { id, name } = team;
        if ((ldClient.getUser().anonymous || prevTeam?.id !== id) && id && name) {
            ldClient.identify({ name, key: id, custom: { user_id: applicantAccount.id } });
        }
    }, [ldClient, team, prevTeam, applicantAccount.id]);

    // Update track on change
    useEffect(() => {
        if (onboardingId && furthestTrack) history.push(`/welcome/${furthestTrack}`);
    }, [furthestTrack, history, onboardingId]);

    // Update GA on team change
    useEffect(() => {
        if (prevTeam?.id !== team?.id && (team.google_analytics_key || team.bing_key)) {
            initializeTracking({
                googleAnalyticsKey: team.google_analytics_key,
                bingKey: team.bing_key,
            });
        }
    }, [team, prevTeam, initializeTracking]);

    const changeSessionLanguage = (language) => {
        if (onboardingId) dispatch(patchSessionLanguage(language));
    };

    const renderContent = () => {
        // If report_status on the applicant object is UNRESPONSIVE show expired link page
        if (isExpired)
            return (
                <Result
                    icon={<InfoCircleOutlined />}
                    title={intl.formatMessage({
                        id: 'welcome.general.expiredLink',
                        defaultMessage: 'Oops! The link to your application has expired.',
                    })}
                />
            );
        return (
            <>
                <QuestionEnclosure>
                    <RouteErrorBoundary>
                        {noId ? (
                            <SessionNotFound />
                        ) : (
                            <Switch>
                                <Route path="/welcome/summary" component={Summary} />
                                <Route path="/welcome/biometricConsent" component={BiometricConsent} />
                                <Route path="/welcome/email" component={Email} />
                                <Route path="/welcome/basics" component={Basics} />
                                <Route path="/welcome/dbsProfileNumber" component={DBSProfileNumber} />
                                <Route
                                    path="/welcome/dbsPaperCriminalRecordCertificate"
                                    component={DBSPaperCriminalRecordCertificate}
                                />
                                <Route path="/welcome/ukSelfDeclaration" component={UKSelfDeclaration} />
                                <Route path="/welcome/ukGenericConsent" component={UKGenericConsent} />
                                <Route path="/welcome/newBasics" component={NewBasics} />
                                <Route path="/welcome/payment" component={Payment} />
                                <Route path="/welcome/paymentNew" component={PaymentNew} />
                                <Route path="/welcome/disclosure" component={Disclosure} />
                                <Route path="/welcome/ukConsentAndDisclosure" component={ConsentAndDisclosure} />
                                <Route path="/welcome/consentAndDisclosure" component={ConsentAndDisclosure} />
                                <Route path="/welcome/signature" component={MouseSignature} />
                                <Route path="/welcome/acicDisclosure" component={AcicDisclosure} />
                                <Route path="/welcome/authorization" component={Authorization} />
                                <Route path="/welcome/moveInDate" component={MoveInDate} />
                                <Route path="/welcome/myCRCConsent" component={MyCRCConsent} />
                                <Route path="/welcome/personal" component={Personal} />
                                <Route path="/welcome/emergencyContact" component={EmergencyContact} />
                                <Route path="/welcome/supplemental" component={Supplemental} />
                                <Route path="/welcome/moreBasics" component={MoreBasics} />
                                <Route path="/welcome/birthplace" component={Birthplace} />
                                <Route path="/welcome/emailVerify" component={EmailVerify} />
                                <Route path="/welcome/oneId" component={OneID} />
                                <Route
                                    exact
                                    path="/welcome/backgroundConsentInternational"
                                    component={BackgroundConsentInternational}
                                />
                                <Route exact path="/welcome/backgroundConsent" component={BackgroundConsent} />
                                <Route exact path="/welcome/nonCriminalConsent" component={NonCriminalConsent} />
                                <Route
                                    exact
                                    path="/welcome/acicPoliceCheckDisclosureConsent"
                                    component={PoliceCheckDisclosureConsent}
                                />
                                <Route exact path="/welcome/purpose" component={Purpose} />
                                <Route path="/welcome/identity" component={Identity} />
                                <Route path="/welcome/rcmpReflow" component={RcmpReflow} />
                                <Route path="/welcome/driver" component={Driver} />
                                <Route path="/welcome/australianLicenses" component={AustralianLicenses} />
                                <Route path="/welcome/gender" component={Gender} />
                                <Route path="/welcome/education" component={Education} />
                                <Route path="/welcome/credential" component={Credential} />
                                <Route path="/welcome/skills" component={Skills} />
                                <Route path="/welcome/selfie" component={Selfie} />
                                <Route path="/welcome/addresses" component={Addresses} />
                                <Route path="/welcome/employers" component={Employers} />
                                <Route path="/welcome/history" component={History} />
                                <Route path="/welcome/coverletter" component={CoverLetter} />
                                <Route path="/welcome/documents" component={Documents} />
                                <Route path="/welcome/acicDocuments" component={AcicDocuments} />
                                <Route path="/welcome/australianNPCDocuments" component={AustralianNPCDocuments} />
                                <Route path="/welcome/customQuestions" component={CustomQuestions} />
                                <Route path="/welcome/proofOfAddress" component={ProofOfAddress} />
                                <Route path="/welcome/passport" component={Passport} />
                                <Route path="/welcome/internationalFields" component={InternationalFields} />
                                <Route path="/welcome/welcomeback" component={WelcomeBack} />
                                <Route path="/welcome/submit" component={Submit} />
                                <Route path="/welcome/vaccinationInfo" component={VaccinationInfo} />
                                <Route path="/welcome/employerReferences" component={EmployerReference} />
                                <Route path="/welcome/positionPropertyLocation" component={PositionPropertyLocation} />
                                <Route path="/welcome/socialMediaDetails" component={SocialMediaDetails} />
                                <Route path="/welcome/rightToWork" component={RightToWork} />
                                {/* NEW CLIENT SPECIFIC */}
                                <Route path="/welcome/signUp" component={SignUp} />
                                {/* FALL BACK */}
                                <Redirect to="/welcome/email" />
                            </Switch>
                        )}
                    </RouteErrorBoundary>
                </QuestionEnclosure>
                <TrackPathBar
                    trackOrder={trackOrder}
                    currTrack="history"
                    furthestTrack={furthestTrack}
                    complete={isSubmitted || Cookies.get('is_submitted') === 'true'}
                />
            </>
        );
    };
    return (
        <Layout>
            <Helmet />
            <PhoneWrapper>
                {window === window.top && <Navbar error={error} changeSessionLanguage={changeSessionLanguage} />}
                {initialLoadComplete ? <LayoutBody>{renderContent()}</LayoutBody> : <Loader />}
            </PhoneWrapper>
        </Layout>
    );
};

export default injectIntl(Welcome);
