import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, message } from 'antd';
import AdjudicationAreYouSureModal from './AdjudicationAreYouSureModal';
import { patchApplicantAdjudication, updateCheckScore } from '../AdjudicationRequests';
import Auth from 'modules/Auth';
import { ErrorAlertAPI } from 'certn-ui/ErrorAlert';
import PropTypes from 'prop-types';
import { isCheckReturned } from '../utils';

import { fetchReport } from 'views/manager/views/hr/views/applications/ApplicationsActions';
import Constants from 'utils/constants';

const AdjudicationButton = ({
    canSubmitAdjudication,
    allChecksScored,
    applicantChecks,
    applicantAdjudication,
    setApplicantAdjudication,
    statusOptions,
    applicantId,
}) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [visible, setVisible] = useState(false);

    const generateLifecycleStatus = () => {
        if (applicantAdjudication?.overall_result === 'DIDNOTPASS' && !allChecksScored()) return 'CLOSED';
        if (['CLEARED', 'DIDNOTPASS'].includes(applicantAdjudication?.overall_result) && allChecksScored()) {
            return 'COMPLETE';
        }
        return 'IN_PROGRESS';
    };

    const updateApplicantAdjudication = (formNotes) => {
        const data = {
            overall_result: applicantAdjudication?.overall_result,
            notes: formNotes || applicantAdjudication?.notes,
            adjudicator: applicantAdjudication?.adjudicator?.id,
            life_cycle_status: generateLifecycleStatus(),
        };

        patchApplicantAdjudication(applicantId, data)
            .then((response) => {
                if (response.life_cycle_status === data.life_cycle_status) {
                    message.success(
                        intl.formatMessage({
                            id: '6cb79.AdjudicationSidebar.adjudicationSubmitted',
                            defaultMessage: 'Adjudication submitted',
                        })
                    );
                    // Get the updated report information
                    dispatch(fetchReport(applicantId));
                }
                setApplicantAdjudication(response);
            })
            .catch((error) => {
                ErrorAlertAPI(error);
                throw error;
            });
    };

    const handleSubmit = (formNotes) => {
        if (applicantAdjudication?.adjudicator?.id === Auth.getUserID()) {
            const checkScorePromises = [];
            applicantChecks.forEach((check) => {
                if (isCheckReturned) {
                    checkScorePromises.push(updateCheckScore(check.id, check.score_result));
                }
            });

            Promise.all(checkScorePromises).then(() => {
                updateApplicantAdjudication(formNotes);
            });
            setVisible(false);
        } else {
            ErrorAlertAPI({
                non_field_errors: [
                    intl.formatMessage({
                        id: '6cb79.AdjudicationSidebar.assignedError',
                        defaultMessage: 'Only the assigned adjudicator can edit applicant adjudication.',
                    }),
                ],
            });
        }
    };

    const handleReopen = () => {
        patchApplicantAdjudication(applicantId, { adjudicator: Auth.getUserID(), life_cycle_status: 'IN_PROGRESS' })
            .then((response) => {
                setApplicantAdjudication(response);
            })
            .catch((error) => {
                ErrorAlertAPI(error);
                throw error;
            });
    };

    return (
        <>
            {canSubmitAdjudication && visible && (
                <AdjudicationAreYouSureModal
                    visible={visible}
                    setVisible={setVisible}
                    notes={applicantAdjudication?.notes}
                    applicantChecks={applicantChecks}
                    handleSubmit={handleSubmit}
                    applicantAdjudication={applicantAdjudication}
                    setApplicantAdjudication={setApplicantAdjudication}
                    statusOptions={statusOptions}
                />
            )}
            {!Constants.adjudicationLifeCycleStatus.active.includes(applicantAdjudication?.life_cycle_status) ? (
                <Button
                    type="primary"
                    data-testid="adjudication_btn_reopen"
                    style={{ width: '85%' }}
                    onClick={() => handleReopen()}
                >
                    <FormattedMessage id="6cb79.AdjudicationSidebar.reopen" defaultMessage="Reopen" />
                </Button>
            ) : canSubmitAdjudication ? (
                <Button
                    type="primary"
                    data-testid="adjudication_btn_submit"
                    style={{ width: '85%' }}
                    onClick={() => setVisible(true)}
                >
                    <FormattedMessage id="6cb79.AdjudicationSidebar.submit" defaultMessage="Submit Adjudication" />
                </Button>
            ) : (
                <Button
                    type="primary"
                    data-testid="adjudication_btn_save"
                    style={{ width: '85%' }}
                    onClick={() => handleSubmit()}
                >
                    <FormattedMessage id="6cb79.AdjudicationSidebar.save" defaultMessage="Save" />
                </Button>
            )}
        </>
    );
};

AdjudicationButton.propTypes = {
    canSubmitAdjudication: PropTypes.func.isRequired,
    allChecksScored: PropTypes.func.isRequired,
    applicantChecks: PropTypes.array.isRequired,
    applicantAdjudication: PropTypes.object.isRequired,
    setApplicantAdjudication: PropTypes.func.isRequired,
    statusOptions: PropTypes.array.isRequired,
    applicantId: PropTypes.string.isRequired,
};

AdjudicationButton.defaultProps = {};

export default AdjudicationButton;
