// Libraries
import React from 'react';
import styled from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons';

const Loader = () => (
    <LoaderContainer>
        <LoaderIcon data-testid="loading" type="loading" />
    </LoaderContainer>
);
export default Loader;

const LoaderIcon = styled(LoadingOutlined)`
    font-size: 50px;
    color: ${(props) => props.theme.color.primary} !important;
`;

const LoaderContainer = styled.div`
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 0 0%;
    width: 100%;
    height: 400px;
    padding: 20px;
    box-sizing: border-box;
    @media (max-width: ${(props) => props.theme.width.sm}) {
        padding-bottom: 10%;
    }
`;

export const AdvancedLoader = () => (
    <AdvancedLoaderContainer>
        <AdvancedLoaderEntry />
        <AdvancedLoaderEntry />
        <AdvancedLoaderEntry />
    </AdvancedLoaderContainer>
);

const AdvancedLoaderContainer = styled.div`
    position: fixed;
    top: 0px;
    height: 100%;
    width: 100%;
    z-index: 9002;
    background: ${({ theme }) => theme.color.certnWhite};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const AdvancedLoaderEntry = styled.div`
    width: 420px;
    height: 60px;
    margin: 16px;
    background: ${(props) => props.theme.color.certnGray600};
`;
