import styled from 'styled-components/macro';
import { Col, Table } from 'antd';

const PartnerPageWrapper = styled.div`
    margin: 10vh 10vw;
`;

const RightAlignedCol = styled(Col)`
    display: flex;
    justify-content: flex-end;
`;

const StyledTable = styled(Table)`
    margin-top: 30px;
    margin-bottom: 30px;
`;

export const PartnerComponents = {
    PartnerPageWrapper,
    RightAlignedCol,
    StyledTable,
};
