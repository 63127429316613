import { patchRequest, postRequest } from 'utils/http';

export const saveTeamMember = (user, id) =>
    patchRequest({
        version: 'v2',
        endpoint: `/users/${id}/`,
        body: JSON.stringify(user),
    });
export const createTeamMember = (data) =>
    postRequest({
        version: 'v2',
        endpoint: '/users/',
        body: JSON.stringify(data),
    });
