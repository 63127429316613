import { CheckStatus, CheckLabel } from 'types';
import Constants from 'utils/constants';

const LEGACY_RETURNED_STATUSES = [Constants.serviceStatus.RETURNED];

const LEGACY_REFERENCE_RETURNED_STATUSES = [
    Constants.serviceStatus.IN_PROGRESS,
    Constants.serviceStatus.PARTIAL,
    Constants.serviceStatus.RETURNED,
];

// Complete included to allow adjudication sidebar to show for reopening adjudication
const CHECK_EXECUTIONS_RETURNED_STATUSES = [CheckStatus.WAITING_ON_ADJUDICATOR, CheckStatus.COMPLETE];

interface LegacyCheckStatus {
    check_label: string;
    pipeline_status: string;
    [others: string]: unknown;
}

interface CheckExecutionStatus {
    status: CheckStatus;
    [others: string]: unknown;
}

export const shouldShowAjudicationSidebar = (
    legacyChecks: LegacyCheckStatus[],
    checkExecutions: CheckExecutionStatus[],
    adjudicationType: 'partial' | 'full' | 'none'
): boolean => {
    if (adjudicationType === 'none') {
        return false;
    }

    if (adjudicationType === 'full') {
        return legacyChecks.every((check) => LEGACY_RETURNED_STATUSES.includes(check.pipeline_status));
    }
    if (adjudicationType === 'partial') {
        const someNonReferenceLegacyChecksReturned = legacyChecks.some((check) =>
            LEGACY_RETURNED_STATUSES.includes(check.pipeline_status)
        );

        if (someNonReferenceLegacyChecksReturned) {
            return true;
        }

        const someLegacyReferenceChecksReturned = legacyChecks.some(
            (check) =>
                check.check_label === CheckLabel.REFERENCE_CHECK &&
                LEGACY_REFERENCE_RETURNED_STATUSES.includes(check.pipeline_status)
        );

        if (someLegacyReferenceChecksReturned) {
            return true;
        }

        const someCheckExecutionStatusReturned = checkExecutions.some((check) =>
            CHECK_EXECUTIONS_RETURNED_STATUSES.includes(check.status)
        );

        return someCheckExecutionStatusReturned;
    }
    return false;
};

interface Check {
    id: string;
    applicant: string;
    report_section: string;
    score_source: string;
    check_label: CheckLabel;
    pipeline_status: string;
    score_result: string;
    notes: string;
    [others: string]: unknown;
}

export const isCheckReturned = (check: Check): boolean => {
    if (
        check.check_label === CheckLabel.REFERENCE_CHECK &&
        LEGACY_REFERENCE_RETURNED_STATUSES.includes(check.pipeline_status)
    ) {
        return true;
    }
    if (check.pipeline_status === Constants.serviceStatus.RETURNED) {
        return true;
    }

    return false;
};
