// Libraries
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Text from 'certn-ui/Text';
import Image from 'certn-ui/Image';
import ShieldIcon from 'images/svgs/icon-shield.svg';
import NoticeFrame from 'certnd/NoticeFrame';

const PrivacyNotice = () => (
    <NoticeFrame>
        <Image margin={10} src={ShieldIcon} />
        <Text align="center" size="xxs" weight="600">
            <FormattedMessage
                id="welcome.NonCriminalConsent.privacyNoticeTitle"
                defaultMessage="Your privacy is important to us."
            />
        </Text>
        <Text align="center" size="xxs">
            <FormattedMessage
                id="welcome.NonCriminalConsent.privacyNoticeBody"
                defaultMessage="Certn will only collect information that is needed to complete your check."
            />
        </Text>
    </NoticeFrame>
);

export default PrivacyNotice;
