import { useSelector } from 'react-redux';
import { OnboardingSession } from 'types';
import { getApplicantVerified } from 'views/welcome/WelcomeSelectors';

import withNavigation from 'views/welcome/modules/WithNavigation';
import { AppearRight } from 'certn-ui/Animate';
import Title from 'certn-ui/Title';
import Text from 'certn-ui/Text';
import { FormattedMessage } from 'react-intl';
import { useEffect } from 'react';

export const EmailVerify = withNavigation(({ handleNextTrack }: { handleNextTrack: () => void }) => {
    const verified = useSelector(getApplicantVerified) as OnboardingSession['applicant_verified'];

    useEffect(() => {
        if (verified) {
            handleNextTrack();
        }
    }, [verified, handleNextTrack]);

    return (
        <AppearRight>
            <Title size="med">
                <FormattedMessage
                    id="welcome.EmailVerify.title"
                    defaultMessage="To complete the check we need to verify you are the owner of this email address. Please <b>check your email</b> now and <b>click the verification link</b> provided."
                    values={{
                        b: (chunks: string) => <strong>{chunks}</strong>,
                    }}
                />
            </Title>
            <Text align="center" size="xxxs" color="certnGray500">
                <FormattedMessage
                    id="welcome.EmailVerify.contactSupport"
                    defaultMessage="If you have not received an email please contact {supportEmail} for more information."
                    values={{
                        supportEmail: <a href="mailto:support@certn.co">support@certn.co</a>,
                    }}
                />
            </Text>
        </AppearRight>
    );
});
