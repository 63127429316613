import styled from 'styled-components';
import { motion } from 'framer-motion';

export const DrawerWrapper = styled(motion.aside)`
    position: fixed;
    z-index: 2;
    pointer-events: all;
    height: 100%;
    background: #f1f3f3;
    box-sizing: border-box;
    right: 0;
    width: 450px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
`;

export const DrawerHeader = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    background: #ffffff;
    border-bottom: 1px solid #e3e3e3;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05);
`;

export const DrawerTitleDiv = styled.div`
    border-bottom: 1px solid #e3e3e3;
    padding: 12px 24px 12px 16px;
    display: flex;
    width: 100%;
    height: 45px;
    justify-content: space-between;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05);
    align-items: center;
`;

export const DrawerFooter = styled.div`
    display: flex;
    padding: 10px 16px;
    background: #ffffff;
    justify-content: flex-end;
    border-top: 1px solid #e3e3e3;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05);
`;

export const DrawerBody = styled.div`
    height: calc(100vh - 98px);
    overflow-y: auto;
`;
