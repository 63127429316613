// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { FormattedMessage } from 'react-intl';

// Components
import { Button, Modal } from 'antd';
import Text from 'certn-ui/Text';

import { ReferenceModule } from 'modules';

const { QUESTION_TYPES } = ReferenceModule;

const TextWithRequired = styled(Text)`
    &::after {
        content: ${(props) => (props.required ? '"*"' : 'normal')};
        color: ${({ theme }) => theme.color.certnRed500};
        margin-left: 5px;
    }
`;

const PreviewTemplateModal = ({ setIsVisible, isVisible, serviceKey, template }) => {
    const displayQuestionType = (type) => {
        switch (type) {
            case QUESTION_TYPES.MULTIPLE_CHOICE:
                return (
                    <FormattedMessage
                        id="manager.PreviewTemplateModal.multipleChoice"
                        defaultMessage="Multiple Choice"
                    />
                );
            case QUESTION_TYPES.TRUE_FALSE:
                return <FormattedMessage id="manager.PreviewTemplateModal.boolean" defaultMessage="True/False" />;
            case QUESTION_TYPES.YES_NO:
                return <FormattedMessage id="manager.PreviewTemplateModal.yesNo" defaultMessage="Yes/No" />;
            case QUESTION_TYPES.NUMERIC_RANGE:
                return '1-10';
            case QUESTION_TYPES.LONG_ANSWER:
                return <FormattedMessage id="manager.PreviewTemplateModal.textInput" defaultMessage="Text Input" />;
            default:
                return '';
        }
    };

    const questionNumberOffset =
        template?.questions && template?.questions[0] && template?.questions[0].index === 0 ? 1 : 0;

    return (
        <Modal
            /* getContainer sets container that the modal is rendered in to prevent scrolling to the top of the page.
               The ID must be set on the parent component for this to select it. */
            getContainer={`#${serviceKey}`}
            centered
            width="800px"
            title={<Text weight="900">{template?.name}</Text>}
            visible={isVisible}
            onCancel={() => setIsVisible(false)}
            footer={[
                <Button
                    style={{ textTransform: 'uppercase' }}
                    key="submit"
                    type="primary"
                    htmlType="submit"
                    onClick={() => setIsVisible(false)}
                >
                    <FormattedMessage id="common.close" defaultMessage="Close" />
                </Button>,
            ]}
        >
            <div>
                {template?.questions?.map((question) => (
                    <Text
                        color="certnGray600"
                        style={{ marginBottom: '10px' }}
                        key={question.index + question.question}
                    >
                        <span style={{ marginRight: '5px' }}>{question.index + questionNumberOffset}.</span>
                        <span>{question.question}</span>
                        <TextWithRequired
                            color="certnGray700"
                            weight="700"
                            display="inline"
                            margin="0 5px"
                            required={question.is_required}
                        >
                            ({displayQuestionType(question.type)})
                        </TextWithRequired>
                    </Text>
                ))}
            </div>
        </Modal>
    );
};

PreviewTemplateModal.propTypes = {
    setIsVisible: PropTypes.func.isRequired,
    isVisible: PropTypes.bool.isRequired,
    serviceKey: PropTypes.string.isRequired,
    template: PropTypes.object,
};

PreviewTemplateModal.defaultProps = {
    template: {},
};

export default PreviewTemplateModal;
