import FileSaver from 'file-saver';
import moment from 'moment';

import { getDateFormat } from './Format';

export const downloadFile = ({ fileContents, fileName, fileType = 'application/pdf' }) => {
    const file = new File([fileContents], fileName, { type: fileType });
    FileSaver.saveAs(file);
};

export const buildApplicationFileName = (application) => {
    const firstName = application?.information?.first_name;
    const lastName = application?.information?.last_name;
    const fullName = lastName && firstName ? `${firstName}_${lastName}` : lastName || firstName;

    const lastestActivity = application?.activity_log?.slice(-1);
    const latestActivityDate = lastestActivity?.[0]?.date_label;

    const dateFallback = moment.utc(application?.last_updated || Date.now()).format(getDateFormat());

    return `${fullName || 'report'}_${latestActivityDate || dateFallback}.pdf`;
};
