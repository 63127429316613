// Libraries
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import AdverseActionStatus from './AdverseActionStatus';
import { adverseActionDetails } from '../features/AdverseActionDrawer/DrawerSelectors';
import { useSelector } from 'react-redux';

// External Libraries
import Frame from 'react-frame-component';

const CertnReportContainer = styled.div`
    @media (min-width: 50rem) {
        position: relative;
        margin-top: 20px;
        margin-bottom: 50px;
        height: 3500px;
        width: 100%;
        padding: 5x;
        display: block;
        border-top: 1px solid ${({ theme }) => theme.color.certnGray100};
        border: ${({ hasAdverseActionStatus }) => (hasAdverseActionStatus ? '1px solid #d8d8d8;' : ';')}
        box-shadow: ${({ hasAdverseActionStatus }) =>
            !hasAdverseActionStatus
                ? '-2px 3px 6px 0px rgba(0, 0, 0, 0.12), 2px 3px 6px 0px rgba(0, 0, 0, 0.12);'
                : ''};
    }
`;
const StyledFrame = styled(Frame)`
    position: relative;
    height: 3500px;
    width: 100%;
`;

const propTypes = {
    report: PropTypes.string.isRequired,
};
const defaultProps = {};
const CertnReport = ({ onScroll, onResize, report }) => {
    const adverseAction = useSelector(adverseActionDetails);

    useEffect(() => {
        window.addEventListener('scroll', onScroll, false);
        window.addEventListener('resize', onResize, false);

        return () => {
            window.removeEventListener('scroll', onScroll, false);
            window.removeEventListener('resize', onResize, false);
        };
    }, [onResize, onScroll]);

    const setHeight = () => {
        setTimeout(
            // eslint-disable-next-line
            () => {
                const container = window.document.getElementById('iframe_container');
                if (container) container.style.height = `${58 + window.frames.ifr.document.body.offsetHeight}px`;
            },
            200
        );
        setTimeout(
            // eslint-disable-next-line
            () => {
                const frame = window.document.getElementById('certnFrame');
                if (frame) frame.style.height = window.document.getElementById('iframe_container').style.height;
            },
            250
        );
    };

    const iframeContainerRef = useRef();
    const certnFrameRef = useRef();
    const hasAdverseActionStatus =
        !!adverseAction?.adverse_action_status && adverseAction?.adverse_action_status !== 'NONE';
    return (
        <>
            <CertnReportContainer
                id="iframe_container"
                ref={iframeContainerRef}
                hasAdverseActionStatus={hasAdverseActionStatus}
            >
                {hasAdverseActionStatus && <AdverseActionStatus adverseAction={adverseAction} />}
                <StyledFrame
                    frameBorder={0}
                    initialContent={report}
                    mountTarget="#childMountPoint"
                    name="ifr"
                    id="certnFrame"
                    ref={certnFrameRef}
                    scrolling="no"
                    onLoad={setHeight}
                />
            </CertnReportContainer>
            <div style={{ marginBottom: '100px' }} />
        </>
    );
};

CertnReport.propTypes = propTypes;
CertnReport.defaultProps = defaultProps;

export default CertnReport;
