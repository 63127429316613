import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { intl } from 'components/GlobalProvider';
import { Menu, Modal, message } from 'antd';
import moment from 'moment';

import { ListCellText } from 'certn-ui/List';
import { ErrorAlertCustom } from 'certn-ui/ErrorAlert';

import { sendReminder } from 'views/manager/views/hr/views/applications/ApplicationsActions';

const ItemSendReminder = ({ applicant, report, ...rest }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const resendTime = moment(applicant.resend_email_time).fromNow(true);

    const resendText = applicant.can_resend_email ? (
        <FormattedMessage id="004e5.StatusDropdown.resendTextSendReminder" defaultMessage="Send Reminder Email" />
    ) : (
        <FormattedMessage
            id="004e5.StatusDropdown.resendTextSendInTime"
            defaultMessage="Reminder Available in {resendTime}"
            values={{ resendTime }}
        />
    );

    const showConfirmReminder = () => {
        const { email } = applicant.applicant_account;

        const content = email
            ? intl.formatMessage(
                  {
                      id: '004e5.StatusDropdown.sendReminderEmailWithEmail',
                      defaultMessage: 'Send a reminder to {email}? You can now do this once every 24 hours.',
                  },
                  { email }
              )
            : intl.formatMessage({
                  id: '004e5.StatusDropdown.sendReminderEmailWithoutEmail',
                  defaultMessage: 'Send a reminder email? You can now do this once every 24 hours.',
              });
        Modal.confirm({
            title: intl.formatMessage({
                id: '004e5.StatusDropdown.sendEmailReminderTitle',
                defaultMessage: 'Send Email Reminder',
            }),
            content,
            okType: 'primary',
            onOk: async () => {
                try {
                    await dispatch(sendReminder(applicant.id));
                    message.success(
                        intl.formatMessage({
                            id: '004e5.StatusDropdown.reminderEmailSent',
                            defaultMessage: 'Reminder email sent.',
                        })
                    );
                    if (report) history.push(`/hr/applications`);
                } catch (error) {
                    ErrorAlertCustom({
                        title: intl.formatMessage({
                            id: '004e5.StatusDropdown.reminderEmailError',
                            defaultMessage: 'Error sending email reminder',
                        }),
                    });
                }
            },
        });
    };

    return (
        <Menu.Item {...rest}>
            <ListCellText
                clickable
                disabled={!applicant.can_resend_email}
                color={applicant.can_resend_email ? 'certnGreen700' : 'certnGray400'}
                onClick={applicant.can_resend_email ? showConfirmReminder : null}
            >
                {resendText}
            </ListCellText>
        </Menu.Item>
    );
};

ItemSendReminder.propTypes = {
    applicant: PropTypes.object.isRequired,
    report: PropTypes.bool,
};

ItemSendReminder.defaultProps = {
    report: false,
};

export default ItemSendReminder;
