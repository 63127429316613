import { getRequest } from 'utils/http';
import { toLower, upperFirst } from 'lodash';
import { SetEducationInstitutionsType, EducationInstitutionsType, UpdateInstitutionsType } from 'types/institution';

const formatString = (string: string) => {
    const formattedString = toLower(string)
        .split(' ')
        .map((str) => upperFirst(str))
        .join(' ');

    return formattedString;
};

const getCountryInstitutions = async (
    setEducationInstitutions: SetEducationInstitutionsType,
    educationInstitutions: EducationInstitutionsType,
    country: string,
    updateInstitutions?: UpdateInstitutionsType
) => {
    getRequest({
        version: 'v2',
        endpoint: `/education/institutions/?country=${country}`,
    })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((response: any) => {
            const result = response.map((d: { name: string; province_state: string; id: string }) => ({
                ...d,
                name: `${formatString(d.name)}${
                    country === 'US' && d.province_state ? `, ${d.province_state.toUpperCase()}` : ''
                }`,
                basic_name: formatString(d.name),
                value: d.id,
            }));
            if (updateInstitutions) updateInstitutions(country, result);
            setEducationInstitutions({ ...educationInstitutions, [country]: result });
        })
        .catch((error) => {
            throw error;
        });
};

export default getCountryInstitutions;
