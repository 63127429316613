// Libraries
import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { CardElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Button as AntdButton } from 'antd';
import { getLocale } from 'base/BaseSelectors';

import config from 'utils/config';
import styles from 'styles/styles';

import './styles.css';

// Custom styling can be passed to options when creating an Element.
const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            color: styles.color.stripePurple,
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '16px',
            '::placeholder': {
                color: styles.color.stripeGray,
            },
        },
        invalid: {
            color: styles.color.stripeOrange,
            iconColor: styles.color.stripeOrange,
        },
    },
};

const CardField = ({ onChange }) => (
    <div className="FormRow">
        <CardElement options={CARD_ELEMENT_OPTIONS} onChange={onChange} />
    </div>
);

const CustomAntdButton = styled(AntdButton)`
    margin-right: ${({ multiLine }) => (multiLine ? '10px' : '0px')};
    margin-top: 15px !important;
`;

const ErrorMessage = styled.div`
    color: red;
`;

const CheckoutForm = (props) => {
    const stripe = useStripe();
    const elements = useElements();
    const intl = useIntl();
    const locale = useSelector(getLocale);
    const [error, setError] = useState(null);
    const [cardComplete, setCardComplete] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [billingDetails, setBillingDetails] = useState({
        name: '',
    });

    // Grab name from antd form
    useEffect(() => setBillingDetails({ name: props.name }), [props.name]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        props.validateFields(async (err) => {
            if (!err) {
                if (!stripe || !elements) {
                    // Stripe.js has not loaded yet. Make sure to disable
                    // form submission until Stripe.js has loaded.
                    return;
                }

                if (error) {
                    elements.getElement('card').focus();
                    return;
                }

                if (cardComplete) {
                    setProcessing(true);
                }

                const payload = await stripe.createToken(elements.getElement(CardElement), {
                    ...billingDetails,
                    preferred_language: locale,
                });

                setProcessing(false);

                if (payload.error) {
                    setError(payload.error);
                } else {
                    props.handleSubmit(payload.token.id);
                }
            }
        });
    };

    return (
        <form className="Form">
            <fieldset className="FormGroup">
                <CardField
                    onChange={(e) => {
                        setError(e.error);
                        setCardComplete(e.complete);
                    }}
                />
            </fieldset>
            {error && <ErrorMessage>{error.message}</ErrorMessage>}
            {props.handleReverse && (
                <CustomAntdButton
                    size="large"
                    type="secondary"
                    loading={props.paymentLoading || processing || !stripe}
                    onClick={props.handleReverse}
                    multiLine
                >
                    <span style={{ textTransform: 'uppercase' }}>
                        {intl.formatMessage({ id: 'common.back', defaultMessage: 'Back' })}
                    </span>
                </CustomAntdButton>
            )}
            <CustomAntdButton
                size="large"
                type="primary"
                loading={props.paymentLoading || processing || !stripe}
                onClick={handleSubmit}
            >
                {processing || props.paymentLoading ? (
                    <FormattedMessage id="61c28.StripePaymentForm.processing" defaultMessage="Processing..." />
                ) : (
                    <span style={{ textTransform: 'uppercase' }}>
                        <FormattedMessage id="welcome.general.submitPayment" defaultMessage="Submit Payment" />
                    </span>
                )}
            </CustomAntdButton>
        </form>
    );
};

const StripePaymentForm = (props) => {
    const [stripePromise, setStripePromise] = useState(null);
    const ELEMENTS_OPTIONS = {
        fonts: [
            {
                cssSrc: 'https://fonts.googleapis.com/css?family=Roboto',
            },
        ],
        locale: props.language,
    };

    // Setup stripe key; if props key use that, otherwise use test key if !live, otherwise use Certn live key
    useEffect(() => {
        let stripeKey = config.isProduction
            ? 'pk_live_vbXCEC9zPcfpo8YjOfcddApm' // Live key
            : 'pk_test_AQHqQZLpYbufin2RJW8MHf2A'; // Test key
        if (props.stripePublicApiKey) stripeKey = props.stripePublicApiKey; // Props key
        setStripePromise(loadStripe(stripeKey));
    }, [props.stripePublicApiKey]);

    return (
        <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
            <CheckoutForm
                validateFields={props.validateFields}
                handleSubmit={props.handleSubmit}
                name={props.name}
                paymentLoading={props.paymentLoading}
                handleReverse={props.handleReverse}
            />
        </Elements>
    );
};

export default StripePaymentForm;
