import { queryKeys } from '../../utils/reactQuery/queryKeys';
import { useQuery } from '@tanstack/react-query';
import { getRequest } from 'utils/http';
import { Application } from 'types';

async function getApplication(applicationId: string): Promise<Application> {
    if (applicationId) {
        const data = await Promise.resolve(
            getRequest({
                hr: true,
                version: 'v1',
                endpoint: `/applicants/${applicationId}/`,
            })
        );
        return data as Application;
    }
    return {} as Application;
}

export default function useApplications(applicationId: string) {
    const fallback = {};
    const { data = fallback } = useQuery<Application>([queryKeys.application, applicationId], () =>
        getApplication(applicationId)
    );
    return data;
}
