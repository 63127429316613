// Libraries
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

// Components
import SummaryItem from './SummaryItem';
import SummaryHeader from './SummaryHeader';
import { FlexCol, SummarySection, SummaryContainer } from './SummaryStyles';

const CustomQuestions = ({ goToTrack, data = [] }) => (
    <SummaryContainer>
        <SummaryHeader
            title={<FormattedMessage id="welcome.general.customQuestions" defaultMessage="Custom Questions" />}
            onClick={goToTrack}
        />
        <SummarySection>
            <FlexCol>
                {data.map(({ question, answer }) => (
                    <SummaryItem title={question} text={answer} />
                ))}
            </FlexCol>
        </SummarySection>
    </SummaryContainer>
);

export default injectIntl(CustomQuestions);
