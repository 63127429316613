// Libraries
import React from 'react';
import { get } from 'lodash';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import { Input, Popover, Select } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';

// Components
import { TrackNavigation } from 'views/welcome/components';
import Title from 'certn-ui/Title';
import { AppearRight } from 'certn-ui/Animate';
import FormLayout from 'certn-form/FormLayout';
import AddressAutoComplete from 'certn-form/AddressAutoComplete';
import EmploymentHistoryHelperText from './EmploymentHistoryHelperText';

// Modules
import { Regex } from 'modules';
import DateInput from 'certn-form/DateInput';
import { endDateIsToday } from 'modules/validation';

const EmployerFields = (
    getFieldDecorator,
    getFieldsValue,
    employers,
    currentEmployerIndex,
    employmentVerification,
    config = null,
    intl,
    form,
    onboardingType,
    booleanToString,
    stringToBoolean
) => {
    /**
     * The dropdown value should be checking both current and end_date.
     * If current is falsy, that could mean it's either:
     * - the form is empty, or
     * - it's a past employer, but we need to check if there's an end_date.
     */
    const getInitialCurrentOrPastValue = () => {
        if (employers?.[currentEmployerIndex]) {
            return booleanToString(
                !get(employers[currentEmployerIndex].end_date) && Boolean(employers[currentEmployerIndex].current)
            );
        }
        return undefined;
    };

    const fields = {
        company_name: () => (
            <Form.Item key="company_name" hasFeedback>
                {getFieldDecorator('company_name', {
                    initialValue: get(employers, [currentEmployerIndex, 'company_name']),
                    rules: [
                        {
                            required: true,
                            message: intl.formatMessage({
                                id: 'error.validation.notBlank',
                                defaultMessage: 'Please do not leave blank',
                            }),
                        },
                    ],
                })(
                    <Input
                        size="large"
                        autoFocus
                        data-testid="company_name"
                        placeholder={intl.formatMessage({
                            id: 'welcome.Employers.organizationName',
                            defaultMessage: 'Organization Name',
                        })}
                        addonBefore={
                            <Popover
                                content={intl.formatMessage({
                                    id: 'welcome.Employers.organizationName',
                                    defaultMessage: 'Organization Name',
                                })}
                            >
                                <InfoCircleOutlined />
                            </Popover>
                        }
                    />
                )}
            </Form.Item>
        ),
        employment_url: () => {
            const website = () => (
                <Form.Item key="employment_url" hasFeedback>
                    {getFieldDecorator('employment_url', {
                        initialValue: get(employers, [currentEmployerIndex, 'employment_url']),
                        rules: [
                            {
                                pattern: Regex.url,
                                message: intl.formatMessage({
                                    id: 'error.validation.url',
                                    defaultMessage: 'Please provide a valid URL',
                                }),
                            },
                        ],
                    })(
                        <Input
                            size="large"
                            data-testid="employment_url"
                            placeholder={intl.formatMessage({
                                id: 'welcome.Employers.website',
                                defaultMessage: 'Website',
                            })}
                            addonBefore={
                                <Popover
                                    content={intl.formatMessage({
                                        id: 'welcome.Employers.website',
                                        defaultMessage: 'Website',
                                    })}
                                >
                                    <InfoCircleOutlined />
                                </Popover>
                            }
                        />
                    )}
                </Form.Item>
            );
            const partTimeFullTime = ['PT', 'FT'].includes(getFieldsValue().part_time_full_time);
            if (onboardingType === 'HR' || partTimeFullTime) {
                return website();
            }
        },
        income: () => (
            <Form.Item key="income" hasFeedback>
                {getFieldDecorator('income', {
                    initialValue: get(employers, [currentEmployerIndex, 'income']),
                    rules: [
                        {
                            pattern: Regex.dollars,
                            message: intl.formatMessage({
                                id: 'error.validation.dollars',
                                defaultMessage: 'Please enter a valid dollar amount',
                            }),
                        },
                        {
                            required: true,
                            message: intl.formatMessage({
                                id: 'error.validation.notBlank',
                                defaultMessage: 'Please do not leave blank',
                            }),
                        },
                    ],
                })(
                    <Input
                        size="large"
                        data-testid="income"
                        placeholder={intl.formatMessage({
                            id: 'welcome.Employers.estimatedIncome',
                            defaultMessage: 'Estimated Gross Yearly Income ($)',
                        })}
                        addonBefore={
                            <Popover
                                content={intl.formatMessage({
                                    id: 'welcome.Employers.estimatedIncome',
                                    defaultMessage: 'Estimated Gross Yearly Income ($)',
                                })}
                            >
                                <InfoCircleOutlined />
                            </Popover>
                        }
                        type="number"
                    />
                )}
            </Form.Item>
        ),
        position: () => {
            const position = () => (
                <Form.Item key="position" hasFeedback>
                    {getFieldDecorator('position', {
                        initialValue: get(employers, [currentEmployerIndex, 'position']),
                        rules: [
                            {
                                pattern: Regex.alphaNumericExtra,
                                message: intl.formatMessage({
                                    id: 'error.validation.alphaNumericExtra',
                                    defaultMessage: 'Please use only numbers, letters, spaces, dashes, and underscores',
                                }),
                            },
                            {
                                required: true,
                                message: intl.formatMessage({
                                    id: 'error.validation.notBlank',
                                    defaultMessage: 'Please do not leave blank',
                                }),
                            },
                        ],
                    })(
                        <Input
                            size="large"
                            data-testid="position"
                            placeholder={intl.formatMessage({
                                id: 'welcome.Employers.position',
                                defaultMessage: 'Position / Situation',
                            })}
                            addonBefore={
                                <Popover
                                    content={intl.formatMessage({
                                        id: 'welcome.Employers.position',
                                        defaultMessage: 'Position / Situation',
                                    })}
                                >
                                    <InfoCircleOutlined />
                                </Popover>
                            }
                        />
                    )}
                </Form.Item>
            );

            const partTimeFullTime = ['PT', 'FT'].includes(getFieldsValue().part_time_full_time);
            if (onboardingType === 'HR' || partTimeFullTime) {
                return position();
            }
        },
        current_or_past: () => (
            <Form.Item key="current" hasFeedback>
                {getFieldDecorator('current', {
                    initialValue: getInitialCurrentOrPastValue(),
                    rules: [
                        {
                            required: true,
                            message: intl.formatMessage({
                                id: 'error.validation.notSelected',
                                defaultMessage: 'Please make a selection',
                            }),
                        },
                    ],
                })(
                    <Select
                        size="large"
                        data-testid="current"
                        placeholder={intl.formatMessage({
                            id: 'welcome.Employers.isCurrentOrPast',
                            defaultMessage: 'Is this a current or past employer?',
                        })}
                    >
                        <Select.Option data-testid="yes_current" value="true">
                            <FormattedMessage
                                id="welcome.Employers.currentEmployer"
                                defaultMessage="I currently work here"
                            />
                        </Select.Option>
                        <Select.Option data-testid="not_current" value="false">
                            <FormattedMessage
                                id="welcome.Employers.pastEmployer"
                                defaultMessage="This is a past employer"
                            />
                        </Select.Option>
                    </Select>
                )}
            </Form.Item>
        ),
        part_time_full_time: () => (
            <Form.Item key="part_time_full_time" hasFeedback>
                {getFieldDecorator('part_time_full_time', {
                    initialValue: get(employers, [currentEmployerIndex, 'part_time_full_time']),
                    rules: [
                        {
                            required: true,
                            message: intl.formatMessage({
                                id: 'error.validation.notBlank',
                                defaultMessage: 'Please do not leave blank',
                            }),
                        },
                    ],
                })(
                    <Select
                        size="large"
                        placeholder={intl.formatMessage({
                            id: 'welcome.Employers.incomeType',
                            defaultMessage: 'Income Type',
                        })}
                    >
                        <Select.Option value="PT">
                            <FormattedMessage id="welcome.Employers.incomePartTime" defaultMessage="Part-Time" />
                        </Select.Option>
                        <Select.Option value="FT">
                            <FormattedMessage id="welcome.Employers.incomeFullTime" defaultMessage="Full-Time" />
                        </Select.Option>
                        <Select.Option value="SL">
                            <FormattedMessage
                                id="welcome.Employers.incomeStudentLoans"
                                defaultMessage="Student Loans / Funding"
                            />
                        </Select.Option>
                        <Select.Option value="PN">
                            <FormattedMessage id="welcome.Employers.incomePension" defaultMessage="Pension" />
                        </Select.Option>
                        <Select.Option value="DA">
                            <FormattedMessage id="welcome.Employers.incomeDisability" defaultMessage="Disability" />
                        </Select.Option>
                        <Select.Option value="OT">
                            <FormattedMessage id="common.other" defaultMessage="Other" />
                        </Select.Option>
                    </Select>
                )}
            </Form.Item>
        ),
        accomplishments: () => (
            <Form.Item key="accomplishments" hasFeedback>
                {getFieldDecorator('accomplishments', {
                    initialValue: get(employers, [currentEmployerIndex, 'accomplishments']),
                })(
                    <Input.TextArea
                        autosize
                        rows={2}
                        data-testid="accomplishments"
                        placeholder={intl.formatMessage({
                            id: 'welcome.Employers.accomplishments',
                            defaultMessage: 'Accomplishment / Responsibilities',
                        })}
                    />
                )}
            </Form.Item>
        ),
        full_date: () => {
            const startDate = () => (
                <Form.Item key="start_date" hasFeedback>
                    {getFieldDecorator('start_date', {
                        initialValue: get(employers, [currentEmployerIndex, 'start_date']),
                        rules: [
                            {
                                required: true,
                                message: intl.formatMessage({
                                    id: 'error.validation.notBlank',
                                    defaultMessage: 'Please do not leave blank',
                                }),
                            },
                        ],
                    })(
                        <DateInput
                            size="large"
                            data-testid="start_date"
                            disableFutureDates
                            placeholder={intl.formatMessage({
                                id: 'form.label.startDate',
                                defaultMessage: 'Start Date',
                            })}
                            addonBeforeUsePlaceholder
                        />
                    )}
                </Form.Item>
            );
            const endDate = () => (
                <Form.Item key="end_date" hasFeedback>
                    {getFieldDecorator('end_date', {
                        initialValue: get(employers, [currentEmployerIndex, 'end_date']),
                        rules: [
                            {
                                validator: (rule, value, callback) => {
                                    // Warn if end date is today - use current instead
                                    const result = endDateIsToday(value);
                                    callback(result);
                                },
                            },
                            {
                                required: true,
                                message: intl.formatMessage({
                                    id: 'error.validation.notBlank',
                                    defaultMessage: 'Please do not leave blank',
                                }),
                            },
                        ],
                    })(
                        <DateInput
                            size="large"
                            data-testid="end_date"
                            disableFutureDates
                            placeholder={intl.formatMessage({
                                id: 'form.label.endDate',
                                defaultMessage: 'End Date',
                            })}
                            addonBeforeUsePlaceholder
                        />
                    )}
                </Form.Item>
            );
            return (
                <React.Fragment key="full_date">
                    {stringToBoolean(getFieldsValue().current) ? (
                        startDate()
                    ) : (
                        <>
                            {startDate()} {endDate()}
                        </>
                    )}
                </React.Fragment>
            );
        },
        address_lookup: () => {
            const addressLookup = () => (
                <React.Fragment key="address_lookup">
                    <FormLayout size="lrg">
                        <AddressAutoComplete
                            getFieldDecorator={getFieldDecorator}
                            form={form}
                            currentAddress={get(employers, [currentEmployerIndex, 'address'])}
                            hideLabels
                            bypassCounty
                            skippable={!employmentVerification}
                        />
                    </FormLayout>
                </React.Fragment>
            );
            const partTimeFullTime = ['PT', 'FT'].includes(getFieldsValue().part_time_full_time);
            if (onboardingType === 'HR' || partTimeFullTime) {
                return addressLookup();
            }
        },
    };
    return config ? config.map((item) => fields[item]()) : Object.values(fields).map((item) => item());
};

const EmployerForm = ({
    form: { getFieldDecorator, validateFields, setFields, getFieldsValue, getFieldValue },
    form,
    handleSubmit,
    employers,
    currentEmployerIndex,
    employmentVerification,
    onboardingType,
    intl,
    booleanToString,
    stringToBoolean,
}) => {
    const getInitialCanContactValue = () => {
        if (employers?.[currentEmployerIndex]?.company_name) {
            return booleanToString(employers?.[currentEmployerIndex].can_contact);
        }
        return undefined;
    };

    const showCanContactEmployer = getFieldValue('current') === 'true';

    return (
        <AppearRight>
            <Title>
                {onboardingType === 'PM' ? (
                    <FormattedMessage
                        id="welcome.Employers.titlePM"
                        defaultMessage="Can you give me a few details about a current or past employer / source of income?"
                    />
                ) : (
                    <FormattedMessage
                        id="welcome.Employers.titleHR"
                        defaultMessage="Can you give me a few details about your current or past employers?"
                    />
                )}
            </Title>
            <EmploymentHistoryHelperText />
            <Form layout="horizontal" onSubmit={(e) => handleSubmit(e, validateFields, setFields)}>
                {onboardingType === 'PM' ? (
                    <FormLayout>
                        {EmployerFields(
                            getFieldDecorator,
                            getFieldsValue,
                            employers,
                            currentEmployerIndex,
                            employmentVerification,
                            [
                                'part_time_full_time',
                                'company_name',
                                'position',
                                'income',
                                'current_or_past',
                                'employment_url',
                                'full_date',
                                'address_lookup',
                            ],
                            intl,
                            form,
                            onboardingType,
                            booleanToString,
                            stringToBoolean
                        )}
                    </FormLayout>
                ) : (
                    <FormLayout>
                        {EmployerFields(
                            getFieldDecorator,
                            getFieldsValue,
                            employers,
                            currentEmployerIndex,
                            employmentVerification,
                            [
                                'company_name',
                                'employment_url',
                                'position',
                                'accomplishments',
                                'current_or_past',
                                'full_date',
                                'address_lookup',
                            ],
                            intl,
                            form,
                            onboardingType,
                            booleanToString,
                            stringToBoolean
                        )}
                    </FormLayout>
                )}
                {showCanContactEmployer && (
                    <FormLayout>
                        <Form.Item key="can_contact">
                            {getFieldDecorator('can_contact', {
                                initialValue: getInitialCanContactValue(),
                                rules: [
                                    {
                                        required: true,
                                        message: intl.formatMessage({
                                            id: 'error.validation.selectAContactPreference',
                                            defaultMessage: 'Please select a contact preference',
                                        }),
                                    },
                                ],
                            })(
                                <Select
                                    size="large"
                                    data-testid="current"
                                    placeholder={intl.formatMessage({
                                        id: 'welcome.Employers.canWeContactEmployer',
                                        defaultMessage: 'Can we contact this employer to verify?',
                                    })}
                                >
                                    <Select.Option data-testid="can_contact_employer" value="true">
                                        <FormattedMessage
                                            id="welcome.Employers.canContactEmployer"
                                            defaultMessage="Yes, you can contact this employer"
                                        />
                                    </Select.Option>
                                    <Select.Option data-testid="cannot_contact_employer" value="false">
                                        <FormattedMessage
                                            id="welcome.Employers.cannotContactEmployer"
                                            defaultMessage="No, you may not contact this employer"
                                        />
                                    </Select.Option>
                                </Select>
                            )}
                        </Form.Item>
                    </FormLayout>
                )}
                <TrackNavigation />
            </Form>
        </AppearRight>
    );
};

export default Form.create()(injectIntl(EmployerForm));
