import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';

// Components
import { ButtonLine } from 'certn-ui/Button';
import BackButton from 'views/welcome/components/BackButton';
import { NextButtonNoButtonLine } from 'views/welcome/components/NextButton';

// Actions & Selectors
import { getTrackPageOrder, getTrackPageLocation, getPrevTrack } from 'views/welcome/WelcomeSelectors';
import { patchWelcomeSession, setTrackPageLocation } from 'views/welcome/WelcomeActions';

const TrackNavigation = ({ disabled = false, ...props }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const trackPageOrder = useSelector(getTrackPageOrder);
    const trackPageLocation = useSelector(getTrackPageLocation);
    const prevTrack = useSelector(getPrevTrack);

    const handleReverse = () => {
        const currentPageIndex = trackPageOrder.indexOf(trackPageLocation);
        // Are we at base or the beginning of the track? Go to the previous track
        if (currentPageIndex === 0) {
            dispatch(patchWelcomeSession(prevTrack));
            history.push(`/welcome/${prevTrack}`);
        } else {
            // Otherwise go back in order of pages, example: adding an employer back to employers base
            const previousTrackPage = trackPageOrder[currentPageIndex - 1];
            dispatch(setTrackPageLocation(previousTrackPage));
        }
    };
    return (
        <ButtonLine dualButtons width="fit-content">
            {!props.hideBack && (
                <BackButton dualButtons type="secondary" {...props} onClick={props.handleReverse || handleReverse} />
            )}
            {!props.hideNext && (
                <NextButtonNoButtonLine dualButtons disabled={disabled} {...props} onClick={props.handleSubmit} />
            )}
        </ButtonLine>
    );
};

TrackNavigation.propsTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    hideBack: PropTypes.bool,
    hideNext: PropTypes.bool,
};

TrackNavigation.defaultProps = {
    onClick: null,
    disabled: false,
    loading: false,
    hideBack: false,
    hideNext: false,
};

export default TrackNavigation;
