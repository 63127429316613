// Libraries
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { message } from 'antd';
import { get } from 'lodash';

// Components
import { ErrorAlertCustom, ErrorAlertAPI } from 'certn-ui/ErrorAlert';
import ResponseSuccessMVR from 'views/welcome/components/SubmissionResponse/ResponseSuccessMVR';
import ResponseSuccess from 'views/welcome/components/SubmissionResponse/ResponseSuccess';
import { PageOne, PageTwoForm } from './components';

// Modules
import withNavigation from 'views/welcome/modules/WithNavigation';

// Actions & Selectors
import { submitWelcomeSession, resendVerifyEmail } from 'views/welcome/WelcomeActions';
import {
    getIsFetchingSilent,
    getInformation,
    getApplicantStatus,
    getApplicant,
    getSettings,
} from 'views/welcome/WelcomeSelectors';

// Constants
import { CHECK_REQUEST } from 'base/BaseConstants';

// TODO: make sure linked in changes to page four and remove history push to addresses
const Submit = (props) => {
    const dispatch = useDispatch();

    const settings = useSelector(getSettings);
    const applicant = useSelector(getApplicant);
    const information = useSelector(getInformation);
    const applicantStatus = useSelector(getApplicantStatus);
    const isFetchingSilent = useSelector(getIsFetchingSilent);

    const redirect = () => {
        setTimeout(() => {
            window.location.replace(settings.redirect_url);
        }, 3000);
    };

    const handleSubmit = (e, validateFields) => {
        e.preventDefault();
        validateFields(async (err, values) => {
            if (!err) {
                try {
                    await dispatch(submitWelcomeSession(values)).catch((error) => {
                        ErrorAlertAPI(error);
                    });
                    if (settings.redirect_url) {
                        message.success(`Finished application, redirecting to: ${settings.redirect_url}`);
                        redirect();
                    }
                } catch {
                    ErrorAlertCustom();
                }
            } else {
                // provide failure error
            }
        });
    };

    const resendEmail = async () => {
        try {
            await dispatch(resendVerifyEmail());
            message.success(
                'Email sent. Check your email for an email from support@certn.co and follow the link inside.'
            );
        } catch {
            ErrorAlertCustom({ title: 'Error sending email' });
        }
    };

    const pages = (status) => {
        switch (status) {
            case 'submitted':
                if (
                    get(applicant, [CHECK_REQUEST.MOTOR_VEHICLE_RECORDS]) &&
                    get(information, ['license_prov_state']) === 'BC'
                ) {
                    return (
                        <ResponseSuccessMVR
                            information={information}
                            isFetchingSilent={isFetchingSilent}
                            history={props.history}
                        />
                    );
                }
                return (
                    <ResponseSuccess
                        handleSubmit={handleSubmit}
                        information={information}
                        isFetchingSilent={isFetchingSilent}
                    />
                );
            case 'verified':
                return <PageTwoForm handleSubmit={handleSubmit} isFetchingSilent={isFetchingSilent} />;
            default:
                return (
                    <PageOne
                        handleSubmit={handleSubmit}
                        resendEmail={resendEmail}
                        information={information}
                        isFetchingSilent={isFetchingSilent}
                    />
                );
        }
    };

    return pages(applicantStatus);
};

export default withRouter(withNavigation(Submit));
