import styled from 'styled-components/macro';
import { Input } from 'antd';

const UserSearchBar = styled.div`
    display: flex;
    justify-content: end;
`;

const UserSearch = styled(Input)`
    max-width: 240px;
`;

const User = {
    UserSearch,
    UserSearchBar,
};

export default User;
