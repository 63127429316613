import React from 'react';

// UI components
import { AppearRight } from 'certn-ui/Animate';
import { TrackNavigation } from 'views/welcome/components';
import { CenterLine } from 'certn-ui/Layout';
import Title from 'certn-ui/Title';

import { FormattedMessage } from 'react-intl';

const PageOne = ({ handleSubmit }) => (
    <AppearRight>
        <Title>
            <FormattedMessage
                id="welcome.WelcomeBack.title"
                defaultMessage="Welcome back! We just need a few more pieces of information from you."
            />
        </Title>
        <CenterLine>
            <FormattedMessage id="welcome.WelcomeBack.nextMessage" defaultMessage="Select 'Next' to continue." />
        </CenterLine>
        <TrackNavigation handleSubmit={handleSubmit} />
    </AppearRight>
);

export default PageOne;
