// Libraries
import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Form as AntdForm } from '@ant-design/compatible';
import { intl } from 'components/GlobalProvider';
import { Input, Select } from 'antd';
import { EMPLOYMENT_SECTORS_OPTIONS } from 'utils/employmentSectorOptions';

// Components
import Form from 'certnd/Form';
import { SecondaryLink } from 'certn-ui/Text';
import PreviewTemplateModal from './PreviewTemplateModal';

// Actions & Selectors
import {
    getAdditionalOptions,
    getPackageSelected,
} from 'views/manager/features/ScreenApplicant/ScreenApplicantSelectors';
import {
    getCountries,
    getTeamSettingsConfig,
    getTeamCurrency,
    getUser,
    getConfigurablePotatoChecks,
} from 'base/BaseSelectors';
import {
    getEmployerReferenceTemplates,
    getAddressReferenceTemplates,
    getIntegrationPackageOptions,
} from 'views/manager/views/settings/SettingsSelectors';

import { INDIVIDUALLY } from 'views/manager/features/ScreenApplicant/ScreenApplicantConstants';
import { ScreenApplicantCheckSettingsContext } from '../../contexts';
import { ACIC_CHECK_REASONS } from './MicroServiceConstants';

const Description = styled.span`
    margin-bottom: 4px;
    font-size: 12px;
    line-height: 15px;
    color: ${({ theme }) => theme.color.certnGray500};
    @media (max-width: ${(props) => props.theme.width.tablet}) {
        display: none;
    }
`;

const Option = styled.div`
    flex: auto;
    display: flex;
    flex-direction: column;
    font-size: ${({ theme }) => theme.font.xxs};
    color: ${({ theme }) => theme.color.certnGray700};
    @media (max-width: ${(props) => props.theme.width.tablet}) {
        width: 100%;
    }
    @media (min-width: ${(props) => props.theme.width.desktop}) {
        ${({ marginRight }) => marginRight && 'margin-right: 15px'}
        ${({ marginLeft }) => marginLeft && 'margin-left: 15px'}
    }
`;

/* Check additional option components */

const InternationalCriminalRecordCheck = ({ form }) => {
    const requestedCountries = useSelector(getAdditionalOptions)?.requested_countries;
    const intCountries = useSelector(getCountries);
    const teamCurrency = useSelector(getTeamCurrency);
    const screenApplicantCheckSettingsContext = useContext(ScreenApplicantCheckSettingsContext);

    return (
        <Option>
            <div>
                <FormattedMessage
                    id="74e6c.AddOptions.screenHeaderNewFuF"
                    defaultMessage="Select countries to screen (optional)"
                />
            </div>
            <Description>
                <FormattedMessage
                    id="74e6c.AddOptions.screenHeaderSubTextNewFuF"
                    defaultMessage="If addresses are provided from a country not specified below, you will have to approve any charges above your price threshold in order to complete the check"
                />
            </Description>
            <Form.Select
                hideBorder
                mode="multiple"
                data-testid="requested_countries_dropdown"
                form={form}
                fieldName="requested_countries"
                options={{
                    initialValue: requestedCountries,
                }}
                optionLabelProp="label"
                optionFilterProp="label"
                placeholder={intl.formatMessage({
                    id: '74e6c.AddOptions.countriesPlaceholderNewFuF',
                    defaultMessage: 'Please select',
                })}
                selectOptions={intCountries?.map((country) => {
                    // Get value from upper-casing, then lower if undefined.
                    // API will eventually make these uppercase to be consistent (written 02/2021)
                    const cost = country[teamCurrency] || country[teamCurrency.toLowerCase()];

                    return {
                        key: country.country_code,
                        value: country.country_code,
                        label: country.country_name,
                        'data-testid': `selectOptions-${country.country_name}`,
                        title: (
                            <>
                                <span
                                    style={{ fontSize: '10px', marginRight: '4px' }}
                                    role="img"
                                    aria-label={country.country_name}
                                >
                                    {country.country_code}{' '}
                                </span>
                                {screenApplicantCheckSettingsContext.showPricing
                                    ? teamCurrency === 'GBP'
                                        ? `${country.country_name} +£${cost}`
                                        : `${country.country_name} +$${cost}`
                                    : `${country.country_name}`}
                            </>
                        ),
                    };
                })}
            />
        </Option>
    );
};

const CredentialVerification = ({ form }) => {
    const packageSelected = useSelector(getPackageSelected);
    const teamSettingsConfig = useSelector(getTeamSettingsConfig);
    const additionalOptions = useSelector(getAdditionalOptions);
    const packageOptions = useSelector(getIntegrationPackageOptions);
    const canOrderIndividualServices = useSelector(getUser).can_order_individual_services;

    let initialValue;
    if (packageSelected) {
        initialValue = additionalOptions?.credential_verification_level;
    } else if (packageOptions) {
        initialValue = packageOptions?.credential_verification_level;
    } else {
        initialValue = teamSettingsConfig?.credential_verification_level;
    }

    return (
        <Option>
            <div>
                <FormattedMessage
                    id="74e6c.AddOptions.verifyCredentialsHeader"
                    defaultMessage="Verify the following credential levels"
                />
            </div>
            <Form.Select
                hideBorder
                form={form}
                fieldName="credential_verification_level"
                disabled={!canOrderIndividualServices}
                options={{
                    initialValue: initialValue || 'ALL',
                    rules: [
                        {
                            required: true,
                            message: intl.formatMessage({
                                id: '74e6c.AddOptions.notBlank',
                                defaultMessage: 'Please do not leave blank',
                            }),
                        },
                    ],
                }}
                autoComplete="false"
                selectOptions={[
                    {
                        key: 'ALL',
                        value: 'ALL',
                        title: intl.formatMessage({
                            id: '74e6c.AddOptions.allCredentials',
                            defaultMessage: 'All credentials listed by applicant',
                        }),
                    },
                    {
                        key: 'HIGHEST',
                        value: 'HIGHEST',
                        title: intl.formatMessage({
                            id: '74e6c.AddOptions.highestCredentials',
                            defaultMessage: 'The highest level credential only',
                        }),
                    },
                ]}
            />
        </Option>
    );
};

const EducationVerification = ({ form }) => {
    const packageSelected = useSelector(getPackageSelected);
    const teamSettingsConfig = useSelector(getTeamSettingsConfig);
    const additionalOptions = useSelector(getAdditionalOptions);
    const packageOptions = useSelector(getIntegrationPackageOptions);
    const canOrderIndividualServices = useSelector(getUser).can_order_individual_services;

    let initialValue;
    if (packageSelected) {
        initialValue = additionalOptions?.education_verification_level;
    } else if (packageOptions) {
        initialValue = packageOptions?.education_verification_level;
    } else {
        initialValue = teamSettingsConfig?.education_verification_level;
    }

    return (
        <Option>
            <div>
                <FormattedMessage
                    id="74e6c.AddOptions.verifyEducationHeader"
                    defaultMessage="Verify the following education levels"
                />
            </div>
            <Form.Select
                hideBorder
                form={form}
                fieldName="education_verification_level"
                options={{
                    initialValue: initialValue || 'ALL',
                    rules: [
                        {
                            required: true,
                            message: intl.formatMessage({
                                id: '74e6c.AddOptions.notBlank',
                                defaultMessage: 'Please do not leave blank',
                            }),
                        },
                    ],
                }}
                disabled={!canOrderIndividualServices}
                autoComplete="false"
                selectOptions={[
                    {
                        key: 'ALL',
                        value: 'ALL',
                        title: intl.formatMessage({
                            id: '74e6c.AddOptions.allEducation',
                            defaultMessage: '***All education levels listed by applicant',
                        }),
                    },
                    {
                        key: 'HIGHEST',
                        value: 'HIGHEST',
                        title: intl.formatMessage({
                            id: '74e6c.AddOptions.highestEducation',
                            defaultMessage: 'The highest level of education only',
                        }),
                    },
                ]}
            />
        </Option>
    );
};

/**
 * Used by Employer and Address Reference email & phone services
 * @param {boolean} employer
 * @param {boolean} address
 * @param {string} serviceKey
 * @param {boolean} showPreviewTemplate exists because the PreviewTemplateModal will not work within the PackageModal
 * @returns reference template drop down and min/max or years selector component
 */
const References = ({ form, employer, address, serviceKey, showPreviewTemplate }) => {
    // Need to remove request_ and phone_ to get key name ex: employer_references_min
    let key = serviceKey.replace('request_', '');
    key = key.replace('phone_', '');

    let selector;
    let questionaireKey;
    if (employer) {
        selector = getEmployerReferenceTemplates;
        questionaireKey = `employer_questionaire_id`;
    }
    if (address) {
        selector = getAddressReferenceTemplates;
        questionaireKey = `address_questionaire_id`;
    }

    const additionalOptions = useSelector(getAdditionalOptions);
    const packageOptions = useSelector(getIntegrationPackageOptions);
    const referenceTemplates = useSelector(selector);
    const questionaire = additionalOptions?.[questionaireKey] || packageOptions?.[questionaireKey];
    const canOrderIndividualServices = useSelector(getUser).can_order_individual_services;
    const [firstReferenceTemplate] = referenceTemplates;
    const initialValue = questionaire || firstReferenceTemplate?.id;
    const getReferenceTemplate = (templateId) => referenceTemplates.find(({ id }) => id === templateId);
    const [selectedTemplate, setSelectedTemplate] = useState(getReferenceTemplate(initialValue));
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            {showPreviewTemplate && (
                <PreviewTemplateModal
                    isVisible={showModal}
                    setIsVisible={setShowModal}
                    template={selectedTemplate}
                    serviceKey={serviceKey}
                />
            )}
            <Option style={{ width: '100%' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span>
                        <FormattedMessage id="74e6c.AddOptions.templateHeader" defaultMessage="Reference template" />
                    </span>
                    {showPreviewTemplate && (
                        <SecondaryLink onClick={() => setShowModal(true)}>
                            <FormattedMessage id="74e6c.AddOptions.templatePreview" defaultMessage="Preview" />
                        </SecondaryLink>
                    )}
                </div>
                <Form.Select
                    hideBorder
                    form={form}
                    fieldName={questionaireKey}
                    disabled={!canOrderIndividualServices}
                    options={{
                        initialValue,
                        rules: [
                            {
                                required: true,
                                message: intl.formatMessage({
                                    id: '74e6c.AddOptions.notBlank',
                                    defaultMessage: 'Please do not leave blank',
                                }),
                            },
                        ],
                    }}
                    placeholder={intl.formatMessage({
                        id: '74e6c.AddOptions.selectOption',
                        defaultMessage: 'Select an Option',
                    })}
                    autoComplete="false"
                    selectOptions={referenceTemplates?.map((template) => ({
                        key: template.id,
                        value: template.id,
                        title: template.name,
                    }))}
                    onChange={(templateId) => setSelectedTemplate(getReferenceTemplate(templateId))}
                />
            </Option>
            <DateRangeOrMinMaxSelector key={serviceKey} form={form} service={key} />
        </>
    );
};

/**
 * Used by US Crim tier 3 to add decide between 7 (default) and 10 year check
 * @param {string} serviceKey
 * @returns drop down with 7/10 year select input
 */
const USCriminalRecordCheck = ({ form }) => {
    const initialValue = useSelector(getAdditionalOptions)?.us_criminal_record_check_years;
    const canOrderIndividualServices = useSelector(getUser).can_order_individual_services;

    return (
        <Option>
            <div>
                <FormattedMessage
                    id="74e6c.AddOptions.usCrimSearchDateRangeSelect"
                    defaultMessage="Select the number of years to search"
                />
            </div>
            <Form.Select
                hideBorder
                form={form}
                fieldName="us_criminal_record_check_years"
                disabled={!canOrderIndividualServices}
                options={{
                    initialValue: initialValue || 7,
                    rules: [
                        {
                            required: true,
                            message: intl.formatMessage({
                                id: '74e6c.AddOptions.notBlank',
                                defaultMessage: 'Please do not leave blank',
                            }),
                        },
                    ],
                }}
                style={{ width: '100%' }}
                placeholder={intl.formatMessage({
                    id: '74e6c.AddOptions.selectOption',
                    defaultMessage: 'Select an Option',
                })}
                autoComplete="false"
                selectOptions={[
                    { key: 7, value: 7, title: 7 },
                    { key: 10, value: 10, title: 10 },
                ]}
            />
        </Option>
    );
};

const EmploymentVerification = ({ form }) => (
    <DateRangeOrMinMaxSelector form={form} service="employment_verification" />
);

const ConfigurablePotatoCheck = ({ form, serviceKey }) => {
    // get the potato configuration
    const potatoConfigs = useSelector(getConfigurablePotatoChecks);
    const serviceConfigurations = potatoConfigs.find((config) => config.request === serviceKey);
    return (
        <>
            {serviceConfigurations?.configurations?.map((configuration) => (
                <CustomSelect
                    key={configuration.name}
                    form={form}
                    fieldLabel={configuration.display_name}
                    fieldName="potato_check_configuration"
                    selectOptions={configuration.choices?.map((choice) => ({
                        key: choice.val,
                        value: JSON.stringify({ [serviceKey]: [{ name: configuration.name, value: choice.val }] }),
                        title: choice.label,
                    }))}
                />
            ))}
        </>
    );
};

const DBSCheck = ({ form }) => {
    const initialValue_employment_sector = useInitialValue('employment_sector');
    const initialValue_role_or_position_title = useInitialValue('role_or_position_title');

    return (
        <AntdForm layout="vertical" style={{ width: '100%' }}>
            <Option>
                <AntdForm.Item
                    label={
                        <FormattedMessage id="74e6c.AddOptions.employmentSector" defaultMessage="Employment Sector" />
                    }
                >
                    {form.getFieldDecorator('employment_sector', {
                        initialValue: initialValue_employment_sector,
                        rules: [
                            {
                                required: true,
                                message: 'Please provide the sector the candidate will work in',
                            },
                        ],
                    })(
                        <Select
                            hideBorder
                            autoComplete="false"
                            style={{ width: '100%' }}
                            data-testid="employment_sector"
                            placeholder={intl.formatMessage({
                                id: '74e6c.AddOptions.PleaseSelect',
                                defaultMessage: 'Please select',
                            })}
                        >
                            {EMPLOYMENT_SECTORS_OPTIONS.map(({ key, title }) => (
                                <Select.Option key={key} value={key}>
                                    {intl.formatMessage(title)}
                                </Select.Option>
                            ))}
                        </Select>
                    )}
                </AntdForm.Item>
            </Option>

            <Option>
                <AntdForm.Item
                    label={
                        <FormattedMessage
                            id="74e6c.AddOptions.candidatesRoleOrPositionTitle"
                            defaultMessage="Candidate's Role or Position Title"
                        />
                    }
                >
                    {form.getFieldDecorator('role_or_position_title', {
                        initialValue: initialValue_role_or_position_title,
                        rules: [
                            {
                                required: true,
                                message: 'Please provide the role or position title the candidate will work in.',
                            },
                            { max: 60, message: 'Title cannot be longer than 60 character' },
                        ],
                    })(
                        <Input.TextArea
                            data-testid="role_or_position_title"
                            showCount
                            maxLength={60}
                            style={{
                                height: 32,
                            }}
                        />
                    )}
                </AntdForm.Item>
            </Option>
        </AntdForm>
    );
};

const VolunteerAcicCheck = ({ form }) => {
    const initialValue_volunteer_role = useInitialValue('volunteer_role');
    const initialValue_volunteer_organization = useInitialValue('volunteer_organization');

    return (
        <AntdForm layout="vertical" style={{ width: '100%' }}>
            <Option>
                <Form.Input
                    label={intl.formatMessage({
                        id: '74e6c.AddOptions.acicVolunteerRoleTitle',
                        defaultMessage: 'Relevant volunteer role, student placement role or course name',
                    })}
                    hideBorder
                    form={form}
                    fieldName="volunteer_role"
                    options={{
                        initialValue: initialValue_volunteer_role,
                    }}
                    style={{ width: '100%' }}
                    autoComplete="false"
                />
            </Option>
            <Option>
                <Form.Input
                    label={intl.formatMessage({
                        id: '74e6c.AddOptions.acicVolunteerOrganizationTitle',
                        defaultMessage: 'Name of the volunteer organization or university/institute',
                    })}
                    hideBorder
                    form={form}
                    fieldName="volunteer_organization"
                    options={{
                        initialValue: initialValue_volunteer_organization,
                    }}
                    style={{ width: '100%' }}
                    autoComplete="false"
                />
            </Option>
            <Option>
                <Form.Input
                    hideBorder
                    form={form}
                    fieldName="purpose_of_check"
                    options={{
                        initialValue: ACIC_CHECK_REASONS.OTHER,
                    }}
                    style={{ display: 'none' }}
                    autoComplete="false"
                />
            </Option>
        </AntdForm>
    );
};

const AcicCheck = ({ form }) => {
    const initialValue_purpose_of_check = useInitialValue('purpose_of_check');
    const initialValue_position_title = useInitialValue('position_title');
    const initialValue_place_of_work = useInitialValue('place_of_work');
    const initialValue_license_type = useInitialValue('license_type');
    const initialValue_issuing_authority = useInitialValue('issuing_authority');
    const initialValue_reason_for_check = useInitialValue('reason_for_check');

    return (
        <AntdForm layout="vertical" style={{ width: '100%' }}>
            <Option>
                <div>
                    <FormattedMessage id="74e6c.AddOptions.acicPurposeOfCheckTitle" defaultMessage="Purpose of check" />
                </div>
                <Form.Select
                    hideBorder
                    form={form}
                    fieldName="purpose_of_check"
                    autoComplete="false"
                    style={{ width: '100%' }}
                    placeholder={intl.formatMessage({
                        id: '74e6c.AddOptions.PleaseSelect',
                        defaultMessage: 'Please select',
                    })}
                    options={{
                        initialValue: initialValue_purpose_of_check,
                    }}
                    selectOptions={[
                        {
                            key: ACIC_CHECK_REASONS.EMPLOYMENT,
                            value: ACIC_CHECK_REASONS.EMPLOYMENT,
                            title: intl.formatMessage({
                                id: '74e6c.AddOptions.employment',
                                defaultMessage: 'Employment',
                            }),
                        },
                        {
                            key: ACIC_CHECK_REASONS.LICENSE,
                            value: ACIC_CHECK_REASONS.LICENSE,
                            title: intl.formatMessage({
                                id: '74e6c.AddOptions.License',
                                defaultMessage: 'License',
                            }),
                        },
                        {
                            key: ACIC_CHECK_REASONS.OTHER,
                            value: ACIC_CHECK_REASONS.OTHER,
                            title: intl.formatMessage({
                                id: '74e6c.AddOptions.Other',
                                defaultMessage: 'Other (Position of Trust/Probity)',
                            }),
                        },
                    ]}
                />
            </Option>
            {form.getFieldValue('purpose_of_check') === ACIC_CHECK_REASONS.EMPLOYMENT ? (
                <>
                    <Option>
                        <Form.Input
                            label={intl.formatMessage({
                                id: '74e6c.AddOptions.acicRoleOrPositionsTitle',
                                defaultMessage: 'Role or Position Title',
                            })}
                            hideBorder
                            form={form}
                            fieldName="position_title"
                            options={{
                                initialValue: initialValue_position_title,
                            }}
                            style={{ width: '100%' }}
                            autoComplete="false"
                        />
                    </Option>
                    <Option>
                        <Form.Input
                            label={intl.formatMessage({
                                id: '74e6c.AddOptions.acicOrgTypeOfWorkOrPlaceOfWork',
                                defaultMessage: 'Organization, Type of Work or Place of Work',
                            })}
                            hideBorder
                            form={form}
                            fieldName="place_of_work"
                            options={{
                                initialValue: initialValue_place_of_work,
                            }}
                            style={{ width: '100%' }}
                            autoComplete="false"
                        />
                    </Option>
                </>
            ) : form.getFieldValue('purpose_of_check') === ACIC_CHECK_REASONS.LICENSE ? (
                <>
                    <Option>
                        <Form.Input
                            label={intl.formatMessage({
                                id: '74e6c.AddOptions.acicLicenseType',
                                defaultMessage: 'License Type',
                            })}
                            hideBorder
                            form={form}
                            fieldName="license_type"
                            options={{
                                initialValue: initialValue_license_type,
                            }}
                            style={{ width: '100%' }}
                            autoComplete="false"
                        />
                    </Option>
                    <Option>
                        <Form.Input
                            label={intl.formatMessage({
                                id: '74e6c.AddOptions.acicIssuingAuthority',
                                defaultMessage: 'Issuing Authority',
                            })}
                            hideBorder
                            form={form}
                            fieldName="issuing_authority"
                            options={{
                                initialValue: initialValue_issuing_authority,
                            }}
                            style={{ width: '100%' }}
                            autoComplete="false"
                        />
                    </Option>
                </>
            ) : form.getFieldValue('purpose_of_check') === ACIC_CHECK_REASONS.OTHER ? (
                <>
                    <Option>
                        <Form.Input
                            label={intl.formatMessage({
                                id: '74e6c.AddOptions.acicReasonForCheck',
                                defaultMessage: 'Reason for Check',
                            })}
                            hideBorder
                            form={form}
                            fieldName="reason_for_check"
                            options={{
                                initialValue: initialValue_reason_for_check,
                            }}
                            style={{ width: '100%' }}
                            autoComplete="false"
                        />
                    </Option>
                    <Option>
                        <Form.Input
                            label={intl.formatMessage({
                                id: '74e6c.AddOptions.acicRequestingOrganization',
                                defaultMessage: 'Requesting Organization',
                            })}
                            hideBorder
                            form={form}
                            fieldName="place_of_work"
                            options={{
                                initialValue: initialValue_place_of_work,
                            }}
                            style={{ width: '100%' }}
                            autoComplete="false"
                        />
                    </Option>
                </>
            ) : null}
        </AntdForm>
    );
};

/* Additional option fields */

/**
 * Lets user choose min/max or previous years of screening service
 * @param {string} service
 * @returns Min/Max || DateRange components
 */
const DateRangeOrMinMaxSelector = ({ form, service }) => {
    const [individually, setIndividually] = useState(true);
    const toggleIndividually = () => setIndividually(!individually);
    const canOrderIndividualServices = useSelector(getUser).can_order_individual_services;

    const fieldName = `${service}_years_or_individually`;
    const initialValue = useInitialValue(fieldName);
    const currentValue = form.getFieldValue(fieldName) || initialValue;

    return (
        <>
            <Option marginRight>
                <div>
                    <FormattedMessage
                        id="74e6c.AddOptions.individualOrDateHeader"
                        defaultMessage="Individually or by date range"
                    />
                </div>
                <Form.Select
                    hideBorder
                    form={form}
                    fieldName={fieldName}
                    disabled={!canOrderIndividualServices}
                    options={{
                        initialValue: initialValue || INDIVIDUALLY,
                        rules: [
                            {
                                required: true,
                                message: intl.formatMessage({
                                    id: '74e6c.AddOptions.notBlank',
                                    defaultMessage: 'Please do not leave blank',
                                }),
                            },
                        ],
                    }}
                    autoComplete="false"
                    style={{ width: '100%' }}
                    selectOptions={[
                        {
                            key: INDIVIDUALLY,
                            value: INDIVIDUALLY,
                            title: intl.formatMessage({
                                id: '74e6c.AddOptions.individuallyLower',
                                defaultMessage: 'Individually',
                            }),
                        },
                        {
                            key: 'YEARS',
                            value: 'YEARS',
                            title: intl.formatMessage({
                                id: '74e6c.AddOptions.dateRange',
                                defaultMessage: 'Date Range',
                            }),
                        },
                    ]}
                    onChange={toggleIndividually}
                />
            </Option>
            {currentValue === INDIVIDUALLY ? (
                <>
                    <Min form={form} fieldName={`${service}_min`} />
                    <Max form={form} fieldName={`${service}_max`} />
                </>
            ) : (
                <DateRange form={form} fieldName={`${service}_years`} />
            )}
        </>
    );
};

/**
 * Select component for years
 * @param {string} fieldName
 * @param {string} {initialValue = useSelector(getAdditionalOptions)?.[fieldName]}
 */
const DateRange = ({ form, fieldName }) => {
    const initialValue = useInitialValue(fieldName);
    const canOrderIndividualServices = useSelector(getUser).can_order_individual_services;

    return (
        <Option>
            <div>
                <FormattedMessage
                    id="74e6c.AddOptions.dateRangeVerificationsHeader"
                    defaultMessage="Date Range for verifications (previous years)"
                />
            </div>
            <Form.Select
                hideBorder
                form={form}
                fieldName={fieldName}
                disabled={!canOrderIndividualServices}
                options={{
                    initialValue: initialValue || 3,
                    rules: [
                        {
                            required: true,
                            message: intl.formatMessage({
                                id: '74e6c.AddOptions.notBlank',
                                defaultMessage: 'Please do not leave blank',
                            }),
                        },
                    ],
                }}
                style={{ width: '100%' }}
                placeholder={intl.formatMessage({
                    id: '74e6c.AddOptions.selectOption',
                    defaultMessage: 'Select an Option',
                })}
                autoComplete="false"
                selectOptions={[
                    { key: 3, value: 3, title: 3 },
                    { key: 5, value: 5, title: 5 },
                    { key: 7, value: 7, title: 7 },
                    { key: 10, value: 10, title: 10 },
                ]}
            />
        </Option>
    );
};

const CustomSelect = ({ form, fieldName, fieldLabel, selectOptions }) => {
    const canOrderIndividualServices = useSelector(getUser).can_order_individual_services;
    const initialValue = selectOptions?.[0]?.value;
    return (
        <Option>
            <div>{fieldLabel}</div>
            <Form.Select
                hideBorder
                form={form}
                fieldName={fieldName}
                disabled={!canOrderIndividualServices}
                options={{
                    initialValue,
                    rules: [
                        {
                            required: true,
                            message: intl.formatMessage({
                                id: '74e6c.AddOptions.notBlank',
                                defaultMessage: 'Please do not leave blank',
                            }),
                        },
                    ],
                }}
                style={{ width: '100%' }}
                placeholder={intl.formatMessage({
                    id: '74e6c.AddOptions.selectOption',
                    defaultMessage: 'Select an Option',
                })}
                autoComplete="false"
                selectOptions={selectOptions}
            />
        </Option>
    );
};
/**
 * Input Number component for minimum number of x/y
 * @param {string} fieldName
 * @param {string} {initialValue = useSelector(getAdditionalOptions)?.[fieldName]}
 */
const Min = ({ form, fieldName }) => {
    const initialValue = useInitialValue(fieldName);
    const canOrderIndividualServices = useSelector(getUser).can_order_individual_services;
    return (
        <Option>
            <div>
                {fieldName === 'employment_verification_min' ? (
                    <FormattedMessage
                        id="74e6c.AddOptions.minHeaderEmployer"
                        defaultMessage="Min employer(s) required"
                    />
                ) : (
                    <FormattedMessage
                        id="74e6c.AddOptions.minHeaderReference"
                        defaultMessage="Min reference(s) required"
                    />
                )}
            </div>
            <Form.InputNumber
                hideBorder
                form={form}
                fieldName={fieldName}
                disabled={!canOrderIndividualServices}
                options={{
                    initialValue: initialValue || 1,
                    rules: [
                        {
                            required: true,
                            message: intl.formatMessage({
                                id: '74e6c.AddOptions.notBlank',
                                defaultMessage: 'Please do not leave blank',
                            }),
                        },
                    ],
                }}
                style={{ width: '100%' }}
                placeholder={intl.formatMessage({
                    id: '74e6c.AddOptions.selectOption',
                    defaultMessage: 'Select an Option',
                })}
                autoComplete="false"
                min={1}
                max={form.getFieldValue(`${fieldName.replace('min', 'max')}`) || 10} // block increase if Max <= Min
            />
        </Option>
    );
};

/**
 * Input Number component for maximum number of x/y
 * @param {string} fieldName
 * @param {string} {initialValue = useSelector(getAdditionalOptions)?.[fieldName]}
 */
const Max = ({ form, fieldName }) => {
    const initialValue = useInitialValue(fieldName);
    const canOrderIndividualServices = useSelector(getUser).can_order_individual_services;

    return (
        <Option marginLeft>
            <div>
                {fieldName === 'employment_verification_max' ? (
                    <FormattedMessage
                        id="74e6c.AddOptions.maxHeaderEmployer"
                        defaultMessage="Max employer(s) required"
                    />
                ) : (
                    <FormattedMessage
                        id="74e6c.AddOptions.maxHeaderReference"
                        defaultMessage="Max reference(s) required"
                    />
                )}
            </div>
            <Form.InputNumber
                hideBorder
                form={form}
                fieldName={fieldName}
                disabled={!canOrderIndividualServices}
                options={{
                    initialValue: initialValue || 1,
                    rules: [
                        {
                            required: true,
                            message: intl.formatMessage({
                                id: '74e6c.AddOptions.notBlank',
                                defaultMessage: 'Please do not leave blank',
                            }),
                        },
                    ],
                }}
                style={{ width: '100%' }}
                placeholder={intl.formatMessage({
                    id: '74e6c.AddOptions.selectOption',
                    defaultMessage: 'Select an Option',
                })}
                autoComplete="false"
                min={form.getFieldValue(`${fieldName.replace('max', 'min')}`) || 1} // block decrease if Min <= Max
                max={10}
            />
        </Option>
    );
};

const useInitialValue = (fieldName) => {
    const teamSettingsConfig = useSelector(getTeamSettingsConfig);
    const additionalOptions = useSelector(getAdditionalOptions);
    const packageOptions = useSelector(getIntegrationPackageOptions);
    const additionalOptionsExist = additionalOptions?.[fieldName];

    let initialValue;
    if (additionalOptionsExist) {
        initialValue = additionalOptions?.[fieldName];
    } else if (packageOptions?.[fieldName]) {
        initialValue = packageOptions[fieldName];
    } else {
        initialValue = teamSettingsConfig?.[fieldName];
    }
    return initialValue;
};

const MicroServiceAddOptions = {
    InternationalCriminalRecordCheck,
    CredentialVerification,
    EducationVerification,
    References,
    EmploymentVerification,
    USCriminalRecordCheck,
    AcicCheck,
    VolunteerAcicCheck,
    DBSCheck,
    ConfigurablePotatoCheck,
};

export default MicroServiceAddOptions;
