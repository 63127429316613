// Libraries
import React from 'react';
import { Alert } from 'antd';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { InfoCircleOutlined, HourglassOutlined } from '@ant-design/icons';
import styled from 'styled-components/macro';

// Styles
import styles from 'styles/styles';

// Components
import { ErrorAlertAPI } from 'certn-ui/ErrorAlert';
import { Box } from 'certn-ui/Layout';
import { get } from 'lodash';
import { LoginForm } from './components';
import Titlebar from 'certn-ui/Titlebar';
import Auth from 'modules/Auth';
import { getUserMode } from 'modules/UserMode';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

// Actions & Selectors
import { clearLogin } from 'views/guest/GuestActions';
import { loginBasic, logout, loginWithMagicLink } from 'base/BaseActions';
import { getIsFetchingSilent, getParams, getUserModeIsHR, getLaunchDarklyFlags } from 'base/BaseSelectors';

const mapStateToProps = (state) => ({
    isFetchingSilent: getIsFetchingSilent(state),
    params: getParams(state),
    userModeIsHR: getUserModeIsHR(state),
    maintenance_message_flag: getLaunchDarklyFlags(state)?.webFeatureEnableMaintenanceNotificationBanner,
});
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            loginBasic,
            logout,
            clearLogin,
            loginWithMagicLink,
        },
        dispatch
    );

const propTypes = {
    // Redux Actions
    loginBasic: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    clearLogin: PropTypes.func.isRequired,
    loginWithMagicLink: PropTypes.func.isRequired,
    // Redux State
    params: PropTypes.shape({
        magic: PropTypes.string,
        type: PropTypes.string,
    }),
    isFetchingSilent: PropTypes.bool.isRequired,
};
const defaultProps = {
    params: {
        magic: '',
        type: '',
    },
};
const StyledAlert = styled(Alert)`
    text-align: left !important;
    max-width: 650px;
    margin: 30px auto !important;
`;

const SessionTimeoutAlert = styled(Alert)`
    text-align: left !important;
    max-width: 650px;
    margin-bottom: 30px !important;
`;

class Login extends React.Component {
    componentDidMount() {
        // Redirects to Applications page if already logged in
        if (Auth.isUserAuthenticated()) {
            const userMode = getUserMode();
            if (userMode === 'HR' || userMode === 'PM')
                return this.props.history.push(`/${userMode.toLowerCase()}/applications`);
            return this.props.history.push(`/${Auth.isHrUser() ? 'hr' : 'pm'}/applications`);
        }

        const type = get(this.props, ['params', 'type']);
        const magic = get(this.props, ['params', 'magic']);
        if (type && magic) {
            this.props.loginWithMagicLink(magic, type).then((response) => {
                if (response.authenticated) {
                    this.props.history.push(`/${type.toLowerCase()}/applications`);
                }
            });
        }
    }

    componentWillUnmount() {
        this.props.clearLogin();
    }

    handleLogin = (authForm) => {
        const fromUrl = get(this.props, ['location', 'state', 'from', 'pathname']);
        const queryParams = this.props.location?.state?.from?.search || '';
        const fullUrl = fromUrl + queryParams;
        this.props
            .loginBasic({ loginForm: authForm })
            .then(() => {
                this.props.history.push(fullUrl);
            })
            .catch((error) => {
                ErrorAlertAPI(error);
            });
    };

    render() {
        const showSessionTimeoutMessage = get(this.props, ['location', 'state', 'sessionTimeout']);

        return (
            <>
                {this.props.maintenance_message_flag && (
                    <Box size="s">
                        <StyledAlert
                            banner
                            message="Scheduled Maintenance"
                            description={
                                <p>
                                    Certn is currently performing critical maintenance which will impact performance for
                                    all clients on July 30th. The purpose of this scheduled maintenance is to promote
                                    greater security, performance, and scalability. For more information, see our{' '}
                                    <a
                                        href="https://help.certn.co/hc/en-us/articles/7799245155603"
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        style={{ color: styles.color.black }}
                                    >
                                        <u>Help Desk Article</u>
                                    </a>
                                    .
                                </p>
                            }
                            type="warning"
                            showIcon
                            icon={<InfoCircleOutlined style={{ color: styles.color.certnOrange500 }} />}
                        />
                    </Box>
                )}
                <Titlebar
                    size="xlrg"
                    center
                    title={this.props.intl.formatMessage({
                        id: 'a3d95.Login.title',
                        defaultMessage: 'Login to Certn',
                    })}
                    subtitle={this.props.intl.formatMessage({
                        id: 'a3d95.Login.subtitle',
                        defaultMessage:
                            'Certn helps property managers, employers, and financial institutions identify, assess, and manage applicants to find the best fit',
                    })}
                />
                {showSessionTimeoutMessage && (
                    <Box size="xs">
                        <SessionTimeoutAlert
                            message={
                                <FormattedMessage id="SessionTimeoutAlert.message" defaultMessage="Session Expired" />
                            }
                            description={
                                <FormattedMessage
                                    id="SessionTimeoutAlert.description"
                                    defaultMessage="You have been signed out due to inactivity. Please login to resume your session."
                                />
                            }
                            type="warning"
                            showIcon
                            icon={<HourglassOutlined style={{ color: styles.color.certnOrange500 }} />}
                        />
                    </Box>
                )}
                <LoginForm onSubmit={this.handleLogin} loading={this.props.isFetchingSilent} />
            </>
        );
    }
}

Login.propTypes = propTypes;
Login.defaultProps = defaultProps;

export default withLDConsumer()(withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(Login))));
