// Libraries
import React, { useState } from 'react';
import { Button, Popconfirm } from 'antd';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components/macro';
import { PlusOutlined } from '@ant-design/icons';

// Components
import Text, { SecondaryLink } from 'certn-ui/Text';

const Container = styled.div`
    min-height: 71px;
    max-width: 580px;
    display: flex;
    justify-content: space-between;
    border: 1px solid ${({ theme }) => theme.color.certnGray400};
    border-radius: 5px;
    padding: 16px 24px;
    margin: 20px 0px;
    ${({ centerContent }) => centerContent && 'align-items: center;'}
    ${({ theme, hasBackground }) => hasBackground && `background-color: ${theme.color.certnGray100};`}
`;

const LeftContent = styled.div`
    height: 100%;
    display: flex;
    align-items: ${({ centerContent }) => (centerContent ? 'center' : 'start')};
    ${({ flexColumn }) => flexColumn && 'flex-direction: column;'}
`;

// background-color of Icon is using 8 digit hex colour notation to adjust the opacity to 10% of the primary colour
const Icon = styled.div`
    display: flex;
    align-items: center;
    font-weight: 600;
    background-color: ${({ theme }) => `${theme['primary-color']}20`};
    color: ${({ theme }) => theme['primary-color']};
    padding: ${({ padding }) => padding || '2px 9px'};
    margin-right: 8px;
    border-radius: 4px;
`;

const ReferenceDetails = ({ reference, onEdit, onDelete }) => {
    const [visible, setVisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);

    const showPopconfirm = () => {
        setVisible(true);
    };

    const handleOk = () => {
        setConfirmLoading(true);
        onDelete(reference.id);
        setTimeout(() => {
            setVisible(false);
            setConfirmLoading(false);
        }, 2000);
    };

    const handleCancel = () => {
        setVisible(false);
    };

    return (
        <Container hasBackground>
            <LeftContent flexColumn>
                <Text weight="600">{`${reference.first_name} ${reference.last_name}`}</Text>
                <Text fontStyle="italic" size="xxs">
                    {reference.company_name}
                </Text>
                <Text size="xxs">{reference.email}</Text>
            </LeftContent>
            <div>
                <SecondaryLink style={{ marginRight: '8px' }} onClick={() => onEdit(reference)}>
                    <FormattedMessage id="common.edit" defaultMessage="Edit" />
                </SecondaryLink>
                <Popconfirm
                    title={
                        <FormattedMessage id="multiAddItem.deleteThisEmployer" defaultMessage="Delete this employer?" />
                    }
                    placement="bottomRight"
                    visible={visible}
                    onConfirm={handleOk}
                    okButtonProps={{ loading: confirmLoading }}
                    onCancel={handleCancel}
                    okText={<FormattedMessage id="common.yes" defaultMessage="Yes" />}
                    cancelText={<FormattedMessage id="common.no" defaultMessage="No" />}
                >
                    <SecondaryLink onClick={showPopconfirm}>
                        <FormattedMessage id="common.delete" defaultMessage="Delete" />
                    </SecondaryLink>
                </Popconfirm>
            </div>
        </Container>
    );
};

const AddReference = ({ onAdd, index, description }) => (
    <Container centerContent>
        <LeftContent centerContent>
            {index === null ? (
                <>
                    <Icon padding="6px">
                        <PlusOutlined />
                    </Icon>
                    <Text>{description}</Text>
                </>
            ) : (
                <>
                    <Icon>{index + 1}</Icon>
                    <Text>{description}</Text>
                </>
            )}
        </LeftContent>
        <Button
            style={{ textTransform: 'uppercase' }}
            key="submit"
            data-testid="add-reference"
            type="primary"
            htmlType="submit"
            onClick={onAdd}
        >
            <FormattedMessage id="welcome.EmployerReference.addReference" defaultMessage="Add Reference" />
        </Button>
    </Container>
);

const EmployerReferenceListItem = ({ index, onAdd, onEdit, onDelete, reference, description }) =>
    reference ? (
        <ReferenceDetails reference={reference} onEdit={onEdit} onDelete={onDelete} />
    ) : (
        <AddReference index={index} onAdd={onAdd} description={description} />
    );

EmployerReferenceListItem.propTypes = {
    index: PropTypes.number,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
    reference: PropTypes.object,
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

EmployerReferenceListItem.defaultProps = {
    index: null,
    onEdit: () => {},
    onDelete: () => {},
    reference: null,
    description: '',
};

export default EmployerReferenceListItem;
