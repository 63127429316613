// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

// Components
import UploadRow from '../../../components/UploadRow';

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: auto;
    > * {
        margin: 8px;
    }
`;

const docNameMap = {
    COVER_LETTER: 'Cover Letter',
    GOVERNMENT_ID: 'Government Id',
    RESUME: 'Resume',
    PROOF_OF_INCOME: 'Proof Of Income',
    VISA: 'Visa (Work, Immigration, Permanent Resident)',
    OTHER: 'Other',
    PASSPORT: 'Passport ID',
    PROOF_OF_ADDRESS: 'Proof Of Address',
};

const propTypes = {
    s3UploadLink: PropTypes.string,
    s3UploadFields: PropTypes.object,
    s3GetUploadLink: PropTypes.func.isRequired,
    fileList: PropTypes.array.isRequired,
    fileProgress: PropTypes.object.isRequired,
    onUpload: PropTypes.func.isRequired,
    handleProgress: PropTypes.func.isRequired,
    handleDeleteDocument: PropTypes.func.isRequired,
    complete: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    missingDocuments: PropTypes.array.isRequired,
    requiredDocuments: PropTypes.array.isRequired,
    docsUploadedNotReq: PropTypes.array.isRequired,
};

const defaultProps = {
    s3UploadLink: null,
    s3UploadFields: null,
};

export const DocumentsUploadLayout = ({
    s3UploadLink,
    s3UploadFields,
    s3GetUploadLink,
    fileList,
    fileProgress,
    onUpload,
    handleProgress,
    handleDeleteDocument,
    complete,
    error,
    missingDocuments,
    requiredDocuments,
    docsUploadedNotReq,
    docsUploadedReq,
}) => (
    <UploadRow.UploadsWrapper>
        {docsUploadedNotReq.length > 0 && (
            <UploadRow.ManyUploadBox>
                {docsUploadedNotReq.map((doc) => (
                    <UploadRow
                        doc={doc}
                        name={doc.name}
                        missing={false}
                        file={doc}
                        s3UploadLink={s3UploadLink}
                        s3UploadFields={s3UploadFields}
                        s3GetUploadLink={s3GetUploadLink}
                        fileList={fileList}
                        fileProgress={fileProgress}
                        onUpload={onUpload}
                        handleProgress={handleProgress}
                        handleDeleteDocument={handleDeleteDocument}
                    />
                ))}
            </UploadRow.ManyUploadBox>
        )}
        {requiredDocuments.length > 0 && (
            <UploadRow.ManyReqUploadBox complete={complete} error={error}>
                {requiredDocuments.map((doc) => {
                    const name = docNameMap[doc] || doc; // Get readable name from map above
                    const missing = missingDocuments.includes(doc);
                    const file = docsUploadedReq.find((item) => item.type === doc);
                    return (
                        <UploadRow
                            key={doc}
                            doc={doc}
                            name={name}
                            missing={missing}
                            file={file}
                            s3UploadLink={s3UploadLink}
                            s3UploadFields={s3UploadFields}
                            s3GetUploadLink={s3GetUploadLink}
                            fileList={fileList}
                            fileProgress={fileProgress}
                            onUpload={onUpload}
                            handleProgress={handleProgress}
                            handleDeleteDocument={handleDeleteDocument}
                        />
                    );
                })}
            </UploadRow.ManyReqUploadBox>
        )}
    </UploadRow.UploadsWrapper>
);

DocumentsUploadLayout.propTypes = propTypes;
DocumentsUploadLayout.defaultProps = defaultProps;

DocumentsUploadLayout.Wrapper = Wrapper;
export default DocumentsUploadLayout;
