import { useIntl } from 'react-intl';
import { Form, Input, Popover } from 'antd';
import { Option } from '../PurposeFormStyles/styled';
import { InfoCircleOutlined } from '@ant-design/icons';

interface PositionTitleAndPlaceOfWork {
    isPositionTitleDisabled: boolean;
    isPlaceOfWorkDisabled: boolean;
}

export const PositionTitleAndPlaceOfWork: React.FC<PositionTitleAndPlaceOfWork> = ({
    isPositionTitleDisabled,
    isPlaceOfWorkDisabled,
}) => {
    const intl = useIntl();
    return (
        <Option>
            <Form.Item
                name="position_title"
                rules={[
                    {
                        required: true,
                        message: intl.formatMessage({
                            id: '74e6c.AddOptions.notBlank',
                            defaultMessage: 'Please do not leave blank',
                        }),
                    },
                ]}
            >
                <Input
                    disabled={isPositionTitleDisabled}
                    placeholder={intl.formatMessage({
                        id: '74e6c.AddOptions.acicRoleOrPositionsTitle',
                        defaultMessage: 'Role or Position Title',
                    })}
                    addonBefore={
                        <Popover
                            content={intl.formatMessage({
                                id: '74e6c.AddOptions.acicRoleOrPositionsTitle',
                                defaultMessage: 'Role or Position Title',
                            })}
                        >
                            <InfoCircleOutlined />
                        </Popover>
                    }
                />
            </Form.Item>
            <Form.Item
                name="place_of_work"
                rules={[
                    {
                        required: true,
                        message: intl.formatMessage({
                            id: '74e6c.AddOptions.notBlank',
                            defaultMessage: 'Please do not leave blank',
                        }),
                    },
                ]}
            >
                <Input
                    disabled={isPlaceOfWorkDisabled}
                    placeholder={intl.formatMessage({
                        id: '74e6c.AddOptions.acicPlaceOfWork',
                        defaultMessage: 'Organization, Type of Work or Place of Work',
                    })}
                    addonBefore={
                        <Popover
                            content={intl.formatMessage({
                                id: '74e6c.AddOptions.acicPlaceOfWork',
                                defaultMessage: 'Organization, Type of Work or Place of Work',
                            })}
                        >
                            <InfoCircleOutlined />
                        </Popover>
                    }
                />
            </Form.Item>
        </Option>
    );
};
