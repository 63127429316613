import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Menu, message } from 'antd';
import { FormattedMessage } from 'react-intl';
import { intl } from 'components/GlobalProvider';
import PropTypes from 'prop-types';
import { ListCellText } from 'certn-ui/List';
import { getUserMode } from 'base/BaseSelectors';
import { clearCSVLink } from 'views/manager/sharedActions/ApplicationActions';
import { fetchReportInfoCSV as fetchHRReportInfoCSV } from 'views/manager/views/hr/views/applications/ApplicationsActions';
import { fetchReportInfoCSV as fetchPMReportInfoCSV } from 'views/manager/views/pm/views/applications/ApplicationsActions';
import { getCsvLink as getHRCsvLink } from 'views/manager/views/hr/views/applications/ApplicationsSelectors';
import { getCsvLink as getPMCsvLink } from 'views/manager/views/pm/views/applications/ApplicationsSelectors';
import usePrevHook from 'utils/hooks/usePrevHook';

const ItemDownloadCSVReport = ({ applicantId, ...rest }) => {
    const dispatch = useDispatch();
    const userMode = useSelector(getUserMode);
    const csvLinkHR = useSelector(getHRCsvLink);
    const csvLinkPM = useSelector(getPMCsvLink);
    const isMounted = React.useRef(false);

    const csvLink = userMode === 'HR' ? csvLinkHR : csvLinkPM;
    const prevCsvLink = usePrevHook(csvLink);

    useEffect(() => {
        const hasMounted = isMounted.current;
        if (hasMounted && prevCsvLink !== csvLink && csvLink !== undefined) {
            window.location = csvLink;
            message.success(
                intl.formatMessage({
                    id: '004e5.StatusDropdown.reportCSVDownloaded',
                    defaultMessage: 'CSV report downloaded.',
                })
            );
            dispatch(clearCSVLink());
        }
        isMounted.current = true;
    }, [csvLink, dispatch, prevCsvLink]);

    const downloadReport = async () => {
        try {
            if (userMode === 'HR') await dispatch(fetchHRReportInfoCSV(applicantId));
            else await dispatch(fetchPMReportInfoCSV(applicantId));
        } catch (error) {
            message.error(
                intl.formatMessage({
                    id: '004e5.StatusDropdown.reportCSVDownloadFailed',
                    defaultMessage: 'Failed to download CSV report.',
                })
            );
        }
    };

    return (
        <Menu.Item {...rest}>
            <ListCellText clickable onClick={downloadReport}>
                <FormattedMessage id="004ed.StatusDropdown.downloadCSVReport" defaultMessage="Download CSV Report" />
            </ListCellText>
        </Menu.Item>
    );
};

ItemDownloadCSVReport.propTypes = {
    applicantId: PropTypes.string.isRequired,
};

export default ItemDownloadCSVReport;
