// Libraries
import React from 'react';
import { get } from 'lodash';
import { FormattedMessage, injectIntl } from 'react-intl';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

// Components
import Title from 'certn-ui/Title';
import { ButtonLine } from 'certn-ui/Button';
import { AppearRight } from 'certn-ui/Animate';
import FormLayout from 'certn-form/FormLayout';
import { TextLink } from 'certn-ui/Text';
import { TrackNavigation } from 'views/welcome/components';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import { Input, Row, Col, Popover, Select } from 'antd';

// Modules
import { Regex } from 'modules';

const ReferenceForm = ({
    form: { getFieldDecorator, validateFields, setFields },
    handleSubmit,
    currentReferenceIndex,
    personalReferences,
    returnToBase,
    intl,
    applicantPhone,
    applicantEmail,
}) => (
    <AppearRight>
        <Title>
            <FormattedMessage
                id="welcome.Personal.referenceTitle"
                defaultMessage="Who would you like to use as a personal reference?"
            />
        </Title>
        <Form layout="horizontal" onSubmit={(e) => handleSubmit(e, validateFields, setFields)}>
            <FormLayout>
                <Form.Item key="first_name" hasFeedback>
                    {getFieldDecorator('first_name', {
                        initialValue: get(personalReferences, [currentReferenceIndex, 'name', 'first_name']),
                        rules: [
                            {
                                pattern: Regex.name,
                                message: intl.formatMessage({
                                    id: 'error.validation.name',
                                    defaultMessage: 'Please enter a valid name',
                                }),
                            },
                            {
                                required: true,
                                message: intl.formatMessage({
                                    id: 'error.validation.notBlank',
                                    defaultMessage: 'Please do not leave blank',
                                }),
                            },
                        ],
                    })(
                        <Input
                            size="large"
                            autoFocus
                            placeholder={intl.formatMessage({
                                id: 'common.firstName',
                                defaultMessage: 'First Name',
                            })}
                            addonBefore={
                                <Popover
                                    content={intl.formatMessage({
                                        id: 'common.firstName',
                                        defaultMessage: 'First Name',
                                    })}
                                >
                                    <InfoCircleOutlined />
                                </Popover>
                            }
                        />
                    )}
                </Form.Item>
                <Form.Item key="last_name" hasFeedback>
                    {getFieldDecorator('last_name', {
                        initialValue: get(personalReferences, [currentReferenceIndex, 'name', 'last_name']),
                        rules: [
                            {
                                pattern: Regex.name,
                                message: intl.formatMessage({
                                    id: 'error.validation.name',
                                    defaultMessage: 'Please enter a valid name',
                                }),
                            },
                            {
                                required: true,
                                message: intl.formatMessage({
                                    id: 'error.validation.notBlank',
                                    defaultMessage: 'Please do not leave blank',
                                }),
                            },
                        ],
                    })(
                        <Input
                            size="large"
                            placeholder={intl.formatMessage({
                                id: 'common.lastName',
                                defaultMessage: 'Last Name',
                            })}
                            addonBefore={
                                <Popover
                                    content={intl.formatMessage({
                                        id: 'common.lastName',
                                        defaultMessage: 'Last Name',
                                    })}
                                >
                                    <InfoCircleOutlined />
                                </Popover>
                            }
                        />
                    )}
                </Form.Item>
                <Form.Item key="email" hasFeedback>
                    {getFieldDecorator('email', {
                        initialValue: get(personalReferences, [currentReferenceIndex, 'email', 'address']),
                        rules: [
                            {
                                pattern: Regex.email,
                                message: intl.formatMessage({
                                    id: 'error.validation.email',
                                    defaultMessage: 'Please provide a valid email',
                                }),
                            },
                            {
                                required: true,
                                message: intl.formatMessage({
                                    id: 'error.validation.notBlank',
                                    defaultMessage: 'Please do not leave blank',
                                }),
                            },
                            {
                                validator: (rule, value, callback) => {
                                    if (value === applicantEmail) {
                                        callback(
                                            intl.formatMessage({
                                                id: 'error.validation.email',
                                                defaultMessage: 'Please provide a valid email',
                                            })
                                        );
                                    } else callback();
                                },
                            },
                        ],
                    })(
                        <Input
                            size="large"
                            placeholder={intl.formatMessage({ id: 'common.email', defaultMessage: 'Email' })}
                            addonBefore={
                                <Popover
                                    content={intl.formatMessage({
                                        id: 'common.email',
                                        defaultMessage: 'Email',
                                    })}
                                >
                                    <InfoCircleOutlined />
                                </Popover>
                            }
                        />
                    )}
                </Form.Item>
                <Form.Item key="phone_number" hasFeedback>
                    {getFieldDecorator('phone_number', {
                        initialValue: get(personalReferences, [currentReferenceIndex, 'phone_number', 'number']),
                        rules: [
                            {
                                pattern: Regex.phoneNumberExtra,
                                message: intl.formatMessage({
                                    id: 'error.validation.phoneNumber',
                                    defaultMessage: 'Please provide a valid phone number',
                                }),
                            },
                            {
                                required: true,
                                message: intl.formatMessage({
                                    id: 'error.validation.notBlank',
                                    defaultMessage: 'Please do not leave blank',
                                }),
                            },
                            {
                                validator: (rule, value, callback) => {
                                    let phone = '';
                                    if (value) phone = parsePhoneNumberFromString(value, 'CA');
                                    if (phone.nationalNumber === applicantPhone?.nationalNumber) {
                                        callback(
                                            intl.formatMessage({
                                                id: 'error.validation.phoneNumber',
                                                defaultMessage: 'Please provide a valid phone number',
                                            })
                                        );
                                    } else callback();
                                },
                            },
                        ],
                    })(
                        <Input
                            size="large"
                            placeholder={intl.formatMessage({
                                id: 'welcome.general.phoneNumber',
                                defaultMessage: 'Phone Number',
                            })}
                            addonBefore={
                                <Popover
                                    content={intl.formatMessage({
                                        id: 'welcome.general.phoneNumber',
                                        defaultMessage: 'Phone Number',
                                    })}
                                >
                                    <InfoCircleOutlined />
                                </Popover>
                            }
                        />
                    )}
                </Form.Item>
                <Form.Item key="association" hasFeedback>
                    {getFieldDecorator('association', {
                        initialValue: get(personalReferences, [currentReferenceIndex, 'association']),
                        rules: [
                            {
                                required: true,
                                message: intl.formatMessage({
                                    id: 'error.validation.notBlank',
                                    defaultMessage: 'Please do not leave blank',
                                }),
                            },
                        ],
                    })(
                        <Select size="large" data-testid="association" placeholder="Association">
                            <Select.Option value="SP" data-testid="spouse">
                                <FormattedMessage
                                    id="welcome.Personal.spousePartner"
                                    defaultMessage="Spouse / Partner"
                                />
                            </Select.Option>
                            <Select.Option value="P" data-testid="parent">
                                <FormattedMessage id="welcome.Personal.parent" defaultMessage="Parent" />
                            </Select.Option>
                            <Select.Option value="S" data-testid="sibling">
                                <FormattedMessage id="welcome.Personal.sibling" defaultMessage="Sibling" />
                            </Select.Option>
                            <Select.Option value="C" data-testid="cousin">
                                <FormattedMessage id="welcome.Personal.cousin" defaultMessage="Cousin" />
                            </Select.Option>
                            <Select.Option value="F" data-testid="friend">
                                <FormattedMessage id="welcome.Personal.friend" defaultMessage="Friend" />
                            </Select.Option>
                            <Select.Option value="O" data-testid="other">
                                <FormattedMessage id="common.other" defaultMessage="Other" />
                            </Select.Option>
                        </Select>
                    )}
                </Form.Item>
            </FormLayout>
            <TrackNavigation />
            <Row gutter={16}>
                <Col sm={24} lg={{ span: 16, offset: 4 }}>
                    <ButtonLine>
                        <TextLink data-testid="no-thanks" id="next" onClick={() => returnToBase()}>
                            <FormattedMessage id="welcome.general.noThanks" defaultMessage="No thanks" />
                        </TextLink>
                    </ButtonLine>
                </Col>
            </Row>
        </Form>
    </AppearRight>
);

export default Form.create()(injectIntl(ReferenceForm));
