import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

// UI Components
import { ListCellLink } from 'certn-ui/List';
import { CellWrapper, CellText } from 'views/manager/components';

// Selectors
import { getUserMode } from 'base/BaseSelectors';

const mapStateToProps = (state) => ({
    userMode: getUserMode(state),
});

const propTypes = {
    // Redux State
    userMode: PropTypes.string.isRequired,
};

const InfoCell = ({ record, userMode, history }) => (
    <CellWrapper col>
        <CellText>{record.building}</CellText>
        <CellText>{record.address}</CellText>
        {!record.listing_count || record.listing_count === 0 ? (
            <CellText>0 Listings</CellText>
        ) : (
            <ListCellLink onClick={() => history.push(`/${userMode.toLowerCase()}/listings?property_id=${record.id}`)}>
                {record.listing_count} Listing
                {record.listing_count !== 1 && 's'}
            </ListCellLink>
        )}
    </CellWrapper>
);

InfoCell.propTypes = propTypes;

export default withRouter(connect(mapStateToProps, {})(InfoCell));
