import React from 'react';

// External Libraries
import { FormattedMessage } from 'react-intl';
import { Modal } from 'antd';

const SettingsCheckerModal = ({
    errors,
    onOk,
    onCancel,
    cancelText = <FormattedMessage id="common.cancel" defaultMessage="Cancel" />,
    okText = <FormattedMessage id="welcome.general.continueAnyway" defaultMessage="Continue Anyway" />,
}) => (
    <>
        {errors && (
            <Modal
                title={
                    <b>
                        <FormattedMessage id="common.warning" defaultMessage="Warning" />
                    </b>
                }
                visible={!!errors}
                onOk={onOk}
                onCancel={onCancel}
                okText={okText}
                cancelText={cancelText}
            >
                <ul>
                    {Object.values(errors).map((item, index) => (
                        <li key={index}>{item}</li>
                    ))}
                </ul>
            </Modal>
        )}
    </>
);

export default SettingsCheckerModal;
