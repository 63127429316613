import { queryKeys } from '../../utils/reactQuery/queryKeys';
import { useQuery } from '@tanstack/react-query';
import { User } from 'types';
import { getRequest } from 'utils/http';

export const useCurrentUser = () => {
    const { data, isLoading, isError, refetch } = useQuery<User>(
        [queryKeys.currentUser],
        () =>
            getRequest<User>({
                version: 'v1',
                endpoint: '/user_info/',
            }),
        {
            staleTime: 5000, // refetch user info on component mount if it is older than 5 seconds
            refetchOnMount: true,
        }
    );

    return {
        user: data,
        userLoading: isLoading,
        userError: isError,
        refetchUser: refetch,
    };
};
