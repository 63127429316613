import React, { useCallback, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Veriff } from '@veriff/js-sdk';
import styled from 'styled-components';
// Actions & Selectors
import { getLocale } from 'base/BaseSelectors';
import { useVeriffBrandedIntegration } from 'hooks/queryHooks/onboarding/useVeriffBrandedIntegration';
import { getOneIDIframeRendererUrl } from '../../../views/guest/views/oneID/views/VeriffIframeRenderer';

const veriffHost = 'https://api.veriff.me';
const VeriffButtonElement = styled.div`
    margin: 30px 0 20px 0;
`;

const OneIDVeriff = ({ oneIdId, handleSubmit }) => {
    const locale = useSelector(getLocale);
    const intl = useIntl();
    const veriffButtonRef = useRef(null);
    const veriffHideButtonRef = useRef(null);
    const veriffPublicAPIKey = useVeriffBrandedIntegration()?.public_key || process.env.REACT_APP_VERIFF_PUBLIC_KEY;

    const hideButton = useCallback(() => {
        veriffHideButtonRef.current.style.display = 'none';
    }, []);

    useEffect(() => {
        const veriffHideButton = document.getElementById('veriff-root');
        veriffHideButtonRef.current = veriffHideButton;
    }, [oneIdId, handleSubmit, veriffHideButtonRef]);

    /*
        Veriff is unable to currently translate this button text.
        This is a temporary fix that can be removed when Veriff has a
        solution for translating this text internally.
    */
    const translateVeriffButton = useCallback(() => {
        veriffButtonRef.current = document.getElementById('veriff-submit-btn');
        const veriffButton = veriffButtonRef.current;
        if (veriffButton) {
            veriffButton.style.backgroundColor = '#12B692';
            veriffButton.value = intl.formatMessage({
                id: 'veriff.button',
                defaultMessage: 'START VERIFICATION',
            });
        }

        return veriffButtonRef;
    }, [intl, veriffButtonRef]);

    useEffect(() => {
        const veriff = Veriff({
            host: veriffHost,
            apiKey: veriffPublicAPIKey,
            parentId: 'veriff-root',
            onSession(err, response) {
                window.veriffSDK.createVeriffFrame({
                    url: `${response?.verification?.url}?lang=${locale}`,
                    hostUrl: getOneIDIframeRendererUrl(),
                    onEvent(msg) {
                        switch (msg) {
                            case 'FINISHED':
                                hideButton();
                                handleSubmit();
                                break;
                            default:
                                break;
                        }
                    },
                });
            },
        });
        veriff.setParams({
            person: {
                givenName: ' ',
                lastName: ' ',
            },
            vendorData: oneIdId,
        });
        veriff.mount();
        translateVeriffButton();
    }, [oneIdId, handleSubmit, locale, translateVeriffButton, hideButton, veriffPublicAPIKey]);

    return <VeriffButtonElement id="veriff-root" />;
};

export default OneIDVeriff;
