import { requestReducer, responseReducer, errorReducer, defaultState as defaultHttpState } from 'utils/http';
import {
    PATCH_REFERENCE_QUESTION_REQ,
    PATCH_REFERENCE_QUESTION_RES,
    PATCH_REFERENCE_QUESTION_ERR,
    FETCH_REFERENCE_QUESTIONS_REQ,
    FETCH_REFERENCE_QUESTIONS_RES,
    FETCH_REFERENCE_QUESTIONS_ERR,
    SUBMIT_REFERENCE_QUESTIONS_REQ,
    SUBMIT_REFERENCE_QUESTIONS_RES,
    SUBMIT_REFERENCE_QUESTIONS_ERR,
} from './ReferenceActions';

const initialState = {
    ...defaultHttpState,
    referenceQuestions: {},
    isSubmitted: false,
};

const base = (state = initialState, action) => {
    switch (action.type) {
        case PATCH_REFERENCE_QUESTION_REQ:
            return {
                ...state,
                ...requestReducer(),
            };
        case FETCH_REFERENCE_QUESTIONS_REQ:
            return {
                ...state,
                ...requestReducer(),
            };
        case SUBMIT_REFERENCE_QUESTIONS_REQ:
            return {
                ...state,
                ...requestReducer(),
            };
        case PATCH_REFERENCE_QUESTION_RES:
            return {
                ...state,
                ...responseReducer(),
            };
        case FETCH_REFERENCE_QUESTIONS_RES:
            return {
                ...state,
                ...responseReducer(),
                referenceQuestions: action.payload.referenceQuestions,
            };
        case SUBMIT_REFERENCE_QUESTIONS_RES:
            return {
                ...state,
                ...responseReducer(),
                isSubmitted: action.payload.isSubmitted,
            };
        case PATCH_REFERENCE_QUESTION_ERR:
            return {
                ...state,
                ...errorReducer(action.payload.error),
            };
        case FETCH_REFERENCE_QUESTIONS_ERR:
            return {
                ...state,
                ...errorReducer(),
                error: action.payload.error,
            };
        case SUBMIT_REFERENCE_QUESTIONS_ERR:
            return {
                ...state,
                ...errorReducer(),
            };
        default:
            return state;
    }
};

export default base;
