import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Button, Modal, message } from 'antd';
import { useHistory } from 'react-router-dom';
import { intl } from 'components/GlobalProvider';

// Components
import { ErrorAlertAPI } from 'certn-ui/ErrorAlert';

// Actions & Selectors
import { getUserMode } from 'base/BaseSelectors';
import {
    getApplicationOfInterest,
    getIsFollowUpFlow,
    getScreenApplicantButtonsDisabled,
    getIntCrimSelectedAndEmpty,
} from 'views/manager/features/ScreenApplicant/ScreenApplicantSelectors';
import {
    upgradeApplication,
    additionalOptionsConflictCheck,
} from 'views/manager/features/ScreenApplicant/ScreenApplicantActions';

// Modules
import { getCheckContent } from 'views/manager/features/ScreenApplicant/components/ScreenApplicant/MicroServiceContent';

const CustomButton = styled(Button)`
    min-width: 167.2px;
`;

const ButtonUpgrade = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const userMode = useSelector(getUserMode);
    const isFollowUpFlow = useSelector(getIsFollowUpFlow);
    const applicantId = useSelector(getApplicationOfInterest)?.id;
    const disabled = useSelector(getScreenApplicantButtonsDisabled);

    // Used to check and display if a FUF check has been removed; add more to getRemovedChecks as needed
    const intCrimEmpty = useSelector(getIntCrimSelectedAndEmpty);
    const [modalVisible, setModalVisible] = useState(false);
    const [applicationSubmitted, setApplicationSubmitted] = useState(false);
    const getRemovedChecks = () => [intCrimEmpty].filter(Boolean);

    const renderButtonText = () => {
        // Follow Up Flow
        if (isFollowUpFlow)
            return intl.formatMessage({ id: 'a0e86.ScreenApplicant.confirm', defaultMessage: 'CONFIRM' });

        // Default isUpgrade
        return intl.formatMessage({
            id: 'a0e86.ScreenApplicant.upgradeButton',
            defaultMessage: 'UPGRADE APPLICATION',
        });
    };

    const handleSubmit = () => {
        const readyToSubmit = dispatch(additionalOptionsConflictCheck);
        if (readyToSubmit) {
            setApplicationSubmitted(true);
            dispatch(upgradeApplication())
                .then(() => {
                    if (isFollowUpFlow)
                        message.success(
                            intl.formatMessage({
                                id: 'a0e86.ScreenApplicant.appUpdated',
                                defaultMessage: 'Application Updated',
                            })
                        );
                    else
                        message.success(
                            intl.formatMessage({
                                id: 'a0e86.ScreenApplicant.additionalInfo',
                                defaultMessage: 'Request for addition information has been sent to the applicant',
                            })
                        );
                    history.push(`/${userMode?.toLowerCase()}/applications/${applicantId}`);
                })
                .catch((error) => {
                    ErrorAlertAPI(error);
                })
                .finally(() => {
                    setApplicationSubmitted(false);
                });
        }
    };

    const handleFollowUpFlowPreCheck = () => {
        // If not FUF, or no services, we don't need to warn so run through regular handleSubmit
        if (!isFollowUpFlow || !getRemovedChecks().length) return handleSubmit();
        // Default
        setModalVisible(true);
    };

    return (
        <>
            <Modal
                destroyOnClose
                title={intl.formatMessage({
                    id: 'a0e86.ScreenApplicant.serviceRemoval',
                    defaultMessage: 'Removing Checks',
                })}
                visible={modalVisible}
                onOk={handleSubmit}
                onCancel={() => setModalVisible(false)}
            >
                <ul>
                    {getRemovedChecks()?.map((item) => (
                        <li key={item}>{getCheckContent(item)?.title}</li>
                    ))}
                </ul>
            </Modal>
            <CustomButton
                disabled={disabled || applicationSubmitted}
                type="primary"
                onClick={handleFollowUpFlowPreCheck}
            >
                {renderButtonText()}
            </CustomButton>
        </>
    );
};

export default ButtonUpgrade;
