import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, Modal, Space } from 'antd';
import { FormProps } from 'antd/lib/form';
import { FormLayout } from 'certn-form';
import Title from 'certn-ui/Title';
import { InternationalPhone } from 'components';
import { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { ApplicantName } from 'types';
import { TrackNavigation } from 'views/welcome/components';
import Subtitle from 'views/welcome/components/Subtitle';
import { getTeam } from 'views/welcome/WelcomeSelectors';
import { FieldHeading } from '../styles';
import { UkCheckType } from '../types';
import PreviousNameField from './PreviousNameField';

export interface NewBasicsFormValues {
    applicantNames: ApplicantName[];
    phone: string;
}

const AddPreviousNameButton = styled.div`
    display: flex;
    align-items: center;
    padding-right: 15px;
    border: 2px dashed #d9d9d9;
    border-radius: 2px;
`;

const WhatsThisLink = styled.a`
    text-decoration: underline;
    color: black;
    font-size: 16px;
`;

const PreviousNamesHelpModal = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
    const title = (
        <FormattedMessage id="welcome.Basics.whatArePreviousNamesTitle" defaultMessage="What are previous names?" />
    );

    return (
        <Modal
            title={title}
            visible={isOpen}
            onCancel={onClose}
            footer={[
                <Button key="ok" onClick={onClose}>
                    <FormattedMessage id="common.ok" defaultMessage="OK" />
                </Button>,
            ]}
        >
            <FormattedMessage
                id="welcome.Basics.whatArePreviousNames"
                defaultMessage="Previous names are any names you been known by throughout your life. This is including your name before marriage (maiden name), and other previous names and/or alias names."
            />
        </Modal>
    );
};

interface Props extends FormProps<NewBasicsFormValues> {
    phoneNumberStatus: 'error' | 'success' | undefined;
    setAllowAdditionalNames: (value: boolean) => void;
    showDateFields: boolean;
    showTitleField: boolean;
    showMiddleNameField: boolean;
    showAllowAdditionalNamesCheckbox: boolean;
    showAddPreviousNamesButton: boolean;
    showPreviousNameTypesDropdown: boolean;
    typeOfUkCheck: UkCheckType;
    dateFormat: string;
}

const NewBasicsForm = (props: Props) => {
    const [form] = Form.useForm<NewBasicsFormValues>();
    const intl = useIntl();
    const [isHelpOpen, setHelpOpen] = useState(false);
    const [previousNameCheckboxValue, setPreviousameCheckboxValue] = useState(false);
    const { country } = useSelector(getTeam);

    const toggleHelpOpen = () => {
        setHelpOpen((current) => !current);
    };
    const showAdditionalFields = {
        showDateFields: props.showDateFields,
        showTitleField: props.showTitleField,
        showMiddleNameField: props.showMiddleNameField,
        showAllowAdditionalNamesCheckbox: props.showAllowAdditionalNamesCheckbox,
        showAddPreviousNamesButton: props.showAddPreviousNamesButton,
        showPreviousNameTypesDropdown: props.showPreviousNameTypesDropdown,
    };

    const toggleShowPreviousNamesButtonChange = useCallback(
        (value: boolean) => {
            setPreviousameCheckboxValue(value);
            props.setAllowAdditionalNames(value);
        },
        [props]
    );

    return (
        <>
            <PreviousNamesHelpModal isOpen={isHelpOpen} onClose={toggleHelpOpen} />
            <Form<NewBasicsFormValues> layout="horizontal" form={form} {...props}>
                <Title>
                    <FormattedMessage id="welcome.Basics.title" defaultMessage="Tell us a little bit about yourself" />
                </Title>
                <Subtitle>
                    <FormattedMessage
                        id="welcome.Basics.subtitle"
                        defaultMessage="We recommend using the information on a government issued ID or birth certificate for accurate results and a quicker turnaround."
                    />
                </Subtitle>
                <FormLayout>
                    <Form.List name="applicantNames">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map((fieldData, index) => (
                                    <PreviousNameField
                                        key={fieldData.key}
                                        fieldData={fieldData}
                                        index={index}
                                        onDelete={remove}
                                        form={form}
                                        showAdditionalFields={showAdditionalFields}
                                        typeOfUkCheck={props.typeOfUkCheck}
                                        dateFormat={props.dateFormat}
                                        toggleHelpOpen={toggleHelpOpen}
                                        toggleShowPreviousNamesButtonChange={toggleShowPreviousNamesButtonChange}
                                    />
                                ))}
                                {(showAdditionalFields.showAddPreviousNamesButton || previousNameCheckboxValue) &&
                                props.typeOfUkCheck !== 'UK_RIGHT_TO_WORK_CHECK' ? (
                                    <Form.Item>
                                        <AddPreviousNameButton>
                                            <Button
                                                type="text"
                                                icon={<PlusOutlined />}
                                                className="flex-fill"
                                                style={{
                                                    flex: 1,
                                                    color: '#12826a',
                                                    textAlign: 'left',
                                                    fontSize: '16px',
                                                }}
                                                onClick={() => add({ type: 'FORMER' })}
                                                data-testid="add_previous_name_button"
                                            >
                                                {' '}
                                                <FormattedMessage
                                                    id="welcome.Basics.addPreviousName"
                                                    defaultMessage="Add previous name"
                                                />
                                            </Button>
                                            <Space />
                                            <WhatsThisLink
                                                onClick={toggleHelpOpen}
                                                data-testid="what_is_previous_name_button"
                                            >
                                                <FormattedMessage
                                                    id="welcome.Basics.whatsThis"
                                                    defaultMessage="What's this?"
                                                />
                                            </WhatsThisLink>
                                        </AddPreviousNameButton>
                                    </Form.Item>
                                ) : undefined}
                            </>
                        )}
                    </Form.List>
                    <FieldHeading>
                        <FormattedMessage id="welcome.Basics.contactDetails" defaultMessage="Contact Details" />
                    </FieldHeading>
                    <Form.Item
                        name="phone"
                        rules={[
                            {
                                required: true,
                                message: intl.formatMessage({
                                    id: 'error.validation.notBlank',
                                    defaultMessage: 'Please do not leave blank',
                                }),
                            },
                        ]}
                    >
                        <InternationalPhone errorStatus={props.phoneNumberStatus} countryOverride={country} />
                    </Form.Item>
                </FormLayout>
                <TrackNavigation />
            </Form>
        </>
    );
};

export default NewBasicsForm;
