import React from 'react';

// Components
import { PhoneWrapper } from 'components';

// TODO think about renaming these two components, possibly touch base with most recent contributors
// I hate that this is a thing but the Layout nesting an element before the route breaks the navigation bar in Internet Explorer
const Wrapper = ({ children, ...rest }) => <PhoneWrapper {...rest}>{children}</PhoneWrapper>;

export default Wrapper;
