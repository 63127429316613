import styled from 'styled-components/macro';

const Span = styled.span`
    font-size: ${({ theme, size }) => theme.font[size]};
    color: ${({ theme, color }) => theme.color[color]};
`;

const Para = styled.p`
    font-size: ${({ theme, size }) => theme.font[size]};
    color: ${({ theme, color }) => theme.color[color]};
`;

const Text = {
    Span,
    Para,
};
export default Text;
