import { IS_DRAWER_OPEN } from './DrawerConstants';
import { Action, DrawerReducerState } from './types';

const initialState: DrawerReducerState = {
    isDrawerOpen: false,
};

const drawer = (state = initialState, action: Action) => {
    const { type, payload } = action;
    switch (type) {
        case IS_DRAWER_OPEN:
            return {
                ...state,
                isDrawerOpen: payload.isDrawerOpen,
                selectedRecordId: payload.id,
            };

        default:
            return state;
    }
};

export default drawer;
