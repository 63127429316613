// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// Modules
import { CopyToClipboard, Format } from 'modules';

// UI Components
import Navbar, { NavMain, NavOption, NavOptions } from 'certn-ui/Navbar';

// Table Components
import { CertnTable } from 'views/manager/components';
import ApplicantsCell from './ApplicantsCell';
import ApplicationsCell from './ApplicationsCell';
import ActionsCell from './ActionsCell';
import InfoCell from './InfoCell';

// Actions and Selectors
import { setOrdering, fetchListings, setPageSize } from 'views/manager/views/pm/views/listings/ListingsActions';
import { getIsFetchingApplications } from 'views/manager/views/pm/views/applications/ApplicationsSelectors';
import {
    getIsFetching,
    getOrdering,
    getReverseOrder,
    getCurrentPage,
    getPageSize,
    getNumListings,
} from 'views/manager/views/pm/views/listings/ListingsSelectors';

const mapStateToProps = (state) => ({
    currentPage: getCurrentPage(state),
    pageSize: getPageSize(state),
    numListings: getNumListings(state),
    isFetching: getIsFetching(state),
    isFetchingApplications: getIsFetchingApplications(state),
    ordering: getOrdering(state),
    reverseOrder: getReverseOrder(state),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setOrdering,
            setPageSize,
            fetchListings,
        },
        dispatch
    );

const propTypes = {
    data: PropTypes.array.isRequired,
    ordering: PropTypes.string.isRequired,
    setOrdering: PropTypes.func.isRequired,
    reverseOrder: PropTypes.bool.isRequired,
    isFetchingApplications: PropTypes.bool,
    isFetching: PropTypes.bool.isRequired,
};

const defaultProps = {
    isFetchingApplications: false,
};

class Table extends React.Component {
    columns = {
        desktop: ['Updated', 'Unit', 'Address', 'Building', 'Agent', 'Applicants', 'Applications', 'Actions'],
        mobile: ['Info', 'Actions'],
        Updated: {
            active: 'modified',
            renderUi: ({ record }) => Format.date(record.modified),
        },
        Unit: {
            width: { default: '6%' },
            active: 'unit',
            renderUi: ({ record }) => record.unit,
        },
        Address: {
            width: { default: '8%' },
            active: 'property__address',
            renderUi: ({ record }) => (record.property && record.property.address) || record.address,
        },
        Building: {
            width: { default: '6%' },
            active: 'property__building',
            renderUi: ({ record }) => record.property && record.property.building,
        },
        Agent: {
            active: 'owner__email',
            renderUi: ({ record }) => record.owner && record.owner.email,
        },
        Applicants: {
            customHeader: ({ data, title }) => (
                <ApplicantsCell.Header
                    data={data}
                    title={title}
                    reverseOrder={this.props.reverseOrder}
                    setOrdering={this.props.setOrdering}
                    ordering={this.props.ordering}
                />
            ),
            renderUi: ({ record }) => <ApplicantsCell record={record} copyToClipboard={CopyToClipboard} />,
        },
        Applications: {
            active: 'new_applicant_count',
            renderUi: ({ record }) => <ApplicationsCell record={record} />,
        },
        Actions: {
            renderUi: ({ record }) => (
                <ActionsCell
                    record={record}
                    showSendApplications={this.props.showSendApplications}
                    showTrialLimitReached={this.propsshowTrialLimitReached}
                    copyToClipboard={CopyToClipboard}
                />
            ),
        },
        Info: {
            active: 'modified',
            renderUi: ({ record }) => <InfoCell record={record} />,
        },
    };

    render() {
        const {
            data,
            isFetching,
            isFetchingApplications,
            ordering,
            reverseOrder,
            numListings,
            pageSize,
            currentPage,
        } = this.props;
        const desktop = window.matchMedia('(min-width: 1024px)').matches;
        const display = desktop ? 'desktop' : 'mobile';
        const paginationConfig = desktop
            ? {
                  total: numListings,
                  showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                  pageSize,
                  pageSizeOptions: ['7', '25', '50'],
                  defaultCurrent: currentPage,
                  current: currentPage,
                  showSizeChanger: true,
                  showQuickJumper: true,
                  onShowSizeChange: (current, size) => this.props.setPageSize(size),
                  onChange: (page) => this.props.fetchListings(page),
              }
            : {
                  simple: true,
                  total: numListings,
                  pageSize,
                  defaultCurrent: currentPage,
                  current: currentPage,
                  onChange: (page) => this.props.fetchListings(page),
              };

        return (
            <CertnTable
                dataSource={data}
                loading={isFetching || isFetchingApplications}
                pagination={paginationConfig}
                columns={this.columns}
                display={display}
                setOrdering={this.props.setOrdering}
                ordering={ordering}
                reverseOrder={reverseOrder}
            />
        );
    }
}

Table.Nav = ({ isActive, setIsActive }) => (
    <Navbar noBorder>
        <NavMain>
            <NavOptions desktop mobile>
                <NavOption label="active" currentRoute={isActive} onClick={() => setIsActive(true)} />
                <NavOption label="inactive" currentRoute={!isActive} onClick={() => setIsActive(false)} />
            </NavOptions>
        </NavMain>
    </Navbar>
);

Table.Nav.displayName = 'TableNav';
Table.propTypes = propTypes;
Table.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(Table);
