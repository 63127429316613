import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Menu } from 'antd';
import Constants from 'utils/constants';
import { AdverseActionStatusType } from './types';
import { ListCellText } from 'certn-ui/List';
import { fetchAdverseActionDetails, toggleDrawer } from 'views/manager/features/AdverseActionDrawer/DrawerActions';
import { adverseActionDetails } from 'views/manager/features/AdverseActionDrawer/DrawerSelectors';
import { fetchApplication as fetchHrApplication } from '../../../ApplicationsActions';
import { fetchApplication as fetchPmApplication } from 'views/manager/views/pm/views/applications/ApplicationsActions';

interface Props {
    applicantId: string;
    isReport: boolean;
    isDashboard: boolean;
    isHr: boolean;
}

// Elements
const StartAdverseActionElement = (): JSX.Element => (
    <FormattedMessage id="004e5.StatusDropdown.initiateAdverseAction" defaultMessage="Start Adverse Action Process" />
);
const AdverseActionDetailsElement = (): JSX.Element => (
    <FormattedMessage id="004e5.StatusDropdown.adverseActionDetails" defaultMessage="Adverse Action Details" />
);

export const ItemAdverseAction: React.FC<Props> = ({
    applicantId,
    isReport,
    isDashboard,
    isHr = false,
    ...rest
}: Props) => {
    const dispatch = useDispatch();
    const adverseAction = useSelector(adverseActionDetails);
    const adverseActionStatus: AdverseActionStatusType = adverseAction?.adverse_action_status;
    const hasAdverseActionStarted: boolean =
        !!adverseActionStatus && adverseActionStatus !== Constants.adverseActionStatus.NONE;

    useEffect(() => {
        if (isDashboard) {
            dispatch(fetchAdverseActionDetails(applicantId));
        }
    }, [applicantId, dispatch, isDashboard]);

    // Event Handlers
    const handleMenuItemClick = () => {
        if (isDashboard) {
            isHr ? dispatch(fetchHrApplication(applicantId)) : dispatch(fetchPmApplication(applicantId));
        }
        dispatch(toggleDrawer(true));
    };

    // Render
    if (isReport && !hasAdverseActionStarted) {
        return (
            <Menu.Item {...rest}>
                <ListCellText style={{ color: 'red' }} onClick={handleMenuItemClick}>
                    <StartAdverseActionElement />
                </ListCellText>
            </Menu.Item>
        );
    }

    if (hasAdverseActionStarted) {
        return (
            <Menu.Item {...rest}>
                <ListCellText onClick={handleMenuItemClick}>
                    <AdverseActionDetailsElement />
                </ListCellText>
            </Menu.Item>
        );
    }

    return null;
};
