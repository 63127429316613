// Libraries
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components/macro';
import { bindActionCreators } from 'redux';
import { EllipsisOutlined } from '@ant-design/icons';
import { intl } from 'components/GlobalProvider';

// UI Components
import { ListCellText } from 'certn-ui/List';
import { Dropdown as AntDropdown, Menu, Modal, message, Button as AntButton } from 'antd';
import { FormattedMessage } from 'react-intl';

// Actions & Selectors
import { deletePosting, togglePostingInactive } from 'views/manager/views/hr/views/postings/PostingsActions';
import { getUser } from 'base/BaseSelectors';

const mapStateToProps = (state) => ({
    canCreateAndEditPackages: getUser(state).can_create_and_edit_packages,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            deletePosting,
            togglePostingInactive,
        },
        dispatch
    );

const Dropdown = styled(AntDropdown)`
    margin: 8px 0;
`;

const Button = styled(AntButton)`
    ${(props) => props.margin && `margin: ${props.margin}`};
    overflow: hidden !important;
    height: 40px !important;
    width: 40px !important;
`;

class DropdownCell extends React.Component {
    showConfirmInactive = (posting) => {
        Modal.confirm({
            title: intl.formatMessage({
                id: '00e2b.DropdownCell.title1',
                defaultMessage: 'Do you want to set this package to Inactive?',
            }),
            content: intl.formatMessage({
                id: '00e2b.DropdownCell.content1',
                defaultMessage:
                    'This will permanently archive this package in the Inactive section. You can still make a copy of it in the future if you would like to re-list.',
            }),
            okType: 'danger',
            onOk: () => {
                this.props.togglePostingInactive(posting.id);
                return new Promise((resolve) => {
                    setTimeout(resolve, 1000);
                })
                    .then(() =>
                        message.success(
                            intl.formatMessage({
                                id: '00e2b.DropdownCell.packageInnactive',
                                defaultMessage: 'Package set to inactive!',
                            })
                        )
                    )
                    .catch(() =>
                        message.error(
                            intl.formatMessage({
                                id: '00e2b.DropdownCell.packageError',
                                defaultMessage: 'Error occurred while attempting to set to inactive',
                            })
                        )
                    );
            },
            onCancel() {},
        });
    };

    showConfirmDelete = (posting) => {
        Modal.confirm({
            title: intl.formatMessage({
                id: '00e2b.DropdownCell.title2',
                defaultMessage: 'Do you really want to delete this package?',
            }),
            content: intl.formatMessage({
                id: '00e2b.DropdownCell.content2',
                defaultMessage:
                    "This action cannot be undone. If you think you might re-list this package in the future, consider selecting Cancel and trying 'Set to Inactive' instead. Otherwise select Okay.",
            }),
            okType: 'danger',
            onOk: () => {
                this.props.deletePosting(posting.id);
                return new Promise((resolve) => {
                    setTimeout(resolve, 1000);
                })
                    .then(() =>
                        message.success(
                            intl.formatMessage({
                                id: '00e2b.DropdownCell.packageDeleted',
                                defaultMessage: 'Package deleted!',
                            })
                        )
                    )
                    .catch(() =>
                        message.error(
                            intl.formatMessage({
                                id: '00e2b.DropdownCell.packageDeleteError',
                                defaultMessage: 'Error occurred while attempting to set delete package',
                            })
                        )
                    );
            },
            onCancel() {},
        });
    };

    render() {
        const { item, history, showSendApplications, copyToClipboard, canCreateAndEditPackages } = this.props;
        return (
            <Dropdown
                overlay={
                    <Menu>
                        {item.is_active && (
                            <Menu.Item key="0">
                                <ListCellText clickable onClick={() => showSendApplications(item.id, item)}>
                                    <FormattedMessage id="00e2b.DropdownCell.send" defaultMessage="Send Application" />
                                </ListCellText>
                            </Menu.Item>
                        )}
                        {item.is_active && (
                            <Menu.Item key="1">
                                <ListCellText
                                    clickable
                                    onClick={() => copyToClipboard({ route: 'packages', urlCode: item.url_code })}
                                >
                                    <FormattedMessage id="00e2b.DropdownCell.copy" defaultMessage="Copy Package Link" />
                                </ListCellText>
                            </Menu.Item>
                        )}
                        {item.is_active && canCreateAndEditPackages && (
                            <>
                                <Menu.Item key="2">
                                    <ListCellText
                                        clickable
                                        onClick={() => history.push(`/hr/packages/${item.id}/edit`)}
                                    >
                                        <FormattedMessage id="00e2b.DropdownCell.edit" defaultMessage="Edit Item" />
                                    </ListCellText>
                                </Menu.Item>
                                <Menu.Item key="3">
                                    <ListCellText clickable onClick={() => this.showConfirmInactive(item)}>
                                        <FormattedMessage
                                            id="00e2b.DropdownCell.set"
                                            defaultMessage="Set to Inactive"
                                        />
                                    </ListCellText>
                                </Menu.Item>
                            </>
                        )}
                        {!item.is_active && canCreateAndEditPackages && (
                            <Menu.Item key="3">
                                <ListCellText
                                    clickable
                                    onClick={() => history.push(`/hr/packages/new/?listing_id=${item.id}`)}
                                >
                                    <FormattedMessage id="00e2b.DropdownCell.relist" defaultMessage="Re-list Package" />
                                </ListCellText>
                            </Menu.Item>
                        )}
                        {canCreateAndEditPackages && (
                            <Menu.Item key="4">
                                <ListCellText clickable onClick={() => this.showConfirmDelete(item)}>
                                    <FormattedMessage id="00e2b.DropdownCell.delete" defaultMessage="Delete Package" />
                                </ListCellText>
                            </Menu.Item>
                        )}
                    </Menu>
                }
                trigger={['click']}
                placement="bottomCenter"
            >
                <Button shape="circle" data-testid="package_action_menu" icon={<EllipsisOutlined />} />
            </Dropdown>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DropdownCell));
