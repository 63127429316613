// Libraries
import React from 'react';

// Components
import DropdownCell from './DropdownCell';

const ActionsCell = ({ record }) => <DropdownCell item={record} />;

ActionsCell.propTypes = {};

export default ActionsCell;
