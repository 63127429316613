import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './rootReducer';
import config from './utils/config';

const configureStore = () => {
    const store = createStore(
        rootReducer,
        process.env.NODE_ENV === 'development' || config.isDevelopment
            ? composeWithDevTools(applyMiddleware(thunkMiddleware))
            : applyMiddleware(thunkMiddleware) // lets us dispatch() functions
    );

    if (process.env.NODE_ENV !== 'production') {
        if (module.hot) {
            module.hot.accept('./rootReducer', () => {
                store.replaceReducer(rootReducer);
            });
        }
    }

    return store;
};

export default configureStore;
