import { Card } from 'antd';
import { FormattedMessage } from 'react-intl';
import Text from 'certn-ui/Text';
import Image from 'certn-ui/Image';
import Loader from 'certn-ui/Loader';
import Success from 'images/svgs/success.svg';
import Incomplete from 'images/svgs/incomplete.svg';
import { QuestionEnclosure } from '../../components';
import Button, { ButtonLine } from 'certn-ui/Button';
import { ApplicationCard } from './ApplicationCard';
import { useUserApplicants } from 'hooks/queryHooks/useUserApplicants';
import { UserApplicant } from 'types';
import { InnerWrap, LayoutBody, OuterWrap, Spacer, Title } from './styled';
import { Fragment } from 'react';

const INCOMPLETE_STATUSES_EN = ['Pending', 'In Progress', 'Sent'];
const INCOMPLETE_STATUSES_FR = ['Attente', 'En Progrès', 'Expédié'];

const INCOMPLETE_STATUSES = [...INCOMPLETE_STATUSES_EN, ...INCOMPLETE_STATUSES_FR];

const redirectToOnboarding = (applicant: UserApplicant) => {
    window.location.replace(applicant.onboarding_link);
};

export const Review = () => {
    const { applicants, applicantsLoading } = useUserApplicants();

    if (applicantsLoading) {
        return (
            <LayoutBody>
                <Loader />
            </LayoutBody>
        );
    }

    // Empty State
    if (!applicants?.length) {
        return (
            <LayoutBody>
                <QuestionEnclosure>
                    <Title>
                        <FormattedMessage
                            id="applicant.Review.emptyTitle"
                            defaultMessage="You haven't requested any checks yet!"
                        />
                    </Title>
                </QuestionEnclosure>
            </LayoutBody>
        );
    }

    const firstApplicant = applicants[0];
    const settings = firstApplicant.application?.team?.settings_config;

    // Incomplete State
    if ([...INCOMPLETE_STATUSES].includes(firstApplicant.status)) {
        return (
            <LayoutBody>
                <QuestionEnclosure>
                    <Title>
                        <FormattedMessage id="applicant.Review.incompleteTitle" defaultMessage="You're almost done!" />
                    </Title>
                    <Text align="center" size="xs">
                        <FormattedMessage
                            id="applicant.Review.incompleteSubtitle"
                            defaultMessage="You have a few more steps before your background check is complete."
                        />
                    </Text>
                    <Image margin={20} src={Incomplete} size="xlrg" />
                </QuestionEnclosure>
                <OuterWrap>
                    <InnerWrap>
                        <Text size="xs">
                            <FormattedMessage
                                id="applicant.Review.continueTitle"
                                defaultMessage="Pick up where you left off"
                            />
                        </Text>
                        <Card>
                            <Text size="xs">
                                <FormattedMessage
                                    id="applicant.Review.continueText"
                                    defaultMessage="We need a few extra pieces of information from you before we can process your report."
                                />
                            </Text>
                            <ButtonLine style={{ paddingBottom: 0 }}>
                                <Button
                                    block
                                    size="large"
                                    type="primary"
                                    certncolor="certnGreen700"
                                    onClick={() => redirectToOnboarding(firstApplicant)}
                                    style={{ maxWidth: 335 }}
                                >
                                    <FormattedMessage
                                        id="applicant.Review.continueButton"
                                        defaultMessage="Finish Report"
                                    />
                                </Button>
                            </ButtonLine>
                        </Card>
                    </InnerWrap>
                </OuterWrap>
            </LayoutBody>
        );
    }

    // Complete State
    return (
        <LayoutBody>
            <QuestionEnclosure>
                <Title>
                    <FormattedMessage id="applicant.Review.completeTitle" defaultMessage="You're all done!" />
                </Title>
                <Text align="center" size="xs">
                    <FormattedMessage
                        id="applicant.Review.completeSubtitle"
                        defaultMessage="Your payment was received and your background check is being processed."
                    />
                </Text>
                <Image margin={20} src={Success} size="xlrg" />
            </QuestionEnclosure>

            <OuterWrap>
                <InnerWrap>
                    <Text size="xs">
                        <FormattedMessage id="applicant.Review.detailsTitle" defaultMessage="Your Check Information" />
                    </Text>
                    {applicants.map((applicant) => (
                        <Fragment key={123}>
                            <ApplicationCard applicant={applicant} />
                            <Spacer size={40} />
                        </Fragment>
                    ))}
                    <Text size="xs">
                        <FormattedMessage
                            id="applicant.Review.infoTitle"
                            defaultMessage="A few things to keep in mind"
                        />
                    </Text>
                    <Card>
                        <Text size="xs">
                            <FormattedMessage
                                id="applicant.Review.info1"
                                defaultMessage="Most checks are returned {time} but can take longer if necessary. If your report isn’t approved within 24 hours, we’ll let you know by email with next-step instructions."
                                values={{
                                    time: firstApplicant.request_australian_criminal_intelligence_commission_check ? (
                                        <FormattedMessage
                                            id="applicant.Review.info1.days"
                                            defaultMessage="within 1-2 business days"
                                        />
                                    ) : (
                                        <FormattedMessage
                                            id="applicant.Review.info1.mins"
                                            defaultMessage="in as little as 15 minutes"
                                        />
                                    ),
                                }}
                            />
                        </Text>
                        <Spacer />
                        <Text size="xs">
                            <FormattedMessage
                                id="applicant.Review.info2"
                                defaultMessage="You can check on the status of your report here, and when your report is ready we’ll email you a copy."
                            />
                        </Text>
                        <Spacer />
                        <Text size="xs">
                            <FormattedMessage
                                id="applicant.Review.info3"
                                defaultMessage="If your report doesn’t look 100% accurate then let us know! We’ll work with you to ensure it’s exactly as it should be."
                            />
                        </Text>
                    </Card>
                    <Spacer />
                    <Text align="center" size="xxs">
                        <FormattedMessage
                            id="applicant.Review.moreQuestions"
                            defaultMessage="More questions? Check out our FAQ."
                        />
                    </Text>
                    <ButtonLine>
                        <Button
                            block
                            size="large"
                            type="primary"
                            certncolor="certnGreen700"
                            onClick={() => {
                                window.open(settings?.redirect_url ?? '/', '_blank');
                            }}
                            style={{ maxWidth: 335 }}
                        >
                            <FormattedMessage id="applicant.Review.faqButton" defaultMessage="Go to FAQ" />
                        </Button>
                    </ButtonLine>
                </InnerWrap>
            </OuterWrap>
        </LayoutBody>
    );
};
