import React from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip as AntTooltip } from 'antd';

const Tooltip = ({ title, style = {}, placement = 'right' }) => (
    <AntTooltip title={title} placement={placement}>
        <QuestionCircleOutlined style={{ ...style }} />
    </AntTooltip>
);
export default Tooltip;
