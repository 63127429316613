// Libraries
import React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

// Components
import { CellWrapper, CellText } from 'views/manager/components';

import { getUser } from 'base/BaseSelectors';
import { getVerboseDateTimeFormat } from 'modules/Format';

const InfoCell = ({ row }) => {
    const user = useSelector(getUser);
    const token =
        `${row?.token?.length}` > 4 ? (
            `${row?.token}`
        ) : (
            <FormattedMessage
                id="60b5f.InfoCell.TokenCharacters"
                defaultMessage="Last four characters: {shortToken}"
                values={{ shortToken: row?.token }}
            />
        );
    const created = moment(row.created).format(getVerboseDateTimeFormat());
    const owner = row.owner === user.id ? user.email : row.owner;

    return (
        <CellWrapper col>
            <CellText>{token}</CellText>
            <CellText>{created}</CellText>
            <CellText>{owner}</CellText>
        </CellWrapper>
    );
};

export default InfoCell;
