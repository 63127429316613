// Libraries
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

// Components
import SummaryItem from './SummaryItem';
import SummaryHeader from './SummaryHeader';
import {
    FlexRow,
    SummarySection,
    SummarySubSection,
    SummaryContainer,
    SummarySubHeader,
    LineBreak,
} from './SummaryStyles';

const Supplemental = ({ information, goToTrack }) => (
    <SummaryContainer>
        <SummaryHeader
            title={<FormattedMessage id="welcome.Summary.otherInformation" defaultMessage="Other Information" />}
            onClick={goToTrack}
        />
        <SummarySection>
            <SummarySubSection>
                <SummarySubHeader>
                    <FormattedMessage id="welcome.Summary.roommates" defaultMessage="Roommates" />
                </SummarySubHeader>
                {information.occupants &&
                    information.occupants.map((occupant, i) => (
                        <React.Fragment key={i}>
                            <FlexRow>
                                <SummaryItem
                                    title={<FormattedMessage id="common.firstName" defaultMessage="First Name" />}
                                    text={occupant.first_name}
                                />
                                <SummaryItem
                                    title={<FormattedMessage id="common.lastName" defaultMessage="Last Name" />}
                                    text={occupant.last_name}
                                />
                                <SummaryItem
                                    title={
                                        <FormattedMessage
                                            id="welcome.general.adultChild"
                                            defaultMessage="Adult / Child"
                                        />
                                    }
                                    text={occupant.adult_child}
                                />
                                <SummaryItem
                                    title={<FormattedMessage id="common.email" defaultMessage="Email" />}
                                    text={occupant.email}
                                />
                            </FlexRow>
                            {i + 1 < information.pet_details && information.pet_details.length && <LineBreak />}
                        </React.Fragment>
                    ))}
                {information.occupants && information.occupants.length === 0 && (
                    <SummaryItem
                        empty
                        title={
                            <FormattedMessage
                                id="welcome.Summary.noOccupantsListed"
                                defaultMessage="You have not listed any occupants"
                            />
                        }
                    />
                )}
            </SummarySubSection>
        </SummarySection>
        <LineBreak />
        <SummarySection>
            <SummarySubSection>
                <SummarySubHeader>
                    <FormattedMessage id="welcome.Summary.pets" defaultMessage="Pets" />
                </SummarySubHeader>
                {information.pet_details &&
                    information.pet_details.map((pet, i) => (
                        <React.Fragment key={i}>
                            <FlexRow>
                                <SummaryItem title="Type" text={pet.type} />
                                <SummaryItem title="Weight" text={pet.weight} />
                            </FlexRow>
                            {i + 1 < information.pet_details && information.pet_details.length && <LineBreak />}
                        </React.Fragment>
                    ))}
                {information.pet_details && information.pet_details.length === 0 && (
                    <SummaryItem
                        empty
                        title={
                            <FormattedMessage
                                id="welcome.Summary.noPetsListed"
                                defaultMessage="You have not listed any pets"
                            />
                        }
                    />
                )}
            </SummarySubSection>
        </SummarySection>
        <LineBreak />
        <SummarySection>
            <SummarySubSection>
                <SummarySubHeader>
                    <FormattedMessage id="welcome.Summary.guarantorCosigner" defaultMessage="Guarantor / Cosigner" />
                </SummarySubHeader>
                <FlexRow>
                    <SummaryItem
                        title={<FormattedMessage id="common.firstName" defaultMessage="First Name" />}
                        text={information.co_signer_first_name}
                    />
                    <SummaryItem
                        title={<FormattedMessage id="common.lastName" defaultMessage="Last Name" />}
                        text={information.co_signer_last_name}
                    />
                    <SummaryItem
                        title={<FormattedMessage id="common.phoneNumber" defaultMessage="Phone Number" />}
                        text={information.co_signer_phone}
                    />
                    <SummaryItem
                        title={<FormattedMessage id="common.email" defaultMessage="Email" />}
                        text={information.co_signer_email}
                    />
                    <SummaryItem
                        title={<FormattedMessage id="welcome.general.relation" defaultMessage="Relation" />}
                        text={information.co_signer_association_label}
                    />
                </FlexRow>
            </SummarySubSection>
        </SummarySection>
        <LineBreak />
        <SummarySection>
            <SummarySubSection>
                <SummarySubHeader>
                    <FormattedMessage id="welcome.Summary.vehicleInformation" defaultMessage="Vehicle Information" />
                </SummarySubHeader>
                <FlexRow>
                    <SummaryItem title="License Plate" text={information.car_plate} />
                    <SummaryItem title="Province" text={information.car_prov_state} />
                </FlexRow>
            </SummarySubSection>
        </SummarySection>
    </SummaryContainer>
);

export default injectIntl(Supplemental);
