// Libraries
import React from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import { Input, Popover } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import ct from 'countries-and-timezones';

// Components
import { TrackNavigation } from 'views/welcome/components';
import { InternationalPhone } from 'components';
import { AppearRight } from 'certn-ui/Animate';
import Title from 'certn-ui/Title';
import FormLayout from 'certn-form/FormLayout';
import AddressAutoComplete from 'certn-form/AddressAutoComplete';
import Text from 'certn-ui/Text';
import { Box } from 'certn-ui/Layout';

// Actions & Selectors
import { getLaunchDarklyFlags } from 'base/BaseSelectors';

// Modules
import { Regex } from 'modules';
import HubspotTracking from './hubspot';

const MoreInfoForm = ({
    phone,
    setPhone,
    handleSubmit,
    handleReverse,
    submitting,
    form: { getFieldDecorator, validateFields },
    form,
    errorStatus,
    intl,
    countryCode,
}) => {
    const { webFeatureEnableTeamSignUpRecaptcha } = useSelector(getLaunchDarklyFlags);
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const country = ct.getCountriesForTimezone(timezone);
    return (
        <AppearRight>
            <HubspotTracking country={countryCode} />
            <Form layout="horizontal">
                <Title>
                    <FormattedMessage
                        id="welcome.SignUp.moreInfoTitle"
                        defaultMessage="Tell us a little bit about yourself"
                    />
                </Title>
                <FormLayout>
                    <Form.Item key="first_name" hasFeedback>
                        {getFieldDecorator('first_name', {
                            rules: [
                                {
                                    pattern: Regex.name,
                                    message: intl.formatMessage({
                                        id: 'error.validation.letters',
                                        defaultMessage: 'Please only use letters',
                                    }),
                                },
                                {
                                    required: true,
                                    message: intl.formatMessage({
                                        id: 'error.validation.notBlank',
                                        defaultMessage: 'Please do not leave blank',
                                    }),
                                },
                            ],
                        })(
                            <Input
                                autoFocus
                                size="large"
                                data-testid="first_name"
                                placeholder={intl.formatMessage({
                                    id: 'common.firstName',
                                    defaultMessage: 'First Name',
                                })}
                                addonBefore={
                                    <Popover
                                        content={intl.formatMessage({
                                            id: 'common.firstName',
                                            defaultMessage: 'First Name',
                                        })}
                                    >
                                        <InfoCircleOutlined />
                                    </Popover>
                                }
                            />
                        )}
                    </Form.Item>
                    <Form.Item key="last_name" hasFeedback>
                        {getFieldDecorator('last_name', {
                            rules: [
                                {
                                    pattern: Regex.name,
                                    message: intl.formatMessage({
                                        id: 'error.validation.letters',
                                        defaultMessage: 'Please only use letters',
                                    }),
                                },
                                {
                                    required: true,
                                    message: intl.formatMessage({
                                        id: 'error.validation.notBlank',
                                        defaultMessage: 'Please do not leave blank',
                                    }),
                                },
                            ],
                        })(
                            <Input
                                size="large"
                                data-testid="last_name"
                                placeholder={intl.formatMessage({
                                    id: 'common.lastName',
                                    defaultMessage: 'Last Name',
                                })}
                                addonBefore={
                                    <Popover
                                        content={intl.formatMessage({
                                            id: 'common.lastName',
                                            defaultMessage: 'Last Name',
                                        })}
                                    >
                                        <InfoCircleOutlined />
                                    </Popover>
                                }
                            />
                        )}
                    </Form.Item>
                    <Form.Item key="company_name" hasFeedback>
                        {getFieldDecorator('company_name', {
                            rules: [
                                {
                                    required: true,
                                    message: intl.formatMessage({
                                        id: 'error.validation.notBlank',
                                        defaultMessage: 'Please do not leave blank',
                                    }),
                                },
                            ],
                        })(
                            <Input
                                size="large"
                                data-testid="company_name"
                                placeholder={intl.formatMessage({
                                    id: 'welcome.SignUp.companyName',
                                    defaultMessage: 'Company Name',
                                })}
                                addonBefore={
                                    <Popover
                                        content={intl.formatMessage({
                                            id: 'welcome.SignUp.companyName',
                                            defaultMessage: 'Company Name',
                                        })}
                                    >
                                        <InfoCircleOutlined />
                                    </Popover>
                                }
                            />
                        )}
                    </Form.Item>
                    <Form.Item key="business_number" hasFeedback>
                        {getFieldDecorator(
                            'business_number',
                            {}
                        )(
                            <Input
                                size="large"
                                data-testid="business_number"
                                placeholder={intl.formatMessage({
                                    id: 'welcome.SignUp.taxIDOrEIN',
                                    defaultMessage: 'Tax ID Number/EIN/Company Registration No.',
                                })}
                                addonBefore={
                                    <Popover
                                        content={intl.formatMessage({
                                            id: 'welcome.SignUp.taxIDOrEIN',
                                            defaultMessage: 'Tax ID Number/EIN/Company Registration No.',
                                        })}
                                    >
                                        <InfoCircleOutlined />
                                    </Popover>
                                }
                            />
                        )}
                    </Form.Item>
                    <InternationalPhone
                        style={{ position: 'relative', top: '-8px' }}
                        key="int-phone"
                        value={phone} // This is required but is buggy and doesn't work all the time, we getDerivedStateFromProps @ customValue instead
                        customValue={phone}
                        errorStatus={errorStatus}
                        onChange={setPhone}
                        countryOverride={country[0]?.id}
                    />
                    <AddressAutoComplete
                        customTitle={intl.formatMessage({
                            id: 'welcome.SignUp.companyAddress',
                            defaultMessage: 'Company Address',
                        })}
                        getFieldDecorator={getFieldDecorator}
                        form={form}
                        hideLabels
                        bypassCounty
                    />
                </FormLayout>
                <TrackNavigation
                    disabled={submitting}
                    loading={submitting}
                    handleSubmit={() => handleSubmit(validateFields)}
                    handleReverse={handleReverse}
                />
                {webFeatureEnableTeamSignUpRecaptcha && (
                    <Box size="xxxs">
                        <Text align="center" size="xxxs" color="certnGray500">
                            <FormattedMessage
                                id="welcome.SignUp.recaptchaText"
                                defaultMessage="This site is protected by reCAPTCHA and the Google {privacyPolicy} and {termsOfService} apply."
                                values={{
                                    privacyPolicy: (
                                        <a href="https://policies.google.com/privacy">
                                            <FormattedMessage
                                                id="welcome.SignUp.recaptchaPrivacyPolicy"
                                                defaultMessage="Privacy Policy"
                                            />
                                        </a>
                                    ),
                                    termsOfService: (
                                        <a href="https://policies.google.com/terms">
                                            <FormattedMessage
                                                id="welcome.SignUp.recaptchaTermsOfService"
                                                defaultMessage="Terms of Service"
                                            />
                                        </a>
                                    ),
                                }}
                            />
                        </Text>
                    </Box>
                )}
            </Form>
        </AppearRight>
    );
};

export default Form.create()(injectIntl(MoreInfoForm));
