// Libraries
import React from 'react';
import { Menu } from 'antd';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { startCase } from 'lodash';

// Components
import { ListCellText } from 'certn-ui/List';

// Utils
import { useTranslateOrderStatus } from 'hooks/useTranslateOrderStatus';
import Constants from 'utils/constants';

const ItemUpgradeAction = ({ applicant, user, ...rest }) => {
    const { webFeatureEnableNewStatusSystem } = useFlags();
    const { translateOrderStatus } = useTranslateOrderStatus();

    const history = useHistory();

    const paymentVerified = user.team?.payment_verified;
    const canUpgrade = applicant.can_upgrade;
    const applicantInfo = applicant.information?.addresses;

    // LDFlag webFeatureEnableNewStatusSystem
    const reportStatus = applicant.report_status;
    const orderStatus = applicant.order_status;

    const getActiveButtonColor = () => {
        // LDFlag webFeatureEnableNewStatusSystem
        if (applicantInfo && webFeatureEnableNewStatusSystem && orderStatus === Constants.orderStatus.ACTION_REQUIRED)
            return 'certnRed500';
        // LDFlag webFeatureEnableNewStatusSystem
        if (reportStatus === Constants.reportStatus.ACTION_REQUIRED && applicantInfo) return 'certnRed500';
        if (paymentVerified && canUpgrade && applicantInfo) return 'certnGreen700';
        return 'certnGray400';
    };

    const renderActiveButtonText = () => {
        // LDFlag webFeatureEnableNewStatusSystem
        if (webFeatureEnableNewStatusSystem && orderStatus === Constants.orderStatus.ACTION_REQUIRED)
            return startCase(translateOrderStatus(orderStatus));
        if (reportStatus === Constants.reportStatus.ACTION_REQUIRED)
            return <FormattedMessage id="004e5.StatusDropdown.actionRequired" defaultMessage="Action Required" />;

        if (canUpgrade)
            return (
                <FormattedMessage id="004e5.StatusDropdown.upgradeApplication" defaultMessage="Upgrade Application" />
            );

        return <FormattedMessage id="004e5.StatusDropdown.waitingOnApplicant" defaultMessage="Waiting on Applicant" />;
    };

    const handleClick = () => {
        paymentVerified && canUpgrade && applicantInfo && history.push('/hr/screen', { applicant });
    };

    return (
        <Menu.Item {...rest}>
            <ListCellText
                clickable
                color={getActiveButtonColor()}
                disabled={!paymentVerified || !canUpgrade}
                onClick={handleClick}
            >
                {renderActiveButtonText()}
            </ListCellText>
        </Menu.Item>
    );
};

ItemUpgradeAction.propTypes = {
    applicant: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
};

export default ItemUpgradeAction;
