import { useSelector } from 'react-redux';
import { intl } from 'components/GlobalProvider';

import { fetchBulkConsentDocuments } from 'views/manager/sharedActions/ApplicationActions';
import {
    setBulkApplicationStatus as setBulkApplicationStatusHR,
    bulkDeleteApplications as bulkDeleteApplicationsHR,
    fetchBulkReportsPDF as fetchBulkReportsPDF_HR,
    fetchBulkReportInfoCSV as fetchBulkReportInfoCSV_HR,
} from 'views/manager/views/hr/views/applications/ApplicationsActions';

import {
    setBulkApplicationStatus as setBulkApplicationStatusPM,
    bulkDeleteApplications as bulkDeleteApplicationsPM,
    fetchBulkReportsPDF as fetchBulkReportsPDF_PM,
    fetchBulkReportInfoCSV as fetchBulkReportInfoCSV_PM,
} from 'views/manager/views/pm/views/applications/ApplicationsActions';

import { getApplicationsView as getApplicationsViewHR } from 'views/manager/views/hr/views/applications/ApplicationsSelectors';
import { getApplicationsView as getApplicationsViewPM } from 'views/manager/views/pm/views/applications/ApplicationsSelectors';

const OnboardingMap = (userMode) => {
    const applicationsViewHR = useSelector(getApplicationsViewHR);
    const applicationsViewPM = useSelector(getApplicationsViewPM);
    const flowMap = {
        HR: {
            move: {
                action: setBulkApplicationStatusHR,
                actions: [
                    {
                        status: 'PROSPECT',
                        title: intl.formatMessage({
                            id: '9492f.BulkActions.hrProspects',
                            defaultMessage: 'Prospects',
                        }),
                    },
                    {
                        status: 'OFFER_PENDING',
                        title: intl.formatMessage({
                            id: '9492f.BulkActions.hrEmploymentPending',
                            defaultMessage: 'Employment Pending',
                        }),
                    },
                    {
                        status: 'OFFER_ACCEPTED',
                        title: intl.formatMessage({
                            id: '9492f.BulkActions.hrEmployees',
                            defaultMessage: 'Employees',
                        }),
                    },
                    {
                        status: 'ARCHIVED',
                        title: intl.formatMessage({ id: 'common.archive', defaultMessage: 'Archive' }),
                    },
                ],
            },
            remove: {
                action: setBulkApplicationStatusHR,
                actions: [
                    {
                        status: 'NONE',
                        title: intl.formatMessage({
                            id: 'common.none',
                            defaultMessage: 'None',
                        }),
                    },
                ],
            },
            delete: {
                action: bulkDeleteApplicationsHR,
                actions: [
                    {
                        status: 'DELETE',
                        title: intl.formatMessage({
                            id: 'common.delete',
                            defaultMessage: 'Delete',
                        }),
                    },
                ],
            },
            bulkDownloadPDF: {
                action: fetchBulkReportsPDF_HR,
            },
            bulkDownloadCSV: {
                action: fetchBulkReportInfoCSV_HR,
            },
            bulkDownloadConsentDocuments: {
                action: fetchBulkConsentDocuments,
            },
            appView: applicationsViewHR,
        },
        PM: {
            move: {
                action: setBulkApplicationStatusPM,
                actions: [
                    {
                        status: 'PROSPECT',
                        title: intl.formatMessage({
                            id: '9492f.BulkActions.pmWaitlist',
                            defaultMessage: 'Waitlist',
                        }),
                    },
                    {
                        status: 'OFFER_PENDING',
                        title: intl.formatMessage({
                            id: '9492f.BulkActions.pmPending',
                            defaultMessage: 'Tenancy Pending',
                        }),
                    },
                    {
                        status: 'OFFER_ACCEPTED',
                        title: intl.formatMessage({
                            id: '9492f.BulkActions.pmResidents',
                            defaultMessage: 'Residents',
                        }),
                    },
                    {
                        status: 'ARCHIVED',
                        title: intl.formatMessage({ id: 'common.archive', defaultMessage: 'Archive' }),
                    },
                ],
            },
            remove: {
                action: setBulkApplicationStatusPM,
                actions: [
                    {
                        status: 'NONE',
                        title: intl.formatMessage({
                            id: 'common.none',
                            defaultMessage: 'None',
                        }),
                    },
                ],
            },
            delete: {
                action: bulkDeleteApplicationsPM,
                actions: [
                    {
                        status: 'DELETE',
                        title: intl.formatMessage({
                            id: 'common.delete',
                            defaultMessage: 'Delete',
                        }),
                    },
                ],
            },
            bulkDownloadPDF: {
                action: fetchBulkReportsPDF_PM,
            },
            bulkDownloadCSV: {
                action: fetchBulkReportInfoCSV_PM,
            },
            bulkDownloadConsentDocuments: {
                action: fetchBulkConsentDocuments,
            },
            appView: applicationsViewPM,
        },
    };

    return flowMap[userMode] || {};
};

export default OnboardingMap;
