// Libraries
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

// Routes & Router
import Loader from 'certn-ui/Loader';

// UI Components
import { ErrorAlertAPI } from 'certn-ui/ErrorAlert';
import Titlebar from 'certn-ui/Titlebar';
import Navbar, { NavMain, NavLeft, NavRight } from 'certn-ui/Navbar';
import { NavLogo } from 'certn-ui/NavLogo';
import Layout, { LayoutBody } from 'certn-ui/Layout';
import { ButtonLine } from 'certn-ui/Button';
import { ProgressBar } from 'certn-ui/ProgressBar';
import { Form } from '@ant-design/compatible';
import { Button, Alert } from 'antd';
import { Link } from 'react-router-dom';
import Trophy from 'images/icons/Trophy.svg';
import Image from 'certn-ui/Image';
import Path from 'modules/Path';
import Constants from 'utils/constants';
import { TrackNavigation } from 'views/welcome/components';

// Actions & Selectors
import { getReferenceQuestions, getError, getIsSubmitted, getIsFetching } from 'views/reference/ReferenceSelectors';
import {
    fetchReferenceQuestions,
    patchReferenceQuestion,
    patchReferenceQuestions,
    submitReferenceQuestions,
} from 'views/reference/ReferenceActions';
import { get } from 'lodash';
import { ReferenceForm } from './components';
import { intl } from 'components/GlobalProvider';
import { getLaunchDarklyFlags, getTeamLogo } from 'base/BaseSelectors';

// CONSTANTS
import { NOT_APPLICABLE } from './ReferenceConstants';

const Reference = ({ form: { getFieldDecorator, validateFieldsAndScroll }, location }) => {
    const dispatch = useDispatch();
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [completed, setCompleted] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [submitError, setSubmitError] = useState(null);
    const referenceQuestions = useSelector(getReferenceQuestions);
    const isSubmitted = useSelector(getIsSubmitted);
    const isFetching = useSelector(getIsFetching);
    const error = useSelector(getError);
    const teamLogo = useSelector(getTeamLogo);
    const { webFeatureEnableNewEmployerReferenceTrack } = useSelector(getLaunchDarklyFlags);

    const queryParams = new URLSearchParams(location.search);
    const isPhone = queryParams.get('method') === 'PHONE';

    useEffect(() => {
        // get the reference id from the URL without react router
        const referenceId = window.location.href.split('reference/')[1].split('/')[0];
        dispatch(fetchReferenceQuestions(referenceId));
    }, [dispatch]);

    const handleReverse = () => {
        if (completed) {
            setCompleted(false);
        } else if (currentQuestion > 0) {
            setCurrentQuestion(currentQuestion - 1);
        }
    };

    const makeQuestion = ({ answer, question }) => {
        switch (question.type) {
            case 'TRUE_FALSE':
                question.true_false_response = answer === NOT_APPLICABLE ? null : answer;
                break;
            case 'YES_NO':
                question.yes_no_response = answer === NOT_APPLICABLE ? null : answer;
                break;
            case 'NUMERIC_RANGE':
                question.numeric_range_response = parseInt(answer, 10);
                break;
            case 'DATE':
                question.date_response = answer;
                break;
            case 'LONG_ANSWER':
                question.long_answer_response = answer;
                break;
            case 'MULTIPLE_CHOICE':
                question.multiple_choice_response = answer;
                break;
            default:
                setSubmitError(
                    <FormattedMessage
                        id="07d4b.Reference.default"
                        defaultMessage="We have encountered a problem submitting your answer. Please contact support@certn.co or use our live chat support."
                    />
                );
        }

        return question;
    };

    const submitAnswer = (data) => {
        const questions = get(referenceQuestions, ['reference_questionaire', 'reference_questions']);
        const question = questions[currentQuestion];

        const questionWithAnswer = makeQuestion({ answer: data[question.id], question });
        dispatch(patchReferenceQuestion(questionWithAnswer));

        if (currentQuestion < questions.length - 1) {
            setCurrentQuestion(currentQuestion + 1);
        } else {
            setCompleted(true);
        }
    };

    const submitAllAnswersAndFinish = (data) => {
        const answers = [];
        const questions = get(referenceQuestions, ['reference_questionaire', 'reference_questions']);

        questions.forEach((question) => {
            const questionWithAnswer = makeQuestion({ answer: data[question.id], question });
            answers.push(questionWithAnswer);
        });

        dispatch(patchReferenceQuestions({ id: referenceQuestions.id, questions: answers }))
            .then(() => finishAndMarkSubmitted())
            .catch((error) => ErrorAlertAPI(error));
    };

    const finishAndMarkSubmitted = () => {
        setSubmitted(true);
        dispatch(submitReferenceQuestions(referenceQuestions.id));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        validateFieldsAndScroll((err, values) => {
            setSubmitError(null);
            if (!err) {
                isPhone ? submitAllAnswersAndFinish(values) : submitAnswer(values);
            }
        });
    };

    const getSubtitleText = () => {
        let info = null;
        let subtitleText = (
            <FormattedMessage
                id="07d4b.Reference.subtitleText"
                defaultMessage="Please fill out the following reference questions"
            />
        );
        if (referenceQuestions && referenceQuestions.type) {
            if (referenceQuestions.type === 'TENANCY') {
                info = referenceQuestions.address_reference.address.information;
                if (info.information) {
                    if (info) {
                        subtitleText = (
                            <FormattedMessage
                                id="07d4b.Reference.referenceWithName"
                                defaultMessage="{first_name} {last_name} has listed you as a rental reference on a recent application."
                                values={{
                                    first_name: info.information.first_name,
                                    last_name: info.information.last_name,
                                }}
                            />
                        );
                    } else {
                        subtitleText = (
                            <FormattedMessage
                                id="07d4b.Reference.rentalReference"
                                defaultMessage="Hello, you have been listed as a rental reference on a recent application. Please answer the following questions."
                            />
                        );
                    }
                }
            } else {
                info = webFeatureEnableNewEmployerReferenceTrack
                    ? referenceQuestions.employer_reference
                    : referenceQuestions.employer_reference.employer;
                if (info) {
                    subtitleText = (
                        <FormattedMessage
                            id="07d4b.Reference.employerReferenceWithName"
                            defaultMessage="{first_name} {last_name} has listed you as an employment reference on a recent application."
                            values={{ first_name: info.information.first_name, last_name: info.information.last_name }}
                        />
                    );
                } else {
                    subtitleText = (
                        <FormattedMessage
                            id="07d4b.Reference.employerReference"
                            defaultMessage="Hello, you have been listed as an employment reference on a recent application. Please answer the following questions."
                        />
                    );
                }
            }
        }
        return subtitleText;
    };

    const renderQuestions = (questions) => {
        if (isPhone) {
            return (
                <Form onSubmit={handleSubmit} style={{ width: '100%' }}>
                    {questions.map((question, index) => (
                        <ReferenceForm
                            key={question.id}
                            question={question}
                            questionCount={index}
                            getFieldDecorator={getFieldDecorator}
                        />
                    ))}
                    <ButtonLine>
                        <Button type="primary" size="large" htmlType="submit">
                            <FormattedMessage id="07d4b.Reference.finishAndSubmit" defaultMessage="Finish And Submit" />
                        </Button>
                    </ButtonLine>
                </Form>
            );
        }

        const thisQuestion = questions[currentQuestion];

        if (thisQuestion && !completed) {
            return (
                <Form style={{ width: '100%' }} data-testid={`${thisQuestion.type.toLowerCase()}`}>
                    <ReferenceForm
                        question={thisQuestion}
                        questionCount={currentQuestion}
                        getFieldDecorator={getFieldDecorator}
                        submitReferenceQuestions={finishAndMarkSubmitted}
                    />
                </Form>
            );
        }
    };

    const renderContent = () => {
        if (isFetching) {
            return <Loader />;
        }

        if (error?.referenceStatus === Constants.referenceResultStatus.UNRESPONSIVE) {
            return (
                <Titlebar
                    size="xlrg"
                    center
                    title={<FormattedMessage id="07d4b.Reference.titleBarTitleExpired" defaultMessage="Expired" />}
                    subtitle={
                        <FormattedMessage
                            id="07d4b.Reference.sorryExpired"
                            defaultMessage="Sorry, but this reference can no longer be submitted."
                        />
                    }
                />
            );
        }

        if (submitted) {
            return (
                <>
                    <Titlebar
                        size="xlrg"
                        center
                        title={<FormattedMessage id="07d4b.Reference.titleBarTitle" defaultMessage="Submitted" />}
                        subtitle={
                            <FormattedMessage
                                id="07d4b.Reference.titleBarSubtitle"
                                defaultMessage="Your answers have been successfully submitted. Thank you!"
                            />
                        }
                    />
                    <Image
                        src={Trophy}
                        alt={<FormattedMessage id="07d4b.Reference.titleBarAlt" defaultMessage="Reference Submitted" />}
                        size="xxlrg"
                    />
                </>
            );
        }

        if (!isSubmitted) {
            let progressPercent = 0;
            const questions = get(referenceQuestions, ['reference_questionaire', 'reference_questions']);

            if (questions) {
                progressPercent = (currentQuestion / questions.length) * 100;

                return (
                    <>
                        <Titlebar
                            size="xlrg"
                            center
                            title={
                                <FormattedMessage
                                    id="07d4b.Reference.referenceQuestions"
                                    defaultMessage="Reference Questions"
                                />
                            }
                            subtitle={getSubtitleText()}
                        />
                        {completed && (
                            <ButtonLine>
                                <Button type="primary" size="large" onClick={finishAndMarkSubmitted}>
                                    <FormattedMessage
                                        id="07d4b.Reference.finishAndSubmit"
                                        defaultMessage="Finish And Submit"
                                    />
                                </Button>
                            </ButtonLine>
                        )}
                        {submitError && (
                            <ButtonLine>
                                <Alert message={submitError} type="error" showIcon />
                            </ButtonLine>
                        )}
                        {renderQuestions(questions)}
                        {/* Parity with removing arrow nav */}

                        {!isPhone && (
                            <TrackNavigation
                                nextButtonTitle={
                                    <FormattedMessage id="e9263.ReferenceForm.next" defaultMessage="Next Question" />
                                }
                                handleSubmit={handleSubmit}
                                handleReverse={handleReverse}
                                hideBack={currentQuestion === 0 || completed}
                                hideNext={completed}
                            />
                        )}
                        <ProgressBar percent={progressPercent} complete={completed} />
                    </>
                );
            }
        }
        return (
            <>
                <Titlebar
                    size="xlrg"
                    center
                    title={<FormattedMessage id="07d4b.Reference.titleBarTitle" defaultMessage="Submitted" />}
                    subtitle={
                        error ? (
                            <FormattedMessage
                                id="07d4b.Reference.sorry"
                                defaultMessage="Sorry, but this reference has already been responded to."
                            />
                        ) : (
                            <FormattedMessage
                                id="07d4b.Reference.success"
                                defaultMessage="Your answers have been successfully submitted. Thank you!"
                            />
                        )
                    }
                />
                {!error && (
                    <Image
                        src={Trophy}
                        alt={<FormattedMessage id="07d4b.Reference.titleBarAlt" defaultMessage="Reference Submitted" />}
                        size="xxlrg"
                    />
                )}
            </>
        );
    };

    const altTag = Path.getAltTagFromSubdomain();
    return (
        <Layout>
            <Navbar fixed>
                <NavMain fixed>
                    <NavLeft>
                        <NavLogo
                            alt={altTag}
                            src={teamLogo}
                            title={intl.formatMessage({
                                id: 'common.Logo',
                                defaultMessage: 'Logo',
                            })}
                        />
                    </NavLeft>
                    <NavRight>
                        <Link to="/login">
                            <FormattedMessage id="07d4b.Reference.login" defaultMessage="Login" />
                        </Link>
                    </NavRight>
                </NavMain>
            </Navbar>
            <LayoutBody>{renderContent()}</LayoutBody>
        </Layout>
    );
};

export default Form.create()(Reference);
