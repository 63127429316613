// Libraries
import { pickBy, identity, isNil, isEmpty, uniq, max, flatten } from 'lodash';
import numeral from 'numeral';
import * as Sentry from '@sentry/browser';
import { notification } from 'antd';

// Modules
import Constants from 'utils/constants';
import { getRequest } from 'utils/http';
import { stringifyDate } from 'modules/Format';
import { isUKOrGB, isUSAOrCanada, OTHER } from 'modules/Countries';

// Components
import { intl } from 'components/GlobalProvider';
import { ErrorAlertCustom, ErrorAlertAPI } from 'certn-ui/ErrorAlert';

// Actions & Selectors
import {
    getLaunchDarklyFlags,
    getUserModeIsHR,
    getUserModeIsPM,
    getUserMode,
    getTeam,
    getTeamCurrency,
    getCountries,
    getConfigurablePotatoChecks,
} from 'base/BaseSelectors';
import {
    requestPackages,
    fetchApplication,
    createApplicationQuick as createApplicationQuickHR,
} from 'views/manager/views/hr/views/applications/ApplicationsActions';
import { submitApplications } from 'views/manager/sharedActions/ApplicationActions';
import {
    requestPackages as requestPackagesPM,
    createApplicationQuick as createApplicationQuickPM,
} from 'views/manager/views/pm/views/applications/ApplicationsActions';
import { fetchAllListings } from 'views/manager/views/pm/views/listings/ListingsActions';
import { getCurrentPackageTestCollection } from 'views/manager/views/hr/views/postings/PostingsSelectors';
import { createPosting, editPosting } from 'views/manager/views/hr/views/postings/PostingsActions';
import { NAMESPACE, INDIVIDUALLY, ActionTypes } from 'views/manager/features/ScreenApplicant/ScreenApplicantConstants';
import {
    getApplicationOfInterest,
    getRequestedCountries,
    getUsCriminalRecordCheckYears,
    getAdditionalOptions,
    getPackageID,
    getIsFollowUpFlow,
    getRCMPQuickScreen,
    getConvictionsExist,
    getIsRequestedCountriesEmpty,
    getIntCrimSelectedAndEmpty,
    getUkVisibleChecks,
    getVisibleChecks,
    getPurchasedAndSelectedChecks,
    getSelectedChecks,
    getSelectedCheckKeys,
    getChecks,
    getCheckKeysInFollowUpFlow,
    getUnPurchasedAndSelectedChecks,
    getIsInternationalCrimInFUF,
    getPackageSelected,
    getQuickScreenPath,
} from './ScreenApplicantSelectors';

// Actions Types
export const SET_APPLICATION_OF_INTEREST = `${NAMESPACE}SET_APPLICATION_OF_INTEREST`;
export const SET_CHECKS = `${NAMESPACE}SET_CHECKS`;
export const TOGGLE_CHECK = `${NAMESPACE}TOGGLE_CHECK`;
export const SET_TOTAL = `${NAMESPACE}SET_TOTAL`;
export const SET_ADDITIONAL_OPTIONS = `${NAMESPACE}SET_ADDITIONAL_OPTIONS`;
export const SET_PACKAGE_SELECTED = `${NAMESPACE}SET_PACKAGE_SELECTED`;
export const RESET_ADDITIONAL_OPTIONS = `${NAMESPACE}RESET_ADDITIONAL_OPTIONS`;
export const RESET_PACKAGE = `${NAMESPACE}RESET_PACKAGE`;
export const PATCH_APPLICANT = `${NAMESPACE}PATCH_APPLICANT`;
export const RESET_APPLICANT = `${NAMESPACE}RESET_APPLICANT`;
export const SET_SHOULD_PERSIST_SCREEN_APPLICANT_STATE = `${NAMESPACE}SET_SHOULD_PERSIST_SCREEN_APPLICANT_STATE`;
export const SET_CHECK_PAGE_COMPLETE = `${NAMESPACE}SET_CHECK_PAGE_COMPLETE`;
export const MODIFY_CHECK_STATE = `${NAMESPACE}MODIFY_CHECK_STATE`;

import {
    getAdditionalOptionsKeys,
    getCheckContent,
} from 'views/manager/features/ScreenApplicant/components/ScreenApplicant/MicroServiceContent';
import getChecksConfig from 'views/manager/features/ScreenApplicant/components/ScreenApplicant/getChecksConfig';

// Constants
import { CHECK_REQUEST } from 'base/BaseConstants';

// Async ACtions
/**
 * fetchGenericChecks
 * Query Params
 * applicant=${applicantID} - checks available to the applicant
 * posting=${posting} - ^
 * package=${package} - ^
 * team=${teamID} - ^
 */

export const fetchGenericChecks = ({
    applicantID = null,
    teamID = null,
    postingID = null,
    packageID = null,
} = {}) => async (dispatch, getState) => {
    const state = getState();
    const userModeIsHR = getUserModeIsHR(state);
    const { GET_GENERIC_CHECKS } = ActionTypes;
    dispatch({
        type: GET_GENERIC_CHECKS.REQ,
    });

    // Endpoint decided based on type of ID passed
    let endpoint = '/checks';
    if (applicantID) endpoint = `/checks?applicant=${applicantID}`;
    if (postingID) endpoint = `/checks?posting=${postingID}`;
    if (packageID) endpoint = `/checks?posting=${packageID}`;
    if (teamID) endpoint = `/checks?team=${teamID}`;

    let lockSelected;
    if (!applicantID && !postingID && !packageID) lockSelected = true;

    const upgrade = !!applicantID;
    try {
        const version = userModeIsHR ? 'v1' : 'v2';
        const allChecks = await getRequest({
            hr: userModeIsHR,
            version,
            endpoint,
        });
        const checks = getChecksConfig({ allChecks, state, lockSelected, upgrade });

        await dispatch({
            type: GET_GENERIC_CHECKS.SUC,
            payload: { checks },
        });
        return checks;
    } catch (error) {
        dispatch({
            type: GET_GENERIC_CHECKS.ERR,
            payload: { error },
        });
        ErrorAlertAPI(error);
    }
};

// Actions
/**
 * Setup Checks Page
 * Function for services page to call to setup
 * @param application - application to modify services on
 * @param packageID - ID of package to let the app know to finalize with setting up package data
 */
export const setupServicesPage = ({ applicant = {}, packageID }) => async (dispatch) => {
    const applicantID = applicant?.id;

    // reset page state
    await dispatch(resetAdditionalOptions());
    await dispatch(clearApplicationOfInterest());
    await dispatch(resetState());

    // set application data and refetch checks, then run conditional logic
    await dispatch(setApplicationOfInterest(applicant));
    await dispatch(fetchGenericChecks({ applicantID, packageID }));
    if (applicantID) await dispatch(setUpgradeAdditionalOptions(applicant)); // upgrade
    if (packageID) await dispatch(setPackageData()); // package selection/edit

    await dispatch(recalculateTotal());
};

// State reset helper function
const resetState = () => (dispatch, getState) => {
    const state = getState();
    const userModeIsHR = getUserModeIsHR(state);
    if (userModeIsHR) dispatch(resetPackage()); // HR reset package
    dispatch(resetApplicant()); // Reset property ID, listing id, screening type
    dispatch(resetServicesOpen());
};

/**
 * Clear application of interest
 */
const clearApplicationOfInterest = () => (dispatch) => {
    const application = {};
    dispatch({ type: SET_APPLICATION_OF_INTEREST, payload: { application } });
};

/**
 * Setup application during upgrade flow
 * @param application
 */
export const setApplicationOfInterest = (application) => (dispatch) => {
    dispatch({ type: SET_APPLICATION_OF_INTEREST, payload: { application } });
};

/**
 * Set additional upgrade options
 * @param application
 */
export const setUpgradeAdditionalOptions = (application) => (dispatch, getState) => {
    const state = getState();

    // If we're in FUF, FUF initialization will handle the rest
    // LDFlag webFeatureEnableNewStatusSystem
    const isWebFeatureEnableNewStatusSystem = getLaunchDarklyFlags(state)?.webFeatureEnableNewStatusSystem;
    if (isWebFeatureEnableNewStatusSystem && application.order_status === Constants.orderStatus.ACTION_REQUIRED) return;
    if (!isWebFeatureEnableNewStatusSystem && application.report_status === Constants.reportStatus.ACTION_REQUIRED)
        return;

    // Upgrade Flow
    const settingsConfig = getTeam(state)?.settings_config;
    const selectedChecksKeys = getSelectedCheckKeys(state);

    // First, gather all the additional option keys from the selected checks
    const relevantAdditionalOptionKeys = getSelectedChecksAdditionalOptionKeys(settingsConfig, selectedChecksKeys);
    // Gather the data associated to the keys above from the application
    const additionalOptions = pickBy(application, (value, key) => relevantAdditionalOptionKeys.includes(key));

    if (additionalOptions?.requested_countries) {
        // At some point the format of requested_countries was changed on the applicant: { country_code: 'JP', country_name: 'Japan' }
        // Format to ['JP', 'CA']
        const requestedCountries = additionalOptions.requested_countries.map((country) => country?.country_code);
        additionalOptions.requested_countries = requestedCountries;
    }

    // Set additional options based on application of interest's selected check settings
    dispatch(setAdditionalOptions(additionalOptions));
};

/**
 * Status calls for page loading
 */
export const setServicePageComplete = (dispatch) => dispatch({ type: SET_CHECK_PAGE_COMPLETE, payload: true });

export const setServicePageLoading = (dispatch) => dispatch({ type: SET_CHECK_PAGE_COMPLETE, payload: false });

/**
 * Toggle if the service is selected
 * @param {string} key
 */
export const toggleCheck = (key, addOptions = undefined) => async (dispatch, getState) => {
    const state = getState();
    const visibleChecks = getUkVisibleChecks(state);
    const checkOfInterest = visibleChecks.find((check) => check.requestFlag === key);
    const onSelect = !checkOfInterest.isSelected;
    const selectedChecks = getSelectedChecks(state);
    const allChecks = getChecks(state);

    let checksToAdd = [];
    let checksToRemove = [];
    const checkFunction = onSelect ? onCheckAdd : onCheckRemove;
    [checksToAdd, checksToRemove] = checkFunction({ selectedChecks, allChecks, toggledCheck: key, visibleChecks });

    // Check open/close panel
    // If checkOfInterest is already open, and we are deselecting it, remove additional options
    if (checkOfInterest.isOpen) {
        // Check with additional options is open, and you are saving; close additional options and continue to toggle check
        if (onSelect) dispatch(toggleCheckIsOpen(key));
        // If we are _not_ toggling, it means we closing the already selected and open check (not removing it)
        else {
            // Save additional option updates before closing
            if (addOptions) {
                dispatch(setAdditionalOptions(addOptions));
            }
            // close an return to bypass toggle logic
            return dispatch(toggleCheckIsOpen(key));
        }
    }

    if (checksToRemove.length) await dispatch(removeMultipleAdditionalOptions(checksToRemove));

    // After toggle, set additional options
    if (onSelect && addOptions) await dispatch(setAdditionalOptions(addOptions));

    await dispatch({
        type: TOGGLE_CHECK,
        payload: { checksToAdd, checksToRemove },
    });

    return dispatch(recalculateTotal());
};

// When removing multiple services
const removeMultipleAdditionalOptions = (services) => (dispatch) => {
    if (services?.length > 0) {
        services.forEach((service) => dispatch(removeAdditionalOptions(service)));
    }
    return null;
};

// Removes additional options, and Some services require additional option tweaks on toggle off
const removeAdditionalOptions = (key) => (dispatch, getState) => {
    const state = getState();
    const settingsConfig = getTeam(state).settings_config;
    const additionalOptionKeys = getAdditionalOptionsKeys(key, settingsConfig);
    const values = additionalOptionKeys?.reduce((acc, item) => ({ ...acc, [item]: undefined }), {});
    if (!isEmpty(values)) dispatch(setAdditionalOptions(values));
    return null;
};

export const getMultiplier = (check, additionalOptions) => {
    if (check.checkAdditionalOptions?.length) {
        const min = check.checkAdditionalOptions.find((option) => option.endsWith('_min'));
        const yearOrIndividually = check.checkAdditionalOptions.find((option) => option.endsWith('_individually'));
        if (additionalOptions[yearOrIndividually] === INDIVIDUALLY) {
            return additionalOptions[min];
        }
    }
    return 1;
};

/**
 * Recalculate total
 * This function runs once for every check + deps we add/remove, can it be optimized?
 */
export const recalculateTotal = () => (dispatch, getState) => {
    const state = getState();
    const team = getTeam(state);
    const teamCurrency = getTeamCurrency(state);
    const intlCountryPrices = getCountries(state);
    const requestedCountries = getRequestedCountries(state) || [];
    const usCriminalRecordCheckYears =
        getUsCriminalRecordCheckYears(state) || team.settings_config.us_criminal_record_check_years;
    const selectedChecksKeys = getSelectedCheckKeys(state);
    const additionalOptions = getAdditionalOptions(state);
    const checks = getVisibleChecks(state);
    const userMode = getUserMode(state).toLowerCase();
    const unPurchasedAndSelectedChecks = getUnPurchasedAndSelectedChecks(state);
    let usd = 0;
    let local = 0;

    // Finds if Us Crim tier 3 is selected and sets the correct price based on options.
    const usCrimTier3Selected = checks.find(
        (check) => check.requestFlag === CHECK_REQUEST.US_CRIMINAL_RECORD_CHECK_TIER_3
    );
    if (usCrimTier3Selected?.isSelected) {
        const usCriminalYearsPriceMap = {
            7: 'seven',
            10: 'ten',
        };
        const price = usCriminalYearsPriceMap?.[usCriminalRecordCheckYears] || usCriminalYearsPriceMap['7'];
        usCrimTier3Selected.price =
            (
                +team.billing_plan[`${userMode}_${CHECK_REQUEST.US_CRIMINAL_RECORD_CHECK_TIER_3}_${price}_year_price`] +
                +team.billing_plan[`${userMode}_${CHECK_REQUEST.US_CRIMINAL_RECORD_CHECK_TIER_1}_price`] +
                +team.billing_plan[`${userMode}_${CHECK_REQUEST.US_CRIMINAL_RECORD_CHECK_TIER_2}_price`]
            )?.toString() ?? '0';
    }

    unPurchasedAndSelectedChecks.forEach((check) => {
        const { requestFlag, price } = check;
        // If price includes an exception, skip it in total calculation
        if (priceExceptions(requestFlag, selectedChecksKeys)) return;
        const multiplier = getMultiplier(check, additionalOptions);
        // TODO: Is this used? check.checks typically look like request_<check>
        if (/^us_.*/.test(requestFlag) && teamCurrency !== 'USD') {
            usd = numeral(price).multiply(multiplier).add(usd).value();
        } else {
            local = numeral(price).multiply(multiplier).add(local).value();
        }
    });

    const internationalCriminalRecordCheck = checks.find(
        (check) => check.requestFlag === CHECK_REQUEST.INTERNATIONAL_CRIMINAL_RECORD_CHECK
    );

    if (!internationalCriminalRecordCheck?.isPurchased || internationalCriminalRecordCheck?.isInFollowUpFlow) {
        requestedCountries.forEach((country) => {
            const foundCountry = intlCountryPrices?.find((item) => item.country_code === country);
            if (foundCountry) {
                // Get value from upper-casing field, then lower if undefined.
                // API will eventually make these uppercase to be consistent (written 02/2021)
                let value = 0;
                if (teamCurrency in foundCountry) {
                    value = foundCountry[teamCurrency];
                } else if (teamCurrency.toLowerCase() in foundCountry) {
                    value = foundCountry[teamCurrency.toLowerCase()];
                }
                local = numeral(local).add(value).value();
            }
        });
    }

    dispatch(setEstimatedTurnAroundTime);

    return dispatch({
        type: SET_TOTAL,
        payload: {
            total: {
                usd,
                local,
            },
        },
    });
};

export const priceExceptions = (requestName, selectedServices) => {
    // Identity Verification is Free when RCMP is selected
    if (requestName === CHECK_REQUEST.IDENTITY_VERIFICATION) {
        if (
            selectedServices.includes(CHECK_REQUEST.CRIMINAL_RECORD_CHECK) ||
            selectedServices.includes(CHECK_REQUEST.ENHANCED_CRIMINAL_RECORD_CHECK) ||
            selectedServices.includes(CHECK_REQUEST.VULNERABLE_SECTOR_CRIMINAL_RECORD_CHECK)
        ) {
            return true;
        }
    }
    if (requestName === CHECK_REQUEST.ENHANCED_IDENTITY_VERIFICATION) {
        if (
            selectedServices.includes(CHECK_REQUEST.UK_BASIC_DBS_CHECK) ||
            selectedServices.includes(CHECK_REQUEST.UK_BASIC_DS_CHECK) ||
            selectedServices.includes(CHECK_REQUEST.UK_RIGHT_TO_WORK_CHECK)
        ) {
            return true;
        }
    }
    return false;
};

/**
 * Shopping cart remove country from additional options
 * @param {string} countryCode // country we are removing
 */
export const removeCountry = (countryCode) => (dispatch, getState) => {
    const state = getState();
    const isInternationalCrimInFUF = getIsInternationalCrimInFUF(state);
    const additionalOptions = getAdditionalOptions(state);
    const requested_countries = additionalOptions.requested_countries.filter((item) => item !== countryCode);
    dispatch(setAdditionalOptions({ requested_countries }));
    /* if no more countries disable CHECK_REQUEST.INTERNATIONAL_CRIMINAL_RECORD_CHECK (recalculate total in toggle) */
    if (requested_countries.length === 0) {
        if (!isInternationalCrimInFUF) return dispatch(toggleCheck(CHECK_REQUEST.INTERNATIONAL_CRIMINAL_RECORD_CHECK));
    }
    return dispatch(recalculateTotal());
};

/**
 * Follow Up Flow add country to additional options
 * @param {string} countryCode // country we are adding
 */
export const addCountry = (countryCode) => async (dispatch, getState) => {
    const state = getState();
    const requestedCountries = getAdditionalOptions(state)?.requested_countries;
    const requested_countries = isEmpty(requestedCountries)
        ? [countryCode]
        : [...requestedCountries, countryCode].sort();
    await dispatch(setAdditionalOptions({ requested_countries }));
    await dispatch(recalculateTotal());
};

/**
 * Set additional options for service
 * @param additionalOptions - Object of all options set via package or individual options set during selection
 */
export const setAdditionalOptions = (additionalOptions) => (dispatch) => {
    dispatch({
        type: SET_ADDITIONAL_OPTIONS,
        payload: { additionalOptions },
    });
};

/**
 * Reset additional options object
 */
const resetAdditionalOptions = () => (dispatch) => {
    dispatch({ type: RESET_ADDITIONAL_OPTIONS });
};

/**
 * Remove selected package/posting
 */
const resetPackage = () => (dispatch) => {
    dispatch({ type: RESET_PACKAGE });
};

/**
 * Set Building to maintain on add new listing
 */
export const setPropertyID = (propertyID) => (dispatch) => {
    dispatch({ type: PATCH_APPLICANT, payload: { propertyID } });
};

/**
 * Select property
 */
export const handleBuildingSelect = (propertyID) => (dispatch) => {
    dispatch(fetchAllListings(propertyID));
    dispatch(handleListingSelect(''));
    dispatch(setPropertyID(propertyID));
};

/**
 * Select listing
 */
export const handleListingSelect = (listingID) => (dispatch) => {
    dispatch({ type: PATCH_APPLICANT, payload: { listingID } });
};

/**
 * Select building and newly created listing
 */
export const handleNewListingSelect = (values) => (dispatch) => {
    const { listingID, propertyID } = values;
    dispatch(handleBuildingSelect(propertyID));
    dispatch({ type: PATCH_APPLICANT, payload: { listingID } });
};

/**
 * Select listing
 */
export const handleScreenTypeSelect = (screenType) => (dispatch) => {
    dispatch({ type: PATCH_APPLICANT, payload: { screenType } });
};

/**
 * Toggle Convictions
 */
export const handleConvictionToggle = (convictionsExist) => (dispatch) => {
    dispatch({ type: PATCH_APPLICANT, payload: { convictionsExist } });
};

/**
 * Reset building id
 */
const resetApplicant = () => (dispatch) => {
    dispatch({ type: RESET_APPLICANT });
};

/**
 * Submit application request
 * @param {object} values // list of Applicant page values such as email, language, team_id
 */
export const submitApplication = (values) => async (dispatch, getState) => {
    const { keys, contact_info, notificationEmails, listing_id, property_id, ...rest } = values;
    const state = getState();
    const userModeIsHR = getUserModeIsHR(state);
    const userModeIsPM = getUserModeIsPM(state);
    const packageSelected = getPackageSelected(state);

    // always add listing_id for PM, if HR make sure a package is currently selected
    let listingId;
    if (userModeIsHR && packageSelected) listingId = getPackageID(state);
    if (userModeIsPM) listingId = listing_id;

    const request = pickBy(
        {
            ...buildPackageRequest(state),
            ...rest,
            listing_id: listingId,
        },
        (val) => !isNil(val)
    );
    if (!values.email_message && values.email_message_new_default) request.email_message = ''; // if blank and set default we reset

    return dispatch(
        submitApplications({ data: request, contactInfoList: contact_info, notificationEmails, hr: userModeIsHR })
    );
};

/**
 * Submit quick screen request
 * @param {object} values // list of Applicant page values such as email, language, team_id
 */
export const submitQuickScreen = (values, webFeatureRequireCurrentAndPositionPropertyAddressesForQuickscreen) => (
    dispatch,
    getState
) => {
    const state = getState();
    const userModeIsHR = getUserModeIsHR(state);
    const listingId = userModeIsHR ? getPackageID(state) : values.listing_id;
    const createApplicationQuick = userModeIsHR ? createApplicationQuickHR : createApplicationQuickPM;

    // Adjust request for quick screen items such as convictions
    const newValues = modifyQuickScreenRequest(
        values,
        state,
        userModeIsHR,
        webFeatureRequireCurrentAndPositionPropertyAddressesForQuickscreen
    );

    const request = pickBy(
        {
            ...buildPackageRequest(state),
            ...newValues,
            listing_id: listingId,
        },
        (val) => !isNil(val)
    );

    return dispatch(createApplicationQuick(request));
};

/**
 * Run through quick screen request adjustment
 * @param {object} values // list of Applicant page values such as email, language, team_id
 */
export const modifyQuickScreenRequest = (
    values,
    state,
    userModeIsHR,
    webFeatureRequireCurrentAndPositionPropertyAddressesForQuickscreen
) => {
    const selectedChecksKeys = getSelectedCheckKeys(state);
    const identity_numbers = [];
    if (values.sin) {
        identity_numbers.push({ country: 'CA', number: values.sin });
    }
    if (values.ssn) {
        identity_numbers.push({ country: 'US', number: values.ssn });
    }

    let bodyData = {
        email: values.email,
        team_id: values.team_id,
        tag: values.tag,
        information: {
            first_name: values.first_name,
            middle_name: values.middle_name,
            last_name: values.last_name,
            phone_number: values.phone_number,
            date_of_birth: stringifyDate(values.date_of_birth),
            license_number: values.license_number,
            gender: values.gender,
            birth_country: values.birth_country,
            birth_province_state: values.birth_province_state,
            birth_other_province_state: values.birth_other_province_state,
            birth_city: values.birth_city,
            rcmp_consent_form: values.rcmp_consent_form,
            identity_numbers,
            addresses: [
                {
                    city: values.city,
                    country: values.country,
                    county: values?.county,
                    unit: values.unit,
                    address: values.address,
                    province_state: values.other_province_state ? OTHER : values.province_state,
                    other_province_state: values.other_province_state,
                    postal_code: values.postal_code,
                    current: true,
                },
            ],
            social_media_details: {
                high_school_name: values.high_school_name,
                college_country: values.college_country,
                college_name: values.college_name,
                instagram_url: values.instagram_url,
                facebook_url: values.facebook_url,
                tiktok_url: values.tiktok_url,
                linkedin_url: values.linkedin_url,
                twitter_url: values.twitter_url,
                reddit_url: values.reddit_url,
            },
        },
        request_base: false,
    };
    if (webFeatureRequireCurrentAndPositionPropertyAddressesForQuickscreen) {
        if (userModeIsHR) {
            let locationType;
            if (values.gig) {
                locationType = 'Gig Location';
            } else locationType = 'Position Location';
            bodyData = {
                ...bodyData,
                position_or_property_location: {
                    address: values.address_work,
                    city: values.city_work,
                    province_state: values.other_province_state_work ? OTHER : values.province_state_work,
                    other_province_state: values.other_province_state_work,
                    country: values.country_work,
                    county: values.county_work,
                    postal_code: values.postal_code_work,
                    location_type: locationType,
                },
            };
        }
    }

    // Conviction Alterations
    const rcmpQuickScreen = getRCMPQuickScreen(state);
    if (rcmpQuickScreen) {
        const convictionsExist = getConvictionsExist(state);
        if (convictionsExist) bodyData.information.convictions = values.convictions;
        bodyData[CHECK_REQUEST.IDENTITY_VERIFICATION] = false;
        bodyData.rcmp_manual_identity_verification = {
            id_frontside_image: values.id_frontside_image,
            witness_name: values.witness_name,
            primary_id: values.primary_id,
            secondary_id: values.secondary_id,
            id_verified_by_agent: values.id_verified_by_agent,
        };
    }

    // If only SOQUIJ remove addresses from request
    const soquij = [CHECK_REQUEST.SOQUIJ];
    const onlySoquij = selectedChecksKeys.every((key) => soquij.includes(key));
    if (onlySoquij) delete bodyData.information.addresses;

    return bodyData;
};

/**
 * Upgrade application request
 */
export const upgradeApplication = () => (dispatch, getState) => {
    const state = getState();
    const isFollowUpFlow = getIsFollowUpFlow(state);
    const userModeIsHR = getUserModeIsHR(state);
    const applicantId = getApplicationOfInterest(state)?.id;
    const upgradeApps = userModeIsHR ? requestPackages : requestPackagesPM;

    let request = pickBy(buildPackageRequest(state), identity);

    // Follow Up Flow upgrade path
    if (isFollowUpFlow) request = dispatch(followUpFlowAdjustments(request));
    // Standard upgrade path
    return dispatch(upgradeApps(applicantId, request));
};

/**
 * Follow Up Flow requires additional changes to request object
 * @param {object} request
 */
const followUpFlowAdjustments = (request) => (dispatch, getState) => {
    const state = getState();
    const checkKeysInFUF = getCheckKeysInFollowUpFlow(state);
    const isRequestedCountriesEmpty = getIsRequestedCountriesEmpty(state);

    if (checkKeysInFUF.includes(CHECK_REQUEST.INTERNATIONAL_CRIMINAL_RECORD_CHECK)) {
        // If no requested countries remove int crim as service
        if (isRequestedCountriesEmpty) {
            request[CHECK_REQUEST.ENHANCED_IDENTITY_VERIFICATION] = true;
            request[CHECK_REQUEST.INTERNATIONAL_CRIMINAL_RECORD_CHECK] = false;
            request.requested_countries = [];
        }
        // Required by API to properly go into FUF
        if (!isRequestedCountriesEmpty) {
            request[CHECK_REQUEST.ENHANCED_IDENTITY_VERIFICATION] = true;
            request[CHECK_REQUEST.INTERNATIONAL_CRIMINAL_RECORD_CHECK] = true;
        }
    }

    return request;
};

// Gather all additional option keys from visible checks
const getSelectedChecksAdditionalOptionKeys = (settingsConfig, selectedChecksKeys) => {
    const relevantAdditionalOptionKeys = selectedChecksKeys.reduce((acc, request) => {
        const options = getAdditionalOptionsKeys(request, settingsConfig);
        if (options.length) {
            return acc.concat(options);
        }
        return acc;
    }, []);
    return relevantAdditionalOptionKeys;
};

/**
 * Set Package Data
 */
export const setPackageData = () => async (dispatch, getState) => {
    try {
        const state = getState();
        const settingsConfig = getTeam(state)?.settings_config;
        const selectedChecksKeys = getSelectedCheckKeys(state);
        const currentPackageTestCollection = getCurrentPackageTestCollection(state);
        const configurablePotatoChecks = getConfigurablePotatoChecks(state);
        const checksWithAdditionalOptions = [];

        const relevantAdditionalOptionKeys = selectedChecksKeys.reduce((acc, request) => {
            const options = getAdditionalOptionsKeys(request, settingsConfig, configurablePotatoChecks);
            if (options.length) {
                checksWithAdditionalOptions.push(request);
                return acc.concat(options);
            }
            return acc;
        }, []);

        // Gather values of additional options from test collection that match with add option key
        const additionalOptions = pickBy(currentPackageTestCollection, (value, key) =>
            relevantAdditionalOptionKeys.includes(key)
        );

        await dispatch({ type: SET_PACKAGE_SELECTED });

        // Set additional options based on package settings
        await dispatch(setAdditionalOptions(additionalOptions));

        // Open checks that have additional options so client can view without expanding
        await dispatch(toggleMultiChecksOpen(checksWithAdditionalOptions));

        await dispatch(recalculateTotal());
        return true;
    } catch {
        ErrorAlertCustom({
            description: intl.formatMessage({
                id: '00z35.ScreenApplicant.errorSelectingPackage',
                defaultMessage: 'Error Selecting Package, please contact support',
            }),
        });
    }
};

/**
 * Save as package
 * @param {string} position_name
 */
export const saveAsPackage = (data) => async (dispatch, getState) => {
    const state = getState();
    const test_collection = buildPackageRequest(state);
    const requestData = {
        ...data,
        test_collection,
    };

    const { id } = await dispatch(createPosting(requestData));
    return id;
};

/**
 * Update package
 * @param {string} packageID
 * @param {object} editingPackageValues // modified services and addOptions
 */
export const updatePackage = (packageID, editingPackageValues) => (dispatch, getState) => {
    const state = getState();
    const test_collection = buildPackageRequest(state);
    const requestData = {
        ...editingPackageValues,
        test_collection,
    };

    return dispatch(editPosting(packageID, requestData));
};

// Builds request object with services and additional options
const buildPackageRequest = (state) => {
    const additionalOptions = getAdditionalOptions(state);
    const services = buildServiceRequestObject(state);
    return { ...additionalOptions, ...services };
};

// Cycles through all services and sets request true if selected
const buildServiceRequestObject = (state) => {
    const services = getVisibleChecks(state).reduce(
        (acc, check) => ({ ...acc, [check.requestFlag]: check.isSelected }),
        {}
    );
    return services;
};

/**
 * Follow up flow accessed from email url
 * @param {string} applicantId
 */
export const emailAccessFollowUpFlow = (applicantId) => (dispatch) =>
    dispatch(fetchApplication(applicantId)).then((response) => {
        dispatch(setupServicesPage({ applicant: response }));
        dispatch(initializeFollowUpFlow(response));
    });

/**
 * Follow up flow initialization
 */
export const initializeFollowUpFlow = (application) => async (dispatch) => {
    const reportSummary = application?.report_summary;
    const actionRequired = (serviceKey) =>
        reportSummary?.[serviceKey]?.status === Constants.reportStatus.ACTION_REQUIRED;

    // International Criminal Record Check
    if (actionRequired('international_criminal_record_check_result')) {
        const addresses = application?.information?.addresses?.map((address) =>
            // UK Province adjustments - Max is standardizing so this will eventually be removed
            isUKOrGB(address.country) ? address.other_province_state : address.country
        );
        const countriesExcludedFromInternationalCheck =
            application.application?.team?.countries_excluded_from_international_check || [];
        const internationalAddresses = uniq(
            [...addresses].filter(
                (code) => !(isUSAOrCanada(code) || countriesExcludedFromInternationalCheck.includes(code))
            )
        );
        dispatch(
            setAdditionalOptions({
                requested_countries: internationalAddresses?.sort(),
            })
        );
    }

    // Check page has finished setting up, run calculations based on new fuf info
    dispatch(recalculateTotal());
    return null;
};

/**
 * Reset services
 */
export const resetServices = (dispatch, getState) => {
    const state = getState();
    const checks = getChecks(state)?.map((check) => {
        check.isOpen = false;
        check.isSelected = false;
        return check;
    });

    return dispatch({
        type: MODIFY_CHECK_STATE,
        payload: { checks },
    });
};

/**
 * Helper function to reset services open
 */
export const resetServicesOpen = () => (dispatch, getState) => {
    const state = getState();
    const checks = getChecks(state)?.map((check) => {
        check.isOpen = false;
        return check;
    });

    dispatch({
        type: MODIFY_CHECK_STATE,
        payload: { checks },
    });
};

/**
 * Helper function to toggle service open state to show/hide additional options panel
 * @param {string} key
 */
export const toggleMultiChecksOpen = (keys) => (dispatch, getState) => {
    const state = getState();
    const checks = getChecks(state)?.map((check) => {
        if (keys.includes(check.requestFlag)) check.isOpen = true;
        return check;
    });

    dispatch({
        type: MODIFY_CHECK_STATE,
        payload: { checks },
    });
};

/**
 * Helper function to toggle service open state to show/hide additional options panel
 * @param {string} key
 */
export const toggleCheckIsOpen = (key) => (dispatch, getState) => {
    const state = getState();
    let isOpen = false;
    const checks = getChecks(state).map((check) => {
        if (check.requestFlag === key) {
            isOpen = !check.isOpen;
            return { ...check, isOpen };
        }
        return check;
    });

    dispatch({
        type: MODIFY_CHECK_STATE,
        payload: { checks },
    });

    // scroll to service
    const myElement = document.getElementById(key);
    if (isOpen) {
        const topPos = myElement.offsetTop;
        window.scrollTo({ top: topPos - 200, behavior: 'smooth' });
    }
};

/**
 * Helper function to calculate ETT for Shopping Cart, run during reCalculateTotal
 */
const setEstimatedTurnAroundTime = (dispatch, getState) => {
    const state = getState();
    const intCrimEmpty = getIntCrimSelectedAndEmpty(state);
    let purchasedAndSelectedChecks = getPurchasedAndSelectedChecks(state);

    // Do not display ett for int crim if no countries are selected
    if (intCrimEmpty)
        purchasedAndSelectedChecks = purchasedAndSelectedChecks.filter(
            ({ requestFlag }) => requestFlag !== CHECK_REQUEST.INTERNATIONAL_CRIMINAL_RECORD_CHECK
        );

    try {
        // Grab service turnaround times from MicroServiceContent.js
        const estimatedTurnAroundTimes = purchasedAndSelectedChecks.map(({ requestFlag }) => {
            const localContent = getCheckContent(requestFlag)?.estimatedTurnaroundTime;
            const apiContent = !localContent
                ? purchasedAndSelectedChecks.filter((check) => check.requestFlag === requestFlag)[0]
                : null;
            return apiContent?.estimatedTurnaroundTimeFromAPI || localContent;
        });
        // Find highest seconds value
        const highestValue = max(estimatedTurnAroundTimes.map(({ time }) => time));
        // Find matching title that goes with highest value
        const estimatedTurnAroundTime = estimatedTurnAroundTimes.find(({ time }) => time === highestValue)?.title || '';
        dispatch({
            type: PATCH_APPLICANT,
            payload: { estimatedTurnAroundTime },
        });
    } catch (err) {
        Sentry.captureException(err);
        ErrorAlertCustom({
            description: intl.formatMessage({
                id: '00z35.ett.generationError',
                defaultMessage: 'Error generating Estimated Turnaround Time',
            }),
        });
    }
};

/**
 * Check if additional options conflict before moving on from screen applicant page
 */
export const additionalOptionsConflictCheck = (dispatch, getState) => {
    const state = getState();
    const addOptions = getAdditionalOptions(state);
    const selectedServices = getSelectedChecks(state);
    const services = selectedServices.map((item) => item.requestFlag);
    const userModeIsPM = getUserModeIsPM(state);

    const quickscreenNotAllowed = !getQuickScreenPath(state);
    const equifaxIdentityVerificationDisabled = getLaunchDarklyFlags(state)
        ?.webFeatureDisableEquifaxIdentityVerification;
    if (
        equifaxIdentityVerificationDisabled &&
        services.includes(CHECK_REQUEST.IDENTITY_VERIFICATION) &&
        quickscreenNotAllowed
    ) {
        notification.error({
            message: intl.formatMessage({
                id: '6cb79.ApplicationsView.message1',
                defaultMessage: 'Error',
            }),
            description: intl.formatMessage({
                id: 'c9274.ManualIdentityVerification.error',
                defaultMessage:
                    'Client Manual Identity Verification can only be ordered with Canadian Criminal Record Checks and Optionally QuickScreenable checks.',
            }),
        });
        return false;
    }

    // Employer reference min or years cannot be greater than employer verification max or years
    const employerReferenceCheck = services.filter((service) =>
        [
            CHECK_REQUEST.EMPLOYER_REFERENCES,
            CHECK_REQUEST.EMPLOYER_PHONE_REFERENCES,
            CHECK_REQUEST.EMPLOYMENT_VERIFICATION,
        ].includes(service)
    );

    if (
        !getLaunchDarklyFlags(state)?.webFeatureEnableNewEmployerReferenceTrack &&
        employerReferenceCheck?.length >= 2
    ) {
        if (
            addOptions.employer_references_years_or_individually === INDIVIDUALLY &&
            addOptions.employment_verification_years_or_individually === INDIVIDUALLY &&
            (addOptions.employer_references_min !== addOptions.employment_verification_min ||
                addOptions.employer_references_max !== addOptions.employment_verification_max)
        ) {
            ErrorAlertCustom({
                description:
                    'Running both Employment Verification and Employer Reference Check requires their min and max values to be the same.',
            });
            dispatch(toggleCheckIsOpen(employerReferenceCheck));
            return false;
        }
        if (
            addOptions.employer_references_years_or_individually === 'YEARS' &&
            addOptions.employment_verification_years_or_individually === 'YEARS' &&
            addOptions.employer_references_years !== addOptions.employment_verification_years
        ) {
            ErrorAlertCustom({
                description:
                    'Running both Employment Verification and Employer Reference Check requires their previous years values to be the same.',
            });
            dispatch(toggleCheckIsOpen(employerReferenceCheck));
            return false;
        }

        if (
            addOptions.employer_references_years_or_individually !==
            addOptions.employment_verification_years_or_individually
        ) {
            ErrorAlertCustom({
                description:
                    'Running both Employment Verification and Employer Reference Check requires their additional option types to be the same (Individually, or, Date Range).',
            });
            dispatch(toggleCheckIsOpen(employerReferenceCheck));
            return false;
        }
    }

    // PM - Tenancy references cannot be greater than
    if (userModeIsPM) {
        // Int Crim Check only
        const tenancyAndInternational = services.filter((service) =>
            [
                CHECK_REQUEST.ADDRESS_REFERENCES,
                CHECK_REQUEST.ADDRESS_PHONE_REFERENCES,
                CHECK_REQUEST.INTERNATIONAL_CRIMINAL_RECORD_CHECK,
            ].includes(service)
        );
        if (tenancyAndInternational?.length >= 2) {
            if (
                addOptions.address_references_years_or_individually === INDIVIDUALLY &&
                addOptions.address_references_max < addOptions.requested_countries?.length
            ) {
                ErrorAlertCustom({
                    description:
                        'Your Tenancy Reference Maximum must be equal to or greater than the amount of International Criminal Record Check countries',
                });
                dispatch(toggleCheckIsOpen(tenancyAndInternational));
                return false;
            }
        }

        // Int Crim Check and US Crim
        const tenancyAndInternationalandUs = services.filter((service) =>
            [
                CHECK_REQUEST.ADDRESS_REFERENCES,
                CHECK_REQUEST.ADDRESS_PHONE_REFERENCES,
                CHECK_REQUEST.INTERNATIONAL_CRIMINAL_RECORD_CHECK,
                CHECK_REQUEST.US_CRIMINAL_RECORD_CHECK_TIER_1,
                CHECK_REQUEST.US_CRIMINAL_RECORD_CHECK_TIER_2,
                CHECK_REQUEST.US_CRIMINAL_RECORD_CHECK_TIER_3,
            ].includes(service)
        );
        if (tenancyAndInternationalandUs?.length >= 3) {
            if (
                addOptions.address_references_years_or_individually === INDIVIDUALLY &&
                addOptions.address_references_max < addOptions.requested_countries?.length + 1
                // US Crim Requires a US address
            ) {
                ErrorAlertCustom({
                    description:
                        'Your Tenancy Reference Maximum must be equal to or greater than the amount of International Criminal Record Check countries, including the required US address from US Criminal Checks',
                });
                dispatch(toggleCheckIsOpen(tenancyAndInternationalandUs));
                return false;
            }
        }
    }

    // No issues
    return true;
};

/**
 * On Add
    1. On added service, are the dependencies satisfied
    1. a. If YES, goto 2
    1. b. If NO, add in order, goto 2

 * Example:
 * 1. Add Int Criminal Record Check - Must Add enhanced_identity_verification as well
 */
const onCheckAdd = ({ selectedChecks, allChecks, toggledCheck, visibleChecks } = {}) => {
    const checkFlagsToAdd = [toggledCheck];

    // find dependent check flags
    const selectionDependentOnRequestFlags = allChecks.find((check) => check.requestFlag === toggledCheck)
        ?.selectionDependentOnRequestFlags;

    // first, add dependencies and array dependencies (takes first value if neither are selected)
    if (selectionDependentOnRequestFlags?.length) {
        selectionDependentOnRequestFlags.forEach((selectionDependentOnRequestFlag) => {
            if (Array.isArray(selectionDependentOnRequestFlag)) {
                // grab all permitted checks that match selectionDependentOnRequestFlags
                const selectionDependentOnChecks = selectionDependentOnRequestFlag
                    .map((requestFlag) => {
                        const selectionDependentOnCheck = allChecks.filter(
                            (check) => check.requestFlag === requestFlag
                        );
                        if (selectionDependentOnCheck.length) {
                            return selectionDependentOnCheck[0];
                        }
                        return undefined;
                    })
                    .filter((check) => !!check);

                // are any dependencies selected? (even if they are no longer permitted they were previously purchased)
                const dependencyMet = selectionDependentOnChecks.some((check) => check.isSelected || check.isPurchased);
                // if dependency met return, otherwise let's add the selectionDependentOnRequestFlag
                if (dependencyMet) return;
                // we only want to add permitted dependent checks
                const permittedSelectionDependentOnRequestFlag = selectionDependentOnChecks.filter(
                    ({ isPermitted }) => isPermitted
                );
                // if no results return, isMissingDependencies will be true and onClick handler for check will warn
                if (isEmpty(permittedSelectionDependentOnRequestFlag)) return;
                // else add first dependencies request flag
                checkFlagsToAdd.push(permittedSelectionDependentOnRequestFlag[0]?.requestFlag);
            }

            if (typeof selectionDependentOnRequestFlag === 'string') {
                // first grab the matching dependency
                const { isSelected, isPermitted, requestFlag } = findCheckOfInterest(
                    allChecks,
                    selectionDependentOnRequestFlag
                );
                // dependencies are unavailable
                if (!isPermitted) return;
                // otherwise select dependency
                if (!isSelected) checkFlagsToAdd.push(requestFlag);
            }
        });
    }

    // if no dependencies were met during recursion, return
    if (isEmpty(checkFlagsToAdd)) return [[], []];

    // second, deal with mutual exclusions (onAdd Removes)
    const checksToRemove = onAddRemovals(checkFlagsToAdd, allChecks, selectedChecks, visibleChecks);

    return [checkFlagsToAdd, checksToRemove];
};

/**
 * On Add Removals
    2. Are there mutually exclusive services included in the recently added check?
    2. a. If NO, STOP
    2. b. If yes, remove the mutually exclusive service, goto ON Remove 1
    2. c. If yes & is a dependency service,

 * Example:
 * 1. Add Int Criminal Record Check - Must Add enhanced_identity_verification as well
 * 2. If Mutually Exclusive checks exists; Adding enhanced_identity_verification should
 *      Remove Identity Verification
 *      and any checks that have identity verification as a dependency (but not enhanced_identity_verification)
 */
const onAddRemovals = (checkFlagsToAdd, allChecks, selectedChecks, visibleChecks) => {
    const checksToRemove = [];
    checkFlagsToAdd.forEach((checkFlagToAdd) => {
        const checkOfInterest = findCheckOfInterest(allChecks, checkFlagToAdd);
        // Cycle through selection on application create prohibited by check flags
        checkOfInterest.selectionOnCreateProhibitedByRequestFlags?.forEach((checkFlagToRemove) => {
            const mutuallyExcludedCheckOfInterest = findCheckOfInterest(selectedChecks, checkFlagToRemove);
            // is the mutually exclusive check selected? Never remove if purchased
            if (mutuallyExcludedCheckOfInterest?.isSelected && !mutuallyExcludedCheckOfInterest?.isPurchased) {
                // is the mutually exclusive check required by a selected check?
                const requiredCheck = selectedChecks.some((check) =>
                    check.selectionDependentOnRequestFlags?.includes(checkFlagToRemove)
                );
                if (requiredCheck) return null;
                // flag()[0] due to checksToRmove returning an array for destructuring
                // We pass in all checks to avoid the scenario where a check is removed and selected checks falls out of sync
                const checkFlagsToRemove = onCheckRemove({
                    allChecks,
                    selectedChecks: allChecks,
                    toggledCheck: checkFlagToRemove,
                    visibleChecks,
                })?.flat()?.[0];
                checksToRemove.push(checkFlagsToRemove);
            }
        });
        return null;
    });
    return checksToRemove;
};

const findCheckOfInterest = (checks, checkFlagOfInterest) =>
    checks.find((check) => check.requestFlag === checkFlagOfInterest);

/**
 * On Remove
    * Must check that all dependency arrays are met

    1. For each checks remaining, are required ones included?
    1. a. If YES, STOP
    1. b. If NO, deselect service missing deps, rerun
 *
 * Example:
 * Remove Int Criminal Record Check - Must remove enhanced_identity_verification as well
 * Scan through list and remove checks that depend on it
 */

const onCheckRemove = ({ selectedChecks, allChecks, visibleChecks, toggledCheck } = {}) => {
    const checksFlagsToRemove = [toggledCheck];
    // toggledCheck for removal = enhanced_identity_verification

    // Remove orphaned dependent checks
    const toggledActualCheck = allChecks.find((check) => check.requestFlag === toggledCheck);
    const otherSelectedChecks = selectedChecks.filter((check) => check.requestFlag !== toggledCheck);
    flatten(toggledActualCheck?.selectionDependentOnRequestFlags || []).forEach((dependentCheck) => {
        const otherSelectedCheckDependsOnIt = otherSelectedChecks.some((check) =>
            flatten(check.selectionDependentOnRequestFlags).includes(dependentCheck)
        );
        const isItselfVisibleCheck = visibleChecks.map((check) => check.requestFlag).includes(dependentCheck);

        if (!otherSelectedCheckDependsOnIt && !isItselfVisibleCheck) {
            checksFlagsToRemove.push(dependentCheck);
        }
    });

    // cycle through all checks
    selectedChecks.forEach((selectedCheck) => {
        // if no dependent checks return;
        if (!selectedCheck.selectionDependentOnRequestFlags?.length) return;
        // We have dependencies, do any of these match enhanced_identity_verification?
        iterateThroughCheckDependencies(selectedCheck.selectionDependentOnRequestFlags);
        /**
         * Cycle through dependent checks, two formats
         * ['enhanced_identity_verification', 'identity_verification', 'equifax']
         * [['identity_verification', 'enhanced_identity_verification'], 'equifax']
         */

        function iterateThroughCheckDependencies(selectedChecksDependentFlagsSet) {
            selectedChecksDependentFlagsSet.forEach((selectedChecksDependentFlags) => {
                // if array, if one of the _other_ checks isSelected, don't remove
                if (Array.isArray(selectedChecksDependentFlags)) {
                    // if none of the dependencies include what's being removed, return;
                    // we are disabling enhanced_identity_verification, do any of this selectedChecks dependencies match? If not, return
                    if (!selectedChecksDependentFlags.flat().includes(toggledCheck)) return;
                    // filter out current checkFlag
                    // selectedCheck = int crim, deps = ['identity_verification', 'enhanced_identity_verification']
                    // Let's grab everything besides what has been toggled off (identity_verification) in this case
                    const dependentCheckWithoutRemovedCheck = selectedChecksDependentFlags.filter(
                        (dependencyFlag) => dependencyFlag !== toggledCheck
                    );

                    // dependentCheckWithoutRemovedCheck = identity_verification
                    // find each of the checks that are not the toggledCheck for removal, and check if they are selected
                    const dependencyMet = selectedChecks
                        .filter((check) => dependentCheckWithoutRemovedCheck.includes(check.requestFlag))
                        .some((check) => check.isSelected);

                    // Has dependency been met? If not, send this array ['identity_verification', 'enhanced_identity_verification'] back through recursion
                    // as we need the individual strings and to hit the base case
                    if (dependencyMet) return;
                    iterateThroughCheckDependencies(selectedChecksDependentFlags);
                }
                // At this stage we are seeing if the dependency for the selected check: international_criminal_record_check
                // is the current check being toggled off, if so, we add international_criminal_record_check to be removed as it's
                // dependency was removed
                if (typeof selectedChecksDependentFlags === 'string' && selectedChecksDependentFlags === toggledCheck)
                    checksFlagsToRemove.push(selectedCheck.requestFlag);
            });
        }
    });

    // Empty array so toggleCheck destructure matches either onCheckAdd || onCheckRemove
    return [[], checksFlagsToRemove];
};
