import React from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { Select, Row, Col, Upload, message } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components/macro';
import Button from 'certn-ui/Button';

import HelpModal from './HelpModal';

const DottedWrapper = styled.div`
    border: 2px dashed #e5e5e5;
    margin: 10px 0;
    padding: 7px 0;
`;

const getOwnFileList = (category, fileList, categoryFileList, index) => {
    let ownFileList = categoryFileList;

    if (category === 'commencement') {
        const rtwFileList = fileList.filter((file) => file.subtype === 'right_to_work');
        ownFileList = [...ownFileList, ...rtwFileList];
    }

    if (index !== undefined) {
        ownFileList = ownFileList.slice(index, index + 1);
    }

    return ownFileList;
};

const UploadSectionInner = ({
    index,
    options,
    category,
    s3UploadLink,
    s3UploadFields,
    s3GetUploadLink,
    fileList,
    onUpload,
    handleProgress,
    handleDeleteDocument,
}) => {
    const intl = useIntl();

    const uploadedDocumentTypes = fileList.map((file) => file.type);
    const onlyOne = options.length === 1;
    const categoryFileList = fileList.filter((file) => file.subtype === category);
    const hideMe = index > categoryFileList.length;
    const ownFileList = getOwnFileList(category, fileList, categoryFileList, index);
    const hasFile = ownFileList.length > 0;
    const defaultValue = onlyOne ? options[0].key : ownFileList[0]?.type;
    const [selectedDocumentType, setDocumentType] = React.useState(defaultValue);
    const uploadDisabled = !selectedDocumentType || hideMe;
    const showRemoveIcon = ownFileList[0]?.subtype !== 'right_to_work';

    const beforeUpload = (file) => {
        const invalidFileType = !['image/jpeg', 'image/png', 'application/pdf'].includes(file.type);
        if (invalidFileType) {
            message.error('You can only upload JPG/PNG/PDF file');
        }
        const tooBig = file.size > 10 * 1024 * 1024;
        if (tooBig) {
            message.error('File must smaller than 10MB');
        }
        if (invalidFileType || tooBig) return false;

        if (uploadedDocumentTypes.includes(selectedDocumentType)) {
            message.error(
                intl.formatMessage({
                    id: 'welcome.AcicDocuments.documentAlreadyUploaded',
                    defaultMessage: 'This type of document has already been uploaded.',
                })
            );

            return false;
        }

        return s3GetUploadLink(file, selectedDocumentType, category);
    };

    const onChange = ({ file }) => file.status === 'uploading' && onUpload();

    return (
        <>
            {options && (
                <Select
                    style={{ width: '100%', ...(onlyOne ? { display: 'none' } : {}) }}
                    onChange={setDocumentType}
                    disabled={hasFile || hideMe}
                    value={defaultValue || selectedDocumentType}
                    placeholder={
                        <FormattedMessage
                            id="welcome.AcicDocuments.selectDocumentType"
                            defaultMessage="Select document type"
                        />
                    }
                >
                    {options.map(({ key, value }) => (
                        <Select.Option
                            key={key}
                            value={key}
                            disabled={uploadedDocumentTypes.includes(key)}
                            title={
                                uploadedDocumentTypes.includes(key)
                                    ? intl.formatMessage({
                                          id: 'welcome.AcicDocuments.documentAlreadyUploaded',
                                          defaultMessage: 'This type of document has already been uploaded.',
                                      })
                                    : undefined
                            }
                        >
                            {value}
                        </Select.Option>
                    ))}
                </Select>
            )}

            <DottedWrapper>
                <Upload
                    accept=".jpg,.jpeg,.png,.pdf"
                    action={s3UploadLink}
                    data={s3UploadFields}
                    beforeUpload={beforeUpload}
                    fileList={ownFileList}
                    onChange={onChange}
                    onProgress={(event, eventFile) => handleProgress({ event, eventFile })}
                    showUploadList={{ showRemoveIcon }}
                    onRemove={(file) => handleDeleteDocument(file)}
                    disabled={uploadDisabled}
                >
                    {(onlyOne || !hasFile) && (
                        <Button
                            certncolor={uploadDisabled ? undefined : 'primary'}
                            type="primary"
                            disabled={uploadDisabled}
                        >
                            <UploadOutlined />{' '}
                            <FormattedMessage id="welcome.AcicDocuments.upload" defaultMessage="Upload" />
                        </Button>
                    )}
                </Upload>
            </DottedWrapper>
        </>
    );
};

const UploadSectionTitle = styled.div`
    margin-top: 12px;
    color: #819697;
    text-align: left;
`;

const WhatsThis = styled.div`
    float: right;
    text-decoration: underline;
    font-size: 14px;
`;

const useToggle = (initialState) => {
    const [state, setState] = React.useState(initialState);
    return [state, () => setState(!state)];
};

const SECTION_TITLES = {
    commencement: [<FormattedMessage id="welcome.AcicDocuments.commencement" defaultMessage="Commencement" />],
    primary: [<FormattedMessage id="welcome.AcicDocuments.primary" defaultMessage="Primary" />],
    secondary: [
        <FormattedMessage id="welcome.AcicDocuments.secondary1of2" defaultMessage="Secondary (1 of 2)" />,
        <FormattedMessage id="welcome.AcicDocuments.secondary2of2" defaultMessage="Secondary (2 of 2)" />,
    ],
    supporting: [
        <FormattedMessage
            id="welcome.AcicDocuments.supportingDocuments"
            defaultMessage="Supporting Documents (Optional)"
        />,
    ],
};

const UploadSection = ({ category, index, documentTypes, ...props }) => {
    const [isHelpOpen, toggleHelpOpen] = useToggle(false);

    return (
        <Row>
            <HelpModal category={category} isOpen={isHelpOpen} onClose={toggleHelpOpen} />

            <Col xs={24} lg={{ span: 12, offset: 6 }}>
                <UploadSectionTitle>
                    {SECTION_TITLES[category][index || 0]}
                    <WhatsThis>
                        <a onClick={toggleHelpOpen}>What's this?</a>
                    </WhatsThis>
                </UploadSectionTitle>
                {documentTypes ? (
                    <UploadSectionInner
                        options={documentTypes[category]}
                        index={index}
                        category={category}
                        {...props}
                    />
                ) : (
                    <Select style={{ width: '100%' }} disabled />
                )}
            </Col>
        </Row>
    );
};

export default UploadSection;
