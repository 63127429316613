// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Input, InputNumber, Button, Tooltip, Switch, Select } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import { injectIntl, FormattedMessage } from 'react-intl';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';

// Components
import { ButtonLine } from 'certn-ui/Button';

// Modules
import { Regex } from 'modules';

// Actions & Selectors
import {
    getTeamMembers,
    getTeamMemberDict,
    getDegreeDict,
    getSpecializations,
    getDegrees,
    getSpecializationOptions,
} from 'views/manager/views/hr/views/postings/PostingsSelectors';
import { getTeamCountry } from 'base/BaseSelectors';
import DateInput from 'certn-form/DateInput';
import ProvStateAutoComplete from 'components/ProvStateAutoComplete';
import { provinceToCode } from 'modules/Format';
import { fetchTeamMembers } from 'views/manager/views/hr/views/postings/PostingsActions';

const mapStateToProps = (state) => ({
    teamMembers: getTeamMembers(state),
    teamMemberDict: getTeamMemberDict(state),
    degreeDict: getDegreeDict(state),
    specializations: getSpecializations(state),
    degrees: getDegrees(state),
    specializationOptions: getSpecializationOptions(state),
    teamCountry: getTeamCountry(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ fetchTeamMembers }, dispatch);

const propTypes = {
    // Redux Store
    teamMembers: PropTypes.array,
    teamMemberDict: PropTypes.object,
    degreeDict: PropTypes.object,
    specializations: PropTypes.array,
    degrees: PropTypes.array,
    specializationOptions: PropTypes.array,
    teamCountry: PropTypes.string,

    // Parent Props
    onSubmit: PropTypes.func.isRequired,
    posting: PropTypes.object,
    postingsNew: PropTypes.bool,
};
const defaultProps = {
    posting: {},
    teamMembers: [],
    teamMemberDict: {},
    degreeDict: {},
    postingsNew: false,
    specializations: [],
    degrees: [],
    teamCountry: '',
    specializationOptions: [],
};

class PostingsForm extends React.Component {
    constructor(props) {
        super(props);
        const { location } = props;
        this.props.fetchTeamMembers();
        let parsed;
        if (location?.search) {
            parsed = queryString.parse(location.search);
        }
        this.state = {
            rentRange: !!props.posting.rent_range || false,
            openPosting: props.posting.open_posting || false,
            startDateExists: !!props.posting.start_date || false,
            skills: [],
            showNewSkillField: false,
            newSkill: '',
            newSkills: [],
            propertyId: parsed?.propertyId || undefined,
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!!nextProps.posting.start_date && !this.state.startDateExists) {
            this.setState({ startDateExists: true });
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const newValues = { ...values };

                // newValues.test_collection = selected;
                // newValues.team_id = selected.team_id; // Team Select?

                // Minimum Employer References which will be in modal soon
                // newValues.test_collection.employer_references_min = values.employer_references_min;

                if (!this.props.postingsNew) {
                    newValues.owner_id = this.props.posting.owner.id;
                }

                try {
                    newValues.notification_list_ids = newValues.notification_list_ids
                        .map((email) => this.props.teamMemberDict[email])
                        .filter((teamMemberEmail) => teamMemberEmail);
                } catch (tryErr) {
                    newValues.notification_list_ids = [];
                }

                try {
                    newValues.required_education_id = this.props.degreeDict[newValues.required_education_id];
                } catch (tryErr) {
                    newValues.required_education_id = [];
                }
                if (newValues.city && newValues.province_state) {
                    newValues.address = {
                        city: newValues.city,
                        province_state:
                            newValues.province_state.length === 2
                                ? newValues.province_state
                                : provinceToCode(newValues.province_state), // need to send 2 digit code to api. Sends OT (other) if entered prov doesn't match existing code.
                    };
                }

                delete newValues.specify_start_date;
                if (!newValues.start_date) {
                    newValues.start_date = null;
                }

                delete newValues.city;
                delete newValues.province_state;

                this.props.history.push(`/hr/package/${this.props.posting.id || 'new'}`, { newValues });

                // this.setState({ microServicesVisible: false }, this.props.onSubmit(newValues));
            }
        });
    };

    validateFields = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err) => {
            if (!err) {
                this.setState({ microServicesVisible: true });
            }
        });
    };

    handleValueSwap = (value) => {
        const currentValue = this.state[value];
        this.setState({ [value]: !currentValue });
    };

    render() {
        const { posting, specializationOptions, intl } = this.props;
        const { getFieldDecorator } = this.props.form;

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 14 },
            },
        };

        let preSelected = posting.test_collection;

        if (preSelected) {
            const { id, created, modified, name, owner, ...restCollection } = preSelected;
            preSelected = restCollection;

            // We need to send in team_id for the screening team drop down.
            preSelected.team_id = posting.team_id;
        }

        return (
            <Form onSubmit={this.handleSubmit}>
                <Form.Item
                    {...formItemLayout}
                    label={<FormattedMessage id="5b473.PostingForm.packageName" defaultMessage="Package Name" />}
                >
                    {getFieldDecorator('position_name', {
                        initialValue: this.props.posting.position_name || null,
                        rules: [
                            {
                                type: 'string',
                                message: (
                                    <FormattedMessage
                                        id="5b473.PostingForm.message1"
                                        defaultMessage="Input must be regular text"
                                    />
                                ),
                                required: true,
                            },
                        ],
                    })(
                        <Input
                            type="text"
                            placeholder={intl.formatMessage({
                                id: '5b473.PostingForm.packageName',
                                defaultMessage: 'Package Name',
                            })}
                        />
                    )}
                </Form.Item>
                <Form.Item {...formItemLayout} label={<FormattedMessage id="common.city" defaultMessage="City" />}>
                    {getFieldDecorator('city', {
                        initialValue: this.props.posting.address?.city,
                        rules: [
                            {
                                type: 'string',
                                message: (
                                    <FormattedMessage
                                        id="5b473.PostingForm.message1"
                                        defaultMessage="Input must be regular text"
                                    />
                                ),
                            },
                        ],
                    })(
                        <Input
                            type="text"
                            placeholder={intl.formatMessage({ id: 'common.city', defaultMessage: 'City' })}
                        />
                    )}
                </Form.Item>
                <Form.Item
                    {...formItemLayout}
                    label={<FormattedMessage id="address.provinceState" defaultMessage="Province/State" />}
                >
                    {getFieldDecorator('province_state', {
                        initialValue: this.props.posting.address?.province_state,
                        rules: [
                            {
                                message: (
                                    <FormattedMessage
                                        id="5b473.PostingForm.provinceState2"
                                        defaultMessage="Select a province/state"
                                    />
                                ),
                            },
                        ],
                    })(<ProvStateAutoComplete size="medium" />)}
                </Form.Item>

                <Form.Item
                    {...formItemLayout}
                    label={<FormattedMessage id="5b473.PostingForm.workType1" defaultMessage="Work Type" />}
                >
                    {getFieldDecorator('work_type', {
                        initialValue: this.props.posting.work_type,
                        rules: [
                            {
                                message: (
                                    <FormattedMessage
                                        id="5b473.PostingForm.workType2"
                                        defaultMessage="Select a work type"
                                    />
                                ),
                            },
                        ],
                    })(
                        <Select
                            style={{ width: '33%' }}
                            placeholder={
                                <FormattedMessage id="5b473.PostingForm.workType1" defaultMessage="Work Type" />
                            }
                        >
                            <Select.Option value="FULL_TIME">
                                <FormattedMessage id="5b473.PostingForm.fullTime" defaultMessage="Full Time" />
                            </Select.Option>
                            <Select.Option value="PART_TIME">
                                <FormattedMessage id="5b473.PostingForm.partTime" defaultMessage="Part Time" />
                            </Select.Option>
                        </Select>
                    )}
                </Form.Item>
                <Form.Item
                    {...formItemLayout}
                    label={
                        <FormattedMessage
                            id="5b473.PostingForm.relevantExp"
                            defaultMessage="Required Years Relevant Experience"
                        />
                    }
                >
                    {getFieldDecorator('required_years_relevant_experience', {
                        initialValue: this.props.posting.required_years_relevant_experience,
                    })(
                        <Select
                            showSearch
                            style={{ width: '33%' }}
                            placeholder={intl.formatMessage({
                                id: '5b473.PostingForm.eg',
                                defaultMessage: '(e.g., 2)',
                            })}
                        >
                            {Array.from(new Array(15), (val, index) => index + 1).map((value) => (
                                <Select.Option key={`years-${value}`} value={value}>
                                    {value}
                                </Select.Option>
                            ))}
                        </Select>
                    )}
                </Form.Item>
                <Form.Item
                    {...formItemLayout}
                    label={
                        <span>
                            <FormattedMessage
                                id="5b473.PostingForm.requiredEducation"
                                defaultMessage="Required Education"
                            />
                            &nbsp;
                            <Tooltip
                                title={
                                    <FormattedMessage
                                        id="5b473.PostingForm.suitability"
                                        defaultMessage="This will be used to assess applicant suitability."
                                    />
                                }
                            >
                                <QuestionCircleOutlined />
                            </Tooltip>
                        </span>
                    }
                >
                    {getFieldDecorator('required_education_id', {
                        initialValue: this.props.posting.required_education
                            ? this.props.posting.required_education.degree
                            : undefined,
                        rules: [
                            {
                                type: 'string',
                                message: (
                                    <FormattedMessage
                                        id="5b473.PostingForm.invalidEdu"
                                        defaultMessage="The input is not valid education type!"
                                    />
                                ),
                            },
                        ],
                    })(
                        <Select
                            showSearch
                            type="text"
                            placeholder={
                                <FormattedMessage
                                    id="5b473.PostingForm.allThatApply"
                                    defaultMessage="Select all that apply"
                                />
                            }
                        >
                            {this.props.degrees.map((degree) => (
                                <Select.Option key={degree.id} value={degree.degree}>
                                    {degree.degree}
                                </Select.Option>
                            ))}
                        </Select>
                    )}
                </Form.Item>
                <Form.Item
                    {...formItemLayout}
                    label={
                        <span>
                            <FormattedMessage
                                id="5b473.PostingForm.requiredSpecialization"
                                defaultMessage="Required Specializations"
                            />
                            &nbsp;
                            <Tooltip
                                title={
                                    <FormattedMessage
                                        id="5b473.PostingForm.specialization"
                                        defaultMessage="These specializations will be used to assess applicant suitability."
                                    />
                                }
                            >
                                <QuestionCircleOutlined />
                            </Tooltip>
                        </span>
                    }
                >
                    {getFieldDecorator('required_specializations_ids', {
                        initialValue: this.props.posting.required_specializations
                            ? this.props.posting.required_specializations.map((specialization) => specialization.id)
                            : undefined,
                        rules: [
                            {
                                type: 'array',
                                message: (
                                    <FormattedMessage
                                        id="5b473.PostingForm.invalidSpecialization"
                                        defaultMessage="The input is not valid specializations"
                                    />
                                ),
                            },
                        ],
                    })(
                        <Select
                            showArrow
                            allowClear
                            mode="multiple"
                            placeholder={
                                <FormattedMessage
                                    id="5b473.PostingForm.allThatApply"
                                    defaultMessage="Select all that apply"
                                />
                            }
                            optionFilterProp="label"
                        >
                            {specializationOptions.map((item) => (
                                <Select.Option label={item.label} value={item.key} key={item.key}>
                                    {item.label}
                                </Select.Option>
                            ))}
                        </Select>
                    )}
                </Form.Item>
                <Form.Item
                    {...formItemLayout}
                    label={
                        <span>
                            <FormattedMessage
                                id="5b473.PostingForm.notificationContacts"
                                defaultMessage="Notification contacts"
                            />
                            &nbsp;
                            <Tooltip
                                title={
                                    <FormattedMessage
                                        id="5b473.PostingForm.contacts"
                                        defaultMessage="These contacts will be sent email updates about the listing."
                                    />
                                }
                            >
                                <QuestionCircleOutlined />
                            </Tooltip>
                        </span>
                    }
                >
                    {getFieldDecorator('notification_list_ids', {
                        initialValue: this.props.posting.notification_list
                            ? this.props.posting.notification_list.map((entry) => entry.email)
                            : undefined,
                        rules: [
                            {
                                type: 'array',
                                message: (
                                    <FormattedMessage
                                        id="error.validation.email.invalid"
                                        defaultMessage="The input is not a valid E-mail!"
                                    />
                                ),
                            },
                        ],
                    })(
                        <Select
                            mode="multiple"
                            type="email"
                            placeholder={intl.formatMessage({
                                id: '5b473.PostingForm.allThatApply',
                                defaultMessage: 'Select all that apply',
                            })}
                        >
                            {this.props.teamMembers.map((member) => (
                                <Select.Option key={member.id} value={member.email}>
                                    {member.email}
                                </Select.Option>
                            ))}
                        </Select>
                    )}
                </Form.Item>
                <Form.Item
                    {...formItemLayout}
                    label={
                        <span>
                            <FormattedMessage
                                id="5b473.PostingForm.customLink"
                                defaultMessage="Specify a custom link?"
                            />
                            &nbsp;
                            <Tooltip
                                title={
                                    <FormattedMessage
                                        id="5b473.PostingForm.customLinkTitle"
                                        defaultMessage="This customizes the link that applicants will follow (ie: certn.co/custom-link). It will be set automatically if you do not specify something."
                                    />
                                }
                            >
                                <QuestionCircleOutlined />
                            </Tooltip>
                        </span>
                    }
                >
                    {getFieldDecorator('url_code', {
                        initialValue: this.props.posting.url_code || null,
                        rules: [
                            {
                                pattern: Regex.urlCode,
                                message: intl.formatMessage({
                                    id: 'error.validation.urlCode',
                                    defaultMessage: 'Please use only numbers, letters, dashes, and underscores',
                                }),
                            },
                            {
                                min: 6,
                                message: intl.formatMessage({
                                    id: 'error.validation.6char',
                                    defaultMessage: 'URL code must be at least 6 characters long',
                                }),
                            },
                        ],
                    })(<Input type="text" />)}
                </Form.Item>
                <Form.Item
                    {...formItemLayout}
                    label={
                        <span>
                            <FormattedMessage
                                id="5b473.PostingForm.specifyStartDate"
                                defaultMessage="Specify a start date?"
                            />
                            &nbsp;
                            <Tooltip
                                title={
                                    <FormattedMessage
                                        id="5b473.PostingForm.title2"
                                        defaultMessage="Leave this option disabled if the successful applicant could start immediately."
                                    />
                                }
                            >
                                <QuestionCircleOutlined />
                            </Tooltip>
                        </span>
                    }
                >
                    {getFieldDecorator('specify_start_date', {
                        initialValue: this.state.startDateExists,
                        valuePropName: 'checked',
                    })(<Switch onClick={() => this.handleValueSwap('startDateExists')} />)}
                </Form.Item>
                {this.state.startDateExists && (
                    <Form.Item
                        {...formItemLayout}
                        label={
                            <span>
                                <FormattedMessage id="form.label.startDate" defaultMessage="Start Date" />
                                &nbsp;
                            </span>
                        }
                    >
                        {getFieldDecorator('start_date', {
                            initialValue: this.props.posting.start_date,
                            rules: [
                                {
                                    required: true,
                                    message: intl.formatMessage({
                                        id: 'error.validation.notBlank',
                                        defaultMessage: 'Please do not leave blank',
                                    }),
                                },
                            ],
                        })(
                            <DateInput
                                placeholder={intl.formatMessage({
                                    id: 'form.label.startDate',
                                    defaultMessage: 'Start Date',
                                })}
                            />
                        )}
                    </Form.Item>
                )}
                {this.props.teamCountry === 'US' && (
                    <>
                        <Form.Item
                            {...formItemLayout}
                            label={
                                <span>
                                    <FormattedMessage
                                        id="5b473.PostingForm.salaryForPosition"
                                        defaultMessage="Salary for position"
                                    />
                                    &nbsp;
                                    <Tooltip
                                        title={
                                            <FormattedMessage
                                                id="5b473.PostingForm.salaryTitle"
                                                defaultMessage="What is the yearly (approximate if hourly/bi-weekly/etc.) salary for this position?"
                                            />
                                        }
                                    >
                                        <QuestionCircleOutlined />
                                    </Tooltip>
                                </span>
                            }
                        >
                            {getFieldDecorator('salary', {
                                initialValue: this.props.posting.salary || null,
                                rules: [],
                            })(
                                <InputNumber
                                    min={0}
                                    formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                    style={{ width: '150px' }}
                                />
                            )}
                        </Form.Item>
                        <Form.Item
                            {...formItemLayout}
                            label={
                                <span>
                                    <FormattedMessage
                                        id="5b473.PostingForm.highSalaryPosition"
                                        defaultMessage="High-salary position?"
                                    />
                                    &nbsp;
                                    <Tooltip
                                        title={
                                            <FormattedMessage
                                                id="5b473.PostingForm.highSalaryTitle"
                                                defaultMessage="Select this if you can attest that the salary for this position will be large enough to be exempt from FCRA regulation in your jurisdiction."
                                            />
                                        }
                                    >
                                        <QuestionCircleOutlined />
                                    </Tooltip>
                                </span>
                            }
                        >
                            {getFieldDecorator('sufficient_salary', {
                                initialValue: this.props.posting.sufficient_salary,
                                valuePropName: 'checked',
                            })(<Switch />)}
                        </Form.Item>
                        <Form.Item
                            {...formItemLayout}
                            label={
                                <span>
                                    <FormattedMessage
                                        id="5b473.PostingForm.highRisk"
                                        defaultMessage="High-risk to safety?"
                                    />
                                    &nbsp;
                                    <Tooltip
                                        title={
                                            <FormattedMessage
                                                id="5b473.PostingForm.highRiskTitle"
                                                defaultMessage="Would the applicant be exposed to situations where they may be considered an unreasonable risk to the property, safety, or welfare of other employees or the public?"
                                            />
                                        }
                                    >
                                        <QuestionCircleOutlined />
                                    </Tooltip>
                                </span>
                            }
                        >
                            {getFieldDecorator('job_safety_undue_risk', {
                                initialValue: this.props.posting.job_safety_undue_risk,
                                valuePropName: 'checked',
                            })(<Switch />)}
                        </Form.Item>
                        <Form.Item
                            {...formItemLayout}
                            label={
                                <span>
                                    <FormattedMessage
                                        id="5b473.PostingForm.highRiskVulnerable"
                                        defaultMessage="High-risk to vulnerable sector?"
                                    />
                                    &nbsp;
                                    <Tooltip
                                        title={
                                            <FormattedMessage
                                                id="5b473.PostingForm.highRiskTitleVulnerable"
                                                defaultMessage="Would the applicant be working with people considered ‘at risk’, typically defined as dealing with children or the elderly?"
                                            />
                                        }
                                    >
                                        <QuestionCircleOutlined />
                                    </Tooltip>
                                </span>
                            }
                        >
                            {getFieldDecorator('high_risk_vulnerable_sector', {
                                initialValue: this.props.posting.high_risk_vulnerable_sector,
                                valuePropName: 'checked',
                            })(<Switch />)}
                        </Form.Item>
                    </>
                )}
                <Form.Item>
                    <ButtonLine>
                        <Button type="primary" htmlType="submit">
                            <FormattedMessage id="5b473.PostingForm.next" defaultMessage="Next" />
                        </Button>
                    </ButtonLine>
                </Form.Item>
            </Form>
        );
    }
}

PostingsForm.propTypes = propTypes;
PostingsForm.defaultProps = defaultProps;

export default Form.create()(withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(PostingsForm))));
