// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';

// UI Components
import { ButtonLine } from 'certn-ui/Button';
import { Box } from 'certn-ui/Layout';
import { ErrorAlertAPI } from 'certn-ui/ErrorAlert';
import { UserOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import { Input, Button, Alert } from 'antd';

const propTypes = {
    // Parent Props
    onSubmit: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
};
const defaultProps = {};

class ForgotPasswordForm extends React.Component {
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.props
                    .onSubmit(values)
                    .then(() => {})
                    .catch((error) => ErrorAlertAPI(error));
            }
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <Box size="xs">
                <Form onSubmit={this.handleSubmit} style={{ width: '100%' }}>
                    <Form.Item>
                        {getFieldDecorator('email', {
                            rules: [
                                {
                                    type: 'email',
                                    required: true,
                                    message: this.props.intl.formatMessage({
                                        id: 'acf53.ForgotPasswordForm.inputEmail',
                                        defaultMessage: 'Please input your email!',
                                    }),
                                },
                            ],
                        })(
                            <Input
                                size="large"
                                prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                                placeholder={this.props.intl.formatMessage({
                                    id: 'common.email',
                                    defaultMessage: 'Email',
                                })}
                                data-testid="email"
                            />
                        )}
                    </Form.Item>
                    <ButtonLine>
                        <Button
                            type="primary"
                            size="large"
                            certncolor="primary"
                            htmlType="submit"
                            style={{ width: '100%' }}
                            loading={this.props.loading}
                            data-testid="button"
                        >
                            <FormattedMessage
                                id="acf53.ForgotPasswordForm.sendResetLink"
                                defaultMessage="Send Reset Link"
                            />
                        </Button>
                    </ButtonLine>
                    {this.props.error && (
                        <ButtonLine>
                            <Alert message={this.props.error} type="error" showIcon />
                        </ButtonLine>
                    )}
                </Form>
            </Box>
        );
    }
}

ForgotPasswordForm.propTypes = propTypes;
ForgotPasswordForm.defaultProps = defaultProps;

export default Form.create()(injectIntl(ForgotPasswordForm));
