// Libraries
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { intl } from 'components/GlobalProvider';

// UI Components
import Titlebar from 'certn-ui/Titlebar';
import Button from 'certn-ui/Button';
import { Layout } from 'certnd';

// External Libraries
import Cookies from 'js-cookie';

// Modules
import Auth from 'modules/Auth';

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

const propTypes = {};
const defaultProps = {};

class ApplyRedirect extends Component {
    constructor(props) {
        super(props);
        if (Auth.isApplicantAuthenticated()) {
            Auth.deauthenticateApplicant();
        }
        // TODO: Add handling here for checking that listing exists
        const listingId = props?.match?.params?.listing_id;
        if (listingId) {
            Cookies.set('listingUrlCode', listingId, { expires: 1 });
            Cookies.remove('onboardingId');
            if (this.props.match.path.indexOf('/hr/') > -1) {
                Cookies.set('onboardingType', 'HR', { expires: 1 });
            } else {
                Cookies.set('onboardingType', 'PM', { expires: 1 });
            }
            this.props.history.push(`/welcome/email/${listingId + this.props.location.search}`);
        }
    }

    render() {
        return (
            <Layout.Vertical width="100%" align="center">
                <Titlebar
                    size="xlrg"
                    center
                    title={intl.formatMessage({
                        id: '920fe.ApplyRedirect.title',
                        defaultMessage: 'Listing Not Found',
                    })}
                    subtitle={intl.formatMessage({
                        id: '920fe.ApplyRedirect.subtitle',
                        defaultMessage:
                            'Please check the link and try again. If that does not work, we can help you over our live chat (in the bottom left)!',
                    })}
                />
                <Button
                    type="primary"
                    size="large"
                    certncolor="primary"
                    style={{ width: '100%' }}
                    onClick={() => this.props.history.push('/login')}
                >
                    <FormattedMessage id="920fe.ApplyRedirect.login" defaultMessage="Login" />
                </Button>
            </Layout.Vertical>
        );
    }
}

ApplyRedirect.propTypes = propTypes;
ApplyRedirect.defaultProps = defaultProps;

export default connect(undefined, mapDispatchToProps)(ApplyRedirect);
