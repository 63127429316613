// Libraries
import styled from 'styled-components/macro';

export const Wrapper = styled.div`
    flex: 1;
`;

export const TopPanel = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px 30px;
    background-color: ${({ theme }) => theme.color.certnGray100};
`;

export const BottomWindow = styled.div`
    display: flex;
    flex-direction: row;
    padding: ${({ padding }) => padding || '30px'};
    border: 1px solid ${({ theme }) => theme.color.certnGray200};
    font-size: 14px;
`;

export const Title = styled.div`
    flex: ${({ flex }) => flex || 1};
`;

export const Column = styled.div`
    display: flex;
    flex-direction: column;
    flex: ${({ flex }) => flex || 1};
    margin: ${(props) => props.margin || 0};
`;
