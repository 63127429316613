// Libraries
import React from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

// UI Components
import Avatar from 'certn-ui/Avatar';

// Selectors
import { getUserMode, getUser } from 'base/BaseSelectors';

const CosignersCell = ({ record, history }) => {
    const user = useSelector(getUser);
    const userMode = useSelector(getUserMode);
    return (
        <>
            {!record.is_cosigner &&
                record.application &&
                record.application.applicants &&
                record.application.applicants.length > 0 &&
                record.application.applicants.map((app) => {
                    if (app.is_cosigner) {
                        return (
                            <Avatar
                                firstName={app.first_name}
                                lastName={app.last_name}
                                email={app.email}
                                hover={`Waiting on: ${app.email}`}
                                tooltip
                                active={app.status === 'Returned'}
                                onClick={
                                    user.can_view_reports
                                        ? () => history.push(`/${userMode.toLowerCase()}/applications/${app.id}`)
                                        : null
                                }
                                key={app.id}
                            />
                        );
                    }
                    return null;
                })}
            {record.is_cosigner &&
                record.application.applicants.map((app) => {
                    if (!app.is_cosigner) {
                        return (
                            <Avatar
                                firstName={app.first_name}
                                lastName={app.last_name}
                                email={app.email}
                                hover={`Waiting on: ${app.email}`}
                                tooltip
                                active={app.status === 'Returned'}
                                onClick={
                                    user.can_view_reports
                                        ? () => history.push(`/${userMode.toLowerCase()}/applications/${app.id}`)
                                        : null
                                }
                                key={app.id}
                            />
                        );
                    }
                })}
        </>
    );
};

export default withRouter(CosignersCell);
