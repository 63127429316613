// Libraries
import { getLaunchDarklyFlags } from 'base/BaseSelectors';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { ErrorAlertAPI } from 'certn-ui/ErrorAlert';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import styled from 'styled-components/macro';
import { RequestReportWrapper } from './components/SummaryStyles';

// Components
import { AppearRight } from 'certn-ui/Animate';
import Title from 'certn-ui/Title';
import { TrackNavigation } from 'views/welcome/components';
import { SubmissionResponse } from 'views/welcome/components/SubmissionResponse';
import Subtitle from 'views/welcome/components/Subtitle';
import RequestEmailCheckbox from 'views/welcome/views/disclosure/components/DisclosureForm/RequestEmailCheckbox';
import { CHECK_REQUEST } from 'base/BaseConstants';

// Modules
import withNavigation from 'views/welcome/modules/WithNavigation';

// Actions & Selectors
import {
    patchWelcomeSession,
    setTrackPageOrder,
    patchInformation,
    summaryCallback,
} from 'views/welcome/WelcomeActions';
import {
    getAnsweredCustomQuestions,
    getApplicant,
    getApplicantStatus,
    getEmployerReferenceSkipReasons,
    getInformation,
    getInternationalFields,
    getIsFetchingSilent,
    getIsSubmitted,
    getSettings,
    getTrackOrder,
    getPositionPropertyLocation,
    getOnboardingType,
    getSocialMediaDetails,
} from 'views/welcome/WelcomeSelectors';

import {
    Addresses,
    AustralianDocuments,
    AustralianLicences,
    AustraliaRightToWork,
    Basics,
    Credential,
    CustomQuestions,
    Documents,
    Driver,
    Education,
    EmergencyContact,
    EmployerReference,
    Employers,
    History,
    InternationalFields,
    NewBasics,
    MoreBasics,
    Passport,
    Personal,
    Supplemental,
    Vaccination,
    PositionPropertyLocation,
    SocialMediaDetails,
} from './components';
import Gender from './components/Gender';
import BirthPlace from './components/BirthPlace';

const SummaryContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const mapStateToProps = (state) => ({
    isFetchingSilent: getIsFetchingSilent(state),
    isSubmitted: getIsSubmitted(state),
    information: getInformation(state),
    internationalFields: getInternationalFields(state),
    customQuestions: getAnsweredCustomQuestions(state),
    settings: getSettings(state),
    applicant: getApplicant(state),
    trackOrder: getTrackOrder(state),
    applicantStatus: getApplicantStatus(state),
    escapeHatchReasons: getEmployerReferenceSkipReasons(state),
    webFeatureEnableNewEmployerReferenceTrack: getLaunchDarklyFlags(state)?.webFeatureEnableNewEmployerReferenceTrack,
    webFeatureRequireCurrentAndPositionPropertyAddressesForOnboarding: getLaunchDarklyFlags(state)
        .webFeatureRequireCurrentAndPositionPropertyAddressesForOnboarding,
    positionPropertyLocation: getPositionPropertyLocation(state),
    onboardingType: getOnboardingType(state),
    socialMediaDetails: getSocialMediaDetails(state),
    webAppDisableApplicantDisclosureOption: getLaunchDarklyFlags(state)?.webAppDisableApplicantDisclosureOption,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setTrackPageOrder,
            patchWelcomeSession,
            patchInformation,
            summaryCallback,
        },
        dispatch
    );

const propTypes = {
    // Redux Actions:
    setTrackPageOrder: PropTypes.func.isRequired,
    patchWelcomeSession: PropTypes.func.isRequired,
    patchInformation: PropTypes.func.isRequired,
    summaryCallback: PropTypes.func.isRequired,
    // Redux Store:
    information: PropTypes.object.isRequired,
};
const defaultProps = {};

class Summary extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.setTrackPageOrder(['base']);
    }

    goToTrack = (track) => {
        // Update the furthest track
        this.props.patchWelcomeSession(track);
        this.props.history.push(`/welcome/${track}`);
    };

    pages = () => {
        const {
            information,
            applicant,
            internationalFields,
            customQuestions,
            escapeHatchReasons,
            webFeatureEnableNewEmployerReferenceTrack,
            webFeatureRequireCurrentAndPositionPropertyAddressesForOnboarding,
            positionPropertyLocation,
            onboardingType,
            socialMediaDetails,
            webAppDisableApplicantDisclosureOption,
        } = this.props;

        const hasCurrentUSAddress =
            information?.addresses &&
            information.addresses.some((address) => address.country === 'US' && address.current === true);

        const tracks = get(this.props, 'trackOrder');
        const isUkCheck =
            applicant && (applicant[CHECK_REQUEST.UK_BASIC_DBS_CHECK] || applicant[CHECK_REQUEST.UK_BASIC_DS_CHECK]);

        const handleSubmit = async () => {
            if (!isUkCheck) {
                this.props.handleNextTrackAndAutoSubmit();
            } else {
                try {
                    await this.props.summaryCallback();
                    this.props.handleNextTrackAndAutoSubmit();
                } catch (error) {
                    ErrorAlertAPI(error);
                }
            }
        };

        return {
            base: (
                <AppearRight>
                    <Title>
                        <FormattedMessage
                            id="welcome.Summary.confirmYourDetails"
                            defaultMessage="Confirm your details"
                        />
                    </Title>
                    <Subtitle>
                        <FormattedMessage
                            id="welcome.Summary.confirmSubtitle"
                            defaultMessage="Please review your details below carefully. Once you confirm, you won't be able to edit these details again."
                        />
                    </Subtitle>
                    <SummaryContainer>
                        {tracks.includes('basics') && (
                            <Basics basics={information} goToTrack={() => this.goToTrack('basics')} />
                        )}
                        {tracks.includes('newBasics') && (
                            <NewBasics
                                applicantNames={information.applicant_names}
                                goToTrack={() => this.goToTrack('newBasics')}
                            />
                        )}
                        {tracks.includes('moreBasics') && (
                            <MoreBasics moreBasics={information} goToTrack={() => this.goToTrack('moreBasics')} />
                        )}
                        {tracks.includes('gender') && isUkCheck && (
                            <Gender information={information} goToTrack={() => this.goToTrack('gender')} />
                        )}
                        {tracks.includes('birthplace') && isUkCheck && (
                            <BirthPlace information={information} goToTrack={() => this.goToTrack('birthplace')} />
                        )}
                        {tracks.includes('supplemental') && (
                            <Supplemental information={information} goToTrack={() => this.goToTrack('supplemental')} />
                        )}
                        {tracks.includes('vaccinationInfo') && (
                            <Vaccination
                                vaccinationStatus={information.self_reported_vaccination_status}
                                goToTrack={() => this.goToTrack('vaccinationInfo')}
                            />
                        )}
                        {tracks.includes('employers') && (
                            <Employers
                                employers={information.employers}
                                goToTrack={() => this.goToTrack('employers')}
                            />
                        )}
                        {tracks.includes('employerReferences') && webFeatureEnableNewEmployerReferenceTrack && (
                            <EmployerReference
                                applicant={applicant}
                                information={information}
                                employerReferences={information.employer_references}
                                goToTrack={() => this.goToTrack('employerReferences')}
                                escapeHatchReasons={escapeHatchReasons}
                            />
                        )}
                        {tracks.includes('addresses') && (
                            <Addresses
                                addresses={information.addresses}
                                goToTrack={() => this.goToTrack('addresses')}
                                isUkCheck={isUkCheck}
                            />
                        )}
                        {tracks.includes('positionPropertyLocation') &&
                            webFeatureRequireCurrentAndPositionPropertyAddressesForOnboarding &&
                            (positionPropertyLocation ? (
                                <PositionPropertyLocation
                                    positionPropertyLocation={positionPropertyLocation}
                                    onboardingType={onboardingType}
                                    goToTrack={() => {
                                        this.goToTrack('positionPropertyLocation');
                                    }}
                                />
                            ) : null)}
                        {tracks.includes('socialMediaDetails') && (
                            <SocialMediaDetails
                                socialMediaDetails={socialMediaDetails}
                                goToTrack={() => {
                                    this.goToTrack('socialMediaDetails');
                                }}
                            />
                        )}
                        {tracks.includes('education') && (
                            <Education
                                educations={information.educations}
                                goToTrack={() => this.goToTrack('education')}
                            />
                        )}
                        {tracks.includes('credential') && (
                            <Credential
                                credentials={information.credentials}
                                goToTrack={() => this.goToTrack('credential')}
                            />
                        )}
                        {tracks.includes('personal') && (
                            <Personal
                                references={information.personal_references}
                                goToTrack={() => this.goToTrack('personal')}
                            />
                        )}
                        {tracks.includes('emergencyContact') && (
                            <EmergencyContact
                                emergencyContact={information}
                                goToTrack={() => this.goToTrack('emergencyContact')}
                            />
                        )}
                        {tracks.includes('documents') && !tracks.includes('acicDocuments') && (
                            <Documents
                                documents={information.documents}
                                goToTrack={() => this.goToTrack('documents')}
                            />
                        )}
                        {tracks.includes('rightToWork') && (
                            <AustraliaRightToWork
                                document={information.documents.find(
                                    (document) => document.document_subtype === 'right_to_work'
                                )}
                                goToTrack={() => this.goToTrack('rightToWork')}
                            />
                        )}
                        {tracks.includes('australianLicenses') && (
                            <AustralianLicences
                                licences={information.identity_numbers.filter((licence) => licence.country === 'AU')}
                                goToTrack={() => this.goToTrack('australianLicenses')}
                            />
                        )}
                        {tracks.includes('acicDocuments') && (
                            <AustralianDocuments
                                documents={information.documents}
                                citizenship={information?.citizenship}
                                goToTrack={() => this.goToTrack('acicDocuments')}
                            />
                        )}
                        {tracks.includes('passport') && (
                            <Passport
                                passport={
                                    information.documents.filter((doc) =>
                                        ['PASSPORT', 'AUSTRALIAN_PASSPORT', 'OTHER_PASSPORT'].includes(
                                            doc.document_type
                                        )
                                    )[0]
                                }
                                goToTrack={() => this.goToTrack('passport')}
                            />
                        )}
                        {tracks.includes('internationalFields') &&
                            internationalFields &&
                            Array.isArray(internationalFields) && (
                                <InternationalFields
                                    data={internationalFields.map(({ label, field }) => ({
                                        title: label,
                                        text: information[field],
                                    }))}
                                    goToTrack={() => this.goToTrack('internationalFields')}
                                />
                            )}
                        {tracks.includes('customQuestions') && customQuestions && Array.isArray(customQuestions) && (
                            <CustomQuestions
                                data={customQuestions}
                                goToTrack={() => this.goToTrack('customQuestions')}
                            />
                        )}
                        {tracks.includes('history') && (
                            <History information={information} goToTrack={() => this.goToTrack('history')} />
                        )}
                        {tracks.includes('driver') && (
                            <Driver information={information} goToTrack={() => this.goToTrack('driver')} />
                        )}
                        {tracks.includes('consentAndDisclosure') &&
                            hasCurrentUSAddress &&
                            !webAppDisableApplicantDisclosureOption && (
                                <RequestReportWrapper>
                                    <RequestEmailCheckbox
                                        hasRequestedEmail={information.requested_disclosure_email}
                                        setHasRequestedEmail={() => {
                                            this.props.patchInformation({
                                                requested_disclosure_email: !information.requested_disclosure_email,
                                            });
                                        }}
                                    />
                                </RequestReportWrapper>
                            )}
                    </SummaryContainer>
                    <TrackNavigation
                        handleSubmit={handleSubmit}
                        nextButtonTitle={<FormattedMessage id="welcome.general.confirm" defaultMessage="Confirm" />}
                    />
                </AppearRight>
            ),
        };
    };

    render() {
        const isApplicationSubmitted = this.props.applicantStatus === 'submitted';
        return isApplicationSubmitted ? (
            <SubmissionResponse history={this.props.history} />
        ) : (
            this.pages()[this.props.trackPageLocation] /* WithNavigation */
        );
    }
}

Summary.propTypes = propTypes;
Summary.defaultProps = defaultProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(withNavigation(Summary))));
