import styled from 'styled-components';

export const Title = styled.span`
    font-size: ${(props) => props.theme.font.lrg};
    font-weight: 600;
`;

export const OuterWrap = styled.div`
    padding: 20px;
    background-color: ${(props) => props.theme.color.certnGray100};
    @media (min-width: ${(props) => props.theme.width.med}) {
        margin: 0 auto;
        max-width: 1024px;
        padding: 20px 44px;
    }
`;

export const InnerWrap = styled.div`
    max-width: 768px;
    margin: auto;
`;

export const LayoutBody = styled.div`
    padding-top: 60px;
`;

export const Spacer = styled.div<{ size?: number }>`
    height: ${(props) => props.size || 24}px;
`;
