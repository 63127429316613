import { getDataSet, reduce } from 'iso3166-2-db';

const digits = (val) => val.replace(/\D/g, '').trim();

const dateWithDashes = ({ target: { value = null } }) => {
    if (value && digits(value).length >= 7) {
        return digits(value).replace(/(\d{4})(\d{2})(\d{1,2})/, '$1-$2-$3');
    }
    if (value && digits(value).length < 7) {
        return digits(value).replace(/(\d{4})(\d{1,2})/, '$1-$2');
    }
    return value;
};

const phoneNumber = ({ target: { value = null } }) => {
    if (value && digits(value).length >= 10) {
        return digits(value);
    }
    return value;
};

const postalCodeJustChars = ({ target: { value = null } }) => {
    if (value && value.length >= 5) {
        return value.replace(/[^a-z0-9]/gi, '');
    }
    return value;
};

const noSpaces = ({ target: { value = null } }) => value.replace(/\s/g, '');

const provinceIsoCode = ({ province_state, country }) => {
    if (!province_state || !country) {
        return '';
    }
    // ISO is language agnostic so we can hardcode 'en' here
    const countriesDB = reduce(getDataSet(), 'en');
    const { regions } = countriesDB && countriesDB[country];
    const region = regions.find((item) => item.name === province_state);
    return region?.iso || province_state;
};

const InputTransform = {
    dateWithDashes,
    postalCodeJustChars,
    phoneNumber,
    noSpaces,
    provinceIsoCode,
};

export default InputTransform;
