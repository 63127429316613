import { Currency } from 'types';
import numeral, { Numeral } from 'numeral';

const DEFAULT_CURRENCY_FORMAT = '0,0.00';

export const formatCurrency = (value: number | Numeral, currency: Currency, addAsterisk = false) => {
    let formattedNumber = numeral(value).format(DEFAULT_CURRENCY_FORMAT);

    if (addAsterisk) {
        formattedNumber = `${formattedNumber}*`;
    }

    if (currency) {
        if (currency === 'GBP') {
            return `£${formattedNumber} ${currency}`;
        }
        return `$${formattedNumber} ${currency}`;
    }
    return formattedNumber;
};
