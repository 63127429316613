// Libraries
import React from 'react';

// Components
import { CellWrapper, CellText } from 'views/manager/components';
import { ApplicationStatus } from 'certn-ui';

const InfoCell = ({ record }) => (
    <CellWrapper col clickable={record.status === 'Returned'}>
        <CellText clickable={record.status === 'Returned'} highlight={!record.is_viewed}>
            {record?.information?.first_name && `${record.information.first_name} ${record.information.last_name}`}
        </CellText>
        <CellText clickable={record.status === 'Returned'} highlight={!record.is_viewed}>
            {record.applicant_account.email}
        </CellText>
        <ApplicationStatus application={record} />
    </CellWrapper>
);

InfoCell.propTypes = {};

export default InfoCell;
