// Libraries
import React, { useEffect, useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Input, Col, Popover, Form } from 'antd';
import { CaretDownOutlined, InfoCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components/macro';
import { FormattedMessage, useIntl } from 'react-intl';

// Modules
import Countries, { isUSAOrCanada } from 'modules/Countries';
import Format from 'modules/Format';
import { Regex, InputTransform } from 'modules';
import styles from 'styles/styles';
import { countryItem, provinceItem, cityItem, CountyItem } from './CommonFormElements';

// Actions and Utils
import { updateAddressError } from 'base/BaseActions';
import { getAddressError } from 'base/BaseSelectors';
import { combineAddress, deriveAddressComponents, getSelectedProvince } from 'utils/addressInputHelpers';

const propTypes = {
    // Parent Props
    currentAddress: PropTypes.object,
    autocompleteId: PropTypes.string,
    showAllFields: PropTypes.bool,
};
const defaultProps = {
    currentAddress: undefined,
    autocompleteId: undefined,
    showAllFields: false,
};

const FindAddress = styled.div`
    margin-bottom: 20px;
    margin-top: -3px;
    padding: ${(props) => (props.showAllAddressFields ? '15px 15px 0px 15px' : '15px')};
    ${(props) => !props.showAllAddressFields && 'height: 0px'};
`;

function WorkLocationAddressInput({ form, currentAddress, autocompleteId, showAllFields, ...props }) {
    const autoComplete = useRef(null); // to make eslint happy
    const dispatch = useDispatch();
    const intl = useIntl();
    const [multi] = useState(autocompleteId ? `_${autocompleteId}` : '');
    const [showAllAddressFields, setShowAllAddressFields] = useState(false);
    const [inputFieldAddress, setInputFieldAddress] = useState(undefined);
    const [isManualEntry, setIsManualEntry] = useState(false);
    const addressError = useSelector(getAddressError);
    const addressLabel = `address${multi}`;
    const unitLabel = `unit${multi}`;
    const cityLabel = `city${multi}`;
    const countryLabel = `country${multi}`;
    const countyLabel = `county${multi}`;
    const postalCodeLabel = `postal_code${multi}`;
    const provinceStateLabel = `province_state${multi}`;
    const otherProvinceStateLabel = `other_province_state${multi}`;
    const autoCompleteLabel = `autocomplete${multi}`;

    // Antd update
    const forceUpdate = useState(0)[1];

    const currentCountry = form.getFieldValue(countryLabel);
    const currentProvinceState = form.getFieldValue(provinceStateLabel);
    const isUSOnly = currentCountry === 'US';
    const isUsCa = isUSAOrCanada(currentCountry);

    const currentCountyValue = form.getFieldValue(countyLabel);
    // API needs this way to work
    const provinceStateFieldName = isUsCa ? provinceStateLabel : otherProvinceStateLabel;
    const otherProvinceStateFieldName = isUsCa ? otherProvinceStateLabel : provinceStateLabel;

    const provinceLabel = intl.formatMessage({
        id: 'address.provinceState',
        defaultMessage: 'Province/State',
    });

    const onClickManualEntry = () => {
        if (!showAllAddressFields) {
            setIsManualEntry((previousValue) => !previousValue);
            setShowAllAddressFields((previousValue) => !previousValue);
            if (isUSOnly && !currentCountyValue) {
                dispatch(updateAddressError(true));
            }
        }
    };

    useEffect(() => {
        setShowAllAddressFields(Boolean(showAllAddressFields || showAllFields || addressError));
    }, [showAllAddressFields, showAllFields, addressError]);

    const handlePlaceSelect = useCallback(() => {
        if (!autoComplete.current) {
            return;
        }
        // Get address object from google service
        const addressObject = autoComplete.current.getPlace();
        const address = addressObject?.address_components;
        if (!address) {
            dispatch(updateAddressError(true));
            return;
        }
        // Legacy code brought over for hooks/antd4x refactor
        let {
            city,
            combinedAddress,
            country,
            county,
            other_province_state,
            postal_code,
            province_state,
            provinceOrOther,
            street_address,
            streetNumber,
        } = deriveAddressComponents(address);

        if (
            !street_address ||
            !city ||
            !provinceOrOther ||
            !county ||
            !country ||
            !postal_code ||
            (postal_code && postal_code.length < 5) ||
            streetNumber === undefined
        ) {
            setShowAllAddressFields(true);
            setIsManualEntry(false);
        }

        // Format postal code to remove space
        if (postal_code) {
            postal_code = Format.postalCodeForm(postal_code);
        }

        form.setFieldsValue({
            [countryLabel]: country,
            [countyLabel]: county,
        });
        setInputFieldAddress(combinedAddress);
        form.setFieldsValue({
            [addressLabel]: street_address,
            [cityLabel]: city,
            [provinceStateLabel]: province_state,
            [otherProvinceStateLabel]: other_province_state || '',
            [postalCodeLabel]: postal_code,
        });
        setShowAllAddressFields(true);
        dispatch(updateAddressError(false));
    }, [
        dispatch,
        form,
        addressLabel,
        cityLabel,
        provinceStateLabel,
        otherProvinceStateLabel,
        postalCodeLabel,
        countryLabel,
        countyLabel,
    ]);

    const initialize = useCallback(() => {
        if (!autoComplete.current) {
            autoComplete.current = new window.google.maps.places.Autocomplete(
                document.getElementById(autoCompleteLabel),
                {
                    fields: ['address_components'], // Save costs by only requesting what we want
                }
            );
            autoComplete.current.addListener('place_changed', handlePlaceSelect);
        }
    }, [autoCompleteLabel, handlePlaceSelect]);

    useEffect(() => {
        // Set window binding and onChange listener
        initialize();
        if (currentAddress) {
            let {
                address,
                unit,
                city,
                county,
                country,
                province_state,
                other_province_state,
                postal_code,
            } = currentAddress;
            if (address || currentAddress) {
                const provinceOrOther = getSelectedProvince(country, province_state, other_province_state);
                const combinedAddress = combineAddress(address, city, provinceOrOther, country, postal_code);
                setShowAllAddressFields(true);
                setInputFieldAddress(combinedAddress);

                if (multi && postal_code) {
                    postal_code = Format.postalCodeForm(postal_code);
                }
                form.setFieldsValue({
                    [addressLabel]: address,
                    [unitLabel]: unit,
                    [cityLabel]: city,
                    [countryLabel]: country,
                    [countyLabel]: county,
                    [provinceStateLabel]: province_state,
                    [otherProvinceStateLabel]: other_province_state,
                    [postalCodeLabel]: postal_code,
                });
            }
        }
        dispatch(updateAddressError(false));
    }, [
        addressLabel,
        cityLabel,
        countryLabel,
        countyLabel,
        currentAddress,
        dispatch,
        form,
        initialize,
        multi,
        otherProvinceStateLabel,
        postalCodeLabel,
        provinceStateLabel,
        unitLabel,
    ]);

    const onCountryChange = () => {
        // Clear the province values
        form.setFieldsValue({
            [cityLabel]: undefined,
            [countyLabel]: undefined,
            [otherProvinceStateLabel]: undefined,
            [postalCodeLabel]: undefined,
            [provinceStateLabel]: undefined,
            [unitLabel]: undefined,
        });
        // Antd forceUpdate required
        forceUpdate((s) => s + 1);
        dispatch(updateAddressError(false));
    };

    const onProvinceChange = () => {
        // Clear the province values
        form.setFieldsValue({
            [cityLabel]: undefined,
            [countyLabel]: undefined,
            [postalCodeLabel]: undefined,
            [unitLabel]: undefined,
        });
        // Antd forceUpdate required
        forceUpdate((s) => s + 1);
        dispatch(updateAddressError(false));
    };

    function handleAddressChange(event) {
        setInputFieldAddress(event.target.value);
    }

    const { skippable, hideLabels, canadaUSOnly, customTitle, bypassPostal, bypassCounty } = props;
    const required = !skippable; // bypass for employer form
    const countryList = canadaUSOnly ? Countries.canadaUS : Countries.all;

    /* Form item doesn't show up if moved to `src/certn-form/CommonFormElements/index.jsx`
Hence keeping it here for now.
*/
    const countyItem =
        !bypassCounty || (isUSOnly && isManualEntry) || (isUSOnly && !currentCountyValue) ? (
            <CountyItem
                required={isUSOnly && !currentCountyValue && addressError}
                currentUsState={currentProvinceState}
                countyLabel={countyLabel}
                showAllAddressFields={showAllAddressFields}
                currentProvinceState={currentProvinceState}
                hideLabels={hideLabels}
            />
        ) : (
            <Form.Item name={countyLabel} noStyle hidden />
        );

    /* Form item doesn't show up if moved to `src/certn-form/CommonFormElements/index.jsx`
Hence keeping it here for now.
*/
    const postalCodeItem = !bypassPostal ? (
        <Form.Item
            hasFeedback
            name={postalCodeLabel}
            getValueFromEvent={InputTransform.postalCodeJustChars}
            rules={[
                {
                    pattern: Regex.getPostalRegex(form.getFieldValue(countryLabel)),
                    message: intl.formatMessage({
                        id: 'error.validation.postalCode',
                        defaultMessage: 'Please provide a valid postal or zip code. Remove any spaces.',
                    }),
                },
                {
                    required: required && !bypassPostal,
                    message: intl.formatMessage({
                        id: 'error.validation.notBlank',
                        defaultMessage: 'Please do not leave blank',
                    }),
                },
            ]}
            style={{ display: showAllAddressFields ? 'block' : 'none' }}
            label={
                !hideLabels
                    ? intl.formatMessage({
                          id: 'addressAuto.postalZip',
                          defaultMessage: 'Postal/Zip Code',
                      })
                    : null
            }
        >
            <Input
                autoComplete="dont-use-autocomplete"
                size="large"
                data-testid="postal_code"
                type="text"
                placeholder={
                    hideLabels
                        ? intl.formatMessage({
                              id: 'addressAuto.postalZip',
                              defaultMessage: 'Postal/Zip Code',
                          })
                        : null
                }
                addonBefore={
                    <Popover
                        content={intl.formatMessage({
                            id: 'addressAuto.postalZip',
                            defaultMessage: 'Postal/Zip Code',
                        })}
                    >
                        <InfoCircleOutlined />
                    </Popover>
                }
            />
        </Form.Item>
    ) : (
        <Form.Item name={postalCodeLabel} noStyle hidden />
    );

    return (
        <>
            <div style={{ marginTop: '5px' }} role="main">
                {!hideLabels && (
                    <Col style={{ textAlign: 'right', paddingRight: '8px', boxSizing: 'border-box' }}>
                        <span>
                            {addressError && !showAllAddressFields && (
                                <span style={{ color: styles.color.certnRed700 }}>*</span>
                            )}
                            <FormattedMessage id="addressAuto.addressLookup" defaultMessage="Address Lookup" />:
                        </span>
                    </Col>
                )}
                <>
                    <Input
                        size="large"
                        id={autoCompleteLabel}
                        data-testid={autoCompleteLabel}
                        value={inputFieldAddress}
                        onChange={handleAddressChange}
                        placeholder={
                            customTitle ||
                            intl.formatMessage({
                                id: 'addressAuto.addressLookup',
                                defaultMessage: 'Address Lookup',
                            })
                        }
                        type="text"
                        addonAfter={
                            <div
                                style={{ cursor: 'pointer' }}
                                onClick={onClickManualEntry}
                                onKeyDown={onClickManualEntry}
                            >
                                <FormattedMessage id="addressAuto.manualEntry" defaultMessage="Manual Entry" />
                                <CaretDownOutlined />
                            </div>
                        }
                        addonBefore={
                            <Popover
                                content={
                                    customTitle ||
                                    intl.formatMessage({
                                        id: 'addressAuto.addressLookupStartTyping',
                                        defaultMessage: 'Address Lookup (Start typing your address)',
                                    })
                                }
                            >
                                <InfoCircleOutlined />
                            </Popover>
                        }
                        style={{
                            border:
                                addressError && !showAllAddressFields ? `1px solid ${styles.color.certnRed700}` : '0',
                        }}
                    />
                    {addressError && !showAllAddressFields && (
                        <span style={{ color: styles.color.certnRed700 }}>
                            <FormattedMessage
                                id="addressAuto.validAddressRequired"
                                defaultMessage="A valid address must be provided"
                            />
                        </span>
                    )}
                </>
                <FindAddress
                    showAllAddressFields={showAllAddressFields}
                    style={showAllAddressFields ? { display: 'default' } : { display: 'block', marginBottom: '0px' }}
                >
                    {countryItem(
                        countryLabel,
                        required,
                        intl,
                        canadaUSOnly,
                        showAllAddressFields,
                        hideLabels,
                        onCountryChange,
                        countryList
                    )}
                    {provinceItem(
                        provinceStateFieldName,
                        required,
                        intl,
                        showAllAddressFields,
                        hideLabels,
                        provinceLabel,
                        currentCountry,
                        onProvinceChange
                    )}
                    <Form.Item name={otherProvinceStateFieldName} noStyle hidden />
                    {cityItem(cityLabel, intl, required, showAllAddressFields, hideLabels)}
                    {countyItem}
                    {postalCodeItem}
                </FindAddress>
            </div>
        </>
    );
}

WorkLocationAddressInput.propTypes = propTypes;
WorkLocationAddressInput.defaultProps = defaultProps;

export default WorkLocationAddressInput;
