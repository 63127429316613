// Libraries
import React, { useState, useEffect } from 'react';
import { snakeCase } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { Col, Row, Radio, Form, Select, Input, Popover } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { intl } from 'components/GlobalProvider';

// Components
import { AppearRight } from 'certn-ui/Animate';
import Title from 'certn-ui/Title';
import { TrackNavigation } from 'views/welcome/components';
import FormLayout from 'certn-form/FormLayout';
import UploadRow from '../../../components/UploadRow';
import Text from 'certn-ui/Text';
import { Box } from 'certn-ui/Layout';

// Modules
import { Regex } from 'modules';
import Countries from 'modules/Countries';

// Constants
import { AUSTRALIAN_CITIZEN, NON_AUSTRALIAN_CITIZEN, AUSTRALIAN_CITIZEN_DOCUMENT_TYPES } from '../RightToWorkConstants';

const RightToWorkForm = ({
    handleSubmit,
    isAustralianCitizen,
    documentNumber,
    documentIssuingCountry,
    documentType,
    fileList,
    file,
    s3GetUploadLink,
    handleDeleteDocument,
    s3UploadLink,
    s3UploadFields,
    onUpload,
    error,
    handleProgress,
    fileProgress,
    isUploading,
    documentTypes,
}) => {
    const [form] = Form.useForm();
    const [citizen, setCitizen] = useState(isAustralianCitizen ? AUSTRALIAN_CITIZEN : undefined);
    const [selectedDocumentType, setSelectedDocumentType] = useState(undefined);

    const documentTypeOptions = documentTypes && documentTypes[citizen];
    const countryOptions =
        citizen === NON_AUSTRALIAN_CITIZEN ? Countries.all.filter(({ alpha2 }) => alpha2 !== 'AU') : Countries.all;

    const onCitizenChange = (event) => {
        const fields = { document_type: undefined };

        if (form.getFieldValue('document_issuing_country') === 'AU') {
            fields.document_issuing_country = undefined;
        }

        form.setFieldsValue(fields);
        setSelectedDocumentType(undefined);
        setCitizen(event.target.value);
    };

    useEffect(() => {
        setSelectedDocumentType(documentType);
    }, [documentType, setSelectedDocumentType]);

    useEffect(() => {
        if (!file) return;

        if (AUSTRALIAN_CITIZEN_DOCUMENT_TYPES.includes(selectedDocumentType)) {
            setCitizen(AUSTRALIAN_CITIZEN);
            return;
        }

        setCitizen(NON_AUSTRALIAN_CITIZEN);
    }, [file, setCitizen, selectedDocumentType]);

    return (
        <AppearRight>
            <Form form={form} layout="horizontal" onFinish={handleSubmit}>
                <Title>
                    <FormattedMessage
                        id="welcome.RightToWork.AustraliaTitle"
                        defaultMessage="Australia Right To Work"
                    />
                </Title>
                <FormLayout center>
                    <Text size="xs" align="center">
                        <FormattedMessage
                            id="welcome.RightToWork.AustraliaSubTitle"
                            defaultMessage="In order to process an Australia right to work check, we need to verify your documentation."
                        />
                    </Text>
                    <br />
                    {documentTypes && (
                        <Row gutter={16}>
                            <Col sm={24} lg={{ span: 24, offset: 0 }}>
                                <Box size="xxs">
                                    <Text weight="600" color="genericGray500">
                                        <FormattedMessage
                                            id="welcome.RightToWork.AutralianCitizenQuestion"
                                            defaultMessage="Are you an Australian Citizen?"
                                        />
                                    </Text>
                                    <Form.Item
                                        name="citizenship"
                                        initialValue={citizen}
                                        rules={[
                                            {
                                                required: true,
                                                type: 'string',
                                                message: intl.formatMessage({
                                                    id: 'error.validation.notSelected',
                                                    defaultMessage: 'Please make a selection',
                                                }),
                                            },
                                        ]}
                                    >
                                        <Radio.Group
                                            disabled={file}
                                            value={citizen}
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'start',
                                                alignItems: 'start',
                                                paddingTop: '4px',
                                            }}
                                            onChange={onCitizenChange}
                                        >
                                            <Radio value={AUSTRALIAN_CITIZEN} style={{ paddingBottom: '8px' }}>
                                                <FormattedMessage
                                                    id="welcome.RightToWork.AustralianCitizenYes"
                                                    defaultMessage="Yes, I am an Australian citizen"
                                                />
                                            </Radio>
                                            <Radio value={NON_AUSTRALIAN_CITIZEN}>
                                                <FormattedMessage
                                                    id="welcome.RightToWork.AustralianCitizenNo"
                                                    defaultMessage="No, I am not an Australian citizen"
                                                />
                                            </Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                    {citizen && documentTypeOptions && (
                                        <>
                                            <Text weight="600" color="genericGray500">
                                                <FormattedMessage
                                                    id="welcome.RightToWork.DocumentTypeLabel"
                                                    defaultMessage="Documentation"
                                                />
                                            </Text>
                                            <Form.Item
                                                name="document_type"
                                                initialValue={selectedDocumentType}
                                                style={{ width: '100%' }}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: intl.formatMessage({
                                                            id: 'error.validation.notSelected',
                                                            defaultMessage: 'Please make a selection',
                                                        }),
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    disabled={file}
                                                    onChange={setSelectedDocumentType}
                                                    placeholder={intl.formatMessage({
                                                        id: 'welcome.RightToWork.DocumentTypePlaceholder',
                                                        defaultMessage: 'Document Type',
                                                    })}
                                                >
                                                    {documentTypeOptions.map(({ key, value }) => (
                                                        <Select.Option key={key} value={key}>
                                                            {value}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                            <Form.Item
                                                hasFeedback
                                                initialValue={documentIssuingCountry}
                                                name="document_issuing_country"
                                                style={{ width: '100%' }}
                                                rules={[
                                                    {
                                                        required: true,
                                                        type: 'string',
                                                        message: intl.formatMessage({
                                                            id: 'error.validation.countryError',
                                                            defaultMessage: 'Select a country!',
                                                        }),
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    data-testid="country"
                                                    size="medium"
                                                    placeholder={intl.formatMessage({
                                                        id: 'welcome.RightToWork.DocumentIssuingCountryPlaceholder',
                                                        defaultMessage: 'Issuing Country',
                                                    })}
                                                    autoComplete="dont-use-autocomplete"
                                                    showSearch
                                                    filterOption={(input, option) =>
                                                        option.props.children
                                                            .toLowerCase()
                                                            .indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    {countryOptions.map((country, index) => (
                                                        <Select.Option
                                                            data-testid={snakeCase(country.name)}
                                                            key={`${country.alpha2}-${index}`}
                                                            value={country.alpha2}
                                                        >
                                                            {country.name}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                            <Form.Item
                                                name="document_number"
                                                style={{ width: '100%' }}
                                                initialValue={documentNumber}
                                                rules={[
                                                    {
                                                        required: true,
                                                        pattern: Regex.alphaNumeric,
                                                        message: intl.formatMessage({
                                                            id: 'error.validation.document.number',
                                                            defaultMessage: 'Please provide a valid document number',
                                                        }),
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    placeholder={intl.formatMessage({
                                                        id: 'welcome.RightToWork.DocumentNumberPlaceholder',
                                                        defaultMessage: 'Document Number',
                                                    })}
                                                    addonBefore={
                                                        <Popover
                                                            content={intl.formatMessage({
                                                                id: 'welcome.RightToWork.DocumentNumberPlaceholder',
                                                                defaultMessage: 'Document Number',
                                                            })}
                                                        >
                                                            <InfoCircleOutlined />
                                                        </Popover>
                                                    }
                                                />
                                            </Form.Item>
                                        </>
                                    )}
                                </Box>
                            </Col>
                        </Row>
                    )}
                </FormLayout>
                {citizen && (
                    <Row gutter={16}>
                        <UploadRow.UploadsWrapper>
                            <UploadRow.ReqUploadBox error={error}>
                                <UploadRow
                                    acceptedFileTypes=".jpg,.jpeg,.png,.pdf"
                                    doc={selectedDocumentType}
                                    file={file}
                                    fileList={fileList}
                                    name={intl.formatMessage({
                                        id: 'welcome.RightToWork.UploadTitle',
                                        defaultMessage: 'Document Image',
                                    })}
                                    s3UploadLink={s3UploadLink}
                                    s3UploadFields={s3UploadFields}
                                    s3GetUploadLink={s3GetUploadLink}
                                    onUpload={onUpload}
                                    handleDeleteDocument={handleDeleteDocument}
                                    error={error}
                                    handleProgress={handleProgress}
                                    fileProgress={fileProgress}
                                    missing={!file}
                                    disabled={!selectedDocumentType}
                                />
                            </UploadRow.ReqUploadBox>
                        </UploadRow.UploadsWrapper>
                    </Row>
                )}
                <FormLayout />
                <TrackNavigation loading={isUploading} />
            </Form>
        </AppearRight>
    );
};

export default RightToWorkForm;
