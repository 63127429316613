import styled from 'styled-components';

export const Subtitle = styled.div`
    font-size: ${(props) => props.theme.font.xxs};
    color: ${(props) => props.theme.color.certnGray500};
`;

export const Row = styled.div`
    @media (min-width: ${(props) => props.theme.width.sm}) {
        display: flex;
    }
`;

export const InfoCol = styled.div`
    flex: 1;
    @media (max-width: ${(props) => props.theme.width.sm}) {
        margin-bottom: 20px;
    }
`;
