import { useMemo } from 'react';
import { ContentLink } from 'certn-ui/Text';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Path } from 'modules';
import { getLanguage } from 'base/BaseSelectors';

type PrivacyURLSProps = Record<DomainProps, LocaleProps>;
type LocaleProps = Record<string, string>;
type DomainProps = 'MYCRC' | 'DEFAULT';

const PRIVACY_URLS: PrivacyURLSProps = {
    MYCRC: {
        en: 'https://mycrc.ca/privacy-policy/',
        fr: 'https://mycrc.ca/fr/privacy-policy/',
        es: 'https://mycrc.ca/es/privacy-policy/',
        default: 'https://mycrc.ca/privacy-policy/',
    },
    DEFAULT: {
        en: 'https://certn.co/privacy-policy/',
        fr: 'https://certn.co/fr/privacy-policy/',
        es: 'https://certn.co/es/privacy-policy/',
        default: 'https://certn.co/privacy-policy/',
    },
};

export const PrivacyPolicyLink = () => {
    const isMyCRC = Path.isMyCRCSubdomain();
    const language: string = useSelector(getLanguage);

    const privacyURL = useMemo(() => {
        if (isMyCRC) {
            return PRIVACY_URLS.MYCRC[language] || PRIVACY_URLS.MYCRC.default;
        }
        return PRIVACY_URLS.DEFAULT[language] || PRIVACY_URLS.DEFAULT.default;
    }, [isMyCRC, language]);

    return (
        <ContentLink href={privacyURL}>
            <FormattedMessage id="common.privacyPolicy" defaultMessage="Privacy Policy" />
        </ContentLink>
    );
};
