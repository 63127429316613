// Libraries
import styled from 'styled-components/macro';

const Section = styled.div`
    max-width: 600px;
    margin: 10px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const List = styled.ul`
    max-width: 320px;
    margin: 10px auto;
    text-align: left;
    font-weight: bold;
`;

const Explain = {
    Section,
    List,
};

export default Explain;
