import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { FormattedMessage } from 'react-intl';

import { Checkbox, Progress, notification } from 'antd';

// SVG Animations
import { AppearRight } from 'certn-ui/Animate';

import { ForwardButton, ButtonLine } from 'certn-ui/Button';

const propTypes = {
    titles: PropTypes.object.isRequired,
    completeAction: PropTypes.func.isRequired,
    fetchStatus: PropTypes.func,

    status: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
    // eslint-disable-next-line
    completeCondition: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]).isRequired,
    // eslint-disable-next-line
    errorCondition: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]).isRequired,
};

const defaultProps = {
    fetchStatus: undefined,
    status: undefined,
};

const LoadingArea = styled.div`
    height: 100%;
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const TextWrapper = styled.div`
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    height: 100px;
    width: 50%;
    margin: auto;
`;

const Text = styled.span`
    font-size: 25px;
`;

const CheckboxAreaWrapper = styled.div`
    margin: 10px;
    width: 50%;
    display: flex;
    justify-content: space-evenly;
`;

const ProgressBarWrapper = styled.div`
    margin: 10px;
    width: 50%;
`;

class LoadingPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            percent: 0,
            rate: 60,
            step: 1,
            complete: false,
            status: <FormattedMessage id="status.pending" defaultMessage="Pending" />,
            stepValues: {
                1: false,
                2: false,
                3: false,
                4: false,
                5: false,
            },
        };
        this.titleStepText = props.titles;
        this.titleStepText[5] = <FormattedMessage id="074a3.LoadingPage.building" defaultMessage="Building Results" />;
        this.titleStepText[6] = <FormattedMessage id="074a3.LoadingPage.completing" defaultMessage="Completing..." />;
    }

    componentDidMount() {
        const { rate } = this.state;
        this.timerId = setInterval(this.barIncrease, rate);
    }

    componentWillUnmount() {
        clearTimeout(this.timerId);
    }

    checkCompleteCondition = () => {
        const { rate } = this.state;
        if (this.checkCondition('completeCondition')) {
            this.setState({ rate: rate / 3, complete: true });
        }
    };

    checkCondition = (type) => this.state.status.toLowerCase() === this.props[type].toLowerCase();

    checkErrorCondition = () => {
        const { completeAction } = this.props;
        if (this.checkCondition('errorCondition')) {
            completeAction();
            notification.error({
                message: <FormattedMessage id="074a3.LoadingPage.message1" defaultMessage="Error" />,
                description: (
                    <FormattedMessage
                        id="074a3.LoadingPage.desc1"
                        defaultMessage="Sorry, there was an error. Please contact support@certn.co"
                    />
                ),
            });
        }
    };

    barIncrease = () => {
        const { percent, complete } = this.state;

        if (percent !== 100) {
            return this.setState({ percent: percent + 1 });
        }

        if (percent === 100) {
            clearTimeout(this.timerId);
            this.checkCompleteCondition();
            if (!complete) this.fetchStatus();
            setTimeout(() => this.setState({ percent: 0 }, () => this.finishedStep()), 1000);
        }
        return null;
    };

    finishedStep = () => {
        const { step, rate, stepValues } = this.state;
        const { completeAction } = this.props;
        const stepVal = { ...stepValues };
        stepVal[step] = true;

        this.setState({ stepValues: stepVal, step: step + 1 }, () => {
            if (step < 5) {
                this.timerId = setInterval(this.barIncrease, rate);
            } else {
                // if (!complete) {
                //     this.completeTimeoutCheck();
                // }
                setTimeout(() => completeAction(), 2000);
            }
        });
    };

    // completeTimeoutCheck = () => {
    //     if (!this.checkCondition('completeCondition')) {
    //         notification.error({
    //             message: 'Server Delay',
    //             description:
    //                 'Sorry, the process took longer than expected, check the application to confirm it completed.',
    //         });
    //         this.titleStepText[6] = 'Server Delay';
    //     }
    // };

    fetchStatus = () => {
        const { fetchId } = this.props;
        this.props.fetch(fetchId).then((application) => this.setState({ status: application.status }));
    };

    handleBackToScreening = () => this.props.completeAction();

    render() {
        const { step, stepValues, percent } = this.state;

        return (
            <LoadingArea>
                <AppearRight distance={10} cut key={step}>
                    <TextWrapper>
                        <Text>{this.titleStepText[step]}</Text>
                    </TextWrapper>
                </AppearRight>
                <CheckboxAreaWrapper>
                    <Checkbox checked={stepValues[1]} />
                    <Checkbox checked={stepValues[2]} />
                    <Checkbox checked={stepValues[3]} />
                    <Checkbox checked={stepValues[4]} />
                    <Checkbox checked={stepValues[5]} />
                </CheckboxAreaWrapper>
                <ProgressBarWrapper>
                    {step < 6 ? <Progress percent={percent} /> : <Progress percent={100} />}
                </ProgressBarWrapper>
                <ButtonLine>
                    <ForwardButton
                        label={<FormattedMessage id="074a3.LoadingPage.keep" defaultMessage="Keep Screening" />}
                        onClick={this.handleBackToScreening}
                    />
                </ButtonLine>
            </LoadingArea>
        );
    }
}

LoadingPage.propTypes = propTypes;
LoadingPage.defaultProps = defaultProps;

export default LoadingPage;
