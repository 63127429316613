// Libraries
import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Form } from '@ant-design/compatible';

// Components
import { TrackNavigation } from 'views/welcome/components';
import Text from 'certn-ui/Text';
import { AppearRight } from 'certn-ui/Animate';
import Title from 'certn-ui/Title';
import MultiAdd, { MultiAddItem, EmptyListContainer } from 'views/welcome/components/MultiAdd';

const RoommatesBase = ({ handleSubmit, handleReverse, roommates, addOrEditRoommate, deleteRoommate, intl }) => (
    <AppearRight>
        <Title>
            <FormattedMessage
                id="welcome.Supplemental.roommates.baseTitle"
                defaultMessage="Can you give me a few details about the other people you will be living with?"
            />
        </Title>
        <Text half align="center" color="certnGray400">
            <FormattedMessage
                id="welcome.Supplemental.roommates.baseDescription"
                defaultMessage="Roommates include spouses, children, and anyone else who will be living with you. Any adults added will be sent their own application to complete."
            />
        </Text>
        <MultiAdd
            title={intl.formatMessage({
                id: 'welcome.Summary.roommates',
                defaultMessage: 'Roommates',
            })}
            buttonTitle={intl.formatMessage({
                id: 'welcome.Supplemental.roommates.baseAddRoommate',
                defaultMessage: 'ADD ROOMMATE',
            })}
            buttonAction={addOrEditRoommate}
        >
            {roommates && roommates.length > 0 ? (
                roommates.map((roommate, index) => (
                    <MultiAddItem
                        key={roommate.id}
                        top={`${roommate.first_name} ${roommate.last_name}`}
                        bottom={`${
                            roommate.adult_child === 'A'
                                ? intl.formatMessage({
                                      id: 'welcome.Supplemental.roommates.adult',
                                      defaultMessage: 'Adult',
                                  })
                                : intl.formatMessage({
                                      id: 'welcome.Supplemental.roommates.child',
                                      defaultMessage: 'Child',
                                  })
                        }`}
                        onEdit={() => addOrEditRoommate(index)}
                        onDelete={() => deleteRoommate(index)}
                        deleteText={intl.formatMessage({
                            id: 'welcome.Supplemental.roommates.baseDeleteEntry',
                            defaultMessage: 'Delete this roommate entry?',
                        })}
                    />
                ))
            ) : (
                <EmptyListContainer>
                    <FormattedMessage
                        id="welcome.Supplemental.roommates.baseNoRoommates"
                        defaultMessage="No Roommate entries added yet."
                    />
                </EmptyListContainer>
            )}
        </MultiAdd>
        <TrackNavigation handleSubmit={handleSubmit} handleReverse={handleReverse} />
    </AppearRight>
);

export default Form.create()(injectIntl(RoommatesBase));
