import { getUserModeIsPM } from 'base/BaseSelectors';
import { useUserTeam } from 'hooks/useUserTeam';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import Constants from 'utils/constants';

export type Filters = {
    adjudicator?: string;
    adjudicationReviewStatus?: string;
    adverseActionStatus?: string[];
    nameEmail?: string;
    status?: string[];
    type?: string[];
    team?: string;
    result?: string[];
};

export const useFilters = (initialFilters: Filters) => {
    const { team } = useUserTeam();
    const isCanada = team?.settings_config?.get_org_country === 'CA';
    const [filters, setFilters] = useState<Filters>(initialFilters);
    const userModeIsPM = useSelector(getUserModeIsPM);
    const { webFeatureDeprecateNumericScoring, webFeatureEnableNewStatusSystem } = useFlags();

    const setFilter = (filterType: string, value: string | string[]) => {
        const newFilters = {
            ...filters,
            [filterType]: value,
        };

        setFilters(newFilters);

        return newFilters;
    };

    const clearFilters = useCallback(() => {
        setFilters({
            nameEmail: undefined,
            team: undefined,
            adjudicator: undefined,
            adjudicationReviewStatus: undefined,
            type: [],
            status: [],
            result: [],
        });
    }, [setFilters]);

    const clearAAFilters = useCallback(() => {
        const newFilters = {
            ...filters,
            adverseActionStatus: [],
            status: [],
        };

        setFilters(newFilters);

        return newFilters;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setFilters]);

    const buildQueryParam = (values: Filters) => {
        let query = '';

        if (values.nameEmail) {
            query += `&search=${values.nameEmail}`;
        }

        if (values.team) {
            query += `&application__team__id=${values.team}`;
        }

        values.type?.forEach((type) => {
            query += `&${type}=True`;
        });
        const statusQueryKey = webFeatureEnableNewStatusSystem ? 'order_status' : 'report_status';
        values.status?.forEach((status) => {
            const statusName = status.toUpperCase().replace(' ', '_');

            // Comments copied from pre-refactoring:
            // If this looks hacky it's because it is.
            // This is a front-end only solution to address Application Status Improvements (CERTN-5472)
            // This should be removed when API 3.0 is used
            if (userModeIsPM) {
                query += `&${statusQueryKey}=${statusName}`;
            } else if (statusName === Constants.reportStatus.IN_PROGRESS && !webFeatureEnableNewStatusSystem) {
                query += `&${statusQueryKey}=${Constants.reportStatus.ANALYZING}`;
            } else if (statusName === Constants.reportStatus.SENT && !webFeatureEnableNewStatusSystem) {
                query += `&${statusQueryKey}=${Constants.reportStatus.SENT}`;
                query += `&${statusQueryKey}=${Constants.reportStatus.IN_PROGRESS}`;
            } else {
                query += `&${statusQueryKey}=${statusName}`;
            }
        });

        let resultQueryKey = 'result';
        if (userModeIsPM && isCanada && !webFeatureDeprecateNumericScoring) {
            resultQueryKey = 'overall_score';
        }
        values.result?.forEach((result) => {
            query += `&${resultQueryKey}=${result.toUpperCase()}`;
        });

        if (values.adjudicator) {
            query += `&applicant_adjudication__adjudicator=${values.adjudicator}`;
        }

        if (values.adjudicationReviewStatus) {
            query += `&applicant_adjudication__life_cycle_status=${values.adjudicationReviewStatus}`;
        }
        values.adverseActionStatus?.forEach((result) => {
            query += `&adverse_action_status=${result}`;
        });

        return query;
    };

    return {
        filters,
        setFilter,
        clearFilters,
        clearAAFilters,
        buildQueryParam,
    };
};
