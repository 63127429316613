// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 10px;
`;
const Label = styled.span`
    font-size: 10px;
    color: ${({ theme }) => theme.color.certnGray400};
`;

// PropTypes
const propTypes = { label: PropTypes.string };
const defaultProps = { label: 'Select' };

const SelectBox = ({ label, children }) => (
    <Wrapper>
        <Label>{label}</Label>
        {children}
    </Wrapper>
);

SelectBox.propTypes = propTypes;
SelectBox.defaultProps = defaultProps;

export default SelectBox;
