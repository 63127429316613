// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Form } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';

// Components
import {
    SectionWrapper,
    SectionTopBar,
    SectionBottomPanel,
    BottomPanelRow,
    BottomPanelRowInner,
    Instructions,
    CustomSelect,
} from '../styled';
import FieldLabel from 'certn-form/FieldLabel';
import EmailAddresses from 'components/EmailAddresses/EmailAddresses';

// Modules
import Auth from 'modules/Auth';

// Actions & Selectors
import { getTeam, getUserInfo, getJoinedTeamsByTeamCountry } from 'base/BaseSelectors';
import { getTeamsByTeamCountry } from 'views/manager/views/admin/AdminSelectors';
import { sortBy } from 'lodash';

const propTypes = {
    submitAttempted: PropTypes.bool.isRequired,
};

const TeamSelection = ({ submitAttempted }) => {
    const intl = useIntl();
    const superteamTeams = useSelector(getTeamsByTeamCountry);
    let joinedTeams = useSelector(getJoinedTeamsByTeamCountry);
    // Default joinedTeams to superteam teams if joinedTeams selector returns empty array or falsey value
    // Without this, during period between web+api release, users won't have joined_team values to switch between
    // TODO: Remove the fallback logic when api branch epic/CERTN-4734/multiple-teams-per-user is in prod
    joinedTeams = joinedTeams?.length ? joinedTeams : superteamTeams;

    // User superteams if user is admin, otherwise use joinedTeams, sorted alphabetically by internal name
    const teams = sortBy(Auth.isPermissionLevel('admin') ? superteamTeams : joinedTeams, (t) => t.internal_name);
    const team = useSelector(getTeam);
    const userInfo = useSelector(getUserInfo);

    const instructions = (
        <Instructions>
            {intl.formatMessage({
                id: 'f5cd8.Applicant.weWillEmail',
                defaultMessage:
                    "We will email notifications to the package owner so they can easily follow this application's progress. Please enter additional emails that should be getting similar notifications Certn.",
            })}
        </Instructions>
    );
    return (
        <SectionWrapper>
            <SectionTopBar>
                <FormattedMessage id="f5cd8.Applicant.screeningTeamHeader" defaultMessage="Screening Team" />
            </SectionTopBar>
            <SectionBottomPanel>
                <BottomPanelRow>
                    <Instructions>
                        <FormattedMessage
                            id="f5cd8.Applicant.screeningInstructions"
                            defaultMessage="Your Screening Team is set to your personal team as the default, however we do allow you to screen an applicant on behalf of another team if you choose."
                        />
                    </Instructions>
                </BottomPanelRow>
                <BottomPanelRow style={{ marginTop: '15px', marginBottom: '-20px' }}>
                    <BottomPanelRowInner>
                        <FieldLabel htmlFor="team_id">
                            <FormattedMessage id="f5cd8.Applicant.screeningTeamField" defaultMessage="Screening team" />
                        </FieldLabel>
                        <Form.Item name="team_id" initialValue={team?.id || userInfo.team.internal_name || undefined}>
                            <CustomSelect
                                showSearch
                                placeholder={intl.formatMessage({
                                    id: 'f5cd8.Applicant.selectATeam',
                                    defaultMessage: 'Select a team',
                                })}
                                optionFilterProp="children"
                            >
                                {teams.map(({ internal_name, id }) => (
                                    <CustomSelect.Option key={id} value={id}>
                                        {internal_name}
                                    </CustomSelect.Option>
                                ))}
                            </CustomSelect>
                        </Form.Item>
                    </BottomPanelRowInner>
                </BottomPanelRow>
                {team.settings_config.allow_package_owner_notifications && (
                    <BottomPanelRow>
                        <BottomPanelRowInner style={{ marginTop: '25px' }}>
                            <EmailAddresses
                                instructions={instructions}
                                label={intl.formatMessage({
                                    id: 'f5cd8.Applicant.additional',
                                    defaultMessage: 'Additional Notification Contact(s)',
                                })}
                                fieldName="notificationEmails"
                                submitAttempted={submitAttempted}
                                required={false}
                            />
                        </BottomPanelRowInner>
                    </BottomPanelRow>
                )}
            </SectionBottomPanel>
        </SectionWrapper>
    );
};

TeamSelection.propTypes = propTypes;

export default TeamSelection;
