// Constants
import { CHECK_REQUEST } from 'base/BaseConstants';

const ADDRESS_REFERENCE = [CHECK_REQUEST.ADDRESS_REFERENCES, CHECK_REQUEST.ADDRESS_PHONE_REFERENCES];

const STANDARD_ACIC = [CHECK_REQUEST.STANDARD_ACIC_CHECK];
const VOLUNTEER_ACIC = [CHECK_REQUEST.VOLUNTEER_ACIC_CHECK];

const CANADIAN_CRIMINAL = [
    CHECK_REQUEST.CRIMINAL_RECORD_CHECK,
    CHECK_REQUEST.ENHANCED_CRIMINAL_RECORD_CHECK,
    CHECK_REQUEST.VULNERABLE_SECTOR_CRIMINAL_RECORD_CHECK,
];

const INTERNATIONAL_CRIMINAL = [CHECK_REQUEST.INTERNATIONAL_CRIMINAL_RECORD_CHECK];

const UK_CRIMINAL = [CHECK_REQUEST.UK_BASIC_DBS_CHECK, CHECK_REQUEST.UK_BASIC_DS_CHECK];

const UK_RIGHT_TO_WORK = [CHECK_REQUEST.UK_RIGHT_TO_WORK_CHECK];

const US_CRIMINAL = [
    CHECK_REQUEST.US_CRIMINAL_RECORD_CHECK_TIER_1,
    CHECK_REQUEST.US_CRIMINAL_RECORD_CHECK_TIER_2,
    CHECK_REQUEST.US_CRIMINAL_RECORD_CHECK_TIER_3,
];

const hasCheck = (checks) => (req) => checks.some((check) => req?.[check]);

const CheckRequested = {
    addressReference: hasCheck(ADDRESS_REFERENCE),
    standardACIC: hasCheck(STANDARD_ACIC),
    volunteerACIC: hasCheck(VOLUNTEER_ACIC),
    australianACIC: hasCheck(STANDARD_ACIC) || hasCheck(VOLUNTEER_ACIC),
    canadianCriminal: hasCheck(CANADIAN_CRIMINAL),
    internationalCriminal: hasCheck(INTERNATIONAL_CRIMINAL),
    ukCriminal: hasCheck(UK_CRIMINAL),
    ukRightToWork: hasCheck(UK_RIGHT_TO_WORK),
    usCriminal: hasCheck(US_CRIMINAL),
};

export default CheckRequested;
