import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';

const propTypes = {
    handleDelete: PropTypes.func,
    handleAdd: PropTypes.func,
};

const defaultProps = {
    handleDelete: () => null,
    handleAdd: () => null,
};

const Tab = ({ title, handleDelete, handleAdd, type = 'primary' }) => (
    <TabContainer type={type}>
        {type === 'primary' ? (
            <Button
                type={type}
                shape="circle"
                style={{ border: 'none', marginRight: '5px' }}
                icon={<CloseOutlined />}
                onClick={() => handleDelete(title)}
            />
        ) : (
            <Button
                type={type}
                shape="circle"
                style={{ border: 'none', marginRight: '5px' }}
                icon={<PlusOutlined />}
                onClick={() => handleAdd(title)}
            />
        )}
        {title && <TabText type={type}>{title}</TabText>}
    </TabContainer>
);

Tab.propTypes = propTypes;
Tab.defaultProps = defaultProps;

export default Tab;

export const TabBox = styled.div`
    max-width: 100%;
    width: ${(props) => props.theme.width.med};
    padding: 20px 10px;
    display: flex;
    justify-content: ${(props) => props.justifyContent || 'center'};
    align-items: center;
    box-sizing: border-box;
    flex-wrap: wrap;
    margin: 0 auto;
`;

const TabContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 15px;
    padding: 5px 20px;
    box-sizing: border-box;
    background: ${(props) => (props.type === 'primary' ? props.theme.color.primary : props.theme.color.certnWhite)};
    border: 2px solid ${(props) => props.theme.color.primary};
    border-radius: 30px;

    @media (max-width: ${(props) => props.theme.width.sm}) {
        margin: 10px;
        padding: 5px 15px;
        border-radius: 20px;
    }
`;

const TabText = styled.span`
    font-size: 18px;
    font-weight: 300;
    color: ${(props) => (props.type === 'primary' ? props.theme.color.certnWhite : props.theme.color.primary)};

    @media (max-width: ${(props) => props.theme.width.sm}) {
        font-size: 14px;
    }
`;
