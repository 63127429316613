// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components/macro';
import { bindActionCreators } from 'redux';
import { EllipsisOutlined } from '@ant-design/icons';

// UI Components
import { ListCellText } from 'certn-ui/List';
import { Dropdown as AntDropdown, Menu, Modal, message, Button as AntButton } from 'antd';

// Actions & Selectors
import { deleteProperty } from 'views/manager/views/pm/views/properties/PropertiesActions';

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            deleteProperty,
        },
        dispatch
    );

const propTypes = {
    // Redux State
    deleteProperty: PropTypes.func.isRequired,
};

const Dropdown = styled(AntDropdown)`
    margin: 8px 0px;
`;

const Button = styled(AntButton)`
    ${(props) => props.margin && `margin: ${props.margin}`};
    overflow: hidden !important;
    height: 40px !important;
    width: 40px !important;
`;

class DropdownCell extends React.Component {
    showConfirmDelete = (item) => {
        Modal.confirm({
            title: 'Do you really want to delete this building?',
            content:
                'This action cannot be undone. If you think you might use this building in the future, consider selecting Cancel. Otherwise select Okay.',
            okType: 'danger',
            onOk: () => {
                this.props.deleteProperty(item.id);
                return new Promise((resolve) => {
                    setTimeout(resolve, 1000);
                })
                    .then(() => message.success('Building deleted!'))
                    .catch(() => message.error('Error occurred while attempting to set delete building'));
            },
            onCancel() {},
        });
    };

    render() {
        const { item, history } = this.props;
        return (
            <Dropdown
                overlay={
                    <Menu>
                        <Menu.Item key="0">
                            <ListCellText
                                clickable
                                onClick={() => history.push(`/pm/listings/new?propertyId=${item.id}`)}
                            >
                                Create Listing
                            </ListCellText>
                        </Menu.Item>
                        <Menu.Item key="1">
                            <ListCellText
                                clickable
                                onClick={() => history.push(`/pm/applications?property_id=${item.id}`)}
                            >
                                View Applications
                            </ListCellText>
                        </Menu.Item>
                        <Menu.Item key="2">
                            <ListCellText clickable onClick={() => history.push(`/pm/properties/${item.id}/edit`)}>
                                Edit Building
                            </ListCellText>
                        </Menu.Item>
                        <Menu.Item key="3">
                            <ListCellText clickable critical onClick={() => this.showConfirmDelete(item)}>
                                Delete Building
                            </ListCellText>
                        </Menu.Item>
                        {/* <Menu.Item key="2">
                            <ListCellText onClick={this.showConfirm}>
                                Set to Inactive
                            </ListCellText>
                        </Menu.Item> */}
                    </Menu>
                }
                trigger={['click']}
                placement="bottomCenter"
            >
                <Button shape="circle" icon={<EllipsisOutlined />} />
            </Dropdown>
        );
    }
}

DropdownCell.propTypes = propTypes;

export default withRouter(connect(undefined, mapDispatchToProps)(DropdownCell));
