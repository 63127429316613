// Libraries
import React from 'react';
import styled from 'styled-components/macro';

// Components
import DropdownCell from './DropdownCell';

const FlexWrapper = styled.div`
    display: flex;
    justify-content: ${(props) => (props.center ? 'center' : 'flex-start')};
    flex-direction: ${(props) => (props.col ? 'column' : 'row')};
    align-items: ${(props) => (props.centerAlign ? 'center' : 'inherit')};
    transition: 0.3s;
    ${(props) => props.margin && 'margin: 2px 0px !important'};
    ${(props) => props.active && `color: ${props.theme.color.certnGreen600}`};
    &:hover {
        cursor: ${(props) => (props.clickable ? 'pointer' : 'inherit')};
        ${(props) => props.header && props.clickable && !props.active && `color: ${props.theme.color.certnGray400}`};
    }
`;

const ActionsCell = ({ row, editTemplate, deleteTemplate }) => (
    <FlexWrapper margin>
        <DropdownCell
            editTemplate={editTemplate}
            deleteTemplate={deleteTemplate}
            templateName={row.name}
            item={row.id}
        />
    </FlexWrapper>
);

export default ActionsCell;
