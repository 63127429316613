import { queryKeys } from '../../utils/reactQuery/queryKeys';
import { useQuery } from '@tanstack/react-query';
import { TeamMinimal } from 'types';
import { getRequest } from 'utils/http';

const fetchTeams = () =>
    getRequest<TeamMinimal[]>({
        version: 'v2',
        endpoint: `/minimal/teams/`,
    });

export const useTeamsMinimal = () => {
    const { data, isLoading, isError } = useQuery<TeamMinimal[]>([queryKeys.teams], fetchTeams);

    return {
        teams: data,
        teamsLoading: isLoading,
        teamsError: isError,
    };
};
