import { postRequest, getRequest, patchRequest } from 'utils/http';

import { NAMESPACE as PARENT_NAMESPACE, STATEPATH as PARENT_STATEPATH } from './ReferenceConstants';

// Constants
export const NAMESPACE = `${PARENT_NAMESPACE}`;
export const STATEPATH = `${PARENT_STATEPATH}`;

// Actions Types
export const PATCH_REFERENCE_QUESTION_REQ = `${NAMESPACE}PATCH_REFERENCE_QUESTION_REQ`;
export const PATCH_REFERENCE_QUESTION_RES = `${NAMESPACE}PATCH_REFERENCE_QUESTION_RES`;
export const PATCH_REFERENCE_QUESTION_ERR = `${NAMESPACE}PATCH_REFERENCE_QUESTION_ERR`;
export const FETCH_REFERENCE_QUESTIONS_REQ = `${NAMESPACE}FETCH_REFERENCE_QUESTIONS_REQ`;
export const FETCH_REFERENCE_QUESTIONS_RES = `${NAMESPACE}FETCH_REFERENCE_QUESTIONS_RES`;
export const FETCH_REFERENCE_QUESTIONS_ERR = `${NAMESPACE}FETCH_REFERENCE_QUESTIONS_ERR`;
export const SUBMIT_REFERENCE_QUESTIONS_REQ = `${NAMESPACE}SUBMIT_REFERENCE_QUESTIONS_REQ`;
export const SUBMIT_REFERENCE_QUESTIONS_RES = `${NAMESPACE}SUBMIT_REFERENCE_QUESTIONS_RES`;
export const SUBMIT_REFERENCE_QUESTIONS_ERR = `${NAMESPACE}SUBMIT_REFERENCE_QUESTIONS_ERR`;

// Action Creators
export const submitReferenceQuestions = (id) => (dispatch) => {
    dispatch({
        type: SUBMIT_REFERENCE_QUESTIONS_REQ,
    });
    return postRequest({
        internal: false,
        version: 'v2',
        endpoint: `/reference/${id}/submit/`,
    }).then(
        (response) => {
            dispatch({
                type: SUBMIT_REFERENCE_QUESTIONS_RES,
                payload: { isSubmitted: response.is_submitted },
            });
        },
        (error) => {
            dispatch({
                type: SUBMIT_REFERENCE_QUESTIONS_ERR,
                payload: { error },
            });
        }
    );
};

export const fetchReferenceQuestions = (id) => (dispatch) => {
    dispatch({
        type: FETCH_REFERENCE_QUESTIONS_REQ,
    });
    return getRequest({
        internal: false,
        version: 'v2',
        endpoint: `/reference/${id}/`,
    }).then(
        (response) => {
            dispatch({
                type: FETCH_REFERENCE_QUESTIONS_RES,
                payload: { referenceQuestions: response },
            });
        },
        (error) => {
            dispatch({
                type: FETCH_REFERENCE_QUESTIONS_ERR,
                payload: { error },
            });
        }
    );
};

export const patchReferenceQuestion = (question) => (dispatch) => {
    dispatch({
        type: PATCH_REFERENCE_QUESTION_REQ,
    });
    return patchRequest({
        internal: false,
        version: 'v2',
        endpoint: `/reference_question/${question.id}/`,
        body: JSON.stringify(question),
    }).then(
        () => {
            dispatch({
                type: PATCH_REFERENCE_QUESTION_RES,
            });
        },
        (error) => {
            dispatch({
                type: PATCH_REFERENCE_QUESTION_ERR,
                payload: { error },
            });
        }
    );
};

export const patchReferenceQuestions = ({ id, questions }) => (dispatch) => {
    dispatch({
        type: PATCH_REFERENCE_QUESTION_REQ,
    });
    return patchRequest({
        internal: false,
        version: 'v2',
        endpoint: `/reference_question/${id}/bulk/`,
        body: JSON.stringify(questions),
    }).then(
        () => {
            dispatch({
                type: PATCH_REFERENCE_QUESTION_RES,
            });
        },
        (error) => {
            dispatch({
                type: PATCH_REFERENCE_QUESTION_ERR,
                payload: { error },
            });
            // Need to be able to catch this error
            throw error;
        }
    );
};
