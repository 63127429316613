import { NAMESPACE as PARENT_NAMESPACE, STATEPATH as PARENT_STATEPATH } from 'views/manager/ManagerConstants';

export const NAMESPACE = `${PARENT_NAMESPACE}FEATURES/ADVESE_ACTION/DRAWER/`;
export const STATEPATH = `${PARENT_STATEPATH}.features.AdverseActionDrawer`;

export const IS_ADVERSE_ACTION_DRAWER_OPEN = `${NAMESPACE}IS_ADVERSE_ACTION_DRAWER_OPEN`;
export const FETCH_ADVERSE_ACTION_DETAILS_REQ = `${NAMESPACE}FETCH_ADVERSE_ACTION_DETAILS_REQ`;
export const FETCH_ADVERSE_ACTION_DETAILS_RES = `${NAMESPACE}FETCH_ADVERSE_ACTION_DETAILS_RES`;
export const FETCH_ADVERSE_ACTION_DETAILS_ERR = `${NAMESPACE}FETCH_ADVERSE_ACTION_DETAILS_ERR`;
export const PAUSE_ADVERSE_ACTION_REQ = `${NAMESPACE}PAUSE_ADVERSE_ACTION_REQ`;
export const PAUSE_ADVERSE_ACTION_RES = `${NAMESPACE}PAUSE_ADVERSE_ACTION_RES`;
export const PAUSE_ADVERSE_ACTION_ERR = `${NAMESPACE}PAUSE_ADVERSE_ACTION_ERR`;
export const RESUME_ADVERSE_ACTION_REQ = `${NAMESPACE}RESUME_ADVERSE_ACTION_REQ`;
export const RESUME_ADVERSE_ACTION_RES = `${NAMESPACE}RESUME_ADVERSE_ACTION_RES`;
export const RESUME_ADVERSE_ACTION_ERR = `${NAMESPACE}RESUME_ADVERSE_ACTION_ERR`;
export const CANCEL_ADVERSE_ACTION_REQ = `${NAMESPACE}CANCEL_ADVERSE_ACTION_REQ`;
export const CANCEL_ADVERSE_ACTION_RES = `${NAMESPACE}CANCEL_ADVERSE_ACTION_RES`;
export const CANCEL_ADVERSE_ACTION_ERR = `${NAMESPACE}CANCEL_ADVERSE_ACTION_ERR`;
export const RESTART_ADVERSE_ACTION_REQ = `${NAMESPACE}RESTART_ADVERSE_ACTION_REQ`;
export const RESTART_ADVERSE_ACTION_RES = `${NAMESPACE}RESTART_ADVERSE_ACTION_RES`;
export const RESTART_ADVERSE_ACTION_ERR = `${NAMESPACE}RESTART_ADVERSE_ACTION_ERR`;
