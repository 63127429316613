import * as React from 'react';
import styles from 'styles/styles';

function ScreenInfoClient(props) {
    return (
        <svg width={41} height={39} viewBox="0 0 41 39" fill="none" {...props}>
            <path
                clipRule="evenodd"
                d="M30.976 33.834l-7.54 4.305.951-8.76 8.095-12.904c.448-.712 1.617-.79 2.6-.177l3.163 1.985c.98.615 1.42 1.704.972 2.416l-8.241 13.135z"
                stroke={styles.color.certnBlue900}
                strokeWidth={1.568}
                strokeLinejoin="round"
            />
            <path
                d="M30.7 32.868l-5.575-3.5M25.642 36.786l-1.816-1.173M28.085 31.147l4.583-7.305M30.893 19.338l6.437 4.039"
                stroke={styles.color.certnBlue900}
                strokeWidth={1.568}
                strokeLinejoin="round"
            />
            <path
                d="M8.83 10.502s6.469.885 8.43-3.91c0 0 2.169 3.785 4.867 3.785"
                stroke={styles.color.certnBlue900}
                strokeWidth={1.568}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.214 22.207c.004 1.155 0 3.644 0 3.644s.263 1.558-8.978 3.909"
                stroke={styles.color.certnBlue900}
                strokeWidth={1.568}
                strokeLinejoin="round"
            />
            <path
                d="M9.214 22.207v3.644c0 .131.263 1.558-8.978 3.909M18.707 22.207v3.644c0 .078.506 1.032 3.287 2.024"
                stroke={styles.color.certnBlue900}
                strokeWidth={1.568}
                strokeLinejoin="round"
            />
            <path
                d="M16.823 22.78c1.252-.3 1.893-.861 1.893-.861 2.118-1.505 3.434-6.227 3.83-9.534.584-4.875-.091-10.703-8.586-10.897-8.494.193-9.169 6.023-8.586 10.897.396 3.307 1.712 8.028 3.83 9.534 0 0 .64.561 1.892.86"
                stroke={styles.color.certnBlue900}
                strokeWidth={1.568}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default ScreenInfoClient;
