// Libraries
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { Spin, Button, Popconfirm, message } from 'antd';
import styled from 'styled-components';
import copy from 'copy-to-clipboard';
import { LoadingOutlined } from '@ant-design/icons';

// Components
import Overlay from 'certn-ui/Overlay';
import { GrayFrame } from 'certnd';
import Typography from 'certnd/Typography';
import { flexAlignCenter } from 'styles/Mixins';

// Actions & Selectors
import { fetchWebhookSecret, cycleWebhookSecret } from 'views/manager/views/settings/SettingsActions';
import { getWebhookSecret, getWebhookSecretFetching } from 'views/manager/views/settings/SettingsSelectors';
import { WEBHOOK_SECRET_PLACEHOLDER } from 'views/manager/views/settings/SettingsConstants';
import { getTeamId } from 'base/BaseSelectors';

const { Heading } = Typography;

const SecretWrapper = styled.div`
    ${flexAlignCenter};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const ObfuscatedKey = styled.span`
    position: relative;
    top: 4px;
    font-size: 15px;
`;

const ActionWrapper = styled.div`
    display: flex;
    flex-direction: row-reverse;
    margin: 2px 0 10px 0;
`;

const WebhookSecret = () => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const [show, setShow] = useState(false);
    const teamID = useSelector(getTeamId);
    const currentTeamID = useParams().teamId;
    const isCurrentTeam = teamID === currentTeamID;
    const webhookSecret = useSelector(getWebhookSecret);
    const desktop = window.matchMedia('(min-width: 1024px)').matches;
    const loading = useSelector(getWebhookSecretFetching);

    useEffect(() => {
        dispatch(fetchWebhookSecret(currentTeamID));
    }, [dispatch, currentTeamID]);

    const copyToClipboard = () => {
        copy(webhookSecret);
        message.success(
            intl.formatMessage({
                id: '59e77.General.webhookSecret.copySuccess',
                defaultMessage: 'Webhook Secret was copied to clipboard.',
            })
        );
    };

    const handleToggleShow = () => {
        setShow(!show);
    };

    const handleGenerateSecert = () => {
        dispatch(cycleWebhookSecret(currentTeamID)).then(() => {
            message.success(
                intl.formatMessage({
                    id: '59e77.General.webhookSecret.cycleSuccess',
                    defaultMessage: 'You have generated a secret. The key will be added to your account.',
                })
            );
        });
    };

    const renderSecret = () => {
        if (!webhookSecret)
            return <FormattedMessage id="59e77.General.webhookSecret.noSecretYet" defaultMessage="No Secret yet" />;

        return (
            <Overlay
                primary
                layer={
                    <Button size="small" onClick={copyToClipboard}>
                        <FormattedMessage id="c838c.ApplicantsCell.copy" defaultMessage="Copy" />
                    </Button>
                }
            >
                {show ? webhookSecret : <ObfuscatedKey>{WEBHOOK_SECRET_PLACEHOLDER}</ObfuscatedKey>}
            </Overlay>
        );
    };

    const renderGenerateButton = () => {
        // Once a webhook secret exists, wrap in pop confirm, otherwise skip for instant generation
        if (webhookSecret && isCurrentTeam)
            return (
                <Popconfirm
                    title={<GenerateConfirmation />}
                    onConfirm={handleGenerateSecert}
                    okText={<FormattedMessage id="welcome.general.yes" defaultMessage="Yes" />}
                    cancelText={<FormattedMessage id="welcome.general.no" defaultMessage="No" />}
                >
                    <Button>
                        <FormattedMessage id="59e77.General.webhookSecret.generate" defaultMessage="Generate New" />
                    </Button>
                </Popconfirm>
            );

        return (
            <Button disabled={!isCurrentTeam} onClick={handleGenerateSecert}>
                <FormattedMessage id="59e77.General.webhookSecret.generate" defaultMessage="Generate New" />
            </Button>
        );
    };

    return (
        <>
            <Heading.H4 disableGutter>
                <FormattedMessage id="59e77.General.webhookSecret.title" defaultMessage="Webhook Secret" />
            </Heading.H4>
            <GrayFrame center={show}>
                {loading ? (
                    <Spin indicator={<LoadingOutlined spin />} />
                ) : (
                    <SecretWrapper>{renderSecret()}</SecretWrapper>
                )}
            </GrayFrame>
            <ActionWrapper>
                {renderGenerateButton()}
                {desktop && (
                    <Button disabled={!webhookSecret} onClick={handleToggleShow} style={{ marginRight: '5px' }}>
                        <FormattedMessage id="59e77.General.webhookSecret.showSecret" defaultMessage="Show Secret" />
                    </Button>
                )}
            </ActionWrapper>
        </>
    );
};

const GenerateConfirmation = () => (
    <div>
        <FormattedMessage
            id="59e77.General.webhookSecret.confirmTitle"
            defaultMessage="You will be generating a new secret. Would you like to continue and replace the current secret?"
        />
        <br />
        <FormattedMessage
            id="59e77.General.webhookSecret.confirmExpiryWarning"
            defaultMessage="The old secret will expire automatically in 48 hours."
        />
    </div>
);

export default WebhookSecret;
