// Libraries
import { get } from 'lodash';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Form as AntdForm } from '@ant-design/compatible';
import copy from 'copy-to-clipboard';
import styled from 'styled-components/macro';
import { FormattedMessage } from 'react-intl';
import { intl } from 'components/GlobalProvider';
import { useSelector, useDispatch } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { Popover, message, Button, Descriptions } from 'antd';

// Components
import Form from 'certnd/Form';
import Loader from 'certn-ui/Loader';
import Typography from 'certnd/Typography';
import Packages from './packages/Packages';
import { ErrorAlertAPI } from 'certn-ui/ErrorAlert';
import ToggleIntegrationButton from './components/ToggleIntegrationButton';

// Modules & Utils
import Auth from 'modules/Auth';
import Path from 'modules/Path';
import { selectBackwardsCompatibleRequestAndTitles } from 'views/manager/features/ScreenApplicant/components/ScreenApplicant/MicroServiceContent';

// Actions & Selectors
import { getIntegrations, getLeverPackages, getIsFetching } from 'views/manager/views/settings/SettingsSelectors';
import {
    enableLever,
    fetchIntegrationPackages,
    updateIntegration,
    fetchReferenceTemplates,
} from 'views/manager/views/settings/SettingsActions';
import { getTeam } from 'views/manager/views/admin/AdminSelectors';

const INTEGRATION_TYPE = 'LEVER';
const INTEGRATION_NAME = 'lever';

const { Heading } = Typography;

const FormBlock = styled(Form.Block)`
    margin: 0 !important;
    max-width: 100% !important;
`;

const subdomainPicker = {
    'staging-app': 'staging-api',
    'demo-app': 'demo-api',
};

const Lever = (props) => {
    const subdomain = subdomainPicker[Path.getCurrentSubdomain()] || 'api';
    const { validateFields, getFieldValue } = props.form;
    const [loading, setLoading] = useState(false);
    const integrations = useSelector((state) => getIntegrations(state));
    const teamId = useSelector((state) => getTeam(state).id);
    const canEdit = Auth.isPermissionLevel('manager');
    const dispatch = useDispatch();
    const isFetching = useSelector(getIsFetching);
    const servicesList = useSelector(selectBackwardsCompatibleRequestAndTitles);
    const [isEditing, setIsEditing] = useState(false);

    // Lever
    const leverIntegration = get(integrations, [INTEGRATION_NAME], {});
    const leverActive = get(integrations, [INTEGRATION_NAME, 'is_active']);
    const leverPackages = useSelector(getLeverPackages);

    const [defaultPackageId, setDefaultPackageId] = useState();

    useEffect(() => {
        dispatch(fetchIntegrationPackages({ teamId, integrationType: INTEGRATION_TYPE }));
        dispatch(fetchReferenceTemplates({ type: 'employer', teamId }));
    }, [dispatch, teamId]);

    useEffect(() => {
        const defaultPackage = get(integrations, [INTEGRATION_NAME, 'default_package']);
        setDefaultPackageId(defaultPackage);
    }, [integrations]);

    const toggleEdit = () => {
        setIsEditing(!isEditing);
    };

    const copyToClipboard = () => {
        const url = `${subdomain}.certn.co/lever/teams/${teamId}/webhooks/`;
        const copySuccess = copy(url);
        if (copySuccess) {
            message.success(
                intl.formatMessage({
                    id: 'a7880.Lever.CopySuccess',
                    defaultMessage: 'Integration link was copied to clipboard.',
                })
            );
        } else {
            message.error(
                intl.formatMessage({
                    id: 'a7880.Lever.CopyError',
                    defaultMessage: 'Integration link could not be copied.',
                })
            );
        }
    };

    const defaultServices = {};
    servicesList.forEach((service) => {
        defaultServices[service.request] = false;
    });

    // saves the updated api and signatures
    const saveChanges = () => {
        validateFields(['api_key_update', 'signature_token_update', 'trigger_stage_name_update'], (err) => {
            if (!err) {
                const changesMade = {};
                if (getFieldValue('api_key_update')) {
                    changesMade.api_key = getFieldValue('api_key_update').trim();
                }
                if (getFieldValue('signature_token_update')) {
                    changesMade.signature_token = getFieldValue('signature_token_update').trim();
                }
                if (getFieldValue('trigger_stage_name_update')) {
                    changesMade.trigger_stage_name = getFieldValue('trigger_stage_name_update').trim();
                }

                dispatch(updateIntegration({ teamId, integrationName: INTEGRATION_NAME, data: changesMade }))
                    .then(() => {
                        message.success(
                            intl.formatMessage({
                                id: 'a7880.Lever.IntegrationUpdated',
                                defaultMessage: 'Integration Updated',
                            })
                        );
                        toggleEdit();
                    })
                    .catch((e) => ErrorAlertAPI(e));
            }
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (leverActive) return props.handleDisableIntegration({ name: INTEGRATION_NAME });
        props.form.validateFields((err, values) => {
            if (!err) {
                setLoading(true);
                const intPkg = {
                    api_key: (values.api_key || leverIntegration.api_key).trim(),
                    signature_token: (values.signature_token || leverIntegration.signature_token).trim(),
                    default_package: defaultPackageId,
                    trigger_stage_name: (values.trigger_stage_name || leverIntegration.trigger_stage_name).trim(),
                    is_active: true,
                };
                if (!leverIntegration.api_key) {
                    dispatch(enableLever({ teamId, data: intPkg }))
                        .then(() => {
                            setLoading(false);
                            message.success(
                                intl.formatMessage({
                                    id: 'a7880.Lever.IntegrationConnected',
                                    defaultMessage: 'Integration Connected',
                                })
                            );
                        })
                        .catch((error) => {
                            setLoading(false);
                            ErrorAlertAPI(error);
                        });
                } else {
                    dispatch(updateIntegration({ teamId, integrationName: INTEGRATION_NAME, data: intPkg }))
                        .then(() => {
                            setLoading(false);
                            message.success(
                                intl.formatMessage({
                                    id: 'a7880.Lever.IntegrationConnected',
                                    defaultMessage: 'Integration Connected',
                                })
                            );
                        })
                        .catch((error) => {
                            setLoading(false);
                            ErrorAlertAPI(error);
                        });
                }
            }
        });
    };

    if (isFetching || !leverIntegration || !leverPackages) return <Loader />;
    return (
        <AntdForm onSubmit={handleSubmit}>
            <p style={{ margin: '0px', paddingBottom: '15px' }}>
                <FormattedMessage
                    id="a7880.Lever.SettingUp"
                    defaultMessage="For help setting up an integration with Lever "
                />
                <a
                    href="https://certn.zendesk.com/hc/en-us/articles/360051930333-Certn-Lever-Integration-Getting-Started"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <FormattedMessage id="a7880.Lever.ClickHere" defaultMessage="Click Here" />
                </a>
                <FormattedMessage
                    id="a7880.Lever.CustomerSupport"
                    defaultMessage=" or contact your Certn customer support representative."
                />
            </p>
            <FormBlock>
                <Heading.H3>
                    <FormattedMessage id="a7880.Lever.IntegrationDetails" defaultMessage="Integration Details:" />
                </Heading.H3>
                {!isEditing && leverIntegration.api_key && leverIntegration.signature_token ? (
                    <>
                        <Descriptions column={1} style={{ paddingLeft: '10px' }}>
                            <Descriptions.Item
                                label={intl.formatMessage({
                                    id: 'a7880.Lever.ApiKey',
                                    defaultMessage: 'API Key Ending In',
                                })}
                            >
                                *{leverIntegration.api_key}
                            </Descriptions.Item>
                            <Descriptions.Item
                                label={intl.formatMessage({
                                    id: 'a7880.Lever.WebhookSignature',
                                    defaultMessage: 'Webhook Signature Ending In',
                                })}
                            >
                                *{leverIntegration.signature_token}
                            </Descriptions.Item>
                            <Descriptions.Item
                                label={intl.formatMessage({
                                    id: 'a7880.Lever.TriggerStage',
                                    defaultMessage: 'Trigger Stage Name',
                                })}
                            >
                                {leverIntegration.trigger_stage_name}
                            </Descriptions.Item>
                        </Descriptions>
                        <Button onClick={toggleEdit}>
                            <FormattedMessage id="a7880.Lever.EditButton" defaultMessage="Edit" />
                        </Button>
                    </>
                ) : !leverActive ? (
                    <>
                        <Form.Input
                            form={props.form}
                            fieldName="api_key"
                            title={intl.formatMessage({ id: 'a7880.Lever.ApiKeyTitle', defaultMessage: 'API Key' })}
                            addonBefore={
                                <Popover
                                    content={intl.formatMessage({
                                        id: 'a7880.Lever.ApiKeyPopover',
                                        defaultMessage:
                                            'API Key that is generated by Lever on the integrations page in Lever Settings',
                                    })}
                                >
                                    <InfoCircleOutlined />
                                </Popover>
                            }
                            options={{
                                rules: [
                                    {
                                        required: true,
                                        message: intl.formatMessage({
                                            id: 'a7880.Lever.ApiKeyError',
                                            defaultMessage: 'Please enter a valid Lever API Key',
                                        }),
                                        whitespace: true,
                                    },
                                ],
                            }}
                        />
                        <Form.Input
                            form={props.form}
                            fieldName="signature_token"
                            title={intl.formatMessage({
                                id: 'a7880.Lever.WebhookTitle',
                                defaultMessage: 'Webhook Signature',
                            })}
                            addonBefore={
                                <Popover
                                    content={intl.formatMessage({
                                        id: 'a7880.Lever.WebhookPopover',
                                        defaultMessage:
                                            'Webhook Signature Token that is generated by Lever on the Webhook page in Lever Settings',
                                    })}
                                >
                                    <InfoCircleOutlined />
                                </Popover>
                            }
                            options={{
                                rules: [
                                    {
                                        required: true,
                                        message: intl.formatMessage({
                                            id: 'a7880.Lever.WebhookError',
                                            defaultMessage: 'Please enter a valid Lever Webhook Signature',
                                        }),
                                        whitespace: true,
                                    },
                                ],
                            }}
                        />
                        <Form.Input
                            form={props.form}
                            fieldName="trigger_stage_name"
                            title={intl.formatMessage({
                                id: 'a7880.Lever.TriggerStageTitle',
                                defaultMessage: 'Trigger Stage',
                            })}
                            addonBefore={
                                <Popover
                                    content={intl.formatMessage({
                                        id: 'a7880.Lever.TriggerStagePopover',
                                        defaultMessage:
                                            'The name of the Lever stage that will trigger sending contacts to Certn to start scans',
                                    })}
                                >
                                    <InfoCircleOutlined />
                                </Popover>
                            }
                            options={{
                                rules: [
                                    {
                                        required: true,
                                        message: intl.formatMessage({
                                            id: 'a7880.Lever.TriggerStageError',
                                            defaultMessage: 'Please enter a valid Lever stage name',
                                        }),
                                        whitespace: true,
                                    },
                                ],
                            }}
                        />
                    </>
                ) : (
                    <>
                        <Form.Input
                            form={props.form}
                            fieldName="api_key_update"
                            title={intl.formatMessage({ id: 'a7880.Lever.ApiKeyTitle', defaultMessage: 'API Key' })}
                            placeholder={
                                intl.formatMessage({
                                    id: 'a7880.Lever.ApiKeyPlaceholder',
                                    defaultMessage: 'Current API Key Ending in: ',
                                }) + leverIntegration.api_key
                            }
                            addonBefore={
                                <Popover
                                    content={intl.formatMessage({
                                        id: 'a7880.Lever.ApiKeyPopover',
                                        defaultMessage:
                                            'API Key that is generated by Lever on the integrations page in Lever Settings',
                                    })}
                                >
                                    <InfoCircleOutlined />
                                </Popover>
                            }
                            options={{
                                rules: [
                                    {
                                        message: intl.formatMessage({
                                            id: 'a7880.Lever.ApiKeyError',
                                            defaultMessage: 'Please enter a valid Lever API Key',
                                        }),
                                        whitespace: true,
                                    },
                                ],
                            }}
                        />
                        <Form.Input
                            form={props.form}
                            fieldName="signature_token_update"
                            title={intl.formatMessage({
                                id: 'a7880.Lever.WebhookTitle',
                                defaultMessage: 'Webhook Signature',
                            })}
                            placeholder={
                                intl.formatMessage({
                                    id: 'a7880.Lever.WebhookPlaceholder',
                                    defaultMessage: 'Current Webhook Signature Ending in: ',
                                }) + leverIntegration.signature_token
                            }
                            addonBefore={
                                <Popover
                                    content={intl.formatMessage({
                                        id: 'a7880.Lever.WebhookPopover',
                                        defaultMessage:
                                            'Webhook Signature Token that is generated by Lever on the Webhook page in Lever Settings',
                                    })}
                                >
                                    <InfoCircleOutlined />
                                </Popover>
                            }
                            options={{
                                rules: [
                                    {
                                        message: intl.formatMessage({
                                            id: 'a7880.Lever.WebhookError',
                                            defaultMessage: 'Please enter a valid Lever Webhook Signature',
                                        }),
                                        whitespace: true,
                                    },
                                ],
                            }}
                        />
                        <Form.Input
                            form={props.form}
                            fieldName="trigger_stage_name_update"
                            title={intl.formatMessage({
                                id: 'a7880.Lever.TriggerStageTitle',
                                defaultMessage: 'Trigger Stage',
                            })}
                            placeholder={
                                intl.formatMessage({
                                    id: 'a7880.Lever.TriggerStagePlaceholder',
                                    defaultMessage: 'Current Trigger Stage Name: ',
                                }) + leverIntegration.trigger_stage_name
                            }
                            addonBefore={
                                <Popover
                                    content={intl.formatMessage({
                                        id: 'a7880.Lever.TriggerStagePopover',
                                        defaultMessage:
                                            'The name of the Lever stage that will trigger sending contacts to Certn to start scans',
                                    })}
                                >
                                    <InfoCircleOutlined />
                                </Popover>
                            }
                            options={{
                                rules: [
                                    {
                                        message: intl.formatMessage({
                                            id: 'a7880.Lever.TriggerStageError',
                                            defaultMessage: 'Please enter a valid Lever stage name',
                                        }),
                                        whitespace: true,
                                    },
                                ],
                            }}
                        />
                        <Button type="primary" onClick={saveChanges} style={{ marginRight: '10px' }}>
                            <FormattedMessage id="a7880.Lever.SaveChanges" defaultMessage="Save Changes" />
                        </Button>
                        <Button onClick={toggleEdit}>
                            <FormattedMessage id="common.cancel" defaultMessage="Cancel" />
                        </Button>
                    </>
                )}
            </FormBlock>
            <Packages
                integrationType={INTEGRATION_TYPE}
                integrationName={INTEGRATION_NAME}
                packages={leverPackages}
                active={leverActive}
                form={props.form}
                defaultPackageId={defaultPackageId}
                useDefaultColumns
                setDefaultPackageId={setDefaultPackageId}
            />
            <FormBlock>
                {leverActive && (
                    <>
                        <Heading.H3 style={{ marginBottom: '10px !important' }}>
                            <FormattedMessage id="a7880.Lever.WebhookAddress" defaultMessage="Webhook Address:" />
                        </Heading.H3>
                        {window.matchMedia('(max-width: 1024px)').matches ? (
                            <>
                                <Heading.H4 style={{ margin: '0px !important' }}>
                                    {`${subdomain}.certn.co/lever/teams/${teamId}/webhooks/`}
                                </Heading.H4>
                                <Button title="Click to Copy" onClick={copyToClipboard} type="primary">
                                    <FormattedMessage
                                        id="a7880.Lever.CopyToClipboard"
                                        defaultMessage="Copy to Clipboard"
                                    />
                                </Button>
                                <Heading.H4 style={{ marginTop: '10px' }}>
                                    <FormattedMessage
                                        id="a7880.Lever.CopyInstructionsMobile"
                                        defaultMessage="Copy the link and give it to Lever when enabling integration between Lever and Certn."
                                    />
                                </Heading.H4>
                            </>
                        ) : (
                            <>
                                <Button
                                    title={intl.formatMessage({
                                        id: 'a7880.Lever.CopyButton',
                                        defaultMessage: 'Click to Copy',
                                    })}
                                    onClick={copyToClipboard}
                                >
                                    {`${subdomain}.certn.co/lever/teams/${teamId}/webhooks/`}
                                </Button>
                                <Heading.H4 style={{ marginTop: '10px' }}>
                                    <FormattedMessage
                                        id="a7880.Lever.CopyInstructions"
                                        defaultMessage="Click the link to copy it to the clipboard. Give it to Lever when enabling integration between Lever and Certn."
                                    />
                                </Heading.H4>
                            </>
                        )}
                    </>
                )}
                <ToggleIntegrationButton canEdit={canEdit} loading={loading} active={leverActive} />
                <br />
            </FormBlock>
        </AntdForm>
    );
};

export default AntdForm.create()(Lever);
