// Libraries
import React from 'react';
import { InfoCircleOutlined, UploadOutlined } from '@ant-design/icons';
import { Select, Row, Col, Upload, Tooltip } from 'antd';
import { FormattedMessage } from 'react-intl';

// Components
import Button from 'certn-ui/Button';
import Title from 'certn-ui/Title';
import Text from 'certn-ui/Text';
import { Box } from 'certn-ui/Layout';
import { AppearRight } from 'certn-ui/Animate';
import { TrackNavigation } from 'views/welcome/components';
import { DocumentsUploadLayout } from './DocumentsUploadLayout';

const DocumentSelection = (value, title) => (
    <Select.Option key={value} value={value}>
        {title}
    </Select.Option>
);

const PageOne = ({
    handleSubmit,
    s3GetUploadLink,
    handleDeleteDocument,
    s3UploadLink,
    s3UploadFields,
    fileList,
    fileProgress,
    handleChangeDocumentType,
    onUpload,
    handleProgress,
    isUploading,
    error,
    onboardingType,
    requiredDocuments,
    missingDocuments,
    nonRequiredDocuments,
    docsUploadedNotReq,
    docsUploadedReq,
}) => (
    <AppearRight>
        <Title>
            <FormattedMessage
                id="welcome.Documents.documentsTitle"
                defaultMessage="Do you have any files or documents you would like to add to your application package?"
            />
        </Title>
        <Row gutter={16} style={{ padding: '5px' }}>
            <DocumentsUploadLayout.Wrapper>
                <Upload
                    accept=".jpg,.jpeg,.png,.gif,.pdf,.doc,.docx,.ppt,.pptx,.odt,.xls,.xlsx,.txt"
                    action={s3UploadLink}
                    data={s3UploadFields}
                    beforeUpload={(file) => s3GetUploadLink(file)}
                    fileList={fileList}
                    onChange={onUpload}
                    onProgress={(event, eventFile) => handleProgress({ event, eventFile })}
                    showUploadList={false}
                    onRemove={(file) => handleDeleteDocument(file)}
                >
                    <Tooltip
                        title={
                            <FormattedMessage
                                id="welcome.Documents.documentsUploadSize"
                                defaultMessage="Maximum file upload size: 10mb"
                            />
                        }
                    >
                        <Button>
                            <UploadOutlined />{' '}
                            <FormattedMessage id="welcome.Documents.upload" defaultMessage="Upload" />
                            <InfoCircleOutlined />
                        </Button>
                    </Tooltip>
                </Upload>
                <Select defaultValue="OTHER" style={{ width: 200 }} onChange={handleChangeDocumentType}>
                    {nonRequiredDocuments.includes('RESUME') &&
                        DocumentSelection(
                            'RESUME',
                            <FormattedMessage id="welcome.Documents.resume" defaultMessage="Resume" />
                        )}
                    {nonRequiredDocuments.includes('COVER_LETTER') &&
                        DocumentSelection(
                            'COVER_LETTER',
                            <FormattedMessage id="welcome.Documents.coverLetter" defaultMessage="Cover Letter" />
                        )}
                    {nonRequiredDocuments.includes('GOVERNMENT_ID') &&
                        DocumentSelection(
                            'GOVERNMENT_ID',
                            <FormattedMessage id="welcome.Documents.governmentId" defaultMessage="Government Id" />
                        )}
                    {nonRequiredDocuments.includes('PROOF_OF_INCOME') &&
                        DocumentSelection(
                            'PROOF_OF_INCOME',
                            <FormattedMessage id="welcome.Documents.proofOfIncome" defaultMessage="Proof Of Income" />
                        )}
                    {nonRequiredDocuments.includes('VISA') &&
                        DocumentSelection(
                            'VISA',
                            <FormattedMessage id="welcome.Documents.visa" defaultMessage="Visa" />
                        )}
                    {nonRequiredDocuments.includes('PASSPORT') &&
                        DocumentSelection(
                            'PASSPORT',
                            <FormattedMessage id="welcome.Documents.passport" defaultMessage="Passport Photo" />
                        )}
                    {nonRequiredDocuments.includes('PROOF_OF_ADDRESS') &&
                        DocumentSelection(
                            'PROOF_OF_ADDRESS',
                            <FormattedMessage id="welcome.Documents.proofOfAddress" defaultMessage="Proof Of Address" />
                        )}
                    <Select.Option value="OTHER">
                        <FormattedMessage id="common.other" defaultMessage="Other" />
                    </Select.Option>
                </Select>
            </DocumentsUploadLayout.Wrapper>
        </Row>
        <Row gutter={16}>
            <DocumentsUploadLayout
                s3UploadLink={s3UploadLink}
                s3UploadFields={s3UploadFields}
                s3GetUploadLink={s3GetUploadLink}
                fileList={fileList}
                fileProgress={fileProgress}
                onUpload={onUpload}
                handleProgress={handleProgress}
                handleDeleteDocument={handleDeleteDocument}
                complete={missingDocuments.length === 0}
                error={error}
                missingDocuments={missingDocuments}
                requiredDocuments={requiredDocuments}
                docsUploadedNotReq={docsUploadedNotReq}
                docsUploadedReq={docsUploadedReq}
            />
        </Row>
        {requiredDocuments.length > 0 && (
            <Text align="center" color="certnGray600" style={{ marginTop: '20px' }}>
                <FormattedMessage
                    id="welcome.Documents.requiredDocuments"
                    defaultMessage="The manager has required that you upload specific documents, these are found in the dotted box above."
                />
            </Text>
        )}
        <Row gutter={16}>
            <Col sm={24} lg={{ span: 20, offset: 2 }}>
                <Box size="m">
                    {onboardingType === 'PM' ? (
                        <Text align="center" color="certnGray600">
                            <br />
                            <FormattedMessage
                                id="welcome.Documents.otherDocuments"
                                defaultMessage="Proof of identity or statements of income are great additions to an application, but if there are other documents that you would like to share, this is the place."
                            />
                        </Text>
                    ) : (
                        <Text align="center" color="certnGray600">
                            <br />
                            <FormattedMessage
                                id="welcome.Documents.coverLetterResume"
                                defaultMessage="This is the place to attach a cover letter or resume. If you have any other files that would also enhance your application, you should add them here."
                            />
                        </Text>
                    )}
                </Box>
            </Col>
        </Row>
        <TrackNavigation loading={isUploading} handleSubmit={handleSubmit} />
    </AppearRight>
);

export default PageOne;
