import Title from 'certn-ui/Title';
import { FormattedMessage } from 'react-intl';
import { TrackNavigation } from 'views/welcome/components';
import { Team } from 'types/types';
import { ReactComponent as OneIDFailure } from 'images/oneID_assets/oneid-failure.svg';
import { Text } from '@certn/ui';

interface Props {
    team: Team;
    handleSubmit: () => void;
}

const RightToWorkFailureForm: React.FC<Props> = (props: Props) => (
    <div style={{ width: '755px', margin: 'auto' }}>
        <Title>
            <FormattedMessage
                id="welcome.RightToWorkFailure.title"
                defaultMessage="We were unable to complete a digital Right to Work check"
            />
            <br />
            <Text align="center" style={{ marginTop: '20px', fontSize: '1rem' }}>
                <FormattedMessage
                    id="welcome.RightToWorkFailure.description"
                    defaultMessage="{teamName} will reach out if they'd like to manually verify your Right to Work. In the meantime, please complete the remainder of the background check."
                    values={{
                        teamName: props.team,
                    }}
                />
            </Text>
        </Title>
        <OneIDFailure style={{ marginBottom: '80px', marginTop: '63px' }} />
        <TrackNavigation handleSubmit={props.handleSubmit} />
    </div>
);

export default RightToWorkFailureForm;
