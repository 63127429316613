// Libraries
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

// Components
import SummaryItem from './SummaryItem';
import SummaryHeader from './SummaryHeader';
import { FlexRow, SummarySection, SummaryContainer } from './SummaryStyles';

const Passport = ({ passport = {}, goToTrack }) => (
    <SummaryContainer>
        <SummaryHeader
            title={<FormattedMessage id="welcome.general.passport" defaultMessage="Passport" />}
            onClick={goToTrack}
        />
        <SummarySection>
            <FlexRow>
                <SummaryItem
                    title={<FormattedMessage id="welcome.general.passportNumber" defaultMessage="ID Number" />}
                    text={passport.document_number}
                />
                <SummaryItem
                    title={
                        <FormattedMessage
                            id="welcome.general.passportExpiration"
                            defaultMessage="Passport Expiration"
                        />
                    }
                    text={passport.document_expiration}
                />
                <SummaryItem
                    title={
                        <FormattedMessage
                            id="welcome.general.passportIssuingCountry"
                            defaultMessage="Country of Issue"
                        />
                    }
                    text={passport.document_issuing_country}
                />
            </FlexRow>
        </SummarySection>
    </SummaryContainer>
);

export default injectIntl(Passport);
