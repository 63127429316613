import { DeleteOutlined, LockOutlined } from '@ant-design/icons';
import { useUserTeam } from 'hooks/useUserTeam';
import { useCallback, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { CheckConfig, Country } from 'types';
import { formatCurrency } from 'utils/formatter';
import { ScreenApplicantCheckSettingsContext } from 'views/manager/features/ScreenApplicant/contexts';
import { removeCountry } from 'views/manager/features/ScreenApplicant/ScreenApplicantActions';
import { CurrencyWrapper, LineItem, PriceItemWrapper } from '../../styled';

type RequestedCountryProps = {
    check: CheckConfig;
    country: Country;
};

export const RequestedCountry: React.FC<RequestedCountryProps> = ({ check, country }) => {
    const dispatch = useDispatch();
    const { showPricing } = useContext(ScreenApplicantCheckSettingsContext);
    const { isPurchased } = check;
    const { team } = useUserTeam();
    const currency = team?.billing_plan?.currency_type;
    const isDisabled = !!isPurchased;

    const handleDelete = useCallback(() => {
        dispatch(removeCountry(country.country_code));
    }, [dispatch, country.country_code]);

    if (!currency) {
        return null;
    }

    const countryPrice = country[currency.toLowerCase() as 'cad' | 'usd'];

    return (
        <LineItem disabled={isDisabled}>
            <span>{`- ${country.country_name}`}</span>
            <CurrencyWrapper>
                {showPricing && <PriceItemWrapper>{formatCurrency(countryPrice, currency)}</PriceItemWrapper>}
                {isDisabled ? (
                    <LockOutlined style={{ cursor: 'not-allowed' }} />
                ) : (
                    <DeleteOutlined onClick={handleDelete} />
                )}
            </CurrencyWrapper>
        </LineItem>
    );
};
