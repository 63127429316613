// Libraries
import React from 'react';
import styled from 'styled-components/macro';
import { ButtonLine } from 'certn-ui/Button';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';

import { CERTN_IMAGES_S3 } from 'base/BaseConstants';

const Wrapper = styled.div`
    width: 100%;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
`;
const PageTitleBox = styled.div`
    max-width: 660px;
    margin: 10px auto;
    margin-bottom: 50px;
    text-align: center;
`;
const PageTitle = styled.span`
    font-size: 20px;
    font-weight: 300;
`;
const Title = styled.span`
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 20px;
`;
const StepInstructions = styled.span`
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 15px;
    color: ${({ red, theme }) => (red ? theme.color.certnRed800 : 'inherit')};
`;
const StepImage = styled.img`
    margin: 0 auto;
    margin-bottom: 50px;
    max-width: 100%;
`;

function imageUrl(filename) {
    return `${CERTN_IMAGES_S3}/${filename}`;
}

const MVR = () => (
    <Wrapper>
        <PageTitleBox>
            <PageTitle>
                <FormattedMessage
                    id="30fc0.MVR.pageTitle"
                    defaultMessage="To complete your driving record verification, you will need to proceed to an external website. Please review the following instructions carefully before proceeding to ensure your verification is processed correctly."
                />
            </PageTitle>
        </PageTitleBox>
        <Title>
            <FormattedMessage id="30fc0.MVR.title" defaultMessage="Submitting Motor Vehicle Records" />{' '}
        </Title>

        <StepInstructions>
            <strong>
                <FormattedMessage id="30fc0.MVR.pageOneTitle" defaultMessage="Page 1:" />
            </strong>
        </StepInstructions>
        <StepInstructions>
            <FormattedMessage id="30fc0.MVR.pageOneStepOne" defaultMessage="a) Fill in the required fields" />
        </StepInstructions>
        <StepInstructions>
            <FormattedMessage id="30fc0.MVR.pageOneStepTwo" defaultMessage="b) Check the checkbox" />
        </StepInstructions>
        <StepInstructions>
            <FormattedMessage id="30fc0.MVR.pageOneStepThree" defaultMessage="c) Check the reCAPTCHA box" />
        </StepInstructions>
        <StepInstructions>
            <FormattedMessage id="30fc0.MVR.pageOneStepFour" defaultMessage="d) Click 'continue' to proceed" />
        </StepInstructions>

        <StepImage src={imageUrl('mvr-step1.png')} alt="Step 1" />

        <StepInstructions>
            <strong>
                <FormattedMessage id="30fc0.MVR.pageTwoTitle" defaultMessage="Page 2:" />
            </strong>
        </StepInstructions>
        <StepInstructions>
            <FormattedMessage id="30fc0.MVR.pageTwoStepOne" defaultMessage="a) Fill in the required fields" />
        </StepInstructions>
        <StepInstructions>
            <FormattedMessage id="30fc0.MVR.pageTwoStepTwo" defaultMessage="b) Click 'continue' to proceed" />
        </StepInstructions>

        <StepImage src={imageUrl('mvr-step2.png')} alt="Step 2" />

        <StepInstructions>
            <strong>
                <FormattedMessage id="30fc0.MVR.pageThreeTitle" defaultMessage="Page 3:" />
            </strong>
        </StepInstructions>
        <StepInstructions>
            <FormattedMessage id="30fc0.MVR.pageThreeStepOne" defaultMessage="a) Check all the checkboxes" />
        </StepInstructions>
        <StepInstructions>
            <FormattedMessage id="30fc0.MVR.pageThreeStepTwo" defaultMessage="b) Select ’20 years’ from the dropdown" />
        </StepInstructions>
        <StepInstructions>
            <FormattedMessage
                id="30fc0.MVR.pageThreeStepThree"
                defaultMessage="c) Enter your personal email into the 'Email' field"
            />
        </StepInstructions>
        <StepInstructions>
            <FormattedMessage
                id="30fc0.MVR.pageThreeStepFour"
                defaultMessage="d) Enter your personal email into 'Confirm email' field"
            />
        </StepInstructions>
        <StepInstructions>
            <FormattedMessage
                id="30fc0.MVR.pageThreeStepFive"
                defaultMessage="e) Enter documents@certn.co into the second 'Email' field"
            />
        </StepInstructions>
        <StepInstructions>
            <FormattedMessage
                id="30fc0.MVR.pageThreeStepSix"
                defaultMessage="f) Enter documents@certn.co into the second 'Confirm email' field"
            />
        </StepInstructions>
        <StepInstructions>
            <FormattedMessage id="30fc0.MVR.pageThreeStepSeven" defaultMessage="g) Click 'submit' to proceed" />
        </StepInstructions>

        <StepInstructions red>
            <FormattedMessage id="30fc0.MVR.important" tagName="strong" defaultMessage="IMPORTANT" />
            <FormattedMessage
                id="30fc0.MVR.applicationMust"
                defaultMessage=": For your application to be processed correctly, you MUST"
            />
            <FormattedMessage
                id="30fc0.MVR.checkAllBoxes"
                defaultMessage=" check all the boxes and select ’20 years’ from the dropdown."
                tagName="strong"
            />
        </StepInstructions>
        <StepInstructions red>
            <FormattedMessage id="30fc0.MVR.important" tagName="strong" defaultMessage="IMPORTANT" />
            <FormattedMessage
                id="30fc0.MVR.applicationMust"
                defaultMessage=": For your application to be processed correctly, you MUST"
            />
            <FormattedMessage
                id="30fc0.MVR.hasSecondEmail"
                defaultMessage=" enter documents@certn.co as the second email."
                tagName="strong"
            />
        </StepInstructions>
        <StepImage src={imageUrl('mvr-step3.png')} alt="Step 3" />
        <StepInstructions>
            <strong>
                <FormattedMessage id="30fc0.MVR.pageFourTitle" defaultMessage="Page 4:" />
            </strong>
        </StepInstructions>
        <StepInstructions>
            <FormattedMessage
                id="30fc0.MVR.confirmation"
                defaultMessage="Confirm that the message says that documents have been sent to your personal email AND to documents@certn.co."
            />
        </StepInstructions>
        <StepImage src={imageUrl('mvr-step4.png')} alt="Step 4" />
        <ButtonLine style={{ marginBottom: '80px' }}>
            <Button type="primary" style={{ width: '200px', height: '40px' }}>
                <a href="https://onlinebusiness.icbc.com/clio/" target="_blank" rel="noopener noreferrer">
                    <FormattedMessage id="30fc0.MVR.proceed" defaultMessage="Proceed to ICBC" />
                </a>
            </Button>
        </ButtonLine>
    </Wrapper>
);

export default MVR;
