// Libraries
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import { formatDate } from 'modules/Format';

// Components
import SummaryItem from './SummaryItem';
import SummaryHeader from './SummaryHeader';
import {
    FlexRow,
    SummarySection,
    SummaryContainer,
    SummarySubHeader,
    LineBreak,
    SummarySubSection,
} from './SummaryStyles';

const History = ({ information, goToTrack }) => {
    const { convictions, evictions, bankruptcies } = information;
    const rentRefusals = information.rent_refusals;

    return (
        <SummaryContainer>
            <SummaryHeader
                title={<FormattedMessage id="welcome.general.history" defaultMessage="History" />}
                onClick={goToTrack}
            />
            {convictions && (
                <SummarySection>
                    <SummarySubSection>
                        <SummarySubHeader>
                            <FormattedMessage id="welcome.general.convictions" defaultMessage="Convictions" />
                        </SummarySubHeader>
                        {convictions.length === 0 && (
                            <SummaryItem
                                empty
                                title={
                                    <FormattedMessage
                                        id="welcome.general.noConvictionsListed"
                                        defaultMessage="You have not listed any convictions"
                                    />
                                }
                            />
                        )}
                        {convictions.map((conviction, i) => (
                            <React.Fragment key={i}>
                                <FlexRow>
                                    <SummaryItem
                                        title={
                                            <FormattedMessage
                                                id="welcome.general.courtLocation"
                                                defaultMessage="Court Location"
                                            />
                                        }
                                        text={conviction.court_location}
                                    />
                                    <SummaryItem
                                        title={
                                            <FormattedMessage
                                                id="welcome.general.dateOfSentence"
                                                defaultMessage="Date Of Sentence"
                                            />
                                        }
                                        text={formatDate(conviction.date_of_sentence)}
                                    />
                                    <SummaryItem
                                        title={
                                            <FormattedMessage id="welcome.Summary.offense" defaultMessage="Offense" />
                                        }
                                        text={conviction.offense}
                                    />
                                    <SummaryItem
                                        title={
                                            <FormattedMessage id="common.description" defaultMessage="Description" />
                                        }
                                        text={conviction.description}
                                    />
                                </FlexRow>
                                {convictions.length > 1 && i + 1 < convictions.length && <LineBreak />}
                            </React.Fragment>
                        ))}
                    </SummarySubSection>
                </SummarySection>
            )}
            {evictions && (
                <>
                    <LineBreak />
                    <SummarySection>
                        <SummarySubSection>
                            <SummarySubHeader>
                                <FormattedMessage id="welcome.Summary.evictions" defaultMessage="Evictions" />
                            </SummarySubHeader>
                            {evictions.length === 0 && (
                                <SummaryItem
                                    empty
                                    title={
                                        <FormattedMessage
                                            id="welcome.Summary.noEvictionsListed"
                                            defaultMessage="You have not listed any evictions"
                                        />
                                    }
                                />
                            )}
                            {evictions.map((eviction, i) => (
                                <>
                                    <FlexRow>
                                        <SummaryItem
                                            title={
                                                <FormattedMessage
                                                    id="welcome.Summary.dateOfEviction"
                                                    defaultMessage="Date Of Eviction"
                                                />
                                            }
                                            text={formatDate(eviction.date_of_eviction)}
                                        />
                                        <SummaryItem
                                            title={
                                                <FormattedMessage
                                                    id="common.description"
                                                    defaultMessage="Description"
                                                />
                                            }
                                            text={eviction.description}
                                        />
                                        <SummaryItem
                                            title={
                                                <FormattedMessage
                                                    id="welcome.general.address"
                                                    defaultMessage="Address"
                                                />
                                            }
                                            text={eviction.address.full_address}
                                        />
                                    </FlexRow>
                                    {i + 1 < evictions.length && <LineBreak />}
                                </>
                            ))}
                        </SummarySubSection>
                    </SummarySection>
                </>
            )}
            {bankruptcies && (
                <>
                    <LineBreak />
                    <SummarySection>
                        <SummarySubSection>
                            <SummarySubHeader>
                                <FormattedMessage id="welcome.Summary.bankruptcies" defaultMessage="Bankruptcies" />
                            </SummarySubHeader>
                            {bankruptcies.length === 0 && (
                                <SummaryItem
                                    empty
                                    title={
                                        <FormattedMessage
                                            id="welcome.Summary.noBankruptciesListed"
                                            defaultMessage="You have not listed any bankruptcies"
                                        />
                                    }
                                />
                            )}
                            {bankruptcies.map((bankruptcy, i) => (
                                <>
                                    <FlexRow>
                                        <SummaryItem
                                            title={
                                                <FormattedMessage
                                                    id="welcome.Summary.dateFiled"
                                                    defaultMessage="Date Filed"
                                                />
                                            }
                                            text={formatDate(bankruptcy.date_filed)}
                                        />
                                        <SummaryItem
                                            title={
                                                <FormattedMessage
                                                    id="common.description"
                                                    defaultMessage="Description"
                                                />
                                            }
                                            text={bankruptcy.description}
                                        />
                                        <SummaryItem
                                            title={
                                                <FormattedMessage
                                                    id="welcome.Summary.creditor"
                                                    defaultMessage="Creditor"
                                                />
                                            }
                                            text={bankruptcy.creditor}
                                        />
                                        <SummaryItem
                                            title={
                                                <FormattedMessage id="welcome.Summary.amount" defaultMessage="Amount" />
                                            }
                                            text={bankruptcy.amount}
                                        />
                                    </FlexRow>
                                    {i + 1 < bankruptcies.length && <LineBreak />}
                                </>
                            ))}
                        </SummarySubSection>
                    </SummarySection>
                </>
            )}
            {rentRefusals && (
                <>
                    <LineBreak />
                    <SummarySection>
                        <SummarySubSection>
                            <SummarySubHeader>
                                <FormattedMessage id="welcome.Summary.rentRefusals" defaultMessage="Rent Refusals" />
                            </SummarySubHeader>
                            {rentRefusals.length === 0 && (
                                <SummaryItem
                                    empty
                                    title={
                                        <FormattedMessage
                                            id="welcome.Summary.noRentRefusalsListed"
                                            defaultMessage="You have not listed any rent refusals"
                                        />
                                    }
                                />
                            )}
                            {rentRefusals.map((refusal, i) => (
                                <>
                                    <FlexRow>
                                        <SummaryItem
                                            title={
                                                <FormattedMessage
                                                    id="welcome.Summary.dateOfRefusal"
                                                    defaultMessage="Date Of Refusal"
                                                />
                                            }
                                            text={formatDate(refusal.date_of_refusal)}
                                        />
                                        <SummaryItem
                                            title={
                                                <FormattedMessage
                                                    id="common.description"
                                                    defaultMessage="Description"
                                                />
                                            }
                                            text={refusal.description}
                                        />
                                        <SummaryItem
                                            title={
                                                <FormattedMessage
                                                    id="welcome.general.address"
                                                    defaultMessage="Address"
                                                />
                                            }
                                            text={refusal.address.full_address}
                                        />
                                    </FlexRow>
                                    {i + 1 < rentRefusals.length && <LineBreak />}
                                </>
                            ))}
                        </SummarySubSection>
                    </SummarySection>
                </>
            )}
        </SummaryContainer>
    );
};

export default injectIntl(History);
