// Libraries
import styled from 'styled-components/macro';

type LayoutProps = {
    hideOverflow?: boolean;
};

const Layout: React.FC<LayoutProps> = ({ children, hideOverflow }) => (
    <LayoutContainer hideOverflow={hideOverflow}>{children}</LayoutContainer>
);
export default Layout;

const LayoutContainer = styled.div<{ hideOverflow?: boolean }>`
    display: flex;
    width: 100%;
    flex-direction: column;
    background: ${({ theme }) => theme.color.certnWhite};
    overflow: ${(props) => (props.hideOverflow ? 'hidden' : 'visible')};
`;

export const LayoutBody = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 0 0%;
    margin: 0 auto 60px auto;
    width: 98%;
    max-width: 100%;
    padding: 60px 15px 0 15px;
    box-sizing: border-box;
    @media (max-width: ${(props) => props.theme.width.lrg}) {
        padding: 100px 10px 0 10px !important;
    }
    @media (max-width: 720px) {
        padding-top: 100px !important;
    }
`;

export const BaseContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    background: ${(props) => props.theme.color.certnWhite};
    /* color: ${(props) => props.theme.color.secondary}; */
    padding-bottom: 50px;
    box-sizing: border-box;
    /* font-family: 'LotaGrotesque-Regular', 'adelle-sans', sans-serif !important; */
    @media (max-width: 720px) {
        padding-bottom: 0 !important;
    }
`;

export const Box = styled.div<{ size?: string; center?: boolean; border?: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: ${(props) => (props.center ? 'center' : 'flex-start')};
    flex: 1 0 0%;
    justify-content: ${(props) => (props.center ? 'center' : 'flex-start')};
    margin: 0 auto;
    width: ${(props) => (props.size ? props.theme.width[props.size] : '100%')};
    max-width: 100%;
    border: ${(props) => (props.border ? `3px solid ${props.theme.color.certnGray100}` : 'none')};
    border-radius: 3px;
`;

export const CenterLine = styled.div<{ multiple?: boolean }>`
    width: 100%;
    display: flex;
    justify-content: ${(props) => (props.multiple ? 'space-around' : 'center')};
    align-items: center;
    padding-bottom: 10px;
    box-sizing: border-box;
`;

export const HalfBoxDesktop = styled.div`
    width: 60%;
    margin: 0 auto;
    @media (max-width: ${(props) => props.theme.width.med}) {
        width: 100%;
    }
`;
