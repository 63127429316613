// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { intl } from 'components/GlobalProvider';

// UI Components
import Loader from 'certn-ui/Loader';
import Titlebar from 'certn-ui/Titlebar';
import { BackButton } from 'certn-ui/Button';
import { message, Result } from 'antd';

// External Libraries
import queryString from 'query-string';
import { FormattedMessage } from 'react-intl';

// Actions & Selectors
import { createPosting, fetchPosting } from 'views/manager/views/hr/views/postings/PostingsActions';
import { getIsFetching, getError, getNewPosting } from 'views/manager/views/hr/views/postings/PostingsSelectors';
import { getSettings } from 'views/manager/views/settings/SettingsSelectors';
import { getEmail, getUser } from 'base/BaseSelectors';

// Other Components
import { PostingsForm } from 'views/manager/views/hr/views/postings/components';

const mapStateToProps = (state) => ({
    isFetching: getIsFetching(state),
    settings: getSettings(state),
    error: getError(state),
    email: getEmail(state),
    newPosting: getNewPosting(state),
    canCreateAndEditPackages: getUser(state).can_create_and_edit_packages,
});
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            createPosting,
            fetchPosting,
        },
        dispatch
    );

const propTypes = {
    // Redux Actions
    createPosting: PropTypes.func.isRequired,
    fetchPosting: PropTypes.func.isRequired,
    // Redux State
    isFetching: PropTypes.bool.isRequired,
    settings: PropTypes.object.isRequired,
    error: PropTypes.object,
    email: PropTypes.string,
};
const defaultProps = {
    error: undefined,
    email: undefined,
};

class PostingsNew extends React.Component {
    constructor(props) {
        super(props);
        const { location } = this.props;
        const relist = location?.search ? queryString.parse(location.search)?.posting_id : null;

        if (relist) {
            this.props.fetchPosting(relist, true);
        }
        this.state = {
            relist,
        };
    }

    onSubmitPosting = (postingData) => {
        Promise.resolve(this.props.createPosting(postingData))
            .then(() => {
                this.props.history.push('/hr/packages?posting_created=true');
            })
            .catch(() => {
                message.error(
                    intl.formatMessage({
                        id: '1cdba.PostingNew.error1',
                        defaultMessage: 'There are errors with at least one of your form entries.',
                    })
                );
            });
    };

    renderNewPackageForm = () => {
        const { relist } = this.state;
        const { canCreateAndEditPackages, newPosting, email } = this.props;
        const defaultValues = {
            notification_list: email ? [{ email }] : [],
        };

        if (canCreateAndEditPackages) {
            return (
                <>
                    <Titlebar
                        title={<FormattedMessage id="1cdba.PostingNew.title1" defaultMessage="Create a New Package" />}
                    />
                    {this.props.isFetching ? (
                        <Loader />
                    ) : (
                        <PostingsForm
                            postingsNew
                            posting={relist && newPosting ? newPosting : defaultValues}
                            onSubmit={this.onSubmitPosting}
                        />
                    )}
                </>
            );
        }

        return (
            <Result
                status="warning"
                title={intl.formatMessage({
                    id: 'common.oops',
                    defaultMessage: 'Oops!',
                })}
                subTitle={intl.formatMessage({
                    id: '1ec29.PostingList.noPermissionToCreatePackage',
                    defaultMessage:
                        "You do not have permission to create packages. Please contact your team's administrator if you need this ability.",
                })}
            />
        );
    };

    render() {
        return (
            <>
                <BackButton
                    label={<FormattedMessage id="common.packages" defaultMessage="Packages" />}
                    onClick={() => this.props.history.push('/hr/packages')}
                />
                {this.renderNewPackageForm()}
            </>
        );
    }
}

PostingsNew.propTypes = propTypes;
PostingsNew.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(PostingsNew);
