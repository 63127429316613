import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { Select } from 'antd';

// Actions & Selectors
import { getLanguage } from 'base/BaseSelectors';
import { setLocale } from 'base/BaseActions';

import { LANGUAGE_EN, LANGUAGE_ES, LANGUAGE_FR } from 'utils/constants';

const Wrapper = styled.div`
    flex-shrink: 0;
    min-width: 90px;
`;

const StyledSelect = styled(Select).withConfig({
    // exclude "borderless" prop to remove React warning
    shouldForwardProp: (prop) => prop !== 'borderless',
})`
    width: 100%;
    ${({ borderless }) => borderless && `* { border: none !important;`}
`;

const LanguageSelect = ({ onChange, noBorders, disableSpanish, disableSetLocale }) => {
    // disableSetLocale - disable setting 'locale' which would re-render app text into the selected language

    const dispatch = useDispatch();
    const language = useSelector(getLanguage);
    const handleLanguageChange = (value) => {
        if (onChange) {
            onChange(value);
        }
        if (!disableSetLocale) {
            dispatch(setLocale(value));
        }
    };

    return (
        <Wrapper>
            <StyledSelect
                borderless={noBorders}
                defaultValue={language}
                onChange={handleLanguageChange}
                data-testid="language_select"
            >
                {/* These values are not translated  - they are always the same */}
                <Select.Option value={LANGUAGE_EN}>English</Select.Option>
                <Select.Option value={LANGUAGE_FR}>Français</Select.Option>
                {!disableSpanish && <Select.Option value={LANGUAGE_ES}>Español</Select.Option>}
            </StyledSelect>
        </Wrapper>
    );
};

LanguageSelect.propTypes = {
    onChange: PropTypes.func,
    noBorders: PropTypes.bool,
    disableSpanish: PropTypes.bool,
};

LanguageSelect.defaultProps = {
    onChange: undefined,
    noBorders: false,
    disableSpanish: false,
};

export default LanguageSelect;
