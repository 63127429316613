// Libraries
import React from 'react';
import styled from 'styled-components/macro';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get } from 'lodash';
import { Dropdown as AntDropdown, Menu, Modal, message } from 'antd';
import moment from 'moment';
import copy from 'copy-to-clipboard';
import { withRouter } from 'react-router-dom';
import { intl } from 'components/GlobalProvider';

// UI Components
import { ListCellText } from 'certn-ui/List';
import { ErrorAlertAPI, ErrorAlertCustom } from 'certn-ui/ErrorAlert';
import {
    ItemDownloadPDFReport,
    ItemDownloadCSVReport,
    ItemDownloadConsentDocs,
    ItemRenewExpiredApplicants,
    ItemShowInfoPanel,
} from 'views/manager/views/hr/views/applications/components/StatusDropdown';

import { ItemAdverseAction } from 'views/manager/views/hr/views/applications/components/StatusDropdown/ItemAdverseAction';

// Actions & Selectors
import { getUser, getLaunchDarklyFlags } from 'base/BaseSelectors';
import {
    setApplicationStatus,
    deleteApplication,
    sendReminder,
    requestPackages,
    fetchApplications,
} from 'views/manager/views/pm/views/applications/ApplicationsActions';

import Constants from 'utils/constants';

const mapStateToProps = (state) => ({
    user: getUser(state),
    isWebFeatureEnableNewStatusSystem: getLaunchDarklyFlags(state)?.webFeatureEnableNewStatusSystem,
    isWebFeatureEnableAdverseAction: getLaunchDarklyFlags(state)?.webFeatureEnableAdverseAction,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setApplicationStatus,
            deleteApplication,
            sendReminder,
            requestPackages,
            fetchApplications,
        },
        dispatch
    );

const Dropdown = styled(AntDropdown)`
    margin: 8px 0px;
`;

class StatusDropdown extends React.Component {
    showConfirmDelete = (applicantId, email) => {
        const { report, history } = this.props;
        const content = email
            ? `You will not be able to retrieve the application for ${email} after deletion.`
            : 'You will not be able to retrieve this application after deletion.';
        Modal.confirm({
            title: 'Are you sure you want to delete this application?',
            content,
            okType: 'danger',
            onOk: () =>
                this.props
                    .deleteApplication(applicantId)
                    .then(() => {
                        message.success('Application deleted');
                        if (report) history.push(`/pm/applications`);
                    })
                    .catch(() => ErrorAlertCustom({ title: 'Error deleting application' })),
            onCancel() {},
        });
    };

    showConfirmReminder = (applicantId, email) => {
        const { report, history } = this.props;
        const content = email
            ? `Send a reminder to ${email}? You can now do this once every 24 hours.`
            : 'Send a reminder email? You can now do this once every 24 hours.';
        Modal.confirm({
            title: 'Send Email Reminder',
            content,
            okType: 'primary',
            onOk: () =>
                this.props
                    .sendReminder(applicantId)
                    .then(() => {
                        message.success('Reminder email sent.');
                        if (report) history.push(`/pm/applications`);
                    })
                    .catch(() => ErrorAlertCustom({ title: 'Error sending email reminder' })),
            onCancel() {},
        });
    };

    handleReportUpgrade = (selected) => {
        this.props
            .requestPackages(this.props.applicant.id, selected)
            .then(this.props.fetchApplications)
            .catch((error) => ErrorAlertAPI(error));
    };

    handleStatusSuccess = (alert) => message.success(alert);

    copyToClipboard = (onboardingLink) =>
        copy(onboardingLink)
            ? message.success('Application link was copied to clipboard.')
            : message.error('Application link could not be copied.');

    renderActiveButtonText = (applicant) => {
        // LDFlag isWebFeatureEnableNewStatusSystem
        const { isWebFeatureEnableNewStatusSystem } = this.props;
        let isActionRequired;
        if (isWebFeatureEnableNewStatusSystem)
            isActionRequired = applicant?.order_status === Constants.orderStatus.ACTION_REQUIRED;
        if (!isWebFeatureEnableNewStatusSystem)
            isActionRequired = applicant?.report_status === Constants.reportStatus.ACTION_REQUIRED;

        if (isActionRequired) return 'Action Required';

        if (applicant?.can_upgrade)
            return intl.formatMessage({
                id: '004e5.StatusDropdown.upgradeApplication',
                defaultMessage: 'Upgrade Application',
            });
        return 'Waiting on Applicant';
    };

    getActiveButtonColor = (applicant, user) => {
        // LDFlag isWebFeatureEnableNewStatusSystem
        const { isWebFeatureEnableNewStatusSystem } = this.props;
        let isActionRequired;
        if (isWebFeatureEnableNewStatusSystem)
            isActionRequired = applicant?.order_status === Constants.orderStatus.ACTION_REQUIRED;
        if (!isWebFeatureEnableNewStatusSystem)
            isActionRequired = applicant?.report_status === Constants.reportStatus.ACTION_REQUIRED;

        if (isActionRequired) return 'certnRed500';
        if (user?.team?.payment_verified && applicant?.can_upgrade) return 'certnGreen700';
        return 'certnGray400';
    };

    render() {
        const {
            user,
            isReport,
            applicant,
            history,
            isWebFeatureEnableNewStatusSystem,
            isWebFeatureEnableAdverseAction,
            sideBarConfig,
            variant,
            isDashboard,
        } = this.props;
        const applicationStatus = get(applicant, ['adjudication_status']);
        const titleMap = {
            PROSPECT: 'Waitlist',
            OFFER_PENDING: 'Tenancy Pending',
            OFFER_ACCEPTED: 'Residents',
            ARCHIVED: 'Archive',
        };
        const resendTime = moment(applicant.resend_email_time).fromNow(true);
        const resendText = applicant.can_resend_email ? 'Send Reminder Email' : `Reminder Available in ${resendTime}`;
        const showCopyApplicationLink = applicant.onboarding_link && !user?.team?.applicant_invites_blocked;

        // LDFlag webFeatureEnableNewStatusSystem
        const orderStatus = applicant.order_status;
        const reportStatus = applicant.report_summary?.report_status;
        let active;
        if (isWebFeatureEnableNewStatusSystem) active = Constants.orderStatus.active.includes(orderStatus);
        if (!isWebFeatureEnableNewStatusSystem) active = Constants.reportStatus.active.includes(reportStatus);

        // LDFlag webFeatureEnableNewStatusSystem
        let isViewReport;
        if (isWebFeatureEnableNewStatusSystem) isViewReport = orderStatus === Constants.orderStatus.COMPLETE;
        if (!isWebFeatureEnableNewStatusSystem) isViewReport = reportStatus === Constants.reportStatus.COMPLETE;

        // LDFlag webFeatureEnableAdverseAction
        const showAdverseAction =
            isWebFeatureEnableAdverseAction &&
            applicant?.is_standalone_aa_available &&
            user.can_initiate_adverse_action;
        const isApplicantInAdverseActionProcess = isWebFeatureEnableAdverseAction && !!applicant?.adverse_action_status;

        const viewReport = intl.formatMessage({
            id: '004e5.StatusDropdown.viewReport',
            defaultMessage: 'View Report',
        });
        const viewPartialReport = intl.formatMessage({
            id: '004e5.StatusDropdown.viewPartialReport',
            defaultMessage: 'View Partial Report',
        });
        const moveToWaitlist = intl.formatMessage({
            id: '004e5.StatusDropdown.moveToWaitlist',
            defaultMessage: 'Move to Waitlist',
        });
        const moveToTenancyPending = intl.formatMessage({
            id: '004e5.StatusDropdown.moveToTenancyPending',
            defaultMessage: 'Move to Tenancy Pending',
        });
        const moveToResidents = intl.formatMessage({
            id: '004e5.StatusDropdown.moveToResidents',
            defaultMessage: 'Move to Residents',
        });
        const moveToArchive = intl.formatMessage({
            id: '004e5.StatusDropdown.moveToArchive',
            defaultMessage: 'Move to Archive',
        });
        const copyApplicationLink = intl.formatMessage({
            id: '004e5.StatusDropdown.copyApplicationLink',
            defaultMessage: 'Copy Application Link',
        });

        if (variant === 'more') {
            return (
                <>
                    <Dropdown
                        overlay={
                            <Menu>
                                {active && user.can_view_reports && (
                                    <>
                                        <ItemDownloadPDFReport applicantId={applicant.id} />
                                        <ItemDownloadCSVReport applicantId={applicant.id} />
                                        <ItemDownloadConsentDocs applicantId={applicant.id} />
                                    </>
                                )}
                                {sideBarConfig.shouldShowSideBar && <ItemShowInfoPanel sideBarConfig={sideBarConfig} />}
                            </Menu>
                        }
                        trigger={['click']}
                        placement="bottomCenter"
                    >
                        {this.props.children}
                    </Dropdown>
                </>
            );
        }

        return (
            <>
                <Dropdown
                    overlay={
                        <Menu>
                            {!isReport && active && user.can_view_reports && (
                                <>
                                    <Menu.Item key="0" onClick={() => history.push(`/pm/applications/${applicant.id}`)}>
                                        {isViewReport ? viewReport : viewPartialReport}
                                    </Menu.Item>
                                    <ItemDownloadPDFReport applicantId={applicant.id} />
                                    <ItemDownloadCSVReport applicantId={applicant.id} />
                                    <ItemDownloadConsentDocs applicantId={applicant.id} />
                                </>
                            )}
                            {applicationStatus !== 'NONE' && (
                                <Menu.Item key="1">
                                    <ListCellText
                                        clickable
                                        onClick={() =>
                                            this.props
                                                .setApplicationStatus(applicant.id, 'NONE')
                                                .then(() => this.handleStatusSuccess('Application moved to None'))
                                                .catch((error) => ErrorAlertAPI(error))
                                        }
                                    >
                                        Remove From {titleMap[applicationStatus]}
                                    </ListCellText>
                                </Menu.Item>
                            )}
                            {!isApplicantInAdverseActionProcess && applicationStatus !== 'PROSPECT' && (
                                <Menu.Item
                                    key="2"
                                    onClick={() =>
                                        this.props
                                            .setApplicationStatus(applicant.id, 'PROSPECT')
                                            .then(() => this.handleStatusSuccess('Application moved to Waitlist'))
                                            .catch((error) => ErrorAlertAPI(error))
                                    }
                                >
                                    {moveToWaitlist}
                                </Menu.Item>
                            )}
                            {!isApplicantInAdverseActionProcess && applicationStatus !== 'OFFER_PENDING' && (
                                <Menu.Item
                                    key="3"
                                    onClick={() =>
                                        this.props
                                            .setApplicationStatus(applicant.id, 'OFFER_PENDING')
                                            .then(() =>
                                                this.handleStatusSuccess('Application moved to Tenancy Pending')
                                            )
                                            .catch((error) => ErrorAlertAPI(error))
                                    }
                                >
                                    {moveToTenancyPending}
                                </Menu.Item>
                            )}
                            {!isApplicantInAdverseActionProcess && applicationStatus !== 'OFFER_ACCEPTED' && (
                                <Menu.Item
                                    key="4"
                                    onClick={() =>
                                        this.props
                                            .setApplicationStatus(applicant.id, 'OFFER_ACCEPTED')
                                            .then(() => this.handleStatusSuccess('Application moved to Residents'))
                                            .catch((error) => ErrorAlertAPI(error))
                                    }
                                >
                                    {moveToResidents}
                                </Menu.Item>
                            )}
                            {!isApplicantInAdverseActionProcess && applicationStatus !== 'ARCHIVED' && (
                                <Menu.Item
                                    key="5"
                                    onClick={() =>
                                        this.props
                                            .setApplicationStatus(applicant.id, 'ARCHIVED')
                                            .then(() => this.handleStatusSuccess('Application moved to Archive'))
                                            .catch((error) => ErrorAlertAPI(error))
                                    }
                                >
                                    {moveToArchive}
                                </Menu.Item>
                            )}
                            {(reportStatus === Constants.reportStatus.UNRESPONSIVE ||
                                orderStatus === Constants.orderStatus.CANCELLED ||
                                orderStatus === Constants.orderStatus.APPLICATION_EXPIRED) && (
                                <ItemRenewExpiredApplicants
                                    applicantIds={[applicant.id]}
                                    fetchApplicationsMethod={fetchApplications}
                                    successMessage="Application was sent again"
                                    errorMessage="Failed to renew application"
                                    listText="Resend Application"
                                />
                            )}
                            {applicant.application && applicationStatus === 'ARCHIVED' && (
                                <Menu.Item key="6">
                                    <ListCellText
                                        clickable
                                        critical
                                        onClick={() =>
                                            this.showConfirmDelete(
                                                applicant.id,
                                                applicant.application?.applicants[0]?.email
                                            )
                                        }
                                    >
                                        Delete Application
                                    </ListCellText>
                                </Menu.Item>
                            )}
                            {showCopyApplicationLink && (
                                <Menu.Item key="7">
                                    <ListCellText
                                        clickable
                                        onClick={() => this.copyToClipboard(applicant.onboarding_link)}
                                    >
                                        {copyApplicationLink}
                                    </ListCellText>
                                </Menu.Item>
                            )}
                            {showAdverseAction && (
                                <ItemAdverseAction
                                    applicantId={applicant.id}
                                    isReport={!!isReport}
                                    isDashboard={isDashboard}
                                />
                            )}
                            {!isApplicantInAdverseActionProcess && active && applicationStatus !== 'A' && (
                                <Menu.Item key="8" disabled={!user?.team?.payment_verified}>
                                    <ListCellText
                                        color={this.getActiveButtonColor(applicant, user)}
                                        onClick={() =>
                                            user?.team?.payment_verified && history.push('/pm/screen', { applicant })
                                        }
                                    >
                                        {this.renderActiveButtonText(applicant)}
                                    </ListCellText>
                                </Menu.Item>
                            )}
                            {applicant.application &&
                                applicant.status === 'Pending' &&
                                !applicant.has_active_automated_reminders && (
                                    <Menu.Item key="9" disabled={!applicant.can_resend_email}>
                                        <ListCellText
                                            clickable
                                            onClick={() =>
                                                applicant.can_resend_email
                                                    ? this.showConfirmReminder(
                                                          applicant.id,
                                                          applicant.applicant_account.email
                                                      )
                                                    : null
                                            }
                                        >
                                            {resendText}
                                        </ListCellText>
                                    </Menu.Item>
                                )}
                        </Menu>
                    }
                    trigger={['click']}
                    placement="bottomCenter"
                >
                    {this.props.children}
                </Dropdown>
            </>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StatusDropdown));
