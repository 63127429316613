// Libraries
import React from 'react';
import { FormattedMessage } from 'react-intl';

// UI Components
import Title from 'certn-ui/Title';
import { AppearRight } from 'certn-ui/Animate';
import MultiAddItemsForm from 'certn-form/MultiAddItemsForm';

class EvictionsForm extends React.Component {
    evictionsConfig = {
        name: 'evictions',
        buttonText: this.props.intl.formatMessage({
            id: 'welcome.History.EvictionsForm.buttonText',
            defaultMessage: 'Add Eviction',
        }),
        keys: [
            {
                id: 'description',
                name: this.props.intl.formatMessage({
                    id: 'common.description',
                    defaultMessage: 'Description',
                }),
                required: true,
            },
            {
                id: 'date_of_eviction',
                name: this.props.intl.formatMessage({
                    id: 'welcome.History.EvictionsForm.doe',
                    defaultMessage: 'Date Of Eviction',
                }),
                required: true,
                dateField: true,
                disableFutureDates: true,
            },
            {
                id: 'address',
                name: this.props.intl.formatMessage({
                    id: 'welcome.History.EvictionsForm.address',
                    defaultMessage: 'Eviction Address',
                }),
                required: true,
            },
        ],
    };

    render() {
        const { handleSubmit, evictions } = this.props;
        return (
            <AppearRight>
                <Title>
                    <FormattedMessage
                        id="welcome.History.EvictionsForm.title"
                        defaultMessage="You mentioned you were evicted previously, what happened?"
                    />
                </Title>
                <MultiAddItemsForm items={evictions} config={this.evictionsConfig} handleSubmit={handleSubmit} />
            </AppearRight>
        );
    }
}

export default EvictionsForm;
