import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form as AntdForm } from '@ant-design/compatible';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash';
import { Descriptions } from 'antd';

// certnd
import Form from 'certnd/Form';
import FormBlock from 'certnd/Form/FormBlock';
import Typography from 'certnd/Typography';
import { intl } from 'components/GlobalProvider';

// certn-ui
import Loader from 'certn-ui/Loader';
import { ErrorAlertAPI } from 'certn-ui/ErrorAlert';

// Actions & Selectors
import {
    generateFountainAPIKey,
    fetchSuperteamIntegrationPackages,
    fetchSuperteamAdmins,
    updateFountainIntegration,
} from 'views/manager/views/settings/SettingsActions';
import {
    getIntegrations,
    getIsFetching,
    getFountainPackages,
    getSuperTeamAdmins,
} from 'views/manager/views/settings/SettingsSelectors';
import { getTeam } from 'views/manager/views/admin/AdminSelectors';

import Packages from './packages/Packages';

const INTEGRATION_NAME = 'fountain';
const INTEGRATION_TYPE = INTEGRATION_NAME.toUpperCase();

const Fountain = (props) => {
    const dispatch = useDispatch();

    // Integrations Selectors
    const isFetching = useSelector(getIsFetching);
    const integrations = useSelector(getIntegrations);
    const packages = useSelector(getFountainPackages);

    // Auth Selectors
    const adminList = useSelector(getSuperTeamAdmins);

    const team = useSelector((state) => getTeam(state));
    const superteamId = team.superteam;

    const [loading, setLoading] = React.useState(false);

    const fountain = get(integrations, ['fountain']);
    const integrationExists = Boolean(get(fountain, ['id']));
    const isActive = get(fountain, ['is_active']);
    const apiKey = get(fountain, ['api_key']);

    useEffect(() => {
        dispatch(fetchSuperteamAdmins(superteamId));
        dispatch(fetchSuperteamIntegrationPackages({ superteamId, integrationType: INTEGRATION_TYPE }));
    }, [team, superteamId, dispatch]);

    useEffect(() => {
        if (!integrationExists || isFetching) return;
        const fountainOwnerValue = props.form.getFieldValue('fountainOwner');
        if (!fountainOwnerValue) {
            props.form.setFieldsValue({ fountainOwner: fountain.owner.id });
        }
    }, [isFetching, integrationExists, props.form, fountain]);

    const onClickGenerateAPIKey = (e) => {
        e.preventDefault();
        setLoading(true);

        dispatch(generateFountainAPIKey(superteamId, team.id))
            .then(() => {
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                ErrorAlertAPI(error);
            });
    };

    const onSelectOwner = (ownerId) => {
        dispatch(updateFountainIntegration({ superteam: superteamId, owner: ownerId }, team.id));
    };

    if (isFetching) return <Loader />;

    return (
        <AntdForm>
            {integrationExists && (
                <FormBlock style={{ paddingBottom: 0 }}>
                    <Descriptions column={1}>
                        <Descriptions.Item
                            label={intl.formatMessage({
                                id: 'integrations.Fountain.IntegrationStatus',
                                defaultMessage: 'Integration Status',
                            })}
                        >
                            {isActive ? (
                                <FormattedMessage id="integrations.Fountain.Connected" defaultMessage="Connected" />
                            ) : (
                                <FormattedMessage
                                    id="integrations.Fountain.NotConnected"
                                    defaultMessage="Not Connected"
                                />
                            )}
                        </Descriptions.Item>
                    </Descriptions>

                    <Form.Select
                        form={props.form}
                        fieldName="fountainOwner"
                        title={intl.formatMessage({
                            id: 'integrations.Shared.AdminOwner',
                            defaultMessage: 'Admin Owner',
                        })}
                        placeholder={intl.formatMessage({
                            id: 'integrations.Shared.AdminOwnerPlaceholder',
                            defaultMessage: 'Select Owner',
                        })}
                        selectOptions={(adminList || []).map((admin) => ({
                            key: admin.id,
                            value: admin.id,
                            title: admin.email,
                        }))}
                        options={{
                            rules: [
                                {
                                    required: true,
                                    message: intl.formatMessage({
                                        id: 'integrations.Shared.AdminOwnerError',
                                        defaultMessage: 'Please input an Owner who is an Admin on the Certn account',
                                    }),
                                },
                            ],
                        }}
                        description={intl.formatMessage({
                            id: 'integrations.Shared.AdminOwnerDesc',
                            defaultMessage:
                                'Please select an Admin to be the owner for this integration. All applications will be run under this user.',
                        })}
                        onSelect={onSelectOwner}
                    />
                </FormBlock>
            )}
            <FormBlock style={{ paddingTop: 0 }}>
                {apiKey && (
                    <>
                        <Descriptions column={1}>
                            <Descriptions.Item
                                label={intl.formatMessage({
                                    id: 'integrations.Fountain.APIKeyTitle',
                                    defaultMessage: 'API Key',
                                })}
                            >
                                {apiKey}
                            </Descriptions.Item>
                        </Descriptions>
                        <Form.Button loading={loading} onClick={onClickGenerateAPIKey}>
                            <FormattedMessage
                                id="integrations.Fountain.RegenerateAPIKey"
                                defaultMessage="Regenerate API Key"
                            />
                        </Form.Button>
                    </>
                )}
                {!apiKey && (
                    <>
                        <Typography.Heading.H3>
                            <FormattedMessage
                                id="integrations.Fountain.GenerateAPIKeyTitle"
                                defaultMessage="Generate API Key for Fountain Integration"
                            />
                        </Typography.Heading.H3>
                        <Form.Button loading={loading} onClick={onClickGenerateAPIKey}>
                            <FormattedMessage
                                id="integrations.Fountain.GenerateAPIKey"
                                defaultMessage="Generate API Key"
                            />
                        </Form.Button>
                    </>
                )}
            </FormBlock>
            {integrationExists && (
                <>
                    <Typography.Heading.H4>
                        <FormattedMessage
                            id="integrations.Fountain.PackageInstructions"
                            defaultMessage="Create packages for use with Fountain. These packages are shared across the organization."
                        />
                    </Typography.Heading.H4>
                    <Packages
                        integrationType={INTEGRATION_TYPE}
                        integrationName={INTEGRATION_NAME}
                        active={isActive}
                        form={props.form}
                        packages={packages}
                        useDefaultColumns={false}
                    />
                </>
            )}
        </AntdForm>
    );
};

export default AntdForm.create()(Fountain);
