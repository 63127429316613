import React, { useState } from 'react';
import { ComplianceDocumentClickwrap } from './ComplianceDocumentClickwrap/ComplianceDocumentClickwrap';
import { ConsentAndDisclosureTemplateProps } from '../types';
import { ErrorAlertAPI } from 'certn-ui/ErrorAlert';
import { patchInformation } from 'views/welcome/WelcomeActions';
import { useDispatch } from 'react-redux';
import config from 'utils/config';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';

type ComplianceDocumentsProps = {
    applicantId: string;
    templates: Array<ConsentAndDisclosureTemplateProps>;
    handleNextTrack: () => void;
};

export const ComplianceDocuments: React.FC<ComplianceDocumentsProps> = ({
    handleNextTrack,
    templates,
    applicantId,
}) => {
    const dispatch = useDispatch();
    const [currentTemplateIndex, setCurrentTemplateIndex] = useState(0);

    const handleNext = () => {
        if (currentTemplateIndex === templates.length - 1) {
            try {
                dispatch(
                    patchInformation({
                        disclosure_accepted: true,
                    })
                );
                handleNextTrack();
            } catch (error) {
                ErrorAlertAPI(error);
                console.error('Error signing IronClad Disclosures', error);
            }
            return;
        }
        setCurrentTemplateIndex(currentTemplateIndex + 1);
    };

    const handleReverse = () => {
        setCurrentTemplateIndex(currentTemplateIndex - 1);
    };

    const handleDemoSkip = () => {
        try {
            dispatch(
                patchInformation({
                    disclosure_accepted: true,
                    non_criminal_consent_given: true,
                    us_criminal_consent_given: true,
                    international_criminal_consent_given: true,
                    acic_disclosure_accepted: true,
                })
            );
            handleNextTrack();
        } catch (error) {
            console.error('Error patching information during consent and disclosure demo skip', error);
        }
    };

    if (templates.length === 0) {
        return null;
    }

    return (
        <>
            <ComplianceDocumentClickwrap
                complianceDocument={templates[currentTemplateIndex]}
                handleNext={handleNext}
                handleReverse={currentTemplateIndex === 0 ? undefined : handleReverse}
                signerId={applicantId}
            />
            {config.isNotProduction && (
                <Button name="demo-skip" size="large" type="dashed" onClick={handleDemoSkip}>
                    <FormattedMessage id="button.demoSkip" defaultMessage="Demo Skip" />
                </Button>
            )}
        </>
    );
};
