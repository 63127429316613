// Libraries
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchTeamProperties, fetchTeam } from 'views/guest/GuestActions';
import { getTeamProperties, getIsFetching, getTeam } from 'views/guest/GuestSelectors';
import { get } from 'lodash';
import { withRouter } from 'react-router-dom';
import { ErrorAlertAPI } from 'certn-ui/ErrorAlert';
import Loader from 'certn-ui/Loader';
import Titlebar from 'certn-ui/Titlebar';
import { CenterLine } from 'certn-ui/Layout';
import ApplySVG from 'images/svgs/svg-components/Apply';
import { injectIntl, FormattedMessage } from 'react-intl';

import SearchInput from '../components/SearchInput';
import List from '../components/List';
import styles from 'styles/styles';

const mapStateToProps = (state) => ({
    isFetching: getIsFetching(state),
    buildings: getTeamProperties(state),
    team: getTeam(state),
});
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchTeamProperties,
            fetchTeam,
        },
        dispatch
    );

const defaultProps = { buildings: [] };

class Buildings extends Component {
    constructor(props) {
        super(props);
        this.state = { search: '' };
        const teamId = get(this.props, ['match', 'params', 'teamId']);
        if (teamId) {
            this.props.fetchTeamProperties(teamId).catch((e) => ErrorAlertAPI(e));
            this.props.fetchTeam(teamId);
        }
    }

    setSearch = (searchTerm) => {
        this.setState({ search: searchTerm });
    };

    render() {
        const teamName = get(this.props, ['team', 'name']);
        const titleText = teamName ? (
            <FormattedMessage
                id="5dca6.Buildings.titleTextTeamName"
                defaultMessage="Find {teamName}'s available listings by building"
                values={{ teamName }}
            />
        ) : (
            <FormattedMessage
                id="5dca6.Buildings.titleTextFindListing"
                defaultMessage="Find available listings by building"
            />
        );
        return (
            <>
                <Titlebar
                    size="xlrg"
                    title={titleText}
                    subtitle={this.props.intl.formatMessage({
                        id: '5dca6.Buildings.subtitle',
                        defaultMessage:
                            "If you're ready to find your new home, browse and apply to all of our rental listings that are currently being advertised.",
                    })}
                    center
                />
                <CenterLine>
                    <ApplySVG color={styles.color.certnOrange400} />
                </CenterLine>
                <SearchInput
                    type="text"
                    onChange={this.setSearch}
                    placeholder={this.props.intl.formatMessage({
                        id: '5dca6.Buildings.searchInputPlaceholder',
                        defaultMessage: 'Search for buildings by address, name or city',
                    })}
                />
                <List.Container>
                    {this.props.isFetching ? (
                        <Loader />
                    ) : (
                        this.props.buildings.map((building, i) => {
                            const search = this.state.search.toLowerCase();
                            if (
                                search === '' ||
                                building.address.toLowerCase().includes(search) ||
                                building.city.toLowerCase().includes(search) ||
                                (building.building && building.building.toLowerCase().includes(search))
                            ) {
                                return (
                                    <List.Item
                                        key={i}
                                        noBorder={this.props.buildings.length < i + 1}
                                        onClick={() => this.props.history.push(`/browse/building/${building.url_code}`)}
                                    >
                                        <List.ItemText>{building.address}</List.ItemText>
                                        <List.ItemText light>{building.city}</List.ItemText>
                                    </List.Item>
                                );
                            }
                            return null;
                        })
                    )}
                </List.Container>
            </>
        );
    }
}

Buildings.defaultProps = defaultProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(Buildings)));
