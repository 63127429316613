// Libraries
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

// Components
import { InternationalFieldsForm } from './components';

// Modules
import withNavigation from 'views/welcome/modules/WithNavigation';

// Actions & Selectors
import { patchInformation } from 'views/welcome/WelcomeActions';
import { getInformation, getInternationalFields } from 'views/welcome/WelcomeSelectors';

InternationalFields.propTypes = {
    // Redux Actions:
    patchInformation: PropTypes.func.isRequired,
    patchWelcomeSession: PropTypes.func.isRequired,
    // Redux Store:
    isFetchingSilent: PropTypes.bool.isRequired,
    information: PropTypes.object.isRequired,
};

const WrappedInternationalFields = withNavigation(InternationalFields);

function InternationalFields(props) {
    const internationalFields = useSelector(getInternationalFields);
    const information = useSelector(getInformation);
    const dispatch = useDispatch();

    const handleSubmit = (e, validateFields) => {
        e.preventDefault();
        const { handleNextTrack } = props;
        validateFields((err, values) => {
            if (!err) {
                dispatch(patchInformation(values)).then(handleNextTrack());
            }
        });
    };

    return (
        <InternationalFieldsForm
            handleSubmit={handleSubmit}
            information={information}
            internationalFields={internationalFields}
        />
    );
}

export default WrappedInternationalFields;
