// Libraries
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// UI Components
import { ButtonLine } from 'certn-ui/Button';
import { Button, Modal, Alert, Form } from 'antd';
import EmailAddresses from 'components/EmailAddresses/EmailAddresses';

const propTypes = {
    error: PropTypes.object,
    listing: PropTypes.object,
    onOk: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    confirmLoading: PropTypes.bool.isRequired,
    mergeApplicants: PropTypes.func.isRequired,
};
const defaultProps = {
    listing: {
        property: {},
    },
    error: {},
};

const SendApplicationsForm = ({ onOk, onCancel, confirmLoading, mergeApplicants, listing, error }) => {
    const [form] = Form.useForm();
    const [submitted, setSubmitted] = useState(false);
    const [merge, setMerge] = useState(false);

    const doSubmit = (e) => {
        e.preventDefault();
        setSubmitted(true);
        setMerge(false);
        form.submit();
    };
    const handleSubmit = (values) => {
        const emails = values.emails.filter(Boolean);
        if (merge) {
            // TODO this might be legacy code - there is no /api/v2/applications/merge endpoint...
            mergeApplicants(emails, listing.id);
        } else {
            onOk(emails, listing.id);
        }
    };

    const handleMergeApplicants = (e) => {
        e.preventDefault();
        setSubmitted(true);
        setMerge(true);
        form.submit();
    };

    return (
        <Modal
            title={`Apply to ${listing.property.address}`}
            visible
            onOk={doSubmit}
            onCancel={onCancel}
            confirmLoading={confirmLoading}
            destroyOnClose
            footer={[
                <Button key="back" onClick={onCancel}>
                    Return
                </Button>,
                <Button key="submit" type="primary" htmlType="submit" loading={confirmLoading} onClick={doSubmit}>
                    Submit
                </Button>,
            ]}
        >
            <Form
                form={form}
                onFinish={handleSubmit}
                initialValues={{
                    emails: [''],
                }}
            >
                <EmailAddresses
                    instructions="Enter applicant(s) email below to send them an application by email."
                    submitAttempted={submitted}
                    fullWidth
                />
                {error && error.emails && (
                    <Alert
                        message="One or more of these emails have already been used to apply to this listing:"
                        type="warning"
                        showIcon
                        description={
                            <div>
                                <ul>
                                    {error.emails.map((email) => (
                                        <li key={email.email}>{email.email}</li>
                                    ))}
                                </ul>
                                <span>
                                    Would you like to reassign these applicants to a new application with the emails
                                    provided? This will remove these applicants from their current applications.
                                </span>
                                <ButtonLine>
                                    <Button onClick={handleMergeApplicants} loading={confirmLoading}>
                                        Yes, Reassign!
                                    </Button>
                                </ButtonLine>
                            </div>
                        }
                    />
                )}
            </Form>
        </Modal>
    );
};

SendApplicationsForm.propTypes = propTypes;
SendApplicationsForm.defaultProps = defaultProps;

export default SendApplicationsForm;
