import { useUserTeam } from 'hooks/useUserTeam';
import numeral from 'numeral';
import { formatCurrency } from 'utils/formatter';
import { Amount, Title, TotalCurrencyWrapper, TotalWrapper } from './styled';

type TotalSectionProps = {
    testId?: string;
    title: string;
    total: { local: number; usd: number };
    tax: number;
    bolded?: boolean;
    addAsterisk?: boolean;
};

export const TotalSection: React.FC<TotalSectionProps> = ({
    testId,
    title,
    total: { local, usd },
    tax,
    bolded = false,
    addAsterisk = false,
}) => {
    const { team } = useUserTeam();
    const currency = team?.billing_plan?.currency_type;

    return (
        <TotalWrapper>
            <Title data-testid={testId} uppercase bolded={bolded}>
                {title}
            </Title>
            <TotalCurrencyWrapper>
                <Amount bolded={bolded}>{formatCurrency(numeral(local).multiply(tax), currency, addAsterisk)}</Amount>
                {usd > 0 && (
                    <Amount bolded={bolded}>{formatCurrency(numeral(usd).multiply(tax), 'USD', addAsterisk)}</Amount>
                )}
            </TotalCurrencyWrapper>
        </TotalWrapper>
    );
};
