// Libraries
import React from 'react';
import styled from 'styled-components/macro';

const FrameText = styled.div`
    font-size: 12px;
    text-align: left;
    margin-top: 20px;
    color: ${(props) => props.theme.color.certnGray600};
`;

const FrameContainer = styled.div`
    margin: auto;
    height: ${(props) => props.height || '350px'};
    width: ${(props) => props.width || '700px'};
    overflow-y: scroll;
    position: relative;
    margin-top: ${(props) => props.marginTop || '20px'};
    margin-bottom: ${(props) => props.marginBottom || '0'};
    border: 1px solid ${(props) => props.theme.color.certnGray100};
    padding: 0px 20px 20px 20px;
    @media (max-width: ${({ theme }) => theme.width.phone}) {
        width: 100%;
    }
`;

const ScrollFrame = (props) => (
    <FrameContainer {...props}>
        <FrameText {...props}>{props.children}</FrameText>
    </FrameContainer>
);

export default ScrollFrame;
