import { message } from 'antd';

// Modules
import Path from 'modules/Path';
import copy from 'copy-to-clipboard';
import Cookies from 'js-cookie';

const CopyToClipboard = ({ route, urlCode = null, type = 'hr' }) => {
    let copySuccess = false;
    if (urlCode) {
        const apply = type === 'pm' ? '/apply/' : '/hr/apply/';

        const copyText = `${Path.getPath()}${apply}${urlCode}`;
        copySuccess = copy(copyText);
    } else if (route) {
        const copyText = `${Path.getPath()}/browse/${route}/${Cookies.get('team_id')}`;
        copySuccess = copy(copyText);
    } else {
        const copyText = `${Path.getPath()}/browse/buildings/${Cookies.get('team_id')}`;
        copySuccess = copy(copyText);
    }
    if (copySuccess) {
        message.success('Apply Link was copied to clipboard.');
    } else {
        message.error('Apply Link could not be copied.');
    }
};

export default CopyToClipboard;
