import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { DatePicker, Popover } from 'antd';
import moment from 'moment';

import { isDateInFuture, isDateInPast, isDateToday, isValidBirthDate, isOver120Years } from 'modules/validation';
import { getDateFormat, getDatePlaceholder } from 'modules/Format';

import { flexCenter, flexRowStretch } from 'styles/Mixins';
import { InfoCircleOutlined } from '@ant-design/icons';

const DatePickerWrapper = styled.div`
    ${flexRowStretch}

    .ant-picker {
        flex: 1;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    .ant-input-group-addon {
        width: 34px;
        ${flexCenter};
        // Don't show red error border
        border-color: ${({ theme }) => theme.color.certnGray400};
    }
`;

// These are the supported date formats that we will use to parse date strings
// The first one is the default format and the one we will store the date as
// https://momentjs.com/docs/#/parsing/
const DATE_FORMATS = [
    'MM-DD-YYYY',
    'YYYY-MM-DD',
    'YYYY-MM',
    'YYYY',
    'YYYYMM',
    'YYYYMMDD',
    'MMM D, YYYY',
    'MMM Do, YYYY',
    'MMMM D, YYYY',
    'MMMM Do, YYYY',
    'YYYY/MM/DD',
    'YYYY/MM',
    'DD/MM/YYYY',
    'MM/YYYY',
    'L',
    'LL',
];

const propTypes = {
    // Render component before date picker
    addonBefore: PropTypes.node,
    // If true - the placeholder prop is displayed as the addonBefore in a Popover tooltip
    addonBeforeUsePlaceholder: PropTypes.bool,
    // Disable future dates
    disableFutureDates: PropTypes.bool,
    // Disable past dates
    disablePastDates: PropTypes.bool,
    disableToday: PropTypes.bool,
    // special rules for birth dates, must be in the past 120 years
    isBirthDate: PropTypes.bool,
    // All other props from DatePicker are allowed as well (e.g. placeholder)
    showToday: PropTypes.bool,
    // The Form.Item will automatically set the value and onChange props
    // So don't specify them here or you'll get errors in the console
};

const defaultProps = {
    addonBefore: undefined,
    addonBeforeUsePlaceholder: false,
    disableFutureDates: false,
    disablePastDates: false,
    disableToday: false,
    isBirthDate: false,
    showToday: false,
};

const DEFAULT_BIRTHDATE = moment('01-01-1990', 'MM-DD-YYYY');

/**
 * This component wraps a DatePicker field to allow saving the date
 * as a string instead of as a moment date object.
 * This component is expected to be inside a Form.Item component, which will set the
 * value, onChange, id and a few other props.
 * If a placeholder is set, it will also render a Popover tooltip similar to
 * how the Input field renders the beforeAddon.
 */
const DateInput = React.forwardRef(
    (
        {
            addonBefore,
            addonBeforeUsePlaceholder,
            disableFutureDates,
            disablePastDates,
            disableToday,
            isBirthDate,
            country = undefined,
            // These are automatically set from the parent Form.Item
            value,
            onChange,
            placeholder,
            ...rest
        },
        ref
    ) => {
        const formattedPlaceholder = `${placeholder} (${getDatePlaceholder(country)})`;

        // Use Jan 1, 1990 as the default birthdate
        let { defaultPickerValue } = rest;
        if (!defaultPickerValue && isBirthDate) {
            defaultPickerValue = DEFAULT_BIRTHDATE;
        }

        // Convert string value to moment date with strict parsing
        let date = moment.utc(value, ['YYYY-MM-DD', getDateFormat(country)], true);
        if (!date.isValid()) {
            date = undefined;
        }

        // Wrap the default onChange function so that we can save date
        // as a string and not as a moment date object
        const onDateChange = (dateMoment, dateString) => {
            // If a date has been selected, format it for the API
            if (dateString) {
                onChange(dateMoment.utc().format('YYYY-MM-DD'));
            } else {
                onChange(dateString);
            }
        };

        // Create a new date list to prevent an endlessly growing list
        const getDateFormats = (baseList) => [getDateFormat(country), ...baseList];

        // Determine any dates that should be disabled (e.g. all dates in the future)
        // Note that the rest parameters are added after this one, so if it contains
        // a disabledDate prop it will override this one (on purpose)
        const disabledDates = (dt) => {
            // There is no place in our app that we allow dates over 120 years old
            if (isOver120Years(dt)) return true;
            // Valid birth dates are in the past
            if (isBirthDate && !isValidBirthDate(dt)) return true;
            if (disablePastDates && isDateInPast(dt)) return true;
            if (disableFutureDates && isDateInFuture(dt)) return true;
            if (disableToday && isDateToday(dt)) return true;
            return false;
        };
        // Render the placeholder using a Popover as the addonBefore component
        let addon = addonBefore;
        if (!addon && addonBeforeUsePlaceholder) {
            if (placeholder) {
                addon = (
                    <Popover content={formattedPlaceholder}>
                        <InfoCircleOutlined />
                    </Popover>
                );
            }
        }

        return (
            <DatePickerWrapper>
                {addon && <span className="ant-input-group-addon">{addon}</span>}
                <DatePicker
                    ref={ref}
                    format={getDateFormats(DATE_FORMATS)}
                    disabledDate={disabledDates}
                    placeholder={formattedPlaceholder}
                    {...rest}
                    defaultPickerValue={defaultPickerValue}
                    value={date}
                    onChange={onDateChange}
                />
            </DatePickerWrapper>
        );
    }
);

DateInput.propTypes = propTypes;
DateInput.defaultProps = defaultProps;

export default DateInput;
