import React from 'react';
import { Menu } from 'antd';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';

// Components
import { ListCellText } from 'certn-ui/List';

// Utils
import Constants from 'utils/constants';

const ItemViewReport = ({ reportStatus, orderStatus, applicantId, ...rest }) => {
    const { webFeatureEnableNewStatusSystem } = useFlags();
    const history = useHistory();

    const viewReport = () => {
        history.push(`/hr/applications/${applicantId}`);
    };

    let isViewReport;
    if (webFeatureEnableNewStatusSystem) isViewReport = orderStatus === Constants.orderStatus.COMPLETE;
    if (!webFeatureEnableNewStatusSystem) isViewReport = reportStatus === Constants.reportStatus.COMPLETE;
    return (
        <Menu.Item {...rest}>
            <ListCellText clickable onClick={viewReport}>
                {/* LDFLag isWebFeatureEnableNewStatusSystem */}
                {isViewReport ? (
                    <FormattedMessage id="004e5.StatusDropdown.viewReport" defaultMessage="View Report" />
                ) : (
                    <FormattedMessage
                        id="004e5.StatusDropdown.viewPartialReport"
                        defaultMessage="View Partial Report"
                    />
                )}
            </ListCellText>
        </Menu.Item>
    );
};

ItemViewReport.propTypes = {
    reportStatus: PropTypes.string.isRequired,
    applicantId: PropTypes.string.isRequired,
};

export default ItemViewReport;
