import { InfoCircleOutlined } from '@ant-design/icons';
import { Form, Input, Popover, Select } from 'antd';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { Regex } from 'modules';
import { useCallback, useState } from 'react';

import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { flexCenter } from 'styles/Mixins';
import { UkCheckType } from '../types';

const TitleSelectWrapper = styled.div`
    display: flex;
    flex-direction: row;

    .ant-row {
        width: 100%;
    }

    .ant-select-selector {
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }

    .ant-input-group-addon {
        ${flexCenter};
        width: 34px;
        height: 40.15px;
        border-color: #d9d9d9;
    }
`;

interface Props {
    fieldData: FormListFieldData;
    titleFieldType: UkCheckType;
    otherTitle: string | undefined;
}

const TitleField: React.FC<Props> = ({ fieldData, titleFieldType, otherTitle }) => {
    const intl = useIntl();
    const { key, name, ...restField } = fieldData;
    const [showOtherTitleField, setShowOtherTitleField] = useState(!!otherTitle);
    const handleChange = useCallback(
        (value: string) => {
            if (titleFieldType === 'UK_BASIC_DS_CHECK') {
                setShowOtherTitleField(value === 'OTHER');
            }
        },
        [titleFieldType]
    );

    let titleOptions: Array<{ value: string; label: string }> = [];
    if (titleFieldType === 'UK_BASIC_DBS_CHECK') {
        titleOptions = [
            { label: intl.formatMessage({ id: 'welcome.Basics.title.mr', defaultMessage: 'Mr' }), value: 'MR' },
            { label: intl.formatMessage({ id: 'welcome.Basics.title.mrs', defaultMessage: 'Mrs' }), value: 'MRS' },
            { label: intl.formatMessage({ id: 'welcome.Basics.title.ms', defaultMessage: 'Ms' }), value: 'MS' },
            { label: intl.formatMessage({ id: 'welcome.Basics.title.miss', defaultMessage: 'Miss' }), value: 'MISS' },
            { label: intl.formatMessage({ id: 'welcome.Basics.title.dr', defaultMessage: 'Dr' }), value: 'DR' },
            {
                label: intl.formatMessage({ id: 'welcome.Basics.title.reverend', defaultMessage: 'Reverend' }),
                value: 'REVEREND',
            },
            {
                label: intl.formatMessage({ id: 'welcome.Basics.title.father', defaultMessage: 'Father' }),
                value: 'FATHER',
            },
            {
                label: intl.formatMessage({ id: 'welcome.Basics.title.captain', defaultMessage: 'Captain' }),
                value: 'CAPTAIN',
            },
            {
                label: intl.formatMessage({ id: 'welcome.Basics.title.professor', defaultMessage: 'Professor' }),
                value: 'PROFESSOR',
            },
            {
                label: intl.formatMessage({ id: 'welcome.Basics.title.baron', defaultMessage: 'Baron' }),
                value: 'BARON',
            },
            {
                label: intl.formatMessage({ id: 'welcome.Basics.title.baroness', defaultMessage: 'Baroness' }),
                value: 'BARONESS',
            },
            {
                label: intl.formatMessage({ id: 'welcome.Basics.title.brigadier', defaultMessage: 'Brigadier' }),
                value: 'BRIGADIER',
            },
            {
                label: intl.formatMessage({ id: 'welcome.Basics.title.canon', defaultMessage: 'Canon' }),
                value: 'CANON',
            },
            {
                label: intl.formatMessage({ id: 'welcome.Basics.title.duchess', defaultMessage: 'Duchess' }),
                value: 'DUCHESS',
            },
            { label: intl.formatMessage({ id: 'welcome.Basics.title.duke', defaultMessage: 'Duke' }), value: 'DUKE' },
            { label: intl.formatMessage({ id: 'welcome.Basics.title.esq', defaultMessage: 'Esq' }), value: 'ESQ' },
            { label: intl.formatMessage({ id: 'welcome.Basics.title.hon', defaultMessage: 'Hon' }), value: 'HON' },
            {
                label: intl.formatMessage({ id: 'welcome.Basics.title.inspector', defaultMessage: 'Inspector' }),
                value: 'INSPECTOR',
            },
            { label: intl.formatMessage({ id: 'welcome.Basics.title.lady', defaultMessage: 'Lady' }), value: 'LADY' },
            { label: intl.formatMessage({ id: 'welcome.Basics.title.lord', defaultMessage: 'Lord' }), value: 'LORD' },
            {
                label: intl.formatMessage({ id: 'welcome.Basics.title.ltcol', defaultMessage: 'Lt Col' }),
                value: 'LT COL',
            },
            {
                label: intl.formatMessage({ id: 'welcome.Basics.title.major', defaultMessage: 'Major' }),
                value: 'MAJOR',
            },
            {
                label: intl.formatMessage({ id: 'welcome.Basics.title.mostrever', defaultMessage: 'Most Rever' }),
                value: 'MOST REVER',
            },
            { label: intl.formatMessage({ id: 'welcome.Basics.title.mx', defaultMessage: 'Mx' }), value: 'MX' },
            {
                label: intl.formatMessage({ id: 'welcome.Basics.title.pastor', defaultMessage: 'Pastor' }),
                value: 'PASTOR',
            },
            {
                label: intl.formatMessage({ id: 'welcome.Basics.title.rabbi', defaultMessage: 'Rabbi' }),
                value: 'RABBI',
            },
            {
                label: intl.formatMessage({ id: 'welcome.Basics.title.revdr', defaultMessage: 'Rev Dr' }),
                value: 'REV DR',
            },
            {
                label: intl.formatMessage({ id: 'welcome.Basics.title.rtreveren', defaultMessage: 'Rt Reveren' }),
                value: 'RT REVEREN',
            },
            { label: intl.formatMessage({ id: 'welcome.Basics.title.sir', defaultMessage: 'Sir' }), value: 'SIR' },
            {
                label: intl.formatMessage({ id: 'welcome.Basics.title.sister', defaultMessage: 'Sister' }),
                value: 'SISTER',
            },
            {
                label: intl.formatMessage({ id: 'welcome.Basics.title.squadronl', defaultMessage: 'Squadron L' }),
                value: 'SQUADRON L',
            },
            {
                label: intl.formatMessage({ id: 'welcome.Basics.title.wgcdr', defaultMessage: 'Wg Cdr' }),
                value: 'WG CDR',
            },
        ];
    } else if (titleFieldType === 'UK_BASIC_DS_CHECK') {
        titleOptions = [
            { label: intl.formatMessage({ id: 'welcome.Basics.title.mr', defaultMessage: 'Mr' }), value: 'MR' },
            { label: intl.formatMessage({ id: 'welcome.Basics.title.mrs', defaultMessage: 'Mrs' }), value: 'MRS' },
            { label: intl.formatMessage({ id: 'welcome.Basics.title.ms', defaultMessage: 'Ms' }), value: 'MS' },
            { label: intl.formatMessage({ id: 'welcome.Basics.title.miss', defaultMessage: 'Miss' }), value: 'MISS' },
            {
                label: intl.formatMessage({ id: 'welcome.Basics.title.other', defaultMessage: 'Other' }),
                value: 'OTHER',
            },
        ];
    }

    return (
        <>
            <TitleSelectWrapper>
                <span className="ant-input-group-addon">
                    <Popover
                        content={intl.formatMessage({
                            id: 'welcome.Basics.personalTitle',
                            defaultMessage: 'Title',
                        })}
                    >
                        <InfoCircleOutlined />
                    </Popover>
                </span>
                <Form.Item
                    {...restField}
                    name={[name, 'title']}
                    rules={[
                        {
                            required: true,
                            message: intl.formatMessage({
                                id: 'error.validation.notBlank',
                                defaultMessage: 'Please do not leave blank',
                            }),
                        },
                    ]}
                >
                    <Select
                        showSearch
                        placeholder={intl.formatMessage({
                            id: 'welcome.Basics.personalTitle',
                            defaultMessage: 'Title',
                        })}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            (option?.label?.toString().toLowerCase() ?? '').includes(input.toLowerCase())
                        }
                        filterSort={(optionA, optionB) =>
                            (optionA?.label?.toString() ?? '')
                                .toLowerCase()
                                .localeCompare((optionB?.label?.toString() ?? '').toLowerCase())
                        }
                        options={titleOptions}
                        size="large"
                        onChange={handleChange}
                        data-testid="title_dropdown"
                    />
                </Form.Item>
            </TitleSelectWrapper>
            {showOtherTitleField ? (
                <Form.Item
                    {...restField}
                    name={[name, 'other_title']}
                    rules={[
                        {
                            pattern: Regex.otherTitle,
                            message: intl.formatMessage({
                                id: 'error.validation.letters',
                                defaultMessage: 'Please only use letters',
                            }),
                        },
                        {
                            required: true,
                            message: intl.formatMessage({
                                id: 'error.validation.notBlank',
                                defaultMessage: 'Please do not leave blank',
                            }),
                        },
                    ]}
                >
                    <Input
                        maxLength={19}
                        size="large"
                        placeholder={intl.formatMessage({
                            id: 'welcome.Basics.personalTitleOther',
                            defaultMessage: 'Other Title',
                        })}
                        addonBefore={
                            <Popover
                                content={intl.formatMessage({
                                    id: 'welcome.Basics.personalTitleOther',
                                    defaultMessage: 'Other Title',
                                })}
                            >
                                <InfoCircleOutlined />
                            </Popover>
                        }
                        data-testid="other_title_input"
                    />
                </Form.Item>
            ) : undefined}
        </>
    );
};
export default TitleField;
