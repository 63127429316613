// Libraries
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { intl, localeMessages } from 'components/GlobalProvider';
import { Form, Input, Button, Checkbox } from 'antd';

// Components
import {
    SectionWrapper,
    SectionTopBar,
    SectionBottomPanel,
    BottomPanelRow,
    BottomPanelRowInner,
    Instructions,
    CustomSelect,
    FieldCheckBoxWrapper,
} from '../../../../styled';
import { fetchUserInfo } from 'base/BaseActions';

import { EmailAddressesAndPhones, EmailAddresses } from 'components';
import FieldLabel from 'certn-form/FieldLabel';
import HRTermsFCRA from './HRTermsFCRA';

// Actions & Selectors
import {
    getScreenApplicantCustomEmail,
    getUserModeIsHR,
    getLanguage,
    getComplianceRegion,
    getTeam,
} from 'base/BaseSelectors';

import { LANGUAGE_EN, LANGUAGE_ES, LANGUAGE_FR, QA_TEST_ID } from 'utils/constants';

const propTypes = {
    form: PropTypes.object.isRequired,
    loading: PropTypes.bool,
    submitAttempted: PropTypes.bool,
};
const defaultProps = {
    loading: false,
    submitAttempted: false,
};

const ApplicantInfo = ({ form, loading, submitAttempted }) => {
    let screenApplicantCustomEmail = useSelector(getScreenApplicantCustomEmail);
    const userModeIsHR = useSelector(getUserModeIsHR);
    const complianceRegion = useSelector(getComplianceRegion);
    const language = useSelector(getLanguage);
    const [currentLanguage, setCurrentLanguage] = useState(language);
    const [hasFetchedUserInfo, setHasFetchedUserInfo] = useState(false);
    const [showSmsOptIn, setShowSmsOptIn] = useState(false);
    const team = useSelector(getTeam);

    const showHRTermsFCRA = complianceRegion?.show_fcra && userModeIsHR;

    const instructions = (
        <Instructions>
            {intl.formatMessage({
                id: 'f5cd8.Applicant.instructions',
                defaultMessage:
                    'We will email instructions to your candidate so they can easily complete their details online and provide consent. We recommend notifying the candidate that they should expect an email from Certn.',
            })}
        </Instructions>
    );

    const defaultEmailMessage =
        "Please visit Certn to provide information and consent needed for the next step in the application process. It'll really make things go quicker. Thanks!";
    // Load the placeholder default value like this so that the intl script picks it up
    const placeholderId = 'f5cd8.Applicant.emailMessage';
    let emailPlaceholder = intl.formatMessage({
        id: placeholderId,
        defaultMessage: defaultEmailMessage,
    });
    // Now load the placeholder based on the selected language
    emailPlaceholder = localeMessages[currentLanguage][placeholderId];

    const dispatch = useDispatch();

    React.useEffect(() => {
        /**
         * AntD form item initial value only works on first render. Changes in values afterwards won't
         * trigger re-render. Needed to create another state variable to track user default email message
         */
        dispatch(fetchUserInfo()).then(() => setHasFetchedUserInfo(true));
    }, [dispatch]);

    const languageChangeHandler = (value) => {
        const oldValue = form.getFieldValue('email_message');
        setCurrentLanguage(value);
        // Update email message if it is still the default placeholder value
        if (oldValue === emailPlaceholder) {
            const email_message = localeMessages[value][placeholderId];
            form.setFieldsValue({ email_message });
        }
    };

    const phoneChangeHandler = () => {
        const applicantContactInfos = form.getFieldValue('contact_info');
        setShowSmsOptIn(applicantContactInfos.some((contactInfo) => contactInfo.phone));
    };

    // Translate the custom email to current language setting if it's set to the default english template.
    if (screenApplicantCustomEmail === defaultEmailMessage) {
        screenApplicantCustomEmail = emailPlaceholder;
    }

    return (
        <SectionWrapper data-testid={QA_TEST_ID.ApplicantInfoSectionWrapper}>
            <SectionTopBar>
                <FormattedMessage id="f5cd8.Applicant.information" defaultMessage="Applicant(s) Information" />
            </SectionTopBar>
            <SectionBottomPanel>
                {team?.superteam?.enable_sms_applicant_invites ? (
                    <EmailAddressesAndPhones
                        instructions={instructions}
                        submitAttempted={submitAttempted}
                        maxAllowed={1000}
                        phoneOnChange={phoneChangeHandler}
                    />
                ) : (
                    <EmailAddresses instructions={instructions} submitAttempted={submitAttempted} maxAllowed={1000} />
                )}
                <BottomPanelRow>
                    <BottomPanelRowInner style={{ marginTop: '-15px' }}>
                        <FieldLabel htmlFor="language">
                            <FormattedMessage id="f5cd8.Applicant.emailLanguage" defaultMessage="Email language" />
                        </FieldLabel>
                        <Form.Item
                            key="language"
                            name="language"
                            initialValue={language}
                            rules={[
                                {
                                    required: true,
                                    message: intl.formatMessage({
                                        id: 'error.validation.notBlank',
                                        defaultMessage: 'Please do not leave blank',
                                    }),
                                },
                            ]}
                        >
                            <CustomSelect onChange={languageChangeHandler}>
                                <CustomSelect.Option value={LANGUAGE_EN}>
                                    <FormattedMessage id="f5cd8.Applicant.english" defaultMessage="English" />
                                </CustomSelect.Option>
                                <CustomSelect.Option value={LANGUAGE_FR}>
                                    <FormattedMessage id="f5cd8.Applicant.french" defaultMessage="French" />
                                </CustomSelect.Option>
                                <CustomSelect.Option value={LANGUAGE_ES}>
                                    <FormattedMessage id="f5cd8.Applicant.spanish" defaultMessage="Spanish" />
                                </CustomSelect.Option>
                            </CustomSelect>
                        </Form.Item>
                    </BottomPanelRowInner>
                </BottomPanelRow>
                <BottomPanelRow>
                    <BottomPanelRowInner style={{ marginTop: '-15px' }}>
                        <FieldLabel htmlFor="email_message" optional>
                            <FormattedMessage
                                id="f5cd8.Applicant.msgToCandidate"
                                defaultMessage="Your message to candidate"
                            />
                        </FieldLabel>
                        {hasFetchedUserInfo && (
                            <Form.Item
                                name="email_message"
                                initialValue={screenApplicantCustomEmail || undefined}
                                style={{ marginBottom: '0px' }}
                            >
                                <Input.TextArea
                                    style={{ width: '100%' }}
                                    rows={4}
                                    columns={12}
                                    placeholder={emailPlaceholder}
                                />
                            </Form.Item>
                        )}
                        <FieldCheckBoxWrapper>
                            <Form.Item
                                name="email_message_new_default"
                                valuePropName="checked"
                                style={{ marginBottom: showSmsOptIn ? '0px' : '10px !important' }}
                            >
                                <Checkbox>
                                    <FormattedMessage
                                        id="f5cd8.Applicant.setMessageAsDefault"
                                        defaultMessage="Set message as default"
                                    />
                                </Checkbox>
                            </Form.Item>
                        </FieldCheckBoxWrapper>
                        {showSmsOptIn && (
                            <FieldCheckBoxWrapper>
                                <Form.Item
                                    name="opt_in_to_sms_invite"
                                    valuePropName="checked"
                                    style={{ marginBottom: '10px !important' }}
                                    rules={[
                                        {
                                            validator: (_, value) => {
                                                if (value) return Promise.resolve();
                                                return Promise.reject(
                                                    <span style={{ marginLeft: '24px' }}>
                                                        <FormattedMessage
                                                            id="f5cd8.Applicant.optInToSmsInviteError"
                                                            defaultMessage="You must acknowledge you have consented to initiate an SMS invite"
                                                        />
                                                    </span>
                                                );
                                            },
                                        },
                                    ]}
                                >
                                    <Checkbox data-testid="opt_in_to_sms_invite">
                                        <FormattedMessage
                                            id="f5cd8.Applicant.optInToSmsInvite"
                                            defaultMessage="I have obtained consent for Certn to contact the applicant via SMS"
                                        />
                                    </Checkbox>
                                </Form.Item>
                            </FieldCheckBoxWrapper>
                        )}
                        {showHRTermsFCRA && <HRTermsFCRA />}
                    </BottomPanelRowInner>
                </BottomPanelRow>
                <BottomPanelRow>
                    <Button disabled={loading} htmlType="submit" type="primary" data-testid="send_application">
                        <FormattedMessage id="f5cd8.Applicant.sendApplication" defaultMessage="SEND APPLICATION" />
                    </Button>
                </BottomPanelRow>
            </SectionBottomPanel>
        </SectionWrapper>
    );
};

ApplicantInfo.propTypes = propTypes;
ApplicantInfo.defaultProps = defaultProps;

export default ApplicantInfo;
