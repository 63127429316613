// Libraries
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { intl } from 'components/GlobalProvider';

// Components
import { SectionWrapper, SectionTopBar, SectionBottomPanel, CustomRadio } from '../styled';
import { CenterLine } from 'certn-ui/Layout';
import { RadioCard } from 'certn-ui/Radio';

// Images
import { ScreenInfoMyself, ScreenInfoClient } from 'images/svgs/svg-components/';

const WhoWillCompleteInfo = ({ screenType, setScreenType }) => (
    <SectionWrapper>
        <SectionTopBar>
            <FormattedMessage id="f5cd8.Applicant.whoWillComplete" defaultMessage="Who will complete the details?" />
        </SectionTopBar>
        <SectionBottomPanel>
            <CenterLine multiple>
                <CustomRadio defaultValue={screenType} onChange={(e) => setScreenType(e?.target?.value)}>
                    <RadioCard
                        screen
                        checkmark
                        margin="0px 20px 0px 0px"
                        width="150px"
                        height="150px"
                        field="candidate"
                        title={intl.formatMessage({ id: 'f5cd8.Applicant.candidate', defaultMessage: 'Candidate' })}
                        getField={(field) => screenType === field}
                        SVG={ScreenInfoClient}
                    />
                    <RadioCard
                        screen
                        checkmark
                        width="150px"
                        height="150px"
                        field="myself"
                        title={intl.formatMessage({ id: 'f5cd8.Applicant.myself', defaultMessage: 'Myself' })}
                        getField={(field) => screenType === field}
                        SVG={ScreenInfoMyself}
                    />
                </CustomRadio>
            </CenterLine>
        </SectionBottomPanel>
    </SectionWrapper>
);

export default WhoWillCompleteInfo;
