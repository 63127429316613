// Libraries
import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { getReviewStatuses } from 'views/manager/views/admin/AdminSelectors.js';

const Status = styled.span`
    color: ${(props) => (props.faded ? props.theme.color.certnGray400 : 'inherit')};
`;

const fadeKeys = ['NEEDS_REVIEW'];

const ReviewStatusCell = ({ record, faded }) => {
    const adjudicationStatuses = useSelector(getReviewStatuses);
    const adjudicationRecordStatus = record?.applicant_adjudication?.life_cycle_status;
    return (
        <Status
            clickable={record.status === 'Returned'}
            data-testid="review_status_cell"
            isViewed={record.is_viewed}
            faded={faded || fadeKeys.includes(adjudicationRecordStatus)}
        >
            {adjudicationStatuses[adjudicationRecordStatus] || adjudicationRecordStatus}
        </Status>
    );
};

ReviewStatusCell.propTypes = {};

export default ReviewStatusCell;
