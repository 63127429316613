// Libraries
import React from 'react';

// React Router & Routes
import { Switch, Route, Redirect } from 'react-router-dom';
import ListingsList from './views/listingsList/ListingsList';
import ListingsNew from './views/listingsNew/ListingsNew';
import ListingsEdit from './views/listingsEdit/ListingsEdit';

class Listings extends React.Component {
    render() {
        return (
            <Switch>
                <Route path="/pm/listings/new" component={ListingsNew} />
                <Route exact path="/pm/listings/:listingId/edit" component={ListingsEdit} />
                <Route exact path="/pm/listings" component={ListingsList} />
                <Redirect to="/pm/listings" />
            </Switch>
        );
    }
}

export default Listings;
