// Libraries
import React from 'react';

// Components
import DropdownCell from './DropdownCell';
import ActionCellWrapper from 'components/ActionCellWrapper';

const ActionsCell = ({
    row,
    defaultPackage,
    editTemplate,
    deletePackage,
    makePackageDefault,
    canDelete,
    integrationHasDefault,
}) => (
    <ActionCellWrapper margin>
        <DropdownCell
            item={row.id}
            packageName={row.name}
            editTemplate={editTemplate}
            defaultPackage={defaultPackage}
            makeDefault={makePackageDefault}
            deletePackage={deletePackage}
            canDelete={canDelete}
            integrationHasDefault={integrationHasDefault}
        />
    </ActionCellWrapper>
);

export default ActionsCell;
