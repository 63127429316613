// Libraries
import React from 'react';
import styled from 'styled-components';
import Constants from 'utils/constants';
import { useFlags } from 'launchdarkly-react-client-sdk';

const Status = styled.span`
    color: ${(props) => (props.faded ? props.theme.color.certnGray400 : 'inherit')};
`;

const fadeKeys = [
    Constants.reportStatus.SENT,
    Constants.reportStatus.ANALYZING,
    Constants.reportStatus.NONE,
    Constants.reportStatus.IN_PROGRESS,
];

const AdjudicationStatusCell = (props) => {
    const { record } = props;
    const { webFeatureEnableNewStatusSystem } = useFlags();

    // LDFlag webFeatureEnableNewStatusSystem
    let isFaded;
    if (webFeatureEnableNewStatusSystem) isFaded = Constants.orderStatus.faded.includes(record.order_status);
    if (!webFeatureEnableNewStatusSystem) isFaded = fadeKeys.includes(record.report_summary.report_status);

    return (
        <Status data-testid="adjudication_status_cell" isViewed={record.is_viewed} faded={isFaded}>
            {record.adjudication_status_label}
        </Status>
    );
};

AdjudicationStatusCell.propTypes = {};

export default AdjudicationStatusCell;
