import Auth from 'modules/Auth';
// import NProgress from 'nprogress';

/**
 * Parse a response based on the type
 * @param {Response} response
 * @returns {Promise} <resolve: *, reject: Error>
 */
export const parseResponse = (response) => {
    const contentType = (response.headers.get('content-type') || '').split(';')[0];
    if (response.status === 204) {
        return Promise.resolve({});
    }
    if (contentType === 'application/json') {
        return response.json();
    }
    if (contentType === 'multipart/form-data') {
        return response.formData();
    }
    if (['text/html', 'text/csv', 'text/plain'].includes(contentType)) {
        return response.text();
    }
    if (['application/octet-stream', 'application/pdf', 'application/zip'].includes(contentType)) {
        return response.blob();
    }
};

/**
 * Check for API-level errors
 * @param {Response} response
 * @returns {Promise} <resolve: Response, reject: Error>
 */
export const checkStatus = (response) =>
    new Promise((resolve, reject) => {
        if (response.ok && response.status < 400) {
            return resolve(response);
        }
        /* After enough time the session token will expire, we want to force a page refresh on 401 but not on failed login attempts */
        if (response.status === 401 && !response.url.endsWith('/login/')) {
            Auth.deauthenticateUser();
            window.location.reload();
        } else {
            parseResponse(response).then(reject).catch(reject);
        }
    });

export const checkApplicantStatus = (response) =>
    new Promise((resolve, reject) => {
        if (response.ok && response.status < 400) {
            return resolve(response);
        }
        /* After enough time the session token will expire, we want to force a page refresh on 401 but not on failed login attempts */
        if (response.status === 401 && !response.url.endsWith('/login/')) {
            Auth.deauthenticateApplicant();
            window.location.reload();
        } else {
            parseResponse(response).then(reject).catch(reject);
        }
    });

// TODO: Create new functions that build the http request, because the stuff in http.js is disgusting
// /**
//  * Create a new Request object
//  * @param {String} method
//  * @param {String} route
//  * @param {*} [data]
//  * @param {Object} [options]
//  * @returns {Request}
//  */
// const buildRequest = (method, route, data = null, definedOptions = {}) => {
//     const options = Object.assign({}, defaultOptions, validateOptions(definedOptions));
//     const body = () => data ? { body: options.json ? JSON.stringify(data) : data } : {};
//     const baseOptions = {
//         method: method.toUpperCase(),
//         mode: options.mode,
//         headers: new Headers(headers(options.headers)),
//     };
//     const requestOptions = Object.assign({}, baseOptions, body());
//     return new Request(getURL(route), requestOptions);
// };

// /**
//  * Execute a request using fetch
//  * @param {String} method
//  * @param {String} route
//  * @param {*} [body]
//  * @param {Object} [options]
//  */
// const executeRequest = (method, route, body, options) =>
//     new Promise((resolve, reject) => {
//         fetch(buildRequest(method, route, body, options))
//             .then(checkStatus)
//             .then(parseResponse)
//             .then(resolve)
//             .catch(reject);
