// Libraries
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

// Components
import { VaccinationForm, VaccinationDocumentUpload } from './components';

// Modules
import withNavigation from 'views/welcome/modules/WithNavigation';

// Actions & Selectors
import { setTrackPageOrder, patchInformation } from 'views/welcome/WelcomeActions';
import { getIsFetchingSilent, getInformation, getSettings } from 'views/welcome/WelcomeSelectors';

// Constants
import { VACCINATION_OPTIONS } from 'views/welcome/WelcomeConstants';

const propTypes = {
    // WithNavigation
    trackPageLocation: PropTypes.string,
    handleNextTrack: PropTypes.func,
    handleForward: PropTypes.func,
};

const defaultProps = {
    trackPageLocation: 'base',
    handleNextTrack: () => {},
    handleForward: () => {},
};

const VaccinationInfo = ({ trackPageLocation, handleNextTrack, handleForward }) => {
    const dispatch = useDispatch();
    const isFetchingSilent = useSelector(getIsFetchingSilent);
    const information = useSelector(getInformation);
    const settings = useSelector(getSettings);
    const [isWarningVisible, setIsWarningVisible] = useState(false);
    const [isNextDisabled, setIsNextDisabled] = useState(true);
    const [warningType, setWarningType] = useState('displayConsentWarning');

    const [vaccinationDocument, setVaccinationDocument] = useState(information.vaccination_document || undefined);

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(setTrackPageOrder(['base', 'documentUploadForm']));
        if (information.self_reported_vaccination_status) {
            setIsNextDisabled(false);
        }
    }, [dispatch, information.self_reported_vaccination_status]);

    const handleSubmit = (e, validateFields, consent) => {
        e.preventDefault();
        validateFields((err, values) => {
            if (!err) {
                if (!consent) {
                    setWarningType('displayConsentWarning');
                    setIsWarningVisible(true);
                    return;
                    // Don't dispatch anything to the api as we don't have consent
                }
                if (
                    values.self_reported_vaccination_status === VACCINATION_OPTIONS.NOT_VACCINATED_WITH_NO_INTENT_TO_BE
                ) {
                    setWarningType('displayNotVaxWarning');
                    setIsWarningVisible(true);
                    dispatch(
                        patchInformation({
                            self_reported_vaccination_status: values.self_reported_vaccination_status,
                            vaccination_consent_given: true,
                        })
                    );
                    return;
                }

                if (
                    [
                        VACCINATION_OPTIONS.FULLY_VACCINATED_MORE_THAN_14_DAYS_AGO,
                        VACCINATION_OPTIONS.FULLY_VACCINATED_LESS_THAN_14_DAYS_AGO,
                        VACCINATION_OPTIONS.PARTIALLY_VACCINATED,
                    ].includes(values.self_reported_vaccination_status)
                ) {
                    dispatch(
                        patchInformation({
                            self_reported_vaccination_status: values.self_reported_vaccination_status,
                            vaccination_consent_given: true,
                        })
                    ).then(handleForward);
                } else {
                    dispatch(
                        patchInformation({
                            self_reported_vaccination_status: values.self_reported_vaccination_status,
                            vaccination_consent_given: true,
                        })
                    ).then(handleNextTrack);
                }
            }
        });
    };

    const pages = () => ({
        base: (
            <VaccinationForm
                handleSubmit={handleSubmit}
                information={information}
                settings={settings}
                isFetchingSilent={isFetchingSilent}
                isWarningVisible={isWarningVisible}
                setIsWarningVisible={setIsWarningVisible}
                warningType={warningType}
                isNextDisabled={isNextDisabled}
                setIsNextDisabled={setIsNextDisabled}
            />
        ),
        documentUploadForm: (
            <VaccinationDocumentUpload
                isNextDisabled={!vaccinationDocument}
                setIsNextDisabled={setIsNextDisabled}
                vaccinationDocument={vaccinationDocument}
                setVaccinationDocument={setVaccinationDocument}
                handleSubmit={() => handleNextTrack()}
            />
        ),
    });

    return pages()[trackPageLocation]; /* WithNavigation */
};

VaccinationInfo.propTypes = propTypes;
VaccinationInfo.defaultProps = defaultProps;

export default withRouter(withNavigation(VaccinationInfo));
