// Libraries
import styled from 'styled-components/macro';
import React, { useEffect, useState } from 'react';
import { message, Button, Result } from 'antd';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { get } from 'lodash';

// Components
import { intl } from 'components/GlobalProvider';
import { PaymentForm, PaymentSuccess, PaymentSuccessMVR } from './components';
import { ErrorAlertAPI } from 'certn-ui/ErrorAlert';
import { useTracking } from 'hooks/trackingHooks';

// Actions & Selectors
import {
    getMyCRCPaymentBreakdown,
    getSettings,
    getIsPrepaid,
    getIsSubmitted,
    getApplicant,
    getInformation,
    getPrevTrack,
    getTeam,
} from 'views/welcome/WelcomeSelectors';
import {
    submitStripeCustomerId,
    submitWelcomeSession,
    fetchPaymentBreakdown,
    patchWelcomeSession,
} from 'views/welcome/WelcomeActions';

import withNavigation from 'views/welcome/modules/WithNavigation';

// External Libraries
import Cookies from 'js-cookie';

// Constants
import { CHECK_REQUEST } from 'base/BaseConstants';
import { TrackNavigation } from 'views/welcome/components';

const CustomInfo = styled(LegacyIcon)`
    color: ${({ theme }) => theme.color.certnGreen700} !important;
`;

// This component was copied 1:1 from the old Payment component and expanded upon
function Payments({ history, handleNextTrackAndAutoSubmit }) {
    const dispatch = useDispatch();
    const settings = useSelector(getSettings);
    const team = useSelector(getTeam);
    const { sendTrackingEvent } = useTracking();
    const [paymentLoading, setPaymentLoading] = useState(false);
    const [paymentSuccess, setPaymentSuccess] = useState(false);
    const [paidButFailedSubmit, setPaidButFailedSubmit] = useState(false);
    const myCRCPaymentBreakdown = useSelector(getMyCRCPaymentBreakdown);
    const isPrepaid = useSelector(getIsPrepaid);
    const prevTrack = useSelector(getPrevTrack);
    const isSubmitted = useSelector(getIsSubmitted);
    const information = useSelector(getInformation);
    const applicant = useSelector(getApplicant);
    const isBcMvr =
        get(applicant, [CHECK_REQUEST.MOTOR_VEHICLE_RECORDS]) && get(information, ['license_prov_state']) === 'BC';

    // On mount fetch payment breakdown
    useEffect(() => {
        dispatch(fetchPaymentBreakdown());
    }, [dispatch]);

    // 3 second wait before sending to redirect URL
    const redirect = () =>
        setTimeout(() => {
            if (settings.applicant_login && !settings.redirect_url) {
                history.push('/applicant/review');
                return;
            }
            window.location.replace(settings.redirect_url);
        }, 1500);

    // opens live session pop-up in Zendesk
    const openZendeskChat = (e) => {
        if (e) e.preventDefault();
        window.zE('webWidget', 'open');
    };

    const handleReverse = () => {
        dispatch(patchWelcomeSession(prevTrack));
        history.push(`/welcome/${prevTrack}`);
    };

    const handlePaymentFormSubmit = (stripe_token_id) => {
        setPaymentLoading(true);
        dispatch(submitStripeCustomerId({ stripe_token_id }))
            .then(() => {
                Cookies.set('has_paid', 'true', { expires: 1 });
                handleNextTrackAndAutoSubmit(dispatchWelcomeSession);
            })
            .catch((error) => {
                setPaymentLoading(false);
                ErrorAlertAPI(error);
            });
    };

    const dispatchWelcomeSession = async () => {
        try {
            await dispatch(submitWelcomeSession());
            setPaymentSuccess(true);

            let msg = intl.formatMessage({
                id: 'welcome.Welcome.paymentSuccessToastDefault',
                defaultMessage: 'Payment submitted',
            });
            if (settings.applicant_login && !settings.redirect_url) {
                msg = intl.formatMessage({
                    id: 'welcome.Welcome.paymentSuccessToastRedirect',
                    defaultMessage: 'Payment submitted, redirecting to application review',
                });
            } else if (settings.redirect_url) {
                msg = `${intl.formatMessage({
                    id: 'welcome.Welcome.paymentSuccessToastCustomRedirect',
                    defaultMessage: 'Payment submitted, redirecting to',
                })} ${settings.redirect_url}`;
            }
            message.success(msg);

            sendTrackingEvent({
                googleAnalyticsKey: team?.google_analytics_key,
                bingKey: team?.bing_key,
                adwordsKey: team?.adwords_conversion_key,
                information,
            });

            Cookies.set('is_submitted', 'true', { expires: 1 });

            if (isBcMvr) Cookies.set('MVR_BC', 'true', { expires: 1 });
            if (settings.redirect_url) redirect();
        } catch (error) {
            setPaidButFailedSubmit(true);
            ErrorAlertAPI(error);
        }
    };

    const cookie = {
        is_submitted: Cookies.get('is_submitted') === 'true',
        has_paid: Cookies.get('has_paid') === 'true',
        MVR_BC: Cookies.get('MVR_BC') === 'true',
    };

    if (!isSubmitted && !cookie.is_submitted && !paidButFailedSubmit) {
        return (
            <>
                <PaymentForm
                    myCRCPaymentBreakdown={myCRCPaymentBreakdown}
                    handleSubmit={handlePaymentFormSubmit}
                    paymentSuccess={paymentSuccess}
                    paymentLoading={paymentLoading}
                    isPrepaid={cookie.has_paid || isPrepaid}
                    handleReverse={handleReverse}
                />
                {(cookie.has_paid || isPrepaid) && <TrackNavigation handleSubmit={handleNextTrackAndAutoSubmit} />}
            </>
        );
    }

    if (isSubmitted || cookie.is_submitted) {
        if (isBcMvr || cookie.MVR_BC) {
            return <PaymentSuccessMVR history={history} />;
        }
        return <PaymentSuccess />;
    }

    return (
        <Result
            status="info"
            icon={<CustomInfo type="info-circle" />}
            title={
                <FormattedMessage
                    id="welcome.Welcome.paymentSuccessSubmitFailure"
                    defaultMessage="Oops! Your payment was successful, but we're having trouble submitting your report."
                />
            }
            subTitle={
                <FormattedMessage
                    id="welcome.Welcome.paymentSuccessSubmitFailureSubtitle"
                    defaultMessage="Don't worry, our support team can help! You can get ahold of them here through live chat, or you can email them directly at {supportEmailLink}."
                    values={{
                        supportEmailLink: (
                            <a
                                href="mailto:support@certn.co?subject=Problem submitting application"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                support@certn.co
                            </a>
                        ),
                    }}
                />
            }
            extra={[
                <Button type="primary" key="console" onClick={openZendeskChat}>
                    <FormattedMessage
                        id="welcome.Welcome.supportChatButton"
                        defaultMessage="Start a chat with support"
                    />
                </Button>,
            ]}
        />
    );
}

export default injectIntl(withNavigation(Payments));
