// Libraries
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Form } from '@ant-design/compatible';
import { injectIntl } from 'react-intl';

// Components
import withNavigation from 'views/welcome/modules/WithNavigation';
import CredentialBase from './components/CredentialBase';
import CredentialDetails from './components/CredentialDetails';

// Actions & Selectors
import { setTrackPageOrder, patchInformation, patchWelcomeSession } from 'views/welcome/WelcomeActions';
import {
    getIsFetchingSilent,
    getInformation,
    getNextTrack,
    getPrevTrack,
    getCredentials,
    getSettings,
    getOnboardingType,
    getApplicant,
} from 'views/welcome/WelcomeSelectors';

// Modules
import withSettingsChecker from 'views/welcome/modules/WithSettingsChecker';

const mapStateToProps = (state) => ({
    isFetchingSilent: getIsFetchingSilent(state),
    information: getInformation(state),
    credentials: getCredentials(state),
    nextTrack: getNextTrack(state, 'credential'),
    prevTrack: getPrevTrack(state, 'credential'),
    settings: getSettings(state),
    applicant: getApplicant(state),
    onboardingType: getOnboardingType(state),
});

const mapDispatchToProps = (dispatch) => ({
    dispatch,
    ...bindActionCreators(
        {
            patchInformation,
            patchWelcomeSession,
            setTrackPageOrder,
        },
        dispatch
    ),
});

const propTypes = {
    // Redux Actions:
    patchInformation: PropTypes.func.isRequired,
    patchWelcomeSession: PropTypes.func.isRequired,
    setTrackPageOrder: PropTypes.func.isRequired,
    // Redux Store:
    isFetchingSilent: PropTypes.bool.isRequired,
    information: PropTypes.object.isRequired,
    applicant: PropTypes.object,
    nextTrack: PropTypes.string,
    prevTrack: PropTypes.string,
};
const defaultProps = {
    applicant: {},
    nextTrack: 'credential',
    prevTrack: 'credential',
};

class Credential extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            trackPageLocation: 'base',
            credentialPageLocation: 'base',
            currentCredentialIndex: 0,
            credentialSelected: false,
            pageOrder: ['base'],
            highestLevelOnly: props.applicant.credential_verification_level === 'HIGHEST',
            resetObj: {
                credentials: [],
            },
        };
        window.scrollTo(0, 0);
        props.setTrackPageOrder(['base', 'credentials']);
    }

    deleteCredential = (credentialIndex) => {
        const newCredentials = this.props.credentials;
        newCredentials.splice(credentialIndex, 1);
        this.props.patchInformation({ credentials: newCredentials });
    };

    // Provide an index to educations array if you want to edit an existing entry
    addOrEditCredential = (credentialIndex = null) => {
        const { handleForward } = this.props; /* WithNavigation */
        const { credentials } = this.props;
        let newCredentialIndex = credentialIndex;
        if (credentialIndex === null) {
            newCredentialIndex = credentials && credentials.length;
        }
        this.setState({ currentCredentialIndex: newCredentialIndex }, handleForward);
    };

    patchNewFormValues = (values) => {
        const { handleForward } = this.props; /* WithNavigation */
        this.props.patchInformation({ credentials: values }).then(handleForward);
    };

    handleUpdateHighest = (id) => {
        const { credentials } = this.props;
        credentials.forEach((credential) => {
            credential.highest_level = false;
            if (credential.id === id) credential.highest_level = true;
        });
        this.props.patchInformation({ credentials });
    };

    pages = () => ({
        base: (
            <CredentialBase
                handleUpdate={this.handleUpdateHighest}
                handleSubmit={this.props.runSettingsCheck}
                isFetchingSilent={this.props.isFetchingSilent}
                credentials={this.props.credentials}
                addOrEditCredential={this.addOrEditCredential}
                deleteCredential={this.deleteCredential}
                setValue={this.setValue}
                intl={this.props.intl}
                highestLevelOnly={this.state.highestLevelOnly}
            />
        ),
        credentials: (
            <CredentialDetails
                handleSubmit={this.patchNewFormValues}
                isFetchingSilent={this.props.isFetchingSilent}
                credentials={this.props.credentials}
                degrees={this.props.degrees}
                specializations={this.props.specializations}
                currentCredentialIndex={this.state.currentCredentialIndex}
                intl={this.props.intl}
                highestLevelOnly={this.state.highestLevelOnly}
            />
        ),
    });

    render() {
        return this.pages()[this.props.trackPageLocation];
    }
}

Credential.propTypes = propTypes;
Credential.defaultProps = defaultProps;

export default Form.create()(
    withRouter(
        connect(mapStateToProps, mapDispatchToProps)(injectIntl(withNavigation(withSettingsChecker(Credential))))
    )
);
