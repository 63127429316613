import styled from 'styled-components';

export const LineItemList = styled.div`
    display: flex;
    flex-direction: column;
`;

export const LineItem = styled.div`
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: ${({ disabled, theme }) => (disabled ? theme.color.certnGray400 : '')};
    ${({ disabled }) => disabled && 'cursor: not-allowed'};
    font-weight: 600;
`;

export const CurrencyWrapper = styled.div`
    display: inline-block;
    white-space: nowrap;
`;

export const PriceItem = styled.span`
    display: inline-block;
    margin-right: 10px;
    text-transform: uppercase;
`;

export const ServiceWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: ${({ isPurchased }) => (isPurchased === 'true' ? 'not-allowed' : 'pointer')};
    padding: 20px;
    height: 100%;
    ${({ options, isPurchased, theme }) =>
        (options || isPurchased === 'true') && `background-color: ${theme.color.certnGray100}`};
`;

export const ServiceText = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

export const ServiceToggle = styled.div`
    margin: 10px;
`;

export const Title = styled.span`
    color: ${(props) => props.theme.color.certnGray600};
    font-size: ${(props) => props.theme.font.xs};
    font-weight: 600;
    margin-bottom: 4px;
`;

export const Description = styled.span`
    margin-bottom: 4px;
    font-size: 12px;
    line-height: 15px;
    color: ${({ theme }) => theme.color.certnGray500};
    white-space: pre-line;
    @media (max-width: ${(props) => props.theme.width.tablet}) {
        display: none;
    }
`;

export const ToggleWrapper = styled.div`
    border: 2px solid ${({ theme }) => theme.color.certnGray200};
    border-radius: 4px;
    box-sizing: border-box;
    text-align: center;
    padding: 4px 0 0 0;
    width: 100px;
    transition: 0.2s;
    @media (max-width: ${({ theme }) => theme.width.tablet}) {
        width: 35px;
    }
`;

export const AdditionalOptionsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
    height: 100%;
`;

export const OptionSection = styled.div`
    display: flex;
    margin-bottom: 20px;
    flex-wrap: wrap;
`;

export const OptionSectionButton = styled.div`
    display: flex;
    justify-content: ${({ isInternationalCrim }) => (isInternationalCrim ? 'space-between' : 'flex-end')};
    margin-top: auto;
    .ant-btn {
        text-transform: uppercase;
    }
`;
