// Libraries
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import { bindActionCreators } from 'redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { EllipsisOutlined } from '@ant-design/icons';

// UI Components
import { ListCellText } from 'certn-ui/List';
import { Dropdown as AntDropdown, Menu, Modal, message, Button as AntButton } from 'antd';

// Actions & Selectors
import { deleteTeam } from 'views/manager/views/admin/AdminActions';

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            deleteTeam,
        },
        dispatch
    );

const Dropdown = styled(AntDropdown)`
    margin: 8px 0px;
`;

const Button = styled(AntButton)`
    ${(props) => props.margin && `margin: ${props.margin}`};
    overflow: hidden !important;
    height: 40px !important;
    width: 40px !important;
`;

class TeamMembersDropdown extends React.Component {
    showConfirmRemove = () => {
        Modal.confirm({
            title: this.props.intl.formatMessage({
                id: '862f6.TeamMemberDropDown.RemoveTitle',
                defaultMessage: 'Do you really want to remove this user from the team?',
            }),
            content: this.props.intl.formatMessage({
                id: '862f6.TeamMemberDropDown.RemoveContent',
                defaultMessage: 'This action does not delete the user.',
            }),
            okType: 'danger',
            okText: this.props.intl.formatMessage({ id: 'common.ok', defaultMessage: 'OK' }),
            cancelText: this.props.intl.formatMessage({
                id: 'common.cancel',
                defaultMessage: 'Cancel',
            }),
            onOk: () => {
                this.props.removeTeamMember();
            },
            onCancel() {},
        });
    };

    showConfirmDelete = () => {
        Modal.confirm({
            title: this.props.intl.formatMessage({
                id: '862f6.TeamMemberDropDown.DisableTitle',
                defaultMessage: 'Do you really want to disable this team member?',
            }),
            content: this.props.intl.formatMessage({
                id: '862f6.TeamMemberDropDown.DeleteContent',
                defaultMessage: 'This action cannot be undone.',
            }),
            okType: 'danger',
            okText: this.props.intl.formatMessage({ id: 'common.ok', defaultMessage: 'OK' }),
            cancelText: this.props.intl.formatMessage({
                id: 'common.cancel',
                defaultMessage: 'Cancel',
            }),
            onOk: () => {
                this.props.saveTeamMember();
                return new Promise((resolve) => {
                    setTimeout(resolve, 1000);
                })
                    .then(() =>
                        message.success(
                            this.props.intl.formatMessage({
                                id: '862f6.TeamMemberDropdown.SuccessMessage',
                                defaultMessage: 'Team member deleted!',
                            })
                        )
                    )
                    .catch(() =>
                        message.error(
                            this.props.intl.formatMessage({
                                id: '862f6.TeamMemberDropdown.ErrorMessage',
                                defaultMessage: 'Error occurred while attempting to delete this team member',
                            })
                        )
                    );
            },
            onCancel() {},
        });
    };

    render() {
        return (
            <Dropdown
                overlay={
                    <Menu>
                        <Menu.Item key="1">
                            <ListCellText clickable onClick={this.props.editTeamMember}>
                                <FormattedMessage
                                    id="862f6.TeamMemberDropdown.EditUser"
                                    defaultMessage="Edit Team Member"
                                />
                            </ListCellText>
                        </Menu.Item>
                        {this.props.showDeleteUserButton && (
                            <Menu.Item key="2">
                                <ListCellText clickable onClick={this.showConfirmRemove}>
                                    <FormattedMessage
                                        id="862f6.TeamMemberDropdown.RemoveUser"
                                        defaultMessage="Remove User From Team"
                                    />
                                </ListCellText>
                            </Menu.Item>
                        )}
                        {this.props.active && (
                            <Menu.Item key="3">
                                <ListCellText clickable onClick={this.showConfirmDelete}>
                                    <FormattedMessage
                                        id="862f6.TeamMemberDropdown.DisableUser"
                                        defaultMessage="Disable Team Member"
                                    />
                                </ListCellText>
                            </Menu.Item>
                        )}
                    </Menu>
                }
                trigger={['click']}
                placement="bottomCenter"
            >
                <Button data-testid="team_members_dropdown" shape="circle" icon={<EllipsisOutlined />} />
            </Dropdown>
        );
    }
}

export default connect(null, mapDispatchToProps)(injectIntl(TeamMembersDropdown));
