import { Button, Modal } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useDisplayDisclosureAgreement } from '../hooks';
import { getApplicant } from 'views/welcome/WelcomeSelectors';
import { useSelector } from 'react-redux';
import { CHECK_REQUEST } from 'base/BaseConstants';

type WarningModalType = {
    visible: boolean;
    onClose: () => void;
};

export const WarningModal: React.FC<WarningModalType> = ({ visible, onClose }) => {
    const displayDisclosureAgreement = useDisplayDisclosureAgreement();
    const applicant = useSelector(getApplicant);

    const isUkCheck =
        applicant &&
        (applicant[CHECK_REQUEST.UK_BASIC_DBS_CHECK] ||
            applicant[CHECK_REQUEST.UK_BASIC_DS_CHECK] ||
            applicant[CHECK_REQUEST.UK_RIGHT_TO_WORK_CHECK]);

    return (
        <Modal
            closable={false}
            width="600px"
            title={<FormattedMessage id="common.warning" defaultMessage="Warning" />}
            visible={visible}
            footer={[
                <Button
                    style={{ textTransform: 'uppercase' }}
                    key="submit"
                    type="primary"
                    htmlType="submit"
                    onClick={onClose}
                >
                    <FormattedMessage id="common.ok" defaultMessage="OK" />
                </Button>,
            ]}
        >
            {displayDisclosureAgreement ? (
                // display this new text for MyCRC US applicants only
                <div style={{ textAlign: 'center' }}>
                    <FormattedMessage
                        id="welcome.Email.warningTitleMyCRCUSApplicants"
                        defaultMessage="It is required that you are of legal age and have read, understood and agreed to our Terms of Use, Privacy Policy, Authorization and Disclosure policies in order to start the application."
                    />
                    <br />
                    <br />
                    <FormattedMessage
                        id="welcome.Email.warningBodyMyCRCUSApplicants"
                        defaultMessage="If you cannot confirm to all four requirements you may close this window and exit your application. For assistance please reach out to Certn Support at {email}."
                        values={
                            isUkCheck
                                ? { email: <a href="mailto:uk-support@certn.co">uk-support@certn.co</a> }
                                : { email: <a href="mailto:support@certn.co">support@certn.co</a> }
                        }
                    />
                </div>
            ) : (
                <div style={{ textAlign: 'center' }}>
                    <FormattedMessage
                        id="welcome.Email.warningTitle"
                        defaultMessage="It is required that you are of legal age and have read, understood and agreed to our Terms of Use and Privacy Policy in order to start the application."
                    />
                    <br />
                    <br />
                    <FormattedMessage
                        id="welcome.Email.warningBody"
                        defaultMessage="If you cannot confirm to all three requirements you may close this window and exit your application. For assistance please reach out to Certn Support at {email}."
                        values={
                            isUkCheck
                                ? { email: <a href="mailto:uk-support@certn.co">uk-support@certn.co</a> }
                                : { email: <a href="mailto:support@certn.co">support@certn.co</a> }
                        }
                    />
                </div>
            )}
        </Modal>
    );
};
