// Libraries
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get } from 'lodash';
import Auth from 'modules/Auth';
import { injectIntl, FormattedMessage } from 'react-intl';

// Actions & Selectors
import { getSettings, getCurrentTeam } from 'views/manager/views/settings/SettingsSelectors';
import { getUser } from 'base/BaseSelectors';

// UI Components
import Form from 'certnd/Form';
import Typography from 'certnd/Typography';

// Constants
import { CHECK_REQUEST } from 'base/BaseConstants';

const { Heading } = Typography;

const mapStateToProps = (state) => ({
    user: getUser(state),
    settings: getSettings(state),
    currentTeam: getCurrentTeam(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

// eslint-disable-next-line
class AddressHistory extends React.Component {
    render() {
        const { currentTeam, form, user } = this.props;

        const tenancyYearsRequiredOptions = [
            {
                title: this.props.intl.formatMessage({ id: 'cb9aa.AddressHistory.Zero', defaultMessage: 'Zero' }),
                key: 0,
                value: 0,
            },
            {
                title: this.props.intl.formatMessage({ id: 'cb9aa.AddressHistory.One', defaultMessage: 'One' }),
                key: 1,
                value: 1,
            },
            {
                title: this.props.intl.formatMessage({ id: 'cb9aa.AddressHistory.Two', defaultMessage: 'Two' }),
                key: 2,
                value: 2,
            },
            {
                title: this.props.intl.formatMessage({ id: 'common.three', defaultMessage: 'Three' }),
                key: 3,
                value: 3,
            },
            {
                title: this.props.intl.formatMessage({ id: 'cb9aa.AddressHistory.Four', defaultMessage: 'Four' }),
                key: 4,
                value: 4,
            },
            {
                title: this.props.intl.formatMessage({ id: 'common.five', defaultMessage: 'Five' }),
                key: 5,
                value: 5,
            },
            {
                title: this.props.intl.formatMessage({ id: 'cb9aa.AddressHistory.Six', defaultMessage: 'Six' }),
                key: 6,
                value: 6,
            },
        ];

        const canEdit = Auth.isPermissionLevel('manager');

        return (
            <Form.Block>
                <Heading.H3>
                    <FormattedMessage
                        id="cb9aa.AddressHistory.Title"
                        defaultMessage="Address History (Property Management Only)"
                    />
                </Heading.H3>

                <Form.Select
                    form={form}
                    disabled={!canEdit}
                    fieldName="tenancy_years_amount_req"
                    title={this.props.intl.formatMessage({
                        id: 'cb9aa.AddressHistory.YearsTitle',
                        defaultMessage: 'Years of Address History Required',
                    })}
                    description={this.props.intl.formatMessage({
                        id: 'cb9aa.AddressHistory.YearsDescription',
                        defaultMessage:
                            'How many years of address history does an applicant need to complete a screen?',
                    })}
                    selectOptions={tenancyYearsRequiredOptions}
                    options={{
                        initialValue: currentTeam.tenancy_years_amount_req || 0,
                    }}
                />

                {user.is_user && (
                    <Form.Checkbox
                        form={form}
                        fieldName={CHECK_REQUEST.ADDRESS_REFERENCES}
                        title={this.props.intl.formatMessage({
                            id: 'cb9aa.AddressHistory.RequestAddressTitle',
                            defaultMessage: 'Request Address References For Applications',
                        })}
                        description={this.props.intl.formatMessage({
                            id: 'cb9aa.AddressHistory.RequestAddressDescription',
                            defaultMessage:
                                'If enabled, address references listed by applicants will be sent an email with a short survey to complete and submit.',
                        })}
                        options={{ initialValue: get(currentTeam, [CHECK_REQUEST.ADDRESS_REFERENCES]) }}
                        disabled={!canEdit}
                    />
                )}

                <Form.Checkbox
                    form={form}
                    fieldName="tenancy_ref_email_req"
                    title={this.props.intl.formatMessage({
                        id: 'cb9aa.AddressHistory.ReferenceEmailTitle',
                        defaultMessage: 'Reference Email Required',
                    })}
                    description={this.props.intl.formatMessage({
                        id: 'cb9aa.AddressHistory.ReferenceEmailDescription',
                        defaultMessage: 'Do address references require a contact email?',
                    })}
                    options={{ initialValue: currentTeam.tenancy_ref_email_req }}
                    disabled={!canEdit}
                />

                <Form.Checkbox
                    form={form}
                    fieldName="tenancy_ref_phone_req"
                    title={this.props.intl.formatMessage({
                        id: 'cb9aa.AddressHistory.ReferencePhoneTitle',
                        defaultMessage: 'Reference Phone Number Required',
                    })}
                    description={this.props.intl.formatMessage({
                        id: 'cb9aa.AddressHistory.ReferencePhoneDescription',
                        defaultMessage: 'Do address references require a contact phone number?',
                    })}
                    options={{ initialValue: currentTeam.tenancy_ref_phone_req }}
                    disabled={!canEdit}
                />

                <Form.Button disabled={!canEdit}>
                    <FormattedMessage id="common.save" defaultMessage="Save" />
                </Form.Button>
            </Form.Block>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AddressHistory));
