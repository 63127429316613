// Libraries
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { LockOutlined } from '@ant-design/icons';
import { Form, Input, Alert } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';

// Components
import { Box } from 'certn-ui/Layout';
import { ButtonLine } from 'certn-ui/Button';

// Modules
import {
    passwordValidator,
    hasLowerCase,
    hasUpperCase,
    hasNumber,
    hasSpecialChar,
    hasLengthOf,
} from 'modules/validation';

const PasswordFeedback = styled.div`
    .ant-row {
        background-color: ${({ theme }) => theme.color.certnGray100};
        padding: 12px;
        border-radius: 4px;
    }
`;

const PasswordRequirement = styled.p`
    text-decoration: ${(props) => (props.done ? 'line-through' : 'none')};
    margin-bottom: 0px;
    color: ${({ theme, done }) => (done ? theme.color.certnGray400 : theme.color.certnGray900)};
`;

function Password({ error, form }) {
    const intl = useIntl();
    const passwordLength = 8;
    const [password, setPassword] = useState('');
    const [passwordTrigger, setPasswordTrigger] = useState(['onBlur']);
    const [repeatTrigger, setRepeatTrigger] = useState(['onBlur']);
    const onPasswordChange = (e) => {
        setPassword(e.target.value);
        const passwordError = form.getFieldError('password');
        if (passwordError?.[0]) {
            setPasswordTrigger(['onChange', 'onBlur']);
        }
    };

    const onRepeatChange = () => {
        const repeatPasswordError = form.getFieldError('repeat_password');
        if (repeatPasswordError?.[0]) {
            setRepeatTrigger(['onChange', 'onBlur']);
        }
    };

    return (
        <>
            <Form.Item
                name="password"
                validateTrigger={passwordTrigger}
                rules={[
                    {
                        required: true,
                        message: intl.formatMessage({
                            id: 'error.validation.password.required',
                            defaultMessage: 'Please input your Password!',
                        }),
                    },
                    {
                        validator: (rule, value) => {
                            // the empty string case is handled by the above rule
                            const validationError = value ? passwordValidator(value) : undefined;
                            return validationError ? Promise.reject(validationError) : Promise.resolve();
                        },
                    },
                ]}
            >
                <Input
                    size="large"
                    prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                    type="password"
                    onChange={onPasswordChange}
                    placeholder={intl.formatMessage({
                        id: 'common.password',
                        defaultMessage: 'Password',
                    })}
                />
            </Form.Item>
            <Form.Item
                name="repeat_password"
                validateTrigger={repeatTrigger}
                rules={[
                    {
                        required: true,
                        message: intl.formatMessage({
                            id: 'error.validation.password.reenter',
                            defaultMessage: 'Please re-enter your password!',
                        }),
                    },
                    {
                        validator: (rule, value) => {
                            if (value && password !== value) {
                                return Promise.reject(
                                    intl.formatMessage({
                                        id: 'error.validation.password.noMatch',
                                        defaultMessage: 'Password does not match',
                                    })
                                );
                            }
                            return Promise.resolve();
                        },
                    },
                ]}
            >
                <Input
                    size="large"
                    prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                    type="password"
                    placeholder={intl.formatMessage({
                        id: '343c3.Password.reenterPlaceholder',
                        defaultMessage: 'Re-enter Password',
                    })}
                    onChange={onRepeatChange}
                />
            </Form.Item>
            <PasswordFeedback>
                <Form.Item>
                    <Box center={false}>
                        <PasswordRequirement done={hasLengthOf(password, passwordLength)}>
                            <FormattedMessage id="343c3.Password.char8" defaultMessage="At least 8 characters long" />
                        </PasswordRequirement>
                        <PasswordRequirement done={hasUpperCase(password)}>
                            <FormattedMessage id="343c3.Password.capital" defaultMessage="Contains a capital letter" />
                        </PasswordRequirement>
                        <PasswordRequirement done={hasLowerCase(password)}>
                            <FormattedMessage id="343c3.Password.lower" defaultMessage="Contains a lowercase letter" />
                        </PasswordRequirement>
                        <PasswordRequirement done={hasNumber(password)}>
                            <FormattedMessage id="343c3.Password.number" defaultMessage="Contains a number" />
                        </PasswordRequirement>
                        <PasswordRequirement done={hasSpecialChar(password)}>
                            <FormattedMessage
                                id="343c3.Password.symbol"
                                defaultMessage="Contains a special symbol (ie: #!$)"
                            />
                        </PasswordRequirement>
                    </Box>
                </Form.Item>
            </PasswordFeedback>
            {error && (
                <ButtonLine>
                    <Alert
                        message={
                            <FormattedMessage
                                id="343c3.Password.message1"
                                defaultMessage="Your password was too similar to your other information. Please make sure it does not contain similar text to your email address."
                            />
                        }
                        type="error"
                        showIcon
                    />
                </ButtonLine>
            )}
        </>
    );
}

Password.propTypes = {
    form: PropTypes.any.isRequired,
    error: PropTypes.any,
};

Password.defaultProps = {
    error: undefined,
};

export default Password;
