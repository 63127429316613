import React from 'react';
import PropTypes from 'prop-types';

import DocSigningContainer from './DocSigningContainer';
import HelloSignEmbed from './HelloSignEmbed';

const AgreementSigning = ({ isOpen, onOpen, onClose, documentTitle, isSigned, endpointURL, onFinish }) => (
    <>
        <DocSigningContainer documentTitle={documentTitle} onOpen={onOpen} isSigned={isSigned} />
        <HelloSignEmbed
            isOpen={isOpen}
            endpointURL={endpointURL}
            onClose={onClose}
            onFinish={onFinish}
            data-testid="hellosign_embed"
        />
    </>
);

AgreementSigning.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onOpen: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    documentTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    isSigned: PropTypes.bool.isRequired,
    endpointURL: PropTypes.string.isRequired,
    onFinish: PropTypes.func.isRequired,
};

export default AgreementSigning;
