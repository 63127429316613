import {
    requestReducer,
    silentRequestReducer,
    responseReducer,
    errorReducer,
    defaultState as defaultHttpState,
} from 'utils/http';
import {
    CLEAR_ERRORS,
    GET_POSTINGS_REQ,
    GET_POSTINGS_RES,
    GET_POSTINGS_ERR,
    GET_ALL_POSTINGS_REQ,
    GET_ALL_POSTINGS_RES,
    GET_ALL_POSTINGS_ERR,
    GET_POSTING_REQ,
    GET_POSTING_RES,
    GET_POSTING_ERR,
    CLEAR_POSTING,
    GET_NEW_POSTING_RES,
    POST_POSTINGS_REQ,
    POST_POSTINGS_RES,
    POST_POSTINGS_ERR,
    PUT_POSTINGS_REQ,
    PUT_POSTINGS_RES,
    PUT_POSTINGS_ERR,
    SILENT_CLOSE_POSTING_REQ,
    CLOSE_POSTING_REQ,
    CLOSE_POSTING_RES,
    CLOSE_POSTING_ERR,
    SILENT_DEL_POSTINGS_REQ,
    DEL_POSTINGS_REQ,
    DEL_POSTINGS_RES,
    DEL_POSTINGS_ERR,
    GET_TEAM_MEMBERS_REQ,
    GET_TEAM_MEMBERS_RES,
    GET_TEAM_MEMBERS_ERR,
    GET_SKILLS_REQ,
    GET_SKILLS_RES,
    GET_SKILLS_ERR,
    GET_DEGREES_REQ,
    GET_DEGREES_RES,
    GET_DEGREES_ERR,
    GET_SPECIALIZATIONS_REQ,
    GET_SPECIALIZATIONS_RES,
    GET_SPECIALIZATIONS_ERR,
    POST_APPLICATIONS_REQ,
    POST_APPLICATIONS_RES,
    POST_APPLICATIONS_ERR,
    POST_MERGE_APPLICANTS_REQ,
    POST_MERGE_APPLICANTS_RES,
    POST_MERGE_APPLICANTS_ERR,
    SET_IS_ACTIVE,
    SET_SEARCH_BY,
    SET_ORDERING,
    SET_PAGE_SIZE,
    SET_FILTER_BY,
    CLEAR_FILTERS,
    RESET_LIST,
    ADD_SKILL_REQ,
    ADD_SKILL_RES,
} from './PostingsActions';

const initialState = {
    ...defaultHttpState,
    postings: [],
    teamMembers: [],
    specializations: [],
    skills: [],
    degrees: [],
    allPostings: [],
    numAllPostings: 0,
    posting: {},
    newPosting: {},
    application: {},
    isActive: true,
    searchBy: null,
    ordering: 'modified',
    reverseOrder: true,
    filterBy: {},
    numPages: 1,
    numPostings: 0,
    previousPage: undefined,
    nextPage: undefined,
    currentPage: 1,
    pageSize: 7,
};

const postings = (state = initialState, action) => {
    switch (action.type) {
        case SILENT_DEL_POSTINGS_REQ:
        case SILENT_CLOSE_POSTING_REQ:
        case GET_TEAM_MEMBERS_REQ:
        case GET_SKILLS_REQ:
        case GET_DEGREES_REQ:
        case GET_SPECIALIZATIONS_REQ:
        case POST_APPLICATIONS_REQ:
        case POST_MERGE_APPLICANTS_REQ:
        case ADD_SKILL_REQ:
            return {
                ...state,
                ...silentRequestReducer(),
            };
        case GET_POSTING_REQ:
        case GET_POSTINGS_REQ:
        case GET_ALL_POSTINGS_REQ:
        case PUT_POSTINGS_REQ:
        case DEL_POSTINGS_REQ:
        case POST_POSTINGS_REQ:
        case CLOSE_POSTING_REQ:
            return {
                ...state,
                ...requestReducer(),
            };
        case ADD_SKILL_RES: {
            const newSkills = state.skills;
            newSkills.push(action.payload.skill);
            return {
                ...state,
                ...responseReducer(),
                skills: newSkills,
            };
        }
        case GET_POSTING_RES:
            return {
                ...state,
                ...responseReducer(),
                posting: action.payload.posting,
            };
        case GET_NEW_POSTING_RES:
            return {
                ...state,
                ...responseReducer(),
                newPosting: action.payload.newPosting,
            };
        case GET_POSTINGS_RES:
            return {
                ...state,
                ...responseReducer(),
                postings: action.payload.postings,
                numPostings: action.payload.numPostings,
                previousPage: action.payload.previousPage,
                nextPage: action.payload.nextPage,
                currentPage: action.payload.currentPage,
            };
        case GET_ALL_POSTINGS_RES:
            return {
                ...state,
                ...responseReducer(),
                allPostings: action.payload.allPostings,
                numAllPostings: action.payload.numAllPostings,
            };
        case GET_SKILLS_RES:
            return {
                ...state,
                ...responseReducer(),
                skills: action.payload.skills,
            };
        case GET_DEGREES_RES:
            return {
                ...state,
                ...responseReducer(),
                degrees: action.payload.degrees,
            };
        case GET_TEAM_MEMBERS_RES:
            return {
                ...state,
                ...responseReducer(),
                teamMembers: action.payload.teamMembers,
            };
        case GET_SPECIALIZATIONS_RES:
            return {
                ...state,
                ...responseReducer(),
                specializations: action.payload.specializations,
            };
        case CLEAR_ERRORS:
        case DEL_POSTINGS_RES:
        case CLOSE_POSTING_RES:
            return {
                ...state,
                ...responseReducer(),
            };
        case PUT_POSTINGS_RES:
            return {
                ...state,
                ...responseReducer(),
                posting: action.payload.posting,
            };
        case POST_APPLICATIONS_RES:
        case POST_MERGE_APPLICANTS_RES:
            return {
                ...state,
                ...responseReducer(),
                application: action.payload.application,
            };
        case POST_POSTINGS_RES:
            return {
                ...state,
                ...responseReducer(),
                posting: action.payload.posting,
                newPosting: action.payload.newPosting,
            };
        case GET_POSTING_ERR:
        case GET_POSTINGS_ERR:
        case GET_ALL_POSTINGS_ERR:
        case PUT_POSTINGS_ERR:
        case DEL_POSTINGS_ERR:
        case POST_POSTINGS_ERR:
        case CLOSE_POSTING_ERR:
        case POST_APPLICATIONS_ERR:
        case POST_MERGE_APPLICANTS_ERR:
        case GET_TEAM_MEMBERS_ERR:
        case GET_SKILLS_ERR:
        case GET_DEGREES_ERR:
        case GET_SPECIALIZATIONS_ERR:
            return {
                ...state,
                ...errorReducer(action.payload.error),
            };
        case SET_IS_ACTIVE:
            return {
                ...state,
                isActive: action.payload.isActive,
                reverseOrder: false,
            };
        case SET_SEARCH_BY:
            return {
                ...state,
                searchBy: action.payload.searchBy,
            };
        case SET_ORDERING:
            return {
                ...state,
                ordering: action.payload.ordering,
                reverseOrder: action.payload.reverseOrder,
            };
        case SET_PAGE_SIZE:
            return {
                ...state,
                pageSize: action.payload.pageSize,
            };
        case SET_FILTER_BY:
        case CLEAR_FILTERS:
            return {
                ...state,
                filterBy: action.payload.filterBy,
            };
        case CLEAR_POSTING:
            return {
                ...state,
                posting: action.payload.posting,
            };
        case RESET_LIST:
            return {
                ...state,
                isActive: true,
                searchBy: null,
                ordering: 'modified',
                reverseOrder: true,
                filterBy: {},
            };
        default:
            return state;
    }
};

export default postings;
