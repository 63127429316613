// Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Select } from 'antd';

// Modules
import { getDataSet, reduce } from 'iso3166-2-db';
import { getLanguage } from 'base/BaseSelectors';
import { useSelector } from 'react-redux';

const CountrySelect = ({ initialValue, placeholder, onChange, autofocus, size }) => {
    const language = useSelector(getLanguage);
    const countriesDB = reduce(getDataSet(), language);
    // For the new country select
    const countryCodes = React.useMemo(
        () => Object.keys(countriesDB).sort((a, b) => (countriesDB[a].name > countriesDB[b].name ? 1 : -1)),
        [countriesDB]
    );

    return (
        <Select
            autoFocus={autofocus}
            size={size}
            data-testid="country"
            placeholder={placeholder}
            onChange={onChange}
            showSearch
            defaultValue={initialValue}
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
            {countryCodes.map((isoCode) => (
                <Select.Option data-testid={countriesDB[isoCode].name} key={isoCode} value={isoCode}>
                    {countriesDB[isoCode].name}
                </Select.Option>
            ))}
        </Select>
    );
};

CountrySelect.propTypes = {
    initialValue: PropTypes.string,
    placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    onChange: PropTypes.func,
    autofocus: PropTypes.bool,
    size: PropTypes.string,
};

CountrySelect.defaultProps = {
    initialValue: null,
    placeholder: null,
    onChange: () => {},
    autofocus: false,
    size: 'large',
};

export default CountrySelect;
