// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// Components
import { DriverForm } from './components';

// Modules
import withNavigation from 'views/welcome/modules/WithNavigation';

// Actions & Selectors
import { setTrackPageOrder, patchInformation, fetchWelcomeSession } from 'views/welcome/WelcomeActions';
import { getIsFetchingSilent, getInformation, getOnboardingType } from 'views/welcome/WelcomeSelectors';

const mapStateToProps = (state) => ({
    isFetchingSilent: getIsFetchingSilent(state),
    information: getInformation(state),
    onboardingType: getOnboardingType(state),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchWelcomeSession,
            patchInformation,
            setTrackPageOrder,
        },
        dispatch
    );

const propTypes = {
    // Redux Actions:
    fetchWelcomeSession: PropTypes.func.isRequired,
    patchInformation: PropTypes.func.isRequired,
    setTrackPageOrder: PropTypes.func.isRequired,
    // Redux Store:
    isFetchingSilent: PropTypes.bool.isRequired,
    information: PropTypes.object.isRequired,
    onboardingType: PropTypes.string.isRequired,
};
const defaultProps = {};

class Driver extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.setTrackPageOrder(['base']);
    }

    handleSubmit = (e, validateFields) => {
        e.preventDefault();

        validateFields((err, values) => {
            if (values.international_license) values.license_type = 'INTERNATIONAL';
            if (values.no_license) values.license_type = 'NONE';
            if (values.no_license || values.international_license) {
                values.license_prov_state = null;
                values.license_number = null;
            }
            if (!err) {
                // in case the MVR check provider is SAAQ based on the info in this track, we may need new track(s)
                // fetch the tracks and onboarding session again before handleNextTrack
                this.props
                    .patchInformation(values)
                    .then(this.props.fetchWelcomeSession)
                    .then(this.props.handleNextTrack);
            } else {
                // provide failure error
            }
        });
    };

    pages = () => ({
        base: (
            <DriverForm
                handleSubmit={this.handleSubmit}
                information={this.props.information}
                isFetchingSilent={this.props.isFetchingSilent}
            />
        ),
    });

    render() {
        return this.pages()[this.props.trackPageLocation]; /* WithNavigation */
    }
}

Driver.propTypes = propTypes;
Driver.defaultProps = defaultProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withNavigation(Driver)));
