import { postRequest, putRequest, getRequest } from 'utils/http';
import { NAMESPACE as PARENT_NAMESPACE, STATEPATH as PARENT_STATEPATH } from './GuestConstants';

// Constants
export const NAMESPACE = `${PARENT_NAMESPACE}`;
export const STATEPATH = `${PARENT_STATEPATH}`;

// Actions Types
export const GET_TEAM_REQ = `${NAMESPACE}GET_TEAM_REQ`;
export const GET_TEAM_RES = `${NAMESPACE}GET_TEAM_RES`;
export const GET_TEAM_ERR = `${NAMESPACE}GET_TEAM_ERR`;
export const GET_TEAM_LISTINGS_REQ = `${NAMESPACE}GET_TEAM_LISTINGS_REQ`;
export const GET_TEAM_LISTINGS_RES = `${NAMESPACE}GET_TEAM_LISTINGS_RES`;
export const GET_TEAM_LISTINGS_ERR = `${NAMESPACE}GET_TEAM_LISTINGS_ERR`;
export const GET_BUILDING_REQ = `${NAMESPACE}GET_BUILDING_REQ`;
export const GET_BUILDING_RES = `${NAMESPACE}GET_BUILDING_RES`;
export const GET_BUILDING_ERR = `${NAMESPACE}GET_BUILDING_ERR`;
export const GET_TEAM_PROPERTIES_REQ = `${NAMESPACE}GET_TEAM_PROPERTIES_REQ`;
export const GET_TEAM_PROPERTIES_RES = `${NAMESPACE}GET_TEAM_PROPERTIES_RES`;
export const GET_TEAM_PROPERTIES_ERR = `${NAMESPACE}GET_TEAM_PROPERTIES_ERR`;
export const POST_RESET_PASSWORD_REQ = `${NAMESPACE}POST_RESET_PASSWORD_REQ`;
export const POST_RESET_PASSWORD_RES = `${NAMESPACE}POST_RESET_PASSWORD_RES`;
export const POST_RESET_PASSWORD_ERR = `${NAMESPACE}POST_RESET_PASSWORD_ERR`;
export const PUT_RESET_PASSWORD_REQ = `${NAMESPACE}PUT_RESET_PASSWORD_REQ`;
export const PUT_RESET_PASSWORD_RES = `${NAMESPACE}PUT_RESET_PASSWORD_RES`;
export const PUT_RESET_PASSWORD_ERR = `${NAMESPACE}PUT_RESET_PASSWORD_ERR`;
export const GET_REFERENCE_QUESTIONS_REQ = `${NAMESPACE}GET_REFERENCE_QUESTIONS_REQ`;
export const GET_REFERENCE_QUESTIONS_RES = `${NAMESPACE}GET_REFERENCE_QUESTIONS_RES`;
export const GET_REFERENCE_QUESTIONS_ERR = `${NAMESPACE}GET_REFERENCE_QUESTIONS_ERR`;
export const SUBMIT_REFERENCE_QUESTIONS_REQ = `${NAMESPACE}SUBMIT_REFERENCE_QUESTIONS_REQ`;
export const SUBMIT_REFERENCE_QUESTIONS_RES = `${NAMESPACE}SUBMIT_REFERENCE_QUESTIONS_RES`;
export const SUBMIT_REFERENCE_QUESTIONS_ERR = `${NAMESPACE}SUBMIT_REFERENCE_QUESTIONS_ERR`;
export const GET_DISPUTE_REQ = `${NAMESPACE}GET_DISPUTE_REQ`;
export const GET_DISPUTE_RES = `${NAMESPACE}GET_DISPUTE_RES`;
export const GET_DISPUTE_ERR = `${NAMESPACE}GET_DISPUTE_ERR`;
export const PUT_DISPUTE_REQ = `${NAMESPACE}PUT_DISPUTE_REQ`;
export const PUT_DISPUTE_RES = `${NAMESPACE}PUT_DISPUTE_RES`;
export const PUT_DISPUTE_ERR = `${NAMESPACE}PUT_DISPUTE_ERR`;
export const CLEAR_LOGIN = `${NAMESPACE}CLEAR_LOGIN`;
export const POST_TALENTHUB_LOGIN_REQ = `${NAMESPACE}POST_TALENTHUB_LOGIN_REQ`;
export const POST_TALENTHUB_LOGIN_RES = `${NAMESPACE}POST_TALENTHUB_LOGIN_RES`;
export const POST_TALENTHUB_LOGIN_ERR = `${NAMESPACE}POST_TALENTHUB_LOGIN_ERR`;

// Action Creators

export const updateDispute = (applicantId, data, mode) => (dispatch) =>
    new Promise((resolve, reject) => {
        // TODO: consolidate hr & pm, I hate this "mode" thing
        const isHR = !!(mode === 'hr');
        dispatch({
            type: PUT_DISPUTE_REQ,
        });
        return putRequest({
            internal: isHR,
            hr: isHR,
            version: isHR ? 'v1' : 'v2',
            endpoint: `applicants/${applicantId}/dispute/`,
            body: JSON.stringify(data),
        }).then(
            (response) => {
                dispatch({
                    type: PUT_DISPUTE_RES,
                    payload: { dispute: response },
                });
                resolve(response);
            },
            (error) => {
                dispatch({
                    type: PUT_DISPUTE_ERR,
                    payload: { error },
                });
                reject(error);
            }
        );
    });

export const fetchDispute = (applicantId) => (dispatch) => {
    dispatch({
        type: GET_DISPUTE_REQ,
    });
    return getRequest({
        internal: true,
        hr: true,
        version: 'v1',
        endpoint: `applicants/${applicantId}/dispute/`,
    }).then(
        (response) => {
            dispatch({
                type: GET_DISPUTE_RES,
                payload: { dispute: response },
            });
            return {
                ...response,
                mode: 'hr',
            };
        },
        () =>
            getRequest({
                internal: false,
                hr: false,
                version: 'v2',
                endpoint: `applicants/${applicantId}/dispute/`,
            }).then(
                (response) => {
                    dispatch({
                        type: GET_DISPUTE_RES,
                        payload: { dispute: response },
                    });
                    return {
                        ...response,
                        mode: 'pm',
                    };
                },
                (error) => {
                    dispatch({
                        type: GET_DISPUTE_ERR,
                        payload: { error },
                    });
                    throw error;
                }
            )
    );
};

export const fetchTeam = (teamId) => (dispatch) => {
    dispatch({
        type: GET_TEAM_REQ,
    });
    return getRequest({
        internal: false,
        version: 'v2',
        endpoint: `public/${teamId}/`,
    }).then(
        (response) => {
            dispatch({
                type: GET_TEAM_RES,
                payload: { team: response },
            });
        },
        (error) => {
            dispatch({
                type: GET_TEAM_ERR,
                payload: { error },
            });
            throw error;
        }
    );
};

export const fetchTeamProperties = (teamId) => (dispatch) => {
    dispatch({
        type: GET_TEAM_PROPERTIES_REQ,
    });
    return getRequest({
        internal: false,
        hr: false,
        version: 'v2',
        endpoint: `public/${teamId}/properties/?ordering=${'address'}`,
    }).then(
        (response) => {
            dispatch({
                type: GET_TEAM_PROPERTIES_RES,
                payload: { teamProperties: response.results },
            });
        },
        (error) => {
            dispatch({
                type: GET_TEAM_PROPERTIES_ERR,
                payload: { error },
            });
            throw error;
        }
    );
};

export const fetchBuilding = (urlCode) => (dispatch) => {
    dispatch({
        type: GET_BUILDING_REQ,
    });
    return getRequest({
        internal: true,
        hr: false,
        version: 'v1',
        endpoint: `applicant/properties/${urlCode}/`,
    }).then(
        (response) => {
            dispatch({
                type: GET_BUILDING_RES,
                payload: { building: response },
            });
        },
        (error) => {
            dispatch({
                type: GET_BUILDING_ERR,
                payload: { error },
            });
            throw error;
        }
    );
};

export const fetchTeamListings = (type = 'PM', teamId, propertyId) => (dispatch) => {
    const isHr = type === 'HR';

    const page = 1;
    const pageSize = 9999;
    const search = '';
    const ordering = 'modified';

    dispatch({
        type: GET_TEAM_LISTINGS_REQ,
    });
    return getRequest({
        internal: false,
        hr: isHr,
        version: isHr ? 'v1' : 'v2',
        endpoint: `public/${teamId}/listings/?page=${page}&page_size=${pageSize}&search=${search}&ordering=${ordering}${
            propertyId && `&property_id=${propertyId}`
        }`,
    }).then(
        (response) => {
            dispatch({
                type: GET_TEAM_LISTINGS_RES,
                payload: { teamListings: response.results },
            });
        },
        (error) => {
            dispatch({
                type: GET_TEAM_LISTINGS_ERR,
                payload: { error },
            });
            throw error;
        }
    );
};

export const clearLogin = () => (dispatch) => {
    dispatch({
        type: CLEAR_LOGIN,
    });
};

export const requestForgotPassword = (email) => (dispatch) => {
    dispatch({
        type: POST_RESET_PASSWORD_REQ,
    });
    return postRequest({
        internal: true,
        endpoint: '/reset_password/',
        body: JSON.stringify(email),
    }).then(
        (response) =>
            dispatch({
                type: POST_RESET_PASSWORD_RES,
                payload: { response },
            }),
        (error) => {
            dispatch({
                type: POST_RESET_PASSWORD_ERR,
                payload: { error },
            });
            throw error;
        }
    );
};

export const resetPassword = (data) => (dispatch) => {
    const body = {
        password: data.password,
        reenter_password: data.repeat_password,
    };
    dispatch({
        type: PUT_RESET_PASSWORD_REQ,
    });
    return putRequest({
        internal: true,
        endpoint: `/reset_password/${data.token}/`,
        body: JSON.stringify(body),
    }).then(
        (response) =>
            dispatch({
                type: PUT_RESET_PASSWORD_RES,
                payload: { response },
            }),
        (error) => {
            dispatch({
                type: PUT_RESET_PASSWORD_ERR,
                payload: { error },
            });
            throw error;
        }
    );
};

export const talentHubLogin = (data) => (dispatch) => {
    dispatch({ type: POST_TALENTHUB_LOGIN_REQ });
    return postRequest({
        exactUrl: true,
        endpoint: '/talent_hub/onboarding/',
        body: JSON.stringify(data),
    }).then(
        (response) => {
            dispatch({
                type: POST_TALENTHUB_LOGIN_RES,
            });
            if (response.url) window.location.href = response.url;
        },
        (error) => {
            dispatch({
                type: POST_TALENTHUB_LOGIN_ERR,
                payload: { error },
            });
            throw error;
        }
    );
};
