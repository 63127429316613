// Libraries
import React from 'react';
import { FormattedMessage } from 'react-intl';

// Routes & Router
import { withRouter } from 'react-router-dom';

// UI Components
import Title from 'certn-ui/Title';
import { BackButton } from 'views/welcome/components';
import { ButtonLine } from 'certn-ui/Button';

// Sentry
import * as Sentry from '@sentry/browser';

class RouteErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            numErrors: 0,
            supportEmail: 'support@certn.co',
        };

        props.history.listen(() => {
            if (this.state.hasError) {
                this.setState({
                    hasError: false,
                });
            }
        });
    }

    // Can use error and info on caught
    componentDidCatch(error, info) {
        this.setState(({ numErrors }) => ({ hasError: true, numErrors: numErrors + 1 }));
        Sentry.withScope((scope) => {
            Object.keys(info).forEach((key) => {
                scope.setExtra(key, info[key]);
            });
            Sentry.captureException(error);
        });
    }

    handleReverse = () => {
        this.props.history.goBack();
    };

    errorQuestion = (numErrors) =>
        numErrors <= 3 ? (
            <FormattedMessage
                id="c5a5b.RouteErrorBoundary.sorry"
                defaultMessage="Sorry, something went wrong, please try hitting back to give it another shot"
            />
        ) : (
            <FormattedMessage
                id="c5a5b.RouteErrorBoundary.noticed"
                defaultMessage="We've noticed you've been seeing a number of errors, sorry for the trouble, please contact {email} and someone will be happy to help"
                values={{ email: this.state.supportEmail }}
            />
        );

    render() {
        const { hasError, numErrors } = this.state;
        return hasError ? (
            <>
                <ButtonLine>
                    <BackButton onClick={this.handleReverse} />
                </ButtonLine>
                <Title>{this.errorQuestion(numErrors)}</Title>
            </>
        ) : (
            this.props.children
        );
    }
}

export default withRouter(RouteErrorBoundary);
