import styled from 'styled-components/macro';

const ReportLoadingWrapper = styled.div`
    display: flex;
    flex: 90%;
    justify-content: center;
    align-items: center;
    z-index: 5;
    border: 1px solid ${({ theme }) => theme.color.certnGray100};
    margin-top: 20px;
    box-shadow: -2px 3px 6px 0px rgba(0, 0, 0, 0.12), 2px 3px 6px 0px rgba(0, 0, 0, 0.12);
`;

export { ReportLoadingWrapper };
