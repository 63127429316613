// Libraries
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash';
import { intl } from 'components/GlobalProvider';
import { FormattedMessage } from 'react-intl';

// Actions & Selectors
import { saveTeam } from 'views/manager/views/admin/AdminActions';
import { getTeam, getIsFetching } from 'views/manager/views/admin/AdminSelectors';
import { getUser, getUserInfo } from 'base/BaseSelectors';
import SettingsPaymentButton from 'views/manager/views/settings/components/SettingsPaymentButton';

import { Form as AntdForm } from '@ant-design/compatible';

// UI Components
import { Button, message, Modal } from 'antd';
import { CountrySelect, ProvStateSelect } from 'components';

import { ErrorAlertAPI, ErrorAlertCustom } from 'certn-ui/ErrorAlert';
import { ContentLink } from 'certn-ui/Text';
import Loader from 'certn-ui/Loader';
import Form from 'certnd/Form';
import Typography from 'certnd/Typography';
import {
    updateSettings,
    fetchButtercupInvoices,
    fetchInvoiceCSV,
    removePaymentCardThenMessage,
} from 'views/manager/views/settings/SettingsActions';

import { getInvoices } from 'views/manager/views/settings/SettingsSelectors';

// import { getSettings, getPaymentVerified, getInvoices } from '../../SettingsSelectors';
import InvoicesList from './components/InvoicesList';
// import BatchCsvDownload from './components/BatchCsvDownload';
import styled from 'styled-components';

const { Heading } = Typography;

const NoticeFrame = styled.div`
    display: flex;
    justify-content: ${({ center }) => (center ? 'center' : 'space-between')};
    width: 100%;
    padding: 10px;
    min-height: 55px;
    margin: 5px 0px;
    border: 1px solid ${({ theme }) => theme.color.certnGray200};
    background-color: ${({ theme }) => theme.color.certnGray100};
    box-sizing: border-box;
    border-radius: 5px;
`;

const Billing = AntdForm.create()(({ form }) => {
    const dispatch = useDispatch();
    const team = useSelector(getTeam);
    const user = useSelector(getUser);
    const isFetching = useSelector(getIsFetching);
    const userInfo = useSelector(getUserInfo);
    const settings = get(userInfo, ['team', 'settings_config']);
    const invoices = useSelector(getInvoices);
    const paymentVerified = team.payment_verified;
    const [country, setCountry] = useState(team.country);
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const teamId = team.id;
    useEffect(() => {
        const handleFetchButtercupInvoices = () => {
            setLoading(true);
            setError(false);
            dispatch(fetchButtercupInvoices(teamId)).then((status) => {
                if (status !== 200) setError(true);
                setLoading(false);
            });
        };

        handleFetchButtercupInvoices(teamId);
    }, [dispatch, teamId]);

    const handleFetchInvoiceCSV = (dateRange, useInvoiceTeamId, invoice_team_id = '') => {
        setDisabled(true);
        dispatch(fetchInvoiceCSV(dateRange, useInvoiceTeamId, teamId, invoice_team_id))
            .then(() => {
                setDisabled(false);
                message.success(
                    intl.formatMessage(
                        {
                            id: 'a2509.Billing.csvSent',
                            defaultMessage: 'Generating CSV report. {email} will receive it in a few minutes.',
                        },
                        { email: user.email }
                    )
                );
            })
            .catch(() => {
                ErrorAlertCustom({
                    title: 'No Applicants Found',
                    description: 'No applicants found within date range, try another range of dates.',
                });
                setDisabled(false);
            });
    };

    const handleRemovePaymentCard = () => {
        dispatch(removePaymentCardThenMessage(teamId));
        form.resetFields();
    };

    const showRemovePaymentConfirm = () => {
        Modal.confirm({
            title: intl.formatMessage({
                id: 'a2509.Billing.RemovePaymentTitle',
                defaultMessage: 'Are you sure you want to remove your payment card?',
            }),
            content: intl.formatMessage({
                id: 'a2509.Billing.RemovePaymentContent',
                defaultMessage: 'You will need to add a new card to continue screening.',
            }),
            okType: 'danger',
            okText: intl.formatMessage({
                id: 'common.ok',
                defaultMessage: 'OK',
            }),
            cancelText: intl.formatMessage({
                id: 'common.cancel',
                defaultMessage: 'Cancel',
            }),
            // We avoid the "Actions must be plain objects. Use custom middleware for async actions" error
            onOk: handleRemovePaymentCard,
            onCancel() {},
        });
    };

    const handleCountryChange = (value) => {
        setCountry(value);
        form.setFieldsValue({
            'team.province_state': undefined,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                // TODO: Would be nice to unite
                // Edge case: one saves, other doesn't -- user will be informed saving failed
                Promise.all([
                    dispatch(updateSettings(values.settings, teamId)),
                    dispatch(saveTeam(values.team, teamId)),
                ])
                    .then(() =>
                        message.success(
                            intl.formatMessage({
                                id: 'common.settingsSavedSuccessfully',
                                defaultMessage: 'Settings saved successfully.',
                            })
                        )
                    )
                    .catch((error) => ErrorAlertAPI(error));
            }
        });
    };

    return isFetching || loading ? (
        <Loader />
    ) : (
        <AntdForm onSubmit={handleSubmit}>
            <Heading.H1 data-testid="title">
                <FormattedMessage id="a2509.Billing.PageTitle" defaultMessage="Billing" />
            </Heading.H1>

            {paymentVerified ? (
                <Button type="primary" style={{ width: '230px' }} onClick={showRemovePaymentConfirm}>
                    <FormattedMessage id="a2509.Billing.RemovePaymentButton" defaultMessage="Remove Payment Method" />
                </Button>
            ) : (
                <SettingsPaymentButton />
            )}
            <BillingFormFields
                form={form}
                team={team}
                settings={settings}
                invoices={invoices}
                country={country}
                handleCountryChange={handleCountryChange}
                handleFetchInvoiceCSV={handleFetchInvoiceCSV}
                disabled={disabled}
                loading={loading}
                error={error}
            />
        </AntdForm>
    );
});

/* eslint-disable @typescript-eslint/no-unused-vars */
const BillingFormFields = ({
    form,
    team,
    settings,
    invoices,
    country,
    handleCountryChange,
    handleFetchInvoiceCSV,
    disabled,
    loading,
    error,
}) => (
    <>
        <Form.Block>
            <Heading.H3>
                {!team.province_state ? (
                    <FormattedMessage
                        id="a2509.Billing.TeamBillingTitleInactive"
                        defaultMessage="Team Billing Info (Complete To Activate Account)"
                    />
                ) : (
                    <FormattedMessage id="a2509.Billing.TeamBillingTitle" defaultMessage="Team Billing Info" />
                )}
            </Heading.H3>

            <Form.Input
                form={form}
                fieldName="team.accounting_first_name"
                title={intl.formatMessage({ id: 'a2509.Billing.FirstName', defaultMessage: 'Accounting First Name' })}
                options={{
                    initialValue: team.accounting_first_name,
                    rules: [
                        {
                            required: true,
                            error: intl.formatMessage({
                                id: 'a2509.Billing.FirstNameError',
                                defaultMessage: 'Please enter your accounting first name',
                            }),
                        },
                    ],
                }}
            />

            <Form.Input
                form={form}
                fieldName="team.accounting_last_name"
                title={intl.formatMessage({ id: 'a2509.Billing.LastName', defaultMessage: 'Accounting Last Name' })}
                options={{
                    initialValue: team.accounting_last_name,
                    rules: [
                        {
                            required: true,
                            error: intl.formatMessage({
                                id: 'a2509.Billing.LastNameError',
                                defaultMessage: 'Please enter your accounting last name',
                            }),
                        },
                    ],
                }}
            />

            <Form.Input
                form={form}
                fieldName="team.accounting_email"
                title={intl.formatMessage({ id: 'a2509.Billing.AccountingEmail', defaultMessage: 'Accounting Email' })}
                options={{
                    initialValue: team.accounting_email,
                    rules: [
                        {
                            required: true,
                            message: 'Please enter your accounting email address',
                        },
                    ],
                }}
            />

            <Form.Input
                form={form}
                fieldName="team.accounting_email_cc"
                title={intl.formatMessage({ id: 'a2509.Billing.TeamCcEmails', defaultMessage: 'CC Emails' })}
                description={intl.formatMessage({
                    id: 'a2509.Billing.CcEmailsDescription',
                    defaultMessage: `All accounting emails will be CC’d to the following addresses. Separate multiple emails with a comma. Maximum 5 emails.`,
                })}
                options={{
                    initialValue: team.accounting_email_cc,
                }}
            />

            <Form.Input
                form={form}
                fieldName="team.street_address"
                title={intl.formatMessage({ id: 'a2509.Billing.StreetAddress', defaultMessage: 'Street Address' })}
                options={{
                    initialValue: team.street_address,
                }}
            />

            <Form.Input
                form={form}
                fieldName="team.city"
                title={intl.formatMessage({ id: 'common.city', defaultMessage: 'City' })}
                options={{
                    initialValue: team.city,
                }}
            />

            <Heading.H4 disableGutter>
                <FormattedMessage id="address.provinceState" defaultMessage="Province/State" />
            </Heading.H4>
            <AntdForm.Item label={null}>
                {form.getFieldDecorator('team.province_state', {
                    initialValue: team.province_state,
                    rules: [
                        {
                            required: true,
                            message: intl.formatMessage({
                                id: 'a2509.Billing.ProvStateRule',
                                defaultMessage: 'Please select a province/state!',
                            }),
                        },
                    ],
                })(<ProvStateSelect selectedCountry={country} />)}
            </AntdForm.Item>

            <Heading.H4 disableGutter>
                <FormattedMessage id="common.country" defaultMessage="Country" />
            </Heading.H4>
            <AntdForm.Item label={null}>
                {form.getFieldDecorator('team.country', {
                    rules: [
                        {
                            required: true,
                            message: intl.formatMessage({
                                id: 'a2509.Billing.CountryError',
                                defaultMessage: 'Please select a country!',
                            }),
                        },
                    ],
                })(<CountrySelect initialValue={country} size="medium" onChange={handleCountryChange} />)}
            </AntdForm.Item>

            <Form.Input
                form={form}
                fieldName="team.postal_code"
                title={intl.formatMessage({ id: 'common.postalZip', defaultMessage: 'Postal Code/Zip Code' })}
                options={{
                    initialValue: team.postal_code,
                }}
            />

            <Form.Input
                form={form}
                fieldName="team.industry"
                title={intl.formatMessage({ id: 'a2509.Billing.Industry', defaultMessage: 'Industry' })}
                options={{
                    initialValue: team.industry,
                }}
            />

            <Form.Input
                form={form}
                fieldName="team.website"
                title={intl.formatMessage({ id: 'a2509.Billing.Website', defaultMessage: 'Website' })}
                options={{
                    initialValue: team.website,
                }}
            />

            <Form.Input
                form={form}
                fieldName="team.business_number"
                title={intl.formatMessage({ id: 'a2509.Billing.TeamBizNum', defaultMessage: 'Business Number' })}
                options={{
                    initialValue: team.business_number,
                }}
            />
            <Form.Button>
                <FormattedMessage id="common.save" defaultMessage="Save" />
            </Form.Button>
        </Form.Block>

        {/* TODO: BIT-769 uncomment this when backend is ready */}
        {/* <Form.Block>
            <BatchCsvDownload
                form={form}
                invoices={invoices}
                handleFetchInvoiceCSV={handleFetchInvoiceCSV}
                disabled={disabled}
            />
        </Form.Block> */}
        <Form.Block>
            <Heading.H3>
                <FormattedMessage id="a2509.Billing.Invoices" defaultMessage="Invoices" />
            </Heading.H3>
            {error ? (
                <NoticeFrame>
                    <FormattedMessage
                        id="a2509.Billing.Timeout"
                        defaultMessage="An unexpected error occurred while loading your invoice(s). Please refresh the page.{br}If your invoice(s) does not load after refreshing, please contact {billingEmail} for a copy to be emailed to you."
                        values={{
                            billingEmail: <ContentLink href="mailto:billing@certn.co">billing@certn.co</ContentLink>,
                            br: (
                                <>
                                    <br />
                                    <br />
                                </>
                            ),
                        }}
                    />
                </NoticeFrame>
            ) : invoices.length === 0 ? (
                <NoticeFrame>
                    <FormattedMessage
                        id="a2509.Billing.Maintenance"
                        defaultMessage="Billing is currently being improved and may be temporarily unavailable. If an invoice is not listed, please reach out to {billingEmail} to get a copy."
                        values={{
                            billingEmail: <ContentLink href="mailto:billing@certn.co">billing@certn.co</ContentLink>,
                        }}
                    />
                </NoticeFrame>
            ) : (
                ''
            )}

            <InvoicesList invoices={invoices} disabled={disabled} />
        </Form.Block>
    </>
);

export default Billing;
