import React from 'react';
import { useDispatch } from 'react-redux';
import { Menu, Modal, message } from 'antd';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { intl } from 'components/GlobalProvider';
import { useHistory } from 'react-router-dom';

import { ListCellText } from 'certn-ui/List';
import { ErrorAlertCustom } from 'certn-ui/ErrorAlert';

import { deleteApplication } from 'views/manager/views/hr/views/applications/ApplicationsActions';

const ItemDelete = ({ applicantId, applicantEmail, report, ...rest }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const showConfirmDelete = () => {
        const content = applicantEmail
            ? intl.formatMessage(
                  {
                      id: '004e5.StatusDropdown.confirmDeleteWithEmail',
                      defaultMessage: 'You will not be able to retrieve the application for {email} after deletion.',
                  },
                  { email: applicantEmail }
              )
            : intl.formatMessage({
                  id: '004e5.StatusDropdown.confirmDeleteNoEmail',
                  defaultMessage: 'You will not be able to retrieve this application after deletion.',
              });
        Modal.confirm({
            title: intl.formatMessage({
                id: '004e5.StatusDropdown.modalDeleteThisApplication',
                defaultMessage: 'Are you sure you want to delete this application?',
            }),
            content,
            okType: 'danger',
            onOk: async () => {
                try {
                    await dispatch(deleteApplication(applicantId));
                    message.success(
                        intl.formatMessage({
                            id: '004e5.StatusDropdown.applicationDeleted',
                            defaultMessage: 'Application deleted',
                        })
                    );
                    if (report) history.push(`/hr/applications`);
                } catch (error) {
                    ErrorAlertCustom({
                        title: intl.formatMessage({
                            id: '004e5.StatusDropdown.errorDeleting',
                            defaultMessage: 'Error deleting application',
                        }),
                    });
                }
            },
        });
    };

    return (
        <Menu.Item {...rest}>
            <ListCellText clickable critical onClick={showConfirmDelete}>
                <FormattedMessage id="004e5.StatusDropdown.deleteApplication" defaultMessage="Delete Application" />
            </ListCellText>
        </Menu.Item>
    );
};

ItemDelete.propTypes = {
    applicantId: PropTypes.string.isRequired,
    applicantEmail: PropTypes.string.isRequired,
    report: PropTypes.bool,
};

ItemDelete.defaultProps = {
    report: false,
};

export default ItemDelete;
