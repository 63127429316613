// Modules
import { AppearRight } from 'certn-ui/Animate';
import { useDispatch } from 'react-redux';
import { patchInformation } from 'views/welcome/WelcomeActions';
import DBSProfileNumberForm, { DBSProfileNumberFormValues } from './components/DBSProfileNumberForm';
import withNavigation from 'views/welcome/modules/WithNavigation';
import { yesNoToBool } from 'views/welcome/yesNoHelpers';

interface Props {
    handleNextTrack: () => void;
}

const DBSProfileNumber = ({ handleNextTrack }: Props) => {
    const dispatch = useDispatch();

    const handleSubmit = async (values: DBSProfileNumberFormValues) => {
        await dispatch(
            patchInformation({
                dbs_profile_number_exists: yesNoToBool(values.dbs_profile_number_exists_yesno),
                dbs_profile_number: values.dbs_profile_number,
            })
        );
        handleNextTrack();
    };

    return (
        <AppearRight>
            <DBSProfileNumberForm handleSubmit={handleSubmit} />
        </AppearRight>
    );
};

export default withNavigation(DBSProfileNumber);
