// Libraries
import React from 'react';
// UI Components
import { TitleHeader } from 'views/manager/components/CertnTable';

const CertnTableCustomHeader = ({ data, title, reverseOrder, setOrdering, ordering }) => (
    <TitleHeader
        title={title}
        handleClick={() => (data.active ? setOrdering(data.active) : null)}
        active={data.active ? ordering === data.active : false}
        clickable={data.active}
        reverseOrder={reverseOrder}
    />
);
CertnTableCustomHeader.propTypes = {};
export default CertnTableCustomHeader;
