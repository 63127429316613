// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { message } from 'antd';

// UI Components
import Titlebar from 'certn-ui/Titlebar';
import { BackButton } from 'certn-ui/Button';
import { ErrorAlertCustom } from 'certn-ui/ErrorAlert';

// Actions & Selectors
import { createProperty } from 'views/manager/views/pm/views/properties/PropertiesActions';
import { getSettings } from 'views/manager/views/settings/SettingsSelectors';
import { updateAddressError } from 'base/BaseActions';
import { handleBuildingSelect } from 'views/manager/features/ScreenApplicant/ScreenApplicantActions';
import { getShouldPersistScreenApplicantState } from 'views/manager/features/ScreenApplicant/ScreenApplicantSelectors';

// Other Components
import { PropertiesForm } from 'views/manager/views/pm/views/properties/components';

// Modules
import { OTHER } from 'modules/Countries';

const mapStateToProps = (state) => ({
    settings: getSettings(state),
    shouldPersistScreenApplicantState: getShouldPersistScreenApplicantState(state),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            createProperty,
            updateAddressError,
            handleBuildingSelect,
        },
        dispatch
    );

const propTypes = {
    // Redux Actions
    createProperty: PropTypes.func.isRequired,
    // Redux State
    settings: PropTypes.object.isRequired,
    shouldPersistScreenApplicantState: PropTypes.bool.isRequired,
};
const defaultProps = {};

class PropertiesNew extends React.Component {
    state = {
        defaultValues: {},
    };

    handlePostSubmit = (response) => {
        if (this.props.shouldPersistScreenApplicantState) {
            this.props.handleBuildingSelect(response?.id);
            message.success('Building Added');
            return this.props.history.push('/pm/screen/applicant');
        }
        return this.props.history.push('/pm/properties?property_created=true');
    };

    onSubmitProperty = (propertyData) => {
        const values = {
            province_state: OTHER,
            other_province_state: null,
            ...propertyData,
        };
        this.props
            .createProperty(values)
            .then((response) => this.handlePostSubmit(response))
            .catch(() =>
                ErrorAlertCustom({
                    title: 'Custom Link already in use.',
                    description:
                        'Please try another custom link name. If you continue to have issues, use our live chat support or contact support@certn.co',
                })
            );
    };

    render() {
        return (
            <>
                {this.props.shouldPersistScreenApplicantState ? (
                    <BackButton label="BACK" onClick={() => this.props.history.push('/pm/screen/applicant')} />
                ) : (
                    <BackButton label="BUILDINGS" onClick={() => this.props.history.push('/pm/properties/')} />
                )}
                <Titlebar title="Create a New Building" />
                <PropertiesForm
                    property={this.state.defaultValues}
                    onSubmit={this.onSubmitProperty}
                    updateAddressError={this.props.updateAddressError}
                />
            </>
        );
    }
}

PropertiesNew.propTypes = propTypes;
PropertiesNew.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(PropertiesNew);
