// Libraries
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

// Components
import SummaryItem from './SummaryItem';
import SummaryHeader from './SummaryHeader';
import { FlexRow, SummarySection, SummaryContainer } from './SummaryStyles';

const Documents = ({ documents, goToTrack }) => (
    <SummaryContainer>
        <SummaryHeader
            title={<FormattedMessage id="welcome.Summary.documents" defaultMessage="Documents" />}
            onClick={goToTrack}
        />

        <SummarySection>
            {documents.length === 0 && (
                <SummaryItem
                    empty
                    title={
                        <FormattedMessage
                            id="welcome.Summary.noDocumentsProvided"
                            defaultMessage="You have not provided any documents"
                        />
                    }
                />
            )}
            {documents.map((document, i) => (
                <React.Fragment key={i}>
                    <FlexRow>
                        <SummaryItem
                            title={
                                <FormattedMessage id="welcome.Summary.documentType" defaultMessage="Document Type" />
                            }
                            text={document.document_type}
                        />
                        <SummaryItem
                            title={<FormattedMessage id="common.firstName" defaultMessage="First Name" />}
                            text={document.file_name}
                        />
                    </FlexRow>
                </React.Fragment>
            ))}
        </SummarySection>
    </SummaryContainer>
);

export default injectIntl(Documents);
