import React from 'react';
import styled from 'styled-components/macro';
import { FormattedMessage } from 'react-intl';
import { ClockCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { orderBy } from 'lodash';
import { useSelector } from 'react-redux';

// Selectors
import { getUser } from 'base/BaseSelectors';

export const AvatarList = ({ list, width, userMode, history }) => {
    const user = useSelector(getUser);
    const ordList = orderBy(list, ['first_name'], ['desc']);
    const begList = ordList.slice(0, 2);
    const endList = ordList.slice(2);
    const avList = begList.map((av) => (
        <Avatar
            width={width}
            firstName={av.first_name}
            lastName={av.last_name}
            email={av.email}
            tooltip
            hover={
                <FormattedMessage
                    id="62d4c.Avatar.wait"
                    defaultMessage="Waiting on: {email}"
                    values={{ email: av.email }}
                />
            }
            active={av.status === 'Returned'}
            onClick={
                user.can_view_reports ? () => history.push(`/${userMode.toLowerCase()}/applications/${av.id}`) : null
            }
            key={av.id}
        />
    ));
    if (endList.length > 0) {
        const plusAvatars = (
            <AvatarListContainer>
                {endList.map((av) => (
                    <Avatar
                        width={width}
                        firstName={av.first_name}
                        lastName={av.last_name}
                        email={av.email}
                        tooltip
                        hover={
                            <FormattedMessage
                                id="62d4c.Avatar.wait"
                                defaultMessage="Waiting on: {email}"
                                values={{ email: av.email }}
                            />
                        }
                        active={av.status === 'Returned'}
                        onClick={
                            user.can_view_reports
                                ? () => history.push(`/${userMode.toLowerCase()}/applications/${av.id}`)
                                : null
                        }
                        key={av.id}
                    />
                ))}
            </AvatarListContainer>
        );
        avList.push(<Avatar defaultLabel={`+${endList.length}`} tooltip active={false} hover={plusAvatars} />);
    }
    return <AvatarListContainer>{avList}</AvatarListContainer>;
};

const Avatar = ({ width, defaultLabel, hover, firstName, lastName, email, active = false, onClick, size }) => {
    let label = defaultLabel || <CertnIcon />;
    let fullName;

    if (firstName && lastName && email) {
        label = (firstName.charAt(0) + lastName.charAt(0)).toUpperCase();
        fullName = `${firstName} ${lastName} (${email})`;
    }

    return (
        <AvatarContainer width={width} size={size}>
            <Tooltip title={fullName || hover} arrowPointAtCenter mouseLeaveDelay={0.05}>
                <AvatarCircle active={active} size={size} onClick={active ? onClick : undefined}>
                    <AvatarText>{label}</AvatarText>
                </AvatarCircle>
            </Tooltip>
        </AvatarContainer>
    );
};
export default Avatar;

export const CosignerAvatar = () => (
    <AvatarContainer>
        <Tooltip
            title={<FormattedMessage id="62d4c.Avatar.cosign" defaultMessage="Cosigner" />}
            arrowPointAtCenter
            mouseLeaveDelay={0.05}
        >
            <AvatarCircle>
                <CertnIcon type="form" certncolor="certnGray600" />
            </AvatarCircle>
        </Tooltip>
    </AvatarContainer>
);

const CertnIcon = styled(ClockCircleOutlined)`
    font-size: 16px;
    color: ${(props) => props.theme.color[props.certncolor]};
`;

const AvatarListContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    width: fit-content;
    margin-right: 10px;
`;

const AvatarContainer = styled.div`
    text-align: center !important;
    width: ${(props) => (props.size ? props.size - 10 : 30)}px !important;
    ${(props) => props.width && `width: ${props.width}`};
`;

const AvatarCircle = styled.div`
    background: ${(props) => (props.certncolor ? props.theme.color[props.certncolor] : props.theme.color.certnGray200)};
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    border: 2px solid ${({ theme }) => theme.color.certnWhite};
    box-sizing: border-box;
    border-radius: 50%;
    min-width: ${(props) => props.size || 45}px;
    min-height: ${(props) => props.size || 45}px;
    height: 100%;
    white-space: nowrap;
    cursor: ${(props) => (props.active ? 'pointer' : 'default')};
    &:hover {
        filter: opacity(90%);
    }
`;

const AvatarText = styled.span`
    color: ${(props) => props.theme.color.certnGray600};
    font-size: 12px;
    font-weight: 700;
`;
