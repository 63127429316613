// Libraries
import React from 'react';
import { FormattedMessage } from 'react-intl';

import SummaryHeader from './SummaryHeader';
import SummaryItem from './SummaryItem';
import { SummaryContainer, SummarySection, SubTitleWrapper, LineBreak, FlexRow } from './SummaryStyles';

interface PropTypes {
    socialMediaDetails: {
        college_country: string;
        college_name: string;
        high_school_name: string;
        instagram_url: string;
        facebook_url: string;
        tiktok_url: string;
        linkedin_url: string;
        twitter_url: string;
        reddit_url: string;
    };
    goToTrack: (track: string) => void;
}

const SocialMediaDetails: React.FC<PropTypes> = ({ socialMediaDetails, goToTrack }: PropTypes) => {
    const hasEductionDetails =
        socialMediaDetails?.college_country || socialMediaDetails?.college_name || socialMediaDetails?.high_school_name;
    return (
        <SummaryContainer>
            <SummaryHeader
                title={
                    <FormattedMessage id="welcome.Summary.socialMediaDetails.Header" defaultMessage="Social Media" />
                }
                onClick={goToTrack}
            />
            <SummarySection>
                <SubTitleWrapper>
                    <FormattedMessage
                        id="welcome.Summary.socialMediaDetails.subheader"
                        defaultMessage="SOCIAL MEDIA DETAILS"
                    />
                </SubTitleWrapper>
                <SummaryItem title="Instagram" text={socialMediaDetails?.instagram_url} />
                <SummaryItem title="Facebook" text={socialMediaDetails?.facebook_url} />
                <SummaryItem title="TikTok" text={socialMediaDetails?.tiktok_url} />
                <SummaryItem title="LinkedIn" text={socialMediaDetails?.linkedin_url} />
                <SummaryItem title="Twitter" text={socialMediaDetails?.twitter_url} />
                <SummaryItem title="Reddit" text={socialMediaDetails?.reddit_url} />
            </SummarySection>
            {hasEductionDetails && (
                <>
                    <LineBreak />
                    <SummarySection>
                        <SubTitleWrapper>
                            <FormattedMessage
                                id="welcome.Summary.socialMediaDetails.EducationDetails"
                                defaultMessage="EDUCATION DETAILS"
                            />
                        </SubTitleWrapper>
                        <SummaryItem
                            title={
                                <FormattedMessage
                                    id="welcome.Summary.socialMediaDetails.Highschool"
                                    defaultMessage="High School Institution"
                                />
                            }
                            text={socialMediaDetails?.high_school_name}
                        />
                        <FlexRow>
                            <SummaryItem
                                title={
                                    <FormattedMessage
                                        id="welcome.Summary.socialMediaDetails.Country"
                                        defaultMessage="Country of Post Secondary Institution"
                                    />
                                }
                                text={socialMediaDetails?.college_country}
                            />
                            <SummaryItem
                                title={
                                    <FormattedMessage
                                        id="welcome.Summary.socialMediaDetails.CollegeInstitution"
                                        defaultMessage="Post Secondary Institution"
                                    />
                                }
                                text={socialMediaDetails?.college_name}
                            />
                        </FlexRow>
                    </SummarySection>
                </>
            )}
        </SummaryContainer>
    );
};

export default SocialMediaDetails;
