interface IdentityNumber {
    country: string;
    number: string;
    identity_number_type: 'DRIVERS_LICENSE' | 'FIREARMS_LICENSE' | 'PASSPORT' | null;
}

export const findIdentityNumber = (identityNumbers: Array<IdentityNumber> | undefined, country: string) =>
    identityNumbers?.find(
        (identityNumber) => identityNumber.country === country && identityNumber.identity_number_type === null
    );

export const identityNumberGetter = (country: string) => (information?: { identity_numbers?: Array<IdentityNumber> }) =>
    findIdentityNumber(information?.identity_numbers, country)?.number;
