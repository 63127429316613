import React from 'react';
import { FormattedMessage } from 'react-intl';
import { WaitingPeriodWrapper, WaitingPeriodTitle, WaitingPeriodText, SectionWrapper } from './styled';
import { WaitingPeriodSectionProps } from './types';

export const WaitingPeriodSection: React.FC<WaitingPeriodSectionProps> = ({ waitingPeriod }) => (
    <SectionWrapper>
        <WaitingPeriodWrapper>
            <WaitingPeriodTitle>
                <FormattedMessage
                    id="AdverseAction.Drawer.WaitingPeriodSection.Title"
                    defaultMessage="Applicant Waiting Period"
                />
            </WaitingPeriodTitle>
            <WaitingPeriodText>
                <FormattedMessage
                    id="AdverseAction.Drawer.WaitingPeriodSection.WeekDays"
                    defaultMessage="{numDays} weekdays"
                    values={{ numDays: waitingPeriod }}
                />
            </WaitingPeriodText>
        </WaitingPeriodWrapper>
    </SectionWrapper>
);
