export const Certn = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width={61} height={70} fill="none" style={{ margin: '0' }}>
        <rect width={52.164} height={69} x={7.745} y={0.5} fill="#fff" stroke="url(#a)" rx={3.5} />
        <rect width={52.164} height={69} x={4.063} y={0.5} fill="#fff" stroke="url(#b)" rx={3.5} />
        <rect width={52.164} height={69} x={0.5} y={0.5} fill="#fff" stroke="url(#c)" rx={3.5} />
        <path
            fill="#134247"
            d="M9.765 4.934a28.58 28.58 0 0 0 2.437 1.562c.412.247.6.573.422.937a.825.825 0 0 1-.277.306c-.342.242-.709.466-1.067.695a.775.775 0 0 1-.901-.008c-.328-.21-.658-.416-.98-.637a.816.816 0 0 0-.995-.024c-.329.213-.647.442-.984.64a.888.888 0 0 0-.477.793v1.557a.878.878 0 0 0 .435.772c.337.21.666.437 1.003.645a.944.944 0 0 1 .499.91v.843a1.073 1.073 0 0 1-.725 1.035h-.233a.073.073 0 0 1-.04-.01c-.131-.079-.265-.153-.392-.235-.832-.538-1.661-1.08-2.493-1.618-.18-.116-.378-.204-.455-.406-.018-.043-.026-.222-.026-.17V7.426c.165-.427.536-.608.89-.837.965-.626 1.936-1.244 2.891-1.885.507-.339.889-.19 1.468.23Z"
        />
        <path
            fill="#1BB793"
            d="M14.465 9.601v.364a.808.808 0 0 1-.424.774c-.952.613-1.904 1.23-2.856 1.851a.734.734 0 0 1-.87 0c-.768-.5-1.536-1.002-2.304-1.506a.791.791 0 0 1-.396-.736c.01-.268 0-.537 0-.805a.746.746 0 0 1 .383-.688c.145-.087.278-.175.416-.267a.733.733 0 0 1 .87 0c.348.228.704.435 1.043.681.34.247.63.216.958 0 .479-.327.976-.627 1.45-.953a.787.787 0 0 1 .956-.026c.124.078.255.145.382.221a.725.725 0 0 1 .392.682c-.003.131 0 .27 0 .408Z"
        />
        <mask
            id="d"
            width={12}
            height={12}
            x={37}
            y={54}
            maskUnits="userSpaceOnUse"
            style={{
                maskType: 'alpha',
            }}
        >
            <path fill="#D9D9D9" d="M37.258 54.194h11.29v11.29h-11.29z" />
        </mask>
        <g mask="url(#d)">
            <path
                fill="#1BB793"
                d="M39.14 59.36v-2.277c0-.166.047-.316.142-.448a.81.81 0 0 1 .367-.29l2.98-1.113a.805.805 0 0 1 .274-.05c.089 0 .18.016.275.05l2.979 1.114a.81.81 0 0 1 .367.289.753.753 0 0 1 .142.448v2.276a5.52 5.52 0 0 1-.948 3.116 4.866 4.866 0 0 1-2.687 2.01.663.663 0 0 1-.375-.036 4.866 4.866 0 0 1-2.568-1.974 5.52 5.52 0 0 1-.948-3.116Zm2.744 2.36h2.038a.38.38 0 0 0 .28-.112.38.38 0 0 0 .112-.28V59.76a.38.38 0 0 0-.112-.28.38.38 0 0 0-.28-.112h-.078v-.47a.906.906 0 0 0-.276-.665.906.906 0 0 0-.665-.276.906.906 0 0 0-.664.276.906.906 0 0 0-.277.665v.47h-.078a.38.38 0 0 0-.28.113.38.38 0 0 0-.112.28v1.567a.38.38 0 0 0 .113.28.38.38 0 0 0 .279.112Zm.47-2.352v-.47a.49.49 0 0 1 .163-.375c.108-.1.237-.15.386-.15.15 0 .278.05.386.15a.49.49 0 0 1 .163.375v.47h-1.098Z"
            />
        </g>
        <rect width={44.032} height={2.54} x={4.516} y={21.734} fill="#DBD9D9" rx={1.27} />
        <rect width={44.032} height={2.54} x={4.516} y={27.944} fill="#DBD9D9" rx={1.27} />
        <rect width={29.919} height={2.54} x={4.516} y={34.153} fill="#DBD9D9" rx={1.27} />
        <rect width={29.919} height={2.54} x={19.476} y={8.185} fill="#134247" rx={1.27} />
        <rect width={25.403} height={2.54} x={4.516} y={54.194} fill="#DBD9D9" rx={1.27} />
        <rect width={21.734} height={2.54} x={4.516} y={59.839} fill="#DBD9D9" rx={1.27} />
        <defs>
            <linearGradient id="a" x1={33.827} x2={33.827} y1={0} y2={70} gradientUnits="userSpaceOnUse">
                <stop stopColor="#F2F2F2" />
                <stop offset={1} stopColor="#E6E6E6" />
            </linearGradient>
            <linearGradient id="b" x1={30.145} x2={30.145} y1={0} y2={70} gradientUnits="userSpaceOnUse">
                <stop stopColor="#F2F2F2" />
                <stop offset={1} stopColor="#E6E6E6" />
            </linearGradient>
            <linearGradient id="c" x1={26.582} x2={26.582} y1={0} y2={70} gradientUnits="userSpaceOnUse">
                <stop stopColor="#F2F2F2" />
                <stop offset={1} stopColor="#E6E6E6" />
            </linearGradient>
        </defs>
    </svg>
);
