import styled from 'styled-components/macro';

const Answer = styled.div`
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    max-width: 100%;
`;

const Container = styled.div`
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    min-width: 30%;
    padding: 10px;
    margin: 5px;
    max-width: 360px;
    background: ${(props) => props.theme.color.certnGray200};
    border: ${(props) => (props.correct ? `5px solid ${props.theme.color.certnGreen700}` : 'none')};
    border-radius: 5px;
    @media (max-width: ${({ theme }) => theme.width.sm}) {
        width: 100%;
        max-width: 100%;
    }
`;

const AnswerText = styled.div`
    padding: 8px;
    font-size: 14px;
`;

Answer.Container = Container;
Answer.Text = AnswerText;

export default Answer;
