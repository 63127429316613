/**
 * Filters out empty emails and duplicates, and converts them to lowercase
 * @param {string[]} emails the array of emails to filter
 * @return {string[]} array of unique email addresses
 */
export function uniqueEmails(emails) {
    const unique = [];
    if (emails && Array.isArray(emails)) {
        emails.forEach((email) => {
            if (email) {
                const lower = email.toLowerCase().replace(/\s+/g, '');
                if (!unique.includes(lower)) {
                    unique.push(lower);
                }
            }
        });
    }
    return unique;
}
