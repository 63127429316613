import config from 'utils/config';

let privacyPolicyAndTerms = 'applicant-privacy-policy-and-website-terms';
let myCRCSignUp = 'mycrc-signup';
let teamSignUp = 'team-signup';

if (config.isNotProduction) {
    privacyPolicyAndTerms += '-test';
    myCRCSignUp += '-test';
    teamSignUp += '-test';
}

export const APPLICANT_PRIVACY_POLICY_AND_WEBSITE_TERMS = privacyPolicyAndTerms;
export const MY_CRC_SIGN_UP = myCRCSignUp;
export const TEAM_SIGN_UP = teamSignUp;
