import { isUSAOrCanada, OTHER } from 'modules/Countries';
import { findIndex, get } from 'lodash';
import { InputTransform } from 'modules';

/**
 * Select province based on country
 * @param {string} country
 * @param {string} provinceState
 * @param {string} provinceOrOtherState
 * @returns {string} selectedProvince
 */
export const getSelectedProvince = (
    country: string | null,
    provinceState: string | null,
    provinceOrOtherState: string | null
): string | null => (isUSAOrCanada(country) ? provinceState : provinceOrOtherState);

/**
 * Concatenate Google Places API's address components into single string
 * @param {string} streetAddress
 * @param {string} city
 * @param {string} provinceOrOther
 * @param {string} country
 * @param {string} postalCode
 * @returns {string} combinedAddress
 */
export const combineAddress = (
    streetAddress: string | null,
    city: string | null,
    provinceOrOther: string | null,
    country: string | null,
    postalCode: string | null
): string => [streetAddress, city, provinceOrOther, country, postalCode].filter(Boolean).join(', ');

export type AddressComponentsType = {
    long_name: string;
    short_name: string;
    types: string[];
};

export type returnDerivedAddressComponentsType = {
    street_address: string | null;
    city: string | null;
    provinceOrOther: string | null;
    country: string | null;
    county: string | null;
    postal_code: string | null;
    streetNumber: string | undefined;
    combinedAddress: string | null;
    province_state: string | null;
    other_province_state: string | undefined;
    unitNumber: string | null;
};
/**
 * Destructure address object and return it's respective components
 * @param {any} address
 * @example
 *  ``` js
 * import googlePlacesAddressResponse from 'utils/tests/data/extensiveGooglePlacesAddressResponse.json'
combinedAddress(googlePlacesAddressResponse)
 * ```
 */
export const deriveAddressComponents = (address: AddressComponentsType[]): returnDerivedAddressComponentsType => {
    const administrativeIndex = findIndex(address, { types: ['administrative_area_level_1'] });
    const countryIndex = findIndex(address, { types: ['country'] });
    const countyIndex = findIndex(address, { types: ['administrative_area_level_2'] });
    const localityIndex = findIndex(address, { types: ['locality'] });
    const postalCodeIndex = findIndex(address, { types: ['postal_code'] });
    const postalTownIndex = findIndex(address, { types: ['postal_town'] });
    const routeIndex = findIndex(address, { types: ['route'] });
    const streetNumberIndex = findIndex(address, { types: ['street_number'] });
    // Available only for few countries like Australia, New Zealand
    const unitNumberIndex = findIndex(address, { types: ['subpremise'] });
    const zipCodeIndex = findIndex(address, { types: ['zip_code'] });

    const streetNumber = get(address[streetNumberIndex], ['long_name']);
    const postalTown =
        Number(postalTownIndex) >= 0
            ? get(address[postalTownIndex], ['long_name']) || get(address[postalTownIndex], ['short_name'])
            : null;
    const route =
        Number(routeIndex) >= 0
            ? get(address[routeIndex], ['long_name']) || get(address[routeIndex], ['short_name'])
            : null;

    // Set state based on google places to fill form
    const street_address =
        Number(streetNumberIndex) >= 0
            ? `${address[streetNumberIndex]?.long_name} ${address[routeIndex]?.long_name}`
            : route || null;
    const city =
        Number(localityIndex) >= 0
            ? address[localityIndex]?.long_name || address[localityIndex]?.short_name
            : postalTown || null;

    let province_state = Number(administrativeIndex) >= 0 ? address[administrativeIndex].short_name : null;
    const country = countryIndex && Number(countryIndex) >= 0 ? address[countryIndex].short_name : null;
    const county = countyIndex && Number(countyIndex) >= 0 ? address[countyIndex].short_name : null;

    let other_province_state;
    if (!isUSAOrCanada(country)) {
        const regionISO = InputTransform.provinceIsoCode({ province_state, country });
        other_province_state = regionISO;
        province_state = OTHER;
    }

    let postal_code = null;
    if (Number(postalCodeIndex) >= 0) postal_code = get(address[postalCodeIndex], ['long_name']);
    if (Number(zipCodeIndex) >= 0) postal_code = get(address[zipCodeIndex], ['long_name']);

    /*
    For countries like Australia where unit number is available.
    We are *NOT* going to complicate ourselves with a custom Unit Number logic from user input.
    The user is given opportunity to edit the unit number before submitting
    */
    const unitNumber = Number(unitNumberIndex) >= 0 ? get(address[unitNumberIndex], ['long_name']) : null;
    const provinceOrOther = getSelectedProvince(country, province_state, other_province_state);
    const combinedAddress = combineAddress(street_address, city, provinceOrOther, country, postal_code);
    return {
        street_address,
        city,
        provinceOrOther,
        country,
        county,
        postal_code,
        streetNumber,
        combinedAddress,
        province_state,
        other_province_state,
        unitNumber,
    };
};

export type AddressHistoryMissingYearsErrors =
    | 'AddressMissingStartDate'
    | 'AddressNotCurrentAndMissingEndDate'
    | 'AddressStartDateGreaterThanAddressEndDate';

export const checkForMissingYearsInAddressHistory = (
    addresses: { start_date: string; end_date: string | null; current: boolean }[],
    yearsRequired: number,
    currentDate: Date
): {
    missingYears: number[];
    error?: AddressHistoryMissingYearsErrors;
} => {
    const coveredYears: { [key: number]: boolean } = {};

    for (let i = 0; i < addresses.length; i += 1) {
        const address = addresses[i];
        if (!address.start_date) {
            return { missingYears: [], error: 'AddressMissingStartDate' };
        }
        if (!address.end_date && !address.current) {
            return { missingYears: [], error: 'AddressNotCurrentAndMissingEndDate' };
        }
        if (address.start_date && address.end_date && address.start_date > address.end_date) {
            return { missingYears: [], error: 'AddressStartDateGreaterThanAddressEndDate' };
        }

        // Intentionally using substrings rather than converting to dates.
        // Converting to a date from the format 'YYYY-MM-DD` led to some
        // odd issues for cases like '2022-01-01'. When converted to a date
        // it would actually be 2021-12-31 date after the timezone is factored in.
        // This would be wrong because the API stores dates as YYYY-MM-DD.
        const startYear: number = parseInt(address.start_date.substring(0, 4));
        const endYear: number = address.end_date
            ? parseInt(address.end_date.substring(0, 4))
            : parseInt(currentDate.toISOString().substring(0, 4)); // set it to current for math
        const yearDiff = endYear - startYear;
        for (let year = 0; year <= yearDiff; year += 1) {
            coveredYears[startYear + year] = true;
        }
    }

    const missingYears: number[] = [];
    const currentYear = currentDate.getFullYear();
    for (let i = 0; i <= yearsRequired; i += 1) {
        const year = currentYear - yearsRequired + i; // start at the back (ascending order)
        if (!coveredYears[year]) {
            missingYears.push(year);
        }
    }

    return { missingYears };
};
