export const DOCUMENT_SUBTYPE = 'right_to_work';

export const AUSTRALIAN_CITIZEN = 'australian_citizen';

export const NON_AUSTRALIAN_CITIZEN = 'non_australian_citizen';

export const AUSTRALIAN_CITIZEN_DOCUMENT_TYPES = [
    'AUSTRALIAN_BIRTH_CERTIFICATE',
    'AUSTRALIAN_CITIZENSHIP_CERTIFICATE',
    'AUSTRALIAN_PASSPORT',
];
