// Libraries
import React from 'react';
import { startCase } from 'lodash';
import { notification } from 'antd';
import { FormattedMessage } from 'react-intl';

// Components
import { intl } from '../components/GlobalProvider';

// Modules
import GoogleTranslate from 'modules/GoogleTranslate';

import { LANGUAGE_EN } from 'utils/constants';

const isValidErrorObject = (error) =>
    error && error instanceof Object && !Array.isArray(error) && Object.keys(error).length > 0;

// - non-descriptive titles return an empty string
// - descriptive titles have request prefix removed
const formatTitle = (title) => {
    const requestPrefix = 'request_';
    const nonDescriptiveTitles = ['detail', 'non_field_error'];
    return nonDescriptiveTitles.includes(title) ? '' : startCase(title.replace(requestPrefix, ''));
};

// locale strings are often hyphenated with language and location ex."en-GB"
const getLanguage = () => intl.locale && intl.locale.split('-')[0];

// joins description if array and removes white space
const formatDescription = (description = '') =>
    (Array.isArray(description) ? description.join(' ') : description).trim();

const displayNotification = ({ title, description, duration = 8 } = {}) => {
    const newTitle =
        title || intl.formatMessage({ id: 'error.response.general.title', defaultMessage: 'Error(s) have occurred' });
    const newDescription =
        description ||
        intl.formatMessage({
            id: 'error.response.general.explain',
            defaultMessage: 'Please use our live chat support or contact support@certn.co',
        });
    notification.error({
        message: newTitle,
        description: newDescription,
        duration,
    });
};

// Format and return google translated notification
// expected format = [title, description]
const displayGoogleTranslatedNotification = (untranslated, language) => {
    GoogleTranslate(untranslated, language)
        .then((resolvedTranslations) => {
            const googleTranslatedTitle = resolvedTranslations[0];
            const googleTranslatedFormattedDescription = formatDescription(resolvedTranslations[1]);
            displayNotification({ title: googleTranslatedTitle, description: googleTranslatedFormattedDescription });
        })
        .catch(() => displayNotification());
};

const displayError = (title, desc) => {
    const language = getLanguage();
    const formattedTitle = formatTitle(title);
    if (language === LANGUAGE_EN) {
        const formattedDescription = formatDescription(desc);
        displayNotification({ title: formattedTitle, description: formattedDescription });
    } else {
        const untranslated = Array.isArray(desc) ? [formattedTitle, ...desc] : [formattedTitle, desc];
        displayGoogleTranslatedNotification(untranslated, language);
    }
};

/**
 * recursiveErrorHandling
 * Function to move through the various error types django returns and display individual errors
 *
 * Types of known API error structures;
 * copy an error into function below to overwrite error for testing
 *
 *  1. error = { detail: 'error detail' };
 *  2. error = { amount: ['A valid integer is required.'], description: ['This field may not be blank.'] };
 *  3. error = {
 *      employer: {
 *          employer_reference: {
 *              amounta: ['error 1'],
 *              pricea: ['error 2'],
 *          },
 *          address_reference: {
 *              amountb: ['error 3'],
 *              priceb: ['error 4'],
 *          },
 *      },
 *  };
 */
const recursiveErrorHandling = (error) => {
    const valueDescription = Object.values(error)[0]; // determine recursive pattern
    const errorEntries = Object.entries(error);

    // if string
    if (typeof valueDescription === 'string') {
        return errorEntries.forEach(([title, desc]) => displayError(title, desc));
    }

    // if array
    if (Array.isArray(valueDescription)) {
        if (typeof valueDescription[0] === 'string') {
            return errorEntries.forEach(([title, desc]) => {
                const description = desc.join(', ');
                return displayError(title, description);
            });
        }

        return errorEntries.forEach(([, desc]) => recursiveErrorHandling(desc));
    }

    // if object
    if (typeof valueDescription === 'object') {
        return errorEntries.forEach(([, desc]) => recursiveErrorHandling(desc));
    }
};

/**
 * ErrorAlertAPI
 * Formats/translates errors returned from API into user facing notifications.
 * Each API error object entry should consist of a title<string> key and description<string | array> value
 */
export const ErrorAlertAPI = (error) => {
    const invalidErrorObject = !isValidErrorObject(error);
    if (invalidErrorObject) return displayNotification();

    try {
        recursiveErrorHandling(error);
    } catch {
        ErrorAlertNoTranslate();
    }
};

// formats/translates custom errors into user facing notifications.
export const ErrorAlertCustom = ({ title = '', description = '' } = {}) => {
    const language = getLanguage();

    if (!title && !description) return displayNotification();
    if (language === LANGUAGE_EN) return displayNotification({ title: startCase(title), description });

    GoogleTranslate([title, description], language)
        .then(([translatedTitle, translatedDescription]) =>
            displayNotification({ title: startCase(translatedTitle), description: translatedDescription })
        )
        .catch(() => displayNotification());
};

// Bypass translation for things like Base.jsx which don't have access to global provider yet
export const ErrorAlertNoTranslate = ({
    title = <FormattedMessage id="6b9b8.ErrorAlert.title1" defaultMessage="Errors have occurred" />,
    description = (
        <FormattedMessage
            id="6b9b8.ErrorAlert.desc1"
            defaultMessage="Please use our live chat support or contact support@certn.co"
        />
    ),
} = {}) => displayNotification({ title: startCase(title), description });
