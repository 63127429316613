import { Dispatch } from 'redux';
import {
    IS_ADVERSE_ACTION_DRAWER_OPEN,
    FETCH_ADVERSE_ACTION_DETAILS_ERR,
    FETCH_ADVERSE_ACTION_DETAILS_RES,
    FETCH_ADVERSE_ACTION_DETAILS_REQ,
    PAUSE_ADVERSE_ACTION_REQ,
    PAUSE_ADVERSE_ACTION_RES,
    PAUSE_ADVERSE_ACTION_ERR,
    RESUME_ADVERSE_ACTION_REQ,
    RESUME_ADVERSE_ACTION_RES,
    RESUME_ADVERSE_ACTION_ERR,
    CANCEL_ADVERSE_ACTION_REQ,
    CANCEL_ADVERSE_ACTION_RES,
    CANCEL_ADVERSE_ACTION_ERR,
    RESTART_ADVERSE_ACTION_REQ,
    RESTART_ADVERSE_ACTION_RES,
    RESTART_ADVERSE_ACTION_ERR,
} from './DrawerConstants';
import { getRequest, patchRequest } from 'utils/http';

export const toggleDrawer = (isAdverseActionDrawerOpen: boolean) => (dispatch: Dispatch) => {
    dispatch({
        type: IS_ADVERSE_ACTION_DRAWER_OPEN,
        payload: {
            isAdverseActionDrawerOpen,
        },
    });
};

export const fetchAdverseActionDetails = (applicantId: string) => async (dispatch: Dispatch) => {
    dispatch({
        type: FETCH_ADVERSE_ACTION_DETAILS_REQ,
    });
    try {
        const response = await getRequest({
            version: 'v2',
            endpoint: `/adverse-action/${applicantId}/details/`,
        });
        dispatch({
            type: FETCH_ADVERSE_ACTION_DETAILS_RES,
            payload: { adverseActionDetails: response },
        });
    } catch (error) {
        dispatch({
            type: FETCH_ADVERSE_ACTION_DETAILS_ERR,
            payload: { error },
        });
        throw new Error();
    }
};

export const pauseAdverseAction = (applicantId: string) => async (dispatch: Dispatch) => {
    dispatch({
        type: PAUSE_ADVERSE_ACTION_REQ,
    });
    try {
        const response = await patchRequest({
            version: 'v2',
            endpoint: `/adverse-action/${applicantId}/pause`,
        });
        dispatch({
            type: PAUSE_ADVERSE_ACTION_RES,
            payload: { adverseActionDetails: response },
        });
    } catch (error) {
        dispatch({
            type: PAUSE_ADVERSE_ACTION_ERR,
            payload: { error },
        });
        throw new Error();
    }
};

export const resumeAdverseAction = (applicantId: string) => async (dispatch: Dispatch) => {
    dispatch({
        type: RESUME_ADVERSE_ACTION_REQ,
    });
    try {
        const response = await patchRequest({
            version: 'v2',
            endpoint: `/adverse-action/${applicantId}/resume`,
        });
        dispatch({
            type: RESUME_ADVERSE_ACTION_RES,
            payload: { adverseActionDetails: response },
        });
    } catch (error) {
        dispatch({
            type: RESUME_ADVERSE_ACTION_ERR,
            payload: { error },
        });
        throw new Error();
    }
};

export const cancelAdverseAction = (applicantId: string) => async (dispatch: Dispatch) => {
    dispatch({
        type: CANCEL_ADVERSE_ACTION_REQ,
    });
    try {
        const response = await patchRequest({
            version: 'v2',
            endpoint: `/adverse-action/${applicantId}/cancel`,
        });
        dispatch({
            type: CANCEL_ADVERSE_ACTION_RES,
            payload: { adverseActionDetails: response },
        });
    } catch (error) {
        dispatch({
            type: CANCEL_ADVERSE_ACTION_ERR,
            payload: { error },
        });
        throw new Error();
    }
};

export const restartAdverseAction = (applicantId: string) => async (dispatch: Dispatch) => {
    dispatch({
        type: RESTART_ADVERSE_ACTION_REQ,
    });
    try {
        const response = await patchRequest({
            version: 'v2',
            endpoint: `/adverse-action/${applicantId}/restart`,
        });
        dispatch({
            type: RESTART_ADVERSE_ACTION_RES,
            payload: { adverseActionDetails: response },
        });
    } catch (error) {
        dispatch({
            type: RESTART_ADVERSE_ACTION_ERR,
            payload: { error },
        });
        throw new Error();
    }
};
