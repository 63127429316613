import {
    requestReducer,
    silentRequestReducer,
    responseReducer,
    errorReducer,
    defaultState as defaultHttpState,
} from 'utils/http';
import {
    SET_LOCALE,
    SET_USER_MODE,
    OPEN_SIDE_BAR,
    CLOSE_SIDE_BAR,
    LOG_USER_OUT,
    POST_LOGIN_REQ,
    POST_LOGIN_RES,
    POST_LOGIN_ERR,
    GET_NOTIFICATIONS_REQ,
    GET_NOTIFICATIONS_RES,
    GET_NOTIFICATIONS_ERR,
    GET_DROPDOWN_NOTIFICATIONS_RES,
    GET_NOTIFICATION_REQ,
    GET_NOTIFICATION_RES,
    GET_NOTIFICATION_ERR,
    SET_NOTIFICATION_VIEWED_REQ,
    SET_NOTIFICATION_VIEWED_RES,
    SET_NOTIFICATION_VIEWED_ERR,
    UPDATE_ADDRESS_ERROR,
    UPDATE_REVIEW_SUBMIT_COMPONENT,
    LOGIN_WITH_MAGIC_LINK,
    GET_USER_INFO_REQ,
    GET_USER_INFO_RES,
    GET_USER_INFO_ERR,
    SET_COUNTRY,
    SAVE_PARAMS,
    GET_COUNTRIES_REQ,
    GET_COUNTRIES_RES,
    GET_COUNTRIES_ERR,
    SET_TEAM_LOGO,
    GET_SKIN_RES,
    UPDATE_SKIN_COLOUR,
    FIRST_LOGIN_COMPLETE,
    SET_LAUNCH_DARKLY_FLAGS,
    GET_POTATO_CHECK_CONFIG_REQ,
    GET_POTATO_CHECK_CONFIG_RES,
    GET_POTATO_CHECK_CONFIG_ERR,
} from './BaseActions';

import { CERTN_IMAGES_S3 } from './BaseConstants';

import styles from 'styles/styles';

const initialState = {
    ...defaultHttpState,
    user: {
        team: {},
    },
    teamLogo: `${CERTN_IMAGES_S3}/logo_n.svg`,
    skinColour: styles.color.certnGreen700,
    sideBarOpen: false,
    error: {
        loginError: undefined,
    },
    userMode: 'HR',
    params: {},
    country: 'US',
    countries: [],
    locale: '', // IMPORTANT: setting this to an empty string enables browser language detection. TRI-1030
    firstLogin: false,
    launchDarklyFlags: {},
    isDrawerOpen: false,
    potatoCheckConfigs: [],
};

const base = (state = initialState, action) => {
    switch (action.type) {
        case GET_SKIN_RES:
            return {
                ...state,
                skinColour: action.payload.skinColour,
                teamLogo: action.payload.teamLogo,
                ssoEnabled: action.payload.ssoEnabled,
            };
        case UPDATE_SKIN_COLOUR:
            return {
                ...state,
                skinColour: action.payload.skinColour,
            };
        case LOG_USER_OUT:
            return {
                ...state,
                params: {},
            };
        case SET_TEAM_LOGO:
            return {
                ...state,
                teamLogo: action.payload.teamLogo,
            };
        case OPEN_SIDE_BAR:
            return {
                ...state,
                sideBarOpen: action.payload.sideBarOpen,
            };
        case CLOSE_SIDE_BAR:
            return {
                ...state,
                sideBarOpen: action.payload.sideBarOpen,
            };
        case POST_LOGIN_REQ:
        case GET_NOTIFICATIONS_REQ:
        case SET_NOTIFICATION_VIEWED_REQ:
        case GET_POTATO_CHECK_CONFIG_REQ:
            return {
                ...state,
                ...silentRequestReducer(),
            };
        case POST_LOGIN_RES:
            return {
                ...state,
                ...responseReducer(),
                token: action.payload.token,
                user: action.payload.user,
            };
        case GET_DROPDOWN_NOTIFICATIONS_RES:
            return {
                ...state,
                ...responseReducer(),
                dropdownNotifications: action.payload.dropdownNotifications,
            };
        case GET_NOTIFICATIONS_RES:
            return {
                ...state,
                ...responseReducer(),
                notifications: action.payload.notifications,
            };
        case SET_NOTIFICATION_VIEWED_RES:
            return {
                ...state,
                ...responseReducer(),
            };
        case GET_POTATO_CHECK_CONFIG_RES:
            return {
                ...state,
                ...responseReducer(),
                potatoCheckConfigs: action.payload.check_configs,
            };
        case POST_LOGIN_ERR:
        case GET_NOTIFICATIONS_ERR:
        case GET_NOTIFICATION_ERR:
        case SET_NOTIFICATION_VIEWED_ERR:
        case GET_POTATO_CHECK_CONFIG_ERR:
            return {
                ...state,
                ...errorReducer(action.payload.error),
            };
        case UPDATE_ADDRESS_ERROR:
            return {
                ...state,
                addressError: action.payload.addressError,
            };
        case UPDATE_REVIEW_SUBMIT_COMPONENT:
            return {
                ...state,
                reviewSubmitUpdated: action.payload,
            };
        case LOGIN_WITH_MAGIC_LINK:
            return {
                ...state,
                ...requestReducer(),
                firstLogin: true,
            };
        case FIRST_LOGIN_COMPLETE:
            return {
                ...state,
                firstLogin: false,
            };
        case GET_NOTIFICATION_REQ:
        case GET_USER_INFO_REQ:
        case GET_COUNTRIES_REQ:
            return {
                ...state,
                ...requestReducer(),
            };
        case GET_NOTIFICATION_RES:
            return {
                ...state,
                ...responseReducer(),
                notification: action.payload.notification,
            };
        case GET_COUNTRIES_RES:
            return {
                ...state,
                ...responseReducer(),
                countries: action.payload.countries,
            };
        case GET_USER_INFO_RES:
            return {
                ...state,
                ...responseReducer(),
                user: action.payload.user,
                teamLogo: action.payload.user.team.settings_config.org_logo_link,
            };
        case GET_USER_INFO_ERR:
        case GET_COUNTRIES_ERR:
            return {
                ...state,
                ...errorReducer(action.payload.error),
            };
        case SET_COUNTRY:
            return {
                ...state,
                country: action.payload.country,
            };
        case SET_USER_MODE:
            return {
                ...state,
                userMode: action.payload.userMode,
            };
        case SET_LOCALE:
            return {
                ...state,
                locale: action.payload.locale,
            };
        case SAVE_PARAMS:
            return {
                ...state,
                params: {
                    ...state.params,
                    ...action.payload.params,
                },
            };
        case SET_LAUNCH_DARKLY_FLAGS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

export default base;
