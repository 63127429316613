// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// Components
import { AuthorizationForm } from './components';

// Modules
import withNavigation from 'views/welcome/modules/WithNavigation';

// Actions & Selectors
import { setTrackPageOrder, patchInformation } from 'views/welcome/WelcomeActions';
import { getIsFetchingSilent, getInformation } from 'views/welcome/WelcomeSelectors';

const mapStateToProps = (state) => ({
    isFetchingSilent: getIsFetchingSilent(state),
    information: getInformation(state),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            patchInformation,
            setTrackPageOrder,
        },
        dispatch
    );

const propTypes = {
    // Redux Actions:
    patchInformation: PropTypes.func.isRequired,
    setTrackPageOrder: PropTypes.func.isRequired,
    // Redux Store:
    isFetchingSilent: PropTypes.bool.isRequired,
    information: PropTypes.object.isRequired,
};
const defaultProps = {};

class Authorization extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.setTrackPageOrder(['base']);
    }

    handleUpdateState = (field, value) => this.setState({ [field]: value });

    handleSubmit = () => {
        const { handleNextTrack } = this.props; /* WithNavigation */
        this.props.patchInformation().then(handleNextTrack);
    };

    pages = () => ({
        base: <AuthorizationForm handleSubmit={this.handleSubmit} />,
    });

    render() {
        return this.pages()[this.props.trackPageLocation]; /* WithNavigation */
    }
}

Authorization.propTypes = propTypes;
Authorization.defaultProps = defaultProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withNavigation(Authorization)));
