// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Button } from 'antd';
import { useFlags } from 'launchdarkly-react-client-sdk';

// Components
import { RequiredText } from 'certn-ui';
import {
    SectionWrapper,
    SectionTopBar,
    SectionBottomPanel,
    BottomPanelRow,
    DisclaimerWrapper,
    Description,
} from 'views/manager/features/ScreenApplicant/styled';
import { SoftcheckDisclaimer, CreditCheckDisclaimer } from 'views/manager/components';

// Actions & Selectors
import { getUserModeIsPM } from 'base/BaseSelectors';
import { getSelectedCheckKeys } from 'views/manager/features/ScreenApplicant/ScreenApplicantSelectors';
import { getProperty } from 'views/manager/views/pm/views/properties/PropertiesSelectors';

// Modules
import isVisibleOrRequired from './isVisibleOrRequired';
import { isUSOrUSTerritory } from 'modules/Countries';

// Constants
import { CHECK_REQUEST } from 'base/BaseConstants';

ReviewAndSubmit.propTypes = {
    loading: PropTypes.bool,
};
ReviewAndSubmit.defaultProps = {
    loading: false,
};

function ReviewAndSubmit({ form, loading }) {
    const selectedServiceKeys = useSelector(getSelectedCheckKeys);
    const userModeIsPM = useSelector(getUserModeIsPM);
    const propertyCountry = useSelector(getProperty).country;
    const { webFeatureBlockOrderingSoftcheckForUsApplicantsViaQuickscreen } = useFlags();
    const [requiredAlerts, optionalAlerts] = reviewAndSubmitAlerts(
        selectedServiceKeys,
        userModeIsPM,
        form,
        propertyCountry,
        webFeatureBlockOrderingSoftcheckForUsApplicantsViaQuickscreen
    );
    const blockerAlertsExist = requiredAlerts.length;
    const alerts = [...requiredAlerts, ...optionalAlerts];
    return (
        <SectionWrapper>
            <SectionTopBar>
                <FormattedMessage id="f5ca1.QuickScreen.reviewAndSubmit" defaultMessage="Review and Submit" />
            </SectionTopBar>
            <SectionBottomPanel>
                <BottomPanelRow style={{ marginBottom: '15px' }}>
                    <Description>
                        <FormattedMessage
                            id="f5ca1.QuickScreen.reviewText"
                            defaultMessage="Please review your applicant's details carefully. Once you submit, you won't be able to edit these details again."
                        />
                    </Description>
                </BottomPanelRow>
                {alerts.length > 0 && (
                    <BottomPanelRow>
                        <Description>
                            <ul>
                                {alerts.map((alert) => (
                                    <li key={alert}>{alert}</li>
                                ))}
                            </ul>
                        </Description>
                    </BottomPanelRow>
                )}
                <BottomPanelRow>
                    <Button
                        type="primary"
                        htmlType="submit"
                        disabled={loading || blockerAlertsExist}
                        style={{ textTransform: 'uppercase' }}
                    >
                        <FormattedMessage id="common.submit" defaultMessage="Submit" />
                    </Button>
                </BottomPanelRow>
                <CreditCheckDisclaimerText />
                <SoftcheckDisclaimerText />
            </SectionBottomPanel>
        </SectionWrapper>
    );
}

function SoftcheckDisclaimerText() {
    const selectedServices = useSelector(getSelectedCheckKeys);

    // Checks to display field and set required, true in brackets for all services
    const visibleList = [CHECK_REQUEST.SOFTCHECK];

    if (!isVisibleOrRequired(visibleList, selectedServices)) return null;
    return (
        <DisclaimerWrapper>
            <SoftcheckDisclaimer margin="0" textAlign="left" />
        </DisclaimerWrapper>
    );
}

function CreditCheckDisclaimerText() {
    const selectedServices = useSelector(getSelectedCheckKeys);

    // Checks to display field and set required, true in brackets for all services
    const visibleList = [CHECK_REQUEST.EQUIFAX];

    if (!isVisibleOrRequired(visibleList, selectedServices)) return null;
    return (
        <DisclaimerWrapper>
            <CreditCheckDisclaimer margin="0" textAlign="left" />
        </DisclaimerWrapper>
    );
}

function reviewAndSubmitAlerts(
    selectedServiceKeys,
    userModeIsPM,
    form,
    propertyCountry,
    webFeatureBlockOrderingSoftcheckForUsApplicantsViaQuickscreen
) {
    let requiredAlerts = [];
    const optionalAlerts = [];

    const isSoftcheckNotAllowed = () => {
        if (!webFeatureBlockOrderingSoftcheckForUsApplicantsViaQuickscreen) return false;
        if (!selectedServiceKeys.includes(CHECK_REQUEST.SOFTCHECK)) return false;

        // if the user tries to run a softcheck on a applicant that lives or works in the US.
        // Display alert and disable submit button
        if (
            isUSOrUSTerritory(form.getFieldValue('country')) ||
            isUSOrUSTerritory(form.getFieldValue('country_work')) ||
            isUSOrUSTerritory(propertyCountry)
        ) {
            return true;
        }

        return false;
    };

    // PM and equifax requires CA/US address
    if (userModeIsPM && selectedServiceKeys.includes(CHECK_REQUEST.EQUIFAX)) {
        if (!['CA', 'US'].includes(form.getFieldValue('country'))) {
            requiredAlerts.push(
                <FormattedMessage
                    id="f5ca1.QuickScreen.CAorUSAAddressRequired"
                    defaultMessage="Canada/USA Address Required"
                />
            );
        }
    }

    if (isSoftcheckNotAllowed()) {
        requiredAlerts.push(
            <FormattedMessage
                id="f5ca1.QuickScreen.SoftcheckAlert.SoftcheckUnavailable"
                defaultMessage="The selected service, Softcheck, is unavailable for US applicants"
            />
        );
    }

    requiredAlerts = requiredAlerts.map((alert) => <RequiredText>{alert}</RequiredText>);

    return [requiredAlerts, optionalAlerts];
}

export default ReviewAndSubmit;
