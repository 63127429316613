import React from 'react';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
    width: 600px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    margin: ${({ margin }) => margin || '0 auto'};
`;
const Body = styled.span`
    font-size: 11px;
    text-align: ${({ textAlign }) => textAlign || 'center'};
    margin: 5px 0;
    color: ${({ theme }) => theme.color.certnGray400};
`;

const CreditCheckDisclaimer = ({ margin, textAlign }) => (
    <Wrapper margin={margin}>
        <Body textAlign={textAlign}>
            By submitting this application you acknowledge that you have received consent from the applicant to obtain a
            personal information report and credit report on them in connection with their application for tenancy.
        </Body>
    </Wrapper>
);

export default CreditCheckDisclaimer;
