// Libraries
import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';

// Components
import { AcicDisclosureForm } from './components/AcicDisclosureForm';
import { ErrorAlertCustom } from 'certn-ui/ErrorAlert';

// Modules
import withNavigation from 'views/welcome/modules/WithNavigation';

// Actions & Selectors
import { setTrackPageOrder, patchInformation } from 'views/welcome/WelcomeActions';
import { getSettings } from 'views/welcome/WelcomeSelectors';

function AcicDisclosure(props) {
    const dispatch = useDispatch();

    const settings = useSelector(getSettings);

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(setTrackPageOrder(['base']));
    }, [dispatch]);

    const handleSubmit = async (hasRequestedEmail) => {
        const { handleNextTrack } = props; /* WithNavigation */
        const { information } = await dispatch(
            patchInformation({
                acic_disclosure_accepted: true,
                requested_disclosure_email: hasRequestedEmail,
                us_criminal_consent_given: true,
            })
        );

        if (information.acic_disclosure_accepted) {
            handleNextTrack();
        } else {
            ErrorAlertCustom({
                title: 'Failed to submit consent',
                description: 'Please select Next again. If you cannot proceed please contact support',
            });
        }
    };

    const pages = () => ({
        base: <AcicDisclosureForm handleSubmit={handleSubmit} orgName={get(settings, ['get_org_name'])} />,
    });

    return pages()[props.trackPageLocation]; /* WithNavigation */
}

export default withRouter(withNavigation(AcicDisclosure));
