import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Select, Button, message } from 'antd';
import { getAdjudicators } from 'views/manager/views/admin/AdminSelectors';
import { ErrorAlertAPI } from 'certn-ui/ErrorAlert';
import { DropdownWrapper, Title, Wrapper, StyledDivider } from '../styled';
import PropTypes from 'prop-types';
import { patchAdjudicator } from '../AdjudicationRequests';

const { Option } = Select;

const AssignedDropdown = ({ applicantId, applicantAdjudication, setApplicantAdjudication }) => {
    const intl = useIntl();
    const [currentAdjudicator, setCurrentAdjudicator] = useState(applicantAdjudication?.adjudicator?.id);
    const [showAssignButton, setShowAssignButton] = useState(false);
    const teamAdjudicators = useSelector(getAdjudicators);

    useEffect(() => {
        if (applicantAdjudication?.adjudicator) {
            setCurrentAdjudicator(applicantAdjudication.adjudicator?.id);
        }
    }, [applicantAdjudication]);

    const updateAdjudicator = (newAdjudicator, unassignAdjudicator = false) => {
        patchAdjudicator(applicantId, newAdjudicator, unassignAdjudicator)
            .then((response) => {
                setApplicantAdjudication({ ...applicantAdjudication, adjudicator: response.adjudicator });
                setCurrentAdjudicator(response.adjudicator?.id);
                message.success(
                    intl.formatMessage({
                        id: '6cb79.AdjudicationSidebar.adjudicationAssigned',
                        defaultMessage: 'Adjudication assigned',
                    })
                );
            })
            .catch((error) => {
                ErrorAlertAPI(error);
                throw error;
            });
    };

    return (
        <DropdownWrapper>
            <Title>
                <FormattedMessage id="6cb79.AdjudicationSidebar.assigned" defaultMessage="Assigned" />
            </Title>
            <Wrapper>
                <Select
                    allowClear
                    data-testid="adjudicators_select"
                    placeholder={intl.formatMessage({
                        id: '6cb79.AdjudicationSidebar.pleaseSelect',
                        defaultMessage: 'Please Select',
                    })}
                    value={applicantAdjudication?.adjudicator?.id}
                    onSelect={(value) => {
                        setCurrentAdjudicator(value);
                        setShowAssignButton(true);
                    }}
                    onClear={() => updateAdjudicator(null, true)}
                >
                    {teamAdjudicators.map((item) => (
                        <Option value={item.id} key={item.id} name={item.name}>
                            {item.name}
                        </Option>
                    ))}
                </Select>
            </Wrapper>
            <Wrapper>
                {showAssignButton && (
                    <Button
                        type="primary"
                        onClick={() => {
                            updateAdjudicator(currentAdjudicator);
                            setShowAssignButton(false);
                        }}
                    >
                        <FormattedMessage id="6cb79.AdjudicationSidebar.assign" defaultMessage="Assign" />
                    </Button>
                )}
                <StyledDivider data-testid="adjudication_sidebar_hr" />
            </Wrapper>
        </DropdownWrapper>
    );
};
AssignedDropdown.propTypes = {
    applicantId: PropTypes.string.isRequired,
    applicantAdjudication: PropTypes.object.isRequired,
    setApplicantAdjudication: PropTypes.func.isRequired,
};

AssignedDropdown.defaultProps = {};

export default AssignedDropdown;
