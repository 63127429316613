import { useState } from 'react';
import { Button, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';

const Tooltip = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    return (
        <>
            <a
                data-testid="tooltip"
                style={{ color: '#819697', textDecorationLine: 'underline' }}
                onClick={() => {
                    setIsModalVisible(true);
                }}
            >
                <FormattedMessage id="welcome.WorkLocation.toolTip" defaultMessage="Why do we ask this?" />
            </a>
            <Modal
                visible={isModalVisible}
                onCancel={() => {
                    setIsModalVisible(false);
                }}
                footer={
                    <Button
                        type="primary"
                        onClick={() => {
                            setIsModalVisible(false);
                        }}
                    >
                        OK
                    </Button>
                }
            >
                <ExclamationCircleOutlined style={{ paddingRight: '25px', fontSize: '22px', color: '#1890FF' }} />
                <span>
                    <b style={{ fontSize: '16px' }}>
                        <FormattedMessage id="welcome.WorkLocation.toolTip" defaultMessage="Why do we ask this?" />
                    </b>
                </span>
                <div style={{ padding: '5%' }}>
                    <FormattedMessage
                        id="welcome.WorkLocation.toolTip.description"
                        defaultMessage="We ask this to ensure we’re providing the appropriate disclosure and authorization forms based on your location, ensuring accuracy and privacy compliance regulations are met in your jurisdiction."
                    />
                </div>
            </Modal>
        </>
    );
};

export default Tooltip;
