import { useState } from 'react';
import { Form as AntdForm } from 'antd';
import { useSelector } from 'react-redux';

// Actions & Selectors
import { PurposeOfCheck } from './components/PurposeOfCheck';
import { PositionTitleAndPlaceOfWork } from './components/PositionTitleAndPlaceOfWork';
import { LicenseTypeAndIssuingAuthority } from './components/LicenseTypeAndIssuingAuthority';
import { ReasonAndRequestingOrganization } from './components/ReasonAndRequestingOrganization';
import { VolunteerRoleAndVolunteerOrganization } from './components/VolunteerRoleAndVolunteerOrganization';
import { TrackNavigation } from 'views/welcome/components';
import { getApplicant, getInformation } from 'views/welcome/WelcomeSelectors';
import { PurposeOption } from './PurposeFormStyles/styled';

interface PurposeFormValues {
    purpose_of_check: string;
    issuing_authority?: string;
    license_type?: string;
    place_of_work?: string;
    reason_for_check?: string;
    position_title?: string;
    volunteer_role?: string;
    volunteer_organization?: string;
}
interface PurposeForm {
    handleSubmit: (values: PurposeFormValues) => void;
}

export const PurposeForm: React.FC<PurposeForm> = ({ handleSubmit }) => {
    // fields off the applicant model which dictate fields being disabled
    const {
        purpose_of_check,
        position_title,
        issuing_authority,
        license_type,
        place_of_work,
        reason_for_check,
        volunteer_role,
        volunteer_organization,
        request_australian_criminal_intelligence_commission_check,
        request_volunteer_acic_check,
    } = useSelector(getApplicant);

    // fields coming from the purpose model which will be updated by the applicant
    const acic_purpose = useSelector(getInformation)?.purpose;
    const purpose_purpose_of_check = acic_purpose?.purpose_of_check;
    const purpose_position_title = acic_purpose?.position_title;
    const purpose_issuing_authority = acic_purpose?.issuing_authority;
    const purpose_license_type = acic_purpose?.license_type;
    const purpose_place_of_work = acic_purpose?.place_of_work;
    const purpose_reason_for_check = acic_purpose?.reason_for_check;
    const purpose_volunteer_role = acic_purpose?.volunteer_role;
    const purpose_volunteer_organization = acic_purpose?.volunteer_organization;

    const [form] = AntdForm.useForm();
    const [purpose, setPurpose] = useState(purpose_of_check || purpose_purpose_of_check || '');
    return (
        <AntdForm
            form={form}
            requiredMark={false}
            layout="vertical"
            onFinish={handleSubmit}
            initialValues={{
                purpose_of_check: purpose_of_check || purpose_purpose_of_check,
                position_title: position_title || purpose_position_title,
                issuing_authority: issuing_authority || purpose_issuing_authority,
                license_type: license_type || purpose_license_type,
                place_of_work: place_of_work || purpose_place_of_work,
                reason_for_check: reason_for_check || purpose_reason_for_check,
                volunteer_role: volunteer_role || purpose_volunteer_role,
                volunteer_organization: volunteer_organization || purpose_volunteer_organization,
            }}
        >
            <PurposeOption>
                {request_australian_criminal_intelligence_commission_check && (
                    <PurposeOfCheck setPurpose={setPurpose} isPurposeOfCheckDisabled={!!purpose_of_check} />
                )}
            </PurposeOption>
            <PurposeOption>
                {purpose === 'EMPLOYMENT' && (
                    <PositionTitleAndPlaceOfWork
                        isPositionTitleDisabled={!!position_title}
                        isPlaceOfWorkDisabled={!!place_of_work}
                    />
                )}
            </PurposeOption>
            <PurposeOption>
                {purpose === 'LICENSE' && (
                    <LicenseTypeAndIssuingAuthority
                        isIssuingAuthorityDisabled={!!issuing_authority}
                        isLicenseTypeDisabled={!!license_type}
                    />
                )}
            </PurposeOption>
            <PurposeOption>
                {purpose === 'OTHER' && request_australian_criminal_intelligence_commission_check && (
                    <ReasonAndRequestingOrganization
                        isReasonForCheckDisabled={!!reason_for_check}
                        isPlaceOfWorkDisabled={!!place_of_work}
                    />
                )}
            </PurposeOption>
            <PurposeOption>
                {purpose === 'OTHER' && request_volunteer_acic_check && (
                    <VolunteerRoleAndVolunteerOrganization
                        isVolunteerRoleDisabled={!!volunteer_role}
                        isVolunteerOrganizationDisabled={!!volunteer_organization}
                    />
                )}
            </PurposeOption>
            <TrackNavigation />
        </AntdForm>
    );
};
