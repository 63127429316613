import { combineReducers } from 'redux';

import admin from './views/admin/AdminReducer';
import hr from './views/hr/HrManagerReducer';
import pm from './views/pm/PropertyManagerReducer';
import settings from './views/settings/SettingsReducer';
import features from './features/FeaturesReducer';
import { LOG_USER_OUT } from 'base/BaseActions';

const manager = combineReducers({
    admin,
    hr,
    pm,
    settings,
    features,
});

// On logout clear state
export default (state, action) => manager(action.type === LOG_USER_OUT ? undefined : state, action);
