import { message } from 'antd';

import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { get } from 'lodash';
import { useIntl } from 'react-intl';

import ResponseSuccessMVR from './ResponseSuccessMVR';
import ResponseSuccess from './ResponseSuccess';

// Actions & Selectors
import { getApplicant, getInformation, getSettings } from 'views/welcome/WelcomeSelectors';

import { CHECK_REQUEST } from 'base/BaseConstants';
import { useCallback, useEffect } from 'react';

const REDIRECT_TIMEOUT_MILLIS = 1500;

const SubmissionResponse = ({ history }: Pick<RouteComponentProps, 'history'>): JSX.Element => {
    const intl = useIntl();
    const { applicant_login, redirect_url } = useSelector(getSettings);
    const applicant = useSelector(getApplicant);
    const information = useSelector(getInformation);

    const redirect = useCallback(
        () =>
            setTimeout(() => {
                if (applicant_login && !redirect_url) {
                    history.push('/applicant/review');
                    return;
                }
                window.location.replace(redirect_url);
            }, REDIRECT_TIMEOUT_MILLIS),
        [history, applicant_login, redirect_url]
    );

    useEffect(() => {
        if (applicant_login && !redirect_url) {
            message.success(
                intl.formatMessage({
                    id: 'welcome.SubmissionSuccess.successRedirect',
                    defaultMessage: 'Application submitted, redirecting to application review',
                })
            );
        } else if (redirect_url) {
            message.success(
                `${intl.formatMessage({
                    id: 'welcome.SubmissionSuccess.successRedirectCustom',
                    defaultMessage: 'Application submitted, redirecting to',
                })} ${redirect_url}`
            );
        }
    }, [applicant_login, redirect_url, intl]);

    useEffect(() => {
        if (redirect_url || applicant_login) redirect();
    }, [applicant_login, redirect_url, redirect]);

    const isRequireMVR: boolean =
        get(applicant, [CHECK_REQUEST.MOTOR_VEHICLE_RECORDS]) && get(information, ['license_prov_state']) === 'BC';

    return <>{isRequireMVR ? <ResponseSuccessMVR history={history} /> : <ResponseSuccess />}</>;
};

export default SubmissionResponse;
