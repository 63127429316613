import styled from 'styled-components';

export const Option = styled.div`
    flex: auto;
    display: flex;
    flex-direction: column;
    font-size: ${({ theme }) => theme.font.xxs};
    color: ${({ theme }) => theme.color.certnGray700};
    @media (max-width: ${(props) => props.theme.width.tablet}) {
        width: 100%;
    }
    @media (min-width: ${(props) => props.theme.width.desktop}) {
        ${({ marginRight }) => marginRight && 'margin-right: 15px'}
        ${({ marginLeft }) => marginLeft && 'margin-left: 15px'}
    }
`;

export const PurposeOption = styled.div`
    flex: auto;
    display: flex;
    flex-direction: column;
    font-size: ${({ theme }) => theme.font.xxs};
    color: ${({ theme }) => theme.color.certnGray700};
    width: 400px;
    @media (max-width: ${(props) => props.theme.width.tablet}) {
        width: 100%;
    }
    @media (min-width: ${(props) => props.theme.width.desktop}) {
        ${({ marginRight }) => marginRight && 'margin-right: 15px'}
        ${({ marginLeft }) => marginLeft && 'margin-left: 15px'}
    }
`;
