// Libraries
import React from 'react';
import styled from 'styled-components';

// Components
import { SectionWrapper, SectionTopBar, SectionBottomPanel, CustomInputFrame } from '../../styled';
import ApplicationsScreenBuildingSection from './ApplicationsScreenBuildingSection';
import ApplicationsScreenListingSection from './ApplicationsScreenListingSection';

const CustomBottomPanel = styled(SectionBottomPanel)`
    @media (min-width: ${({ theme }) => theme.width.desktop}) {
        flex-direction: row;
    }
`;

const PropertyManagementScreening = ({ form }) => (
    <SectionWrapper>
        <SectionTopBar>Select Building and Listing</SectionTopBar>
        <CustomBottomPanel>
            <CustomInputFrame style={{ marginRight: '20px' }}>
                <ApplicationsScreenBuildingSection setFieldsValue={form.setFieldsValue} />
            </CustomInputFrame>
            <CustomInputFrame>
                <ApplicationsScreenListingSection optional={false} />
            </CustomInputFrame>
        </CustomBottomPanel>
    </SectionWrapper>
);

export default PropertyManagementScreening;
