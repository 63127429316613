// Libraries
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Auth from 'modules/Auth';
import { injectIntl, FormattedMessage } from 'react-intl';

// Actions & Selectors
import { getSettings, getCurrentTeam } from 'views/manager/views/settings/SettingsSelectors';

// UI Components
import Form from 'certnd/Form';
import Typography from 'certnd/Typography';

const { Heading } = Typography;

const mapStateToProps = (state) => ({
    settings: getSettings(state),
    currentTeam: getCurrentTeam(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

// eslint-disable-next-line
class EmploymentHistory extends React.Component {
    render() {
        const { currentTeam, form } = this.props;

        const canEdit = Auth.isPermissionLevel('manager');

        return (
            <Form.Block>
                <Heading.H3>
                    <FormattedMessage id="fa039.EmploymentHistory.PageTitle" defaultMessage="Employment History" />
                </Heading.H3>
                <Form.Checkbox
                    form={form}
                    fieldName="employer_ref_req"
                    title={this.props.intl.formatMessage({
                        id: 'fa039.EmploymentHistory.EmployerRefTitle',
                        defaultMessage: 'Employer Reference Required',
                    })}
                    description={this.props.intl.formatMessage({
                        id: 'fa039.EmploymentHistory.EmployerRefDescription',
                        defaultMessage: 'Do all employer references need a reference contact?',
                    })}
                    options={{ initialValue: currentTeam.employer_ref_req }}
                    disabled={!canEdit}
                />

                <Form.Checkbox
                    form={form}
                    fieldName="employer_ref_email_req"
                    title={this.props.intl.formatMessage({
                        id: 'fa039.EmploymentHistory.EmailTitle',
                        defaultMessage: 'Reference Email Required',
                    })}
                    description={this.props.intl.formatMessage({
                        id: 'fa039.EmploymentHistory.EmailDescription',
                        defaultMessage: 'Do employer references require a contact email?',
                    })}
                    options={{ initialValue: currentTeam.employer_ref_email_req }}
                    disabled={!canEdit}
                />

                <Form.Checkbox
                    form={form}
                    fieldName="employer_ref_phone_req"
                    title={this.props.intl.formatMessage({
                        id: 'fa039.EmploymentHistory.PhoneTitle',
                        defaultMessage: 'Reference Phone Number Required',
                    })}
                    description={this.props.intl.formatMessage({
                        id: 'fa039.EmploymentHistory.PhoneDescription',
                        defaultMessage: 'Do employer references require a contact phone number?',
                    })}
                    options={{ initialValue: currentTeam.employer_ref_phone_req }}
                    disabled={!canEdit}
                />

                <Form.Button disabled={!canEdit}>
                    <FormattedMessage id="common.save" defaultMessage="Save" />
                </Form.Button>
            </Form.Block>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(EmploymentHistory));
