import React, { useEffect, useState } from 'react';
import { Select, Popover } from 'antd';
import { Form } from '@ant-design/compatible';
import { InfoCircleOutlined } from '@ant-design/icons';
import { getRequest } from '../utils/http';

const CountySelect = ({ required, intl, hideLabels, getFieldDecorator, ids, province, disabled }) => {
    const [counties, setCounties] = useState([]);

    useEffect(() => {
        const fetchCounties = async () => {
            const response = await getRequest({
                version: 'v2',
                endpoint: `/document-management/counties/?state=${province}`,
            });
            setCounties(response);
        };

        if (province) {
            fetchCounties();
        }
    }, [province]);

    return (
        <Form.Item
            hasFeedback
            style={{ display: required ? 'block' : 'none', marginBottom: '5px' }}
            label={!hideLabels ? intl.formatMessage({ id: 'common.county', defaultMessage: 'County' }) : null}
        >
            {getFieldDecorator(ids.county, {
                validateTrigger: 'onSubmit',
                rules: [
                    {
                        required: true,
                        message: intl.formatMessage({
                            id: 'error.validation.notBlank',
                            defaultMessage: 'Please enter a county',
                        }),
                    },
                ],
            })(
                <Select
                    size="large"
                    data-testid="county"
                    type="text"
                    placeholder={
                        hideLabels
                            ? intl.formatMessage({
                                  id: 'addressAuto.county',
                                  defaultMessage: 'County',
                              })
                            : null
                    }
                    addonBefore={
                        <Popover
                            content={intl.formatMessage({
                                id: 'addressAuto.county',
                                defaultMessage: 'County',
                            })}
                        >
                            <InfoCircleOutlined />
                        </Popover>
                    }
                    disabled={disabled}
                >
                    {counties.map((addCounty, index) => (
                        <Select.Option data-testid={addCounty} key={`${addCounty}-${index}`} value={addCounty}>
                            {addCounty}
                        </Select.Option>
                    ))}
                </Select>
            )}
        </Form.Item>
    );
};

export default CountySelect;
