/* eslint-disable no-shadow */
export enum CheckLabel {
    REFERENCE_CHECK = 'References Check',
    RCMP_CHECK = 'Criminal Record Check',
    VACCINATION_CHECK = 'Vaccination Check',
    VERIFICATION_CHECK = 'Verification Check',
    EQUIFAX_CHECK = 'Equifax Credit Check',
    SOQUIJ_CHECK = 'SOQUIJ Criminal Check',
    SOFTCHECK = 'Softcheck Check',
    US_CRIMINAL_RECORD_CHECK = 'US Criminal Record Check',
    INTERNATIONAL_CRIMINAL_RECORD_CHECK = 'International Criminal Record Check',
    MOTOR_VEHICLE_RECORD_CHECK = 'Motor Vehicle Record Check',
    ID_VERIFICATION_CHECK = 'ID Verification Check',
    INSTANT_VERIFY_CHECK = 'Instant Verify Check',
    INFORMATION_CHECK = 'Information Check',
}
