import { startCase } from 'lodash';
import { useIntl } from 'react-intl';

export const useCheckTranslation = () => {
    const intl = useIntl();

    const checks: Record<string, string> = {
        base: intl.formatMessage({
            id: 'd4f5f.Base.Base',
            defaultMessage: 'Base',
        }),
        softcheck: intl.formatMessage({
            id: '70aa2.ServiceBreakdown.Softcheck',
            defaultMessage: 'Softcheck™',
        }),
        equifax: intl.formatMessage({
            id: '70aa2.ServiceBreakdown.equifax',
            defaultMessage: 'Equifax Credit Report',
        }),
        identity_verification: intl.formatMessage({
            id: 'services.identityVerification',
            defaultMessage: 'Identity Verification',
        }),
        enhanced_identity_verification: intl.formatMessage({
            id: '70aa2.ServiceBreakdown.id1',
            defaultMessage: 'OneID',
        }),
        criminal_record_check: intl.formatMessage({
            id: 'services.canadianCriminalRecordCheck',
            defaultMessage: 'Canadian Criminal Record Check',
        }),
        enhanced_criminal_record_check: intl.formatMessage({
            id: '450bc.MicroServicesActive.enhancedCriminalRecordCheck',
            defaultMessage: 'Enhanced Canadian Criminal Record Check',
        }),
        vulnerable_sector_criminal_record_check: intl.formatMessage({
            id: '3f746.MicroServiceContent.vscTitle',
            defaultMessage: 'Vulnerable Sector Check',
        }),
        international_criminal_record_check: intl.formatMessage({
            id: 'services.intlCriminalRecordCheck',
            defaultMessage: 'International Criminal Record Check',
        }),
        education_verification: intl.formatMessage({
            id: 'common.educationVerification',
            defaultMessage: 'Education Verification',
        }),
        credential_verification: intl.formatMessage({
            id: 'services.credentialVerification',
            defaultMessage: 'Credential Verification',
        }),
        employer_references: intl.formatMessage({
            id: 'common.employerReferences',
            defaultMessage: 'Employer References',
        }),
        employer_phone_references: intl.formatMessage({
            id: 'common.employerPhoneReferences',
            defaultMessage: 'Employer Phone References',
        }),
        employment_verification: intl.formatMessage({
            id: 'common.employmentVerification',
            defaultMessage: 'Employment Verification',
        }),
        motor_vehicle_records: intl.formatMessage({
            id: 'common.motorVehicleRecords',
            defaultMessage: 'Motor Vehicle Records',
        }),
    };

    return (checkLabel: string) => {
        if (!checks[checkLabel]) {
            console.error(`Check translation missing for ${checkLabel}.`);
            return startCase(checkLabel);
        }

        return checks[checkLabel];
    };
};
