import parseDomain from 'parse-domain';
import { startCase } from 'lodash';
import { getURLParamPlainJavascript } from 'modules/Format';

class Path {
    static getCurrentSubdomain() {
        const subdomainInURL = getURLParamPlainJavascript('embed');

        if (subdomainInURL) {
            return subdomainInURL;
        }
        // return 'staging-app'; // only for crossbrowsertesting local tunnel
        if (parseDomain(window.location.href, { customTlds: /localhost/ }).tld === 'localhost') {
            return 'develop-app';
        }
        if (parseDomain(window.location.href).subdomain) {
            const sub = parseDomain(window.location.href).subdomain;
            return sub;
        }
        return 'app';
    }

    static getPath() {
        if (parseDomain(window.location.href, { customTlds: /localhost/ }).tld === 'localhost') {
            return 'https://develop-app.certn.co';
        }
        if (parseDomain(window.location.href)) {
            const url = parseDomain(window.location.href);
            const path = `https://${url.subdomain}.${url.domain}.${url.tld}`;
            return path;
        }
        return 'app';
    }

    static getAltTagFromSubdomain() {
        const subdomain = startCase(this.getCurrentSubdomain());

        return subdomain === 'app' ? 'Certn' : subdomain;
    }

    static isLimeSubdomain() {
        const subdomain = this.getCurrentSubdomain();
        return subdomain === 'lime';
    }

    static isMyCRCSubdomain() {
        const subdomain = this.getCurrentSubdomain();
        return subdomain === 'mycrc';
    }
}

export default Path;
