import React from 'react';

const Felony = () => (
    <svg width="68" height="68" viewBox="0 0 68 68" fill="none">
        <path d="M28.1272 47.4064H12.532" stroke="#12B692" strokeLinecap="round" strokeLinejoin="round" />
        <path
            d="M38.4752 30.2584L55.9504 45.6456C57.6 47.0992 57.6784 49.5624 56.1224 51.1184L55.4584 51.7832C53.9024 53.3384 51.44 53.2608 49.9848 51.6096L34.5928 34.1408"
            stroke="#12B692"
            strokeLinejoin="round"
        />
        <path d="M31.197 16.6355L20.66 27.1724L28.3261 34.8385L38.863 24.3016L31.197 16.6355Z" stroke="#12B692" />
        <path
            d="M31.2736 16.6976L31.7224 13.5616L34.6512 10.6336L44.836 20.8168L41.9064 23.7488L38.7704 24.1976"
            stroke="#12B692"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path d="M10.7592 57.3672V52.2472H29.9008V57.3672" stroke="#12B692" strokeLinejoin="round" />
        <path
            d="M20.7096 27.2616L17.5736 27.7112L14.6448 30.6408L24.8288 40.8248L27.76 37.8952L28.2096 34.7592"
            stroke="#12B692"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default Felony;
