// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// UI Components
import Loader from 'certn-ui/Loader';
import Titlebar from 'certn-ui/Titlebar';
import { BackButton } from 'certn-ui/Button';
import { message } from 'antd';

// Actions & Selectors
import { fetchProperty, editProperty } from 'views/manager/views/pm/views/properties/PropertiesActions';
import { getIsFetching, getProperty } from 'views/manager/views/pm/views/properties/PropertiesSelectors';
import { updateAddressError } from 'base/BaseActions';

// Other Components
import { PropertiesForm } from 'views/manager/views/pm/views/properties/components';

// Modules
import { OTHER } from 'modules/Countries';

const mapStateToProps = (state) => ({ isFetching: getIsFetching(state), property: getProperty(state) });
const mapDispatchToProps = (dispatch) =>
    bindActionCreators({ fetchProperty, editProperty, updateAddressError }, dispatch);

const propTypes = {
    // Redux Actions
    fetchProperty: PropTypes.func.isRequired,
    editProperty: PropTypes.func.isRequired,
    // Redux State
    isFetching: PropTypes.bool.isRequired,
    property: PropTypes.object,
};
const defaultProps = {
    property: {},
};

class PropertiesEdit extends React.Component {
    componentDidMount() {
        if (this.props.match && this.props.match.params) {
            this.props.fetchProperty(this.props.match.params.propertyId);
        }
    }

    onSubmitProperty = (propertyData) => {
        const values = {
            province_state: OTHER,
            other_province_state: null,
            ...propertyData,
        };
        if (this.props.match && this.props.match.params) {
            this.props.editProperty(this.props.match.params.propertyId, values);
            message.success('Building Saved');
        } else {
            message.error('Building Not Saved');
        }
    };

    render() {
        return (
            <>
                <BackButton label="BUILDINGS" onClick={() => this.props.history.push('/pm/properties/')} />
                <Titlebar title="Edit Building" />
                {this.props.isFetching ? (
                    <Loader />
                ) : (
                    <PropertiesForm
                        property={this.props.property}
                        onSubmit={this.onSubmitProperty}
                        tempAddress={this.props.tempAddress}
                        updateAddressError={this.props.updateAddressError}
                    />
                )}
            </>
        );
    }
}

PropertiesEdit.propTypes = propTypes;
PropertiesEdit.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(PropertiesEdit);
