import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Button, Modal } from 'antd';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { CHECK_REQUEST } from 'base/BaseConstants';
import { ErrorAlertCustom } from 'certn-ui/ErrorAlert';
import Loader from 'certn-ui/Loader';
import { getRequest, postRequest } from 'utils/http';
import { TrackNavigation } from 'views/welcome/components';
import { setInitialProcessingStatus } from 'views/welcome/views/oneId/utils';
import { submitIdentityVerification } from 'views/welcome/WelcomeActions';
import { getApplicant, getEnhancedIdentityPending, getOnboardingId } from 'views/welcome/WelcomeSelectors';
import { YotiEventNotification, SessionStatusData, CreateSessionData } from './types';
import { OneIdStatus } from './constants';

// Might be useful for other components however for now it can just live in this component
const useMediaQuery = (query: string) => {
    const mediaMatch = window.matchMedia(query);
    const [matches, setMatches] = useState(mediaMatch.matches);

    useEffect(() => {
        const handler = (event: MediaQueryListEvent) => {
            setMatches(event.matches);
        };

        mediaMatch?.addEventListener ? mediaMatch.addEventListener('change', handler) : mediaMatch.addListener(handler);

        return () => {
            mediaMatch?.removeEventListener
                ? mediaMatch.removeEventListener('change', handler)
                : mediaMatch.removeListener(handler);
        };
    });
    return matches;
};

const YotiModal = ({
    isOpen,
    onClose,
    yotiUrl,
    isLoading,
    isError,
}: {
    isOpen: boolean;
    onClose: () => void;
    yotiUrl: string;
    isLoading: boolean;
    isError: boolean;
}) => {
    const fullscreenWidth = '940px';
    const baseHeight = '650px';
    const useWideScreenSizes = useMediaQuery(`(min-width: ${fullscreenWidth})`);
    const intl = useIntl();

    return (
        <Modal
            visible={isOpen}
            onCancel={onClose}
            footer={null}
            width={useWideScreenSizes ? fullscreenWidth : '100vw'}
            bodyStyle={{
                height: useWideScreenSizes ? baseHeight : '100vh',
                marginTop: !useWideScreenSizes ? '-100px' : undefined,
            }}
            style={!useWideScreenSizes ? { margin: 0, maxWidth: '100vw' } : undefined}
        >
            {isLoading ? (
                <Loader />
            ) : isError ? (
                <Alert
                    type="error"
                    message={intl.formatMessage({
                        id: 'yoti.sessionError',
                        defaultMessage: 'Unable to start Yoti session. Please contact support.',
                    })}
                />
            ) : (
                yotiUrl && (
                    <iframe
                        src={yotiUrl}
                        title="Yoti"
                        data-testid="yoti-modal"
                        style={{ border: 0, height: '100%', width: '100%' }}
                        allow="camera"
                    />
                )
            )}
        </Modal>
    );
};
const ConfirmCloseModal = ({ isOpen, onClose, onOk }: { isOpen: boolean; onClose: () => void; onOk: () => void }) => {
    const title = (
        <strong>
            <FormattedMessage id="welcome.OneID.incompleteVerification" defaultMessage="Incomplete Verification" />
        </strong>
    );
    return (
        <Modal
            title={title}
            visible={isOpen}
            onCancel={onOk}
            onOk={onOk}
            footer={[
                <Button
                    key="exit"
                    onClick={onClose}
                    style={{ textTransform: 'uppercase' }}
                    data-testid="exit-verification"
                >
                    <FormattedMessage id="common.exit" defaultMessage="Exit" />
                </Button>,
                <Button
                    key="continue"
                    onClick={onOk}
                    type="primary"
                    style={{ textTransform: 'uppercase' }}
                    data-testid="continue-verification"
                >
                    <FormattedMessage id="welcome.OneID.continueVerification" defaultMessage="Continue Verification" />
                </Button>,
            ]}
        >
            <FormattedMessage
                id="welcome.OneID.incompleteVerification.body"
                defaultMessage="Are you sure you want to exit the identity verification process?"
            />
        </Modal>
    );
};

interface Props {
    handleSubmit: () => void;
    handleProcessing: (value: boolean) => void;
}

export const OneIDYoti: React.FC<Props> = ({ handleSubmit, handleProcessing }) => {
    const { webFeatureOneIdYotiContinueWhenIframeError } = useFlags();
    const onboardingId = useSelector(getOnboardingId);
    const enhancedIdentityPending = useSelector(getEnhancedIdentityPending);
    const applicant = useSelector(getApplicant);
    const dispatch = useDispatch();
    const intl = useIntl();
    const pollingBackend = useRef(false);
    const [yotiModalOpen, setYotiModalOpen] = useState(false);
    const [closeModalConfirmOpen, setCloseModalConfirmOpen] = useState(false);
    const [processing, setProcessing] = useState(
        setInitialProcessingStatus(
            applicant[CHECK_REQUEST.UK_RIGHT_TO_WORK_CHECK],
            applicant[CHECK_REQUEST.UK_BASIC_DBS_CHECK] || applicant[CHECK_REQUEST.UK_BASIC_DS_CHECK],
            enhancedIdentityPending
        )
    );
    const [showTrackNavigation, setShowTrackNavigation] = useState(!enhancedIdentityPending);
    const [yotiUrlLoading, setYotiUrlLoading] = useState<boolean>(false);
    const [yotiUrlError, setYotiUrlError] = useState<boolean>(false);
    const [yotiUrl, setYotiUrl] = useState<string>('');
    const [yotiResponse, setYotiResponse] = useState<YotiEventNotification | undefined>(undefined);

    const closeYotiModal = () => {
        yotiModalOpen && setCloseModalConfirmOpen(true);
    };
    const confirmCloseYotiModal = () => {
        if (yotiModalOpen) {
            setCloseModalConfirmOpen(false);
            setYotiModalOpen(false);
        }
    };
    const returnToYotiModal = () => {
        setCloseModalConfirmOpen(false);
    };

    const handleStartVerification = useCallback(async () => {
        setYotiUrlError(false);
        setYotiModalOpen(true);
        setYotiUrlLoading(true);
        const { src_url } = await postRequest<CreateSessionData>({
            hr: true,
            version: 'v1',
            endpoint: `onboarding/${onboardingId}/identity_verification/create_session/`,
        });
        setYotiUrlLoading(false);
        if (src_url) {
            setYotiUrl(src_url);
        } else {
            setYotiUrlError(true);
        }
    }, [onboardingId]);

    useEffect(() => {
        if (yotiModalOpen) {
            window.addEventListener('message', (event: MessageEvent) => {
                const { data }: { data: YotiEventNotification } = event;
                setYotiResponse(data);
            });
        } else {
            window.removeEventListener('message', () => {});
        }
    }, [yotiModalOpen]);

    useEffect(() => {
        const handleYotiEvent = (_yotiResponse: YotiEventNotification) => {
            if (_yotiResponse.eventType === 'SUCCESS') {
                setShowTrackNavigation(false);
                if (
                    applicant[CHECK_REQUEST.UK_RIGHT_TO_WORK_CHECK] &&
                    !(applicant[CHECK_REQUEST.UK_BASIC_DBS_CHECK] || applicant[CHECK_REQUEST.UK_BASIC_DS_CHECK])
                ) {
                    handleSubmit();
                } else {
                    handleProcessing(true);
                    setProcessing(true);
                    dispatch(submitIdentityVerification());
                }
            } else if (_yotiResponse.eventType === 'ERROR') {
                ErrorAlertCustom({
                    title: 'Something went wrong!',
                    description: `Error ${_yotiResponse.eventCode}: There's been an error. Please try refreshing the session, opening your session in another browser or checking your camera settings.`,
                });
            }
        };
        const handleYotiEventContinueWithIframeError = (_yotiResponse: YotiEventNotification) => {
            if (['SUCCESS', 'ERROR'].includes(_yotiResponse.eventType)) {
                setShowTrackNavigation(false);
                if (
                    applicant[CHECK_REQUEST.UK_RIGHT_TO_WORK_CHECK] &&
                    !(applicant[CHECK_REQUEST.UK_BASIC_DBS_CHECK] || applicant[CHECK_REQUEST.UK_BASIC_DS_CHECK])
                ) {
                    handleSubmit();
                } else {
                    handleProcessing(true);
                    setProcessing(true);
                    dispatch(submitIdentityVerification());
                }
            }
        };
        if (yotiResponse?.eventType) {
            setYotiModalOpen(false);
            webFeatureOneIdYotiContinueWhenIframeError
                ? handleYotiEventContinueWithIframeError(yotiResponse)
                : handleYotiEvent(yotiResponse);
        }
    }, [applicant, dispatch, handleProcessing, handleSubmit, yotiResponse, webFeatureOneIdYotiContinueWhenIframeError]);

    useEffect(() => {
        if (processing && !pollingBackend.current) {
            pollingBackend.current = true;
            const timer = setInterval(async () => {
                const response = await getRequest<SessionStatusData>({
                    hr: true,
                    version: 'v1',
                    endpoint: `onboarding/${onboardingId}/identity_verification/session_status/`,
                });
                if (response.status !== OneIdStatus.PENDING) {
                    clearInterval(timer);
                    // One day we might want to split the paths depending if the status is ACCEPTED or not
                    handleProcessing(false);
                    handleSubmit();
                }
            }, 10000);
        }
    }, [handleProcessing, handleSubmit, onboardingId, processing]);

    return (
        <div>
            <YotiModal
                isOpen={yotiModalOpen}
                onClose={closeYotiModal}
                yotiUrl={yotiUrl}
                isLoading={yotiUrlLoading}
                isError={yotiUrlError}
            />
            <ConfirmCloseModal
                isOpen={closeModalConfirmOpen}
                onClose={confirmCloseYotiModal}
                onOk={returnToYotiModal}
            />

            {showTrackNavigation && (
                <TrackNavigation
                    handleSubmit={handleStartVerification}
                    nextButtonTitle={intl.formatMessage({ id: 'veriff.button', defaultMessage: 'START VERIFICATION' })}
                    disabled={!!yotiModalOpen}
                />
            )}
        </div>
    );
};
