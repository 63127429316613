// Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Components
import { intl } from 'components/GlobalProvider';
import Form from 'certnd/Form';

CustomFields.propTypes = {
    field: PropTypes.string,
    label: PropTypes.string,
    selectOptions: PropTypes.array,
};

CustomFields.defaultProps = {
    field: '',
    label: '',
    selectOptions: [],
};

const MultipleChoice = ({ field, title, selectOptions, ...rest }) => (
    <Form.Select
        headerLeft
        hideBorder
        fieldName={field}
        title={title}
        placeholder={intl.formatMessage({
            id: 'welcome.general.selectAnswer',
            defaultMessage: 'Select your answer',
        })}
        selectOptions={selectOptions.map((opt) => ({
            key: opt[0],
            value: opt[0],
            title: opt[1],
        }))}
        {...rest}
    />
);

const Select = ({ field, title, selectOptions, ...rest }) => (
    <Form.Select
        headerLeft
        hideBorder
        fieldName={field}
        title={title}
        placeholder={intl.formatMessage({
            id: 'welcome.general.selectAnswer',
            defaultMessage: 'Select your answer',
        })}
        selectOptions={[
            {
                key: 0,
                value: 'Yes',
                title: intl.formatMessage({
                    id: 'welcome.general.yes',
                    defaultMessage: 'Yes',
                }),
            },
            {
                key: 1,
                value: 'No',
                title: intl.formatMessage({
                    id: 'welcome.general.no',
                    defaultMessage: 'No',
                }),
            },
        ]}
        {...rest}
    />
);

const Text = ({ field, title, ...rest }) => (
    <Form.Input headerLeft hideBorder fieldName={field} title={title} placeholder="Enter your answer" {...rest} />
);

const PositiveNumber = ({ field, title, ...rest }) => (
    <Form.InputNumber headerLeft hideBorder fieldName={field} title={title} min={0} {...rest} />
);

const typeMap = {
    MULTIPLE_CHOICE: MultipleChoice,
    YES_NO: Select,
    TEXT: Text,
    POSITIVE_NUMBER: PositiveNumber,
};

function CustomFields({
    form,
    label,
    selectOptions,
    initialValue,
    hyperlink_url,
    hyperlink_label,
    type = 'TEXT',
    ...rest
}) {
    const FormType = typeMap[type.toUpperCase()] || typeMap.TEXT;
    const title = hyperlink_url ? (
        <>
            <span>{label} </span>
            <a href={hyperlink_url} rel="noopener noreferrer" target="_blank">
                ({hyperlink_label || 'Link'})
            </a>
        </>
    ) : (
        label
    );

    return (
        <FormType
            form={form}
            size="large"
            autoComplete="false"
            title={title}
            selectOptions={selectOptions}
            options={{
                initialValue,
                rules: [
                    {
                        required: true,
                        message: intl.formatMessage({
                            id: 'error.validation.notBlank',
                            defaultMessage: 'Please do not leave blank',
                        }),
                    },
                ],
            }}
            {...rest}
        />
    );
}

export default CustomFields;
