// Libraries
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import { message, Button, Divider } from 'antd';

// Modules & Utils
import { get } from 'lodash';
import { Regex } from 'modules';
import Auth from 'modules/Auth';

// Actions & Selectors
import { getSettings, getCurrentTeam } from 'views/manager/views/settings/SettingsSelectors';
import { updateSettings } from 'views/manager/views/settings/SettingsActions';
import { getTeam } from 'views/manager/views/admin/AdminSelectors';
import { getUser, getSkinColour } from 'base/BaseSelectors';
import { updateSkinColour } from 'base/BaseActions';

import { Form as AntdForm } from '@ant-design/compatible';

// UI Components
import { ErrorAlertAPI } from 'certn-ui/ErrorAlert';
import Form from 'certnd/Form';
import { WebhookSecret } from './components';
import Typography from 'certnd/Typography';
import { SketchPicker } from 'react-color';

const { Heading } = Typography;

const mapStateToProps = (state) => ({
    user: getUser(state),
    settings: getSettings(state),
    currentTeam: getCurrentTeam(state),
    team: getTeam(state),
    skinColour: getSkinColour(state),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            updateSettings,
            updateSkinColour,
        },
        dispatch
    );

const General = AntdForm.create()(
    // eslint-disable-next-line
    class Team extends React.Component {
        state = { showColor: false };

        handleSubmit = (e) => {
            e.preventDefault();
            this.props.form.validateFieldsAndScroll((err, values) => {
                if (!err) {
                    values.org_primary_color = this.props.skinColour;

                    this.props
                        .updateSettings(values, this.props.team.id)
                        .then(() =>
                            message.success(
                                this.props.intl.formatMessage({
                                    id: 'common.settingsSavedSuccessfully',
                                    defaultMessage: 'Settings saved successfully.',
                                })
                            )
                        )
                        .catch((error) => ErrorAlertAPI(error));
                }
            });
        };

        handleChangeComplete = (colour) => {
            colour && this.props.updateSkinColour(colour.hex);
        };

        render() {
            const { form, currentTeam, team } = this.props;
            const canEdit = Auth.isPermissionLevel('manager');

            return (
                <AntdForm onSubmit={this.handleSubmit}>
                    <Heading.H1 data-testid="title">
                        <FormattedMessage id="59e77.General.PageTitle" defaultMessage="Team Settings" />
                    </Heading.H1>
                    <Form.Block>
                        <Form.Input
                            form={form}
                            fieldName="internal_name"
                            title={this.props.intl.formatMessage({
                                id: '59e77.General.TeamNameTitle',
                                defaultMessage: 'Team/Company Name (Internal)',
                            })}
                            description={this.props.intl.formatMessage({
                                id: '59e77.General.TeamNameDescription',
                                defaultMessage: 'What should your organization be known by?',
                            })}
                            options={{ initialValue: get(team, ['internal_name']) }}
                            disabled={!canEdit}
                        />

                        <Form.Input
                            form={form}
                            fieldName="org_name"
                            title={this.props.intl.formatMessage({
                                id: '59e77.General.PublicTitle',
                                defaultMessage: 'Team Public Name',
                            })}
                            description={this.props.intl.formatMessage({
                                id: '59e77.General.PublicDescription',
                                defaultMessage:
                                    'The name used publicly in emails from Certn when you send out applications.',
                            })}
                            options={{ initialValue: get(currentTeam, ['org_name']) }}
                            disabled={!canEdit}
                        />

                        <Form.Input
                            form={form}
                            fieldName="webhook_url"
                            title={this.props.intl.formatMessage({
                                id: '59e77.General.WebhookTitle',
                                defaultMessage: 'Webhook URL',
                            })}
                            description={this.props.intl.formatMessage({
                                id: '59e77.General.WebhookDescription',
                                defaultMessage:
                                    "What URL should we send your results to? (If you don't know what this is, don't worry - just leave it as-is)",
                            })}
                            options={{
                                initialValue: get(currentTeam, ['webhook_url']),
                                rules: [
                                    {
                                        pattern: Regex.urlWebhook,
                                        message: this.props.intl.formatMessage({
                                            id: 'error.validation.url',
                                            defaultMessage: 'Please provide a valid URL',
                                        }),
                                    },
                                ],
                            }}
                            disabled={!canEdit}
                        />

                        <WebhookSecret />
                        <Divider />

                        <Form.Checkbox
                            form={form}
                            fieldName="team_visible_listings"
                            title={this.props.intl.formatMessage({
                                id: '59e77.General.PackageTitle',
                                defaultMessage: 'Open Package Visibility',
                            })}
                            description={this.props.intl.formatMessage({
                                id: '59e77.General.PackageDescription',
                                defaultMessage: 'Allow users to see all available packages for your team.',
                            })}
                            options={{ initialValue: currentTeam.team_visible_listings }}
                            disabled={!canEdit}
                        />

                        <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                            {this.state.showColor ? (
                                <SketchPicker
                                    color={this.props.skinColour}
                                    onChangeComplete={this.handleChangeComplete}
                                />
                            ) : (
                                <Button onClick={() => this.setState({ showColor: true })}>
                                    <FormattedMessage id="59e77.General.ThemeColor" defaultMessage="Edit Theme Color" />
                                </Button>
                            )}
                        </div>

                        <Form.Button disabled={!canEdit}>
                            <FormattedMessage id="common.save" defaultMessage="Save" />
                        </Form.Button>
                    </Form.Block>
                </AntdForm>
            );
        }
    }
);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(General)));
