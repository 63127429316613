import React, { useState, useEffect } from 'react';
import { TrackNavigation } from 'views/welcome/components';
import { postRequest } from 'utils/http';
import { ErrorAlertAPI } from 'certn-ui/ErrorAlert';
import Title from 'certn-ui/Title';
import { FormattedMessage, useIntl } from 'react-intl';
import { ConsentAndDisclosureWrapper } from './styled';
import { Clickwrap } from 'components/Clickwrap';
import { ConsentAndDisclosureTemplateProps } from '../../types';

type ComplianceDocumentClickwrapProps = {
    complianceDocument: ConsentAndDisclosureTemplateProps;
    signerId: string;
    handleNext: () => void;
    handleReverse: undefined | (() => void);
};

export const ComplianceDocumentClickwrap: React.FC<ComplianceDocumentClickwrapProps> = ({
    complianceDocument,
    signerId,
    handleNext,
    handleReverse,
}) => {
    const intl = useIntl();
    const [isConsentGiven, setIsConsentGiven] = useState(false);
    const contractId = complianceDocument?.external_id;
    const documentRenderData = complianceDocument?.render_data;
    const templateType = complianceDocument?.template_type;
    const templateId = complianceDocument?.template_id;

    let acceptanceText;
    if (templateType && templateType.toUpperCase() === 'CONSENT') {
        acceptanceText = intl.formatMessage({
            id: 'welcome.consentAndDisclosure.checkBoxConsentText',
            defaultMessage: 'I understand and agree to this document',
        });
    } else {
        acceptanceText = intl.formatMessage({
            id: 'welcome.consentAndDisclosure.checkBoxAcknowledgeText',
            defaultMessage: 'I acknowledge that I have received and understand this document',
        });
    }

    useEffect(() => {
        if (!contractId) {
            ErrorAlertAPI();
            console.error('Unable to initialize contract. Missing Clickwrap Contract ID');
        }
    }, [contractId]);

    if (!contractId) {
        return null;
    }

    const handleValid = async () => {
        const signingData = {
            applicant_id: signerId,
            external_id: contractId,
            render_data: documentRenderData,
            template_id: templateId,
        };

        try {
            await postRequest({
                version: 'v2',
                endpoint: `/applicant-consent-and-disclosure-document/create/`,
                body: JSON.stringify(signingData),
            });
            setIsConsentGiven(true);
        } catch (error) {
            ErrorAlertAPI(error);
            setIsConsentGiven(false);
        }
    };

    return (
        <>
            <Title>{complianceDocument.template_name}</Title>
            <FormattedMessage
                id="welcome.consentAndDisclosure.text"
                defaultMessage="Please review the following document before continuing"
            />
            <ConsentAndDisclosureWrapper>
                <Clickwrap
                    data-testid={`template-${templateId}`}
                    contractId={contractId}
                    clickWrapStyle="scroll"
                    forceScroll
                    signerId={signerId}
                    allowDisagreed={false}
                    onValid={handleValid}
                    renderData={documentRenderData}
                    acceptanceLanguage={acceptanceText}
                    setSigned={setIsConsentGiven}
                />
                <TrackNavigation disabled={!isConsentGiven} handleSubmit={handleNext} handleReverse={handleReverse} />
            </ConsentAndDisclosureWrapper>
        </>
    );
};
