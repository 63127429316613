// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import { Upload, Space } from 'antd';

// Components
import Button from 'certn-ui/Button';
import { FormattedMessage } from 'react-intl';

const UploadsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    > * {
        margin: 8px;
    }
`;

const BaseUploadBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 10px;
    width: 100%;
    transition-duration: 0.5s;
    margin-top: 5px;
    margin-bottom: 14px; // Push down fields et al

    // Don't double border between UploadLineItem components
    & > div:not(:last-child) {
        border-bottom: none !important;
    }

    // UploadLineItem border
    & > div {
        border: 2px dashed ${({ theme }) => theme.color.certnGray200};
        border-radius: 4px;
    }
`;

const ReqUploadBox = styled(BaseUploadBox)`
    // UploadLineItem border color
    & > div {
        ${({ error, theme }) => error && `border-color: ${theme.color.certnRed500};`}
    }
`;

const ManyUploadBox = styled(BaseUploadBox)`
    border: 2px dashed transparent; // Transparent so req & non-req boxes align (due to 2px border)

    // UploadLineItem border
    & > div {
        border: 1px solid ${({ theme }) => theme.color.certnGray200};
        border-radius: 0;
    }
`;

const ManyReqUploadBox = styled(ManyUploadBox)`
    border-color: ${({ theme }) => theme.color.certnGray200};
    ${({ error, theme }) => error && `border-color: ${theme.color.certnRed500};`}
    ${({ complete, theme }) => complete && `border-color: ${theme.color.certnGreen700}`};
`;

const UploadLineItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px;
    overflow: hidden;
`;

const FileTitle = styled.span`
    font-size: 20px;
    margin-left: 10px;
    margin-right: 10px;
    color: ${(props) => props.theme.color.certnGray600};
`;

const UploadRow = ({
    doc,
    name,
    missing,
    file,
    s3UploadLink,
    s3UploadFields,
    s3GetUploadLink,
    fileList,
    fileProgress,
    onUpload,
    handleProgress,
    handleDeleteDocument,
    requestMethod,
    acceptedFileTypes,
    fileName,
    disabled,
    hideRemove,
}) => {
    // TODO: Understand how fileProgress, file, missing variables duplicate information & simplify
    // Decision logic pertaining to them was pulled across (including the line below)
    const percentUploaded =
        typeof doc === 'object' && fileProgress.OTHER ? fileProgress.OTHER[name] : fileProgress[doc];
    // Not zero or undefined & not 100
    const isUploading = percentUploaded && percentUploaded !== 100;

    function renderFileText() {
        const isUploaded = file && !missing && !isUploading;

        if (isUploaded) {
            return (
                <a href={file.url}>
                    <DownloadOutlined style={{ marginRight: '5px' }} />
                    {name}
                </a>
            );
        }
        if (isUploading) {
            return `${percentUploaded}%`;
        }
        return name;
    }

    return (
        <UploadLineItem>
            <Upload
                action={s3UploadLink}
                data={s3UploadFields}
                beforeUpload={(f) => s3GetUploadLink(f, doc)}
                fileList={fileList}
                onChange={onUpload}
                onProgress={(event, eventFile) => handleProgress({ event, eventFile, doc })}
                showUploadList={false}
                style={{ marginLeft: '10px' }}
                disabled={!missing || disabled}
                method={requestMethod}
                accept={acceptedFileTypes}
                name={fileName}
            >
                {missing ? (
                    <Button type="primary" certncolor="certnGreen700" style={{ minWidth: '128px' }}>
                        <Space>
                            <UploadOutlined />
                            <FormattedMessage id="welcome.Documents.upload" defaultMessage="Upload" />
                        </Space>
                    </Button>
                ) : (
                    !hideRemove && (
                        <Button
                            type="primary"
                            certncolor="certnGreen700"
                            style={{ minWidth: '128px' }}
                            onClick={() => handleDeleteDocument(file)}
                            loading={isUploading}
                        >
                            {!isUploading && (
                                <Space>
                                    <DownloadOutlined />
                                    <FormattedMessage id="welcome.Documents.remove" defaultMessage="Remove" />
                                </Space>
                            )}
                        </Button>
                    )
                )}
            </Upload>
            <FileTitle>{renderFileText()}</FileTitle>
        </UploadLineItem>
    );
};

const propTypes = {
    s3UploadLink: PropTypes.string,
    s3UploadFields: PropTypes.object,
    s3GetUploadLink: PropTypes.func,
    fileList: PropTypes.array,
    fileProgress: PropTypes.object.isRequired,
    onUpload: PropTypes.func.isRequired,
    handleProgress: PropTypes.func,
    handleDeleteDocument: PropTypes.func.isRequired,
    error: PropTypes.bool.isRequired,
    missingDocuments: PropTypes.array,
    requiredDocuments: PropTypes.array,
    docsUploadedNotReq: PropTypes.array,
    requestMethod: PropTypes.string,
    acceptedFileTypes: PropTypes.string,
    fileName: PropTypes.string,
    disabled: PropTypes.bool,
};

const defaultProps = {
    s3UploadLink: null,
    s3UploadFields: null,
    requestMethod: 'POST',
    acceptedFileTypes: '*',
    s3GetUploadLink: () => {},
    handleProgress: () => {},
    missingDocuments: [],
    requiredDocuments: [],
    docsUploadedNotReq: [],
    fileList: [],
    fileName: 'file',
    disabled: false,
};

UploadRow.propTypes = propTypes;
UploadRow.defaultProps = defaultProps;

UploadRow.UploadsWrapper = UploadsWrapper;
UploadRow.ManyUploadBox = ManyUploadBox;
UploadRow.ReqUploadBox = ReqUploadBox;
UploadRow.ManyReqUploadBox = ManyReqUploadBox;
export default UploadRow;
