import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { getChecksSortedByPurchased } from 'views/manager/features/ScreenApplicant/ScreenApplicantSelectors';
import { CartItem } from './CartItem';
import { CartItemList, CartItemsWrapper } from './styled';

export const CartItems = () => {
    const intl = useIntl();
    const checksSortedByPurchased = useSelector(getChecksSortedByPurchased);

    return (
        <CartItemsWrapper>
            {checksSortedByPurchased.length === 0 ? (
                <div style={{ marginBottom: '5px', fontWeight: 600 }}>
                    {intl.formatMessage({
                        id: 'acce0.ScreenApplicantCart.noServices',
                        defaultMessage: 'No services selected',
                    })}
                </div>
            ) : (
                <CartItemList>
                    {checksSortedByPurchased.map((check, key) => (
                        <CartItem key={key} check={check} />
                    ))}
                </CartItemList>
            )}
        </CartItemsWrapper>
    );
};
