import { useFlags } from 'launchdarkly-react-client-sdk';
import { FormattedMessage, useIntl } from 'react-intl';
import { StaticResult } from '../AdvancedFilteringLayout';
import { FilterItem, FilterTitle } from '../styled';
import Constants from 'utils/constants';
import { useTranslateOrderStatus } from 'hooks/useTranslateOrderStatus';

type StatusFilterProps = {
    value?: string[];
    onChange: (value: string[]) => void;
};

export const StatusFilter: React.FC<StatusFilterProps> = ({ value, onChange }) => {
    const intl = useIntl();
    const { webFeatureEnableNewStatusSystem } = useFlags();
    const { translateOrderStatus } = useTranslateOrderStatus();

    // LDFlag webFeatureEnableNewStatusSystem
    let statusesList;
    if (webFeatureEnableNewStatusSystem) {
        statusesList = [
            {
                request: Constants.orderStatus.ACTION_REQUIRED,
                title: translateOrderStatus(Constants.orderStatus.ACTION_REQUIRED),
            },
            {
                request: Constants.orderStatus.CANCELLED,
                title: translateOrderStatus(Constants.orderStatus.CANCELLED),
            },
            {
                request: Constants.orderStatus.COMPLETE,
                title: translateOrderStatus(Constants.orderStatus.COMPLETE),
            },
            {
                request: Constants.orderStatus.IN_DISPUTE,
                title: translateOrderStatus(Constants.orderStatus.IN_DISPUTE),
            },
            {
                request: Constants.orderStatus.IN_PROGRESS,
                title: translateOrderStatus(Constants.orderStatus.IN_PROGRESS),
            },
            {
                request: Constants.orderStatus.WAITING_ON_CANDIDATE,
                title: translateOrderStatus(Constants.orderStatus.WAITING_ON_CANDIDATE),
            },
        ];
    } else {
        statusesList = [
            {
                request: Constants.reportStatus.COMPLETE,
                title: intl.formatMessage({ id: 'status.complete', defaultMessage: 'Complete' }),
            },
            {
                request: Constants.reportStatus.PARTIAL,
                title: intl.formatMessage({ id: 'status.partial', defaultMessage: 'Partial' }),
            },
            {
                request: Constants.reportStatus.ACTION_REQUIRED,
                title: intl.formatMessage({
                    id: 'status.requiresAction',
                    defaultMessage: 'Requires action',
                }),
            },
            {
                request: Constants.reportStatus.ANALYZING,
                title: intl.formatMessage({ id: 'status.analyzing', defaultMessage: 'Analyzing' }),
            },
            {
                request: Constants.reportStatus.SENT,
                title: intl.formatMessage({
                    id: 'status.waitingOnCandidate',
                    defaultMessage: 'Waiting on candidate',
                }),
            },
            {
                request: Constants.reportStatus.UNRESPONSIVE,
                title: intl.formatMessage({
                    id: 'status.unresponsive',
                    defaultMessage: 'Unresponsive',
                }),
            },
        ];
    }

    return (
        <FilterItem>
            <FilterTitle>
                <FormattedMessage
                    id="225d8.AdvancedFiltering.searchByStatus"
                    defaultMessage="Search by application status:"
                />
            </FilterTitle>
            <StaticResult data-testid="advancedFilteringStatus" selected={value} handleChange={onChange}>
                {statusesList}
            </StaticResult>
        </FilterItem>
    );
};
