// Libraries
import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { get } from 'lodash';

// Modules
import { OTHER } from '../modules/Countries';

// Components
import { InfoCircleOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import { Button, Popover } from 'antd';
import FormLayout from 'certn-form/FormLayout';
import FormItem from 'certn-form/FormItem';
import AddressAutoComplete from 'certn-form/AddressAutoComplete';
import { TrackNavigation } from 'views/welcome/components';

// Actions & Selectors
import { updateAddressError } from 'base/BaseActions';
import { getIsFetchingSilent } from 'views/welcome/WelcomeSelectors';
import { patchInformation } from 'views/welcome/WelcomeActions';

const mapStateToProps = (state) => ({
    isFetchingSilent: getIsFetchingSilent(state),
});
const mapDispatchToProps = (dispatch) => bindActionCreators({ patchInformation, updateAddressError }, dispatch);

const propTypes = { updateAddressError: PropTypes.func.isRequired };
const defaultProps = {};

const CustomForm = styled(Form)`
    ${({ leftAligned }) => !leftAligned && 'width: 100%'}
`;

const ConvictionWrapper = styled.div`
    margin: 10px 0;
`;

class MultiAddItemsForm extends React.Component {
    constructor(props) {
        super(props);

        let items = [];
        if (this.props.config.name === 'courses') {
            this.props.items[this.props.currentEducationIndex].courses.map((item, i) => {
                items.push(i);
                return null;
            });
        } else {
            this.props.items.map((item, i) => {
                items.push(i);
                return null;
            });
        }

        if (items.length === 0) {
            items = [0];
        }

        this.state = {
            items,
        };
    }

    remove = (k) => {
        this.setState((prevState) => ({ items: prevState.items.filter((item) => item !== k) }));
    };

    add = () => {
        const { items } = this.state;
        const lastItem = items[items.length - 1] + 1;
        const nextItems = items.concat(lastItem);
        this.setState({ items: nextItems });
    };

    formatAndSubmit = (e, validateFields) => {
        e.preventDefault();
        validateFields((err, values) => {
            if (!err) {
                const { config } = this.props;

                if (values[config.keys[0].id]) {
                    const items = [];
                    values[config.keys[0].id].map((name, i) => {
                        const tempObject = {};
                        config.keys.map((keyName) => {
                            if (keyName.id !== 'address') {
                                tempObject[keyName.id] = values[keyName.id][i];
                            }
                            return null;
                        });
                        items.push(tempObject);

                        // custom nesting for Rent Refusals / Evictions
                        if (config.name === 'rent_refusals' || config.name === 'evictions') {
                            items[i].address = {
                                country: values[`country-${i}`],
                                province_state: values[`province_state-${i}`] || OTHER,
                                other_province_state: values[`other_province_state-${i}`] || null,
                                city: values[`city-${i}`],
                                address: values[`address-${i}`],
                                postal_code: values[`postal_code-${i}`],
                            };
                        }
                        // I hate this, but the original component was written extremely poorly.. We need to refactor this shit
                        if (config.name === 'convictions') {
                            const convictionSplit = items[i].description.split('_');
                            // eslint-disable-next-line
                            items[i].description = convictionSplit[0];
                            if (convictionSplit[1]) {
                                // eslint-disable-next-line
                                items[i].offense = convictionSplit[1];
                            }
                        }
                        return null;
                    });
                    values[config.name] = items;
                }

                config.keys.map((item) => {
                    delete values[item.id];
                    return null;
                });
                this.props.handleSubmit(values);
            } else {
                // provide failure error
            }

            if (!values.address || !values.city || !values.country) {
                this.props.updateAddressError(true);
            }
        });
    };

    render() {
        const { items } = this.state;
        const { size, offset, leftAligned } = this.props;
        const keyNames = this.props.config.keys;

        const FormType = leftAligned ? FormLayout.R1NDefault : FormLayout.R2N;

        return (
            <>
                <CustomForm
                    leftAligned={leftAligned}
                    layout="horizontal"
                    onSubmit={(e) => this.formatAndSubmit(e, this.props.form.validateFields)}
                >
                    <FormType size={size} offset={offset}>
                        {items.map((i) => (
                            <ConvictionWrapper key={i}>
                                {keyNames.map((keyName, index) => {
                                    let initialValue;
                                    if (this.props.config.name === 'courses') {
                                        initialValue = get(this.props, [
                                            'items',
                                            this.props.currentEducationIndex,
                                            'courses',
                                            i,
                                            keyName.id,
                                        ]);
                                    } else {
                                        initialValue = get(this.props, ['items', i, keyName.id]);
                                    }

                                    const fieldName = `${keyName.id}[${i}]`;

                                    if (keyName.id === 'address') {
                                        return (
                                            <>
                                                <AddressAutoComplete
                                                    getFieldDecorator={this.props.form.getFieldDecorator}
                                                    form={this.props.form}
                                                    addressError={this.props.addressError}
                                                    hideLabels
                                                    autocompleteId={i}
                                                    currentAddress={initialValue}
                                                    bypassCounty
                                                />
                                                {items.length > 1 && i > 0 && index === keyNames.length - 1 ? (
                                                    <MinusCircleOutlined
                                                        className="dynamic-delete-button"
                                                        onClick={() => this.remove(i)}
                                                        style={{ padding: '0 5px', boxSizing: 'border-box' }}
                                                    />
                                                ) : null}
                                            </>
                                        );
                                    }
                                    return (
                                        <FormItem
                                            key={index}
                                            style={{
                                                margin: 0,
                                                marginBottom: index === keyNames.length - 1 ? '20px' : '0px',
                                                display: keyName.hide ? 'none' : 'initial',
                                            }}
                                            required={keyName.required}
                                            form={this.props.form}
                                            fieldName={fieldName}
                                            initialValue={initialValue}
                                            placeholder={keyName.name}
                                            addonBefore={
                                                <Popover content={keyName.name}>
                                                    <InfoCircleOutlined />
                                                </Popover>
                                            }
                                            showDelete={items.length > 1 && i > 0 && index === keyNames.length - 1}
                                            onDelete={() => this.remove(i)}
                                            rules={keyName.rules}
                                            dateField={keyName.dateField}
                                            disablePastDates={keyName.disablePastDates}
                                            disableFutureDates={keyName.disableFutureDates}
                                            disableToday={keyName.disableToday}
                                            isBirthDate={keyName.isBirthDate}
                                            display={keyName.display}
                                        />
                                    );
                                })}
                            </ConvictionWrapper>
                        ))}
                    </FormType>
                    <Form.Item>
                        <Button type="dashed" onClick={this.add}>
                            <PlusOutlined /> {this.props.config.buttonText}
                        </Button>
                    </Form.Item>
                    {!this.props.leftAligned && <TrackNavigation />}
                </CustomForm>
            </>
        );
    }
}

MultiAddItemsForm.propTypes = propTypes;
MultiAddItemsForm.defaultProps = defaultProps;

export default Form.create()(connect(mapStateToProps, mapDispatchToProps)(MultiAddItemsForm));
