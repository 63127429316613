import { useQuery } from '@tanstack/react-query';
import { queryKeys, QueryOptions } from 'utils/reactQuery';
import { Postings } from 'types';
import { getRequest } from 'utils/http';

const fetchPostings = () =>
    getRequest<Postings>({
        hr: true,
        version: 'v1',
        endpoint: `/listings/?is_active=true&page=1&page_size=9001`,
    });

export const usePostings = (options?: QueryOptions) => {
    const { data, isLoading, isError, refetch } = useQuery<Postings>([queryKeys.postings], fetchPostings, options);

    return {
        postings: data,
        isPostingsLoading: isLoading,
        hasPostingsError: isError,
        refetchPostings: refetch,
    };
};
