// Libraries
import React from 'react';
import { Form } from '@ant-design/compatible';
import { FormattedMessage } from 'react-intl';

// Components
import Title from 'certn-ui/Title';
import { AppearRight } from 'certn-ui/Animate';
import { FlexCardRow } from 'certn-ui/Card';
import { TrackNavigation } from 'views/welcome/components';
import { RadioCard } from 'certn-ui/Radio';
import FelonySVG from 'images/svgs/svg-components/onboarding/Felony';

const BaseForm = ({ form, handleSubmit, intl, convictions }) => (
    <AppearRight>
        <Title>
            <FormattedMessage
                id="welcome.RcmpReflow.BaseForm.title"
                defaultMessage="Our initial analysis shows you may have omitted or incorrectly entered a criminal conviction. Can you confirm"
            />
        </Title>
        <Form layout="horizontal" onSubmit={(e) => handleSubmit(e, form.validateFields)}>
            <FlexCardRow>
                <Form.Item>
                    {form.getFieldDecorator('convictions', {
                        valuePropName: 'checked',
                        initialValue: (convictions && convictions.length > 0) || undefined,
                    })(
                        <RadioCard
                            field="convictions"
                            title={intl.formatMessage({
                                id: 'welcome.History.BaseForm.convictionsTitle',
                                defaultMessage: 'I have been convicted of a crime and have not been pardoned',
                            })}
                            // description="I have been convicted of a crime."
                            getField={form.getFieldValue}
                            setField={form.setFieldsValue}
                            SVG={FelonySVG}
                        />
                    )}
                </Form.Item>
            </FlexCardRow>
            <TrackNavigation />
        </Form>
    </AppearRight>
);

export default Form.create()(BaseForm);
