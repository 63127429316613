// Libraries
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { message } from 'antd';
import { injectIntl } from 'react-intl';

// Components
import ProofOfAddressForm from './ProofOfAddressForm';
import { ErrorAlertCustom } from 'certn-ui/ErrorAlert';

// Modules
import withNavigation from 'views/welcome/modules/WithNavigation';

// Actions & Selectors
import {
    setTrackPageOrder,
    patchInformation,
    fetchSignedS3Link,
    confirmUploadSuccessful,
    deleteDocument,
} from 'views/welcome/WelcomeActions';
import {
    getIsFetchingSilent,
    getInformation,
    getS3UploadLink,
    getS3UploadFields,
    getPreparedDocument,
    getDefaultFileList,
    getOnboardingType,
    getSettings,
    getApplicant,
    getProofOfAddressReq,
    getDocuments,
    getTrackOrder,
} from 'views/welcome/WelcomeSelectors';

const mapStateToProps = (state) => ({
    isFetchingSilent: getIsFetchingSilent(state),
    information: getInformation(state),
    s3UploadLink: getS3UploadLink(state),
    s3UploadFields: getS3UploadFields(state),
    preparedDocument: getPreparedDocument(state),
    fileList: getDefaultFileList(state),
    onboardingType: getOnboardingType(state),
    settings: getSettings(state),
    applicant: getApplicant(state),
    proofOfAddressReq: getProofOfAddressReq(state),
    documents: getDocuments(state),
    tracks: getTrackOrder(state),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setTrackPageOrder,
            patchInformation,
            fetchSignedS3Link,
            confirmUploadSuccessful,
            deleteDocument,
        },
        dispatch
    );

const propTypes = {
    // Redux Actions:
    patchInformation: PropTypes.func.isRequired,
    setTrackPageOrder: PropTypes.func.isRequired,
    fetchSignedS3Link: PropTypes.func.isRequired,
    confirmUploadSuccessful: PropTypes.func.isRequired,
    deleteDocument: PropTypes.func.isRequired,
    // Redux Store:
    isFetchingSilent: PropTypes.bool.isRequired,
    information: PropTypes.object.isRequired,
    loginError: PropTypes.object,
    s3UploadLink: PropTypes.string,
    s3UploadFields: PropTypes.object,
    preparedDocument: PropTypes.object,
    fileList: PropTypes.array,
    settings: PropTypes.object,
    proofOfAddressReq: PropTypes.bool,
    documents: PropTypes.array,
    tracks: PropTypes.array,
};
const defaultProps = {
    s3UploadLink: undefined,
    s3UploadFields: undefined,
    preparedDocument: undefined,
    settings: {},
    fileList: [],
    loginError: null,
    proofOfAddressReq: false,
    documents: [],
    tracks: [],
};

class ProofOfAddress extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false,
            proofOfAddress: {},
            otherDocs: [],
            fileList: [],
            proofOfAddressProgress: 0,
            uploadComplete: false,
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        const { documents } = this.props;
        const [proofOfAddress] = documents.filter((doc) => doc.document_type === 'PROOF_OF_ADDRESS');
        this.props.setTrackPageOrder(['base']); /* WithNavigation */
        this.setState({
            fileList: this.props.fileList,
            proofOfAddress: proofOfAddress || {},
            uploadComplete: !!proofOfAddress,
        });
    }

    onUpload = () => {
        const { fileList } = this.state;
        const { intl } = this.props;

        if (this.props.preparedDocument) {
            if (fileList.map((item) => item.name).includes(this.props.preparedDocument.file_name)) {
                message.error(
                    intl.formatMessage({ id: 'welcome.Documents.duplicateName', defaultMessage: 'Duplicate file name' })
                );
                return;
            }

            const newFileList = [...fileList];
            const newDocument = {
                uid: this.props.preparedDocument.id,
                name: this.props.preparedDocument.file_name,
                status: 'done',
                url: this.props.preparedDocument.url,
                type: this.props.preparedDocument.document_type,
            };
            newFileList.push(newDocument);
            this.setState({ fileList: newFileList, proofOfAddress: newDocument }, () => this.props.patchInformation());
        }
    };

    truncateFileName = (name) => (name.length > 20 ? `${name.substring(0, 20)}...` : name);

    // called periodically as upload progresses
    handleProgress = ({ event, eventFile }) => {
        const { intl } = this.props;
        const proofOfAddressProgress = Math.round(event.percent);
        const uploadComplete = proofOfAddressProgress === 100;
        if (uploadComplete)
            message.success(
                intl.formatMessage(
                    { id: 'welcome.Documents.uploadSuccessful', defaultMessage: '{fileName} uploaded successfully!' },
                    { fileName: this.truncateFileName(eventFile.name) }
                )
            );
        this.setState({ proofOfAddressProgress, uploadComplete });
    };

    isProofOfAddressMissing = () => Object.values(this.state.proofOfAddress).length === 0;

    getProofOfAddressFromFileList = () => this.state.fileList.filter((doc) => doc.type === 'PROOF_OF_ADDRESS')[0] || {};

    getOtherDocs = () => this.state.fileList.filter((doc) => doc.type !== 'PROOF_OF_ADDRESS') || {};

    documentTypeValid = (fileType) => /jpg|jpeg|png|gif|pdf|doc|docx|ppt|pptx|odt|xls|xlsx|txt|text/.test(fileType);

    // For document upload
    s3GetUploadLink = (file, documentType = 'PROOF_OF_ADDRESS') => {
        if (this.documentTypeValid(file.type)) {
            return this.props.fetchSignedS3Link(file, documentType);
        }

        const { fileList } = this.state;
        const { intl } = this.props;
        if (fileList.map((item) => item.name).includes(file.name)) return false;

        message.error(
            intl.formatMessage({
                id: 'welcome.Documents.errorUploadingDocumentType',
                defaultMessage: 'Invalid file type',
            })
        );
        return false;
    };

    handleDeleteDocument = (file) => {
        const { fileList } = this.state;
        const { intl } = this.props;
        this.props
            .deleteDocument(file)
            .then(() => this.props.patchInformation())
            .catch(() => ErrorAlertCustom());
        const index = fileList.findIndex((f) => f.type === 'PROOF_OF_ADDRESS');
        const newFileList = [...fileList];
        newFileList.splice(index, 1);

        message.warn(
            intl.formatMessage(
                {
                    id: 'welcome.Documents.documentRemoved',
                    defaultMessage: '{fileName} removed',
                },
                { fileName: this.truncateFileName(file.name) }
            )
        );

        this.setState({ fileList: newFileList, proofOfAddress: {}, proofOfAddressProgress: 0, uploadComplete: false });
    };

    handleSubmit = (e, validateFields) => {
        e.preventDefault();
        const { handleNextTrack } = this.props; /* WithNavigation */
        const { intl } = this.props;
        const proofOfAddress = this.props.preparedDocument || this.getProofOfAddressFromFileList();
        if (!proofOfAddress.id) proofOfAddress.id = proofOfAddress.uid;
        const otherDocs = this.getOtherDocs();

        if (this.isProofOfAddressMissing()) {
            validateFields();
            return this.setState({ error: true }, () =>
                message.error(
                    intl.formatMessage({
                        id: 'welcome.Documents.missingRequiredDocuments',
                        defaultMessage: 'You are missing required documents',
                    })
                )
            );
        }

        validateFields((err, values) => {
            if (!err) {
                // API gets signed links and then file gets uploaded to S3, we need to verify that the finished upload completes
                // and tell the API it was successful, otherwise delete the file. Research into customRequest
                const proofOfAddressCheckAsync = new Promise((resolve, reject) =>
                    fetch(proofOfAddress.url).then((response) => {
                        if (response.ok) {
                            this.props.confirmUploadSuccessful(proofOfAddress.id).then(resolve);
                        } else {
                            this.handleDeleteDocument(proofOfAddress);
                            this.setState({ error: true });
                            message.error(
                                intl.formatMessage(
                                    {
                                        id: 'welcome.Documents.errorUploadingDocument',
                                        defaultMessage: 'Error uploading document: {name}',
                                    },
                                    { name: proofOfAddress.name }
                                )
                            );
                            reject();
                        }
                    })
                );

                proofOfAddressCheckAsync.then(() => {
                    this.setState({ error: false });
                    const updatedProofOfAddress = { ...proofOfAddress, ...values };
                    const updatedDocuments = { documents: [updatedProofOfAddress, ...otherDocs] };
                    return this.props.patchInformation(updatedDocuments).then(handleNextTrack());
                });
            }
        });
    };

    pages = () => {
        const { s3UploadFields, s3UploadLink, onboardingType, isFetchingSilent } = this.props;
        const { fileList, error, proofOfAddressProgress, uploadComplete } = this.state;
        const proofOfAddress = this.getProofOfAddressFromFileList();
        const missing = this.isProofOfAddressMissing();

        return {
            base: (
                <ProofOfAddressForm
                    handleSubmit={this.handleSubmit}
                    information={this.props.information}
                    documentNumber={this.state.proofOfAddress.document_number}
                    documentExpiration={this.state.proofOfAddress.document_expiration}
                    documentIssuingCountry={this.state.proofOfAddress.document_issuing_country}
                    documentIssuingProvinceState={this.state.proofOfAddress.document_issuing_province_state}
                    s3GetUploadLink={this.s3GetUploadLink}
                    handleDeleteDocument={this.handleDeleteDocument}
                    onUpload={this.onUpload}
                    handleChangeDocumentType={this.handleChangeDocumentType}
                    s3UploadLink={s3UploadLink}
                    s3UploadFields={s3UploadFields}
                    proofOfAddress={proofOfAddress}
                    fileList={fileList}
                    error={error}
                    isFetchingSilent={isFetchingSilent}
                    onboardingType={onboardingType}
                    missing={missing}
                    handleProgress={this.handleProgress}
                    fileProgress={{ PROOF_OF_ADDRESS: proofOfAddressProgress }}
                    uploadComplete={uploadComplete}
                />
            ),
        };
    };

    render() {
        return this.pages()[this.props.trackPageLocation]; /* WithNavigation */
    }
}

ProofOfAddress.propTypes = propTypes;
ProofOfAddress.defaultProps = defaultProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(withNavigation(ProofOfAddress))));
