// Libraries
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { get } from 'lodash';

// Components
import { Roommates, BaseForm, CosignerForm, PetsForm, CarForm } from './components';

// Actions & Selectors
import { patchInformation, patchWelcomeSession, setTrackPageLocation } from 'views/welcome/WelcomeActions';
import {
    getIsFetchingSilent,
    getInformation,
    getPets,
    getOccupants,
    getNextTrack,
    getPrevTrack,
    getApplicantAccount,
} from 'views/welcome/WelcomeSelectors';

const mapStateToProps = (state) => ({
    isFetchingSilent: getIsFetchingSilent(state),
    information: getInformation(state),
    pets: getPets(state),
    occupants: getOccupants(state),
    nextTrack: getNextTrack(state, 'supplemental'),
    prevTrack: getPrevTrack(state, 'supplemental'),
    applicantAccount: getApplicantAccount(state),
});

const mapDispatchToProps = (dispatch) => ({
    dispatch,
    ...bindActionCreators(
        {
            patchInformation,
            patchWelcomeSession,
            setTrackPageLocation,
        },
        dispatch
    ),
});

const propTypes = {
    // Redux Actions:
    patchInformation: PropTypes.func.isRequired,
    patchWelcomeSession: PropTypes.func.isRequired,
    // Redux Store:
    isFetchingSilent: PropTypes.bool.isRequired,
    information: PropTypes.object.isRequired,
    pets: PropTypes.string,
    applicantAccount: PropTypes.object,
    loginError: PropTypes.object,
    nextTrack: PropTypes.string,
    prevTrack: PropTypes.string,
};
const defaultProps = {
    applicantAccount: {},
    loginError: undefined,
    pets: undefined,
    nextTrack: 'supplemental',
    prevTrack: 'supplemental',
};

class Supplemental extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            trackPageLocation: 'base',
            pageOrder: ['base'],
            resetObj: {
                roommates: {
                    occupants: [],
                },
                cosigner: {
                    co_signer_first_name: null,
                    co_signer_last_name: null,
                    co_signer_phone: null,
                    co_signer_email: null,
                    co_signer_association: null,
                    co_signer_living_with_applicant: null,
                },
                pets: {
                    pet_details: [],
                },
                car: {
                    car_plate: null,
                    car_prov_state: null,
                },
            },
        };
        window.scrollTo(0, 0);
    }

    pages = () => ({
        base: (
            <BaseForm
                handleSubmit={this.buildPageOrder}
                handleReverse={this.handleReverse}
                information={this.props.information}
                isFetchingSilent={this.props.isFetchingSilent}
                toggleSelect={this.toggleSelect}
                selectOptions={this.state.selectOptions}
            />
        ),
        roommates: <Roommates handleSubmit={this.handleForward} handleReverse={this.handleReverse} />,
        cosigner: (
            <CosignerForm
                handleSubmit={this.handleSubmit}
                handleReverse={this.handleReverse}
                information={this.props.information}
                isFetchingSilent={this.props.isFetchingSilent}
                applicantAccount={this.props.applicantAccount}
            />
        ),
        pets: (
            <PetsForm
                pets={get(this.props, ['information', 'pet_details']) || []}
                handleSubmit={this.patchNewFormValues}
                isFetchingSilent={this.props.isFetchingSilent}
            />
        ),
        car: (
            <CarForm
                handleSubmit={this.handleSubmit}
                handleReverse={this.handleReverse}
                information={this.props.information}
                isFetchingSilent={this.props.isFetchingSilent}
            />
        ),
    });

    simulateNextClick = () => {
        document.getElementById('next').click();
    };

    handleForward = () => {
        const { trackPageLocation, pageOrder } = this.state;
        const currentIndex = pageOrder.indexOf(trackPageLocation);

        if (currentIndex === pageOrder.length - 1) {
            this.props
                .patchWelcomeSession(this.props.nextTrack)
                .then(() => this.props.setTrackPageLocation('base'))
                .then(() => this.props.history.push(`/welcome/${this.props.nextTrack}`));
        } else {
            this.setState({ trackPageLocation: pageOrder[currentIndex + 1] });
        }
    };

    handleReverse = () => {
        const { trackPageLocation, pageOrder } = this.state;
        const currentIndex = pageOrder.indexOf(trackPageLocation);
        if (currentIndex === 0) {
            this.props.history.push(`/welcome/${this.props.prevTrack}`);
        } else {
            this.setState({ trackPageLocation: pageOrder[currentIndex - 1] });
        }
    };

    handleSubmit = (e, validateFields, moduleName = null) => {
        e.preventDefault();
        validateFields((err, values) => {
            if (!err) {
                const hasOwnModule = ['roommate']; // Add this if the fields you're submitting are part of own module
                let patchValues = values;
                if (moduleName && hasOwnModule.includes(moduleName)) {
                    patchValues = { [moduleName]: values };
                }

                if (moduleName === 'co_signer') {
                    patchValues.co_signer_living_with_applicant = patchValues.co_signer_living_with_applicant === 'yes';
                }

                if (values.car_plate) {
                    // split on " " or "-", filter them out, then join with ""
                    patchValues.car_plate = values.car_plate
                        .split(/([- ]+)/)
                        .filter((str) => !/([- ]+)/.test(str))
                        .join('');
                }

                this.patchNewFormValues(patchValues);
            } else {
                // provide failure error
            }
        });
    };

    patchNewFormValues = (values) => {
        this.props.patchInformation(values).then(this.handleForward);
    };

    buildPageOrder = (e, validateFields) => {
        e.preventDefault();
        validateFields((err, values) => {
            if (!err) {
                // Figure out which pages to go to during track
                const pagesToAdd = Object.keys(values).filter((key) => values[key]);
                const newPageOrder = ['base'].concat(pagesToAdd);

                // Figure out which objects to delete during patch
                const pagesToDelete = Object.keys(values).filter((key) => !values[key]);
                let resetObject = {};
                pagesToDelete.forEach((page) => {
                    resetObject = { ...resetObject, ...this.state.resetObj[page] };
                });

                this.setState({ pageOrder: newPageOrder }, () => {
                    this.props.patchInformation(resetObject).then(this.handleForward);
                });
            } else {
                // provide failure error
            }
        });
    };

    render() {
        return this.pages()[this.state.trackPageLocation];
    }
}

Supplemental.propTypes = propTypes;
Supplemental.defaultProps = defaultProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Supplemental));
