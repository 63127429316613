import { useAdjudicationReviewStatuses } from 'hooks/queryHooks/useAdjudicationReviewStatuses';
import { FormattedMessage } from 'react-intl';
import { StaticResult } from '../AdvancedFilteringLayout';
import { FilterItem, FilterTitle } from '../styled';

type AdjudicationReviewStatusProps = {
    value?: string;
    onChange: (value: string) => void;
};

export const AdjudicationReviewStatus: React.FC<AdjudicationReviewStatusProps> = ({ value, onChange }) => {
    const { adjudicationReviewStatuses } = useAdjudicationReviewStatuses();

    if (!adjudicationReviewStatuses) {
        return null;
    }

    const filteredAdjudicationReviewStatuses = Object.entries(adjudicationReviewStatuses)
        .filter(([key]) => key !== 'COMPLETE')
        .map(([key, val]) => ({ request: key, title: val }));

    return (
        <FilterItem>
            <FilterTitle>
                <FormattedMessage id="225d8.AdvancedFiltering.reviewStatus" defaultMessage="Review Status" />
            </FilterTitle>
            <StaticResult
                data-testid="advancedFilteringAdjudicationReviewStatus"
                selected={value}
                allowClear
                mode="default"
                handleChange={onChange}
            >
                {filteredAdjudicationReviewStatuses}
            </StaticResult>
        </FilterItem>
    );
};
