// Libraries
import React from 'react';

// React Router & Routes
import { Switch, Route, Redirect } from 'react-router-dom';
import PostingsList from './views/postingsList/PostingsList';
import PostingsNew from './views/postingsNew/PostingsNew';
import PostingsEdit from './views/postingsEdit/PostingsEdit';

class Postings extends React.Component {
    render() {
        return (
            <Switch>
                <Route path="/hr/packages/new" component={PostingsNew} />
                <Route exact path="/hr/packages/:postingId/edit" component={PostingsEdit} />
                <Route exact path="/hr/packages" component={PostingsList} />
                <Redirect to="/hr/packages" />
            </Switch>
        );
    }
}

export default Postings;
