import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Modal } from 'antd';

// Modules
import withNavigation from 'views/welcome/modules/WithNavigation';
import settingsCheck from 'views/welcome/modules/SettingsChecker';

// Constants
import {
    ADDRESS_SETTINGS,
    EMPLOYER_SETTINGS,
    EMPLOYER_REFERENCE_SETTINGS,
    PERSONAL_SETTINGS,
    CREDENTIAL_SETTINGS,
    EDUCATION_SETTINGS,
} from 'views/welcome/WelcomeConstants';

// Actions & Selectors
import { getLanguage, getLaunchDarklyFlags } from 'base/BaseSelectors';
import {
    getTrackOrder,
    getFurthestTrack,
    getSettings,
    getInformation,
    getOnboardingType,
    getApplicant,
} from 'views/welcome/WelcomeSelectors';

const trackConstantsMap = (track) => {
    const map = {
        addresses: ADDRESS_SETTINGS,
        employers: EMPLOYER_SETTINGS,
        employerReferences: EMPLOYER_REFERENCE_SETTINGS,
        personal: PERSONAL_SETTINGS,
        credential: CREDENTIAL_SETTINGS,
        education: EDUCATION_SETTINGS,
    };
    return map[track];
};

const OPTIONAL = '_optional';

const withSettingsChecker = (WrappedComponent) =>
    withNavigation((props) => {
        const [settingsErrors, setSettingErrors] = useState();
        const [optional, setOptional] = useState(false);
        const currentTrack = useSelector(getFurthestTrack);
        const settings = useSelector(getSettings);
        const applicant = useSelector(getApplicant);
        const information = useSelector(getInformation);
        const onboardingType = useSelector(getOnboardingType);
        const tracks = useSelector(getTrackOrder);
        const language = useSelector(getLanguage);
        const {
            webFeatureEnableNewEmployerReferenceTrack,
            webFeatureRequireCurrentAndPositionPropertyAddressesForOnboarding,
        } = useSelector(getLaunchDarklyFlags);
        const trackConstants = trackConstantsMap(currentTrack);

        const runSettingsCheck = () => {
            const { handleNextTrack } = props; /* WithNavigation */
            const errors = settingsCheck(
                settings,
                applicant,
                information,
                language,
                trackConstants,
                webFeatureEnableNewEmployerReferenceTrack,
                webFeatureRequireCurrentAndPositionPropertyAddressesForOnboarding,
                onboardingType,
                tracks
            );
            if (errors) {
                errorsFound(errors);
            } else {
                // No errors, move to next track
                handleNextTrack();
            }
        };

        const errorsFound = (errors) => {
            // Set errors
            setSettingErrors(errors);
            // SettingsChecker.jsx sets optional keys with [key]_optional to determine if we can Continue Anyway
            if (allKeysOptional(errors)) setOptional(true);
        };

        const allKeysOptional = (errors) => Object.keys(errors).every((key) => key.endsWith(OPTIONAL));

        const getConfirmButtonText = () => {
            if (optional)
                return <FormattedMessage id="welcome.general.continueAnyway" defaultMessage="Continue anyway" />;

            return <FormattedMessage id="common.ok" defaultMessage="OK" />;
        };

        const getCancelButtonText = () => <FormattedMessage id="common.cancel" defaultMessage="Cancel" />;

        const handleCancel = () => {
            resetState();
        };

        const handleOk = () => {
            // If all keys are optional, hitting ok will move forward in the flow
            if (optional) return props.handleNextTrack();
            // Or reset state;
            resetState();
        };

        const resetState = () => {
            setSettingErrors(null);
            setOptional(false);
        };

        // Set button texts
        const confirmButtonText = getConfirmButtonText();
        const cancelButtonText = getCancelButtonText();
        const errorValues = settingsErrors && Object.values(settingsErrors);

        return (
            <>
                {settingsErrors && (
                    <Modal
                        title={
                            <b>
                                <FormattedMessage id="common.warning" defaultMessage="Warning" />
                            </b>
                        }
                        visible={!!settingsErrors}
                        onOk={handleOk}
                        onCancel={handleCancel}
                        okText={confirmButtonText}
                        cancelText={cancelButtonText}
                    >
                        <ul>
                            {errorValues.map((item, index) => {
                                if (errorValues.length > index + 1) {
                                    return (
                                        <li style={{ paddingBottom: '20px' }} key={index}>
                                            {item}
                                        </li>
                                    );
                                }

                                return <li key={index}>{item}</li>;
                            })}
                        </ul>
                    </Modal>
                )}
                <WrappedComponent runSettingsCheck={runSettingsCheck} {...props} />
            </>
        );
    });

export default (WrapperComponent) => withSettingsChecker(WrapperComponent);
