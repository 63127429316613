// Libraries
import React from 'react';
import { useSelector } from 'react-redux';

// Components
import BirthPlaceSelect from 'components/BirthPlaceSelect';

// Actions & Selectors
import { getSelectedCheckKeys } from 'views/manager/features/ScreenApplicant/ScreenApplicantSelectors';

// Modules
import isVisibleOrRequired from './isVisibleOrRequired';

// Constants
import { RCMP_REQUESTS } from 'views/manager/features/ScreenApplicant/ScreenApplicantConstants';

const BirthPlace = ({ form }) => {
    const selectedServices = useSelector(getSelectedCheckKeys);

    // Checks to display field and set required, true in brackets for all services
    const visibleList = RCMP_REQUESTS;

    if (!isVisibleOrRequired(visibleList, selectedServices)) return null;
    return <BirthPlaceSelect size="medium" noAddonBefore form={form} labels />;
};

export default BirthPlace;
