// Libraries
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';

// Components
import { ErrorAlertAPI, ErrorAlertCustom } from 'certn-ui/ErrorAlert';
import { EmailForm } from './EmailForm';
import { useDisplayDisclosureAgreement } from './hooks';

// Modules
import Auth from 'modules/Auth';
import withNavigation from 'views/welcome/modules/WithNavigation';

// Actions & Selectors
import { createWelcomeSession, patchInformation, setTrackPageOrder } from 'views/welcome/WelcomeActions';
import { getOwnerId } from 'views/welcome/WelcomeSelectors';
import { FormProps } from 'antd/lib/form';
import { WarningModal } from './WarningModal';
import { getParams } from 'base/BaseSelectors';
import { validateEmailAddressForSecureSMS } from './utils';
import { isSecureSMSErrorType, SecureSMSErrorType } from './EmailForm/EmailForm';

export const Email = withNavigation(({ handleNextTrack }: { handleNextTrack: () => void }) => {
    const dispatch = useDispatch();
    const ownerId = useSelector(getOwnerId);
    const onboardingUrlParameters = useSelector(getParams);
    const [isWarningVisible, setIsWarningVisible] = useState(false);
    const displayDisclosureAgreement = useDisplayDisclosureAgreement();
    const [secureSMSErrors, setSecureSMSErrors] = useState<SecureSMSErrorType>();
    const [disclosureSigned, setDisclosureSigned] = useState<boolean>(!displayDisclosureAgreement);

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(setTrackPageOrder(['base']));
    }, [dispatch]);

    const handleSubmit: FormProps['onFinish'] = async (values: {
        age_accepted: boolean;
        privacy_policy_accepted: boolean;
        consent_to_marketing: boolean;
        terms_of_use_accepted?: boolean;
        email: string;
        password?: string;
        secure_sms_confirm_email?: string;
        secure_sms_confirm_phone: string;
    }) => {
        // Must consent to all to move on, only one API value to track
        const terms_accepted =
            values.age_accepted &&
            values.privacy_policy_accepted &&
            disclosureSigned &&
            !!(values.terms_of_use_accepted === undefined || values.terms_of_use_accepted);

        if (!terms_accepted) {
            setIsWarningVisible(true);
            return;
        }
        const request = {
            terms_accepted,
        };

        if (Auth.isApplicantAuthenticated()) {
            // Let's our API know it's initialized from the onBoarding flow (used in Vouched)
            await dispatch(patchInformation(request));
            handleNextTrack();
            return;
        }

        const listingId = Cookies.get('listingId');

        if (values?.secure_sms_confirm_email) {
            try {
                await validateEmailAddressForSecureSMS(
                    values.secure_sms_confirm_email,
                    values.secure_sms_confirm_phone,
                    onboardingUrlParameters
                );
            } catch (errors: SecureSMSErrorType | unknown) {
                if (isSecureSMSErrorType(errors)) {
                    setSecureSMSErrors(errors);
                }
                return;
            }
        }

        if (listingId || ownerId) {
            try {
                await dispatch(
                    createWelcomeSession({
                        email: values?.email || values?.secure_sms_confirm_email,
                        password: (values.password || null) as null,
                        consentToMarketing: values.consent_to_marketing,
                    })
                );
                await dispatch(patchInformation(request));
                handleNextTrack();
            } catch (error) {
                ErrorAlertAPI(error);
            }
        } else {
            ErrorAlertCustom({
                title: 'Error Starting Application',
                description:
                    'Make sure the link you followed to get here is correct and try again. If you continue to have issues, please try our support chat window or contact support@certn.co.',
            });
        }
    };

    return (
        <>
            <WarningModal visible={isWarningVisible} onClose={() => setIsWarningVisible(false)} />
            <EmailForm
                handleSubmit={handleSubmit}
                disclosureSigned={disclosureSigned}
                setDisclosureSigned={setDisclosureSigned}
                secureSMSErrors={secureSMSErrors}
            />
        </>
    );
});
