// Libraries
import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

// UI Components
import { LayoutBody } from 'certn-ui/Layout';
import RouteErrorBoundary from 'certn-ui/RouteErrorBoundary';
import { Teams } from './views';

const Admin = () => (
    <LayoutBody>
        <RouteErrorBoundary>
            <Switch>
                <Route path="/admin/teams" component={Teams} />
                <Redirect to="/admin/teams" />
            </Switch>
        </RouteErrorBoundary>
    </LayoutBody>
);

export default Admin;
