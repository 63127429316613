// Libraries
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import Auth from 'modules/Auth';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useFlags } from 'launchdarkly-react-client-sdk';

// Selectors
import { getCurrentTeam } from 'views/manager/views/settings/SettingsSelectors';

const SidebarItem = styled.div`
    display: flex;
    padding: 12px;
    cursor: pointer;
    border: 1px solid ${(props) => props.theme.color.certnGray200};
    border-top: 0px;
    width: 200px;
    text-decoration: none !important;
    transition: 0.1s;
    @media (max-width: ${(props) => props.theme.width.tablet}) {
        width: 100%;
    }
    &:hover {
        background: ${(props) => props.theme.color.certnGray100};
    }
`;

const SidebarMobileWrapper = styled.div`
    @media (max-width: ${(props) => props.theme.width.tablet}) {
        display: ${(props) => (props.display ? 'initial' : 'none')};
    }
`;

const StyledSidebar = styled.div`
    position: sticky;
    top: 80px;
    display: flex;
    flex-direction: column;
    border-top: 1px solid ${(props) => props.theme.color.certnGray200};
    border-bottom: 0px;
    width: 200px;
    margin: 5px 20px 0 0;
    @media (max-width: ${(props) => props.theme.width.tablet}) {
        margin-bottom: 15px;
        margin-right: 0px;
        width: 100%;
    }
`;

const MenuToggle = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const MenuToggleText = styled.div`
    margin-right: 20px;
    font-weight: 600;
`;

const SidebarDropdown = styled.div`
    display: none;
    @media (max-width: ${(props) => props.theme.width.tablet}) {
        display: initial;
    }
`;

const SettingsSidebar = (props) => {
    const [showMenu, setShowMenu] = useState(false);
    const { webFeatureEnableAdverseAction } = useFlags();
    const currentTeamSettings = useSelector(getCurrentTeam);
    const toggleMenu = () => setShowMenu(!showMenu);

    const renderCurrentPage = (tab) => {
        let currentPage = 'Current Page';
        if (tab === 'general') {
            currentPage = 'General';
        } else if (tab === 'users') {
            currentPage = 'Users';
        } else if (tab === 'notifications') {
            currentPage = 'Notifications';
        } else if (tab === 'billing') {
            currentPage = 'Billing';
        } else if (tab === 'applications') {
            currentPage = 'Applications';
        } else if (tab === 'references') {
            currentPage = `${props.params.type === 'address' ? 'Tenancy' : 'Employer'} References`;
        } else if (tab === 'report') {
            currentPage = 'Report';
        } else if (tab === 'support') {
            currentPage = 'Support';
        } else if (tab === 'api-keys') {
            currentPage = 'ApiKeys';
        } else if (tab === 'integrations') {
            currentPage = 'Integrations';
        } else if (tab === 'adverse-action') {
            currentPage = 'AdverseAction';
        }

        return currentPage;
    };
    const { teamId } = props;

    return (
        <div>
            {/* div used as containing block for sidebar sticky positioning */}
            <StyledSidebar>
                <SidebarDropdown>
                    <SidebarItem onClick={toggleMenu}>
                        <MenuToggle style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                            <MenuToggleText>{renderCurrentPage(props.tab)}</MenuToggleText>
                            <LegacyIcon type={showMenu ? 'caret-up' : 'caret-down'} />
                        </MenuToggle>
                    </SidebarItem>
                </SidebarDropdown>
                <SidebarMobileWrapper onClick={toggleMenu} display={showMenu ? 'false' : undefined}>
                    {/* GENERAL */}
                    <NavLink to={`/settings/general/${teamId || ''}`} activeStyle={{ fontWeight: 'bolder' }}>
                        <SidebarItem data-testid="settings-sidebar-item">
                            <FormattedMessage id="789a1.SettingsSidebar.General" defaultMessage="General" />
                        </SidebarItem>
                    </NavLink>
                    {/* USERS */}
                    {Auth.isPermissionLevel('manager') && (
                        <NavLink to={`/settings/users/${teamId || ''}`} activeStyle={{ fontWeight: 'bolder' }}>
                            <SidebarItem data-testid="settings-sidebar-item">
                                <FormattedMessage id="789a1.SettingsSidebar.Users" defaultMessage="Users" />
                            </SidebarItem>
                        </NavLink>
                    )}
                    {/* NOTIFICATIONS */}
                    <NavLink to={`/settings/notifications/${teamId || ''}`} activeStyle={{ fontWeight: 'bolder' }}>
                        <SidebarItem data-testid="settings-sidebar-item">
                            <FormattedMessage id="789a1.SettingsSidebar.Notifications" defaultMessage="Notifications" />
                        </SidebarItem>
                    </NavLink>
                    {/* BILLING */}
                    {Auth.isPermissionLevel('admin') && (
                        <NavLink to={`/settings/billing/${teamId || ''}`} activeStyle={{ fontWeight: 'bolder' }}>
                            <SidebarItem data-testid="settings-sidebar-item">
                                <FormattedMessage id="789a1.SettingsSidebar.Billing" defaultMessage="Billing" />
                            </SidebarItem>
                        </NavLink>
                    )}
                    {/* APPLICATIONS */}
                    <NavLink to={`/settings/applications/${teamId || ''}`} activeStyle={{ fontWeight: 'bolder' }}>
                        <SidebarItem data-testid="settings-sidebar-item">
                            <FormattedMessage id="common.applications" defaultMessage="Applications" />
                        </SidebarItem>
                    </NavLink>
                    {/* REFERENCES */}
                    {Auth.isPermissionLevel('manager') && props.isPM && (
                        <NavLink to={`/settings/references/${teamId}/address`} activeStyle={{ fontWeight: 'bolder' }}>
                            <SidebarItem data-testid="settings-sidebar-item">
                                <FormattedMessage id="789a1.SettingsSidebar.References" defaultMessage="References" />
                            </SidebarItem>
                        </NavLink>
                    )}
                    {Auth.isPermissionLevel('manager') && !props.isPM && (
                        <NavLink to={`/settings/references/${teamId}/employer`} activeStyle={{ fontWeight: 'bolder' }}>
                            <SidebarItem data-testid="settings-sidebar-item">
                                <FormattedMessage id="789a1.SettingsSidebar.References" defaultMessage="References" />
                            </SidebarItem>
                        </NavLink>
                    )}
                    {/* REPORT */}
                    {Auth.isPermissionLevel('manager') && (
                        <NavLink to={`/settings/report/${teamId || ''}`} activeStyle={{ fontWeight: 'bolder' }}>
                            <SidebarItem data-testid="settings-sidebar-item">
                                <FormattedMessage id="789a1.SettingsSidebar.Report" defaultMessage="Report" />
                            </SidebarItem>
                        </NavLink>
                    )}
                    {/* DOCUMENTS */}
                    {Auth.isPermissionLevel('manager') && (
                        <NavLink to={`/settings/documents/${teamId || ''}`} activeStyle={{ fontWeight: 'bolder' }}>
                            <SidebarItem data-testid="settings-sidebar-item">
                                <FormattedMessage id="common.documents" defaultMessage="Documents" />
                            </SidebarItem>
                        </NavLink>
                    )}
                    {/* INTEGRATIONS */}
                    {Auth.isPermissionLevel('admin') && (
                        <NavLink to={`/settings/integrations/${teamId || ''}`} activeStyle={{ fontWeight: 'bolder' }}>
                            <SidebarItem data-testid="settings-sidebar-item">
                                <FormattedMessage
                                    id="789a1.SettingsSidebar.Integrations"
                                    defaultMessage="Integrations"
                                />
                            </SidebarItem>
                        </NavLink>
                    )}
                    {/* CUSTOMER SUPPORT */}
                    <NavLink to={`/settings/support/${teamId || ''}`} activeStyle={{ fontWeight: 'bolder' }}>
                        <SidebarItem>
                            <FormattedMessage id="789a1.SettingsSidebar.Support" defaultMessage="Support" />
                        </SidebarItem>
                    </NavLink>
                    {/* API KEYS */}
                    {Auth.isPermissionLevel('manager') && (
                        <NavLink to={`/settings/api-keys/${teamId || ''}`} activeStyle={{ fontWeight: 'bolder' }}>
                            <SidebarItem>
                                <FormattedMessage id="789a1.SettingsSidebar.APIKeys" defaultMessage="API Keys" />
                            </SidebarItem>
                        </NavLink>
                    )}
                    {/* ADVERSE ACTION */}
                    {webFeatureEnableAdverseAction && currentTeamSettings?.enable_adverse_action && (
                        <NavLink to={`/settings/adverse-action/${teamId || ''}`} activeStyle={{ fontWeight: 'bolder' }}>
                            <SidebarItem>
                                <FormattedMessage
                                    id="789a1.SettingsSidebar.AdverseAction"
                                    defaultMessage="Adverse Action"
                                />
                            </SidebarItem>
                        </NavLink>
                    )}
                </SidebarMobileWrapper>
            </StyledSidebar>
        </div>
    );
};

export default SettingsSidebar;
