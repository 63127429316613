/**
 * getChecksConfig, formerly MicroServicesModule is used to further configure the
 * results of the new API generic checks. We need a way to mutate the state for better
 * handling, but also provide override methods for certain pathings
 * Example API result:
 *     {
        name: 'identity_verification',
        turn_around_time: 0,
        selection_dependent_on_request_flags: [],
        selection_on_create_prohibited_by_request_flags: ['request_enhanced_identity_verification'],
        selection_on_upgrade_prohibited_by_request_flag: ['request_enhanced_identity_verification'],
        price: 1.99,
        is_permitted: true,
        is_selected: false,
        is_configured: true,
        is_purchased: null,
        is_eligible: null,
        request_flag: 'request_identity_verification',
    },
* post configuration:
    {
        requestFlag: 'request_identity_verification',
        selectionOnCreateProhibitedByRequestFlags:  ['request_enhanced_identity_verification'],
        selectionOnUpgradeProhibitedByRequestFlag: ['request_enhanced_identity_verification'],
        selectionDependentOnRequestFlags: [],
        isConfigured: true,
        isOpen: false,
        isPermitted: true,
        isSelected: false,
        isPurchased: null,
        price: '1.99',
        isLocked: false,
        isInFollowUpFlow: false,
    }
*/

// Selectors
import {
    getApplicationOfInterest,
    getIsUpgrade,
} from 'views/manager/features/ScreenApplicant/ScreenApplicantSelectors';
import {
    getUserModeIsHR,
    getTeam,
    getTeamIsCA,
    getUser,
    getLaunchDarklyFlags,
    getConfigurablePotatoChecks,
} from 'base/BaseSelectors';
import { isEmpty } from 'lodash';

// Constants
import { CHECK_REQUEST, QUICK_SCREEN_CHECKS_HR, QUICK_SCREEN_CHECKS_PM } from 'base/BaseConstants';

// Utils
import {
    getAdditionalOptionsKeys,
    getCheckRequestsToTitles,
    getOptions,
    turnaroundTime,
} from 'views/manager/features/ScreenApplicant/components/ScreenApplicant/MicroServiceContent';

// Used for shopping cart items
const getPurchasedOverides = (isUserModeHR, request_flag, application, is_purchased, isUpgrade) => {
    if (!application) return is_purchased;
    const checks = {
        // Custom Purchase Logic
        // request_base will always be purchased off selection if PM and application creation
        [CHECK_REQUEST.BASE]: !!application?.[CHECK_REQUEST.BASE] || (!isUserModeHR && !isUpgrade),
        [CHECK_REQUEST.ENHANCED_IDENTITY_VERIFICATION]: !!application?.[CHECK_REQUEST.ENHANCED_IDENTITY_VERIFICATION],
        [CHECK_REQUEST.US_CRIMINAL_RECORD_CHECK_TIER_1]:
            !!application?.[CHECK_REQUEST.US_CRIMINAL_RECORD_CHECK_TIER_2] ||
            !!application?.[CHECK_REQUEST.US_CRIMINAL_RECORD_CHECK_TIER_3],
        [CHECK_REQUEST.US_CRIMINAL_RECORD_CHECK_TIER_2]: !!application?.[CHECK_REQUEST.US_CRIMINAL_RECORD_CHECK_TIER_3],
    };
    return checks[request_flag] || is_purchased;
};

/**
 * getSelectionOnUpgradeProhibitedBy
 * These checks are locked during the upgrade process if any of the checks listed in selection_on_upgrade_prohibited_by_request_flags have been purchased
 * *
 * This is needed for checks like us crim 1/2/3 which only return previous tiers as is_purchased
 * once the Pipeline returns with a result. Also for references checks which once you purchase email,
 * you cannot upgrade to phone reference.
 * @param {object} application - application with request_ boolean flags that tell us if the check is purchased or not
 * @param {array} selection_on_upgrade_prohibited_by_request_flags - purchased checks which block upgrading of current check
 * @returns - boolean if any of the upgrade prohibited checks have been purchased (are truthy) on the application object
 */
const getSelectionOnUpgradeProhibitedBy = (application, selectionOnUpgradeProhibitedByRequestFlags) =>
    selectionOnUpgradeProhibitedByRequestFlags.some((check) => !!application[check]);

// PM Base is always selected
const getSelectedOverides = (isUserModeHR, request_flag, is_selected, isUpgrade) => {
    // only needs true case pathing
    if (request_flag === CHECK_REQUEST.BASE) {
        if (!isUserModeHR && !isUpgrade) return true;
    }

    return is_selected;
};

const getPermittedOverides = (request_flag, isUpgrade, isEligible, is_permitted, launchDarklyFlags) => {
    // requires both true and false pathing as we are overriding what is_permitted handles
    // check specific overrides added for parity with the old system
    const checksToOverride = [
        CHECK_REQUEST.EQUIFAX,
        CHECK_REQUEST.BASE,
        CHECK_REQUEST.SOFTCHECK,
        CHECK_REQUEST.SOCIAL_MEDIA,
    ];

    if (checksToOverride.includes(request_flag)) {
        if (isUpgrade && isEligible) return true;
        if (isUpgrade && !isEligible) return false;
    }

    // if Equifax Identity Verification is disabled for the team, they can't order it on upgrade
    if (
        isUpgrade &&
        request_flag === CHECK_REQUEST.IDENTITY_VERIFICATION &&
        launchDarklyFlags?.webFeatureDisableEquifaxIdentityVerification
    )
        return false;
    return is_permitted;
};

export const getPrice = (check, allChecks) => {
    let priceResult = check.price;

    // At the moment these us crim prices are tiered on the API side but on the front end acting as independent packages
    if (
        [CHECK_REQUEST.US_CRIMINAL_RECORD_CHECK_TIER_3, CHECK_REQUEST.US_CRIMINAL_RECORD_CHECK_TIER_2].includes(
            check.request_flag
        )
    ) {
        const usCrimTier1 = allChecks.filter(
            (c) => c.request_flag === CHECK_REQUEST.US_CRIMINAL_RECORD_CHECK_TIER_1
        )[0];
        priceResult += usCrimTier1.price;
    }

    if (CHECK_REQUEST.US_CRIMINAL_RECORD_CHECK_TIER_3 === check.request_flag) {
        const usCrimTier2 = allChecks.filter(
            (c) => c.request_flag === CHECK_REQUEST.US_CRIMINAL_RECORD_CHECK_TIER_2
        )[0];
        priceResult += usCrimTier2.price;
    }

    return priceResult.toString();
};

// Can Quickscreen
const getIsCheckQuickScreenable = (check, userModeIsHR, teamIsCA) => {
    // Quick screen only for Canadian teams
    if (!teamIsCA) return false;

    const quickscreenChecks = userModeIsHR ? QUICK_SCREEN_CHECKS_HR : QUICK_SCREEN_CHECKS_PM;

    if (quickscreenChecks.includes(check)) return true;
    return false;
};

/**
 *
 * @param {Array} allChecks - list of all checks returned from API
 * @param {Object} state - used for selectors
 * @param {Boolean} lockSelected - if !teamID, we can use this to determine if the check is a team default
 * @param {Boolean} upgrade - used to tell if application in upgrade or FUF which triggers lock & isInFollowUpFlow = true
 */
const getChecksConfig = ({ allChecks, state, lockSelected, upgrade }) => {
    const isUserModeHR = getUserModeIsHR(state);
    const application = getApplicationOfInterest(state);
    const team = getTeam(state);
    const teamIsCA = getTeamIsCA(state);
    const isUpgrade = getIsUpgrade(state);
    const options = getOptions(state);
    const settingsConfig = team.settings_config;
    const cantOrderIndividualServices = !getUser(state).can_order_individual_services;
    const checkRequestsToTitles = getCheckRequestsToTitles(options);
    const launchDarklyFlags = getLaunchDarklyFlags(state);
    const configurablePotatoChecks = getConfigurablePotatoChecks(state);

    const microServicesDetermined = allChecks
        // Filter out all checks that don't have check content yet, behavioural was selected in team settings and broke upgrades on PM
        ?.filter(({ request_flag, title, description }) => {
            const contentExists = checkRequestsToTitles[request_flag];
            const titleAndDescription = !!(title && description);
            if (contentExists || titleAndDescription) return true;
            console.error(`request flag: "${request_flag} not found in content generator`);
        })
        ?.map((check) => {
            const {
                request_flag,
                turn_around_time,
                is_purchased,
                is_permitted,
                missing_configuration_steps,
                is_configured: isConfigured,
                is_eligible: isEligible,
                selection_dependent_on_request_flags: selectionDependentOnRequestFlags,
                selection_on_create_prohibited_by_request_flags: selectionOnCreateProhibitedByRequestFlags,
                selection_on_upgrade_prohibited_by_request_flags: selectionOnUpgradeProhibitedByRequestFlags,
                is_access_fee_check: isAccessFeeCheck,
                title,
                description,
                estimated_turnaround_time,
            } = check;
            let { is_selected } = check;

            // Locked & FUF settings
            let isLocked = false;
            let isInFollowUpFlow = false;
            let isMissingDependencies = false;
            const isTeamDefault = false;
            // Some checks can have missing configuration steps but are still shown
            if (missing_configuration_steps?.length > 0) isLocked = true;

            // if check has dependencies, and none of those dependencies are permitted, isMissingDependencies is true and warn
            if (!isEmpty(selectionDependentOnRequestFlags)) {
                const dependentChecks = allChecks.filter((c) =>
                    selectionDependentOnRequestFlags.flat()?.includes(c.request_flag)
                );
                const hasPermittedDependencies = dependentChecks.some(
                    (dependentCheck) => dependentCheck.is_purchased || dependentCheck.is_permitted
                );
                if (!hasPermittedDependencies) isMissingDependencies = true;
            }

            // If team endpoint, any items that show up as selected are from request_ flag team settings
            // Will uncomment in the future once default checks gets sorted
            // if (lockSelected && is_selected) isTeamDefault = true;
            if (is_purchased) isLocked = true;

            // FUF/upgrade logic to lock selected items - Also catches any delayed check isPaid, checks that toggle on the way BACK from pipeline
            if (upgrade && is_selected) {
                if (!is_purchased) isInFollowUpFlow = true;
                isLocked = true;
            }
            // Values & Overrides
            const checkAdditionalOptions = getAdditionalOptionsKeys(
                request_flag,
                settingsConfig,
                configurablePotatoChecks
            );
            // Temporary for cart parity
            if (lockSelected && is_selected) is_selected = false;
            // Temporary for hide_ parity
            if (is_selected && !isConfigured) is_selected = false;
            // Overrides
            const price = getPrice(check, allChecks);
            const isSelected = getSelectedOverides(isUserModeHR, request_flag, is_selected, isUpgrade);
            // isPurchased Overrides
            let isPurchased = getPurchasedOverides(isUserModeHR, request_flag, application, is_purchased, isUpgrade);
            // temporary partial/not returned from pipeline protection
            // if we are upgrading, and item comes back from API as is_selected, it is purchased
            if (isUpgrade && isSelected && !isInFollowUpFlow) isPurchased = true;
            const isPermitted = getPermittedOverides(
                request_flag,
                isUpgrade,
                isEligible,
                is_permitted,
                launchDarklyFlags
            );
            const isQuickScreenable = getIsCheckQuickScreenable(request_flag, isUserModeHR, teamIsCA);
            const isOpen = checkAdditionalOptions.length > 0 && is_selected && !lockSelected && !upgrade;

            // additional lock override logic for checks such as address_phone_references -> address_references
            if (application && !isInFollowUpFlow && selectionOnUpgradeProhibitedByRequestFlags?.length > 0) {
                isLocked = getSelectionOnUpgradeProhibitedBy(application, selectionOnUpgradeProhibitedByRequestFlags);
            }
            // Locks all checks if can_order_individual_services is present
            if (cantOrderIndividualServices) isLocked = true;

            return {
                requestFlag: request_flag,
                missingConfigurationSteps: missing_configuration_steps,
                turnAroundTime: turn_around_time,
                isConfigured,
                selectionDependentOnRequestFlags,
                selectionOnCreateProhibitedByRequestFlags,
                selectionOnUpgradeProhibitedByRequestFlags,
                checkAdditionalOptions,
                isOpen,
                isTeamDefault,
                isPermitted,
                isSelected,
                isPurchased,
                price,
                isLocked,
                isInFollowUpFlow,
                isQuickScreenable,
                isMissingDependencies,
                titleFromAPI: title,
                descriptionFromAPI: description,
                estimatedTurnaroundTimeFromAPI: estimated_turnaround_time
                    ? turnaroundTime(estimated_turnaround_time.time, estimated_turnaround_time.title)
                    : null,
                isAccessFeeCheck,
            };
        });

    return microServicesDetermined;
};

export default getChecksConfig;
