// Libraries
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components/macro';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

// Components
import { ReferenceTemplate, ReferencesTable, ActionsCell } from './components';

// Modules
import { ReferenceModule } from 'modules';

// Actions & Selectors
import { getReferenceTemplates } from 'views/manager/views/settings/SettingsSelectors';
import {
    fetchReferenceTemplates,
    saveReferenceTemplate,
    updateReferenceTemplate,
    deleteReferenceTemplate,
} from 'views/manager/views/settings/SettingsActions';
import { getTeamId } from 'views/manager/views/admin/AdminSelectors';

const ReferenceWrapper = styled.div`
    @media (min-width: ${(props) => props.theme.width.desktop}) {
        max-width: 1000px;
        min-width: 1000px;
    }
`;

const mapStateToProps = (state) => ({
    teamId: getTeamId(state),
    referenceTemplates: getReferenceTemplates(state),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchReferenceTemplates,
            saveReferenceTemplate,
            updateReferenceTemplate,
            deleteReferenceTemplate,
        },
        dispatch
    );

class References extends React.Component {
    constructor(props) {
        super(props);
        this.state = { page: 'table', exampleTemplate: {}, type: props.match.params.type };
        this.fetchTemplates();
    }

    fetchTemplates = (typeOverride = null) => {
        const type = typeOverride || this.state.type;
        const { teamId } = this.props;
        this.props.fetchReferenceTemplates({ type, teamId }).then(() => this.handleFetchReferenceTemplate(type));
    };

    handleFetchReferenceTemplate = (type) => this.setState({ exampleTemplate: ReferenceModule[type], type });

    componentWillUnmount() {
        this.handleFetchReferenceTemplate = () => {};
    }

    handleChangeType = (type) => {
        this.fetchTemplates(type);
    };

    editTemplate = (row) => {
        const { type } = this.state;
        const { referenceTemplates } = this.props;
        this.setState({
            template: referenceTemplates[type].find((item) => item.id === row.id),
            page: 'template',
            onSave: this.props.updateReferenceTemplate,
        });
    };

    columns = () => [
        {
            title: <FormattedMessage id="97cf4.References.TemplateName" defaultMessage="Template Name" />,
            dataIndex: 'name',
            key: 'name',
            width: '90%',
            render: (text, row) => (
                <div style={{ cursor: 'pointer' }} onClick={() => this.editTemplate(row)}>
                    {text}
                </div>
            ),
        },
        {
            title: <FormattedMessage id="common.actions" defaultMessage="Actions" />,
            dataIndex: 'actions',
            key: 'actions',
            width: '10%',
            render: (text, row) => {
                const { type } = this.state;
                const { teamId, referenceTemplates } = this.props;
                const template = referenceTemplates[type].find((item) => item.id === row.id);
                return (
                    <ActionsCell
                        team={this.props.team}
                        row={row}
                        deleteTemplate={
                            referenceTemplates[type].length <= 1
                                ? null
                                : () => this.props.deleteReferenceTemplate({ type, teamId, id: template.id })
                        }
                        editTemplate={() => this.editTemplate(row)}
                    />
                );
            },
        },
    ];

    pages = {
        table: () => (
            <ReferencesTable
                templates={this.props.referenceTemplates[this.state.type]}
                columns={this.columns()}
                type={this.state.type}
                handleChangeType={this.handleChangeType}
                addTemplate={() =>
                    this.setState((prevState) => ({
                        template: prevState.exampleTemplate,
                        page: 'template',
                        onSave: this.props.saveReferenceTemplate,
                    }))
                }
            />
        ),
        template: () => (
            <ReferenceTemplate
                template={this.state.template}
                back={() => this.setState({ page: 'table' })}
                onSave={this.state.onSave}
                type={this.state.type}
            />
        ),
    };

    render() {
        const { page } = this.state;
        return <ReferenceWrapper>{this.pages[page]()}</ReferenceWrapper>;
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(References));
