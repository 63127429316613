// Libraries
import React from 'react';
import { get } from 'lodash';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import { Radio, Input, Popover } from 'antd';

import { FormattedMessage, injectIntl } from 'react-intl';

// Components
import { boolToYesNo } from 'views/welcome/yesNoHelpers';
import { TrackNavigation } from 'views/welcome/components';
import Subtitle from 'views/welcome/components/Subtitle';
import { AppearRight } from 'certn-ui/Animate';
import Title from 'certn-ui/Title';
import FormLayout from 'certn-form/FormLayout';
import DBSFieldHeading from 'views/welcome/components/DBSFieldHeading';
import AddressAutoComplete from 'certn-form/AddressAutoComplete';
// Modules
import { Regex } from 'modules';

const DBSPaperCriminalRecordCertificateFormFields = ({ form, getFieldDecorator, information, intl }) => {
    const [
        dbsPaperCriminalRecordCertificateConfirmation,
        setDbsPaperCriminalRecordCertificateConfirmation,
    ] = React.useState(boolToYesNo(information?.dbs_paper_certificate_consent_given));

    const [currentAddress, setNewAddress] = React.useState(
        boolToYesNo(information?.dbs_paper_certificate_current_address_confirmation)
    );
    const onChangePaperCertRadio = (evt) => setDbsPaperCriminalRecordCertificateConfirmation(evt.target.value);
    const onChangeCurrentAddressRadio = (evt) => setNewAddress(evt.target.value);

    const fields = {
        paper_cert_confirmation: () => (
            <Form.Item key="welcome.DBSPaperCriminalRecordCertificate.confirmation" noStyle>
                <DBSFieldHeading>
                    <FormattedMessage
                        id="welcome.DBSPaperCriminalRecordCertificate.confirmation"
                        defaultMessage="Produce Paper DBS Certificate?"
                    />
                </DBSFieldHeading>
                {getFieldDecorator('dbs_paper_certificate_consent_given_yesno', {
                    initialValue: boolToYesNo(get(information, 'dbs_paper_certificate_consent_given')),
                    rules: [
                        {
                            required: true,
                            message: intl.formatMessage({
                                id: 'error.validation.noOptionSelected',
                                defaultMessage: 'Please select an option!',
                            }),
                        },
                    ],
                })(
                    <Radio.Group
                        name="dbs_paper_certificate_consent_given_yesno"
                        onChange={onChangePaperCertRadio}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'start',
                            alignItems: 'start',
                        }}
                    >
                        <Radio value="YES" style={{ paddingBottom: '25px' }}>
                            <FormattedMessage id="common.yes" defaultMessage="Yes" />
                        </Radio>
                        <Radio value="NO">
                            <FormattedMessage id="common.no" defaultMessage="No" />
                        </Radio>
                    </Radio.Group>
                )}
            </Form.Item>
        ),
        current_address: () => {
            if (
                dbsPaperCriminalRecordCertificateConfirmation === 'YES' ||
                get(information, 'dbs_paper_certificate_consent_given')
            )
                return (
                    <Form.Item key="dbs_paper_certificate_current_address_confirmation_yesno" noStyle>
                        <DBSFieldHeading>
                            <FormattedMessage
                                id="welcome.DBSPaperCriminalRecordCertificate.CurrentAddress.confirmation"
                                defaultMessage="Receive Paper DBS Certificate at Current Address?"
                            />
                        </DBSFieldHeading>
                        {getFieldDecorator('dbs_paper_certificate_current_address_confirmation_yesno', {
                            initialValue: boolToYesNo(
                                get(information, 'dbs_paper_certificate_current_address_confirmation')
                            ),
                            rules: [
                                {
                                    required: true,
                                    message: intl.formatMessage({
                                        id: 'error.validation.noOptionSelected',
                                        defaultMessage: 'Please select an option!',
                                    }),
                                },
                            ],
                        })(
                            <Radio.Group
                                name="dbs_paper_certificate_current_address_confirmation_yesno"
                                onChange={onChangeCurrentAddressRadio}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'start',
                                    alignItems: 'start',
                                }}
                            >
                                <Radio
                                    value="YES"
                                    style={{ paddingBottom: '25px' }}
                                    data-testid="addressRadioButtonYes"
                                >
                                    <FormattedMessage
                                        id="welcome.DBSPaperCriminalRecordCertificate.Yes"
                                        defaultMessage="Yes"
                                    />
                                </Radio>
                                <Radio value="NO" data-testid="addressRadioButtonNo">
                                    <FormattedMessage
                                        id="welcome.DBSPaperCriminalRecordCertificate.No"
                                        defaultMessage="No"
                                    />
                                </Radio>
                            </Radio.Group>
                        )}
                    </Form.Item>
                );
            return null;
        },
        recipient_name: () => {
            if (currentAddress === 'NO' && dbsPaperCriminalRecordCertificateConfirmation === 'YES')
                return (
                    <Form.Item key="dbs_paper_certificate_recipient_name" noStyle>
                        <DBSFieldHeading>
                            <FormattedMessage
                                id="welcome.DBSPaperCriminalRecordCertificate.Address.title"
                                defaultMessage="Enter Address to Receive Paper DBS Certificate"
                            />
                        </DBSFieldHeading>
                        {getFieldDecorator('dbs_paper_certificate_recipient_name', {
                            initialValue: get(information, 'dbs_paper_certificate_recipient_name'),
                            rules: [
                                {
                                    required: true,
                                    message: intl.formatMessage({
                                        id: 'error.validation.notBlank',
                                        defaultMessage: 'Please do not leave blank',
                                    }),
                                },
                                {
                                    pattern: Regex.name,
                                    message: intl.formatMessage({
                                        id: 'error.validation.letters',
                                        defaultMessage: 'Please only use letters',
                                    }),
                                },
                            ],
                        })(
                            <Input
                                size="large"
                                placeholder="Recipient Name"
                                addonBefore={
                                    <Popover content="Recipient Name">
                                        <InfoCircleOutlined />
                                    </Popover>
                                }
                            />
                        )}
                    </Form.Item>
                );
            return null;
        },
        recipient_department: () => {
            if (currentAddress === 'NO' && dbsPaperCriminalRecordCertificateConfirmation === 'YES')
                return (
                    <Form.Item key="dbs_paper_certificate_recipient_department" noStyle>
                        {getFieldDecorator('dbs_paper_certificate_recipient_department', {
                            initialValue: get(information, 'dbs_paper_certificate_recipient_department'),
                            rules: [
                                {
                                    required: true,
                                    message: intl.formatMessage({
                                        id: 'error.validation.notBlank',
                                        defaultMessage: 'Please do not leave blank',
                                    }),
                                },
                            ],
                        })(
                            <Input
                                size="large"
                                placeholder="Recipient Department"
                                addonBefore={
                                    <Popover content="Recipient Department">
                                        <InfoCircleOutlined />
                                    </Popover>
                                }
                            />
                        )}
                    </Form.Item>
                );
            return null;
        },
        address: () => {
            if (currentAddress === 'NO' && dbsPaperCriminalRecordCertificateConfirmation === 'YES')
                return (
                    <Form.Item key="dbs_paper_certificate_address" noStyle>
                        {getFieldDecorator('dbs_paper_certificate_address', {
                            initialValue: get(information, 'dbs_paper_certificate_address'),
                        })(
                            <React.Fragment key="address_lookup">
                                <AddressAutoComplete
                                    getFieldDecorator={getFieldDecorator}
                                    form={form}
                                    hideLabels
                                    currentAddress={get(information, 'dbs_paper_certificate_address') || {}}
                                    isUkCheck
                                    isSolelyUkCheck
                                />
                            </React.Fragment>
                        )}
                    </Form.Item>
                );
            return null;
        },
    };

    return Object.entries(fields).map(([key, item]) => <React.Fragment key={key}>{item()}</React.Fragment>);
};

const DBSPaperCriminalRecordCertificateForm = ({
    handleSubmit,
    form: { getFieldDecorator, validateFields, getValueFromEvent, setFieldsValue },
    form,
    information,
    intl,
}) => (
    <AppearRight>
        <Form layout="horizontal" onSubmit={(e) => handleSubmit(e, validateFields)}>
            <Title>
                <FormattedMessage
                    id="welcome.DBSPaperCriminalRecordCertificate.title"
                    defaultMessage="Do you want to receive a paper certificate?"
                />
            </Title>
            <Subtitle>
                <FormattedMessage
                    id="welcome.DBSPaperCriminalRecordCertificate.subtitle"
                    defaultMessage="If you choose yes, you have the option to enter our UK delivery address to speed up the screening process: 160 London Road, Sevenoaks, Kent, TN13 1BT, United Kingdom"
                />
            </Subtitle>
            <FormLayout>
                {DBSPaperCriminalRecordCertificateFormFields({
                    form,
                    getFieldDecorator,
                    getValueFromEvent,
                    setFieldsValue,
                    information,
                    intl,
                })}
            </FormLayout>
            <TrackNavigation />
        </Form>
    </AppearRight>
);

export default Form.create()(injectIntl(DBSPaperCriminalRecordCertificateForm));
