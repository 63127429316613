// Libraries
import React from 'react';
import styled from 'styled-components/macro';
import { ClockCircleOutlined } from '@ant-design/icons';
import { Timeline } from 'antd';
import { FormattedMessage } from 'react-intl';

// Components
import { Wrapper, TopPanel, BottomWindow, Title, Column } from './ReportBottomPanelLayout';
import styles from 'styles/styles';

const { Item } = Timeline;

const CustomTimelineItem = styled(Item)`
    /* For some reason the custom dot component wasn't centered */
    > div[class*='ant-timeline-item-head'] {
        top: 4px !important;
    }
`;

const Activity = styled.div`
    padding-bottom: 20px;
`;

const ThemeBypassCircle = styled.div`
    width: 10px;
    height: 10px;
    margin: -3px; /* Pulls gray line in closer to dot */
    color: ${({ theme, color }) => theme.color[color] || theme.color.certnBlue500};
    background-color: ${({ theme }) => theme.color.certnWhite};
    border: 2px solid ${({ theme, color }) => theme.color[color] || theme.color.certnBlue500};
    border-radius: 100px;
`;

const icon = (status) => {
    const iconMap = {
        waiting: <ClockCircleOutlined style={{ fontSize: '16px', color: styles.color.certnBlue600 }} />,
        pre_undeliverable: <ThemeBypassCircle color="certnRed500" />,
        re_investigation_scheduled: <ThemeBypassCircle color="certnGray200" />,
        post_scheduled: <ThemeBypassCircle color="certnGray200" />,
        default: <ThemeBypassCircle />,
    };
    return iconMap[status.toLowerCase()] || iconMap.default;
};

const ReportActivityLog = ({ activityLog = [] }) =>
    activityLog?.length > 0 && (
        <Wrapper>
            <TopPanel>
                <Title flex="2">
                    <FormattedMessage id="5a5ed.ReportActivityLog.activity" defaultMessage="Activity" />
                </Title>
                <Title>
                    <FormattedMessage id="5a5ed.ReportActivityLog.date" defaultMessage="Date" />
                </Title>
                <Title>
                    <FormattedMessage id="5a5ed.ReportActivityLog.time" defaultMessage="Time" />
                </Title>
            </TopPanel>
            <BottomWindow padding="30px 30px 0px 30px">
                <Column flex="2" margin="5px 0 0 0">
                    <Timeline>
                        {activityLog &&
                            activityLog.map((item) => (
                                <CustomTimelineItem dot={icon(item.status)}>{item.status_label}</CustomTimelineItem>
                            ))}
                    </Timeline>
                </Column>
                <Column>{activityLog && activityLog.map((item) => <Activity>{item.date_label}</Activity>)}</Column>
                <Column>{activityLog && activityLog.map((item) => <Activity>{item.time_label}</Activity>)}</Column>
            </BottomWindow>
        </Wrapper>
    );

export default ReportActivityLog;
