// Libraries
import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

// UI Components
import Titlebar from 'certn-ui/Titlebar';
import Button, { ButtonLine } from 'certn-ui/Button';
import ResetPasswordForm from './ResetPasswordForm';
import { ErrorAlertCustom } from 'certn-ui/ErrorAlert';

// Actions & Selectors
import { resetPassword, clearLogin } from 'views/guest/GuestActions';
import { getIsFetchingSilent, getResetRequestSent, getError } from 'views/guest/GuestSelectors';

const ResetPassword = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { reset_token } = useParams();
    const intl = useIntl();
    const isFetchingSilent = useSelector(getIsFetchingSilent);
    const error = useSelector(getError);
    const resetRequestSent = useSelector(getResetRequestSent);

    const onBack = () => {
        if (localStorage.getItem('talentHubParams')) {
            history.push('/integrate/talenthub');
        } else {
            history.push('/login');
        }
    };

    useEffect(
        () =>
            function cleanup() {
                dispatch(clearLogin());
            },
        [dispatch]
    );

    if (!resetRequestSent) {
        const onReset = (values) => {
            values.token = reset_token;
            dispatch(resetPassword(values)).catch(() => {
                const description = intl.formatMessage({
                    id: '72d43.ResetPasswordForm.passwordError',
                    defaultMessage: 'Error resetting password',
                });
                ErrorAlertCustom({ description });
            });
        };
        return (
            <>
                <Titlebar
                    size="xlrg"
                    center
                    title={intl.formatMessage({
                        id: '26f59.ResetPassword.title',
                        defaultMessage: 'Reset Password',
                    })}
                />
                <ResetPasswordForm onSubmit={onReset} loading={isFetchingSilent} error={error?.detail} />
            </>
        );
    }

    return (
        <>
            <Titlebar
                size="xlrg"
                center
                title={intl.formatMessage({
                    id: '26f59.ResetPassword.passwordReset',
                    defaultMessage: 'Password Reset!',
                })}
                subtitle={intl.formatMessage({
                    id: '26f59.ResetPassword.returnToLoginPage',
                    defaultMessage: 'Return to the login page to sign in.',
                })}
            />
            <ButtonLine style={{ paddingTop: 0 }}>
                <Button type="primary" size="large" certncolor="primary" onClick={onBack}>
                    <FormattedMessage id="common.back" defaultMessage="Back" />
                </Button>
            </ButtonLine>
        </>
    );
};

export default ResetPassword;
