// Libraries
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Form as AntdForm } from '@ant-design/compatible';

// Components
import { AppearRight } from 'certn-ui/Animate';
import Title from 'certn-ui/Title';
import { TrackNavigation, CustomFields } from 'views/welcome/components';
import FormLayout from 'certn-form/FormLayout';

const CustomQuestionsForm = ({ information, customQuestions, handleSubmit, form, form: { validateFields } }) => (
    <AppearRight>
        <AntdForm layout="horizontal" onSubmit={(e) => handleSubmit(e, validateFields)}>
            <Title>
                <FormattedMessage
                    id="welcome.CustomQuestions.title"
                    defaultMessage="Additional fields are required to complete this application."
                />
            </Title>
            <FormLayout>
                {customQuestions &&
                    customQuestions.length > 0 &&
                    customQuestions.map(({ question, type, hyperlink_url, hyperlink_label }, index) => {
                        const initialValue = information.custom_questions.find((q) => q.question === question);
                        return (
                            question && (
                                <CustomFields
                                    type={type}
                                    form={form}
                                    field={index.toString()}
                                    label={question}
                                    hyperlink_url={hyperlink_url}
                                    hyperlink_label={hyperlink_label}
                                    initialValue={initialValue ? initialValue.answer : undefined}
                                />
                            )
                        );
                    })}
                <TrackNavigation />
            </FormLayout>
        </AntdForm>
    </AppearRight>
);

export default AntdForm.create()(CustomQuestionsForm);
