// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

// Components
import {
    SectionWrapper,
    SectionTopBar,
    SectionBottomPanel,
    CustomInputFrame,
} from 'views/manager/features/ScreenApplicant/styled';
import {
    Email,
    FirstName,
    Alias,
    MaidenName,
    MiddleName,
    LastName,
    Gender,
    BirthPlace,
    PhoneNumber,
    DateOfBirth,
    SIN,
    SSN,
    License,
    Address,
    Convictions,
    ConsentUpload,
    IDUpload,
    ReviewAndSubmit,
    WorkLocation,
    SocialMedia,
} from './components';

const QuickScreen = ({ form, loading, isPM }) => (
    <>
        <ConsentUpload form={form} />
        <IDUpload form={form} />
        <CandidateDetails form={form} />
        <SocialMedia form={form} />
        <Address form={form} />
        <WorkLocation form={form} isPM={isPM} />
        <Convictions form={form} />
        <ReviewAndSubmit form={form} loading={loading} />
    </>
);

const CandidateDetails = ({ form }) => (
    <SectionWrapper>
        <SectionTopBar>
            <FormattedMessage
                id="f5ca1.QuickScreen.quickScreenHeader"
                defaultMessage="Enter Your Candidate's Details"
            />
        </SectionTopBar>
        <SectionBottomPanel>
            <CustomInputFrame>
                <Email />
                <FirstName />
                <Alias />
                <MiddleName />
                <LastName />
                <MaidenName />
                <BirthPlace form={form} />
                <Gender />
                <DateOfBirth />
                <SIN />
                <SSN />
                <License />
                <PhoneNumber />
            </CustomInputFrame>
        </SectionBottomPanel>
    </SectionWrapper>
);

QuickScreen.propTypes = {
    form: PropTypes.object.isRequired,
    loading: PropTypes.bool,
};

QuickScreen.defaultProps = {
    loading: false,
};

export default QuickScreen;
