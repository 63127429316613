import React, { useEffect, useState } from 'react';
import TitleBar from 'certn-ui/Titlebar';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Col, Input, message, Modal, Row } from 'antd';
import { PartnerComponents } from './Components';
import { Form } from '@ant-design/compatible';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Store } from 'redux';
import { getTeamMembers, getTeamMembersCount } from '../settings/SettingsSelectors';
import { fetchPaginatedTeamMembers } from '../settings/SettingsActions';
import { getTeam } from '../admin/AdminSelectors';
import Constants from 'utils/constants';
import { PartnerManagementInformation, Team, User } from '../../../../types';
import { getUser } from '../../../../base/BaseSelectors';
import { ActionsCell } from '../settings/views/users/components';
import { LinkOutlined } from '@ant-design/icons';
import { getRequest, postRequest, putRequest } from '../../../../utils/http';
import { stringifyDate } from '../../../../modules/Format';
import EditUserModalForm from '../../../../components/EditUserModalForm';
import { createTeamMember, saveTeamMember } from './helpers';
import { PERMISSION_LEVEL } from '../../../../base/BaseConstants';
import copy from 'copy-to-clipboard';
import { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form';

const mapStateToProps = (state: Store) => ({
    team: getTeam(state),
    teamMembers: getTeamMembers(state),
    teamMembersCount: getTeamMembersCount(state),
    user: getUser(state),
});

interface PropTypes extends RouteComponentProps {
    team: Team;
    teamMembers: User[];
    teamMembersCount: number;
    user: User;
    form: WrappedFormUtils;
}

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            fetchPaginatedTeamMembers,
        },
        dispatch
    );

const getPartnerManagementInformation = async (): Promise<PartnerManagementInformation> => {
    const data = await Promise.resolve(
        getRequest({
            version: 'v1',
            endpoint: `/partner/`,
        })
    );
    return data as PartnerManagementInformation;
};

const getPartnerUsers = async (partner_id: string | undefined): Promise<User[]> => {
    const data = await Promise.resolve(
        getRequest({
            version: 'v2',
            endpoint: `/users/?partner=${partner_id}`,
        })
    );
    return data as User[];
};

const setTeamMemberToInactive = async (teamMember: User): Promise<User> =>
    putRequest({
        version: 'v2',
        endpoint: `/users/${teamMember.id}/`,
        body: JSON.stringify({
            is_active: false,
        }),
    });

const postWebhookURL = async (webhookURL: string): Promise<PartnerManagementInformation> => {
    const body: BodyInit = JSON.stringify({
        webhook_url: webhookURL,
    });
    const data = await Promise.resolve(
        putRequest({
            version: 'v1',
            endpoint: `/partner/update/`,
            body,
        })
    );
    return data as PartnerManagementInformation;
};

const formatManagementInformationForTable = (partnerManagementInformation: PartnerManagementInformation) => [
    {
        id: partnerManagementInformation.oauth_application.client_id,
        created: stringifyDate(partnerManagementInformation.oauth_application.created),
        user_name: partnerManagementInformation.oauth_application?.user
            ? `${partnerManagementInformation.oauth_application.user.first_name} ${partnerManagementInformation.oauth_application.user.last_name}`
            : 'N/A',
    },
];

const formatUsersForTable = (partnerUsers: User[]) => {
    const _formattedUsers = [];
    for (let partnerUserIndex = 0; partnerUserIndex < partnerUsers.length; partnerUserIndex += 1) {
        const user: User = partnerUsers[partnerUserIndex];
        _formattedUsers.push({
            id: user.id,
            first_name: user.first_name,
            last_name: user.last_name,
            email: user.email,
            status: user.is_active ? 'Active' : 'Inactive',
            is_active: user.is_active,
            api_user_role_display: user.api_user_role_display,
            api_user_role: user.api_user_role,
            actions: 'Edit',
        });
    }
    return _formattedUsers;
};

interface OAuthInformation {
    client_id: string;
    client_secret: string;
}
const generateNewKeys = async (): Promise<OAuthInformation> =>
    postRequest({
        version: 'v1',
        endpoint: `/replace-oauth-application/`,
    });

const Partner: React.FC<PropTypes> = ({ form, user }) => {
    const intl = useIntl();
    const partnerHasKey = true;

    const [partnerManagementInformation, setPartnerManagementInformation] = useState<
        PartnerManagementInformation | undefined
    >(undefined);
    const [partnerUsers, setPartnerUsers] = useState<User[] | undefined>(undefined);
    const [webhookURL, setWebhookURL] = useState<string>('');
    const [teamMemberBeingEdited, setTeamMemberBeingEdited] = useState<User | undefined>(undefined);
    const [isCreatingNewUser, setIsCreatingNewUser] = useState<boolean>(false);
    const [apiSecretKey, setApiSecretKey] = useState<string | undefined>(undefined);
    const [apiClientIDTemp, setApiClientIDTemp] = useState<string | undefined>(undefined);

    const updateAllPartnerData = async () => {
        getPartnerManagementInformation().then((partnerResponseData) => {
            setPartnerManagementInformation(partnerResponseData);
            setWebhookURL(partnerResponseData.webhook_url);
            getPartnerUsers(partnerResponseData.id).then((usersResponseData) => setPartnerUsers(usersResponseData));
        });
    };

    useEffect(() => {
        updateAllPartnerData();
    }, []);

    const APIUserColumns = [
        {
            title: 'First Name',
            dataIndex: 'first_name',
            key: 'first_name',
        },
        {
            title: 'Last Name',
            dataIndex: 'last_name',
            key: 'last_name',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Permissions',
            dataIndex: 'api_user_role_display',
            key: 'api_user_role_display',
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (_: string, row: unknown) => (
                <>
                    <ActionsCell
                        data-testid="actions-cell"
                        row={row}
                        showDeleteUserButton={false}
                        editTeamMember={() => {
                            setTeamMemberBeingEdited(row as User);
                        }}
                        saveTeamMember={(_user: User, _id: string) =>
                            saveTeamMember(_user, _id).then(() => updateAllPartnerData())
                        }
                        removeTeamMember={() => setTeamMemberToInactive(row as User).then(() => updateAllPartnerData())}
                    />
                </>
            ),
        },
    ];

    const APIKeyColumns = [
        {
            title: 'Client ID',
            dataIndex: 'id',
            key: 'oauth_application__client_id',
        },
        {
            title: 'Date Generated',
            dataIndex: 'created',
            key: 'created',
        },
        {
            title: 'User',
            dataIndex: 'user_name',
            key: 'user_name',
        },
    ];

    if (user.api_user_role !== Constants.apiUserRole.ADMIN) {
        return <></>;
    }

    return (
        <PartnerComponents.PartnerPageWrapper>
            <TitleBar
                title={intl.formatMessage({
                    id: 'partner.title',
                    defaultMessage: 'Partner API',
                })}
            />
            {user.api_user_role === Constants.apiUserRole.ADMIN && (
                <Row>
                    <Col span={16}>
                        <h1>
                            <FormattedMessage id="partner.apiKey" defaultMessage="API Key" />
                        </h1>
                    </Col>
                    {partnerHasKey && (
                        <PartnerComponents.RightAlignedCol span={8}>
                            <Button
                                type="primary"
                                onClick={() => {
                                    Modal.confirm({
                                        title: intl.formatMessage({
                                            id: 'partner.confirmNewKey',
                                            defaultMessage: 'Confirm New Key',
                                        }),
                                        content: intl.formatMessage({
                                            id: 'partner.confirmNewKeyContent',
                                            defaultMessage:
                                                'This will permanently invalidate all previously generated keys. Are you sure you want to continue?',
                                        }),
                                        okType: 'danger',
                                        onOk: () => {
                                            generateNewKeys().then((data) => {
                                                updateAllPartnerData();
                                                setApiSecretKey(data.client_secret);
                                                setApiClientIDTemp(data.client_id);
                                            });
                                        },
                                        onCancel() {},
                                    });
                                }}
                            >
                                <FormattedMessage id="partner.generateNewKey" defaultMessage="Refresh Keys" />
                            </Button>
                        </PartnerComponents.RightAlignedCol>
                    )}
                </Row>
            )}
            <PartnerComponents.StyledTable
                columns={APIKeyColumns}
                dataSource={
                    partnerManagementInformation
                        ? formatManagementInformationForTable(partnerManagementInformation)
                        : []
                }
                pagination={false}
            />
            <Row>
                <Col span={16}>
                    <h1>
                        <FormattedMessage id="partner.Users" defaultMessage="Users" />
                    </h1>
                </Col>
                {partnerHasKey && (
                    <PartnerComponents.RightAlignedCol span={8}>
                        <Button type="primary" onClick={() => setIsCreatingNewUser(!isCreatingNewUser)}>
                            <FormattedMessage id="partner.addNewUser" defaultMessage="Add New User" />
                        </Button>
                    </PartnerComponents.RightAlignedCol>
                )}
            </Row>
            <PartnerComponents.StyledTable
                columns={APIUserColumns}
                dataSource={partnerUsers ? formatUsersForTable(partnerUsers) : []}
            />
            <Row>
                <Col span={8}>
                    <h1>
                        <FormattedMessage id="partner.webhookSecretString" defaultMessage="Secret String" />
                    </h1>
                    <Row>
                        <Col span={16}>
                            <Input disabled value={partnerManagementInformation?.webhook_secret} />
                        </Col>
                        <Col span={6} style={{ marginLeft: '10px' }}>
                            <Button
                                onClick={() => {
                                    if (partnerManagementInformation?.webhook_secret) {
                                        copy(partnerManagementInformation.webhook_secret);
                                    }
                                }}
                            >
                                <LinkOutlined /> <FormattedMessage id="partner.copySecret" defaultMessage="Copy" />
                            </Button>
                        </Col>
                    </Row>
                </Col>
                <Col span={8}>
                    <h1>
                        <FormattedMessage id="partner.webhookURL" defaultMessage="Webhook URL" />
                    </h1>
                    <Row>
                        <Col span={16}>
                            <Input value={webhookURL} onChange={(event) => setWebhookURL(event.target.value)} />
                        </Col>
                        <Col span={6} style={{ marginLeft: '10px' }}>
                            <Button
                                type="primary"
                                onClick={() => {
                                    if (webhookURL !== partnerManagementInformation?.webhook_url) {
                                        postWebhookURL(webhookURL).then(() => {
                                            message.success('Webhook URL successfully updated');
                                        });
                                    }
                                }}
                            >
                                {partnerManagementInformation?.webhook_url ? (
                                    <FormattedMessage id="partner.save" defaultMessage="Save" />
                                ) : (
                                    <FormattedMessage
                                        id="partner.enableIntegration"
                                        defaultMessage="Enable Integration"
                                    />
                                )}
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <EditUserModalForm
                isEditingTeamMember={!!teamMemberBeingEdited}
                setIsEditingTeamMember={setTeamMemberBeingEdited}
                handleSubmit={(_isCreatingUser: boolean) => {
                    form.validateFieldsAndScroll((err) => {
                        if (!err) {
                            if (_isCreatingUser) {
                                createTeamMember({
                                    team_id: user?.team?.id,
                                    permission_level: PERMISSION_LEVEL.MANAGER,
                                    ...form.getFieldsValue(),
                                }).then(() => {
                                    updateAllPartnerData();
                                });
                            } else {
                                saveTeamMember(form.getFieldsValue() as User, teamMemberBeingEdited?.id).then(() => {
                                    updateAllPartnerData();
                                });
                            }
                            setIsCreatingNewUser(false);
                            setTeamMemberBeingEdited(undefined);
                        }
                    });
                }}
                teamMember={teamMemberBeingEdited}
                isCreatingNewUser={isCreatingNewUser}
                setIsCreatingNewUser={setIsCreatingNewUser}
                form={form}
            />
            <Modal
                visible={!!apiSecretKey}
                title={intl.formatMessage({
                    id: 'partner.newKey',
                    defaultMessage: 'New Key',
                })}
                footer={[
                    <Button
                        key="submit"
                        type="primary"
                        onClick={() => {
                            setApiSecretKey(undefined);
                        }}
                    >
                        <FormattedMessage id="partner.close" defaultMessage="Close" />
                    </Button>,
                ]}
            >
                <p>
                    <FormattedMessage
                        id="partner.newKeyWarning"
                        defaultMessage="Copy the secret key, it will not be available again once this window closes."
                    />
                </p>
                <b>
                    <FormattedMessage id="partner.newIdLabel" defaultMessage="Client ID: " />
                </b>
                <br />
                {apiClientIDTemp}
                <br />
                <b>
                    <FormattedMessage id="partner.newKeyLabel" defaultMessage="Secret Key: " />
                </b>
                <br />
                {apiSecretKey}
                <br />
            </Modal>
        </PartnerComponents.PartnerPageWrapper>
    );
};

export default Form.create()(withRouter(connect(mapStateToProps, mapDispatchToProps)(Partner)));
