// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CloseOutlined } from '@ant-design/icons';
import { message, Button as AntdButton, Layout, Tabs, notification } from 'antd';
import styled from 'styled-components/macro';
import { get } from 'lodash';

// Components
import ApplicationTitleBar from './components/ApplicationTitleBar';
import { ErrorAlertAPI } from 'certn-ui/ErrorAlert';
import Loader from 'certn-ui/Loader';
import LoadingPage from 'certn-ui/LoadingPage';
import { SideBar, ReportLoadingWrapper, ReportBottomPanel, CertnReport } from 'views/manager/components';

// Actions & Selectors
import { clearCSVLink } from 'views/manager/sharedActions/ApplicationActions';
import {
    fetchApplication,
    fetchReport,
    patchComments,
    requestPackages,
    setApplicationStatus,
} from 'views/manager/views/pm/views/applications/ApplicationsActions';
import { fetchAdverseActionDetails } from 'views/manager/features/AdverseActionDrawer/DrawerActions';
import {
    getIsFetching,
    getIsFetchingCSV,
    getIsFetchingSilent,
    getReport,
    getApplication,
    getCsvLink,
} from 'views/manager/views/pm/views/applications/ApplicationsSelectors';
import { getSettings } from 'views/manager/views/settings/SettingsSelectors';

// Constants
import { CHECK_REQUEST } from 'base/BaseConstants';
import styles from 'styles/styles';

const ReportContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const mapStateToProps = (state) => ({
    isFetching: getIsFetching(state),
    isFetchingCSV: getIsFetchingCSV(state),
    isFetchingSilent: getIsFetchingSilent(state),
    report: getReport(state),
    application: getApplication(state),
    csvLink: getCsvLink(state),
    settings: getSettings(state),
});
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchAdverseActionDetails,
            fetchApplication,
            fetchReport,
            patchComments,
            requestPackages,
            setApplicationStatus,
            clearCSVLink,
        },
        dispatch
    );

const propTypes = {
    // Redux Actions
    setApplicationStatus: PropTypes.func.isRequired,
    fetchApplication: PropTypes.func.isRequired,
    fetchReport: PropTypes.func.isRequired,
    patchComments: PropTypes.func.isRequired,
    requestPackages: PropTypes.func.isRequired,
    // Redux State
    isFetching: PropTypes.bool.isRequired,
    isFetchingCSV: PropTypes.bool,
    isFetchingSilent: PropTypes.bool.isRequired,
    report: PropTypes.object,
    settings: PropTypes.object,
    application: PropTypes.object,
    csvLink: PropTypes.string,
};

const defaultProps = {
    report: {},
    application: {},
    settings: {},
    csvLink: undefined,
    isFetchingCSV: undefined,
};

class ApplicationsView extends React.Component {
    constructor(props) {
        super(props);
        const { applicantId } = props.match.params;

        // Legacy code, refactoring but no time to rewrite
        this.state = {
            applicantId: null,
            upgradingReport: false,
            titles: null,
            sidebarCollapsed: true,
            sidebarLoading: true,
            sideBarData: {},
            activeReport: null,
            fetchingSubReport: false,
            commentInput: '',
            name: undefined,
            sideBarTopPosition: '160px',
            applicantIdOrReportId: applicantId,
        };
        this.props.fetchReport(applicantId);
    }

    // Legacy code, refactoring but no time to rewrite, should use application from redux state not response
    componentDidMount = () => {
        const { applicantIdOrReportId } = this.state;
        this.props.fetchApplication(applicantIdOrReportId).then((response) => {
            this.setState(() => ({
                applicantId: response.id,
                sideBarData: {
                    applicant: [response.application.applicants.find((applicant) => applicant.id === response.id)],
                    coApplicants: response.application.applicants.filter(
                        (report) => report.id !== response.id && !report.is_cosigner
                    ),
                    coSigners: response.application.applicants.filter(
                        (report) => report.id !== response.id && report.is_cosigner
                    ),
                },
                activeReport: response.id,
                sidebarLoading: false,
                applicantIdOrReportId,
            }));
            this.props.application?.is_standalone_aa_available &&
                this.props.fetchAdverseActionDetails(applicantIdOrReportId);
        });
    };

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScroll, false);
        window.removeEventListener('resize', this.onResize, false);
        this.props.clearCSVLink();
    }

    handleUpdateComment = (e) => {
        const commentInput = e.target.value;
        this.setState(() => ({ commentInput }));
    };

    handleSubmitComment = () => {
        this.props.patchComments(this.state.commentInput, this.state.applicantId).then(null, (error) => {
            notification.error({
                message: 'Error',
                description: error,
            });
        });
        this.setState({ commentInput: '' });
    };

    showSubReport = (applicantId) => {
        this.setState({
            activeReport: applicantId,
            fetchingSubReport: true,
        });

        this.props.fetchReport(applicantId).then(() =>
            this.props.fetchApplication(applicantId).then(() =>
                this.setState(() => ({
                    applicantId,
                    fetchingSubReport: false,
                }))
            )
        );
    };

    onScroll = () => {
        if (
            get(window, ['frames', 'ifr', 'document', 'documentElement', 'scrollTop']) &&
            get(window, ['frames', 'ifr', 'document', 'body', 'scrollTop'])
        ) {
            window.frames.ifr.document.documentElement.scrollTop = window.pageYOffset;
            window.frames.ifr.document.body.scrollTop = window.pageYOffset;
        }

        if (window.pageYOffset >= 100 && this.state.sideBarTopPosition === '160px')
            this.setState({ sideBarTopPosition: '70px' });
        else if (window.pageYOffset < 100 && this.state.sideBarTopPosition === '70px')
            this.setState({ sideBarTopPosition: '160px' });
    };

    onResize = () => {
        if (get(window, ['frames', 'ifr', 'document', 'body', 'offsetHeight'])) {
            setTimeout(() => {
                const container = window.document.getElementById('iframe_container');
                if (container) container.style.height = `${58 + window.frames.ifr.document.body.offsetHeight}px`;
            }, 100);
            setTimeout(() => {
                const frame = window.document.getElementById('certnFrame');
                if (frame) frame.style.height = `${58 + window.frames.ifr.document.body.offsetHeight}px`;
            }, 100);
        }
    };

    handleReportUpgrade = (values = null) => {
        const currApp = this.props.application;
        this.props
            .requestPackages(this.state.applicantId, values)
            .then(() => {
                if (values[CHECK_REQUEST.EQUIFAX] && !currApp[CHECK_REQUEST.EQUIFAX]) {
                    this.toggle('upgradingReport');
                } else {
                    message.success('Request for addition information has been sent to the applicant');
                }
            })
            .catch((error) => ErrorAlertAPI(error));
    };

    toggle = (key) => {
        this.setState((prevState) => ({
            [key]: !prevState[key],
        }));
    };

    handleDownloadConsentDocs = async () => {
        try {
            await this.props.fetchConsentDocuments({
                applicantId: this.state.applicantId,
            });
            // TODO show success
        } catch (error) {
            // TODO handle error
        }
    };

    render() {
        const { application, isFetchingSilent } = this.props;
        const {
            upgradingReport,
            applicantId,
            activeReport,
            fetchingSubReport,
            sidebarCollapsed,
            sidebarLoading,
            commentInput,
            sideBarData: { applicant, coSigners, coApplicants },
        } = this.state;
        const isDesktopView = window.matchMedia('(min-width: 1024px)').matches;
        if (upgradingReport && application.is_equifax_eligible)
            return (
                <LoadingPage
                    fetch={this.props.fetchApplication}
                    fetchId={applicantId}
                    completeCondition="Returned"
                    errorCondition="Error"
                    completeAction={() => this.toggle('upgradingReport')}
                    titles={{
                        1: 'Establishing connection with Equifax servers',
                        2: 'Scanning databases for possible matches',
                        3: 'Acquiring matching Equifax records',
                        4: 'Unpacking server response',
                    }}
                />
            );

        const showLoader =
            (this.props.isFetching && !fetchingSubReport && !sidebarLoading) ||
            (!(this.props.report && this.props.report.html) && !fetchingSubReport);

        return (
            <>
                {showLoader ? (
                    <Loader />
                ) : (
                    <>
                        <ApplicationTitleBar
                            isFetchingSilent={isFetchingSilent}
                            application={application}
                            sidebarLoading={sidebarLoading}
                            sidebarCollapsed={sidebarCollapsed}
                            toggleSidebar={() => this.toggle('sidebarCollapsed')}
                            onResize={this.onResize}
                            isDesktopView={isDesktopView}
                        />
                        {isDesktopView && (
                            <div style={{ display: 'flex' }}>
                                {fetchingSubReport ? (
                                    <ReportLoadingWrapper>
                                        <Loader />
                                    </ReportLoadingWrapper>
                                ) : (
                                    <ReportContainer
                                        style={{ width: sidebarCollapsed ? '100%' : 'calc(100% - 225px)' }}
                                    >
                                        <CertnReport
                                            onScroll={this.onScroll}
                                            onResize={this.onResize}
                                            report={this.props.report.html}
                                            style={{ width: '250px' }}
                                        />
                                        {application.activity_log && application.activity_log.length > 0 && (
                                            <ReportBottomPanel
                                                activityLog={application.activity_log}
                                                adjudicationStatus={application.adjudication_status}
                                                adjudicationStatusLabel={application.adjudication_status_label}
                                                status={application.report_summary.report_result}
                                                applicantId={application.id}
                                                setApplicationStatus={this.props.setApplicationStatus}
                                            />
                                        )}
                                    </ReportContainer>
                                )}
                                {/* TODO separate this to it's own component */}
                                <div
                                    style={{
                                        position: 'fixed',
                                        right: '0px',
                                        top: `${this.state.sideBarTopPosition}`,
                                        height: `calc(100vh - ${this.state.sideBarTopPosition})`,
                                        overflowY: 'scroll',
                                        visibility: sidebarCollapsed ? 'hidden' : 'initial',
                                        transition: 'visibility: 0.5s',
                                    }}
                                >
                                    <Layout
                                        hasSider
                                        style={{
                                            backgroundColor: styles.color.certnWhite,
                                            marginBottom: '50px',
                                            height: fetchingSubReport ? '780px' : 'initial',
                                        }}
                                    >
                                        <Layout.Sider
                                            theme="light"
                                            trigger={null}
                                            collapsedWidth={0}
                                            collapsible
                                            collapsed={sidebarCollapsed}
                                            width="235px"
                                            style={{
                                                marginBottom: '50px',
                                                borderLeft: '0',
                                                collapsedWidth: '0',
                                                height: `${fetchingSubReport ? 'fit-content' : '0px'}`,
                                            }}
                                        >
                                            <SideBar.InfoBar>
                                                <div>Applicant Info</div>{' '}
                                                <AntdButton
                                                    onClick={() => this.toggle('sidebarCollapsed')}
                                                    style={{ border: '0' }}
                                                    shape="circle"
                                                    icon={<CloseOutlined />}
                                                    size="small"
                                                    data-testid="close_sidebar_button"
                                                />
                                            </SideBar.InfoBar>
                                            <Tabs
                                                defaultActiveKey="1"
                                                size="small"
                                                tabBarStyle={{
                                                    fontWeight: '500',
                                                    overflow: 'visible',
                                                    margin: 'auto',
                                                }}
                                            >
                                                <Tabs.TabPane tab="Applicants" key="1" style={{ minHeight: '1000px' }}>
                                                    <SideBar.ApplicantsSection
                                                        title="Applicant"
                                                        data={applicant}
                                                        activeReport={activeReport}
                                                        showSubReport={this.showSubReport}
                                                    />
                                                    {coSigners && coSigners.length > 0 && (
                                                        <SideBar.ApplicantsSection
                                                            title="Co-Signers"
                                                            data={coSigners}
                                                            activeReport={activeReport}
                                                            showSubReport={this.showSubReport}
                                                        />
                                                    )}
                                                    {coApplicants && coApplicants.length > 0 && (
                                                        <SideBar.ApplicantsSection
                                                            title="Co-Applicants"
                                                            data={coApplicants}
                                                            activeReport={activeReport}
                                                            showSubReport={this.showSubReport}
                                                        />
                                                    )}
                                                </Tabs.TabPane>
                                                <Tabs.TabPane tab="Comments" key="2" style={{ minHeight: '1000px' }}>
                                                    <SideBar.CommentsSection
                                                        handleUpdateComment={this.handleUpdateComment}
                                                        patchComments={this.handleSubmitComment}
                                                        comments={this.props.application.comments}
                                                        commentInput={commentInput}
                                                    />
                                                </Tabs.TabPane>
                                            </Tabs>
                                        </Layout.Sider>
                                    </Layout>
                                </div>
                            </div>
                        )}
                    </>
                )}
            </>
        );
    }
}

ApplicationsView.propTypes = propTypes;
ApplicationsView.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationsView);
