// Libraries
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { intl } from 'components/GlobalProvider';
import queryString from 'query-string';
import { useFlags } from 'launchdarkly-react-client-sdk';

// Components
import { ButtonUpgrade, ButtonContinue, ButtonSaveAsPackage } from './components/ScreenApplicant';
import Loader from 'certn-ui/Loader';
import { MicroService, FUFApplicantInformation, FUFIntCrimCheckCountries, ScreenApplicantCart } from './components';
import { getNumApplications } from 'views/manager/views/hr/views/applications/ApplicationsSelectors';
import { PackageSelection } from './components/ScreenApplicant/PackageSelection/PackageSelection';
import LimeBanner from 'views/manager/components/LimeBanner';
import {
    MicroServicesLayout,
    MicroServicesListBox,
    ListItems,
    RightSideFrame,
    TitleBar,
    Title,
    Disclaimer,
    CartButtonWrapper,
    EmptyServicesWrapper,
} from './styled';

// Actions & Selectors
import { getTeam, getUserModeIsHR, getUser, getTeamSettingsConfig } from 'base/BaseSelectors';
import { fetchReferenceTemplates } from 'views/manager/views/settings/SettingsActions';
import {
    getIsUpgrade,
    getIsFollowUpFlow,
    getCheckPageComplete,
    isFetchingCountriesLoading,
    getIsInternationalCrimInFUF,
    getShouldPersistScreenApplicantState,
    getUkVisibleChecks,
    getIsOnlyQuickScreen,
} from './ScreenApplicantSelectors';
import {
    setupServicesPage,
    emailAccessFollowUpFlow,
    initializeFollowUpFlow,
    setServicePageComplete,
    setServicePageLoading,
    recalculateTotal,
    handleScreenTypeSelect,
    SET_SHOULD_PERSIST_SCREEN_APPLICANT_STATE,
} from './ScreenApplicantActions';
import { fetchCountries } from 'base/BaseActions';
import { fetchTeam } from 'views/manager/views/admin/AdminActions';

// Utils
import Auth from 'modules/Auth';
import Constants, { QA_TEST_ID } from 'utils/constants';

// Constants
import { NEW, CANDIDATE, MYSELF } from './ScreenApplicantConstants';
import { ScreenApplicantCheckSettingsContext } from './contexts';

const ContinueOrSavePackage = () => {
    const {
        params: { packageID },
    } = useRouteMatch();
    const isUpgrade = useSelector(getIsUpgrade);
    const isFollowUpFlow = useSelector(getIsFollowUpFlow);
    const userModeIsHR = useSelector(getUserModeIsHR);
    const canCreateAndEditPackages = useSelector(getUser).can_create_and_edit_packages;

    if (isFollowUpFlow || isUpgrade)
        return (
            <CartButtonWrapper>
                <ButtonUpgrade />
            </CartButtonWrapper>
        );
    return (
        <CartButtonWrapper>
            {!packageID && <ButtonContinue />}
            {userModeIsHR && canCreateAndEditPackages && <ButtonSaveAsPackage />}
        </CartButtonWrapper>
    );
};

const ScreenApplicant = () => {
    const { webFeatureEnableNewStatusSystem } = useFlags();
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const {
        params: { packageID },
    } = useRouteMatch();
    const numApplications = useSelector(getNumApplications);
    const userModeIsHR = useSelector(getUserModeIsHR);
    const isCountriesLoading = useSelector(isFetchingCountriesLoading);
    const isInternationalCrimInFUF = useSelector(getIsInternationalCrimInFUF);
    const teamId = useSelector(getTeam)?.id ?? '';
    const paymentVerified = useSelector(getTeam)?.payment_verified;
    const [mobile, setMobile] = useState(false);
    const mobileDevice = window.matchMedia('(max-width: 1023px)').matches;
    const reset = location.state?.reset;
    const team = useSelector(getTeam);
    const editingPackage = userModeIsHR && packageID && packageID !== NEW;
    const hidePricing = useSelector(getTeamSettingsConfig).hide_pricing_for_non_admins;
    const isAdmin = Auth.isPermissionLevel('admin');
    const showPricing = isAdmin || !hidePricing;

    // Follow Up Flow
    const [applicantId] = useState(queryString.parse(location?.search)?.applicantId);
    const isServicePageComplete = useSelector(getCheckPageComplete);
    const isFollowUpFlow = useSelector(getIsFollowUpFlow);

    // Page persist
    const shouldPersistScreenApplicantState = useSelector(getShouldPersistScreenApplicantState);

    // Generic Checks
    const visibleChecks = useSelector(getUkVisibleChecks);
    const hasSoftCheck = visibleChecks.some((check) => check.requestFlag === 'request_softcheck');

    // Default Screen Type
    const defaultScreenType = useSelector(getIsOnlyQuickScreen) ? MYSELF : CANDIDATE;

    useEffect(() => {
        dispatch(recalculateTotal());
    }, [dispatch, isCountriesLoading]);

    // Ensures admin team object exists to be referenced in StripeCheckoutContainer
    useEffect(() => {
        dispatch(fetchTeam(teamId));
    }, [dispatch, teamId]);

    // Fetch Resources
    useEffect(() => {
        dispatch(fetchCountries(teamId));
        dispatch(fetchReferenceTemplates({ type: 'address', teamId }));
        dispatch(fetchReferenceTemplates({ type: 'employer', teamId }));
    }, [dispatch, teamId, userModeIsHR]);

    useEffect(() => {
        dispatch(handleScreenTypeSelect(defaultScreenType));
    }, [defaultScreenType, dispatch]);

    useEffect(() => {
        setMobile(mobileDevice);
    }, [mobileDevice]);

    const setupInitialScreenApplicantPage = React.useCallback(() => {
        // Reset after clicking Screen Applicant from Nav Bar
        if (location.state?.reset) history.replace({ reset: false });

        // Follow up Flow direct email access
        if (applicantId) {
            dispatch(emailAccessFollowUpFlow(applicantId)).then(() => dispatch(setServicePageComplete));
            return;
        }

        // Regular load, Follow Up Flow, Upgrade Flow, Package New & Package Edit
        dispatch(
            setupServicesPage({ applicant: location.state?.applicant, packageID: editingPackage && packageID })
        ).then(() => {
            // If Follow Up Flow, initialize
            // LDFlag webFeatureEnableNewStatusSystem
            let awaitingAction;
            if (webFeatureEnableNewStatusSystem)
                awaitingAction = location.state?.applicant?.order_status === Constants.orderStatus.ACTION_REQUIRED;
            if (!webFeatureEnableNewStatusSystem)
                awaitingAction = location.state?.applicant?.report_status === Constants.reportStatus.ACTION_REQUIRED;

            if (awaitingAction) dispatch(initializeFollowUpFlow(location.state?.applicant));

            // Both routes must complete
            dispatch(setServicePageComplete);
        });
    }, [
        webFeatureEnableNewStatusSystem,
        editingPackage,
        applicantId,
        dispatch,
        history,
        location.state?.applicant,
        location.state?.reset,
        packageID,
    ]);

    useEffect(() => {
        // if state persist don't initialize
        if (shouldPersistScreenApplicantState) return;
        // else let's initialize and set service page loading
        dispatch(setServicePageLoading);
        setupInitialScreenApplicantPage();
    }, [dispatch, paymentVerified, shouldPersistScreenApplicantState, reset, setupInitialScreenApplicantPage]);

    useEffect(
        () =>
            function cleanup() {
                dispatch({ type: SET_SHOULD_PERSIST_SCREEN_APPLICANT_STATE, payload: false });
            },
        [dispatch]
    );

    const isChecksPageLoading = isCountriesLoading || !isServicePageComplete;

    if (isChecksPageLoading) return <Loader />;
    return (
        <>
            <LimeBanner isTeamBlockedWithApplications={team.applicant_invites_blocked && numApplications > 0} />
            <MicroServicesLayout data-testid={QA_TEST_ID.MicroServicesLayout}>
                <ScreenApplicantCheckSettingsContext.Provider value={{ showPricing }}>
                    <MicroServicesListBox data-testid="microservice_list_container">
                        <ListItems>
                            <TitleBar main>
                                <Title data-testid="screen_applicant_title">
                                    {intl.formatMessage({
                                        id: 'common.services',
                                        defaultMessage: 'Checks',
                                    })}
                                </Title>
                                <PackageSelection />
                            </TitleBar>
                            {visibleChecks?.length > 0 &&
                                visibleChecks.map((check, index) => (
                                    <MicroService check={check} key={index} data-testid={check.requestFlag} />
                                ))}
                            {visibleChecks.length === 0 && (
                                <EmptyServicesWrapper>
                                    <FormattedMessage
                                        id="a0e86.ScreenApplicant.serviceListEmpty"
                                        defaultMessage="Check list appears to be empty, please contact support"
                                    />
                                </EmptyServicesWrapper>
                            )}
                        </ListItems>
                        {!mobile && hasSoftCheck && <DisclaimerSection />}
                    </MicroServicesListBox>
                    <RightSideFrame isFollowUpFlow={isFollowUpFlow}>
                        {/* Shopping Cart */}
                        <ScreenApplicantCart continueSaveAs={<ContinueOrSavePackage mobile={mobile} />} />
                        {isFollowUpFlow && (
                            <>
                                {/* Applicant Information */}
                                <FUFApplicantInformation />
                                {/* Int Crim Countries */}
                                {isInternationalCrimInFUF && <FUFIntCrimCheckCountries />}
                            </>
                        )}
                    </RightSideFrame>
                    {mobile && hasSoftCheck && <DisclaimerSection />}
                </ScreenApplicantCheckSettingsContext.Provider>
            </MicroServicesLayout>
        </>
    );
};

const DisclaimerSection = () => (
    <Disclaimer>
        <FormattedMessage
            id="a0e86.ScreenApplicant.disclaimer"
            defaultMessage={`* Softcheck provides instant access to public records quickly and easily. Softcheck is not a consumer report as defined under the Fair Credit Reporting Act, or "FCRA". Certn cannot guarantee the accuracy of the information contained in a Softcheck, particularly social media profiles and criminal record information. To guarantee the accuracy of records, please ensure you select Basic, or Enhanced ID verification, and a Criminal Record Check.`}
        />
    </Disclaimer>
);

export default ScreenApplicant;
