// Libraries
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Upload, message, Modal } from 'antd';
import { FilePdfFilled, PlusOutlined } from '@ant-design/icons';

// Components
import { ContentWrapper, ContentTitle, ContentText, DeleteText, FileName, FileWrapper, SectionWrapper } from './styled';
import { intl } from 'components/GlobalProvider';

// Utils & Modules
import Auth from 'modules/Auth';
import config from 'utils/config';
import Constants from 'utils/constants';
import { deleteRequest } from 'utils/http';

// Types
import { ContentSectionProps } from './types';
import { RcFile, UploadChangeParam } from 'antd/lib/upload';

export const ContentSection: React.FC<ContentSectionProps> = ({ adverseAction, isPreAdverse, reload }) => {
    const [isUploading, setIsUploading] = useState(false);
    const {
        adverse_action_contents: adverseActionContents,
        pre_adverse_action_contents: preAdverseActionContents,
        adverse_action_status: adverseActionStatus,
    } = adverseAction;
    const contents = isPreAdverse ? preAdverseActionContents : adverseActionContents;
    const userUploads = contents?.user_uploads || [];
    const files = [contents?.letter, contents?.report, contents?.summary_of_rights].filter(Boolean);
    const canEdit = adverseActionStatus && adverseActionStatus === Constants.adverseActionStatus.NONE;

    const truncatedFileName = (name: string) => (name.length > 20 ? `${name.substring(0, 20)}...` : name);

    const handleDelete = async (id: string, fileName: string) => {
        try {
            await deleteRequest({ version: 'v2', endpoint: `/adverse-action/documents/${id}` });
            message.success(
                intl.formatMessage(
                    {
                        id: 'AdverseAction.Drawer.ContentSection.DeleteSuccessful',
                        defaultMessage: '{fileName} has been deleted',
                    },
                    { fileName }
                )
            );
            reload();
        } catch (error) {
            message.error(
                intl.formatMessage(
                    {
                        id: 'AdverseAction.Drawer.ContentSection.DeleteFailed',
                        defaultMessage: 'Failed to delete {fileName}',
                    },
                    { fileName }
                )
            );
        }
    };

    const beforeUpload = (file: RcFile) => {
        if (file.size > 10 * 1024 * 1024) {
            Modal.warning({
                centered: true,
                title: intl.formatMessage({
                    id: 'AdverseAction.Drawer.ContentSection.fileSizeErrorTitle',
                    defaultMessage: 'Exceeds file size',
                }),
                content: intl.formatMessage({
                    id: 'AdverseAction.Drawer.ContentSection.fileSizeErrorDescription',
                    defaultMessage:
                        'The PDF document you are trying to upload exceeds the max file size limit of 10 MB. Reduce the file size and try again.',
                }),
                okText: intl.formatMessage({ id: 'common.ok', defaultMessage: 'OK' }),
            });
            setIsUploading(false);
            return false;
        }

        setIsUploading(true);
        return true;
    };

    const onUpload = ({ file }: UploadChangeParam) => {
        const { name, status } = file;

        if (status === 'error') {
            setIsUploading(false);
            message.error(
                intl.formatMessage(
                    {
                        id: 'AdverseAction.Drawer.ContentSection.UploadFailed',
                        defaultMessage: 'Failed to upload {fileName}',
                    },
                    { fileName: truncatedFileName(name) }
                )
            );
        }

        if (status !== 'done') return;

        message.success(
            intl.formatMessage(
                {
                    id: 'AdverseAction.Drawer.ContentSection.UploadSuccessful',
                    defaultMessage: '{fileName} uploaded successfully!',
                },
                { fileName: truncatedFileName(name) }
            )
        );
        setIsUploading(false);
        reload();
    };

    return (
        <SectionWrapper>
            <ContentWrapper style={{ borderBottom: '1px solid #e3e3e3' }}>
                <ContentTitle>
                    {isPreAdverse ? (
                        <FormattedMessage
                            id="AdverseAction.Drawer.ContentSection.PreAdverseTitle"
                            defaultMessage="Pre-Adverse Action Contents"
                        />
                    ) : (
                        <FormattedMessage
                            id="AdverseAction.Drawer.ContentSection.AdverseTitle"
                            defaultMessage="Adverse Action Contents"
                        />
                    )}
                </ContentTitle>
                <ContentText>
                    <FormattedMessage
                        id="AdverseAction.Drawer.ContentSection.Subtitle"
                        defaultMessage="These documents are included automatically in a single combined PDF."
                    />
                </ContentText>
            </ContentWrapper>
            <ContentWrapper>
                {files.map((fileName) => (
                    <FileWrapper key={fileName}>
                        <FileName>
                            <FilePdfFilled style={{ opacity: '0.45', marginRight: '6px' }} />
                            {fileName}
                        </FileName>
                    </FileWrapper>
                ))}
                {userUploads.map(({ id, document_name: fileName }) => (
                    <FileWrapper hasDelete key={fileName}>
                        <FileName>
                            <FilePdfFilled style={{ opacity: '0.45', marginRight: '6px' }} />
                            {fileName}
                        </FileName>
                        {canEdit && (
                            <DeleteText onClick={() => handleDelete(id, truncatedFileName(fileName))}>
                                <FormattedMessage id="common.delete" defaultMessage="Delete" />
                            </DeleteText>
                        )}
                    </FileWrapper>
                ))}
                {canEdit && (
                    <>
                        <Upload
                            accept=".pdf"
                            name="document_path"
                            action={`${config.url}/api/v2/adverse-action/${adverseAction.applicant_id}/documents/upload`}
                            data={{ type: isPreAdverse ? 'PA' : 'AA' }}
                            headers={{ Authorization: `Token ${Auth.getToken()}` }}
                            beforeUpload={beforeUpload}
                            onChange={onUpload}
                            showUploadList={false}
                        >
                            <Button
                                style={{ borderRadius: '4px', marginBottom: '12px' }}
                                size="large"
                                loading={isUploading}
                            >
                                <PlusOutlined style={{ marginRight: '6px' }} />
                                <FormattedMessage
                                    id="AdverseAction.Drawer.ContentSection.AddPDF"
                                    defaultMessage="Add Additional PDF"
                                />
                            </Button>
                        </Upload>
                        <ContentText>
                            <FormattedMessage
                                id="AdverseAction.Drawer.ContentSection.AddPDFExplanation"
                                defaultMessage="Note, an individualized assessment is required in the cities of New York and Los Angeles. Use the above button to add this type of documentation."
                            />
                        </ContentText>
                    </>
                )}
            </ContentWrapper>
        </SectionWrapper>
    );
};
