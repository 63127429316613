import { Dispatch } from 'redux';
import { IS_DRAWER_OPEN } from './DrawerConstants';

export const toggleDrawer = (isDrawerOpen: boolean, id?: string) => (dispatch: Dispatch) => {
    dispatch({
        type: IS_DRAWER_OPEN,
        payload: {
            isDrawerOpen,
            id,
        },
    });
};
