// Libraries
import React from 'react';
import styled from 'styled-components/macro';

const Navbar = styled.div`
    z-index: ${(props) => (props.fixed ? '11' : '10')};
    width: 100%;
    min-height: 60px;
    position: ${(props) => (props.fixed ? 'fixed' : 'relative')};
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom: ${(props) => (props.noBorder ? '0px' : `2px solid ${props.theme.color.certnGray100}`)};
    box-sizing: border-box;
    margin-bottom: 5px;
`;
export default Navbar;

export const NavMain = styled.div`
    height: 60px;
    width: 100%;
    max-width: 100%;
    background: ${({ theme }) => theme.color.certnWhite};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${(props) => (props.fixed ? '0 15px' : '0')};
    box-sizing: border-box;
    @media (max-width: ${(props) => props.theme.width.lrg}) {
        padding: ${(props) => (props.fixed ? '0 20px' : '0')};
    }
`;

export const NavAbove = styled.div`
    flex: 1 1;
    width: 100%;
    z-index: 1;
    background: ${({ theme }) => theme.color.certnGreen700};
    color: ${({ theme }) => theme.color.certnWhite};
    text-align: center;
    padding: 2px;
    font-size: ${(props) => props.theme.font.xxs};
`;

export const NavBelow = styled.div`
    flex: 1 1;
    width: 100%;
    background: ${({ theme }) => theme.color.certnWhite};
    z-index: 1;
    display: flex;
`;

export const NavLeft = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: flex-start;
    width: fit-content;
    flex: 1 0 0;
`;

export const NavRight = styled.div`
    display: flex;
    width: 300px;
    height: 100%;
    justify-content: flex-end;
    align-items: center;
    @media (max-width: ${(props) => props.theme.width.med}) {
        width: 180px;
    }
    ${(props) => props.onboarding && 'width: 100%'};
    > *:not(:last-child) {
        margin-right: 5px;
    }
`;

// TODO: Swap to non-required flags like "hideDesktop" and "hideMobile" or something
export const NavOptions = styled.div`
    align-items: center;
    height: 100%;
    width: 100%;
    justify-content: flex-start;
    display: ${(props) => (props.desktop ? 'flex' : 'none')};

    @media (max-width: ${(props) => props.theme.width.lrg}) {
        display: ${(props) => (props.mobile ? 'flex' : 'none')};
        width: 100%;
    }
`;

export const NavOption = (props) => (
    <NavOptionContainer topNav={props.topNav} currentRoute={props.currentRoute} onClick={props.onClick}>
        <NavOptionText currentRoute={props.currentRoute}>{props.label}</NavOptionText>
    </NavOptionContainer>
);

const NavOptionContainer = styled.div`
    /* flex: 1 1; */
    height: 100%;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${(props) => props.theme.color.secondary};
    box-sizing: border-box;
    border-top: 3px solid ${({ theme }) => theme.color.certnWhite};
    border-bottom: 3px solid ${(props) => (props.currentRoute ? props.theme.color.primary : 'white')} !important;
    border-radius: 2px;
    cursor: pointer;
    min-width: 80px;
    transition: 0.3s;
    background: white;
    padding: 0 15px;
    ${(props) => props.topNav && 'margin-left: 20px'};

    &:hover {
        border-bottom: 3px solid
            ${(props) => (props.currentRoute ? props.theme.color.primary : props.theme.color.certnGray100)} !important;
        color: ${(props) => props.theme.color.certnGray400} !important;
        font-weight: 700 !important;
    }

    &:active {
        border-bottom: ${(props) => `3px solid ${props.theme.color.primary}`} !important;
        color: ${(props) => props.theme.color.certnGreen700} !important;
    }

    @media (max-width: ${(props) => props.theme.width.sm}) {
        padding: 0 10px;
        min-width: 50px;
    }

    @media (max-width: ${(props) => props.theme.width.lrg}) {
        margin: 0;
    }
`;

const NavOptionText = styled.span`
    text-transform: uppercase;
    text-decoration: none !important;
    font-weight: ${(props) => (props.currentRoute ? 700 : 600)};
    font-size: 12px;
    color: ${(props) => (props.currentRoute ? `${props.theme.color.primary} !important` : 'inherit')};

    @media (max-width: ${(props) => props.theme.width.sm}) {
        font-size: 10px;
    }
`;
