// Libraries
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { formatDate } from 'modules/Format';

// Components
import SummaryItem from './SummaryItem';
import SummaryHeader from './SummaryHeader';
import { FlexRow, SummarySection, SummaryContainer } from './SummaryStyles';
import { CHECK_REQUEST } from 'base/BaseConstants';

// Actions & Selectors
import { getApplicantAccount, getTeamIsMyCRC, getSettings, getApplicant } from 'views/welcome/WelcomeSelectors';

const Basics = ({ basics, goToTrack }) => {
    const applicantEmail = useSelector(getApplicantAccount)?.email;
    const teamIsMyCRC = useSelector(getTeamIsMyCRC);
    const { get_org_country: country } = useSelector(getSettings);
    const applicant = useSelector(getApplicant);
    const isUkCheck =
        applicant && (applicant[CHECK_REQUEST.UK_BASIC_DBS_CHECK] || applicant[CHECK_REQUEST.UK_BASIC_DS_CHECK]);
    return (
        <SummaryContainer>
            <SummaryHeader
                title={<FormattedMessage id="welcome.general.basicInformation" defaultMessage="Basic Information" />}
                onClick={goToTrack}
            />
            <SummarySection>
                <FlexRow>
                    {isUkCheck && (
                        <SummaryItem
                            title={<FormattedMessage id="common.Basics.title_name" defaultMessage="Title" />}
                            text={basics.title}
                        />
                    )}
                    <SummaryItem
                        title={<FormattedMessage id="common.firstName" defaultMessage="First Name" />}
                        text={basics.first_name}
                    />
                    <SummaryItem
                        title={<FormattedMessage id="common.middleName" defaultMessage="Middle Name" />}
                        text={basics.middle_name}
                    />
                    <SummaryItem
                        title={<FormattedMessage id="common.lastName" defaultMessage="Last Name" />}
                        text={basics.last_name}
                    />
                    {!teamIsMyCRC && (
                        <SummaryItem
                            title={<FormattedMessage id="welcome.general.alias" defaultMessage="Alias" />}
                            text={basics.alias}
                        />
                    )}
                    <SummaryItem
                        title={<FormattedMessage id="welcome.general.maidenName" defaultMessage="Maiden Name" />}
                        text={basics.last_name_at_birth}
                    />
                    {teamIsMyCRC && (
                        <SummaryItem
                            title={<FormattedMessage id="common.dateOfBirth" defaultMessage="Date of Birth" />}
                            text={formatDate(basics.date_of_birth, country)}
                        />
                    )}
                    {teamIsMyCRC && (
                        <SummaryItem
                            title={<FormattedMessage id="welcome.Gender.sex" defaultMessage="Gender" />}
                            text={basics.gender}
                        />
                    )}
                    <SummaryItem
                        title={<FormattedMessage id="welcome.general.phoneNumber" defaultMessage="Phone Number" />}
                        text={basics.phone_number}
                    />
                    <SummaryItem
                        title={<FormattedMessage id="common.email" defaultMessage="Email" />}
                        text={applicantEmail}
                        full="True"
                    />
                </FlexRow>
            </SummarySection>
        </SummaryContainer>
    );
};

export default injectIntl(Basics);
