import moment from 'moment';
import { intl } from 'components/GlobalProvider';
import { getDateFormat } from 'modules/Format';

// Values returned from the API are in the format YYYY-MM-DD
export const stringToMomentDate = (value) => moment.utc(value?.replace(/\D/g, ''), 'YYYYMMDD', true);

export const isDateInPast = (momentDate) => {
    if (!momentDate || !momentDate.isValid()) {
        return false;
    }
    return momentDate.utc() < moment();
};

export const isDateInFuture = (momentDate) => {
    if (!momentDate || !momentDate.isValid()) {
        return false;
    }
    return momentDate.utc() > moment();
};

export const isDateToday = (momentDate) => {
    if (!momentDate || !momentDate.isValid()) {
        return false;
    }
    return moment().diff(momentDate, 'days') === 0;
};

export const endDateIsToday = (value = '') => {
    const date = typeof value === 'string' ? stringToMomentDate(value) : moment(value);
    if (isDateToday(date)) {
        return intl.formatMessage({
            id: 'error.validation.endDateIsToday.removeEnd',
            defaultMessage: 'If this is current, please remove this end date.',
        });
    }
    return undefined;
};

export const getEndDateBeforeStartDateError = (endDateString, startDateString, dateFormat = getDateFormat()) => {
    if (endDateString && moment(endDateString, dateFormat).isBefore(moment(startDateString, dateFormat))) {
        return intl.formatMessage({
            id: 'error.validation.startDateEarlierThanEndDate',
            defaultMessage: 'Start date must be earlier than end date',
        });
    }
    return undefined;
};

export const isUnderXYears = (momentDate, years) => {
    if (!momentDate || !momentDate.isValid()) {
        return false;
    }
    return moment().diff(momentDate, 'years') < years;
};

export const isUnder18Years = (momentDate) => isUnderXYears(momentDate, 18);

export const isOverXYears = (momentDate, years) => {
    if (!momentDate || !momentDate.isValid()) {
        return false;
    }
    return moment().diff(momentDate, 'years') > years;
};

export const isOver120Years = (momentDate) => isOverXYears(momentDate, 120);

/** Valid birth dates are any date in the past 120 years. */
export const isValidBirthDate = (momentDate) => isDateInPast(momentDate) && !isOver120Years(momentDate);

export const dateBefore18Yrs = (value = '') => {
    if (!value) return undefined;

    const date = stringToMomentDate(value);
    if (isUnder18Years(date)) {
        return intl.formatMessage({
            id: 'error.validation.dateBefore18Yrs.notLegal',
            defaultMessage: 'Must be 18 years old to continue',
        });
    }
    return undefined;
};

export const hasLowerCase = (value) => {
    const regex = new RegExp('^(?=.*[a-z])');
    return regex.test(value);
};

export const hasUpperCase = (value) => {
    const regex = new RegExp('^(?=.*[A-Z])');
    return regex.test(value);
};

export const hasNumber = (value) => {
    const regex = new RegExp('^(?=.*[0-9])');
    return regex.test(value);
};

export const hasSpecialChar = (value) => {
    const regex = new RegExp('^(?=.*[!@#$%^&*])');
    return regex.test(value);
};

export const hasLengthOf = (value, length) => {
    if (value === undefined || value === null) {
        return false;
    }
    return value.length >= length;
};

export const isUUID = (value) => {
    const regex = RegExp('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$');
    return regex.test(value);
};

export const passwordValidator = (value) => {
    if (!hasLowerCase(value)) {
        return intl.formatMessage({
            id: 'error.validation.password.lowercase',
            defaultMessage: 'Password needs at least one lowercase letter',
        });
    }
    if (!hasUpperCase(value)) {
        return intl.formatMessage({
            id: 'error.validation.password.uppercase',
            defaultMessage: 'Password needs at least one uppercase letter',
        });
    }
    if (!hasNumber(value)) {
        return intl.formatMessage({
            id: 'error.validation.password.number',
            defaultMessage: 'Password needs at least one number',
        });
    }
    if (!hasSpecialChar(value)) {
        return intl.formatMessage({
            id: 'error.validation.password.special',
            defaultMessage: 'Password needs at least one special character (ie: !@#$%^&*)',
        });
    }
    if (!hasLengthOf(value, 8)) {
        return intl.formatMessage(
            {
                id: 'error.validation.password.minLength',
                defaultMessage: 'Password must be at least {minLength} characters long',
            },
            {
                minLength: 8,
            }
        );
    }
    return undefined;
};

export const simplePasswordValidator = (value, minLength = 10) => {
    if (!hasLengthOf(value, minLength)) {
        return intl.formatMessage(
            {
                id: 'error.validation.password.minLength',
                defaultMessage: 'Password must be at least {minLength} characters long',
            },
            {
                minLength,
            }
        );
    }
    return undefined;
};

export const containsHTMLValidator = (value) => {
    const tagsRegex = /<.+?>/;
    if (tagsRegex.test(value)) {
        return intl.formatMessage({
            id: 'error.validation.noHTML',
            defaultMessage: 'Please do not use brackets or HTML tags',
        });
    }
    return undefined;
};
