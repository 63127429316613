// Libraries
import styled from 'styled-components/macro';
import { FormattedMessage } from 'react-intl';
import { useContext } from 'react';
import { Modal } from 'antd';

// Components
import Typography from 'certnd/Typography';
import CountryOptions from './CountryOptions';
import { PackageForm } from './PackageForm';
import { PackagesContext } from '../Packages.context';

// Modules & Utils
import MicroServiceAddOptions from 'views/manager/features/ScreenApplicant/components/ScreenApplicant/MicroServiceAddOptions';

// Constants
import { CHECK_REQUEST } from 'base/BaseConstants';

const { Heading } = Typography;

const OptionsWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 20px 0 20px;
    justify-content: space-between;
    @media (max-width: ${(props) => props.theme.width.tablet}) {
        width: 100%;
    }
    @media (min-width: ${(props) => props.theme.width.desktop}) {
        ${({ marginRight }) => marginRight && 'margin-right: 15px'}
        ${({ marginLeft }) => marginLeft && 'margin-left: 15px'}
    }
`;

export const PackageModal = (props) => {
    const { modalVisible, savePackage, handleModalCancel, isFetching } = props;
    const { form, editingPackage } = useContext(PackagesContext);
    const { getFieldValue } = form;

    const getRequestedPackages = () => {
        const fieldRequestedPackages = getFieldValue('requested_packages');
        if (editingPackage && editingPackage.services && fieldRequestedPackages === undefined) {
            return editingPackage.services;
        }

        return fieldRequestedPackages;
    };

    // Flags for displaying additional modal form fields based on selected services
    const displayIntCrimCountries = () => {
        const requestedPackages = getRequestedPackages();
        return requestedPackages?.includes(CHECK_REQUEST.INTERNATIONAL_CRIMINAL_RECORD_CHECK);
    };
    const displayReferenceQuestionaire = () => {
        const requestedPackages = getRequestedPackages();
        return (
            requestedPackages?.includes(CHECK_REQUEST.EMPLOYER_REFERENCES) ||
            requestedPackages?.includes(CHECK_REQUEST.EMPLOYER_PHONE_REFERENCES)
        );
    };
    const displayEmploymentVerification = () => {
        const requestedPackages = getRequestedPackages();
        return requestedPackages?.includes(CHECK_REQUEST.EMPLOYMENT_VERIFICATION);
    };
    const displayCredentialVerification = () => {
        const requestedPackages = getRequestedPackages();
        return requestedPackages?.includes(CHECK_REQUEST.CREDENTIAL_VERIFICATION);
    };
    const displayEducationVerification = () => {
        const requestedPackages = getRequestedPackages();
        return requestedPackages?.includes(CHECK_REQUEST.EDUCATION_VERIFICATION);
    };
    const displayAcicCheck = () => {
        const requestedPackages = getRequestedPackages();
        return requestedPackages?.includes(CHECK_REQUEST.STANDARD_ACIC_CHECK);
    };

    const displayCountries = displayIntCrimCountries();
    const displayQuestionaires = displayReferenceQuestionaire();
    const displayEmploymentVerificationOptions = displayEmploymentVerification();
    const displayEducationVerificationOptions = displayEducationVerification();
    const displayCredentialVerificationOptions = displayCredentialVerification();
    const displayAcicCheckOptions = displayAcicCheck();

    return (
        <Modal
            title={<FormattedMessage id="22572.Packages.PackageEditor" defaultMessage="Package Editor" />}
            visible={modalVisible}
            onOk={savePackage}
            okText={<FormattedMessage id="22572.Packages.OkText" defaultMessage="Save Package" />}
            cancelText={<FormattedMessage id="common.cancel" defaultMessage="Cancel" />}
            onCancel={handleModalCancel}
            confirmLoading={isFetching}
            centered
            width={800}
            distroyOnClose
        >
            <PackageForm />

            {displayCountries && <CountryOptions editingPackage={editingPackage} form={form} />}
            {displayQuestionaires && (
                <>
                    <Heading.H4>
                        <b>
                            <FormattedMessage
                                id="22572.Packages.ReferencesOptions"
                                defaultMessage="Employer References Options"
                            />
                        </b>
                    </Heading.H4>
                    <OptionsWrapper>
                        <MicroServiceAddOptions.References employer form={form} serviceKey="employer_references" />
                    </OptionsWrapper>
                </>
            )}
            {displayEmploymentVerificationOptions && (
                <>
                    <Heading.H4>
                        <b>
                            <FormattedMessage
                                id="22572.Packages.EmploymentVerificationOptions"
                                defaultMessage="Employment Verification Options"
                            />
                        </b>
                    </Heading.H4>
                    <OptionsWrapper>
                        <MicroServiceAddOptions.EmploymentVerification form={form} />
                    </OptionsWrapper>
                </>
            )}
            {displayEducationVerificationOptions && (
                <>
                    <Heading.H4>
                        <b>
                            <FormattedMessage
                                id="22572.Packages.EducationVerificationOptions"
                                defaultMessage="Education Verification Options"
                            />
                        </b>
                    </Heading.H4>
                    <OptionsWrapper>
                        <MicroServiceAddOptions.EducationVerification form={form} />
                    </OptionsWrapper>
                </>
            )}
            {displayCredentialVerificationOptions && (
                <>
                    <Heading.H4>
                        <b>
                            <FormattedMessage
                                id="22572.Packages.CredentialVerificationOptions"
                                defaultMessage="Credential Verification Options"
                            />
                        </b>
                    </Heading.H4>
                    <OptionsWrapper>
                        <MicroServiceAddOptions.CredentialVerification form={form} />
                    </OptionsWrapper>
                </>
            )}
            {displayAcicCheckOptions && (
                <>
                    <Heading.H4>
                        <b>
                            <FormattedMessage
                                id="22572.Packages.AcicCheckOptions"
                                defaultMessage="Australian Criminal Intelligence Commission Check Options"
                            />
                        </b>
                    </Heading.H4>
                    <OptionsWrapper>
                        <MicroServiceAddOptions.AcicCheck form={form} />
                    </OptionsWrapper>
                </>
            )}
        </Modal>
    );
};
