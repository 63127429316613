// Libraries
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

// Components
import { ErrorAlertCustom } from 'certn-ui/ErrorAlert';
import { EmployerForm, ReferenceForm, Base } from './components';

// Modules
import withNavigation from 'views/welcome/modules/WithNavigation';
import withSettingsChecker from 'views/welcome/modules/WithSettingsChecker';
import { getEndDateBeforeStartDateError } from 'modules/validation';
import { OTHER } from 'modules/Countries';

// Actions & Selectors
import { updateAddressError } from 'base/BaseActions';
import { getLaunchDarklyFlags } from 'base/BaseSelectors';
import { setTrackPageOrder, patchInformation } from 'views/welcome/WelcomeActions';
import {
    getIsFetchingSilent,
    getInformation,
    getEmployers,
    getOnboardingType,
    getSettings,
    getApplicant,
    getApplicantAccount,
} from 'views/welcome/WelcomeSelectors';

// Constants
import { CHECK_REQUEST } from 'base/BaseConstants';

const mapStateToProps = (state) => ({
    isFetchingSilent: getIsFetchingSilent(state),
    information: getInformation(state),
    employers: getEmployers(state),
    onboardingType: getOnboardingType(state),
    settings: getSettings(state),
    applicant: getApplicant(state),
    applicantAccount: getApplicantAccount(state),
    webFeatureEnableNewEmployerReferenceTrack: getLaunchDarklyFlags(state)?.webFeatureEnableNewEmployerReferenceTrack,
});

const mapDispatchToProps = (dispatch) => ({
    dispatch,
    ...bindActionCreators(
        {
            patchInformation,
            updateAddressError,
            setTrackPageOrder,
        },
        dispatch
    ),
});

const propTypes = {
    // Redux Actions:
    patchInformation: PropTypes.func.isRequired,
    setTrackPageOrder: PropTypes.func.isRequired,
    // Redux Store:
    isFetchingSilent: PropTypes.bool.isRequired,
    information: PropTypes.object.isRequired,
    settings: PropTypes.object.isRequired,
    onboardingType: PropTypes.string,
    employers: PropTypes.array,
};
const defaultProps = {
    employers: [],
    onboardingType: 'PM',
};

class Employers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentEmployerIndex: 0,
            phone: null,
            errorStatus: null,
            userIsCA: props.settings.get_org_country === 'CA',
        };
    }

    componentDidMount() {
        const { setTrackPageOrder: setPageOrder, webFeatureEnableNewEmployerReferenceTrack } = this.props;
        const pageOrder = ['base', 'employer', 'reference'];
        window.scrollTo(0, 0);

        if (webFeatureEnableNewEmployerReferenceTrack) {
            pageOrder.pop();
        }

        setPageOrder(pageOrder);
    }

    handleUpdateState = (field, value) => this.setState({ [field]: value });

    booleanToString = (value) => (value ? 'true' : 'false');

    stringToBoolean = (value) => value === 'true';

    pages = () => {
        const { returnToBase, applicantAccount, applicant, settings } = this.props; /* WithNavigation */
        const requests = applicant || settings;
        const isEmploymentVerification = requests[CHECK_REQUEST.EMPLOYMENT_VERIFICATION];
        const applicantEmail = applicantAccount && applicantAccount.email;

        const referenceRequired =
            requests[CHECK_REQUEST.EMPLOYER_REFERENCES] ||
            requests[CHECK_REQUEST.EMPLOYER_PHONE_REFERENCES] ||
            settings.employer_ref_req;

        return {
            base: (
                <Base
                    handleSubmit={this.props.runSettingsCheck}
                    isFetchingSilent={this.props.isFetchingSilent}
                    employers={this.props.employers}
                    addOrEditEmployer={this.addOrEditEmployer}
                    deleteEmployer={this.deleteEmployer}
                    setValue={this.setValue}
                    onboardingType={this.props.onboardingType}
                    requests={requests}
                />
            ),
            employer: (
                <EmployerForm
                    handleSubmit={this.handleSubmit}
                    isFetchingSilent={this.props.isFetchingSilent}
                    employers={this.props.employers}
                    currentEmployerIndex={this.state.currentEmployerIndex}
                    onboardingType={this.props.onboardingType}
                    intl={this.props.intl}
                    employmentVerification={isEmploymentVerification}
                    booleanToString={this.booleanToString}
                    stringToBoolean={this.stringToBoolean}
                />
            ),
            reference: (
                <ReferenceForm
                    handleSubmit={this.handleSubmit}
                    isFetchingSilent={this.props.isFetchingSilent}
                    employers={this.props.employers}
                    currentEmployerIndex={this.state.currentEmployerIndex}
                    returnToBase={returnToBase}
                    settings={this.props.settings}
                    applicant={this.props.applicant}
                    errorStatus={this.state.errorStatus}
                    applicantEmail={applicantEmail}
                    userIsCA={this.state.userIsCA}
                    emailReferenceRequired={requests[CHECK_REQUEST.EMPLOYER_REFERENCES]}
                    referenceRequired={referenceRequired}
                />
            ),
        };
    };

    // How each of the pages patches the module in parts
    handleSubmit = (e, validateFields, setFields, phone = null) => {
        e.preventDefault();
        const {
            handleForward,
            trackPageLocation,
            information,
            applicantAccount,
            onboardingType,
        } = this.props; /* WithNavigation */
        const requests = this.props.applicant || this.props.settings;
        const isEmploymentVerification = requests[CHECK_REQUEST.EMPLOYMENT_VERIFICATION];
        const referenceRequired =
            requests[CHECK_REQUEST.EMPLOYER_REFERENCES] ||
            requests[CHECK_REQUEST.EMPLOYER_PHONE_REFERENCES] ||
            this.props.settings.employer_ref_req;
        const applicantEmail = applicantAccount && applicantAccount.email;
        const applicantPhone = information.phone_number && parsePhoneNumberFromString(information.phone_number, 'CA');

        const isEmployersPage = trackPageLocation === 'employer';
        const isReferencesPage = trackPageLocation === 'reference';

        validateFields((err, values) => {
            let newValues = {
                province_state: OTHER,
                other_province_state: null,
                ...values,
            };
            let newErr = err;

            const isCurrent = this.stringToBoolean(newValues.current);

            // Employer track only
            if (isEmployersPage) {
                newValues.can_contact = this.stringToBoolean(newValues.can_contact);

                /**
                 * For past employers, we will contact them regardless. We want to set
                 * `can_contact` to true so that API doesn't throw error on submission.
                 */
                if (!isCurrent) {
                    newValues.can_contact = true;
                }
            }

            // Reference track only
            if (isReferencesPage) {
                newValues.reference_can_contact = !newValues.reference_can_contact;
                // Employer phone reference
                if (this.props.applicant[CHECK_REQUEST.EMPLOYER_PHONE_REFERENCES] && !phone) {
                    this.handleUpdateState('errorStatus', 'error');
                    newErr = true;
                }
            }

            const partTimeFullTime = ['PT', 'FT'].includes(newValues.part_time_full_time);
            if (!partTimeFullTime) {
                if (newValues.part_time_full_time === 'PN') {
                    newValues.position = 'Pension';
                } else if (newValues.part_time_full_time === 'DA') {
                    newValues.position = 'Disability';
                } else if (newValues.part_time_full_time === 'SL') {
                    newValues.position = 'Student Loans';
                } else if (newValues.part_time_full_time === 'OT') {
                    newValues.position = 'Other';
                }
            }

            const email = newValues.contact_email;
            if (isReferencesPage && email && email === applicantEmail) {
                ErrorAlertCustom({
                    description: 'Reference email must be different from applicant email.',
                });
                newErr = true;
            }

            if (
                isReferencesPage &&
                (phone ||
                    (values.phone_type && values.phone_type !== 'NONE') ||
                    requests[CHECK_REQUEST.ADDRESS_PHONE_REFERENCES] ||
                    this.props.settings.employer_ref_phone_req)
            ) {
                const phoneNumber = phone && parsePhoneNumberFromString(phone, 'CA');
                const isPhoneRefDifferentFromApplicant = phoneNumber.nationalNumber !== applicantPhone?.nationalNumber;

                if (phoneNumber && phoneNumber.isValid() && isPhoneRefDifferentFromApplicant) {
                    this.handleUpdateState('errorStatus', 'success');
                } else {
                    if (!isPhoneRefDifferentFromApplicant) {
                        ErrorAlertCustom({
                            description: 'Reference phone number must be different from applicant phone number.',
                        });
                    }
                    this.handleUpdateState('errorStatus', 'error');
                    newErr = true;
                }
                const employerReference = {
                    contact: {
                        can_contact: true,
                        is_reference: true,
                        type: 'EMPLOYER',
                    },
                    email: {
                        address: newValues.contact_email,
                    },
                    name: {
                        first_name: newValues.contact_first_name,
                        last_name: newValues.contact_last_name,
                    },
                    phone_number: {
                        number: phoneNumber.formatInternational(),
                        national_number: phoneNumber.nationalNumber,
                        country: phoneNumber.country,
                        calling_code: phoneNumber.countryCallingCode,
                    },
                    position: newValues.contact_position,
                };

                newValues.employer_reference = employerReference;
            }

            const dateCompareError = getEndDateBeforeStartDateError(values.end_date, values.start_date);
            if (dateCompareError) {
                setFields({
                    start_date: {
                        value: values.start_date,
                        errors: [new Error(dateCompareError)],
                    },
                });
            }
            if (isEmployersPage) {
                newValues = {
                    ...newValues,
                    address: {
                        address: values.address,
                        unit: values.unit,
                        city: values.city,
                        province_state: values.province_state,
                        other_province_state: values.other_province_state,
                        country: values.country,
                        postal_code: values.postal_code,
                    },
                    current: this.stringToBoolean(values.current),
                };
                if (isEmploymentVerification) {
                    if (!newValues.address || !newValues.city || !newValues.country) {
                        this.props.updateAddressError(true);
                    }
                }
            }

            if (!newValues) newErr = true;
            if (!newErr && !dateCompareError) {
                const { employers } = this.props;
                const { currentEmployerIndex } = this.state;
                // Replace the values at the current index with values -- or if new, add new entry
                try {
                    // EDIT: Grab existing entry data at index, then add new values from form
                    const patchValues = employers;
                    if (patchValues[currentEmployerIndex]) {
                        patchValues[currentEmployerIndex] = {
                            ...patchValues[currentEmployerIndex],
                            ...newValues,
                        };

                        if (patchValues[currentEmployerIndex].current) {
                            patchValues[currentEmployerIndex].end_date = null;
                            delete patchValues[currentEmployerIndex].reason_for_leaving;
                        }
                    }
                    // ADD: Add new values from form, and grab the new index
                    else patchValues.push(newValues);
                    this.props.patchInformation({ employers: patchValues }).then(() => {
                        const noContactCurrent = isEmployersPage && newValues.current && !newValues.can_contact;
                        if (noContactCurrent || (!referenceRequired && onboardingType === 'HR')) {
                            // Go back to employers list (skip reference form)
                            this.props.returnToBase();
                        } else {
                            handleForward();
                        }
                    });
                } catch (error) {
                    ErrorAlertCustom({ title: 'Error saving employer.' });
                }
            } else {
                // provide failure error?
            }
        });
    };

    deleteEmployer = (employerIndex) => {
        const newEmployers = this.props.employers;
        newEmployers.splice(employerIndex, 1);
        this.props.patchInformation({ employers: newEmployers });
    };

    // Provide an index to employers array if you want to edit an existing entry
    addOrEditEmployer = (employerIndex = null) => {
        const { handleForward } = this.props; /* WithNavigation */
        let newEmployerIndex = employerIndex;
        if (employerIndex === null) {
            newEmployerIndex = this.props.employers && this.props.employers.length;
        }
        this.setState({ currentEmployerIndex: newEmployerIndex }, handleForward);
    };

    render() {
        const { trackPageLocation } = this.props; /* WithNavigation */
        return this.pages()[trackPageLocation];
    }
}

Employers.propTypes = propTypes;
Employers.defaultProps = defaultProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withNavigation(withSettingsChecker(Employers))));
