import React from 'react';
import { useDispatch } from 'react-redux';
import { Menu, message } from 'antd';
import PropTypes from 'prop-types';

import { ListCellText } from 'certn-ui/List';
import { ErrorAlertAPI } from 'certn-ui/ErrorAlert';

import { setApplicationStatus } from 'views/manager/views/hr/views/applications/ApplicationsActions';

const ItemMoveTo = ({ applicantId, status, successMessage, listText, ...rest }) => {
    const dispatch = useDispatch();

    const handleStatusSuccess = (alert) => {
        message.success(alert);
    };

    const handleSetApplicationStatus = async () => {
        try {
            await dispatch(setApplicationStatus(applicantId, status));
            handleStatusSuccess(successMessage);
        } catch (error) {
            ErrorAlertAPI(error);
        }
    };

    return (
        <Menu.Item {...rest}>
            <ListCellText clickable onClick={handleSetApplicationStatus}>
                {listText}
            </ListCellText>
        </Menu.Item>
    );
};

ItemMoveTo.propTypes = {
    applicantId: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    successMessage: PropTypes.string.isRequired,
    report: PropTypes.bool,
};

ItemMoveTo.defaultProps = {
    report: false,
};

export default ItemMoveTo;
