// Libraries
import React from 'react';
import { FormattedMessage } from 'react-intl';

// Components
import { AppearRight } from 'certn-ui/Animate';
import { ButtonLine } from 'certn-ui/Button';
import Title from 'certn-ui/Title';
import Trophy from 'images/svgs/svg-components/onboarding/Trophy';

const PaymentSuccess = ({ applyTo = null }) => (
    <AppearRight>
        <Title>
            <FormattedMessage
                id="welcome.Payment.allDone"
                defaultMessage="Payment successful, your application for {toAddress} has been submitted! You're all done!"
                values={{
                    toAddress: applyTo ? 'this address' : '',
                }}
            />
        </Title>
        <Trophy />
        <ButtonLine>
            <span>
                <FormattedMessage id="welcome.general.youCanCloseTab" defaultMessage="You can now close this tab." />
            </span>
        </ButtonLine>
    </AppearRight>
);

export default PaymentSuccess;
