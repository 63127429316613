// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// Components
import { DBSPaperCriminalRecordCertificateForm } from './components';

// Modules
import withNavigation from 'views/welcome/modules/WithNavigation';

// Actions & Selectors
import { updateAddressError } from 'base/BaseActions';
import { setTrackPageOrder, patchInformation } from 'views/welcome/WelcomeActions';
import { getIsFetchingSilent, getInformation, getOnboardingType, getSettings } from 'views/welcome/WelcomeSelectors';
import { yesNoToBool } from 'views/welcome/yesNoHelpers';
import { getUser } from 'base/BaseSelectors';

const mapStateToProps = (state) => ({
    isFetchingSilent: getIsFetchingSilent(state),
    information: getInformation(state),
    onboardingType: getOnboardingType(state),
    settings: getSettings(state),
    user: getUser(state),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            patchInformation,
            setTrackPageOrder,
            updateAddressError,
        },
        dispatch
    );

const propTypes = {
    // Redux Actions:
    patchInformation: PropTypes.func.isRequired,
    setTrackPageOrder: PropTypes.func.isRequired,
    updateAddressError: PropTypes.func.isRequired,
    // Redux Store:
    isFetchingSilent: PropTypes.bool.isRequired,
    information: PropTypes.object.isRequired,
    onboardingType: PropTypes.string.isRequired,
    settings: PropTypes.object,
    user: PropTypes.object.isRequired,
};
const defaultProps = {
    settings: {},
};

class DBSPaperCriminalRecordCertificate extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.setTrackPageOrder(['base']);
    }

    handleUpdateState = (field, value) => this.setState({ [field]: value });

    handleSubmit = (e, validateFields) => {
        e.preventDefault();
        const { handleNextTrack } = this.props; /* WithNavigation */

        validateFields((err, values) => {
            if (!err) {
                this.props.updateAddressError(false);
                const newValues = {
                    dbs_paper_certificate_consent_given: yesNoToBool(values.dbs_paper_certificate_consent_given_yesno),
                    dbs_paper_certificate_current_address_confirmation: yesNoToBool(
                        values.dbs_paper_certificate_current_address_confirmation_yesno
                    ),
                    dbs_paper_certificate_recipient_department: values.dbs_paper_certificate_recipient_department,
                    dbs_paper_certificate_recipient_name: values.dbs_paper_certificate_recipient_name,
                    dbs_paper_certificate_address: {
                        unit: values.address_line_2 ? values.address_line_1 : '',
                        address: values.address_line_2 || values.address_line_1,
                        city: values.city,
                        country: values.country,
                        county: values.county,
                        other_province_state: values.other_province_state,
                        postal_code: values.postal_code,
                    },
                };
                this.props.patchInformation(newValues).then(handleNextTrack);
            } else if (err.address_line_1) {
                this.props.updateAddressError(true);
            }
        });
    };

    pages = () => ({
        base: (
            <DBSPaperCriminalRecordCertificateForm
                isFetchingSilent={this.props.isFetchingSilent}
                onboardingType={this.props.onboardingType}
                handleSubmit={this.handleSubmit}
                information={this.props.information}
            />
        ),
    });

    render() {
        return this.pages()[this.props.trackPageLocation]; /* WithNavigation */
    }
}

DBSPaperCriminalRecordCertificate.propTypes = propTypes;
DBSPaperCriminalRecordCertificate.defaultProps = defaultProps;

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(withNavigation(DBSPaperCriminalRecordCertificate))
);
