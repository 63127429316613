import { CheckExecution, CheckExecutionsStatusPriority } from 'types';

export const getUniqueCheckExecutionsSortedByStatusPriority = (checkExecutions: CheckExecution[] = []) => {
    // sorting required to ensure that Check execution status with lower priority number will be included in
    checkExecutions.sort((a, b) => CheckExecutionsStatusPriority[a.status] - CheckExecutionsStatusPriority[b.status]);

    return [...new Set(checkExecutions.map((check) => check.check_name))].map((checkName) =>
        checkExecutions.find((check) => check.check_name === checkName)
    );
};
