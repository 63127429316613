import React, { useState } from 'react';
import styled from 'styled-components/macro';

const Overlay = (props) => {
    const [overlayVisible, setOverlayVisible] = useState(false);

    return (
        <OverlayContainer
            primary={props.primary}
            onMouseEnter={() => setOverlayVisible(true)}
            onMouseLeave={() => setOverlayVisible(false)}
        >
            {overlayVisible && <OverlayLayer>{props.layer}</OverlayLayer>}
            <BaseLayer blur={overlayVisible}>{props.children}</BaseLayer>
        </OverlayContainer>
    );
};

export default Overlay;

const OverlayContainer = styled.div`
    width: fit-content;
    height: 100%;
    position: relative;
    overflow: hidden;
    &:hover {
        ${({ primary, theme }) => primary && `color: ${theme.color.certnGreen700}`};
    }
`;

const OverlayLayer = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 101;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const BaseLayer = styled.div`
    width: 100%;
    height: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    filter: ${(props) => (props.blur ? 'blur(5px)' : 'none')};
`;
