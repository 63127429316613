// Libraries
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchDispute, updateDispute } from 'views/guest/GuestActions';
import { getDispute, getIsFetching } from 'views/guest/GuestSelectors';
import { get } from 'lodash';
import { withRouter } from 'react-router-dom';
import Loader from 'certn-ui/Loader';
import Titlebar from 'certn-ui/Titlebar';
import styled from 'styled-components/macro';
import { Input } from 'antd';
import Image from 'certn-ui/Image';
import Button, { ButtonLine } from 'certn-ui/Button';
import { ErrorAlertAPI, ErrorAlertCustom } from 'certn-ui/ErrorAlert';
import { injectIntl, FormattedMessage } from 'react-intl';

// Images
import ThumbsUp from 'images/svgs/thumbs.svg';

const mapStateToProps = (state) => ({
    dispute: getDispute(state),
    isFetching: getIsFetching(state),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchDispute,
            updateDispute,
        },
        dispatch
    );

const pageStates = {
    LOADING: 'LOADING',
    READY: 'READY',
    ERROR: 'ERROR',
    ERROR_INVALIDID: 'ERROR_INVALIDID',
    ERROR_ALREADYSUBMITTED: 'ERROR_ALREADYSUBMITTED',
    CONFIRM: 'CONFIRM',
    SUCCESS: 'SUCCESS',
};

class Dispute extends Component {
    constructor(props) {
        super(props);
        this.state = { reportSections: [], criminalCharges: [], pageState: pageStates.LOADING };
    }

    componentDidMount = () => {
        const applicantId = get(this.props, ['match', 'params', 'applicant_id']);
        if (!applicantId) {
            this.setState({ pageState: pageStates.ERROR_INVALIDID });
            ErrorAlertCustom(
                this.props.intl.formatMessage({
                    id: '73fb8.Dispute.invalidErrorTitle1',
                    defaultMessage: 'Invalid Dispute',
                }),
                this.props.intl.formatMessage({
                    id: '73fb8.Dispute.invalidErrorMessage1',
                    defaultMessage: 'Application id for dispute is not defined',
                })
            );
            return;
        }

        const reportSections = [];

        const disputeReasons = [
            {
                id: 'INCORRECT_DETAILS',
                name: this.props.intl.formatMessage({
                    id: '73fb8.Dispute.incorrectDetails',
                    defaultMessage: 'The information is wrong',
                }),
                selected: false,
            },
            {
                id: 'INCORRECT_IDENTITY',
                name: this.props.intl.formatMessage({
                    id: '73fb8.Dispute.incorrectIdentity',
                    defaultMessage: 'This is not me',
                }),
                selected: false,
            },
            {
                id: 'IRRELEVANT_ENTRY',
                name: this.props.intl.formatMessage({
                    id: '73fb8.Dispute.irrelevantEntry',
                    defaultMessage: 'This should not matter for my application',
                }),
                selected: false,
            },
        ];
        let isAlreadySubmitted = false;
        this.props.fetchDispute(applicantId).then(
            ({ mode, ...response }) => {
                let criminalCharges = {};

                response &&
                    Object.keys(response).forEach((key) => {
                        if (response[key].dispute) {
                            isAlreadySubmitted = true;
                        }
                        if (key === 'criminal_charges') {
                            const isCriminalChargesSubmitted =
                                response.criminal_charges.filter((charge) => charge.dispute).length > 0;
                            criminalCharges = {
                                selected: isCriminalChargesSubmitted,
                                charges: response.criminal_charges,
                            };
                            isAlreadySubmitted = isCriminalChargesSubmitted;
                        } else {
                            reportSections.push({ ...response[key], name: key, disputeReasons });
                        }
                    });

                this.setState({
                    mode,
                    reportSections,
                    criminalCharges,
                    pageState: isAlreadySubmitted ? pageStates.ERROR_ALREADYSUBMITTED : pageStates.READY,
                });
            },
            (error) => {
                ErrorAlertAPI(error);
                this.setState({ pageState: pageStates.ERROR_INVALIDID });
            }
        );
    };

    selectSection = (sectionId) => {
        if (sectionId === 'criminal_charges') {
            this.setState((prevState) => ({
                criminalCharges: {
                    ...prevState.criminalCharges,
                    selected: !prevState.criminalCharges.selected,
                },
            }));
        } else {
            this.setState((prevState) => ({
                reportSections: prevState.reportSections.map((section) => {
                    if (section.name === sectionId) {
                        return { ...section, dispute: !section.dispute };
                    }
                    return section;
                }),
            }));
        }
    };

    selectDisputeReason = (sectionName, disputeReasonId) => {
        this.setState((prevState) => ({
            reportSections: prevState.reportSections.map((section) => {
                if (section.name === sectionName) {
                    return {
                        ...section,
                        disputeReasons: section.disputeReasons.map((reason) => {
                            if (reason.id === disputeReasonId) {
                                return { ...reason, selected: true };
                            }
                            return { ...reason, selected: false };
                        }),
                    };
                }
                return section;
            }),
        }));
    };

    updateChargeDescription = (chargeId, value) => {
        const charges = get(this.state, ['criminalCharges', 'charges']);

        const newCharges = charges.map((charge) => {
            if (chargeId === charge.id) {
                return { ...charge, dispute_details: value };
            }
            return charge;
        });

        this.setState((prevState) => ({
            criminalCharges: { ...prevState.criminalCharges, charges: newCharges },
        }));
    };

    updateDescription = (sectionName, value) => {
        this.setState((prevState) => ({
            reportSections: prevState.reportSections.map((section) => {
                if (sectionName === section.name) {
                    return { ...section, dispute_details: value };
                }
                return section;
            }),
        }));
    };

    selectCharge = (chargeId, field = 'dispute', value = null) => {
        const newCharges = this.state.criminalCharges.charges.map((charge) => {
            let newValue = !charge.dispute;

            if (value !== null) {
                newValue = value;
            }

            if (charge.id === chargeId) {
                return { ...charge, [field]: newValue };
            }
            return charge;
        });

        this.setState((prevState) => ({
            criminalCharges: {
                ...prevState.criminalCharges,
                charges: newCharges,
            },
        }));
    };

    updateDispute = () => {
        const applicantId = get(this.props, ['match', 'params', 'applicant_id']);
        if (!applicantId)
            ErrorAlertCustom(
                this.props.intl.formatMessage({
                    id: '73fb8.Dispute.invalidErrorTitle2',
                    defaultMessage: 'Invalid Dispute',
                }),
                this.props.intl.formatMessage({
                    id: '73fb8.Dispute.invalidErrorMessage2',
                    defaultMessage: 'Application id for dispute is not defined',
                })
            );

        const finalData = {};

        this.state.reportSections
            .filter((section) => section.dispute)
            .forEach((section) => {
                let disputeReason = '';

                section.disputeReasons.forEach((reason) => {
                    if (reason.selected) {
                        disputeReason = reason.id;
                    }
                });

                const newSection = {
                    id: section.id,
                    dispute: section.dispute,
                    dispute_details: section.dispute_details,
                    dispute_reason: disputeReason,
                };

                finalData[section.name] = newSection;

                return newSection;
            });

        const charges = get(this.state, ['criminalCharges', 'charges']);
        const criminalCharges_selected = get(this.state, ['criminalCharges', 'selected']);

        if (charges && criminalCharges_selected) {
            // if criminal charges is not selected, don't send any criminal_charges
            // required because each criminal charge is not wiped when criminal_charges is deselected
            finalData.criminal_charges = charges.map((charge) => ({
                id: charge.id,
                dispute: charge.dispute,
                dispute_details: charge.dispute_details,
                dispute_reason: charge.disputeReason,
            }));
        }

        this.props
            .updateDispute(applicantId, finalData, this.state.mode)
            .then(() => {
                this.setState({ pageState: pageStates.SUCCESS });
            })
            .catch((error) => {
                ErrorAlertAPI(error);
                this.setState({ pageState: pageStates.ERROR });
            });
    };

    render() {
        const { pageState } = this.state;
        let selectedSections = 0;

        if (pageState === pageStates.LOADING || this.props.isFetching) {
            return (
                <LoaderWrapper>
                    <Loader />
                </LoaderWrapper>
            );
        }

        if (pageState === pageStates.CONFIRM) {
            return (
                <>
                    <Titlebar
                        size="xlrg"
                        title={this.props.intl.formatMessage({
                            id: '73fb8.Dispute.confirmTitle',
                            defaultMessage: 'Confirm Dispute Submission?',
                        })}
                        subtitle={this.props.intl.formatMessage({
                            id: '73fb8.Dispute.confirmMessage',
                            defaultMessage:
                                'Submitting a dispute is final. You will not be able to edit the information once submitted.',
                        })}
                        center
                    />
                    <ButtonLine>
                        <CancelButton onClick={() => this.setState({ pageState: pageStates.READY })}>
                            <FormattedMessage id="common.cancel" defaultMessage="Cancel" />
                        </CancelButton>
                        <SubmitButton onClick={() => this.updateDispute()}>
                            <FormattedMessage id="73fb8.Dispute.confirmAndSubmit" defaultMessage="Confirm & Submit" />
                        </SubmitButton>
                    </ButtonLine>
                </>
            );
        }

        if (pageState === pageStates.SUCCESS) {
            return (
                <>
                    <Titlebar
                        size="xlrg"
                        title={this.props.intl.formatMessage({
                            id: '73fb8.Dispute.successTitle',
                            defaultMessage: 'Dispute Submitted',
                        })}
                        subtitle={this.props.intl.formatMessage({
                            id: '73fb8.Dispute.successMessage',
                            defaultMessage:
                                'Thank you for submitting your dispute. The company will be notified of the dispute, and our team will review your details and re-process your results.',
                        })}
                        center
                    />
                    <Image src={ThumbsUp} size="xxlrg" />
                </>
            );
        }

        if (pageState === pageStates.ERROR_INVALIDID) {
            return (
                <Titlebar
                    size="xlrg"
                    title={this.props.intl.formatMessage({
                        id: '73fb8.Dispute.errorInvalidTitle',
                        defaultMessage: 'Uh Oh',
                    })}
                    subtitle={this.props.intl.formatMessage({
                        id: '73fb8.Dispute.errorInvalidMessage',
                        defaultMessage: 'There was a problem retrieving your application for dispute.',
                    })}
                    center
                />
            );
        }

        if (pageState === pageStates.ERROR_ALREADYSUBMITTED) {
            return (
                <Titlebar
                    size="xlrg"
                    title={this.props.intl.formatMessage({
                        id: '73fb8.Dispute.errorAlreadySubmittedTitle',
                        defaultMessage: 'Dispute Submitted',
                    })}
                    subtitle={this.props.intl.formatMessage({
                        id: '73fb8.Dispute.errorAlreadySubmittedMessage',
                        defaultMessage:
                            'Looks like your application was already disputed. The company has been notified, and our team is re-processing your results.',
                    })}
                    center
                />
            );
        }

        if (pageState === pageStates.READY) {
            if (this.state.reportSections.length === 0) {
                return (
                    <Titlebar
                        size="xlrg"
                        title={this.props.intl.formatMessage({
                            id: '73fb8.Dispute.readyTitle',
                            defaultMessage: 'Register A Dispute',
                        })}
                        subtitle={this.props.intl.formatMessage({
                            id: '73fb8.Dispute.readyMessage',
                            defaultMessage: 'Your application is either processing or has nothing to dispute.',
                        })}
                        center
                    />
                );
            }

            return (
                <>
                    <Titlebar
                        size="xlrg"
                        title={this.props.intl.formatMessage({
                            id: '73fb8.Dispute.registerTitle',
                            defaultMessage: 'Register A Dispute',
                        })}
                        subtitle={this.props.intl.formatMessage({
                            id: '73fb8.Dispute.registerMessage',
                            defaultMessage:
                                'If you wish to dispute a specific section or sections of your report, please select them below.',
                        })}
                        center
                    />
                    <Container>
                        <SectionsWrapper>
                            {get(this.state, ['criminalCharges', 'charges', 'length']) > 0 && (
                                <SectionButton
                                    selected={this.state.criminalCharges.selected}
                                    onClick={() => this.selectSection('criminal_charges')}
                                >
                                    <FormattedMessage
                                        id="73fb8.Dispute.criminalCharges"
                                        defaultMessage="Criminal Charges"
                                    />
                                </SectionButton>
                            )}
                        </SectionsWrapper>

                        {this.state.reportSections.map((section) => {
                            let disputeReasonSelected = false;

                            if (section.dispute) {
                                selectedSections += 1;
                                return (
                                    <Description>
                                        <DescriptionTitleRow>
                                            <DescriptionSubtitle>
                                                {disputeReasonSelected
                                                    ? this.props.intl.formatMessage({
                                                          id: '73fb8.Dispute.selectAllItems',
                                                          defaultMessage: 'Select all items you wish to dispute',
                                                      })
                                                    : this.props.intl.formatMessage({
                                                          id: '73fb8.Dispute.Reason',
                                                          defaultMessage: 'Dispute Description',
                                                      })}
                                            </DescriptionSubtitle>
                                        </DescriptionTitleRow>

                                        <SectionsWrapper>
                                            {section.disputeReasons.map((reason) => {
                                                if (reason.selected) {
                                                    disputeReasonSelected = true;
                                                }

                                                return (
                                                    <DisputeReasonButton
                                                        selected={reason.selected}
                                                        onClick={() =>
                                                            this.selectDisputeReason(section.name, reason.id)
                                                        }
                                                    >
                                                        {reason.name}
                                                    </DisputeReasonButton>
                                                );
                                            })}
                                        </SectionsWrapper>

                                        {disputeReasonSelected && (
                                            <Input.TextArea
                                                placeholder={this.props.intl.formatMessage({
                                                    id: '73fb8.Dispute.reasonSelectedPlaceholder',
                                                    defaultMessage:
                                                        'Please provide an explanation for what detail you are disputing and why you are disputing it. Please provide any evidence you have to validate your claim.',
                                                })}
                                                autosize={{ minRows: 3, maxRows: 5 }}
                                                style={{
                                                    padding: '20px',
                                                    width: '100%',
                                                    marginTop: '20px',
                                                    marginRight: '10px',
                                                    marginLeft: '10px',
                                                }}
                                                onChange={(e) => this.updateDescription(section.name, e.target.value)}
                                                value={section.dispute_details}
                                            />
                                        )}
                                    </Description>
                                );
                            }
                        })}

                        {this.state.criminalCharges.selected && (
                            <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '20px' }}>
                                <DescriptionTitleRow>
                                    <DescriptionTitle>
                                        <FormattedMessage
                                            id="73fb8.Dispute.chargesToDisputeTitle"
                                            defaultMessage="Select Charges To Dispute"
                                        />
                                    </DescriptionTitle>
                                    <DescriptionSubtitle>
                                        <FormattedMessage
                                            id="73fb8.Dispute.chargesToDisputeSubtitle"
                                            defaultMessage="The ID for each charge should match the ID on the report."
                                        />
                                    </DescriptionSubtitle>
                                </DescriptionTitleRow>
                                {this.state.criminalCharges.charges.map((charge, i) => (
                                    <DisputeReasonButton
                                        style={{ marginBottom: '10px' }}
                                        selected={charge.dispute}
                                        onClick={() => this.selectCharge(charge.id)}
                                    >
                                        {`${
                                            charge.offense_description ||
                                            `${this.props.intl.formatMessage({
                                                id: '73fb8.Dispute.charge',
                                                defaultMessage: 'CHARGE',
                                            })} ${i + 1}`
                                        } (${charge.id.slice(0, 6).toUpperCase()})`}
                                    </DisputeReasonButton>
                                ))}

                                <div style={{ width: '100%' }}>
                                    {this.state.criminalCharges.charges.map((charge, i) => {
                                        if (charge.dispute) {
                                            return (
                                                <Description>
                                                    <DescriptionTitleRow>
                                                        <DescriptionTitle>
                                                            {`${
                                                                charge.offense_description ||
                                                                `${this.props.intl.formatMessage({
                                                                    id: '73fb8.Dispute.charge',
                                                                    defaultMessage: 'CHARGE',
                                                                })} ${i + 1}`
                                                            } (${charge.id.slice(0, 6).toUpperCase()})`}
                                                        </DescriptionTitle>
                                                        <DescriptionSubtitle>
                                                            {!charge.disputeReason
                                                                ? this.props.intl.formatMessage({
                                                                      id: '73fb8.Dispute.selectDisputeReason',
                                                                      defaultMessage: 'Select dispute reason',
                                                                  })
                                                                : this.props.intl.formatMessage({
                                                                      id: '73fb8.Dispute.disputeReasonDescription',
                                                                      defaultMessage: 'Dispute Description',
                                                                  })}
                                                        </DescriptionSubtitle>
                                                    </DescriptionTitleRow>

                                                    <SectionsWrapper>
                                                        <DisputeReasonButton
                                                            selected={charge.disputeReason === 'INCORRECT_DETAILS'}
                                                            onClick={() =>
                                                                this.selectCharge(
                                                                    charge.id,
                                                                    'disputeReason',
                                                                    'INCORRECT_DETAILS'
                                                                )
                                                            }
                                                        >
                                                            <FormattedMessage
                                                                id="73fb8.Dispute.informationIsWrong"
                                                                defaultMessage="The information is wrong"
                                                            />
                                                        </DisputeReasonButton>
                                                        <DisputeReasonButton
                                                            selected={charge.disputeReason === 'INCORRECT_IDENTITY'}
                                                            onClick={() =>
                                                                this.selectCharge(
                                                                    charge.id,
                                                                    'disputeReason',
                                                                    'INCORRECT_IDENTITY'
                                                                )
                                                            }
                                                        >
                                                            <FormattedMessage
                                                                id="73fb8.Dispute.thisIsNotMe"
                                                                defaultMessage="This is not me"
                                                            />
                                                        </DisputeReasonButton>
                                                        <DisputeReasonButton
                                                            selected={charge.disputeReason === 'IRRELEVANT_ENTRY'}
                                                            onClick={() =>
                                                                this.selectCharge(
                                                                    charge.id,
                                                                    'disputeReason',
                                                                    'IRRELEVANT_ENTRY'
                                                                )
                                                            }
                                                        >
                                                            <FormattedMessage
                                                                id="73fb8.Dispute.shouldNotMatter"
                                                                defaultMessage="This should not matter for my application"
                                                            />
                                                        </DisputeReasonButton>
                                                    </SectionsWrapper>

                                                    {charge.disputeReason && (
                                                        <Input.TextArea
                                                            placeholder={this.props.intl.formatMessage({
                                                                id: '73fb8.Dispute.disputeExplanationPlaceholder',
                                                                defaultMessage:
                                                                    'Please provide an explanation for what detail you are disputing and why you are disputing it. Please provide any evidence you have to validate your claim.',
                                                            })}
                                                            autosize={{ minRows: 3, maxRows: 5 }}
                                                            style={{
                                                                padding: '20px',
                                                                width: '100%',
                                                                marginTop: '20px',
                                                                marginRight: '10px',
                                                                marginLeft: '10px',
                                                            }}
                                                            onChange={(e) =>
                                                                this.updateChargeDescription(charge.id, e.target.value)
                                                            }
                                                            value={charge.dispute_details}
                                                        />
                                                    )}
                                                </Description>
                                            );
                                        }
                                    })}
                                </div>
                            </div>
                        )}
                        <ButtonLine>
                            <SubmitButton
                                type="primary"
                                onClick={() => this.setState({ pageState: pageStates.CONFIRM })}
                                disabled={
                                    selectedSections === 0 &&
                                    (!this.state.criminalCharges.selected ||
                                        this.state.criminalCharges.charges.filter((charge) => charge.dispute).length ===
                                            0)
                                }
                            >
                                <FormattedMessage id="73fb8.Dispute.submitDispute" defaultMessage="Submit Dispute" />
                            </SubmitButton>
                        </ButtonLine>
                    </Container>
                </>
            );
        }

        return (
            <Titlebar
                size="xlrg"
                title={this.props.intl.formatMessage({
                    id: '73fb8.Dispute.submitDisputeErrorTitle',
                    defaultMessage: 'Uh Oh',
                })}
                subtitle={this.props.intl.formatMessage({
                    id: '73fb8.Dispute.submitDisputeErrorMessage',
                    defaultMessage: 'Something unexpected happened.',
                })}
                center
            />
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(Dispute)));

const LoaderWrapper = styled.div`
    padding: 20px;
    display: flex;
    justify-content: center;
    width: 100%;
`;

const SectionButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% / 3 - 20px);
    padding: 20px;
    margin: 10px;
    background: ${(props) => props.theme.color.certnGray100};
    color: ${(props) => (props.selected ? props.theme.color.certnGreen700 : props.theme.color.certnDarkGrey2)};
    border-width: 1px;
    border-style: solid;
    border-color: ${(props) => (props.selected ? props.theme.color.certnGreen700 : props.theme.color.certnGray200)};
    border-radius: 4px;
    cursor: pointer;
    font-size: 18px;
    text-align: center;
    transition: 0.4s;
    box-sizing: border-box;
    &:hover {
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    }
    @media (max-width: ${(props) => props.theme.width.tablet}) {
        width: 100%;
        margin: 0px;
        margin-bottom: 10px;
    }
`;

const DisputeReasonButton = styled(SectionButton)`
    padding: 15px;
    margin: 0px 10px;
    font-size: 14px;
    @media (max-width: ${(props) => props.theme.width.tablet}) {
        flex-direction: column;
        width: 100%;
        margin-right: 0px;
        margin-bottom: 8px;
    }
`;

const SubmitButton = styled(Button)`
    background-color: ${(props) => (props.disabled ? 'white' : props.theme.color.certnGreen700)} !important;
    border-color: ${(props) =>
        props.disabled ? props.theme.color.certnGray400 : props.theme.color.certnGreen700} !important;
    color: ${(props) => (props.disabled ? props.theme.color.certnGray400 : 'white')} !important;
    margin-bottom: 20px;
    margin-top: 40px;
    height: 50px;
    width: 200px;
    &:hover {
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    }
`;

const CancelButton = styled(SubmitButton)`
    background-color: white !important;
    border-color: ${(props) => props.theme.color.certnGray400} !important;
    color: ${(props) => props.theme.color.certnGray600} !important;
`;

const Container = styled.div`
    width: 950px;
    margin: 0 auto;
    box-sizing: border-box;
    margin-bottom: 20px;
    @media (max-width: ${(props) => props.theme.width.tablet}) {
        width: 100%;
        margin: 0;
    }
`;

const SectionsWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    @media (max-width: ${(props) => props.theme.width.tablet}) {
        flex-direction: column;
    }
`;

const Description = styled.div`
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    border-radius: 4px;
    margin-top: 20px;
    flex-direction: column;
    @media (max-width: ${(props) => props.theme.width.tablet}) {
        width: 100%;
    }
`;

const DescriptionTitle = styled.div`
    font-size: 20px;
    font-weight: 600;
`;

const DescriptionSubtitle = styled.div`
    font-size: 16px;
    font-weight: 600;
    opacity: 0.5;
    margin-left: 15px;
`;

const DescriptionTitleRow = styled.div`
    display: flex;
    margin-bottom: 10px;
    margin-right: 10px;
    margin-left: 10px;
    align-items: flex-end;
    @media (max-width: ${(props) => props.theme.width.tablet}) {
        flex-direction: column;
        align-items: flex-start;
    }
`;
