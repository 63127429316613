import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import config from '../utils/config';
import styles from '../styles/styles';

const StyledNavLogo = styled.img`
    max-width: 150px;
    max-height: 35px;
    margin-right: 20px;
    box-sizing: border-box;
    @media (max-width: ${(props) => props.theme.width.med}) {
        max-width: 100px;
    }
    cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};
`;

const StyledLink = styled.a`
    position: absolute;
    left: 55px;
    bottom: 1px;
    display: block;
    font-size: 13px;
    font-weight: bold;
    cursor: pointer;
`;

/**
 * Displays the API Server name link when running on localhost, or when in development mode.
 */
const ServerDisplay = () => {
    const { env, url, isDevelopment, isLocal } = config;
    const { hostname } = window.location;
    const display = isDevelopment || hostname === 'localhost';
    const style = { color: styles.serverColors[env] || styles.serverColors.unknown };
    return display ? (
        <StyledLink style={style} title="Open the admin page" href={`${url}/admin/`} target={`DjangoAdmin-${env}`}>
            {isLocal ? 'localhost:8000' : env}
        </StyledLink>
    ) : null;
};

export const NavLogo = (props) => (
    <>
        <StyledNavLogo {...props} />
        <ServerDisplay />
    </>
);

NavLogo.propTypes = {
    alt: PropTypes.string.isRequired,
    src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    onClick: PropTypes.func,
    clickable: PropTypes.bool,
};

NavLogo.defaultProps = {
    title: undefined,
    onClick: undefined,
    clickable: false,
};
