// Libraries
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

// Components
import { AppearRight } from 'certn-ui/Animate';
import Title from 'certn-ui/Title';
import Trophy from 'images/svgs/svg-components/onboarding/Trophy';

const Congrats = () => (
    <AppearRight>
        <Title>
            <FormattedMessage
                id="welcome.SignUp.congratsTitle"
                defaultMessage="You've completed the sign up process. Before we can submit your completed account creation, we need you to verify your email address."
            />
        </Title>
        <Trophy />
    </AppearRight>
);

export default injectIntl(Congrats);
