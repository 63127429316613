import React from 'react';
import { Modal } from 'antd';
import { FormattedMessage } from 'react-intl';
import { Form as AntdForm } from '@ant-design/compatible';
import { TeamMemberForm } from '../views/manager/views/settings/components';

const EditUserModalForm = ({
    isEditingTeamMember,
    setIsEditingTeamMember,
    handleSubmit,
    teamMember,
    form,
    isCreatingNewUser,
    setIsCreatingNewUser,
}) => (
    <AntdForm>
        <Modal
            title={
                isCreatingNewUser ? (
                    <FormattedMessage id="editUserModal.CreateTeamMember" defaultMessage="Create Team Member" />
                ) : (
                    <FormattedMessage id="editUserModal.EditTeamMember" defaultMessage="Edit Team Member" />
                )
            }
            visible={isEditingTeamMember || isCreatingNewUser}
            onOk={() => handleSubmit(isCreatingNewUser)}
            onCancel={() => {
                setIsEditingTeamMember(undefined);
                setIsCreatingNewUser(false);
            }}
            okText={<FormattedMessage id="common.submit" defaultMessage="Submit" />}
            cancelText={<FormattedMessage id="common.cancel" defaultMessage="Cancel" />}
            destroyOnClose
            style={{ minWidth: '80%', paddingBottom: '80px' }}
        >
            <TeamMemberForm partnerApiUser form={form} editingTeamMember={teamMember} />
        </Modal>
    </AntdForm>
);

export default EditUserModalForm;
