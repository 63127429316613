// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form } from 'antd';

// Components
import { AppearRight } from 'certn-ui/Animate';
import Title from 'certn-ui/Title';
import FormLayout from 'certn-form/FormLayout';
import { TrackNavigation } from 'views/welcome/components';
import DateInput from 'certn-form/DateInput';

const MoveInDateForm = ({ handleSubmit, defaultMoveInDate }) => {
    const intl = useIntl();
    const [form] = Form.useForm();

    return (
        <AppearRight>
            <Form layout="horizontal" onFinish={handleSubmit} form={form}>
                <Title>
                    <FormattedMessage
                        id="welcome.MoveInDate.moveInDateTitle"
                        defaultMessage="If you have a preferred move in date, please enter it here."
                    />
                </Title>
                <FormLayout size="16" offset="4">
                    <Form.Item name="desired_move_in_date" initialValue={defaultMoveInDate} validateTrigger="onChange">
                        <DateInput
                            size="large"
                            disablePastDates
                            placeholder={intl.formatMessage({
                                id: 'welcome.MoveInDate.desiredMoveInDate',
                                defaultMessage: 'Desired Move-in Date',
                            })}
                            addonBeforeUsePlaceholder
                        />
                    </Form.Item>
                </FormLayout>
                <TrackNavigation />
            </Form>
        </AppearRight>
    );
};

MoveInDateForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    defaultMoveInDate: PropTypes.string,
};
MoveInDateForm.defaultProps = {
    defaultMoveInDate: undefined,
};

export default MoveInDateForm;
