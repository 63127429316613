// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { FormattedMessage } from 'react-intl';

// Components
import EmployerReferenceListItem from './EmployerReferenceListItem';

const Container = styled.div`
    width: 100%;
    max-width: 600px;
    margin: 20px auto;
    padding: 10px;
    box-sizing: border-box;
`;

const requiredListItems = ({ onAdd, minimum, numReferences, isYears }) => {
    // Only show required for INDIVIDUALLY
    if (isYears) {
        return [];
    }

    const listItems = [];
    for (let i = 0; i < minimum; i += 1) {
        // Only show required slots if more references are required
        if (i >= numReferences) {
            listItems.push(
                <EmployerReferenceListItem
                    key={`add-reference-${i}`}
                    onAdd={onAdd}
                    index={i}
                    description={
                        <FormattedMessage
                            id="welcome.EmployerReference.addReferenceDescription"
                            defaultMessage="Add your employer reference"
                        />
                    }
                />
            );
        }
    }

    return listItems;
};

const additionalListItem = ({ onAdd, minimum, maximum, numReferences, isYears }) => {
    // Always show for YEARS, otherwise only show if min has been reach but max has not
    if (!isYears && (numReferences < minimum || numReferences >= maximum)) {
        return [];
    }

    return (
        <EmployerReferenceListItem
            key="add-reference-additional"
            onAdd={onAdd}
            description={
                isYears ? (
                    <FormattedMessage
                        id="welcome.EmployerReference.addReferenceDescription"
                        defaultMessage="Add your employer reference"
                    />
                ) : (
                    <FormattedMessage
                        id="welcome.EmployerReference.addReferenceDescriptionAdditional"
                        defaultMessage="Add additional employer reference (optional)"
                    />
                )
            }
        />
    );
};

const EmployerReferenceList = ({ onAdd, onDelete, onEdit, references, maximum, minimum, isYears }) => (
    <Container>
        {references.map((reference) => (
            <EmployerReferenceListItem
                key={`reference-${reference.id}`}
                reference={reference}
                onEdit={onEdit}
                onDelete={onDelete}
            />
        ))}
        {requiredListItems({ onAdd, minimum, numReferences: references.length, isYears })}
        {additionalListItem({
            onAdd,
            minimum,
            maximum,
            numReferences: references.length,
            isYears,
        })}
    </Container>
);

EmployerReferenceList.propTypes = {
    onAdd: PropTypes.func,
    references: PropTypes.arrayOf(PropTypes.object),
    minimum: PropTypes.number,
    maximum: PropTypes.number,
    isYears: PropTypes.bool,
};

EmployerReferenceList.defaultProps = {
    onAdd: () => {},
    references: [],
    minimum: 1,
    maximum: 1,
    isYears: false,
};

export default EmployerReferenceList;
