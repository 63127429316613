import React from 'react';
import styles from 'styles/styles';

const SvgComponent = ({ scale }) => (
    <svg width={67} height={63} style={{ transform: `scale3d(${scale}, ${scale}, ${scale})` }}>
        <title>dog_pet_allowed_friendly</title>
        <g stroke={styles.color.certnGreen700} strokeWidth={1.525} fill="none" fillRule="evenodd">
            <path d="M1.866 14.536S-.426 20.891 4.74 26.692M32.649 17.417L20.899 1.324" strokeLinecap="round" />
            <path d="M50.021 32.812s-3.744-1.338-6.232-5.087" />
            <path
                d="M44.77 11.101s6.039 12.935-1.565 16.555c0 0-11.214 2.714-3.408-13.612 1.8-3.762 7.39-3.175 7.39-3.175 7 .25 9 3.855 9 3.855l9.457.697c-1.75 8.75-1.373 9.28-3.873 10.78S51.234 28.92 51.234 28.92s-1.387 7.982-2.65 10.033c-2.152 3.484-6.9 6.416-6.9 6.416l.498 13.498c-.098 3.176-1.652 2.75-2.25 2.75-.598 0-2.676-.541-2.676-.541l-2.822-14.207c-5.268 3.834-11.601 2.168-14.899-.5-2.457-1.986-7.851-2.75-7.851-2.75l-3.399 14.75c-.7 2.959-1.495 2.75-2.101 2.75-2.183 0-3.25-1.25-3.25-1.25v-26c0-7.333 2.271-7.334 2.271-7.334 9.25 2.5 22.098 1.25 27.943.469"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
    </svg>
);

export default SvgComponent;
