// Libraries
import * as Sentry from '@sentry/browser';
import Cookies from 'js-cookie';
import { get, isEmpty } from 'lodash';

// Components
import { ErrorAlertAPI } from 'certn-ui/ErrorAlert';

// Modules & Utils
import Auth from 'modules/Auth';
import { simpleStringifyJSON } from 'modules/Format';
import { createNamespacedFetchActionTypes } from 'utils/autoredux';
import { deleteRequest, getRequest, patchRequest, postRequest, putRequest } from 'utils/http';

// Actions & Selectors
import { SET_LOCALE } from 'base/BaseActions';
import { getLanguage } from 'base/BaseSelectors';
import { NAMESPACE as PARENT_NAMESPACE, STATEPATH as PARENT_STATEPATH } from './WelcomeConstants';
import { getEmail, getOnboardingId, getOnboardingType, getOwnerId, getApplicant } from './WelcomeSelectors';

// Constants
export const NAMESPACE = `${PARENT_NAMESPACE}`;
export const STATEPATH = `${PARENT_STATEPATH}`;

// Action Types
export const GET_LISTING_REQ = `${NAMESPACE}GET_LISTING_REQ`;
export const GET_LISTING_RES = `${NAMESPACE}GET_LISTING_RES`;
export const GET_LISTING_ERR = `${NAMESPACE}GET_LISTING_ERR`;
export const GET_ONBOARDING_REQ = `${NAMESPACE}GET_ONBOARDING_REQ`;
export const GET_ONBOARDING_RES = `${NAMESPACE}GET_ONBOARDING_RES`;
export const GET_ONBOARDING_ERR = `${NAMESPACE}GET_ONBOARDING_ERR`;
export const POST_ONBOARDING_REQ = `${NAMESPACE}POST_ONBOARDING_REQ`;
export const POST_ONBOARDING_RES = `${NAMESPACE}POST_ONBOARDING_RES`;
export const POST_ONBOARDING_ERR = `${NAMESPACE}POST_ONBOARDING_ERR`;
export const PATCH_ONBOARDING_REQ = `${NAMESPACE}PATCH_ONBOARDING_REQ`;
export const PATCH_ONBOARDING_RES = `${NAMESPACE}PATCH_ONBOARDING_RES`;
export const PATCH_ONBOARDING_ERR = `${NAMESPACE}PATCH_ONBOARDING_ERR`;
export const PUT_VERIFY_ONBOARDING_REQ = `${NAMESPACE}PUT_VERIFY_ONBOARDING_REQ`;
export const PUT_VERIFY_ONBOARDING_RES = `${NAMESPACE}PUT_VERIFY_ONBOARDING_RES`;
export const PUT_VERIFY_ONBOARDING_ERR = `${NAMESPACE}PUT_VERIFY_ONBOARDING_ERR`;
export const POST_RESEND_VERIFY_REQ = `${NAMESPACE}POST_RESEND_VERIFY_REQ`;
export const POST_RESEND_VERIFY_RES = `${NAMESPACE}POST_RESEND_VERIFY_RES`;
export const POST_RESEND_VERIFY_ERR = `${NAMESPACE}POST_RESEND_VERIFY_ERR`;
export const PUT_SUBMIT_REQ = `${NAMESPACE}PUT_SUBMIT_REQ`;
export const PUT_SUBMIT_RES = `${NAMESPACE}PUT_SUBMIT_RES`;
export const PUT_SUBMIT_ERR = `${NAMESPACE}PUT_SUBMIT_ERR`;
export const GET_VALIDATE_REQ = `${NAMESPACE}GET_VALIDATE_REQ`;
export const GET_VALIDATE_ERR = `${NAMESPACE}GET_VALIDATE_ERR`;
export const PATCH_INFORMATION_REQ = `${NAMESPACE}PATCH_INFORMATION_REQ`;
export const PATCH_INFORMATION_RES = `${NAMESPACE}PATCH_INFORMATION_RES`;
export const PATCH_INFORMATION_ERR = `${NAMESPACE}PATCH_INFORMATION_ERR`;
export const PATCH_POSITION_PROPERTY_REQ = `${NAMESPACE}PATCH_POSITION_PROPERTY_REQ`;
export const PATCH_POSITION_PROPERTY_RES = `${NAMESPACE}PATCH_POSITION_PROPERTY_RES`;
export const PATCH_POSITION_PROPERTY_ERR = `${NAMESPACE}PATCH_POSITION_PROPERTY_ERR`;
export const POST_POSITION_PROPERTY_REQ = `${NAMESPACE}POST_POSITION_PROPERTY_REQ`;
export const POST_POSITION_PROPERTY_RES = `${NAMESPACE}POST_POSITION_PROPERTY_RES`;
export const POST_POSITION_PROPERTY_ERR = `${NAMESPACE}POST_POSITION_PROPERTY_ERR`;
export const GET_POSITION_PROPERTY_REQ = `${NAMESPACE}POST_POSITION_PROPERTY_REQ`;
export const GET_POSITION_PROPERTY_RES = `${NAMESPACE}POST_POSITION_PROPERTY_RES`;
export const GET_POSITION_PROPERTY_ERR = `${NAMESPACE}POST_POSITION_PROPERTY_ERR`;
export const PATCH_LANGUAGE_REQ = `${NAMESPACE}PATCH_LANGUAGE_REQ`;
export const PATCH_LANGUAGE_RES = `${NAMESPACE}PATCH_LANGUAGE_RES`;
export const PATCH_LANGUAGE_ERR = `${NAMESPACE}PATCH_LANGUAGE_ERR`;
export const RESET_APPLICATION = `${NAMESPACE}RESET_APPLICATION`;
export const POST_S3_REQ = `${NAMESPACE}POST_S3_REQ`;
export const POST_S3_RES = `${NAMESPACE}POST_S3_RES`;
export const POST_S3_ERR = `${NAMESPACE}POST_S3_ERR`;
export const POST_NETVERIFY_REQ = `${NAMESPACE}POST_NETVERIFY_REQ`;
export const POST_NETVERIFY_RES = `${NAMESPACE}POST_NETVERIFY_RES`;
export const POST_NETVERIFY_ERR = `${NAMESPACE}POST_NETVERIFY_ERR`;
export const DELETE_DOCUMENT_REQ = `${NAMESPACE}DELETE_DOCUMENT_REQ`;
export const DELETE_DOCUMENT_RES = `${NAMESPACE}DELETE_DOCUMENT_RES`;
export const DELETE_DOCUMENT_ERR = `${NAMESPACE}DELETE_DOCUMENT_ERR`;
export const GET_SPECIALIZATIONS_REQ = `${NAMESPACE}GET_SPECIALIZATIONS_REQ`;
export const GET_SPECIALIZATIONS_RES = `${NAMESPACE}GET_SPECIALIZATIONS_RES`;
export const GET_SPECIALIZATIONS_ERR = `${NAMESPACE}GET_SPECIALIZATIONS_ERR`;
export const GET_SKILLS_REQ = `${NAMESPACE}GET_SKILLS_REQ`;
export const GET_SKILLS_RES = `${NAMESPACE}GET_SKILLS_RES`;
export const GET_SKILLS_ERR = `${NAMESPACE}GET_SKILLS_ERR`;
export const GET_DEGREES_REQ = `${NAMESPACE}GET_DEGREES_REQ`;
export const GET_DEGREES_RES = `${NAMESPACE}GET_DEGREES_RES`;
export const GET_DEGREES_ERR = `${NAMESPACE}GET_DEGREES_ERR`;
export const GET_EMPLOYER_REFERENCE_SKIP_REASONS_REQ = `${NAMESPACE}GET_EMPLOYER_REFERENCE_SKIP_REASONS_REQ`;
export const GET_EMPLOYER_REFERENCE_SKIP_REASONS_RES = `${NAMESPACE}GET_EMPLOYER_REFERENCE_SKIP_REASONS_RES`;
export const GET_EMPLOYER_REFERENCE_SKIP_REASONS_ERR = `${NAMESPACE}GET_EMPLOYER_REFERENCE_SKIP_REASONS_ERR`;
export const CONFIRM_UPLOAD_SUCCESSFUL_REQ = `${NAMESPACE}CONFIRM_UPLOAD_SUCCESSFUL_REQ`;
export const CONFIRM_UPLOAD_SUCCESSFUL_RES = `${NAMESPACE}CONFIRM_UPLOAD_SUCCESSFUL_RES`;
export const CONFIRM_UPLOAD_SUCCESSFUL_ERR = `${NAMESPACE}CONFIRM_UPLOAD_SUCCESSFUL_ERR`;
export const PATCH_NETVERIFY_BYPASS_REQ = `${NAMESPACE}PATCH_NETVERIFY_BYPASS_REQ`;
export const PATCH_NETVERIFY_BYPASS_RES = `${NAMESPACE}PATCH_NETVERIFY_BYPASS_RES`;
export const PATCH_NETVERIFY_BYPASS_ERR = `${NAMESPACE}PATCH_NETVERIFY_BYPASS_ERR`;
export const SET_TRACK_PAGE_ORDER = `${NAMESPACE}SET_TRACK_PAGE_ORDER`;
export const GET_CONVICTION_OFFENSES_REQ = `${NAMESPACE}GET_CONVICTION_OFFENSES_REQ`;
export const GET_CONVICTION_OFFENSES_RES = `${NAMESPACE}GET_CONVICTION_OFFENSES_RES`;
export const GET_CONVICTION_OFFENSES_ERR = `${NAMESPACE}GET_CONVICTION_OFFENSES_ERR`;
export const POST_WHITE_LABEL_NETVERIFY_REQ = `${NAMESPACE}POST_WHITE_LABEL_NETVERIFY_REQ`;
export const POST_WHITE_LABEL_NETVERIFY_RES = `${NAMESPACE}POST_WHITE_LABEL_NETVERIFY_RES`;
export const POST_WHITE_LABEL_NETVERIFY_ERR = `${NAMESPACE}POST_WHITE_LABEL_NETVERIFY_ERR`;
export const POST_AUTOFAIL_NETVERIFY_REQ = `${NAMESPACE}POST_AUTOFAIL_NETVERIFY_REQ`;
export const POST_AUTOFAIL_NETVERIFY_RES = `${NAMESPACE}POST_AUTOFAIL_NETVERIFY_RES`;
export const POST_AUTOFAIL_NETVERIFY_ERR = `${NAMESPACE}POST_AUTOFAIL_NETVERIFY_ERR`;
export const POST_SIGN_UP_REQ = `${NAMESPACE}POST_SIGN_UP_REQ`;
export const POST_SIGN_UP_RES = `${NAMESPACE}POST_SIGN_UP_RES`;
export const POST_SIGN_UP_ERR = `${NAMESPACE}POST_SIGN_UP_ERR`;
export const POST_CHECK_EMAIL_EXIST_REQ = `${NAMESPACE}POST_CHECK_EMAIL_EXIST_REQ`;
export const POST_CHECK_EMAIL_EXIST_RES = `${NAMESPACE}POST_CHECK_EMAIL_EXIST_RES`;
export const POST_CHECK_EMAIL_EXIST_ERR = `${NAMESPACE}POST_CHECK_EMAIL_EXIST_ERR`;
export const TOGGLE_NAVIGATION_BARS = `${NAMESPACE}TOGGLE_NAVIGATION_BARS`;
export const SET_TRACK_PAGE_LOCATION = `${NAMESPACE}SET_TRACK_PAGE_LOCATION`;
export const PATCH_APPLICANT = `${NAMESPACE}PATCH_APPLICANT`;
export const PATCH_ENHANCED_IDENTITY_VERIFICATION = `${NAMESPACE}PATCH_ENHANCED_IDENTITY_VERIFICATION`;
export const POST_SOCIAL_MEDIA_DETAILS_REQ = `${NAMESPACE}POST_SOCIAL_MEDIA_DETAILS_REQ`;
export const POST_SOCIAL_MEDIA_DETAILS_RES = `${NAMESPACE}POST_SOCIAL_MEDIA_DETAILS_RES`;
export const POST_SOCIAL_MEDIA_DETAILS_ERR = `${NAMESPACE}POST_SOCIAL_MEDIA_DETAILS_ERR`;
export const GET_SOCIAL_MEDIA_DETAILS_REQ = `${NAMESPACE}POST_SOCIAL_MEDIA_DETAILS_REQ`;
export const GET_SOCIAL_MEDIA_DETAILS_RES = `${NAMESPACE}POST_SOCIAL_MEDIA_DETAILS_RES`;
export const GET_SOCIAL_MEDIA_DETAILS_ERR = `${NAMESPACE}POST_SOCIAL_MEDIA_DETAILS_ERR`;

// Actions using new react loader
export const POST_STRIPE_CUSTOMER_ID = createNamespacedFetchActionTypes(NAMESPACE)('POST_STRIPE_CUSTOMER_ID');
export const GET_STRIPE_PAYMENT_BREAKDOWN = createNamespacedFetchActionTypes(NAMESPACE)('GET_STRIPE_PAYMENT_BREAKDOWN');
export const GET_SUPPORTED_ID_TYPES = createNamespacedFetchActionTypes(NAMESPACE)('GET_SUPPORTED_ID_TYPES');
export const PATCH_IDENTITY_VERIFY_SESSION_START = createNamespacedFetchActionTypes(NAMESPACE)(
    'PATCH_IDENTITY_VERIFY_SESSION_START'
);
export const PATCH_ONEID_BYPASS = createNamespacedFetchActionTypes(NAMESPACE)('PATCH_ONEID_BYPASS');

// Action Creators
export const setTrackPageLocation = (trackPageLocation) => (dispatch) => {
    dispatch({
        type: SET_TRACK_PAGE_LOCATION,
        payload: { trackPageLocation },
    });
};

// Vouched
export const toggleNavigationBars = ({ navigationVisible = true }) => (dispatch) => {
    dispatch({
        type: TOGGLE_NAVIGATION_BARS,
        payload: { navigationVisible },
    });
};

export const submitIdentityVerification = () => (dispatch, getState) => {
    const state = getState();
    const onboardingId = getOnboardingId(state);
    const onboardingType = getOnboardingType(state);

    dispatch({
        type: PATCH_IDENTITY_VERIFY_SESSION_START.REQ,
    });
    return patchRequest({
        internal: true,
        hr: onboardingType === 'HR',
        endpoint: `/onboarding/${onboardingId}/identity_verification/session_started/`,
    }).then(
        () => {
            dispatch({
                type: PATCH_IDENTITY_VERIFY_SESSION_START.SUC,
            });
        },
        (error) => {
            dispatch({
                type: PATCH_IDENTITY_VERIFY_SESSION_START.ERR,
                payload: { error },
            });
            ErrorAlertAPI(error);
            throw error;
        }
    );
};

// Stripe Payment HR/PM
export const fetchPaymentBreakdown = () => (dispatch, getState) => {
    const onboardingId = getOnboardingId(getState());
    dispatch({
        type: GET_STRIPE_PAYMENT_BREAKDOWN.REQ,
    });
    return getRequest({
        internal: true,
        hr: true,
        endpoint: `/onboarding/${onboardingId}/payment/`,
    }).then(
        (response) => {
            dispatch({
                type: GET_STRIPE_PAYMENT_BREAKDOWN.SUC,
                payload: { myCRCPaymentBreakdown: response },
            });
        },
        (error) => {
            dispatch({
                type: GET_STRIPE_PAYMENT_BREAKDOWN.ERR,
                payload: { error },
            });
            ErrorAlertAPI(error);
            throw error;
        }
    );
};

export const submitStripeCustomerId = (stripe_customer_id) => (dispatch, getState) => {
    const onboardingId = getOnboardingId(getState());
    const onboardingType = getOnboardingType(getState());

    dispatch({ type: POST_STRIPE_CUSTOMER_ID.REQ });
    return putRequest({
        internal: true,
        hr: onboardingType === 'HR',
        version: 'v1',
        endpoint: `/onboarding/${onboardingId}/payment/`,
        body: JSON.stringify(stripe_customer_id),
    }).then(
        (response) => {
            dispatch({ type: POST_STRIPE_CUSTOMER_ID.SUC });
            return response;
        },
        (error) => {
            dispatch({ type: POST_STRIPE_CUSTOMER_ID.ERR });
            throw error;
        }
    );
};

// Sign Up
export const submitSignUpRequest = (data) => async (dispatch) => {
    dispatch({
        type: POST_SIGN_UP_REQ,
    });
    try {
        const { team } = await postRequest({
            version: 'v1',
            endpoint: `/sign_up/`,
            body: JSON.stringify(data),
        });
        dispatch({
            type: POST_SIGN_UP_RES,
            payload: { team },
        });
    } catch (error) {
        dispatch({
            type: POST_SIGN_UP_ERR,
            payload: { error },
        });
        ErrorAlertAPI(error);
        throw error;
    }
};

export const checkIfEmailExists = (email) => (dispatch) => {
    dispatch({
        type: POST_CHECK_EMAIL_EXIST_REQ,
    });
    return postRequest({
        version: 'v1',
        endpoint: `/email_check/`,
        body: JSON.stringify(email),
    }).then(
        (response) => {
            dispatch({
                type: POST_CHECK_EMAIL_EXIST_RES,
            });
            return response;
        },
        (error) => {
            dispatch({
                type: POST_CHECK_EMAIL_EXIST_ERR,
                payload: { error },
            });
            ErrorAlertAPI(error);
        }
    );
};

export const fetchConvictionOffenses = () => (dispatch) =>
    new Promise((resolve, reject) => {
        dispatch({ type: GET_CONVICTION_OFFENSES_REQ });
        return getRequest({
            internal: true,
            hr: false,
            version: 'v1',
            endpoint: '/onboarding/charges/',
        }).then(
            (response) => {
                dispatch({
                    type: GET_CONVICTION_OFFENSES_RES,
                    payload: { convictionOffenses: response },
                });
                resolve(response);
            },
            (error) => {
                dispatch({
                    type: GET_CONVICTION_OFFENSES_ERR,
                    payload: { error },
                });
                reject(error);
            }
        );
    });

/* Used by WithNavigation to determine page display */
export const setTrackPageOrder = (trackPageOrder) => (dispatch) =>
    new Promise((resolve) => {
        dispatch({
            type: SET_TRACK_PAGE_ORDER,
            payload: { trackPageOrder },
        });
        resolve();
    });

export const fetchSpecializations = () => (dispatch) => {
    dispatch({
        type: GET_SPECIALIZATIONS_REQ,
    });
    return getRequest({
        hr: true,
        version: 'v1',
        endpoint: '/specializations/',
    }).then(
        (response) => {
            dispatch({
                type: GET_SPECIALIZATIONS_RES,
                payload: { specializations: response },
            });
        },
        (error) => {
            dispatch({
                type: GET_SPECIALIZATIONS_ERR,
                payload: { error },
            });
        }
    );
};

export const fetchSkills = () => (dispatch) => {
    dispatch({
        type: GET_SKILLS_REQ,
    });
    return getRequest({
        hr: true,
        version: 'v1',
        endpoint: '/skills/',
    }).then(
        (response) => {
            dispatch({
                type: GET_SKILLS_RES,
                payload: { skills: response },
            });
        },
        (error) => {
            dispatch({
                type: GET_SKILLS_ERR,
                payload: { error },
            });
        }
    );
};

export const fetchDegrees = () => (dispatch) => {
    dispatch({
        type: GET_DEGREES_REQ,
    });
    return getRequest({
        hr: true,
        version: 'v1',
        endpoint: '/degrees/',
    }).then(
        (response) => {
            dispatch({
                type: GET_DEGREES_RES,
                payload: { degrees: response },
            });
        },
        (error) => {
            dispatch({
                type: GET_DEGREES_ERR,
                payload: { error },
            });
        }
    );
};

export const fetchHrCategories = () => (dispatch) => {
    dispatch(fetchSpecializations());
    dispatch(fetchSkills());
    dispatch(fetchDegrees());
    dispatch(fetchEmployerReferenceSkipReasons());
};

export const fetchListingOrPosting = (listingId) => (dispatch) =>
    new Promise((resolve, reject) => {
        const onboardingType = Cookies.get('onboardingType') || 'PM';
        dispatch({
            type: GET_LISTING_REQ,
        });
        return getRequest({
            internal: true,
            hr: onboardingType === 'HR',
            endpoint: `/applicant/listings/${listingId}/`,
        }).then(
            (response) => {
                dispatch({
                    type: GET_LISTING_RES,
                    payload: {
                        listing: response,
                    },
                });
                Cookies.set('listingId', response.id, { expires: 1 });
                resolve(response);
            },
            (error) => {
                dispatch({
                    type: GET_LISTING_ERR,
                    payload: { error },
                });
                reject(error);
            }
        );
    });

export const createWelcomeSession = ({ email, password = null, consentToMarketing = false }) => (dispatch, getState) =>
    new Promise((resolve, reject) => {
        const language = getLanguage(getState());
        const currentEmail = getEmail(getState());
        const ownerId = getOwnerId(getState());
        const listingId = Cookies.get('listingId');
        const onboardingType = Cookies.get('onboardingType') || 'PM';
        const onboardingId = Cookies.get('onboardingId');

        // first case is when you get an email invite
        if (currentEmail === email || onboardingId) {
            Sentry.configureScope((scope) => {
                scope.setUser({ email });
            });
            resolve(email);
        } else if (!listingId && !ownerId) {
            reject(`Cannot continue without original email: ${currentEmail}`);
        } else {
            dispatch({
                type: POST_ONBOARDING_REQ,
                payload: {
                    isPatching: true,
                },
            });
            const bodyJSON = {
                email: email.replace(/\s/g, ''),
                language,
                password,
                token: Cookies.get('applicant_token'),
                consent_to_marketing: consentToMarketing,
            };
            if (listingId) {
                bodyJSON.listing_id = listingId;
            }
            if (ownerId) {
                bodyJSON.owner_id = ownerId;
            }
            postRequest({
                internal: true,
                hr: onboardingType === 'HR',
                endpoint: '/onboarding/',
                body: JSON.stringify(bodyJSON),
            }).then(
                (response) => {
                    const listingOrPosting = response.listing ? response.listing : response.hr_listing;
                    let furthestTrack;

                    if (response.welcome && !isEmpty(response.welcome)) {
                        furthestTrack = response.welcome;
                    } else {
                        [furthestTrack] = response.tracks;
                    }

                    dispatch({
                        type: POST_ONBOARDING_RES,
                        payload: {
                            furthestTrack,
                            listing: listingOrPosting,
                            tracks: response.tracks,
                            applicant_account: response.applicant_account,
                            applicant_verified: response.applicant_verified,
                            complianceRegion: response.compliance_region,
                            coApplicants: response.co_applicants,
                            onboardingId: response.id,
                            information: response.information,
                            isCosigner: response.is_cosigner,
                            isSubmitted: response.is_submitted,
                            onboardingType: response.onboarding_session_type,
                            ownerId: response.owner_id,
                            settings: response.settings_config,
                            applicant: response.applicant,
                            identityVerification: response.identity_verification,
                            enhancedIdentityVerification: response.enhanced_identity_verification,
                            language: response.language,
                            internationalFields: response.international_fields,
                            team: response.team,
                            user: { consentToMarketing: response.consent_to_marketing },
                        },
                    });

                    // Applicant Login
                    if (response.token) {
                        Auth.authenticateApplicant(response);
                        Cookies.set('applicantLoginRedirect', response.applicant?.onboarding_link);
                    }

                    // Set return-to-track cookies
                    Cookies.set('onboardingId', response.id, { expires: 1 });
                    Cookies.set('onboardingType', response.onboarding_session_type, { expires: 1 });
                    if (listingOrPosting && listingOrPosting.id) {
                        Cookies.set('listingId', listingOrPosting.id, { expires: 1 });
                    } else {
                        Cookies.remove('listingId', '');
                    }
                    if (listingOrPosting && listingOrPosting.url_code) {
                        Cookies.set('listingUrlCode', listingOrPosting.url_code, { expires: 1 });
                    } else {
                        Cookies.remove('listingUrlCode', '');
                    }
                    Sentry.configureScope((scope) => {
                        scope.setUser({ email: get(response, ['information', 'applicant_account', 'email']) });
                    });

                    // Response Promise
                    resolve(furthestTrack);
                },
                (error) => {
                    dispatch({
                        type: POST_ONBOARDING_ERR,
                        payload: { error },
                    });
                    reject(error);
                }
            );
        }
    });

export const fetchWelcomeSession = () => (dispatch, getState) =>
    new Promise((resolve, reject) => {
        const onboardingId = getOnboardingId(getState());
        const onboardingType = getOnboardingType(getState());
        const currLanguage = getLanguage(getState());

        dispatch({
            type: GET_ONBOARDING_REQ,
        });

        if (onboardingId) {
            getRequest({
                internal: true,
                hr: onboardingType === 'HR',
                endpoint: `/onboarding/${onboardingId}/`,
            }).then(
                (response) => {
                    const listingOrPosting = response.listing ? response.listing : response.hr_listing;
                    let furthestTrack;

                    if (response.welcome && !isEmpty(response.welcome)) {
                        furthestTrack = response.welcome;
                    } else {
                        [furthestTrack] = response.tracks;
                    }

                    dispatch({
                        type: GET_ONBOARDING_RES,
                        payload: {
                            furthestTrack,
                            listing: listingOrPosting,
                            tracks: response.tracks,
                            applicant_account: response.applicant_account,
                            applicant_verified: response.applicant_verified,
                            complianceRegion: response.compliance_region,
                            coApplicants: response.co_applicants,
                            onboardingId: response.id,
                            information: response.information,
                            isCosigner: response.is_cosigner,
                            isSubmitted: response.is_submitted,
                            isPrepaid: response.is_prepaid,
                            onboardingType: response.onboarding_session_type,
                            ownerId: response.owner_id,
                            settings: response.settings_config,
                            applicant: response.applicant,
                            identityVerification: response.identity_verification,
                            enhancedIdentityVerification: response.enhanced_identity_verification,
                            language: response.language,
                            internationalFields: response.international_fields,
                            team: response.team,
                            user: {
                                consentToMarketing: response.consent_to_marketing,
                            },
                        },
                    });
                    if (response.language && currLanguage !== response.language) {
                        dispatch({
                            type: SET_LOCALE,
                            payload: {
                                locale: response.language,
                            },
                        });
                    }
                    Cookies.set('isCosigner', response.is_cosigner, { expires: 1 });
                    Cookies.set('onboardingId', response.id, { expires: 1 });
                    Cookies.set('onboardingType', response.onboarding_session_type, { expires: 1 });
                    if (listingOrPosting && listingOrPosting.id) {
                        Cookies.set('listingId', listingOrPosting.id, { expires: 1 });
                    } else {
                        Cookies.remove('listingId', '');
                    }
                    if (listingOrPosting && listingOrPosting.url_code) {
                        Cookies.set('listingUrlCode', listingOrPosting.url_code, { expires: 1 });
                    } else {
                        Cookies.remove('listingUrlCode', '');
                    }

                    Sentry.configureScope((scope) => {
                        scope.setUser({ email: get(response, ['information', 'applicant_account', 'email']) });
                    });

                    resolve(furthestTrack);
                },
                (error) => {
                    dispatch({
                        type: GET_ONBOARDING_ERR,
                        payload: { error },
                    });
                    reject(error);
                }
            );
        }
    });
export const fetchSocialMediaDetails = (informationId) => (dispatch) => {
    /* eslint-disable no-new */
    new Promise((resolve, reject) => {
        dispatch({
            type: GET_SOCIAL_MEDIA_DETAILS_REQ,
        });
        getRequest({
            version: 'v2',
            endpoint: `/information/${informationId}/social_media_details/`,
        }).then(
            (response) => {
                dispatch({
                    type: GET_SOCIAL_MEDIA_DETAILS_RES,
                    payload: { socialMediaDetails: response },
                });
                resolve(response);
            },
            (error) => {
                dispatch({
                    type: GET_SOCIAL_MEDIA_DETAILS_ERR,
                    payload: { error },
                });
                reject(error);
            }
        );
    });
};
export const fetchPositionPropertyLocation = (positionPropertyId) => (dispatch) => {
    /* eslint-disable no-new */
    new Promise((resolve, reject) => {
        dispatch({
            type: GET_POSITION_PROPERTY_REQ,
        });
        getRequest({
            version: 'v2',
            endpoint: `/information/position_property_location/${positionPropertyId}`,
        }).then(
            (response) => {
                dispatch({
                    type: GET_POSITION_PROPERTY_RES,
                    payload: { positionPropertyLocation: response },
                });
                resolve(response);
            },
            (error) => {
                dispatch({
                    type: GET_POSITION_PROPERTY_ERR,
                    payload: { error },
                });
                reject(error);
            }
        );
    });
};
export const patchWelcomeSession = (track) => (dispatch, getState) =>
    new Promise((resolve, reject) => {
        const onboardingId = getOnboardingId(getState());
        const onboardingType = getOnboardingType(getState());

        dispatch({
            type: PATCH_ONBOARDING_REQ,
        });

        const dataJSON = {
            welcome: track,
        };
        const stringifiedData = simpleStringifyJSON(dataJSON);
        patchRequest({
            internal: true,
            hr: onboardingType === 'HR',
            endpoint: `/onboarding/${onboardingId}/`,
            body: JSON.stringify(stringifiedData),
        }).then(
            (response) => {
                dispatch({
                    type: PATCH_ONBOARDING_RES,
                    payload: {
                        furthestTrack: response.welcome,
                        tracks: response.tracks,
                        internationalFields: response.international_fields,
                        identityVerification: response.identity_verification,
                        enhancedIdentityVerification: response.enhanced_identity_verification,
                    },
                });
                resolve(response);
            },
            (error) => {
                dispatch({
                    type: PATCH_ONBOARDING_ERR,
                    payload: { error },
                });
                reject(error);
            }
        );
    });

export const patchSessionLanguage = (language) => (dispatch, getState) =>
    new Promise((resolve, reject) => {
        const onboardingId = getOnboardingId(getState());
        const onboardingType = getOnboardingType(getState());

        dispatch({
            type: PATCH_LANGUAGE_REQ,
        });
        const dataJSON = { language };
        const stringifiedData = simpleStringifyJSON(dataJSON);
        patchRequest({
            internal: true,
            hr: onboardingType === 'HR',
            endpoint: `/onboarding/${onboardingId}/`,
            body: JSON.stringify(stringifiedData),
        }).then(
            (response) => {
                dispatch({
                    type: PATCH_LANGUAGE_RES,
                    payload: {
                        language: response.language,
                    },
                });
                dispatch({
                    type: SET_LOCALE,
                    payload: {
                        locale: language,
                    },
                });
                resolve(response);
            },
            (error) => {
                dispatch({
                    type: PATCH_LANGUAGE_ERR,
                    payload: { error },
                });
                reject(error);
            }
        );
    });

export const resendVerifyEmail = () => (dispatch, getState) => {
    const onboardingId = getOnboardingId(getState());
    const onboardingType = getOnboardingType(getState());
    dispatch({
        type: POST_RESEND_VERIFY_REQ,
    });
    if (onboardingId) {
        return postRequest({
            internal: true,
            hr: onboardingType === 'HR',
            endpoint: `/onboarding/${onboardingId}/resend/`,
        }).then(
            () => {
                dispatch({
                    type: POST_RESEND_VERIFY_RES,
                });
            },
            (error) => {
                dispatch({
                    type: POST_RESEND_VERIFY_ERR,
                    payload: { error },
                });
                throw error;
            }
        );
    }
};
export const postSocialMediaDetails = (informationId, data = {}) => (dispatch) =>
    new Promise((resolve, reject) => {
        dispatch({
            type: POST_SOCIAL_MEDIA_DETAILS_REQ,
        });

        postRequest({
            version: 'v2',
            endpoint: `/information/${informationId}/social_media_details/`,
            body: JSON.stringify(simpleStringifyJSON(data)),
        }).then(
            (response) => {
                dispatch({
                    type: POST_SOCIAL_MEDIA_DETAILS_RES,
                    payload: { socialMediaDetails: response },
                });
                resolve(response);
            },
            (error) => {
                dispatch({
                    type: POST_SOCIAL_MEDIA_DETAILS_ERR,
                    payload: { error },
                });
                ErrorAlertAPI(error);
                reject(error);
            }
        );
    });

export const postPositionPropertyLocation = (informationId, data = {}) => (dispatch) =>
    new Promise((resolve, reject) => {
        dispatch({
            type: POST_POSITION_PROPERTY_REQ,
        });

        postRequest({
            version: 'v2',
            endpoint: `/information/${informationId}/position_property_location/`,
            body: JSON.stringify(simpleStringifyJSON(data)),
        }).then(
            (response) => {
                dispatch({
                    type: POST_POSITION_PROPERTY_RES,
                    payload: { positionPropertyLocation: response },
                });
                resolve(response);
            },
            (error) => {
                dispatch({
                    type: POST_POSITION_PROPERTY_ERR,
                    payload: { error },
                });
                ErrorAlertAPI(error);
                reject(error);
            }
        );
    });

export const patchPositionPropertyLocation = (positionPropertyId, data = {}) => (dispatch) =>
    new Promise((resolve, reject) => {
        dispatch({
            type: PATCH_POSITION_PROPERTY_REQ,
        });
        patchRequest({
            version: 'v2',
            endpoint: `/information/position_property_location/${positionPropertyId}`,
            body: JSON.stringify(simpleStringifyJSON(data)),
        }).then(
            (response) => {
                dispatch({
                    type: PATCH_POSITION_PROPERTY_RES,
                    payload: { positionPropertyLocation: response },
                });
                resolve(response);
            },
            (error) => {
                dispatch({
                    type: PATCH_POSITION_PROPERTY_ERR,
                    payload: { error },
                });
                ErrorAlertAPI(error);
                reject(error);
            }
        );
    });
export const patchInformation = (data = {}) => (dispatch, getState) =>
    new Promise((resolve, reject) => {
        const onboardingId = getOnboardingId(getState());
        const onboardingType = getOnboardingType(getState());

        dispatch({
            type: PATCH_INFORMATION_REQ,
        });

        const stringifiedData = simpleStringifyJSON(data);
        patchRequest({
            internal: true,
            hr: onboardingType === 'HR',
            endpoint: `/onboarding/${onboardingId}/information/`,
            body: JSON.stringify(stringifiedData),
        }).then(
            (response) => {
                dispatch({
                    type: PATCH_INFORMATION_RES,
                    payload: { information: response.information },
                });
                dispatch({
                    type: PATCH_APPLICANT,
                    payload: {
                        applicant: response.applicant,
                    },
                });
                dispatch({
                    type: PATCH_ENHANCED_IDENTITY_VERIFICATION,
                    payload: {
                        enhancedIdentityVerification: response.enhanced_identity_verification,
                    },
                });
                resolve(response);
            },
            (error) => {
                dispatch({
                    type: PATCH_INFORMATION_ERR,
                    payload: { error },
                });
                ErrorAlertAPI(error);
                reject(error);
            }
        );
    });

export const verifyWelcomeSession = (onboardingId, verifyToken) => (dispatch, getState) => {
    const onboardingType = getOnboardingType(getState());
    dispatch({
        type: PUT_VERIFY_ONBOARDING_REQ,
    });

    const verifyJson = {
        token: verifyToken,
    };
    return putRequest({
        internal: true,
        hr: onboardingType === 'HR',
        endpoint: `/onboarding/${onboardingId}/verify/`,
        body: JSON.stringify(verifyJson),
    }).then(
        (response) => {
            dispatch({
                type: PUT_VERIFY_ONBOARDING_RES,
                payload: { applicant_verified: response.applicant_verified },
            });
        },
        (error) => {
            dispatch({
                type: PUT_VERIFY_ONBOARDING_ERR,
                payload: { error },
            });
        }
    );
};

export const submitWelcomeSession = () => (dispatch, getState) =>
    new Promise((resolve, reject) => {
        const onboardingType = getOnboardingType(getState());
        const onboardingId = getOnboardingId(getState());
        const applicantId = getApplicant(getState())?.id;
        dispatch({
            type: PUT_SUBMIT_REQ,
        });

        putRequest({
            internal: true,
            hr: onboardingType === 'HR',
            endpoint: `/onboarding/${onboardingId}/submit/`,
        }).then(
            (response) => {
                dispatch({
                    type: PUT_SUBMIT_RES,
                    payload: {
                        isSubmitted: response.is_submitted,
                        isPatching: true,
                    },
                });
                Cookies.remove('onboardingId', '');
                Cookies.remove('isCosigner', '');
                Cookies.remove('passwordToken', '');
                localStorage.removeItem(`signatureImage_${applicantId.substring(0, 6)}`);
                resolve(response);
            },
            (error) => {
                dispatch({
                    type: PUT_SUBMIT_ERR,
                    payload: { error },
                });
                reject(error);
            }
        );
    });

export const summaryCallback = () => (dispatch, getState) =>
    new Promise((resolve, reject) => {
        const onboardingType = getOnboardingType(getState());
        const onboardingId = getOnboardingId(getState());
        dispatch({
            type: GET_VALIDATE_REQ,
        });

        getRequest({
            internal: true,
            hr: onboardingType === 'HR',
            endpoint: `/onboarding/${onboardingId}/validate/`,
        }).then(
            (response) => {
                resolve(response);
            },
            (error) => {
                dispatch({
                    type: GET_VALIDATE_ERR,
                    payload: { error },
                });
                reject(error);
            }
        );
    });

export const fetchSignedS3Link = (file, documentType, subtype) => (dispatch, getState) =>
    new Promise((resolve, reject) => {
        const onboardingId = getOnboardingId(getState());
        const onboardingType = getOnboardingType(getState());
        let dataJSON = {
            document_type: documentType,
            document_subtype: subtype,
            file_name: file.name,
            file_type: file.type,
        };
        dataJSON = JSON.stringify(simpleStringifyJSON(dataJSON));

        postRequest({
            internal: true,
            hr: onboardingType === 'HR',
            endpoint: `/onboarding/${onboardingId}/documents/`,
            body: dataJSON,
        }).then(
            (response) => {
                dispatch({
                    type: POST_S3_RES,
                    payload: {
                        s3UploadLink: response.url,
                        s3UploadFields: response.fields,
                        preparedDocument: response.document,
                    },
                });
                resolve(response);
            },
            (error) => {
                dispatch({
                    type: POST_S3_ERR,
                    payload: {
                        s3UploadLink: null,
                        s3UploadFields: null,
                        preparedDocument: null,
                    },
                });
                reject(error);
            }
        );
    });

export const confirmUploadSuccessful = (documentId) => (dispatch, getState) =>
    new Promise((resolve, reject) => {
        const onboardingId = getOnboardingId(getState());
        const onboardingType = getOnboardingType(getState());
        dispatch({
            type: CONFIRM_UPLOAD_SUCCESSFUL_REQ,
        });
        postRequest({
            internal: true,
            hr: onboardingType === 'HR',
            endpoint: `/onboarding/${onboardingId}/documents/${documentId}/`,
        }).then(
            (response) => {
                dispatch({
                    type: CONFIRM_UPLOAD_SUCCESSFUL_RES,
                });
                resolve(response);
            },
            (error) => {
                dispatch({
                    type: CONFIRM_UPLOAD_SUCCESSFUL_ERR,
                });
                reject(error);
            }
        );
    });

export const deleteVaccinationDocument = () => (dispatch, getState) =>
    new Promise((resolve, reject) => {
        const onboardingId = getOnboardingId(getState());
        const onboardingType = getOnboardingType(getState());

        patchRequest({
            internal: true,
            hr: onboardingType === 'HR',
            endpoint: `/onboarding/${onboardingId}/vaccination/document/upload/`,
            body: JSON.stringify({ vaccination_document: null }),
        }).then(
            (response) => {
                resolve(response);
            },
            (error) => {
                reject(error);
            }
        );
    });

export const deleteDocument = (file) => (dispatch, getState) =>
    new Promise((resolve, reject) => {
        const onboardingId = getOnboardingId(getState());
        const onboardingType = getOnboardingType(getState());

        deleteRequest({
            internal: true,
            hr: onboardingType === 'HR',
            endpoint: `/onboarding/${onboardingId}/documents/${file.uid || file}/`,
        }).then(
            (response) => {
                dispatch({
                    type: DELETE_DOCUMENT_RES,
                });
                resolve(response);
            },
            (error) => {
                dispatch({
                    type: DELETE_DOCUMENT_ERR,
                    payload: { error },
                });
                reject(error);
            }
        );
    });

export const fetchAcicDocumentTypes = () => () =>
    getRequest({
        version: 'v2',
        endpoint: `/documents/acic_document_types/`,
    });

export const fetchRightToWorkDocumentTypes = () => () =>
    getRequest({
        version: 'v2',
        endpoint: `/documents/rtw_document_types/`,
    });

export const fetchAustraliaNpcDocumentTypesAndPoints = () => () =>
    getRequest({
        version: 'v2',
        endpoint: `/documents/australian_npc_document_types_and_points/`,
    });

export const demoSkipOneID = (options) => (dispatch, getState) =>
    new Promise((resolve, reject) => {
        const onboardingId = getOnboardingId(getState());
        const onboardingType = getOnboardingType(getState());

        dispatch({ type: PATCH_NETVERIFY_BYPASS_REQ });

        patchRequest({
            internal: true,
            hr: onboardingType === 'HR',
            endpoint: `/onboarding/${onboardingId}/identity_verification_demo_skip`,
            body: JSON.stringify(options),
        }).then(
            (response) => {
                dispatch({ type: PATCH_NETVERIFY_BYPASS_RES });
                resolve(response);
            },
            (error) => {
                dispatch({
                    type: PATCH_NETVERIFY_BYPASS_ERR,
                    payload: { error },
                });
                reject(error);
            }
        );
    });

export const bypassOneID = () => async (dispatch, getState) => {
    const onboardingId = getOnboardingId(getState());
    const onboardingType = getOnboardingType(getState());

    dispatch({ type: PATCH_ONEID_BYPASS.REQ });
    try {
        await patchRequest({
            internal: true,
            hr: onboardingType === 'HR',
            endpoint: `/onboarding/${onboardingId}/oneid_bypass`,
        });
    } catch (error) {
        dispatch({
            type: PATCH_ONEID_BYPASS.ERR,
            payload: { error },
        });
    }
};

export const fetchEmployerReferenceSkipReasons = () => (dispatch) => {
    dispatch({
        type: GET_EMPLOYER_REFERENCE_SKIP_REASONS_REQ,
    });
    return getRequest({
        internal: false,
        version: 'v2',
        endpoint: '/references/employer_reference/skip_reasons/',
    }).then(
        (response) => {
            dispatch({
                type: GET_EMPLOYER_REFERENCE_SKIP_REASONS_RES,
                payload: { employerReferenceSkipReasons: response },
            });
        },
        (error) => {
            dispatch({
                type: GET_EMPLOYER_REFERENCE_SKIP_REASONS_ERR,
                payload: { error },
            });
        }
    );
};
