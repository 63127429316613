import ReactGA from 'react-ga4';
import { initialize as initializeBingTracking, trackEvent as trackBingEvent } from 'react-tracking-bing';
import Cookies from 'js-cookie';
import { OnboardingSession } from 'types';
import { getEnhancedConversionTrackingAddress } from './utils';

type TrackingKeys = {
    googleAnalyticsKey?: string;
    bingKey?: string;
    adwordsKey?: string;
};

export const useTracking = () => {
    const initializeTracking = ({ googleAnalyticsKey, bingKey }: TrackingKeys) => {
        let initialized = false;

        if (googleAnalyticsKey) {
            ReactGA.initialize([
                {
                    trackingId: googleAnalyticsKey,
                },
            ]);

            initialized = true;
        }

        if (bingKey) {
            initializeBingTracking(bingKey);
            initialized = true;
        }

        if (!initialized) {
            console.error('No tracking was initialized. Ensure team has keys configured.');
        }
    };

    const sendGATrackingEvent = (googleAnalyticsKey: string | undefined, details: Record<string, string> = {}) => {
        ReactGA.gtag('event', 'conversion', {
            category: 'Tracking',
            action: 'Click',
            label: 'Conversions',
            send_to: googleAnalyticsKey,
            ...details,
        });
    };

    const sendTrackingEvent = ({
        googleAnalyticsKey,
        bingKey,
        adwordsKey,
        information,
    }: TrackingKeys & { information: OnboardingSession['information'] }) => {
        if (googleAnalyticsKey) {
            sendGATrackingEvent(googleAnalyticsKey);
        }

        const { gtag } = window;
        if (adwordsKey && gtag) {
            if (information) {
                gtag('set', 'user_data', {
                    email: Cookies.get('applicant_email'),
                    phone_number: (information.phone_number ?? '').replace(/ /g, ''),
                    address: getEnhancedConversionTrackingAddress(information),
                });
            } else {
                console.error('Failed to create enhanced conversion tracking event. Information object not provided.');
            }

            gtag('event', 'conversion', {
                send_to: adwordsKey,
                transaction_id: '',
            });
        }

        if (bingKey) {
            trackBingEvent('purchase');
        }

        if (googleAnalyticsKey === undefined || adwordsKey === undefined || bingKey === undefined) {
            console.error('Failed to send conversion tracking event. One or more keys are missing.');
        }
    };

    return {
        initializeTracking,
        sendTrackingEvent,
        sendGATrackingEvent,
    };
};
