// Libraries
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components/macro';
import { intl } from 'components/GlobalProvider';
import { FormattedMessage } from 'react-intl';
import { EllipsisOutlined } from '@ant-design/icons';

// Components
import { ListCellText } from 'certn-ui/List';
import { Dropdown as AntDropdown, Menu, Modal, Button as AntButton } from 'antd';

const Dropdown = styled(AntDropdown)`
    margin: 8px 0px;
`;

const Button = styled(AntButton)`
    ${(props) => props.margin && `margin: ${props.margin}`};
    overflow: hidden !important;
    height: 40px !important;
    width: 40px !important;
`;

class DropdownCell extends React.Component {
    showConfirmDelete = () => {
        Modal.confirm({
            // TODO FIX THIS SO IS SHOWS THE STRING AND NOT THE ID
            title: intl.formatMessage({
                id: '6bd3a.DropdownCell.ConfirmTitle',
                defaultMessage: 'Do you really want to delete this package?',
            }),
            content: intl.formatMessage({
                id: '6bd3a.DropdownCell.ConfirmContent',
                defaultMessage:
                    'This action cannot be undone. Please confirm you have the correct package before proceeding',
            }),
            okType: 'danger',
            oKText: intl.formatMessage({ id: 'common.ok', defaultMessage: 'OK' }),
            cancelText: intl.formatMessage({
                id: 'common.cancel',
                defaultMessage: 'Cancel',
            }),
            onOk: () => {
                this.props.deletePackage(this.props.item);
            },
            onCancel: () => {},
        });
    };

    render() {
        const {
            packageName,
            editTemplate,
            defaultPackage,
            makeDefault,
            item,
            canDelete,
            integrationHasDefault,
        } = this.props;
        return (
            <Dropdown
                overlay={
                    <Menu>
                        <Menu.Item key="1">
                            <ListCellText clickable onClick={() => editTemplate(item)}>
                                <FormattedMessage id="6bd3a.DropdownCell.Edit" defaultMessage="Edit Template" />
                            </ListCellText>
                        </Menu.Item>
                        {item !== defaultPackage && integrationHasDefault && (
                            <Menu.Item key="2">
                                <ListCellText clickable onClick={() => makeDefault(item)}>
                                    <FormattedMessage id="6bd3a.DropdownCell.Make" defaultMessage="Make Default" />
                                </ListCellText>
                            </Menu.Item>
                        )}
                        {canDelete &&
                            item !== defaultPackage && ( // HIDE DELETE IF ONLY ONE LEVER PACKAGE LEFT
                                <Menu.Item key="3">
                                    <ListCellText
                                        clickable
                                        critical
                                        onClick={() => this.showConfirmDelete(packageName)}
                                    >
                                        <FormattedMessage
                                            id="6bd3a.DropdownCell.Delete"
                                            defaultMessage="Delete Template"
                                        />
                                    </ListCellText>
                                </Menu.Item>
                            )}
                    </Menu>
                }
                trigger={['click']}
                placement="bottomCenter"
            >
                <Button shape="circle" icon={<EllipsisOutlined />} />
            </Dropdown>
        );
    }
}

export default withRouter(connect()(DropdownCell));
