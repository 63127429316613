import { useUserTeam } from 'hooks/useUserTeam';
import numeral from 'numeral';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { QA_TEST_ID } from 'utils/constants';
import { getTotal, getIsAccessFeeCheckInCart } from 'views/manager/features/ScreenApplicant/ScreenApplicantSelectors';
import { StyledCartTotalsWrapper } from './styled';
import { TotalSection } from './TotalSection';

const INTEGER_PERCENTAGE_FORMAT = '0%';

export const CartTotals = () => {
    const intl = useIntl();
    const { team } = useUserTeam();
    const total = useSelector(getTotal);
    const addAsterisk = useSelector(getIsAccessFeeCheckInCart);
    const taxAmount = parseFloat(team?.billing_plan?.tax_percentage_services ?? '0');

    return (
        <StyledCartTotalsWrapper>
            <TotalSection
                testId={QA_TEST_ID.Subtotal}
                title={intl.formatMessage({
                    id: 'acce0.ScreenApplicantCart.subtotal',
                    defaultMessage: 'Subtotal',
                })}
                total={total}
                tax={1}
            />
            <TotalSection
                testId={QA_TEST_ID.Tax}
                title={intl.formatMessage(
                    {
                        id: 'acce0.ScreenApplicantCart.taxtype',
                        defaultMessage: 'Tax ({tax})',
                    },
                    {
                        tax: numeral(taxAmount).format(INTEGER_PERCENTAGE_FORMAT),
                    }
                )}
                total={total}
                tax={taxAmount}
            />
            <TotalSection
                testId={QA_TEST_ID.Total}
                title={intl.formatMessage({
                    id: 'common.total',
                    defaultMessage: 'Total',
                })}
                total={total}
                tax={taxAmount + 1}
                bolded
                addAsterisk={addAsterisk}
            />
        </StyledCartTotalsWrapper>
    );
};
