// Libraries
import React from 'react';

// Components
import { OneIDVeriff, OneIDVouched, OneIDYoti, OneIDTrustmatic } from './identityProviders';

// Actions & Selectors
import { ONEID_PROVIDERS } from 'views/welcome/WelcomeConstants';
import { OneIdProviders } from 'types';

interface Props {
    oneIDProvider: OneIdProviders;
    handleSubmit: () => void;
    oneIdId: string;
    handleProcessing: (value: boolean) => void;
}

/**
 * OneID - Used to render various identity verification providers
 * identity_verification_provider in welcome.enhancedIdentityVerification object
 */
const OneIDProviderWrapper: React.FC<Props> = ({ oneIDProvider, handleSubmit, oneIdId, handleProcessing }) => {
    // Add additional providers here, this component may need to be altered to support different vendors

    const renderOneIDProvider = () => {
        if (oneIDProvider === ONEID_PROVIDERS.VOUCHED) {
            return <OneIDVouched handleSubmit={handleSubmit} oneIdId={oneIdId} />;
        }

        if (ONEID_PROVIDERS.VERIFF.includes(oneIDProvider)) {
            return <OneIDVeriff handleSubmit={handleSubmit} oneIdId={oneIdId} />;
        }

        if (oneIDProvider === ONEID_PROVIDERS.YOTI) {
            return <OneIDYoti handleSubmit={handleSubmit} handleProcessing={handleProcessing} />;
        }
        if (oneIDProvider === ONEID_PROVIDERS.TRUSTMATIC) {
            return <OneIDTrustmatic handleSubmit={handleSubmit} />;
        }
        return null;
    };

    return <>{renderOneIDProvider()}</>;
};

export default OneIDProviderWrapper;
