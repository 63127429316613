import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';

import { ServerEnv } from 'utils/config';

export const LDProviderFactory = async () => {
    const currentServerEnv = process.env.REACT_APP_ENV;

    const launchDarklyClientSideProdKey = '6021eba0595c0a0ae0d103a5';
    const launchDarklyClientSideDemoKey = '6053b1d9427dc00dba352b12';
    const launchDarklyClientSideStagingKey = '60dbbb7ace62f1240b46c4d6';
    const launchDarklyClientSideDevKey = '60dbbb56f1ea35253021be62';

    let clientSideID;
    if (currentServerEnv === ServerEnv.PROD) {
        clientSideID = launchDarklyClientSideProdKey;
    } else if (currentServerEnv === ServerEnv.DEMO) {
        clientSideID = launchDarklyClientSideDemoKey;
    } else if (currentServerEnv === ServerEnv.STAGING) {
        clientSideID = launchDarklyClientSideStagingKey;
    } else {
        clientSideID = launchDarklyClientSideDevKey;
    }

    return asyncWithLDProvider({
        clientSideID,
        user: {
            key: 'not-logged-in',
            name: 'Anonymous User',
        },
    });
};
