// Libraries
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { formatDate } from 'modules/Format';

// Components
import SummaryHeader from '../SummaryHeader';
import SummaryItem from '../SummaryItem';
import { FlexRow, LineBreak, SummaryContainer, SummarySection } from '../SummaryStyles';
import { formatOtherProvince } from 'modules/Countries';
import { AddressSerializer } from 'types/serializers';

interface Props {
    addresses: AddressSerializer[];
    goToTrack: () => void;
    isUkCheck: boolean | undefined;
}

const Addresses = ({ addresses, goToTrack, isUkCheck }: Props) => {
    const intl = useIntl();

    return (
        <SummaryContainer>
            <SummaryHeader
                title={<FormattedMessage id="welcome.general.addresses" defaultMessage="Addresses" />}
                onClick={goToTrack}
            />

            <SummarySection>
                {addresses.length === 0 && (
                    <SummaryItem
                        empty
                        title={
                            <FormattedMessage
                                id="welcome.Summary.noAddressesListed"
                                defaultMessage="You have not listed any addresses"
                            />
                        }
                    />
                )}
                {addresses.map((address, i) => (
                    <React.Fragment key={i}>
                        <FlexRow>
                            {address.cost && (
                                <SummaryItem
                                    title={
                                        <FormattedMessage
                                            id="welcome.Addresses.rentMortgage"
                                            defaultMessage="Rent/Mortgage $"
                                        />
                                    }
                                    text={address.cost}
                                />
                            )}
                            {isUkCheck ? (
                                <>
                                    <SummaryItem
                                        title={
                                            <FormattedMessage
                                                id="addressAuto.addressLine1"
                                                defaultMessage="Address Line 1"
                                            />
                                        }
                                        text={address.unit || address.address}
                                    />

                                    <SummaryItem
                                        title={
                                            <FormattedMessage
                                                id="addressAuto.addressLine2"
                                                defaultMessage="Address Line 2"
                                            />
                                        }
                                        text={address.unit ? address.address : ''}
                                    />
                                </>
                            ) : (
                                <>
                                    <SummaryItem
                                        title={
                                            <FormattedMessage id="welcome.general.address" defaultMessage="Address" />
                                        }
                                        text={address.address}
                                    />
                                    {address.unit && (
                                        <SummaryItem
                                            title={<FormattedMessage id="welcome.general.unit" defaultMessage="Unit" />}
                                            text={address.unit}
                                        />
                                    )}
                                </>
                            )}
                            <SummaryItem
                                title={
                                    isUkCheck ? (
                                        <FormattedMessage id="addressAuto.townCity" defaultMessage="Town/City" />
                                    ) : (
                                        <FormattedMessage id="common.city" defaultMessage="City" />
                                    )
                                }
                                text={address.city}
                            />
                            <SummaryItem
                                title={<FormattedMessage id="common.country" defaultMessage="Country" />}
                                text={address.country_label || address.country}
                            />
                            {!isUkCheck &&
                                (address.province_state !== 'OT' ? (
                                    <SummaryItem
                                        title={
                                            <FormattedMessage id="welcome.general.province" defaultMessage="Province" />
                                        }
                                        text={address.province_state}
                                    />
                                ) : (
                                    <SummaryItem
                                        title={
                                            <FormattedMessage id="welcome.general.province" defaultMessage="Province" />
                                        }
                                        text={formatOtherProvince(address.country, address.other_province_state, intl)}
                                    />
                                ))}
                            <SummaryItem
                                title={
                                    isUkCheck ? (
                                        <FormattedMessage id="addressAuto.postcode" defaultMessage="Postcode" />
                                    ) : (
                                        <FormattedMessage
                                            id="welcome.general.postalCode"
                                            defaultMessage="Postal Code"
                                        />
                                    )
                                }
                                text={address.postal_code}
                            />
                        </FlexRow>
                        <FlexRow>
                            <SummaryItem
                                title={
                                    <FormattedMessage
                                        id="welcome.general.currentOrPast"
                                        defaultMessage="Current or Past?"
                                    />
                                }
                                text={address.current ? 'Current' : 'Past'}
                            />
                            {address.start_date && (
                                <SummaryItem
                                    title={
                                        <FormattedMessage id="welcome.general.startDate" defaultMessage="Start Date" />
                                    }
                                    text={formatDate(address.start_date, isUkCheck && 'UK')}
                                />
                            )}
                            {address.end_date && (
                                <SummaryItem
                                    title={<FormattedMessage id="welcome.general.endDate" defaultMessage="End Date" />}
                                    text={formatDate(address.end_date, isUkCheck && 'UK')}
                                />
                            )}
                        </FlexRow>

                        {address && address.address_reference && (
                            <FlexRow>
                                <SummaryItem
                                    title={
                                        <FormattedMessage
                                            id="welcome.Summary.referenceFirstName"
                                            defaultMessage="Reference First Name"
                                        />
                                    }
                                    text={address.address_reference.name && address.address_reference.name.first_name}
                                />
                                <SummaryItem
                                    title={
                                        <FormattedMessage
                                            id="welcome.Summary.referenceLastName"
                                            defaultMessage="Reference Last Name"
                                        />
                                    }
                                    text={address.address_reference.name && address.address_reference.name.last_name}
                                />
                                <SummaryItem
                                    title={
                                        <FormattedMessage
                                            id="welcome.Summary.referenceEmail"
                                            defaultMessage="Reference Email"
                                        />
                                    }
                                    text={address.address_reference.email && address.address_reference.email.address}
                                />
                                <SummaryItem
                                    title={
                                        <FormattedMessage
                                            id="welcome.Summary.referencePhone"
                                            defaultMessage="Reference Phone"
                                        />
                                    }
                                    text={
                                        address.address_reference.phone_number &&
                                        address.address_reference.phone_number.number
                                    }
                                />
                                <SummaryItem
                                    title={
                                        <FormattedMessage id="welcome.general.phoneType" defaultMessage="Phone Type" />
                                    }
                                    text={
                                        address.address_reference.phone_number &&
                                        address.address_reference.phone_number.phone_type
                                    }
                                />
                            </FlexRow>
                        )}

                        {addresses.length > 1 && i + 1 < addresses.length && (
                            <div style={{ marginTop: 13, marginBottom: 25, paddingLeft: 12, paddingRight: 12 }}>
                                <LineBreak />
                            </div>
                        )}
                    </React.Fragment>
                ))}
            </SummarySection>
        </SummaryContainer>
    );
};

export default Addresses;
