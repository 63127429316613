// Libraries
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl, FormattedMessage } from 'react-intl';

// React Router & Routes
import { withRouter } from 'react-router-dom';

// Other Components
import { ErrorAlertAPI } from 'certn-ui/ErrorAlert';
import Loader from 'certn-ui/Loader';
import { get } from 'lodash';
import Titlebar from 'certn-ui/Titlebar';
import { CenterLine } from 'certn-ui/Layout';
import ApplySVG from 'images/svgs/svg-components/Apply';
import PropTypes from 'prop-types';

// Actions & Selectors
import { fetchBuilding } from 'views/guest/GuestActions';
import { getIsFetching, getBuilding } from 'views/guest/GuestSelectors';
import styled from 'styled-components/macro';
import SearchInput from '../components/SearchInput';
import List from '../components/List';
import styles from 'styles/styles';

const ViewAllBuildings = styled.div`
    padding: 15px;
    font-size: 16px;
    color: ${styles.color.certnGreen700};
    cursor: pointer;
`;

const Row = styled.div`
    display: flex;
`;

const mapStateToProps = (state) => ({
    isFetching: getIsFetching(state),
    building: getBuilding(state),
});
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchBuilding,
        },
        dispatch
    );

const propTypes = {
    // Redux Actions
    fetchBuilding: PropTypes.func.isRequired,
    // Redux Store
    building: PropTypes.object,
};

const defaultProps = {
    building: {},
};

class BuildingListings extends Component {
    constructor(props) {
        super(props);
        this.state = { search: '' };
        const buildingId = get(props, ['match', 'params', 'buildingId']);
        this.props.fetchBuilding(buildingId).catch((e) => ErrorAlertAPI(e));
    }

    setSearch = (searchTerm) => {
        this.setState({ search: searchTerm });
    };

    render() {
        const { building, isFetching, history } = this.props;
        const buildingAddress = building.address;
        const titleText = building.address ? (
            <FormattedMessage
                id="07545.BuildingListings.titleText"
                defaultMessage="Apply to listings at {buildingAddress}"
                values={{ buildingAddress }}
            />
        ) : (
            <FormattedMessage id="07545.BuildingListings.titleTextGeneric" defaultMessage="Loading listings..." />
        );
        const listings = get(building, 'listings') || [];

        return (
            <>
                <Titlebar
                    size="xlrg"
                    title={titleText}
                    subtitle={this.props.intl.formatMessage({
                        id: '07545.BuildingListings.subtitle',
                        defaultMessage:
                            "If you're ready to find your new home, browse and apply to all of our rental listings that are currently being advertised.",
                    })}
                    center
                />
                <CenterLine>
                    <ApplySVG color={styles.color.certnOrange400} />
                </CenterLine>
                <CenterLine>
                    <ViewAllBuildings onClick={() => this.props.history.push(`/browse/listings/${building.team_id}`)}>
                        Click here to view all buildings
                    </ViewAllBuildings>
                </CenterLine>
                <SearchInput
                    type="text"
                    onChange={this.setSearch}
                    placeholder={this.props.intl.formatMessage({
                        id: '07545.BuildingListings.searchInputPlaceholder',
                        defaultMessage: 'Search for listings by address, unit or city',
                    })}
                />
                <List.Container>
                    {isFetching ? (
                        <Loader />
                    ) : listings.length > 0 ? (
                        listings.map((listing, i) => {
                            const search = this.state.search.toLowerCase();
                            if (
                                search === '' ||
                                building.address.toLowerCase().includes(search) ||
                                building.city.toLowerCase().includes(search) ||
                                (listing.unit && listing.unit.toLowerCase().includes(search))
                            ) {
                                return (
                                    <List.Item
                                        key={i}
                                        noBorder={listings.length < i + 1}
                                        onClick={() => history.push(`/apply/${listing.url_code}`)}
                                    >
                                        <Row>
                                            <List.ItemText>{building.address} </List.ItemText>
                                            {listing.unit && (
                                                <List.ItemText light style={{ marginLeft: '10px' }}>
                                                    {listing.unit}
                                                </List.ItemText>
                                            )}
                                        </Row>
                                        <List.ItemText light>
                                            ${listing.rent}
                                            /mo
                                        </List.ItemText>
                                    </List.Item>
                                );
                            }
                            return null;
                        })
                    ) : (
                        <List.Item noBorder>
                            <List.ItemText>
                                <FormattedMessage
                                    id="07545.BuildingListings.noListings"
                                    defaultMessage="This building has no listings."
                                />
                            </List.ItemText>
                        </List.Item>
                    )}
                </List.Container>
            </>
        );
    }
}

BuildingListings.propTypes = propTypes;
BuildingListings.defaultProps = defaultProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(BuildingListings)));
