// Libraries
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Form, Input, Select, Popover } from 'antd';

import { getDataSet, reduce } from 'iso3166-2-db';

// Components
import { ProvStateSelect } from 'components';
import { RequiredText } from 'certn-ui';

// Modules
import { isUSAOrCanada } from 'modules/Countries';
import { Regex } from 'modules';

import { getLanguage } from 'base/BaseSelectors';
import { getPrepopulatedFields } from 'views/welcome/WelcomeSelectors';

const propTypes = {
    form: PropTypes.object.isRequired,
    information: PropTypes.object,
    labels: PropTypes.bool,
    size: PropTypes.string,
    noAddonBefore: PropTypes.bool,
};
const defaultProps = {
    information: {},
    labels: false,
    size: 'large',
    noAddonBefore: false,
};

const BirthPlaceSelect = ({ information, labels, size, noAddonBefore, form, isUKCheck }) => {
    const intl = useIntl();
    const birthCountry = form.getFieldValue('birth_country') || information.birth_country;
    // When birth country changes - need to set the state
    // Otherwise a re-render doesn't occur and the values are stale
    const [currentCountry, setCurrentCountry] = useState(birthCountry);
    const isBornInUSAOrCanada = isUSAOrCanada(birthCountry);

    const provinceFieldName = isBornInUSAOrCanada ? 'birth_province_state' : 'birth_other_province_state';

    const language = useSelector(getLanguage);
    const prepopulatedFields = useSelector(getPrepopulatedFields);

    const countriesDB = reduce(getDataSet(), language);
    // For the new country select
    const countryCodes = React.useMemo(
        () => Object.keys(countriesDB).sort((a, b) => (countriesDB[a].name > countriesDB[b].name ? 1 : -1)),
        [countriesDB]
    );

    const everyProvOrStateHasISOCode = countriesDB[birthCountry]?.regions.every((region) => region.iso);

    // Reset birth prov state upon changing to/from US/CA countries
    const countryChange = (value) => {
        // Use null instead of undefined - otherwise the saved value from information will override it
        form.setFieldsValue({
            birth_province_state: null,
            birth_other_province_state: null,
        });
        if (value !== currentCountry) {
            setCurrentCountry(value);
        }
    };

    const initialProvinceState = isBornInUSAOrCanada
        ? information.birth_province_state
        : information.birth_other_province_state;

    const countryLabel = intl.formatMessage({
        id: 'welcome.Birthplace.country',
        defaultMessage: 'Country of Birth',
    });
    const provStateLabel = intl.formatMessage({
        id: 'welcome.Birthplace.provState',
        defaultMessage: 'Province / State of Birth',
    });

    const cityLabel = isUKCheck
        ? intl.formatMessage({
              id: 'welcome.Birthplace.townCityOfBirth',
              defaultMessage: 'Town/City of Birth',
          })
        : intl.formatMessage({
              id: 'welcome.Birthplace.cityMunicipality',
              defaultMessage: 'City / Municipality of Birth',
          });

    return (
        <>
            {labels && <RequiredText>{countryLabel}</RequiredText>}
            <Form.Item
                name="birth_country"
                initialValue={information.birth_country}
                rules={[
                    {
                        required: true,
                        message: intl.formatMessage({
                            id: 'error.validation.countryError',
                            defaultMessage: 'Select a country!',
                        }),
                    },
                ]}
            >
                <Select
                    size={size}
                    placeholder={countryLabel}
                    onChange={countryChange}
                    showSearch
                    autoComplete="dont-use-autocomplete"
                    filterOption={(input = '', { props }) =>
                        props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                        props.value.toLowerCase().indexOf(input.toLowerCase()) === 0
                    }
                    data-testid="birth_country"
                    disabled={prepopulatedFields.includes('birth_country')}
                >
                    {countryCodes.map((isoCode) => (
                        <Select.Option data-testid={countriesDB[isoCode].name} key={isoCode} value={isoCode}>
                            {countriesDB[isoCode].name}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
            {!isUKCheck && (
                <>
                    {labels && <RequiredText>{provStateLabel}</RequiredText>}
                    <Form.Item
                        name={provinceFieldName}
                        dependencies={['birth_country']}
                        initialValue={initialProvinceState}
                        rules={[
                            {
                                required: everyProvOrStateHasISOCode,
                                message: intl.formatMessage({
                                    id: 'error.validation.provinceError',
                                    defaultMessage: 'Select a province!',
                                }),
                            },
                            {
                                pattern: Regex.city,
                                message: intl.formatMessage({
                                    id: 'error.validation.letters',
                                    defaultMessage: 'Please only use letters',
                                }),
                            },
                        ]}
                    >
                        <ProvStateSelect
                            size={size}
                            selectedCountry={birthCountry}
                            placeholder={provStateLabel}
                            disabled={!birthCountry}
                            data-testid={provinceFieldName}
                        />
                    </Form.Item>
                </>
            )}
            {labels && <RequiredText>{cityLabel}</RequiredText>}
            <Form.Item
                name="birth_city"
                initialValue={information.birth_city}
                rules={[
                    !isUKCheck && {
                        pattern: Regex.city,
                        message: intl.formatMessage({
                            id: 'error.validation.letters',
                            defaultMessage: 'Please only use letters',
                        }),
                    },
                    {
                        required: true,
                        message: intl.formatMessage({
                            id: 'error.validation.notBlank',
                            defaultMessage: 'Please do not leave blank',
                        }),
                    },
                ]}
            >
                <Input
                    maxLength={60}
                    size={size}
                    autoComplete="dont-use-autocomplete"
                    placeholder={cityLabel}
                    data-testid="birth_city"
                    addonBefore={
                        noAddonBefore ? null : (
                            <Popover content={cityLabel}>
                                <InfoCircleOutlined data-testid="birth_city_addon" />
                            </Popover>
                        )
                    }
                />
            </Form.Item>
        </>
    );
};

BirthPlaceSelect.propTypes = propTypes;
BirthPlaceSelect.defaultProps = defaultProps;
export default BirthPlaceSelect;
