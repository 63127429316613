// Libraries
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

// Components
import withNavigation from 'views/welcome/modules/WithNavigation';
import EducationBase from './components/EducationBase';
import EducationDetails from './components/EducationDetails';

// Actions & Selectors
import { setTrackPageOrder, patchInformation, patchWelcomeSession } from 'views/welcome/WelcomeActions';
import {
    getIsFetchingSilent,
    getInformation,
    getNextTrack,
    getPrevTrack,
    getEducations,
    getDegrees,
    getSpecializations,
    getSettings,
    getOnboardingType,
    getApplicant,
} from 'views/welcome/WelcomeSelectors';
import { updateAddressError } from 'base/BaseActions';

// Modules
import withSettingsChecker from 'views/welcome/modules/WithSettingsChecker';

const mapStateToProps = (state) => ({
    isFetchingSilent: getIsFetchingSilent(state),
    information: getInformation(state),
    educations: getEducations(state),
    degrees: getDegrees(state),
    specializations: getSpecializations(state),
    nextTrack: getNextTrack(state, 'education'),
    prevTrack: getPrevTrack(state, 'education'),
    settings: getSettings(state),
    applicant: getApplicant(state),
    onboardingType: getOnboardingType(state),
});

const mapDispatchToProps = (dispatch) => ({
    dispatch,
    ...bindActionCreators(
        {
            patchInformation,
            patchWelcomeSession,
            setTrackPageOrder,
            updateAddressError,
        },
        dispatch
    ),
});

const propTypes = {
    // Redux Actions:
    updateAddressError: PropTypes.func.isRequired,
    patchInformation: PropTypes.func.isRequired,
    patchWelcomeSession: PropTypes.func.isRequired,
    setTrackPageOrder: PropTypes.func.isRequired,
    // Redux Store:
    isFetchingSilent: PropTypes.bool.isRequired,
    information: PropTypes.object.isRequired,
    educations: PropTypes.array,
    applicant: PropTypes.object,
    degrees: PropTypes.array,
    specializations: PropTypes.array,
    nextTrack: PropTypes.string,
    prevTrack: PropTypes.string,
};
const defaultProps = {
    applicant: {},
    educations: [],
    degrees: [],
    specializations: [],
    nextTrack: 'education',
    prevTrack: 'education',
};

class Education extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            trackPageLocation: 'base',
            educationPageLocation: 'base',
            currentEducationIndex: 0,
            educationSelected: false,
            pageOrder: ['base'],
            highestLevelOnly: props.applicant.education_verification_level === 'HIGHEST',
            resetObj: {
                educations: [],
            },
            institutions: {},
        };
        window.scrollTo(0, 0);
        props.setTrackPageOrder(['base', 'educations']);
    }

    updateInstitutions = (country, educationalInstitutions) => {
        this.setState((state) => ({
            institutions: { ...state.institutions, [country]: educationalInstitutions },
        }));
    };

    deleteEducation = (educationIndex) => {
        const newEducations = this.props.educations;
        newEducations.splice(educationIndex, 1);
        this.props.patchInformation({ educations: newEducations });
    };

    // Provide an index to educations array if you want to edit an existing entry
    addOrEditEducation = (educationIndex = null) => {
        const { handleForward } = this.props; /* WithNavigation */
        const { educations } = this.props;
        let newEducationIndex = educationIndex;
        if (educationIndex === null) {
            newEducationIndex = educations && educations.length;
        }
        this.setState({ currentEducationIndex: newEducationIndex }, handleForward);
    };

    patchNewFormValues = (values) => {
        const { handleForward } = this.props; /* WithNavigation */
        this.props.patchInformation({ educations: values }).then(handleForward);
    };

    handleUpdateHighest = (id) => {
        const { educations } = this.props;
        educations.forEach((education) => {
            education.highest_level = false;
            if (education.id === id) education.highest_level = true;
        });
        this.props.patchInformation({ educations });
    };

    pages = () => ({
        base: (
            <EducationBase
                handleUpdate={this.handleUpdateHighest}
                handleSubmit={this.props.runSettingsCheck}
                isFetchingSilent={this.props.isFetchingSilent}
                educations={this.props.educations}
                addOrEditEducation={this.addOrEditEducation}
                deleteEducation={this.deleteEducation}
                setValue={this.setValue}
                intl={this.props.intl}
                highestLevelOnly={this.state.highestLevelOnly}
            />
        ),
        educations: (
            <EducationDetails
                handleSubmit={this.patchNewFormValues}
                isFetchingSilent={this.props.isFetchingSilent}
                educations={this.props.educations}
                degrees={this.props.degrees}
                institutions={this.state.institutions}
                updateInstitutions={this.updateInstitutions}
                specializations={this.props.specializations}
                currentEducationIndex={this.state.currentEducationIndex}
                updateAddressError={this.props.updateAddressError}
                highestLevelEntryExists={this.highestLevelEntryExists}
                highestLevelOnly={this.state.highestLevelOnly}
            />
        ),
    });

    render() {
        return this.pages()[this.props.trackPageLocation];
    }
}

Education.propTypes = propTypes;
Education.defaultProps = defaultProps;

export default withLDConsumer()(
    withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(withNavigation(withSettingsChecker(Education)))))
);
