// Libraries
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Collapse } from 'antd';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { get } from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
// Components
import Loader from 'certn-ui/Loader';
import LoadingPage from 'certn-ui/LoadingPage';
import Titlebar from 'certn-ui/Titlebar';
import { ReportBottomPanel, CertnReport } from 'views/manager/components';
import ApplicationTitleBar from './components/ApplicationTitleBar';
import CommentsSidebar from './components/CommentsSidebar';

import AdjudicationSidebar from './components/AdjudicationSidebar/AdjudicationSidebar';
// Actions & Selectors
import { clearCSVLink } from 'views/manager/sharedActions/ApplicationActions';
import {
    fetchApplication,
    fetchReport,
    setApplicationStatus,
} from 'views/manager/views/hr/views/applications/ApplicationsActions';
import { fetchAdverseActionDetails } from 'views/manager/features/AdverseActionDrawer/DrawerActions';
import { getIsFetching, getReport } from 'views/manager/views/hr/views/applications/ApplicationsSelectors';
import { useApplication } from 'utils/hooks/useApplication';
import { getTeam, getUserInfo } from 'base/BaseSelectors';
import { getApplicantAdjudicationChecks } from './components/AdjudicationSidebar/AdjudicationRequests';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { shouldShowAjudicationSidebar } from './components/AdjudicationSidebar/utils';
import { currentUserIsAdjudicator, getReportIsCurrentlyViewable } from './utils';

const ReportContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const ApplicationsView = () => {
    const dispatch = useDispatch();
    const { applicantId } = useParams();
    const intl = useIntl();

    const isFetching = useSelector(getIsFetching);
    const report = useSelector(getReport);
    const team = useSelector(getTeam);
    const userCanViewReports = useSelector(getUserInfo)?.can_view_reports;
    const { webFeatureAdjudicationAllowPartialAdjudication } = useFlags();

    const [applicantHasNoReport, setApplicantHasNoReport] = useState(false);
    const [sidebarLoading, setSidebarLoading] = useState(true);
    const [sidebarCollapsed, setSidebarCollapsed] = useState(true);
    const [upgradingReport, setUpgradingReport] = useState(false);
    const [applicationAdjudicationChecks, setApplicantChecks] = useState([]);
    const { application } = useApplication();
    const { Panel } = Collapse;
    const SummaryOfRightsHeader = intl.formatMessage({
        id: 'report.sorHeader',
        defaultMessage: 'Summary of Rights',
    });
    const Article23aHeader = intl.formatMessage({
        id: 'report.article23aHeader',
        defaultMessage: 'Article 23-A ',
    });
    const [reportIsCurrentlyViewable, setReportIsCurrentlyViewable] = useState(false);

    const applicantHasAdjudication = () => !!application?.applicant_adjudication;
    const adjudicationIsEnabled = currentUserIsAdjudicator() && applicantHasAdjudication();
    const adjudicationIsEnabledForApplication = applicantHasAdjudication();
    const partialAdjudicationEnabled =
        team?.superteam?.enable_partial_adjudication && webFeatureAdjudicationAllowPartialAdjudication;
    const showAdjudicationSidebar = shouldShowAjudicationSidebar(
        applicationAdjudicationChecks,
        application.check_executions,
        partialAdjudicationEnabled && adjudicationIsEnabled ? 'partial' : adjudicationIsEnabled ? 'full' : 'none'
    );

    useEffect(() => {
        try {
            setReportIsCurrentlyViewable(
                getReportIsCurrentlyViewable(
                    partialAdjudicationEnabled,
                    adjudicationIsEnabledForApplication,
                    application.check_executions,
                    application.applicant_adjudication
                )
            );
        } catch (error) {
            setReportIsCurrentlyViewable();
        }
    }, [application, partialAdjudicationEnabled, adjudicationIsEnabledForApplication]);

    useEffect(() => {
        const loadReport = async () => {
            try {
                await dispatch(fetchReport(applicantId));
            } catch (error) {
                setApplicantHasNoReport(true);
            }
        };

        const loadApplication = async () => {
            try {
                await dispatch(fetchApplication(applicantId));
            } catch (error) {
                // TODO we don't handle this error yet
            }
            setSidebarLoading(false);
        };

        loadReport();
        loadApplication();
        getApplicantAdjudicationChecks(applicantId)
            .then((response) => {
                setApplicantChecks(response);
            })
            .catch(() => {});

        return () => {
            dispatch(clearCSVLink());
            return () => {
                window.removeEventListener('scroll', onScroll);
                window.removeEventListener('resize', onResize);
            };
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [applicantId, dispatch]);

    useEffect(() => {
        const loadAdverseActionDetails = async () => {
            try {
                await dispatch(fetchAdverseActionDetails(applicantId));
            } catch (error) {
                console.error(error);
            }
        };
        application?.is_both_pa_and_aa_available && loadAdverseActionDetails();
    }, [application, dispatch, applicantId]);

    // TODO can this be moved? Only being used as prop for CertnReport component
    const onScroll = () => {
        if (
            get(window, ['frames', 'ifr', 'document', 'documentElement', 'scrollTop']) &&
            get(window, ['frames', 'ifr', 'document', 'body', 'scrollTop'])
        ) {
            window.frames.ifr.document.documentElement.scrollTop = window.pageYOffset;
            window.frames.ifr.document.body.scrollTop = window.pageYOffset;
        }
    };

    const onResize = () => {
        if (get(window, ['frames', 'ifr', 'document', 'body', 'offsetHeight'])) {
            setTimeout(() => {
                const container = window.document.getElementById('iframe_container');
                if (container) container.style.height = `${58 + window.frames.ifr.document.body.offsetHeight}px`;
            }, 100);
            setTimeout(() => {
                const frame = window.document.getElementById('certnFrame');
                if (frame) frame.style.height = `${58 + window.frames.ifr.document.body.offsetHeight}px`;
            }, 100);
        }
    };

    const handleSetApplicationStatus = (applicantID, moveStatus) =>
        dispatch(setApplicationStatus(applicantID, moveStatus));

    if (!userCanViewReports)
        return (
            <>
                <Titlebar
                    title={
                        <FormattedMessage
                            id="6cb79.ApplicationsView.reportAccessDenied"
                            defaultMessage="Report unavailable"
                        />
                    }
                />
                <FormattedMessage
                    id="6cb79.ApplicationsView.reportAccessDeniedNoPermissionsDescription"
                    defaultMessage="You don't have the right permissions to view reports. Contact your admin for access."
                />
            </>
        );

    if (reportIsCurrentlyViewable === undefined) return <Loader />;

    if (applicantHasNoReport) {
        return (
            <>
                <Titlebar
                    title={
                        <FormattedMessage
                            id="6cb79.ApplicationsView.applicantResultNameUndefined"
                            defaultMessage="Applicant Results"
                        />
                    }
                />
                <FormattedMessage
                    id="6cb79.ApplicationsView.applicantHasNoReport"
                    defaultMessage="Applicant has not started report. There is nothing to display."
                />
            </>
        );
    }

    if (!reportIsCurrentlyViewable) {
        return (
            <>
                <Titlebar
                    title={
                        <FormattedMessage
                            id="6cb79.ApplicationsView.reportAccessDenied"
                            defaultMessage="Report unavailable"
                        />
                    }
                />
                <FormattedMessage
                    id="6cb79.ApplicationsView.reportAccessDeniedDescription"
                    defaultMessage="You cannot view the report at this time"
                />
            </>
        );
    }

    if (upgradingReport && application.is_equifax_eligible) {
        return (
            <LoadingPage
                fetch={() => dispatch(fetchApplication)}
                fetchId={applicantId}
                completeCondition="Returned"
                errorCondition="Error"
                completeAction={setUpgradingReport(false)}
                titles={{
                    1: (
                        <FormattedMessage
                            id="6cb79.ApplicationsView.title1"
                            defaultMessage="Establishing connection with Equifax servers"
                        />
                    ),
                    2: (
                        <FormattedMessage
                            id="6cb79.ApplicationsView.title2"
                            defaultMessage="Scanning databases for possible matches"
                        />
                    ),
                    3: (
                        <FormattedMessage
                            id="6cb79.ApplicationsView.title3"
                            defaultMessage="Acquiring matching Equifax records"
                        />
                    ),
                    4: (
                        <FormattedMessage
                            id="6cb79.ApplicationsView.title4"
                            defaultMessage="Unpacking server response"
                        />
                    ),
                }}
            />
        );
    }

    const showLoader = (isFetching && !sidebarLoading) || !(report && report.html) || !application.id;
    const isDesktopView = window.matchMedia('(min-width: 1024px)').matches;

    return (
        <>
            {showLoader ? (
                <Loader />
            ) : (
                <>
                    <ApplicationTitleBar
                        application={application}
                        sidebarLoading={sidebarLoading}
                        sidebarCollapsed={sidebarCollapsed}
                        toggleSidebar={() => setSidebarCollapsed((prevState) => !prevState)}
                        onResize={onResize}
                        isDesktopView={isDesktopView}
                    />
                    {isDesktopView && (
                        <div style={{ display: 'flex' }}>
                            <ReportContainer
                                style={{
                                    width: showAdjudicationSidebar || !sidebarCollapsed ? 'calc(100% - 225px)' : '100%',
                                    zIndex: '1',
                                }}
                            >
                                <CertnReport
                                    onScroll={onScroll}
                                    onResize={onResize}
                                    report={report.html}
                                    style={{ width: '250px' }}
                                />
                                {application?.should_attach_us_summary_of_rights && (
                                    <div style={{ margin: '0px 0px 40px 0px' }}>
                                        <Collapse expandIconPosition="right">
                                            <Panel header={SummaryOfRightsHeader} key="1">
                                                <object
                                                    style={{ height: '500px', width: '100%' }}
                                                    type="application/pdf"
                                                    data={application?.summary_of_rights_url}
                                                    aria-label={SummaryOfRightsHeader}
                                                />
                                            </Panel>
                                        </Collapse>
                                    </div>
                                )}
                                {application?.should_attach_article_23a && (
                                    <div style={{ margin: '0px 0px 40px 0px' }}>
                                        <Collapse expandIconPosition="right">
                                            <Panel header={Article23aHeader} key="1">
                                                <object
                                                    style={{ height: '500px', width: '100%' }}
                                                    type="application/pdf"
                                                    data={application?.article_23a_url}
                                                    aria-label={Article23aHeader}
                                                />
                                            </Panel>
                                        </Collapse>
                                    </div>
                                )}
                                {application.activity_log && application.activity_log.length > 0 && (
                                    <ReportBottomPanel
                                        activityLog={application.activity_log}
                                        adjudicationStatus={application.adjudication_status}
                                        adjudicationStatusLabel={application.adjudication_status_label}
                                        status={application.report_summary.report_result}
                                        applicantId={application.id}
                                        setApplicationStatus={handleSetApplicationStatus}
                                    />
                                )}
                            </ReportContainer>
                            {showAdjudicationSidebar ? (
                                <AdjudicationSidebar applicantId={application.id} usersTeam={team} />
                            ) : (
                                <CommentsSidebar
                                    sidebarCollapsed={sidebarCollapsed}
                                    toggleSidebar={() => setSidebarCollapsed((prevState) => !prevState)}
                                    applicantId={application.id}
                                    comments={application.comments}
                                />
                            )}
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default ApplicationsView;
