// Libraries
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { injectIntl } from 'react-intl';

// Components
import withNavigation from 'views/welcome/modules/WithNavigation';
import { BaseForm, ConvictionsForm } from './components';

// Actions & Selectors
import { patchInformation, setTrackPageOrder } from 'views/welcome/WelcomeActions';
import { getIsFetchingSilent, getInformation, getOnboardingType } from 'views/welcome/WelcomeSelectors';

const mapStateToProps = (state) => ({
    isFetchingSilent: getIsFetchingSilent(state),
    information: getInformation(state),
    onboardingType: getOnboardingType(state),
});

const mapDispatchToProps = (dispatch) => ({
    dispatch,
    ...bindActionCreators(
        {
            patchInformation,
            setTrackPageOrder,
        },
        dispatch
    ),
});

const propTypes = {
    // Redux Actions:
    patchInformation: PropTypes.func.isRequired,
    setTrackPageOrder: PropTypes.func.isRequired,
    // Redux Store:
    isFetchingSilent: PropTypes.bool.isRequired,
    information: PropTypes.object.isRequired,
    onboardingType: PropTypes.string.isRequired,
    applicantAccount: PropTypes.object,
    loginError: PropTypes.object,
};
const defaultProps = {
    applicantAccount: {},
    loginError: undefined,
};

class RcmpReflow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            resetObj: {
                convictions: { convictions: [] },
            },
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    handleSubmit = (e, validateFields) => {
        e.preventDefault();
        validateFields((err, values) => {
            if (!err) {
                this.patchNewFormValues(values);
            } else {
                // provide failure error
            }
        });
    };

    patchNewFormValues = (values) => {
        const { handleNextTrack } = this.props; /* WithNavigation */

        this.props.patchInformation(values).then(handleNextTrack);
    };

    buildPageOrder = (e, validateFields) => {
        e.preventDefault();
        const { handleForward, handleNextTrack } = this.props; /* WithNavigation */

        validateFields((err, values) => {
            if (!err) {
                // Figure out which pages to go to during track
                const pagesToAdd = Object.keys(values).filter((key) => values[key]);
                const newPageOrder = ['base'].concat(pagesToAdd);

                // Figure out which objects to delete during patch
                const pagesToDelete = Object.keys(values).filter((key) => !values[key]);
                let resetObject = {};
                pagesToDelete.forEach((page) => {
                    resetObject = { ...resetObject, ...this.state.resetObj[page] };
                });
                this.props.setTrackPageOrder(newPageOrder);
                this.props.patchInformation(resetObject).then(() =>
                    /* When zero cards toggled, go to next track instead of return to base */
                    newPageOrder.length === 1 ? handleNextTrack() : handleForward()
                );
            } else {
                // provide failure error
            }
        });
    };

    pages = () => ({
        base: (
            <BaseForm
                convictions={get(this.props, ['information', 'convictions']) || []}
                handleSubmit={this.buildPageOrder}
                isFetchingSilent={this.props.isFetchingSilent}
                selectOptions={this.state.selectOptions}
                intl={this.props.intl}
            />
        ),
        convictions: (
            <ConvictionsForm
                convictions={get(this.props, ['information', 'convictions']) || []}
                isFetchingSilent={this.props.isFetchingSilent}
                handleSubmit={this.patchNewFormValues}
                intl={this.props.intl}
            />
        ),
    });

    render() {
        return this.pages()[this.props.trackPageLocation]; /* WithNavigation */
    }
}

RcmpReflow.propTypes = propTypes;
RcmpReflow.defaultProps = defaultProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(withNavigation(RcmpReflow))));
