import CreditCardOutlined from '@ant-design/icons/lib/icons/CreditCardOutlined';
import { useIntl } from 'react-intl';
import { OnboardingStep } from '../OnboardingStep';

type OnboardingPaymentStepProps = {
    completed?: boolean;
};

export const OnboardingPaymentStep: React.FC<OnboardingPaymentStepProps> = ({ completed }) => {
    const intl = useIntl();

    return (
        <OnboardingStep
            title={intl.formatMessage({
                id: 'onboarding.setupPaymentMethod',
                defaultMessage: 'Setup Payment Method',
            })}
            subText={intl.formatMessage({
                id: 'onboarding.setupPaymentMethodDescription',
                defaultMessage: 'All payments securely processed by Stripe',
            })}
            completed={!!completed}
            icon={<CreditCardOutlined />}
        />
    );
};
