import styled from 'styled-components';

export const AccessFeeDisclaimerText = styled.div`
    width: 100%;
    font-size: 13px;
    margin: 0 0 10px 0;
    padding: 0 20px;
    text-align: center;
    text-decoration: underline;
    font-style: italic;
    color: ${({ theme }) => theme.color.certnGray500};
`;
