import { ErrorAlertAPI, ErrorAlertCustom } from 'certn-ui/ErrorAlert';
import { TextLinkGray } from 'certn-ui/Text';
import { buildApplicationFileName, downloadFile } from 'modules/files';
import { parseResponse } from 'modules/Requests';
import { FormattedMessage } from 'react-intl';
import { UserApplicant } from 'types';

type DownloadReportProps = {
    applicant: UserApplicant;
};

export const DownloadReport: React.FC<DownloadReportProps> = ({ applicant }) => {
    const download = async () => {
        let url: string | null | undefined = applicant.rcmp_result?.rcmp_result_document;
        if (applicant.request_australian_criminal_intelligence_commission_check) {
            url = applicant.australian_criminal_intelligence_commission_check_result?.certificate_url;
        }

        if (!url) {
            console.error('Unable to download report. Missing report URL.');
            ErrorAlertCustom();
            return;
        }

        const fileName = buildApplicationFileName(applicant.application);

        try {
            const response = await fetch(url);
            const parsed = await parseResponse(response);
            downloadFile({ fileContents: parsed, fileName, fileType: 'application/pdf' });
        } catch (error) {
            ErrorAlertAPI(error);
        }
    };

    return (
        <TextLinkGray onClick={download}>
            <FormattedMessage id="applicant.Review.downloadReport" defaultMessage="Download Report" />
        </TextLinkGray>
    );
};
