// Libraries
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import HelloSign from 'hellosign-embedded';
import { Modal, message as AntdMessage } from 'antd';
import { useIntl } from 'react-intl';

// Components
import { ErrorAlertCustom } from 'certn-ui/ErrorAlert';
import styles from 'styles/styles';

// Modules & Utils
import { postRequest } from 'utils/http';

const client = new HelloSign();

const HelloSignEmbed = ({ isOpen, endpointURL, onClose, onFinish }) => {
    const intl = useIntl();

    const [docsReady, setDocsReady] = useState(false);

    const pdfRef = useRef();

    useEffect(() => {
        const getSigningURL = async () => {
            try {
                const { url, message } = await postRequest({
                    version: 'v1',
                    endpoint: endpointURL,
                });

                const helloSignDevClientId = 'a5ce6ca187d9115309eb216ee8778507';
                const helloSignProdClientId = '2cc4624fbb7d6fb33d0c0b5f81870f6a';
                const clientId =
                    process.env.REACT_APP_SERVER === 'development' ? helloSignDevClientId : helloSignProdClientId;

                if (url) {
                    client.open(url, {
                        clientId,
                        skipDomainVerification: true,
                        container: pdfRef.current,
                        whiteLabeling: {
                            header_background_color: styles.color.certnGray900,
                            text_color1: styles.color.certnGray900,
                            text_color2: styles.color.certnWhite,
                            primary_button_color: styles.color.certnGreen700,
                            secondary_button_color: styles.color.certnGreen700,
                            secondary_button_text_color: styles.color.certnWhite,
                            link_color: styles.color.certnGreen700,
                            legal_version: 'terms2',
                        },
                    });
                    setDocsReady(true);
                } else {
                    // If we don't get a signing url in a 200 response, the docs are already signed
                    AntdMessage.success(message);
                    onFinish();
                }
            } catch (error) {
                ErrorAlertCustom({
                    title: 'Failed to load documents',
                    description: 'Please reload the page. If you cannot proceed please contact support.',
                });
            }
        };

        if (isOpen && !docsReady) {
            getSigningURL();
        } else if (docsReady) {
            client.on('sign', () => {
                onFinish();
                client.close();
                AntdMessage.success(
                    intl.formatMessage({
                        id: 'welcome.Disclosure.documentsSignedSuccess',
                        defaultMessage: 'Documents signed',
                    })
                );
            });

            client.on('cancel', () => {
                onClose();
                setDocsReady(false);
            });
            client.on('error', () => {
                onClose();
                setDocsReady(false);
                ErrorAlertCustom({
                    title: 'Failed to load documents',
                    description: 'Please reload the page. If you cannot proceed please contact support.',
                });
            });
        }
    }, [docsReady, intl, isOpen, onClose, onFinish, endpointURL]);

    return isOpen && docsReady && <Modal ref={pdfRef} data-testid="disclosure_hello_sign" />;
};

HelloSignEmbed.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    endpointURL: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    onFinish: PropTypes.func.isRequired,
};

export default HelloSignEmbed;
