import PropTypes from 'prop-types';

export const formPropTypes = PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    getFieldError: PropTypes.func.isRequired,
    getFieldsError: PropTypes.func.isRequired,
    getFieldsValue: PropTypes.func.isRequired,
    getFieldValue: PropTypes.func.isRequired,
    isFieldsTouched: PropTypes.func.isRequired,
    isFieldTouched: PropTypes.func.isRequired,
    isFieldValidating: PropTypes.func.isRequired,
    resetFields: PropTypes.func.isRequired,
    setFields: PropTypes.func.isRequired,
    setFieldsValue: PropTypes.func.isRequired,
    validateFields: PropTypes.func.isRequired,
    validateFieldsAndScroll: PropTypes.func.isRequired,
});

export const formOptionsPropTypes = PropTypes.shape({
    getValueFromEvent: PropTypes.func,
    getValueProps: PropTypes.func,
    initialValue: PropTypes.any,
    normalize: PropTypes.func,
    preserve: PropTypes.bool,
    rules: PropTypes.array,
    trigger: PropTypes.string,
    validateFirst: PropTypes.bool,
    validateTrigger: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    valuePropName: PropTypes.string,
});
