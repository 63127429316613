import styled from 'styled-components';

export const TotalWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`;

export const TotalCurrencyWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Title = styled.div<{ uppercase: boolean; bolded?: boolean }>`
    font-size: ${({ theme }) => theme.font.xs};
    font-weight: ${({ bolded }) => (bolded ? 600 : 'initial')};
    ${({ uppercase }) => uppercase && 'text-transform: uppercase;'};
`;

export const Amount = styled.span<{ bolded?: boolean }>`
    font-weight: ${({ bolded }) => (bolded ? 600 : 'initial')};
`;
