// Libraries
import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Row, Col } from 'antd';
import { useSelector } from 'react-redux';

// Actions & Selectors
import {
    getTeamIsMyCRC,
    getPrepopulatedFields,
    getSettings,
    getApplicant,
    getIsUkDbsOrDsCheck,
    getIsUkRtwCheck,
    getIsAustraliaACICCheck,
    getIsSolelyUkRTWCheck,
} from 'views/welcome/WelcomeSelectors';

// Components
import { Box } from 'certn-ui/Layout';
import Text from 'certn-ui/Text';
import Title from 'certn-ui/Title';
import { AppearRight } from 'certn-ui/Animate';
import MultiAdd, { MultiAddItem, EmptyListContainer } from 'views/welcome/components/MultiAdd';
import { TrackNavigation } from 'views/welcome/components';

// Modules
import { CheckRequested } from 'modules';
import { getLanguage } from 'base/BaseSelectors';
import { formatDate } from 'modules/Format';
import { transformForUk } from 'certn-form/AddressAutoComplete';

function getAddressLabel(address, isUkCheck) {
    if (address.auto_address) {
        return address.auto_address;
    }
    if (isUkCheck) {
        const { address_line_1, address_line_2 } = transformForUk(address);
        if (address_line_1)
            if (address_line_2) {
                return `${address_line_1}, ${address_line_2}`;
            } else {
                return address_line_1;
            }
    }

    return (
        address.address || (
            <FormattedMessage id="welcome.Addresses.streetAddressMissing" defaultMessage="- Street Address Missing -" />
        )
    );
}

const CustomLink = styled.span`
    color: ${({ theme }) => theme.color.certnGreen700};
    padding: 0px !important;
    font-size: 16px !important;
    font-weight: 300 !important;
    cursor: pointer;
`;

const Base = ({
    handleSubmit,
    handleUpdate,
    addresses,
    addOrEditAddress,
    deleteAddress,
    onboardingType,
    intl,
    requiredYears,
    isUkCheck,
}) => {
    const teamIsMyCRC = useSelector(getTeamIsMyCRC);
    const settings = useSelector(getSettings);
    const applicant = useSelector(getApplicant);
    const prepopulatedFields = useSelector(getPrepopulatedFields);
    const requests = applicant || settings;

    const min = requests.address_references_min;
    const max = requests.address_references_max;
    const years = requests.address_references_years;

    const canRefReq = CheckRequested.canadianCriminal(requests);
    const usRefReq = CheckRequested.usCriminal(requests);
    const addRefReq = CheckRequested.addressReference(requests);
    const isUkDbsOrDsCheck = useSelector(getIsUkDbsOrDsCheck);
    const isUkRtwCheck = useSelector(getIsUkRtwCheck);
    const isAustraliaACICCheck = useSelector(getIsAustraliaACICCheck);
    const solelyUkRtwCheck = useSelector(getIsSolelyUkRTWCheck);

    // US and Canadian Crim checks require an address in each respective country
    // If client only wants 1 address but both US/CAN criminal checks the applicant encouters an error therefor increase max to 2
    // If these params equal false it checks for absolute number or range (date range or number range)
    // If date range: max === null, number range: min !== max therefore both false leading to alternate helper text
    const numOfAddReq = usRefReq && canRefReq && max === 1 ? 2 : min === max ? max : false;
    const language = useSelector(getLanguage);
    return (
        <AppearRight>
            <Title>
                {solelyUkRtwCheck ? (
                    <FormattedMessage id="welcome.Addresses.currentAddress" defaultMessage="Current Address" />
                ) : (
                    <FormattedMessage id="welcome.Addresses.addressHistory" defaultMessage="Address history" />
                )}
            </Title>
            {(onboardingType === 'HR' || requiredYears > 0) && !(teamIsMyCRC && !isAustraliaACICCheck) && (
                <Row gutter={16}>
                    <Col sm={24} lg={{ span: 24, offset: 0 }}>
                        <Box size="xxs">
                            <Text align="center" color="certnGray600">
                                {requiredYears > 0 ? (
                                    <FormattedMessage
                                        id="welcome.Addresses.internationalYears"
                                        defaultMessage="Please provide <b>{requiredYears}</b> years of address history, starting from <b>{fromDate}</b> to <b>today</b>."
                                        values={{
                                            b: (chunks) => <strong>{chunks}</strong>,
                                            requiredYears,
                                            fromDate: `${new Date().toLocaleString(language, { month: 'long' })} ${
                                                new Date().getFullYear() - requiredYears
                                            }`,
                                        }}
                                    />
                                ) : solelyUkRtwCheck ? (
                                    <FormattedMessage
                                        id="welcome.Addresses.currentAddress.body"
                                        defaultMessage="Please provide your current address."
                                    />
                                ) : (
                                    <FormattedMessage
                                        id="welcome.Addresses.upToFiveYears"
                                        defaultMessage="Please provide up to 5 years of address history, starting from {currentMonth} {yearFiveYearsAgo} to today."
                                        values={{
                                            yearFiveYearsAgo: new Date().getFullYear() - 5,
                                            currentMonth: new Date().toLocaleString(language, { month: 'long' }),
                                        }}
                                    />
                                )}
                            </Text>
                        </Box>
                    </Col>
                </Row>
            )}
            {onboardingType === 'PM' && (
                <Text align="center" color="certnGray600">
                    {numOfAddReq ? (
                        <FormattedMessage
                            id="welcome.Addresses.absolute"
                            defaultMessage="Please enter {req} {country} {plural}"
                            values={{
                                req: numOfAddReq,
                                country:
                                    usRefReq && canRefReq
                                        ? ''
                                        : canRefReq && numOfAddReq === 1
                                        ? 'Canadian'
                                        : usRefReq && numOfAddReq === 1
                                        ? 'US'
                                        : '',
                                plural: (
                                    <span style={{ textTransform: 'lowercase' }}>
                                        {numOfAddReq > 1 ? (
                                            <FormattedMessage
                                                id="welcome.general.addresses"
                                                defaultMessage="Addresses"
                                            />
                                        ) : (
                                            <FormattedMessage id="welcome.general.address" defaultMessage="Address" />
                                        )}
                                    </span>
                                ),
                            }}
                        />
                    ) : years ? (
                        <FormattedMessage
                            id="welcome.Addresses.years"
                            defaultMessage="Please give up to {years} years of address history"
                            values={{ years }}
                        />
                    ) : (
                        <FormattedMessage
                            id="welcome.Addresses.range"
                            defaultMessage="Please enter {min}-{max} addresses"
                            values={{ min, max }}
                        />
                    )}
                    {addRefReq && (
                        <FormattedMessage
                            id="welcome.Addresses.withLandlordRef"
                            defaultMessage=" with a landlord reference"
                        />
                    )}
                    {(canRefReq || usRefReq) && numOfAddReq > 1 && (
                        <FormattedMessage
                            id="welcome.Addresses.northAmericaCrimChecks"
                            defaultMessage=" (At least {can}{and}{us} address is required)"
                            values={{
                                and: usRefReq && canRefReq ? ' & ' : '',
                                can: canRefReq ? (
                                    <FormattedMessage id="welcome.Addresses.canadian" defaultMessage="1 Canadian" />
                                ) : (
                                    ''
                                ),
                                us: usRefReq ? (
                                    <FormattedMessage id="welcome.Addresses.us" defaultMessage="1 US" />
                                ) : (
                                    ''
                                ),
                            }}
                        />
                    )}
                </Text>
            )}
            <MultiAdd
                title={
                    teamIsMyCRC || solelyUkRtwCheck ? (
                        <FormattedMessage id="welcome.general.address" defaultMessage="Address" />
                    ) : (
                        <FormattedMessage id="welcome.general.addresses" defaultMessage="Addresses" />
                    )
                }
                buttonTitle={<FormattedMessage id="welcome.Addresses.addAddress" defaultMessage="ADD ADDRESS" />}
                buttonAction={() => addOrEditAddress(null)}
                disableAdd={(teamIsMyCRC || solelyUkRtwCheck) && addresses.length === 1 && !isAustraliaACICCheck}
                testKey="address"
            >
                {addresses && addresses.length > 0 ? (
                    addresses.map((address, index) => (
                        <MultiAddItem
                            key={address.id}
                            top={getAddressLabel(address, isUkDbsOrDsCheck || isUkRtwCheck)}
                            bottom={
                                requiredYears > 0
                                    ? // Once feature flag is enabled and start/end dates are being required,
                                      // this n/a logic can be cleaned up. This is just a interim solution to
                                      // prevent null from showing up in the web.
                                      `${address.start_date ? formatDate(address.start_date) : 'n/a'} - ${
                                          address.current
                                              ? intl.formatMessage({
                                                    id: 'welcome.Addresses.current',
                                                    defaultMessage: 'Current',
                                                })
                                              : address.end_date
                                              ? formatDate(address.end_date)
                                              : 'n/a'
                                      }`
                                    : address.current
                                    ? intl.formatMessage({
                                          id: 'welcome.Addresses.currentAddress',
                                          defaultMessage: 'Current Address',
                                      })
                                    : !isUkCheck && (
                                          <CustomLink onClick={() => handleUpdate(address.id)} type="link">
                                              {intl.formatMessage({
                                                  id: 'welcome.Addresses.setCurrentAddress',
                                                  defaultMessage: 'Set as Current',
                                              })}
                                          </CustomLink>
                                      )
                            }
                            onEdit={() => addOrEditAddress(index)}
                            onDelete={() => deleteAddress(index)}
                            testKey="address"
                            canDelete={
                                addresses.length !== 1 && !(prepopulatedFields.includes('address') && address.current)
                            }
                        />
                    ))
                ) : (
                    <EmptyListContainer>
                        <FormattedMessage id="welcome.Addresses.noAddresses" defaultMessage="No addresses added yet." />
                    </EmptyListContainer>
                )}
            </MultiAdd>
            <TrackNavigation handleSubmit={handleSubmit} />
        </AppearRight>
    );
};

export default injectIntl(Base);
