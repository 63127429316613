import React, { useState } from 'react';
import { Select, Input, Popover, Empty, Form } from 'antd';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { InfoCircleOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

const { Option } = Select;

const OptionNotFound = styled.div`
    border-top: 2px solid ${({ theme }) => theme.color.certnGray100};
    padding: 5px 12px;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.85);
`;

const ManualSelect = styled.div`
    margin-bottom: '0px';
`;

/**
 * Select with option not found button that displays a manual input box when clicked
 * Used when candidates need to manually enter information that might not be listed in the dropdown
 */
const ManualAddSelect = ({
    listItems,
    notFoundMessage,
    placeholder,
    initialValue,
    setValue,
    form,
    required,
    decoratorName,
    tooltip,
    selectStyles,
    size,
}) => {
    const [showCustomField, setShowCustomField] = useState(false);
    const intl = useIntl();

    // name is used for value if the data doesn't specify a different value
    const updateValue = ({ name, value = null, showCustom = false }) => {
        if (showCustom) {
            form.setFieldsValue({ [`${decoratorName}_select`]: notFoundMessage });
        }
        setValue({ name, value: value || name });
        setShowCustomField(showCustom);
    };

    return (
        <ManualSelect style={selectStyles}>
            <Form.Item
                name={`${decoratorName}_select`}
                initialValue={initialValue}
                rules={[
                    {
                        message: intl.formatMessage({
                            id: 'error.validation.notBlank',
                            defaultMessage: 'Please do not leave blank',
                        }),
                    },
                ]}
                hasFeedback
                style={{ marginBottom: '5px' }}
            >
                <Select
                    size={size}
                    showSearch
                    data-testid="manual-select"
                    notFoundContent={Empty}
                    optionFilterProp="children"
                    placeholder={placeholder}
                    onChange={(value, event) => updateValue({ name: event.name, value })}
                    dropdownRender={(menu) => (
                        <>
                            {menu}
                            <OptionNotFound onClick={() => updateValue({ name: null, showCustom: true })}>
                                {notFoundMessage}
                            </OptionNotFound>
                        </>
                    )}
                >
                    {listItems.map((item) => (
                        <Option value={item.value || item.name} key={item.id || item.name} name={item.name}>
                            {item.name}
                        </Option>
                    ))}
                </Select>
            </Form.Item>

            {showCustomField && (
                <Form.Item
                    hasFeedback
                    name={`${decoratorName}_input`}
                    rules={[
                        {
                            required: required && showCustomField,
                            message: intl.formatMessage({
                                id: 'error.validation.notBlank',
                                defaultMessage: 'Please do not leave blank',
                            }),
                        },
                    ]}
                    style={{ marginBottom: '5px' }}
                >
                    <Input
                        size={size}
                        data-testid="manual-input"
                        type="text"
                        autoFocus
                        placeholder={placeholder}
                        onChange={(e) => updateValue({ name: e.target.value, showCustom: true })}
                        addonBefore={
                            <Popover content={tooltip || placeholder}>
                                <InfoCircleOutlined />
                            </Popover>
                        }
                    />
                </Form.Item>
            )}
        </ManualSelect>
    );
};

const propTypes = {
    listItems: PropTypes.array,
    notFoundMessage: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    initialValue: PropTypes.string,
    setValue: PropTypes.func.isRequired,
    required: PropTypes.bool,
    decoratorName: PropTypes.string,
    tooltip: PropTypes.string,
    size: PropTypes.string,
};

const defaultProps = {
    listItems: [],
    initialValue: undefined,
    placeholder: '',
    required: false,
    decoratorName: '',
    tooltip: undefined,
    size: 'large',
};

ManualAddSelect.propTypes = propTypes;
ManualAddSelect.defaultProps = defaultProps;

export default ManualAddSelect;
