// Libraries
import { FormattedMessage } from 'react-intl';

// Components
import { DocumentSerializer } from 'types/serializers';
import SummaryHeader from './SummaryHeader';
import SummaryItem from './SummaryItem';
import { FlexRow, SummaryContainer, SummarySection } from './SummaryStyles';

// Modules
import { formatCountry } from 'modules/Countries';

interface Props {
    document: DocumentSerializer | undefined;
    goToTrack: (track: string) => void;
}

const AustraliaRightToWork = ({ document, goToTrack }: Props) => (
    <SummaryContainer>
        <SummaryHeader
            title={
                <FormattedMessage id="welcome.Summary.australiaRightToWork" defaultMessage="Australia Right To Work" />
            }
            onClick={goToTrack}
        />
        <SummarySection>
            {document ? (
                <FlexRow>
                    <SummaryItem
                        title={<FormattedMessage id="welcome.Summary.documentType" defaultMessage="Document Type" />}
                        text={document.document_type_display || document.document_type}
                        grow
                    />
                    {document.document_number && (
                        <SummaryItem
                            title={
                                <FormattedMessage
                                    id="welcome.Summary.documentNumber"
                                    defaultMessage="Document Number"
                                />
                            }
                            text={document.document_number}
                            grow
                        />
                    )}
                    {document.document_issuing_country && (
                        <SummaryItem
                            title={
                                <FormattedMessage
                                    id="welcome.Summary.documentIssuingCountry"
                                    defaultMessage="Issuing Country"
                                />
                            }
                            text={formatCountry(document.document_issuing_country)}
                            grow
                        />
                    )}
                </FlexRow>
            ) : (
                <SummaryItem
                    empty
                    title={
                        <FormattedMessage
                            id="welcome.Summary.noDocumentsProvided"
                            defaultMessage="You have not provided any documents"
                        />
                    }
                />
            )}
        </SummarySection>
    </SummaryContainer>
);

export default AustraliaRightToWork;
