import styled from 'styled-components';

export const EstimatedTurnAroundTimeText = styled.div`
    width: 100%;
    font-size: 13px;
    margin: 0 0 20px 0;
    padding: 0 20px;
    text-align: center;
    font-style: italic;
    color: ${({ theme }) => theme.color.certnGray500};
`;
