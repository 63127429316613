// Libraries
import React from 'react';
import { useSelector } from 'react-redux';
import Auth from 'modules/Auth';
import { intl } from 'components/GlobalProvider';
import { FormattedMessage } from 'react-intl';

// Actions & Selectors
import { getCurrentTeam } from 'views/manager/views/settings/SettingsSelectors';

// UI Components
import Form from 'certnd/Form';
import Typography from 'certnd/Typography';

const { Heading } = Typography;

const EducationVerification = ({ form }) => {
    const canEdit = Auth.isPermissionLevel('manager');
    const currentTeam = useSelector(getCurrentTeam);
    const verificationLevel = [
        {
            title: intl.formatMessage({ id: 'common.all', defaultMessage: 'All' }),
            key: 'ALL',
            value: 'ALL',
        },
        {
            title: intl.formatMessage({ id: '85c43.EducationVerification.ListHighest', defaultMessage: 'Highest' }),
            key: 'HIGHEST',
            value: 'HIGHEST',
        },
    ];

    return (
        <Form.Block>
            <Heading.H3>
                <FormattedMessage id="common.educationVerification" defaultMessage="Education Verification" />
            </Heading.H3>

            <Form.Select
                form={form}
                fieldName="education_verification_level"
                title={intl.formatMessage({
                    id: '85c43.EducationVerification.SelectTitle',
                    defaultMessage: 'Level of education to verify',
                })}
                description={intl.formatMessage({
                    id: '85c43.EducationVerification.SelectDescription',
                    defaultMessage: 'Should we verify all education provided, or only the highest level?',
                })}
                options={{ initialValue: currentTeam.education_verification_level || 'ALL' }}
                selectOptions={verificationLevel}
                disabled={!canEdit}
            />
            <Form.Button disabled={!canEdit}>
                <FormattedMessage id="common.save" defaultMessage="Save" />
            </Form.Button>
        </Form.Block>
    );
};

export default EducationVerification;
