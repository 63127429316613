import { NAMESPACE as PARENT_NAMESPACE, STATEPATH as PARENT_STATEPATH } from 'views/manager/ManagerConstants';

import { getRequest, patchRequest, postRequest, deleteRequest, putRequest } from 'utils/http';
import { simpleStringifyJSON } from 'modules/Format';
import FileSaver from 'file-saver';
import { ErrorAlertAPI, ErrorAlertCustom } from 'certn-ui/ErrorAlert';
import { ActionTypes } from 'views/manager/views/settings/SettingsConstants';

// Selectors
import { fetchTeamMember, fetchTeam } from 'views/manager/views/admin/AdminActions';
import { fetchUserInfo } from 'base/BaseActions';
import { getTeamId } from 'base/BaseSelectors';

// Messaging
import { message } from 'antd';
import { intl } from 'components/GlobalProvider';

// Constants
export const NAMESPACE = `${PARENT_NAMESPACE}SETTINGS/`;
export const STATEPATH = `${PARENT_STATEPATH}.settings`;

// Actions Types
export const GET_SETTINGS_REQ = `${NAMESPACE}GET_SETTINGS_REQ`;
export const GET_SETTINGS_RES = `${NAMESPACE}GET_SETTINGS_RES`;
export const GET_SETTINGS_ERR = `${NAMESPACE}GET_SETTINGS_ERR`;
export const PATCH_SETTINGS_REQ = `${NAMESPACE}PATCH_SETTINGS_REQ`;
export const PATCH_SETTINGS_RES = `${NAMESPACE}PATCH_SETTINGS_RES`;
export const PATCH_SETTINGS_ERR = `${NAMESPACE}PATCH_SETTINGS_ERR`;
export const SAVE_CARD_TOKEN_REQ = `${NAMESPACE}SAVE_CARD_TOKEN_REQ`;
export const SAVE_CARD_TOKEN_RES = `${NAMESPACE}SAVE_CARD_TOKEN_RES`;
export const SAVE_CARD_TOKEN_ERR = `${NAMESPACE}SAVE_CARD_TOKEN_ERR`;
export const FETCH_TEAM_MEMBERS_REQ = `${NAMESPACE}FETCH_TEAM_MEMBERS_REQ`;
export const FETCH_TEAM_MEMBERS_RES = `${NAMESPACE}FETCH_TEAM_MEMBERS_RES`;
export const FETCH_TEAM_MEMBERS_ERR = `${NAMESPACE}FETCH_TEAM_MEMBERS_ERR`;
export const FETCH_ALL_TEAM_MEMBERS_REQ = `${NAMESPACE}FETCH_ALL_TEAM_MEMBERS_REQ`;
export const FETCH_ALL_TEAM_MEMBERS_RES = `${NAMESPACE}FETCH_ALL_TEAM_MEMBERS_RES`;
export const FETCH_ALL_TEAM_MEMBERS_ERR = `${NAMESPACE}FETCH_ALL_TEAM_MEMBERS_ERR`;
export const FETCH_SUPERTEAM_ADMINS_REQ = `${NAMESPACE}FETCH_SUPERTEAM_ADMINS_REQ`;
export const FETCH_SUPERTEAM_ADMINS_RES = `${NAMESPACE}FETCH_SUPERTEAM_ADMINS_RES`;
export const FETCH_SUPERTEAM_ADMINS_ERR = `${NAMESPACE}FETCH_SUPERTEAM_ADMINS_ERR`;
export const CREATE_GROUP_REQ = `${NAMESPACE}CREATE_GROUP_REQ`;
export const CREATE_GROUP_RES = `${NAMESPACE}CREATE_GROUP_RES`;
export const CREATE_GROUP_ERR = `${NAMESPACE}CREATE_GROUP_ERR`;
export const FETCH_GROUPS_REQ = `${NAMESPACE}FETCH_GROUPS_REQ`;
export const FETCH_GROUPS_RES = `${NAMESPACE}FETCH_GROUPS_RES`;
export const FETCH_GROUPS_ERR = `${NAMESPACE}FETCH_GROUPS_ERR`;
export const GET_TEAM_SETTINGS_RES = `${NAMESPACE}GET_TEAM_SETTINGS_RES`;
export const FETCH_INVOICES_REQ = `${NAMESPACE}FETCH_INVOICES_REQ`;
export const FETCH_INVOICES_RES = `${NAMESPACE}FETCH_INVOICES_RES`;
export const FETCH_INTEGRATIONS_REQ = `${NAMESPACE}FETCH_INTEGRATIONS_REQ`;
export const FETCH_INTEGRATIONS_RES = `${NAMESPACE}FETCH_INTEGRATIONS_RES`;
export const FETCH_INTEGRATIONS_ERR = `${NAMESPACE}FETCH_INTEGRATIONS_ERR`;
export const DISPLAY_ERROR_NOTIFICATION = `${NAMESPACE}GENERIC_INTEGRATIONS_ERR`;
export const FETCH_REFERENCE_TEMPLATES_REQ = `${NAMESPACE}FETCH_REFERENCE_TEMPLATES_REQ`;
export const FETCH_REFERENCE_TEMPLATES_RES = `${NAMESPACE}FETCH_REFERENCE_TEMPLATES_RES`;
export const CREATE_REFERENCE_TEMPLATE_REQ = `${NAMESPACE}CREATE_REFERENCE_TEMPLATE_REQ`;
export const CREATE_REFERENCE_TEMPLATE_RES = `${NAMESPACE}CREATE_REFERENCE_TEMPLATE_RES`;
export const UPDATE_REFERENCE_TEMPLATE_REQ = `${NAMESPACE}UPDATE_REFERENCE_TEMPLATE_REQ`;
export const UPDATE_REFERENCE_TEMPLATE_RES = `${NAMESPACE}UPDATE_REFERENCE_TEMPLATE_RES`;
export const DELETE_REFERENCE_TEMPLATE_REQ = `${NAMESPACE}DELETE_REFERENCE_TEMPLATE_REQ`;
export const DELETE_REFERENCE_TEMPLATE_RES = `${NAMESPACE}DELETE_REFERENCE_TEMPLATE_RES`;
export const FETCH_API_KEYS_REQ = `${NAMESPACE}FETCH_API_KEYS_REQ`;
export const FETCH_API_KEYS_RES = `${NAMESPACE}FETCH_API_KEYS_RES`;
export const FETCH_API_KEYS_ERR = `${NAMESPACE}FETCH_API_KEYS_ERR`;
export const CREATE_API_KEYS_REQ = `${NAMESPACE}CREATE_API_KEYS_REQ`;
export const CREATE_API_KEYS_RES = `${NAMESPACE}CREATE_API_KEYS_RES`;
export const CREATE_API_KEYS_ERR = `${NAMESPACE}CREATE_API_KEYS_ERR`;
export const DELETE_API_KEYS_REQ = `${NAMESPACE}DELETE_API_KEYS_REQ`;
export const DELETE_API_KEYS_RES = `${NAMESPACE}DELETE_API_KEYS_RES`;
export const DELETE_API_KEYS_ERR = `${NAMESPACE}DELETE_API_KEYS_ERR`;
export const FETCH_INTEGRATION_PACKAGES_REQ = `${NAMESPACE}FETCH_INTEGRATION_PACKAGES_REQ`;
export const FETCH_INTEGRATION_PACKAGES_RES = `${NAMESPACE}FETCH_INTEGRATION_PACKAGES_RES`;
export const UPDATE_INTEGRATION_PACKAGES_REQ = `${NAMESPACE}UPDATE_INTEGRATION_PACKAGES_REQ`;
export const UPDATE_INTEGRATION_PACKAGES_RES = `${NAMESPACE}UPDATE_INTEGRATION_PACKAGES_RES`;
export const DELETE_INTEGRATION_PACKAGES_REQ = `${NAMESPACE}DELETE_INTEGRATION_PACKAGES_REQ`;
export const DELETE_INTEGRATION_PACKAGES_RES = `${NAMESPACE}DELETE_INTEGRATION_PACKAGES_RES`;
export const CREATE_INTEGRATION_PACKAGES_RES = `${NAMESPACE}CREATE_INTEGRATION_PACKAGES_RES`;
export const SET_INTEGRATION_PACKAGE_OPTIONS_RES = `${NAMESPACE}SET_INTEGRATION_PACKAGE_OPTIONS_RES`;
export const RESET_INTEGRATION_PACKAGE_OPTIONS_RES = `${NAMESPACE}RESET_INTEGRATION_PACKAGE_OPTIONS_RES`;
export const UPDATE_INTEGRATION_RES = `${NAMESPACE}UPDATE_INTEGRATION_RES`;
export const UPDATE_INTEGRATION_REQ = `${NAMESPACE}UPDATE_INTEGRATION_REQ`;
export const GET_BULK_INVOICE_CSV_REQ = `${NAMESPACE}GET_BULK_INVOICE_CSV_REQ`;
export const GET_BULK_INVOICE_CSV_RES = `${NAMESPACE}GET_BULK_INVOICE_CSV_RES`;
export const GENERATE_ICIMS_CREDENTIALS_REQ = `${NAMESPACE}GENERATE_ICIMS_CREDENTIALS_REQ`;
export const GENERATE_ICIMS_CREDENTIALS_RES = `${NAMESPACE}GENERATE_ICIMS_CREDENTIALS_RES`;

export const fetchWebhookSecret = (teamId) => (dispatch) => {
    const { GET_WEBHOOK_SECRET } = ActionTypes;
    return getRequest({
        endpoint: `/api/v2/teams/${teamId}/webhook-secrets/`,
        exactUrl: true,
    })
        .then((response) => {
            dispatch({
                type: GET_WEBHOOK_SECRET.SUC,
                payload: { response },
            });
            return response;
        })
        .catch((error) => {
            ErrorAlertAPI(error);
            throw error;
        });
};

export const cycleWebhookSecret = (teamId) => (dispatch) => {
    const { PATCH_WEBHOOK_SECRET } = ActionTypes;
    return postRequest({
        endpoint: `/api/v2/teams/${teamId}/webhook-secrets/roll-key/`,
        exactUrl: true,
    })
        .then((response) => {
            dispatch({
                type: PATCH_WEBHOOK_SECRET.SUC,
                payload: { response: [response] },
            });
            return response;
        })
        .catch((error) => {
            ErrorAlertAPI(error);
            throw error;
        });
};

export const updateIntegration = ({ teamId, integrationName, data }) => (dispatch) => {
    dispatch({
        type: UPDATE_INTEGRATION_REQ,
    });
    return patchRequest({
        endpoint: `/${integrationName}/teams/${teamId}/integration/`,
        exactUrl: true,
        body: JSON.stringify(data),
    })
        .then((response) => {
            dispatch({
                type: UPDATE_INTEGRATION_RES,
                payload: { response, integrationName },
            });
            dispatch(fetchIntegrations(teamId));
            return response;
        })
        .catch((error) => {
            dispatch({
                type: DISPLAY_ERROR_NOTIFICATION,
                payload: { error },
            });
            throw error;
        });
};

export const createIntegrationPackage = ({ integrationType, data }) => (dispatch) =>
    postRequest({
        hr: true,
        version: 'v1',
        endpoint: '/hrtestcollections/',
        body: JSON.stringify(data),
    })
        .then((response) => {
            dispatch({
                type: CREATE_INTEGRATION_PACKAGES_RES,
            });
            if (data.team) {
                dispatch(fetchIntegrationPackages({ teamId: data.team, integrationType }));
            } else {
                dispatch(fetchSuperteamIntegrationPackages({ superteamId: data.superteam, integrationType }));
            }
            return response;
        })
        .catch((error) => {
            dispatch({
                type: DISPLAY_ERROR_NOTIFICATION,
                payload: { error },
            });
            throw error;
        });

// fetches integration packages for integrations at the team level
export const fetchIntegrationPackages = ({ teamId, integrationType }) => (dispatch) => {
    dispatch({
        type: FETCH_INTEGRATION_PACKAGES_REQ,
    });
    return getRequest({
        hr: true,
        version: 'v1',
        endpoint: `/hrtestcollections/?team_id=${teamId}&integration_type=${integrationType}`,
    })
        .then((response) => {
            dispatch({
                type: FETCH_INTEGRATION_PACKAGES_RES,
                payload: { response, integrationType },
            });
        })
        .catch((error) => {
            dispatch({
                type: DISPLAY_ERROR_NOTIFICATION,
                payload: { error },
            });
            throw error;
        });
};

// fetches integration packages for integrations at the superteam level
export const fetchSuperteamIntegrationPackages = ({ superteamId, integrationType }) => (dispatch) => {
    dispatch({
        type: FETCH_INTEGRATION_PACKAGES_REQ,
    });
    return getRequest({
        hr: true,
        version: 'v1',
        endpoint: `/hrtestcollections/?superteam_id=${superteamId}&integration_type=${integrationType}`,
    })
        .then((response) => {
            dispatch({
                type: FETCH_INTEGRATION_PACKAGES_RES,
                payload: { response, integrationType },
            });
        })
        .catch((error) => {
            dispatch({
                type: DISPLAY_ERROR_NOTIFICATION,
                payload: { error },
            });
            throw error;
        });
};

export const deleteIntegrationPackage = ({ teamId, integrationId, integrationType }) => (dispatch) => {
    dispatch({
        type: DELETE_INTEGRATION_PACKAGES_REQ,
    });
    return deleteRequest({
        hr: true,
        version: 'v1',
        endpoint: `/hrtestcollections/${integrationId}/`,
    })
        .then((response) => {
            dispatch({
                type: DELETE_INTEGRATION_PACKAGES_RES,
            });
            dispatch(fetchIntegrationPackages({ teamId, integrationType }));
            return response;
        })
        .catch((error) => {
            dispatch({
                type: DISPLAY_ERROR_NOTIFICATION,
                payload: { error },
            });
            throw error;
        });
};

export const updateIntegrationPackage = ({ teamId, integrationType, packageID, data }) => (dispatch) => {
    dispatch({
        type: UPDATE_INTEGRATION_PACKAGES_REQ,
    });
    return patchRequest({
        hr: true,
        version: 'v1',
        endpoint: `/hrtestcollections/${packageID}`,
        body: JSON.stringify(data),
    })
        .then((response) => {
            dispatch({
                type: UPDATE_INTEGRATION_PACKAGES_RES,
            });
            dispatch(fetchIntegrationPackages({ teamId, integrationType }));
            return response;
        })
        .catch((error) => {
            dispatch({
                type: DISPLAY_ERROR_NOTIFICATION,
                payload: { error },
            });
            throw error;
        });
};

export const setIntegrationPackageOptions = (options) => (dispatch) => {
    dispatch({
        type: SET_INTEGRATION_PACKAGE_OPTIONS_RES,
        payload: { options },
    });
};

export const resetIntegrationPackageOptions = () => (dispatch) => {
    dispatch({
        type: RESET_INTEGRATION_PACKAGE_OPTIONS_RES,
        payload: {},
    });
};

export const deleteReferenceTemplate = ({ type, teamId, id }) => (dispatch) => {
    dispatch({
        type: DELETE_REFERENCE_TEMPLATE_REQ,
    });
    return deleteRequest({
        version: 'v2',
        endpoint: `/teams/${teamId}/${type}/reference_templates/${id}`,
    }).then(
        (response) => {
            dispatch({ type: DELETE_REFERENCE_TEMPLATE_RES });
            dispatch(fetchReferenceTemplates({ type, teamId }));
            return response;
        },
        (error) => {
            dispatch({
                type: DISPLAY_ERROR_NOTIFICATION,
                payload: { error },
            });
            throw error;
        }
    );
};

export const updateReferenceTemplate = ({ type, teamId, data, stringifiedData = simpleStringifyJSON(data) }) => (
    dispatch
) => {
    dispatch({
        type: UPDATE_REFERENCE_TEMPLATE_REQ,
    });
    return putRequest({
        version: 'v2',
        endpoint: `/teams/${teamId}/${type}/reference_templates/${data.id}`,
        body: JSON.stringify(stringifiedData),
    }).then(
        (response) => {
            dispatch({ type: UPDATE_REFERENCE_TEMPLATE_RES });
            dispatch(fetchReferenceTemplates({ type, teamId }));
            return response;
        },
        (error) => {
            dispatch({
                type: DISPLAY_ERROR_NOTIFICATION,
                payload: { error },
            });
            throw error;
        }
    );
};

export const saveReferenceTemplate = ({ type, teamId, data, stringifiedData = simpleStringifyJSON(data) }) => (
    dispatch
) => {
    dispatch({
        type: CREATE_REFERENCE_TEMPLATE_REQ,
    });
    return postRequest({
        version: 'v2',
        endpoint: `/teams/${teamId}/${type}/reference_templates/`,
        body: JSON.stringify(stringifiedData),
    }).then(
        (response) => {
            dispatch({ type: CREATE_REFERENCE_TEMPLATE_RES });
            dispatch(fetchReferenceTemplates({ type, teamId }));
            return response;
        },
        (error) => {
            dispatch({
                type: DISPLAY_ERROR_NOTIFICATION,
                payload: { error },
            });
            throw error;
        }
    );
};

export const fetchReferenceTemplates = ({ type, teamId }) => (dispatch) => {
    dispatch({
        type: FETCH_REFERENCE_TEMPLATES_REQ,
    });
    return getRequest({
        version: 'v2',
        endpoint: `/teams/${teamId}/${type}/reference_templates/`,
    }).then(
        (response) => {
            dispatch({
                type: FETCH_REFERENCE_TEMPLATES_RES,
                payload: {
                    referenceTemplates: {
                        [type]: response,
                    },
                },
            });

            return response;
        },
        (error) => {
            dispatch({
                type: DISPLAY_ERROR_NOTIFICATION,
                payload: { error },
            });
            throw error;
        }
    );
};

export const generateIcimsWebhookCredentials = ({ superteamId, teamId }) => (dispatch) => {
    dispatch({
        type: GENERATE_ICIMS_CREDENTIALS_REQ,
    });
    return getRequest({
        endpoint: `/icims/setup/webhook_credentials/?superteam_id=${superteamId}`,
        exactUrl: true,
    })
        .then(() => {
            dispatch({
                type: GENERATE_ICIMS_CREDENTIALS_RES,
            });
            dispatch(fetchIntegrations(teamId));
        })
        .catch((error) => {
            dispatch({
                type: DISPLAY_ERROR_NOTIFICATION,
                payload: { error },
            });
            throw error;
        });
};

export const generateFountainAPIKey = (superteamId, teamId) => (dispatch) =>
    postRequest({
        endpoint: `/fountain/create_credentials/`,
        exactUrl: true,
        body: JSON.stringify({ superteam_id: superteamId }),
    }).then(() => {
        dispatch(fetchIntegrations(teamId));
    });

export const updateFountainIntegration = (integrationData, teamId) => (dispatch) =>
    putRequest({
        endpoint: `/fountain/manage/`,
        exactUrl: true,
        body: JSON.stringify(integrationData),
    }).then(() => {
        dispatch(fetchIntegrations(teamId));
    });

export const fetchIntegrations = (teamId) => (dispatch) => {
    dispatch({
        type: FETCH_INTEGRATIONS_REQ,
    });
    return getRequest({
        version: 'v2',
        endpoint: `/teams/${teamId}/integrations/`,
    })
        .then((response) =>
            dispatch({
                type: FETCH_INTEGRATIONS_RES,
                payload: {
                    integrations: response,
                },
            })
        )
        .catch((error) => {
            dispatch({
                type: FETCH_INTEGRATIONS_ERR,
                payload: { error },
            });
            throw error;
        });
};

export const enableWorkday = ({ teamId, superteamId, data, stringifiedData = simpleStringifyJSON(data) }) => (
    dispatch
) =>
    postRequest({
        endpoint: `/workday/teams/${superteamId}/integration/`,
        exactUrl: true,
        body: JSON.stringify(stringifiedData),
    }).then(() => {
        dispatch(fetchIntegrations(teamId));
    });

export const enableTaleo = ({ teamId, data, stringifiedData = simpleStringifyJSON(data) }) => (dispatch) =>
    postRequest({
        endpoint: `/taleo/teams/${teamId}/integration/`,
        exactUrl: true,
        body: JSON.stringify(stringifiedData),
    }).then(() => {
        dispatch(fetchIntegrations(teamId));
    });

export const enableJobAdder = ({ teamId }) => (dispatch) =>
    getRequest({
        endpoint: `/jobadder/teams/${teamId}/oauth2/`,
        exactUrl: true,
    }).then((response) => {
        if (response.url) {
            window.location.href = response.url;
        }
        dispatch(fetchIntegrations(teamId));
    });

export const enableLever = ({ teamId, data }) => (dispatch) =>
    postRequest({
        endpoint: `/lever/teams/${teamId}/integration/`,
        exactUrl: true,
        body: JSON.stringify(data),
    }).then(() => {
        dispatch(fetchIntegrations(teamId));
    });

export const enableSuccessFactors = ({ teamId, data }) => (dispatch) =>
    postRequest({
        endpoint: `/success_factors/setup/`,
        exactUrl: true,
        body: JSON.stringify(data),
    }).then(
        () => {
            dispatch(fetchIntegrations(teamId));
        },
        (error) => {
            throw error;
        }
    );

export const updateSuccessFactors = ({ teamId, data }) => (dispatch) =>
    patchRequest({
        endpoint: `/success_factors/setup/`,
        exactUrl: true,
        body: JSON.stringify(data),
    }).then(
        () => {
            dispatch(fetchIntegrations(teamId));
        },
        (error) => {
            throw error;
        }
    );

export const enableIcims = ({ teamId, superteamId, data }) => (dispatch) =>
    postRequest({
        endpoint: `/icims/setup/?superteam_id=${superteamId}`,
        exactUrl: true,
        body: JSON.stringify(data),
    }).then(() => {
        dispatch(fetchIntegrations(teamId));
    });

export const disableIcims = ({ teamId, superteamId, data }) => (dispatch) =>
    patchRequest({
        endpoint: `/icims/setup/?superteam_id=${superteamId}`,
        exactUrl: true,
        body: JSON.stringify(data),
    }).then(() => {
        dispatch(fetchIntegrations(teamId));
    });

export const disableWorkday = ({ teamId, superteamId, data }) => (dispatch) =>
    postRequest({
        endpoint: `/workday/teams/${superteamId}/disable/`,
        exactUrl: true,
        body: JSON.stringify(data),
    }).then(() => {
        dispatch(fetchIntegrations(teamId));
    });

export const disableIntegration = ({ teamId, integration }) => (dispatch) =>
    new Promise((resolve, reject) =>
        postRequest({
            endpoint: `/${integration.toLowerCase()}/teams/${teamId}/disable/`,
            exactUrl: true,
        })
            .then(() => dispatch(fetchIntegrations(teamId)))
            .catch((error) => {
                reject(error);
            })
    );

export const joinTeam = (data, id) => (dispatch) => {
    dispatch(saveTeamMember(data, id)).then(() => dispatch(fetchUserInfo()));
};

export const saveTeamMember = (data, id) => (dispatch) =>
    new Promise((resolve, reject) =>
        patchRequest({
            version: 'v2',
            endpoint: `/users/${id}/`,
            body: JSON.stringify(data),
        }).then(
            (response) => {
                dispatch(fetchTeamMember(id));
                resolve(response);
            },
            (error) => {
                reject(error);
            }
        )
    );

export const fetchInvoicePDF = (invoiceId) => (dispatch) => {
    dispatch({
        type: FETCH_INVOICES_REQ,
    });
    return getRequest({
        version: 'v2',
        endpoint: `/invoices/${invoiceId}/pdf/`,
    })
        .then((response) => {
            const file = new File([response], `report-${invoiceId}.pdf`, { type: 'application/pdf' });
            FileSaver.saveAs(file);
        })
        .catch((error) => {
            dispatch({
                type: DISPLAY_ERROR_NOTIFICATION,
                payload: { error },
            });
        });
};

export const fetchButtercupInvoices = (teamId) => (dispatch) => {
    dispatch({
        type: FETCH_INVOICES_REQ,
    });
    return getRequest({
        version: 'v2',
        endpoint: `/invoices/?team_id=${teamId}`,
    })
        .then((response) => {
            dispatch({
                type: FETCH_INVOICES_RES,
                payload: {
                    invoices: response,
                },
            });
            return 200;
        })
        .catch((error) => {
            dispatch({
                type: DISPLAY_ERROR_NOTIFICATION,
                payload: { error },
            });
            dispatch({
                type: FETCH_INVOICES_RES,
                payload: {
                    invoices: [],
                },
            });
        });
};

export const fetchGroups = (teamId) => (dispatch) => {
    dispatch({
        type: FETCH_GROUPS_REQ,
    });
    return getRequest({
        version: 'v2',
        endpoint: `/groups/?team_id=${teamId}`,
    })
        .then((response) => {
            dispatch({
                type: FETCH_GROUPS_RES,
                payload: {
                    groups: response,
                },
            });
        })
        .catch((error) => {
            dispatch({
                type: FETCH_GROUPS_ERR,
                payload: { error },
            });
        });
};

export const createGroup = (group, teamId) => (dispatch) => {
    dispatch({
        type: CREATE_GROUP_REQ,
    });
    return postRequest({
        version: 'v2',
        endpoint: `/groups/`,
        body: JSON.stringify({ name: group, team_id: teamId }),
    })
        .then((response) => {
            dispatch({
                type: CREATE_GROUP_RES,
            });
            return response;
        })
        .catch((error) => {
            dispatch({
                type: CREATE_GROUP_ERR,
                payload: { error },
            });
            throw error;
        });
};

export const createTeamMember = (data) => () =>
    postRequest({
        version: 'v2',
        endpoint: '/users/',
        body: JSON.stringify(data),
    });

export const fetchTeamMembers = (teamId, order = 'first_name') => (dispatch) => {
    dispatch({
        type: FETCH_TEAM_MEMBERS_REQ,
    });
    return getRequest({
        version: 'v2',
        endpoint: `/users/?joined_teams=${teamId}&ordering=${order}`,
    })
        .then((response) => {
            dispatch({
                type: FETCH_TEAM_MEMBERS_RES,
                payload: {
                    teamMembers: response,
                },
            });
        })
        .catch((error) => {
            dispatch({
                type: FETCH_TEAM_MEMBERS_ERR,
                payload: { error },
            });
        });
};

export const fetchPaginatedTeamMembers = (teamId, page = 1, page_size, order = 'first_name', searchByName = '') => (
    dispatch
) => {
    dispatch({
        type: FETCH_TEAM_MEMBERS_REQ,
    });
    return getRequest({
        version: 'v2',
        endpoint: `/paginated-users/?joined_teams=${teamId}&page=${page}&ordering=${order}&page_size=${page_size}&search=${searchByName}`,
    })
        .then((response) => {
            dispatch({
                type: FETCH_TEAM_MEMBERS_RES,
                payload: {
                    teamMembers: response.results,
                    teamMembersCount: response.count,
                },
            });
        })
        .catch((error) => {
            dispatch({
                type: FETCH_TEAM_MEMBERS_ERR,
                payload: { error },
            });
        });
};

export const fetchSuperteamAdmins = (superteamId) => (dispatch) => {
    dispatch({
        type: FETCH_SUPERTEAM_ADMINS_REQ,
    });
    return getRequest({
        version: 'v2',
        endpoint: `/superteam/${superteamId}/admins/`,
    })
        .then((response) => {
            dispatch({
                type: FETCH_SUPERTEAM_ADMINS_RES,
                payload: {
                    superteamAdmins: response,
                },
            });
        })
        .catch((error) => {
            dispatch({
                type: FETCH_SUPERTEAM_ADMINS_ERR,
                payload: { error },
            });
        });
};

// fetch all users who are part of the superteam
export const fetchAllSuperTeamMembers = (dispatch) => {
    dispatch({
        type: FETCH_ALL_TEAM_MEMBERS_REQ,
    });
    return getRequest({
        version: 'v2',
        endpoint: '/users/',
    })
        .then((response) => {
            dispatch({
                type: FETCH_ALL_TEAM_MEMBERS_RES,
                payload: { allSuperTeamUsers: response },
            });
        })
        .catch((error) => {
            dispatch({
                type: FETCH_ALL_TEAM_MEMBERS_ERR,
                payload: { error },
            });
        });
};

// Action Creators
export const fetchSettings = () => (dispatch, getState) => dispatch(fetchTeamSettings(getTeamId(getState())));

export const fetchTeamSettings = (teamId) => (dispatch) => {
    dispatch({
        type: GET_SETTINGS_REQ,
    });
    return getRequest({
        version: 'v2',
        endpoint: `/teams/${teamId}/settings/`,
    }).then(
        (settings) => {
            dispatch({
                type: GET_TEAM_SETTINGS_RES,
                payload: {
                    settings: { ...settings },
                },
            });
        },
        (error) => {
            dispatch({
                type: GET_SETTINGS_ERR,
                payload: {
                    error,
                    settingsError: 'You do not have valid permissions to view and/or change Settings',
                },
            });
            throw error;
        }
    );
};

// Action Creators
export const updateSettings = (formData, teamId) => (dispatch) => {
    dispatch({
        type: PATCH_SETTINGS_REQ,
    });
    return patchRequest({
        version: 'v2',
        endpoint: `/teams/${teamId}/settings/`,
        body: JSON.stringify(simpleStringifyJSON(formData)),
    }).then(
        (settings) => {
            dispatch({
                type: PATCH_SETTINGS_RES,
                payload: {
                    settings: { ...settings },
                },
            });
            dispatch(fetchUserInfo());
        },
        (error) => {
            dispatch({
                type: PATCH_SETTINGS_ERR,
                payload: {
                    error,
                    settingsError: 'You do not have valid permissions to view and/or change Settings',
                },
            });
            throw error;
        }
    );
};

export const saveCardToken = (stripeToken, teamId) => async (dispatch) => {
    dispatch({
        type: SAVE_CARD_TOKEN_REQ,
    });
    try {
        const settings = await postRequest({
            version: 'v2',
            endpoint: `/teams/${teamId}/stripe/`,
            body: JSON.stringify(simpleStringifyJSON({ stripe_token: stripeToken })),
        })
            .then(() =>
                message.success(
                    intl.formatMessage({
                        id: 'a2509.Billing.AddPaymentSuccess',
                        defaultMessage: 'Payment card added',
                    })
                )
            )
            .catch((error) => ErrorAlertAPI(error));
        dispatch({
            type: SAVE_CARD_TOKEN_RES,
            payload: {
                settings: { ...settings },
            },
        });
    } catch (error) {
        dispatch({
            type: SAVE_CARD_TOKEN_ERR,
            payload: {
                error,
                settingsError: 'You do not have valid permissions to view and/or change Settings',
            },
        });
    }
};

export const removePaymentCardThenMessage = (teamId) => (dispatch) => {
    deleteRequest({
        version: 'v2',
        endpoint: `/teams/${teamId}/stripe/`,
    })
        .then(() =>
            message.success(
                intl.formatMessage({
                    id: 'a2509.Billing.RemovePaymentSuccess',
                    defaultMessage: 'Payment card removed',
                })
            )
        )
        .then(() => {
            dispatch(fetchTeam(teamId));
            dispatch(fetchUserInfo());
        })
        .catch(() =>
            ErrorAlertCustom({
                title: intl.formatMessage({
                    id: 'a2509.Billing.RemovePaymentError',
                    defaultMessage: 'Error removing card',
                }),
            })
        );
};

export const fetchApiKeys = () => (dispatch) => {
    dispatch({
        type: FETCH_API_KEYS_REQ,
    });
    return getRequest({
        version: 'v1',
        endpoint: `/access_token`,
    })
        .then((response) => {
            dispatch({
                type: FETCH_API_KEYS_RES,
                payload: {
                    apiKeys: response,
                },
            });
            return response;
        })
        .catch((error) => {
            dispatch({
                type: DISPLAY_ERROR_NOTIFICATION,
                payload: { error },
            });
            throw error;
        });
};

export const createApiKey = () => (dispatch) => {
    dispatch({
        type: CREATE_API_KEYS_REQ,
    });
    return postRequest({
        version: 'v1',
        endpoint: `/access_token`,
    })
        .then((response) => {
            dispatch({
                type: CREATE_API_KEYS_RES,
                payload: {
                    response,
                },
            });
            return response;
        })
        .catch((error) => {
            dispatch({
                type: CREATE_API_KEYS_ERR,
                payload: { error },
            });
            throw error;
        });
};

export const deleteApiKey = (id) => (dispatch) => {
    dispatch({
        type: DELETE_API_KEYS_REQ,
    });
    return deleteRequest({
        version: 'v1',
        endpoint: `/access_token/${id}`,
    })
        .then((response) => {
            dispatch({
                type: DELETE_API_KEYS_RES,
                payload: {
                    apiKeys: response,
                },
            });
            dispatch(fetchApiKeys());
            return response;
        })
        .catch((error) => {
            dispatch({
                type: DELETE_API_KEYS_ERR,
                payload: { error },
            });

            throw error;
        });
};

export const fetchInvoiceCSV = ([start_date, end_date], use_invoice_team_id = '', team_id, invoice_team_id = '') => (
    dispatch
) => {
    dispatch({
        type: GET_BULK_INVOICE_CSV_REQ,
    });
    return getRequest({
        version: 'v2',
        endpoint: `invoices/csv/batch/?start_date=${start_date}&end_date=${end_date}&team=${team_id}&invoice_team_id=${invoice_team_id}&use_invoice_team_id=${use_invoice_team_id}`,
    }).then(
        () => {
            dispatch({
                type: GET_BULK_INVOICE_CSV_RES,
            });
        },
        (error) => {
            dispatch({
                type: DISPLAY_ERROR_NOTIFICATION,
                payload: { error },
            });
            throw error;
        }
    );
};
