// Libraries
import React from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Form } from '@ant-design/compatible';
import { InfoCircleOutlined } from '@ant-design/icons';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Checkbox, Input, Popover, AutoComplete, Select } from 'antd';

// Components
import Text from 'certn-ui/Text';
import Title from 'certn-ui/Title';
import { AppearRight } from 'certn-ui/Animate';
import FormLayout from 'certn-form/FormLayout';
import { InternationalPhone } from 'components';
import { TrackNavigation } from 'views/welcome/components';
// Modules
import { Regex } from 'modules';
import DateInput from 'certn-form/DateInput';
import { endDateIsToday } from 'modules/validation';

// Constants
import { CHECK_REQUEST } from 'base/BaseConstants';

const FormWithSpace = styled(Form)`
    margin-top: 20px;
`;

const FieldToggleContainer = styled.div`
    display: flex;
    align-items: center;
    padding-left: 12px;
    transform: translateY(-25px);
    height: 40px;
    background-color: ${({ theme }) => theme.color.certnGray100};
    border-color: ${({ theme }) => theme.color.certnGray300};
    border-width: 0 1px 1px;
    border-style: solid;
`;

/**
 * We need to adjust the error message position when the
 * checkbox element is rendered.
 */
const FormItemEmail = styled(Form.Item)`
    ${({ hasCheckbox }) => hasCheckbox && "[class$='form-explain'] { transform: translateY(40px); }"}
`;

const EmployerReferenceForm = ({
    form: { getFieldDecorator, validateFields, setFields },
    handleSubmit,
    reference,
    intl,
    settings,
    applicant,
    phoneErrorStatus,
    employers,
}) => {
    const [isNoEmailChecked, setIsNoEmailChecked] = React.useState(reference && !get(reference, 'email'));
    const [phone, setPhone] = React.useState(get(reference, 'phone_number'));
    const [currentEmployer, setCurrentEmployer] = React.useState(
        reference && get(reference, 'start_date') && !get(reference, 'end_date')
    );
    const requests = applicant || settings;
    const isEmailRequired =
        settings.employer_ref_email_req ||
        requests[CHECK_REQUEST.EMPLOYER_REFERENCES] ||
        (requests[CHECK_REQUEST.EMPLOYER_PHONE_REFERENCES] && !isNoEmailChecked);
    const shouldShowNoEmailCheckbox =
        requests[CHECK_REQUEST.EMPLOYER_PHONE_REFERENCES] && !settings.employer_ref_email_req;
    const relationshipOptions = [
        intl.formatMessage({
            id: 'welcome.EmployerReference.reportedTo',
            defaultMessage: 'You reported to them',
        }),
        intl.formatMessage({
            id: 'welcome.EmployerReference.juniorTo',
            defaultMessage: 'They were your senior but did not manage you directly',
        }),
        intl.formatMessage({
            id: 'welcome.EmployerReference.workedWith',
            defaultMessage: 'You worked with them',
        }),
        intl.formatMessage({
            id: 'welcome.EmployerReference.managed',
            defaultMessage: 'You managed them',
        }),
        intl.formatMessage({
            id: 'common.other',
            defaultMessage: 'Other',
        }),
    ];

    const handleNoEmailCheckboxChange = (e) => {
        setIsNoEmailChecked(e.target.checked);
        // Clear input field
        if (e.target.checked) {
            setFields({ email: { value: null } });
        }
    };

    const handleEmployerDatesDisplay = (e) => {
        e === true ? setCurrentEmployer(true) : setCurrentEmployer(false);
    };

    const handleCompanySelect = async (e) => {
        let employer = employers.filter((emp) => emp.id === e)[0];
        const employersByCompanyName = employers.filter((emp) => emp.company_name === e);
        // Enable auto-complete for the case that user typing a unique company name instead of selecting.
        // If there are multiple companies with the same name, no auto-completion will be done.
        if (!employer && employersByCompanyName.length === 1) {
            // eslint-disable-next-line prefer-destructuring
            employer = employersByCompanyName[0];
        }
        if (employer) {
            await setCurrentEmployer(!employer.end_date);
            setFields({
                company_name: { value: employer.company_name },
                position: { value: employer.position },
                start_date: { value: employer.start_date },
                end_date: { value: employer.end_date },
            });
        }
    };

    return (
        <AppearRight>
            <Title>
                <FormattedMessage
                    id="welcome.EmployerReference.formTitle"
                    defaultMessage="Add your employer reference details"
                />
            </Title>
            <Title size="xs" color="certnGray500">
                <FormattedMessage
                    id="welcome.EmployerReference.formSubtitle"
                    defaultMessage="Please ensure when providing a contact, that the reference is the supervisor or direct superior of your position."
                />
            </Title>
            <FormWithSpace layout="horizontal" onSubmit={(e) => handleSubmit(e, validateFields, setFields, phone)}>
                <FormLayout>
                    <Text size="xs" color="certnGray500" margin="10px 0">
                        <FormattedMessage
                            id="welcome.EmployerReference.employmentDetails"
                            defaultMessage="Your Employment Details"
                        />
                    </Text>
                    {employers.length > 0 ? (
                        <Form.Item key="company_name" hasFeedback>
                            {getFieldDecorator('company_name', {
                                initialValue: reference?.company_name,
                                rules: [
                                    {
                                        pattern: Regex.alphaNumericExtra,
                                        message: intl.formatMessage({
                                            id: 'error.validation.alphaNumericExtra',
                                            defaultMessage:
                                                'Please use only numbers, letters, spaces, dashes, and underscores',
                                        }),
                                    },
                                    {
                                        required: true,
                                        message: intl.formatMessage({
                                            id: 'error.validation.notBlank',
                                            defaultMessage: 'Please do not leave blank',
                                        }),
                                    },
                                ],
                            })(
                                <AutoComplete
                                    size="large"
                                    data-testid="company_name"
                                    options={[
                                        {
                                            label: (
                                                <Text size="xxs" color="black">
                                                    <FormattedMessage
                                                        id="welcome.EmployerReference.companyName"
                                                        defaultMessage="Company Name"
                                                    />
                                                </Text>
                                            ),
                                            options: employers.map((employer) => ({
                                                label: employer.company_name,
                                                value: employer.id,
                                            })),
                                        },
                                    ]}
                                    placeholder={intl.formatMessage({
                                        id: 'welcome.EmployerReference.companyName',
                                        defaultMessage: 'Company Name',
                                    })}
                                    onSearch={(e) => handleCompanySelect(e)}
                                    onSelect={(e) => handleCompanySelect(e)}
                                />
                            )}
                        </Form.Item>
                    ) : (
                        <Form.Item key="company_name" hasFeedback>
                            {getFieldDecorator('company_name', {
                                initialValue: reference?.company_name,
                                rules: [
                                    {
                                        pattern: Regex.alphaNumericExtra,
                                        message: intl.formatMessage({
                                            id: 'error.validation.alphaNumericExtra',
                                            defaultMessage:
                                                'Please use only numbers, letters, spaces, dashes, and underscores',
                                        }),
                                    },
                                    {
                                        required: true,
                                        message: intl.formatMessage({
                                            id: 'error.validation.notBlank',
                                            defaultMessage: 'Please do not leave blank',
                                        }),
                                    },
                                ],
                            })(
                                <Input
                                    size="large"
                                    placeholder={intl.formatMessage({
                                        id: 'welcome.EmployerReference.companyName',
                                        defaultMessage: 'Company Name',
                                    })}
                                    addonBefore={
                                        <Popover
                                            content={
                                                <FormattedMessage
                                                    id="welcome.EmployerReference.companyName"
                                                    defaultMessage="Company Name"
                                                />
                                            }
                                        >
                                            <InfoCircleOutlined />
                                        </Popover>
                                    }
                                />
                            )}
                        </Form.Item>
                    )}
                    <Form.Item key="position" hasFeedback>
                        {getFieldDecorator('position', {
                            initialValue: reference?.position,
                            rules: [
                                {
                                    pattern: Regex.alphaNumericExtra,
                                    message: intl.formatMessage({
                                        id: 'error.validation.alphaNumericExtra',
                                        defaultMessage:
                                            'Please use only numbers, letters, spaces, dashes, and underscores',
                                    }),
                                },
                                {
                                    required: true,
                                    message: intl.formatMessage({
                                        id: 'error.validation.notBlank',
                                        defaultMessage: 'Please do not leave blank',
                                    }),
                                },
                            ],
                        })(
                            <Input
                                size="large"
                                placeholder={intl.formatMessage({
                                    id: 'welcome.EmployerReference.position',
                                    defaultMessage: 'Role / Position',
                                })}
                                data-testid="position"
                                addonBefore={
                                    <Popover
                                        content={
                                            <FormattedMessage
                                                id="welcome.EmployerReference.position"
                                                defaultMessage="Role / Position"
                                            />
                                        }
                                    >
                                        <InfoCircleOutlined />
                                    </Popover>
                                }
                            />
                        )}
                    </Form.Item>
                    <Form.Item key="current" hasFeedback>
                        {getFieldDecorator('current', {
                            initialValue: currentEmployer,
                            rules: [
                                {
                                    required: true,
                                    message: intl.formatMessage({
                                        id: 'error.validation.notSelected',
                                        defaultMessage: 'Please make a selection',
                                    }),
                                },
                            ],
                        })(
                            <Select
                                size="large"
                                data-testid="current"
                                placeholder={intl.formatMessage({
                                    id: 'welcome.Employers.isCurrentOrPast',
                                    defaultMessage: 'Is this a current or past employer?',
                                })}
                                onChange={(e) => handleEmployerDatesDisplay(e)}
                            >
                                <Select.Option data-testid="yes_current" value>
                                    <FormattedMessage
                                        id="welcome.Employers.currentEmployer"
                                        defaultMessage="I currently work here"
                                    />
                                </Select.Option>
                                <Select.Option data-testid="not_current" value={false}>
                                    <FormattedMessage
                                        id="welcome.Employers.pastEmployer"
                                        defaultMessage="This is a past employer"
                                    />
                                </Select.Option>
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item key="start_date" hasFeedback>
                        {getFieldDecorator('start_date', {
                            initialValue: reference?.start_date,
                            rules: [
                                {
                                    required: true,
                                    message: intl.formatMessage({
                                        id: 'error.validation.notBlank',
                                        defaultMessage: 'Please do not leave blank',
                                    }),
                                },
                            ],
                        })(
                            <DateInput
                                size="large"
                                data-testid="start_date"
                                disableFutureDates
                                placeholder={intl.formatMessage({
                                    id: 'form.label.startDate',
                                    defaultMessage: 'Start Date',
                                })}
                                addonBeforeUsePlaceholder
                            />
                        )}
                    </Form.Item>
                    {!currentEmployer && (
                        <Form.Item key="end_date" hasFeedback>
                            {getFieldDecorator('end_date', {
                                initialValue: reference?.end_date,
                                rules: [
                                    {
                                        validator: (rule, value, callback) => {
                                            // Warn if end date is today - use current instead
                                            const result = endDateIsToday(value);
                                            callback(result);
                                        },
                                    },
                                    {
                                        required: true,
                                        message: intl.formatMessage({
                                            id: 'error.validation.notBlank',
                                            defaultMessage: 'Please do not leave blank',
                                        }),
                                    },
                                ],
                            })(
                                <DateInput
                                    size="large"
                                    data-testid="end_date"
                                    disableFutureDates
                                    placeholder={intl.formatMessage({
                                        id: 'form.label.endDate',
                                        defaultMessage: 'End Date',
                                    })}
                                    addonBeforeUsePlaceholder
                                />
                            )}
                        </Form.Item>
                    )}
                    <Text size="xs" color="certnGray500" margin="10px 0">
                        <FormattedMessage
                            id="welcome.EmployerReference.referenceDetails"
                            defaultMessage="Reference Details"
                        />
                    </Text>
                    <Form.Item key="first_name" hasFeedback>
                        {getFieldDecorator('first_name', {
                            initialValue: reference?.first_name,
                            rules: [
                                {
                                    pattern: Regex.name,
                                    message: intl.formatMessage({
                                        id: 'error.validation.name',
                                        defaultMessage: 'Please enter a valid name',
                                    }),
                                },
                                {
                                    required: true,
                                    message: intl.formatMessage({
                                        id: 'error.validation.notBlank',
                                        defaultMessage: 'Please do not leave blank',
                                    }),
                                },
                            ],
                        })(
                            <Input
                                autoFocus
                                size="large"
                                placeholder={intl.formatMessage({
                                    id: 'welcome.EmployerReference.referenceFirstName',
                                    defaultMessage: 'Reference First Name',
                                })}
                                addonBefore={
                                    <Popover
                                        content={
                                            <FormattedMessage
                                                id="welcome.EmployerReference.referenceFirstName"
                                                defaultMessage="Reference First Name"
                                            />
                                        }
                                    >
                                        <InfoCircleOutlined />
                                    </Popover>
                                }
                            />
                        )}
                    </Form.Item>
                    <Form.Item key="last_name" hasFeedback>
                        {getFieldDecorator('last_name', {
                            initialValue: reference?.last_name,
                            rules: [
                                {
                                    pattern: Regex.name,
                                    message: intl.formatMessage({
                                        id: 'error.validation.name',
                                        defaultMessage: 'Please enter a valid name',
                                    }),
                                },
                                {
                                    required: true,
                                    message: intl.formatMessage({
                                        id: 'error.validation.notBlank',
                                        defaultMessage: 'Please do not leave blank',
                                    }),
                                },
                            ],
                        })(
                            <Input
                                size="large"
                                placeholder={intl.formatMessage({
                                    id: 'welcome.EmployerReference.referenceLastName',
                                    defaultMessage: 'Reference Last Name',
                                })}
                                addonBefore={
                                    <Popover
                                        content={
                                            <FormattedMessage
                                                id="welcome.EmployerReference.referenceLastName"
                                                defaultMessage="Reference Last Name"
                                            />
                                        }
                                    >
                                        <InfoCircleOutlined />
                                    </Popover>
                                }
                            />
                        )}
                    </Form.Item>
                    <Form.Item key="reference_job_title" hasFeedback>
                        {getFieldDecorator('reference_job_title', {
                            initialValue: reference?.reference_job_title,
                            rules: [
                                {
                                    pattern: Regex.alphaNumericExtra,
                                    message: intl.formatMessage({
                                        id: 'error.validation.alphaNumericExtra',
                                        defaultMessage:
                                            'Please use only numbers, letters, spaces, dashes, and underscores',
                                    }),
                                },
                                {
                                    required: true,
                                    message: intl.formatMessage({
                                        id: 'error.validation.notBlank',
                                        defaultMessage: 'Please do not leave blank',
                                    }),
                                },
                            ],
                        })(
                            <Input
                                size="large"
                                placeholder={intl.formatMessage({
                                    id: 'welcome.EmployerReference.referencePosition',
                                    defaultMessage: 'Reference Job Title',
                                })}
                                addonBefore={
                                    <Popover
                                        content={
                                            <FormattedMessage
                                                id="welcome.EmployerReference.referencePosition"
                                                defaultMessage="Reference Job Title"
                                            />
                                        }
                                    >
                                        <InfoCircleOutlined />
                                    </Popover>
                                }
                            />
                        )}
                    </Form.Item>
                    <FormItemEmail key="email" hasFeedback hasCheckbox={shouldShowNoEmailCheckbox}>
                        {getFieldDecorator('email', {
                            initialValue: reference?.email,
                            rules: [
                                {
                                    pattern: Regex.email,
                                    message: intl.formatMessage({
                                        id: 'error.validation.email',
                                        defaultMessage: 'Please provide a valid email',
                                    }),
                                },
                                {
                                    required: isEmailRequired,
                                    message: intl.formatMessage({
                                        id: 'error.validation.notBlank',
                                        defaultMessage: 'Please do not leave blank',
                                    }),
                                },
                            ],
                        })(
                            <Input
                                size="large"
                                disabled={isNoEmailChecked}
                                placeholder={intl.formatMessage({
                                    id: 'welcome.EmployerReference.referenceEmail',
                                    defaultMessage: 'Reference Email',
                                })}
                                addonBefore={
                                    <Popover
                                        content={
                                            <FormattedMessage
                                                id="welcome.EmployerReference.referenceEmail"
                                                defaultMessage="Reference Email"
                                            />
                                        }
                                    >
                                        <InfoCircleOutlined />
                                    </Popover>
                                }
                            />
                        )}
                    </FormItemEmail>
                    {shouldShowNoEmailCheckbox && (
                        <FieldToggleContainer>
                            <Checkbox
                                id="no_email_checkbox"
                                onChange={handleNoEmailCheckboxChange}
                                checked={isNoEmailChecked}
                            >
                                <FormattedMessage
                                    id="welcome.EmployerReference.noReferenceEmail"
                                    defaultMessage="I don't have a reference email"
                                />
                            </Checkbox>
                        </FieldToggleContainer>
                    )}
                    <InternationalPhone
                        key="int-phone"
                        value={phone}
                        errorStatus={phoneErrorStatus}
                        onChange={(num) => setPhone(num)}
                    />
                    <Form.Item key="relationship" hasFeedback>
                        {getFieldDecorator('relationship', {
                            initialValue: reference?.relationship,
                            rules: [
                                {
                                    pattern: Regex.alphaNumericExtra,
                                    message: intl.formatMessage({
                                        id: 'error.validation.alphaNumericExtra',
                                        defaultMessage:
                                            'Please use only numbers, letters, spaces, dashes, and underscores',
                                    }),
                                },
                                {
                                    required: true,
                                    message: intl.formatMessage({
                                        id: 'error.validation.notBlank',
                                        defaultMessage: 'Please do not leave blank',
                                    }),
                                },
                            ],
                        })(
                            <Select
                                size="large"
                                data-testid="relationship_select"
                                placeholder={intl.formatMessage({
                                    id: 'welcome.EmployerReference.relationship',
                                    defaultMessage: 'Reference Relationship',
                                })}
                            >
                                {relationshipOptions.map((option) => (
                                    <Select.Option value={option} key={`relationship-${option}`}>
                                        {option}
                                    </Select.Option>
                                ))}
                            </Select>
                        )}
                    </Form.Item>
                    <TrackNavigation
                        nextButtonTitle={
                            reference ? (
                                <FormattedMessage
                                    id="welcome.EmployerReference.updateReference"
                                    defaultMessage="Update Reference"
                                />
                            ) : (
                                <FormattedMessage
                                    id="welcome.EmployerReference.addReference"
                                    defaultMessage="Add Reference"
                                />
                            )
                        }
                    />
                </FormLayout>
            </FormWithSpace>
        </AppearRight>
    );
};

EmployerReferenceForm.propTypes = {
    form: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    reference: PropTypes.object,
    intl: PropTypes.object.isRequired,
    settings: PropTypes.object.isRequired,
    applicant: PropTypes.object.isRequired,
    phoneErrorStatus: PropTypes.oneOf(['success', 'error']),
    employers: PropTypes.array.isRequired,
};

EmployerReferenceForm.defaultProps = {
    reference: null,
    phoneErrorStatus: null,
};

export default Form.create()(injectIntl(EmployerReferenceForm));
