import CertnTitle from 'certn-ui/Title';
import Auth from 'modules/Auth';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { getApplicantLogin, getListing, getSettings } from 'views/welcome/WelcomeSelectors';

export const Title = () => {
    const settings = useSelector(getSettings);
    const listing = useSelector(getListing);
    const isApplicantLogin = useSelector(getApplicantLogin);

    let text;
    if (isApplicantLogin && !Auth.isApplicantAuthenticated()) {
        text = (
            <FormattedMessage
                id="welcome.Email.loadingReadyAccount"
                defaultMessage="Create an account to start your background check"
            />
        );
    } else if (listing && listing.property) {
        text = (
            <FormattedMessage
                id="welcome.Email.loadingReadyAddress"
                defaultMessage="Ready to start your background check for {address}?"
                values={{ address: listing.property.address }}
            />
        );
    } else if (listing && listing.position_name) {
        text = (
            <FormattedMessage
                id="welcome.Email.loadingReadyBackgroundCheck"
                defaultMessage="Ready to start your background check?"
            />
        );
    } else if (settings && settings.get_org_name) {
        text = (
            <FormattedMessage
                id="welcome.Email.loadingReadyOrgName"
                defaultMessage="Ready to start your background check with {orgName}?"
                values={{ orgName: settings.get_org_name }}
            />
        );
    } else {
        text = (
            <FormattedMessage id="welcome.Email.loadingReady" defaultMessage="Ready to start your background check?" />
        );
    }

    return <CertnTitle>{text}</CertnTitle>;
};
