import React from 'react';
import { Button, Result } from 'antd';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components/macro';
import { Icon as LegacyIcon } from '@ant-design/compatible';

export const CustomInfo = styled(LegacyIcon)`
    color: ${({ theme }) => theme.color.certnGreen700} !important;
`;

const SessionNotFound = () => {
    const history = useHistory();

    return (
        <Result
            status="info"
            icon={<CustomInfo type="info-circle" />}
            title={<FormattedMessage id="welcome.Welcome.oopsTitle" defaultMessage="Oops, this page doesn't exist" />}
            subTitle={
                <FormattedMessage
                    id="welcome.Welcome.oopsSubTitle"
                    defaultMessage="Looks like we've led you to a dead end. Let's get you back to where you were."
                />
            }
            extra={[
                <Button type="primary" key="console" onClick={history.goForward}>
                    Back
                </Button>,
            ]}
        />
    );
};
export default SessionNotFound;
