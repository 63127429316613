// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { get, isNull } from 'lodash';

// Modules
import { CheckRequested } from 'modules';
import Format, { getMonthFormat } from 'modules/Format';
import { isUKOrGB } from 'modules/Countries';
import { yearsValidationError } from 'views/welcome/views/employerReference/employerReferenceHelpers';

// Constants
import { CHECK_REQUEST } from 'base/BaseConstants';

// Determines accumulated years with month resolution to give leeway with small address gaps < 1 month
const yearsAccumulated = (entries) => {
    if (!Array.isArray(entries)) return 0;

    const totalMonths = entries.reduce(
        (acc, { start_date, end_date }) => acc + moment(end_date || undefined).diff(moment(start_date), 'months'),
        0
    );
    return Math.floor(totalMonths / 12);
};

// Used to set optional keys, if all keys in error are optional WithSettingsChecker.jsx will allow you to continue anyway
// example, to set key on error use: error[optional(key)] = (
const optional = (key) => `${key}_optional`;

// Flow
const settingsCheck = (
    settings,
    applicant,
    information,
    language,
    config,
    webFeatureEnableNewEmployerReferenceTrack,
    webFeatureRequireCurrentAndPositionPropertyAddressesForOnboarding,
    onboardingType = 'PM'
) => {
    const requests = applicant || settings;
    const allEmployers = information?.employers;

    // For employer reference checks
    // Past employers will be contacted regardless. No consent needed. But we only count the employers with employer reference contact
    const contactableEmployersForReferenceCheck = information?.employers.filter(
        (employer) =>
            !isNull(employer.employer_reference) && ((employer.current && employer.can_contact) || !employer.current)
    );

    /**
     * For employment verification checks - Applicant can skip Reference contact form
     * Verifications process -> call the company's HR dept for verification
     *
     */
    const contactableEmployersForVerificationCheck = information?.employers.filter(
        (employer) => (employer.current && employer.can_contact) || !employer.current
    );

    const canCrimReq = CheckRequested.canadianCriminal(requests);
    const usCrimReq = CheckRequested.usCriminal(requests);
    const ukCrimReq = CheckRequested.ukCriminal(requests);
    const addRefReq = CheckRequested.addressReference(requests);

    const error = {};
    const settingsLogic = {
        // Address Track
        address_references_min_max: (key) => {
            // Must be individually
            if (requests.address_references_years_or_individually !== 'INDIVIDUALLY') return null;

            let numRequired = 1;
            let maxAllowed = null;
            if (addRefReq) {
                numRequired = requests.address_references_min;
                maxAllowed = requests.address_references_max;
            }

            // rent_or_own: "O" = Own, "R" = Rent, "S" = Staying w/ Friends + Fam

            let numAddressReferences = 0;
            information.addresses.map((address) => {
                const owner = address.rent_or_own === 'O';
                if (!owner && address.landlords_first_name && address.landlords_last_name) {
                    numAddressReferences += 1;
                    return null;
                }
                if (!addRefReq && owner) {
                    numAddressReferences += 1;
                    return null;
                }
            });

            if (numAddressReferences < numRequired) {
                error[key] = (
                    <FormattedMessage
                        id="settingsChecker.address_references_min"
                        defaultMessage="You must provide a landlord reference for at least {address_references_min} of your addresses"
                        values={{ address_references_min: requests.address_references_min }}
                    />
                );
                return null;
            }

            // US and Canadian Crim checks require an address in each respective country
            // If client only wants 1 address but both US/CAN criminal checks the applicant encouters error therefor increase max in this scenario
            if (canCrimReq && usCrimReq && maxAllowed === 1) maxAllowed = 2;

            if (maxAllowed && numAddressReferences > maxAllowed) {
                error[key] = (
                    <FormattedMessage
                        id="settingsChecker.address_references_max"
                        defaultMessage="Maximum number of contactable address references: {suggested}"
                        values={{ suggested: maxAllowed }}
                    />
                );
                return null;
            }
        },
        address_references_years: (key) => {
            // Must be years
            if (requests.address_references_years_or_individually !== 'YEARS') return null;

            if (
                addRefReq &&
                applicant.address_references_years &&
                applicant.address_references_years > yearsAccumulated(information?.addresses)
            ) {
                const yearsRequired = applicant.address_references_years;

                if (yearsRequired > yearsAccumulated(information?.addresses)) {
                    error[optional(key)] = (
                        <FormattedMessage
                            id="settingsChecker.address_years"
                            defaultMessage="This application is suggesting address history dating back to at least {date}"
                            values={{
                                date: moment().subtract(yearsRequired, 'years').format(getMonthFormat()),
                            }}
                        />
                    );
                }
            }
        },
        tenancy_years_amount_req: (key) => {
            // If address reference selected use address_references_years key instead
            if (addRefReq && requests.address_references_years_or_individually === 'YEARS') return null;

            if (information.addresses.length === 0) {
                error[key] = (
                    <FormattedMessage
                        id="settingsChecker.tenancy_years_least_one"
                        defaultMessage="You must provide at least one address"
                    />
                );
            }
            if (settings[key] > yearsAccumulated(information.addresses)) {
                error[key] = (
                    <FormattedMessage
                        id="settingsChecker.tenancy_years_amount_req"
                        defaultMessage="Please provide {years_required} year(s) of address history dating back to at least {date}"
                        values={{
                            date: moment().subtract(settings[key], 'years').format(getMonthFormat()),
                            years_required: settings[key],
                        }}
                    />
                );
            }
        },
        hr_tenancy_years_amount_req: (key) => {
            if (applicant[CHECK_REQUEST.STANDARD_ACIC_CHECK]) {
                // TODO make the years_lookback variable configurable so it's not always 5 years
                const years_lookback = 5;
                const todays_date_x_years_ago = moment().subtract(years_lookback, 'years');
                const valid_address_history = (address) => moment(address.start_date).isBefore(todays_date_x_years_ago);
                if (!information.addresses.some(valid_address_history)) {
                    error[key] = (
                        <FormattedMessage
                            id="settingsChecker.hr_tenancy_years_amount_req_x_years"
                            defaultMessage="This application requires 5 years of address history: from {todays_date_x_years_ago} to {todays_date}"
                            values={{
                                todays_date_x_years_ago: todays_date_x_years_ago.format('MM-DD-YYYY'),
                                todays_date: moment().format('MM-DD-YYYY'),
                            }}
                        />
                    );
                }
            }
            if (ukCrimReq) {
                const yearsLookback = 5;
                const todaysDateXYearsAgo = moment().subtract(yearsLookback, 'years');
                const address_history_older_than_required_years = (address) =>
                    moment(address.start_date).isBefore(todaysDateXYearsAgo);
                if (!information.addresses.some(address_history_older_than_required_years)) {
                    error[key] = (
                        <FormattedMessage
                            id="settingsChecker.hr_tenancy_years_amount_req_x_years"
                            defaultMessage="This application requires 5 years of address history: from {todays_date_x_years_ago} to {todays_date}"
                            values={{
                                todays_date_x_years_ago: todaysDateXYearsAgo.format('DD-MM-YYYY'),
                                todays_date: moment().format('DD-MM-YYYY'),
                            }}
                        />
                    );
                }

                // Sorts the addresses into start date order, descending
                const sortedAddresses = information.addresses
                    .sort((addressA, addressB) => moment(addressA.start_date) - moment(addressB.start_date))
                    .reverse();

                for (let i = 1; i < sortedAddresses.length; i += 1) {
                    const thisAddress = sortedAddresses[i];
                    const nextAddress = sortedAddresses[i - 1];
                    const gapDays = moment(nextAddress.start_date).diff(moment(thisAddress.end_date), 'days');
                    if (gapDays > 0) {
                        error[key] = (
                            <FormattedMessage
                                id="settingsChecker.hr_tenancy_gaps_in_address_history"
                                defaultMessage="This application requires that there are no gaps in your address history. There is a {gapDays} day gap between {address_a} and {address_b}"
                                values={{
                                    gapDays,
                                    address_a: thisAddress.address,
                                    address_b: nextAddress.address,
                                }}
                            />
                        );
                    }
                }
            }

            if (information.addresses.length < 1) {
                const errorMessage = applicant[CHECK_REQUEST.UK_RIGHT_TO_WORK_CHECK] ? (
                    <FormattedMessage
                        id="settingsChecker.tenancy_years_least_one"
                        defaultMessage="You must provide at least one address"
                    />
                ) : (
                    <FormattedMessage
                        id="settingsChecker.hr_tenancy_years_amount_req"
                        defaultMessage="You need at least one year of address history"
                    />
                );
                error[key] = errorMessage;
            }

            let currentAddressCount = 0;

            information.addresses.map((address) => {
                if (address.current) currentAddressCount += 1;
                return null;
            });

            if (currentAddressCount > 1) {
                error[key] = (
                    <FormattedMessage
                        id="settingsChecker.hr_tenancy_years_amount_req_max"
                        defaultMessage="You may not add more than 1 current address"
                    />
                );
            }
        },
        address_ref_req: (key) => {
            // CHECK IF ALL ADDRESSES HAVE REFERENCE CONTACTS
            // Added filter so doesn't take into accout owned property as was prompting for references when applicant owns house and does have a reference
            if (applicant[CHECK_REQUEST.ADDRESS_PHONE_REFERENCES]) {
                information.addresses
                    .filter((add) => add.rent_or_own !== 'O')
                    .forEach((address) => {
                        if (!address.address_reference) {
                            error[key] = (
                                <FormattedMessage
                                    id="settingsChecker.address_ref_contact_details"
                                    defaultMessage="Please provide contact details for all address references"
                                />
                            );
                        }
                    });
            }
        },
        has_mycrc_country_address: (key) => {
            // REQUIRE MYCRC
            if (!settings.my_crc_region) return null;

            const hasMyCRCCountry = information.addresses.some((address) => address.country === settings.my_crc_region);
            if (!hasMyCRCCountry) {
                error[key] = (
                    <FormattedMessage
                        id="settingsChecker.has_mycrc_country_address"
                        defaultMessage="This MyCRC application requires an address from: {missingCountry}"
                        values={{ missingCountry: Format.codeToCountry(settings.my_crc_region) }}
                    />
                );
            }
        },
        has_canadian_address: (key) => {
            const requiresCanadianAddress = CheckRequested.canadianCriminal(applicant);
            if (requiresCanadianAddress) {
                let hasCanadianAddress = false;
                information.addresses.map((address) => {
                    if (address.country === 'CA') {
                        hasCanadianAddress = true;
                    }
                    return null;
                });
                if (!hasCanadianAddress) {
                    error[key] = (
                        <FormattedMessage
                            id="settingsChecker.has_canadian_address"
                            defaultMessage="At least one Canadian address is required for a background check"
                        />
                    );
                }
            }
        },
        has_us_address: (key) => {
            let needAddressInUSA = false;
            if (applicant !== null) {
                if (
                    applicant[CHECK_REQUEST.US_CRIMINAL_RECORD_CHECK_TIER_1] ||
                    applicant[CHECK_REQUEST.US_CRIMINAL_RECORD_CHECK_TIER_2] ||
                    applicant[CHECK_REQUEST.US_CRIMINAL_RECORD_CHECK_TIER_3]
                ) {
                    needAddressInUSA = true;
                }
            }
            if (needAddressInUSA) {
                let hasUSAddress = false;
                information.addresses.map((address) => {
                    if (address.country === 'US') {
                        hasUSAddress = true;
                    }
                    return null;
                });
                if (!hasUSAddress) {
                    error[key] = (
                        <FormattedMessage
                            id="settingsChecker.has_us_address"
                            defaultMessage="At least one US address is required"
                        />
                    );
                }
            }
        },

        other_address_start_dates_after_current_address_start_date: (key) => {
            if (information.addresses.length === 1) {
                return null;
            }
            const currentAddress = information.addresses.find((address) => address.current === true);
            if (
                information.addresses
                    .filter((address) => address !== currentAddress)
                    .some((address) => moment(address?.start_date).isAfter(moment(currentAddress?.start_date)))
            ) {
                error[key] = (
                    <FormattedMessage
                        id="settingsChecker.other_address_start_dates_after_current_address_start_date"
                        defaultMessage="Former addresses must be before current address"
                    />
                );
            }
        },

        has_current_address: (key) => {
            if (!webFeatureRequireCurrentAndPositionPropertyAddressesForOnboarding) return;
            const currentAddress = information.addresses.find((address) => address.current === true);
            if (!currentAddress) {
                error[key] = (
                    <FormattedMessage
                        id="settingsChecker.has_current_address"
                        defaultMessage="You need to mark at least one address as Current"
                    />
                );
            }
        },
        each_address_has_street: (key) => {
            let addressHasNoStreet = false;
            information.addresses.map((address) => {
                if (!address.address) {
                    addressHasNoStreet = true;
                }
                return null;
            });
            if (addressHasNoStreet) {
                error[key] = (
                    <FormattedMessage
                        id="settingsChecker.each_address_has_street"
                        defaultMessage='At least one address entry is missing a "Street Address". Please edit your entries to include "Street Address"'
                    />
                );
            }
        },
        addresses_complete: (key) => {
            const isIncomplete = ukCrimReq
                ? (address) => !address.country || !address.address || !address.city
                : (address) =>
                      !address.country ||
                      !address.address ||
                      !address.city ||
                      !address.postal_code ||
                      !(address.province_state || address.other_province_state);

            if (information.addresses.some(isIncomplete)) {
                error[key] = (
                    <FormattedMessage
                        id="settingsChecker.addresses_complete"
                        defaultMessage="At least one address entry is missing a required address field. Please edit your entries and add any missing fields"
                    />
                );
                return null;
            }
        },
        address_request_international_criminal_record_check: (key) => {
            if (applicant && !applicant[CHECK_REQUEST.INTERNATIONAL_CRIMINAL_RECORD_CHECK]) return null;
            if (applicant !== null) {
                // If country field is United Kingdom, 'UK countries' are pulled from the other_province_state field.
                const userCountryCodes = information.addresses.map((address) =>
                    isUKOrGB(address.country) ? address.other_province_state : address.country
                );
                // Finds missing requested countries (UK countries included)
                const missingCountries = applicant.requested_countries.reduce((acc, item) => {
                    if (!userCountryCodes.includes(item.country_code)) acc.push(item.country_name);
                    return acc;
                }, []);
                const numYears = Math.max(
                    settings.required_address_history_years ? settings.required_address_history_years : 0,
                    settings.global_address_history_years ? settings.global_address_history_years : 0
                );
                settingsLogic.has_current();
                if (missingCountries && missingCountries.length > 0) {
                    const newKey = settings.follow_up_international_criminal_record_check ? optional(key) : key;
                    error[newKey] =
                        missingCountries.length > 1 ? (
                            <FormattedMessage
                                id="settingsChecker.address_request_international_criminal_record_check_plural"
                                defaultMessage="This application requires a minimum of 1 address from the following countries: <b>{missingCountries}</b>"
                                values={{
                                    b: (chunks) => <strong>{chunks}</strong>,
                                    missingCountries: missingCountries.join(', '),
                                }}
                            />
                        ) : (
                            <FormattedMessage
                                id="settingsChecker.address_request_international_criminal_record_check_singular"
                                defaultMessage="This application requires a minimum of 1 address from the following country: <b>{missingCountries}</b>"
                                values={{
                                    b: (chunks) => <strong>{chunks}</strong>,
                                    missingCountries: missingCountries.join(', '),
                                }}
                            />
                        );
                }
                // international criminal record checks require 5, 7, or 10 years of address history
                if (
                    information.addresses.filter((a) => moment().diff(a.start_date, 'years') >= numYears).length === 0
                ) {
                    error[key] = (
                        <FormattedMessage
                            id="settingsChecker.address_x_years_history_request_international_criminal_record_check"
                            defaultMessage="This application requires <b>{numYears}</b> years of address history from: <b>{fromDate}</b> to <b>today</b>"
                            values={{
                                b: (chunks) => <strong>{chunks}</strong>,
                                numYears,
                                fromDate: `${new Date().toLocaleString(language, { month: 'long' })} ${
                                    new Date().getFullYear() - numYears
                                }`,
                            }}
                        />
                    );
                }
            }
        },
        global_address_history_years: (key) => {
            if (
                applicant &&
                // if applicant has international criminal record check, missing required years is already checked in the previous block
                // skip this block if already checked the years to avoid duplicate error message
                !applicant[CHECK_REQUEST.INTERNATIONAL_CRIMINAL_RECORD_CHECK] &&
                settings.global_address_history_years != null &&
                settings.global_address_history_years > 0 &&
                information.addresses.filter(
                    (a) => moment().diff(a.start_date, 'years') >= settings.global_address_history_years
                ).length === 0
            ) {
                error[key] = (
                    <FormattedMessage
                        id="settingsChecker.address_x_years_history_request_international_criminal_record_check"
                        defaultMessage="This application requires <b>{numYears}</b> years of address history from: <b>{fromDate}</b> to <b>today</b>"
                        values={{
                            b: (chunks) => <strong>{chunks}</strong>,
                            numYears: settings.global_address_history_years,
                            fromDate: `${new Date().toLocaleString(language, { month: 'long' })} ${
                                new Date().getFullYear() - settings.global_address_history_years
                            }`,
                        }}
                    />
                );
            }
        },
        has_current: () => {
            const hasCurrent = information.addresses.some((address) => address.current);

            if (!hasCurrent) {
                error.has_current = (
                    <FormattedMessage
                        id="settingsChecker.has_current"
                        defaultMessage="This application requires one current address"
                    />
                );
            }
        },
        personal_ref_amount_req: (key) => {
            if (settings[key] > information.personal_references.length) {
                error[optional(key)] = (
                    <FormattedMessage
                        id="settingsChecker.personal_ref_amount_req"
                        defaultMessage="Suggested number of personal references: {amount}"
                        values={{
                            amount: settings.personal_ref_amount_req,
                        }}
                    />
                );
            }
        },
        // Employer Track
        employer_years: (key) => {
            const isVerificationRequestsYears = requests.employment_verification_years_or_individually === 'YEARS';
            const isEmployerRefRequestsYears = requests.employer_references_years_or_individually === 'YEARS';
            const isEmployerReferenceChecks =
                requests[CHECK_REQUEST.EMPLOYER_REFERENCES] || requests[CHECK_REQUEST.EMPLOYER_PHONE_REFERENCES];
            const employerRefYears = requests.employer_references_years;

            // Employment verification first; Employer Reference cannot be higher than
            if (
                requests[CHECK_REQUEST.EMPLOYMENT_VERIFICATION] &&
                isVerificationRequestsYears &&
                applicant?.employment_verification_years > yearsAccumulated(allEmployers)
            ) {
                error[key] = (
                    <FormattedMessage
                        id="settingsChecker.employer_years_history"
                        defaultMessage="You must provide at least {years} years of employment history"
                        values={{
                            years: applicant.employment_verification_years,
                        }}
                    />
                );
                return null;
            }

            // If using new employer references, stop here
            if (webFeatureEnableNewEmployerReferenceTrack) return null;

            // Then Employer Reference, as it's a 1:1 relationship to Employer
            if (
                isEmployerReferenceChecks &&
                isEmployerRefRequestsYears &&
                employerRefYears > yearsAccumulated(contactableEmployersForReferenceCheck)
            ) {
                error[key] = (
                    <FormattedMessage
                        id="settingsChecker.employer_records_total_ref_years"
                        defaultMessage="Please provide additional contactable references to account for {refYearsRequired} total years of employment. You can add more employers or add a reference contact to a currently listed employer if you have not already."
                        values={{ refYearsRequired: employerRefYears }}
                    />
                );
                return null;
            }
        },
        employment_verification_can_contact_req: (key) => {
            // IF EMPLOYMENT VERIFICATION CHECK IS NOT REQUESTED, STOP HERE
            if (requests && !requests[CHECK_REQUEST.EMPLOYMENT_VERIFICATION]) return null;

            const numRequired = requests.employment_verification_min;
            const maxAllowed = requests.employment_verification_max;
            if (numRequired && contactableEmployersForVerificationCheck.length < numRequired) {
                error[key] = (
                    <FormattedMessage
                        id="settingsChecker.employment_verification_can_contact_req_min"
                        defaultMessage="You must allow at least {suggested} of your employers to be contacted for verification"
                        values={{ suggested: numRequired }}
                    />
                );
                return null;
            }

            // IF YEARS IS SELECTED, STOP HERE
            if (requests.employment_verification_years_or_individually === 'YEARS') return null;

            if (maxAllowed && contactableEmployersForVerificationCheck.length > maxAllowed) {
                error[key] = (
                    <FormattedMessage
                        id="settingsChecker.employment_verification_can_contact_req_max"
                        defaultMessage="Maximum number of contactable employers: {suggested}"
                        values={{ suggested: maxAllowed }}
                    />
                );
                return null;
            }
        },
        employer_records_amount_req: (key) => {
            const isRequestYears = requests.employer_references_years_or_individually === 'YEARS';

            // If using new employer references, stop here
            if (webFeatureEnableNewEmployerReferenceTrack) return null;

            // IF YEARS IS SELECTED, STOP HERE
            if (isRequestYears) return null;

            // IF EMPLOYMENT REFERENCE CHECK IS NOT REQUESTED, STOP HERE
            if (
                requests &&
                !requests[CHECK_REQUEST.EMPLOYER_REFERENCES] &&
                !requests[CHECK_REQUEST.EMPLOYER_PHONE_REFERENCES]
            )
                return null;

            const numRequired = requests.employer_references_min;
            const maxAllowed = requests.employer_references_max;

            const seen = new Set();

            information.employers.forEach((employer) => {
                if (
                    employer.employer_reference && // Does the reference exist
                    get(employer.employer_reference, ['contact', 'can_contact']) && // Can we contact the reference
                    (requests[CHECK_REQUEST.EMPLOYMENT_VERIFICATION]
                        ? employer.employer_reference.phone_number
                        : true) && // Do we need to phone them, and is there a phone number
                    (requests[CHECK_REQUEST.EMPLOYER_REFERENCES]
                        ? get(employer, ['employer_reference', 'email', 'address'])
                        : true) // Do we need to email them, and is there an email address
                ) {
                    const { first_name, last_name, email, phone_number } = employer.employer_reference.name;
                    const emailAddress = (email && email.address) || '';
                    const phone = (phone_number && phone_number.number) || '';
                    seen.add(first_name + last_name + emailAddress + phone);
                }
            });

            if (numRequired && seen.size < numRequired) {
                error[key] = (
                    <FormattedMessage
                        id="settingsChecker.employer_records_amount_req_min"
                        defaultMessage="Please provide additional contactable references to account for {employerRequired} total employers. You can add more employers or add a reference contact to a currently listed employer if you have not already."
                        values={{ employerRequired: numRequired }}
                    />
                );
                return null;
            }

            if (maxAllowed && seen.size > maxAllowed) {
                error[key] = (
                    <FormattedMessage
                        id="settingsChecker.employer_records_amount_req_max"
                        defaultMessage="You've provided more than the maximum number of contactable employer references: {maxAllowed}. Please remove {numEmployerToBeRemoved} to continue."
                        values={{ maxAllowed, numEmployerToBeRemoved: seen.size - maxAllowed }}
                    />
                );
                return null;
            }
        },
        employer_ref_req: (key) => {
            // If using new employer references, stop here
            if (webFeatureEnableNewEmployerReferenceTrack) return null;

            // IF NO EMPLOYER RECORDS ADDED, STOP HERE
            settingsLogic.employer_records_amount_req('employer_records_amount_req');
            if (error.employer_records_amount_req) return null;

            // CHECK IF ALL EMPLOYERS HAVE REFERENCE CONTACTS
            if (settings[key]) {
                information.employers.forEach((employer) => {
                    if (!employer.employer_reference) {
                        error[key] = (
                            <FormattedMessage
                                id="settingsChecker.employer_ref_contact_details"
                                defaultMessage="Please provide contact details for all employer references"
                            />
                        );
                    }
                });
            }
        },
        // Employer References Track
        employer_reference_minimum: (key) => {
            if (
                requests.employer_references_years_or_individually === 'YEARS' &&
                yearsValidationError(information.employer_references, requests.employer_references_years)
            ) {
                error[key] = (
                    <FormattedMessage
                        id="settingsChecker.employer_reference_years"
                        defaultMessage="You must provide {numEmployerReferencesYears} years of employer references."
                        values={{ numEmployerReferencesYears: requests.employer_references_years }}
                    />
                );
            } else if (
                requests.employer_references_years_or_individually === 'INDIVIDUALLY' &&
                information.employer_references.length < requests.employer_references_min
            ) {
                error[key] = (
                    <FormattedMessage
                        id="settingsChecker.employer_reference_minimum"
                        defaultMessage="You must provide a minimum of {numEmployerReferences} employer references."
                        values={{ numEmployerReferences: requests.employer_references_min }}
                    />
                );
            }

            return null;
        },
        // Education Track
        education_verification_has_highest_level: (key) => {
            // Only continue if edu verification and highest level is set to Highest
            if (requests[CHECK_REQUEST.EDUCATION_VERIFICATION] && requests.education_verification_level === 'HIGHEST') {
                if (!information?.educations.some((education) => education.highest_level)) {
                    error[key] = (
                        <FormattedMessage
                            id="settingsChecker.education_verification_has_highest_level"
                            defaultMessage="Please select your highest level of education"
                        />
                    );
                }
            }

            return null;
        },
        education_verification_minimum: (key) => {
            // Only continue if education verification and meets team minimum requirement
            if (
                requests[CHECK_REQUEST.EDUCATION_VERIFICATION] &&
                information.educations.length < requests.education_verification_min
            ) {
                error[key] = (
                    <FormattedMessage
                        id="settingsChecker.education_verification_minimum"
                        defaultMessage="You must provide a minimum of {numEducations} educations."
                        values={{ numEducations: requests.education_verification_min }}
                    />
                );
            }

            return null;
        },
        // Credential Track
        credential_verification_has_highest_level: (key) => {
            // Only continue if edu verification and highest level is set to Highest
            if (
                requests[CHECK_REQUEST.CREDENTIAL_VERIFICATION] &&
                requests.credential_verification_level === 'HIGHEST'
            ) {
                if (!information?.credentials.some((credential) => credential.highest_level)) {
                    error[key] = (
                        <FormattedMessage
                            id="settingsChecker.credential_verification_has_highest_level"
                            defaultMessage="Please select your highest level credential"
                        />
                    );
                }
            }

            return null;
        },
        credential_verification_minimum: (key) => {
            // Only continue if credential verification and meets team minimum requirement
            if (
                requests[CHECK_REQUEST.CREDENTIAL_VERIFICATION] &&
                information.credentials.length < requests.credential_verification_min
            ) {
                error[key] = (
                    <FormattedMessage
                        id="settingsChecker.credential_verification_minimum"
                        defaultMessage="You must provide a minimum of {numCredentials} credentials."
                        values={{ numCredentials: requests.credential_verification_min }}
                    />
                );
            }

            return null;
        },
    };

    (config[onboardingType] || config).forEach((key) => settingsLogic[key] && settingsLogic[key](key));

    return Object.keys(error).length === 0 ? null : error;
};

settingsCheck.propTypes = {
    information: PropTypes.shape({
        employers: PropTypes.array,
    }),
};

settingsCheck.defaultProps = {
    information: {
        employers: [],
    },
};

export default settingsCheck;
