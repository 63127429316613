import { FormInstance } from 'antd/lib/form/hooks/useForm';
import { createContext } from 'react';
import { IntegrationPackage } from 'types';

export type PackagesContextProps = {
    form: FormInstance | null;
    packages: IntegrationPackage[];
    editingPackage: Record<string, unknown>;
    allowQuickscreen?: boolean;
};

export const PackagesContext = createContext<PackagesContextProps>({
    form: null,
    packages: [],
    editingPackage: {},
});
