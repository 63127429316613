// Libraries
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';

// Components
import { GenderForm } from './components';

// Modules
import withNavigation from 'views/welcome/modules/WithNavigation';
import { provinceToCode } from 'modules/Format';

// Actions & Selectors
import { setTrackPageOrder, patchInformation } from 'views/welcome/WelcomeActions';

const propTypes = {
    // withNavigation
    handleNextTrack: PropTypes.func.isRequired,
    trackPageLocation: PropTypes.string,
    trackOrder: PropTypes.array,
};
const defaultProps = {
    trackPageLocation: 'base',
};

// TODO: make sure linked in changes to page four and remove history push to addresses
const Gender = ({ trackPageLocation, handleNextTrack }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(setTrackPageOrder(['base']));
    }, [dispatch]);

    const handleSubmit = (values) => {
        if (values.license_prov_state) {
            values.license_prov_state = provinceToCode(values.license_prov_state);
        }
        dispatch(patchInformation(values)).then(handleNextTrack);
    };

    const pages = {
        base: <GenderForm handleSubmit={handleSubmit} />,
    };

    return pages[trackPageLocation];
};

Gender.propTypes = propTypes;
Gender.defaultProps = defaultProps;

export default withRouter(withNavigation(Gender));
