import { SUPPORTED_LANGUAGES, LANGUAGE_EN, LANGUAGE_STORAGE_KEY } from './constants';

export const getLanguageByPriority = (languageFromQueryParam, languageFromStore) => {
    const supportedlanguageFromQueryParam = getSupportedLanguage([languageFromQueryParam]);
    const supportedlanguageFromStore = getSupportedLanguage([languageFromStore]);
    const languageFromLocalStorage = getLanguagefromLocalStorage();
    const languageFromBrowser = getLanguageFromBrowser();
    const defaultLanguage = LANGUAGE_EN;
    return (
        supportedlanguageFromQueryParam ||
        supportedlanguageFromStore ||
        languageFromLocalStorage ||
        languageFromBrowser ||
        defaultLanguage
    );
};

export const getLanguageFromBrowser = () => {
    if (!navigator) return LANGUAGE_EN;
    const languageList = navigator.languages ? navigator.languages : [navigator.language] || [];
    const preferredLanguage = getSupportedLanguage(languageList);
    return preferredLanguage;
};

const getSupportedLanguage = (languageList) => {
    let supportedLanguage;
    languageList.forEach((language) => {
        if (supportedLanguage) return;
        const validLanguage = SUPPORTED_LANGUAGES.find((lang) => lang === language?.split('-')[0]);
        supportedLanguage = validLanguage;
    });
    return supportedLanguage;
};

const getLanguagefromLocalStorage = () => {
    const languageFromLocalStorage = localStorage.getItem(LANGUAGE_STORAGE_KEY)?.split('-')[0];
    return getSupportedLanguage([languageFromLocalStorage]);
};
