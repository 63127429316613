import { FormattedMessage } from 'react-intl';
import { Button, Modal } from 'antd';

export const ConfirmCloseTrustmaticModal = ({
    isOpen,
    onClose,
    onOk,
}: {
    isOpen: boolean;
    onClose: () => void;
    onOk: () => void;
}) => {
    const title = (
        <strong>
            <FormattedMessage id="welcome.OneID.incompleteVerification" defaultMessage="Incomplete Verification" />
        </strong>
    );
    return (
        <Modal
            title={title}
            visible={isOpen}
            onCancel={onOk}
            onOk={onOk}
            footer={[
                <Button
                    key="exit"
                    onClick={onClose}
                    style={{ textTransform: 'uppercase' }}
                    data-testid="exit-verification"
                >
                    <FormattedMessage id="common.exit" defaultMessage="Exit" />
                </Button>,
                <Button
                    key="continue"
                    onClick={onOk}
                    type="primary"
                    style={{ textTransform: 'uppercase' }}
                    data-testid="continue-verification"
                >
                    <FormattedMessage id="welcome.OneID.continueVerification" defaultMessage="Continue Verification" />
                </Button>,
            ]}
        >
            <FormattedMessage
                id="welcome.OneID.incompleteVerification.body"
                defaultMessage="Are you sure you want to exit the identity verification process?"
            />
        </Modal>
    );
};
