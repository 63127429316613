import React from 'react';
import styles from 'styles/styles';

const SvgComponent = ({ scale }) => (
    <svg width={65} height={60} style={{ transform: `scale3d(${scale}, ${scale}, ${scale})` }}>
        <title>work_meeting_teamwork_office_business</title>
        <g stroke={styles.color.certnGreen700} strokeWidth={1.525} fill="none" fillRule="evenodd">
            <g strokeLinejoin="round">
                <path d="M42.309 24.915l-.551-5.291c-.207-2.491-2.107-3.811-4.213-3.811H27.456c-2.106 0-4.006 1.319-4.213 3.811l-.552 5.291" />
                <path
                    d="M28.906 3.051a4.706 4.706 0 1 0 7.187 6.077 4.706 4.706 0 0 0-7.187-6.077z"
                    strokeLinecap="round"
                />
            </g>
            <path d="M10.096 32.654h44.808" strokeLinejoin="round" />
            <path d="M32.5 32.565v26.057" />
            <g transform="translate(1 1)">
                <path d="M21.257 58.644l-3.958-16.677-12.796.003C-.695 41.255.138 35.842.441 33.358c.609-5.012 1.471-14.616 2.836-16.774 2.372-3.746 6.752-3.682 7.607-2.992 2 1.61 2.271 4.381 4.337 12.815l12.246 2.955" />
                <circle strokeLinecap="round" strokeLinejoin="round" cx={14.425} cy={5.056} r={4.7} />
                <path
                    d="M10.042 27.333l-1.068 6.853 11.3 1.932s2.139.482 2.663 2.809l3.239 18.982"
                    strokeLinejoin="round"
                />
                <path d="M8.496 22.302l2.794 8.779" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <path d="M42.744 59.644l3.957-16.677 12.795.003c5.199-.715 4.365-6.128 4.064-8.612-.609-5.012-1.471-14.616-2.836-16.774-2.373-3.746-6.754-3.682-7.607-2.992-2 1.61-2.271 4.381-4.338 12.815l-12.246 2.955" />
            <path
                d="M45.986 3.02a4.704 4.704 0 0 0 .551 6.625 4.702 4.702 0 0 0 6.072-7.177 4.7 4.7 0 0 0-6.623.552z"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M53.959 28.333l1.068 6.853-11.301 1.932s-2.137.482-2.662 2.809l-3.24 18.982"
                strokeLinejoin="round"
            />
            <path d="M55.504 23.302l-2.793 8.779" strokeLinecap="round" strokeLinejoin="round" />
        </g>
    </svg>
);

export default SvgComponent;
