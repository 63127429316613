// Libraries
import React from 'react';
import styled from 'styled-components/macro';

const SummaryNotice = ({ title, text, title2, text2 }) => (
    <Wrapper>
        <Title>{title}</Title>
        <Text>{text}</Text>
        {title2 && text2 && (
            <div>
                <br />
                <Title>{title2}</Title>
                <Text>{text2}</Text>
            </div>
        )}
    </Wrapper>
);

export default SummaryNotice;

const Wrapper = styled.div`
    background: ${({ theme }) => theme.color.certnGray100};
    display: block;
    text-align: left;
    padding: 15px;
    padding-right: 25px;
    padding-left: 25px;
    margin-top: 25px;
    margin-left: 25px;
    margin-right: 25px;
`;

const Title = styled.div`
    font-size: 16px;
    font-weight: bold;
`;

const Text = styled.div`
    font-size: 16px;
`;
