import { combineReducers } from 'redux';

import base from 'base/BaseReducer';
import manager from 'views/manager/ManagerReducer';
import guest from 'views/guest/GuestReducer';
import welcome from 'views/welcome/WelcomeReducer';
import reference from 'views/reference/ReferenceReducer';
import { fetchReducer as fetch } from 'utils/autoredux';

const rootReducer = combineReducers({
    base,
    manager,
    guest,
    welcome,
    reference,
    fetch,
});

export default rootReducer;
