import styled from 'styled-components';
import { Typography } from 'antd';

const { Text } = Typography;

export const SectionWrapper = styled.div`
    margin: 16px 16px 0;
    background: #fff;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
`;
export const WaitingPeriodWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 16px;
`;

export const WaitingPeriodTitle = styled(Text)`
    font-weight: 600;
    font-size: 16px;
`;

export const WaitingPeriodText = styled(Text)`
    font-size: 14px;
    line-height: 125%;
    opacity: 0.45;
`;
