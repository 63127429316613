// Libraries
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { DownloadOutlined } from '@ant-design/icons';
import { Form as AntdForm } from '@ant-design/compatible';
import { Button, DatePicker, Space } from 'antd';
import { injectIntl, FormattedMessage } from 'react-intl';

// Selectors
import { getLocale } from 'base/BaseSelectors';

// Modules
import Auth from 'modules/Auth';
import { getDateFormat, stringifyDate } from 'modules/Format';

// UI Components
import Typography from 'certnd/Typography';

const { RangePicker } = DatePicker;
const { Heading } = Typography;

const DateRangePicker = ({ title, label, form, handleButtonClick, disabled = false }) => {
    const [dateRange, setDateRange] = useState([]);
    const canEdit = Auth.isPermissionLevel('manager');
    const locale = useSelector(getLocale);
    const dateFormat = getDateFormat();

    const setDate = (val) => {
        if (val?.length > 0) {
            setDateRange([val[0].format(dateFormat), val[1].add(1, 'day').format(dateFormat)]);
        } else {
            setDateRange([]);
        }
    };

    return (
        <>
            <Heading.H3>{title}</Heading.H3>
            <FormattedMessage
                id="355e3.DateRangePicker.FormLabel"
                defaultMessage="Choose a date range to download {labelType} details in a CSV"
                values={{ labelType: label }}
            />
            <AntdForm.Item>
                {form.getFieldDecorator('range-picker')(
                    <RangePicker
                        disabledDate={(current) =>
                            // Can not select days before today and today
                            current && current.valueOf() > moment()
                        }
                        format={getDateFormat()}
                        ranges={{
                            Today: [moment(), moment()],
                            'Last Week': [moment().subtract(7, 'days'), moment()],
                            'This Month': [moment().startOf('month'), moment()],
                            'Last Month': [
                                moment().subtract(1, 'month').startOf('month'),
                                moment().startOf('month').subtract(1, 'day'),
                            ],
                        }}
                        onChange={setDate}
                        locale={locale}
                    />
                )}
            </AntdForm.Item>
            <Button
                type="primary"
                disabled={!canEdit || dateRange.length === 0 || disabled}
                onClick={() => {
                    handleButtonClick([stringifyDate(dateRange[0]), stringifyDate(dateRange[1])], false);
                }}
            >
                <Space>
                    <DownloadOutlined />
                    <FormattedMessage id="355e3.DateRangePicker.DownloadButton" defaultMessage="Download" />
                </Space>
            </Button>
        </>
    );
};

export default injectIntl(DateRangePicker);
