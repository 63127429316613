// Libraries
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Auth from 'modules/Auth';
import { injectIntl, FormattedMessage } from 'react-intl';

// Actions & Selectors
import { getSettings, getCurrentTeam } from 'views/manager/views/settings/SettingsSelectors';

// UI Components
import Form from 'certnd/Form';
import Typography from 'certnd/Typography';

const { Heading } = Typography;

const mapStateToProps = (state) => ({
    settings: getSettings(state),
    currentTeam: getCurrentTeam(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

class PersonalReferences extends React.Component {
    render() {
        const { currentTeam, form } = this.props;

        const personalRefAmountRequiredOptions = [
            {
                title: this.props.intl.formatMessage({
                    id: 'f3def.PersonalReferences.ListZero',
                    defaultMessage: 'Zero',
                }),
                key: 0,
                value: 0,
            },
            {
                title: this.props.intl.formatMessage({
                    id: 'f3def.PersonalReferences.ListOne',
                    defaultMessage: 'One',
                }),
                key: 1,
                value: 1,
            },
            {
                title: this.props.intl.formatMessage({
                    id: 'f3def.PersonalReferences.ListTwo',
                    defaultMessage: 'Two',
                }),
                key: 2,
                value: 2,
            },
        ];

        const canEdit = Auth.isPermissionLevel('manager');

        return (
            <Form.Block>
                <Heading.H3>
                    <FormattedMessage id="f3def.PersonalReferences.PageTitle" defaultMessage="Personal References" />
                </Heading.H3>
                <Form.Select
                    form={form}
                    fieldName="personal_ref_amount_req"
                    title={this.props.intl.formatMessage({
                        id: 'f3def.PersonalReferences.RefNumTitle',
                        defaultMessage: 'Number of Personal References Required',
                    })}
                    description={this.props.intl.formatMessage({
                        id: 'f3def.PersonalReferences.RefNumDescription',
                        defaultMessage: 'How many personal references does an applicant need to complete a screen?',
                    })}
                    options={{ initialValue: currentTeam.personal_ref_amount_req || 0 }}
                    selectOptions={personalRefAmountRequiredOptions}
                    disabled={!canEdit}
                />
                <Form.Button disabled={!canEdit}>
                    <FormattedMessage id="common.save" defaultMessage="Save" />
                </Form.Button>
            </Form.Block>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PersonalReferences));
