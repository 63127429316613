import {
    NAMESPACE as PARENT_NAMESPACE,
    STATEPATH as PARENT_STATEPATH,
} from 'views/manager/views/pm/PropertyManagerConstants';

import { getRequest, postRequest, putRequest, deleteRequest } from 'utils/http';
import { simpleStringifyJSON } from 'modules/Format';

import {} from 'lodash';

import Auth from 'modules/Auth';

import {
    getIsActive,
    getSearchBy,
    getOrdering,
    getFilterBy,
    getPageSize,
    getReverseOrder,
    getCurrentPage,
} from './ListingsSelectors';
import { uniqueEmails } from 'utils/unique-emails';

// Constants
export const NAMESPACE = `${PARENT_NAMESPACE}LISTINGS/`;
export const STATEPATH = `${PARENT_STATEPATH}.listings`;

// Actions Types
export const CLEAR_ERRORS = `${NAMESPACE}CLEAR_ERRORS`;
export const GET_LISTINGS_REQ = `${NAMESPACE}GET_LISTINGS_REQ`;
export const GET_LISTINGS_RES = `${NAMESPACE}GET_LISTINGS_RES`;
export const GET_LISTINGS_ERR = `${NAMESPACE}GET_LISTINGS_ERR`;
export const GET_ALL_LISTINGS_REQ = `${NAMESPACE}GET_ALL_LISTINGS_REQ`;
export const GET_ALL_LISTINGS_RES = `${NAMESPACE}GET_ALL_LISTINGS_RES`;
export const GET_ALL_LISTINGS_ERR = `${NAMESPACE}GET_ALL_LISTINGS_ERR`;
export const GET_LISTING_REQ = `${NAMESPACE}GET_LISTING_REQ`;
export const GET_LISTING_RES = `${NAMESPACE}GET_LISTING_RES`;
export const GET_LISTING_ERR = `${NAMESPACE}GET_LISTING_ERR`;
export const GET_NEW_LISTING_REQ = `${NAMESPACE}GET_NEW_LISTING_REQ`;
export const GET_NEW_LISTING_RES = `${NAMESPACE}GET_NEW_LISTING_RES`;
export const GET_NEW_LISTING_ERR = `${NAMESPACE}GET_NEW_LISTING_ERR`;
export const POST_LISTINGS_REQ = `${NAMESPACE}POST_LISTINGS_REQ`;
export const POST_LISTINGS_RES = `${NAMESPACE}POST_LISTINGS_RES`;
export const POST_LISTINGS_ERR = `${NAMESPACE}POST_LISTINGS_ERR`;
export const SILENT_CLOSE_LISTING_REQ = `${NAMESPACE}SILENT_CLOSE_LISTING_REQ`;
export const CLOSE_LISTING_REQ = `${NAMESPACE}CLOSE_LISTING_REQ`;
export const CLOSE_LISTING_RES = `${NAMESPACE}CLOSE_LISTING_RES`;
export const CLOSE_LISTING_ERR = `${NAMESPACE}CLOSE_LISTING_ERR`;
export const PUT_LISTINGS_REQ = `${NAMESPACE}PUT_LISTINGS_REQ`;
export const PUT_LISTINGS_RES = `${NAMESPACE}PUT_LISTINGS_RES`;
export const PUT_LISTINGS_ERR = `${NAMESPACE}PUT_LISTINGS_ERR`;
export const SILENT_DEL_LISTINGS_REQ = `${NAMESPACE}SILENT_DEL_LISTINGS_REQ`;
export const DEL_LISTINGS_REQ = `${NAMESPACE}DEL_LISTINGS_REQ`;
export const DEL_LISTINGS_RES = `${NAMESPACE}DEL_LISTINGS_RES`;
export const DEL_LISTINGS_ERR = `${NAMESPACE}DEL_LISTINGS_ERR`;
export const GET_TEAM_MEMBERS_REQ = `${NAMESPACE}GET_TEAM_MEMBERS_REQ`;
export const GET_TEAM_MEMBERS_RES = `${NAMESPACE}GET_TEAM_MEMBERS_RES`;
export const GET_TEAM_MEMBERS_ERR = `${NAMESPACE}GET_TEAM_MEMBERS_ERR`;
export const POST_MERGE_APPLICANTS_REQ = `${NAMESPACE}POST_MERGE_APPLICANTS_REQ`;
export const POST_MERGE_APPLICANTS_RES = `${NAMESPACE}POST_MERGE_APPLICANTS_RES`;
export const POST_MERGE_APPLICANTS_ERR = `${NAMESPACE}POST_MERGE_APPLICANTS_ERR`;

// List Actions Types
export const SET_IS_ACTIVE = `${NAMESPACE}SET_IS_ACTIVE`;
export const SET_SEARCH_BY = `${NAMESPACE}SET_SEARCH_BY`;
export const SET_ORDERING = `${NAMESPACE}SET_ORDERING`;
export const SET_PAGE_SIZE = `${NAMESPACE}SET_PAGE_SIZE`;
export const SET_FILTER_BY = `${NAMESPACE}SET_FILTER_BY`;
export const CLEAR_FILTERS = `${NAMESPACE}CLEAR_FILTERS`;
export const RESET_LIST = `${NAMESPACE}RESET_LIST`;

// Action Creators
export const fetchListings = (page = null, silent = false) => (dispatch, getState) => {
    const state = getState();
    const isActive = getIsActive(state) ? 'True' : 'False';
    const searchBy = getSearchBy(state);
    const ordering = getOrdering(state);
    const reverseOrder = getReverseOrder(state);
    const filterBy = getFilterBy(state);
    const pageSize = getPageSize(state);
    const currentPage = getCurrentPage(state);

    const nextPage = page || currentPage;

    let queryParams = `?is_active=${isActive}&page=${nextPage}&page_size=${pageSize}`;
    let filterQueryParams = '';
    if (searchBy) queryParams += `&search=${searchBy}`;
    if (ordering) queryParams += `&ordering=${reverseOrder ? `-${ordering}` : ordering}`;
    if (filterBy) {
        Object.keys(filterBy).forEach((filterKey) => {
            filterQueryParams += `&${filterKey}=${filterBy[filterKey]}`;
        });
        queryParams += filterQueryParams;
    }

    if (!silent) {
        dispatch({
            type: GET_LISTINGS_REQ,
        });
    }
    return getRequest({
        internal: false,
        version: 'v2',
        endpoint: `/listings/${queryParams}`,
    }).then(
        (response) => {
            dispatch({
                type: GET_LISTINGS_RES,
                payload: {
                    listings: response.results,
                    numListings: response.count,
                    previousPage: response.previous,
                    nextPage: response.next,
                    currentPage: nextPage,
                },
            });
        },
        (error) => {
            dispatch({
                type: GET_LISTINGS_ERR,
                payload: { error },
            });
        }
    );
};

export const fetchAllListings = (propertyId = null) => (dispatch) => {
    let queryParams = '?is_active=true&page=1&page_size=9001&ordering=property__address';
    if (propertyId) queryParams += `&property_id=${propertyId}`;
    dispatch({
        type: GET_ALL_LISTINGS_REQ,
    });
    return getRequest({
        internal: false,
        version: 'v2',
        endpoint: `/listings/${queryParams}`,
    }).then(
        (response) => {
            dispatch({
                type: GET_ALL_LISTINGS_RES,
                payload: {
                    allListings: response.results,
                    numAllListings: response.count,
                },
            });
        },
        (error) => {
            dispatch({
                type: GET_ALL_LISTINGS_ERR,
                payload: { error },
            });
        }
    );
};

export const fetchListing = (listingId, relist = false) => (dispatch) => {
    dispatch({
        type: GET_LISTING_REQ,
    });
    return getRequest({
        internal: false,
        version: 'v2',
        endpoint: `/listings/${listingId}/`,
    }).then(
        (response) => {
            if (relist) {
                dispatch({
                    type: GET_NEW_LISTING_RES,
                    payload: {
                        newListing: response,
                    },
                });
            } else {
                dispatch({
                    type: GET_LISTING_RES,
                    payload: {
                        listing: response,
                    },
                });
            }
        },
        (error) => {
            dispatch({
                type: GET_LISTING_ERR,
                payload: { error },
            });
        }
    );
};

export const createListing = (formData) => (dispatch) => {
    formData.owner_id = Auth.getUserID();
    dispatch({
        type: POST_LISTINGS_REQ,
    });
    return postRequest({
        internal: false,
        version: 'v2',
        endpoint: '/listings/',
        body: JSON.stringify(formData),
    }).then(
        (response) => {
            // TODO: Figure out if anything is listening for "listing" in this case
            dispatch({
                type: POST_LISTINGS_RES,
                payload: {
                    listing: response,
                    newListing: response,
                },
            });
            return response;
        },
        (error) => {
            dispatch({
                type: POST_LISTINGS_ERR,
                payload: { error },
            });
            throw error;
        }
    );
};

export const editListing = (listingId, formData) => (dispatch) => {
    dispatch({
        type: PUT_LISTINGS_REQ,
    });
    return putRequest({
        internal: false,
        version: 'v2',
        endpoint: `/listings/${listingId}/`,
        body: JSON.stringify(simpleStringifyJSON(formData)),
    }).then(
        (response) => {
            dispatch({
                type: PUT_LISTINGS_RES,
                payload: {
                    listing: response,
                },
            });
        },
        (error) => {
            dispatch({
                type: PUT_LISTINGS_ERR,
                payload: { error },
            });
        }
    );
};

export const deleteListing = (listingId) => (dispatch) => {
    dispatch({
        type: SILENT_DEL_LISTINGS_REQ,
    });
    return deleteRequest({
        internal: false,
        version: 'v2',
        endpoint: `/listings/${listingId}/`,
    }).then(
        () => {
            dispatch({
                type: DEL_LISTINGS_RES,
            });
            return dispatch(fetchListings(null, true));
        },
        (error) => {
            dispatch({
                type: DEL_LISTINGS_ERR,
                payload: { error },
            });
        }
    );
};

export const toggleListingInactive = (listingId) => (dispatch) => {
    dispatch({
        type: SILENT_CLOSE_LISTING_REQ,
    });
    return putRequest({
        internal: false,
        version: 'v2',
        endpoint: `/listings/${listingId}/`,
        body: JSON.stringify({ is_active: false, url_code: null }),
    }).then(
        () => {
            dispatch({
                type: CLOSE_LISTING_RES,
            });
            return dispatch(fetchListings(null, true));
        },
        (error) => {
            dispatch({
                type: CLOSE_LISTING_ERR,
                payload: { error },
            });
        }
    );
};

export const mergeApplicants = (emails, listingId) => (dispatch) => {
    const bodyData = {
        emails: uniqueEmails(emails),
        listing_id: listingId,
    };
    dispatch({
        type: POST_MERGE_APPLICANTS_REQ,
    });
    return postRequest({
        internal: false,
        version: 'v2',
        endpoint: '/applications/merge/', // TODO this endpoint doesn't exist
        body: JSON.stringify(simpleStringifyJSON(bodyData)),
    }).then(
        (response) => {
            dispatch({
                type: POST_MERGE_APPLICANTS_RES,
                payload: {
                    application: response,
                },
            });
        },
        (error) => {
            dispatch({
                type: POST_MERGE_APPLICANTS_ERR,
                payload: { error },
            });
        }
    );
};

export const fetchTeamMembers = () => (dispatch) => {
    dispatch({
        type: GET_TEAM_MEMBERS_REQ,
    });
    return getRequest({
        internal: false,
        version: 'v2',
        endpoint: '/team_members/',
    }).then(
        (response) => {
            dispatch({
                type: GET_TEAM_MEMBERS_RES,
                payload: {
                    teamMembers: response,
                },
            });
        },
        (error) => {
            dispatch({
                type: GET_TEAM_MEMBERS_ERR,
                payload: { error },
            });
        }
    );
};

export const clearErrors = () => (dispatch) =>
    dispatch({
        type: CLEAR_ERRORS,
        payload: {
            error: undefined,
        },
    });

// List Action Creators
export const setIsActive = (isActive = true) => (dispatch, getState) => {
    const currentIsActive = getIsActive(getState());
    dispatch({
        type: SET_IS_ACTIVE,
        payload: {
            isActive,
        },
    });
    if (currentIsActive === isActive) return dispatch(fetchListings());
    return dispatch(fetchListings(1));
};

export const setSearchBy = (searchBy) => (dispatch) => {
    dispatch({
        type: SET_SEARCH_BY,
        payload: {
            searchBy,
        },
    });
    return dispatch(fetchListings(1));
};

export const setOrdering = (ordering) => (dispatch, getState) => {
    const currentOrdering = getOrdering(getState());
    const reverseOrder = currentOrdering === ordering ? !getReverseOrder(getState()) : false;
    dispatch({
        type: SET_ORDERING,
        payload: {
            ordering,
            reverseOrder,
        },
    });
    return dispatch(fetchListings(1));
};

export const setPageSize = (pageSize) => (dispatch) => {
    dispatch({
        type: SET_PAGE_SIZE,
        payload: {
            pageSize,
        },
    });
};

export const setFilterBy = (filterBy) => (dispatch) => {
    dispatch({
        type: SET_FILTER_BY,
        payload: {
            filterBy,
        },
    });
    return dispatch(fetchListings(1));
};

export const clearFilters = () => (dispatch) => {
    dispatch({
        type: CLEAR_FILTERS,
        payload: {
            filterBy: {},
        },
    });
    return dispatch(fetchListings(1));
};

export const resetListAndFetch = () => (dispatch) => {
    dispatch({
        type: RESET_LIST,
        payload: {},
    });
    return dispatch(fetchListings(1));
};

export const resetListAndFilter = (filterBy) => (dispatch) => {
    dispatch({
        type: RESET_LIST,
        payload: {},
    });
    return dispatch(setFilterBy(filterBy));
};
