import Form, { FormInstance } from 'antd/lib/form';
import { InputTransform, Regex } from 'modules';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Input, Popover } from 'antd';

type EmailInputProps = {
    form: FormInstance;
    isLime: boolean;
};

export const EmailInput: React.FC<EmailInputProps> = ({ form, isLime }) => {
    const intl = useIntl();
    const [emailTrigger, setEmailTrigger] = useState('onBlur');

    const onEmailChange = useCallback(() => {
        const emailError = form.getFieldError('email');
        if (emailError?.[0]) {
            setEmailTrigger('onChange');
        }
    }, [form, setEmailTrigger]);

    return (
        <Form.Item
            name="email"
            validateTrigger={emailTrigger}
            getValueFromEvent={InputTransform.noSpaces}
            rules={[
                {
                    type: 'email',
                    message: intl.formatMessage({
                        id: 'error.validation.email.invalid',
                        defaultMessage: 'The input is not a valid E-mail!',
                    }),
                },
                {
                    required: true,
                    message: intl.formatMessage({
                        id: 'error.validation.email.required',
                        defaultMessage: 'Please input your E-mail!',
                    }),
                },
                () => ({
                    validator(_, value) {
                        if (isLime && value?.match(Regex.freeEmailDomains)) {
                            return Promise.reject(
                                new Error(
                                    intl.formatMessage({
                                        id: 'error.validation.workEmailAddressOnly',
                                        defaultMessage:
                                            'Work addresses permitted only. Please use our help chat with any questions.',
                                    })
                                )
                            );
                        }
                        return Promise.resolve();
                    },
                }),
            ]}
        >
            <Input
                size="large"
                data-testid="email"
                placeholder={
                    isLime
                        ? intl.formatMessage({
                              id: 'welcome.Email.workEmailAddress',
                              defaultMessage: 'Work Email Address',
                          })
                        : intl.formatMessage({
                              id: 'common.emailAddress',
                              defaultMessage: 'Email Address',
                          })
                }
                onChange={onEmailChange}
                autoFocus
                addonBefore={
                    <Popover
                        content={intl.formatMessage({
                            id: 'common.emailAddress',
                            defaultMessage: 'Email Address',
                        })}
                    >
                        <InfoCircleOutlined />
                    </Popover>
                }
            />
        </Form.Item>
    );
};
