// Libraries
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { getDataSet, reduce } from 'iso3166-2-db';
import { cloneDeep } from 'lodash';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

// Components
import { ErrorAlertCustom } from 'certn-ui/ErrorAlert';
import { AddressForm, ReferenceForm, Base } from './components';

// Modules
import withNavigation from 'views/welcome/modules/WithNavigation';
import withSettingsChecker from 'views/welcome/modules/WithSettingsChecker';
import Format from 'modules/Format';
import { getEndDateBeforeStartDateError } from 'modules/validation';
import { OTHER } from 'modules/Countries';

// Actions & Selectors
import { getAddressError, getLanguage, getLaunchDarklyFlags } from 'base/BaseSelectors';
import { updateAddressError } from 'base/BaseActions';
import { setTrackPageLocation, setTrackPageOrder, patchInformation } from 'views/welcome/WelcomeActions';
import {
    getIsFetchingSilent,
    getInformation,
    getAddresses,
    getTrackOrder,
    getOnboardingType,
    getSettings,
    getApplicant,
    getApplicantAccount,
    getTeamIsMyCRC,
    getIsUkRtwCheck,
    getIsUkDbsOrDsCheck,
} from 'views/welcome/WelcomeSelectors';

// Constants
import { CHECK_REQUEST } from 'base/BaseConstants';
import AddressHistoryWarningModal from './components/AddressHistoryWarningModal';
import { checkForMissingYearsInAddressHistory } from 'utils/addressInputHelpers';
import { CheckRequested } from '../../../../modules';

const mapStateToProps = (state) => ({
    isFetchingSilent: getIsFetchingSilent(state),
    information: getInformation(state),
    addresses: getAddresses(state),
    addressError: getAddressError(state),
    onboardingType: getOnboardingType(state),
    settings: getSettings(state),
    applicant: getApplicant(state),
    applicantAccount: getApplicantAccount(state),
    tracks: getTrackOrder(state),
    teamIsMyCRC: getTeamIsMyCRC(state),
    isUkRtwCheck: getIsUkRtwCheck(state),
    isUkDbsOrDsCheck: getIsUkDbsOrDsCheck(state),
    lang: getLanguage(state),
    webFeatureEnableCcrcAddressHistory: getLaunchDarklyFlags(state)?.webFeatureEnableCcrcAddressHistory,
    webFeatureRequireCurrentAndPositionPropertyAddressesForOnboarding: getLaunchDarklyFlags(state)
        ?.webFeatureRequireCurrentAndPositionPropertyAddressesForOnboarding,
});

const mapDispatchToProps = (dispatch) => ({
    dispatch,
    ...bindActionCreators(
        {
            patchInformation,
            updateAddressError,
            setTrackPageOrder,
            setTrackPageLocation,
        },
        dispatch
    ),
});

const propTypes = {
    // Redux Actions:
    patchInformation: PropTypes.func.isRequired,
    setTrackPageOrder: PropTypes.func.isRequired,
    updateAddressError: PropTypes.func.isRequired,
    // Redux Store:
    isFetchingSilent: PropTypes.bool.isRequired,
    information: PropTypes.object.isRequired,
    settings: PropTypes.object.isRequired,
    applicant: PropTypes.object,
    addresses: PropTypes.array,
    onboardingType: PropTypes.string,
    addressError: PropTypes.bool,
    teamIsMyCRC: PropTypes.bool,
    isUkRtwCheck: PropTypes.bool,
    isUkDbsOrDsCheck: PropTypes.bool,
    isUkCheck: PropTypes.bool,
};
const defaultProps = {
    applicant: {},
    addresses: [],
    addressError: false,
    onboardingType: 'PM',
    teamIsMyCRC: false,
    isUkRtwCheck: false,
    isUkDbsOrDsCheck: false,
    isUkCheck: false,
};

class Addresses extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentAddressIndex: 0,
            errorStatus: null,
            userIsCA: props.settings.get_org_country === 'CA',
            modalVisible: false,
            modalError: null,
            modalMissingYears: [],
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.setTrackPageOrder(
            this.props.onboardingType === 'PM' ? ['base', 'address', 'reference'] : ['base', 'address']
        );
    }

    componentDidUpdate() {
        const { addresses, applicant } = this.props;
        const isUkCheck = applicant[CHECK_REQUEST.UK_BASIC_DBS_CHECK] || applicant[CHECK_REQUEST.UK_BASIC_DS_CHECK];

        if (isUkCheck && addresses.some((address) => address.current && !address.start_date)) {
            // This is a special situation that arises when current address was prefilled by OneID.
            // Force them into the address form to enter the start date
            this.props.setTrackPageLocation('address');
        }
    }

    handleUpdateState = (field, value) => this.setState({ [field]: value });

    handleUpdateCurrent = (id) => {
        const { addresses } = this.props;
        addresses.forEach((address) => {
            address.current = false;
            if (address.id === id) address.current = true;
        });
        this.props.patchInformation({ addresses });
    };

    pages = () => {
        const { returnToBase, settings, applicantAccount, applicant } = this.props; /* WithNavigation */
        const requests = this.props.applicant || settings;
        const applicantEmail = applicantAccount && applicantAccount.email;

        // Checks with specific address history requirements
        const requireCCRC5YearAddressHistoryDates =
            this.props.webFeatureEnableCcrcAddressHistory &&
            (this.props.applicant.request_criminal_record_check ||
                this.props.applicant.request_enhanced_criminal_record_check);
        const require5YearAddressHistoryDates =
            requireCCRC5YearAddressHistoryDates || applicant[CHECK_REQUEST.AUSTRALIAN_NATIONAL_POLICE_CHECK];
        const isUkCheck = applicant[CHECK_REQUEST.UK_BASIC_DBS_CHECK] || applicant[CHECK_REQUEST.UK_BASIC_DS_CHECK];
        const isInternationalCriminalRecordCheck = applicant && CheckRequested.internationalCriminal(applicant);
        const isAustraliaACICCheck = applicant && CheckRequested.australianACIC(applicant);
        const isConfigurablePotatoWithAddress = applicant && applicant[CHECK_REQUEST.INTERNATIONAL_FINANCIAL_CHECK];

        // Global address history requirement - check agnostic
        const has_global_address_history_requirement =
            settings?.global_address_history_years != null && settings.global_address_history_years > 0;

        // Build the address history controller based on the checks
        const address_history_years_max = () => {
            let y = 0;
            if (isInternationalCriminalRecordCheck) y = settings.required_address_history_years;
            if (isAustraliaACICCheck || require5YearAddressHistoryDates) y = Math.max(y, 5);
            if (isUkCheck) y = Math.max(y, 5);
            if (has_global_address_history_requirement) y = Math.max(y, settings.global_address_history_years);
            return y;
        };
        const requiredYears = address_history_years_max();
        const showDateFields =
            has_global_address_history_requirement ||
            this.props.onboardingType === 'PM' ||
            require5YearAddressHistoryDates ||
            this.props.isUkDbsOrDsCheck ||
            isInternationalCriminalRecordCheck ||
            isAustraliaACICCheck ||
            isConfigurablePotatoWithAddress;

        const handleModalCancel = () => {
            this.setState({ modalVisible: false });
        };

        const handleSubmitAddressHistoryWarningModal = () => {
            this.setState({ modalVisible: false });
            this.props.runSettingsCheck();
        };

        const checkAddressHistory = () => {
            if (require5YearAddressHistoryDates) {
                const { addresses } = this.props;
                const missingYearsCheck = checkForMissingYearsInAddressHistory(addresses, requiredYears, new Date());
                if (missingYearsCheck.error) {
                    this.setState({ modalError: missingYearsCheck.error });
                    this.setState({ modalVisible: true });
                    this.setState({ modalMissingYears: [] });
                    return;
                }
                if (missingYearsCheck.missingYears.length > 0) {
                    this.setState({ modalError: null });
                    this.setState({ modalVisible: true });
                    this.setState({ modalMissingYears: missingYearsCheck.missingYears });
                    return;
                }
            }
            if (isUkCheck) {
                const { addresses } = this.props;
                this.props
                    .patchInformation({ addresses })
                    .then(() => {
                        this.props.runSettingsCheck();
                    })
                    .catch(() => {
                        ErrorAlertCustom();
                    });
            } else {
                this.props.runSettingsCheck();
            }
        };

        return {
            base: (
                <div>
                    <AddressHistoryWarningModal
                        modalVisible={this.state.modalVisible}
                        handleModalCancel={handleModalCancel}
                        handleNextTrack={handleSubmitAddressHistoryWarningModal}
                        missingYears={this.state.modalMissingYears}
                        requiredYears={requiredYears}
                        error={this.state.modalError}
                    />
                    <Base
                        handleUpdate={this.handleUpdateCurrent}
                        handleSubmit={checkAddressHistory}
                        isFetchingSilent={this.props.isFetchingSilent}
                        addresses={this.props.addresses}
                        addOrEditAddress={this.addOrEditAddress}
                        deleteAddress={this.deleteAddress}
                        setValue={this.setValue}
                        onboardingType={this.props.onboardingType}
                        showBottomText={this.showBottomText}
                        tracks={this.props.tracks}
                        requiredYears={requiredYears}
                        isUkCheck={isUkCheck}
                    />
                </div>
            ),
            address: (
                <AddressForm
                    handleSubmit={this.handleSubmit}
                    isFetchingSilent={this.props.isFetchingSilent}
                    addresses={this.props.addresses}
                    currentAddressIndex={this.state.currentAddressIndex}
                    onboardingType={this.props.onboardingType}
                    tracks={this.props.tracks}
                    showDateFields={showDateFields}
                    requiredYears={requiredYears}
                    country={this.props.settings.get_org_country}
                />
            ),
            reference: (
                <ReferenceForm
                    handleSubmit={this.handleSubmit}
                    isFetchingSilent={this.props.isFetchingSilent}
                    addresses={this.props.addresses}
                    currentAddressIndex={this.state.currentAddressIndex}
                    returnToBase={returnToBase}
                    settings={this.props.settings}
                    errorStatus={this.state.errorStatus}
                    applicantEmail={applicantEmail}
                    userIsCA={this.state.userIsCA}
                    emailReferenceRequired={
                        requests[CHECK_REQUEST.ADDRESS_REFERENCES] || settings.tenancy_ref_email_req
                    }
                    phoneReferenceRequired={
                        requests[CHECK_REQUEST.ADDRESS_PHONE_REFERENCES] || settings.tenancy_ref_phone_req
                    }
                />
            ),
        };
    };

    // How each of the pages patches the module in parts
    handleSubmit = (e, validateFields, setFields, bypassAutocomplete = false, phone = null) => {
        e.preventDefault();
        const {
            handleForward,
            trackPageLocation,
            returnToBase,
            information,
            applicantAccount,
            webFeatureRequireCurrentAndPositionPropertyAddressesForOnboarding,
        } = this.props; /* WithNavigation */
        const { settings, teamIsMyCRC } = this.props;
        const exclusivelyUkRtwCheck = this.props.isUkRtwCheck && !this.props.isUkDbsOrDsCheck;
        const applicantEmail = applicantAccount && applicantAccount.email;
        const applicantPhone = information.phone_number && parsePhoneNumberFromString(information.phone_number, 'CA');
        const countries = reduce(getDataSet(), this.props.lang);

        validateFields((err, values) => {
            const { request_address_phone_references } = this.props.applicant || this.props.settings;
            const { currentAddressIndex } = this.state;

            let newValues = cloneDeep(values);

            if (values.country) {
                newValues = { ...newValues, country_label: countries[values.country].name };
            }

            let newErr = err;

            // Add default province_state value of OT for international countries
            if (newValues.other_province_state && !newValues.province_state) {
                newValues.province_state = OTHER;
            }

            // Address phone reference
            if (
                trackPageLocation === 'reference' &&
                (request_address_phone_references || settings.tenancy_ref_phone_req) &&
                !phone
            ) {
                this.handleUpdateState('errorStatus', 'error');
                newErr = true;
            }

            if (
                values.country === 'US' &&
                !values.county &&
                webFeatureRequireCurrentAndPositionPropertyAddressesForOnboarding
            ) {
                this.handleUpdateState('errorStatus', 'error');
                newErr = true;
                this.props.updateAddressError(true);
            }

            const email = newValues.landlords_email;
            if (trackPageLocation === 'reference' && email && email === applicantEmail) {
                ErrorAlertCustom({
                    description: 'Reference email must be different from applicant email.',
                });
                newErr = true;
            }

            if (trackPageLocation === 'reference' && phone) {
                const phoneNumber = phone && parsePhoneNumberFromString(phone, 'CA');
                const isPhoneRefDifferentFromApplicant = phoneNumber.nationalNumber !== applicantPhone?.nationalNumber;
                if (phoneNumber && phoneNumber.isValid() && isPhoneRefDifferentFromApplicant) {
                    this.handleUpdateState('errorStatus', 'success');
                } else {
                    if (!isPhoneRefDifferentFromApplicant) {
                        ErrorAlertCustom({
                            description: 'Reference phone number must be different from applicant phone number.',
                        });
                    }
                    this.handleUpdateState('errorStatus', 'error');
                    newErr = true;
                }

                const addressReference = {
                    contact: {
                        can_contact: true,
                        is_reference: true,
                        type: 'TENANCY',
                    },
                    email: {
                        address: newValues.landlords_email,
                    },
                    name: {
                        first_name: newValues.landlords_first_name,
                        last_name: newValues.landlords_last_name,
                    },
                    phone_number: {
                        number: phoneNumber.formatInternational(),
                        national_number: phoneNumber.nationalNumber,
                        country: phoneNumber.country,
                        calling_code: phoneNumber.countryCallingCode,
                        phone_type: newValues.phone_type,
                    },
                };

                newValues.address_reference = addressReference;
            }

            const dateCompareError = getEndDateBeforeStartDateError(newValues.end_date, newValues.start_date);
            if (dateCompareError) {
                setFields({
                    start_date: {
                        value: newValues.start_date,
                        errors: [new Error(dateCompareError)],
                    },
                });
            }

            if (!bypassAutocomplete) {
                if (!newValues.address || !newValues.city || !newValues.country) {
                    this.props.updateAddressError(true);
                }
            }

            if (newValues.address_line_1 || newValues.address_line_2) {
                if (newValues.address_line_2) {
                    newValues.unit = newValues.address_line_1;
                    newValues.address = newValues.address_line_2;
                } else {
                    newValues.address = newValues.address_line_1;
                    newValues.unit = '';
                }
                delete newValues.address_line_1;
                delete newValues.address_line_2;
            }

            if (!newValues) newErr = true;

            if (!newErr && !dateCompareError) {
                const { addresses } = this.props;

                // Replace the values at the current index with values -- or if new, add new entry
                try {
                    // EDIT: Grab existing entry data at index, then add new values from form
                    const patchValues = addresses;
                    if (patchValues[currentAddressIndex]) {
                        patchValues[currentAddressIndex] = {
                            province_state: OTHER,
                            other_province_state: null,
                            ...patchValues[currentAddressIndex],
                            ...newValues,
                        };

                        if (patchValues[currentAddressIndex].current) {
                            patchValues[currentAddressIndex].end_date = null;
                            delete patchValues[currentAddressIndex].reason_for_leaving;
                        }
                    }
                    // ADD: Add new values from form, and grab the new index
                    else patchValues.push(newValues);

                    if (teamIsMyCRC || exclusivelyUkRtwCheck) patchValues[0].current = true;

                    this.props
                        .patchInformation({ addresses: patchValues })
                        .then(() =>
                            newValues !== null &&
                            newValues.rent_or_own === 'O' /* Home Owner doesn't need reference return to Base */
                                ? returnToBase()
                                : handleForward()
                        );
                } catch (error) {
                    console.error('error', error);
                }
            } else {
                // provide failure error?
            }
        });
    };

    deleteAddress = (addressIndex) => {
        const newAddresses = this.props.addresses;
        newAddresses.splice(addressIndex, 1);
        this.props.patchInformation({ addresses: newAddresses }).then((data) => data);
    };

    // Provide an index to addresses array if you want to edit an existing entry
    addOrEditAddress = (addressIndex = null) => {
        const { handleForward } = this.props; /* WithNavigation */
        let newAddressIndex = addressIndex;
        if (addressIndex === null) {
            newAddressIndex = this.props.addresses && this.props.addresses.length;
        }
        this.setState({ currentAddressIndex: newAddressIndex }, handleForward);
    };

    showBottomText = (address) => {
        let bottomText = null;
        const { applicant } = this.props;
        const isCanadianCriminalCheck = CheckRequested.canadianCriminal(applicant);

        if (this.props.onboardingType === 'PM') {
            const message = address.current ? (
                this.props.intl.formatMessage({
                    id: 'welcome.Addresses.currentAddress',
                    defaultMessage: 'Current Address',
                })
            ) : address.end_date ? (
                `${Format.date(address.start_date)} - ${Format.date(address.end_date)}`
            ) : (
                <FormattedMessage
                    id="welcome.Addresses.startWithPresent"
                    defaultMessage="{start} - Present"
                    values={{ start: Format.date(address.start_date) }}
                />
            );
            bottomText = message;
        } else if (this.props.onboardingType === 'HR' && isCanadianCriminalCheck) {
            bottomText = address.current ? (
                <FormattedMessage id="welcome.Addresses.currentAddress" defaultMessage="Current Address" />
            ) : (
                <FormattedMessage id="welcome.Addresses.pastAddress" defaultMessage="Past Address" />
            );
        }
        return bottomText;
    };

    render() {
        const { trackPageLocation } = this.props; /* WithNavigation */

        return this.pages()[trackPageLocation];
    }
}

Addresses.propTypes = propTypes;
Addresses.defaultProps = defaultProps;

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(injectIntl(withNavigation(withSettingsChecker(Addresses))))
);
