import React from 'react';
import { withRouter } from 'react-router-dom';

import { QuestionCircleOutlined } from '@ant-design/icons';

// UI Components
import { Button, Tooltip } from 'antd';
import { TitleHeader } from 'views/manager/components';
import { ListCellLink } from 'certn-ui/List';
import Overlay from 'certn-ui/Overlay';

const ApplicantsCell = ({ record, copyToClipboard }) => {
    const urlCode = record.url_code;
    return (
        <>
            <Overlay
                layer={
                    <Button size="small" onClick={() => copyToClipboard(urlCode)}>
                        Copy
                    </Button>
                }
            >
                <ListCellLink onClick={() => copyToClipboard(urlCode)}>{urlCode}</ListCellLink>
            </Overlay>
        </>
    );
};

ApplicantsCell.Header = ({ data, title, reverseOrder, setOrdering, ordering }) => (
    <TitleHeader
        title={title}
        handleClick={() => (data.active ? setOrdering(data.active) : null)}
        active={data.active ? ordering === data.active : false}
        clickable={data.active}
        reverseOrder={reverseOrder}
    >
        <span style={{ marginRight: '2px' }}>Apply Link </span>
        <Tooltip title="This link will allow applicants to apply directly for this Package">
            <QuestionCircleOutlined />
        </Tooltip>
    </TitleHeader>
);

ApplicantsCell.propTypes = {};

export default withRouter(ApplicantsCell);
