// Modules
import { AppearRight } from 'certn-ui/Animate';
import Loader from 'certn-ui/Loader';
import { useRequest } from 'hooks/queryHooks/useRequest';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CHECK_REQUEST } from 'base/BaseConstants';
import { ApplicantName } from 'types';
import { deleteRequest, patchRequest, postRequest } from 'utils/http';
import { patchInformation } from 'views/welcome/WelcomeActions';
import {
    getApplicant,
    getIsUkDbsOrDsCheck,
    getInformation,
    getTeam,
    getIsUkRtwCheck,
} from 'views/welcome/WelcomeSelectors';
import withNavigation from 'views/welcome/modules/WithNavigation';
import NewBasicsForm, { NewBasicsFormValues } from './components/NewBasicsForm';
import moment from 'moment';
import { getDateFormat } from 'modules/Format';
import { ErrorAlertAPI } from 'certn-ui/ErrorAlert';
import { UkCheckType } from './types';

interface Props {
    handleNextTrack: () => void;
}

const NewBasics = ({ handleNextTrack }: Props) => {
    const [phoneNumberStatus, setPhoneNumberStatus] = useState<'error' | 'success'>();
    const information = useSelector(getInformation);
    const { country } = useSelector(getTeam);
    const applicant = useSelector(getApplicant);
    const isUkDbsOrDsCheck = useSelector(getIsUkDbsOrDsCheck);
    const isUkRtwCheck = useSelector(getIsUkRtwCheck);

    const dispatch = useDispatch();

    const [submitPreviousNames, setSubmitPreviousNames] = useState(!isUkDbsOrDsCheck);

    const query = useRequest<ApplicantName[]>({
        version: 'v2',
        endpoint: `/information/${information?.id}/applicantnames/`,
    });

    const updateApplicantNames = useCallback(
        (oldNames: ApplicantName[] = [], newNames: Partial<ApplicantName>[]) => {
            const newNameIds = newNames.map((applicantName) => applicantName.id);
            const namesToDelete = oldNames.filter((applicantName) => !newNameIds.includes(applicantName.id));

            const deleteOperations = namesToDelete.map((applicantName) =>
                deleteRequest({ version: 'v2', endpoint: `/applicantnames/${applicantName.id}/` })
            );

            let createOperations: Promise<unknown>[] = [];
            if (submitPreviousNames) {
                createOperations = newNames.map((name) => {
                    if (name.id) {
                        return patchRequest({
                            version: 'v2',
                            endpoint: `/applicantnames/${name.id}/`,
                            body: JSON.stringify(name),
                        });
                    }

                    return postRequest({
                        version: 'v2',
                        endpoint: `/information/${information?.id}/applicantnames/`,
                        body: JSON.stringify(name),
                    });
                });
            } else if (newNames[0].id) {
                createOperations = [
                    patchRequest({
                        version: 'v2',
                        endpoint: `/applicantnames/${newNames[0].id}/`,
                        body: JSON.stringify(newNames[0]),
                    }),
                ];
            } else {
                createOperations = [
                    postRequest({
                        version: 'v2',
                        endpoint: `/information/${information?.id}/applicantnames/`,
                        body: JSON.stringify(newNames[0]),
                    }),
                ];
            }

            return Promise.all([...deleteOperations, ...createOperations]).catch((error) => {
                ErrorAlertAPI(error);
                throw error;
            });
        },
        [information?.id, submitPreviousNames]
    );

    const handleFinish = useCallback(
        async (values: NewBasicsFormValues) => {
            const phoneNumber = values.phone && parsePhoneNumberFromString(values.phone, 'CA');

            if (!phoneNumber || !phoneNumber.isValid()) {
                setPhoneNumberStatus('error');
                return;
            }

            setPhoneNumberStatus('success');

            values.applicantNames[0].type = 'PRIMARY';
            if (isUkDbsOrDsCheck) {
                values.applicantNames = values.applicantNames.map((applicantName, index) => ({
                    ...applicantName,
                    ...(index === 0 && { name_end_date: undefined }),
                    ...(index > 0 && { type: 'FORMER' }),
                    ...(applicantName.title !== 'OTHER' && { other_title: '' }),
                }));
            }

            if (applicant?.[CHECK_REQUEST.UK_BASIC_DBS_CHECK]) {
                values.applicantNames.forEach((applicantName, index) => {
                    if (index > 0) {
                        values.applicantNames[index].name_end_date = moment(applicantName.name_end_date).format(
                            'YYYY-MM-DD'
                        );
                    }
                });
            }

            dispatch(
                patchInformation({
                    phone: {
                        number: phoneNumber.formatInternational(),
                        national_number: phoneNumber.nationalNumber,
                        country: phoneNumber.country,
                        calling_code: phoneNumber.countryCallingCode,
                    },
                })
            );

            await updateApplicantNames(query.data, values.applicantNames);

            query.refetch();
            handleNextTrack();
        },
        [applicant, dispatch, handleNextTrack, isUkDbsOrDsCheck, query, updateApplicantNames]
    );

    const setAllowAdditionalNames = useCallback((value: boolean) => {
        setSubmitPreviousNames(value);
    }, []);

    if (query.isLoading) {
        return <Loader />;
    }

    const initialApplicantNames =
        !query.data || query.data?.length < 1
            ? [{ type: 'PRIMARY' }]
            : query.data
                  .map((app_name) =>
                      app_name.name_end_date
                          ? { ...app_name, name_end_date: moment(app_name.name_end_date) }
                          : { ...app_name }
                  )
                  .sort((a) => (a.type === 'PRIMARY' ? -1 : 0));

    const initialValues = {
        applicantNames: initialApplicantNames,
        phone: information?.phone?.number,
    };

    const typeOfUkCheck: UkCheckType = applicant[CHECK_REQUEST.UK_BASIC_DBS_CHECK]
        ? 'UK_BASIC_DBS_CHECK'
        : applicant[CHECK_REQUEST.UK_BASIC_DS_CHECK]
        ? 'UK_BASIC_DS_CHECK'
        : applicant[CHECK_REQUEST.UK_RIGHT_TO_WORK_CHECK]
        ? 'UK_RIGHT_TO_WORK_CHECK'
        : undefined;

    return (
        <AppearRight>
            <NewBasicsForm
                initialValues={initialValues}
                onFinish={handleFinish}
                phoneNumberStatus={phoneNumberStatus}
                setAllowAdditionalNames={setAllowAdditionalNames}
                showDateFields={applicant[CHECK_REQUEST.UK_BASIC_DBS_CHECK]}
                showTitleField={isUkDbsOrDsCheck}
                showMiddleNameField={!applicant[CHECK_REQUEST.UK_BASIC_DS_CHECK]}
                showAllowAdditionalNamesCheckbox={isUkDbsOrDsCheck}
                showAddPreviousNamesButton={!isUkDbsOrDsCheck && !isUkRtwCheck}
                showPreviousNameTypesDropdown={!isUkDbsOrDsCheck}
                typeOfUkCheck={typeOfUkCheck}
                dateFormat={getDateFormat(country)}
            />
        </AppearRight>
    );
};

export default withNavigation(NewBasics);
