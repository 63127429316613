import React from 'react';
import Form from 'certnd/Form';
import { intl } from 'components/GlobalProvider';
import { Popover } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getSuperTeamAdmins } from 'views/manager/views/settings/SettingsSelectors';

const SuccessFactorsForm = (props) => {
    const adminList = useSelector(getSuperTeamAdmins);
    return (
        <>
            <Form.Input
                form={props.form}
                fieldName="success_factors_api_url"
                title={intl.formatMessage({
                    id: 'a7880.SuccessFactors.ApiUrlTitle',
                    defaultMessage: 'API URL',
                })}
                placeholder={intl.formatMessage({
                    id: 'a7880.SuccessFactors.ApiUrlPlaceholder',
                    defaultMessage: 'Current API URL: ',
                })}
                addonBefore={
                    <Popover
                        content={intl.formatMessage({
                            id: 'a7880.SuccessFactors.ApiUrlPopover',
                            defaultMessage: 'URL for the Success Factors API',
                        })}
                    >
                        <InfoCircleOutlined />
                    </Popover>
                }
                options={{
                    initialValue: props?.successFactorsData?.success_factors_api_url || undefined,
                    rules: [
                        {
                            required: true,
                            message: intl.formatMessage({
                                id: 'a7880.SuccessFactors.ApiUrlError',
                                defaultMessage: 'Please enter a valid Success Factors API URL',
                            }),
                            whitespace: true,
                        },
                    ],
                }}
            />
            <Form.Input
                form={props.form}
                fieldName="success_factors_company_id"
                title={intl.formatMessage({
                    id: 'a7880.SuccessFactors.CompanyIdTitle',
                    defaultMessage: 'Company ID',
                })}
                placeholder={intl.formatMessage({
                    id: 'a7880.SuccessFactors.CompanyIdPlaceholder',
                    defaultMessage: 'Company ID: ',
                })}
                addonBefore={
                    <Popover
                        content={intl.formatMessage({
                            id: 'a7880.SuccessFactors.CompanyIdPopover',
                            defaultMessage: 'Company ID for Success Factors API',
                        })}
                    >
                        <InfoCircleOutlined />
                    </Popover>
                }
                options={{
                    initialValue: props?.successFactorsData?.success_factors_company_id || undefined,
                    rules: [
                        {
                            required: true,
                            message: intl.formatMessage({
                                id: 'a7880.SuccessFactors.CompanyIdError',
                                defaultMessage: 'Please enter a valid Company ID',
                            }),
                            whitespace: true,
                        },
                    ],
                }}
            />
            <Form.Input
                form={props.form}
                fieldName="success_factors_username"
                title={intl.formatMessage({
                    id: 'a7880.SuccessFactors.UsernameTitle',
                    defaultMessage: 'Username',
                })}
                placeholder={intl.formatMessage({
                    id: 'a7880.SuccessFactors.UsernamePlaceholder',
                    defaultMessage: 'Username:',
                })}
                addonBefore={
                    <Popover
                        content={intl.formatMessage({
                            id: 'a7880.SuccessFactors.UsernamePopover',
                            defaultMessage: 'Username for the Success Factors API',
                        })}
                    >
                        <InfoCircleOutlined />
                    </Popover>
                }
                options={{
                    initialValue: props?.successFactorsData?.success_factors_username || undefined,
                    rules: [
                        {
                            required: true,
                            message: intl.formatMessage({
                                id: 'a7880.SuccessFactors.UsernameError',
                                defaultMessage: 'Please enter a valid username',
                            }),
                            whitespace: true,
                        },
                    ],
                }}
            />
            <Form.Password
                form={props.form}
                fieldName="success_factors_password"
                title={intl.formatMessage({
                    id: 'a7880.SuccessFactors.PasswordTitle',
                    defaultMessage: 'Password',
                })}
                placeholder={intl.formatMessage({
                    id: 'a7880.SuccessFactors.PasswordPlaceholder',
                    defaultMessage: 'Password:',
                })}
                options={{
                    initialValue: props?.successFactorsData?.success_factors_password || undefined,
                    rules: [
                        {
                            required: true,
                            message: intl.formatMessage({
                                id: 'a7880.SuccessFactors.PasswordError',
                                defaultMessage: 'Please enter a valid password',
                            }),
                            whitespace: true,
                        },
                    ],
                }}
            />
            <Form.Select
                form={props.form}
                fieldName="success_factors_owner"
                title={intl.formatMessage({
                    id: 'a7880.SuccessFactors.OwnerTitle',
                    defaultMessage: 'Admin Owner',
                })}
                placeholder={intl.formatMessage({
                    id: 'a7880.SuccessFactors.OwnerPlaceholder',
                    defaultMessage: 'Select',
                })}
                selectOptions={[
                    ...(adminList || []).map((admin) => ({
                        key: admin.id,
                        value: admin.id,
                        title: admin.email,
                    })),
                ]}
                options={{
                    initialValue: props?.successFactorsData?.owner?.id || undefined,
                    rules: [
                        {
                            required: true,
                            message: intl.formatMessage({
                                id: 'a7880.SuccessFactors.OwnerError',
                                defaultMessage: 'Please input an Owner who is an Admin on the SuperTeam',
                            }),
                            whitespace: true,
                        },
                    ],
                }}
                description={intl.formatMessage({
                    id: 'a7880.SuccessFactors.OwnerDescription',
                    defaultMessage:
                        'Please select an Admin to be the owner for this integration. All applications will be run under this user.',
                })}
            />
        </>
    );
};

SuccessFactorsForm.propTypes = {
    form: PropTypes.object.isRequired,
    successFactorsData: PropTypes.object,
};

SuccessFactorsForm.defaultProps = {
    successFactorsData: {},
};

export default SuccessFactorsForm;
