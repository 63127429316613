// Libraries
import React, { useState, useEffect } from 'react';
import { bindActionCreators, Dispatch, Store } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Modules
import withNavigation from 'views/welcome/modules/WithNavigation';
import withSettingsChecker from 'views/welcome/modules/WithSettingsChecker';
import { SocialMediaDetailsForm } from './components';
// types
import { PostSocialMediaDetailsFunction, HandleNextTrackFunction, InformationType, ValuesType } from './types';
// Actions & Selectors
import { setTrackPageOrder /* eslint-disable no-shadow */, postSocialMediaDetails } from 'views/welcome/WelcomeActions';

import {
    getIsFetchingSilent,
    getInformation,
    getOnboardingType,
    getSocialMediaDetails,
} from 'views/welcome/WelcomeSelectors';

const mapStateToProps = (state: Store) => ({
    isFetchingSilent: getIsFetchingSilent(state),
    information: getInformation(state),
    onboardingType: getOnboardingType(),
    socialMediaDetails: getSocialMediaDetails(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            postSocialMediaDetails,
            setTrackPageOrder,
        },
        dispatch
    );

interface PropTypes {
    setTrackPageOrder: (trackPageLocation: [string]) => void;
    trackPageLocation: string;
    postSocialMediaDetails: PostSocialMediaDetailsFunction;
    handleNextTrack: HandleNextTrackFunction;
    information: InformationType;
    socialMediaDetails: ValuesType;
}

const SocialMediaDetails: React.FC<PropTypes> = ({
    setTrackPageOrder,
    trackPageLocation,
    postSocialMediaDetails,
    information,
    handleNextTrack,
    socialMediaDetails,
}: PropTypes) => {
    useEffect(() => {
        setTrackPageOrder(['base']);
    }, [setTrackPageOrder]);

    const [educationInstitutions, setEducationInstitutions] = useState({});
    const [institution, setInstitution] = useState({ name: socialMediaDetails?.college_name, value: '' });
    const [country, setCountry] = useState(socialMediaDetails?.college_country);

    const onFinish = async (formValues: ValuesType) => {
        delete formValues.institution_select;
        const data = { ...formValues, college_name: institution.name, college_country: country };
        await postSocialMediaDetails(information.id, data);
        handleNextTrack();
    };

    const pages = () => ({
        base: (
            <SocialMediaDetailsForm
                onFinish={onFinish}
                setEducationInstitutions={setEducationInstitutions}
                educationInstitutions={educationInstitutions}
                setInstitution={setInstitution}
                institution={institution}
                country={country}
                setCountry={setCountry}
                socialMediaDetails={socialMediaDetails}
            />
        ),
    });
    return pages()[trackPageLocation as keyof typeof pages];
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(withNavigation(withSettingsChecker(SocialMediaDetails)))
);
