import { requestReducer, responseReducer, errorReducer, defaultState } from 'utils/http';

import {
    IS_ADVERSE_ACTION_DRAWER_OPEN,
    FETCH_ADVERSE_ACTION_DETAILS_ERR,
    FETCH_ADVERSE_ACTION_DETAILS_RES,
    FETCH_ADVERSE_ACTION_DETAILS_REQ,
    PAUSE_ADVERSE_ACTION_REQ,
    PAUSE_ADVERSE_ACTION_RES,
    PAUSE_ADVERSE_ACTION_ERR,
    RESUME_ADVERSE_ACTION_REQ,
    RESUME_ADVERSE_ACTION_RES,
    RESUME_ADVERSE_ACTION_ERR,
    CANCEL_ADVERSE_ACTION_REQ,
    CANCEL_ADVERSE_ACTION_RES,
    CANCEL_ADVERSE_ACTION_ERR,
    RESTART_ADVERSE_ACTION_REQ,
    RESTART_ADVERSE_ACTION_RES,
    RESTART_ADVERSE_ACTION_ERR,
} from './DrawerConstants';
import { Action, DrawerReducerState } from './types';

const initialState: DrawerReducerState = {
    ...defaultState,
    isAdverseActionDrawerOpen: false,
    adverseActionDetails: null,
};

const AdverseActionDrawer = (state = initialState, action: Action) => {
    const { type, payload } = action;
    switch (type) {
        case IS_ADVERSE_ACTION_DRAWER_OPEN:
            return {
                ...state,
                isAdverseActionDrawerOpen: payload.isAdverseActionDrawerOpen,
            };
        case FETCH_ADVERSE_ACTION_DETAILS_REQ:
        case PAUSE_ADVERSE_ACTION_REQ:
        case RESUME_ADVERSE_ACTION_REQ:
        case CANCEL_ADVERSE_ACTION_REQ:
        case RESTART_ADVERSE_ACTION_REQ:
            return {
                ...state,
                ...requestReducer(),
            };
        case FETCH_ADVERSE_ACTION_DETAILS_RES:
        case PAUSE_ADVERSE_ACTION_RES:
        case RESUME_ADVERSE_ACTION_RES:
        case CANCEL_ADVERSE_ACTION_RES:
        case RESTART_ADVERSE_ACTION_RES:
            return {
                ...state,
                ...responseReducer(),
                adverseActionDetails: payload.adverseActionDetails,
            };
        case FETCH_ADVERSE_ACTION_DETAILS_ERR:
        case PAUSE_ADVERSE_ACTION_ERR:
        case RESUME_ADVERSE_ACTION_ERR:
        case CANCEL_ADVERSE_ACTION_ERR:
        case RESTART_ADVERSE_ACTION_ERR:
            return {
                ...state,
                ...errorReducer(payload.error),
            };
        default:
            return state;
    }
};

export default AdverseActionDrawer;
