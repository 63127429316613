// Libraries
import { get } from 'lodash';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { intl } from 'components/GlobalProvider';
import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux'; // useDispatch in 7.1+ release
import { Popover, message } from 'antd';
import { Form as AntdForm } from '@ant-design/compatible';
import { InfoCircleOutlined } from '@ant-design/icons';

// Components
import Form from 'certnd/Form';
import Loader from 'certn-ui/Loader';
import Packages from './packages/Packages';
import { ErrorAlertAPI } from 'certn-ui/ErrorAlert';
import ToggleIntegrationButton from './components/ToggleIntegrationButton';

// Modules
import Auth from 'modules/Auth';

// Actions & Selectors
import { getTeam } from 'views/manager/views/admin/AdminSelectors';
import {
    enableTaleo,
    fetchIntegrationPackages,
    fetchReferenceTemplates,
} from 'views/manager/views/settings/SettingsActions';
import { getIntegrations, getTaleoPackages, getIsFetching } from 'views/manager/views/settings/SettingsSelectors';

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            enableTaleo,
        },
        dispatch
    );

const Taleo = (props) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [defaultPackageId, setDefaultPackageId] = useState();
    const teamId = useSelector((state) => getTeam(state).id);
    const integrations = useSelector(getIntegrations);
    const isFetching = useSelector(getIsFetching);
    const canEdit = Auth.isPermissionLevel('manager');

    // Taleo
    const taleoPackages = useSelector(getTaleoPackages);
    const taleoActive = get(integrations, ['taleo', 'is_active']);
    const taleoIntegration = get(integrations, ['taleo'], {});

    useEffect(() => {
        dispatch(fetchIntegrationPackages({ teamId, integrationType: 'TALEO' }));
        dispatch(fetchReferenceTemplates({ type: 'employer', teamId }));
    }, [dispatch, teamId]);

    useEffect(() => {
        const defaultPackage = get(integrations, ['taleo', 'default_package']);
        setDefaultPackageId(defaultPackage);
    }, [integrations]);

    const handleSubmit = () => {
        setLoading(true);
        props.form.validateFields((err, values) => {
            if (err) {
                setLoading(false);
                return;
            }

            const data = {
                username: values.username,
                password: values.password,
                default_package: defaultPackageId,
                wsdl: values.wsdl,
                is_active: true,
            };
            dispatch(enableTaleo({ teamId, data }))
                .then(() => {
                    setLoading(false);
                    message.success(
                        intl.formatMessage({
                            id: 'fc898.Taleo.IntegrationConnected',
                            defaultMessage: 'Integration Connected',
                        })
                    );
                })
                .catch((error) => {
                    setLoading(false);
                    ErrorAlertAPI(error);
                });
        });
    };

    if (isFetching) return <Loader />;
    return (
        <AntdForm
            onSubmit={(e) => {
                e.preventDefault();
                if (taleoActive) return props.handleDisableIntegration({ name: 'Taleo' });
                handleSubmit();
            }}
        >
            <Form.Block>
                {!taleoActive && (
                    <>
                        <Form.Input
                            form={props.form}
                            fieldName="username"
                            title={intl.formatMessage({ id: 'common.username', defaultMessage: 'Username' })}
                            addonBefore={
                                <Popover
                                    content={intl.formatMessage({
                                        id: 'common.username',
                                        defaultMessage: 'Username',
                                    })}
                                >
                                    <InfoCircleOutlined />
                                </Popover>
                            }
                            options={{
                                rules: [
                                    {
                                        required: true,
                                        error: intl.formatMessage({
                                            id: 'fc898.Taleo.UsernamePopoverValidation',
                                            defaultMessage: 'Please enter your Username',
                                        }),
                                    },
                                ],
                            }}
                        />

                        <Form.Password
                            form={props.form}
                            fieldName="password"
                            title={intl.formatMessage({ id: 'common.password', defaultMessage: 'Password' })}
                            options={{
                                rules: [
                                    {
                                        required: true,
                                        error: intl.formatMessage({
                                            id: 'fc898.Taleo.PasswordValid',
                                            defaultMessage: 'Please enter your Password',
                                        }),
                                    },
                                ],
                            }}
                        />
                    </>
                )}
                <Form.Input
                    form={props.form}
                    fieldName="wsdl"
                    title={intl.formatMessage({ id: 'fc898.Taleo.Endpoint', defaultMessage: 'Endpoint URL' })}
                    addonBefore={
                        <Popover
                            content={
                                <FormattedMessage
                                    id="fc898.Taleo.EndpointPopover"
                                    defaultMessage="Example: https://{company}.taleo.net/enterprise/soap"
                                />
                            }
                        >
                            <InfoCircleOutlined />
                        </Popover>
                    }
                    autoComplete={taleoActive ? 'off' : 'on'}
                    disabled={taleoActive}
                    options={{
                        initialValue: taleoIntegration.wsdl,
                        rules: [
                            {
                                required: true,
                                error: intl.formatMessage({
                                    id: 'fc898.Taleo.EndpointValid',
                                    defaultMessage: 'Please enter the Endpoint URL',
                                }),
                            },
                        ],
                    }}
                />
            </Form.Block>
            <Packages
                integrationType="TALEO"
                integrationName="taleo"
                packages={taleoPackages}
                active={taleoActive}
                form={props.form}
                defaultPackageId={defaultPackageId}
                useDefaultColumns
                setDefaultPackageId={setDefaultPackageId}
            />
            <Form.Block>
                <ToggleIntegrationButton canEdit={canEdit} loading={loading} active={taleoActive} />
            </Form.Block>
        </AntdForm>
    );
};

export default AntdForm.create()(connect(null, mapDispatchToProps)(Taleo));
