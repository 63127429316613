// Libraries
import React from 'react';
import styled from 'styled-components';
import { Select } from 'antd';

// Components
import { intl } from 'components/GlobalProvider';

const AntdOption = Select.Option;

const StaticResultSpan = styled.span`
    color: ${(props) => props.theme.colorMap[props.reportResult]};
`;

export const StaticResult = (props) => (
    <FilterSelect {...props}>
        {props.children &&
            Array.isArray(props.children) &&
            props.children.map((item, index) => {
                // If it's an array that includes requests we want to destructure it, otherwise single entry is both value and key
                const { request, title } = typeof item === 'object' ? item : { request: item, title: item };

                return (
                    <AntdOption value={request} key={index}>
                        <StaticResultSpan
                            key={request}
                            reportResult={request}
                            data-testid={`AdvancedFilteringLayout-${title}`}
                        >
                            {title}
                        </StaticResultSpan>
                    </AntdOption>
                );
            })}
    </FilterSelect>
);

const FilterSelect = ({ defaultValue, children, selected, handleChange, mode = 'multiple', ...rest }) => (
    <Select
        showArrow
        allowClear
        mode={mode}
        dropdownMatchSelectWidth={false}
        defaultValue={defaultValue}
        maxTagCount={1}
        maxTagPlaceholder={(value) => <div>{value.length}...</div>}
        placeholder={intl.formatMessage({
            id: '2021c.AdvancedFilteringLayout.pleaseSelect',
            defaultMessage: 'Please select',
        })}
        filterOption={(inputValue, option) => {
            if (inputValue && option?.children?.props?.children?.toLowerCase().includes(inputValue.toLowerCase()))
                return option;
        }}
        onChange={handleChange}
        value={selected}
        {...rest}
    >
        {children}
    </Select>
);
