// Libraries
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

// Actions & Selectors
import { getLocale, getSkinColour } from 'base/BaseSelectors';

import styles from 'styles/styles';
import config from 'utils/config';
import { setTrackPageOrder, toggleNavigationBars } from 'views/welcome/WelcomeActions';
import { VOUCHED_STAGES } from 'views/welcome/WelcomeConstants';

const VouchedElement = styled.div`
    width: 100%;
    @media (min-width: ${({ theme }) => theme.width.desktop}) {
        height: 555px;
        margin-top: -20px;
    }
    /* Helper icon */
    #vouched_cameraHelper {
        position: relative !important;
        top: 46px !important;
    }
    /* Helper text centering */
    #vouched_CameraPrompt {
        @media (min-width: ${({ theme }) => theme.width.desktop}) {
            position: absolute !important;
            display: flex !important;
            justify-content: center !important;
            top: 50px !important;
            width: 100% !important;
            margin: auto !important;
        }
    }
    /* Center text fix from parent component, appears in blurry photo confirm prompt */
    ul {
        text-align: left !important;
    }

    /* Child combinator to override relative positioning for that specific 'UserConfirmation' div */
    #mainDiv > div {
        position: relative !important;
    }
`;

// Vouched public keys
const vouchedDevKey = 'Vu#UQ-wfriGb!85W0cZEEwGS#7.s~n';
const vouchedProdKey = 'Ilr-@61a153R.1Ty1@Vz@XrHXI_qDE';
const publicKey = config.isProduction ? vouchedProdKey : vouchedDevKey;
/**
 * Vouched OneID - SDK script in index.html
 * GlobalStyles.jsx we add exceptions for Font Awesome icons that were overwritten from our global font family
 */

const OneIDVouched = ({ oneIdId, handleSubmit }) => {
    const dispatch = useDispatch();
    const colour = useSelector(getSkinColour) || styles.color.certnGreen700;
    const locale = useSelector(getLocale);
    const callbackURL = `${config.url}/api/v1/vouched/callback/`;
    const intl = useIntl();

    const translateCountries = useCallback(
        (element) => {
            element.innerHTML = element.innerHTML
                .replace(
                    'United States / Canada (+1)',
                    intl.formatMessage({ id: 'vouched.us_can', defaultMessage: 'United States / Canada (+1)' })
                )
                .replace(
                    'United Kingdom (+44)',
                    intl.formatMessage({ id: 'vouched.uk', defaultMessage: 'United Kingdom (+44)' })
                );
        },
        [intl]
    );

    const translateSelectedCountry = useCallback(() => {
        document.querySelectorAll('div.MuiInput-input').forEach(translateCountries);
    }, [translateCountries]);

    const translateCountriesDropdown = useCallback(() => {
        document.querySelectorAll('li.MuiListItem-gutters').forEach(translateCountries);
    }, [translateCountries]);

    useEffect(() => {
        dispatch(setTrackPageOrder(['base']));
    }, [dispatch]);

    useEffect(() => {
        // Our locale strings differ from theirs
        const vouchedLocaleMap = {
            'en-US': 'en',
            'fr-CA': 'fr_CA',
            es: 'es',
            fr: 'fr_CA',
        };
        // eslint-disable-next-line
        const vouched = Vouched({
            locale: vouchedLocaleMap[locale] || 'en',
            // Vouched public key
            appId: publicKey,
            crossDevice: true,
            crossDeviceQRCode: true,
            crossDeviceSMS: true,
            includeBarcode: true,
            includeBackId: true,
            face: 'both',
            callbackURL,
            showTermsAndPrivacy: false,
            showProgressBar: false,
            liveness: 'straight',
            maxRetriesBeforeNext: 1,
            theme: {
                name: 'avant',
                baseColor: colour,
                font: 'Helvetica Neue, Helvetica, Arial, sans-serif',
            },
            content: {
                overlayHeader: 'Support',
                middleBackIdCapturedInstructions: '',
                crossDeviceShowOff: true,
                qrDesktopLink: '',
                carouselCompanyText: [],
                carouselCompanyImg: [],
                upperSuccess: '',
                upperFailure: '',
                lowerSuccess: '',
                lowerFailure: '',
            },
            userConfirmation: {
                confirmImages: true,
            },
            properties: [
                {
                    name: oneIdId,
                    value: 'test-value', // temporary placeholder while Vouched fixes a bug on their end (both fields required)
                },
            ],

            // callback executed after every job posting : { stage, attempts, job }
            onSubmit: ({ stage }) => {
                if (stage === VOUCHED_STAGES.CONFIRM) {
                    handleSubmit();
                }
            },

            // callback executed after camera is accessed (cameraRendered true/false)
            onCameraEvent: (cameraEvent) => {
                const cameraOpened = cameraEvent?.cameraRendered;
                if (cameraOpened) dispatch(toggleNavigationBars({ navigationVisible: false }));
                else dispatch(toggleNavigationBars({ navigationVisible: true }));
            },
            onInit: () => {
                translateSelectedCountry();

                document
                    .querySelectorAll('div.MuiInputBase-root.MuiInput-root.MuiInput-underline')[0]
                    ?.addEventListener('mousedown', () => {
                        // Translate in async to allow time for Vouched script to finish
                        setTimeout(() => {
                            translateCountriesDropdown();

                            // Custom event listener to translate countries every time a new country is selected
                            document.querySelectorAll('li.MuiListItem-gutters').forEach((el) => {
                                el.addEventListener('click', () => {
                                    // Translate in async to allow time for Vouched script to finish
                                    setTimeout(translateSelectedCountry, 0);
                                });
                            });
                        }, 0);
                    });
            },
        });

        vouched.mount('#vouched-element');
    }, [
        dispatch,
        colour,
        locale,
        callbackURL,
        oneIdId,
        handleSubmit,
        translateSelectedCountry,
        translateCountriesDropdown,
    ]);

    return <VouchedElement id="vouched-element" />;
};

export default OneIDVouched;
