// Libraries
import React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components/macro';
import { EllipsisOutlined } from '@ant-design/icons';

// Modules
import Auth from 'modules/Auth';

// Components
import { StatusDropdown } from 'views/manager/views/pm/views/applications/components';
import { Button as AntButton } from 'antd';

const Button = styled(AntButton)`
    ${(props) => props.margin && `margin: ${props.margin}`};
    overflow: hidden !important;
    height: 40px !important;
    width: 40px !important;
`;

const ActionsCell = ({ record, history, isDashboard }) => (
    <StatusDropdown userIsNotBasic={Auth.isNotBasic()} applicant={record} history={history} isDashboard={isDashboard}>
        <Button id="applicant-menu-button" icon={<EllipsisOutlined />} shape="circle" />
    </StatusDropdown>
);

export default withRouter(ActionsCell);
