import { CHECK_REQUEST } from 'base/BaseConstants';
import { useUserTeam } from 'hooks/useUserTeam';
import numeral from 'numeral';
import { useContext } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { CheckConfig } from 'types';
import { formatCurrency } from 'utils/formatter';
import { ScreenApplicantCheckSettingsContext } from 'views/manager/features/ScreenApplicant/contexts';
import { getMultiplier, priceExceptions } from 'views/manager/features/ScreenApplicant/ScreenApplicantActions';
import {
    getAdditionalOptions,
    getSelectedCheckKeys,
} from 'views/manager/features/ScreenApplicant/ScreenApplicantSelectors';
import { PriceItemWrapper } from '../styled';

const FREE_SERVICES = [CHECK_REQUEST.BASE];
const DASHED_SERVICES = [CHECK_REQUEST.INTERNATIONAL_CRIMINAL_RECORD_CHECK];

export const PriceItem: React.FC<{ check: CheckConfig }> = ({ check }) => {
    const { showPricing } = useContext(ScreenApplicantCheckSettingsContext);

    return <PriceItemWrapper>{showPricing && <Price check={check} />}</PriceItemWrapper>;
};

const Price: React.FC<{ check: CheckConfig }> = ({ check }) => {
    const intl = useIntl();
    const selectedCheckKeys = useSelector(getSelectedCheckKeys);
    const { requestFlag, price } = check;
    const additionalOptions = useSelector(getAdditionalOptions);
    const multiplier = getMultiplier(check, additionalOptions);
    const { team } = useUserTeam();
    const currency = team?.billing_plan?.currency_type;

    if (DASHED_SERVICES.includes(requestFlag)) {
        return <>-</>;
    }

    if (priceExceptions(requestFlag, selectedCheckKeys)) {
        return <>-</>;
    }

    if (FREE_SERVICES.includes(requestFlag)) {
        return (
            <>
                {intl.formatMessage({
                    id: 'common.free',
                    defaultMessage: 'Free',
                })}
            </>
        );
    }

    return <>{formatCurrency(numeral(price).multiply(multiplier), currency)}</>;
};
