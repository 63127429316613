// Libraries
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

// Components
import SummaryItem from './SummaryItem';
import SummaryHeader from './SummaryHeader';
import { FlexRow, SummarySection, SummaryContainer } from './SummaryStyles';

const Driver = ({ information, goToTrack }) => (
    <SummaryContainer>
        <SummaryHeader
            title={<FormattedMessage id="welcome.Summary.mvr" defaultMessage="Motor Vehicle Record" />}
            onClick={goToTrack}
        />
        <SummarySection>
            <FlexRow>
                <SummaryItem
                    title={<FormattedMessage id="common.country" defaultMessage="Country" />}
                    text={information.license_type}
                />
                <SummaryItem
                    title={<FormattedMessage id="address.provinceState" defaultMessage="Province/State" />}
                    text={information.license_prov_state}
                />
                <SummaryItem
                    title={<FormattedMessage id="welcome.Driver.licenseNumber" defaultMessage="License Number" />}
                    text={information.license_number}
                />
            </FlexRow>
        </SummarySection>
    </SummaryContainer>
);

export default injectIntl(Driver);
