import styled from 'styled-components/macro';
import { Button as AntdButton } from 'antd';

export const CustomAntButton = styled(AntdButton)`
    color: ${({ theme }) => theme.color.certnGray600} !important;
    width: 100%;
`;

export const OptionsWrapper = styled.div`
    width: 360px;
    margin: auto;
    @media (max-width: ${(props) => props.theme.width.desktop}) {
        width: 100%;
    }
    > div {
        margin: 10px 0px;
    }
`;
