import React from 'react';
import PropTypes from 'prop-types';
import { DeleteOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import { Input, Button, Space } from 'antd';
import { useIntl } from 'react-intl';

import DateInput from 'certn-form/DateInput';

const FormItem = ({
    fieldName,
    form,
    initialValue,
    display,
    rules,
    required,
    style,
    inputStyle,
    placeholder,
    addonBefore,
    showDelete,
    onDelete,
    dateField,
    // DateInput only props:
    disableFutureDates,
    disablePastDates,
    disableToday,
    isBirthDate,
}) => {
    const intl = useIntl();
    const { getFieldDecorator } = form;
    let formItemRules = [];

    if (rules) {
        formItemRules = rules.map((r) => ({
            pattern: r.pattern,
            message: r.message,
        }));
    }
    if (required) {
        formItemRules.push({
            required: true,
            message: intl.formatMessage({
                id: 'error.validation.notBlank',
                defaultMessage: 'Please do not leave blank',
            }),
        });
    }

    const renderInput = () => {
        if (display) return display();
        if (dateField) {
            return (
                <DateInput
                    placeholder={placeholder}
                    style={inputStyle}
                    addonBefore={addonBefore}
                    disableFutureDates={disableFutureDates}
                    disablePastDates={disablePastDates}
                    disableToday={disableToday}
                    isBirthDate={isBirthDate}
                />
            );
        }
        return <Input placeholder={placeholder} style={inputStyle} addonBefore={addonBefore} />;
    };

    return (
        <Form.Item style={style} required={required}>
            {getFieldDecorator(fieldName, {
                initialValue,
                validateTrigger: ['onChange'],
                rules: formItemRules,
            })(renderInput())}
            {showDelete ? (
                <Button className="dynamic-delete-button" type="dashed" onClick={onDelete}>
                    <Space>
                        <DeleteOutlined />
                        {intl.formatMessage({ id: 'common.remove', defaultMessage: 'Remove' })}
                    </Space>
                </Button>
            ) : null}
        </Form.Item>
    );
};

FormItem.propTypes = {
    fieldName: PropTypes.string.isRequired,
    form: PropTypes.object.isRequired,
    initialValue: PropTypes.any,
    display: PropTypes.func,
    rules: PropTypes.array,
    required: PropTypes.bool,
    style: PropTypes.object,
    inputStyle: PropTypes.object,
    addonBefore: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    placeholder: PropTypes.string,
    showDelete: PropTypes.bool,
    onDelete: PropTypes.func,
    dateField: PropTypes.bool,
    disableFutureDates: PropTypes.bool,
    disablePastDates: PropTypes.bool,
    disableToday: PropTypes.bool,
    isBirthDate: PropTypes.bool,
};

FormItem.defaultProps = {
    initialValue: undefined,
    display: undefined,
    rules: undefined,
    required: false,
    style: undefined,
    inputStyle: undefined,
    addonBefore: undefined,
    placeholder: undefined,
    showDelete: false,
    onDelete: () => {},
    dateField: false,
    disableFutureDates: false,
    disablePastDates: false,
    disableToday: false,
    isBirthDate: false,
};

export default FormItem;
