// Libraries
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

// Components
import Text from 'certn-ui/Text';
import { AppearRight } from 'certn-ui/Animate';
import Title, { FlowSubTitle } from 'certn-ui/Title';
import { TrackNavigation } from 'views/welcome/components';
import MultiAdd, { MultiAddItem, EmptyListContainer } from 'views/welcome/components/MultiAdd';

// Modules
import Format from 'modules/Format';

const CustomLink = styled.span`
    color: ${({ theme }) => theme.color.certnGreen700};
    padding: 0px !important;
    font-size: 16px !important;
    font-weight: 300 !important;
    cursor: pointer;
`;

const CredentialBase = ({
    handleSubmit,
    handleUpdate,
    credentials,
    addOrEditCredential,
    deleteCredential,
    intl,
    highestLevelOnly,
}) => {
    const renderBottom = (credential) => {
        if (highestLevelOnly) {
            if (credential.highest_level) {
                return intl.formatMessage({
                    id: 'welcome.Credential.highestLevel',
                    defaultMessage: 'Highest Level',
                });
            }
            return (
                <CustomLink onClick={() => handleUpdate(credential.id)} type="link">
                    {intl.formatMessage({
                        id: 'welcome.Credential.setHighestLevel',
                        defaultMessage: 'Set as Highest Level',
                    })}
                </CustomLink>
            );
        }
        let date = Format.date(credential.date_of_certification);
        if (date === 'n/a') {
            date = intl.formatMessage({ id: 'welcome.Education.CredentialBase.current', defaultMessage: 'Current' });
        }
        return date;
    };

    return (
        <AppearRight>
            <Title>
                <FormattedMessage
                    id="welcome.Education.CredentialBase.title"
                    defaultMessage="Can you give me a few details about your credentials?"
                />
            </Title>
            {highestLevelOnly && (
                <FlowSubTitle>
                    <Text align="center" color="certnGray600">
                        <FormattedMessage
                            id="welcome.Education.CredentialBase.subTitle"
                            defaultMessage="We only need to verify your highest level credential, so use the checkbox when adding your entry to indicate your highest level credential."
                        />
                    </Text>
                </FlowSubTitle>
            )}
            <MultiAdd
                testKey="credential"
                title={intl.formatMessage({
                    id: 'welcome.Education.CredentialBase.multiTitle',
                    defaultMessage: 'Credential',
                })}
                buttonTitle={intl.formatMessage({
                    id: 'welcome.Education.CredentialBase.multiButtonTitle',
                    defaultMessage: 'ADD CREDENTIAL',
                })}
                buttonAction={addOrEditCredential}
            >
                {credentials && credentials.length > 0 ? (
                    credentials.map((credential, index) => (
                        <MultiAddItem
                            testKey="credential"
                            key={credential.id}
                            top={credential.certification}
                            bottom={renderBottom(credential)}
                            onEdit={() => addOrEditCredential(index)}
                            onDelete={() => deleteCredential(index)}
                            canDelete={credentials.length !== 1}
                        />
                    ))
                ) : (
                    <EmptyListContainer>
                        <FormattedMessage
                            id="welcome.Education.CredentialBase.multiEmptyTitle"
                            defaultMessage="No Credential entries added yet."
                        />
                    </EmptyListContainer>
                )}
            </MultiAdd>
            <TrackNavigation handleSubmit={handleSubmit} />
        </AppearRight>
    );
};
export default CredentialBase;
