import styled from 'styled-components/macro';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import SummaryItem from '../SummaryItem';
import { FlexRow, SummarySection, SummaryContainer, LineBreak } from '../SummaryStyles';
import SummaryHeader from '../SummaryHeader';
import SummaryNotice from '../SummaryNotice';
import { formatDate } from 'modules/Format';
import { convertReasonObjectToArray } from 'views/welcome/views/employerReference/EmployerReference';

const SubTitleWrapper = styled.div`
    font-size: 14px;
    font-weight: lighter;
    opacity: 0.6;
    font-size: 14px;
    text-align: left;
    letter-spacing: 1px;
    margin: 0px 12px;
    text-transform: uppercase;
`;

const EmployerReference = ({ applicant, information, employerReferences, goToTrack, escapeHatchReasons }) => {
    const reasonList = convertReasonObjectToArray(escapeHatchReasons);
    const skipReason = information?.employer_reference_skip_reason
        ? reasonList.filter((r) => r.constant === information.employer_reference_skip_reason)[0].readable
        : null;

    return (
        <SummaryContainer>
            <SummaryHeader
                title={
                    <FormattedMessage
                        id="welcome.Summary.employmentReferences"
                        defaultMessage="Employment References"
                    />
                }
                onClick={goToTrack}
            />
            {information && information.employer_reference_skip_reason && (
                <SummaryNotice
                    title={
                        <FormattedMessage
                            id="welcome.Summary.refsProvidedTitle"
                            defaultMessage="References Provided:"
                        />
                    }
                    text={
                        <FormattedMessage
                            id="welcome.Summary.refsProvided"
                            defaultMessage="You've added {added} of {minimum} references"
                            values={{ added: employerReferences.length, minimum: applicant.employer_references_min }}
                        />
                    }
                    title2={<FormattedMessage id="welcome.Summary.refsReasonTitle" defaultMessage="Reason Selected:" />}
                    text2={skipReason}
                />
            )}
            {employerReferences.length === 0 && (
                <SummarySection data-testid="no_employer_reference_listed">
                    <SummaryItem
                        empty
                        title={
                            <FormattedMessage
                                id="welcome.Summary.noEmployerReferencesListed"
                                defaultMessage="You have not listed any Employer References"
                            />
                        }
                    />
                </SummarySection>
            )}
            {employerReferences.map((employerReference, i) => (
                <React.Fragment key={i}>
                    <SummarySection data-testid={`employer_reference_${i + 1}`}>
                        <SubTitleWrapper>
                            <FormattedMessage
                                id="welcome.Summary.employmentDetails"
                                defaultMessage="Your Employment Details"
                            />
                        </SubTitleWrapper>
                        <FlexRow>
                            <SummaryItem
                                title={<FormattedMessage id="welcome.Summary.company" defaultMessage="Company" />}
                                text={employerReference.company_name}
                            />
                            <SummaryItem
                                title={<FormattedMessage id="welcome.Summary.position" defaultMessage="Position" />}
                                text={employerReference.position}
                            />
                            {!employerReference.end_date ? (
                                <SummaryItem
                                    title={
                                        <FormattedMessage id="welcome.Summary.startDate" defaultMessage="Start Date" />
                                    }
                                    text={formatDate(employerReference.start_date)}
                                />
                            ) : (
                                <FlexRow>
                                    <SummaryItem
                                        title={
                                            <FormattedMessage
                                                id="welcome.Summary.startDate"
                                                defaultMessage="Start Date"
                                            />
                                        }
                                        text={formatDate(employerReference.start_date)}
                                    />
                                    <SummaryItem
                                        title={
                                            <FormattedMessage id="welcome.Summary.endDate" defaultMessage="End Date" />
                                        }
                                        text={formatDate(employerReference.end_date)}
                                    />
                                </FlexRow>
                            )}
                        </FlexRow>
                        <br />
                        <SubTitleWrapper>
                            <FormattedMessage
                                id="welcome.Summary.referenceDetails"
                                defaultMessage="Reference Details"
                            />
                        </SubTitleWrapper>
                        <FlexRow>
                            <SummaryItem
                                title={<FormattedMessage id="welcome.Summary.firstName" defaultMessage="First Name" />}
                                text={employerReference.first_name}
                            />
                            <SummaryItem
                                title={<FormattedMessage id="welcome.Summary.lastName" defaultMessage="Last Name" />}
                                text={employerReference.last_name}
                            />
                            <SummaryItem
                                title={<FormattedMessage id="welcome.Summary.jobTitle" defaultMessage="Job Title" />}
                                text={employerReference.reference_job_title}
                            />
                            <SummaryItem
                                title={
                                    <FormattedMessage id="welcome.Summary.phoneNumber" defaultMessage="Phone Number" />
                                }
                                text={employerReference.phone_number}
                            />
                            <SummaryItem
                                title={<FormattedMessage id="welcome.Summary.email" defaultMessage="Email" />}
                                text={employerReference.email}
                            />
                        </FlexRow>
                        <FlexRow>
                            <SummaryItem
                                title={
                                    <FormattedMessage id="welcome.Summary.relationship" defaultMessage="Relationship" />
                                }
                                text={employerReference.relationship}
                            />
                        </FlexRow>
                    </SummarySection>
                    {employerReferences.length > 1 && i + 1 < employerReferences.length && <LineBreak />}
                </React.Fragment>
            ))}
        </SummaryContainer>
    );
};
const propTypes = {
    employerReferences: PropTypes.array,
    goToTrack: PropTypes.func.isRequired,
};

const defaultProps = {
    employerReferences: [],
};

EmployerReference.propTypes = propTypes;
EmployerReference.defaultProps = defaultProps;

export default EmployerReference;
