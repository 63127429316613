// Libraries
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';

// Modules & Utils
import { provinceToCode } from 'modules/Format';
import Auth from 'modules/Auth';

// UI
import Titlebar, { TitlebarCtaButton } from 'certn-ui/Titlebar';
import { Form } from '@ant-design/compatible';
import { Button, message, Modal, Table, Col, Pagination } from 'antd';

// Actions & Selectors
import { getUser, getTeam, getTeamId } from 'base/BaseSelectors';
import { createTeam as createTeamAction } from 'views/manager/views/admin/AdminActions';
import { joinTeam as joinTeamAction } from 'views/manager/views/settings/SettingsActions';
import { getIsFetching } from 'views/manager/views/admin/AdminSelectors';

// Components
import { TeamsForm } from 'views/manager/views/admin/components';
import { Team } from './components';
import { getRequest } from 'utils/http';

const mapStateToProps = (state) => ({
    isFetching: getIsFetching(state),
    user: getUser(state),
    userTeam: getTeam(state),
    teamID: getTeamId(state),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            joinTeam: joinTeamAction,
            createTeam: createTeamAction,
        },
        dispatch
    );

const Teams = ({ userTeam, form, history, teamID, createTeam, intl, user, joinTeam }) => {
    const [addTeam, setAddTeam] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [orderBy, setOrderBy] = useState('-created');
    const [searchByName, setSearchByName] = useState('');
    const [teams, setTeams] = useState([]);
    const [teamsCount, setTeamsCount] = useState(0);

    const fetchPaginatedTeams = async (page, page_size, order_by, search_by_name) => {
        const response = await getRequest({
            version: 'v2',
            endpoint: `/paginated-teams/?page=${page}&page_size=${page_size}&ordering=${order_by}&search=${search_by_name}`,
        });
        setTeams(response.results);
        setTeamsCount(response.count);
    };

    const orderByOptions = [
        {
            label: intl.formatMessage({
                id: '00c59.Teams.newest',
                defaultMessage: 'Newest',
            }),
            value: '-created',
        },
        {
            label: intl.formatMessage({
                id: '00c59.Teams.name',
                defaultMessage: 'Name',
            }),
            value: 'name',
        },
    ];

    useEffect(() => {
        fetchPaginatedTeams(currentPage, pageSize, orderBy, searchByName);
    }, [currentPage, pageSize, orderBy, searchByName]);

    const handleSubmit = (e) => {
        e.preventDefault();
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                values.other_province_state = values.province_state;
                values.province_state = provinceToCode(values.province_state);

                createTeam(values)
                    .then(() => {
                        message.success(
                            intl.formatMessage({
                                id: '00c59.Teams.successfullyCreated',
                                defaultMessage: 'Team was successfully created!',
                            })
                        );
                        setAddTeam(false);
                        fetchPaginatedTeams(currentPage, pageSize, orderBy, searchByName);
                    })
                    .catch(() => {
                        message.error(
                            intl.formatMessage({
                                id: '00c59.Teams.errorCreating',
                                defaultMessage: 'Error creating team. Please contact Support.',
                            })
                        );
                    });
            }
        });
    };

    const handleJoinTeam = (team_id) => {
        joinTeam({ team_id }, user.id);
    };

    const teamColumns = [
        {
            title: intl.formatMessage({ id: '00c59.Teams.team', defaultMessage: 'Team' }),
            dataIndex: 'name',
            render: (text) => <span>{text}</span>,
            width: '40%',
        },
        {
            title: intl.formatMessage({ id: '00c59.Teams.teamPublicName', defaultMessage: 'Team Public Name' }),
            dataIndex: 'org_name',
            render: (text, record) => <span>{text || record.internal_name || '-'}</span>,
            width: '20%',
        },
        {
            title: intl.formatMessage({ id: '00c59.Teams.activeUsers', defaultMessage: 'Active Users' }),
            dataIndex: 'active_user_count',
            render: (text) => <span>{text}</span>,
            width: '10%',
        },
        {
            title: '',
            width: '30%',
            render: (text, record) => (
                <Team.ButtonRow>
                    {userTeam.allow_team_select && (
                        <Col>
                            <Team.ButtonWrapper>
                                {record.id === teamID ? (
                                    <Button type="primary" disabled>
                                        <FormattedMessage id="00c59.Teams.activeTeam" defaultMessage="Default Team" />
                                    </Button>
                                ) : (
                                    !user?.api_user_role && (
                                        <Button
                                            type="primary"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleJoinTeam(record.id);
                                            }}
                                        >
                                            <FormattedMessage
                                                id="00c59.Teams.joinTeam"
                                                defaultMessage="Set as default"
                                            />
                                        </Button>
                                    )
                                )}
                            </Team.ButtonWrapper>
                        </Col>
                    )}
                    <Col>
                        <Team.ButtonWrapper>
                            <Button
                                type="secondary"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    history.push(`/settings/payment_details/${record.id}`);
                                }}
                            >
                                <FormattedMessage id="00c59.Teams.settings" defaultMessage="Settings" />
                            </Button>
                        </Team.ButtonWrapper>
                    </Col>
                </Team.ButtonRow>
            ),
        },
    ];

    return (
        <span>
            <Titlebar
                title={intl.formatMessage({
                    id: '00c59.Teams.teamManagementTitle',
                    defaultMessage: 'Team Management',
                })}
                marginBottom
            >
                {Auth.isPermissionLevel('admin') && (
                    <TitlebarCtaButton type="primary" onClick={() => setAddTeam(true)}>
                        <FormattedMessage id="00c59.Teams.addATeam" defaultMessage="ADD A TEAM" />
                    </TitlebarCtaButton>
                )}
            </Titlebar>
            <Team.TeamSearchBar>
                <Team.TeamSearch
                    placeholder={intl.formatMessage({
                        id: '00c59.Teams.searchTeams',
                        defaultMessage: 'Search Teams',
                    })}
                    onChange={(e) => setSearchByName(e.target.value)}
                />
                <Team.TeamSortByText>
                    <FormattedMessage id="00c59.Teams.sortBy" defaultMessage="Sort By:" />
                </Team.TeamSortByText>
                <Team.TeamOrderBy defaultValue="Newest" onChange={setOrderBy} options={orderByOptions} />
            </Team.TeamSearchBar>

            {teams.length > 0 && <Table rowKey="id" columns={teamColumns} dataSource={teams} pagination={false} />}
            <Form>
                <Modal
                    title={intl.formatMessage({ id: '00c59.Teams.addTeam', defaultMessage: 'Add Team' })}
                    visible={addTeam}
                    onOk={handleSubmit}
                    onCancel={() => setAddTeam(false)}
                    okText={intl.formatMessage({ id: 'common.submit', defaultMessage: 'Submit' })}
                >
                    <TeamsForm form={form} teams={teams} />
                </Modal>
            </Form>
            <Team.PaginationFooterWrapper>
                <Pagination
                    total={teamsCount}
                    pageSize={pageSize}
                    current={currentPage}
                    onChange={(page, size) => {
                        setCurrentPage(page);
                        setPageSize(size);
                    }}
                />
            </Team.PaginationFooterWrapper>
        </span>
    );
};

export default Form.create()(withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(Teams))));
