import React from 'react';
import styled from 'styled-components/macro';

const SkeletonLoader = ({ height, width, radius, style }) => (
    <Bottom radius={radius} height={height} width={width} style={style}>
        <Top radius={radius} />
    </Bottom>
);

export default SkeletonLoader;

const Bottom = styled.div`
    max-width: 100%;
    min-width: ${(props) => props.width || '100%'};
    min-height: ${(props) => props.height || '18px'};
    width: ${(props) => props.width || '100%'};
    height: ${(props) => props.height || '18px'};
    margin: ${(props) => props.margin || '10px 0px 0px 0px'};
    border-radius: ${(props) => props.radius || '4px'};
    background: ${({ theme }) => theme.color.certnGray300};
    background: linear-gradient(
        to right,
        ${({ theme }) => theme.color.certnGray300} 0%,
        ${({ theme }) => theme.color.certnGray100} 100%
    );
`;

const Top = styled.div`
    @keyframes colorChange {
        0% {
            opacity: 1;
        }
        50% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.color.certnGray300};
    background: linear-gradient(
        to right,
        ${({ theme }) => theme.color.certnGray100} 0%,
        ${({ theme }) => theme.color.certnGray300} 100%
    );
    border-radius: ${(props) => props.radius || '4px'};
    animation-name: colorChange;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in;
`;
