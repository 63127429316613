import { PSClickWrap } from '@pactsafe/pactsafe-react-sdk';
import { ErrorAlertAPI } from 'certn-ui/ErrorAlert';
import { useCallback, useEffect } from 'react';
import config from 'utils/config';
import { useIntl } from 'react-intl';

type ClickwrapProps = {
    groupKey?: string;
    containerId?: string;
    signerId: string;
    allowDisagreed?: boolean;
    onValid?: () => void;
    onInvalid?: () => void;
    onRendered?: (context: unknown) => void;
    clickWrapStyle?: string;
    contractId?: string;
    forceScroll?: boolean;
    disableSending?: boolean;
    renderData?: Record<string, unknown>;
    acceptanceLanguage?: string;
    setSigned?: (signed: boolean) => void;
};

export const Clickwrap: React.FC<ClickwrapProps> = ({
    groupKey,
    contractId,
    signerId,
    containerId,
    onValid,
    onInvalid,
    onRendered,
    clickWrapStyle,
    allowDisagreed,
    forceScroll,
    renderData,
    acceptanceLanguage,
    setSigned,
    disableSending = true,
}) => {
    const ACCESS_ID = process.env.REACT_APP_CLICKWRAP_ACCESS_ID;
    const filter = contractId ? `id==${contractId}` : undefined;
    const intl = useIntl();

    useEffect(() => {
        if (!ACCESS_ID) {
            ErrorAlertAPI();
            console.error('Unable to initialize contract. Missing Clickwrap Access ID.');
        }
    }, [ACCESS_ID]);

    const handleError = useCallback((error: string) => console.error('Error when signing contract:', error), []);

    const handleRetrieved = (
        _responseJSON: unknown,
        _xhr: unknown,
        context: { agreed: { data: { values: Record<string, boolean> } } }
    ) => {
        if (forceScroll === true) {
            const scrollMessage = document.getElementById(`ps-scroll-message-${contractId}`) as HTMLElement | null;
            if (scrollMessage) {
                scrollMessage.textContent = intl.formatMessage({
                    id: 'welcome.consentAndDisclosure.scrollText',
                    defaultMessage: 'Please scroll to the bottom to accept',
                });
            }
        }

        let isAgreed = false;

        if (groupKey !== undefined) {
            const groupInputs = document.getElementById(`ps-group-${groupKey}`)?.getElementsByTagName('input') || [];
            isAgreed = Object.values(context?.agreed.data.values).every(Boolean);
            if (isAgreed === true) {
                Array.from(groupInputs).map((checkbox) => {
                    if (checkbox.type === 'checkbox') {
                        checkbox.checked = true;
                        checkbox.disabled = true;
                    }
                });
            }
        } else if (contractId !== undefined) {
            const checkbox = document.getElementById(`ps-contract-checkbox-${contractId}`) as HTMLInputElement | null;
            isAgreed = context?.agreed.data.values[`:${contractId}`];
            if (isAgreed === true && checkbox != null) {
                checkbox.disabled = true;
                checkbox.checked = true;
            }
        }
        setSigned?.(isAgreed);
    };

    if (!ACCESS_ID) {
        return null;
    }
    return (
        <PSClickWrap
            accessId={ACCESS_ID}
            groupKey={groupKey}
            filter={filter}
            forceScroll={forceScroll}
            containerId={containerId}
            signerId={signerId}
            disableSending={disableSending}
            allowDisagreed={allowDisagreed}
            clickWrapStyle={clickWrapStyle}
            dynamic={!!renderData}
            renderData={renderData}
            onValid={() => (onValid ? onValid() : setSigned?.(true))}
            onInvalid={() => (onInvalid ? onInvalid() : setSigned?.(false))}
            onRendered={onRendered}
            onRetrieved={handleRetrieved}
            onError={handleError}
            testMode={config.isNotProduction}
            acceptanceLanguage={acceptanceLanguage}
        />
    );
};
