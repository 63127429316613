// Libraries
import React from 'react';

// Cell Components
import { ApplicationStatus } from 'certn-ui';

const StatusCell = ({ record }) => <ApplicationStatus application={record} />;

StatusCell.propTypes = {};

export default StatusCell;
