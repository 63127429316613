// Libraries
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

// Modules
import { getDateFormat } from 'modules/Format';
import withNavigation from 'views/welcome/modules/WithNavigation';
import { getEndDateBeforeStartDateError } from 'modules/validation';
import withSettingsChecker from 'views/welcome/modules/WithSettingsChecker';
import { yearsValidationError } from 'views/welcome/views/employerReference/employerReferenceHelpers';

// Components
import Text from 'certn-ui/Text';
import Title from 'certn-ui/Title';
import { AppearRight } from 'certn-ui/Animate';
import { ErrorAlertCustom } from 'certn-ui/ErrorAlert';
import { TrackNavigation } from 'views/welcome/components';
import EmployerReferenceList from './components/EmployerReferenceList';
import EmployerReferenceForm from './components/EmployerReferenceForm';

// Actions & Selectors
import { setTrackPageOrder, patchInformation } from 'views/welcome/WelcomeActions';
import {
    getEmployerReferences,
    getEmployers,
    getSettings,
    getApplicant,
    getApplicantAccount,
    getInformation,
    getEmployerReferenceSkipReasons,
} from 'views/welcome/WelcomeSelectors';

// Constants
import { CHECK_REQUEST } from 'base/BaseConstants';
import EmployerReferenceSkipModal from './components/EmployerReferenceSkipModal';

const inlineBold = (text) => (
    <Text display="inline" weight="600">
        {text}
    </Text>
);

export const convertReasonObjectToArray = (reasons) => {
    const reasonKeys = Object.keys(reasons);
    const reasonList = reasonKeys.map((key) => ({ constant: key, readable: reasons[key] }));
    return reasonList;
};

const EmployerReference = ({ trackPageLocation, handleForward, runSettingsCheck, handleNextTrack, intl }) => {
    const dispatch = useDispatch();
    const [employerReference, setEmployerReference] = useState(null);
    const [phoneErrorStatus, setPhoneErrorStatus] = useState(null);
    const employerReferences = useSelector(getEmployerReferences);
    const employers = useSelector(getEmployers) || [];
    const information = useSelector(getInformation);
    const applicantAccount = useSelector(getApplicantAccount);
    const settings = useSelector(getSettings);
    const applicant = useSelector(getApplicant);
    const {
        employer_references_min: minReferences,
        employer_references_max: maxReferences,
        employer_references_years_or_individually: yearsOrIndividually,
        employer_references_years: numYears,
    } = applicant || settings;
    const { disable_employer_references_escape_hatch: disableEscapeHatch } = settings;
    const [modalVisible, setModalVisible] = useState(false);
    const [skipReason, setSkipReason] = useState(information?.employer_reference_skip_reason);
    const escapeHatchReasons = useSelector(getEmployerReferenceSkipReasons);
    const reasonList = convertReasonObjectToArray(escapeHatchReasons);

    useEffect(() => {
        dispatch(setTrackPageOrder(['base', 'form']));
    }, [dispatch]);

    const individuallySubtitle = () => {
        const willBeContacted = inlineBold(
            <FormattedMessage id="welcome.EmployerReference.subtitleBoldFragment" defaultMessage="will be contacted" />
        );

        if (minReferences !== maxReferences) {
            return (
                <FormattedMessage
                    id="welcome.EmployerReference.subtitleRange"
                    defaultMessage="Please provide {minimum} to {maximum} employer references that {willBeContacted} by Certn. The references provided should be either a former/current supervisor or direct superior."
                    values={{ minimum: inlineBold(minReferences), maximum: inlineBold(maxReferences), willBeContacted }}
                />
            );
        }

        return minReferences === 1 ? (
            <FormattedMessage
                id="welcome.EmployerReference.subtitleSingular"
                defaultMessage="Please provide 1 employer reference that {willBeContacted} by Certn. The reference provided should be either a former/current supervisor or direct superior."
                values={{ willBeContacted }}
            />
        ) : (
            <FormattedMessage
                id="welcome.EmployerReference.subtitlePlural"
                defaultMessage="Please provide {minimum} employer references that {willBeContacted} by Certn. The references provided should be either a former/current supervisor or direct superior."
                values={{ minimum: inlineBold(minReferences), willBeContacted }}
            />
        );
    };

    const handleAdd = () => {
        setEmployerReference(null);
        handleForward();
    };

    const handleDelete = (id) => {
        try {
            const newEmployerReferences = employerReferences.filter((reference) => reference.id !== id);
            dispatch(patchInformation({ employer_references: newEmployerReferences }));
        } catch (error) {
            ErrorAlertCustom({
                title: intl.formatMessage({
                    id: 'welcome.EmployerReference.deleteError',
                    defaultMessage: 'Error deleting reference.',
                }),
            });
        }
    };

    const handleEdit = (reference) => {
        setEmployerReference(reference);
        handleForward();
    };

    const checkSettingsMet = () => {
        if (yearsOrIndividually === 'YEARS' && yearsValidationError(employerReferences, numYears)) {
            setModalVisible(true);
            return;
        }

        if (information.employer_references.length < minReferences) {
            setModalVisible(true);
            return;
        }

        setSkipReason(null);
        dispatch(patchInformation({ employer_reference_skip_reason: '' }));
        handleNextTrack();
    };

    const handleSubmit = (e, validateFields, setFields, phone = null) => {
        e.preventDefault();

        const applicantEmail = applicantAccount && applicantAccount.email;
        const applicantPhone = information.phone_number && parsePhoneNumberFromString(information.phone_number, 'CA');
        const phoneNumber = phone && parsePhoneNumberFromString(phone, 'CA');
        const isPhoneSameAsApplicant = phoneNumber?.nationalNumber === applicantPhone?.nationalNumber;

        validateFields((errors, values) => {
            const { email } = values;
            let hasErrors = Boolean(errors);

            // phone validation for InternationalPhone component
            if (phoneNumber) {
                if (phoneNumber.isValid() && isPhoneSameAsApplicant) {
                    ErrorAlertCustom({
                        description: intl.formatMessage({
                            id: 'welcome.EmployerReference.differentPhoneError',
                            defaultMessage: 'Reference phone number must be different from applicant phone number.',
                        }),
                    });
                    hasErrors = true;
                } else if (phoneNumber.isValid()) {
                    setPhoneErrorStatus('success');
                } else {
                    setPhoneErrorStatus('error');
                    hasErrors = true;
                }
            } else if (applicant[CHECK_REQUEST.EMPLOYER_PHONE_REFERENCES] || settings.employer_ref_phone_req) {
                // phone number is required when phone references are requested
                setPhoneErrorStatus('error');
                hasErrors = true;
            }

            if (email && email === applicantEmail) {
                ErrorAlertCustom({
                    description: intl.formatMessage({
                        id: 'welcome.EmployerReference.differentEmailError',
                        defaultMessage: 'Reference email must be different from applicant email.',
                    }),
                });
                hasErrors = true;
            }

            const dateCompareError = getEndDateBeforeStartDateError(
                moment(values.end_date).format(getDateFormat()),
                moment(values.start_date).format(getDateFormat())
            );
            if (dateCompareError) {
                setFields({
                    start_date: {
                        value: values.start_date,
                        errors: [new Error(dateCompareError)],
                    },
                });
            }

            if (!hasErrors && !dateCompareError) {
                try {
                    const referenceId = employerReference?.id;
                    const newEmployerReference = {
                        ...values,
                        phone_number: phoneNumber ? phoneNumber.formatInternational() : '',
                        email: values.email || '',
                    };
                    let newEmployerReferences = employerReferences;

                    if (referenceId) {
                        newEmployerReferences = employerReferences.map((reference) =>
                            reference.id === referenceId ? { ...reference, ...newEmployerReference } : reference
                        );
                    } else {
                        newEmployerReferences.push(newEmployerReference);
                    }

                    dispatch(patchInformation({ employer_references: newEmployerReferences })).then(() =>
                        handleForward()
                    );
                } catch (error) {
                    ErrorAlertCustom({
                        title: intl.formatMessage({
                            id: 'welcome.EmployerReference.generalError',
                            defaultMessage: 'Error saving reference.',
                        }),
                    });
                }
            }
        });
    };

    const handleModalCancel = () => {
        setModalVisible(false);
    };

    const saveSkipReason = (reason) => {
        const givenReason = reasonList.filter((r) => r.readable === reason)[0].constant;
        setSkipReason(givenReason);
        dispatch(patchInformation({ employer_reference_skip_reason: givenReason })).then(() => handleNextTrack());
    };

    const pages = () => ({
        base: (
            <AppearRight>
                <EmployerReferenceSkipModal
                    skipReason={
                        skipReason ? reasonList?.filter((reason) => reason.constant === skipReason)[0].readable : null
                    }
                    reasonList={reasonList}
                    modalVisible={modalVisible}
                    saveReason={saveSkipReason}
                    handleModalCancel={handleModalCancel}
                    numberOfReferences={employerReferences.length}
                    yearsOrIndividual={yearsOrIndividually}
                    numberOfYears={numYears}
                    employerReferencesMin={minReferences}
                />
                <Title>
                    <FormattedMessage
                        id="welcome.EmployerReference.title"
                        defaultMessage="Please provide your employer references"
                    />
                </Title>
                <Title size="xs" color="certnGray500">
                    {yearsOrIndividually === 'INDIVIDUALLY' ? (
                        individuallySubtitle()
                    ) : (
                        <FormattedMessage
                            id="welcome.EmployerReference.subtitleYears"
                            defaultMessage="Please provide {numYears} years of employer references to be contacted. The references provided should be a supervisor or direct superior of your position."
                            values={{ numYears: inlineBold(numYears) }}
                        />
                    )}
                </Title>
                <EmployerReferenceList
                    onAdd={handleAdd}
                    onDelete={handleDelete}
                    onEdit={handleEdit}
                    references={employerReferences}
                    maximum={maxReferences}
                    minimum={minReferences}
                    isYears={yearsOrIndividually === 'YEARS'}
                />
                <TrackNavigation handleSubmit={disableEscapeHatch ? runSettingsCheck : checkSettingsMet} />
            </AppearRight>
        ),
        form: (
            <EmployerReferenceForm
                handleSubmit={handleSubmit}
                settings={settings}
                applicant={applicant}
                applicantEmail={applicantAccount.email}
                phoneErrorStatus={phoneErrorStatus}
                reference={employerReference}
                employers={employers}
            />
        ),
    });

    return pages()[trackPageLocation];
};

export default withRouter(withNavigation(withSettingsChecker(injectIntl(EmployerReference))));
