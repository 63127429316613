// Libraries
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Form, Select, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

// Actions & Selectors
import { getAllListings, getAllListingIds } from 'views/manager/views/pm/views/listings/ListingsSelectors';
import { getListingId, getPropertyId } from 'views/manager/features/ScreenApplicant/ScreenApplicantSelectors';
import {
    handleListingSelect,
    SET_SHOULD_PERSIST_SCREEN_APPLICANT_STATE,
} from 'views/manager/features/ScreenApplicant/ScreenApplicantActions';

const ApplicationsScreenListingSection = ({ optional }) => {
    const allListings = useSelector(getAllListings);
    const allListingIds = useSelector(getAllListingIds);
    const listingId = useSelector(getListingId);
    const propertyId = useSelector(getPropertyId);
    const history = useHistory();
    const dispatch = useDispatch();
    const disabled = !propertyId;
    return (
        <>
            <Form.Item
                name="listing_id"
                initialValue={listingId || null}
                rules={[
                    {
                        required: !optional,
                        message: 'Please select an existing (active) listing',
                    },
                    {
                        type: 'enum',
                        enum: allListingIds,
                        message: <span style={{ color: 'red' }}>Please select a listing from the list</span>,
                    },
                ]}
            >
                <Select
                    showArrow
                    allowClear
                    showSearch
                    data-testid="select_a_listing"
                    onChange={(id) => dispatch(handleListingSelect(id))}
                    disabled={allListings.length === 0 || disabled}
                    placeholder={allListings.length > 0 ? 'Select a listing' : 'This building has no listings'}
                    filterOption={(inputValue, option) =>
                        option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                    }
                >
                    {allListings.map((listing) => (
                        <Select.Option key={listing.id} text={listing.id}>
                            {listing.unit
                                ? `${listing.unit} --- ${listing.property.address} (${
                                      listing.applicant_count
                                  } Application${listing.applicant_count !== 1 ? 's' : ''})`
                                : `${listing.property.address} (${listing.applicant_count} Application${
                                      listing.applicant_count !== 1 ? 's' : ''
                                  })`}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item>
                <Button
                    disabled={disabled}
                    type="dashed"
                    onClick={() => {
                        dispatch({ type: SET_SHOULD_PERSIST_SCREEN_APPLICANT_STATE, payload: true });
                        history.push('/pm/listings/new');
                    }}
                    style={{ display: 'block', width: '100%' }}
                >
                    <PlusOutlined />
                    Add Listing
                </Button>
            </Form.Item>
        </>
    );
};

export default ApplicationsScreenListingSection;
