import Constants from './constants';
import {
    ClockCircleFilled,
    PauseCircleFilled,
    StopOutlined,
    CheckCircleFilled,
    ExclamationCircleFilled,
} from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import { AdverseActionStatusType } from 'views/manager/features/AdverseActionDrawer/types';

export const AdverseActionStatusComponentType = (statusType: AdverseActionStatusType) => {
    switch (statusType) {
        case Constants.adverseActionStatus.SCHEDULED:
            return (
                <>
                    <ClockCircleFilled style={{ color: '#E67D23', paddingRight: '6px' }} />
                    <FormattedMessage id="adverseActionStatus.scheduled" defaultMessage="Adverse Action Scheduled" />
                </>
            );
        case Constants.adverseActionStatus.PAUSED:
            return (
                <>
                    <PauseCircleFilled style={{ color: '#E67D23', paddingRight: '6px' }} />
                    <FormattedMessage id="adverseActionStatus.paused" defaultMessage="Adverse Action Paused" />
                </>
            );
        case Constants.adverseActionStatus.CANCELLED:
            return (
                <>
                    <StopOutlined style={{ color: '#FF4D4F', paddingRight: '6px' }} />
                    <FormattedMessage id="adverseActionStatus.cancelled" defaultMessage="Adverse Action Cancelled" />
                </>
            );
        case Constants.adverseActionStatus.COMPLETE:
            return (
                <>
                    <CheckCircleFilled style={{ color: '#17B893', paddingRight: '6px' }} />
                    <FormattedMessage id="adverseActionStatus.complete" defaultMessage="Adverse Action Complete" />
                </>
            );
        case Constants.adverseActionStatus.IN_DISPUTE:
            return (
                <>
                    <ExclamationCircleFilled style={{ color: '#9B59B6', paddingRight: '6px' }} />
                    <FormattedMessage id="adverseActionStatus.dispute" defaultMessage="Adverse Action In Dispute" />
                </>
            );
        case Constants.adverseActionStatus.UNDELIVERABLE:
            return (
                <>
                    <ExclamationCircleFilled style={{ color: '#FF4D4F', paddingRight: '6px' }} />
                    <FormattedMessage
                        id="adverseActionStatus.undeliverable"
                        defaultMessage="Adverse Action Undeliverable"
                    />
                </>
            );
        default:
            return null;
    }
};
