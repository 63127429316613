// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { intl } from 'components/GlobalProvider';

// UI Components
import Loader from 'certn-ui/Loader';
import Titlebar from 'certn-ui/Titlebar';
import { BackButton } from 'certn-ui/Button';
import { message, Result } from 'antd';

// Actions & Selectors
import { fetchPosting, editPosting } from 'views/manager/views/hr/views/postings/PostingsActions';
import { getIsFetching, getPosting } from 'views/manager/views/hr/views/postings/PostingsSelectors';
import { getUser } from 'base/BaseSelectors';

// Other Components
import { PostingsForm } from 'views/manager/views/hr/views/postings/components';
import { FormattedMessage } from 'react-intl';

const mapStateToProps = (state) => ({
    isFetching: getIsFetching(state),
    canCreateAndEditPackages: getUser(state).can_create_and_edit_packages,
    posting: getPosting(state),
});
const mapDispatchToProps = (dispatch) => bindActionCreators({ fetchPosting, editPosting }, dispatch);

const propTypes = {
    // Redux Actions
    fetchPosting: PropTypes.func.isRequired,
    editPosting: PropTypes.func.isRequired,
    // Redux State
    isFetching: PropTypes.bool.isRequired,
    posting: PropTypes.object,
};
const defaultProps = {
    posting: {},
};

class PostingsEdit extends React.Component {
    componentDidMount() {
        if (this.props.match && this.props.match.params) {
            this.props.fetchPosting(this.props.match.params.postingId);
        }
    }

    onSubmitPosting = (postingData) => {
        if (this.props.match && this.props.match.params) {
            this.props
                .editPosting(this.props.match.params.postingId, postingData)
                .then(() =>
                    message.success(
                        intl.formatMessage({
                            id: '6bc3d.PostingEdit.packageSaved',
                            defaultMessage: 'Package Saved',
                        })
                    )
                )
                .catch(() =>
                    message.error(
                        intl.formatMessage({
                            id: '6bc3d.PostingEdit.packageNotSaved',
                            defaultMessage: 'Package Not Saved',
                        })
                    )
                );
        }
    };

    render() {
        const { canCreateAndEditPackages } = this.props;

        return (
            <>
                <BackButton
                    label={<FormattedMessage id="6bc3d.PostingEdit.packages" defaultMessage="PACKAGES" />}
                    onClick={() => this.props.history.push('/hr/packages')}
                />

                {canCreateAndEditPackages ? (
                    <>
                        <Titlebar
                            title={
                                <FormattedMessage id="6bc3d.PostingEdit.editPackage" defaultMessage="Edit Package" />
                            }
                        />
                        {this.props.isFetching ? (
                            <Loader />
                        ) : (
                            <PostingsForm posting={this.props.posting} onSubmit={this.onSubmitPosting} />
                        )}
                    </>
                ) : (
                    <Result
                        status="warning"
                        title={intl.formatMessage({
                            id: 'common.oops',
                            defaultMessage: 'Oops!',
                        })}
                        subTitle={intl.formatMessage({
                            id: '1ec29.PostingList.noPermissionToEditPackage',
                            defaultMessage:
                                "You do not have permission to edit packages. Please contact your team's administrator if you need this ability.",
                        })}
                    />
                )}
            </>
        );
    }
}

PostingsEdit.propTypes = propTypes;
PostingsEdit.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(PostingsEdit);
