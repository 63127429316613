import { get } from 'lodash';

// ----- Action Creator helper functions -----
export function createNamespacedFetchActionTypes(namespace) {
    return function createFetchActionTypes(name) {
        return {
            REQ: `${namespace}${name}_REQUEST`,
            SUC: `${namespace}${name}_SUCCESS`,
            ERR: `${namespace}${name}_ERROR`,
        };
    };
}

export function createNamespacedActionType(namespace) {
    return function createActionType(name) {
        return `${namespace}${name}`;
    };
}

// ----- Selector helper functions -----
const getLocalState = (state) => get(state, 'fetch');

export function isFetchingSelector(state, name) {
    return getLocalState(state).inflight.includes(name);
}

export function isCompleteSelector(state, name) {
    return getLocalState(state).complete.includes(name);
}

export function isNotCompleteSelector(state, name) {
    return isFetchingSelector(state, name) || !isCompleteSelector(state, name);
}

export function isFetchingNamespacedSelector(namespace) {
    return function (state, name) {
        return getLocalState(state).inflight.includes(`${namespace}${name}`);
    };
}

export function anyFetchingSelector(state) {
    return getLocalState(state).inflight.length > 0;
}

// ----- Core Reducer slice -----
const initialState = {
    inflight: [],
    complete: [],
    failed: [],
};

export function fetchReducer(state = initialState, action) {
    const matches = /(.*)_(REQUEST|SUCCESS|ERROR)/.exec(action.type);
    const old_matches = /(.*)_(REQ|RES|ERR)/.exec(action.type);

    // not a *_REQUEST / *_SUCCESS / *_ERROR
    // or a *_REQ / *_RES / *_ERR
    // action creator, so we exit with passed in state
    if (!matches && !old_matches) return state;

    const [, requestName, requestState] = matches || old_matches;
    const { inflight, complete, failed } = state;

    switch (requestState) {
        case 'REQUEST':
        case 'REQ':
            return {
                ...state,
                inflight: [requestName, ...inflight],
            };
        case 'SUCCESS':
        case 'RES':
            return {
                ...state,
                inflight: inflight.filter((inflightReqName) => inflightReqName !== requestName),
                complete: [requestName, ...complete],
                failed: failed.filter((failedReqName) => failedReqName !== requestName),
            };
        case 'ERROR':
        case 'ERR':
            return {
                ...state,
                inflight: inflight.filter((inflightReqName) => inflightReqName !== requestName),
                failed: [requestName, ...inflight.filter((failedReqName) => failedReqName !== requestName)],
            };
        default:
            return state;
    }
}
