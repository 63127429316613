// Libraries
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

// Components
import PrivacyNotice from 'views/welcome/views/nonCriminalConsent/components/PrivacyNotice';
import { YesNoConsent } from 'views/welcome/components';
import { AppearRight } from 'certn-ui/Animate';

// Modules
import withNavigation from 'views/welcome/modules/WithNavigation';

// Actions & Selectors
import { setTrackPageOrder, patchInformation } from 'views/welcome/WelcomeActions';
import { getInformation, getTeam } from 'views/welcome/WelcomeSelectors';

const PoliceCheckDisclosureConsent = ({ handleNextTrack }) => {
    const dispatch = useDispatch();
    const isAPIConsentGiven = useSelector(getInformation)?.acic_police_check_disclosure_consent_given || undefined;
    const teamName = useSelector(getTeam)?.name;

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(setTrackPageOrder(['base']));
    }, [dispatch]);

    const handleSubmit = () => {
        dispatch(patchInformation({ acic_police_check_disclosure_consent_given: true })).then(handleNextTrack);
    };

    return (
        <AppearRight>
            <YesNoConsent
                isAPIConsentGiven={isAPIConsentGiven}
                handleSubmit={handleSubmit}
                mainTitle={
                    <FormattedMessage
                        id="welcome.PoliceCheckDisclosureConsent.title"
                        defaultMessage="Authorisation to disclose Police Check results"
                    />
                }
                mainSubTitle={
                    <>
                        <FormattedMessage
                            id="welcome.PoliceCheckDisclosureConsent.subtitle"
                            defaultMessage="To complete your background check, we require your authorization to release the results Do you agree to the following statement?"
                        />
                        <PrivacyNotice />
                    </>
                }
                mainBody={
                    <FormattedMessage
                        id="welcome.PoliceCheckDisclosureConsent.body"
                        defaultMessage="I authorise Certn to send the police check result to {clientName} for the purpose of assessing your suitability for employment and/or position of trust"
                        values={{ clientName: teamName }}
                    />
                }
                warningTitle={
                    <FormattedMessage
                        id="welcome.PoliceCheckDisclosureConsent.warningTitle"
                        defaultMessage="Consent to run a police check is required in order to move forward."
                    />
                }
            />
        </AppearRight>
    );
};

export default withNavigation(PoliceCheckDisclosureConsent);
