import React, { useRef } from 'react';
import { Button, Menu, Dropdown, message } from 'antd';
import { FormattedMessage } from 'react-intl';
import { DownOutlined } from '@ant-design/icons';
import { getRequest } from 'utils/http';
import { intl } from 'components/GlobalProvider';
import { useSelector } from 'react-redux';

// Modules
import {
    ApplicantDetailsWrapper,
    ApplicantEmailText,
    ApplicantNameText,
    DownloadDropdownComponent,
    NameSectionWrapper,
} from './styled';
import { downloadFile } from 'modules/files';

// Selectors
import { getApplication } from 'views/manager/views/hr/views/applications/ApplicationsSelectors';

import { getApplication as getPMApplication } from 'views/manager/views/pm/views/applications/ApplicationsSelectors';

// Types
import { NameSectionProps } from './types';

export const NameSection: React.FC<NameSectionProps> = ({ adverseActionStatus, isHR, adverseAction }) => {
    const applicant_name = adverseAction?.applicant_name;
    const applicant_email = adverseAction?.applicant_email;
    const applicant_id = adverseAction?.applicant_id;

    const application = useSelector(isHR ? getApplication : getPMApplication);
    // TODO: Trying to align the dropdown with the parent container - Not working yet
    const dropdownRef = useRef<HTMLDivElement>(null);
    const shortUid = application?.short_uid;

    const getAdverseActionPDF = (isPreAdverse: boolean) => {
        getRequest({
            version: 'v2',
            endpoint: `/adverse-action/${applicant_id}/${isPreAdverse ? 'pa' : 'aa'}/download/final-notice`,
        })
            .then((response) => {
                downloadFile({
                    fileContents: response,
                    fileName: `${shortUid}.${isPreAdverse ? 'preadverse' : 'adverse'}action.pdf`,
                    fileType: 'adverseAction/pdf',
                });
            })
            .catch(() => {
                message.error(
                    intl.formatMessage({
                        id: 'AdverseAction.Drawer.NameSection.DownloadFailed',
                        defaultMessage: 'Failed to download adverse action',
                    })
                );
            });
    };

    return (
        <NameSectionWrapper>
            <ApplicantDetailsWrapper>
                <ApplicantNameText>{applicant_name}</ApplicantNameText>
                <ApplicantEmailText>{applicant_email}</ApplicantEmailText>
            </ApplicantDetailsWrapper>
            {adverseActionStatus && adverseActionStatus !== 'NONE' && (
                <DownloadDropdownComponent ref={dropdownRef}>
                    <Dropdown
                        overlay={
                            <Menu>
                                {isHR && (
                                    <Menu.Item onClick={() => getAdverseActionPDF(true)}>
                                        <FormattedMessage
                                            id="applicant.AdverseAction.Drawer.NameSection.DownloadPA"
                                            defaultMessage="Download Pre-Adverse Action PDF"
                                        />
                                    </Menu.Item>
                                )}
                                <Menu.Item onClick={() => getAdverseActionPDF(false)}>
                                    <FormattedMessage
                                        id="applicant.AdverseAction.Drawer.NameSection.DownloadAA"
                                        defaultMessage="Download Adverse Action PDF"
                                    />
                                </Menu.Item>
                            </Menu>
                        }
                        trigger={['click']}
                        placement="bottomLeft"
                        getPopupContainer={(triggerNode) => triggerNode.parentElement || document.body}
                    >
                        <Button style={{ borderRadius: '4px' }} size="large">
                            <FormattedMessage
                                id="applicant.AdverseAction.Drawer.NameSection.More"
                                defaultMessage="More"
                            />
                            <DownOutlined style={{ marginLeft: '6px' }} />
                        </Button>
                    </Dropdown>
                </DownloadDropdownComponent>
            )}
        </NameSectionWrapper>
    );
};
