// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

// Actions & Selectors
import { patchWelcomeSession, setTrackPageLocation, submitWelcomeSession } from 'views/welcome/WelcomeActions';
import { getTrackPageOrder, getNextTrack, getTrackOrder, getTrackPageLocation } from 'views/welcome/WelcomeSelectors';

import { ErrorAlertAPI } from 'certn-ui/ErrorAlert';

const mapStateToProps = (state, ownProps) => {
    const trackLocation = ownProps.location?.pathname?.split('/')[2];
    return {
        trackOrder: getTrackOrder(state),
        trackPageOrder: getTrackPageOrder(state),
        nextTrack: getNextTrack(state, trackLocation),
        trackPageLocation: getTrackPageLocation(state),
        trackLocation,
    };
};
const mapDispatchToProps = (dispatch) => ({
    dispatch,
    ...bindActionCreators(
        {
            patchWelcomeSession,
            setTrackPageLocation,
            submitWelcomeSession,
        },
        dispatch
    ),
});

const propTypes = {
    // Redux Store:
    trackOrder: PropTypes.array.isRequired,
    trackPageOrder: PropTypes.array.isRequired,
    nextTrack: PropTypes.string.isRequired,
    trackPageLocation: PropTypes.string.isRequired,
};
const defaultProps = {};

// This component is redundant, we should eventually move all of this into redux and move the handle forward logic into track navigation. Handle Reverse is now in TrackNavigation.jsx
const withNavigation = (WrappedComponent) =>
    class extends React.Component {
        simulateNextClick = () => {
            const next = window.top.document.getElementById('next');
            if (next) next.click();
        };

        returnToBase = () => {
            this.props.setTrackPageLocation('base');
        };

        /**
         *  "welcome" field is patched with prevTrack to save track position on refresh,
         *  forcing users to revalidate tracks they have stepped back from.
         *
         *  This helps cover edge cases where users step back and delete mandatory data (ex. Passport document) and
         *  then refresh the page rather than attempting to resubmit the track. Previously, a refresh would take
         *  users back to the furthest track they had reached, analagous to forward progress in football (via Mike).
         */

        handleForward = () => {
            const { trackPageOrder, trackPageLocation } = this.props;
            const currentPageIndex = trackPageOrder.indexOf(trackPageLocation);
            if (this.endOfTrack()) {
                this.returnToBase();
            } else {
                const nextTrackPage = trackPageOrder[currentPageIndex + 1];
                this.props.setTrackPageLocation(nextTrackPage);
            }
        };

        handleNextTrack = () => {
            const { history, nextTrack } = this.props;
            this.props
                .patchWelcomeSession(nextTrack)
                .then(() => this.props.setTrackPageLocation('base'))
                .then(() => history.push(`/welcome/${nextTrack}`));
        };

        handleSkipNextTrack = (trackToSkip = undefined) => {
            if (trackToSkip) {
                const { history, trackOrder } = this.props;
                const trackToSkipIndex = trackOrder.indexOf(trackToSkip);
                if (trackToSkipIndex === -1 || trackToSkipIndex === trackOrder.length - 1) {
                    this.handleNextTrack();
                    return;
                }
                const nextTrack = trackOrder[trackToSkipIndex + 1];
                this.props
                    .patchWelcomeSession(nextTrack)
                    .then(() => this.props.setTrackPageLocation('base'))
                    .then(() => history.push(`/welcome/${nextTrack}`));
            } else {
                this.handleNextTrack();
            }
        };

        handleNextTrackAndAutoSubmit = (customSubmitCallback = undefined) => {
            const { trackLocation, trackOrder } = this.props;
            const isLastTrack = trackOrder[trackOrder.length - 1] === trackLocation;
            if (!isLastTrack) {
                this.handleNextTrack();
                return;
            }
            this.autoSubmit(customSubmitCallback);
        };

        autoSubmit = async (customSubmitCallback) => {
            if (customSubmitCallback !== undefined && typeof customSubmitCallback === 'function') {
                customSubmitCallback();
            } else {
                try {
                    await this.props.submitWelcomeSession();
                } catch (error) {
                    ErrorAlertAPI(error);
                }
            }
        };

        /* Used in tracks similar to History where the final page signifies moving forward instead of returning to base */
        endOfTrack = () => {
            const { trackPageOrder, trackPageLocation } = this.props;
            return trackPageOrder.indexOf(trackPageLocation) === trackPageOrder.length - 1;
        };

        render() {
            return (
                <>
                    <WrappedComponent
                        trackPageOrder={this.props.trackPageOrder}
                        trackPageLocation={this.props.trackPageLocation}
                        handleForward={this.handleForward}
                        returnToBase={this.returnToBase}
                        endOfTrack={this.endOfTrack}
                        handleNextTrack={this.handleNextTrack}
                        handleSkipNextTrack={this.handleSkipNextTrack}
                        handleNextTrackAndAutoSubmit={this.handleNextTrackAndAutoSubmit}
                        {...this.props}
                    />
                </>
            );
        }
    };

withNavigation.propTypes = propTypes;
withNavigation.defaultProps = defaultProps;

export default (WrapperComponent) =>
    connect(mapStateToProps, mapDispatchToProps)(withRouter(withNavigation(WrapperComponent)));
