// Libraries
import React from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

// UI Components
import { AvatarList } from 'certn-ui/Avatar';

// Selectors
import { getUserMode } from 'base/BaseSelectors';

const CoapplicantsCell = ({ record, history }) => {
    const userMode = useSelector(getUserMode);
    return (
        <>
            {!record.is_cosigner &&
                record.application &&
                record.application.applicants &&
                record.application.applicants.length > 0 && (
                    <AvatarList
                        width="100%"
                        userMode={userMode}
                        history={history}
                        record={record}
                        list={record.application.applicants.filter((app) => !app.is_cosigner && app.id !== record.id)}
                    />
                )}
        </>
    );
};

export default withRouter(CoapplicantsCell);
