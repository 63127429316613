import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectBackwardsCompatibleRequestAndTitles } from 'views/manager/features/ScreenApplicant/components/ScreenApplicant/MicroServiceContent';
import { StaticResult } from '../AdvancedFilteringLayout';
import { FilterItem, FilterTitle } from '../styled';

type TypeFilterProps = {
    value?: string[];
    onChange: (value: string) => void;
};

export const TypeFilter: React.FC<TypeFilterProps> = ({ value, onChange }) => {
    const backwardsCompatibleRequestAndTitles = useSelector(selectBackwardsCompatibleRequestAndTitles);

    if (!backwardsCompatibleRequestAndTitles?.length) {
        return null;
    }

    return (
        <FilterItem>
            <FilterTitle>
                <FormattedMessage id="225d8.AdvancedFiltering.searchByScan" defaultMessage="Search by scan type:" />
            </FilterTitle>
            <StaticResult data-testid="advancedFilteringType" selected={value} handleChange={onChange}>
                {backwardsCompatibleRequestAndTitles}
            </StaticResult>
        </FilterItem>
    );
};
