// Libraries
import React from 'react';
import { Radio } from 'antd';

// Components
import Title from 'certn-ui/Title';
import Answer from './Answer';

const Questions = ({ question, handleAnswerSelect }) => (
    <>
        <Title>{question.text}</Title>
        <Answer>
            <Radio.Group
                onChange={(e) => handleAnswerSelect(e.target.value)}
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    flexDirection: 'row',
                    justifyContent: 'center',
                }}
            >
                {question.choices.map((c) => (
                    <Answer.Container key={c.id} correct={c.correctAnswer}>
                        <Answer.Text>{c.text}</Answer.Text>
                        <Radio value={c.id} style={{ margin: 0 }} />
                    </Answer.Container>
                ))}
            </Radio.Group>
        </Answer>
    </>
);

export default Questions;
