import * as React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Container = styled.div`
    background-color: ${({ theme }) => theme.color.certnOrange100};
    color: ${({ theme }) => theme.color.certnRed900};
    padding: 12px;
    text-align: center;
    font-size: ${({ theme }) => theme.font.xxs};
    border-radius: 4px;
`;

const Message = ({ children }) => <Container data-testid="message-ui">{children}</Container>;

Message.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
};

export default Message;
