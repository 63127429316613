import { QueryClient, QueryCache } from '@tanstack/react-query';
import { ErrorAlertAPI } from 'certn-ui/ErrorAlert';
import * as Sentry from '@sentry/browser';

function queryErrorHandler(error: unknown): void {
    if (error) {
        // display toast message to user
        ErrorAlertAPI(error);
    }
}

export function generateQueryClient(): QueryClient {
    return new QueryClient({
        queryCache: new QueryCache({
            onError: (error: unknown) => {
                if (error) {
                    // log to sentry
                    Sentry.captureException(JSON.stringify(error));
                }
            },
        }),
        defaultOptions: {
            queries: {
                onError: queryErrorHandler,
                refetchOnMount: false,
                refetchOnWindowFocus: false,
                refetchOnReconnect: false,
            },
            mutations: {
                onError: queryErrorHandler,
            },
        },
    });
}

export const queryClient = generateQueryClient();
