// Libraries
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Row, Col } from 'antd';

// Components
import Title from 'certn-ui/Title';
import Text from 'certn-ui/Text';
import { AppearRight } from 'certn-ui/Animate';
import { TrackNavigation } from 'views/welcome/components';
import UploadSection from './UploadSection';

const AcicDocumentsForm = ({ handleSubmit, isUploading, showCommencement, ...props }) => (
    <>
        <AppearRight>
            <Title>
                <FormattedMessage id="welcome.AcicDocuments.documentsTitle" defaultMessage="Identification Documents" />
            </Title>

            <Row gutter={[16, 16]}>
                <Col sm={24} md={{ span: 16, offset: 4 }} lg={{ span: 14, offset: 5 }} xl={{ span: 12, offset: 6 }}>
                    <Text align="center" color="certnGray600">
                        <FormattedMessage
                            id="welcome.AcicDocuments.description"
                            defaultMessage="To run your background check and meet the Australian Criminal Intelligence Commission (ACIC) requirements, we require {numDocs} identification documents to be provided. The combined documents must include a minimum of 1 photo identification, full name, and date of birth."
                            values={{ numDocs: showCommencement ? 4 : 3 }}
                        />
                    </Text>
                    <Text align="center" color="certnGray600">
                        <b>
                            <FormattedMessage
                                id="welcome.AcicDocuments.info"
                                defaultMessage="File types: jpg, png, or pdf, with a max file size of 10MB."
                            />
                        </b>
                    </Text>
                </Col>
            </Row>

            {showCommencement && <UploadSection category="commencement" {...props} />}
            <UploadSection category="primary" {...props} />
            <UploadSection category="secondary" index={0} {...props} />
            <UploadSection category="secondary" index={1} {...props} />
            <UploadSection category="supporting" {...props} />

            <TrackNavigation loading={isUploading} handleSubmit={handleSubmit} />
        </AppearRight>
    </>
);

export default AcicDocumentsForm;
