// Libraries
import React from 'react';

// Components
import ApiKeysDropdown from 'views/manager/views/settings/components/ApiKeysDropdown';
import ActionCellWrapper from 'components/ActionCellWrapper';

const ActionsCell = ({ row, deleteApiKey, copyTokenToClipboard }) => (
    <ActionCellWrapper margin>
        <ApiKeysDropdown
            id={row.id}
            token={row.token}
            active={row.is_active}
            deleteApiKey={deleteApiKey}
            copyTokenToClipboard={copyTokenToClipboard}
        />
    </ActionCellWrapper>
);

export default ActionsCell;
