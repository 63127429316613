// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components/macro';
import { bindActionCreators } from 'redux';
import { EllipsisOutlined } from '@ant-design/icons';

// UI Components
import { ListCellText } from 'certn-ui/List';
import { Dropdown as AntDropdown, Menu, Modal, message, Button as AntButton } from 'antd';

// Actions & Selectors
import { deleteListing, toggleListingInactive } from 'views/manager/views/pm/views/listings/ListingsActions';

// Selectors
import { getUser, getUserMode } from 'base/BaseSelectors';

const mapStateToProps = (state) => ({
    user: getUser(state),
    userMode: getUserMode(state),
    canCreateAndEditPackages: getUser(state).can_create_and_edit_packages,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            deleteListing,
            toggleListingInactive,
        },
        dispatch
    );

const propTypes = {
    // Redux State
    user: PropTypes.object.isRequired,
    userMode: PropTypes.string.isRequired,
};

const Dropdown = styled(AntDropdown)`
    margin: 8px 0px;
`;

const Button = styled(AntButton)`
    ${(props) => props.margin && `margin: ${props.margin}`};
    overflow: hidden !important;
    height: 40px !important;
    width: 40px !important;
`;

class DropdownCell extends React.Component {
    showConfirmInactive = (listing) => {
        Modal.confirm({
            title: 'Do you want to set this listing to Inactive?',
            content:
                'This will permanently archive this listing in the Inactive section. You can still make a copy of it in the future if you would like to re-list.',
            onOk: () => {
                this.props.toggleListingInactive(listing.id);
                return new Promise((resolve) => {
                    setTimeout(resolve, 1000);
                })
                    .then(() => message.success('Listing set to inactive!'))
                    .catch(() => message.error('Error occurred while attempting to set to inactive'));
            },
            onCancel() {},
        });
    };

    showConfirmDelete = (listing) => {
        Modal.confirm({
            title: 'Do you really want to delete this listing?',
            content:
                'This action cannot be undone. If you think you might re-list this listing in the future, consider selecting Cancel and trying "Set to Inactive" instead. Otherwise select Okay.',
            okType: 'danger',
            onOk: () => {
                this.props.deleteListing(listing.id);
                return new Promise((resolve) => {
                    setTimeout(resolve, 1000);
                })
                    .then(() => message.success('Listing deleted!'))
                    .catch(() => message.error('Error occurred while attempting to set delete listing'));
            },
            onCancel() {},
        });
    };

    render() {
        const {
            item,
            user,
            history,
            showSendApplications,
            canCreateAndEditPackages,
            showTrialLimitReached,
            copyToClipboard,
        } = this.props;
        return (
            <Dropdown
                overlay={
                    <Menu>
                        {item.is_active && (
                            <Menu.Item key="0">
                                <ListCellText
                                    clickable
                                    onClick={
                                        user && !user?.team?.payment_verified
                                            ? () => showTrialLimitReached()
                                            : () => showSendApplications(item.id, item)
                                    }
                                >
                                    Send Application
                                </ListCellText>
                            </Menu.Item>
                        )}
                        {item.is_active && (
                            <Menu.Item key="1">
                                <ListCellText
                                    clickable
                                    onClick={() =>
                                        copyToClipboard({ route: 'listings', urlCode: item.url_code, type: 'pm' })
                                    }
                                >
                                    Copy Listing Link
                                </ListCellText>
                            </Menu.Item>
                        )}
                        {item.is_active && canCreateAndEditPackages && (
                            <>
                                <Menu.Item key="2">
                                    <ListCellText
                                        clickable
                                        onClick={() => history.push(`/pm/listings/${item.id}/edit`)}
                                    >
                                        Edit item
                                    </ListCellText>
                                </Menu.Item>
                                <Menu.Item key="3">
                                    <ListCellText clickable onClick={() => this.showConfirmInactive(item)}>
                                        Set to Inactive
                                    </ListCellText>
                                </Menu.Item>
                            </>
                        )}
                        {!item.is_active && canCreateAndEditPackages && (
                            <Menu.Item key="3">
                                <ListCellText
                                    clickable
                                    onClick={() => history.push(`/pm/listings/new?listing_id=${item.id}`)}
                                >
                                    Re-list Listing
                                </ListCellText>
                            </Menu.Item>
                        )}
                        {canCreateAndEditPackages && (
                            <Menu.Item key="4">
                                <ListCellText clickable critical onClick={() => this.showConfirmDelete(item)}>
                                    Delete Listing
                                </ListCellText>
                            </Menu.Item>
                        )}
                    </Menu>
                }
                trigger={['click']}
                placement="bottomCenter"
            >
                <Button shape="circle" icon={<EllipsisOutlined />} />
            </Dropdown>
        );
    }
}

DropdownCell.propTypes = propTypes;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DropdownCell));
