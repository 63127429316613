// Libraries
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { isEmpty } from 'lodash';
import queryString from 'query-string';
import { useIntl } from 'react-intl';
import TagManager from 'react-gtm-module';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

// Components
import { MoreInfoForm, Congrats } from './components';
import { SignUpForm } from './SignUpForm';
import { ErrorAlertCustom } from 'certn-ui/ErrorAlert';

// Actions & Selectors
import { submitSignUpRequest, checkIfEmailExists } from 'views/welcome/WelcomeActions';
import { SMB, SIGNUP_VALID_TEAM_TYPES, COUNTRY_USA, COUNTRY_AUS, COUNTRY_UK } from 'views/welcome/WelcomeConstants';
import { updateAddressError } from 'base/BaseActions';
import { getLaunchDarklyFlags } from 'base/BaseSelectors';
import config from 'utils/config';

// Modules
import { OTHER } from 'modules/Countries';
import { useTracking } from 'hooks/trackingHooks';
import { Path } from 'modules';

// links
// https://app.certn.co/welcome/signUp/?type=SMB&teamType=HR&preset_pricing_plan=param_from_url
// https://app.certn.co/welcome/signUp/?type=SMB&teamType=PM&preset_pricing_plan=param_from_url
const SignUp = () => {
    const [trackPageLocation, setTrackPageLocation] = useState('base');
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const intl = useIntl();
    const [phone, setPhone] = useState(null);
    const [phoneError, setPhoneError] = useState(false);
    const [signUpState, setSignUpState] = useState({});
    const [submitting, setSubmitting] = useState(false);
    const { executeRecaptcha } = useGoogleReCaptcha();
    const { initializeTracking, sendGATrackingEvent } = useTracking();
    const { webFeatureEnableTeamSignUpRecaptcha } = useSelector(getLaunchDarklyFlags);

    // Mode and type specific sign up flow, defaults to regular if not found in WelcomeConstants.js
    const parsed = queryString.parse(location?.search);
    const type = parsed?.type;
    const country = parsed?.country;
    const presetPricingPlan = parsed?.preset_pricing_plan;
    const isLime = type === SMB;
    const isUsa = country?.toLowerCase() === COUNTRY_USA;
    const isUk = country?.toLowerCase() === COUNTRY_UK;
    const isAus = country?.toLowerCase() === COUNTRY_AUS;
    const teamType = parsed?.teamType?.toUpperCase() || 'HR';
    const billingPlan = teamType === 'HR' ? teamType : 'STANDARD';
    const trackPageOrder = isLime ? ['base', 'moreInfo'] : ['base', 'moreInfo', 'congrats'];
    const countryCode = isUsa ? 'US' : isUk ? 'GB' : isAus ? 'AU' : 'CA';

    const submissionConfirmedUrl = {
        USA: 'https://www.certnlime.com/submissionconfirmed/',
        UK: 'https://certn.co/uk/thank-you/',
        AUS: 'https://certn.co/anz/submission-confirmed/',
        DEFAULT: 'https://certn.co/submissionconfirmed/',
    };

    const googleAnalyticsKey = 'G-THB94K48WC';
    useEffect(() => {
        if (config.isProduction) {
            const tagManagerKey = isUsa ? 'GTM-WDD2WNS' : 'GTM-P5SBHWJ';
            const tagManagerArgs = { gtmId: tagManagerKey };
            TagManager.initialize(tagManagerArgs);
        }
    }, [isUsa]);

    useEffect(() => {
        if (config.isProduction) {
            initializeTracking({ googleAnalyticsKey });
        }
        // Query param validation
        if (isEmpty(parsed)) return;
        if (!SIGNUP_VALID_TEAM_TYPES.includes(teamType)) return history.push('/welcome/signUp');
    }, [history, googleAnalyticsKey, initializeTracking, parsed, teamType, type]);

    const handleForward = () => {
        const currentPageIndex = trackPageOrder.indexOf(trackPageLocation);
        setTrackPageLocation(trackPageOrder[currentPageIndex + 1]);
    };

    const handleReverse = () => {
        const currentPageIndex = trackPageOrder.indexOf(trackPageLocation);
        setTrackPageLocation(trackPageOrder[currentPageIndex - 1]);
    };

    const handleSignUpSubmit = (values) => {
        // If already submitting, eject, don't run again
        if (submitting) return;

        setSubmitting(true);

        dispatch(checkIfEmailExists({ email: values.email })).then(async (response) => {
            if (response?.result === 'FAIL') {
                const description = intl.formatMessage({
                    id: 'welcome.SignUp.emailExists',
                    defaultMessage: 'This email already exists in our system',
                });
                ErrorAlertCustom({ description });
            } else if (response?.result === 'OK') {
                let recaptchaToken;
                if (webFeatureEnableTeamSignUpRecaptcha) {
                    recaptchaToken = await executeRecaptcha('sign_up');
                }
                setSignUpState({ ...values, recaptcha_token: recaptchaToken });
                handleForward();
            }
            setSubmitting(false);
        });
    };

    const handleMoreInfoSubmit = (validateFields) => {
        // If already submitting, eject, don't run again
        if (submitting) return;

        validateFields((err, values) => {
            setSubmitting(true);
            let newErr = err;
            let { address, ...newValues } = values;
            const phoneNumber = phone && parsePhoneNumberFromString(phone, 'CA');
            if (phoneNumber && phoneNumber.isValid()) {
                setPhoneError('success');
            } else {
                setPhoneError('error');
                newErr = true;
            }

            // Address drop down on error in moreInfo track
            if (trackPageLocation === 'moreInfo') {
                if (!newValues.address || !newValues.city || !newValues.country) {
                    dispatch(updateAddressError(true));
                }
            }

            if (!newErr) {
                newValues = {
                    ...newValues,
                    other_province_state: newValues.other_province_state || null,
                    province_state: newValues.province_state || OTHER,
                    street_address: address,
                    team_phone_number: phoneNumber.formatInternational(),
                };
                dispatch(
                    submitSignUpRequest({
                        ...signUpState,
                        ...newValues,
                        team_type: teamType,
                        billing_plan_type: billingPlan,
                        is_lime: isLime,
                        preset_pricing_plan: presetPricingPlan,
                        subdomain: Path.getCurrentSubdomain(),
                    })
                )
                    .then(() => {
                        if (config.isProduction) {
                            sendGATrackingEvent(googleAnalyticsKey, { type, country: country?.toLowerCase() });
                        }
                        if (isLime) {
                            window.location.replace(
                                isUsa
                                    ? submissionConfirmedUrl.USA
                                    : isUk
                                    ? submissionConfirmedUrl.UK
                                    : isAus
                                    ? submissionConfirmedUrl.AUS
                                    : submissionConfirmedUrl.DEFAULT
                            );
                        } else {
                            handleForward();
                        }
                    })
                    .catch(() => setSubmitting(false));
            } else {
                setSubmitting(false);
            }
        });
    };

    const pages = () => ({
        base: (
            <SignUpForm
                onSubmit={handleSignUpSubmit}
                submitting={submitting}
                isLime={isLime}
                email={signUpState.email}
                countryCode={countryCode}
            />
        ),
        moreInfo: (
            <MoreInfoForm
                handleSubmit={handleMoreInfoSubmit}
                phone={phone}
                setPhone={setPhone}
                errorStatus={phoneError}
                submitting={submitting}
                handleReverse={handleReverse}
                countryCode={countryCode}
            />
        ),
        congrats: <Congrats />,
    });

    return <>{pages()[trackPageLocation]}</>;
};

export default SignUp;
