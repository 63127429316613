import React from 'react';
import styled from 'styled-components/macro';
import { Progress } from 'antd';

export const ProgressBar = ({ percent, complete }) => {
    let percentComplete = percent;
    if (complete) percentComplete = 100;
    return (
        <ProgressBarWrapper>
            <Progress percent={Math.round(percentComplete)} />
        </ProgressBarWrapper>
    );
};

export const ProgressBarWrapper = styled.footer`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: ${(props) => (props.fixed ? '11' : '10')};
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    min-height: 60px;
    @media (max-width: 720px) {
        min-height: 38px;
        padding-bottom: 4px;
    }
    border-top: 2px solid ${(props) => props.theme.color.certnGray100};
    padding: 0 10%;
    box-sizing: border-box;
    background-color: ${({ theme }) => theme.color.certnWhite};
`;
