// Libraries
import styled from 'styled-components/macro';

const QuestionEnclosure = styled.div`
    flex: 1 0 0%;
    max-width: 75%;
    width: 75%;
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    text-align: center;
    align-items: center;
    padding-top: 30px;

    @media (max-width: ${(props) => props.theme.width.xlrg}) {
        width: 85%;
        max-width: 85%;
    }

    @media (max-width: ${(props) => props.theme.width.lrg}) {
        width: 100%;
        max-width: 100%;
    }

    @media (max-width: ${(props) => props.theme.width.phone}) {
        padding-bottom: 0px;
    }
`;

export default QuestionEnclosure;
