// Libraries
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';

// Components
import SummaryItem from './SummaryItem';
import SummaryHeader from './SummaryHeader';
import { FlexRow, SummarySection, SummaryContainer } from './SummaryStyles';
import { CHECK_REQUEST } from 'base/BaseConstants';

// Actions & Selectors
import { getApplicant } from 'views/welcome/WelcomeSelectors';

const BirthPlace = ({ information, goToTrack }) => {
    const applicant = useSelector(getApplicant);
    const isUkCheck =
        applicant && (applicant[CHECK_REQUEST.UK_BASIC_DBS_CHECK] || applicant[CHECK_REQUEST.UK_BASIC_DS_CHECK]);
    return (
        <SummaryContainer>
            <SummaryHeader
                title={<FormattedMessage id="welcome.birthplace.country" defaultMessage="Country of Birth" />}
                onClick={goToTrack}
            />
            <SummarySection>
                <FlexRow>
                    {isUkCheck && (
                        <SummaryItem
                            title={
                                <FormattedMessage id="welcome.birthplace.country" defaultMessage="Country of Birth" />
                            }
                            text={information.birth_country}
                        />
                    )}
                    {isUkCheck && (
                        <SummaryItem
                            title={<FormattedMessage id="welcome.birthplace.city" defaultMessage="Town/City" />}
                            text={information.birth_city}
                        />
                    )}
                </FlexRow>
            </SummarySection>
        </SummaryContainer>
    );
};

export default injectIntl(BirthPlace);
