import React from 'react';
import styles from 'styles/styles';

const Bankruptcy = ({ scale }) => (
    <svg width={63} height={55} style={{ transform: `scale3d(${scale}, ${scale}, ${scale})` }}>
        <title>real estate_falling_decrease_market_graph_chart</title>
        <g stroke={styles.color.certnGreen700} strokeWidth={1.525} fill="none" fillRule="evenodd">
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M14.853 9.494l6.298 12.399 9.868-7.899 4.132 15.399 7-11 14.368 21.101"
            />
            <path d="M62.382 47.955h-55.5V.888" />
            <path
                d="M.617 13.247h6.21M.617 27.341h6.21M.617 41.436h6.21M13.435 54.113v-6.211M26.833 54.113v-6.211M40.231 54.113v-6.211M53.63 54.113v-6.211"
                strokeLinejoin="round"
            />
        </g>
    </svg>
);

export default Bankruptcy;
