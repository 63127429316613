// Libraries
import React from 'react';
import { withRouter } from 'react-router';
import styled from 'styled-components/macro';
import { Button as AntdButton } from 'antd';
import { FormattedMessage } from 'react-intl';

// Components
import { Wrapper, TopPanel, BottomWindow, Title, Column } from './ReportBottomPanelLayout';

const Button = styled(AntdButton)`
    background-color: ${({ theme, color }) => theme.color[color]} !important;
    border-color: ${({ theme, color }) => theme.color[color]} !important;
`;

const MoveToAccepted = ({ accepted, onClick }) => (
    <Button disabled={accepted} type="primary" color={accepted || 'certnGreen700'} onClick={onClick}>
        <FormattedMessage id="4532e.ReportAdjudication.move" defaultMessage="MOVE APPLICANT TO EMPLOYEES" />
    </Button>
);

const buttonConfiguration = ({ applicantId, adjudicationStatus, setApplicationStatus }) => {
    const accepted = adjudicationStatus === 'OFFER_ACCEPTED';

    // Button setApplicationStatus bindings
    const moveApplicationTo = (moveStatus) => () => setApplicationStatus(applicantId, moveStatus);
    const offerAccepted = moveApplicationTo('OFFER_ACCEPTED');

    const adjudicationStatusMap = {
        OFFER_PENDING: [MoveToAccepted({ onClick: offerAccepted })],
        NONE: [MoveToAccepted({ onClick: offerAccepted })],
        OFFER_ACCEPTED: [MoveToAccepted({ accepted })],
        PROSPECT: [MoveToAccepted({ onClick: offerAccepted })],
        ARCHIVED: [],
    };
    return adjudicationStatusMap[adjudicationStatus];
};

const ReportAdjudication = (props) => {
    const [left, right] = buttonConfiguration(props);
    return (
        <Wrapper>
            <TopPanel>
                <Title>
                    <FormattedMessage id="4532e.ReportAdjudication.adj" defaultMessage="Adjudication" />
                </Title>
            </TopPanel>
            <BottomWindow>
                <Column margin="0px 30px 0px 0px">{left}</Column>
                <Column>{right}</Column>
            </BottomWindow>
        </Wrapper>
    );
};

export default withRouter(ReportAdjudication);
