import { get } from 'lodash';
import { createSelector } from 'reselect';
import { STATEPATH as PARENT_STATEPATH } from 'views/manager/views/pm/PropertyManagerConstants';

const STATEPATH = `${PARENT_STATEPATH}.properties`;

const getLocalState = (state) => get(state, STATEPATH);

export const getIsFetching = (state) => getLocalState(state).isFetching;
export const getIsFetchingSilent = (state) => getLocalState(state).isFetchingSilent;
export const getProperties = (state) => getLocalState(state).properties;
export const getAllProperties = (state) => getLocalState(state).allProperties;
export const getAllPropertyNames = createSelector(getAllProperties, (properties) =>
    properties.map((property) => property.building)
);
export const getAllPropertyIds = createSelector(getAllProperties, (properties) =>
    properties.map((property) => property.id)
);
export const getProperty = (state) => getLocalState(state).property;
export const getNewProperty = (state) => getLocalState(state).newProperty;

// List Selectors
export const getIsActive = (state) => getLocalState(state).isActive;
export const getSearchBy = (state) => getLocalState(state).searchBy;
export const getOrdering = (state) => getLocalState(state).ordering;
export const getReverseOrder = (state) => getLocalState(state).reverseOrder;
export const getFilterBy = (state) => getLocalState(state).filterBy;
export const getPageSize = (state) => getLocalState(state).pageSize;
export const getCurrentPage = (state) => getLocalState(state).currentPage;
export const getNumProperties = (state) => getLocalState(state).numProperties;
export const getNumAllProperties = (state) => getLocalState(state).numAllProperties;
