import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Button } from 'antd';

// Actions & Selectors
import { getIsFetchingSilent } from 'views/welcome/WelcomeSelectors';

const CustomButton = styled(Button)`
    ${({ dualButtons }) => dualButtons && 'flex: 1 0 35%'};
    text-transform: uppercase;
`;

const BackButton = ({ dualButtons, onClick, disabled, loading, children, type }) => {
    const isFetchingSilent = useSelector(getIsFetchingSilent);
    const intl = useIntl();
    return (
        <CustomButton
            dualButtons={dualButtons}
            onClick={onClick}
            id="back"
            type={type}
            size="large"
            wide="true"
            loading={loading || isFetchingSilent}
            disabled={disabled}
        >
            {children || intl.formatMessage({ id: 'common.back', defaultMessage: 'Back' })}
        </CustomButton>
    );
};

BackButton.propsTypes = {
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    children: PropTypes.node,
    type: PropTypes.string,
};

BackButton.defaultProps = {
    onClick: null,
    disabled: false,
    loading: false,
    children: undefined,
    type: 'link',
};

export default BackButton;
