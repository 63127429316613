export const NAMESPACE = 'base/';
export const STATEPATH = 'base';

// Report Status'
export const ACTION_REQUIRED = 'ACTION_REQUIRED';

// Auth.js permission levels
export const PERMISSION_LEVEL = {
    STAFF: 4,
    ADMIN: 3,
    MANAGER: 2,
    USER: 1,
    APPLICANT: 0,
};

// Public S3 certn images link
export const CERTN_IMAGES_S3 = process.env.REACT_APP_CERTN_IMAGES_S3_URL;

// Check request flags
export const CHECK_REQUEST = {
    BASE: 'request_base',
    SOFTCHECK: 'request_softcheck',
    EQUIFAX: 'request_equifax',
    IDENTITY_VERIFICATION: 'request_identity_verification',
    ENHANCED_IDENTITY_VERIFICATION: 'request_enhanced_identity_verification',
    CRIMINAL_RECORD_CHECK: 'request_criminal_record_check',
    ENHANCED_CRIMINAL_RECORD_CHECK: 'request_enhanced_criminal_record_check',
    VULNERABLE_SECTOR_CRIMINAL_RECORD_CHECK: 'request_vulnerable_sector_criminal_record_check',
    INTERNATIONAL_CRIMINAL_RECORD_CHECK: 'request_international_criminal_record_check',
    STANDARD_ACIC_CHECK: 'request_australian_criminal_intelligence_commission_check',
    VOLUNTEER_ACIC_CHECK: 'request_volunteer_acic_check',
    RIGHT_TO_WORK: 'request_right_to_work',
    UK_BASIC_DBS_CHECK: 'request_uk_basic_dbs_check',
    UK_BASIC_DS_CHECK: 'request_uk_basic_ds_check',
    UK_RIGHT_TO_WORK_CHECK: 'request_uk_right_to_work_check',
    EDUCATION_VERIFICATION: 'request_education_verification',
    CREDENTIAL_VERIFICATION: 'request_credential_verification',
    EMPLOYER_REFERENCES: 'request_employer_references',
    EMPLOYER_PHONE_REFERENCES: 'request_employer_phone_references',
    EMPLOYMENT_VERIFICATION: 'request_employment_verification',
    MOTOR_VEHICLE_RECORDS: 'request_motor_vehicle_records',
    US_CRIMINAL_RECORD_CHECK_TIER_1: 'request_us_criminal_record_check_tier_1',
    US_CRIMINAL_RECORD_CHECK_TIER_2: 'request_us_criminal_record_check_tier_2',
    US_CRIMINAL_RECORD_CHECK_TIER_3: 'request_us_criminal_record_check_tier_3',
    ADDRESS_REFERENCES: 'request_address_references',
    ADDRESS_PHONE_REFERENCES: 'request_address_phone_references',
    INSTANT_VERIFY_EMPLOYMENT: 'request_instant_verify_employment',
    INSTANT_VERIFY_CREDENTIAL: 'request_instant_verify_credential',
    INSTANT_VERIFY_EDUCATION: 'request_instant_verify_education',
    SOQUIJ: 'request_soquij',
    VACCINATION_CHECK: 'request_vaccination_check',
    SOCIAL_MEDIA: 'request_social_media_check',
    AUSTRALIAN_NATIONAL_POLICE_CHECK: 'request_australian_national_police_check',
    INTERNATIONAL_FINANCIAL_CHECK: 'request_international_financial_check',
};

// Active Checks
export const ACTIVE_SERVICES = {
    HR_CA: [
        CHECK_REQUEST.BASE,
        CHECK_REQUEST.SOFTCHECK,
        CHECK_REQUEST.SOQUIJ,
        CHECK_REQUEST.EQUIFAX,
        CHECK_REQUEST.CRIMINAL_RECORD_CHECK,
        CHECK_REQUEST.ENHANCED_CRIMINAL_RECORD_CHECK,
        CHECK_REQUEST.VULNERABLE_SECTOR_CRIMINAL_RECORD_CHECK,
        CHECK_REQUEST.INTERNATIONAL_CRIMINAL_RECORD_CHECK,
        CHECK_REQUEST.IDENTITY_VERIFICATION,
        CHECK_REQUEST.ENHANCED_IDENTITY_VERIFICATION,
        CHECK_REQUEST.EDUCATION_VERIFICATION,
        CHECK_REQUEST.CREDENTIAL_VERIFICATION,
        CHECK_REQUEST.EMPLOYER_REFERENCES,
        CHECK_REQUEST.EMPLOYER_PHONE_REFERENCES,
        CHECK_REQUEST.EMPLOYMENT_VERIFICATION,
        CHECK_REQUEST.MOTOR_VEHICLE_RECORDS,
        CHECK_REQUEST.US_CRIMINAL_RECORD_CHECK_TIER_1,
        CHECK_REQUEST.US_CRIMINAL_RECORD_CHECK_TIER_2,
        CHECK_REQUEST.US_CRIMINAL_RECORD_CHECK_TIER_3,
        CHECK_REQUEST.INSTANT_VERIFY_EMPLOYMENT,
        CHECK_REQUEST.INSTANT_VERIFY_EDUCATION,
        CHECK_REQUEST.VACCINATION_CHECK,
        CHECK_REQUEST.SOCIAL_MEDIA,
    ],
    HR_US: [
        CHECK_REQUEST.BASE,
        CHECK_REQUEST.EQUIFAX,
        CHECK_REQUEST.US_CRIMINAL_RECORD_CHECK_TIER_1,
        CHECK_REQUEST.US_CRIMINAL_RECORD_CHECK_TIER_2,
        CHECK_REQUEST.US_CRIMINAL_RECORD_CHECK_TIER_3,
        CHECK_REQUEST.INTERNATIONAL_CRIMINAL_RECORD_CHECK,
        CHECK_REQUEST.ENHANCED_IDENTITY_VERIFICATION,
        CHECK_REQUEST.EDUCATION_VERIFICATION,
        CHECK_REQUEST.CREDENTIAL_VERIFICATION,
        CHECK_REQUEST.EMPLOYER_REFERENCES,
        CHECK_REQUEST.EMPLOYER_PHONE_REFERENCES,
        CHECK_REQUEST.EMPLOYMENT_VERIFICATION,
        CHECK_REQUEST.MOTOR_VEHICLE_RECORDS,
        CHECK_REQUEST.CRIMINAL_RECORD_CHECK,
        CHECK_REQUEST.ENHANCED_CRIMINAL_RECORD_CHECK,
        CHECK_REQUEST.VULNERABLE_SECTOR_CRIMINAL_RECORD_CHECK,
        CHECK_REQUEST.INSTANT_VERIFY_EMPLOYMENT,
        CHECK_REQUEST.INSTANT_VERIFY_EDUCATION,
        CHECK_REQUEST.SOCIAL_MEDIA,
    ],
    PM_CA: [
        CHECK_REQUEST.BASE,
        CHECK_REQUEST.SOFTCHECK,
        CHECK_REQUEST.EQUIFAX,
        CHECK_REQUEST.IDENTITY_VERIFICATION,
        CHECK_REQUEST.ENHANCED_IDENTITY_VERIFICATION,
        CHECK_REQUEST.EMPLOYER_REFERENCES,
        CHECK_REQUEST.EMPLOYER_PHONE_REFERENCES,
        CHECK_REQUEST.EMPLOYMENT_VERIFICATION,
        CHECK_REQUEST.ADDRESS_REFERENCES,
        CHECK_REQUEST.ADDRESS_PHONE_REFERENCES,
        CHECK_REQUEST.US_CRIMINAL_RECORD_CHECK_TIER_1,
        CHECK_REQUEST.SOCIAL_MEDIA,
    ],
    PM_US: [
        CHECK_REQUEST.BASE,
        CHECK_REQUEST.EQUIFAX,
        CHECK_REQUEST.US_CRIMINAL_RECORD_CHECK_TIER_1,
        CHECK_REQUEST.US_CRIMINAL_RECORD_CHECK_TIER_2,
        CHECK_REQUEST.US_CRIMINAL_RECORD_CHECK_TIER_3,
        CHECK_REQUEST.INTERNATIONAL_CRIMINAL_RECORD_CHECK,
        CHECK_REQUEST.ENHANCED_IDENTITY_VERIFICATION,
        CHECK_REQUEST.EMPLOYER_REFERENCES,
        CHECK_REQUEST.EMPLOYER_PHONE_REFERENCES,
        CHECK_REQUEST.EMPLOYMENT_VERIFICATION,
        CHECK_REQUEST.ADDRESS_REFERENCES,
        CHECK_REQUEST.ADDRESS_PHONE_REFERENCES,
        CHECK_REQUEST.CRIMINAL_RECORD_CHECK,
        CHECK_REQUEST.ENHANCED_CRIMINAL_RECORD_CHECK,
        CHECK_REQUEST.VULNERABLE_SECTOR_CRIMINAL_RECORD_CHECK,
        CHECK_REQUEST.SOCIAL_MEDIA,
    ],
    HR_AU: [
        CHECK_REQUEST.EDUCATION_VERIFICATION,
        CHECK_REQUEST.CREDENTIAL_VERIFICATION,
        CHECK_REQUEST.EMPLOYER_REFERENCES,
        CHECK_REQUEST.EMPLOYER_PHONE_REFERENCES,
        CHECK_REQUEST.EMPLOYMENT_VERIFICATION,
        CHECK_REQUEST.STANDARD_ACIC_CHECK,
        CHECK_REQUEST.VOLUNTEER_ACIC_CHECK,
        CHECK_REQUEST.RIGHT_TO_WORK,
        CHECK_REQUEST.INTERNATIONAL_CRIMINAL_RECORD_CHECK,
        CHECK_REQUEST.ENHANCED_IDENTITY_VERIFICATION, // OneID
    ],
    HR_GB: [
        CHECK_REQUEST.SOFTCHECK,
        CHECK_REQUEST.ENHANCED_IDENTITY_VERIFICATION,
        CHECK_REQUEST.INTERNATIONAL_CRIMINAL_RECORD_CHECK,
        CHECK_REQUEST.EMPLOYER_REFERENCES,
        CHECK_REQUEST.EMPLOYER_PHONE_REFERENCES,
        CHECK_REQUEST.EMPLOYMENT_VERIFICATION,
        CHECK_REQUEST.EDUCATION_VERIFICATION,
        CHECK_REQUEST.CREDENTIAL_VERIFICATION,
        CHECK_REQUEST.UK_BASIC_DBS_CHECK,
        CHECK_REQUEST.UK_BASIC_DS_CHECK,
        CHECK_REQUEST.UK_RIGHT_TO_WORK_CHECK,
    ],
};

// Checks that if only selected allow quick screen
export const QUICK_SCREEN_CHECKS_HR = [
    CHECK_REQUEST.BASE,
    CHECK_REQUEST.SOFTCHECK,
    CHECK_REQUEST.CRIMINAL_RECORD_CHECK,
    CHECK_REQUEST.ENHANCED_CRIMINAL_RECORD_CHECK,
    CHECK_REQUEST.SOQUIJ,
    CHECK_REQUEST.SOCIAL_MEDIA,
];

export const QUICK_SCREEN_CHECKS_WITH_ADDITIONS_HR = [
    ...QUICK_SCREEN_CHECKS_HR,
    CHECK_REQUEST.IDENTITY_VERIFICATION,
    CHECK_REQUEST.SOCIAL_MEDIA,
];

export const QUICK_SCREEN_CHECKS_PM = [
    CHECK_REQUEST.BASE,
    CHECK_REQUEST.SOFTCHECK,
    CHECK_REQUEST.EQUIFAX,
    CHECK_REQUEST.SOCIAL_MEDIA,
];
