// Libraries
import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect, useSelector } from 'react-redux'; // useDispatch in 7.1+ release
import { get } from 'lodash';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Form as AntdForm } from '@ant-design/compatible';
import { Popover, message } from 'antd';
import { intl } from 'components/GlobalProvider';
import { FormattedMessage } from 'react-intl';

// Components
import Form from 'certnd/Form';
import { ErrorAlertAPI } from 'certn-ui/ErrorAlert';
import ToggleIntegrationButton from './components/ToggleIntegrationButton';

// Modules
import Auth from 'modules/Auth';

// Actions & Selectors
import { getIntegrations } from 'views/manager/views/settings/SettingsSelectors';
import { enableWorkday, disableWorkday } from 'views/manager/views/settings/SettingsActions';
import { getTeam } from 'views/manager/views/admin/AdminSelectors';

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            enableWorkday,
            disableWorkday,
        },
        dispatch
    );

const Workday = (props) => {
    const [loading, setLoading] = useState(false);
    const integrations = useSelector((state) => getIntegrations(state));
    const teamId = useSelector((state) => getTeam(state).id);
    const superteamId = useSelector((state) => getTeam(state).superteam);
    const canEdit = Auth.isPermissionLevel('manager');

    // Workday
    const workdayActive = get(integrations, ['workday', 'is_active']);
    const workdayWSDL = get(integrations, ['workday', 'wsdl']);

    const handleSubmit = () => {
        props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                setLoading(true);
                props
                    .enableWorkday({ teamId, superteamId, data: values })
                    .then(() => {
                        setLoading(false);
                        message.success(
                            intl.formatMessage({
                                id: 'e08d0.Workday.Connected',
                                defaultMessage: 'Integration Connected',
                            })
                        );
                    })
                    .catch((error) => {
                        setLoading(false);
                        ErrorAlertAPI(error);
                    });
            }
        });
    };

    return (
        <AntdForm
            onSubmit={(e) => {
                e.preventDefault();
                if (workdayActive) return props.disableWorkday({ teamId, superteamId });
                handleSubmit();
            }}
        >
            <Form.Block>
                {!workdayActive && (
                    <>
                        <Form.Input
                            form={props.form}
                            fieldName="username"
                            title={intl.formatMessage({ id: 'common.username', defaultMessage: 'Username' })}
                            addonBefore={
                                <Popover
                                    content={intl.formatMessage({
                                        id: 'e08d0.Workday.UsernamePopover',
                                        defaultMessage: 'Example: username@tenant_name',
                                    })}
                                >
                                    <InfoCircleOutlined />
                                </Popover>
                            }
                            options={{
                                rules: [
                                    {
                                        required: true,
                                        error: intl.formatMessage({
                                            id: 'e08d0.Workday.UsernamePopoverValidation',
                                            defaultMessage: 'Please enter your Username',
                                        }),
                                    },
                                ],
                            }}
                        />

                        <Form.Password
                            form={props.form}
                            fieldName="password"
                            title={intl.formatMessage({ id: 'common.password', defaultMessage: 'Password' })}
                            options={{
                                rules: [
                                    {
                                        required: true,
                                        error: intl.formatMessage({
                                            id: 'e08d0.Workday.PasswordValid',
                                            defaultMessage: 'Please enter your Password',
                                        }),
                                    },
                                ],
                            }}
                        />

                        <Form.Input
                            form={props.form}
                            fieldName="sso_subdomain"
                            title={intl.formatMessage({
                                id: 'e08d0.Workday.SSOSubdomain',
                                defaultMessage: 'Single Sign-On Subdomain',
                            })}
                        />

                        <Form.TextArea
                            form={props.form}
                            fieldName="private_key"
                            title={intl.formatMessage({
                                id: 'e08d0.Workday.PrivateKey',
                                defaultMessage: 'Private key',
                            })}
                        />
                        <Form.TextArea
                            form={props.form}
                            fieldName="public_key"
                            title={intl.formatMessage({ id: 'e08d0.Workday.PublicKey', defaultMessage: 'Public key' })}
                        />

                        <Form.Input
                            form={props.form}
                            fieldName="equifax_member_number"
                            title={intl.formatMessage({
                                id: 'e08d0.Workday.EquifaxMemberNumber',
                                defaultMessage: 'Equifax Member Number',
                            })}
                        />

                        <Form.Input
                            form={props.form}
                            fieldName="equifax_security_code"
                            title={intl.formatMessage({
                                id: 'e08d0.Workday.EquifaxSecurityCode',
                                defaultMessage: 'Equifax Security Code',
                            })}
                        />
                    </>
                )}
                <Form.Input
                    form={props.form}
                    fieldName="wsdl"
                    title={intl.formatMessage({ id: 'e08d0.Workday.Endpoint', defaultMessage: 'Endpoint URL' })}
                    addonBefore={
                        <Popover
                            content={
                                <FormattedMessage
                                    id="e08d0.Workday.EndpointPopover"
                                    defaultMessage="Example: https://{server_name}.workday.com/ccx/service/{tenant_name}/Recruiting/v33.0?wsdl"
                                />
                            }
                        >
                            <InfoCircleOutlined />
                        </Popover>
                    }
                    autoComplete={workdayActive ? 'off' : 'on'}
                    disabled={workdayActive}
                    options={{
                        initialValue: workdayWSDL,
                        rules: [
                            {
                                required: true,
                                error: intl.formatMessage({
                                    id: 'e08d0.Workday.EndpointValid',
                                    defaultMessage: 'Please enter the Endpoint URL',
                                }),
                            },
                        ],
                    }}
                />
                <ToggleIntegrationButton canEdit={canEdit} loading={loading} active={workdayActive} />
            </Form.Block>
        </AntdForm>
    );
};

export default AntdForm.create()(connect(null, mapDispatchToProps)(Workday));
