// Libraries
import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

// Components
import SummaryHeader from './SummaryHeader';
import SummaryItem from './SummaryItem';
import { FlexRow, GridRow, SubTitleWrapper, SummaryContainer, SummarySection } from './SummaryStyles';

// Actions & Selectors
import { ApplicantName } from 'types';
import { getApplicantAccount, getInformation, getApplicant } from 'views/welcome/WelcomeSelectors';
import { CHECK_REQUEST } from 'base/BaseConstants';

const NameRow = ({ children, isUkCheck }: { children: ReactNode; isUkCheck: boolean }) =>
    isUkCheck ? <GridRow gridItems={4}>{children}</GridRow> : <FlexRow>{children}</FlexRow>;

const nameFields = (isUkCheck: boolean, isDbsCheck: boolean, name: ApplicantName) =>
    isUkCheck ? (
        isDbsCheck ? (
            <>
                <SummaryItem
                    title={<FormattedMessage id="welcome.Summary.firstName" defaultMessage="First Name" />}
                    text={name.first_name}
                    grow
                />
                {name.middle_name && (
                    <SummaryItem
                        title={<FormattedMessage id="welcome.Summary.middleName" defaultMessage="Middle Name" />}
                        text={name.middle_name}
                        grow
                    />
                )}
            </>
        ) : (
            <SummaryItem
                title={<FormattedMessage id="welcome.Summary.forenames" defaultMessage="Forename(s)" />}
                text={name.first_name}
                grow
            />
        )
    ) : (
        <SummaryItem
            title={<FormattedMessage id="welcome.Summary.givenName" defaultMessage="Given Name(s)" />}
            text={`${name.first_name} ${name.middle_name ? `${name.middle_name} ` : ''}`}
            grow
        />
    );

interface Props {
    applicantNames: ApplicantName[];
    goToTrack: (track: string) => void;
}

const NewBasics = ({ applicantNames, goToTrack }: Props) => {
    const applicantEmail = useSelector(getApplicantAccount)?.email;
    const applicantPhone = useSelector(getInformation)?.phone_number;
    const personalDetails = applicantNames.filter((applicantName) => applicantName.type === 'PRIMARY');
    const previousNames = applicantNames.filter((applicantName) => applicantName.type !== 'PRIMARY');
    const applicant = useSelector(getApplicant);
    const isUkCheck =
        applicant && (applicant[CHECK_REQUEST.UK_BASIC_DBS_CHECK] || applicant[CHECK_REQUEST.UK_BASIC_DS_CHECK]);
    const isDbsCheck = applicant && applicant[CHECK_REQUEST.UK_BASIC_DBS_CHECK];

    return (
        <SummaryContainer>
            <SummaryHeader
                title={<FormattedMessage id="welcome.general.basicInformation" defaultMessage="Basic Information" />}
                onClick={goToTrack}
            />
            <SummarySection>
                <SubTitleWrapper>
                    <FormattedMessage id="welcome.Summary.personalDetails" defaultMessage="PERSONAL DETAILS" />
                </SubTitleWrapper>
                {personalDetails.map((name, i) => (
                    <React.Fragment key={i}>
                        <NameRow isUkCheck={isUkCheck}>
                            {isUkCheck && (
                                <SummaryItem
                                    title={<FormattedMessage id="common.Basics.title_name" defaultMessage="Title" />}
                                    text={name.other_title || name.title_display}
                                />
                            )}
                            {nameFields(isUkCheck, isDbsCheck, name)}
                            <SummaryItem
                                title={<FormattedMessage id="welcome.Summary.surname-en-AU" defaultMessage="Surname" />}
                                text={name.last_name}
                                grow
                            />
                        </NameRow>
                    </React.Fragment>
                ))}
                {previousNames.map((name, i) => (
                    <React.Fragment key={i}>
                        <SubTitleWrapper>
                            <FormattedMessage id="welcome.Summary.previousName" defaultMessage="PREVIOUS NAME" />
                            {isUkCheck && ` ${i + 1}`}
                        </SubTitleWrapper>
                        <NameRow isUkCheck={isUkCheck}>
                            {nameFields(isUkCheck, isDbsCheck, name)}
                            <SummaryItem
                                title={<FormattedMessage id="welcome.Summary.surname-en-AU" defaultMessage="Surname" />}
                                text={name.last_name}
                                grow
                            />
                            {isDbsCheck && (
                                <SummaryItem
                                    title={<FormattedMessage id="welcome.Summary.endDate" defaultMessage="End Date" />}
                                    text={name.name_end_date}
                                />
                            )}
                        </NameRow>
                    </React.Fragment>
                ))}

                <SubTitleWrapper>
                    <FormattedMessage id="welcome.Summary.contactDetails" defaultMessage="CONTACT DETAILS" />
                </SubTitleWrapper>

                <FlexRow>
                    <SummaryItem
                        title={<FormattedMessage id="common.email" defaultMessage="Email" />}
                        text={applicantEmail}
                        grow
                    />
                    <SummaryItem
                        title={<FormattedMessage id="welcome.general.phoneNumber" defaultMessage="Phone Number" />}
                        text={applicantPhone}
                        grow
                    />
                </FlexRow>
            </SummarySection>
        </SummaryContainer>
    );
};

export default NewBasics;
