// Libraries
import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

// Components
import { TrackNavigation } from 'views/welcome/components';
import Title from 'certn-ui/Title';
import { AppearRight } from 'certn-ui/Animate';
import { FaqLink } from 'certn-ui';

const ConsentText = styled.div`
    text-align: left;
    width: 450px;
    margin: auto;
    @media (max-width: ${({ theme }) => theme.width.tablet}) {
        width: 100%;
    }
`;

const propTypes = {
    // Parent Props
    acceptConsent: PropTypes.func.isRequired,
};

const ConsentForm = ({ acceptConsent }) => (
    <AppearRight>
        <Title>
            <FormattedMessage
                id="welcome.MyCRCConsent.consentTitle"
                defaultMessage="You are about to start a criminal record check. What does this mean?"
            />
        </Title>
        <ConsentText>
            <FormattedMessage
                id="welcome.MyCRCConsent.consentDisclaimer"
                defaultMessage="{li} You verify that you are at least 18 years of age {nl} {li} You consent to the search of the RCMP National Repository of Criminal Records {nl} {li} You understand that MyCRC searches for criminal records based on the personal information you provide. If a match is found, by law we cannot show criminal record details, including conviction information. {nl} {li} You recognize that this is not a Vulnerable Sector Check. {nl} {li} You acknowledge that MyCRC is a third party background check provider.  {nl} {li} You acknowledge that MyCRC checks may not be suitable for immigration and VISA related purposes. {br} {br} {br}"
                values={{
                    nl: <p />,
                    br: <br />,
                    li: '\u2022',
                }}
            />
        </ConsentText>
        <ConsentText style={{ marginBottom: '-15px' }}>
            <FormattedMessage
                id="welcome.MyCRCConsent.consentEndText"
                defaultMessage="Have more questions? {faq}, or jump straight in."
                values={{
                    faq: <FaqLink />,
                }}
            />
        </ConsentText>
        <TrackNavigation
            handleSubmit={acceptConsent}
            nextButtonTitle={
                <FormattedMessage id="welcome.myCRCConsent.yesIConsent" defaultMessage="Start my background check" />
            }
        />
    </AppearRight>
);

ConsentForm.propTypes = propTypes;

export default injectIntl(ConsentForm);
