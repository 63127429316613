import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Row, Subtitle } from '../styled';
import { AdjustedInput, ResponsiveButton } from './styled';
import styles from 'styles/styles';
import { message, notification } from 'antd';
import { Regex } from 'modules';
import { UserApplicant } from 'types';
import { ErrorAlertAPI } from 'certn-ui/ErrorAlert';
import { postRequest } from 'utils/http';

type ShareReportProps = {
    applicant: UserApplicant;
};

export const ShareReport: React.FC<ShareReportProps> = ({ applicant }) => {
    const intl = useIntl();
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSendReport = async () => {
        if (!email) {
            return message.error(
                intl.formatMessage({
                    id: 'error.validation.email.required',
                    defaultMessage: 'Please input your E-mail!',
                })
            );
        }
        if (!RegExp(Regex.email).test(email)) {
            return message.error(
                intl.formatMessage({
                    id: 'error.validation.email.invalid',
                    defaultMessage: 'The input is not a valid E-mail!',
                })
            );
        }
        setLoading(true);
        try {
            await postRequest({
                hr: true,
                version: 'v1',
                endpoint: `reports/${applicant.id}/send`,
                body: JSON.stringify({ email }),
                applicantLogin: true,
            });

            notification.success({
                message: intl.formatMessage({
                    id: 'message.reportShared',
                    defaultMessage: 'Report Shared',
                }),
                description:
                    intl.formatMessage({
                        id: 'message.reportSharedWith',
                        defaultMessage: 'Your report has been shared with ',
                    }) + email,
            });
        } catch (error) {
            ErrorAlertAPI(error);
        }
        setLoading(false);
    };

    return (
        <Row>
            <div style={{ width: '100%' }}>
                <Subtitle>
                    <FormattedMessage id="applicant.Review.shareReport" defaultMessage="Share my report" />
                </Subtitle>
                <Row>
                    <AdjustedInput
                        size="large"
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder={intl.formatMessage({
                            id: 'common.enterEmailAddress',
                            defaultMessage: 'Enter email address',
                        })}
                    />
                    <ResponsiveButton
                        size="large"
                        type="primary"
                        style={{ color: styles.color.secondary }}
                        loading={loading}
                        certncolor="certnMyCRCBrand"
                        onClick={handleSendReport}
                    >
                        <FormattedMessage id="applicant.Review.send" defaultMessage="Send" />
                    </ResponsiveButton>
                </Row>
            </div>
        </Row>
    );
};
