import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { LockOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';

const CustomButton = styled(Button)`
    min-width: 60px !important;
    height: 30px !important;
    border-radius: initial !important;
`;

const propTypes = {
    disabled: PropTypes.bool,
    itemSelected: PropTypes.func.isRequired,
    addItem: PropTypes.func.isRequired,
    removeItem: PropTypes.func.isRequired,
};

const defaultProps = {
    disabled: false,
};

const FUFAddRemoveItem = ({ disabled, itemSelected, addItem, removeItem }) => {
    const renderButtonChildren = () => {
        if (disabled) return <LockOutlined />;
        if (itemSelected) return <FormattedMessage id="common.remove" defaultMessage="Remove" />;
        return <FormattedMessage id="common.add" defaultMessage="Add" />;
    };

    const handleOnClick = () => {
        if (itemSelected) removeItem();
        else addItem();
    };

    return (
        <CustomButton disabled={disabled} onClick={handleOnClick} type={itemSelected ? 'danger' : 'primary'}>
            {renderButtonChildren()}
        </CustomButton>
    );
};

FUFAddRemoveItem.propTypes = propTypes;
FUFAddRemoveItem.defaultProps = defaultProps;

export default FUFAddRemoveItem;
