// Libraries
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Auth from 'modules/Auth';
import { injectIntl, FormattedMessage } from 'react-intl';

// UI Components
import Form from 'certnd/Form';
import Typography from 'certnd/Typography';

const { Heading } = Typography;

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

// eslint-disable-next-line
class General extends React.Component {
    render() {
        const { form, settings } = this.props;

        const canEdit = Auth.isPermissionLevel('manager');

        return (
            <Form.Block>
                <Heading.H3>
                    <FormattedMessage id="b280f.General.PageTitle" defaultMessage="General" />
                </Heading.H3>

                <Form.Checkbox
                    form={form}
                    fieldName="emergency_contact_req"
                    title={this.props.intl.formatMessage({
                        id: 'b280f.General.EmergencyTitle',
                        defaultMessage: 'Emergency Contact Required',
                    })}
                    description={this.props.intl.formatMessage({
                        id: 'b280f.General.EmergencyDescription',
                        defaultMessage: 'Require applicant to enter an emergency contact.',
                    })}
                    options={{ initialValue: settings.emergency_contact_req }}
                    disabled={!canEdit}
                />

                <Form.Button disabled={!canEdit}>
                    <FormattedMessage id="common.save" defaultMessage="Save" />
                </Form.Button>
            </Form.Block>
        );
    }
}

export default connect(undefined, mapDispatchToProps)(injectIntl(General));
