// Libraries
import React from 'react';
import styled from 'styled-components/macro';

// React Router & Routes
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';

// UI Components
import { LayoutBody } from 'certn-ui/Layout';
import RouteErrorBoundary from 'certn-ui/RouteErrorBoundary';

// Views
import NotificationsList from './components/NotificationsList';

const SettingsWrapper = styled.div`
    display: flex;
`;

class Notifications extends React.Component {
    render() {
        return (
            <>
                <LayoutBody>
                    <RouteErrorBoundary>
                        <SettingsWrapper>
                            <Switch>
                                <Route path="/notifications/list" component={NotificationsList} />
                                <Redirect to="/settings" />
                            </Switch>
                        </SettingsWrapper>
                    </RouteErrorBoundary>
                </LayoutBody>
            </>
        );
    }
}

export default withRouter(Notifications);
