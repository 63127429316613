import styled from 'styled-components';

export const PDFIconWithText = styled.div`
    display: flex;

    svg {
        margin-right: 24px;
    }

    ${(props) => props.xs && 'justify-content: center;'}
`;

export const DocSigningWrapper = styled.div`
    border: 1.5px solid ${({ theme }) => theme.color.certnGray100};
    padding: 16px;
`;

export const DocumentSignButton = styled.button`
    text-align: left;
    display: flex;
    justify-content: flex-end;
    color: ${({ theme }) => theme.color.certnGreen700};
    cursor: pointer;
    margin-left: auto;
    background: none;
    border: none;

    &:focus {
        outline: none;
    }

    &:disabled {
        color: ${({ theme }) => theme.color.certnLightGray1};
    }

    @media (max-width: 576px) {
        text-align: center;
        margin: auto;
        padding-top: 12px;
    }
`;
