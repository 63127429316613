import React from 'react';
import { Form } from '@ant-design/compatible';
import { Input, Select } from 'antd';
import { injectIntl, FormattedMessage } from 'react-intl';

const DateForm = ({ label, formItemLayout, getFieldDecorator, YMD, date, birthday, intl }) => (
    <Form.Item {...formItemLayout} label={label}>
        <Input.Group compact>
            {getFieldDecorator(YMD.year, {
                initialValue: date ? date.year : undefined,
                rules: [
                    {
                        required: true,
                        message: <FormattedMessage id="103b4.DateForm.yearSelect" defaultMessage="Select a year!" />,
                    },
                ],
            })(
                <Select
                    showSearch
                    style={{ width: '33%' }}
                    placeholder={
                        birthday ? (
                            <FormattedMessage id="103b4.DateForm.birthYear" defaultMessage="Birth Year" />
                        ) : (
                            <FormattedMessage id="103b4.DateForm.year" defaultMessage="Year" />
                        )
                    }
                >
                    {Array.from(new Array(118), (val, index) => 2020 - index).map((value) => (
                        <Select.Option key={`year-${value}`} value={value}>
                            {value}
                        </Select.Option>
                    ))}
                </Select>
            )}
            {getFieldDecorator(YMD.month, {
                initialValue: date ? date.month : undefined,
                rules: [
                    {
                        required: true,
                        message: <FormattedMessage id="103b4.DateForm.monthSelect" defaultMessage="Select a month!" />,
                    },
                ],
            })(
                <Select
                    showSearch
                    style={{ width: '33%' }}
                    placeholder={intl.formatMessage({ id: '103b4.DateForm.month', defaultMessage: 'Month' })}
                >
                    <Select.Option value="January">
                        <FormattedMessage id="103b4.DateForm.January" defaultMessage="January" />
                    </Select.Option>
                    <Select.Option value="February">
                        <FormattedMessage id="103b4.DateForm.February" defaultMessage="February" />
                    </Select.Option>
                    <Select.Option value="March">
                        <FormattedMessage id="103b4.DateForm.March" defaultMessage="March" />
                    </Select.Option>
                    <Select.Option value="April">
                        <FormattedMessage id="103b4.DateForm.April" defaultMessage="April" />
                    </Select.Option>
                    <Select.Option value="May">
                        <FormattedMessage id="103b4.DateForm.May" defaultMessage="May" />
                    </Select.Option>
                    <Select.Option value="June">
                        <FormattedMessage id="103b4.DateForm.June" defaultMessage="June" />
                    </Select.Option>
                    <Select.Option value="July">
                        <FormattedMessage id="103b4.DateForm.July" defaultMessage="July" />
                    </Select.Option>
                    <Select.Option value="August">
                        <FormattedMessage id="103b4.DateForm.August" defaultMessage="August" />
                    </Select.Option>
                    <Select.Option value="September">
                        <FormattedMessage id="103b4.DateForm.September" defaultMessage="September" />
                    </Select.Option>
                    <Select.Option value="October">
                        <FormattedMessage id="103b4.DateForm.October" defaultMessage="October" />
                    </Select.Option>
                    <Select.Option value="November">
                        <FormattedMessage id="103b4.DateForm.November" defaultMessage="November" />
                    </Select.Option>
                    <Select.Option value="December">
                        <FormattedMessage id="103b4.DateForm.December" defaultMessage="December" />
                    </Select.Option>
                </Select>
            )}
            {getFieldDecorator(YMD.day, {
                initialValue: date ? date.day : undefined,
                rules: [
                    {
                        required: true,
                        message: <FormattedMessage id="103b4.DateForm.daySelect" defaultMessage="Select a day!" />,
                    },
                ],
            })(
                <Select
                    showSearch
                    style={{ width: '33%' }}
                    placeholder={intl.formatMessage({ id: '103b4.DateForm.day', defaultMessage: 'Day' })}
                >
                    {Array.from(new Array(31), (val, index) => index + 1).map((value) => (
                        <Select.Option key={`day-${value}`} value={value}>
                            {value}
                        </Select.Option>
                    ))}
                </Select>
            )}
        </Input.Group>
    </Form.Item>
);

export default injectIntl(DateForm);
