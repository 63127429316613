// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import { Input, Button, Tooltip } from 'antd';
import { useDispatch } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';

// Components
import { ButtonLine } from 'certn-ui/Button';
import AddressAutoComplete from 'certn-form/AddressAutoComplete';

// Modules
import { Regex } from 'modules';

// Actions
import { updateAddressError } from 'base/BaseActions';

const propTypes = {
    // Parent Props
    onSubmit: PropTypes.func.isRequired,
    property: PropTypes.object,
};

const defaultProps = {
    property: {},
};

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 14 },
    },
};

const PropertiesForm = ({ onSubmit, property, form }) => {
    const { getFieldDecorator } = form;
    const intl = useIntl();
    const dispatch = useDispatch();
    const { webFeatureRequireCurrentAndPositionPropertyAddressesForOnboarding } = useFlags();
    const handleSubmit = (e) => {
        e.preventDefault();
        form.validateFieldsAndScroll((err, values) => {
            if (!err && values) {
                onSubmit(values);
                dispatch(updateAddressError(false));
            }
            // TODO: [POTUS-180] Remove Feature Flag.
            else if (
                values?.country === 'US' &&
                !values?.county &&
                !webFeatureRequireCurrentAndPositionPropertyAddressesForOnboarding
            ) {
                onSubmit(values);
                dispatch(updateAddressError(false));
            } else {
                dispatch(updateAddressError(true));
            }
        });
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Item {...formItemLayout} label="Building Name">
                {getFieldDecorator('building', {
                    initialValue: property.building || null,
                    rules: [
                        {
                            type: 'string', // FIXME: Add custom validation for name
                            message: 'The input is not a valid building name!',
                        },
                    ],
                })(<Input type="text" />)}
            </Form.Item>
            <Form.Item
                {...formItemLayout}
                label={
                    <span>
                        Building Code &nbsp;
                        <Tooltip title="A unique identifier for the property">
                            <QuestionCircleOutlined />
                        </Tooltip>
                    </span>
                }
            >
                {getFieldDecorator('building_code', {
                    initialValue: property.building_code || null,
                    rules: [
                        {
                            type: 'string', // FIXME: Add custom validation for name
                            message: 'The input is not a valid building code!',
                        },
                    ],
                })(<Input type="text" />)}
            </Form.Item>
            <Form.Item
                {...formItemLayout}
                label={
                    <span>
                        Specify a custom link?&nbsp;
                        <Tooltip title="This customizes the link you can send out to applicants (ie: certn.co/apply/custom-link). It will be generated automatically if you do not specify something.">
                            <QuestionCircleOutlined />
                        </Tooltip>
                    </span>
                }
            >
                {getFieldDecorator('url_code', {
                    initialValue: property.url_code || null,
                    rules: [
                        {
                            pattern: Regex.urlCode,
                            message: intl.formatMessage({
                                id: 'error.validation.urlCode',
                                defaultMessage: 'Please use only numbers, letters, dashes, and underscores',
                            }),
                        },
                        {
                            min: 6,
                            message: intl.formatMessage({
                                id: 'error.validation.6char',
                                defaultMessage: 'URL code must be at least 6 characters long',
                            }),
                        },
                    ],
                })(<Input type="text" placeholder="(ie: 432-park-avenue)" />)}
            </Form.Item>
            <Form.Item {...formItemLayout} label="Address Lookup">
                <AddressAutoComplete
                    getFieldDecorator={getFieldDecorator}
                    form={form}
                    currentAddress={property}
                    hideLabels
                    canadaUSOnly
                />
            </Form.Item>
            <Form.Item>
                <ButtonLine>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </ButtonLine>
            </Form.Item>
        </Form>
    );
};

PropertiesForm.propTypes = propTypes;
PropertiesForm.defaultProps = defaultProps;

export default Form.create()(PropertiesForm);
