// Libraries
import React from 'react';

// React Router & Routes
import { Switch, Route, Redirect } from 'react-router-dom';
import PropertiesList from './views/propertiesList/PropertiesList';
import PropertiesNew from './views/propertiesNew/PropertiesNew';
import PropertiesEdit from './views/propertiesEdit/PropertiesEdit';

class Properties extends React.Component {
    render() {
        return (
            <Switch>
                <Route exact path="/pm/properties/new" component={PropertiesNew} />
                <Route exact path="/pm/properties/:propertyId/edit" component={PropertiesEdit} />
                <Route exact path="/pm/properties" component={PropertiesList} />
                <Redirect to="/pm/properties" />
            </Switch>
        );
    }
}

export default Properties;
