import { CountryCode } from 'countries-and-timezones';

export const HUBSPOT_TRACKING_ORG_ID = process.env.REACT_APP_HUBSPOT_TRACKING_ORG_ID;

export const HUBSPOT_BUSINESS_UNIT_IDS: Partial<Record<CountryCode, string>> = {
    US: process.env.REACT_APP_US_HUBSPOT_BUSINESS_UNIT_ID,
    GB: process.env.REACT_APP_GB_HUBSPOT_BUSINESS_UNIT_ID,
    AU: process.env.REACT_APP_AU_HUBSPOT_BUSINESS_UNIT_ID,
    CA: process.env.REACT_APP_CA_HUBSPOT_BUSINESS_UNIT_ID,
};
