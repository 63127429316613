import moment from 'moment';

import {
    NAMESPACE as PARENT_NAMESPACE,
    STATEPATH as PARENT_STATEPATH,
} from 'views/manager/views/hr/HrManagerConstants';

import { getRequest, patchRequest, postRequest, putRequest, deleteRequest } from 'utils/http';
import Constants from 'utils/constants';
import { uniqueEmails } from 'utils/unique-emails';
import { checkApplicantStatus, checkStatus, parseResponse } from 'modules/Requests';
import { simpleStringifyJSON, getDateTimeFormat } from 'modules/Format';
import { downloadFile, buildApplicationFileName } from 'modules/files';
import { handleBatchError } from 'modules';

// Modules
import { ErrorAlertAPI } from 'certn-ui/ErrorAlert';

import {
    getApplicationsView,
    getSearchBy,
    getOrdering,
    getFilterByEnabled,
    getPageSize,
    getReverseOrder,
    getCurrentPage,
    getApplications,
    getFilterQuery,
} from './ApplicationsSelectors';
import { getLaunchDarklyFlags } from 'base/BaseSelectors';

// Constants
export const NAMESPACE = `${PARENT_NAMESPACE}APPLICATIONS/`;
export const STATEPATH = `${PARENT_STATEPATH}.applications`;
import { CHECK_REQUEST } from 'base/BaseConstants';

// Actions Types
export const POST_REPORT_SUMMARY_REQ = `${NAMESPACE}POST_REPORT_SUMMARY_REQ`;
export const POST_REPORT_SUMMARY_RES = `${NAMESPACE}POST_REPORT_SUMMARY_RES`;
export const POST_REPORT_SUMMARY_ERR = `${NAMESPACE}POST_REPORT_SUMMARY_ERR`;
export const GET_APPLICATIONS_REQ = `${NAMESPACE}GET_APPLICATIONS_REQ`;
export const GET_APPLICATIONS_RES = `${NAMESPACE}GET_APPLICATIONS_RES`;
export const GET_APPLICATIONS_ERR = `${NAMESPACE}GET_APPLICATIONS_ERR`;
export const GET_APPLICATION_REQ = `${NAMESPACE}GET_APPLICATION_REQ`;
export const GET_APPLICATION_RES = `${NAMESPACE}GET_APPLICATION_RES`;
export const GET_APPLICATION_ERR = `${NAMESPACE}GET_APPLICATION_ERR`;
export const SILENT_PUT_APPLICATIONS_REQ = `${NAMESPACE}SILENT_PUT_APPLICATIONS_REQ`;
export const SILENT_PUT_APPLICANTS_REQ = `${NAMESPACE}SILENT_PUT_APPLICANTS_REQ`;
export const PUT_APPLICATIONS_REQ = `${NAMESPACE}PUT_APPLICATIONS_REQ`;
export const PUT_APPLICATIONS_RES = `${NAMESPACE}PUT_APPLICATIONS_RES`;
export const PUT_APPLICATIONS_ERR = `${NAMESPACE}PUT_APPLICATIONS_ERR`;
export const PUT_PACKAGES_REQ = `${NAMESPACE}PUT_PACKAGES_REQ`;
export const PUT_PACKAGES_RES = `${NAMESPACE}PUT_PACKAGES_RES`;
export const PUT_PACKAGES_ERR = `${NAMESPACE}PUT_PACKAGES_ERR`;
export const POST_APPLICATIONS_QUICK_REQ = `${NAMESPACE}POST_APPLICATIONS_QUICK_REQ`;
export const POST_APPLICATIONS_QUICK_RES = `${NAMESPACE}POST_APPLICATIONS_QUICK_RES`;
export const POST_APPLICATIONS_QUICK_ERR = `${NAMESPACE}POST_APPLICATIONS_QUICK_ERR`;
export const POST_APPLICATIONS_SEND_REQ = `${NAMESPACE}POST_APPLICATIONS_SEND_REQ`;
export const POST_APPLICATIONS_SEND_RES = `${NAMESPACE}POST_APPLICATIONS_SEND_RES`;
export const POST_APPLICATIONS_SEND_ERR = `${NAMESPACE}POST_APPLICATIONS_SEND_ERR`;
export const DEL_APPLICATIONS_REQ = `${NAMESPACE}DEL_APPLICATIONS_REQ`;
export const DEL_APPLICATIONS_RES = `${NAMESPACE}DEL_APPLICATIONS_RES`;
export const DEL_APPLICATIONS_ERR = `${NAMESPACE}DEL_APPLICATIONS_ERR`;
export const GET_REPORT_REQ = `${NAMESPACE}GET_REPORT_REQ`;
export const GET_REPORT_RES = `${NAMESPACE}GET_REPORT_RES`;
export const GET_REPORT_ERR = `${NAMESPACE}GET_REPORT_ERR`;
export const GET_REPORT_PDF_REQ = `${NAMESPACE}GET_REPORT_PDF_REQ`;
export const GET_REPORT_PDF_RES = `${NAMESPACE}GET_REPORT_PDF_RES`;
export const GET_REPORT_PDF_ERR = `${NAMESPACE}GET_REPORT_PDF_ERR`;
export const GET_BULK_REPORTS_PDF_REQ = `${NAMESPACE}GET_BULK_REPORTS_PDF_REQ`;
export const GET_BULK_REPORTS_PDF_RES = `${NAMESPACE}GET_BULK_REPORTS_PDF_RES`;
export const GET_BULK_REPORTS_PDF_ERR = `${NAMESPACE}GET_BULK_REPORTS_PDF_ERR`;
export const POST_BUY_EQUIFAX_REQ = `${NAMESPACE}POST_BUY_EQUIFAX_REQ`;
export const POST_BUY_EQUIFAX_RES = `${NAMESPACE}POST_BUY_EQUIFAX_RES`;
export const POST_BUY_EQUIFAX_ERR = `${NAMESPACE}POST_BUY_EQUIFAX_ERR`;
export const GET_REPORT_CSV_REQ = `${NAMESPACE}GET_REPORT_CSV_REQ`;
export const GET_REPORT_CSV_RES = `${NAMESPACE}GET_REPORT_CSV_RES`;
export const GET_REPORT_CSV_ERR = `${NAMESPACE}GET_REPORT_CSV_ERR`;
export const GET_BULK_REPORT_CSV_REQ = `${NAMESPACE}GET_BULK_REPORT_CSV_REQ`;
export const GET_BULK_REPORT_CSV_RES = `${NAMESPACE}GET_BULK_REPORT_CSV_RES`;
export const GET_BULK_REPORT_CSV_ERR = `${NAMESPACE}GET_BULK_REPORT_CSV_ERR`;
export const PATCH_NOTES_REQ = `${NAMESPACE}PATCH_NOTES_REQ`;
export const PATCH_NOTES_RES = `${NAMESPACE}PATCH_NOTES_RES`;
export const PATCH_NOTES_ERR = `${NAMESPACE}PATCH_NOTES_ERR`;
export const POST_COMMENTS_REQ = `${NAMESPACE}POST_COMMENTS_REQ`;
export const POST_COMMENTS_RES = `${NAMESPACE}POST_COMMENTS_RES`;
export const POST_COMMENTS_ERR = `${NAMESPACE}POST_COMMENTS_ERR`;
export const SEND_REMINDER_REQ = `${NAMESPACE}SEND_REMINDER_REQ`;
export const SEND_REMINDER_RES = `${NAMESPACE}SEND_REMINDER_RES`;
export const SEND_REMINDER_ERR = `${NAMESPACE}SEND_REMINDER_ERR`;
export const DELETE_APPLICATIONS_REQ = `${NAMESPACE}DELETE_APPLICATIONS_REQ`;
export const DELETE_APPLICATIONS_RES = `${NAMESPACE}DELETE_APPLICATIONS_RES`;
export const DELETE_APPLICATIONS_ERR = `${NAMESPACE}DELETE_APPLICATIONS_ERR`;
export const POST_NOTIFICATIONS_CONTACTS_REQ = `${NAMESPACE}POST_NOTIFICATIONS_CONTACTS_REQ`;
export const POST_NOTIFICATIONS_CONTACTS_RES = `${NAMESPACE}POST_NOTIFICATIONS_CONTACTS_RES`;
export const POST_NOTIFICATIONS_CONTACTS_ERR = `${NAMESPACE}POST_NOTIFICATIONS_CONTACTS_ERR`;
export const PATCH_EXPIRED_APPLICANT_RENEWAL_REQ = `${NAMESPACE}PATCH_EXPIRED_APPLICANT_RENEWAL_REQ`;
export const PATCH_EXPIRED_APPLICANT_RENEWAL_RES = `${NAMESPACE}PATCH_EXPIRED_APPLICANT_RENEWAL_RES`;
export const PATCH_EXPIRED_APPLICANT_RENEWAL_ERR = `${NAMESPACE}PATCH_EXPIRED_APPLICANT_RENEWAL_ERR`;
export const FETCH_FILTERED_APPLICATIONS_ERR = `${NAMESPACE}FETCH_FILTERED_APPLICATIONS_ERR`;

// List Actions Types
export const SET_APPLICATIONS_VIEW = `${NAMESPACE}SET_APPLICATIONS_VIEW`;
export const SET_SEARCH_BY = `${NAMESPACE}SET_SEARCH_BY`;
export const SET_ORDERING = `${NAMESPACE}SET_ORDERING`;
export const SET_FILTER_BY = `${NAMESPACE}SET_FILTER_BY`;
export const SET_PAGE_SIZE = `${NAMESPACE}SET_PAGE_SIZE`;
export const CLEAR_FILTERS = `${NAMESPACE}CLEAR_FILTERS`;
export const RESET_LIST = `${NAMESPACE}RESET_LIST`;

// Action Creators
export const fetchApplications = (page = null, silent = false) => (dispatch, getState) => {
    // TODO: Put all this in a selector and do all this gross shit there.
    const state = getState();
    const applicationsView = getApplicationsView(state);
    const searchBy = getSearchBy(state);
    const ordering = getOrdering(state);
    let reverseOrder = getReverseOrder(state);
    const filterBy = getFilterByEnabled(state);
    const pageSize = getPageSize(state);
    const filters = getFilterQuery(state);
    const currentPage = getCurrentPage(state);
    const isDeprecateNumericScoring = getLaunchDarklyFlags(state)?.webFeatureDeprecateNumericScoring;

    const nextPage = page || currentPage;

    // Adjudication Status query params
    const viewQueryParams = adjudicationStatusBinding(applicationsView);
    const queryAll = applicationsView === 'All';

    let queryParams = `?page=${nextPage}&page_size=${pageSize}${viewQueryParams}`;

    if (searchBy) queryParams += `&search=${searchBy}`;

    // LD Flag: webFeatureDeprecateNumericScoring
    if (!isDeprecateNumericScoring) {
        if (ordering === 'certn_score') {
            reverseOrder = !reverseOrder;
        }
    }

    if (ordering) queryParams += `&ordering=${reverseOrder ? `-${ordering}` : ordering}`;

    // Used when navigating on applications link from listings/packages page
    if (filterBy && filterBy.application__listing_id) {
        queryParams += `&application__listing_id=${filterBy.application__listing_id}`;
    }

    if (filters) {
        queryParams += filters;
    }

    if (!silent) {
        dispatch({
            type: GET_APPLICATIONS_REQ,
        });
    }

    return new Promise((resolve, reject) =>
        getRequest({
            hr: true,
            version: 'v1',
            endpoint: `/minimal/applicants/${queryParams}`,
        }).then(
            (response) => {
                dispatch({
                    type: GET_APPLICATIONS_RES,
                    payload: {
                        applications: response.results,
                        numApplications: response.count,
                        previousPage: response.previous,
                        nextPage: response.next,
                        currentPage: nextPage,
                        queryAll,
                    },
                });
                resolve({ response });
            },
            (error) => {
                dispatch({
                    type: GET_APPLICATIONS_ERR,
                    payload: { error },
                });
                reject({ error });
            }
        )
    );
};

const adjudicationStatusBinding = (status) => {
    let viewQueryParams = '';
    switch (status) {
        case 'Adverse Action':
            viewQueryParams += '&adverse_action_applicants_only=True';
            break;
        case 'Needs Review':
            viewQueryParams += '&adjudication_applicants_only=True';
            break;
        case 'Prospects':
            viewQueryParams += '&adjudication_status=PROSPECT';
            break;
        case 'Employment Pending':
            viewQueryParams += '&adjudication_status=OFFER_PENDING';
            break;
        case 'Employees':
            viewQueryParams += '&adjudication_status=OFFER_ACCEPTED';
            break;
        case 'Archive':
            viewQueryParams += '&adjudication_status=ARCHIVED';
            break;
        case 'All':
            viewQueryParams += '&adjudication_status_exclude=ARCHIVED';
            break;
        default:
            viewQueryParams += '&adjudication_status_exclude=ARCHIVED';
            break;
    }
    return viewQueryParams;
};

export const buildQueryParams = (values, page = null, state) => {
    const isWebFeatureEnableNewStatusSystem = getLaunchDarklyFlags(state)?.webFeatureEnableNewStatusSystem;
    let filters = '';
    const currentPage = getCurrentPage(state);
    const nextPage = page || currentPage;

    if (values.nameEmail && values.nameEmail.length > 0) {
        filters += `&search=${values.nameEmail}`;
    }
    // Filter by team
    if (values.team && values.team.length > 0) {
        filters += `&application__team__id=${values.team}`;
    }
    if (values.type && values.type.length > 0) {
        values.type.forEach((item) => {
            filters += `&${item}=True`;
        });
    }

    if (values.adjudicator && values.adjudicator.length > 0) {
        filters += `&applicant_adjudication__adjudicator=${values.adjudicator}`;
    }

    if (values.adjudicationReviewStatus && values.adjudicationReviewStatus.length > 0) {
        filters += `&applicant_adjudication__life_cycle_status=${values.adjudicationReviewStatus}`;
    }

    if (values.status && values.status.length > 0) {
        values.status.forEach((item) => {
            // LDFlag webFeatureEnableNewStatusSystem
            if (isWebFeatureEnableNewStatusSystem) {
                // item is already cased properly: 'ACTION_REQUIRED'
                filters += `&order_status=${item}`;
                return;
            }
            // If this looks hacky it's because it is.
            // This is a front-end only solution to address Application Status Improvements (CERTN-5472)
            // This should be removed when API 3.0 is used
            if (item.toUpperCase().replace(' ', '_') === Constants.reportStatus.IN_PROGRESS) {
                filters += `&report_status=${Constants.reportStatus.ANALYZING}`;
            } else if (item.toUpperCase().replace(' ', '_') === Constants.reportStatus.SENT) {
                filters += `&report_status=${Constants.reportStatus.SENT}`;
                filters += `&report_status=${Constants.reportStatus.IN_PROGRESS}`;
            } else {
                filters += `&report_status=${item.toUpperCase().replace(' ', '_')}`;
            }
        });
    }
    if (values.result && values.result.length > 0) {
        values.result.forEach((item) => {
            filters += `&result=${item.toUpperCase()}`;
        });
    }
    return { filters, nextPage };
};

export const patchNotes = (notes, applicationId) => (dispatch) => {
    dispatch({
        type: PATCH_NOTES_REQ,
    });
    return patchRequest({
        hr: true,
        version: 'v1',
        endpoint: `/applicants/${applicationId}/`,
        body: JSON.stringify(simpleStringifyJSON({ notes })),
    }).then(
        () => {
            dispatch({
                type: PATCH_NOTES_RES,
                payload: { notes },
            });
        },
        (error) => {
            dispatch({
                type: PATCH_NOTES_ERR,
                payload: { error },
            });
        }
    );
};

export const patchComments = (comment, applicationId) => (dispatch) => {
    dispatch({ type: POST_COMMENTS_REQ });
    return new Promise((resolve, reject) =>
        postRequest({
            internal: false,
            hr: true,
            version: 'v1',
            endpoint: `/applicants/${applicationId}/comments/`,
            body: JSON.stringify(simpleStringifyJSON({ text: comment })),
        }).then(
            (response) => {
                dispatch({
                    type: POST_COMMENTS_RES,
                    payload: { comments: response },
                });
                resolve();
            },
            (error) => {
                dispatch({
                    type: POST_COMMENTS_ERR,
                    payload: { error },
                });
                reject('Comment could not be posted at this time.');
            }
        )
    );
};

export const fetchApplication = (applicationId) => (dispatch) => {
    dispatch({
        type: GET_APPLICATION_REQ,
    });
    return getRequest({
        hr: true,
        version: 'v1',
        endpoint: `/applicants/${applicationId}/`,
    }).then(
        (response) => {
            dispatch({
                type: GET_APPLICATION_RES,
                payload: {
                    application: response,
                },
            });
            return response;
        },
        (error) => {
            dispatch({
                type: GET_APPLICATION_ERR,
                payload: { error },
            });
        }
    );
};

export const setApplicantViewed = (applicantId) => (dispatch) => {
    dispatch({
        type: SILENT_PUT_APPLICANTS_REQ,
    });
    return putRequest({
        hr: true,
        version: 'v1',
        endpoint: `/applicants/${applicantId}/`,
        body: JSON.stringify({ is_viewed: true }),
    }).then(
        () => {
            dispatch({
                type: PUT_APPLICATIONS_RES,
            });
        },
        (error) => {
            dispatch({
                type: PUT_APPLICATIONS_ERR,
                payload: { error },
            });
        }
    );
};

// Used when upgrading report from report view page
export const requestPackages = (applicantId, values) => (dispatch) => {
    dispatch({
        type: PUT_PACKAGES_REQ,
    });
    return new Promise((resolve, reject) =>
        putRequest({
            hr: true,
            version: 'v1',
            endpoint: `/applicants/${applicantId}/packages/`,
            body: JSON.stringify(values),
        }).then(
            (response) => {
                dispatch({
                    type: PUT_PACKAGES_RES,
                    payload: {
                        application: response,
                    },
                });
                resolve();
            },
            (error) => {
                dispatch({
                    type: PUT_PACKAGES_ERR,
                    payload: { error },
                });
                reject(error);
            }
        )
    );
};

export const setApplicationStatus = (applicantId, adjudication_status = 'NONE') => (dispatch) => {
    dispatch({ type: SILENT_PUT_APPLICATIONS_REQ });
    return putRequest({
        hr: true,
        version: 'v1',
        endpoint: `/applicants/${applicantId}/`,
        body: JSON.stringify(simpleStringifyJSON({ adjudication_status })),
    }).then(
        (response) => {
            dispatch({
                type: PUT_APPLICATIONS_RES,
                payload: {
                    application: response,
                },
            });
            return dispatch(fetchApplications(null, true));
        },
        (error) => {
            dispatch({
                type: PUT_APPLICATIONS_ERR,
                payload: { error },
            });
            throw error;
        }
    );
};

export const setBulkApplicationStatus = (requestObjects) => (dispatch) => {
    dispatch({ type: SILENT_PUT_APPLICATIONS_REQ });
    return patchRequest({
        hr: true,
        version: 'v1',
        endpoint: '/applicants/batch/',
        body: JSON.stringify(simpleStringifyJSON(requestObjects)),
    }).then(
        (response) => {
            dispatch({
                type: PUT_APPLICATIONS_RES,
                payload: {
                    application: response,
                },
            });
            return dispatch(fetchApplications(null, true));
        },
        (error) => {
            dispatch({
                type: PUT_APPLICATIONS_ERR,
                payload: { error },
            });
            throw error;
        }
    );
};

export const bulkDeleteApplications = (requestObjects) => (dispatch) => {
    dispatch({ type: DELETE_APPLICATIONS_REQ });
    return postRequest({
        hr: true,
        version: 'v1',
        endpoint: '/applicants/batch/delete',
        body: JSON.stringify(simpleStringifyJSON(requestObjects)),
    }).then(
        (response) => {
            dispatch({
                type: DELETE_APPLICATIONS_RES,
                payload: {
                    application: response,
                },
            });
            return dispatch(fetchApplications(null, true));
        },
        (error) => {
            dispatch({
                type: DELETE_APPLICATIONS_ERR,
                payload: { error },
            });
            throw error;
        }
    );
};

export const deleteApplication = (applicantId) => (dispatch) => {
    dispatch({
        type: DEL_APPLICATIONS_REQ,
    });
    return deleteRequest({
        hr: true,
        version: 'v1',
        endpoint: `/applicants/${applicantId}/`,
    }).then(
        () => {
            dispatch({
                type: DEL_APPLICATIONS_RES,
            });
            return dispatch(fetchApplications(null, true));
        },
        (error) => {
            dispatch({
                type: DEL_APPLICATIONS_ERR,
                payload: { error },
            });
            throw error;
        }
    );
};

export const sendReminder = (applicantId) => (dispatch) => {
    dispatch({
        type: SEND_REMINDER_REQ,
    });
    return postRequest({
        hr: true,
        version: 'v1',
        endpoint: `/applicants/${applicantId}/resend/`,
    }).then(
        () => {
            dispatch({
                type: SEND_REMINDER_RES,
            });
            return dispatch(fetchApplications(null, true));
        },
        (error) => {
            dispatch({
                type: SEND_REMINDER_ERR,
                payload: { error },
            });
            throw error;
        }
    );
};

export const sendApplications = (emails, postingId, upgrades = {}, compliance) => (dispatch) => {
    const newEmails = Array.isArray(emails) ? [...emails] : [emails];
    const bodyData = uniqueEmails(newEmails).map((email) => {
        const bodyItem = {
            email,
            listing_id: postingId,
            ...upgrades,
        };
        const missingUpgrades = {
            [CHECK_REQUEST.BASE]: Boolean(upgrades[CHECK_REQUEST.BASE]),
            [CHECK_REQUEST.SOFTCHECK]: Boolean(upgrades[CHECK_REQUEST.SOFTCHECK]),
            [CHECK_REQUEST.ADDRESS_REFERENCES]: Boolean(upgrades[CHECK_REQUEST.ADDRESS_REFERENCES]),
            [CHECK_REQUEST.MOTOR_VEHICLE_RECORDS]: Boolean(upgrades[CHECK_REQUEST.MOTOR_VEHICLE_RECORDS]),
        };
        Object.assign(bodyItem, missingUpgrades);

        if (compliance) {
            Object.assign(bodyItem, compliance);
        }
        return bodyItem;
    });
    dispatch({
        type: POST_APPLICATIONS_SEND_REQ,
    });
    return postRequest({
        hr: true,
        version: 'v1',
        endpoint: '/applications/invite/batch/',
        body: JSON.stringify(simpleStringifyJSON(bodyData)),
    }).then(
        (response) => {
            dispatch({
                type: POST_APPLICATIONS_SEND_RES,
                payload: { sentApplication: response },
            });
        },
        (error) => {
            dispatch({
                type: POST_APPLICATIONS_SEND_ERR,
                payload: { error },
            });
            handleBatchError(error);
        }
    );
};

export const createApplicationQuick = (formData) => (dispatch) => {
    dispatch({
        type: POST_APPLICATIONS_QUICK_REQ,
    });
    return postRequest({
        hr: true,
        version: 'v1',
        endpoint: '/applications/quick/',
        body: JSON.stringify(formData),
    }).then(
        (response) => {
            dispatch({
                type: POST_APPLICATIONS_QUICK_RES,
                payload: { newApplication: response },
            });
            dispatch(fetchApplications(1));
            return response.id;
        },
        (error) => {
            dispatch({
                type: POST_APPLICATIONS_QUICK_ERR,
                payload: { error },
            });
            throw error;
        }
    );
};

export const fetchReportInfoCSV = (applicantId) => async (dispatch) => {
    dispatch({
        type: GET_REPORT_CSV_REQ,
    });
    try {
        const response = await getRequest({
            hr: true,
            version: 'v1',
            endpoint: `applicants/${applicantId}/csv/`,
        });
        dispatch({
            type: GET_REPORT_CSV_RES,
            payload: { csvLink: response.url },
        });
    } catch (error) {
        dispatch({
            type: GET_REPORT_CSV_ERR,
            payload: { error },
        });
        throw error;
    }
};

export const postReportSummary = (applicantId, email, cc, language, emailMessage) => (dispatch) => {
    dispatch({
        type: POST_REPORT_SUMMARY_REQ,
    });
    return postRequest({
        hr: true,
        version: 'v1',
        endpoint: `applicants/${applicantId}/share`,
        body: JSON.stringify({
            email,
            cc,
            language,
            email_message: emailMessage,
        }),
    }).then(
        (response) => {
            dispatch({
                type: POST_REPORT_SUMMARY_RES,
            });
            return response;
        },
        (error) => {
            dispatch({
                type: POST_REPORT_SUMMARY_ERR,
                payload: { error },
            });
            throw error;
        }
    );
};

const CSVFileSave = (response) => {
    const fileName = `Batch-Report-CSV_${moment().format(getDateTimeFormat())}.csv`;
    downloadFile({ fileContents: response, fileName, fileType: 'text/csv' });
};

export const fetchBulkReportInfoCSV = (applicantIds) => async (dispatch) => {
    dispatch({
        type: GET_BULK_REPORT_CSV_REQ,
    });
    try {
        const response = await postRequest({
            hr: true,
            version: 'v1',
            endpoint: `applicants/csv/batch`,
            body: JSON.stringify(applicantIds),
        });
        dispatch({
            type: GET_BULK_REPORT_CSV_RES,
        });
        CSVFileSave(response);
    } catch (error) {
        dispatch({
            type: GET_BULK_REPORT_CSV_ERR,
            payload: { error },
        });
        throw new Error();
    }
};

export const fetchDateRangeReportInfoCSV = ([start_date, end_date]) => (dispatch) => {
    dispatch({
        type: GET_BULK_REPORT_CSV_REQ,
    });
    return getRequest({
        hr: true,
        version: 'v1',
        endpoint: `applicants/csv/batch/?start_date=${start_date}&end_date=${end_date}`,
    }).then(
        (response) => {
            dispatch({
                type: GET_BULK_REPORT_CSV_RES,
            });
            CSVFileSave(response);
        },
        (error) => {
            dispatch({
                type: GET_BULK_REPORT_CSV_ERR,
                payload: { error },
            });
            ErrorAlertAPI(error);
        }
    );
};

export const fetchReportPDF = ({ applicantId, applicantLogin = false }) => (dispatch, getState) => {
    dispatch({
        type: GET_REPORT_PDF_REQ,
    });

    // Get temporary url for PDF from API
    return getRequest({
        hr: true,
        version: 'v1',
        endpoint: `/reports/${applicantId}/link/`,
        applicantLogin,
    })
        .then((response) => {
            const { report_url } = response;
            if (!report_url) throw new Error('No Download URL Available');

            // Get the contents at that URL
            return fetch(report_url, {
                method: 'GET',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/pdf',
                },
            })
                .then(applicantLogin ? checkApplicantStatus : checkStatus)
                .then(parseResponse);
        })
        .then(
            // Download that content
            (response) => {
                const application = getApplications(getState()).find((x) => x.id === applicantId);
                const fileName = buildApplicationFileName(application);
                downloadFile({ fileContents: response, fileName, fileType: 'application/pdf' });
                dispatch({
                    type: GET_REPORT_PDF_RES,
                });
            },
            (error) => {
                dispatch({
                    type: GET_REPORT_PDF_ERR,
                    payload: { error },
                });
                throw new Error(error);
            }
        );
};

export const fetchBulkReportsPDF = (applicantIds) => (dispatch) => {
    dispatch({
        type: GET_BULK_REPORTS_PDF_REQ,
    });
    return postRequest({
        hr: true,
        version: 'v1',
        endpoint: `/reports/pdf/batch/`,
        body: JSON.stringify(applicantIds),
    }).then(
        (response) => {
            dispatch({
                type: GET_BULK_REPORTS_PDF_RES,
            });
            const fileName = `Batch-Reports-PDF_${moment().format(getDateTimeFormat())}.zip`;
            downloadFile({ fileContents: response, fileName, fileType: 'application/zip' });
        },
        (error) => {
            dispatch({
                type: GET_BULK_REPORTS_PDF_ERR,
                payload: { error },
            });
            throw new Error();
        }
    );
};

export const fetchReport = (applicantId) => (dispatch) => {
    dispatch({
        type: GET_REPORT_REQ,
        payload: { report: {} },
    });
    return new Promise((resolve, reject) =>
        getRequest({
            hr: true,
            version: 'v1',
            endpoint: `/reports/${applicantId}/web/`,
        }).then(
            (response) => {
                dispatch({
                    type: GET_REPORT_RES,
                    payload: { report: response },
                });
                dispatch(setApplicantViewed(applicantId));
                resolve(response);
            },
            (error) => {
                dispatch({
                    type: GET_REPORT_ERR,
                    payload: { error },
                });
                reject(error);
            }
        )
    );
};

export const renewExpiredApplicants = (applicantIds) => (dispatch) => {
    dispatch({
        type: PATCH_EXPIRED_APPLICANT_RENEWAL_REQ,
    });
    return patchRequest({
        internal: true,
        version: 'v1',
        endpoint: '/applicants/renew/',
        body: JSON.stringify(applicantIds),
    }).then(
        (response) => {
            dispatch({
                type: PATCH_EXPIRED_APPLICANT_RENEWAL_RES,
                payload: { response },
            });
            return response;
        },
        (error) => {
            dispatch({
                type: PATCH_EXPIRED_APPLICANT_RENEWAL_ERR,
                payload: { error },
            });
            throw error;
        }
    );
};

export const buyEquifax = (applicantId) => (dispatch) => {
    dispatch({
        type: POST_BUY_EQUIFAX_REQ,
    });
    return postRequest({
        hr: true,
        version: 'v1',
        endpoint: `/pay_financial/${applicantId}/`,
    }).then(
        (response) => {
            dispatch({
                type: POST_BUY_EQUIFAX_RES,
                payload: { applicant: response },
            });
            dispatch(fetchReport(applicantId));
        },
        (error) => {
            dispatch({
                type: POST_BUY_EQUIFAX_ERR,
                payload: { error },
            });
        }
    );
};

// List Action Creators
export const setSearchBy = (searchBy) => (dispatch) => {
    dispatch({
        type: SET_SEARCH_BY,
        payload: { searchBy },
    });
    return dispatch(fetchApplications(1));
};

export const setOrdering = (ordering, isActiveSwitch = false) => (dispatch, getState) => {
    const currentOrdering = getOrdering(getState());
    let reverseOrder = currentOrdering === ordering ? !getReverseOrder(getState()) : false;
    if (isActiveSwitch) {
        reverseOrder = false;
    }
    dispatch({
        type: SET_ORDERING,
        payload: {
            ordering,
            reverseOrder,
        },
    });
    return dispatch(fetchApplications(1));
};

export const setApplicationsView = (view = 'All') => (dispatch) => {
    dispatch({
        type: SET_APPLICATIONS_VIEW,
        payload: { view },
    });
};

export const setPageSize = (pageSize) => (dispatch) => {
    dispatch({
        type: SET_PAGE_SIZE,
        payload: { pageSize },
    });
};

export const setFilterBy = (filterBy) => (dispatch) => {
    dispatch({
        type: SET_FILTER_BY,
        payload: { filterBy },
    });
    return dispatch(fetchApplications(1));
};

export const clearFilters = () => (dispatch) => {
    dispatch({
        type: SET_FILTER_BY,
        payload: { filterBy: {} },
    });
    return dispatch(fetchApplications(1));
};

export const resetListAndFetch = () => (dispatch) => {
    dispatch({
        type: RESET_LIST,
        payload: {},
    });
    return dispatch(fetchApplications(1));
};

export const resetListAndFilter = (filterBy) => (dispatch) => {
    dispatch({
        type: RESET_LIST,
        payload: {},
    });
    return dispatch(setFilterBy(filterBy));
};
