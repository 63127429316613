import { useFlags } from 'launchdarkly-react-client-sdk';
import Path from 'modules/Path';
import { useSelector } from 'react-redux';
import config from 'utils/config';
import { getTeam } from 'views/welcome/WelcomeSelectors';

export const useDisplayDisclosureAgreement = () => {
    const team = useSelector(getTeam);
    const isMyCRC = Path.isMyCRCSubdomain();
    const teamCountry = team?.country;
    const { webFeatureEnableMycrcUs } = useFlags();

    return !!(webFeatureEnableMycrcUs && (isMyCRC || config.isNotProduction) && teamCountry === 'US');
};
