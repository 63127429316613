import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button as AntdButton } from 'antd';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { ApplicationStatus } from 'certn-ui';
import Titlebar from 'certn-ui/Titlebar';
import { StatusDropdown } from 'views/manager/views/hr/views/applications/components';
import { DownOutlined } from '@ant-design/icons';
import Auth from 'modules/Auth';
import config from 'utils/config';
import { getUser } from 'base/BaseSelectors';
import { getIsFetchingSilent } from 'views/manager/views/hr/views/applications/ApplicationsSelectors';
import { useFlags } from 'launchdarkly-react-client-sdk';

const DownOutlinedMarginLeft6px = styled(DownOutlined)`
    margin-left: 6px;
`;

const AntdButtonRound = styled(AntdButton)`
    border-radius: 4px !important;
    ${({ theme, isBlue }) => isBlue && `background-color: ${theme.color.certnBlue500} !important;`};
    ${({ theme, isBlue }) => isBlue && `border-color: ${theme.color.certnBlue500} !important;`};
`;

const StatusButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    > button {
        ${({ webFeatureEnableNewStatusSystem }) => !webFeatureEnableNewStatusSystem && 'height: 34.5px !important'};
    }
`;

const ApplicationTitleBar = ({
    application,
    sidebarLoading,
    toggleSidebar,
    onResize,
    isDesktopView,
    sidebarCollapsed,
}) => {
    const isFetchingSilent = useSelector(getIsFetchingSilent);
    const user = useSelector(getUser);
    const { webFeatureEnableNewStatusSystem } = useFlags();

    const applicantName = `${application.information?.first_name || ''} ${application.information?.last_name || ''}`;
    const disabled = isFetchingSilent || !user?.team?.payment_verified || !application?.can_upgrade;

    const getTitle = () => {
        if (isDesktopView) {
            return applicantName ? (
                <FormattedMessage
                    id="6cb79.ApplicationsView.applicantResultName"
                    defaultMessage="Applicant Results - {applicantName}"
                    values={{ applicantName }}
                />
            ) : (
                <FormattedMessage id="6cb79.ApplicationsView.applicantResult" defaultMessage="Applicant Results " />
            );
        }

        return 'Application Results';
    };

    const sideBarConfig = {
        shouldShowSideBar: isDesktopView && !Auth.isAdjudicatorUser(),
        sidebarLoading,
        sidebarCollapsed,
        toggleSidebar,
        onResize,
    };

    // LDFlag webFeatureEnableNewStatusSystem
    const size = webFeatureEnableNewStatusSystem ? 'large' : 'middle';

    return (
        <Titlebar title={getTitle()} mobile={!isDesktopView} center={!isDesktopView}>
            {!isDesktopView && <div style={{ fontSize: '16px' }}>{applicantName}</div>}
            <StatusButtonWrapper webFeatureEnableNewStatusSystem={webFeatureEnableNewStatusSystem}>
                {Auth.isPermissionLevel('staff') && (
                    <AntdButtonRound
                        isBlue
                        type="primary"
                        size={size}
                        loading={isFetchingSilent}
                        onClick={() => window.open(`${config.url}/admin/applications/applicant/${application.id}`)}
                    >
                        <FormattedMessage id="6cb79.ApplicationsView.djangoAdmin" defaultMessage="Django Admin" />
                    </AntdButtonRound>
                )}
                <ApplicationStatus isReport application={application} />
                <StatusDropdown isReport applicant={application}>
                    <AntdButtonRound type="primary" size={size} disabled={disabled} loading={isFetchingSilent}>
                        <FormattedMessage id="common.actions" defaultMessage="Actions" />
                        <DownOutlinedMarginLeft6px />
                    </AntdButtonRound>
                </StatusDropdown>

                <StatusDropdown sideBarConfig={sideBarConfig} variant="more" isReport applicant={application}>
                    <AntdButtonRound size={size} disabled={disabled} loading={isFetchingSilent}>
                        <FormattedMessage id="common.more" defaultMessage="More" />
                        <DownOutlinedMarginLeft6px />
                    </AntdButtonRound>
                </StatusDropdown>
            </StatusButtonWrapper>
        </Titlebar>
    );
};

ApplicationTitleBar.propTypes = {
    application: PropTypes.object.isRequired,
    sidebarLoading: PropTypes.bool.isRequired,
    toggleSidebar: PropTypes.func.isRequired,
    onResize: PropTypes.func.isRequired,
    isDesktopView: PropTypes.bool,
};

ApplicationTitleBar.defaultProps = {
    isDesktopView: false,
};

export default ApplicationTitleBar;
