import styled from 'styled-components';
import { Select } from 'antd';

export const PackageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    @media (min-width: ${(props) => props.theme.width.tablet}) {
        align-items: center;
        flex-direction: row;
        width: 300px;
    }
`;

export const Or = styled.div`
    flex: 1.2;
    margin-right: 10px;
    @media (max-width: ${(props) => props.theme.width.tablet}) {
        margin-top: 10px;
    }
`;

export const PackageSelect = styled(Select)`
    flex: 4;
`;
