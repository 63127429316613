import {
    requestReducer,
    silentRequestReducer,
    responseReducer,
    errorReducer,
    defaultState as defaultHttpState,
} from 'utils/http';
import {
    POST_EID_START_REQ,
    POST_EID_START_RES,
    POST_EID_START_ERR,
    POST_EID_RESPONSE_REQ,
    POST_EID_RESPONSE_RES,
    POST_EID_RESPONSE_ERR,
} from 'views/welcome/views/identity/IdentityActions';
import {
    GET_LISTING_REQ,
    GET_LISTING_RES,
    GET_LISTING_ERR,
    GET_ONBOARDING_REQ,
    GET_ONBOARDING_RES,
    GET_ONBOARDING_ERR,
    POST_ONBOARDING_REQ,
    POST_ONBOARDING_RES,
    POST_ONBOARDING_ERR,
    PATCH_ONBOARDING_REQ,
    PATCH_ONBOARDING_RES,
    PATCH_ONBOARDING_ERR,
    PATCH_LANGUAGE_REQ,
    PATCH_LANGUAGE_RES,
    PATCH_LANGUAGE_ERR,
    PUT_VERIFY_ONBOARDING_REQ,
    PUT_VERIFY_ONBOARDING_RES,
    PUT_VERIFY_ONBOARDING_ERR,
    POST_RESEND_VERIFY_REQ,
    POST_RESEND_VERIFY_RES,
    POST_RESEND_VERIFY_ERR,
    POST_NETVERIFY_REQ,
    POST_NETVERIFY_RES,
    POST_NETVERIFY_ERR,
    PUT_SUBMIT_REQ,
    PUT_SUBMIT_RES,
    PUT_SUBMIT_ERR,
    PATCH_INFORMATION_REQ,
    PATCH_INFORMATION_RES,
    PATCH_INFORMATION_ERR,
    RESET_APPLICATION,
    POST_S3_RES,
    DELETE_DOCUMENT_RES,
    GET_SPECIALIZATIONS_REQ,
    GET_SPECIALIZATIONS_RES,
    GET_SPECIALIZATIONS_ERR,
    GET_SKILLS_REQ,
    GET_SKILLS_RES,
    GET_SKILLS_ERR,
    GET_DEGREES_REQ,
    GET_DEGREES_RES,
    GET_DEGREES_ERR,
    GET_EMPLOYER_REFERENCE_SKIP_REASONS_REQ,
    GET_EMPLOYER_REFERENCE_SKIP_REASONS_RES,
    GET_EMPLOYER_REFERENCE_SKIP_REASONS_ERR,
    PATCH_NETVERIFY_BYPASS_REQ,
    PATCH_NETVERIFY_BYPASS_ERR,
    SET_TRACK_PAGE_ORDER,
    GET_CONVICTION_OFFENSES_REQ,
    GET_CONVICTION_OFFENSES_RES,
    POST_WHITE_LABEL_NETVERIFY_REQ,
    POST_WHITE_LABEL_NETVERIFY_RES,
    POST_WHITE_LABEL_NETVERIFY_ERR,
    POST_AUTOFAIL_NETVERIFY_REQ,
    POST_AUTOFAIL_NETVERIFY_RES,
    POST_AUTOFAIL_NETVERIFY_ERR,
    POST_CHECK_EMAIL_EXIST_REQ,
    POST_CHECK_EMAIL_EXIST_RES,
    POST_CHECK_EMAIL_EXIST_ERR,
    GET_STRIPE_PAYMENT_BREAKDOWN,
    GET_SUPPORTED_ID_TYPES,
    TOGGLE_NAVIGATION_BARS,
    POST_SIGN_UP_RES,
    SET_TRACK_PAGE_LOCATION,
    POST_POSITION_PROPERTY_REQ,
    POST_POSITION_PROPERTY_RES,
    POST_POSITION_PROPERTY_ERR,
    PATCH_POSITION_PROPERTY_REQ,
    PATCH_POSITION_PROPERTY_RES,
    PATCH_POSITION_PROPERTY_ERR,
    GET_POSITION_PROPERTY_RES,
    GET_POSITION_PROPERTY_ERR,
    GET_POSITION_PROPERTY_REQ,
    PATCH_APPLICANT,
    POST_SOCIAL_MEDIA_DETAILS_ERR,
    POST_SOCIAL_MEDIA_DETAILS_RES,
    POST_SOCIAL_MEDIA_DETAILS_REQ,
    GET_SOCIAL_MEDIA_DETAILS_ERR,
    GET_SOCIAL_MEDIA_DETAILS_RES,
    GET_SOCIAL_MEDIA_DETAILS_REQ,
    PATCH_ENHANCED_IDENTITY_VERIFICATION,
} from './WelcomeActions';

import { LANGUAGE_EN } from 'utils/constants';

const initialState = {
    ...defaultHttpState,
    language: LANGUAGE_EN,
    furthestTrack: '',
    tracks: ['email'],
    trackPageOrder: ['base'],
    trackPageLocation: 'base',
    applicant_account: {},
    applicant_verified: false,
    coApplicants: [],
    onboardingId: undefined,
    information: {},
    isCosigner: false,
    isSubmitted: false,
    listing: {},
    onboardingType: 'PM',
    ownerId: undefined,
    settings: {},
    skills: [],
    degrees: [],
    employerReferenceSkipReasons: {},
    convictionOffenses: {},
    specializations: [],
    transaction_reference: undefined,
    redirect_url: undefined,
    identityQuestions: [],
    internationalFields: [],
    myCRCPaymentBreakdown: {},
    supportedIdTypes: [],
    team: {},
    navigationVisible: true,
};

const welcome = (state = initialState, action) => {
    switch (action.type) {
        case GET_ONBOARDING_REQ:
            return {
                ...state,
                ...requestReducer(),
            };
        case GET_LISTING_REQ:
        case POST_ONBOARDING_REQ:
        case POST_RESEND_VERIFY_REQ:
        case POST_NETVERIFY_REQ:
        case PUT_SUBMIT_REQ:
        case PUT_VERIFY_ONBOARDING_REQ:
        case PATCH_INFORMATION_REQ:
        case PATCH_ONBOARDING_REQ:
        case GET_SKILLS_REQ:
        case GET_DEGREES_REQ:
        case GET_SPECIALIZATIONS_REQ:
        case GET_EMPLOYER_REFERENCE_SKIP_REASONS_REQ:
        case GET_CONVICTION_OFFENSES_REQ:
        case POST_EID_START_REQ:
        case POST_EID_RESPONSE_REQ:
        case PATCH_LANGUAGE_REQ:
        case PATCH_NETVERIFY_BYPASS_REQ:
        case POST_WHITE_LABEL_NETVERIFY_REQ:
        case POST_AUTOFAIL_NETVERIFY_REQ:
        case POST_CHECK_EMAIL_EXIST_REQ:
        case POST_POSITION_PROPERTY_REQ:
        case PATCH_POSITION_PROPERTY_REQ:
        case GET_POSITION_PROPERTY_REQ:
        case POST_SOCIAL_MEDIA_DETAILS_REQ:
        case GET_SOCIAL_MEDIA_DETAILS_REQ:
            return {
                ...state,
                ...silentRequestReducer(),
            };
        case POST_RESEND_VERIFY_RES:
        case POST_WHITE_LABEL_NETVERIFY_RES:
        case POST_AUTOFAIL_NETVERIFY_RES:
        case POST_CHECK_EMAIL_EXIST_RES:
            return {
                ...state,
                ...responseReducer(),
            };
        case POST_EID_RESPONSE_RES:
            return {
                ...state,
                ...responseReducer(),
                identityVerification: action.payload.identityVerification,
            };
        case GET_SUPPORTED_ID_TYPES.SUC:
            return {
                ...state,
                ...responseReducer(),
                supportedIdTypes: action.payload.supportedIdTypes,
            };
        case GET_STRIPE_PAYMENT_BREAKDOWN.SUC:
            return {
                ...state,
                myCRCPaymentBreakdown: action.payload.myCRCPaymentBreakdown,
            };
        case POST_EID_START_RES:
            return {
                ...state,
                ...responseReducer(),
                identityQuestions: action.payload.identityQuestions,
            };
        case POST_NETVERIFY_RES:
            return {
                ...state,
                ...responseReducer(),
                transaction_reference: action.payload.transaction_reference,
                redirect_url: action.payload.redirect_url,
            };
        case PUT_VERIFY_ONBOARDING_RES:
            return {
                ...state,
                ...responseReducer(),
                applicant_verified: action.payload.applicant_verified,
            };
        case PATCH_LANGUAGE_RES:
            return {
                ...state,
                ...responseReducer(),
                language: action.payload.language,
            };
        case GET_LISTING_RES:
            return {
                ...state,
                ...responseReducer(),
                listing: action.payload.listing,
            };
        case PATCH_ONBOARDING_RES:
            return {
                ...state,
                ...responseReducer(),
                furthestTrack: action.payload.furthestTrack,
                tracks: action.payload.tracks,
                internationalFields: action.payload.internationalFields,
                identityVerification: action.payload.identityVerification,
                enhancedIdentityVerification: action.payload.enhancedIdentityVerification,
            };
        case GET_ONBOARDING_RES:
        case POST_ONBOARDING_RES:
            return {
                ...state,
                ...responseReducer(),
                furthestTrack: action.payload.furthestTrack,
                tracks: action.payload.tracks,
                applicant_account: action.payload.applicant_account,
                applicant_verified: action.payload.applicant_verified,
                complianceRegion: action.payload.complianceRegion,
                coApplicants: action.payload.coApplicants,
                onboardingId: action.payload.onboardingId,
                information: action.payload.information,
                isCosigner: action.payload.isCosigner,
                isSubmitted: action.payload.isSubmitted,
                isPrepaid: action.payload.isPrepaid,
                listing: action.payload.listing,
                onboardingType: action.payload.onboardingType,
                ownerId: action.payload.ownerId,
                settings: action.payload.settings,
                applicant: action.payload.applicant,
                internationalFields: action.payload.internationalFields,
                identityVerification: action.payload.identityVerification,
                enhancedIdentityVerification: action.payload.enhancedIdentityVerification,
                team: action.payload.team,
                user: action.payload.user,
            };
        case PUT_SUBMIT_RES:
            return {
                ...state,
                ...responseReducer(),
                isSubmitted: action.payload.isSubmitted,
            };
        case PATCH_INFORMATION_RES:
            return {
                ...state,
                ...responseReducer(),
                information: action.payload.information,
            };
        case PATCH_ENHANCED_IDENTITY_VERIFICATION:
            return {
                ...state,
                ...responseReducer(),
                enhancedIdentityVerification: action.payload.enhancedIdentityVerification,
            };
        case PATCH_APPLICANT:
            return {
                ...state,
                ...responseReducer(),
                applicant: action.payload.applicant,
            };
        case GET_SKILLS_RES:
            return {
                ...state,
                ...responseReducer(),
                skills: action.payload.skills,
            };
        case GET_DEGREES_RES:
            return {
                ...state,
                ...responseReducer(),
                degrees: action.payload.degrees,
            };
        case GET_SPECIALIZATIONS_RES:
            return {
                ...state,
                ...responseReducer(),
                specializations: action.payload.specializations,
            };
        case GET_EMPLOYER_REFERENCE_SKIP_REASONS_RES:
            return {
                ...state,
                ...responseReducer(),
                employerReferenceSkipReasons: action.payload.employerReferenceSkipReasons,
            };
        case GET_CONVICTION_OFFENSES_RES:
            return {
                ...state,
                ...responseReducer(),
                convictionOffenses: action.payload.convictionOffenses,
            };
        case POST_POSITION_PROPERTY_RES:
        case PATCH_POSITION_PROPERTY_RES:
        case POST_SOCIAL_MEDIA_DETAILS_RES:
            return {
                ...state,
                ...responseReducer(),
                ...action.payload,
            };
        case GET_LISTING_ERR:
        case GET_ONBOARDING_ERR:
        case POST_RESEND_VERIFY_ERR:
        case POST_NETVERIFY_ERR:
        case PATCH_INFORMATION_ERR:
        case PUT_VERIFY_ONBOARDING_ERR:
        case PUT_SUBMIT_ERR:
        case POST_ONBOARDING_ERR:
        case GET_SKILLS_ERR:
        case GET_DEGREES_ERR:
        case GET_SPECIALIZATIONS_ERR:
        case GET_EMPLOYER_REFERENCE_SKIP_REASONS_ERR:
        case PATCH_ONBOARDING_ERR:
        case POST_EID_START_ERR:
        case POST_EID_RESPONSE_ERR:
        case PATCH_LANGUAGE_ERR:
        case PATCH_NETVERIFY_BYPASS_ERR:
        case POST_WHITE_LABEL_NETVERIFY_ERR:
        case POST_AUTOFAIL_NETVERIFY_ERR:
        case POST_CHECK_EMAIL_EXIST_ERR:
        case POST_POSITION_PROPERTY_ERR:
        case PATCH_POSITION_PROPERTY_ERR:
        case GET_POSITION_PROPERTY_ERR:
        case POST_SOCIAL_MEDIA_DETAILS_ERR:
        case GET_SOCIAL_MEDIA_DETAILS_ERR:
            return {
                ...state,
                ...errorReducer(action.payload.error),
            };
        case RESET_APPLICATION:
            return {
                ...initialState,
                listing: state.listing,
                onboardingId: state.onboardingId,
                onboardingType: state.onboardingType,
            };
        case POST_S3_RES:
            return {
                ...state,
                ...responseReducer(),
                s3UploadLink: action.payload.s3UploadLink,
                s3UploadFields: action.payload.s3UploadFields,
                preparedDocument: action.payload.preparedDocument,
            };
        case DELETE_DOCUMENT_RES:
            return {
                ...state,
                ...responseReducer(),
            };
        case SET_TRACK_PAGE_ORDER:
            return {
                ...state,
                trackPageOrder: action.payload.trackPageOrder,
            };
        case TOGGLE_NAVIGATION_BARS:
            return {
                ...state,
                navigationVisible: action.payload.navigationVisible,
            };
        case POST_SIGN_UP_RES:
            return {
                ...state,
                team: action.payload.team,
            };
        case SET_TRACK_PAGE_LOCATION:
            return {
                ...state,
                ...action.payload,
            };
        case GET_SOCIAL_MEDIA_DETAILS_RES:
        case GET_POSITION_PROPERTY_RES:
            return {
                ...state,
                ...responseReducer(),
                ...action.payload,
            };
        default:
            return state;
    }
};

export default welcome;
