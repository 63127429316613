// Libraries
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Row, Col } from 'antd';

// Components
import { AppearRight } from 'certn-ui/Animate';
import Title from 'certn-ui/Title';
import { TrackNavigation, AgreementSigning } from 'views/welcome/components';
import Text from 'certn-ui/Text';
import Button from 'certn-ui/Button';

import config from 'utils/config';

// Actions & Selectors
import { getInformation, getOnboardingId } from 'views/welcome/WelcomeSelectors';

const AcicDisclosureForm = ({ handleSubmit }) => {
    const intl = useIntl();

    const { acic_disclosure_accepted } = useSelector(getInformation);
    const onboardingId = useSelector(getOnboardingId);

    const [isSigned, setIsSigned] = useState(false);
    const [showAgreementSigning, setShowAgreementSigning] = useState(false);

    const handleOpenAgreementSigning = useCallback(() => setShowAgreementSigning(true), [setShowAgreementSigning]);
    const handleCloseAgreementSigning = useCallback(() => setShowAgreementSigning(false), [setShowAgreementSigning]);
    const onAgreementSigningFinish = useCallback(() => setIsSigned(true), [setIsSigned]);

    const documentTitle = (
        <FormattedMessage id="welcome.acicDisclosure.acicDisclosureForm" defaultMessage="ACIC Disclosure Form" />
    );

    return (
        <AppearRight>
            <Title>
                <FormattedMessage
                    id="welcome.acicDisclosure.title"
                    defaultMessage="Disclosure and Authorization Forms"
                />
            </Title>
            <Row gutter={[16, 16]}>
                <Col sm={24} md={{ span: 14, offset: 5 }} lg={{ span: 12, offset: 6 }} xl={{ span: 10, offset: 7 }}>
                    <Text align="center" color="certnGray600">
                        <FormattedMessage
                            id="welcome.acicDisclosure.subtitle"
                            defaultMessage="The following documents must be electronically signed for the background check process to continue."
                        />
                    </Text>
                </Col>
            </Row>
            <Row style={{ padding: '16px 0' }}>
                <Col span={24} md={{ span: 16, offset: 4 }} lg={{ span: 16, offset: 4 }}>
                    <AgreementSigning
                        isOpen={showAgreementSigning}
                        onOpen={handleOpenAgreementSigning}
                        onClose={handleCloseAgreementSigning}
                        documentTitle={documentTitle}
                        isSigned={acic_disclosure_accepted || isSigned}
                        endpointURL={`/onboarding/${onboardingId}/acic_signing_urls/`}
                        onFinish={onAgreementSigningFinish}
                    />
                </Col>
            </Row>
            {acic_disclosure_accepted || isSigned ? (
                <TrackNavigation handleSubmit={() => handleSubmit()} />
            ) : (
                <>
                    <TrackNavigation
                        nextButtonTitle={intl.formatMessage({
                            id: 'disclosure.signDocuments',
                            defaultMessage: 'Sign Documents',
                        })}
                        handleSubmit={handleOpenAgreementSigning}
                    />
                    {config.isNotProduction && (
                        <Button size="large" type="dashed" onClick={() => handleSubmit()}>
                            {intl.formatMessage({ id: 'button.demoSkip', defaultMessage: 'Demo Skip' })}
                        </Button>
                    )}
                </>
            )}
        </AppearRight>
    );
};

AcicDisclosureForm.propTypes = {
    orgName: PropTypes.string,
    handleSubmit: PropTypes.func.isRequired,
};

AcicDisclosureForm.defaultProps = {
    orgName: 'Certn',
};

export default injectIntl(AcicDisclosureForm);
