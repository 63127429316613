import {
    requestReducer,
    silentRequestReducer,
    responseReducer,
    errorReducer,
    defaultState as defaultHttpState,
} from 'utils/http';
import {
    CLEAR_ERRORS,
    GET_LISTINGS_REQ,
    GET_LISTINGS_RES,
    GET_LISTINGS_ERR,
    GET_ALL_LISTINGS_REQ,
    GET_ALL_LISTINGS_RES,
    GET_ALL_LISTINGS_ERR,
    GET_LISTING_REQ,
    GET_LISTING_RES,
    GET_LISTING_ERR,
    GET_NEW_LISTING_RES,
    POST_LISTINGS_REQ,
    POST_LISTINGS_RES,
    POST_LISTINGS_ERR,
    PUT_LISTINGS_REQ,
    PUT_LISTINGS_RES,
    PUT_LISTINGS_ERR,
    SILENT_CLOSE_LISTING_REQ,
    CLOSE_LISTING_REQ,
    CLOSE_LISTING_RES,
    CLOSE_LISTING_ERR,
    SILENT_DEL_LISTINGS_REQ,
    DEL_LISTINGS_REQ,
    DEL_LISTINGS_RES,
    DEL_LISTINGS_ERR,
    GET_TEAM_MEMBERS_REQ,
    GET_TEAM_MEMBERS_RES,
    GET_TEAM_MEMBERS_ERR,
    POST_MERGE_APPLICANTS_REQ,
    POST_MERGE_APPLICANTS_RES,
    POST_MERGE_APPLICANTS_ERR,
    SET_IS_ACTIVE,
    SET_SEARCH_BY,
    SET_ORDERING,
    SET_PAGE_SIZE,
    SET_FILTER_BY,
    CLEAR_FILTERS,
    RESET_LIST,
} from './ListingsActions';

const initialState = {
    ...defaultHttpState,
    listings: [],
    teamMembers: [],
    allListings: [],
    numAllListings: 0,
    listing: {},
    newListing: {},
    application: {},
    isActive: true,
    searchBy: null,
    ordering: 'modified',
    reverseOrder: true,
    filterBy: {},
    numPages: 1,
    numListings: 0,
    previousPage: undefined,
    nextPage: undefined,
    currentPage: 1,
    pageSize: 7,
};

const listings = (state = initialState, action) => {
    switch (action.type) {
        case SILENT_DEL_LISTINGS_REQ:
        case SILENT_CLOSE_LISTING_REQ:
        case GET_TEAM_MEMBERS_REQ:
        case POST_MERGE_APPLICANTS_REQ:
            return {
                ...state,
                ...silentRequestReducer(),
            };
        case GET_LISTING_REQ:
        case GET_LISTINGS_REQ:
        case GET_ALL_LISTINGS_REQ:
        case PUT_LISTINGS_REQ:
        case DEL_LISTINGS_REQ:
        case POST_LISTINGS_REQ:
        case CLOSE_LISTING_REQ:
            return {
                ...state,
                ...requestReducer(),
            };
        case GET_LISTING_RES:
            return {
                ...state,
                ...responseReducer(),
                listing: action.payload.listing,
            };
        case GET_NEW_LISTING_RES:
            return {
                ...state,
                ...responseReducer(),
                newListing: action.payload.newListing,
            };
        case GET_LISTINGS_RES:
            return {
                ...state,
                ...responseReducer(),
                listings: action.payload.listings,
                numListings: action.payload.numListings,
                previousPage: action.payload.previousPage,
                nextPage: action.payload.nextPage,
                currentPage: action.payload.currentPage,
            };
        case GET_ALL_LISTINGS_RES:
            return {
                ...state,
                ...responseReducer(),
                allListings: action.payload.allListings,
                numAllListings: action.payload.numAllListings,
            };
        case GET_TEAM_MEMBERS_RES:
            return {
                ...state,
                ...responseReducer(),
                teamMembers: action.payload.teamMembers,
            };
        case CLEAR_ERRORS:
        case DEL_LISTINGS_RES:
        case CLOSE_LISTING_RES:
            return {
                ...state,
                ...responseReducer(),
            };
        case PUT_LISTINGS_RES:
            return {
                ...state,
                ...responseReducer(),
                listing: action.payload.listing,
            };
        case POST_MERGE_APPLICANTS_RES:
            return {
                ...state,
                ...responseReducer(),
                application: action.payload.application,
            };
        case POST_LISTINGS_RES:
            return {
                ...state,
                ...responseReducer(),
                listing: action.payload.listing,
                newListing: action.payload.newListing,
            };
        case GET_LISTING_ERR:
        case GET_LISTINGS_ERR:
        case GET_ALL_LISTINGS_ERR:
        case PUT_LISTINGS_ERR:
        case DEL_LISTINGS_ERR:
        case POST_LISTINGS_ERR:
        case CLOSE_LISTING_ERR:
        case POST_MERGE_APPLICANTS_ERR:
        case GET_TEAM_MEMBERS_ERR:
            return {
                ...state,
                ...errorReducer(action.payload.error),
            };
        case SET_IS_ACTIVE:
            return {
                ...state,
                isActive: action.payload.isActive,
                reverseOrder: false,
            };
        case SET_SEARCH_BY:
            return {
                ...state,
                searchBy: action.payload.searchBy,
            };
        case SET_ORDERING:
            return {
                ...state,
                ordering: action.payload.ordering,
                reverseOrder: action.payload.reverseOrder,
            };
        case SET_PAGE_SIZE:
            return {
                ...state,
                pageSize: action.payload.pageSize,
            };
        case SET_FILTER_BY:
        case CLEAR_FILTERS:
            return {
                ...state,
                filterBy: action.payload.filterBy,
            };
        case RESET_LIST:
            return {
                ...state,
                isActive: true,
                searchBy: null,
                ordering: 'modified',
                reverseOrder: true,
                filterBy: {},
            };
        default:
            return state;
    }
};

export default listings;
