import React from 'react';
import { Menu } from 'antd';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { ListCellText } from 'certn-ui/List';

const ItemShowInfoPanel = ({ sideBarConfig, ...rest }) => {
    const { sidebarLoading, sidebarCollapsed, toggleSidebar, onResize } = sideBarConfig;
    const handleClick = () => {
        toggleSidebar();
        onResize();
    };

    return (
        <Menu.Item {...rest} loading={sidebarLoading.sidebarLoading} data-testid="toggle_comments_button">
            <ListCellText clickable onClick={handleClick}>
                {sidebarCollapsed ? (
                    <FormattedMessage id="004e5.StatusDropdown.showInfoPanel" defaultMessage="Show Info Panel" />
                ) : (
                    <FormattedMessage id="004e5.StatusDropdown.hideInfoPanel" defaultMessage="Hide Info Panel" />
                )}
            </ListCellText>
        </Menu.Item>
    );
};

ItemShowInfoPanel.propTypes = {
    sideBarConfig: PropTypes.shape({
        sidebarLoading: PropTypes.bool.isRequired,
        sidebarCollapsed: PropTypes.bool.isRequired,
        toggleSidebar: PropTypes.func.isRequired,
        onResize: PropTypes.func.isRequired,
    }).isRequired,
};

export default ItemShowInfoPanel;
