// Libraries
import React from 'react';
import { InfoCircleOutlined, UploadOutlined } from '@ant-design/icons';
import { Select, Row, Col, Upload, Tooltip } from 'antd';
import { FormattedMessage } from 'react-intl';

// Components
import Button from 'certn-ui/Button';
import Title from 'certn-ui/Title';
import Text from 'certn-ui/Text';
import { Box } from 'certn-ui/Layout';
import { AppearRight } from 'certn-ui/Animate';
import { TrackNavigation } from 'views/welcome/components';
import { DocumentsUploadLayout } from './DocumentsUploadLayout';

const AddressDocumentUpload = ({
    handleSubmit,
    s3GetUploadLink,
    handleDeleteDocument,
    s3UploadLink,
    s3UploadFields,
    fileList,
    fileProgress,
    handleChangeDocumentType,
    onUpload,
    handleProgress,
    isUploading,
    error,
    requiredDocuments,
    missingDocuments,
    docsUploadedNotReq,
    docsUploadedReq,
}) => (
    <AppearRight>
        <Title>
            <FormattedMessage
                id="welcome.Documents.AddressDocumentTitle"
                defaultMessage="Please upload a document that can be used to verify your current address."
            />
        </Title>

        <Row gutter={16} style={{ padding: '5px' }}>
            <DocumentsUploadLayout.Wrapper>
                <Upload
                    accept=".jpg,.jpeg,.png,.gif,.pdf,.doc,.docx,.ppt,.pptx,.odt,.xls,.xlsx,.txt"
                    action={s3UploadLink}
                    data={s3UploadFields}
                    beforeUpload={(file) => s3GetUploadLink(file)}
                    fileList={fileList}
                    onChange={onUpload}
                    onProgress={(event, eventFile) => handleProgress({ event, eventFile })}
                    showUploadList={false}
                    onRemove={(file) => handleDeleteDocument(file)}
                >
                    <Tooltip
                        title={
                            <FormattedMessage
                                id="welcome.Documents.documentsUploadSize"
                                defaultMessage="Maximum file upload size: 10mb"
                            />
                        }
                    >
                        <Button>
                            <UploadOutlined />{' '}
                            <FormattedMessage id="welcome.Documents.upload" defaultMessage="Upload" />
                            <InfoCircleOutlined />
                        </Button>
                    </Tooltip>
                </Upload>
                <Select
                    defaultValue="OTHER"
                    style={{ width: 200, textAlign: 'left' }}
                    onChange={handleChangeDocumentType}
                >
                    <Select.Option value="OTHER">
                        <FormattedMessage id="welcome.Documents.addressDocument" defaultMessage="Address Document" />
                    </Select.Option>
                </Select>
            </DocumentsUploadLayout.Wrapper>
        </Row>
        <Row gutter={16}>
            <DocumentsUploadLayout
                s3UploadLink={s3UploadLink}
                s3UploadFields={s3UploadFields}
                s3GetUploadLink={s3GetUploadLink}
                fileList={fileList}
                fileProgress={fileProgress}
                onUpload={onUpload}
                handleProgress={handleProgress}
                handleDeleteDocument={handleDeleteDocument}
                complete={missingDocuments.length === 0}
                error={error}
                missingDocuments={missingDocuments}
                requiredDocuments={requiredDocuments}
                docsUploadedNotReq={docsUploadedNotReq}
                docsUploadedReq={docsUploadedReq}
            />
        </Row>
        <Row gutter={16}>
            <Col sm={24} lg={{ span: 20, offset: 2 }}>
                <Box size="m">
                    <Text align="center" color="certnGray600">
                        <br />
                        <FormattedMessage
                            id="welcome.Documents.adrressDocuments"
                            defaultMessage="Please ensure your address is clear and unobstructed on the provided document. If we are unable to verify your address, our verification team will reach out requesting supplemental documentation."
                        />
                    </Text>
                </Box>
            </Col>
        </Row>
        <TrackNavigation loading={isUploading} handleSubmit={handleSubmit} />
    </AppearRight>
);

export default AddressDocumentUpload;
