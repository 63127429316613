// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { parsePhoneNumberFromString, parseIncompletePhoneNumber } from 'libphonenumber-js';
import { formatPhoneNumberIntl } from 'react-phone-number-input';

import { get } from 'lodash';

// Components
import { NameAndNumberForm } from './components';

// Modules
import withNavigation from 'views/welcome/modules/WithNavigation';

// Actions & Selectors
import { setTrackPageOrder, patchInformation } from 'views/welcome/WelcomeActions';
import { getIsFetchingSilent, getInformation, getOnboardingType, getSettings } from 'views/welcome/WelcomeSelectors';
import { getUser } from 'base/BaseSelectors';

const mapStateToProps = (state) => ({
    isFetchingSilent: getIsFetchingSilent(state),
    information: getInformation(state),
    onboardingType: getOnboardingType(state),
    settings: getSettings(state),
    user: getUser(state),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            patchInformation,
            setTrackPageOrder,
        },
        dispatch
    );

const propTypes = {
    // Redux Actions:
    patchInformation: PropTypes.func.isRequired,
    setTrackPageOrder: PropTypes.func.isRequired,
    // Redux Store:
    isFetchingSilent: PropTypes.bool.isRequired,
    information: PropTypes.object.isRequired,
    onboardingType: PropTypes.string.isRequired,
    settings: PropTypes.object,
    user: PropTypes.object.isRequired,
};
const defaultProps = {
    settings: {},
};

class Basics extends React.Component {
    state = {
        phone: null,
        errorStatus: null,
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        const phone = get(this.props.information, ['phone']);
        phone && this.setState({ phone: phone.number });
        this.props.setTrackPageOrder(['base']);
    }

    handleUpdateState = (field, value) => this.setState({ [field]: value });

    handleSubmit = (e, validateFields) => {
        e.preventDefault();
        const { phone } = this.state;
        const { handleNextTrack } = this.props; /* WithNavigation */

        validateFields((err, values) => {
            let newErr = err;
            let newValues = { ...values };
            const phoneNumber = phone && parsePhoneNumberFromString(phone, 'CA');
            if (phoneNumber && phoneNumber.isValid()) {
                this.handleUpdateState('errorStatus', 'success');
            } else {
                this.handleUpdateState('errorStatus', 'error');
                newErr = true;
            }

            if (!newErr) {
                newValues = {
                    ...newValues,
                    phone: {
                        number: phoneNumber.formatInternational(),
                        national_number: phoneNumber.nationalNumber,
                        country: phoneNumber.country,
                        calling_code: phoneNumber.countryCallingCode,
                    },
                };
                this.props.patchInformation(newValues).then(handleNextTrack);
            } else {
                // provide failure error
            }
        });
    };

    pages = () => ({
        base: (
            <NameAndNumberForm
                handleSubmit={this.handleSubmit}
                information={this.props.information}
                isFetchingSilent={this.props.isFetchingSilent}
                teamName={get(this.props.settings, ['org_name'])}
                errorStatus={this.state.errorStatus}
                userIsCA={this.props.settings.get_org_country === 'CA'}
                phone={{
                    num: formatPhoneNumberIntl(this.state.phone),
                    update: (num) => {
                        this.handleUpdateState('phone', num ? parseIncompletePhoneNumber(num) : null);
                    },
                }}
            />
        ),
    });

    render() {
        return this.pages()[this.props.trackPageLocation]; /* WithNavigation */
    }
}

Basics.propTypes = propTypes;
Basics.defaultProps = defaultProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withNavigation(Basics)));
