import { Layout, Divider } from 'antd';
import styled from 'styled-components/macro';

export const AdjudicationLayout = styled(Layout)`
    position: fixed;
    right: 0px;
    top: ${(props) => `${props.sidebartopposition}`};
    height: ${(props) => `calc(100vh - ${props.sidebartopposition})`};
    transition: 'top 0.125s ease';
`;

export const InfoBar = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 19px;
    color: ${({ theme }) => theme.color.certnGray500};
    font-weight: 600;
    padding-bottom: 20px;
    white-space: nowrap;
    overflow: hidden;
`;

export const DropdownWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
`;

export const Title = styled.span`
    font-size: ${(props) => props.theme.font.xs};
`;

export const Wrapper = styled.div`
    width: 200px;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
`;

export const StyledDivider = styled(Divider)`
    margin: 1.5px;
    border-top: 2px solid ${({ theme }) => theme.color.certnGray200} !important;
    position: relative;
    top: 10px;
`;
