import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

interface PurposeModalSection {
    section: string;
}
export const PurposeModalSection: React.FC<PurposeModalSection> = ({ section }) => {
    const bulletPoints: ReactNode[] = [];
    let boldText: ReactNode;
    let regularText: ReactNode;

    if (section === 'employment') {
        boldText = <FormattedMessage id="48554.PurposeModalSection.employmentBold" defaultMessage="Employment" />;
        regularText = (
            <FormattedMessage
                id="48554.PurposeModalSection.employmentRegular"
                defaultMessage="should be selected if you need this for:"
            />
        );
        bulletPoints.push(
            <FormattedMessage id="48554.PurposeModalSection.employmentBullets1" defaultMessage="Paid employment" />
        );
        bulletPoints.push(
            <FormattedMessage id="48554.PurposeModalSection.employmentBullets2" defaultMessage="Paid contract work" />
        );
    } else if (section === 'license') {
        boldText = <FormattedMessage id="48554.PurposeModalSection.license" defaultMessage="License" />;
        regularText = (
            <FormattedMessage
                id="48554.PurposeModalSection.licenseRegular"
                defaultMessage="should be selected if you need this check for licensing or industry accreditation, e.g.:"
            />
        );
        bulletPoints.push(
            <FormattedMessage
                id="48554.PurposeModalSection.licenseBullets1"
                defaultMessage="Approved managers license"
            />
        );
        bulletPoints.push(
            <FormattedMessage id="48554.PurposeModalSection.licenseBullets2" defaultMessage="Real estate license" />
        );
        bulletPoints.push(
            <FormattedMessage id="48554.PurposeModalSection.licenseBullets3" defaultMessage="Racing license" />
        );
    } else if (section === 'other') {
        boldText = (
            <FormattedMessage id="48554.PurposeModalSection.other" defaultMessage="Other (Position of Trust/Probity)" />
        );
        regularText = (
            <FormattedMessage
                id="48554.PurposeModalSection.otherRegular"
                defaultMessage="should be selected if you need this check for a purpose other than employment or licensing, e.g.:"
            />
        );
        bulletPoints.push(
            <FormattedMessage id="48554.PurposeModalSection.otherBullets1" defaultMessage="House / pet sitter" />
        );
        bulletPoints.push(
            <FormattedMessage id="48554.PurposeModalSection.otherBullets2" defaultMessage="Board member" />
        );
        bulletPoints.push(
            <FormattedMessage id="48554.PurposeModalSection.otherBullets3" defaultMessage="Insurance claim" />
        );
    }

    return (
        <>
            <b>{boldText}</b> {regularText}
            <br />
            <ul>
                {bulletPoints.map((bp) => (
                    <li>{bp}</li>
                ))}
            </ul>
        </>
    );
};
