import styled from 'styled-components/macro';

export const PurposeFormLabel = styled.label`
    color: #819697;
`;

export const PurposeFormSpan = styled.span`
    color: #818181;
    float: left;
`;
