// Libraries
import React from 'react';
import { Link } from 'react-router-dom';
import { InfoCircleOutlined, LockOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import { Input, Popover, Button } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';

// Components
import Title from 'certn-ui/Title';
import { Box } from 'certn-ui/Layout';
import { ButtonLine } from 'certn-ui/Button';
import { AppearRight } from 'certn-ui/Animate';

// Modules
import { InputTransform } from 'modules';

const SignInForm = ({ handleSubmit, loading, form, intl }) => {
    const startText = <FormattedMessage id="applicant.SignIn.title" defaultMessage="Log in to view your check" />;

    return (
        <AppearRight>
            <Box size="xs">
                <Form onSubmit={(e) => handleSubmit(e, form.validateFields)} style={{ width: '100%' }}>
                    <Title>{startText}</Title>
                    <Form.Item hasFeedback>
                        {form.getFieldDecorator('email', {
                            getValueFromEvent: InputTransform.noSpaces,
                            rules: [
                                {
                                    type: 'email',
                                    message: (
                                        <FormattedMessage
                                            id="error.validation.email.invalid"
                                            defaultMessage="The input is not a valid E-mail!"
                                        />
                                    ),
                                },
                                {
                                    required: true,
                                    message: (
                                        <FormattedMessage
                                            id="error.validation.email.required"
                                            defaultMessage="Please input your E-mail!"
                                        />
                                    ),
                                },
                            ],
                        })(
                            <Input
                                size="large"
                                data-testid="email"
                                placeholder={intl.formatMessage({
                                    id: 'common.emailAddress',
                                    defaultMessage: 'Email Address',
                                })}
                                autoFocus
                                addonBefore={
                                    <Popover
                                        content={
                                            <FormattedMessage id="common.emailAddress" defaultMessage="Email Address" />
                                        }
                                    >
                                        <InfoCircleOutlined />
                                    </Popover>
                                }
                            />
                        )}
                    </Form.Item>
                    <Form.Item>
                        {form.getFieldDecorator('password', {
                            rules: [
                                {
                                    required: true,
                                    message: (
                                        <FormattedMessage
                                            id="error.validation.password.required"
                                            defaultMessage="Please input your Password!"
                                        />
                                    ),
                                },
                            ],
                        })(
                            <Input.Password
                                visibilityToggle
                                size="large"
                                placeholder={intl.formatMessage({
                                    id: 'common.password',
                                    defaultMessage: 'Password',
                                })}
                                addonBefore={
                                    <Popover
                                        content={<FormattedMessage id="common.password" defaultMessage="Password" />}
                                    >
                                        <LockOutlined />
                                    </Popover>
                                }
                            />
                        )}
                    </Form.Item>
                    <ButtonLine>
                        <Button
                            data-testid="submit"
                            type="primary"
                            size="large"
                            certncolor="primary"
                            htmlType="submit"
                            style={{ width: '100%' }}
                            loading={loading}
                        >
                            <FormattedMessage id="applicant.SignIn.loginButton" defaultMessage="Log in" />
                        </Button>
                    </ButtonLine>
                    <Link data-testid="forgot-password" to="/forgot">
                        <FormattedMessage id="applicant.SignIn.forgotPassword" defaultMessage="Forgot your password?" />
                    </Link>
                </Form>
            </Box>
        </AppearRight>
    );
};

export default Form.create()(injectIntl(SignInForm));
