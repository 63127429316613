// Libraries
import { FormattedMessage } from 'react-intl';
import Text from 'certn-ui/Text';
import NoticeFrame from 'certnd/NoticeFrame';

const PrepaidNotice = () => (
    <NoticeFrame>
        <Text align="center" weight="600">
            <FormattedMessage id="welcome.Payment.prepaidNoticeTitle" defaultMessage="Payment details added" />
        </Text>
        <Text align="center">
            <FormattedMessage
                id="welcome.Payment.prepaidNoticeBody"
                defaultMessage="Your Payment details have been added already, click ‘Next’ to continue the process."
            />
        </Text>
    </NoticeFrame>
);

export default PrepaidNotice;
