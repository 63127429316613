// Libraries
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { PrivacyPolicyLink } from 'certn-ui';
import PrivacyNotice from './components/PrivacyNotice';

// Components
import { YesNoConsent } from 'views/welcome/components';
import { AppearRight } from 'certn-ui/Animate';

// Modules
import withNavigation from 'views/welcome/modules/WithNavigation';

// Actions & Selectors
import { setTrackPageOrder, patchInformation } from 'views/welcome/WelcomeActions';
import { getInformation, getOnboardingType } from 'views/welcome/WelcomeSelectors';

const NonCriminalConsent = ({ handleNextTrack }) => {
    const dispatch = useDispatch();
    const isAPIConsentGiven = useSelector(getInformation).non_criminal_consent_given;
    const applicantType = useSelector(getOnboardingType);

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(setTrackPageOrder(['base']));
    }, [dispatch]);

    const handleSubmit = () => {
        dispatch(patchInformation({ non_criminal_consent_given: true })).then(handleNextTrack);
    };

    return (
        <AppearRight>
            <YesNoConsent
                signatureConsent
                isAPIConsentGiven={isAPIConsentGiven}
                handleSubmit={handleSubmit}
                warningTitle={
                    <FormattedMessage
                        id="welcome.NonCriminalConsent.warningTitle"
                        defaultMessage="We need your consent before we can collect and verify your personal information. This is a security and safety measure meant to protect your personal information."
                    />
                }
                mainTitle={
                    <FormattedMessage
                        id="welcome.NonCriminalConsent.title"
                        defaultMessage="We need to obtain your consent before we can perform the requested background check services"
                    />
                }
                mainBody={
                    applicantType === 'HR' ? (
                        <FormattedMessage
                            id="welcome.NonCriminalConsent.nonCriminalConsentHR"
                            defaultMessage='I understand and acknowledge that I am agreeing to this consent electronically and by doing so, to the extent permitted by applicable law:
                            {br}
                            (1) I hereby authorize Certn (and its designated representatives, as applicable) to collect, use, disclose and independently verify my Personal Information which may include, but is not limited to, my name, address, email address, date of birth, my education, employment history, earnings history, credit history, motor vehicle history, public criminal history, publicly available social media information, professional credentials, and my right to work (collectively, "Personal Information") for the purposes of delivering a background screening report in relation to my application for employment with the Company.
                            {br}
                            (2) I acknowledge and agree that the Company may collect, use and disclose the Personal Information for the purposes of evaluating my suitability for employment with the Company;
                            {br}
                            (3) I also authorize Certn to (i) contact the relevant law enforcement agencies, educational institutions, information service bureaus, credit bureaus, public websites, data repositories, motor vehicle records agencies, as well as all other accessible private and public sector archives (including any other person, organization, or agency) and (ii) receive copies of verified results on me through any reasonable means including in writing, electronic transmission or over the phone;
                            {br}
                            (4) I understand and agree that, where my personal history requires background verifications to be carried out in countries where I have worked, studied, or resided, my Personal Information may be sent and verified in those relevant countries.
                            {br}
                            (5) I agree that an electronic copy of this consent is equally effective as an original signature for the purposes of validity, enforceability, and admissibility and authorize Certn to export this Background Screening Consent in a PDF document and present it to any of my referees which may request a proof of my consent for the purposes set out above;
                            {br}
                            (6) I acknowledge that I have read and understood Certn’s {privacyPolicy} and hereby confirm that my consent is fully informed and freely given and I am aware that I can withdraw it at any time.
                            {br}
                            (7) I acknowledge that if I do not consent to Certn’s and/or the Company’s collection, use and disclosure of the information, this may result in my conditional offer being void. Without a conditional offer, I will not have a relationship with the Company for any purposes; and
                            {br}
                            (8) I acknowledge that if I am denied employment wholly or partly because of information received from a consumer reporting agency or credit information obtained from a person other than a consumer reporting agency, if required by law, the Company and/or Certn will notify me of that fact and, upon request by me within the time limits required by the applicable legislation, shall inform me of the nature and source of the information where the information is furnished by a person other than a consumer reporting agency; or of the name and address of the consumer reporting agency, where the information is furnished by a consumer reporting agency, and any other information required by law.
                            {br}
                            If you have any questions about the collection, use, disclosure, or storage of your Personal Information or if you would like to request more information about Certn’s privacy policies and procedures, please contact Certn’s Privacy Officer at 1-844-987-0690 or {privacyEmail}.'
                            values={{
                                br: (
                                    <>
                                        <br />
                                        <br />
                                    </>
                                ),
                                privacyPolicy: <PrivacyPolicyLink />,
                                privacyEmail: (
                                    <a style={{ display: 'contents' }} href="mailto:privacy@certn.co">
                                        privacy@certn.co
                                    </a>
                                ),
                            }}
                        />
                    ) : (
                        <FormattedMessage
                            id="welcome.NonCriminalConsent.nonCriminalConsentPM"
                            defaultMessage='I understand and acknowledge that I am agreeing to this consent electronically and by doing so, to the extent permitted by applicable law:
                            {br}
                            (1) I hereby authorize Certn (and its designated representatives, as applicable) to collect and independently verify my Personal Information which may include, but is not limited to, my name, address, email address, date of birth, my references, earnings history, credit history, public criminal history, publicly available social media information, and professional credentials (collectively, "Personal Information") for the purposes of delivering a background screening report in relation to my tenancy;
                            {br}
                            (2) I also authorize Certn to (i) contact the relevant law enforcement agencies, information service bureaus, credit bureaus, public websites, data repositories, as well as all other accessible private and public sector archives (including any other person, organization, or agency) and (ii) receive copies of verified results on me through any reasonable means including in writing, electronic transmission or over the phone;
                            {br}
                            (3) I understand and agree that, where my personal history requires background verifications to be carried out in countries where I have worked and/or resided, my Personal Information may be sent and verified in those relevant countries;
                            {br}
                            (4) I agree that an electronic copy of this consent is equally effective as an original signature for the purposes of validity, enforceability, and admissibility and authorize Certn to export this Background Screening Consent in a PDF document and present it to any of my referees which may request a proof of my consent for the purposes set out above;
                            {br}
                            (5) I acknowledge that I have read and understood Certn’s {privacyPolicy} and hereby confirm that my consent is fully informed and freely given and I am aware that I can withdraw it at any time.'
                            values={{
                                br: (
                                    <>
                                        <br />
                                        <br />
                                    </>
                                ),
                                privacyPolicy: <PrivacyPolicyLink />,
                            }}
                        />
                    )
                }
                mainSubTitle={<PrivacyNotice />}
            />
        </AppearRight>
    );
};

export default withNavigation(NonCriminalConsent);
