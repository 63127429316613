import { get } from 'lodash';
import { createSelector } from 'reselect';
import { STATEPATH as PARENT_STATEPATH } from 'views/manager/views/pm/PropertyManagerConstants';

const STATEPATH = `${PARENT_STATEPATH}.listings`;

const getLocalState = (state) => get(state, STATEPATH);

export const getIsFetching = (state) => getLocalState(state).isFetching;
export const getIsFetchingSilent = (state) => getLocalState(state).isFetchingSilent;
export const getError = (state) => getLocalState(state).error;
export const getListings = (state) => getLocalState(state).listings;
export const getAllListings = (state) => getLocalState(state).allListings;
export const getAllListingNames = createSelector(getAllListings, (listings) =>
    listings.map((listing) => listing.property.building)
);
export const getAllListingIds = createSelector(getAllListings, (listings) => listings.map((listing) => listing.id));
export const getListing = (state) => getLocalState(state).listing;
export const getNewListing = (state) => getLocalState(state).newListing;
export const getApplication = (state) => getLocalState(state).application;
export const getTeamMembers = (state) => getLocalState(state).teamMembers;
export const getTeamMemberDict = createSelector(getTeamMembers, (teamMembers) =>
    teamMembers.reduce((obj, item) => {
        obj[item.email] = item.id;
        return obj;
    }, {})
);

// List Selectors
export const getIsActive = (state) => getLocalState(state).isActive;
export const getSearchBy = (state) => getLocalState(state).searchBy;
export const getOrdering = (state) => getLocalState(state).ordering;
export const getReverseOrder = (state) => getLocalState(state).reverseOrder;
export const getFilterBy = (state) => getLocalState(state).filterBy;
export const getFilterByEnabled = createSelector(getFilterBy, (filters) => {
    Object.keys(filters).forEach((key) => !filters[key] && delete filters[key]);
    return filters;
});
export const getPageSize = (state) => getLocalState(state).pageSize;
export const getCurrentPage = (state) => getLocalState(state).currentPage;
export const getNumListings = (state) => getLocalState(state).numListings;
export const getNumAllListings = (state) => getLocalState(state).numAllListings;
