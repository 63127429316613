import styled from 'styled-components';
import { withStopProps } from 'modules/StopProps';
import { Typography } from 'antd';

const { Title } = Typography;

const StyledTitle = styled(withStopProps(Title, ['disableGutter', 'strong', 'headerLeft']))`
    font-weight: ${(props) => (props.strong ? 600 : 400)} !important;
    margin-bottom: ${(props) => (props.disableGutter ? '0px !important' : '20px !important')};
    ${(props) => props.headerLeft && 'float: left' && 'text-align: left'}
`;

const H1 = styled(StyledTitle).attrs(() => ({ level: 1 }))`
    font-size: 26px !important;
    line-height: 34px !important;
`;
const H2 = styled(StyledTitle).attrs(() => ({ level: 2 }))`
    font-size: 22px !important;
    line-height: 28px !important;
`;
const H3 = styled(StyledTitle).attrs(() => ({ level: 3 }))`
    font-size: 18px !important;
    line-height: 24px !important;
`;
const H4 = styled(StyledTitle).attrs(() => ({ level: 4 }))`
    font-size: 14px !important;
    line-height: 18px !important;
`;

const TypogHeading = {
    H1,
    H2,
    H3,
    H4,
};
export default TypogHeading;
