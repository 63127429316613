// Libraries
import styled from 'styled-components/macro';
import { FormattedMessage } from 'react-intl';
import { intl } from 'components/GlobalProvider';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useReducer, useState, useMemo } from 'react';
import { Button, message, Table } from 'antd';
import { useParams } from 'react-router-dom';

// Components
import Form from 'certnd/Form';
import Typography from 'certnd/Typography';
import { ActionsCell, PackageModal } from './components';
import { ErrorAlertAPI } from 'certn-ui/ErrorAlert';

// utils
import { selectBackwardsCompatibleRequestAndTitles } from 'views/manager/features/ScreenApplicant/components/ScreenApplicant/MicroServiceContent';
import { useTeam } from 'hooks/queryHooks/useTeam';

// Actions & Selectors
import { getIsFetching } from 'views/manager/views/settings/SettingsSelectors';
import {
    createIntegrationPackage,
    deleteIntegrationPackage,
    resetIntegrationPackageOptions,
    setIntegrationPackageOptions,
    updateIntegration,
    updateIntegrationPackage,
} from 'views/manager/views/settings/SettingsActions';

// Constants
import { CHECK_REQUEST } from 'base/BaseConstants';
import { PackagesContext } from './Packages.context';
import Loader from 'certn-ui/Loader';

const { Heading } = Typography;
const FormBlock = styled(Form.Block)`
    margin: 0 !important;
    max-width: 100% !important;
`;
const PackageTitle = styled.div`
    display: flexbox;
    flex-wrap: wrap;
    justify-content: space-between;
`;

const Packages = (props) => {
    const {
        integrationType,
        integrationName,
        form,
        packages,
        active,
        defaultPackageId,
        setDefaultPackageId,
        useDefaultColumns,
        allowQuickscreen,
    } = props;
    const { validateFields, getFieldValue } = form;
    const { teamId } = useParams();
    const { team, teamLoading } = useTeam({ id: teamId });
    const dispatch = useDispatch();
    const isFetching = useSelector(getIsFetching);
    const backwardsCompatibleRequestAndTitles = useSelector(selectBackwardsCompatibleRequestAndTitles);
    // Filters services that are not available for anyone to run.
    const servicesList = useMemo(() => {
        if (!team) {
            return [];
        }

        return backwardsCompatibleRequestAndTitles.filter(
            (service) =>
                ![CHECK_REQUEST.VULNERABLE_SECTOR_CRIMINAL_RECORD_CHECK, CHECK_REQUEST.VACCINATION_CHECK].includes(
                    service.request
                )
        );
    }, [team, backwardsCompatibleRequestAndTitles]);
    const superteamIntegration = ['ICIMS', 'FOUNTAIN'].includes(integrationType);

    const [editingPackage, setEditingPackage] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
        id: undefined,
        name: undefined,
        services: undefined,
        countries: undefined,
        employer_questionaire_id: undefined,
        employer_references_min: undefined,
        employer_references_max: undefined,
        address_references_min: undefined,
        address_references_max: undefined,
        employment_verification_min: undefined,
        employment_verification_max: undefined,
        education_verification_min: undefined,
        education_verification_max: undefined,
        credential_verification_min: undefined,
        credential_verification_max: undefined,
        credential_verification_level: undefined,
        education_verification_level: undefined,
        employer_references_years: undefined,
        address_references_years: undefined,
        employment_verification_years: undefined,
        address_references_years_or_individually: undefined,
        employer_references_years_or_individually: undefined,
        employment_verification_years_or_individually: undefined,
        international_follow_up_applicant_country_select: false,
        international_follow_up_cost_threshold: undefined,
    });

    const [modalVisible, setModalVisibility] = useState(false);

    const isQuickscreen = form.getFieldValue('is_quickscreen');
    useEffect(() => {
        setEditingPackage({
            is_quickscreen: isQuickscreen,
        });
    }, [isQuickscreen, setEditingPackage]);

    const toggleModal = () => {
        setModalVisibility(!modalVisible);
    };

    const defaultServices = {};
    servicesList.forEach((service) => {
        defaultServices[service.request] = false;
    });

    // save a new package to the database
    const savePackage = (e) => {
        e.preventDefault();
        validateFields((err, values) => {
            if (err) return;
            const exportData = {
                name: values.package_name.trim(),
                integration: integrationType,
                requested_countries: getFieldValue('requested_countries'),
                employer_questionaire_id: values.employer_questionaire_id,
                employer_references_min: values.employer_references_min,
                employer_references_max: values.employer_references_max,
                address_references_min: values.address_references_min,
                address_references_max: values.address_references_max,
                employment_verification_min: values.employment_verification_min,
                employment_verification_max: values.employment_verification_max,
                education_verification_min: values.education_verification_min,
                education_verification_max: values.education_verification_max,
                credential_verification_min: values.credential_verification_min,
                credential_verification_max: values.credential_verification_max,
                credential_verification_level: values.credential_verification_level,
                education_verification_level: values.education_verification_level,
                employer_references_years: values.employer_references_years,
                address_references_years: values.address_references_years,
                employment_verification_years: values.employment_verification_years,
                address_references_years_or_individually: values.address_references_years_or_individually,
                employer_references_years_or_individually: values.employer_references_years_or_individually,
                employment_verification_years_or_individually: values.employment_verification_years_or_individually,
                international_follow_up_applicant_country_select: false,
                international_follow_up_cost_threshold: undefined,
                is_quickscreen: values.is_quickscreen,
                purpose_of_check: values.purpose_of_check,
                position_title: values.position_title,
                place_of_work: values.place_of_work,
                license_type: values.license_type,
                issuing_authority: values.issuing_authority,
                reason_for_check: values.reason_for_check,
                state_or_territory: values.state_or_territory,
                location_of_work: values.location_of_work,
                ...defaultServices,
            };
            if (superteamIntegration) {
                exportData.superteam = team.superteam;
            } else {
                exportData.team = team.id;
            }
            getFieldValue('requested_packages').map((service) => {
                exportData[service] = true;
            });
            if (editingPackage.id) {
                dispatch(
                    updateIntegrationPackage({
                        teamId: team.id,
                        integrationType,
                        packageID: editingPackage.id,
                        data: exportData,
                    })
                )
                    .then(() =>
                        message.success(
                            intl.formatMessage({
                                id: '22572.Packages.PackageChanged',
                                defaultMessage: 'Package Changes Saved',
                            })
                        )
                    )
                    .catch((err) => ErrorAlertAPI(err));
            } else {
                dispatch(createIntegrationPackage({ integrationType, data: exportData }))
                    .then(() =>
                        message.success(
                            intl.formatMessage({
                                id: '22572.Packages.PackageCreated',
                                defaultMessage: 'Package Created',
                            })
                        )
                    )
                    .catch((err) => ErrorAlertAPI(err));
            }
            handleModalCancel();
        });
    };

    const handleModalCancel = () => {
        setEditingPackage({
            id: undefined,
            name: undefined,
            services: undefined,
            countries: undefined,
            questionaire: undefined,
        });
        dispatch(resetIntegrationPackageOptions());
        toggleModal();
    };

    // opens the modal with populated fields
    const editPackage = (packageID) => {
        const [packageInfo] = packages.filter((pkg) => pkg.id === packageID);
        dispatch(setIntegrationPackageOptions({ options: packageInfo }));
        const requestedServices = [];
        Object.keys(packageInfo).forEach((service) => {
            if (service.startsWith('request_') && packageInfo[service] === true) {
                requestedServices.push(service);
            }
        });
        setEditingPackage({
            ...packageInfo,
            id: packageID,
            services: requestedServices,
            countries: packageInfo.requested_countries,
        });
        toggleModal();
    };

    // makes selected package the default
    const makeDefault = (packageID) => {
        if (active) {
            const packageUpdate = { default_package: packageID };
            dispatch(updateIntegration({ teamId: team.id, integrationName, data: packageUpdate })).then(
                () =>
                    message.success(
                        intl.formatMessage({
                            id: '22572.Packages.DefaultPackageUpdated',
                            defaultMessage: 'Default Package Successfully Updated',
                        })
                    ),
                (e) => ErrorAlertAPI(e)
            );
        } else {
            setDefaultPackageId(packageID);
        }
    };
    // deletes specified package
    const deletePackage = (packageID) => {
        if (packages.length <= 1 && active) {
            message.error(
                intl.formatMessage({
                    id: '22572.Packages.DeleteLastPackage',
                    defaultMessage:
                        'Package cannot be deleted. At least one package must be present to keep integration enabled.',
                })
            );
        } else if (packageID === defaultPackageId) {
            message.error(
                intl.formatMessage({
                    id: '22572.Packages.DeleteDefaultPackage',
                    defaultMessage: 'Cannot delete default package. Set a different default before deleting.',
                })
            );
        } else {
            dispatch(deleteIntegrationPackage({ teamId: team.id, integrationId: packageID, integrationType }))
                .then(() =>
                    message.success(
                        intl.formatMessage({
                            id: '22572.Packages.DeleteSuccess',
                            defaultMessage: 'Package deleted',
                        })
                    )
                )
                .catch((error) => ErrorAlertAPI(error));
        }
    };

    // Table columns and actioncell settings for when the integration requires a default package.
    const defaultColumns = [
        {
            title: intl.formatMessage({ id: '22572.Packages.TableName', defaultMessage: 'Name' }),
            dataIndex: 'name',
            render: (text) => <a>{text}</a>,
            width: '70%',
        },
        {
            title: intl.formatMessage({ id: '22572.Packages.TableDefault', defaultMessage: 'Default' }),
            dataIndex: 'defaultCheck',
            align: 'center',
            width: '20%',
            render: (text, row) => (row.id === defaultPackageId ? '✓' : ''),
        },
        {
            title: intl.formatMessage({ id: 'common.actions', defaultMessage: 'Actions' }),
            dataIndex: 'actions',
            width: '10%',
            render: (text, row) => (
                <ActionsCell
                    row={row}
                    defaultPackage={defaultPackageId}
                    editTemplate={editPackage}
                    makePackageDefault={makeDefault}
                    deletePackage={deletePackage}
                    canDelete={packages.length > 1}
                    integrationHasDefault
                />
            ),
        },
    ];

    // Table columns and actioncell settings for when the integration doesn't require a default package.
    const noDefaultColumns = [
        {
            title: intl.formatMessage({ id: '22572.Packages.TableName', defaultMessage: 'Name' }),
            dataIndex: 'name',
            render: (text) => <a>{text}</a>,
            width: '70%',
        },
        {
            title: intl.formatMessage({ id: 'common.actions', defaultMessage: 'Actions' }),
            dataIndex: 'actions',
            width: '10%',
            render: (text, row) => (
                <ActionsCell
                    row={row}
                    defaultPackage={defaultPackageId}
                    editTemplate={editPackage}
                    makePackageDefault={makeDefault}
                    deletePackage={deletePackage}
                    canDelete
                    integrationHasDefault={false}
                />
            ),
        },
    ];

    if (teamLoading) {
        return <Loader />;
    }

    return (
        <PackagesContext.Provider value={{ form, packages, editingPackage, allowQuickscreen }}>
            <FormBlock>
                <PackageTitle>
                    <Heading.H3>
                        <FormattedMessage
                            id="22572.Packages.IntegrationPackages"
                            defaultMessage="Integration Packages"
                        />
                    </Heading.H3>
                    <Button style={{ marginBottom: '10px' }} type="secondary" onClick={toggleModal}>
                        <FormattedMessage id="22572.Packages.NewButton" defaultMessage="Create New Package" />
                    </Button>
                </PackageTitle>
                {modalVisible && (
                    <PackageModal
                        modalVisible={modalVisible}
                        savePackage={savePackage}
                        handleModalCancel={handleModalCancel}
                        isFetching={isFetching}
                    />
                )}
                {packages.length > 0 ? (
                    <Table
                        rowKey={(integrationPackage) => integrationPackage.id}
                        columns={useDefaultColumns ? defaultColumns : noDefaultColumns}
                        dataSource={packages}
                        size="middle"
                        pagination={false}
                    />
                ) : (
                    <Heading.H4>
                        <FormattedMessage
                            id="22572.Packages.Instructions"
                            defaultMessage="Click 'Create New Package' button above to add a default package."
                        />
                    </Heading.H4>
                )}
            </FormBlock>
        </PackagesContext.Provider>
    );
};

export default Packages;
