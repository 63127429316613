import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import Typography from 'certnd/Typography';
import styles from 'styles/styles';

const { Text, Heading } = Typography;

const FormDescriptionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-bottom: ${(props) => (props.hideBorder ? 'none' : `solid ${styles.color.certnGray400} 1px`)};
    width: 100%;
    padding-bottom: 20px;
    margin-bottom: ${(props) => (props.hideBorder ? 'none' : '20px')};
`;

FormDescription.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    titleProps: PropTypes.object,
    descriptionProps: PropTypes.object,
    hideBorder: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
};
FormDescription.defaultProps = {
    title: undefined,
    titleProps: {},
    description: undefined,
    descriptionProps: {},
    hideBorder: false,
    children: null,
};

export default function FormDescription(props) {
    const { title, description, titleProps, descriptionProps, hideBorder } = props;
    return (
        <FormDescriptionWrapper hideBorder={hideBorder}>
            {title && (
                <Heading.H4 disableGutter {...titleProps}>
                    {title}
                </Heading.H4>
            )}
            {description ? <Text.Secondary {...descriptionProps}>{description}</Text.Secondary> : null}
            {props.children}
        </FormDescriptionWrapper>
    );
}
