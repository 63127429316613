import React from 'react';
import styled from 'styled-components/macro';
import { Card } from 'antd';
import Button from 'certn-ui/Button';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { getSkinColour } from 'base/BaseSelectors';

const styles = {
    loginCard: {
        margin: '0 auto',
        textAlign: 'center',
        maxWidth: 420,
    },
};

const Unsubscribed = (props) => {
    const skinColour = useSelector(getSkinColour);
    const PrimaryH2 = styled.h2`
        font-weight: lighter;
        padding-top: 24px;
        color: ${skinColour};
    `;

    let conditionalText = (
        <FormattedMessage
            id="fedbf.Unsubscribed.conditionalText"
            defaultMessage="You've been unsubscribed from all notification emails"
        />
    );

    if (props.errors) {
        conditionalText = props.errors.detail;
    }
    if (props.location.search) {
        const { type } = queryString.parse(props.location.search);
        if (type === 'all') {
            conditionalText = (
                <FormattedMessage
                    id="fedbf.Unsubscribed.conditionalText"
                    defaultMessage="You've been unsubscribed from all notification emails"
                />
            );
        } else if (type === 'notifications') {
            conditionalText = (
                <FormattedMessage
                    id="fedbf.Unsubscribed.conditionalText"
                    defaultMessage="You've been unsubscribed from all notification emails"
                />
            );
        }
    }
    return (
        <div className="container">
            <Card style={styles.loginCard}>
                <PrimaryH2>{conditionalText}</PrimaryH2>
                <Button type="primary" certncolor="primary" onClick={() => props.history.push('/')}>
                    <FormattedMessage id="fedbf.Unsubscribed.signIn" defaultMessage="SIGN IN" />
                </Button>
            </Card>
        </div>
    );
};

export default withRouter(Unsubscribed);
