// Libraries
import React, { useState } from 'react';
import { message } from 'antd';
import { bindActionCreators, Dispatch, Store } from 'redux';
import { connect } from 'react-redux';
import { Form as AntdForm } from '@ant-design/compatible';
import { FormattedMessage } from 'react-intl';
import { intl } from 'components/GlobalProvider';
import { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form';

// Components
import Form from 'certnd/Form';
import Typography from 'certnd/Typography';
import { ErrorAlertAPI } from 'certn-ui/ErrorAlert';
import { Notifications, WaitingPeriod } from './components';

// Actions & Selectors
/* eslint-disable no-shadow */
import { updateSettings } from 'views/manager/views/settings/SettingsActions';
import { getCurrentTeam } from 'views/manager/views/settings/SettingsSelectors';
import { getTeam, getUserModeIsHR } from 'base/BaseSelectors';

// Types
import { Team } from 'types';
import { TeamSettingsType, UpdateSettingsFunction } from './types';

const { Heading } = Typography;

const mapStateToProps = (state: Store) => ({
    currentTeamSettings: getCurrentTeam(state),
    team: getTeam(state),
    userModeIsHR: getUserModeIsHR(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            updateSettings,
        },
        dispatch
    );

interface PropTypes {
    form: WrappedFormUtils<unknown>;
    currentTeamSettings: TeamSettingsType;
    team: Team;
    userModeIsHR: boolean;
    updateSettings: UpdateSettingsFunction;
}

const AdverseAction: React.FC<PropTypes> = ({ form, currentTeamSettings, team, userModeIsHR, updateSettings }) => {
    const [defaultWaitingPeriod, setDefaultWaitingPeriod] = useState(
        currentTeamSettings?.adverse_action_waiting_period
    );

    const validateFormFields = async (values: TeamSettingsType) => {
        if (!values.enable_adverse_action_manual_followup) {
            const waitingPeriod = values.adverse_action_waiting_period;
            const followupWaitingPeriod = values.adverse_action_manual_followup_waiting_period;
            if (followupWaitingPeriod && waitingPeriod && followupWaitingPeriod > waitingPeriod) {
                values.adverse_action_manual_followup_waiting_period = waitingPeriod;
                form.setFieldsValue({ adverse_action_manual_followup_waiting_period: waitingPeriod });
            }
        }

        try {
            await updateSettings(values as TeamSettingsType, team.id);
            message.success(
                intl.formatMessage({
                    id: 'common.settingsSavedSuccessfully',
                    defaultMessage: 'Settings saved successfully.',
                })
            );
        } catch (error: unknown) {
            ErrorAlertAPI(error);
        }
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        form.validateFieldsAndScroll((errors, values) => {
            if (errors) return;

            validateFormFields(values as TeamSettingsType);
        });
    };

    return (
        <AntdForm onSubmit={handleSubmit}>
            <Heading.H1 data-testid="title">
                <FormattedMessage id="settings.AdverseAction.PageTitle" defaultMessage="Adverse Action" />
            </Heading.H1>
            <Form.Block>
                {userModeIsHR && (
                    <WaitingPeriod
                        form={form}
                        waitingPeriod={currentTeamSettings?.adverse_action_waiting_period}
                        setDefaultWaitingPeriod={setDefaultWaitingPeriod}
                    />
                )}
                <Notifications
                    form={form}
                    defaultWaitingPeriod={defaultWaitingPeriod}
                    enableFollowup={currentTeamSettings?.enable_adverse_action_manual_followup}
                    followupWaitingPeriod={currentTeamSettings?.adverse_action_manual_followup_waiting_period}
                />
                <Form.Button>
                    <FormattedMessage id="common.save" defaultMessage="Save" />
                </Form.Button>
            </Form.Block>
        </AntdForm>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(AntdForm.create()(AdverseAction));
