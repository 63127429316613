// Libraries
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// UI Components
import { LayoutBody } from 'certn-ui/Layout';
import RouteErrorBoundary from 'certn-ui/RouteErrorBoundary';
import { ScreenApplicant, Applicant } from 'views/manager/features/ScreenApplicant/';

// Actions & Selectors
import { updateUserIsNew, setUserMode } from 'base/BaseActions';
import { getUserInfo } from 'base/BaseSelectors';
import { fetchSettings } from 'views/manager/views/settings/SettingsActions';
import { servicesSelected } from 'views/manager/features/ScreenApplicant/ScreenApplicantSelectors';

// React Router & Routes
import { Switch, Redirect, withRouter, Route } from 'react-router-dom';
import Routes from 'Routes';
import Applications from './views/applications/Applications';
import Postings from './views/postings/Postings';

// Actions & Selectors
import { sendApplications, fetchSpecializations, fetchSkills, fetchDegrees } from './views/postings/PostingsActions';
import { getIsFetchingSilent, getError, getApplication } from './views/postings/PostingsSelectors';

const mapStateToProps = (state) => ({
    application: getApplication(state),
    isFetchingSilent: getIsFetchingSilent(state),
    isError: getError(state),
    user: getUserInfo(state),
    servicesSelected: servicesSelected(state),
});
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchSettings,
            sendApplications,
            fetchSpecializations,
            fetchDegrees,
            fetchSkills,
            updateUserIsNew,
            setUserMode,
        },
        dispatch
    );

const propTypes = {
    // redux actions
    fetchSettings: PropTypes.func.isRequired,
    sendApplications: PropTypes.func.isRequired,
    setUserMode: PropTypes.func.isRequired,
    // redux state
    application: PropTypes.object.isRequired,
    isFetchingSilent: PropTypes.bool.isRequired,
    error: PropTypes.object,
    user: PropTypes.object.isRequired,
    servicesSelected: PropTypes.bool,
};
const defaultProps = {
    error: {},
    servicesSelected: false,
};

class HrManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sendApplicationsVisible: false,
            dontShowModalCheckbox: false,
            modalVisible: false,
        };
        this.props.setUserMode('HR');
        this.props.fetchSettings();
        this.props.fetchDegrees();
        this.props.fetchSpecializations();
        this.props.fetchSkills();
    }

    changeCheckboxState = () => {
        this.setState((prevState) => ({ dontShowModalCheckbox: !prevState.dontShowModalCheckbox }));
    };

    closeModal = () => {
        this.setState({ modalVisible: false });

        if (this.state.dontShowModalCheckbox) {
            this.props.updateUserIsNew();
        }
    };

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (nextProps.user !== this.props.user) {
            this.setState({ modalVisible: nextProps.user.is_new });
        }
    };

    render() {
        return (
            <>
                <LayoutBody>
                    <RouteErrorBoundary>
                        <Switch>
                            <Route exact path="/hr/screen" component={ScreenApplicant} />
                            <Route
                                exact
                                path="/hr/screen/applicant"
                                render={(props) =>
                                    this.props.servicesSelected ? (
                                        <Applicant {...props} />
                                    ) : (
                                        <Redirect to="/hr/screen" />
                                    )
                                }
                            />
                            <Route exact path="/hr/package/:packageID" component={ScreenApplicant} />
                            <Route path="/hr/applications" component={Applications} />
                            <Routes.Pro userMode="hr" path="/hr/packages" component={Postings} />
                            <Redirect to="/hr/applications" />
                        </Switch>
                    </RouteErrorBoundary>
                </LayoutBody>
            </>
        );
    }
}

HrManager.propTypes = propTypes;
HrManager.defaultProps = defaultProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HrManager));
