// Libraries
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { message } from 'antd';
import { intl } from 'components/GlobalProvider';

// Components
import { RightToWorkForm } from './components';

// Modules
import withNavigation from 'views/welcome/modules/WithNavigation';

// Actions & Selectors
import {
    setTrackPageOrder,
    patchInformation,
    fetchSignedS3Link,
    confirmUploadSuccessful,
    deleteDocument,
    fetchRightToWorkDocumentTypes,
} from 'views/welcome/WelcomeActions';
import {
    getInformation,
    getIsFetchingSilent,
    getS3UploadLink,
    getS3UploadFields,
    getPreparedDocument,
    getDefaultFileList,
    getOnboardingType,
    getSettings,
    getApplicant,
    getDocuments,
    getTrackOrder,
} from 'views/welcome/WelcomeSelectors';

// Constants
import { DOCUMENT_SUBTYPE } from './RightToWorkConstants';

const mapStateToProps = (state) => ({
    information: getInformation(state),
    isFetchingSilent: getIsFetchingSilent(state),
    s3UploadLink: getS3UploadLink(state),
    s3UploadFields: getS3UploadFields(state),
    preparedDocument: getPreparedDocument(state),
    fileList: getDefaultFileList(state),
    onboardingType: getOnboardingType(state),
    settings: getSettings(state),
    applicant: getApplicant(state),
    documents: getDocuments(state),
    tracks: getTrackOrder(state),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setTrackPageOrder,
            patchInformation,
            fetchSignedS3Link,
            confirmUploadSuccessful,
            deleteDocument,
            fetchRightToWorkDocumentTypes,
        },
        dispatch
    );

const propTypes = {
    // Redux Actions:
    patchInformation: PropTypes.func.isRequired,
    setTrackPageOrder: PropTypes.func.isRequired,
    fetchSignedS3Link: PropTypes.func.isRequired,
    confirmUploadSuccessful: PropTypes.func.isRequired,
    deleteDocument: PropTypes.func.isRequired,
    // Redux Store:
    information: PropTypes.object.isRequired,
    isFetchingSilent: PropTypes.bool.isRequired,
    loginError: PropTypes.object,
    s3UploadLink: PropTypes.string,
    s3UploadFields: PropTypes.object,
    preparedDocument: PropTypes.object,
    fileList: PropTypes.array,
    settings: PropTypes.object,
    documents: PropTypes.array,
    tracks: PropTypes.array,
};
const defaultProps = {
    s3UploadLink: undefined,
    s3UploadFields: undefined,
    preparedDocument: undefined,
    settings: {},
    fileList: [],
    loginError: null,
    documents: [],
    tracks: [],
};

class RightToWork extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false,
            rtwFileList: [],
            fileProgress: {},
            isUploading: false,
            document: {},
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        const { fileList, documents } = this.props;
        const rtwFileList = fileList.filter((file) => file.subtype === DOCUMENT_SUBTYPE);
        const [document] = documents.filter((doc) => doc.document_subtype === DOCUMENT_SUBTYPE);

        this.props.fetchRightToWorkDocumentTypes().then((documentTypes) => this.setState({ documentTypes }));
        this.props.setTrackPageOrder(['base']);

        this.setState({
            rtwFileList,
            document,
            fileProgress: rtwFileList.reduce((acc, file) => ({ ...acc, [file.type]: 100 }), {}),
        });
    }

    confirmUploadSuccessful = async (file) => {
        const response = await fetch(file.url);

        if (response.ok) {
            await this.props.confirmUploadSuccessful(file.uid);
        } else {
            message.error(
                intl.formatMessage(
                    {
                        id: 'welcome.Documents.errorUploadingDocument',
                        defaultMessage: 'Error uploading document: {name}',
                    },
                    { name: file.name }
                )
            );
            await this.handleDeleteDocument(file);
        }

        await this.props.patchInformation();
    };

    onUpload = () => {
        const { rtwFileList } = this.state;
        const { preparedDocument } = this.props;

        if (preparedDocument) {
            if (rtwFileList.some((item) => item.name === preparedDocument.file_name)) {
                return;
            }

            const newFile = {
                uid: preparedDocument.id,
                name: preparedDocument.file_name,
                status: 'done',
                url: preparedDocument.url,
                type: preparedDocument.document_type,
                subtype: preparedDocument.document_subtype,
            };

            this.setState({ rtwFileList: [...rtwFileList, newFile] }, () => this.props.patchInformation());
        }
    };

    truncateFileName = (name) => (name.length > 20 ? `${name.substring(0, 20)}...` : name);

    // called periodically as upload progresses
    handleProgress = async ({ event, eventFile, doc }) => {
        const { fileProgress } = this.state;
        const { preparedDocument } = this.props;
        const percent = Math.round(event.percent);
        if (percent === 100) {
            message.success(
                intl.formatMessage(
                    { id: 'welcome.Documents.uploadSuccessful', defaultMessage: '{fileName} uploaded successfully!' },
                    { fileName: this.truncateFileName(eventFile.name) }
                )
            );

            await this.confirmUploadSuccessful({ ...eventFile, uid: preparedDocument.id });
        }

        const updatedFileProgress = { ...fileProgress, [doc]: percent };
        const isUploading = Object.keys(updatedFileProgress).some((key) => updatedFileProgress[key] !== 100);

        this.setState({ fileProgress: updatedFileProgress, isUploading });
    };

    getOtherDocuments = () => this.props.fileList.filter((doc) => doc.subtype !== DOCUMENT_SUBTYPE);

    documentTypeValid = (fileType) => /jpg|jpeg|png|pdf|doc|docx|odt/.test(fileType);

    s3GetUploadLink = (file, documentType) => {
        const { rtwFileList } = this.state;

        if (rtwFileList.map((item) => item.name).includes(file.name)) {
            message.error(
                intl.formatMessage({ id: 'welcome.Documents.duplicateName', defaultMessage: 'Duplicate file name' })
            );
            return false;
        }

        if (this.documentTypeValid(file.type)) {
            return this.props.fetchSignedS3Link(file, documentType, DOCUMENT_SUBTYPE);
        }

        message.error(
            intl.formatMessage({
                id: 'welcome.Documents.errorUploadingDocumentType',
                defaultMessage: 'Invalid file type',
            })
        );
        return false;
    };

    handleDeleteDocument = async (file) => {
        const { fileProgress, rtwFileList } = this.state;
        try {
            await this.props.deleteDocument(file);
            await this.props.patchInformation();

            const updatedFileProgress = { ...fileProgress };
            delete updatedFileProgress[file.type];

            const index = rtwFileList.indexOf(file);
            const newFileList = [...rtwFileList];
            newFileList.splice(index, 1);
            this.setState({ rtwFileList: newFileList, fileProgress: { ...updatedFileProgress } }, () =>
                message.warn(
                    intl.formatMessage(
                        {
                            id: 'welcome.Documents.documentRemoved',
                            defaultMessage: '{fileName} removed',
                        },
                        { fileName: this.truncateFileName(file.name) }
                    )
                )
            );
        } catch (err) {
            message.error('Failed to delete');
        }
    };

    handleSubmit = (values) => {
        const { rtwFileList } = this.state;
        const { handleNextTrack, patchInformation: patchInfo, preparedDocument } = this.props;

        const selectedDocument = preparedDocument || (rtwFileList.length && rtwFileList[0]);
        const otherDocuments = this.getOtherDocuments();
        const citizenship = values?.citizenship === 'australian_citizen' ? ['AU'] : [];

        if (!selectedDocument) {
            this.setState({ error: true });
            return;
        }

        if (!selectedDocument.id) selectedDocument.id = selectedDocument.uid;

        patchInfo({
            citizenship,
            documents: [{ ...selectedDocument, ...values }, ...otherDocuments],
        }).then(handleNextTrack);
    };

    pages = () => {
        const { s3UploadFields, s3UploadLink, onboardingType, isFetchingSilent, information } = this.props;
        const { rtwFileList, error, fileProgress, isUploading, documentTypes, document } = this.state;

        return {
            base: (
                <RightToWorkForm
                    handleSubmit={this.handleSubmit}
                    isAustralianCitizen={information.citizenship && information.citizenship.includes('AU')}
                    citizenship={information?.citizenship}
                    documentNumber={document?.document_number}
                    documentIssuingCountry={document?.document_issuing_country}
                    documentType={document?.document_type}
                    s3GetUploadLink={this.s3GetUploadLink}
                    handleDeleteDocument={this.handleDeleteDocument}
                    onUpload={this.onUpload}
                    handleProgress={this.handleProgress}
                    s3UploadLink={s3UploadLink}
                    s3UploadFields={s3UploadFields}
                    fileList={rtwFileList}
                    file={rtwFileList.length ? rtwFileList[0] : null}
                    fileProgress={fileProgress}
                    isUploading={isUploading}
                    onboardingType={onboardingType}
                    error={error}
                    isFetchingSilent={isFetchingSilent}
                    documentTypes={documentTypes}
                />
            ),
        };
    };

    render() {
        return this.pages()[this.props.trackPageLocation];
    }
}

RightToWork.propTypes = propTypes;
RightToWork.defaultProps = defaultProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withNavigation(RightToWork)));
