//
// A list of regular expressions used throughout the app.
// It should only validate against the thing itself, not length or existance.
// Let's keep this list concise and alphabetized.
//
const phoneNumber = /^[0-9]{10}$/; // 10 digit only
const phoneNumberExtra = /([0-9\s-]{7,})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/;
const letters = /^[ \p{L}']+$/u;
const twoPlusLetters = /^[\p{L}'][\p{L}'][ \p{L}']*$/u;
const twoPlusConsecutiveLetters = /[a-zA-Z]{2,}/;
const email = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const name = /^[\p{L}'-][ \p{L}'-]*$/u; // 1+ letters but with hyphen allowed
const ukDsName = /^[\p{L}\p{N}\s'-.]*$/u; // 1+ letters and numbers but with spaces, hyphens, apostrophes and periods
const ukDbsName = /^((\p{L}+)|(\p{L}[\p{L}\s'-]*\p{L}))$/u; // 1+ letters with spaces, apostrophes and hyphens allowed anywhere except first and last character
const otherTitle = /^[\p{L}][ \p{L}\s][ \p{L}.]*?$/u; // 1+ letters, spaces and periods
const middleName = /^([\p{L}']|[\p{L}'][ \p{L}'-]*)$/u; // extends name regex by allowing single middle initial
const numbers = /^\d*$/;
const licensePlate = /^(?:[a-zA-Z0-9][- ]?){1,8}$/;
const urlCode = /^[-a-zA-Z0-9_]+$/; // no space
const employerName = /^[- ,\p{L}0-9_']+$/u;
const alphaNumeric = /^[a-zA-Z0-9]+$/;
const alphaNumericExtra = /^[- ,#\p{L}0-9_']+$/u; // spaces and extra symbols
const city = /^[- \p{L}0-9'./]+$/u;
const dollars = /^[0-9]+(\.[0-9]{1,2})?$/;
const weight = /^[0-9]+(\.[0-9]+)?$/;
const url = /^(https?:\/\/)?(www\.)?[-\p{L}0-9]{2,63}\.[-\p{L}0-9]{2,63}\b([-\p{L}0-9@:%_+.~#?&=/]*)$/u;
const urlWebhook = /^(https?:\/\/)?[^/]+\.[^/.]{2,}(\/.+)?$/;
const postalCode = /^[A-Za-z0-9]{4,}?$/;
const postalCodeCanada = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
const postcodeUK = /^[A-Za-z]{1,2}[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}$/;
const sinssn = /^(?:\d[ -]?){9}$/;
const niNumber = /^(?!BG)(?!GB)(?!NK)(?!KN)(?!TN)(?!NT)(?!ZZ)(?:[A-CEGHJ-PR-TW-Z][A-CEGHJ-NPR-TW-Z])(?:\s*\d\s*){6}[A-D ]{1}?$/;
const maximum = (max) => new RegExp(`^.{1,maximum}$`.replace('maximum', max));
// This regex that matches Visa, MasterCard, American Express, Diners Club, Discover, and JCB cards:
const creditCardNumber = /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/;
const creditCardExpiry = /^\d{2}\/\d{2}$/;
const freeEmailDomains = /(@shaw|@hotmail|@gmail|@googlemail|@yahoo|@gmx|@ymail|@outlook|@bluewin|@protonmail|@rocketmail)/i;

const getPostalRegex = (country) => {
    switch (country) {
        case 'CA':
            return postalCodeCanada;
        case 'UK':
        case 'GB':
            return postcodeUK;
        default:
            return postalCode;
    }
};

const Regex = {
    phoneNumber,
    phoneNumberExtra,
    letters,
    twoPlusLetters,
    twoPlusConsecutiveLetters,
    email,
    numbers,
    licensePlate,
    urlCode,
    alphaNumeric,
    alphaNumericExtra,
    city,
    dollars,
    weight,
    url,
    urlWebhook,
    postalCode,
    sinssn,
    name,
    ukDsName,
    ukDbsName,
    otherTitle,
    middleName,
    employerName,
    postalCodeCanada,
    maximum,
    creditCardNumber,
    creditCardExpiry,
    freeEmailDomains,
    niNumber,
    postcodeUK,
    getPostalRegex,
};

export default Regex;
