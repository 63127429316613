// Libraries
import { createGlobalStyle } from 'styled-components/macro';
import styles from 'styles/styles';

const GlobalStyles = createGlobalStyle`
    html {
        box-sizing: border-box;
        overflow: overlay;
        overflow-x: hidden;
        font-size: 16px;
        font-weight: 400 !important;
        color: ${styles.color.certnGreen900} !important;
    }
    *, *:before, *:after {
        box-sizing: inherit;
    }
    body {
        padding: 0;
        margin: 0;
        font-size: 1rem !important;
        line-height: 1.5;
        color: ${styles.color.certnGreen900};
    }

    ${'' /* Font Awesome class and prefix bypass */}
    html,
    *:not(.fa):not(.far):not(.fas):not(.fal):not(.fab):not(.far):not(.fad), *:not(.fa):not(.far):before, *:not(.fa):after,
    body {
        font-family: 'LotaGrotesque-Regular', 'adelle-sans', sans-serif !important;
    }
    .ant-input-group-addon {
        line-height: initial !important;
    }
    textarea#role_or_position_title.ant-input {
        resize: none;
        height: 32px;
    }
    /* Left panel input font size */
    span.ant-input-group-addon {
        font-size: 11px !important;
    }
    form.ant-form {
        font-family: 'LotaGrotesque-Regular', 'adelle-sans', sans-serif !important;
        color: ${styles.color.certnGreen900};
    }
    /* Antd Table fixes */
    .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
        padding: 0px 6px !important;
        padding-left: 15px !important;
        color: ${styles.color.certnGreen900};
    }
    .ant-table-thead > tr > th {
        word-break: inherit !important;
        height: 60px;
        color: ${styles.color.certnGreen900};
    }
    div.ant-modal-mask {
        overflow: overlay !important;
    }
    div.fc-widget-normal {
        bottom: 35px !important;
    }
    div.ant-select  {
        text-align: left !important;
    }
    /* Required to position the success & error icons properly in DateInput */
    .ant-legacy-form-item-control.has-feedback > .ant-legacy-form-item-children {
        display: block;
    }
    .has-error {
        .ant-picker, .ant-input-group-addon, .ant-select-selector {
            border-color: ${styles.color.certnRed600} !important;
        }

    }

    .grecaptcha-badge { visibility: hidden; }

    /* OneID hiding provider specific language */
    .veriff-description { display: none }

    svg {
        margin: auto;
    }

    span.ant-radio {
        margin-bottom: auto;
    }
`;

export default GlobalStyles;
