// Libraries
import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Table as AntdTable } from 'antd';
import { useTransition, animated } from 'react-spring';
import { snakeCase } from 'lodash';
import { intl } from 'components/GlobalProvider';
import { useFlags } from 'launchdarkly-react-client-sdk';

// Components
import { BulkActions } from 'views/manager/components/BulkActions';
import Constants, { REPORT_ID_COLUMN_KEY } from 'utils/constants';
// Selectors
import { getUser, getTeam, getTeamApplicationColumns } from 'base/BaseSelectors';

// Modules & Utils
import { canViewReportWithClientAdjudication } from 'modules/applicationHelper';

const propTypes = {
    rowSelection: PropTypes.object,
    applications: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
};

const defaultProps = {
    rowSelection: {
        selectedRowKeys: [],
    },
    applications: false,
};

const Table = styled(AntdTable)`
    word-break: inherit !important;
    overflow-x: hidden !important;
`;

// Only used for Info Cell now
export const CellWrapper = styled.div`
    display: flex;
    justify-content: ${(props) => (props.center ? 'center' : 'flex-start')};
    flex-direction: ${(props) => (props.col ? 'column' : 'row')};
    align-items: ${(props) => (props.centerAlign ? 'center' : 'inherit')};
    transition: 0.3s;
    padding: 5px 0px;
    ${({ active, theme }) => active && `color: ${theme.color.certnGreen600}`};

    &:hover {
        cursor: ${(props) => (props.clickable ? 'pointer' : 'inherit')};
        ${({ header, clickable, active, theme }) =>
            header && clickable && !active && `color: ${theme.color.certnGray400}`};
    }
`;

export const CellText = styled.a`
    display: block;
    font-weight: ${(props) => (props.highlight && props.clickable ? '800' : '500')};
    color: ${(props) => (props.critical ? props.theme.color.certnRed500 : 'inherit')};
    ${(props) => !props.clickable && 'cursor: text'};
    :after {
        cursor: inherit;
    }
    overflow: ${({ overflow }) => (overflow ? 'visible !important' : 'inherit')};
    &:hover {
        color: inherit;
    }
`;

export const TitleHeader = (props) => (
    <CellWrapper header centerAlign active={props.active} onClick={props.handleClick} clickable={props.clickable}>
        {props.children || <div style={{ marginRight: '4px' }}>{props.title}</div>}
        <HeaderCellArrows active={props.active}>
            <LegacyIcon type={props.reverseOrder ? 'up' : 'down'} />
        </HeaderCellArrows>
    </CellWrapper>
);

const HeaderCellArrows = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    transition: 0.3s;
    color: ${({ active, theme }) => (active ? theme.color.certnGreen600 : theme.color.certnGray100)};
`;

const CustomTable = styled(Table)`
    .ant-table-body {
        overflow-x: auto !important;
    }
`;

const columnsThatOverflow = ['Application Status'];

const CertnTable = ({
    dataSource,
    display,
    match,
    history,
    setOrdering,
    ordering,
    reverseOrder,
    columns,
    rowSelection,
    loading,
    pagination,
    applications,
    title,
}) => {
    const { webFeatureEnableNewStatusSystem } = useFlags();
    const [toggle, setToggle] = useState(false);
    const user = useSelector(getUser);
    const team = useSelector(getTeam);
    const teamApplicationColumns = useSelector(getTeamApplicationColumns);
    const noClick = ['Actions', 'Co-applicants', 'Co-signers'];

    useEffect(() => {
        if (rowSelection.selectedRowKeys.length > 0) setToggle(true);
        if (rowSelection.selectedRowKeys.length === 0) setToggle(false);
    }, [rowSelection.selectedRowKeys.length]);

    const transitions = useTransition(toggle, null, {
        from: { opacity: 0, transform: 'scaleY(0)', maxHeight: '0px' },
        enter: { opacity: 1, transform: 'scaleY(1)', maxHeight: '1000px' },
        leave: { opacity: 0, transform: 'scaleY(0)', maxHeight: '0px' },
    });

    const canShowColumn = (removeForPermissionLevel, columnTitle) => {
        if (columnTitle === 'adverse_action_status')
            return user?.can_initiate_adverse_action || user?.can_view_adverse_action;

        return (
            !removeForPermissionLevel &&
            (!columns.clientConfiguredColumns || teamApplicationColumns[columnTitle] || !applications) &&
            (columnTitle !== REPORT_ID_COLUMN_KEY || team.superteam.has_short_uid_enabled)
        );
    };

    const onCellClick = (evt, recordId) => {
        if (evt.metaKey || evt.ctrlKey) return;

        evt.preventDefault();
        history.push(`${match.url}/${recordId}`);
    };

    return (
        <>
            {/* Only show bulk actions button when selected rows exist, fade and y scale in/out of view */}
            {transitions.map(({ item, key, props }) =>
                item ? (
                    <animated.div key={key} style={props}>
                        <BulkActions applications={applications} />
                    </animated.div>
                ) : (
                    <div key={key} />
                )
            )}
            <CustomTable
                rowKey={(record) => record.key}
                rowSelection={applications && rowSelection}
                dataSource={dataSource}
                loading={loading}
                pagination={pagination}
                size="small"
                tableLayout="auto"
                scroll={{ x: 'fit-content' }}
            >
                {columns[display].map((column) => {
                    const columnTitle = snakeCase(column);
                    const data = columns[column];

                    if (canShowColumn(data.removeForPermissionLevel, columnTitle))
                        return (
                            <Table.Column
                                ellipsis
                                title={
                                    data.customHeader ? (
                                        data.customHeader({ data, title, reverseOrder, setOrdering, ordering })
                                    ) : (
                                        <TitleHeader
                                            title={column}
                                            handleClick={() => (data.active ? setOrdering(data.active) : null)}
                                            active={data.active ? ordering === data.active : false}
                                            clickable={data.active}
                                            reverseOrder={reverseOrder}
                                        />
                                    )
                                }
                                dataIndex={columnTitle}
                                key={columnTitle}
                                onCell={() => ({
                                    style: {
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                    },
                                })}
                                render={(text, record) => {
                                    // Applications PM/HR use this, Postings/Listings/Buildings do not
                                    // Bell specific - needed a way to determine if a user can click into a report based on presence of adjudication model and its status
                                    // LDFlag webFeatureEnableNewStatusSystem
                                    let userMeetsAdjudicationCriteria;
                                    if (webFeatureEnableNewStatusSystem)
                                        userMeetsAdjudicationCriteria = canViewReportWithClientAdjudication(
                                            record,
                                            user,
                                            record.order_status
                                        );
                                    if (!webFeatureEnableNewStatusSystem)
                                        userMeetsAdjudicationCriteria = canViewReportWithClientAdjudication(
                                            record,
                                            user,
                                            record.report_status
                                        );

                                    // LDFlag webFeatureEnableNewStatusSystem
                                    let active;
                                    if (webFeatureEnableNewStatusSystem)
                                        active = Constants.orderStatus.active.includes(record?.order_status);
                                    if (!webFeatureEnableNewStatusSystem)
                                        active =
                                            record.report_summary &&
                                            Constants.reportStatus.active.includes(
                                                record?.report_summary?.report_status
                                            );

                                    const isClickable =
                                        active &&
                                        user.can_view_reports &&
                                        userMeetsAdjudicationCriteria &&
                                        columnTitle !== REPORT_ID_COLUMN_KEY;
                                    const canClick = isClickable && !noClick.includes(column);

                                    return (
                                        <CellText
                                            overflow={columnsThatOverflow.includes(column)}
                                            data-testid={column.toLowerCase()}
                                            style={{
                                                overflow:
                                                    columnTitle ===
                                                        intl.formatMessage({
                                                            id: '5a4e4.CertnTable.score',
                                                            defaultMessage: 'score',
                                                        }) ||
                                                    columnTitle ===
                                                        intl.formatMessage({
                                                            id: '5a4e4.CertnTable.status',
                                                            defaultMessage: 'status',
                                                        })
                                                        ? 'visible'
                                                        : 'inherit',
                                            }}
                                            clickable={isClickable}
                                            highlight={!record.is_viewed}
                                            onClick={canClick ? (evt) => onCellClick(evt, record.id) : undefined}
                                            href={canClick ? `${match.url}/${record.id}` : undefined}
                                        >
                                            {data.renderUi({ record })}
                                        </CellText>
                                    );
                                }}
                            />
                        );
                })}
            </CustomTable>
        </>
    );
};

CertnTable.propTypes = propTypes;
CertnTable.defaultProps = defaultProps;

export default withRouter(CertnTable);
