// Libraries
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Form as AntdForm } from '@ant-design/compatible';
import { Popover } from 'antd';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

// Components
import Loader from 'certn-ui/Loader';
import { AppearRight } from 'certn-ui/Animate';
import Title from 'certn-ui/Title';
import Form from 'certnd/Form';
import FormLayout from 'certn-form/FormLayout';
import { StripePaymentForm } from 'certnd';
import Typography from 'certnd/Typography';
import PrepaidNotice from './PrepaidNotice';

// Actions & Selectors
import { getMyCRCLoading, getStripePublicApiKey } from 'views/welcome/WelcomeSelectors';
import { getLanguage } from 'base/BaseSelectors';

// Modules
import { Regex } from 'modules';
import { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form';
import { useCheckTranslation } from 'hooks/useCheckTranslation';

const { Heading } = Typography;

const PaymentContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
`;

const PaymentRow = styled.div`
    display: flex;
    flex-direction: fow;
    justify-content: space-between;
`;

const BottomText = styled.span`
    font-size: 18px;
`;

interface PaymentBreakdownProps {
    microservice_prices: number;
    tax: number;
    total: number;
    currency: number;
}

type PaymentFormProps = {
    paymentLoading: boolean;
    paymentSuccess: boolean;
    myCRCPaymentBreakdown: PaymentBreakdownProps;
    handleSubmit: () => void;
    form: WrappedFormUtils<unknown>;
    isPrepaid: boolean;
    handleReverse: () => void;
} & WrappedComponentProps;

const PaymentForm = ({
    paymentLoading,
    paymentSuccess,
    myCRCPaymentBreakdown,
    handleSubmit,
    form,
    intl,
    isPrepaid,
    handleReverse,
}: PaymentFormProps) => {
    const myCRCLoading = useSelector(getMyCRCLoading);
    const stripePublicApiKey = useSelector(getStripePublicApiKey);
    const { microservice_prices, tax, total, currency } = myCRCPaymentBreakdown;
    const language = useSelector(getLanguage);
    const translateCheck = useCheckTranslation();

    if (myCRCLoading)
        return (
            <AppearRight>
                <Title>
                    <FormattedMessage id="welcome.Payment.title" defaultMessage="Payment Information." />
                </Title>
                <Loader />
            </AppearRight>
        );
    if (paymentSuccess)
        return (
            <AppearRight>
                <Title>
                    <FormattedMessage id="welcome.Payment.title" defaultMessage="Payment Information." />
                </Title>
                <Heading.H3>
                    <FormattedMessage id="welcome.Payment.success" defaultMessage="Payment successful, thank you" />
                </Heading.H3>
            </AppearRight>
        );
    return (
        <AppearRight>
            <AntdForm layout="horizontal">
                <Title>
                    <FormattedMessage id="welcome.Payment.title" defaultMessage="Payment Information." />
                </Title>
                {microservice_prices && total ? (
                    <FormLayout>
                        <PaymentContainer>
                            {microservice_prices &&
                                Object.entries(microservice_prices)
                                    .filter((item) => item[1] && item[1] !== '0') // Remove rows with $0
                                    .map((item, index) => (
                                        <PaymentRow key={index}>
                                            <div>{translateCheck(item[0])}</div>
                                            <div>${item[1]}</div>
                                        </PaymentRow>
                                    ))}
                            {tax !== 0 && tax !== null && (
                                <PaymentRow>
                                    <FormattedMessage id="welcome.Payment.tax" defaultMessage="Tax" />
                                    <div>${tax}</div>
                                </PaymentRow>
                            )}

                            <PaymentRow>
                                <BottomText>
                                    <FormattedMessage id="welcome.Payment.orderTotal" defaultMessage="Order Total" />
                                </BottomText>
                                <BottomText>
                                    <span style={{ fontSize: '12px' }}>({currency})</span> ${total}
                                </BottomText>
                            </PaymentRow>
                        </PaymentContainer>
                        {isPrepaid ? (
                            <PrepaidNotice />
                        ) : (
                            <>
                                <Form.Input
                                    size="large"
                                    form={form}
                                    fieldName="name"
                                    addonBefore={
                                        <Popover
                                            content={intl.formatMessage({
                                                id: 'welcome.Payment.cardholderName',
                                                defaultMessage: 'Cardholder Name',
                                            })}
                                        >
                                            <InfoCircleOutlined />
                                        </Popover>
                                    }
                                    options={{
                                        validateTrigger: 'onSubmit',
                                        rules: [
                                            {
                                                pattern: Regex.name,
                                                message: intl.formatMessage({
                                                    id: 'error.validation.letters',
                                                    defaultMessage: 'Please only use letters',
                                                }),
                                            },
                                            {
                                                required: true,
                                                message: intl.formatMessage({
                                                    id: 'error.validation.notBlank',
                                                    defaultMessage: 'Please do not leave blank',
                                                }),
                                            },
                                        ],
                                    }}
                                    placeholder={intl.formatMessage({
                                        id: 'welcome.Payment.cardholderName',
                                        defaultMessage: 'Cardholder Name',
                                    })}
                                    autoComplete="false"
                                />
                                <StripePaymentForm
                                    validateFields={form.validateFields}
                                    handleSubmit={handleSubmit}
                                    name={form.getFieldValue('name') || ''}
                                    paymentLoading={paymentLoading}
                                    stripePublicApiKey={stripePublicApiKey}
                                    language={language}
                                    handleReverse={handleReverse}
                                />
                            </>
                        )}
                    </FormLayout>
                ) : (
                    <FormattedMessage
                        id="welcome.Payment.errorFetchPaymentBreakdown"
                        defaultMessage="There was an issue retrieving payment breakdown, please contact support"
                    />
                )}
            </AntdForm>
        </AppearRight>
    );
};

export default AntdForm.create()(injectIntl(PaymentForm));
