// Libraries
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash';
import { injectIntl, FormattedMessage } from 'react-intl';
import { useFlags } from 'launchdarkly-react-client-sdk';

// Selectors and Actions
import {
    getAllSuperTeamUsers,
    getAllSuperTeamUserEmails,
    getNonTeamEmailList,
} from 'views/manager/views/settings/SettingsSelectors';
import { fetchAllSuperTeamMembers } from 'views/manager/views/settings/SettingsActions';

import { passwordValidator } from 'modules/validation';
import { Regex } from 'modules';

import { Form as AntdForm } from '@ant-design/compatible';
import Form from 'certnd/Form';
import Constants from 'utils/constants';

const TeamMemberForm = ({ intl, form, editingTeamMember, groups, partnerApiUser }) => {
    const { useEffect, useState, useMemo } = React;
    const dispatch = useDispatch();
    const { webFeatureEnableAdverseAction } = useFlags();

    const allSuperTeamUsers = useSelector(getAllSuperTeamUsers);
    const allSuperTeamUserEmails = useSelector(getAllSuperTeamUserEmails);
    const emailList = useSelector(getNonTeamEmailList) || [];

    const [isEmailOnSuperTeamUsersList, setIsEmailOnSuperTeamUsersList] = useState(false);

    useEffect(() => {
        dispatch(fetchAllSuperTeamMembers);
    }, [dispatch]);

    const fillForm = (email) => {
        const selectedUser = allSuperTeamUsers?.find((user) => user.email === email);
        form.setFieldsValue({
            first_name: selectedUser?.first_name,
            last_name: selectedUser?.last_name,
            permission_level: selectedUser?.permission_level,
            can_create_reports: selectedUser?.can_create_reports,
            can_view_reports: selectedUser?.can_view_reports,
            can_view_applications: selectedUser?.can_view_applications,
            can_create_and_edit_packages: selectedUser?.can_create_and_edit_packages,
            can_order_individual_services: selectedUser?.can_order_individual_services,
            existing_group: selectedUser?.user_group,
        });
    };

    /**
     * Some values need to be cleared but some such as the toggles need to keep
     * the current values, in case the user changes these values before they touch
     * the email input
     */
    const setFormValues = () => {
        const existingValues = getFieldsValue();

        form.setFieldsValue({
            ...existingValues,
            first_name: null,
            last_name: null,
            existing_group: null,
        });
    };

    const onBlur = (event) => {
        const email = event.target.value;
        if (allSuperTeamUserEmails.includes(email)) {
            fillForm(email);
            setIsEmailOnSuperTeamUsersList(true);
        } else {
            setFormValues();
            setIsEmailOnSuperTeamUsersList(false);
        }
    };

    const { getFieldsValue } = form;

    const permissionLevel = getFieldsValue().permission_level || get(editingTeamMember, 'permission_level', 1);
    const apiUserRole = getFieldsValue().api_user_role || get(editingTeamMember, 'api_user_role', 1);
    const userGroupId = get(editingTeamMember, ['user_group', 'id'], null);
    const newGroup = getFieldsValue().new_group || false;
    const isManager = permissionLevel === 2;
    const isAdmin = permissionLevel === 3;
    const isStaff = permissionLevel === 4;

    const requiredRule = useMemo(
        () => ({
            required: true,
            message: intl.formatMessage({
                id: 'error.validation.notBlank',
                defaultMessage: 'Please do not leave blank',
            }),
        }),
        [intl]
    );

    const emailRule = useMemo(
        () => ({
            pattern: Regex.email,
            message: intl.formatMessage({
                id: 'error.validation.email',
                defaultMessage: 'Please provide a valid email',
            }),
        }),
        [intl]
    );

    const nameRule = useMemo(
        () => ({
            pattern: Regex.name,
            message: intl.formatMessage({
                id: 'error.validation.letters',
                defaultMessage: 'Please only use letters',
            }),
        }),
        [intl]
    );

    const radioApiOptions = [
        {
            title: intl.formatMessage({
                id: 'cf457.TeamMemberForm.ApiSupportUserTitle',
                defaultMessage: 'API Support User',
            }),
            description: intl.formatMessage({
                id: 'cf457.TeamMemberForm.ApiSupportUserDescription',
                defaultMessage: 'Provides support for end users but cannot modify partner settings.',
            }),
            key: Constants.apiUserRole.SUPPORT,
            value: Constants.apiUserRole.SUPPORT,
        },
        {
            title: intl.formatMessage({
                id: 'cf457.TeamMemberForm.ApiAdminUserTitle',
                defaultMessage: 'API Admin User',
            }),
            description: intl.formatMessage({
                id: 'cf457.TeamMemberForm.ApiAdminUserDescription',
                defaultMessage: 'Provides support for end users and is able to modify partner settings.',
            }),
            key: Constants.apiUserRole.ADMIN,
            value: Constants.apiUserRole.ADMIN,
        },
    ];

    const radioOptions = useMemo(() => {
        const options = [];
        if (isStaff) {
            options.push({
                title: intl.formatMessage({
                    id: 'cf457.TeamMemberForm.SuperuserTitle',
                    defaultMessage: 'Superuser',
                }),
                description: intl.formatMessage({
                    id: 'cf457.TeamMemberForm.SuperuserDescription',
                    defaultMessage: 'Has access to everything',
                }),
                key: 4,
                value: 4,
            });
        } else {
            options.push(
                {
                    title: intl.formatMessage({
                        id: 'cf457.TeamMemberForm.AdminTitle',
                        defaultMessage: 'Admin',
                    }),
                    description: intl.formatMessage({
                        id: 'cf457.TeamMemberForm.AdminDescription',
                        defaultMessage:
                            'Has access to reports, results, settings, billing, user management and team management.',
                    }),
                    key: 3,
                    value: 3,
                },
                {
                    title: intl.formatMessage({
                        id: 'cf457.TeamMemberForm.ManagerTitle',
                        defaultMessage: 'Manager',
                    }),
                    description: intl.formatMessage({
                        id: 'cf457.TeamMemberForm.ManagerDescription',
                        defaultMessage:
                            'Has access to reports, results, settings and user management for their assigned team.',
                    }),
                    key: 2,
                    value: 2,
                },
                {
                    title: intl.formatMessage({
                        id: 'cf457.TeamMemberForm.UserTitle',
                        defaultMessage: 'User',
                    }),
                    description: intl.formatMessage({
                        id: 'cf457.TeamMemberForm.UserDescription',
                        defaultMessage:
                            'Has access to reports and results by default, unless a different scope is defined.',
                    }),
                    key: 1,
                    value: 1,
                }
            );
        }
        return options;
    }, [intl, isStaff]);

    const userPermissions = useMemo(() => {
        const permissions = [
            {
                fieldName: 'can_create_reports',
                title: intl.formatMessage({
                    id: 'cf457.TeamMemberForm.CreateReports',
                    defaultMessage: 'Can Create Reports',
                }),
                defaultValue: true,
            },
            {
                fieldName: 'can_view_reports',
                title: intl.formatMessage({
                    id: 'cf457.TeamMemberForm.ViewReports',
                    defaultMessage: 'Can View Reports',
                }),
                defaultValue: true,
            },
            {
                fieldName: 'can_share_reports',
                title: intl.formatMessage({
                    id: 'cf457.TeamMemberForm.ShareReports',
                    defaultMessage: 'Can Share Reports',
                }),
                defaultValue: true,
            },
            {
                fieldName: 'can_view_applications',
                title: intl.formatMessage({
                    id: 'cf457.TeamMemberForm.ViewApplications',
                    defaultMessage: 'Can View Applications',
                }),
                key: 1,
                value: 1,
                defaultValue: true,
            },
            {
                fieldName: 'can_create_and_edit_packages',
                title: intl.formatMessage({
                    id: 'cf457.TeamMemberForm.canCreateAndEditPackages',
                    defaultMessage: 'Can Create and Edit Packages',
                }),
                defaultValue: true,
            },
            {
                fieldName: 'can_order_individual_services',
                title: intl.formatMessage({
                    id: 'cf457.TeamMemberForm.canOrderIndividualServices',
                    defaultMessage: 'Can Order Individual Checks',
                }),
                defaultValue: true,
            },
        ];

        if (webFeatureEnableAdverseAction) {
            (isManager || isAdmin || isStaff) &&
                permissions.push(
                    {
                        fieldName: 'can_view_adverse_action',
                        title: intl.formatMessage({
                            id: 'cf457.TeamMemberForm.canViewAdverseActionDetails',
                            defaultMessage: 'Can View Adverse Action Details',
                        }),
                        defaultValue: false,
                    },
                    {
                        fieldName: 'can_initiate_adverse_action',
                        title: intl.formatMessage({
                            id: 'cf457.TeamMemberForm.canInitiateAdverseAction',
                            defaultMessage: 'Can Initiate Adverse Action',
                        }),
                        defaultValue: false,
                    }
                );
        }

        return permissions;
    }, [intl, webFeatureEnableAdverseAction, isStaff, isManager, isAdmin]);

    // Ant V4 Autocomplete options need to be in value/label format
    const emailListOptions = emailList.map((email) => ({
        value: email,
        label: email,
    }));

    const renderPasswordField = () => {
        if (editingTeamMember || isEmailOnSuperTeamUsersList) {
            return null;
        }

        // render when creating new user
        return (
            <Form.Input
                form={form}
                data-testid="new_password"
                title={intl.formatMessage({
                    id: 'cf457.TeamMemberForm.NewPassword',
                    defaultMessage: 'New Password',
                })}
                fieldName="password"
                options={{
                    initialValue: editingTeamMember?.password,
                    rules: [
                        {
                            validator: (rule, value, callback) => {
                                callback(value ? passwordValidator(value) : undefined);
                            },
                        },
                        {
                            required: true,
                            message: intl.formatMessage({
                                id: 'cf457.TeamMemberForm.PasswordRequired',
                                defaultMessage: 'Password is required!',
                            }),
                        },
                    ],
                }}
                autoComplete="false"
                disabled={isEmailOnSuperTeamUsersList}
            />
        );
    };

    return (
        <>
            <Form.AutoComplete
                dataSource={emailListOptions}
                form={form}
                fieldName="email"
                title={intl.formatMessage({
                    id: 'common.email',
                    defaultMessage: 'Email',
                })}
                options={{
                    initialValue: editingTeamMember?.email,
                    rules: [emailRule, requiredRule],
                }}
                onBlur={onBlur}
                placeholder="john@company.com"
                defaultActiveFirstOption="false"
                backfill="true"
                filterOption="true"
            />

            <Form.Input
                form={form}
                fieldName="first_name"
                title={intl.formatMessage({
                    id: 'common.firstName',
                    defaultMessage: 'First Name',
                })}
                options={{
                    initialValue: editingTeamMember?.first_name,
                    rules: [nameRule, requiredRule],
                }}
                placeholder="Joe"
                autoComplete="false"
                disabled={isEmailOnSuperTeamUsersList}
            />
            <Form.Input
                form={form}
                fieldName="last_name"
                title={intl.formatMessage({
                    id: 'common.lastName',
                    defaultMessage: 'Last Name',
                })}
                options={{
                    initialValue: editingTeamMember?.last_name,
                    rules: [nameRule, requiredRule],
                }}
                placeholder="Smith"
                autoComplete="false"
                disabled={isEmailOnSuperTeamUsersList}
            />

            {partnerApiUser ? (
                <Form.Radio
                    hideBorder
                    form={form}
                    fieldName="api_user_role"
                    options={{ initialValue: apiUserRole }}
                    radioOptions={radioApiOptions}
                />
            ) : (
                <Form.Radio
                    hideBorder
                    form={form}
                    fieldName="permission_level"
                    options={{ initialValue: permissionLevel }}
                    radioOptions={radioOptions}
                    disabled={isStaff || isEmailOnSuperTeamUsersList}
                />
            )}

            {permissionLevel && (
                <>
                    {userPermissions.map(({ fieldName, title, defaultValue }) => {
                        const initialValue = get(editingTeamMember, fieldName, defaultValue);
                        return (
                            <Form.Switch
                                data-testid={fieldName}
                                key={fieldName}
                                hideBorder
                                form={form}
                                fieldName={fieldName}
                                title={title}
                                options={{ initialValue }}
                                style={{ marginBottom: '0px' }}
                                disabled={isEmailOnSuperTeamUsersList}
                            />
                        );
                    })}
                </>
            )}

            {renderPasswordField()}
            {!partnerApiUser && (
                <>
                    <Form.Radio
                        hideBorder
                        form={form}
                        fieldName="new_group"
                        options={{ initialValue: false }}
                        radioOptions={[
                            {
                                title: intl.formatMessage({
                                    id: 'cf457.TeamMemberForm.ChooseExisting',
                                    defaultMessage: 'Choose An Existing Group',
                                }),
                                key: 'false',
                                value: false,
                            },
                            {
                                title: intl.formatMessage({
                                    id: 'cf457.TeamMemberForm.ChooseNew',
                                    defaultMessage: 'Create A New Group',
                                }),
                                key: 'true',
                                value: true,
                            },
                        ]}
                        disabled={isEmailOnSuperTeamUsersList}
                    />

                    {newGroup ? (
                        <Form.Input
                            form={form}
                            fieldName="user_group"
                            title={
                                <FormattedMessage
                                    id="cf457.TeamMemberForm.CreateGroup"
                                    defaultMessage="Enter New Group Name"
                                />
                            }
                            options={{
                                rules: [requiredRule],
                            }}
                            autoComplete="false"
                            disabled={isEmailOnSuperTeamUsersList}
                        />
                    ) : (
                        <Form.Select
                            hideBorder
                            form={form}
                            fieldName="user_group_id"
                            title={intl.formatMessage({
                                id: 'cf457.TeamMemberForm.ExistingGroup',
                                defaultMessage: 'Existing Group',
                            })}
                            options={{ initialValue: userGroupId }}
                            selectOptions={[
                                {
                                    key: -1,
                                    value: null,
                                    title: intl.formatMessage({
                                        id: 'common.none',
                                        defaultMessage: 'None',
                                    }),
                                },
                                ...(groups || []).map((group, index) => ({
                                    key: index,
                                    value: group.id,
                                    title: group.name || '',
                                })),
                            ]}
                            disabled={isEmailOnSuperTeamUsersList}
                        />
                    )}
                </>
            )}
        </>
    );
};

export default AntdForm.create()(injectIntl(TeamMemberForm));
