import styled from 'styled-components';
import { Typography } from 'antd';

const { Text } = Typography;
interface Props {
    dashboard?: boolean;
}

export const StatusSectionWrapper = styled.div`
    display: flex;
    margin: 0 16px 16px;
    justify-content: space-between;
    width: calc(100% - 32px);
    border: 1px solid #d4d4d4;
    border-radius: 4px;
`;
export const StatusWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 16px;
`;

export const StatusText = styled(Text)`
    font-weight: 600;
    font-size: 16px;
`;

export const TimestampText = styled(Text)<Props>`
    font-size: 14px;
    line-height: 125%;
    opacity: 0.45;
    padding-left: ${(props) => (props.dashboard ? '6px' : '22px')};
`;

export const ActionsDropdownComponent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 16px 0;
`;
