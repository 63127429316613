import styled from 'styled-components';

export const LegalWrapper = styled.div`
    text-align: left;
    margin-top: 8px;
    .ant-checkbox-wrapper {
        margin-left: 0px;
        margin-top: 4px;
    }
`;
