import {
    SET_APPLICATION_OF_INTEREST,
    SET_CHECKS,
    TOGGLE_CHECK,
    SET_TOTAL,
    SET_ADDITIONAL_OPTIONS,
    SET_PACKAGE_SELECTED,
    RESET_ADDITIONAL_OPTIONS,
    RESET_PACKAGE,
    PATCH_APPLICANT,
    RESET_APPLICANT,
    SET_SHOULD_PERSIST_SCREEN_APPLICANT_STATE,
    SET_CHECK_PAGE_COMPLETE,
    MODIFY_CHECK_STATE,
} from './ScreenApplicantActions';
import { CANDIDATE, ActionTypes } from 'views/manager/features/ScreenApplicant/ScreenApplicantConstants';

const initialState = {
    applicationOfInterest: null,
    total: { usd: 0, local: 0 },
    additionalOptions: {},
    applicant: {
        propertyID: '',
        listingID: '',
        screenType: CANDIDATE,
        estimatedTurnAroundTime: '',
        convictionsExist: false,
    },
    shouldPersistScreenApplicantState: false,
    packageSelected: false,
    checkPageComplete: false,
    checks: [],
};

const screenApplicant = (state = initialState, action) => {
    const { type, payload = {} } = action;
    switch (type) {
        case ActionTypes.GET_GENERIC_CHECKS.SUC:
            return {
                ...state,
                ...payload,
            };
        case SET_APPLICATION_OF_INTEREST:
            return {
                ...state,
                applicationOfInterest: payload.application,
            };
        case SET_CHECKS:
            return {
                ...state,
                ...payload,
            };
        case TOGGLE_CHECK:
            return {
                ...state,
                checks: state.checks.map((check) => {
                    if (payload.checksToAdd.includes(check.requestFlag)) return { ...check, isSelected: true };
                    if (payload.checksToRemove.includes(check.requestFlag)) return { ...check, isSelected: false };
                    return check;
                }),
            };
        case MODIFY_CHECK_STATE:
            return {
                ...state,
                ...payload,
            };
        case SET_ADDITIONAL_OPTIONS:
            return {
                ...state,
                additionalOptions: {
                    ...state.additionalOptions,
                    ...payload.additionalOptions,
                },
            };
        case RESET_ADDITIONAL_OPTIONS:
            return {
                ...state,
                additionalOptions: {},
            };
        case SET_PACKAGE_SELECTED:
            return {
                ...state,
                packageSelected: true,
            };
        case RESET_PACKAGE:
            return {
                ...state,
                packageSelected: false,
            };
        case SET_TOTAL:
            return {
                ...state,
                total: payload.total,
            };
        case PATCH_APPLICANT:
            return {
                ...state,
                applicant: {
                    ...state.applicant,
                    ...action.payload,
                },
            };
        case RESET_APPLICANT:
            return {
                ...state,
                applicant: {
                    propertyID: '',
                    listingID: '',
                    screenType: CANDIDATE,
                    estimatedTurnAroundTime: '',
                },
            };
        case SET_CHECK_PAGE_COMPLETE:
            return {
                ...state,
                checkPageComplete: action.payload,
            };
        case SET_SHOULD_PERSIST_SCREEN_APPLICANT_STATE:
            return {
                ...state,
                shouldPersistScreenApplicantState: action.payload,
            };
        default:
            return state;
    }
};

export default screenApplicant;
