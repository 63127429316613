// Libraries
import React from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import { Input, Popover, Select } from 'antd';
import { injectIntl, FormattedMessage } from 'react-intl';
import { get } from 'lodash';

// Components
import { AppearRight } from 'certn-ui/Animate';
import Title from 'certn-ui/Title';
import { TrackNavigation } from 'views/welcome/components';
import FormLayout from 'certn-form/FormLayout';

// Modules
import { Regex } from 'modules';

const CosignerForm = ({
    handleSubmit,
    handleReverse,
    form: { getFieldDecorator, validateFields },
    information,
    intl,
}) => {
    const association = get(information, 'co_signer_association');
    let livingWith = get(information, 'co_signer_living_with_applicant');
    if (typeof livingWith === 'boolean') {
        livingWith = livingWith ? 'yes' : 'no';
    }
    return (
        <AppearRight>
            <Title>
                <FormattedMessage
                    id="welcome.Supplemental.cosigner.formTitle"
                    defaultMessage="You mentioned you have a guarantor. Add their information here and we'll send them an application once you submit yours!"
                />
            </Title>
            <Form layout="horizontal" onSubmit={(e) => handleSubmit(e, validateFields, 'co_signer')}>
                <FormLayout.R2N size="6" offset="6">
                    <Form.Item>
                        {getFieldDecorator('co_signer_first_name', {
                            initialValue: get(information, 'co_signer_first_name') || null,
                            rules: [
                                {
                                    pattern: Regex.letters,
                                    message: intl.formatMessage({
                                        id: 'error.validation.letters',
                                        defaultMessage: 'Please only use letters',
                                    }),
                                },
                                {
                                    required: true,
                                    message: intl.formatMessage({
                                        id: 'error.validation.notBlank',
                                        defaultMessage: 'Please do not leave blank',
                                    }),
                                },
                            ],
                        })(
                            <Input
                                placeholder={intl.formatMessage({
                                    id: 'common.firstName',
                                    defaultMessage: 'First Name',
                                })}
                                addonBefore={
                                    <Popover
                                        content={intl.formatMessage({
                                            id: 'common.firstName',
                                            defaultMessage: 'First Name',
                                        })}
                                    >
                                        <InfoCircleOutlined />
                                    </Popover>
                                }
                            />
                        )}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('co_signer_last_name', {
                            initialValue: get(information, 'co_signer_last_name') || null,
                            rules: [
                                {
                                    pattern: Regex.letters,
                                    message: intl.formatMessage({
                                        id: 'error.validation.letters',
                                        defaultMessage: 'Please only use letters',
                                    }),
                                },
                                {
                                    required: true,
                                    message: intl.formatMessage({
                                        id: 'error.validation.notBlank',
                                        defaultMessage: 'Please do not leave blank',
                                    }),
                                },
                            ],
                        })(
                            <Input
                                placeholder={intl.formatMessage({
                                    id: 'common.lastName',
                                    defaultMessage: 'Last Name',
                                })}
                                addonBefore={
                                    <Popover
                                        content={intl.formatMessage({
                                            id: 'common.lastName',
                                            defaultMessage: 'Last Name',
                                        })}
                                    >
                                        <InfoCircleOutlined />
                                    </Popover>
                                }
                            />
                        )}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('co_signer_phone', {
                            initialValue: get(information, 'co_signer_phone') || null,
                            rules: [
                                {
                                    pattern: Regex.phoneNumberExtra,
                                    message: intl.formatMessage({
                                        id: 'error.validation.phoneNumber',
                                        defaultMessage: 'Please provide a valid phone number',
                                    }),
                                },
                                {
                                    required: true,
                                    message: intl.formatMessage({
                                        id: 'error.validation.notBlank',
                                        defaultMessage: 'Please do not leave blank',
                                    }),
                                },
                            ],
                        })(
                            <Input
                                placeholder={intl.formatMessage({
                                    id: 'common.phoneNumber',
                                    defaultMessage: 'Phone Number',
                                })}
                                addonBefore={
                                    <Popover
                                        content={intl.formatMessage({
                                            id: 'common.phoneNumber',
                                            defaultMessage: 'Phone Number',
                                        })}
                                    >
                                        <InfoCircleOutlined />
                                    </Popover>
                                }
                            />
                        )}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('co_signer_email', {
                            initialValue: get(information, 'co_signer_email') || null,
                            rules: [
                                {
                                    type: 'email',
                                    message: intl.formatMessage({
                                        id: 'error.validation.email.invalid',
                                        defaultMessage: 'The input is not a valid E-mail!',
                                    }),
                                },
                                {
                                    required: true,
                                    message: intl.formatMessage({
                                        id: 'error.validation.email.required',
                                        defaultMessage: 'Please input your E-mail!',
                                    }),
                                },
                                // {
                                //     validator: (rule, value, callback) => {
                                //         callback(
                                //             intl.formatMessage({
                                //                 id: existCosignerEmail(value, applicantAccount.email),
                                //             })
                                //         );
                                //     },
                                // },
                            ],
                        })(
                            <Input
                                placeholder={intl.formatMessage({
                                    id: 'common.email',
                                    defaultMessage: 'Email',
                                })}
                                addonBefore={
                                    <Popover
                                        content={intl.formatMessage({
                                            id: 'common.email',
                                            defaultMessage: 'Email',
                                        })}
                                    >
                                        <InfoCircleOutlined />
                                    </Popover>
                                }
                            />
                        )}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('co_signer_association', {
                            initialValue: association && association !== 'N' ? association : undefined,
                            rules: [
                                {
                                    required: true,
                                    message: intl.formatMessage({
                                        id: 'error.validation.chooseOption',
                                        defaultMessage: 'Please choose an option',
                                    }),
                                },
                            ],
                        })(
                            <Select
                                placeholder={intl.formatMessage({
                                    id: 'welcome.Supplemental.cosignerRelationship',
                                    defaultMessage: 'Relationship',
                                })}
                            >
                                <Select.Option value="SP">
                                    <FormattedMessage
                                        id="welcome.Supplemental.cosignerRelationship.spousePartner"
                                        defaultMessage="Spouse/Partner"
                                    />
                                </Select.Option>
                                <Select.Option value="P">
                                    <FormattedMessage
                                        id="welcome.Supplemental.cosignerRelationship.parent"
                                        defaultMessage="Parent"
                                    />
                                </Select.Option>
                                <Select.Option value="S">
                                    <FormattedMessage
                                        id="welcome.Supplemental.cosignerRelationship.sibling"
                                        defaultMessage="Sibling"
                                    />
                                </Select.Option>
                                <Select.Option value="C">
                                    <FormattedMessage
                                        id="welcome.Supplemental.cosignerRelationship.cousin"
                                        defaultMessage="Cousin"
                                    />
                                </Select.Option>
                                <Select.Option value="F">
                                    <FormattedMessage
                                        id="welcome.Supplemental.cosignerRelationship.friend"
                                        defaultMessage="Friend"
                                    />
                                </Select.Option>
                                <Select.Option value="O">
                                    <FormattedMessage id="common.other" defaultMessage="Other" />
                                </Select.Option>
                            </Select>
                        )}
                    </Form.Item>
                </FormLayout.R2N>
                <TrackNavigation handleReverse={handleReverse} />
            </Form>
        </AppearRight>
    );
};

export default Form.create()(injectIntl(CosignerForm));
