// Libraries
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';

// Components
import SummaryItem from './SummaryItem';
import SummaryHeader from './SummaryHeader';
import { FlexRow, SummarySection, SummaryContainer } from './SummaryStyles';
import { formatDate } from 'modules/Format';
import { CHECK_REQUEST } from 'base/BaseConstants';

// Actions & Selectors
import { getSIN, getSSN, getNIN, getSettings, getApplicant, getInformation } from 'views/welcome/WelcomeSelectors';

const MoreBasics = ({ moreBasics, goToTrack }) => {
    const socialInsuranceNumber = useSelector(getSIN);
    const socialSecurityNumber = useSelector(getSSN);
    const nationalInsuranceNumber = useSelector(getNIN);
    const settings = useSelector(getSettings);
    const teamIsUS = settings.get_org_country === 'US';
    const teamIsCA = settings.get_org_country === 'CA';
    const applicant = useSelector(getApplicant);
    const isUkCheck =
        applicant && (applicant[CHECK_REQUEST.UK_BASIC_DBS_CHECK] || applicant[CHECK_REQUEST.UK_BASIC_DS_CHECK]);
    const isDsCheck = applicant && applicant[CHECK_REQUEST.UK_BASIC_DS_CHECK];
    const information = useSelector(getInformation);
    return (
        <SummaryContainer>
            <SummaryHeader
                title={<FormattedMessage id="welcome.general.moreBasics" defaultMessage="More Basics" />}
                onClick={goToTrack}
            />
            <SummarySection>
                <FlexRow>
                    {teamIsCA && (
                        <SummaryItem
                            title={<FormattedMessage id="welcome.general.sin" defaultMessage="SIN (CA)" />}
                            text={socialInsuranceNumber}
                        />
                    )}
                    {socialSecurityNumber && teamIsUS && (
                        <SummaryItem
                            title={<FormattedMessage id="welcome.general.ssn" defaultMessage="SSN (USA)" />}
                            text={socialSecurityNumber}
                        />
                    )}
                    {isUkCheck && (
                        <SummaryItem
                            title={
                                <FormattedMessage id="welcome.Summary.nin" defaultMessage="National Insurance Number" />
                            }
                            text={nationalInsuranceNumber}
                        />
                    )}
                    {isDsCheck && (
                        <SummaryItem
                            title={
                                <FormattedMessage
                                    id="welcome.MoreBasics.mothersMaidenName"
                                    defaultMessage="Mother's maiden name"
                                />
                            }
                            text={information?.mothers_maiden_name}
                        />
                    )}
                    <SummaryItem
                        title={<FormattedMessage id="common.dateOfBirth" defaultMessage="Date Of Birth" />}
                        text={formatDate(moreBasics.date_of_birth, isUkCheck && 'UK')}
                    />
                </FlexRow>
            </SummarySection>
        </SummaryContainer>
    );
};

export default injectIntl(MoreBasics);
