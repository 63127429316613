// Libraries
import { get } from 'lodash';
import { createSelector } from 'reselect';

// Actions & Selectors
import { STATEPATH } from './AdminActions';
import { getCountry } from 'base/BaseSelectors';

const getLocalState = (state) => get(state, STATEPATH);

export const getIsFetching = (state) => getLocalState(state).isFetching;
export const getTeams = (state) => getLocalState(state).teams;
export const getTeamsCount = (state) => getLocalState(state).teams_count;
export const getAdjudicators = (state) => getLocalState(state).adjudicators;
export const getTeam = (state) => getLocalState(state).team;
export const getTeamId = (state) => getLocalState(state).team.id;
export const getTeamMember = (state) => getLocalState(state).teamMember;
export const getTeamIsHR = (state) => getTeam(state).team_type === 'HR';
export const getTeamIsPM = (state) => getTeam(state).team_type === 'PM';
export const getTeamAccountManager = (state) => getTeam(state).account_manager;
export const getReviewStatuses = (state) => getLocalState(state).reviewStatuses;

export const getTeamsByTeamCountry = createSelector(getCountry, getTeams, (country, teams) =>
    teams.filter((team) => team.country === country)
);
