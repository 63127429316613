import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Actions
import { saveParams } from 'base/BaseActions';

const mapDispatchToProps = (dispatch) => bindActionCreators({ saveParams }, dispatch);
const propTypes = { saveParams: PropTypes.func.isRequired };
const defaultProps = {};
class ScrollToTop extends Component {
    state = {
        saved: false,
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        this.setState({ saved: true });
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            window.scrollTo(0, 0);
        }
    }

    render() {
        if (this.state.saved) {
            return this.props.children;
        }
        return null;
    }
}

ScrollToTop.propTypes = propTypes;
ScrollToTop.defaultProps = defaultProps;

export default withRouter(connect(undefined, mapDispatchToProps)(ScrollToTop));
