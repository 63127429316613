import { getRequest, patchRequest } from 'utils/http';

export const getAdjudicationReviewStatuses = () =>
    getRequest({
        hr: true,
        version: 'v1',
        endpoint: `adjudication/overall_result/options/`,
    });

export const getApplicantAdjudication = (applicantId) =>
    getRequest({
        hr: true,
        version: 'v1',
        endpoint: `adjudication/applicant/${applicantId}/`,
    });

export const getApplicantAdjudicationChecks = (applicantId) =>
    getRequest({
        hr: true,
        version: 'v1',
        endpoint: `adjudication/applicant/${applicantId}/checks/`,
    });

export const patchAdjudicator = (applicantId, newAdjudicator, unassignAdjudicator) =>
    patchRequest({
        hr: true,
        version: 'v1',
        endpoint: `adjudication/applicant/${applicantId}/`,
        body: JSON.stringify({ adjudicator: newAdjudicator, unassign_adjudicator: unassignAdjudicator }),
    });

export const patchApplicantAdjudication = (applicantId, data) =>
    patchRequest({
        hr: true,
        version: 'v1',
        endpoint: `adjudication/applicant/${applicantId}/`,
        body: JSON.stringify(data),
    });

export const updateCheckScore = (checkID, scoreResult) =>
    patchRequest({
        hr: true,
        version: 'v1',
        endpoint: `adjudication/checkscore/${checkID}/`,
        body: JSON.stringify({ score_result: scoreResult }),
    });
