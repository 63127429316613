import FormBlock from './FormBlock';
import FormButton from './FormButton';
import FormDescription from './FormDescription';
import { FormInput, FormTextArea, FormPassword, FormSearch, FormInputNumber, FormAutoComplete } from './FormInput';
import FormCheckbox from './FormCheckbox';
import FormRadio from './FormRadio';
import FormSelect from './FormSelect';
import FormSwitch from './FormSwitch';
import FormWrapper from './FormWrapper';

const Form = {
    AutoComplete: FormAutoComplete,
    Block: FormBlock,
    Button: FormButton,
    Description: FormDescription,
    Input: FormInput,
    TextArea: FormTextArea,
    Password: FormPassword,
    Search: FormSearch,
    Checkbox: FormCheckbox,
    Radio: FormRadio,
    Select: FormSelect,
    Switch: FormSwitch,
    Wrapper: FormWrapper,
    InputNumber: FormInputNumber,
};

export default Form;
