import { defineMessage } from 'react-intl';

export const EMPLOYMENT_SECTORS_OPTIONS = [
    {
        key: 'LOCAL GOVERNMENT',
        title: defineMessage({
            id: '74e6c.AddOptions.localGovernment',
            defaultMessage: 'Local Government',
        }),
    },
    {
        key: 'CENTRAL GOVERNMENT',
        title: defineMessage({
            id: '74e6c.AddOptions.centralGovernment',
            defaultMessage: 'Central Government',
        }),
    },
    {
        key: 'PUBLIC SECTOR- OTHER',
        title: defineMessage({
            id: '74e6c.AddOptions.publicSector-Other',
            defaultMessage: 'Public Sector - Other',
        }),
    },
    {
        key: 'NHS',
        title: defineMessage({
            id: '74e6c.AddOptions.NHS',
            defaultMessage: 'NHS',
        }),
    },
    {
        key: 'PRESCHOOL EDUCATION',
        title: defineMessage({
            id: '74e6c.AddOptions.preschoolEducation',
            defaultMessage: 'Preschool Education',
        }),
    },
    {
        key: 'PRIMARY EDUCATION',
        title: defineMessage({
            id: '74e6c.AddOptions.primaryEducation',
            defaultMessage: 'Primary Education',
        }),
    },
    {
        key: 'SECONDARY EDUCATION',
        title: defineMessage({
            id: '74e6c.AddOptions.secondaryEducation',
            defaultMessage: 'Secondary Education',
        }),
    },
    {
        key: 'ACADEMY EDUCATION',
        title: defineMessage({
            id: '74e6c.AddOptions.academyEducation',
            defaultMessage: 'Academy Education',
        }),
    },
    {
        key: 'FURTHER EDUCATION',
        title: defineMessage({
            id: '74e6c.AddOptions.furtherEducation',
            defaultMessage: 'Further Education',
        }),
    },
    {
        key: 'HIGHER EDUCATION',
        title: defineMessage({
            id: '74e6c.AddOptions.higherEducation',
            defaultMessage: 'Higher Education',
        }),
    },
    {
        key: 'INDEPENDENT EDUCATION',
        title: defineMessage({
            id: '74e6c.AddOptions.independentEducation',
            defaultMessage: 'Independent Education',
        }),
    },
    {
        key: 'VOLUNTARY/CHARITY',
        title: defineMessage({
            id: '74e6c.AddOptions.volunatryCharity',
            defaultMessage: 'Voluntary / Charity',
        }),
    },
    {
        key: 'PRIVATE HEALTHCARE',
        title: defineMessage({
            id: '74e6c.AddOptions.privateHealthcare',
            defaultMessage: 'Private Healthcare',
        }),
    },
    {
        key: 'AGRICULTURE, FORESTRY AND FISHING',
        title: defineMessage({
            id: '74e6c.AddOptions.agricultureForestryAndFishing',
            defaultMessage: 'Agriculture, Forestry and Fishing',
        }),
    },
    {
        key: 'MINING AND QUARRYING',
        title: defineMessage({
            id: '74e6c.AddOptions.miningAndQuarrying',
            defaultMessage: 'Mining And Quarrying',
        }),
    },
    {
        key: 'MANUFACTURING',
        title: defineMessage({
            id: '74e6c.AddOptions.manufacturing',
            defaultMessage: 'Manufacturing',
        }),
    },
    {
        key: 'RETAIL',
        title: defineMessage({
            id: '74e6c.AddOptions.retail',
            defaultMessage: 'Retail',
        }),
    },
    {
        key: 'ENERGY AND AIR CONDITIONING',
        title: defineMessage({
            id: '74e6c.AddOptions.energyAndAirConditioning',
            defaultMessage: 'Energy and Air Conditioning',
        }),
    },
    {
        key: 'WATER AND WASTE MANAGEMENT',
        title: defineMessage({
            id: '74e6c.AddOptions.waterAndWasteManagement',
            defaultMessage: 'Water and Waste Management',
        }),
    },
    {
        key: 'CONSTRUCTION',
        title: defineMessage({
            id: '74e6c.AddOptions.construction',
            defaultMessage: 'Construction',
        }),
    },
    {
        key: 'TRADE OR REPAIR OF VEHICLES',
        title: defineMessage({
            id: '74e6c.AddOptions.tradeOrRepairOfVehicles',
            defaultMessage: 'Trade or Repair of Vehicles',
        }),
    },
    {
        key: 'TRANSPORTATION AND STORAGE',
        title: defineMessage({
            id: '74e6c.AddOptions.transportationAndStorage',
            defaultMessage: 'Transportation and Storage',
        }),
    },
    {
        key: 'ACCOMMODATION AND FOOD SERVICE',
        title: defineMessage({
            id: '74e6c.AddOptions.accommodationAndFoodService',
            defaultMessage: 'Accommodation and Food Service',
        }),
    },
    {
        key: 'INFORMATION AND COMMUNICATION',
        title: defineMessage({
            id: '74e6c.AddOptions.informationAndCommunication',
            defaultMessage: 'Information and Communication',
        }),
    },
    {
        key: 'FINANCIAL AND INSURANCE',
        title: defineMessage({
            id: '74e6c.AddOptions.financialAndInsurance',
            defaultMessage: 'Financial and Insurance',
        }),
    },
    {
        key: 'REAL ESTATE ACTIVITIES',
        title: defineMessage({
            id: '74e6c.AddOptions.realEstateActivities',
            defaultMessage: 'Real Estate Activities',
        }),
    },
    {
        key: 'PROFESSIONAL, TECHNICAL',
        title: defineMessage({
            id: '74e6c.AddOptions.professionalTechnical',
            defaultMessage: 'Professional, Technical',
        }),
    },
    {
        key: 'ADMINISTRATIVE AND SUPPORT',
        title: defineMessage({
            id: '74e6c.AddOptions.administrativeAndSupport',
            defaultMessage: 'Administrative and Support',
        }),
    },
    {
        key: 'SOCIAL CARE ',
        title: defineMessage({
            id: '74e6c.AddOptions.socialCare',
            defaultMessage: 'Social Care',
        }),
    },
    {
        key: 'ARTS AND ENTERTAINMENT',
        title: defineMessage({
            id: '74e6c.AddOptions.artsAndEntertainment',
            defaultMessage: 'Arts and Entertainment',
        }),
    },
    {
        key: 'LEISURE, SPORT AND TOURISM',
        title: defineMessage({
            id: '74e6c.AddOptions.leisureSportAndTourism',
            defaultMessage: 'Leisure, Sport and Tourism',
        }),
    },
    {
        key: 'FOSTER/ADOPTION',
        title: defineMessage({
            id: '74e6c.AddOptions.fosterAdoption',
            defaultMessage: 'Foster / Adoption',
        }),
    },
    {
        key: 'CHILD CARE',
        title: defineMessage({
            id: '74e6c.AddOptions.childCare',
            defaultMessage: 'Child Care',
        }),
    },
    {
        key: 'DRIVERS',
        title: defineMessage({
            id: '74e6c.AddOptions.drivers',
            defaultMessage: 'Drivers',
        }),
    },
    {
        key: 'LAW ENFORCEMENT AND SECURITY',
        title: defineMessage({
            id: '74e6c.AddOptions.lawEnforcementAndSecurity',
            defaultMessage: 'Law Enforcement and Security',
        }),
    },
    {
        key: 'RECRUITMENT AND HR',
        title: defineMessage({
            id: '74e6c.AddOptions.recruitmentAndHR',
            defaultMessage: 'Recruitment and HR',
        }),
    },
];
