// Libraries
import React from 'react';
import { FormattedMessage } from 'react-intl';

// UI Components
import Title from 'certn-ui/Title';
import { AppearRight } from 'certn-ui/Animate';
import MultiAddItemsForm from 'certn-form/MultiAddItemsForm';

class RentRefusalForm extends React.Component {
    rentRefusalsConfig = {
        name: 'rent_refusals',
        buttonText: this.props.intl.formatMessage({
            id: 'welcome.History.RefusalsForm.buttonText',
            defaultMessage: 'Add Rent Refusal',
        }),
        keys: [
            {
                id: 'description',
                name: this.props.intl.formatMessage({
                    id: 'common.description',
                    defaultMessage: 'Description',
                }),
                required: true,
            },
            {
                id: 'date_of_refusal',
                name: this.props.intl.formatMessage({
                    id: 'welcome.general.dateOfRefusal',
                    defaultMessage: 'Date Of Refusal',
                }),
                required: true,
                dateField: true,
                disableFutureDates: true,
            },
            {
                id: 'address',
                name: this.props.intl.formatMessage({ id: 'welcome.general.address', defaultMessage: 'Address' }),
                required: true,
            },
        ],
    };

    render() {
        const { handleSubmit, rentRefusals } = this.props;
        return (
            <AppearRight>
                <Title>
                    <FormattedMessage
                        id="welcome.History.RefusalsForm.title"
                        defaultMessage="You mentioned you refused rent previously, what happened?"
                    />
                </Title>
                <MultiAddItemsForm items={rentRefusals} config={this.rentRefusalsConfig} handleSubmit={handleSubmit} />
            </AppearRight>
        );
    }
}

export default RentRefusalForm;
