// Libraries
import React from 'react';
import PropTypes from 'prop-types';

// UI Components
import { ButtonLine } from 'certn-ui/Button';
import { Box } from 'certn-ui/Layout';
import { Form } from '@ant-design/compatible';
import { Input, Alert, Radio } from 'antd';
import styled from 'styled-components/macro';
import { injectIntl, FormattedMessage } from 'react-intl';
import DateInput from 'certn-form/DateInput';

// CONSTANTS
import { NOT_APPLICABLE } from '../ReferenceConstants';

const propTypes = {
    question: PropTypes.object.isRequired,
    questionCount: PropTypes.number.isRequired,
    getFieldDecorator: PropTypes.func.isRequired,
    error: PropTypes.string,
    submitReferenceQuestions: PropTypes.func,
};
const defaultProps = {
    error: undefined,
    submitReferenceQuestions: null,
};

const RadioWrapper = styled(Radio.Group)`
    display: flex !important;
    flex-direction: column !important;
    > label {
        margin: 5px !important;
    }
`;

const QuestionTitle = styled.h4`
    margin: 30px 0 15px 0;
    color: ${({ theme }) => theme.color.certnGray700};
    &::after {
        content: ${(props) => (props.required ? '"*"' : 'normal')};
        color: ${({ theme }) => theme.color.certnRed500};
        margin-left: 5px;
    }
`;

const ReferenceForm = ({ question, questionCount, getFieldDecorator, error, submitReferenceQuestions, intl }) => {
    const renderQuestion = () => {
        const { TextArea } = Input;
        const rules = {
            requiredBlank: {
                required: true,
                message: intl.formatMessage({
                    id: 'error.validation.notBlank',
                    defaultMessage: 'Please do not leave blank',
                }),
            },
            requiredSelection: {
                required: true,
                message: intl.formatMessage({
                    id: 'error.validation.notSelected',
                    defaultMessage: 'Please make a selection',
                }),
            },
            validDate: {
                required: true,
                message: intl.formatMessage({
                    id: 'error.validation.validDate',
                    defaultMessage: 'Please enter a valid date',
                }),
            },
            validText: {
                type: 'string',
                message: intl.formatMessage({
                    id: 'e9263.ReferenceForm.invalid',
                    defaultMessage: 'The input is not valid text!',
                }),
            },
        };

        switch (question.type) {
            case 'NUMERIC_RANGE':
                return (
                    <>
                        <QuestionTitle required={question.is_required}>
                            {`${questionCount + 1}. ${question.question}`}
                        </QuestionTitle>
                        <Form.Item>
                            {getFieldDecorator(question.id, {
                                initialValue: question.numeric_range_response,
                                rules: question.is_required ? [rules.requiredSelection] : [],
                            })(
                                <Radio.Group>
                                    <Radio value={1}>1</Radio>
                                    <Radio value={2}>2</Radio>
                                    <Radio value={3}>3</Radio>
                                    <Radio value={4}>4</Radio>
                                    <Radio value={5}>5</Radio>
                                    <Radio value={6}>6</Radio>
                                    <Radio value={7}>7</Radio>
                                    <Radio value={8}>8</Radio>
                                    <Radio value={9}>9</Radio>
                                    <Radio value={10}>10</Radio>
                                </Radio.Group>
                            )}
                        </Form.Item>
                    </>
                );
            case 'DATE':
                return (
                    <>
                        <QuestionTitle required={question.is_required}>
                            {`${questionCount + 1}. ${question.question}`}
                        </QuestionTitle>
                        <Form.Item>
                            {getFieldDecorator(question.id, {
                                initialValue: question.date_response,
                                rules: [rules.validDate],
                            })(
                                <DateInput
                                    size="large"
                                    data-testid="reference_date"
                                    disableFutureDates
                                    placeholder={intl.formatMessage({
                                        id: 'field.placeholder.date',
                                        defaultMessage: 'Date',
                                    })}
                                    addonBeforeUsePlaceholder
                                />
                            )}
                        </Form.Item>
                    </>
                );
            case 'LONG_ANSWER':
                return (
                    <>
                        <QuestionTitle required={question.is_required}>
                            {`${questionCount + 1}. ${question.question}`}
                        </QuestionTitle>
                        <Form.Item style={{ width: '100%' }}>
                            {getFieldDecorator(question.id, {
                                initialValue: question.long_answer_response,
                                rules: question.is_required
                                    ? [rules.validText, rules.requiredBlank]
                                    : [rules.validText],
                            })(<TextArea rows={4} />)}
                        </Form.Item>
                    </>
                );
            case 'TRUE_FALSE':
                return (
                    <>
                        <QuestionTitle required={question.is_required}>
                            {`${questionCount + 1}. ${question.question}`}
                        </QuestionTitle>
                        <Form.Item>
                            {getFieldDecorator(question.id, {
                                initialValue: question.true_false_response,
                                rules: question.is_required ? [rules.requiredSelection] : [],
                            })(
                                <Radio.Group>
                                    <Radio value>
                                        <FormattedMessage id="e9263.ReferenceForm.true" defaultMessage="True" />
                                    </Radio>
                                    <Radio value={false}>
                                        <FormattedMessage id="e9263.ReferenceForm.false" defaultMessage="False" />
                                    </Radio>
                                    <Radio value={NOT_APPLICABLE}>
                                        <FormattedMessage id="e9263.ReferenceForm.na" defaultMessage="Not Applicable" />
                                    </Radio>
                                </Radio.Group>
                            )}
                        </Form.Item>
                    </>
                );
            case 'YES_NO':
                return (
                    <>
                        <QuestionTitle required={question.is_required}>
                            {`${questionCount + 1}. ${question.question}`}
                        </QuestionTitle>
                        <Form.Item>
                            {getFieldDecorator(question.id, {
                                initialValue: question.yes_no_response,
                                rules: question.is_required ? [rules.requiredSelection] : [],
                            })(
                                <Radio.Group>
                                    <Radio value="yes">
                                        <FormattedMessage id="e9263.ReferenceForm.yes" defaultMessage="Yes" />
                                    </Radio>
                                    <Radio value="no">
                                        <FormattedMessage id="e9263.ReferenceForm.no" defaultMessage="No" />
                                    </Radio>
                                    <Radio value={NOT_APPLICABLE}>
                                        <FormattedMessage id="e9263.ReferenceForm.na" defaultMessage="Not Applicable" />
                                    </Radio>
                                </Radio.Group>
                            )}
                        </Form.Item>
                    </>
                );
            case 'MULTIPLE_CHOICE':
                return (
                    <>
                        <QuestionTitle required={question.is_required}>
                            {`${questionCount + 1}. ${question.question}`}
                        </QuestionTitle>
                        <Form.Item>
                            {getFieldDecorator(question.id, {
                                initialValue: question.multiple_choice_response,
                                rules: question.is_required ? [rules.requiredSelection] : [],
                            })(
                                <RadioWrapper>
                                    {question.multiple_choice_options.map((option) => (
                                        <Radio key={option} value={option}>
                                            {option}
                                        </Radio>
                                    ))}
                                </RadioWrapper>
                            )}
                        </Form.Item>
                    </>
                );
            default:
        }
        return null;
    };

    return (
        <Box size="med" center>
            {renderQuestion()}
            {Boolean(submitReferenceQuestions) && error && (
                <ButtonLine>
                    <Alert message={error} type="error" showIcon />
                </ButtonLine>
            )}
        </Box>
    );
};

ReferenceForm.propTypes = propTypes;
ReferenceForm.defaultProps = defaultProps;

export default injectIntl(ReferenceForm);
