// Libraries
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get } from 'lodash';
import { withRouter } from 'react-router-dom';
import { Form as AntdForm } from '@ant-design/compatible';
import { injectIntl, FormattedMessage } from 'react-intl';
import { message } from 'antd';
import { intl } from 'components/GlobalProvider';

// Modules & Utils
import Auth from 'modules/Auth';

// Actions & Selectors
import { getSettings, getCurrentTeam } from 'views/manager/views/settings/SettingsSelectors';
import { updateSettings } from 'views/manager/views/settings/SettingsActions';
import { getTeam } from 'views/manager/views/admin/AdminSelectors';

// UI Components
import { ErrorAlertAPI } from 'certn-ui/ErrorAlert';
import Form from 'certnd/Form';
import Typography from 'certnd/Typography';

const { Heading } = Typography;

const mapStateToProps = (state) => ({
    settings: getSettings(state),
    currentTeam: getCurrentTeam(state),
    team: getTeam(state),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            updateSettings,
        },
        dispatch
    );

const Notifications = AntdForm.create()(
    // eslint-disable-next-line
    class Team extends React.Component {
        handleSubmit = (e) => {
            e.preventDefault();
            this.props.form.validateFieldsAndScroll((err, values) => {
                if (!err) {
                    this.props
                        .updateSettings(values, this.props.team.id)
                        .then(() =>
                            message.success(
                                intl.formatMessage({
                                    id: 'common.settingsSavedSuccessfully',
                                    defaultMessage: 'Settings saved successfully.',
                                })
                            )
                        )
                        .catch((error) => ErrorAlertAPI(error));
                }
            });
        };

        resetFilters = (event) => {
            const { form } = this.props;
            const { value } = event.target;

            if (value === 'NONE') {
                form.setFieldsValue({ review_notifications_only: false });
            }
        };

        render() {
            const canEdit = Auth.isPermissionLevel('manager');
            const { form, currentTeam } = this.props;

            const notification_frequency =
                form.getFieldValue('notification_frequency') || currentTeam.notification_frequency;

            return (
                <AntdForm onSubmit={this.handleSubmit}>
                    <Heading.H1 data-testid="title">
                        <FormattedMessage id="0baa3.Notifications.PageTitle" defaultMessage="Email Notifications" />
                    </Heading.H1>
                    <Form.Block>
                        <Heading.H3>
                            <FormattedMessage id="notifications.recipients" defaultMessage="Recipients" />
                        </Heading.H3>
                        <Form.Checkbox
                            form={form}
                            data-testid="send_owner_notifications"
                            fieldName="send_owner_notifications"
                            title={this.props.intl.formatMessage({
                                id: '0baa3.Notifications.EmailUpdatesTitle',
                                defaultMessage: 'Send Email Updates to Application Creator',
                            })}
                            description={this.props.intl.formatMessage({
                                id: '0baa3.Notifications.EmailUpdatesDescription',
                                defaultMessage: 'Send email update notifications to the creator of an application.',
                            })}
                            options={{ initialValue: get(currentTeam, ['send_owner_notifications']) }}
                            disabled={!canEdit}
                            hideBorderOnly
                        />
                        <Form.Checkbox
                            form={form}
                            data-testid="allow_package_owner_notifications"
                            fieldName="allow_package_owner_notifications"
                            title={this.props.intl.formatMessage({
                                id: '0baa3.Notifications.ApplicationOwnerNotifications',
                                defaultMessage: 'Enable Additional Destinations For Email Notifications',
                            })}
                            description={this.props.intl.formatMessage({
                                id: '0baa3.Notifications.NotificationUpdatesDescription',
                                defaultMessage:
                                    "Currently only the application owner receives notification about an application's progress. Turn this on if you want additional destinations for email notifications.",
                            })}
                            options={{ initialValue: get(currentTeam, ['allow_package_owner_notifications']) }}
                            disabled={!canEdit}
                        />

                        <Heading.H3>
                            <FormattedMessage
                                id="notifications.notificationFrequency"
                                defaultMessage="Notification Frequency"
                            />
                        </Heading.H3>
                        <Form.Radio
                            form={form}
                            onChange={this.resetFilters}
                            fieldName="notification_frequency"
                            options={{ initialValue: get(currentTeam, ['notification_frequency']) }}
                            radioOptions={[
                                {
                                    title: this.props.intl.formatMessage({
                                        id: '0baa3.Notifications.NoUpdateTitle',
                                        defaultMessage: 'No Update Emails',
                                    }),
                                    description: this.props.intl.formatMessage({
                                        id: '0baa3.Notifications.NoUpdateDescription',
                                        defaultMessage: 'Turn off all email notifications about applications',
                                    }),
                                    key: 'NONE',
                                },
                                {
                                    title: this.props.intl.formatMessage({
                                        id: '0baa3.Notifications.PartialTitle',
                                        defaultMessage: 'Partial Update Emails',
                                    }),
                                    description: this.props.intl.formatMessage({
                                        id: '0baa3.Notifications.PartialDescription',
                                        defaultMessage:
                                            'Send me an email when any part of the report is updated or is returned',
                                    }),
                                    key: 'PARTIAL',
                                },
                                {
                                    title: this.props.intl.formatMessage({
                                        id: '0baa3.Notifications.CompletionTitle',
                                        defaultMessage: 'Completion Update Emails',
                                    }),
                                    description: this.props.intl.formatMessage({
                                        id: '0baa3.Notifications.CompletionDescription',
                                        defaultMessage: 'Send me an email when a new report is completed and returned',
                                    }),
                                    key: 'COMPLETE',
                                },
                            ]}
                        />
                        <Heading.H3>
                            <FormattedMessage id="common.filters" defaultMessage="Filters" />
                        </Heading.H3>
                        <Form.Checkbox
                            form={form}
                            data-testid="review_notifications_only"
                            fieldName="review_notifications_only"
                            title={this.props.intl.formatMessage({
                                id: 'notifications.reviewStatusTitle',
                                defaultMessage: "Send email updates for applications with a 'Review' status",
                            })}
                            description={this.props.intl.formatMessage({
                                id: 'notifications.reviewStatusDescription',
                                defaultMessage:
                                    "Turn this on if you'd like emails to only be sent when the report or parts of the report are in a 'Review' status.",
                            })}
                            options={{ initialValue: get(currentTeam, ['review_notifications_only']) }}
                            disabled={!canEdit || notification_frequency === 'NONE'}
                        />
                        <Form.Button disabled={!canEdit}>
                            <FormattedMessage id="common.save" defaultMessage="Save" />
                        </Form.Button>
                    </Form.Block>
                </AntdForm>
            );
        }
    }
);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(Notifications)));
