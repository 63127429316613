// Libraries
import React from 'react';
import styled from 'styled-components';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Select, Input, Row, Col, Checkbox } from 'antd';
import { get } from 'lodash';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

// Components
import { ProvStateSelect } from 'components';
import { AppearRight } from 'certn-ui/Animate';
import { Box } from 'certn-ui/Layout';
import Text from 'certn-ui/Text';
import Title from 'certn-ui/Title';
import { TrackNavigation } from 'views/welcome/components';
import FormLayout from 'certn-form/FormLayout';

// Modules
import { Format, LicenceValidator } from 'modules';
import Countries, { isUSAOrCanada } from 'modules/Countries';
import { hasLicenseRegex } from 'modules/LicenceValidator';
import { parseURL } from '../utils';
import { getLaunchDarklyFlags } from 'base/BaseSelectors';

const TextLabel = styled(Text)`
    color: ${(props) => props.theme.color.certnGreen700};
`;

const CheckboxWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: -20px;
    margin-bottom: 20px;
    > div {
        margin: 0 !important;
    }
`;

const LicenseWrapper = styled.div`
    display: flex;
    margin-bottom: 20px;
`;

const mapStateToProps = (state) => ({
    quebec_mvr_flag: getLaunchDarklyFlags(state)?.webFeatureEnableQuebecMvr,
});

// Used along with the new province state lib that supports multi-language
const provinceFilter = ({ iso }) =>
    // Filter province/states that we don't have regexs for or license images
    hasLicenseRegex(iso) && Format.provinceCodeToName(iso) !== 'Other';

class DriverForm extends React.Component {
    componentDidMount() {
        const { information } = this.props;
        const no_license = get(information, 'license_type') === 'NONE';
        const international_license = get(information, 'license_type') === 'INTERNATIONAL';

        this.props.form.setFieldsValue({ no_license, international_license });
    }

    reset = (e) => {
        if (e.target.checked) {
            this.props.form.setFieldsValue({ license_type: undefined });
            this.props.form.setFieldsValue({ license_prov_state: undefined });
            this.props.form.setFieldsValue({ license_number: undefined });
            this.setState({ [e.target.id]: true });
            return null;
        }
        this.setState({ [e.target.id]: false });
    };

    countryChange = () => {
        this.props.form.setFieldsValue({ license_prov_state: undefined });
        this.props.form.setFieldsValue({ license_number: undefined });
    };

    generateImageURL = () =>
        parseURL(this.props.form.getFieldValue('license_type'), this.props.form.getFieldValue('license_prov_state'));

    render() {
        const {
            handleSubmit,
            information,
            form: { getFieldDecorator, validateFields, getFieldValue, setFieldsValue },
            intl,
            quebec_mvr_flag,
        } = this.props;
        const unavailableLocation = quebec_mvr_flag ? ['AB'] : ['AB', 'QC'];
        const provDisabled = unavailableLocation.includes(getFieldValue('license_prov_state'));
        return (
            <AppearRight>
                <Form layout="horizontal" onSubmit={(e) => handleSubmit(e, validateFields)}>
                    <Title>
                        <FormattedMessage
                            id="welcome.Driver.title"
                            defaultMessage="In order to begin a motor vehicle check, we need the following information about your current driver's license:"
                        />
                    </Title>
                    <FormLayout>
                        <TextLabel>
                            <FormattedMessage id="welcome.Driver.countryOfIssue" defaultMessage="Country of issue">
                                {([text]) => `${text?.toUpperCase()}`}
                            </FormattedMessage>
                        </TextLabel>
                        <Form.Item>
                            {getFieldDecorator('license_type', {
                                initialValue:
                                    get(information, 'license_type') &&
                                    get(information, 'license_type') !== 'NONE' &&
                                    get(information, 'license_type') !== 'INTERNATIONAL'
                                        ? get(information, 'license_type')
                                        : undefined,
                                rules: [
                                    {
                                        required:
                                            !getFieldValue('international_license') && !getFieldValue('no_license'),
                                        message: intl.formatMessage({
                                            id: 'error.validation.countryError',
                                            defaultMessage: 'Select a country!',
                                        }),
                                    },
                                ],
                            })(
                                <Select
                                    disabled={getFieldValue('no_license') || getFieldValue('international_license')}
                                    placeholder={intl.formatMessage({
                                        id: 'welcome.Driver.countryOfIssue',
                                        defaultMessage: 'Country of issue',
                                    })}
                                    autoComplete="false"
                                    onChange={this.countryChange}
                                    showSearch
                                    filterOption={(input = '', { props }) =>
                                        props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                        props.value.indexOf(input.toUpperCase()) === 0
                                    }
                                >
                                    {Countries.getCanadaUsOnly(intl).map(({ name, alpha2 }) => (
                                        <Select.Option value={alpha2} key={alpha2}>
                                            {name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            )}
                        </Form.Item>
                        <CheckboxWrapper>
                            <Form.Item>
                                {getFieldDecorator('international_license', {
                                    initialValue: get(information, 'license_type') === 'INTERNATIONAL',
                                    valuePropName: 'checked',
                                })(
                                    <Checkbox onChange={this.reset} disabled={getFieldValue('no_license')}>
                                        <FormattedMessage
                                            id="welcome.Driver.nonCAUSALicense"
                                            defaultMessage="I currently have a driver's license from a country not above."
                                        />
                                    </Checkbox>
                                )}
                            </Form.Item>
                            <Form.Item>
                                {getFieldDecorator('no_license', {
                                    initialValue: get(information, 'license_type') === 'NONE',
                                    valuePropName: 'checked',
                                })(
                                    <Checkbox onChange={this.reset} disabled={getFieldValue('international_license')}>
                                        <FormattedMessage
                                            id="welcome.Driver.noLicense"
                                            defaultMessage="I don't currently have a valid driver's license."
                                        />
                                    </Checkbox>
                                )}
                            </Form.Item>
                        </CheckboxWrapper>
                        <div
                            style={{
                                display: isUSAOrCanada(getFieldValue('license_type')) ? 'initial' : 'none',
                            }}
                        >
                            <TextLabel>
                                <FormattedMessage
                                    id="welcome.Driver.ofIssue"
                                    defaultMessage="PROVINCE/STATE OF ISSUE"
                                />
                            </TextLabel>
                            <Form.Item>
                                {getFieldDecorator('license_prov_state', {
                                    initialValue: get(information, 'license_prov_state')
                                        ? get(information, 'license_prov_state')
                                        : undefined,
                                    rules: [
                                        {
                                            required:
                                                !getFieldValue('international_license') && !getFieldValue('no_license'),
                                            message: intl.formatMessage({
                                                id: 'error.validation.countryError',
                                                defaultMessage: 'Select a country!',
                                            }),
                                        },
                                    ],
                                })(
                                    <ProvStateSelect
                                        placeholder={
                                            <FormattedMessage
                                                id="address.provinceState"
                                                defaultMessage="PROVINCE/STATE"
                                            />
                                        }
                                        onChange={(val) =>
                                            unavailableLocation.includes(val) &&
                                            setFieldsValue({ license_number: undefined })
                                        }
                                        selectedCountry={getFieldValue('license_type')}
                                        provinceFilter={provinceFilter}
                                    />
                                )}
                            </Form.Item>
                        </div>
                        <div
                            style={{
                                display: getFieldValue('license_prov_state') ? 'initial' : 'none',
                            }}
                        >
                            {!provDisabled && (
                                <LicenseWrapper>
                                    <img style={{ width: '100%' }} src={this.generateImageURL()} alt="License" />
                                </LicenseWrapper>
                            )}
                            <TextLabel>
                                <FormattedMessage id="welcome.Driver.licenseNumber" defaultMessage="License Number">
                                    {([text]) => `${text?.toUpperCase()}`}
                                </FormattedMessage>
                            </TextLabel>
                            <Form.Item>
                                {getFieldDecorator('license_number', {
                                    initialValue: get(information, 'license_number') || undefined,
                                    validateTrigger: 'onBlur',
                                    getValueFromEvent: (target) => target.target.value?.toUpperCase(),
                                    rules: [
                                        {
                                            validator: (rule, value, callback) => {
                                                if (!value) return callback();
                                                return LicenceValidator(value, getFieldValue('license_prov_state'))
                                                    ? callback()
                                                    : callback(
                                                          new Error(
                                                              intl.formatMessage({
                                                                  id: 'welcome.Driver.licenceValidation',
                                                                  defaultMessage:
                                                                      'The license entered does not match the formatting of the Province/State selected.',
                                                              })
                                                          )
                                                      );
                                            },
                                        },
                                        {
                                            required:
                                                !getFieldValue('international_license') &&
                                                !getFieldValue('no_license') &&
                                                !provDisabled,
                                            message: intl.formatMessage({
                                                id: 'error.validation.notBlank',
                                                defaultMessage: 'Please do not leave blank',
                                            }),
                                        },
                                    ],
                                })(
                                    <Input
                                        disabled={provDisabled}
                                        placeholder={intl.formatMessage({
                                            id: 'welcome.Driver.licenseNumber',
                                            defaultMessage: 'License Number',
                                        })}
                                    />
                                )}
                            </Form.Item>
                        </div>
                    </FormLayout>
                    {provDisabled && (
                        <Row gutter={16}>
                            <Col sm={24} lg={{ span: 24, offset: 0 }}>
                                <Box size="xxs">
                                    <Text align="center" color="certnGray600">
                                        <FormattedMessage
                                            id="welcome.Driver.unavailable"
                                            defaultMessage="Digital motor vehicle records are currently unavailable from this jurisdiction. Your prospective employer may ask you to submit physical paperwork instead. Click next to continue."
                                        />
                                    </Text>
                                </Box>
                            </Col>
                        </Row>
                    )}
                    <TrackNavigation />
                </Form>
            </AppearRight>
        );
    }
}
export default withLDConsumer()(connect(mapStateToProps)(Form.create()(injectIntl(DriverForm))));
