// Libraries
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Form, Checkbox } from 'antd';

// Components
import { ScrollFrame } from 'views/welcome/components';

const HRTermsFCRA = ({ intl }) => (
    <>
        <Form.Item
            name="terms_accepted"
            key="terms_accepted"
            valuePropName="checked"
            initialValue={false}
            rules={[
                {
                    validator: (_, value) => {
                        if (value) return Promise.resolve();
                        return Promise.reject(
                            new Error(
                                intl.formatMessage({
                                    id: 'error.validation.mustAgreeToTerms',
                                    defaultMessage: 'You must agree to Terms of Check to continue',
                                })
                            )
                        );
                    },
                },
            ]}
        >
            <Checkbox>
                <FormattedMessage
                    id="welcome.Submit.userCertCheckbox"
                    defaultMessage="By “signing here or checking the box”, I certify that the following is true:"
                />
            </Checkbox>
        </Form.Item>
        <ScrollFrame height="320px" width="100%" marginTop="0" marginBottom="24px">
            <FormattedMessage
                id="welcome.Submit.userCertOne"
                defaultMessage="1. This report is being requested for employment purposes only."
            />
            <br />
            <br />
            <FormattedMessage
                id="welcome.Submit.userCertTwo"
                defaultMessage="2. Prior to this consumer report request being submitted, a disclosure will be provided to the consumer in accordance with requirements set forth in the Fair Credit Reporting Act (FCRA) and the consumer will authorize in writing that this report may be procured."
            />
            <br />
            <br />
            <FormattedMessage
                id="welcome.Submit.userCertThree"
                defaultMessage="3. Information contained in the background check will not be used in violation of any federal or state equal opportunity law or regulation."
            />
            <br />
            <br />
            <FormattedMessage
                id="welcome.Submit.userCertFour"
                defaultMessage="4. If I am requesting an investigative consumer report on this consumer, that upon the written request the consumer made within a reasonable period of time after the disclosures required above, I will make a complete disclosure of the nature and scope of the investigation. This will be made in a written statement that is mailed, or otherwise delivered, to the consumer no later than five days after the date on which the request was received from the consumer or the report was first requested, whichever is later in time."
            />
            <br />
            <br />
            <FormattedMessage
                id="welcome.Submit.userCertFive"
                defaultMessage="5. If my jurisdiction does not allow a background check to be procured until after a conditional offer has been made, that I have made this conditional offer before requesting this report."
            />
            <br />
            <br />
            <FormattedMessage
                id="welcome.Submit.userCertSix"
                defaultMessage="6. <u>I will comply with New York City Law.</u> I understand that the New York City Humans Rights Law (“NYCHRL”) imposes various legal requirements on covered employers and businesses. I will comply with all applicable requirements under NYCHRL. Among other things, I shall:"
                values={{
                    u: (chunks) => <u>{chunks}</u>,
                }}
            />
            <br />
            <ul>
                <FormattedMessage
                    id="welcome.Submit.userCertSixA"
                    defaultMessage="A. Not obtain or evaluate criminal history information about an applicant or employee until after a conditional offer of employment, promotion, or transfer has been extended to him or her;"
                />
                <br />
                <FormattedMessage
                    id="welcome.Submit.userCertSixB"
                    defaultMessage="B. Not inquire about or consider criminal history that is prohibited from being considered under NYCHRL;"
                />
                <br />
            </ul>
        </ScrollFrame>
    </>
);

export default injectIntl(HRTermsFCRA);
