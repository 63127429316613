import { message } from 'antd';
import { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ErrorAlertCustom } from 'certn-ui/ErrorAlert';
import {
    patchInformation,
    fetchSignedS3Link,
    confirmUploadSuccessful,
    deleteDocument,
} from 'views/welcome/WelcomeActions';
import { getDocuments } from 'views/welcome/WelcomeSelectors';

const SELFIE = 'SELFIE';

function useS3GetUploadLinkAndUpload(intl) {
    const dispatch = useDispatch();
    const documents = useSelector(getDocuments);

    const [selfie, setSelfie] = useState(documents?.find((doc) => doc.document_type === SELFIE) || {});
    const [uploadProgress, setUploadProgress] = useState(0);

    const s3GetUploadLinkAndUpload = useCallback(
        async (imgDataUri) => {
            const onUpload = async ({ document: preparedDocument, url, fields }) => {
                const showError = () => {
                    message.error(
                        intl.formatMessage({
                            id: 'welcome.Selfie.errorUploadingSelfie',
                            defaultMessage: 'Error uploading selfie',
                        })
                    );
                };

                const constructUploadBody = async (s3UploadFields) => {
                    const result = await fetch(imgDataUri);
                    const blob = await result.blob();
                    const body = new FormData();
                    Object.entries(s3UploadFields).forEach(([key, value]) => body.append(key, value));
                    body.append('file', blob);
                    return body;
                };

                const handleDeleteDocument = (file) => {
                    dispatch(deleteDocument(file))
                        .then(() => dispatch(patchInformation()))
                        .catch(() => ErrorAlertCustom());

                    message.warn(
                        intl.formatMessage({
                            id: 'welcome.Selfie.selfieRemoved',
                            defaultMessage: 'Selfie removed',
                        })
                    );

                    setSelfie({});
                    setUploadProgress(0);
                };

                const confirmUploadToS3 = async (newSelfie) => {
                    const s3Response = await fetch(newSelfie.url);

                    if (!s3Response.ok) {
                        handleDeleteDocument(newSelfie);
                        throw new Error();
                    }

                    await dispatch(confirmUploadSuccessful(preparedDocument.id));
                    await dispatch(patchInformation());
                    setUploadProgress(100);
                };

                const uploadToS3 = async (body, s3UploadLink) => {
                    const s3Response = await fetch(s3UploadLink, {
                        method: 'POST',
                        body,
                    });

                    if (!s3Response.ok) {
                        throw new Error();
                    }
                };

                if (!preparedDocument) {
                    return;
                }

                try {
                    const body = await constructUploadBody(fields);
                    await uploadToS3(body, url);

                    const newSelfie = {
                        status: 'done',
                        uid: preparedDocument.id,
                        name: preparedDocument.file_name,
                        url: preparedDocument.url,
                        type: preparedDocument.document_type,
                    };

                    setSelfie(newSelfie);
                    setUploadProgress(50);

                    try {
                        await confirmUploadToS3(newSelfie);
                    } catch (e) {
                        showError();
                    }
                } catch (e) {
                    showError();
                }
            };

            const response = await dispatch(fetchSignedS3Link({ name: 'selfie.jpg', type: 'image/jpeg' }, SELFIE));
            onUpload(response);
        },
        [intl, dispatch]
    );

    return {
        selfie,
        uploadProgress,
        s3GetUploadLinkAndUpload,
    };
}

export default useS3GetUploadLinkAndUpload;
