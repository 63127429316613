import { useQuery } from '@tanstack/react-query';
import { queryKeys } from 'utils/reactQuery';
import { OrderData } from 'types';
import { getRequest } from 'utils/http';

export const useOrderStatus = (applicantId: string) => {
    const { data, isFetching, isError, remove } = useQuery<OrderData>([queryKeys.orderStatus], () =>
        getRequest<OrderData>({
            endpoint: `/applicant/${applicantId}/order_status/`,
            internal: true,
        })
    );

    return {
        orderData: data,
        orderFetching: isFetching,
        orderError: isError,
        removeQuery: remove,
    };
};
