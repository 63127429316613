// Libraries
import React from 'react';
import styled from 'styled-components/macro';
import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';

const SummaryHeader = ({ title, onClick }) => (
    <Wrapper>
        <Title>{title}</Title>
        <Button
            size="large"
            icon={<EditOutlined />}
            shape="circle"
            style={{ border: 'none', margin: '0 2px' }}
            onClick={onClick}
        />
    </Wrapper>
);

export default SummaryHeader;

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${({ theme }) => theme.color.certnGray400};
    padding: 15px;
    padding-right: 25px;
    padding-left: 25px;
`;

const Title = styled.div`
    font-size: 20px;
    font-weight: bold;
`;
