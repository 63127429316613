import Country from 'country-data';
import { capitalize } from 'lodash';
import { locale, intl } from 'components/GlobalProvider';
import moment from 'moment';

class Format {
    static truncateText(text, length) {
        if (text.length > length) {
            return `${text.substring(0, length)}...`;
        }
        return text;
    }

    static percent(value, decimals = 0) {
        if (value === null || value === undefined) {
            return 'n/a';
        }

        let percent = '';
        if (value > 1) {
            percent = `${value.toFixed(decimals)}%`;
        } else {
            percent = `${(value * 100).toFixed(decimals)}%`;
        }
        return percent;
    }

    static dollar(amount) {
        let newAmount = amount;
        if (newAmount === null || newAmount === undefined) {
            return 'n/a';
        }
        newAmount = parseFloat(newAmount).toFixed(2);
        const formatter = new Intl.NumberFormat(locale, {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
        });
        return formatter.format(newAmount);
    }

    static countryToCode(name) {
        const country = Country.lookup.countries({ name })[0];
        if (country) {
            return country.alpha2;
        }
        return name;
    }

    static codeToCountry(code) {
        const country = Country.lookup.countries({ alpha2: code })[0];
        if (country) {
            return country.name;
        }
        return code;
    }

    static codeToProvState(code) {
        const provStates = {
            AB: 'Alberta',
            BC: 'British Columbia',
            MB: 'Manitoba',
            NB: 'New Brunswick',
            NL: 'Newfoundland and Labrador',
            NS: 'Nova Scotia',
            NT: 'Northwest Territories',
            NU: 'Nunavut',
            ON: 'Ontario',
            PE: 'Prince Edward Island',
            QC: 'Quebec',
            SK: 'Saskatchewan',
            YT: 'Yukon',
        };
        const provState = provStates[code];
        if (provState) {
            return provState;
        }
        return code;
    }

    static date(date, month = 'short') {
        if (!date) {
            return 'n/a';
        }
        const newDate = new Date(date);
        const options = {
            timeZone: 'UTC',
            year: 'numeric',
            month,
            day: 'numeric',
        };
        return newDate.toLocaleDateString(locale, options);
    }

    static age(date, yo = false) {
        if (!date) {
            return 'n/a';
        }
        const birthday = new Date(date);
        const today = new Date();
        const diff_ms = today - birthday.getTime();
        const age_dt = new Date(diff_ms);

        return `${Math.abs(age_dt.getUTCFullYear() - 1970)}${yo ? ' years old' : ''}`;
    }

    static duration(start, end, current = false) {
        const newStart = new Date(start);
        let newEnd;
        if (current) {
            newEnd = new Date();
        } else {
            newEnd = new Date(end);
        }

        let months;
        months = (newEnd.getFullYear() - newStart.getFullYear()) * 12;
        months += newEnd.getMonth();
        months -= newStart.getMonth();
        if (months < 0) {
            months = 0;
        }

        const years = parseInt(months / 12, 10);
        months %= 12;

        return `${years} Years, ${months} Months`;
    }

    static postalCode(code) {
        if (!code) {
            return 'n/a';
        }
        const newCode = `${code.slice(0, 3)}-${code.slice(3)}`;
        return newCode;
    }

    static postalCodeForm(code) {
        if (!code) {
            return null;
        }
        const newCode = code.replace(/[^a-z0-9]/gi, '');
        return newCode;
    }

    static phone(num) {
        if (!num) {
            return 'n/a';
        }
        let newNum = num;
        if (num.length === 10) {
            newNum = `(${num.slice(0, 3)}) ${num.slice(3, 6)}-${num.slice(6)}`;
        } else if (num.length === 7) {
            newNum = `${num.slice(0, 3)}-${num.slice(3)}`;
        } else if (num.length === 11) {
            newNum = `+${num.slice(0, 1)} (${num.slice(1, 4)}) ${num.slice(4, 7)} ${num.slice(7)}`;
        } else if (num.length === 12) {
            newNum = `+${num.slice(0, 2)} (${num.slice(2, 5)}) ${num.slice(5, 8)} ${num.slice(8)}`;
        }

        return newNum;
    }

    static codeToReason(code, other) {
        let reason = '';
        if (code === 'BN') {
            reason = 'Looking for bigger/nicer location';
        } else if (code === 'CS') {
            reason = 'Looking for change of scenery';
        } else if (code === 'S') {
            reason = 'School';
        } else if (code === 'CJ') {
            reason = 'Change of job';
        } else if (code === 'CJL') {
            reason = 'Change of job location';
        } else if (code === 'CFRS') {
            reason = 'Change in family/relationship status';
        } else if (code === 'SP') {
            reason = 'Sale of property';
        } else if (code === 'E') {
            reason = 'Evicted';
        } else if (code === 'M') {
            reason = 'Moved out of home';
        } else if (code === 'O' && other) {
            reason = other;
        } else {
            reason = 'No reason given';
        }

        return reason;
    }

    static codeToAssociation(code) {
        let assoc = '';
        if (code === 'SP') {
            assoc = 'Spouse/Partner';
        } else if (code === 'P') {
            assoc = 'Parent';
        } else if (code === 'S') {
            assoc = 'Sibling';
        } else if (code === 'C') {
            assoc = 'Cousin';
        } else if (code === 'F') {
            assoc = 'Friend';
        } else {
            assoc = 'Other';
        }

        return assoc;
    }

    static codeToHealthInsurance(code) {
        const options = {
            NC: 'No Coverage',
            PG: 'Public / Government Plan',
            PEP: 'Public + Employer / Private Plan',
            E: 'Employer',
            P: 'Private',
        };
        const insurance = options[code];
        if (insurance) {
            return insurance;
        }
        return 'None Specified';
    }
}

export const getURLParamPlainJavascript = (name) => {
    const newName = name.replace(/[[\]]/g, '\\$&');
    const url = window.location.href;
    const regex = new RegExp(`[?&]${newName}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const parseLink = (link) => {
    let correctedLink = link;
    // Is this try catch ncessary now that we Array.isArray below?
    try {
        correctedLink = JSON.parse(link.replace(/'/g, '"'));
    } catch (e) {
        console.error('Is not an array');
    }
    return Array.isArray(correctedLink) ? correctedLink[0] : correctedLink;
};

export const camelcase = (text) => {
    const partOne = text.slice(0, 1).toUpperCase();
    const partTwo = text.substring(1).toLowerCase();
    return partOne + partTwo;
};

export const titleCase = (text) => (text ? text.replace(/[\w]+/g, capitalize) : '');

export const provinceCodeToName = (code) => {
    let province = '';
    if (code === 'AB') {
        province = 'Alberta';
    } else if (code === 'BC') {
        province = 'British Columbia';
    } else if (code === 'MB') {
        province = 'Manitoba';
    } else if (code === 'NB') {
        province = 'New Brunswick';
    } else if (code === 'NL') {
        province = 'Newfoundland and Labrador';
    } else if (code === 'NS') {
        province = 'Nova Scotia';
    } else if (code === 'ON') {
        province = 'Ontario';
    } else if (code === 'PE') {
        province = 'Prince Edward Island';
    } else if (code === 'QC') {
        province = 'Quebec';
    } else if (code === 'SK') {
        province = 'Saskatchewan';
    } else if (code === 'NT') {
        province = 'Northwest Territories';
    } else if (code === 'NU') {
        province = 'Nunavut';
    } else if (code === 'YT') {
        province = 'Yukon';
    } else if (code === 'AL') {
        province = 'Alabama';
    } else if (code === 'AK') {
        province = 'Alaska';
    } else if (code === 'AZ') {
        province = 'Arizona';
    } else if (code === 'AR') {
        province = 'Arkansas';
    } else if (code === 'CA') {
        province = 'California';
    } else if (code === 'CO') {
        province = 'Colorado';
    } else if (code === 'CT') {
        province = 'Connecticut';
    } else if (code === 'DE') {
        province = 'Delaware';
    } else if (code === 'DC') {
        province = 'District Of Columbia';
    } else if (code === 'FL') {
        province = 'Florida';
    } else if (code === 'GA') {
        province = 'Georgia';
    } else if (code === 'HI') {
        province = 'Hawaii';
    } else if (code === 'ID') {
        province = 'Idaho';
    } else if (code === 'IL') {
        province = 'Illinois';
    } else if (code === 'IN') {
        province = 'Indiana';
    } else if (code === 'IA') {
        province = 'Iowa';
    } else if (code === 'KS') {
        province = 'Kansas';
    } else if (code === 'KY') {
        province = 'Kentucky';
    } else if (code === 'LA') {
        province = 'Louisiana';
    } else if (code === 'ME') {
        province = 'Maine';
    } else if (code === 'MD') {
        province = 'Maryland';
    } else if (code === 'MA') {
        province = 'Massachusetts';
    } else if (code === 'MI') {
        province = 'Michigan';
    } else if (code === 'MN') {
        province = 'Minnesota';
    } else if (code === 'MS') {
        province = 'Mississippi';
    } else if (code === 'MO') {
        province = 'Missouri';
    } else if (code === 'MT') {
        province = 'Montana';
    } else if (code === 'NE') {
        province = 'Nebraska';
    } else if (code === 'NV') {
        province = 'Nevada';
    } else if (code === 'NH') {
        province = 'New Hampshire';
    } else if (code === 'NJ') {
        province = 'New Jersey';
    } else if (code === 'NM') {
        province = 'New Mexico';
    } else if (code === 'NY') {
        province = 'New York';
    } else if (code === 'NC') {
        province = 'North Carolina';
    } else if (code === 'ND') {
        province = 'North Dakota';
    } else if (code === 'OH') {
        province = 'Ohio';
    } else if (code === 'OK') {
        province = 'Oklahoma';
    } else if (code === 'OR') {
        province = 'Oregon';
    } else if (code === 'PA') {
        province = 'Pennsylvania';
    } else if (code === 'RI') {
        province = 'Rhode Island';
    } else if (code === 'SC') {
        province = 'South Carolina';
    } else if (code === 'SD') {
        province = 'South Dakota';
    } else if (code === 'TN') {
        province = 'Tennessee';
    } else if (code === 'TX') {
        province = 'Texas';
    } else if (code === 'UT') {
        province = 'Utah';
    } else if (code === 'VT') {
        province = 'Vermont';
    } else if (code === 'VA') {
        province = 'Virginia';
    } else if (code === 'WA') {
        province = 'Washington';
    } else if (code === 'WV') {
        province = 'West Virginia';
    } else if (code === 'WI') {
        province = 'Wisconsin';
    } else if (code === 'WY') {
        province = 'Wyoming';
    } else if (code === 'ACT') {
        province = 'Australian Capital Territory';
    } else if (code === 'NSW') {
        province = 'New South Wales';
    } else if (code === 'NT') {
        province = 'Northern Territory';
    } else if (code === 'QLD') {
        province = 'Queensland';
    } else if (code === 'SA') {
        province = 'South Australia';
    } else if (code === 'TAS') {
        province = 'Tasmania';
    } else if (code === 'VIC') {
        province = 'Victoria';
    } else if (code === 'WA') {
        province = 'Western Australia';
    } else if (code === 'ENG') {
        province = 'England';
    } else if (code === 'WLS') {
        province = 'Wales';
    } else if (code === 'SCT') {
        province = 'Scotland';
    } else if (code === 'NIR') {
        province = 'Northern Ireland';
    } else {
        province = 'Other';
    }
    return province;
};

export const provinceToCode = (prov) => {
    let code = '';
    if (prov === 'Alberta') {
        code = 'AB';
    } else if (prov === 'British Columbia') {
        code = 'BC';
    } else if (prov === 'Manitoba') {
        code = 'MB';
    } else if (prov === 'New Brunswick') {
        code = 'NB';
    } else if (prov === 'Newfoundland and Labrador') {
        code = 'NL';
    } else if (prov === 'Nova Scotia') {
        code = 'NS';
    } else if (prov === 'Ontario') {
        code = 'ON';
    } else if (prov === 'Prince Edward Island') {
        code = 'PE';
    } else if (prov === 'Quebec') {
        code = 'QC';
    } else if (prov === 'Saskatchewan') {
        code = 'SK';
    } else if (prov === 'Northwest Territories') {
        code = 'NT';
    } else if (prov === 'Nunavut') {
        code = 'NU';
    } else if (prov === 'Yukon') {
        code = 'YT';
    } else if (prov === 'Alabama') {
        code = 'AL';
    } else if (prov === 'Alaska') {
        code = 'AK';
    } else if (prov === 'Arizona') {
        code = 'AZ';
    } else if (prov === 'Arkansas') {
        code = 'AR';
    } else if (prov === 'California') {
        code = 'CA';
    } else if (prov === 'Colorado') {
        code = 'CO';
    } else if (prov === 'Connecticut') {
        code = 'CT';
    } else if (prov === 'Delaware') {
        code = 'DE';
    } else if (prov === 'District Of Columbia') {
        code = 'DC';
    } else if (prov === 'Florida') {
        code = 'FL';
    } else if (prov === 'Georgia') {
        code = 'GA';
    } else if (prov === 'Hawaii') {
        code = 'HI';
    } else if (prov === 'Idaho') {
        code = 'ID';
    } else if (prov === 'Illinois') {
        code = 'IL';
    } else if (prov === 'Indiana') {
        code = 'IN';
    } else if (prov === 'Iowa') {
        code = 'IA';
    } else if (prov === 'Kansas') {
        code = 'KS';
    } else if (prov === 'Kentucky') {
        code = 'KY';
    } else if (prov === 'Louisiana') {
        code = 'LA';
    } else if (prov === 'Maine') {
        code = 'ME';
    } else if (prov === 'Maryland') {
        code = 'MD';
    } else if (prov === 'Massachusetts') {
        code = 'MA';
    } else if (prov === 'Michigan') {
        code = 'MI';
    } else if (prov === 'Minnesota') {
        code = 'MN';
    } else if (prov === 'Mississippi') {
        code = 'MS';
    } else if (prov === 'Missouri') {
        code = 'MO';
    } else if (prov === 'Montana') {
        code = 'MT';
    } else if (prov === 'Nebraska') {
        code = 'NE';
    } else if (prov === 'Nevada') {
        code = 'NV';
    } else if (prov === 'New Hampshire') {
        code = 'NH';
    } else if (prov === 'New Jersey') {
        code = 'NJ';
    } else if (prov === 'New Mexico') {
        code = 'NM';
    } else if (prov === 'New York') {
        code = 'NY';
    } else if (prov === 'North Carolina') {
        code = 'NC';
    } else if (prov === 'North Dakota') {
        code = 'ND';
    } else if (prov === 'Ohio') {
        code = 'OH';
    } else if (prov === 'Oklahoma') {
        code = 'OK';
    } else if (prov === 'Oregon') {
        code = 'OR';
    } else if (prov === 'Pennsylvania') {
        code = 'PA';
    } else if (prov === 'Rhode Island') {
        code = 'RI';
    } else if (prov === 'South Carolina') {
        code = 'SC';
    } else if (prov === 'South Dakota') {
        code = 'SD';
    } else if (prov === 'Tennessee') {
        code = 'TN';
    } else if (prov === 'Texas') {
        code = 'TX';
    } else if (prov === 'Utah') {
        code = 'UT';
    } else if (prov === 'Vermont') {
        code = 'VT';
    } else if (prov === 'Virginia') {
        code = 'VA';
    } else if (prov === 'Washington') {
        code = 'WA';
    } else if (prov === 'West Virginia') {
        code = 'WV';
    } else if (prov === 'Wisconsin') {
        code = 'WI';
    } else if (prov === 'Wyoming') {
        code = 'WY';
    } else if (prov === 'Australian Capital Territory') {
        code = 'ACT';
    } else if (prov === 'New South Wales') {
        code = 'NSW';
    } else if (prov === 'Northern Territory') {
        code = 'NT';
    } else if (prov === 'Queensland') {
        code = 'QLD';
    } else if (prov === 'South Australia') {
        code = 'SA';
    } else if (prov === 'Tasmania') {
        code = 'TAS';
    } else if (prov === 'Victoria') {
        code = 'VIC';
    } else if (prov === 'Western Australia') {
        code = 'WA';
    } else if (prov === 'England') {
        code = 'ENG';
    } else if (prov === 'Wales') {
        code = 'WLS';
    } else if (prov === 'Scotland') {
        code = 'SCT';
    } else if (prov === 'Northern Ireland') {
        code = 'NIR';
    } else {
        code = 'OT';
    }
    return code;
};

// The date methods below will eventually return different per region or country
// So please leave them as methods instead of making them constants
export const getDateFormat = (country = undefined) => {
    switch (country) {
        case 'UK':
            return 'DD-MM-YYYY';
        case 'GB':
            return 'DD-MM-YYYY';
        // case 'CA':
        //     return 'DD-MM-YYYY';
        // case 'US':
        //     return 'MM-DD-YYYY';
        default:
            return 'MM-DD-YYYY';
    }
};

export const getVerboseDateFormat = () => 'MMMM Do, YYYY';

export const getDateTimeFormat = () => 'MM-DD-YYYY_HH-mm-ss';

export const getVerboseDateTimeFormat = () => 'MMMM Do YYYY, h:mm a';

export const getMonthFormat = () => 'MMMM of YYYY';

// Placeholders need to be intl strings because they are represented differently in different languages
export const getDatePlaceholder = (country = undefined) => {
    switch (country) {
        case 'UK':
            return intl.formatMessage({ id: 'field.placeholder.dateFormatUK', defaultMessage: 'DD-MM-YYYY' });
        case 'GB':
            return intl.formatMessage({ id: 'field.placeholder.dateFormatUK', defaultMessage: 'DD-MM-YYYY' });
        // case 'CA':
        //     return intl.formatMessage({ id: 'field.placeholder.dateFormatCA', defaultMessage: 'DD-MM-YYYY' });
        // case 'US':
        //     return intl.formatMessage({ id: 'field.placeholder.dateFormatUS', defaultMessage: 'MM-DD-YYYY' });
        default:
            return intl.formatMessage({ id: 'field.placeholder.dateFormatUS', defaultMessage: 'MM-DD-YYYY' });
    }
};

// Convert an API date into a formatted date
export const formatDate = (dateString = '', country) => {
    const date = moment(dateString, 'YYYY-MM-DD');

    if (moment(date).isValid()) {
        return moment(date).format(getDateFormat(country));
    }

    return dateString;
};

export const parseDate = (dateString) => (dateString ? dateString.replace(/\D/g, '') : '');

// This is used for API requests so it has to stay in the format YYYY-MM-DD
export const stringifyDate = (date) => {
    if (date === null) {
        return null;
    }

    // If the date is already YYYY-MM-DD then we are OK
    // Ideally in the future we can remove this method because the DateInput converts all dates to YYYY-MM-DD for us
    const momentDate = moment(date, 'YYYY-MM-DD', true);
    if (momentDate.isValid()) {
        return date;
    }
    // This section is unreliably assuming that new Date() will not change the day based on the timezone
    const newDate = new Date(date);
    const cleanDate = newDate.toISOString().slice(0, 10).replace(/\D/g, '');
    if (cleanDate.length > 6) return `${cleanDate.slice(0, 4)}-${cleanDate.slice(4, 6)}-${cleanDate.slice(6, 8)}`;
    if (cleanDate.length > 4) return `${cleanDate.slice(0, 4)}-${cleanDate.slice(4, 6)}`;
    return cleanDate;
};
export const stringifyPhoneNum = (phoneNum = '') => {
    const cleanPhoneNum = phoneNum.replace(/\D/g, '');
    // FixMe: Determine what server needs & if different with various endpoints
    // if (cleanPhoneNum.length >= 7) {
    //     return `${cleanPhoneNum.slice(0, 3)}-${cleanPhoneNum.slice(3, 6)}-${cleanPhoneNum.slice(6, 10)}`;
    // }
    // else if (cleanPhoneNum.length > 3) return `${cleanPhoneNum.slice(0, 3)}-${cleanPhoneNum.slice(3, 6)}`;
    return cleanPhoneNum;
};

export const stringifyJSON = (blob) => {
    if (!blob) return blob;
    if (Array.isArray(blob)) return blob.map((value) => stringifyJSON(value));
    if (typeof blob === 'object') {
        const newBlob = {};
        Object.keys(blob).forEach((key) => {
            if (key.includes('date') && Date.parse(blob[key])) {
                newBlob[key] = stringifyDate(
                    new Date(new Date(blob[key]).getTime() - new Date(blob[key]).getTimezoneOffset() * 60000)
                );
            } else if (typeof blob[key] !== 'object' && key.includes('phone')) {
                newBlob[key] = stringifyPhoneNum(blob[key]);
            } else {
                newBlob[key] = stringifyJSON(blob[key]);
            }
        });
        return newBlob;
    }
    return blob;
};

export const splitDate = (date) => {
    if (date) {
        return {
            year: date.substring(0, 4),
            month: dateMonthNumberToString(date.substring(5, 7)),
            day: date.substring(8, 10),
        };
    }
};

export const simpleStringifyJSON = (blob) => {
    if (!blob) return blob;
    if (Array.isArray(blob)) return blob.map((value) => simpleStringifyJSON(value));
    if (typeof blob === 'object') {
        const newBlob = {};
        Object.keys(blob).forEach((key) => {
            if (typeof blob[key] !== 'boolean' && key.includes('date')) {
                if (blob[key] === '' || blob[key] === undefined) {
                    newBlob[key] = null;
                } else {
                    newBlob[key] = stringifyDate(blob[key]);
                }
            } else {
                newBlob[key] = simpleStringifyJSON(blob[key]);
            }
        });
        return newBlob;
    }
    return blob;
};

export const parseJSON = (blob) => {
    if (!blob) return blob;
    if (Array.isArray(blob)) return blob.map((value) => parseJSON(value));
    if (typeof blob === 'object') {
        // TODO: FIX THIS JANKY ASS SHIT
        if (blob instanceof Date && !Number.isNaN(blob.valueOf())) {
            return new Date(blob.getTime() + blob.getTimezoneOffset() * 60000);
        }
        const newBlob = {};
        Object.keys(blob).forEach((key) => {
            if (typeof blob[key] !== 'object') {
                if (key.includes('date') && Date.parse(blob[key])) {
                    newBlob[key] = new Date(
                        // TODO: FIX THIS JANKY ASS SHIT
                        new Date(blob[key]).getTime() + new Date(blob[key]).getTimezoneOffset() * 60000
                    );
                } else if (key.includes('phone')) newBlob[key] = stringifyPhoneNum(blob[key]);
                else newBlob[key] = blob[key];
            } else newBlob[key] = parseJSON(blob[key]);
        });
        return newBlob;
    }
    return blob;
};

// eslint-disable-next-line
export const dateMonthNumberToString = (month) => {
    switch (month) {
        case '01':
            return 'January';
        case '02':
            return 'February';
        case '03':
            return 'March';
        case '04':
            return 'April';
        case '05':
            return 'May';
        case '06':
            return 'June';
        case '07':
            return 'July';
        case '08':
            return 'August';
        case '09':
            return 'September';
        case '10':
            return 'October';
        case '11':
            return 'November';
        case '12':
            return 'December';
        default:
            return 'January';
    }
};

export const dateMonthStringToNumber = (month) => {
    switch (month) {
        case 'January':
            return '01';
        case 'February':
            return '02';
        case 'March':
            return '03';
        case 'April':
            return '04';
        case 'May':
            return '05';
        case 'June':
            return '06';
        case 'July':
            return '07';
        case 'August':
            return '08';
        case 'September':
            return '09';
        case 'October':
            return '10';
        case 'November':
            return '11';
        case 'December':
            return '12';
        default:
            return '01';
    }
};

export const simpleParseJSON = (blob) => {
    if (!blob) return blob;
    if (Array.isArray(blob)) return blob.map((value) => simpleParseJSON(value));
    if (typeof blob === 'object') {
        const newBlob = {};
        Object.keys(blob).forEach((key) => {
            if (typeof blob[key] !== 'object') {
                if (key.includes('phone')) newBlob[key] = stringifyPhoneNum(blob[key]);
                else newBlob[key] = blob[key];
            } else newBlob[key] = simpleParseJSON(blob[key]);
        });
        return newBlob;
    }
    return blob;
};

// Function to remove unused bas64 encoding, works with images and pdf (application)
export const base64Format = (base64image) => base64image?.replace(/data:(image|application)\/[A-Za-z]*;base64,/i, '');

Format.provinceCodeToName = provinceCodeToName;
Format.base64Format = base64Format;

export default Format;
