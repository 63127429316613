import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { FilterFrame, FilterClear } from './styled';

import Auth from 'modules/Auth';
import { TextSearch } from './TextSearch';
import { AdjudicatorFilter } from './AdjudicatorFilter';
import { AdjudicationReviewStatus } from './AdjudicationReviewStatus';
import { TeamFilter } from './TeamFilter';
import { TypeFilter } from './TypeFilter';
import { StatusFilter } from './StatusFilter';
import { ResultFilter } from './ResultFilter';
import { isEmpty } from 'lodash';
import { Filters, useFilters } from './hooks';
import { AdverseActionFilter } from './AdverseActionFilter';
import { useLocation } from 'react-router-dom';
import { getApplicationsView as getApplicationsViewHR } from 'views/manager/views/hr/views/applications/ApplicationsSelectors';
import { getApplicationsView as getApplicationsViewPM } from 'views/manager/views/pm/views/applications/ApplicationsSelectors';

type AdvancedFilteringProps = {
    filters: Filters;
    resetIndex?: number;
    resetIndexForAA?: number;
    onChange: (filters: Filters, query: string) => Promise<void>;
};

export const AdvancedFiltering: React.FC<AdvancedFilteringProps> = ({
    filters: initialFilters,
    resetIndex,
    resetIndexForAA,
    onChange,
}) => {
    const location = useLocation();
    const isHR = location?.pathname === '/hr/applications';

    const applicationsView = useSelector(isHR ? getApplicationsViewHR : getApplicationsViewPM);
    const { filters, setFilter, clearFilters, clearAAFilters, buildQueryParam } = useFilters(initialFilters);
    const [mobile] = useState(window.matchMedia('(max-width: 1020px)').matches);

    const isFilteringEmpty = !Object.values(filters).some((filterValue) => !isEmpty(filterValue));
    const needsAdjudicationReviewTab = applicationsView === 'Needs Review';
    const inAdverseActionTab = applicationsView === 'Adverse Action';

    const updateAndSearch = (filterType: string, value: string | string[]) => {
        const newFilters = setFilter(filterType, value);
        const query = buildQueryParam(newFilters);
        return onChange(newFilters, query);
    };

    const clearAll = useCallback(() => {
        clearFilters();
        return onChange({}, '');
    }, [clearFilters, onChange]);

    const clearAA = useCallback(() => {
        const newFilters = clearAAFilters();
        return onChange(newFilters, buildQueryParam(newFilters));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clearFilters, onChange]);

    useEffect(() => {
        // reset all filters when index changes
        // useful if you want to avoid storing state in the parent component
        if (resetIndex) {
            clearAll();
        }
    }, [resetIndex, clearAll]);

    useEffect(() => {
        if (resetIndexForAA) {
            clearAA();
        }
    }, [resetIndexForAA, clearAA]);

    return (
        <FilterFrame mobile={mobile}>
            <TextSearch
                value={filters.nameEmail}
                onChange={(value) => setFilter('nameEmail', value)}
                onSearch={() => onChange(filters, buildQueryParam(filters))}
            />
            {Auth.isAdjudicatorUser() && needsAdjudicationReviewTab ? (
                <>
                    <AdjudicatorFilter
                        value={filters.adjudicator}
                        onChange={(value) => updateAndSearch('adjudicator', value)}
                    />
                    <AdjudicationReviewStatus
                        value={filters.adjudicationReviewStatus}
                        onChange={(value) => updateAndSearch('adjudicationReviewStatus', value)}
                    />
                </>
            ) : (
                <>
                    {Auth.isPermissionLevel('user') && (
                        <TeamFilter value={filters.team} onChange={(value) => updateAndSearch('team', value)} />
                    )}

                    <TypeFilter value={filters.type} onChange={(value) => updateAndSearch('type', value)} />

                    {inAdverseActionTab ? (
                        <AdverseActionFilter
                            value={filters.adverseActionStatus}
                            onChange={(value) => updateAndSearch('adverseActionStatus', value)}
                        />
                    ) : (
                        <StatusFilter value={filters.status} onChange={(value) => updateAndSearch('status', value)} />
                    )}
                    <ResultFilter value={filters.result} onChange={(value) => updateAndSearch('result', value)} />
                </>
            )}
            <FilterClear onClick={clearAll} disabled={isFilteringEmpty}>
                <FormattedMessage id="225d8.AdvancedFiltering.clearAll" defaultMessage="Clear All" />
            </FilterClear>
        </FilterFrame>
    );
};
