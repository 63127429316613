// Libraries
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Components
import { BiometricConsentClickwrap } from './BiometricConsentClickwrap';

// Modules
import withNavigation from 'views/welcome/modules/WithNavigation';

// Actions & Selectors
import { setTrackPageOrder, patchInformation } from 'views/welcome/WelcomeActions';
import { ErrorAlertAPI } from '../../../../certn-ui/ErrorAlert';
import { getApplicant } from '../../WelcomeSelectors';

export const BiometricConsent: React.FC<{ handleNextTrack: () => void }> = ({ handleNextTrack }) => {
    const applicantId = useSelector(getApplicant)?.id;
    const dispatch = useDispatch();

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(setTrackPageOrder(['base']));
    }, [dispatch]);

    const handleNext = () => {
        try {
            dispatch(
                patchInformation({
                    biometric_consent_given: true,
                })
            );
            handleNextTrack();
        } catch (error) {
            ErrorAlertAPI(error);
            console.error('Error signing IronClad Disclosures', error);
        }
    };

    return <BiometricConsentClickwrap handleNext={handleNext} signerId={applicantId} />;
};

export default withNavigation(BiometricConsent);
