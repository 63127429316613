// Libraries
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import styled from 'styled-components/macro';
import StripeCheckout from 'react-stripe-checkout';
import Cookies from 'js-cookie';
import { FormattedMessage } from 'react-intl';

// Images and Graphics
import certnLogo from 'images/icon.png';

// Actions and Selectors
import { fetchUserInfo } from 'base/BaseActions';
import { getLocale, getTeamBillingPlan } from 'base/BaseSelectors';
import { fetchTeam } from 'views/manager/views/admin/AdminActions';
import { getTeam } from 'views/manager/views/admin/AdminSelectors';
import { saveCardToken } from 'views/manager/views/settings/SettingsActions';

const StyledParagraph = styled.p`
    margin-top: 12px;
    margin-bottom: 0;
    text-align: center;
    color: ${({ theme }) => theme.color.certnGray600};
    font-size: ${({ theme }) => theme.font.xxs};

    a {
        color: ${({ theme }) => theme.color.certnGray600};
        text-decoration: underline;
    }
`;

export const StripeModal = ({ children, stripeSubmitCallback }) => {
    const dispatch = useDispatch();
    const { id: teamId } = useSelector(getTeam);
    const { stripe_public_api_key } = useSelector(getTeamBillingPlan);
    const locale = useSelector(getLocale);

    const onStripeSubmit = async (token) => {
        await dispatch(saveCardToken(token.id, teamId));
        if (stripeSubmitCallback) {
            stripeSubmitCallback();
        }
        dispatch(fetchTeam(teamId));
        dispatch(fetchUserInfo());
    };

    return (
        <StripeCheckout
            token={onStripeSubmit}
            stripeKey={stripe_public_api_key}
            name="Certn"
            description="Authorize Your Credit Card"
            image={certnLogo}
            panelLabel="Authorize Card"
            amount={0}
            currency="CAD"
            email={Cookies.get('email')}
            locale={locale}
            style={{ backgroundColor: 'green' }}
        >
            {children}
        </StripeCheckout>
    );
};

const StripeCheckoutContainer = ({ stripeSubmitCallback = null }) => {
    const { paymentVerified } = useSelector(getTeam);

    // TODO: Revisit CAD currency when made available in other countries
    return (
        <div>
            <StripeModal stripeSubmitCallback={stripeSubmitCallback}>
                <Button
                    type="primary"
                    certncolor={paymentVerified ? 'certnGrey' : 'certnRed500'}
                    disabled={paymentVerified}
                    block
                >
                    <span style={{ textTransform: 'uppercase' }}>
                        {paymentVerified ? (
                            <FormattedMessage
                                id="stripeCheckoutContainer.buttonLabelVerified"
                                defaultMessage="Payment Verified"
                            />
                        ) : (
                            <FormattedMessage
                                id="stripeCheckoutContainer.buttonLabelNew"
                                defaultMessage="Connect Payment Card"
                            />
                        )}
                    </span>
                </Button>
            </StripeModal>
            <StyledParagraph>
                <FormattedMessage
                    id="stripeCheckoutContainer.termsAndPrivacyPolicy"
                    defaultMessage="All payments are securely processed by Stripe. View Stripe's terms and privacy policies <a>here.</a>"
                    values={{
                        a: (chunks) => (
                            <a rel="noreferrer" target="_blank" href="https://stripe.com/en-ca/privacy">
                                {chunks}
                            </a>
                        ),
                    }}
                />
            </StyledParagraph>
        </div>
    );
};

export default StripeCheckoutContainer;
