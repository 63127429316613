// Libraries
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Form as AntdForm } from '@ant-design/compatible';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Currency } from 'types';
import { Path, Regex } from 'modules';

// Components
import Loader from 'certn-ui/Loader';
import { AppearRight } from 'certn-ui/Animate';
import Title from 'certn-ui/Title';
import Form from 'certnd/Form';
import { StripePaymentForm } from 'certnd';
import Typography from 'certnd/Typography';
import PrepaidNotice from './PrepaidNotice';
import { Certn } from '../svgs/Certn';

// Actions & Selectors
import { getMyCRCLoading, getStripePublicApiKey, getFullName } from 'views/welcome/WelcomeSelectors';
import { getLanguage } from 'base/BaseSelectors';

// Modules
import { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form';
import numeral from 'numeral';
import { formatCurrency } from 'utils/formatter';
import { BBB } from '../svgs/BBB';
import { PBSA } from '../svgs/PBSA';
import { StripePayment } from '../svgs/StripePayment';
import { MyCRC } from '../svgs/MyCRC';

const { Heading } = Typography;

const PaymentRow = styled.div`
    display: flex;
    flex-direction: fow;
    justify-content: space-between;
`;

const Text = styled.span`
    font-size: 16px;
`;

const ServiceWrapper = styled.div`
    width: 524px;
    height: 102px;
    display: flex;
    padding: 16px 24px 16px 24px;
    background-color: #f9f9f9;
    border: 1px solid #e3e3e3;
    border-radius: 12px;
    margin: auto;
`;

const HorizonalDivider = styled.div`
    border-bottom: ${(props) => `1px solid ${props.theme.color.certnGray200}`};
`;

interface PaymentBreakdownProps {
    microservice_prices: number;
    tax: number;
    total: number;
    currency: Currency;
}

type PaymentFormProps = {
    paymentLoading: boolean;
    paymentSuccess: boolean;
    myCRCPaymentBreakdown: PaymentBreakdownProps;
    handleSubmit: () => void;
    form: WrappedFormUtils<unknown>;
    isPrepaid: boolean;
    handleReverse: () => void;
} & WrappedComponentProps;

// This component was copied 1:1 from the old PaymentForm component and expanded upon
const PaymentForm = ({
    paymentLoading,
    paymentSuccess,
    myCRCPaymentBreakdown,
    handleSubmit,
    form,
    intl,
    isPrepaid,
    handleReverse,
}: PaymentFormProps) => {
    const myCRCLoading = useSelector(getMyCRCLoading);
    const stripePublicApiKey = useSelector(getStripePublicApiKey);
    const fullName = useSelector(getFullName);
    const { microservice_prices, tax, total, currency } = myCRCPaymentBreakdown;
    const language = useSelector(getLanguage);
    const isMyCRC = Path.isMyCRCSubdomain();

    if (myCRCLoading)
        return (
            <AppearRight>
                <Title>
                    <FormattedMessage id="welcome.Payment.reviewPayment" defaultMessage="Review & Payment" />
                </Title>
                <Loader />
            </AppearRight>
        );
    if (paymentSuccess)
        return (
            <AppearRight>
                <Title>
                    <FormattedMessage id="welcome.Payment.reviewPayment" defaultMessage="Review & Payment" />
                </Title>
                <Heading.H3>
                    <FormattedMessage id="welcome.Payment.success" defaultMessage="Payment successful, thank you" />
                </Heading.H3>
            </AppearRight>
        );
    return (
        <AppearRight>
            <AntdForm layout="vertical">
                <div style={{ display: 'flex', flexDirection: 'column', gap: '24px', width: '524px', margin: 'auto' }}>
                    <Title padding="20px 20px 0px 20px">
                        <FormattedMessage id="welcome.Payment.reviewPayment" defaultMessage="Review & Payment" />
                    </Title>
                    <ServiceWrapper>
                        {isMyCRC ? <MyCRC /> : <Certn />}
                        <div
                            style={{
                                marginLeft: '24px',
                                display: 'flex',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                            }}
                        >
                            <div style={{ fontSize: '16px', fontWeight: 'bold' }}>
                                {isMyCRC ? 'myCRC' : 'Certn'}{' '}
                                <FormattedMessage
                                    id="welcome.Payment.backgroundCheckFor"
                                    defaultMessage="Background Check for"
                                />{' '}
                                {fullName}
                            </div>
                            <div style={{ fontSize: '16px' }}>
                                {formatCurrency(numeral(total).subtract(tax), currency)}
                            </div>
                        </div>
                    </ServiceWrapper>
                    {microservice_prices && total ? (
                        <>
                            <div>
                                {tax !== 0 && tax !== null && (
                                    <PaymentRow style={{ color: '#767676' }}>
                                        <Text>
                                            <FormattedMessage id="welcome.Payment.tax" defaultMessage="Tax" />
                                        </Text>
                                        <Text>
                                            <div>${tax}</div>
                                        </Text>
                                    </PaymentRow>
                                )}

                                <PaymentRow>
                                    <Text style={{ fontWeight: 'bold' }}>
                                        <FormattedMessage id="common.total" defaultMessage="Total" />
                                    </Text>
                                    <Text style={{ fontWeight: 'bold' }}>
                                        ${total} {currency}
                                    </Text>
                                </PaymentRow>
                            </div>
                            <HorizonalDivider />
                            {isPrepaid ? (
                                <PrepaidNotice />
                            ) : (
                                <div>
                                    <Text style={{ display: 'flex' }}>
                                        <FormattedMessage
                                            id="welcome.Payment.cardholderName"
                                            defaultMessage="Cardholder name"
                                        />
                                    </Text>
                                    <Form.Input
                                        hideBorder
                                        size="large"
                                        form={form}
                                        fieldName="name"
                                        options={{
                                            validateTrigger: 'onSubmit',
                                            rules: [
                                                {
                                                    pattern: Regex.name,
                                                    message: intl.formatMessage({
                                                        id: 'error.validation.letters',
                                                        defaultMessage: 'Please only use letters',
                                                    }),
                                                },
                                                {
                                                    required: true,
                                                    message: intl.formatMessage({
                                                        id: 'error.validation.notBlank',
                                                        defaultMessage: 'Please do not leave blank',
                                                    }),
                                                },
                                            ],
                                        }}
                                        placeholder={intl.formatMessage({
                                            id: 'welcome.Payment.cardholderName',
                                            defaultMessage: 'Cardholder name',
                                        })}
                                        autoComplete="false"
                                    />
                                    <div>
                                        <Text style={{ display: 'flex' }}>
                                            <FormattedMessage
                                                id="welcome.Payment.cardDetails"
                                                defaultMessage="Card details"
                                            />
                                        </Text>

                                        <StripePaymentForm
                                            validateFields={form.validateFields}
                                            handleSubmit={handleSubmit}
                                            name={form.getFieldValue('name') || ''}
                                            paymentLoading={paymentLoading}
                                            stripePublicApiKey={stripePublicApiKey}
                                            language={language}
                                            handleReverse={handleReverse}
                                        />
                                    </div>
                                </div>
                            )}
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    margin: 'auto',
                                    gap: '40px',
                                }}
                            >
                                <div>
                                    <StripePayment />
                                </div>
                                <div style={{ display: 'flex', gap: '20px' }}>
                                    <PBSA />
                                    <BBB />
                                </div>
                            </div>
                        </>
                    ) : (
                        <FormattedMessage
                            id="welcome.Payment.errorFetchPaymentBreakdown"
                            defaultMessage="There was an issue retrieving payment breakdown, please contact support"
                        />
                    )}
                </div>
            </AntdForm>
        </AppearRight>
    );
};

export default AntdForm.create()(injectIntl(PaymentForm));
