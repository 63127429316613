import styled from 'styled-components/macro';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import { getUser } from 'base/BaseSelectors';
import { AdverseActionStatusComponentType } from 'utils/AdverseActionStatusType';
import { timestampText } from 'utils/adverseActionUtils';
import Format from 'modules/Format';
import { StatusSectionProps } from '../features/AdverseActionDrawer/StatusSection/types';
import { TimestampText } from '../features/AdverseActionDrawer/StatusSection/styled';
import { toggleDrawer } from '../features/AdverseActionDrawer/DrawerActions';

const AdverseActionStatusContainer = styled.div`
    width: 100%;
    height: 40px;
    background-color: #f9f9f9;
    font: Lota Grotesque;
    position: relative;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #d8d8d8;
    justify-content: space-between;
`;

const AdverseActionStatus: React.FC<StatusSectionProps> = ({ adverseAction }) => {
    const user = useSelector(getUser);
    const dispatch = useDispatch();
    const { adverse_action_status: status, adverse_action_status_timestamp: timestamp } = adverseAction;

    const canInitiateAA = user?.can_initiate_adverse_action;
    const canViewAA = user?.can_view_adverse_action;

    const handleDetailsClick = () => {
        dispatch(toggleDrawer(true));
    };

    return canInitiateAA || canViewAA ? (
        <AdverseActionStatusContainer>
            <div>
                <span style={{ marginLeft: '23px', top: '25%' }}>{AdverseActionStatusComponentType(status)}</span>
                <TimestampText dashboard>
                    {timestampText(status, timestamp)}
                    {` ${Format.date(timestamp)}`}
                </TimestampText>
            </div>
            {canInitiateAA ? (
                <Button type="link" style={{ marginRight: '23px' }} onClick={handleDetailsClick}>
                    <FormattedMessage id="adverseActionStatus.viewDetails" defaultMessage="View Details" />
                </Button>
            ) : null}
        </AdverseActionStatusContainer>
    ) : null;
};
export default AdverseActionStatus;
