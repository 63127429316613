import gt from 'google-translate';

const apiKey = process.env.REACT_APP_GOOGLE_TRANSLATE_API_KEY;
const googleTranslate = gt(apiKey);

// translates string or array to target language and returns flat translation array
const GoogleTranslate = (text, targetLanguage) =>
    new Promise((resolve, reject) => {
        // API returns an object or array of objects with {translatedText, detectedSourceLanguage, originalText}
        googleTranslate.translate(text, targetLanguage, (error, translations) => {
            if (error) return reject(error);
            if (Array.isArray(translations)) return resolve(translations.map((t) => t.translatedText));
            return resolve([translations.translatedText]);
        });
    });

export default GoogleTranslate;
