// Libraries
import React from 'react';

// Components
import DropdownCell from './DropdownCell';

const ActionsCell = ({ record, showSendApplications, showTrialLimitReached, copyToClipboard }) => (
    <DropdownCell
        showSendApplications={showSendApplications}
        showTrialLimitReached={showTrialLimitReached}
        copyToClipboard={copyToClipboard}
        item={record}
    />
);

ActionsCell.propTypes = {};

export default ActionsCell;
