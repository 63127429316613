// Libraries
import { createSelector } from 'reselect';
import { get } from 'lodash';
import Cookies from 'js-cookie';

import { isUKOrGB } from 'modules/Countries';
import { STATEPATH } from './BaseActions';

const getLocalState = (state) => get(state, STATEPATH);

export const getIsFetching = (state) => getLocalState(state).isFetching;
export const getIsFetchingSilent = (state) => getLocalState(state).isFetchingSilent;
export const getTeamLogo = (state) => getLocalState(state).teamLogo;
export const getUser = (state) => getLocalState(state).user;
export const getTeam = (state) => getLocalState(state).user.team;
export const getComplianceRegion = (state) => getTeam(state)?.compliance_region;
export const getJoinedTeams = (state) => getLocalState(state).user.joined_teams || [];
export const getTeamId = (state) => getLocalState(state).user.team.id;
export const getScreenApplicantCustomEmail = (state) => getLocalState(state).user.email_message;
export const getToken = (state) => getLocalState(state).token;
export const getSideBarOpen = (state) => getLocalState(state).sideBarOpen;
export const getNotifications = (state) => getLocalState(state).notifications;
export const getDropdownNotifications = (state) => getLocalState(state).dropdownNotifications;
export const getNotification = (state) => getLocalState(state).notification;
export const getUserInfo = (state) => getLocalState(state).user;
export const getUserMode = (state) => getLocalState(state).userMode;
export const getCountry = (state) => getLocalState(state).user.team.country;
export const getCountryIsUS = (state) => getCountry(state) === 'US';
export const getParams = (state) => getLocalState(state).params;
export const getLocale = (state) => getLocalState(state).locale;
export const getCountries = (state) => getLocalState(state).countries;
export const getSkinColour = (state) => getLocalState(state).skinColour;
export const getSSOEnabled = (state) => getLocalState(state).ssoEnabled;
export const getAllMicroservices = (state) => getLocalState(state).microservices;
export const getTeamSettingsConfig = (state) => getUser(state).team.settings_config;
export const getFirstLogin = (state) => getLocalState(state).firstLogin;
export const getTeamIsLime = (state) => getTeam(state).is_lime;
export const getTeamIsSMB = (state) => getTeam(state).smb;
export const getTeamIsPaymentVerified = (state) => getTeam(state).payment_verified;
export const getLaunchDarklyFlags = (state) => getLocalState(state).launchDarklyFlags;
export const getPotatoCheckTitles = (state) =>
    getLocalState(state).potatoCheckConfigs?.map((check) => ({ title: check.title, request: check.request }));
export const getConfigurablePotatoChecks = (state) =>
    getLocalState(state).potatoCheckConfigs?.filter((check) => check.configurations && check.configurations.length > 0);

// Application list columns based on userMode from settings config
export const getTeamApplicationColumns = createSelector(
    getUserMode,
    getTeamSettingsConfig,
    (userMode, settingsConfig) => userMode && settingsConfig[`${userMode.toLowerCase()}_application_column_settings`]
);

export const getLanguage = createSelector(getLocale, (locale) => locale.split('-')[0]);

export const getTeamCountry = createSelector(getTeam, (team) => team?.country);
export const getJoinedTeamsByTeamCountry = createSelector(getCountry, getJoinedTeams, (country, joinedTeams) =>
    joinedTeams.filter((team) => team.country === country)
);
export const getTeamBillingPlan = createSelector(getTeam, (team) => team?.billing_plan);
export const getTeamCurrency = createSelector(getTeamBillingPlan, (billingPlan) => billingPlan?.currency_type);
export const getTeamIsUS = (state) => getTeamCountry(state) === 'US';
export const getTeamIsCA = (state) => getTeamCountry(state) === 'CA';
export const getTeamIsUK = (state) => getTeamCountry(state) === 'GB';
export const getTeamIsAUS = (state) => getTeamCountry(state) === 'AU';
export const getTeamIsUKorGB = (state) => isUKOrGB(getTeamCountry(state));

// Cookies Selectors
export const getEmail = () => Cookies.get('email');

// Error Selectors
export const getError = (state) => getLocalState(state).error;
export const getLoginError = createSelector(getError, (error) => error.loginError);
export const getSendApplicationError = (state) => getLocalState(state).sendApplicationError;

// Auto-complete Address Selectors
export const getAddressError = (state) => getLocalState(state).addressError;

// Usermode PM / Usermode HR
export const getUserModeIsPM = (state) => getUserMode(state) === 'PM';
export const getUserModeIsHR = (state) => getUserMode(state) === 'HR';
