import Form from 'certnd/Form';
import { FormattedMessage } from 'react-intl';

import styles from 'styles/styles';

const ToggleIntegrationButton = ({ canEdit, loading, active }) => (
    <Form.Button
        disabled={!canEdit}
        loading={loading}
        style={
            active
                ? { background: styles.color.certnRed500, color: 'white', border: styles.color.certnRed500 }
                : { background: styles.color.certnGreen700, color: 'white', border: styles.color.certnGreen700 }
        }
    >
        {active ? (
            <FormattedMessage id="common.disableIntegration" defaultMessage="Disable Integration" />
        ) : (
            <FormattedMessage id="common.enableIntegration" defaultMessage="Enable Integration" />
        )}
    </Form.Button>
);

export default ToggleIntegrationButton;
