import { queryKeys } from 'utils/reactQuery/queryKeys';
import { useQuery } from '@tanstack/react-query';
import { UserApplicant } from 'types';
import { getRequest } from 'utils/http';
import { Paginated } from 'types/common';

export const useUserApplicants = () => {
    const { data, isLoading, isError } = useQuery<Paginated<UserApplicant[]>>([queryKeys.userApplicants], () =>
        getRequest<Paginated<UserApplicant[]>>({
            hr: true,
            version: 'v1',
            endpoint: `/applicants/applicant`,
            applicantLogin: true,
        })
    );

    return {
        applicants: data?.results,
        applicantsLoading: isLoading,
        applicantsError: isError,
    };
};
