import React, { Fragment } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { number, func, object } from 'prop-types';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import { Input, Tooltip, Switch } from 'antd';
import { Regex } from 'modules';

const propTypes = {
    formItemLayout: object,
    rent_range: number,
    rentRange: number,
    rent: number,
    getFieldDecorator: func,
    handleValueSwap: func,
};

const defaultProps = {
    formItemLayout: {},
    rent_range: 0,
    rentRange: 0,
    rent: 0,
    getFieldDecorator: null,
    handleValueSwap: null,
};

const RentRange = ({ formItemLayout, rent_range, rentRange, rent, getFieldDecorator, handleValueSwap, intl }) => {
    const rules = [
        {
            pattern: Regex.dollers,
            message: intl.formatMessage({
                id: 'error.validation.dollars',
                defaultMessage: 'Please enter a valid dollar amount',
            }),
        },
        {
            required: true,
            message: intl.formatMessage({
                id: 'error.validation.notBlank',
                defaultMessage: 'Please do not leave blank',
            }),
        },
        {
            max: 8,
            message: intl.formatMessage({
                id: 'error.validation.8digits',
                defaultMessage: 'Amount must be less than 8 digits',
            }),
        },
    ];
    return (
        <>
            <Form.Item
                {...formItemLayout}
                label={
                    <span>
                        <FormattedMessage id="1a427.RentRange.isRent" defaultMessage="Is rent a range?" />
                        &nbsp;
                        <Tooltip
                            title={intl.formatMessage({
                                id: 'welcome.RentRange.title',
                                defaultMessage: 'Enable this option if there is no set rent for this listing.',
                            })}
                        >
                            <QuestionCircleOutlined />
                        </Tooltip>
                    </span>
                }
            >
                {getFieldDecorator('rentRange', {
                    initialValue: !!rent_range || false,
                    valuePropName: 'checked',
                })(<Switch onClick={() => handleValueSwap('rentRange')} />)}
            </Form.Item>
            <Form.Item
                {...formItemLayout}
                label={
                    rentRange
                        ? intl.formatMessage({
                              id: 'welcome.RentRange.rentRangeLow',
                              defaultMessage: 'Monthly rent - Low ($)',
                          })
                        : intl.formatMessage({
                              id: 'welcome.RentRange.rentRangeTitle',
                              defaultMessage: 'Monthly rent ($)',
                          })
                }
            >
                {getFieldDecorator('rent', {
                    initialValue: rent || null,
                    rules,
                })(<Input type="number" />)}
            </Form.Item>
            {rentRange && (
                <Form.Item
                    {...formItemLayout}
                    label={intl.formatMessage({
                        id: 'welcome.RentRange.rentRangeHigh',
                        defaultMessage: 'Monthly rent - High ($)',
                    })}
                >
                    {getFieldDecorator('rent_range', {
                        initialValue: rent_range || null,
                        rules,
                    })(<Input type="number" />)}
                </Form.Item>
            )}
        </>
    );
};

RentRange.propTypes = propTypes;
RentRange.defaultProps = defaultProps;

export default injectIntl(RentRange);
