import { FormattedMessage } from 'react-intl';
import { AccessFeeDisclaimerText } from './styled';

export const AccessFeeDisclaimer = () => (
    <AccessFeeDisclaimerText>
        *
        <FormattedMessage
            id="acce0.ScreenApplicantCart.accessFeeDisclaimer"
            defaultMessage="Applicable access fees will be passed through."
        />
    </AccessFeeDisclaimerText>
);
