// Libraries
import React from 'react';
import { FormattedMessage } from 'react-intl';
// // Components
import SummaryHeader from './SummaryHeader';
import SummaryItem from './SummaryItem';
import { FlexRow, SummarySection, SummaryContainer } from './SummaryStyles';

interface Props {
    onboardingType: string;
    goToTrack: (track: string) => void;
    positionPropertyLocation: {
        address: string;
        city: string;
        country: string;
        postal_code: string;
        province_state: string;
        location_type: string;
    };
}

const PositionPropertyLocation: React.FC<Props> = ({ onboardingType, goToTrack, positionPropertyLocation }: Props) => {
    const determineHeader = () => {
        let header = (
            <FormattedMessage
                id="welcome.Summary.positionPropertyLocation.Header.PM"
                defaultMessage="Property Location"
            />
        );
        if (onboardingType === 'HR' || positionPropertyLocation.location_type === 'Gig Location') {
            header = (
                <FormattedMessage
                    id="welcome.Summary.positionPropertyLocation.Header.HR"
                    defaultMessage="Position Location"
                />
            );
        }
        return header;
    };

    return (
        <SummaryContainer>
            <SummaryHeader title={determineHeader()} onClick={goToTrack} />
            <SummarySection>
                {positionPropertyLocation.location_type === 'Gig Location' ? (
                    <SummaryItem
                        title={
                            <FormattedMessage
                                id="welcome.Summary.positionPropertyLocation.selected"
                                defaultMessage="Selected"
                            />
                        }
                        text={
                            <FormattedMessage
                                id="welcome.PositionPropertyLocation.gig"
                                defaultMessage="I work from home or do not have a primary work location (ex. rideshare or service delivery)."
                            />
                        }
                    />
                ) : (
                    <FlexRow>
                        <SummaryItem
                            title={
                                <FormattedMessage
                                    id="welcome.Summary.positionPropertyLocation.city"
                                    defaultMessage="City"
                                />
                            }
                            text={positionPropertyLocation.city}
                        />
                        <SummaryItem
                            title={
                                <FormattedMessage
                                    id="welcome.Summary.positionPropertyLocation.country"
                                    defaultMessage="Country"
                                />
                            }
                            text={positionPropertyLocation.country}
                        />
                        <SummaryItem
                            title={
                                <FormattedMessage
                                    id="welcome.Summary.positionPropertyLocation.prov"
                                    defaultMessage="Province"
                                />
                            }
                            text={positionPropertyLocation.province_state}
                        />
                        <SummaryItem
                            title={
                                <FormattedMessage
                                    id="welcome.Summary.positionPropertyLocation.postalCode"
                                    defaultMessage="Postal Code"
                                />
                            }
                            text={positionPropertyLocation.postal_code}
                        />
                    </FlexRow>
                )}
            </SummarySection>
        </SummaryContainer>
    );
};

export default PositionPropertyLocation;
