import styled from 'styled-components';
import * as Sentry from '@sentry/browser';

const StyledMissingConfig = styled.p`
    color: ${({ theme }) => theme.color.certnRed500};
    font-size: ${({ theme }) => theme.font.xxxs};
`;

export const renderMissingConfigurationSteps = (missingConfigurationSteps, missingConfigurationStepInfo, title) => (
    <>
        {missingConfigurationSteps?.map((missingStep) => {
            if (
                missingConfigurationStepInfo &&
                Object.prototype.hasOwnProperty.call(missingConfigurationStepInfo, missingStep)
            ) {
                const missingInfo = missingConfigurationStepInfo[missingStep];
                return <StyledMissingConfig key={missingStep}>{missingInfo.message}</StyledMissingConfig>;
            }

            Sentry.captureException(
                new Error(
                    `Missing check configuration step ${missingStep} has no missing step configuration info defined for check ${title}.`
                )
            );
            return <></>;
        })}
    </>
);
