// Libraries
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Form } from 'antd';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

// Components
import { RequiredText } from 'certn-ui';
import { intl } from 'components/GlobalProvider';
import { InternationalPhone } from 'components';

// Actions & Selectors
import { getSelectedCheckKeys } from 'views/manager/features/ScreenApplicant/ScreenApplicantSelectors';

// Modules
import isVisibleOrRequired from './isVisibleOrRequired';

// Constants
import { CHECK_REQUEST } from 'base/BaseConstants';

const PhoneNumber = () => {
    const [phone, setPhone] = useState();
    const selectedServices = useSelector(getSelectedCheckKeys);
    const label = intl.formatMessage({
        id: 'common.phoneNumber',
        defaultMessage: 'Phone Number',
    });

    const possiblePhoneNumber = (num) => {
        const phoneNumber = num && parsePhoneNumberFromString(num, 'CA');
        if (phoneNumber?.isPossible()) return true;
    };

    // Checks to display field and set required, true in brackets for all services
    const visibleList = [
        CHECK_REQUEST.SOFTCHECK,
        CHECK_REQUEST.EQUIFAX,
        CHECK_REQUEST.CRIMINAL_RECORD_CHECK,
        CHECK_REQUEST.ENHANCED_CRIMINAL_RECORD_CHECK,
        CHECK_REQUEST.VULNERABLE_SECTOR_CRIMINAL_RECORD_CHECK,
    ];

    if (!isVisibleOrRequired(visibleList, selectedServices)) return null;

    return (
        <>
            <RequiredText>{label}</RequiredText>
            <Form.Item
                name="phone_number"
                initialValue={null}
                validateTrigger="onBlur"
                rules={[
                    {
                        validator: (_, value) => {
                            if (possiblePhoneNumber(value)) return Promise.resolve();
                            return Promise.reject(
                                intl.formatMessage({
                                    id: 'error.validation.phoneNumber',
                                    defaultMessage: 'Please provide a valid phone number',
                                })
                            );
                        },
                    },
                ]}
            >
                <InternationalPhone
                    key="phone_number"
                    value={phone}
                    customValue={phone}
                    onChange={(num) => setPhone(num)}
                />
            </Form.Item>
        </>
    );
};

export default PhoneNumber;
