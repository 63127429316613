import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    display: flex;
`;

const Dot = styled.span`
    color: red;
    margin-right: 2px;
`;

const RequiredText = ({ children }) => (
    <Wrapper>
        <Dot>*</Dot> {children}
    </Wrapper>
);

export default RequiredText;
