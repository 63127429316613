// Libraries
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { FormattedMessage } from 'react-intl';
import { Switch } from 'antd';

// Components
import {
    SectionWrapper,
    SectionTopBar,
    SectionBottomPanel,
    BottomPanelRow,
} from 'views/manager/features/ScreenApplicant/styled';
import { ConvictionsDeclaration } from 'components';

// Actions & Selectors
import {
    getSelectedCheckKeys,
    getConvictionsExist,
} from 'views/manager/features/ScreenApplicant/ScreenApplicantSelectors';
import { handleConvictionToggle } from 'views/manager/features/ScreenApplicant/ScreenApplicantActions';

// Modules
import isVisibleOrRequired from './isVisibleOrRequired';

// Constants
import { QA_TEST_ID } from 'utils/constants';
import { RCMP_REQUESTS } from 'views/manager/features/ScreenApplicant/ScreenApplicantConstants';

const Convictions = ({ form }) => {
    const dispatch = useDispatch();
    const selectedServices = useSelector(getSelectedCheckKeys);
    const convictionsExist = useSelector(getConvictionsExist) || undefined;

    // Checks to display field and set required, true in brackets for all services
    const visibleList = RCMP_REQUESTS;

    if (!isVisibleOrRequired(visibleList, selectedServices)) return null;

    const handleOnChange = (checked) => dispatch(handleConvictionToggle(checked));

    return (
        <SectionWrapper data-testid={QA_TEST_ID.QuickScreenConvictionsSectionWrapper}>
            <SectionTopBar>
                <FormattedMessage id="f5ca1.QuickScreen.convictions" defaultMessage="Declare Previous Convictions" />
            </SectionTopBar>
            <SectionBottomPanel>
                <BottomPanelRow>
                    <Form.Item style={{ marginBottom: '0' }}>
                        <Switch
                            checked={convictionsExist}
                            onChange={handleOnChange}
                            checkedChildren={<div>Yes</div>}
                            unCheckedChildren={<div>No</div>}
                        />
                    </Form.Item>
                </BottomPanelRow>
                {convictionsExist && <ConvictionsDeclaration leftAligned form={form} />}
            </SectionBottomPanel>
        </SectionWrapper>
    );
};

export default Convictions;
