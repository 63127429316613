import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';

// Components
import { CheckBoxWrapper } from './styled';
import Text from 'certn-ui/Text';
import { Checkbox } from 'antd';

const RequestEmailCheckbox = ({ hasRequestedEmail, setHasRequestedEmail }) => (
    <CheckBoxWrapper>
        <Checkbox
            checked={hasRequestedEmail}
            onChange={(e) => setHasRequestedEmail(e.target.checked)}
            data-testid="request_report_email_checkbox"
        >
            <Text size="xss" color="certnGray600" display="initial" fontStyle="italic">
                <FormattedMessage
                    id="welcome.Disclosure.recieveReports"
                    defaultMessage="Check this box to receive a free copy of any Consumer report, Investigative Consumer Report or Credit Report from Certn electronically. For a paper copy, Contact Certn at {link_1} or {link_2}"
                    values={{
                        link_1: <a href="tel:1-844-987-0670">1-844-987-0670</a>,
                        link_2: <a href="mailto:support@certn.co">support@certn.co.</a>,
                    }}
                />
            </Text>
        </Checkbox>
    </CheckBoxWrapper>
);

RequestEmailCheckbox.propTypes = {
    hasRequestedEmail: PropTypes.bool.isRequired,
    setHasRequestedEmail: PropTypes.func.isRequired,
};

export default injectIntl(RequestEmailCheckbox);
