import { Checkbox } from 'antd';
import styled from 'styled-components';

export const StyledCheckbox = styled(Checkbox)`
    .ant-checkbox {
        float: left;
        margin-top: 4px;
    }

    & > span:last-child {
        display: flex;
    }
`;
