// Libraries
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Auth from 'modules/Auth';
import { injectIntl, FormattedMessage } from 'react-intl';

// Actions & Selectors
import { getSettings, getCurrentTeam } from 'views/manager/views/settings/SettingsSelectors';

// UI Components
import Form from 'certnd/Form';
import Typography from 'certnd/Typography';

const { Heading } = Typography;

const mapStateToProps = (state) => ({
    settings: getSettings(state),
    currentTeam: getCurrentTeam(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

// eslint-disable-next-line
class EmploymentReferences extends React.Component {
    render() {
        const { currentTeam, form } = this.props;

        const yearsRequired = [
            {
                title: this.props.intl.formatMessage({
                    id: 'common.three',
                    defaultMessage: 'Three',
                }),
                key: 3,
                value: 3,
            },
            {
                title: this.props.intl.formatMessage({
                    id: 'common.five',
                    defaultMessage: 'Five',
                }),
                key: 5,
                value: 5,
            },
            {
                title: this.props.intl.formatMessage({
                    id: '4a211.EmploymentReferences.ListSeven',
                    defaultMessage: 'Seven',
                }),
                key: 7,
                value: 7,
            },
        ];

        const canEdit = Auth.isPermissionLevel('manager');

        return (
            <Form.Block>
                <Heading.H3>
                    <FormattedMessage
                        id="4a211.EmploymentReferences.PageTitle"
                        defaultMessage="Employment References"
                    />
                </Heading.H3>
                <Form.InputNumber
                    hideBorder
                    form={form}
                    fieldName="employer_references_min"
                    options={{
                        initialValue: currentTeam.employer_references_min || 1,
                    }}
                    title={this.props.intl.formatMessage({
                        id: '4a211.EmploymentReferences.MinRefTitle',
                        defaultMessage: 'Minimum number of employer references',
                    })}
                    description={this.props.intl.formatMessage({
                        id: '4a211.EmploymentReferences.MinRefDescription',
                        defaultMessage: "What's the minimum number of employer references to check?",
                    })}
                    autoComplete="false"
                    min={1}
                    max={form.getFieldValue('employer_references_max')} // block increase if Max <= Min
                />

                <Form.InputNumber
                    hideBorder
                    form={form}
                    fieldName="employer_references_max"
                    options={{
                        initialValue: currentTeam.employer_references_max || 1,
                    }}
                    title={this.props.intl.formatMessage({
                        id: '4a211.EmploymentReferences.MaxRefTitle',
                        defaultMessage: 'Maximum number of employer references',
                    })}
                    description={this.props.intl.formatMessage({
                        id: '4a211.EmploymentReferences.MaxRefDescription',
                        defaultMessage:
                            "What's the maximum number of employer references to check? Users will not be able to include more than this amount.",
                    })}
                    autoComplete="false"
                    min={form.getFieldValue('employer_references_min')} // block decrease if Min <= Max
                    max={10}
                />

                <Form.Select
                    form={form}
                    fieldName="employer_references_years"
                    title={this.props.intl.formatMessage({
                        id: '4a211.EmploymentReferences.YearsTitle',
                        defaultMessage: 'Years of employer references to verify',
                    })}
                    description={this.props.intl.formatMessage({
                        id: '4a211.EmploymentReferences.YearsDescription',
                        defaultMessage: 'How many years dating back of employer references should we verify up to?',
                    })}
                    options={{ initialValue: currentTeam.employer_references_years || 3 }}
                    selectOptions={yearsRequired}
                    disabled={!canEdit}
                />

                <Form.Button disabled={!canEdit}>
                    <FormattedMessage id="common.save" defaultMessage="Save" />
                </Form.Button>
            </Form.Block>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(EmploymentReferences));
