import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Form } from '@ant-design/compatible';
import { Select } from 'antd';
import Typography from 'certnd/Typography';
import { formPropTypes, formOptionsPropTypes } from './formPropTypes';
import styles from 'styles/styles';

const { Option } = Select;
const { Heading, Text } = Typography;

const FormSelectWrapper = styled.div`
    display: block;
    border-bottom: ${(props) => (props.hideBorder ? 'none' : `solid ${styles.color.certnGray400} 1px`)};
    width: 100%;
    padding-bottom: ${(props) => (props.hideBorder ? 'none' : '20px')};
    margin-bottom: 20px;
`;

const FormSelect = ({
    hideBorder,
    title,
    description,
    fieldName,
    form,
    options,
    selectOptions,
    headerLeft,
    ...rest
}) => (
    <FormSelectWrapper hideBorder={hideBorder}>
        {title ? (
            <Heading.H4 headerLeft={headerLeft} disableGutter>
                {title}
            </Heading.H4>
        ) : null}
        <Form.Item style={{ marginBottom: 5 }}>
            {form.getFieldDecorator(
                fieldName,
                options
            )(
                <Select style={{ width: '100%' }} {...rest}>
                    {selectOptions.map(({ title: optionTitle, ...restSelectOptions }) => (
                        <Option {...restSelectOptions}>{optionTitle}</Option>
                    ))}
                </Select>
            )}
        </Form.Item>
        {description ? <Text.Secondary>{description}</Text.Secondary> : null}
    </FormSelectWrapper>
);

FormSelect.propTypes = {
    hideBorder: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string,
    fieldName: PropTypes.string.isRequired,
    form: formPropTypes.isRequired,
    options: formOptionsPropTypes,
    selectOptions: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
            key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            // Allow null value, so not required
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
        })
    ).isRequired,
};

FormSelect.defaultProps = {
    hideBorder: false,
    title: undefined,
    description: undefined,
    options: {},
};

export default FormSelect;
