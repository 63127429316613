// Libraries
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

// Components
import Text from 'certn-ui/Text';
import { AppearRight } from 'certn-ui/Animate';
import Title, { FlowSubTitle } from 'certn-ui/Title';
import { TrackNavigation } from 'views/welcome/components';
import MultiAdd, { MultiAddItem, EmptyListContainer } from 'views/welcome/components/MultiAdd';
import SettingsCheckerModal from 'views/welcome/components/SettingsCheckerModal';

// Modules
import Format from 'modules/Format';

const CustomLink = styled.span`
    color: ${({ theme }) => theme.color.certnGreen700};
    padding: 0px !important;
    font-size: 16px !important;
    font-weight: 300 !important;
    cursor: pointer;
`;

class EducationBase extends React.Component {
    state = { settingsErrors: null };

    renderBottom = (education) => {
        if (this.props.highestLevelOnly) {
            if (education.highest_level) {
                return this.props.intl.formatMessage({
                    id: 'welcome.Education.highestLevel',
                    defaultMessage: 'Highest Level',
                });
            }
            return (
                <CustomLink onClick={() => this.props.handleUpdate(education.id)} type="link">
                    {this.props.intl.formatMessage({
                        id: 'welcome.Education.setHighestLevel',
                        defaultMessage: 'Set as Highest Level',
                    })}
                </CustomLink>
            );
        }
        return education.end_date
            ? Format.date(education.end_date)
            : this.props.intl.formatMessage({ id: 'welcome.Educations.present', defaultMessage: 'Present' });
    };

    render = () => {
        const { handleSubmit, educations, addOrEditEducation, deleteEducation, intl, highestLevelOnly } = this.props;

        return (
            <AppearRight>
                <SettingsCheckerModal
                    onCancel={() => this.setState({ settingsErrors: null })}
                    onOk={() => this.setState({ settingsErrors: null })}
                    okText="Okay"
                    errors={this.state.settingsErrors}
                />
                <Title>
                    <FormattedMessage
                        id="welcome.Education.EducationBase.title"
                        defaultMessage="Can you give me a few details about your education?"
                    />
                </Title>
                {highestLevelOnly && (
                    <FlowSubTitle>
                        <Text align="center" color="certnGray600">
                            <FormattedMessage
                                id="welcome.Education.EducationBase.subTitle"
                                defaultMessage="We only need to verify your highest level of education, so use the checkbox when adding your entry to indicate your highest level of education."
                            />
                        </Text>
                    </FlowSubTitle>
                )}
                <MultiAdd
                    testKey="education"
                    title={intl.formatMessage({
                        id: 'welcome.Education.EducationBase.multiTitle',
                        defaultMessage: 'Education',
                    })}
                    buttonTitle={intl.formatMessage({
                        id: 'welcome.Education.EducationBase.multiButtonTitle',
                        defaultMessage: 'ADD EDUCATION',
                    })}
                    buttonAction={addOrEditEducation}
                >
                    {educations && educations.length > 0 ? (
                        educations.map((education, index) => (
                            <MultiAddItem
                                testKey="education"
                                key={education.id}
                                top={
                                    `${education.degree.degree}, ${education.institution}` ||
                                    intl.formatMessage({
                                        id: 'welcome.Education.EducationBase.unnamedProgram',
                                        defaultMessage: 'Unnamed Program',
                                    })
                                }
                                bottom={this.renderBottom(education)}
                                onEdit={() => addOrEditEducation(index)}
                                onDelete={() => deleteEducation(index)}
                                canDelete={educations.length !== 1}
                            />
                        ))
                    ) : (
                        <EmptyListContainer>
                            <FormattedMessage
                                id="welcome.Education.EducationBase.multiEmptyTitle"
                                defaultMessage="No Education entries added yet."
                            />
                        </EmptyListContainer>
                    )}
                </MultiAdd>
                <TrackNavigation handleSubmit={handleSubmit} />
            </AppearRight>
        );
    };
}

export default EducationBase;
