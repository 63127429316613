// React & Redux
import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import styled, { withTheme } from 'styled-components';
import {
    ThunderboltFilled,
    CheckOutlined,
    LockOutlined,
    MinusOutlined,
    PlusOutlined,
    CalendarOutlined,
} from '@ant-design/icons';
import { Form as AntdForm } from '@ant-design/compatible';
import { Button, message } from 'antd';
import { animated, useSpring } from 'react-spring';
import { FormattedMessage } from 'react-intl';
import { intl } from 'components/GlobalProvider';
import { useUserTeam } from 'hooks/useUserTeam';

// UI Components
import {
    ServiceWrapper,
    ServiceText,
    ServiceToggle,
    Title,
    Description,
    ToggleWrapper,
    AdditionalOptionsWrapper,
    OptionSection,
    OptionSectionButton,
} from './styled';
import MicroServiceAddOptions from './MicroServiceAddOptions';
import useCheckContent from './MicroServiceContent';
import { toggleCheck, toggleCheckIsOpen } from 'views/manager/features/ScreenApplicant/ScreenApplicantActions';
import { getConfigurablePotatoChecks, getUser } from 'base/BaseSelectors';
import { getCurrentTeam } from 'views/manager/views/settings/SettingsSelectors';
import { renderMissingConfigurationSteps } from './MicroServiceHelpers';

// Constants
import { CHECK_REQUEST } from 'base/BaseConstants';
import { ScreenApplicantCheckSettingsContext } from '../../contexts';

const StyledPrice = styled.span`
    margin-left: 10px;
    color: ${({ theme }) => theme['primary-color']};
`;

/**
 * Builds UI for each service and takes care of saving || expanding drop down if additional options in MiroServiceContent.js
 */
const MicroserviceOption = (props) => {
    const {
        isPurchased,
        isLocked,
        isTeamDefault,
        isMissingDependencies,
        missingConfigurationSteps,
        missingConfigurationStepInfo,
        title,
        showPricePerCheck,
    } = props;
    const requiredYears = useSelector(getCurrentTeam)?.required_address_history_years;
    const purchasedOrLocked = isPurchased || isLocked || isTeamDefault;
    const screenApplicantCheckSettingsContext = useContext(ScreenApplicantCheckSettingsContext);
    const { team } = useUserTeam();
    const currency = team?.billing_plan?.currency_type;

    // React Spring
    const config = { mass: 5, tension: 2000, friction: 200 };
    const springProps = useSpring({ config, maxHeight: props.isOpen ? 550 : 0 });

    // Save for services with additional options
    const handleSave = (validateFields) => {
        validateFields((err, values) => {
            if (!err) props.handleToggle(values); // Set service isSelected
        });
    };

    // Logic run when service is clicked. Checks with additional options are handled differently
    const serviceClickLogic = () => {
        if (isMissingDependencies) {
            return message.error(
                intl.formatMessage({
                    id: 'f923e.MicroService.missingDependencies',
                    defaultMessage:
                        'The required dependencies for this check are not configured, please contact your account manager',
                })
            );
        }

        // do nothing if isPurchased or isLocked
        if (purchasedOrLocked) return undefined;

        // if no additional options or isSelected, toggle
        if (!props.checkAdditionalOptions.length || props.isSelected) return props.handleToggle();

        // otherwise check has additional options and we want to open/close
        if (props.checkAdditionalOptions.length && !props.isSelected) {
            // close if open, otherwise open
            props.toggleCheckIsOpen();
        }
    };

    return (
        <>
            <ServiceWrapper
                isPurchased={purchasedOrLocked ? 'true' : 'false'}
                onClick={serviceClickLogic}
                data-testid={props.serviceKey}
            >
                <ServiceText>
                    <Title>
                        {props.title} {props.isQuickScreenable && <ThunderboltFilled />}
                        {screenApplicantCheckSettingsContext.showPricing && props.price > 0 && (
                            <StyledPrice>
                                {currency === 'GBP'
                                    ? `£${parseFloat(props.price).toFixed(2)}`
                                    : `$${parseFloat(props.price).toFixed(2)}`}
                                {showPricePerCheck && (
                                    <span style={{ marginLeft: '5px' }}>
                                        <FormattedMessage id="f923e.MicroService.perCheck" defaultMessage="per check" />
                                    </span>
                                )}
                            </StyledPrice>
                        )}
                    </Title>
                    {renderMissingConfigurationSteps(missingConfigurationSteps, missingConfigurationStepInfo, title)}
                    <Description>
                        {props.description} {props.link}
                    </Description>
                </ServiceText>
                <ServiceToggle>
                    <Toggle {...props} optionVisible={props.isOpen} />
                </ServiceToggle>
            </ServiceWrapper>

            {props.checkAdditionalOptions.length > 0 && (
                <animated.div key={props.serviceKey} style={{ ...springProps, overflow: 'hidden' }}>
                    <ServiceWrapper options>
                        <MicroserviceAdditionalOptions
                            handleSave={handleSave}
                            serviceKey={props.serviceKey}
                            optionVisible={props.isOpen}
                            title={props.title}
                            requiredYears={requiredYears}
                        />
                    </ServiceWrapper>
                </animated.div>
            )}
        </>
    );
};

const Toggle = withTheme(
    ({
        serviceKey,
        checkAdditionalOptions,
        optionVisible,
        isPurchased,
        isLocked,
        isInFollowUpFlow,
        isSelected,
        processing,
        theme,
    }) => {
        if (processing) {
            return intl.formatMessage({
                id: 'f923e.MicroService.processing',
                defaultMessage: 'Processing..',
            });
        }

        if (isSelected && !isPurchased && !isInFollowUpFlow) {
            return (
                <ToggleWrapper
                    data-testid={`${serviceKey}_checked`}
                    style={{
                        backgroundColor: theme['primary-color'],
                        borderColor: theme['primary-color'],
                    }}
                >
                    <CheckOutlined
                        style={{
                            color: 'white',
                            fontSize: '24px',
                        }}
                    />
                </ToggleWrapper>
            );
        }
        if (isPurchased || isLocked || isInFollowUpFlow) {
            return (
                <ToggleWrapper data-testid={`${serviceKey}_isLocked`}>
                    <LockOutlined style={{ color: theme.color.certnGray400, fontSize: '24px' }} />
                </ToggleWrapper>
            );
        }
        if (optionVisible && checkAdditionalOptions) {
            return (
                <ToggleWrapper>
                    <MinusOutlined
                        data-testid={`${serviceKey}_minus`}
                        style={{ color: theme.color.certnLightGray400, fontSize: '24px' }}
                    />
                </ToggleWrapper>
            );
        }
        if (!isSelected && !isPurchased) {
            return (
                <ToggleWrapper data-testid={`${serviceKey}_add`}>
                    <PlusOutlined style={{ color: theme.color.certnGray400, fontSize: '24px' }} />
                </ToggleWrapper>
            );
        }
        if (!isSelected && isPurchased) {
            return intl.formatMessage({
                id: 'f923e.MicroService.unavailable',
                defaultMessage: 'Unavailable',
            });
        }
    }
);

const MicroserviceAdditionalOptions = AntdForm.create()(
    ({ form, handleSave, serviceKey, optionVisible, requiredYears }) => {
        const { resetFields } = form;
        // reset fields when additional options are closed
        useEffect(() => {
            if (!optionVisible) {
                resetFields();
            }
        }, [optionVisible, resetFields]);
        const configurablePotatoChecks = useSelector(getConfigurablePotatoChecks);
        const isConfigurablePotato =
            configurablePotatoChecks?.filter((check) => check.request === serviceKey).length > 0;
        const options = additionalOptionPanels(form, serviceKey, isConfigurablePotato);
        const canOrderIndividualServices = useSelector(getUser).can_order_individual_services;
        const isInternationalCrim = serviceKey === 'request_international_criminal_record_check';
        return (
            <AdditionalOptionsWrapper id={serviceKey}>
                <OptionSection>
                    {options ||
                        intl.formatMessage({
                            id: 'f923e.MicroService.options',
                            defaultMessage: 'Missing Options',
                        })}
                </OptionSection>

                <OptionSectionButton isInternationalCrim={isInternationalCrim}>
                    {isInternationalCrim && (
                        <span style={{ fontSize: 14, fontWeight: 400 }}>
                            <CalendarOutlined /> {requiredYears}{' '}
                            <FormattedMessage
                                id="microService.required"
                                defaultMessage="years of address history will be required from the candidate"
                            />
                        </span>
                    )}
                    <Button
                        data-testid={`${serviceKey}_save`}
                        type="primary"
                        disabled={!canOrderIndividualServices}
                        onClick={() => handleSave(form.validateFields)}
                    >
                        <FormattedMessage id="f923e.MicroService.save" defaultMessage="Add to order" />
                    </Button>
                </OptionSectionButton>
            </AdditionalOptionsWrapper>
        );
    }
);

/**
 * Calls /components/AddOptions to build additional options component
 * @param {string} serviceKey
 * @param {boolean} isConfigurablePotato
 * @returns drop down panel component with react spring animation
 */
const additionalOptionPanels = (form, serviceKey, isConfigurablePotato) => {
    if (isConfigurablePotato) {
        return <MicroServiceAddOptions.ConfigurablePotatoCheck form={form} serviceKey={serviceKey} />;
    }

    const components = {
        [CHECK_REQUEST.EMPLOYMENT_VERIFICATION]: <MicroServiceAddOptions.EmploymentVerification form={form} />,
        [CHECK_REQUEST.EMPLOYER_REFERENCES]: (
            <MicroServiceAddOptions.References employer form={form} serviceKey={serviceKey} showPreviewTemplate />
        ),
        [CHECK_REQUEST.EMPLOYER_PHONE_REFERENCES]: (
            <MicroServiceAddOptions.References employer form={form} serviceKey={serviceKey} showPreviewTemplate />
        ),
        [CHECK_REQUEST.ADDRESS_REFERENCES]: (
            <MicroServiceAddOptions.References address form={form} serviceKey={serviceKey} showPreviewTemplate />
        ),
        [CHECK_REQUEST.ADDRESS_PHONE_REFERENCES]: (
            <MicroServiceAddOptions.References address form={form} serviceKey={serviceKey} showPreviewTemplate />
        ),
        [CHECK_REQUEST.INTERNATIONAL_CRIMINAL_RECORD_CHECK]: (
            <MicroServiceAddOptions.InternationalCriminalRecordCheck form={form} />
        ),
        [CHECK_REQUEST.CREDENTIAL_VERIFICATION]: (
            <MicroServiceAddOptions.CredentialVerification form={form} serviceKey={serviceKey} />
        ),
        [CHECK_REQUEST.EDUCATION_VERIFICATION]: (
            <MicroServiceAddOptions.EducationVerification form={form} serviceKey={serviceKey} />
        ),
        [CHECK_REQUEST.US_CRIMINAL_RECORD_CHECK_TIER_3]: (
            <MicroServiceAddOptions.USCriminalRecordCheck form={form} serviceKey={serviceKey} />
        ),
        [CHECK_REQUEST.STANDARD_ACIC_CHECK]: <MicroServiceAddOptions.AcicCheck form={form} />,
        [CHECK_REQUEST.VOLUNTEER_ACIC_CHECK]: <MicroServiceAddOptions.VolunteerAcicCheck form={form} />,
        [CHECK_REQUEST.UK_BASIC_DBS_CHECK]: <MicroServiceAddOptions.DBSCheck form={form} />,
    };
    return components[serviceKey] || undefined;
};

/**
 * Gathers data to build service UI
 * @param {string} serviceKey
 */
const MicroService = ({ check }) => {
    const dispatch = useDispatch();

    // Generic Checks
    const {
        isTeamDefault,
        isLocked,
        isInFollowUpFlow,
        isOpen,
        isQuickScreenable,
        requestFlag,
        isSelected,
        isPurchased,
        checkAdditionalOptions,
        missingConfigurationSteps,
        isMissingDependencies,
        titleFromAPI,
        descriptionFromAPI,
    } = check;

    // Check description and ETT, and title/turnaround time overrides
    const { title, description, link, missingConfigurationStepInfo } = useCheckContent(requestFlag);
    const isEmployerReference = [CHECK_REQUEST.EMPLOYER_REFERENCES, CHECK_REQUEST.EMPLOYER_PHONE_REFERENCES].includes(
        requestFlag
    );

    // If check hasn't been added to checkContent file we want to bypass
    if (!title && !titleFromAPI) return null;
    return (
        <MicroserviceOption
            title={titleFromAPI || title}
            description={descriptionFromAPI || description}
            link={link}
            missingConfigurationStepInfo={missingConfigurationStepInfo}
            key={requestFlag}
            serviceKey={requestFlag}
            isSelected={isSelected}
            isQuickScreenable={isQuickScreenable}
            isTeamDefault={isTeamDefault}
            isPurchased={isPurchased}
            isLocked={isLocked}
            isInFollowUpFlow={isInFollowUpFlow}
            isOpen={isOpen}
            checkAdditionalOptions={checkAdditionalOptions}
            handleToggle={(addOptions) => dispatch(toggleCheck(requestFlag, addOptions))}
            toggleCheckIsOpen={() => dispatch(toggleCheckIsOpen(check.requestFlag))}
            price={check.price}
            missingConfigurationSteps={missingConfigurationSteps}
            isMissingDependencies={isMissingDependencies}
            showPricePerCheck={isEmployerReference}
        />
    );
};

MicroserviceOption.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
    isQuickScreenable: PropTypes.bool.isRequired,
    handleToggle: PropTypes.func.isRequired,
    isSelected: PropTypes.bool,
    isPurchased: PropTypes.bool,
    checkAdditionalOptions: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
};
MicroserviceOption.defaultProps = {
    checkAdditionalOptions: false,
    isSelected: false,
    isPurchased: false,
};

export default MicroService;
