// Libraries
import React from 'react';
import { useSelector } from 'react-redux';
import { Form, Input } from 'antd';

// Components
import { intl } from 'components/GlobalProvider';

// Actions & Selectors
import { getSelectedCheckKeys } from 'views/manager/features/ScreenApplicant/ScreenApplicantSelectors';

// Modules
import { Regex } from 'modules';
import isVisibleOrRequired from './isVisibleOrRequired';

const MiddleName = () => {
    const selectedServices = useSelector(getSelectedCheckKeys);
    const label = intl.formatMessage({
        id: 'f5ca1.QuickScreen.middleName',
        defaultMessage: 'Legal Middle Name (if applicable)',
    });

    // Checks to display field and set required, true in brackets for all services
    const visibleList = [true];
    const requiredList = [];

    if (!isVisibleOrRequired(visibleList, selectedServices)) return null;
    return (
        <>
            {label}
            <Form.Item
                name="middle_name"
                initialValue={null}
                rules={[
                    {
                        pattern: Regex.name,
                        message: intl.formatMessage({
                            id: 'error.validation.letters',
                            defaultMessage: 'Please only use letters',
                        }),
                    },
                    {
                        required: isVisibleOrRequired(requiredList, selectedServices),
                        message: intl.formatMessage({
                            id: 'error.validation.notBlank',
                            defaultMessage: 'Please do not leave blank',
                        }),
                    },
                ]}
            >
                <Input placeholder={label} type="text" />
            </Form.Item>
        </>
    );
};

export default MiddleName;
