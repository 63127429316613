import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import moment from 'moment';
import { startCase, lowerCase } from 'lodash';

// Actions & Selectors
import {
    getApplicantInformationObject,
    getLastUpdated,
} from 'views/manager/features/ScreenApplicant/ScreenApplicantSelectors';

import {
    RightSideBox,
    BoxSmallRow,
    BoxShadedRowFrame,
    BoxColumn,
    TitleBar,
    Title,
} from 'views/manager/features/ScreenApplicant/styled';
import { getVerboseDateFormat } from 'modules/Format';

/**
 * FUFApplicantInformation rendered by ScreenApplicant.jsx
 * @returns row listing of user information including first name, middle name, last name, and updated
 */
const FUFApplicantInformation = () => {
    const intl = useIntl();
    const information = useSelector(getApplicantInformationObject);
    const lastUpdated = useSelector(getLastUpdated);
    const firstName = information?.first_name;
    const middleName = information?.middle_name;
    const lastName = information?.last_name;

    // Moment last updated formatting
    const updated = moment(lastUpdated)?.format(getVerboseDateFormat());

    // Proper string casing
    const casing = (string) => startCase(lowerCase(string));

    return (
        <RightSideBox>
            <TitleBar>
                <Title>
                    {intl.formatMessage({
                        id: 'a0e86.ScreenApplicantInfo.appInfo',
                        defaultMessage: 'Applicant Information',
                    })}
                </Title>
            </TitleBar>
            <BoxShadedRowFrame>
                {firstName && (
                    <BoxSmallRow>
                        <BoxColumn>
                            {intl.formatMessage({ id: 'common.firstName', defaultMessage: 'First Name' })}
                        </BoxColumn>
                        <BoxColumn>{casing(firstName)}</BoxColumn>
                    </BoxSmallRow>
                )}
                {middleName && (
                    <BoxSmallRow>
                        <BoxColumn>
                            {intl.formatMessage({ id: 'common.middleName', defaultMessage: 'Middle Name' })}
                        </BoxColumn>
                        <BoxColumn>{casing(middleName)}</BoxColumn>
                    </BoxSmallRow>
                )}
                {lastName && (
                    <BoxSmallRow>
                        <BoxColumn>
                            {intl.formatMessage({ id: 'common.lastName', defaultMessage: 'Last Name' })}
                        </BoxColumn>
                        <BoxColumn>{casing(lastName)}</BoxColumn>
                    </BoxSmallRow>
                )}
                {lastUpdated && (
                    <BoxSmallRow>
                        <BoxColumn>{intl.formatMessage({ id: 'common.updated', defaultMessage: 'Updated' })}</BoxColumn>
                        <BoxColumn>{updated}</BoxColumn>
                    </BoxSmallRow>
                )}
            </BoxShadedRowFrame>
        </RightSideBox>
    );
};

export default FUFApplicantInformation;
