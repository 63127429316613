import { FormattedMessage } from 'react-intl';
import { Modal } from 'antd';
import styled from 'styled-components';

const DiscUnorderedList = styled.ul`
    margin-top: 12px;
    margin-left: 24px;
    list-style-type: disc;
    list-style-position: outside;
`;

const HELP_MODALS = {
    commencement: {
        title: (
            <FormattedMessage id="welcome.AcicDocuments.commencementDocument" defaultMessage="Commencement Document" />
        ),
        content: (
            <>
                <p>
                    <FormattedMessage
                        id="welcome.AcicDocuments.commencementDocumentDescription"
                        defaultMessage="Commencement documents show your birth in Australia or arrival proof. The following documents are accepted:"
                    />
                </p>
                <DiscUnorderedList>
                    <li>
                        <FormattedMessage
                            id="welcome.AcicDocuments.commencementDocumentBullet1"
                            defaultMessage="Australian birth certificate or authorised record of birth (not an extract or birth card)"
                        />
                    </li>
                    <li>
                        <FormattedMessage
                            id="welcome.AcicDocuments.commencementDocumentBullet2"
                            defaultMessage="Australian citizenship certificate"
                        />
                    </li>
                    <li>
                        <FormattedMessage
                            id="welcome.AcicDocuments.commencementDocumentBullet3"
                            defaultMessage="Valid Australian Visa, supported by a foreign passport, which is needed for verification"
                        />
                    </li>
                    <li>
                        <FormattedMessage
                            id="welcome.AcicDocuments.commencementDocumentBullet4"
                            defaultMessage="ImmiCard issued by Department of Home Affairs that enables the cardholder to prove their visa and/or migration status and enrol in services"
                        />
                    </li>
                    <li>
                        <FormattedMessage
                            id="welcome.AcicDocuments.commencementDocumentBullet5"
                            defaultMessage="Current Australian passport not expired"
                        />
                    </li>
                </DiscUnorderedList>
            </>
        ),
    },
    primary: {
        title: <FormattedMessage id="welcome.AcicDocuments.primaryDocument" defaultMessage="Primary Document" />,
        content: (
            <>
                <p>
                    <FormattedMessage
                        id="welcome.AcicDocuments.primaryDocumentDescription"
                        defaultMessage="Primary documents show your use in the community. The following documents are accepted:"
                    />
                </p>
                <DiscUnorderedList>
                    <li>
                        <FormattedMessage
                            id="welcome.AcicDocuments.primaryDocumentBullet1"
                            defaultMessage="Current Australian passport, not expired"
                        />
                    </li>
                    <li>
                        <FormattedMessage
                            id="welcome.AcicDocuments.primaryDocumentBullet2"
                            defaultMessage="Current Australian drivers licence, learner permit or provisional licence issued by a state or  territory, showing a signature and/or photo and the same name as claimed"
                        />
                    </li>
                    <li>
                        <FormattedMessage
                            id="welcome.AcicDocuments.primaryDocumentBullet3"
                            defaultMessage="ImmiCard issued by Department of Home Affairs that enables the cardholder to prove their visa  and/or migration status and enrol in services"
                        />
                    </li>
                    <li>
                        <FormattedMessage
                            id="welcome.AcicDocuments.primaryDocumentBullet4"
                            defaultMessage="Australian marriage certificate issued by a state or territory (church or celebrant-issued certificates are not accepted)"
                        />
                    </li>
                    <li>
                        <FormattedMessage
                            id="welcome.AcicDocuments.primaryDocumentBullet5"
                            defaultMessage="Current passport issued by a country other than Australia with a valid entry stamp or visa"
                        />
                    </li>
                    <li>
                        <FormattedMessage
                            id="welcome.AcicDocuments.primaryDocumentBullet6"
                            defaultMessage="current proof of age or photo identity card issued by an Australian Government agency in the  name of the applicant, with a photo"
                        />
                    </li>
                    <li>
                        <FormattedMessage
                            id="welcome.AcicDocuments.primaryDocumentBullet7"
                            defaultMessage="Current shooter or firearms licence showing a signature and photo (not minor or junior permit  or licence)"
                        />
                    </li>
                    <li>
                        <FormattedMessage
                            id="welcome.AcicDocuments.primaryDocumentBullet8"
                            defaultMessage="Current student identification card with a signature or photo. For persons under 18 years of age with no other Primary Use in Community Document"
                        />
                    </li>
                </DiscUnorderedList>
            </>
        ),
    },
    secondary: {
        title: <FormattedMessage id="welcome.AcicDocuments.secondaryDocument" defaultMessage="Secondary Document" />,
        content: (
            <>
                <p>
                    <FormattedMessage
                        id="welcome.AcicDocuments.secondaryDocumentDescription"
                        defaultMessage="Secondary documents show your use in the community. The following documents are accepted:"
                    />
                </p>
                <DiscUnorderedList>
                    <li>
                        <FormattedMessage
                            id="welcome.AcicDocuments.secondaryDocumentBullet1"
                            defaultMessage="Certificate of identity issued by DFAT to refugees and non-Australian citizens for entry to  Australia"
                        />
                    </li>
                    <li>
                        <FormattedMessage
                            id="welcome.AcicDocuments.secondaryDocumentBullet2"
                            defaultMessage="Document of identity issued by DFAT to Australian citizens or persons who have the nationality  of a Commonwealth country for travel purposes"
                        />
                    </li>
                    <li>
                        <FormattedMessage
                            id="welcome.AcicDocuments.secondaryDocumentBullet3"
                            defaultMessage="Convention travel document secondary (United Nations) issued by DFAT"
                        />
                    </li>
                    <li>
                        <FormattedMessage
                            id="welcome.AcicDocuments.secondaryDocumentBullet4"
                            defaultMessage="Foreign government issued documents (for example, drivers licence)"
                        />
                    </li>
                    <li>
                        <FormattedMessage
                            id="welcome.AcicDocuments.secondaryDocumentBullet5"
                            defaultMessage="Medicare card"
                        />
                    </li>
                    <li>
                        <FormattedMessage
                            id="welcome.AcicDocuments.secondaryDocumentBullet6"
                            defaultMessage="A relationship certificate issued by a state or territory Births, Deaths and Marriages"
                        />
                    </li>
                    <li>
                        <FormattedMessage
                            id="welcome.AcicDocuments.secondaryDocumentBullet7"
                            defaultMessage="Enrolment with the Australian Electoral Commission"
                        />
                    </li>
                    <li>
                        <FormattedMessage
                            id="welcome.AcicDocuments.secondaryDocumentBullet8"
                            defaultMessage="Security guard or crowd control photo licence"
                        />
                    </li>
                    <li>
                        <FormattedMessage
                            id="welcome.AcicDocuments.secondaryDocumentBullet9"
                            defaultMessage="Evidence of right to an Australian Government benefit (Centrelink or Veterans’ Affairs)"
                        />
                    </li>
                    <li>
                        <FormattedMessage
                            id="welcome.AcicDocuments.secondaryDocumentBullet10"
                            defaultMessage="Consular photo identity card issued by DFAT"
                        />
                    </li>
                    <li>
                        <FormattedMessage
                            id="welcome.AcicDocuments.secondaryDocumentBullet11"
                            defaultMessage="Photo identity card issued to an officer by a police force"
                        />
                    </li>
                    <li>
                        <FormattedMessage
                            id="welcome.AcicDocuments.secondaryDocumentBullet12"
                            defaultMessage="Photo identity card issued by the Australian Defence Force"
                        />
                    </li>
                    <li>
                        <FormattedMessage
                            id="welcome.AcicDocuments.secondaryDocumentBullet13"
                            defaultMessage="Photo identity card issued by the Australian Government or a state or territory government (this  may take the form of a Working with Children/Vulnerable People Card or a government  occupational licence)"
                        />
                    </li>
                    <li>
                        <FormattedMessage
                            id="welcome.AcicDocuments.secondaryDocumentBullet14"
                            defaultMessage="Aviation Security Identification Card (ASIC)"
                        />
                    </li>
                    <li>
                        <FormattedMessage
                            id="welcome.AcicDocuments.secondaryDocumentBullet15"
                            defaultMessage="Maritime Security Identification Card (MSIC)"
                        />
                    </li>
                    <li>
                        <FormattedMessage
                            id="welcome.AcicDocuments.secondaryDocumentBullet16"
                            defaultMessage="Firearms licence"
                        />
                    </li>
                    <li>
                        <FormattedMessage
                            id="welcome.AcicDocuments.secondaryDocumentBullet17"
                            defaultMessage="Credit reference check"
                        />
                    </li>
                    <li>
                        <FormattedMessage
                            id="welcome.AcicDocuments.secondaryDocumentBullet18"
                            defaultMessage="Australian secondary or tertiary student photo identity document"
                        />
                    </li>
                    <li>
                        <FormattedMessage
                            id="welcome.AcicDocuments.secondaryDocumentBullet19"
                            defaultMessage="Certified academic transcript from an Australian university or a registered higher education provider"
                        />
                    </li>
                    <li>
                        <FormattedMessage
                            id="welcome.AcicDocuments.secondaryDocumentBullet20"
                            defaultMessage="Trusted referees report"
                        />
                    </li>
                    <li>
                        <FormattedMessage
                            id="welcome.AcicDocuments.secondaryDocumentBullet21"
                            defaultMessage="Bank card, credit card or bank statement (without recording the payment/card number/s)"
                        />
                    </li>
                    <li>
                        <FormattedMessage
                            id="welcome.AcicDocuments.secondaryDocumentBullet22"
                            defaultMessage="Australian tax file number"
                        />
                    </li>
                    <li>
                        <FormattedMessage
                            id="welcome.AcicDocuments.secondaryDocumentBullet23"
                            defaultMessage="State/territory government rates assessment notice or Australian Taxation Office assessment notice"
                        />
                    </li>
                    <li>
                        <FormattedMessage
                            id="welcome.AcicDocuments.secondaryDocumentBullet24"
                            defaultMessage="Australian utility bill showing name and address"
                        />
                    </li>
                    <li>
                        <FormattedMessage
                            id="welcome.AcicDocuments.secondaryDocumentBullet25"
                            defaultMessage="Australian private health insurance card"
                        />
                    </li>
                    <li>
                        <FormattedMessage
                            id="welcome.AcicDocuments.secondaryDocumentBullet26"
                            defaultMessage="Australian trade association card"
                        />
                    </li>
                </DiscUnorderedList>
            </>
        ),
    },
    supporting: {
        title: (
            <FormattedMessage
                id="welcome.AcicDocuments.supportingDocuments"
                defaultMessage="Supporting Documents (Optional)"
            />
        ),
        content: (
            <>
                <p>
                    <FormattedMessage
                        id="welcome.AcicDocuments.supportingDocumentsDescription"
                        defaultMessage="Supporting documents may be used to validate the 4 identification documents above. These documents are required to provide evidence of a name change. Example documents include but are not limited to:"
                    />
                </p>
                <DiscUnorderedList>
                    <li>
                        <FormattedMessage
                            id="welcome.AcicDocuments.supportingDocumentsBullet1"
                            defaultMessage="Change of name cerificate issued by the Australian Registry of Births, Deaths and Marriages"
                        />
                    </li>
                    <li>
                        <FormattedMessage
                            id="welcome.AcicDocuments.supportingDocumentsBullet2"
                            defaultMessage="Australian marriage certificate issued by a state or territory (church or celebrant-issued certificates are not accepted)"
                        />
                    </li>
                    <li>
                        <FormattedMessage
                            id="welcome.AcicDocuments.supportingDocumentsBullet3"
                            defaultMessage="Foreign marriage ceriticate"
                        />
                    </li>
                    <li>
                        <FormattedMessage
                            id="welcome.AcicDocuments.supportingDocumentsBullet4"
                            defaultMessage="Deed poll certificate"
                        />
                    </li>
                    <li>
                        <FormattedMessage
                            id="welcome.AcicDocuments.supportingDocumentsBullet5"
                            defaultMessage="Relationship certificate issued by a state or territory Births, Deaths and Marriages"
                        />
                    </li>
                </DiscUnorderedList>
            </>
        ),
    },
};

type Props = {
    category: keyof typeof HELP_MODALS;
    isOpen: boolean;
    onClose: () => void;
};

const HelpModal = ({ category, isOpen, onClose }: Props) => {
    const { title, content } = HELP_MODALS[category];

    return (
        <Modal title={title} visible={isOpen} onOk={onClose} onCancel={onClose}>
            {content}
        </Modal>
    );
};

export default HelpModal;
