const getBrowser = () => {
    const ua = navigator.userAgent;
    let tem;
    let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return { name: 'IE', version: tem[1] || '' };
    }
    if (M[1] === 'Chrome') {
        tem = ua.match(/\bOPR\/(\d+)/);
        if (tem != null) {
            return { name: 'Opera', version: tem[1] };
        }
    }
    if (window.navigator.userAgent.indexOf('Edge') > -1) {
        tem = ua.match(/Edge\/(\d+)/);
        if (tem != null) {
            return { name: 'Edge', version: tem[1] };
        }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    tem = ua.match(/version\/(\d+)/i);
    if (tem != null) {
        M.splice(1, 1, tem[1]);
    }
    return {
        name: M[0],
        version: +M[1],
    };
};

const isOutdatedBrowser = (browser) => {
    let outdated = false;

    if (browser.name === 'Chrome' && browser.version < 48) outdated = true;
    if (browser.name === 'MSIE' || browser.name === 'IE') outdated = true;
    if (browser.name === 'firefox' && browser.version < 60) outdated = true;
    if (browser.name === 'Edge' && browser.version < 15) outdated = true;
    if (browser.name === 'Safari' && browser.version < 12) outdated = true;

    return outdated;
};

const detectedBrowser = getBrowser();
const BrowserNotSupported = isOutdatedBrowser(detectedBrowser);

export default BrowserNotSupported;
