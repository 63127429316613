// Libraries
import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import { Input, Popover } from 'antd';
import { get } from 'lodash';

// Components
import { AppearRight } from 'certn-ui/Animate';
import Title from 'certn-ui/Title';
import FormLayout from 'certn-form/FormLayout';
import { TrackNavigation } from 'views/welcome/components';
import ProvStateAutoComplete from 'components/ProvStateAutoComplete';

// Modules
import { Regex } from 'modules';

const CarForm = ({ handleSubmit, handleReverse, form: { getFieldDecorator, validateFields }, information, intl }) => (
    <AppearRight>
        <Title>
            <FormattedMessage
                id="welcome.Supplemental.car.formTitle"
                defaultMessage="You mentioned you require parking. Help your potential landlord by providing the registration info for your vehicle below."
            />
        </Title>
        <Form layout="horizontal" onSubmit={(e) => handleSubmit(e, validateFields)}>
            <FormLayout.R2N size="6" offset="6">
                <Form.Item>
                    {getFieldDecorator('car_plate', {
                        initialValue: get(information, 'car_plate') || null,
                        rules: [
                            {
                                pattern: Regex.licensePlate,
                                message: intl.formatMessage({
                                    id: 'error.validation.licensePlate',
                                    defaultMessage: 'Please use up to 8 numbers and letters',
                                }),
                            },
                        ],
                    })(
                        <Input
                            placeholder={intl.formatMessage({
                                id: 'welcome.Supplemental.car.licensePlateNumber',
                                defaultMessage: 'License Plate Number',
                            })}
                            addonBefore={
                                <Popover
                                    content={intl.formatMessage({
                                        id: 'welcome.Supplemental.car.licensePlateNumber',
                                        defaultMessage: 'License Plate Number',
                                    })}
                                >
                                    <InfoCircleOutlined />
                                </Popover>
                            }
                        />
                    )}
                </Form.Item>
                <Form.Item>
                    {getFieldDecorator('car_prov_state', {
                        initialValue: get(information, 'car_prov_state') || undefined,
                        rules: [
                            {
                                required: true,
                                message: 'Select a province!',
                            },
                        ],
                    })(<ProvStateAutoComplete />)}
                </Form.Item>
            </FormLayout.R2N>
            <TrackNavigation handleReverse={handleReverse} />
        </Form>
    </AppearRight>
);

export default Form.create()(injectIntl(CarForm));
