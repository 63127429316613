// Libraries
import React, { useEffect } from 'react';
import { bindActionCreators, Dispatch, Store } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Components
import { PositionPropertyLocationForm } from './components';

// Modules
import withNavigation from 'views/welcome/modules/WithNavigation';
import withSettingsChecker from 'views/welcome/modules/WithSettingsChecker';
import { OTHER } from 'modules/Countries';

// types
import {
    InformationType,
    ValuesType,
    SetTrackPageOrderFunction,
    HandleNextTrackFunction,
    PostPositionPropertyLocationFunction,
    PatchPositionPropertyLocationFunction,
    PositionPropertyLocationType,
} from './types';

// Actions & Selectors
import {
    setTrackPageOrder /* eslint-disable no-shadow */,
    postPositionPropertyLocation /* eslint-disable no-shadow */,
    patchPositionPropertyLocation /* eslint-disable no-shadow */,
    fetchWelcomeSession,
} from 'views/welcome/WelcomeActions';
import {
    getIsFetchingSilent,
    getInformation,
    getOnboardingType,
    getPositionPropertyLocation,
} from 'views/welcome/WelcomeSelectors';

const mapStateToProps = (state: Store) => ({
    isFetchingSilent: getIsFetchingSilent(state),
    information: getInformation(state),
    onboardingType: getOnboardingType(),
    positionPropertyLocation: getPositionPropertyLocation(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            postPositionPropertyLocation,
            patchPositionPropertyLocation,
            setTrackPageOrder,
            fetchWelcomeSession,
        },
        dispatch
    );

interface PropTypes {
    setTrackPageOrder: SetTrackPageOrderFunction;
    trackPageLocation: string;
    handleNextTrack: HandleNextTrackFunction;
    fetchWelcomeSession: () => Promise<void>;
    information: InformationType;
    postPositionPropertyLocation: PostPositionPropertyLocationFunction;
    patchPositionPropertyLocation: PatchPositionPropertyLocationFunction;
    positionPropertyLocation: PositionPropertyLocationType;
    onboardingType: string;
}

const PositionPropertyLocation: React.FC<PropTypes> = ({
    setTrackPageOrder,
    trackPageLocation,
    handleNextTrack,
    fetchWelcomeSession,
    information,
    postPositionPropertyLocation,
    patchPositionPropertyLocation,
    positionPropertyLocation,
    onboardingType,
}: PropTypes) => {
    const currentPositionPropertyLocation = positionPropertyLocation;
    useEffect(() => {
        setTrackPageOrder(['base']);
    }, [setTrackPageOrder]);

    const determineLocationType = (gig: string | undefined) => {
        // Determine what location type to send to the backend
        let locationType = 'Property Location';
        if (gig) {
            locationType = 'Gig Location';
        } else if (onboardingType === 'HR') {
            locationType = 'Position Location';
        }
        return locationType;
    };

    const transformData = (values: ValuesType) => {
        values.location_type = determineLocationType(values.gig);
        values.province_state = values.other_province_state ? OTHER : values.province_state;
        delete values.gig; // API doesn't need this.
    };

    const handleSubmit = async (values: ValuesType) => {
        transformData(values);
        if (!information.position_or_property_location_id) {
            await postPositionPropertyLocation(information.id, values);
        } else {
            await patchPositionPropertyLocation(information.position_or_property_location_id, values);
        }
        if (values.country === 'US') {
            await fetchWelcomeSession();
        }
        handleNextTrack();
    };

    const pages = () => ({
        base: (
            <PositionPropertyLocationForm
                onFinish={handleSubmit}
                currentPositionPropertyLocation={currentPositionPropertyLocation}
                onboardingType={onboardingType}
            />
        ),
    });

    return pages()[trackPageLocation as keyof typeof pages];
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(withNavigation(withSettingsChecker(PositionPropertyLocation)))
);
