import React from 'react';
import { useSelector } from 'react-redux';
import { MessageOutlined } from '@ant-design/icons';
import Text from 'certn-ui/Text';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

// Components
import { HideMobile } from './styled';
import CertnNavbar, { NavMain, NavLeft, NavRight } from 'certn-ui/Navbar';
import { NavLogo } from 'certn-ui/NavLogo';
import { LanguageSelect } from 'base/components';
import ApplicationTarget from './ApplicationTarget';

// Actions & Selectors
import { getTeamLogo } from 'base/BaseSelectors';
import { getNavigationVisible, getTeamIsUK, getTeamIsAUS } from 'views/welcome/WelcomeSelectors';

// modules
import { Path } from 'modules';
import { useFlags } from 'launchdarkly-react-client-sdk';

const Navbar = ({ error, changeSessionLanguage }) => {
    const logo = useSelector(getTeamLogo);
    const navigationVisible = useSelector(getNavigationVisible);
    const isTeamUK = useSelector(getTeamIsUK);
    const isTeamAUS = useSelector(getTeamIsAUS);
    const { webFeatureDisableSpanishLanguageOption } = useFlags();
    const intl = useIntl();
    if (!navigationVisible) return null;

    const altTag = Path.getAltTagFromSubdomain();

    return (
        <CertnNavbar fixed>
            <NavMain fixed>
                <NavLeft>
                    <NavLogo
                        alt={altTag}
                        src={logo}
                        title={intl.formatMessage({
                            id: 'common.Logo',
                            defaultMessage: 'Logo',
                        })}
                    />
                </NavLeft>
                <NavRight onboarding>
                    <HideMobile>
                        <Text align="right" margin="0 10px 0 0">
                            <ApplicationTarget error={error} />
                        </Text>
                    </HideMobile>
                    {window.fcWidget && (
                        <Button
                            id="industry-toggle-button"
                            shape="circle"
                            size="large"
                            onClick={() => {
                                window.fcWidget.open();
                            }}
                        >
                            <MessageOutlined style={{ marginTop: '4px', fontSize: '24px' }} />
                        </Button>
                    )}
                    {!(isTeamUK || isTeamAUS) && (
                        <LanguageSelect
                            onChange={changeSessionLanguage}
                            disableSpanish={webFeatureDisableSpanishLanguageOption}
                        />
                    )}
                </NavRight>
            </NavMain>
        </CertnNavbar>
    );
};

Navbar.propTypes = {
    error: PropTypes.bool.isRequired,
    changeSessionLanguage: PropTypes.func.isRequired,
};

export default Navbar;
