import { combineReducers } from 'redux';

import applications from './views/applications/ApplicationsReducer';
import properties from './views/properties/PropertiesReducer';
import listings from './views/listings/ListingsReducer';

const propertyManager = combineReducers({
    applications,
    properties,
    listings,
});

export default propertyManager;
