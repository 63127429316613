// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

// Modules
import { CopyToClipboard, Format } from 'modules';

// UI Components
import Navbar, { NavMain, NavOption, NavOptions } from 'certn-ui/Navbar';

// Table Components
import { CertnTable, CertnTableCustomHeader } from 'views/manager/components';
import ApplicantsCell from './ApplicantsCell';
import ApplicationsCell from './ApplicationsCell';
import ActionsCell from './ActionsCell';
import InfoCell from './InfoCell';
import ServicesCell from './ServicesCell';

// Actions and Selectors
import { setOrdering, fetchPostings, setPageSize } from 'views/manager/views/hr/views/postings/PostingsActions';
import { getIsFetchingApplications } from 'views/manager/views/hr/views/applications/ApplicationsSelectors';
import {
    getIsFetching,
    getOrdering,
    getReverseOrder,
    getCurrentPage,
    getPageSize,
    getNumPostings,
} from 'views/manager/views/hr/views/postings/PostingsSelectors';

const mapStateToProps = (state) => ({
    currentPage: getCurrentPage(state),
    pageSize: getPageSize(state),
    numPostings: getNumPostings(state),
    isFetching: getIsFetching(state),
    isFetchingApplications: getIsFetchingApplications(state),
    ordering: getOrdering(state),
    reverseOrder: getReverseOrder(state),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setOrdering,
            setPageSize,
            fetchPostings,
        },
        dispatch
    );

const propTypes = {
    data: PropTypes.array.isRequired,
    ordering: PropTypes.string.isRequired,
    setOrdering: PropTypes.func.isRequired,
    reverseOrder: PropTypes.bool.isRequired,
    isFetchingApplications: PropTypes.bool,
    isFetching: PropTypes.bool.isRequired,
};

const defaultProps = {
    isFetchingApplications: false,
};

class Table extends React.Component {
    columns = {
        desktop: [
            'Updated',
            'Position',
            'City',
            'Province',
            'Agent',
            'Checks',
            'Applicants',
            'Applications',
            'Actions',
        ],
        mobile: ['Info', 'Actions'],
        Updated: {
            active: 'modified',
            renderUi: ({ record }) => Format.date(record.modified),
            customHeader: ({ data }) => (
                <CertnTableCustomHeader
                    data={data}
                    title={<FormattedMessage id="99d99.Table.updated" defaultMessage="Updated" />}
                    reverseOrder={this.props.reverseOrder}
                    setOrdering={this.props.setOrdering}
                    ordering={this.props.ordering}
                />
            ),
        },
        Position: {
            active: 'position_name',
            renderUi: ({ record }) => record.position_name,
            customHeader: ({ data }) => (
                <CertnTableCustomHeader
                    data={data}
                    title={<FormattedMessage id="99d99.Table.packageName" defaultMessage="Package Name" />}
                    reverseOrder={this.props.reverseOrder}
                    setOrdering={this.props.setOrdering}
                    ordering={this.props.ordering}
                />
            ),
        },
        City: {
            active: 'address__city',
            renderUi: ({ record }) => (record.address && record.address.city) || record.city,
            customHeader: ({ data }) => (
                <CertnTableCustomHeader
                    data={data}
                    title={<FormattedMessage id="common.city" defaultMessage="City" />}
                    reverseOrder={this.props.reverseOrder}
                    setOrdering={this.props.setOrdering}
                    ordering={this.props.ordering}
                />
            ),
        },
        Province: {
            active: 'address__province_state',
            renderUi: ({ record }) => record.address && record.address.province_state,
            customHeader: ({ data }) => (
                <CertnTableCustomHeader
                    data={data}
                    title={<FormattedMessage id="address.provinceState" defaultMessage="Province/State" />}
                    reverseOrder={this.props.reverseOrder}
                    setOrdering={this.props.setOrdering}
                    ordering={this.props.ordering}
                />
            ),
        },
        Agent: {
            renderUi: ({ record }) => record.owner && record.owner.email,
            customHeader: ({ data }) => (
                <CertnTableCustomHeader
                    data={data}
                    title={<FormattedMessage id="99d99.Table.agent" defaultMessage="Agent" />}
                    reverseOrder={this.props.reverseOrder}
                    setOrdering={this.props.setOrdering}
                    ordering={this.props.ordering}
                />
            ),
        },
        Checks: {
            renderUi: ({ record }) => record.test_collection && <ServicesCell data={record.test_collection} />,
        },
        Applicants: {
            customHeader: ({ data, title }) => (
                <ApplicantsCell.Header
                    data={data}
                    title={title}
                    reverseOrder={this.props.reverseOrder}
                    setOrdering={this.props.setOrdering}
                    ordering={this.props.ordering}
                />
            ),
            renderUi: ({ record }) => <ApplicantsCell record={record} copyToClipboard={CopyToClipboard} />,
        },
        Applications: {
            active: 'new_applicant_count',
            renderUi: ({ record }) => <ApplicationsCell record={record} />,
            customHeader: ({ data }) => (
                <CertnTableCustomHeader
                    data={data}
                    title={<FormattedMessage id="common.applications" defaultMessage="Applications" />}
                    reverseOrder={this.props.reverseOrder}
                    setOrdering={this.props.setOrdering}
                    ordering={this.props.ordering}
                />
            ),
        },
        Actions: {
            renderUi: ({ record }) => (
                <ActionsCell
                    record={record}
                    showSendApplications={this.props.showSendApplications}
                    showTrialLimitReached={this.props.showTrialLimitReached}
                    copyToClipboard={CopyToClipboard}
                />
            ),
            customHeader: ({ data }) => (
                <CertnTableCustomHeader
                    data={data}
                    title={<FormattedMessage id="common.actions" defaultMessage="Actions" />}
                    reverseOrder={this.props.reverseOrder}
                    setOrdering={this.props.setOrdering}
                    ordering={this.props.ordering}
                />
            ),
        },
        Info: {
            active: 'modified',
            renderUi: ({ record, flow, history }) => <InfoCell record={record} flow={flow} history={history} />,
            customHeader: ({ data }) => (
                <CertnTableCustomHeader
                    data={data}
                    title={<FormattedMessage id="common.info" defaultMessage="Info" />}
                    reverseOrder={this.props.reverseOrder}
                    setOrdering={this.props.setOrdering}
                    ordering={this.props.ordering}
                />
            ),
        },
    };

    render() {
        const {
            data,
            isFetching,
            isFetchingApplications,
            ordering,
            reverseOrder,
            numPostings,
            pageSize,
            currentPage,
        } = this.props;
        const desktop = window.matchMedia('(min-width: 1024px)').matches;
        const display = desktop ? 'desktop' : 'mobile';
        const paginationConfig = desktop
            ? {
                  total: numPostings,
                  showTotal: (total, range) => {
                      const range0 = range[0];
                      const range1 = range[1];
                      return (
                          <FormattedMessage
                              id="99d99.Table.range"
                              defaultMessage="{range0}-{range1} of {total} items"
                              values={{ range0, range1, total }}
                          />
                      );
                  },
                  pageSize,
                  pageSizeOptions: ['7', '25', '50'],
                  defaultCurrent: currentPage,
                  current: currentPage,
                  showSizeChanger: true,
                  showQuickJumper: true,
                  onShowSizeChange: (current, size) => this.props.setPageSize(size),
                  onChange: (page) => this.props.fetchPostings(page),
              }
            : {
                  simple: true,
                  total: numPostings,
                  pageSize,
                  defaultCurrent: currentPage,
                  current: currentPage,
                  onChange: (page) => this.props.fetchPostings(page),
              };

        return (
            <CertnTable
                dataSource={data}
                loading={isFetching || isFetchingApplications}
                pagination={paginationConfig}
                columns={this.columns}
                display={display}
                setOrdering={this.props.setOrdering}
                ordering={ordering}
                reverseOrder={reverseOrder}
            />
        );
    }
}

Table.Nav = ({ isActive, setIsActive }) => (
    <Navbar noBorder>
        <NavMain>
            <NavOptions desktop mobile>
                <NavOption
                    label={<FormattedMessage id="99d99.Table.active" defaultMessage="active" />}
                    currentRoute={isActive}
                    onClick={() => setIsActive(true)}
                />
                <NavOption
                    label={<FormattedMessage id="99d99.Table.inactive" defaultMessage="inactive" />}
                    currentRoute={!isActive}
                    onClick={() => setIsActive(false)}
                />
            </NavOptions>
        </NavMain>
    </Navbar>
);

Table.Nav.displayName = 'TableNav';
Table.propTypes = propTypes;
Table.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(Table);
