// Libraries
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components/macro';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Form as AntdForm } from '@ant-design/compatible';
import { intl } from 'components/GlobalProvider';

// UI Components
import { Button, message, Table, Modal, Pagination } from 'antd';
import TeamMemberForm from 'views/manager/views/settings/components/TeamMemberForm';
import Loader from 'certn-ui/Loader';
import { ActionsCell, InfoCell, User } from './components';

// Other Components
import { getUser } from 'base/BaseSelectors';
import { get } from 'lodash';
import { getTeam } from 'views/manager/views/admin/AdminSelectors';
import {
    fetchAllSuperTeamMembers,
    saveTeamMember,
    createTeamMember,
    createGroup,
    fetchGroups,
    fetchPaginatedTeamMembers,
} from 'views/manager/views/settings/SettingsActions';
import {
    getTeamMembers,
    getIsFetching,
    getGroups,
    getCurrentTeam,
    getIsFetchingTeamMembers,
    getIsFetchingGroups,
    getAllSuperTeamUserEmails,
    getAllSuperTeamUsers,
    getTeamMembersCount,
} from 'views/manager/views/settings/SettingsSelectors';

// UI Components
import Typography from 'certnd/Typography';

const { Heading } = Typography;

const TeamWrapper = styled.div`
    @media (min-width: ${(props) => props.theme.width.desktop}) {
        max-width: 1200px;
        min-width: 1000px;
    }
`;

const mapStateToProps = (state) => ({
    isFetching: getIsFetching(state),
    user: getUser(state),
    teamMembers: getTeamMembers(state),
    teamMembersCount: getTeamMembersCount(state),
    allSuperTeamUsers: getAllSuperTeamUsers(state),
    allSuperTeamUserEmails: getAllSuperTeamUserEmails(state),
    groups: getGroups(state),
    team: getTeam(state),
    currentTeam: getCurrentTeam(state),
    isFetchingTeamMembers: getIsFetchingTeamMembers(state),
    isFetchingGroups: getIsFetchingGroups(state),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchAllSuperTeamMembers,
            fetchPaginatedTeamMembers,
            saveTeamMember,
            createTeamMember,
            fetchGroups,
            createGroup,
        },
        dispatch
    );

class Users extends React.Component {
    constructor(props) {
        super(props);
        const { location } = props;
        const teamId = props.team.id;
        this.state = { addTeamMember: false, editingTeamMember: null, pageSize: 10, page: 1 };
        this.removeTeamMember = this.removeTeamMember.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        props.fetchPaginatedTeamMembers(teamId, this.state.page, this.state.pageSize);
        props.fetchGroups(teamId);
        if (location?.search) {
            const parsed = queryString.parse(location.search);
            if (parsed.member_created)
                message.success(
                    intl.formatMessage({
                        id: '2b97d.Users.NewSuccess',
                        defaultMessage: 'Team member was successfully created!',
                    })
                );
            if (parsed.member_saved)
                message.success(
                    intl.formatMessage({
                        id: '2b97d.Users.EditSuccess',
                        defaultMessage: 'Team member details saved!',
                    })
                );
        }
    }

    debounceTimeout = null;

    handlePageChange = async (page, pageSize, order, searchByName) => {
        const { team } = this.props;
        const delay = 300;

        clearTimeout(this.debounceTimeout);

        // Create promises for fetching members and delaying
        const timeoutPromise = new Promise((resolve) => {
            this.debounceTimeout = setTimeout(resolve, delay);
        });

        timeoutPromise
            .then(async () => {
                const fetchMembersPromise = this.props.fetchPaginatedTeamMembers(
                    team.id,
                    page,
                    pageSize,
                    order,
                    searchByName
                );
                await fetchMembersPromise;
                this.setState({ page, pageSize, order, searchByName });
            })
            .catch((error) => {
                console.warn('Warning:', error);
            });
    };

    removeTeamMember = (values) => {
        if (values.joined_teams?.length <= 1) {
            message.error(
                this.props.intl.formatMessage({
                    id: '862f6.Users.SingleTeamRemove',
                    defaultMessage: 'Cannot remove user from their only team.',
                })
            );
            return;
        }
        if (!values.joined_teams?.includes(this.props.team?.id)) {
            message.error(
                this.props.intl.formatMessage({
                    id: '862f6.Users.UnassociatedTeamRemove',
                    defaultMessage: 'Cannot remove user from this team. They are not currently on it.',
                })
            );
            return;
        }

        const data = { joined_teams: values.joined_teams?.filter((team) => team !== this.props.team?.id) };
        return this.props.saveTeamMember(data, values.id).then(
            () => {
                message.success(
                    this.props.intl.formatMessage({
                        id: '862f6.Users.RemoveSuccess',
                        defaultMessage: 'Team Member Removed',
                    })
                );
                this.props.fetchPaginatedTeamMembers(this.props.team?.id);
            },
            () =>
                message.error(
                    this.props.intl.formatMessage({
                        id: '862f6.Users.RemoveError',
                        defaultMessage: 'Error occurred while attempting to remove this team member',
                    })
                )
        );
    };

    desktopColumns = [
        {
            title: <FormattedMessage id="common.firstName" defaultMessage="First Name" />,
            dataIndex: 'first_name',
            key: 'first_name',
            render: (text, row) => (
                <div
                    role="button"
                    tabIndex={0}
                    onClick={() => this.props.history.push(`/admin/user/${row.id}`)}
                    onKeyDown={() => this.props.history.push(`/admin/user/${row.id}`)}
                >
                    {text}
                </div>
            ),
        },
        {
            title: <FormattedMessage id="common.lastName" defaultMessage="Last Name" />,
            dataIndex: 'last_name',
            key: 'last_name',
        },
        {
            title: <FormattedMessage id="common.email" defaultMessage="Email" />,
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: <FormattedMessage id="2b97d.Users.ColumnStatus" defaultMessage="Status" />,
            dataIndex: 'is_active',
            key: 'is_active',
            render: (text, row) => <div>{row.is_active ? 'Active' : 'Inactive'}</div>,
        },
        {
            title: <FormattedMessage id="2b97d.Users.ColumnGroup" defaultMessage="Group" />,
            dataIndex: 'user_group',
            key: 'user_group',
            render: (text, row) => <div>{get(row, ['user_group', 'name'])}</div>,
        },
        {
            title: <FormattedMessage id="2b97d.Users.ColumnPermissionLevel" defaultMessage="Permission Level" />,
            dataIndex: 'permission_level_display',
            key: 'permission_level_display',
        },
        {
            title: <FormattedMessage id="common.actions" defaultMessage="Actions" />,
            dataIndex: 'actions',
            key: 'actions',
            render: (text, row) => (
                <ActionsCell
                    team={this.props.team}
                    row={row}
                    editTeamMember={(user) => this.setState({ editingTeamMember: user })}
                    removeTeamMember={this.removeTeamMember}
                    saveTeamMember={this.props.saveTeamMember}
                    fetchTeamMembers={this.props.fetchPaginatedTeamMembers}
                />
            ),
        },
    ];

    mobileColumns = [
        {
            title: <FormattedMessage id="common.info" defaultMessage="Info" />,
            dataIndex: 'info',
            key: 'info',
            width: '35%',
            onCell: () => ({
                style: {
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: 150,
                },
            }),
            render: (text, row) => <InfoCell row={row} history={this.props.history} />,
        },
        {
            title: <FormattedMessage id="common.actions" defaultMessage="Actions" />,
            dataIndex: 'actions',
            key: 'actions',
            width: '10%',
            render: (text, row) => (
                <ActionsCell
                    team={this.props.team}
                    row={row}
                    editTeamMember={(user) => this.setState({ editingTeamMember: user })}
                    removeTeamMember={this.removeTeamMember}
                    saveTeamMember={this.props.saveTeamMember}
                    fetchTeamMembers={this.props.fetchPaginatedTeamMembers}
                />
            ),
        },
    ];

    createMember = (values, teamId) => {
        this.props
            .createTeamMember({ ...values, team_id: teamId })
            .then(() => {
                message.success(
                    intl.formatMessage({
                        id: '2b97d.Users.NewSuccess',
                        defaultMessage: 'Team member was successfully created!',
                    })
                );
                this.setState({ addTeamMember: false });
                this.props.fetchPaginatedTeamMembers(teamId);
            })
            .catch((error) => {
                if (get(error, ['email'])) {
                    message.error(get(error, ['email']));
                } else if (get(error, ['permission_level'])) {
                    message.error(get(error, ['permission_level']));
                } else {
                    message.error(
                        intl.formatMessage({
                            id: '2b97d.Users.EditError',
                            defaultMessage: 'There are errors with at least one of your form entries.',
                        })
                    );
                }
            });
    };

    saveMember = (values, teamId) => {
        this.props
            .saveTeamMember(values, this.state.editingTeamMember.id)
            .then(() => {
                message.success(
                    intl.formatMessage({
                        id: '2b97d.Users.UserSaved',
                        defaultMessage: 'User saved!',
                    })
                );
                this.setState({ editingTeamMember: null });
            })
            .then(() => {
                this.props.fetchPaginatedTeamMembers(teamId);
            });
    };

    addExistingMember = (values, teamId) => {
        const selectedUser = this.props.allSuperTeamUsers?.filter((user) => user?.email === values?.email)[0];
        const data = {
            joined_teams: [...selectedUser.joined_teams, teamId],
        };
        this.props
            .saveTeamMember(data, selectedUser.id)
            .then(() => {
                message.success(
                    intl.formatMessage({
                        id: '2b97d.Users.AddUser',
                        defaultMessage: 'User Added To Team.',
                    })
                );
                this.setState({ addTeamMember: false });
            })
            .then(() => {
                this.props.fetchPaginatedTeamMembers(teamId);
            });
    };

    handleSubmit = (e, type) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const newValues = values;

                const { team } = this.props;

                if (newValues.new_group) {
                    this.props.createGroup(newValues.user_group, team.id).then((response) => {
                        values.user_group_id = response.id;
                        if (type === 'edit') {
                            this.saveMember(values, team.id);
                        } else {
                            this.createMember(values, team.id);
                        }
                    });
                } else if (type === 'edit') {
                    this.saveMember(values, team.id);
                } else if (this.props.allSuperTeamUserEmails.includes(values.email)) {
                    this.addExistingMember(values, team.id);
                } else {
                    this.createMember(values, team.id);
                }
            }
        });
    };

    render() {
        const { teamMembers, form, groups, isFetchingTeamMembers, isFetchingGroups } = this.props;
        const { editingTeamMember, addTeamMember } = this.state;

        return (
            <TeamWrapper>
                <Heading.H1 data-testid="title" style={{ display: 'inline-block' }}>
                    <FormattedMessage id="2b97d.Users.Users" defaultMessage="Users" />
                </Heading.H1>
                <Button
                    style={{ float: 'right' }}
                    type="primary"
                    onClick={() => this.setState({ addTeamMember: true })}
                >
                    <FormattedMessage id="2b97d.Users.AddMember" defaultMessage="ADD TEAM MEMBER" />
                </Button>
                <User.UserSearchBar>
                    <User.UserSearch
                        placeholder={intl.formatMessage({
                            id: '2b97d.Users.SearchUsers',
                            defaultMessage: 'Search Users',
                        })}
                        onChange={(e) => {
                            this.handlePageChange(1, 10, 'first_name', e.target.value);
                        }}
                    />
                </User.UserSearchBar>
                {!isFetchingTeamMembers && !isFetchingGroups ? (
                    <div>
                        {teamMembers && teamMembers.length > 0 ? (
                            <>
                                <Table
                                    rowKey={(record) => record.id}
                                    columns={
                                        window.matchMedia('(min-width: 1024px)').matches
                                            ? this.desktopColumns
                                            : this.mobileColumns
                                    }
                                    dataSource={teamMembers}
                                    pagination={false}
                                />
                                <span style={{ textAlign: 'right', width: '100%' }}>
                                    <Pagination
                                        total={this.props.teamMembersCount}
                                        pageSize={this.state.pageSize}
                                        current={this.state.page}
                                        onChange={(page, pageSize) => this.handlePageChange(page, pageSize)}
                                    />
                                </span>
                            </>
                        ) : (
                            <div>
                                <div>
                                    <FormattedMessage
                                        id="2b97d.Users.EmptyTeam"
                                        defaultMessage="This team currently has no team members"
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                ) : (
                    <Loader />
                )}
                <AntdForm>
                    <Modal
                        title={<FormattedMessage id="2b97d.Users.EditTeamMember" defaultMessage="Edit Team Member" />}
                        visible={editingTeamMember !== null}
                        onOk={(e) => this.handleSubmit(e, 'edit')}
                        onCancel={() => this.setState({ editingTeamMember: null })}
                        okText={<FormattedMessage id="common.submit" defaultMessage="Submit" />}
                        cancelText={<FormattedMessage id="common.cancel" defaultMessage="Cancel" />}
                        destroyOnClose
                        style={{ minWidth: '80%', paddingBottom: '80px' }}
                    >
                        <TeamMemberForm
                            form={form}
                            editingTeamMember={editingTeamMember}
                            togglePassword={this.togglePassword}
                            groups={groups}
                        />
                    </Modal>
                    <Modal
                        title={<FormattedMessage id="2b97d.Users.AddTeamMember" defaultMessage="Add Team Member" />}
                        visible={addTeamMember}
                        onOk={(e) => this.handleSubmit(e, 'create')}
                        onCancel={() => this.setState({ addTeamMember: false })}
                        okText={<FormattedMessage id="common.submit" defaultMessage="Submit" />}
                        cancelText={<FormattedMessage id="common.cancel" defaultMessage="Cancel" />}
                        style={{ minWidth: '80%', paddingBottom: '80px' }}
                        destroyOnClose
                    >
                        <TeamMemberForm form={form} groups={groups} />
                    </Modal>
                </AntdForm>
            </TeamWrapper>
        );
    }
}

export default AntdForm.create()(withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(Users))));
