import React, { useState } from 'react';
import Title from 'certn-ui/Title';
import { FormattedMessage } from 'react-intl';
import { AppearRight } from 'certn-ui/Animate';
import { Form, Checkbox } from 'antd';
import { FormLayout, WorkLocationAddressInput } from 'certn-form';
import Tooltip from './tooltip';
import { TrackNavigation } from 'views/welcome/components';
import { PositionPropertyLocationType } from '../types';
import { FormProps } from 'antd/lib/form';

interface PropTypes {
    onFinish: FormProps['onFinish'];
    currentPositionPropertyLocation: PositionPropertyLocationType;
    onboardingType: string;
}
const PositionPropertyLocationForm: React.FC<PropTypes> = ({
    onFinish,
    currentPositionPropertyLocation,
    onboardingType,
}: PropTypes) => {
    const [form] = Form.useForm();
    const [gigSelected, setGigSelected] = useState(currentPositionPropertyLocation?.location_type === 'Gig Location');
    return (
        <AppearRight>
            <Title color={undefined} padding={undefined}>
                {onboardingType === 'HR' ? (
                    <FormattedMessage
                        id="welcome.WorkLocation.positionLocationHeading"
                        defaultMessage="For this position, where will you be working from most of the time?"
                    />
                ) : (
                    <FormattedMessage
                        id="welcome.WorkLocation.propertylocationHeading"
                        defaultMessage="Please provide the location of the property you’re applying for"
                    />
                )}
            </Title>
            <Tooltip />

            <Form form={form} layout="horizontal" onFinish={onFinish}>
                {onboardingType === 'HR' ? (
                    <Form.Item name="gig" valuePropName="checked" initialValue={gigSelected}>
                        <Checkbox data-testid="gig_checkbox" onChange={() => setGigSelected(!gigSelected)}>
                            <FormattedMessage
                                id="welcome.PositionPropertyLocation.gig"
                                defaultMessage="I work from home or do not have a primary work location (ex. rideshare or service delivery)."
                            />
                        </Checkbox>
                    </Form.Item>
                ) : null}
                <FormLayout>
                    {!gigSelected ? (
                        <WorkLocationAddressInput
                            form={form}
                            currentAddress={currentPositionPropertyLocation}
                            hideLabels
                            bypassCounty
                            autocompleteId={undefined}
                            showAllFields={false}
                        />
                    ) : null}
                </FormLayout>
                <TrackNavigation />
            </Form>
        </AppearRight>
    );
};

export default PositionPropertyLocationForm;
