// Libraries
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

// Components
import { MoreBasicsForm } from './components';

// Modules
import withNavigation from 'views/welcome/modules/WithNavigation';
import { findIdentityNumber } from 'views/welcome/identityNumbers';

// Actions & Selectors
import { setTrackPageOrder, patchInformation } from 'views/welcome/WelcomeActions';
import {
    getIsFetchingSilent,
    getInformation,
    getOnboardingType,
    getSettings,
    getApplicant,
} from 'views/welcome/WelcomeSelectors';

const propTypes = {
    // WithNavigation
    trackPageLocation: PropTypes.string,
    handleNextTrack: PropTypes.func,
};

const defaultProps = {
    trackPageLocation: 'base',
    handleNextTrack: () => {},
};

const addOrUpdateIdentityNumber = (identityNumbers, country, number) => {
    const existing = findIdentityNumber(identityNumbers, country);
    if (existing) {
        existing.number = number;
    } else {
        identityNumbers.push({ country, number, identity_number_type: null });
    }
};

const MoreBasics = ({ trackPageLocation, handleNextTrack }) => {
    const dispatch = useDispatch();
    const isFetchingSilent = useSelector(getIsFetchingSilent);
    const information = useSelector(getInformation);
    const onboardingType = useSelector(getOnboardingType);
    const settings = useSelector(getSettings);
    const applicant = useSelector(getApplicant);

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(setTrackPageOrder(['base']));
    }, [dispatch]);

    const handleSubmit = (e, validateFields) => {
        e.preventDefault();

        validateFields((err, values) => {
            if (!err) {
                const identityNumbers = [...(information.identity_numbers || [])];

                if (typeof values.sin === 'string') {
                    const sinNumber = values.sin.replace(/[^0-9]/g, '');
                    addOrUpdateIdentityNumber(identityNumbers, 'CA', sinNumber);
                }

                if (typeof values.nin === 'string') {
                    const niNumber = values.nin.replace(/ /g, '');
                    addOrUpdateIdentityNumber(identityNumbers, 'GB', niNumber);
                }

                if (typeof values.ssn === 'string') {
                    const ssnNumber = values.ssn.replace(/[^0-9]/g, '');
                    addOrUpdateIdentityNumber(identityNumbers, 'US', ssnNumber);
                }

                delete values.sin;
                delete values.ssn;
                delete values.nin;

                values.identity_numbers = identityNumbers;

                dispatch(patchInformation(values)).then(handleNextTrack);
            }
        });
    };

    const pages = () => ({
        base: (
            <MoreBasicsForm
                handleSubmit={handleSubmit}
                information={information}
                isFetchingSilent={isFetchingSilent}
                onboardingType={onboardingType}
                isHr={onboardingType === 'HR'}
                teamIsCA={settings.get_org_country === 'CA'}
                teamIsUS={settings.get_org_country === 'US'}
                applicant={applicant}
            />
        ),
    });

    return pages()[trackPageLocation]; /* WithNavigation */
};

MoreBasics.propTypes = propTypes;
MoreBasics.defaultProps = defaultProps;

export default withRouter(withNavigation(MoreBasics));
