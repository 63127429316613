// Libraries
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import { message, Modal, Collapse, Badge } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';

// Components
import Loader from 'certn-ui/Loader';
import { ErrorAlertCustom, ErrorAlertAPI } from 'certn-ui/ErrorAlert';
import Typography from 'certnd/Typography';
import { Workday, JobAdder, Lever, Icims, Taleo, Fountain, SuccessFactors } from './components';

// Modules
import { getURLParamPlainJavascript } from 'modules/Format';

// Actions & Selectors
import { fetchIntegrations, disableIntegration } from 'views/manager/views/settings/SettingsActions';
import { getIsFetching } from 'base/BaseSelectors';
import { getTeam } from 'views/manager/views/admin/AdminSelectors';
import { getIntegrations } from '../../SettingsSelectors';
import { useFlags } from 'launchdarkly-react-client-sdk';

const { Heading } = Typography;
const { Panel } = Collapse;

const IntegrationWrapper = styled.div`
    max-width: 800px;
    width: 100%;
    @media (max-width: ${({ theme }) => theme.width.desktop}) {
        max-width: 100%;
        margin-right: 15px;
    }
`;

const Integration = styled.div``;

const Integrations = () => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const [showDisableModal, setShowDisableModal] = useState(false);
    const [integration, setDisableIntegration] = useState({});
    const teamId = useSelector((state) => getTeam(state).id);
    const isFetching = useSelector((state) => getIsFetching(state));
    const integrations = useSelector((state) => getIntegrations(state));
    const [activeIntegrations, setActiveIntegrations] = useState(null);
    const { webFeatureEnableFountainIntegration } = useFlags();

    const getActiveIntegrations = React.useCallback(() => {
        const active_integrations = Object.entries(integrations)
            .map(([integration_key, { is_active, active }]) => {
                if (is_active || active) return integration_key;
            })
            .filter((element) => element);
        setActiveIntegrations(active_integrations);
    }, [integrations]);

    useEffect(() => {
        dispatch(fetchIntegrations(teamId));
        const errorMessage = getURLParamPlainJavascript('error_message');
        if (errorMessage) {
            ErrorAlertCustom({ description: errorMessage });
        }
    }, [dispatch, teamId]);

    useEffect(() => {
        if (integrations && Object.keys(integrations).length > 0) getActiveIntegrations();
    }, [getActiveIntegrations, integrations]);

    const confirmDisable = () => {
        dispatch(disableIntegration({ teamId, integration: integration.name }))
            .then(() => {
                message.success(
                    intl.formatMessage(
                        {
                            id: '6f64a.Integrations.DisableSuccess',
                            defaultMessage: '{integr} disabled',
                        },
                        { integr: integration.name }
                    )
                );
            })
            .catch((error) => ErrorAlertAPI(error));
        setShowDisableModal(false);
    };

    const handleDisableIntegration = ({ name, text = false }) => {
        setDisableIntegration({ name, text });
        setShowDisableModal(true);
    };

    const IntegrationTitle = ({ title }) => (
        <Badge status={activeIntegrations.includes(title.toLowerCase()) ? 'success' : 'default'} text={title} />
    );

    return (
        <IntegrationWrapper>
            {isFetching || !integrations || !activeIntegrations ? (
                <Loader />
            ) : (
                <>
                    <Modal
                        title={
                            <FormattedMessage
                                id="6f64a.Integrations.DisableTitle"
                                defaultMessage="Disable {integr} Integration"
                                values={{ integr: integration.name }}
                            />
                        }
                        visible={showDisableModal}
                        onOk={confirmDisable}
                        onCancel={() => setShowDisableModal(false)}
                        okText={
                            <FormattedMessage id="common.disableIntegration" defaultMessage="Disable Integration" />
                        }
                        cancelText={<FormattedMessage id="common.cancel" defaultMessage="Cancel" />}
                    >
                        <div>
                            {integration.text || (
                                <FormattedMessage
                                    id="6f64a.Integrations.DisableText"
                                    defaultMessage="Are you sure you want to disable the {integr} integration?"
                                    values={{ integr: integration.name }}
                                />
                            )}
                        </div>
                    </Modal>
                    <Heading.H1>
                        <FormattedMessage id="6f64a.Integrations.PageTitle" defaultMessage="Integrations" />
                    </Heading.H1>
                    <Collapse expandIconPosition="right" defaultActiveKey={activeIntegrations}>
                        <Panel header={<IntegrationTitle title="JobAdder" />} key="jobadder">
                            <Integration>
                                <JobAdder handleDisableIntegration={handleDisableIntegration} />
                            </Integration>
                        </Panel>
                        <Panel header={<IntegrationTitle title="Workday" />} key="workday">
                            <Integration>
                                <Workday handleDisableIntegration={handleDisableIntegration} />
                            </Integration>
                        </Panel>
                        <Panel header={<IntegrationTitle title="Success Factors" />} key="success">
                            <Integration>
                                <SuccessFactors handleDisableIntegration={handleDisableIntegration} />
                            </Integration>
                        </Panel>
                        <Panel header={<IntegrationTitle title="Lever" />} key="lever">
                            <Integration>
                                <Lever handleDisableIntegration={handleDisableIntegration} />
                            </Integration>
                        </Panel>
                        <Panel header={<IntegrationTitle title="iCIMS" />} key="icims">
                            <Icims handleDisableIntegration={handleDisableIntegration} />
                        </Panel>
                        <Panel header={<IntegrationTitle title="Taleo" />} key="Taleo">
                            <Integration>
                                <Taleo handleDisableIntegration={handleDisableIntegration} />
                            </Integration>
                        </Panel>
                        {webFeatureEnableFountainIntegration && (
                            <Panel header={<IntegrationTitle title="Fountain" />} key="Fountain">
                                <Integration>
                                    <Fountain handleDisableIntegration={handleDisableIntegration} />
                                </Integration>
                            </Panel>
                        )}
                    </Collapse>
                </>
            )}
        </IntegrationWrapper>
    );
};

export default Integrations;
