import TypogHeading from './TypogHeading';
import TypogParagraph from './TypogParagraph';
import TypogText from './TypogText';

const Typography = {
    Heading: TypogHeading,
    Paragraph: TypogParagraph,
    Text: TypogText,
};

export default Typography;
