// Libraries
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { formatDate } from 'modules/Format';

// Components
import SummaryItem from './SummaryItem';
import SummaryHeader from './SummaryHeader';
import { FlexRow, SummarySection, SummaryContainer, LineBreak } from './SummaryStyles';

const Employers = ({ employers, goToTrack }) => (
    <SummaryContainer>
        <SummaryHeader
            title={<FormattedMessage id="welcome.general.employers" defaultMessage="Employers" />}
            onClick={goToTrack}
        />

        <SummarySection>
            {employers.length === 0 && (
                <SummaryItem
                    empty
                    title={
                        <FormattedMessage
                            id="welcome.Summary.noEmployersListed"
                            defaultMessage="You have not listed any employers"
                        />
                    }
                />
            )}
            {employers.map((employer, i) => (
                <React.Fragment key={i}>
                    <FlexRow>
                        <SummaryItem
                            title={<FormattedMessage id="welcome.general.company" defaultMessage="Company" />}
                            text={employer.company_name}
                        />
                        <SummaryItem
                            title={<FormattedMessage id="welcome.general.position" defaultMessage="Position" />}
                            text={employer.position}
                        />
                        {employer.income && (
                            <SummaryItem
                                title={
                                    <FormattedMessage
                                        id="welcome.general.income"
                                        defaultMessage="Estimated Yearly Income"
                                    />
                                }
                                text={employer.income}
                            />
                        )}
                        <SummaryItem
                            title={
                                <FormattedMessage
                                    id="welcome.general.currentOrPast"
                                    defaultMessage="Current or Past?"
                                />
                            }
                            text={employer.current ? 'Current' : 'Past'}
                        />
                        <SummaryItem
                            title={<FormattedMessage id="welcome.general.website" defaultMessage="Website" />}
                            text={employer.employment_url}
                        />
                        {employer.start_date && (
                            <SummaryItem
                                title={<FormattedMessage id="welcome.general.startDate" defaultMessage="Start Date" />}
                                text={formatDate(employer.start_date)}
                            />
                        )}
                        {employer.address && employer.address.address && (
                            <SummaryItem
                                title={<FormattedMessage id="welcome.general.address" defaultMessage="Address" />}
                                text={`${employer.address.unit ? employer.address.unit : ''} ${
                                    employer.address.address
                                } `}
                            />
                        )}
                        {employer.address && employer.address.address && (
                            <SummaryItem
                                title={
                                    <FormattedMessage id="welcome.Summary.employerLocation" defaultMessage="Location" />
                                }
                                text={`${employer.address.city} ${employer.address.province_state} ${employer.address.country}`}
                            />
                        )}
                        {employer.address && employer.address.postal_code && (
                            <SummaryItem
                                title={<FormattedMessage id="addressAuto.postalZip" defaultMessage="Postal/Zip Code" />}
                                text={employer.address.postal_code}
                            />
                        )}
                    </FlexRow>

                    {employer.employer_reference && employer.employer_reference.name.first_name && (
                        <>
                            <FlexRow>
                                <SummaryItem
                                    title={
                                        <FormattedMessage
                                            id="welcome.general.referenceFirstName"
                                            defaultMessage="Reference First Name"
                                        />
                                    }
                                    text={
                                        employer.employer_reference.name && employer.employer_reference.name.first_name
                                    }
                                />
                                <SummaryItem
                                    title={
                                        <FormattedMessage
                                            id="welcome.general.referenceLastName"
                                            defaultMessage="Reference Last Name"
                                        />
                                    }
                                    text={
                                        employer.employer_reference.name && employer.employer_reference.name.last_name
                                    }
                                />
                                <SummaryItem
                                    title={
                                        <FormattedMessage
                                            id="welcome.general.referencePosition"
                                            defaultMessage="Reference Position"
                                        />
                                    }
                                    text={employer.employer_reference.position}
                                />
                                <SummaryItem
                                    title={
                                        <FormattedMessage
                                            id="welcome.general.referenceEmail"
                                            defaultMessage="Reference Email"
                                        />
                                    }
                                    text={
                                        employer.employer_reference.email && employer.employer_reference.email.address
                                    }
                                />
                                <SummaryItem
                                    title={
                                        <FormattedMessage
                                            id="welcome.general.referencePhone"
                                            defaultMessage="Reference Phone"
                                        />
                                    }
                                    text={
                                        employer.employer_reference.phone_number &&
                                        employer.employer_reference.phone_number.number
                                    }
                                />
                                <SummaryItem
                                    title={
                                        <FormattedMessage id="welcome.general.phoneType" defaultMessage="Phone Type" />
                                    }
                                    text={
                                        employer.employer_reference.phone_number &&
                                        employer.employer_reference.phone_number.phone_type
                                    }
                                />
                            </FlexRow>
                        </>
                    )}

                    {employers.length > 1 && i + 1 < employers.length && <LineBreak />}
                </React.Fragment>
            ))}
        </SummarySection>
    </SummaryContainer>
);

export default Employers;
