import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getUserModeIsHR } from 'base/BaseSelectors';
import { ErrorAlertAPI } from 'certn-ui/ErrorAlert';
import { getRequest } from 'utils/http';
import { CheckProps } from 'types';

const USERMODE = {
    V1: 'v1',
    V2: 'v2',
} as const;

const ENDPOINT = '/checks';

export const usePackageChecks = () => {
    const userModeIsHR = useSelector(getUserModeIsHR);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [validChecks, setValidChecks] = useState<CheckProps[]>([]);

    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true);
                setIsError(false);
                const allChecks = await getRequest<CheckProps[]>({
                    hr: userModeIsHR,
                    version: userModeIsHR ? USERMODE.V1 : USERMODE.V2,
                    endpoint: ENDPOINT,
                });
                const filteredChecks = allChecks.filter((check: CheckProps) => check.is_permitted);
                setValidChecks(filteredChecks);
                setIsLoading(false);
            } catch (e) {
                ErrorAlertAPI(e);
                setIsError(true);
                setIsLoading(false);
            }
        })();
    }, [userModeIsHR]);

    return { isLoading, isError, validChecks };
};
