import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Form } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

import {
    StyledWrapper,
    Instructions,
    EmailInput,
    RemoveButton,
    ButtonRow,
    AddApplicantButton,
    EmailWithPhoneRow,
    EmailColumn,
    PhoneColumn,
    InternationalPhoneWrapper,
} from './styled';
import { hasDuplicatesInPreviousInputs, emailRules } from './EmailAddresses';
import InternationalPhone from 'components/InternationalPhone';
import FieldLabel from 'certn-form/FieldLabel';

const propTypes = {
    instructions: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    submitAttempted: PropTypes.bool,
    maxAllowed: PropTypes.number,
    fieldName: PropTypes.string,
    required: PropTypes.bool,
    phoneOnChange: PropTypes.func,
};

const defaultProps = {
    instructions: null,
    submitAttempted: false,
    maxAllowed: 5,
    fieldName: 'contact_info',
    required: true,
    phoneOnChange: null,
};

const EmailAddressesAndPhones = ({ instructions, submitAttempted, maxAllowed, fieldName, required, phoneOnChange }) => {
    const intl = useIntl();
    const validateTrigger = submitAttempted ? ['onChange', 'onBlur'] : ['onSubmit'];
    const placeholder = intl.formatMessage({
        id: '5ce00.EmailAddresses.email.placeholder',
        defaultMessage: 'joe.smith@example.com',
    });

    const phoneRules = (index) => [
        ({ getFieldValue }) => ({
            // Phone input values must be untouched or valid phone numbers
            validator(rule, value) {
                const parsedPhoneNumber = value && parsePhoneNumberFromString(value, 'CA');
                if (parsedPhoneNumber && !parsedPhoneNumber.isValid()) {
                    return Promise.reject(
                        intl.formatMessage({
                            id: 'error.validation.phoneNumber',
                            defaultMessage: 'Please provide a valid phone number',
                        })
                    );
                }

                // Don't allow duplicates
                const phoneNumbers = getFieldValue(fieldName).map((entry) => entry.phone);
                if (value && index > 0 && phoneNumbers && value !== '+1') {
                    if (hasDuplicatesInPreviousInputs(phoneNumbers, index, value))
                        return Promise.reject(
                            intl.formatMessage({
                                id: 'error.validation.phoneNumberDuplicates',
                                defaultMessage: 'This phone number is already listed above',
                            })
                        );
                }
                return Promise.resolve();
            },
        }),
    ];

    return (
        <StyledWrapper>
            <Instructions>{instructions}</Instructions>
            <Form.List name={fieldName} initialValue={[{ email: '', phone: null }]}>
                {(fields, { add, remove }) => (
                    <>
                        {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                            <EmailWithPhoneRow key={key}>
                                <EmailColumn>
                                    <FieldLabel htmlFor={`${fieldName}[${name}].email`}>
                                        {intl.formatMessage({
                                            id: 'common.emailAddress',
                                            defaultMessage: 'Email Address',
                                        })}
                                    </FieldLabel>
                                    <Form.Item
                                        {...restField}
                                        key={key}
                                        name={[name, 'email']}
                                        fieldKey={[fieldKey, 'email']}
                                        validateTrigger={validateTrigger}
                                        rules={emailRules(fieldName, index, required, intl, 'email')}
                                    >
                                        <EmailInput
                                            id={`${fieldName}[${name}].email`}
                                            type="email"
                                            placeholder={placeholder}
                                        />
                                    </Form.Item>
                                </EmailColumn>
                                <PhoneColumn>
                                    <FieldLabel htmlFor={`${fieldName}[${name}].email`} optional>
                                        {intl.formatMessage({
                                            id: 'common.mobileNumber',
                                            defaultMessage: 'Mobile number',
                                        })}{' '}
                                    </FieldLabel>
                                    <InternationalPhoneWrapper>
                                        <Form.Item
                                            {...restField}
                                            key={key}
                                            name={[name, 'phone']}
                                            fieldKey={[fieldKey, 'phone']}
                                            validateTrigger={validateTrigger}
                                            rules={phoneRules(index)}
                                            style={{ flexGrow: '1' }}
                                        >
                                            <InternationalPhone
                                                key="int-phone"
                                                id={`${fieldName}[${name}].phone`}
                                                inputSettings={{
                                                    inputSize: 'md',
                                                    showPopOver: false,
                                                }}
                                                onChange={phoneOnChange}
                                            />
                                        </Form.Item>
                                        {index > 0 && (
                                            <RemoveButton
                                                onClick={() => {
                                                    remove(name);
                                                    phoneOnChange();
                                                }}
                                                title={intl.formatMessage({
                                                    id: 'common.removeApplicant',
                                                    defaultMessage: 'Remove applicant',
                                                })}
                                            />
                                        )}
                                    </InternationalPhoneWrapper>
                                </PhoneColumn>
                            </EmailWithPhoneRow>
                        ))}
                        {fields.length < maxAllowed && (
                            <ButtonRow>
                                <AddApplicantButton type="dashed" onClick={() => add({ email: '', phone: '+1' })}>
                                    <PlusOutlined size="small" />
                                    {intl.formatMessage({
                                        id: 'common.addAdditionalApplicant',
                                        defaultMessage: 'Add Additional Applicant',
                                    })}
                                </AddApplicantButton>
                            </ButtonRow>
                        )}
                    </>
                )}
            </Form.List>
        </StyledWrapper>
    );
};

EmailAddressesAndPhones.propTypes = propTypes;
EmailAddressesAndPhones.defaultProps = defaultProps;

export default EmailAddressesAndPhones;
