// Libraries
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

// Components
import SummaryItem from './SummaryItem';
import SummaryHeader from './SummaryHeader';
import { FlexRow, SummarySection, SummaryContainer } from './SummaryStyles';

const EmergencyContact = ({ emergencyContact, goToTrack }) => (
    <SummaryContainer>
        <SummaryHeader
            title={<FormattedMessage id="welcome.general.emergencyContact" defaultMessage="Emergency Contact" />}
            onClick={goToTrack}
        />
        <SummarySection>
            <FlexRow>
                <SummaryItem
                    title={<FormattedMessage id="common.firstName" defaultMessage="First Name" />}
                    text={emergencyContact.emergency_contact_first_name}
                />
                <SummaryItem
                    title={<FormattedMessage id="common.lastName" defaultMessage="Last Name" />}
                    text={emergencyContact.emergency_contact_last_name}
                />
                <SummaryItem
                    title={<FormattedMessage id="common.email" defaultMessage="Email" />}
                    text={emergencyContact.emergency_contact_email}
                />
                <SummaryItem
                    title={<FormattedMessage id="welcome.general.phoneNumber" defaultMessage="Phone Number" />}
                    text={emergencyContact.emergency_contact_phone}
                />
            </FlexRow>
        </SummarySection>
    </SummaryContainer>
);

export default injectIntl(EmergencyContact);
