import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Form } from '@ant-design/compatible';
import { Switch } from 'antd';
import Typography from 'certnd/Typography';
import { formPropTypes, formOptionsPropTypes } from './formPropTypes';
import styles from 'styles/styles';

const { Heading } = Typography;

const FormSwitchWrapper = styled.div`
    display: flex;
    flex-direction: row;
    border-bottom: ${(props) => (props.hideBorder ? 'none' : `solid ${styles.color.certnGray400} 1px`)};
    width: 100%;
    margin-top: -5px;
    margin-bottom: ${(props) => (props.hideBorder ? 'none' : '20px')};

    & > *:not(:last-child) {
        margin-right: 10px;
    }
`;

const FormSwitchContent = styled.div`
    display: block;
    padding-top: 12px;
    margin-bottom: ${(props) => (props.hideBorder ? 'none' : '20px')};
`;

const FormSwitch = ({ hideBorder, title, form, fieldName, options, ...rest }) => (
    <FormSwitchWrapper hideBorder={hideBorder}>
        <Form.Item>
            {/* If this is a form-connected field (otherwise often used with onChange) */}
            {!!form && !!fieldName ? (
                form.getFieldDecorator(fieldName, {
                    valuePropName: 'checked',
                    ...options,
                })(<Switch {...rest} />)
            ) : (
                <Switch {...rest} />
            )}
        </Form.Item>
        <FormSwitchContent hideBorder={hideBorder}>
            {title ? <Heading.H4 disableGutter>{title}</Heading.H4> : null}
        </FormSwitchContent>
    </FormSwitchWrapper>
);

FormSwitch.propTypes = {
    hideBorder: PropTypes.bool,
    title: PropTypes.string,
    fieldName: PropTypes.string,
    form: formPropTypes,
    options: formOptionsPropTypes,
};

FormSwitch.defaultProps = {
    hideBorder: false,
    title: undefined,
    fieldName: undefined,
    form: undefined,
    options: {},
};

export default FormSwitch;
