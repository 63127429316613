// Libraries
import React, { useState, useEffect } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash';
import styled from 'styled-components/macro';
import { Form as AntdForm } from '@ant-design/compatible';
import { Popover, Descriptions, message } from 'antd';
import { FormattedMessage } from 'react-intl';
import { intl } from 'components/GlobalProvider';

// Components
import Form from 'certnd/Form';
import Typography from 'certnd/Typography';
import Loader from 'certn-ui/Loader';
import { ErrorAlertAPI } from 'certn-ui/ErrorAlert';
import Packages from './packages/Packages';
import ToggleIntegrationButton from './components/ToggleIntegrationButton';

// Modules & Utils
import Auth from 'modules/Auth';

// Actions & Selectors
import {
    getIntegrations,
    getIsFetching,
    getSuperTeamAdmins,
    getIcimsPackages,
} from 'views/manager/views/settings/SettingsSelectors';
import {
    enableIcims,
    disableIcims,
    fetchReferenceTemplates,
    fetchSuperteamAdmins,
    fetchSuperteamIntegrationPackages,
    generateIcimsWebhookCredentials,
} from 'views/manager/views/settings/SettingsActions';
import { getTeam } from 'views/manager/views/admin/AdminSelectors';

const INTEGRATION_TYPE = 'ICIMS';
const INTEGRATION_NAME = 'icims';

const { Heading } = Typography;

const FormBlock = styled(Form.Block)`
    margin: 0 !important;
    max-width: 100% !important;
`;
const SetUpForm = styled(Form.Block)`
    margin: 0 !important;
    max-width: 100% !important;
    padding-bottom: 0;
`;

const Icims = (props) => {
    const dispatch = useDispatch();
    const canEdit = Auth.isPermissionLevel('admin');
    const isFetching = useSelector(getIsFetching);
    const [loading, setLoading] = useState(false);
    const integrations = useSelector((state) => getIntegrations(state));

    const teamId = useSelector((state) => getTeam(state).id);
    const icimsActive = get(integrations, ['icims', 'is_active']);
    const icimsIntegration = get(integrations, ['icims']);
    const superteamId = useSelector((state) => getTeam(state).superteam);
    const adminList = useSelector(getSuperTeamAdmins);
    const icimsPackages = useSelector(getIcimsPackages);
    const hasWebhookCredentials = get(integrations, ['icims', 'icims_webhook_username']);

    useEffect(() => {
        dispatch(fetchSuperteamAdmins(superteamId));
        dispatch(fetchSuperteamIntegrationPackages({ superteamId, integrationType: INTEGRATION_TYPE }));
        dispatch(fetchReferenceTemplates({ type: 'employer', teamId }));
    }, [superteamId, dispatch, teamId]);

    const handleGenerate = (e) => {
        e.preventDefault();
        dispatch(generateIcimsWebhookCredentials({ superteamId, teamId }))
            .then(() => {
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                ErrorAlertAPI(error);
            });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!hasWebhookCredentials) {
            message.error(
                intl.formatMessage({
                    id: '6f403.Icims.WebhookCredsError',
                    defaultMessage:
                        'Please generate credentials for iCIMS to use to authenticate requests sent to Certn',
                })
            );
            return;
        }
        props.form.validateFields((err, values) => {
            if (!err) {
                setLoading(true);
                const newIntegration = {
                    owner: values.owner,
                    super_team: superteamId,
                    customer_id: values.icims_customer_id,
                    icims_username: values.icims_username,
                    icims_password: values.icims_password,
                    icims_webhook_username: icimsIntegration?.icims_webhook_username,
                    icims_webhook_password: icimsIntegration?.icims_webhook_password,
                    is_active: true,
                };
                if (!icimsActive) {
                    dispatch(enableIcims({ teamId, superteamId, data: newIntegration }))
                        .then(() => {
                            setLoading(false);
                        })
                        .catch((error) => {
                            setLoading(false);
                            ErrorAlertAPI(error);
                        });
                } else {
                    newIntegration.is_active = false;
                    dispatch(disableIcims({ teamId, superteamId, data: newIntegration }))
                        .then(() => {
                            setLoading(false);
                        })
                        .catch((error) => {
                            setLoading(false);
                            ErrorAlertAPI(error);
                        });
                }
            }
        });
    };
    if (isFetching) return <Loader />;
    return (
        <AntdForm onSubmit={handleSubmit}>
            {!icimsActive && (
                <>
                    <FormBlock style={{ paddingBottom: '0' }}>
                        <Heading.H3>
                            <FormattedMessage
                                id="6f403.Icims.Title"
                                defaultMessage="iCIMS Integration Setup Instructions"
                            />
                        </Heading.H3>
                        <Heading.H4>
                            <FormattedMessage
                                id="6f403.Icims.InstructionsOne"
                                defaultMessage="To set up an integration between iCIMS and Certn please visit the "
                            />
                            {/* TODO: Update Link with Certn Marketplace page */}
                            <a
                                href="https://marketplace.icims.com/home#!category/6311/6319"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <FormattedMessage
                                    id="6f403.Icims.InstructionsLink"
                                    defaultMessage="iCIMS Marketplace"
                                />
                            </a>
                            <FormattedMessage
                                id="6f403.Icims.InstructionsTwo"
                                defaultMessage=" and follow the link there to notify iCIMS of your desire to set up the integration."
                            />
                        </Heading.H4>
                        <Heading.H4>
                            <FormattedMessage
                                id="6f403.Icims.InstructionsThree"
                                defaultMessage="Please contact your Certn Customer Success Representative for more information and further instructions on setting up an integration between iCIMS and Certn."
                            />
                        </Heading.H4>
                        <SetUpForm>
                            <Form.Input
                                form={props.form}
                                fieldName="icims_customer_id"
                                title={intl.formatMessage({
                                    id: '6f403.Icims.CustomerIDTitle',
                                    defaultMessage: 'iCIMS Customer ID',
                                })}
                                addonBefore={
                                    <Popover
                                        content={intl.formatMessage({
                                            id: '6f403.Icims.CustomerIDMessage',
                                            defaultMessage:
                                                'The Customer ID is provided by iCIMS for setting up the integration',
                                        })}
                                    >
                                        <InfoCircleOutlined />
                                    </Popover>
                                }
                                options={{
                                    rules: [
                                        {
                                            required: true,
                                            message: intl.formatMessage({
                                                id: '6f403.Icims.CustomerIDErrorMessage',
                                                defaultMessage: 'Please input the iCIMS Customer ID',
                                            }),
                                            whitespace: true,
                                        },
                                    ],
                                }}
                            />
                            <Form.Input
                                form={props.form}
                                fieldName="icims_username"
                                title={intl.formatMessage({
                                    id: '6f403.Icims.UsernameTitle',
                                    defaultMessage: 'iCIMS Username',
                                })}
                                addonBefore={
                                    <Popover
                                        content={intl.formatMessage({
                                            id: '6f403.Icims.UsernameMessage',
                                            defaultMessage: 'Please input the username Certn will use to access iCIMS',
                                        })}
                                    >
                                        <InfoCircleOutlined />
                                    </Popover>
                                }
                                options={{
                                    rules: [
                                        {
                                            required: true,
                                            message: intl.formatMessage({
                                                id: '6f403.Icims.UsernameErrorMessage',
                                                defaultMessage: 'Please input the iCIMS Username',
                                            }),
                                            whitespace: true,
                                        },
                                    ],
                                }}
                            />
                            <Form.Input
                                form={props.form}
                                fieldName="icims_password"
                                title={intl.formatMessage({
                                    id: '6f403.Icims.Password',
                                    defaultMessage: 'iCIMS Password',
                                })}
                                addonBefore={
                                    <Popover
                                        content={intl.formatMessage({
                                            id: '6f403.Icims.PasswordMessage',
                                            defaultMessage: 'Please input the password Certn will use to access iCIMS',
                                        })}
                                    >
                                        <InfoCircleOutlined />
                                    </Popover>
                                }
                                options={{
                                    rules: [
                                        {
                                            required: true,
                                            message: intl.formatMessage({
                                                id: '6f403.Icims.PasswordErrorMessage',
                                                defaultMessage: 'Please input the iCIMS Password',
                                            }),
                                            whitespace: true,
                                        },
                                    ],
                                }}
                            />
                            <Form.Select
                                form={props.form}
                                fieldName="owner"
                                title={intl.formatMessage({ id: '6f403.Icims.Owner', defaultMessage: 'Admin Owner' })}
                                placeholder={intl.formatMessage({
                                    id: '22572.Packages.ServicesPlaceholder',
                                    defaultMessage: 'Select',
                                })}
                                selectOptions={[
                                    ...(adminList || []).map((admin) => ({
                                        key: admin.id,
                                        value: admin.id,
                                        title: admin.email,
                                    })),
                                ]}
                                options={{
                                    rules: [
                                        {
                                            required: true,
                                            message: intl.formatMessage({
                                                id: '6f403.Icims.OwnerError',
                                                defaultMessage:
                                                    'Please input an Owner who is an Admin on the SuperTeam',
                                            }),
                                            whitespace: true,
                                        },
                                    ],
                                }}
                                description={intl.formatMessage({
                                    id: '6f403.Icims.AdminSelectDescription',
                                    defaultMessage:
                                        'Please select an Admin to be the owner for this integration. All applications will be run under this user.',
                                })}
                            />
                        </SetUpForm>
                    </FormBlock>
                    <FormBlock style={{ paddingTop: '0' }}>
                        <Heading.H3>
                            <FormattedMessage
                                id="6f403.Icims.GenerateTitle"
                                defaultMessage="Generate Icims Webhook Username and Password"
                            />
                        </Heading.H3>
                        <Heading.H4>
                            <FormattedMessage
                                id="6f403.Icims.GenerateExplanation"
                                defaultMessage="To ensure the authenticity of webhook requests sent to Certn, each request is required to include a basic authorization header. Use the button below to generate a username and password combination for iCIMS to use with requests sent to Certn. These credentials must be given to iCIMS before the integration can be enabled."
                            />
                        </Heading.H4>
                        <Form.Button loading={loading} disabled={!canEdit} onClick={handleGenerate}>
                            {hasWebhookCredentials ? (
                                <FormattedMessage
                                    id="6f403.Icims.RegenerateUsernamePassword"
                                    defaultMessage="Regenerate Credentials"
                                />
                            ) : (
                                <FormattedMessage
                                    id="6f403.Icims.GenerateUsernamePassword"
                                    defaultMessage="Generate Credentials"
                                />
                            )}
                        </Form.Button>
                        {hasWebhookCredentials && (
                            <Descriptions column={1} style={{ marginTop: '10px', paddingLeft: '10px' }}>
                                <Descriptions.Item
                                    label={intl.formatMessage({
                                        id: 'common.username',
                                        defaultMessage: 'Username',
                                    })}
                                >
                                    {icimsIntegration.icims_webhook_username}
                                </Descriptions.Item>
                                <Descriptions.Item
                                    label={intl.formatMessage({
                                        id: 'common.password',
                                        defaultMessage: 'Password',
                                    })}
                                >
                                    {icimsIntegration.icims_webhook_password}
                                </Descriptions.Item>
                            </Descriptions>
                        )}
                    </FormBlock>
                </>
            )}
            {icimsActive && (
                <>
                    <Heading.H4>
                        <FormattedMessage
                            id="6f403.Icims.InstructionsFour"
                            defaultMessage="Create packages for use with the iCIMS integration. These packages are shared across the organization."
                        />
                    </Heading.H4>
                    <Packages
                        integrationType={INTEGRATION_TYPE}
                        integrationName={INTEGRATION_NAME}
                        packages={icimsPackages}
                        active={icimsActive}
                        form={props.form}
                        useDefaultColumns={false}
                    />
                </>
            )}
            <ToggleIntegrationButton canEdit={canEdit} loading={loading} active={icimsActive} />
        </AntdForm>
    );
};

export default AntdForm.create()(Icims);
