// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

// UI Components
import { ButtonLine } from 'certn-ui/Button';
import { Box } from 'certn-ui/Layout';
import { Form, Button } from 'antd';

// Form Components
import Password from 'certn-ui/Password';

const propTypes = {
    onSubmit: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.any,
};
const defaultProps = {
    error: undefined,
};

const ResetPasswordForm = ({ onSubmit, loading, error }) => {
    const [form] = Form.useForm();
    return (
        <Box size="xs">
            <Form form={form} onFinish={onSubmit} style={{ width: '100%' }}>
                <Password error={error} form={form} />
                <ButtonLine>
                    <Button
                        type="primary"
                        size="large"
                        certncolor="primary"
                        htmlType="submit"
                        style={{ width: '100%' }}
                        loading={loading}
                    >
                        <FormattedMessage id="common.submit" defaultMessage="Submit" />
                    </Button>
                </ButtonLine>
            </Form>
        </Box>
    );
};

ResetPasswordForm.propTypes = propTypes;
ResetPasswordForm.defaultProps = defaultProps;

export default ResetPasswordForm;
