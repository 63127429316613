// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Form as AntdForm } from '@ant-design/compatible';
import { Select, Form } from 'antd';

import { getPrepopulatedFields } from 'views/welcome/WelcomeSelectors';

const propTypes = {
    information: PropTypes.object,
    size: PropTypes.string,
    includeGenderX: PropTypes.bool,
};

const defaultProps = {
    information: {},
    size: 'large',
    includeGenderX: false,
};

export const GenderSelect = ({ information, size, includeGenderX }) => {
    const intl = useIntl();
    const placeholder = intl.formatMessage({ id: 'welcome.Gender.selectSex', defaultMessage: 'Select Your Sex' });
    const prepopulatedFields = useSelector(getPrepopulatedFields);
    return (
        <Form.Item
            name="gender"
            initialValue={information.gender}
            rules={[
                {
                    required: true,
                    message: placeholder,
                },
            ]}
        >
            <Select
                size={size}
                showSearch
                filterOption={(input = '', option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) === 0
                }
                placeholder={placeholder}
                disabled={prepopulatedFields.includes('gender')}
            >
                <Select.Option value="M">
                    {intl.formatMessage({ id: 'welcome.Gender.male', defaultMessage: 'Male' })}
                </Select.Option>
                <Select.Option value="F">
                    {intl.formatMessage({ id: 'welcome.Gender.female', defaultMessage: 'Female' })}
                </Select.Option>
                {includeGenderX && (
                    <Select.Option value="X">
                        {intl.formatMessage({ id: 'welcome.Gender.X', defaultMessage: 'X' })}
                    </Select.Option>
                )}
            </Select>
        </Form.Item>
    );
};

GenderSelect.propTypes = propTypes;
GenderSelect.defaultProps = defaultProps;
export default GenderSelect;

// Uses Antd v3 Form
export const GenderSelectLegacy = ({ information, size = 'large', getFieldDecorator, includeGenderX }) => {
    const intl = useIntl();
    const placeholder = intl.formatMessage({ id: 'welcome.Gender.selectSex', defaultMessage: 'Select Your Sex' });
    return (
        <AntdForm.Item>
            {getFieldDecorator('gender', {
                initialValue: information.gender,
                rules: [
                    {
                        required: true,
                        message: placeholder,
                    },
                ],
            })(
                <Select size={size} showSearch placeholder={placeholder}>
                    <Select.Option value="M">
                        {intl.formatMessage({ id: 'welcome.Gender.male', defaultMessage: 'Male' })}
                    </Select.Option>
                    <Select.Option value="F">
                        {intl.formatMessage({ id: 'welcome.Gender.female', defaultMessage: 'Female' })}
                    </Select.Option>
                    {includeGenderX && (
                        <Select.Option value="X">
                            {intl.formatMessage({ id: 'welcome.Gender.X', defaultMessage: 'X' })}
                        </Select.Option>
                    )}
                </Select>
            )}
        </AntdForm.Item>
    );
};

GenderSelectLegacy.propTypes = {
    ...propTypes,
    getFieldDecorator: PropTypes.func.isRequired,
};
GenderSelectLegacy.defaultProps = {
    ...defaultProps,
};
