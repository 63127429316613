import { useQuery } from '@tanstack/react-query';
import { queryKeys } from 'utils/reactQuery';
import { Adjudicator } from 'types';
import { getRequest } from 'utils/http';

const fetchAdjudicators = async () => {
    const response = await getRequest<Adjudicator[]>({
        hr: true,
        version: 'v1',
        endpoint: `team/adjudicators/`,
    });

    return response.map((d) => ({
        id: d.id,
        name: `${d.first_name} ${d.last_name}`,
    }));
};

export const useAdjudicators = () => {
    const { data, isLoading, isError } = useQuery<(Adjudicator & { name: string })[]>(
        [queryKeys.adjudicators],
        fetchAdjudicators
    );

    return {
        adjudicators: data,
        isAdjudicatorsLoading: isLoading,
        hasAdjudicatorsError: isError,
    };
};
