// Libraries
import React from 'react';
import { useSelector } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Input } from 'antd';

// Components
import { intl } from 'components/GlobalProvider';

// Actions & Selectors
import { getSelectedCheckKeys } from 'views/manager/features/ScreenApplicant/ScreenApplicantSelectors';

// Modules
import isVisibleOrRequired from './isVisibleOrRequired';

// Constants
import { CHECK_REQUEST } from 'base/BaseConstants';

const License = () => {
    const selectedServices = useSelector(getSelectedCheckKeys);
    const label = intl.formatMessage({
        id: 'f5ca1.QuickScreen.driversLicense',
        defaultMessage: "Driver's License Number:",
    });

    // Checks to display field and set required, true in brackets for all services
    const visibleList = [CHECK_REQUEST.EQUIFAX];
    const requiredList = [];

    if (!isVisibleOrRequired(visibleList, selectedServices)) return null;
    return (
        <>
            {label}
            <Form.Item
                name="license_number"
                initialValue={null}
                rules={[
                    {
                        type: 'string',
                        message: intl.formatMessage({
                            id: 'error.validation.validLicense',
                            defaultMessage: 'The input is not valid license number!',
                        }),
                    },
                    {
                        required: isVisibleOrRequired(requiredList, selectedServices),
                        message: intl.formatMessage({
                            id: 'error.validation.notBlank',
                            defaultMessage: 'Please do not leave blank',
                        }),
                    },
                ]}
            >
                <Input placeholder={label} />
            </Form.Item>
        </>
    );
};

export default License;
