import React from 'react';
import styled from 'styled-components/macro';
import { FormattedMessage } from 'react-intl';

import { InfoCircleOutlined, MailOutlined } from '@ant-design/icons';

import { Button } from 'antd';

import ChrisAvatar from 'images/avatars/chris-cook.png';
import CurtisAvatar from 'images/avatars/curtis-smith.png';
import AndrewAvatar from 'images/avatars/andrew-mcleod.png';
import ElleAvatar from 'images/avatars/elle-herron.png';
import CertnIcon from 'images/icon.png';

import Format from 'modules/Format';
import styles from 'styles/styles';

const Notification = ({ notification, onClick, type, setNotificationViewed, seen }) => {
    const { owner, message, subject } = notification.notification;
    let avatar = <InfoCircleOutlined style={{ color: styles.color.certnBlue500, fontSize: '20px' }} />;
    let avatarLink = CertnIcon;
    let ownerEmail;
    if (owner && owner.email) {
        // TODO: rethink this to make it more general
        ownerEmail = owner.email;
        if (ownerEmail === 'chris@certn.co') avatarLink = ChrisAvatar;
        if (ownerEmail === 'curtis@certn.co') avatarLink = CurtisAvatar;
        if (ownerEmail === 'andrew@certn.co') avatarLink = AndrewAvatar;
        if (ownerEmail === 'elle.herron@certn.co') avatarLink = ElleAvatar;
    }
    if (avatarLink)
        avatar = (
            <Avatar
                src={avatarLink}
                alt={<FormattedMessage id="dbe15.Notifications.certnEmployee" defaultMessage="Certn Employee" />}
            />
        );

    if (type === 'list') {
        return (
            <ContainerList onClick={setNotificationViewed} viewed={notification.is_viewed}>
                <AvatarContainer>{avatar}</AvatarContainer>
                <Body>
                    <>
                        <Date large>{Format.date(notification.created)}</Date>
                        <Header large>{subject}</Header>
                        <Message large>{message}</Message>
                    </>
                </Body>
                {!notification.is_viewed && (
                    <IconWrapper>
                        <Button type="primary" onClick={setNotificationViewed}>
                            <MailOutlined style={{ paddingTop: '5px' }} />
                        </Button>
                    </IconWrapper>
                )}
            </ContainerList>
        );
    }
    return (
        <Container onClick={onClick}>
            <AvatarContainer>{avatar}</AvatarContainer>
            <Body>
                <Date>{Format.date(notification.created)}</Date>
                <Header>{subject}</Header>
                <Message>{Format.truncateText(message, 80)}</Message>
            </Body>
            <IconWrapper>
                <Button type="primary" shape="circle" onClick={seen}>
                    <MailOutlined style={{ paddingTop: '5px' }} />
                </Button>
            </IconWrapper>
        </Container>
    );
};

export default Notification;

const IconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ContainerList = styled.div`
    display: flex;
    width: 900px;
    padding: 15px;
    border: 1px solid ${(props) => props.theme.color.certnGray200};
    border-radius: 4px;
    margin-top: 10px;
    opacity: ${(props) => (props.viewed ? 0.6 : 1.0)};
    background: ${({ viewed, theme }) => !viewed && theme.color.certnGreen100};
    transition: 0.3s;
    &:hover {
        cursor: pointer;
        opacity: 1;
    }
    @media (max-width: ${({ theme }) => theme.width.tablet}) {
        width: 100%;
    }
`;

const Container = styled.div`
    width: 360px;
    max-width: 100%;
    min-height: 80px;
    flex-shrink: 0;
    display: flex;
    padding: 5px;
    box-sizing: border-box;
    background: ${({ theme }) => theme.color.certnWhite};
    border-bottom: 1px solid ${({ theme }) => theme.color.certnGray400};
    background: ${({ theme, isViewed }) => (isViewed ? theme.color.certnWhite : theme.color.certnGreen100)};
    cursor: pointer;
    &:hover {
        background: ${({ theme }) => theme.color.certnGreen100};
    }
    z-index: 99999;
`;

export const AvatarContainer = styled.div`
    width: 80px;
    min-width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    margin-right: 10px;
`;

export const Avatar = styled.img`
    height: 60px;
    width: 60px;
    border-radius: 50%;
    border: 1px solid ${(props) => props.theme.color.certnGray400};
`;

export const Body = styled.div`
    width: 100%;
`;

export const Link = styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
    text-align: right;
`;

export const MessageContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    padding: 3px;
    box-sizing: border-box;
`;

export const Type = styled.span`
    font-weight: 700;
    font-size: 0.8em;
`;

export const Header = styled.div`
    word-break: break-word;
    font-weight: 700;
    margin-right: 10px;
    font-size: ${(props) => (props.large ? '1.3em' : '1.0em')};
    ${(props) => props.large && 'padding-top: 5px; padding-bottom: 5px;'}
    color: ${(props) => (props.viewed ? props.theme.color.certnGray600 : props.theme.color.certnGreen700)};
`;

export const Message = styled.div`
    word-break: break-word;
    font-size: ${(props) => (props.large ? '1.1em' : '0.9em')};
    ${(props) => props.large && 'margin-bottom: 10px;'}
`;

export const Date = styled.div`
    font-weight: 500;
    font-size: ${(props) => (props.large ? '1.0em' : '0.7em')};
`;

export const ButtonRow = styled.div`
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 100%;
    background: ${({ theme }) => theme.color.certnWhite};
`;
