// Libraries
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

// Components
import { ErrorAlertCustom } from 'certn-ui/ErrorAlert';
import { ReferenceForm, Base } from './components';

// Modules
import withNavigation from 'views/welcome/modules/WithNavigation';
import withSettingsChecker from 'views/welcome/modules/WithSettingsChecker';

// Actions & Selectors
import { patchInformation, setTrackPageOrder } from 'views/welcome/WelcomeActions';
import {
    getIsFetchingSilent,
    getInformation,
    getPersonalReferences,
    getOnboardingType,
    getSettings,
    getApplicant,
    getApplicantAccount,
} from 'views/welcome/WelcomeSelectors';

const mapStateToProps = (state) => ({
    isFetchingSilent: getIsFetchingSilent(state),
    information: getInformation(state),
    personalReferences: getPersonalReferences(state),
    onboardingType: getOnboardingType(state),
    settings: getSettings(state),
    applicant: getApplicant(state),
    applicantAccount: getApplicantAccount(state),
});

const mapDispatchToProps = (dispatch) => ({
    dispatch,
    ...bindActionCreators(
        {
            patchInformation,
            setTrackPageOrder,
        },
        dispatch
    ),
});

const propTypes = {
    // Redux Actions:
    patchInformation: PropTypes.func.isRequired,
    setTrackPageOrder: PropTypes.func.isRequired,
    // Redux Store:
    isFetchingSilent: PropTypes.bool.isRequired,
    information: PropTypes.object.isRequired,
    settings: PropTypes.object.isRequired,
    onboardingType: PropTypes.string,
    personalReferences: PropTypes.array,
};
const defaultProps = {
    personalReferences: [],
    onboardingType: 'PM',
};

class Personal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentReferenceIndex: 0,
        };
        props.setTrackPageOrder(['base', 'reference']);
        window.scrollTo(0, 0);
    }

    // How each of the pages patches the module in parts
    handleSubmit = (e, validateFields) => {
        e.preventDefault();
        const { handleForward, information, applicantAccount } = this.props; /* WithNavigation */
        const applicantEmail = applicantAccount && applicantAccount.email;
        const applicantPhone = information.phone_number && parsePhoneNumberFromString(information.phone_number, 'CA');

        validateFields((err, values) => {
            let newValues = { ...values };
            let newErr = err;
            const phone = newValues.phone_number;
            if (phone) {
                const phoneNumber = phone && parsePhoneNumberFromString(phone, 'CA');
                if (phoneNumber.nationalNumber === applicantPhone?.nationalNumber) {
                    ErrorAlertCustom({
                        description: 'Reference phone number must be different from applicant phone number.',
                    });
                    newErr = true;
                }
            }

            const { email } = newValues;
            if (email && email === applicantEmail) {
                ErrorAlertCustom({
                    description: 'Reference email must be different from applicant email.',
                });
                newErr = true;
            }

            if (!newErr) {
                const { personalReferences } = this.props;
                const { currentReferenceIndex } = this.state;

                // Replace the values at the current index with values -- or if new, add new entry
                newValues = {
                    ...newValues,
                    name: {
                        first_name: values.first_name,
                        last_name: values.last_name,
                    },
                    phone_number: {
                        number: values.phone_number,
                    },
                    email: {
                        address: values.email,
                    },
                    contact: {
                        can_contact: true,
                        is_reference: true,
                        type: 'PERSONAL',
                    },
                    association: values.association,
                };
                // EDIT: Grab existing entry data at index, then add new values from form
                const patchValues = personalReferences;
                if (patchValues[currentReferenceIndex]) {
                    patchValues[currentReferenceIndex] = {
                        ...patchValues[currentReferenceIndex],
                        ...newValues,
                    };
                } else patchValues.push(newValues);

                this.props.patchInformation({ personal_references: patchValues }).then(handleForward);
            } else {
                // provide failure error?
            }
        });
    };

    deleteReference = (personalReferenceIndex) => {
        const newReferences = this.props.personalReferences;
        newReferences.splice(personalReferenceIndex, 1);
        this.props.patchInformation({ personal_references: newReferences });
    };

    // Provide an index to personal reference array if you want to edit an existing entry
    addOrEditReference = (personalReferenceIndex = null) => {
        const { handleForward } = this.props; /* WithNavigation */

        let newReferenceIndex = personalReferenceIndex;
        if (personalReferenceIndex === null) {
            newReferenceIndex = this.props.personalReferences && this.props.personalReferences.length;
        }
        this.setState({ currentReferenceIndex: newReferenceIndex }, handleForward);
    };

    pages = () => {
        const { information, applicantAccount } = this.props;
        const applicantPhone = information.phone_number && parsePhoneNumberFromString(information.phone_number, 'CA');
        const applicantEmail = applicantAccount && applicantAccount.email;

        return {
            base: (
                <Base
                    handleSubmit={this.props.runSettingsCheck}
                    isFetchingSilent={this.props.isFetchingSilent}
                    personalReferences={this.props.personalReferences}
                    addOrEditReference={this.addOrEditReference}
                    deleteReference={this.deleteReference}
                    setValue={this.setValue}
                    nextTrack={this.props.handleNextTrack}
                />
            ),
            reference: (
                <ReferenceForm
                    handleSubmit={this.handleSubmit}
                    isFetchingSilent={this.props.isFetchingSilent}
                    personalReferences={this.props.personalReferences}
                    currentReferenceIndex={this.state.currentReferenceIndex}
                    returnToBase={this.props.returnToBase}
                    applicantPhone={applicantPhone}
                    applicantEmail={applicantEmail}
                />
            ),
        };
    };

    render() {
        return this.pages()[this.props.trackPageLocation];
    }
}

Personal.propTypes = propTypes;
Personal.defaultProps = defaultProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withNavigation(withSettingsChecker(Personal))));
