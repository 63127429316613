// Libraries
import styled from 'styled-components/macro';

export const FlexRow = styled.div<{ flex?: string | number }>`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex: ${(props) => props.flex || 1};
`;

export const GridRow = styled.div<{ gridItems: number }>`
    display: grid;
    grid-template-columns: repeat(${(props) => props.gridItems}, 1fr);
`;

export const FlexCol = styled.div`
    flex-direction: column;
`;

export const SummaryContainer = styled.div`
    width: 800px;
    @media (max-width: ${({ theme }) => theme.width.tablet}) {
        width: 100%;
    }
    border: 1px solid ${({ theme }) => theme.color.certnGray400};
    border-radius: 3px;
    margin-bottom: 40px;
`;

export const RequestReportWrapper = styled.div`
    width: 600px;
    @media (max-width: ${({ theme }) => theme.width.tablet}) {
        width: 90%;
    }
    border-radius: 3px;
    margin-bottom: 15px;
`;

export const LineBreak = styled.div`
    width: 100%;
    border-top: 1px solid ${({ theme }) => theme.color.certnGray400};
`;

export const SummarySubHeader = styled.div`
    font-size: 18px;
    text-align: left;
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 15px;
`;

export const SummarySubSection = styled.div`
    /* margin-bottom: 20px; */
    padding-bottom: 10px;
`;

export const SummarySection = styled.div`
    width: 100%;
    padding: 25px;
`;

export const SubTitleWrapper = styled.div`
    font-size: 14px;
    font-weight: lighter;
    opacity: 0.6;
    font-size: 14px;
    text-align: left;
    letter-spacing: 1px;
    margin: 0px 12px;
    text-transform: uppercase;
`;
