// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import ReportActivityLog from './ReportActivityLog';
import ReportAdjudication from './ReportAdjudication';

// Selectors
import { getCountry } from 'base/BaseSelectors';

const BottomPanel = styled.div`
    display: flex;
    justify-content: space-between;
    > :not(:last-child) {
        margin-right: 20px;
    }
`;

const ReportBottomPanel = ({ activityLog, ...rest }) => {
    const isUS = useSelector((state) => getCountry(state)) === 'US';
    return (
        <BottomPanel>
            <ReportActivityLog activityLog={activityLog.filter((log) => log.status)} />
            {isUS && <ReportAdjudication {...rest} />}
        </BottomPanel>
    );
};

ReportBottomPanel.propTypes = {
    activityLog: PropTypes.array,
};

ReportBottomPanel.defaultProps = {
    activityLog: [],
};

export default ReportBottomPanel;
