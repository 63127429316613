// Libraries
import React from 'react';
import Auth from 'modules/Auth';
// import { injectIntl, FormattedMessage } from 'react-intl';
import { useIntl, FormattedMessage } from 'react-intl';

// Components
import Form from 'certnd/Form';
import Typography from 'certnd/Typography';

const { Heading } = Typography;

function ApplicationExpirationSettings(props) {
    const intl = useIntl();
    const { form, settings } = props;

    const canEdit = Auth.isPermissionLevel('manager');

    return (
        <Form.Block>
            <Heading.H3>
                <FormattedMessage
                    id="abc69.ApplicationExpirationSettings.Title"
                    defaultMessage="Unresponsive Applications"
                />
            </Heading.H3>
            <Form.Checkbox
                form={form}
                fieldName="unresponsive_can_expire_applications"
                title={intl.formatMessage({
                    id: 'abc69.ApplicationExpirationSettings.expireBoolean',
                    defaultMessage: 'Unresponsive Applications Will Expire',
                })}
                description={intl.formatMessage({
                    id: 'abc69.ApplicationExpirationSettings.expireBooleanDescription',
                    defaultMessage:
                        'Check this box if you want your applications to expire after a certain number of days.',
                })}
                options={{ initialValue: settings.unresponsive_can_expire_applications }}
                disabled={!canEdit}
                hideBorder
            />
            <Form.InputNumber
                form={form}
                fieldName="unresponsive_applications_num_days"
                title={intl.formatMessage({
                    id: 'abc69.ApplicationExpirationSettings.numDaysApplicationExpire',
                    defaultMessage: 'Set application expiration time (days).',
                })}
                description={intl.formatMessage({
                    id: 'abc69.ApplicationExpirationSettings.numDaysApplicationExpireDescription',
                    defaultMessage:
                        'An application must be submitted by an applicant after the selected number of days otherwise the application will expire.',
                })}
                min={1}
                max={14}
                options={{ initialValue: settings.unresponsive_applications_num_days }}
                disabled={!canEdit}
                defaultValue={7}
                addTopMargin
            />
            <Form.Checkbox
                form={form}
                fieldName="unresponsive_can_send_expiry_notification_emails"
                title={intl.formatMessage({
                    id: 'abc69.ApplicationExpirationSettings.emailExpiryNotice',
                    defaultMessage: 'Email Notifications',
                })}
                description={intl.formatMessage({
                    id: 'abc69.ApplicationExpirationSettings.emailExpiryNoticeDescription',
                    defaultMessage: 'Get notified by email when applications become unresponsive.',
                })}
                options={{ initialValue: settings.unresponsive_can_send_expiry_notification_emails }}
                disabled={!canEdit}
            />
            <Form.Checkbox
                form={form}
                fieldName="unresponsive_can_send_expiry_notification_dashboard"
                title={intl.formatMessage({
                    id: 'abc69.ApplicationExpirationSettings.appExpiryNotice',
                    defaultMessage: 'Dashboard Notifications',
                })}
                description={intl.formatMessage({
                    id: 'abc69.ApplicationExpirationSettings.appExpiryNoticeDescription',
                    defaultMessage:
                        'Receive notification on your dashboard when unresponsive applications expire. After following up with applicant you send a new application or delete.',
                })}
                options={{ initialValue: settings.unresponsive_can_send_expiry_notification_dashboard }}
                disabled={!canEdit}
            />
            <Heading.H3>
                <FormattedMessage
                    id="abc69.ApplicationExpirationSettings.TitleUnresponsiveReferences"
                    defaultMessage="Unresponsive References"
                />
            </Heading.H3>
            <Form.Checkbox
                form={form}
                fieldName="unresponsive_can_expire_email_refs"
                title={intl.formatMessage({
                    id: 'abc69.ApplicationExpirationSettings.expireBooleanEmailRef',
                    defaultMessage: 'Unresponsive Email References Will Expire',
                })}
                description={intl.formatMessage({
                    id: 'abc69.ApplicationExpirationSettings.expireEmailReferencesBooleanDescription',
                    defaultMessage:
                        "If a reference does not respond within the time limit the application will move into a 'Complete' state but the reference section of the report will be empty.",
                })}
                options={{ initialValue: settings.unresponsive_can_expire_email_refs }}
                hideBorder
                disabled={!canEdit}
            />
            <Form.InputNumber
                form={form}
                fieldName="unresponsive_email_refs_num_days"
                title={intl.formatMessage({
                    id: 'abc69.ApplicationExpirationSettings.numDaysEmailRefExpire',
                    defaultMessage: 'Set number of days until an email reference will expire (days).',
                })}
                description={intl.formatMessage({
                    id: 'abc69.ApplicationExpirationSettings.numDaysEmailRefExpireDescription',
                    defaultMessage:
                        'Set a specific number of days you are willing to wait before determining a reference as unresponsive. The window starts when the applicant submits their application.',
                })}
                min={1}
                max={14}
                options={{ initialValue: settings.unresponsive_email_refs_num_days }}
                disabled={!canEdit}
                defaultValue={7}
                addTopMargin
            />
            {/* <Form.Checkbox
                form={form}
                fieldName="unresponsive_email_refs_daily_reminder"
                title={intl.formatMessage({
                    id: 'abc69.ApplicationExpirationSettings.emailRefReminder',
                    defaultMessage: 'Send daily reminder to reference',
                })}
                description={intl.formatMessage({
                    id: 'abc69.ApplicationExpirationSettings.emailRefReminderDescription',
                    defaultMessage:
                        'Each reference will receive daily reminders to provide information about the applicant.',
                })}
                options={{ initialValue: settings.unresponsive_email_refs_daily_reminder }}
                disabled={!canEdit}
            />
            <Form.Checkbox
                form={form}
                fieldName="unresponsive_email_refs_48hr_reminder"
                title={intl.formatMessage({
                    id: 'abc69.ApplicationExpirationSettings.emailRef48Reminder',
                    defaultMessage: 'Send reminder to reference ever 48hrs',
                })}
                description={intl.formatMessage({
                    id: 'abc69.ApplicationExpirationSettings.emailRef48ReminderDescription',
                    defaultMessage:
                        'Each reference will receive daily reminders to provide information about the applicant.',
                })}
                options={{ initialValue: settings.unresponsive_email_refs_48hr_reminder }}
                disabled={!canEdit}
            /> */}
            <Form.Button disabled={!canEdit}>
                <FormattedMessage id="common.save" defaultMessage="Save" />
            </Form.Button>
        </Form.Block>
    );
}

export default ApplicationExpirationSettings;
