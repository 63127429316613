// Libraries
import React from 'react';
import { get } from 'lodash';
import { FormattedMessage, injectIntl } from 'react-intl';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import { Input, Popover, Select } from 'antd';
import { useSelector } from 'react-redux';

// Components
import Title from 'certn-ui/Title';
import { AppearRight } from 'certn-ui/Animate';
import FormLayout from 'certn-form/FormLayout';
import { TrackNavigation } from 'views/welcome/components';
import AddressAutoComplete from 'certn-form/AddressAutoComplete';

// Actions & Selectors
import {
    getPrepopulatedFields,
    getIsUkCheck,
    getIsUkRtwCheck,
    getIsUkDbsOrDsCheck,
    getEnhancedIdentity,
    getIsSolelyUkCheck,
} from 'views/welcome/WelcomeSelectors';

// Modules
import { Regex } from 'modules';
import DateInput from 'certn-form/DateInput';
import { Label } from 'certn-form/Label';
import Checkbox from 'antd/es/checkbox/Checkbox';
import { getEndDateBeforeStartDateError } from 'modules/validation';
import { useFlags } from 'launchdarkly-react-client-sdk';

const AddressForm = ({
    form: { getFieldDecorator, validateFields, setFields, getFieldsValue },
    form,
    handleSubmit,
    addresses,
    currentAddressIndex,
    onboardingType,
    intl,
    requiredYears,
    showDateFields,
    country,
}) => {
    const missingCurrentAddress = () => !addresses?.some((item) => item.current);
    const prepopulatedFields = useSelector(getPrepopulatedFields);
    const isCurrentAddressPrepopulated = prepopulatedFields.includes('address');
    const isCurrentAddress = get(addresses, [currentAddressIndex, 'current']);
    const isSolelyUkCheck = useSelector(getIsSolelyUkCheck);
    const isUkCheck = useSelector(getIsUkCheck);
    const isUkDbsOrDsCheck = useSelector(getIsUkDbsOrDsCheck);
    const isUkRtwCheck = useSelector(getIsUkRtwCheck);
    const enhancedID = useSelector(getEnhancedIdentity);

    const { webFeatureRequireEndDateOnAddressIfDateInputVisible } = useFlags();

    const showCurrentAddressDropdown =
        (missingCurrentAddress() && requiredYears === 0 && !isUkRtwCheck) || isUkDbsOrDsCheck;

    const showCurrentAddressCheckBox = showDateFields && (missingCurrentAddress() || isCurrentAddress);

    return (
        <AppearRight>
            <Title>
                <FormattedMessage id="welcome.Addresses.addressDetails" defaultMessage="Address details" />
            </Title>
            <Form layout="horizontal" onSubmit={(e) => handleSubmit(e, validateFields, setFields)}>
                {onboardingType === 'PM' && (
                    <FormLayout>
                        <Form.Item hasFeedback key="rent_or_own">
                            {getFieldDecorator('rent_or_own', {
                                initialValue: get(addresses, [currentAddressIndex, 'rent_or_own']),
                                rules: [
                                    {
                                        required: true,
                                        message: intl.formatMessage({
                                            id: 'error.validation.notBlank',
                                            defaultMessage: 'Please do not leave blank',
                                        }),
                                    },
                                ],
                            })(
                                <Select
                                    size="large"
                                    placeholder={intl.formatMessage({
                                        id: 'welcome.Addresses.rentOrOwn',
                                        defaultMessage: 'Rent or Own?',
                                    })}
                                >
                                    <Select.Option value="R">
                                        <FormattedMessage id="welcome.Addresses.rent" defaultMessage="Rent" />
                                    </Select.Option>
                                    <Select.Option value="O">
                                        <FormattedMessage id="welcome.Addresses.own" defaultMessage="Own" />
                                    </Select.Option>
                                    <Select.Option value="S">
                                        <FormattedMessage
                                            id="welcome.Addresses.stayingWithFriendsFamily"
                                            defaultMessage="Staying with Family/Friends"
                                        />
                                    </Select.Option>
                                </Select>
                            )}
                        </Form.Item>
                        <Form.Item hasFeedback key="cost">
                            {getFieldDecorator('cost', {
                                initialValue: get(addresses, [currentAddressIndex, 'cost']),
                                rules: [
                                    {
                                        pattern: Regex.dollars,
                                        message: intl.formatMessage({
                                            id: 'error.validation.dollars',
                                            defaultMessage: 'Please enter a valid dollar amount',
                                        }),
                                    },
                                    {
                                        required: true,
                                        message: intl.formatMessage({
                                            id: 'error.validation.notBlank',
                                            defaultMessage: 'Please do not leave blank',
                                        }),
                                    },
                                ],
                            })(
                                <Input
                                    size="large"
                                    placeholder={intl.formatMessage({
                                        id: 'welcome.Addresses.rentMortgage',
                                        defaultMessage: 'Rent/Mortgage $',
                                    })}
                                    addonBefore={
                                        <Popover
                                            content={intl.formatMessage({
                                                id: 'welcome.Addresses.rentMortgage',
                                                defaultMessage: 'Rent/Mortgage $',
                                            })}
                                        >
                                            <InfoCircleOutlined />
                                        </Popover>
                                    }
                                    type="number"
                                />
                            )}
                        </Form.Item>
                    </FormLayout>
                )}
                <FormLayout>
                    <Label>
                        <FormattedMessage id="welcome.Addresses.addressLabel" defaultMessage="Address" />
                    </Label>
                    <AddressAutoComplete
                        getFieldDecorator={getFieldDecorator}
                        form={form}
                        currentAddress={addresses[currentAddressIndex]}
                        hideLabels
                        isUkCheck={isUkCheck}
                        isSolelyUkCheck={isSolelyUkCheck}
                        disabled={isCurrentAddressPrepopulated && isCurrentAddress}
                        oneID={isCurrentAddressPrepopulated && isCurrentAddress ? enhancedID.address : undefined}
                    />
                </FormLayout>
                {showCurrentAddressDropdown ? (
                    <FormLayout>
                        <Form.Item hasFeedback key="current">
                            {getFieldDecorator('current', {
                                initialValue:
                                    isCurrentAddress !== undefined ? isCurrentAddress : missingCurrentAddress(),
                                rules: [
                                    {
                                        required: true,
                                        message: intl.formatMessage({
                                            id: 'error.validation.notBlank',
                                            defaultMessage: 'Please do not leave blank',
                                        }),
                                    },
                                ],
                            })(
                                <Select
                                    size="large"
                                    placeholder={intl.formatMessage({
                                        id: 'welcome.Addresses.currentAddress',
                                        defaultMessage: 'Current Address',
                                    })}
                                    disabled={isCurrentAddressPrepopulated}
                                    data-testid="current_or_past_address_dropdown"
                                >
                                    <Select.Option value>
                                        <FormattedMessage
                                            id="welcome.Addresses.currentAddress"
                                            defaultMessage="Current Address"
                                        />
                                    </Select.Option>
                                    <Select.Option value={false}>
                                        <FormattedMessage
                                            id="welcome.Addresses.pastAddress"
                                            defaultMessage="Past Address"
                                        />
                                    </Select.Option>
                                </Select>
                            )}
                        </Form.Item>
                    </FormLayout>
                ) : undefined}
                {onboardingType === 'PM' && (
                    <FormLayout>
                        {!getFieldsValue().current && (
                            <Form.Item hasFeedback key="reason_for_leaving">
                                {getFieldDecorator('reason_for_leaving', {
                                    initialValue:
                                        get(addresses, [currentAddressIndex, 'reason_for_leaving']) !== 'N'
                                            ? get(addresses, [currentAddressIndex, 'reason_for_leaving'])
                                            : undefined,
                                    rules: [
                                        {
                                            pattern: Regex.alphaNumericExtra,
                                            message: intl.formatMessage({
                                                id: 'error.validation.alphaNumericExtra',
                                                defaultMessage:
                                                    'Please use only numbers, letters, spaces, dashes, and underscores',
                                            }),
                                        },
                                    ],
                                })(
                                    <Select
                                        placeholder={intl.formatMessage({
                                            id: 'welcome.Addresses.reasonForLeaving',
                                            defaultMessage: 'Reason for leaving',
                                        })}
                                        size="large"
                                    >
                                        <Select.Option value="BN">
                                            <FormattedMessage
                                                id="welcome.Addresses.biggerNicer"
                                                defaultMessage="Looking for bigger or nicer location"
                                            />
                                        </Select.Option>
                                        <Select.Option value="S">
                                            <FormattedMessage id="welcome.Addresses.school" defaultMessage="School" />
                                        </Select.Option>
                                        <Select.Option value="CS">
                                            <FormattedMessage
                                                id="welcome.Addresses.changeOfScenery"
                                                defaultMessage="Looking for change of scenery"
                                            />
                                        </Select.Option>
                                        <Select.Option value="CJ">
                                            <FormattedMessage
                                                id="welcome.Addresses.changeOfJob"
                                                defaultMessage="Change of job"
                                            />
                                        </Select.Option>
                                        <Select.Option value="CJL">
                                            <FormattedMessage
                                                id="welcome.Addresses.changeOfJobLocation"
                                                defaultMessage="Change of job location"
                                            />
                                        </Select.Option>
                                        <Select.Option value="CFRS">
                                            <FormattedMessage
                                                id="welcome.Addresses.changeInFamilyStatus"
                                                defaultMessage="Change in family or relationship status"
                                            />
                                        </Select.Option>
                                        <Select.Option value="SP">
                                            <FormattedMessage
                                                id="welcome.Addresses.saleOfProperty"
                                                defaultMessage="Sale of property"
                                            />
                                        </Select.Option>
                                        <Select.Option value="E">
                                            <FormattedMessage id="welcome.Addresses.evicted" defaultMessage="Evicted" />
                                        </Select.Option>
                                        <Select.Option value="M">
                                            <FormattedMessage
                                                id="welcome.Addresses.movedOutOfHome"
                                                defaultMessage="Moved out of home"
                                            />
                                        </Select.Option>
                                        <Select.Option value="O">
                                            <FormattedMessage id="common.other" defaultMessage="Other" />
                                        </Select.Option>
                                    </Select>
                                )}
                            </Form.Item>
                        )}
                    </FormLayout>
                )}
                {showDateFields ? (
                    <FormLayout>
                        <Label>
                            <FormattedMessage id="welcome.AddressesForm.startDate" defaultMessage="Start date" />
                        </Label>
                        <Form.Item hasFeedback key="start_date">
                            {getFieldDecorator('start_date', {
                                initialValue: get(addresses, [currentAddressIndex, 'start_date']),
                                rules: [
                                    {
                                        required: true,
                                        message: intl.formatMessage({
                                            id: 'error.validation.notBlank',
                                            defaultMessage: 'Please do not leave blank',
                                        }),
                                    },
                                    {
                                        validator: (rule, value, callback) => {
                                            if (getFieldsValue().end_date) {
                                                const isEndDateBeforeStartDate = getEndDateBeforeStartDateError(
                                                    getFieldsValue().end_date,
                                                    value,
                                                    'YYYY-MM-DD'
                                                );
                                                callback(isEndDateBeforeStartDate);
                                            } else {
                                                callback(undefined);
                                            }
                                        },
                                    },
                                ],
                            })(
                                <DateInput
                                    disableFutureDates
                                    size="large"
                                    placeholder={intl.formatMessage({
                                        id: 'form.label.startDate',
                                        defaultMessage: 'Start Date',
                                    })}
                                    addonBeforeUsePlaceholder
                                    country={country}
                                />
                            )}
                        </Form.Item>
                        {showCurrentAddressCheckBox && (
                            <Form.Item
                                key="current"
                                style={{ textAlign: 'left', marginTop: '-20px', marginBottom: '0px' }}
                            >
                                {getFieldDecorator('current', {
                                    initialValue: isCurrentAddress || false,
                                    rules: [
                                        {
                                            validator: (rule, value, callback) => {
                                                if (
                                                    getFieldsValue().current &&
                                                    addresses.find((a, i) => a.current && i !== currentAddressIndex)
                                                ) {
                                                    callback(
                                                        intl.formatMessage({
                                                            id: 'settingsChecker.hr_tenancy_years_amount_req_max',
                                                            defaultMessage:
                                                                'You may not add more than 1 current address',
                                                        })
                                                    );
                                                } else {
                                                    callback(undefined);
                                                }
                                            },
                                        },
                                        {
                                            required: true,
                                            message: intl.formatMessage({
                                                id: 'error.validation.notBlank',
                                                defaultMessage: 'Please do not leave blank',
                                            }),
                                        },
                                    ],
                                })(
                                    <Checkbox defaultChecked={isCurrentAddress || false}>
                                        {intl.formatMessage({
                                            id: 'welcome.AddressesForm.currentAddressCheckbox',
                                            defaultMessage: 'I currently live at this address',
                                        })}
                                    </Checkbox>
                                )}
                            </Form.Item>
                        )}
                        {!getFieldsValue().current && (
                            <>
                                <Label>
                                    <FormattedMessage id="welcome.AddressesForm.endDate" defaultMessage="End date" />
                                </Label>
                                <Form.Item hasFeedback key="end_date">
                                    {getFieldDecorator('end_date', {
                                        initialValue: get(addresses, [currentAddressIndex, 'end_date']),
                                        rules: [
                                            {
                                                validator: (rule, value, callback) => {
                                                    if (getFieldsValue().start_date) {
                                                        const isEndDateLessThanStartDate = getEndDateBeforeStartDateError(
                                                            value,
                                                            getFieldsValue().start_date,
                                                            'YYYY-MM-DD'
                                                        );
                                                        callback(isEndDateLessThanStartDate);
                                                    } else {
                                                        callback(undefined);
                                                    }
                                                },
                                            },
                                            {
                                                required:
                                                    requiredYears > 0 ||
                                                    webFeatureRequireEndDateOnAddressIfDateInputVisible,
                                                message: intl.formatMessage({
                                                    id: 'error.validation.notBlank',
                                                    defaultMessage: 'Please do not leave blank',
                                                }),
                                            },
                                        ],
                                    })(
                                        <DateInput
                                            size="large"
                                            disableFutureDates
                                            placeholder={intl.formatMessage({
                                                id: 'form.label.endDate',
                                                defaultMessage: 'End Date',
                                            })}
                                            addonBeforeUsePlaceholder
                                            country={country}
                                        />
                                    )}
                                </Form.Item>
                            </>
                        )}
                    </FormLayout>
                ) : undefined}
                <TrackNavigation />
            </Form>
        </AppearRight>
    );
};

export default Form.create()(injectIntl(AddressForm));
