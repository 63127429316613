// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// Modules
import { CopyToClipboard, Format } from 'modules';

// Table Components
import { CertnTable } from 'views/manager/components';
import ListingsCell from './ListingsCell';
import ActionsCell from './ActionsCell';
import InfoCell from './InfoCell';
import ApplicantsCell from './ApplicantsCell';

// Actions & Selectors
import { setOrdering, fetchProperties, setPageSize } from 'views/manager/views/pm/views/properties/PropertiesActions';
import {
    getIsFetching,
    getOrdering,
    getReverseOrder,
    getCurrentPage,
    getPageSize,
    getNumProperties,
} from 'views/manager/views/pm/views/properties/PropertiesSelectors';
import { getIsFetchingApplications } from 'views/manager/views/pm/views/applications/ApplicationsSelectors';
import { getUserMode, getUser } from 'base/BaseSelectors';

const mapStateToProps = (state) => ({
    userMode: getUserMode(state),
    ordering: getOrdering(state),
    reverseOrder: getReverseOrder(state),
    currentPage: getCurrentPage(state),
    pageSize: getPageSize(state),
    numProperties: getNumProperties(state),
    isFetching: getIsFetching(state),
    isFetchingApplications: getIsFetchingApplications(state),
    user: getUser(state),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setOrdering,
            fetchProperties,
            setPageSize,
        },
        dispatch
    );

const propTypes = {
    data: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    ordering: PropTypes.string.isRequired,
    setOrdering: PropTypes.func.isRequired,
    reverseOrder: PropTypes.bool.isRequired,
    setPageSize: PropTypes.func.isRequired,
    currentPage: PropTypes.number.isRequired,
    isFetchingApplications: PropTypes.bool,
    pageSize: PropTypes.number.isRequired,
};

const defaultProps = {
    isFetchingApplications: false,
};

class Table extends React.Component {
    columns = {
        desktop: ['Updated', 'Name', 'Code', 'Address', 'City', 'Listings', 'Applicants', 'Actions'],
        mobile: ['Info', 'Actions'],
        Updated: {
            active: 'modified',
            renderUi: ({ record }) => Format.date(record.modified),
        },
        Name: {
            active: 'building',
            renderUi: ({ record }) => record.building,
        },
        Code: {
            active: 'building_code',
            renderUi: ({ record }) => record.building_code,
        },
        Address: {
            active: 'property__address',
            renderUi: ({ record }) => (record.property && record.property.address) || record.address,
        },
        City: {
            active: 'city',
            renderUi: ({ record }) => (record.address && record.address.city) || record.city,
        },
        Applicants: {
            customHeader: ({ data, title }) => (
                <ApplicantsCell.Header
                    data={data}
                    title={title}
                    reverseOrder={this.props.reverseOrder}
                    setOrdering={this.props.setOrdering}
                    ordering={this.props.ordering}
                />
            ),
            renderUi: ({ record }) => <ApplicantsCell record={record} copyToClipboard={CopyToClipboard} />,
        },
        Actions: {
            renderUi: ({ record }) => <ActionsCell record={record} />,
        },
        Listings: {
            active: 'listing_count',
            renderUi: ({ record }) => <ListingsCell record={record} />,
        },
        Info: {
            active: 'modified',
            renderUi: ({ record }) => <InfoCell record={record} />,
        },
    };

    render() {
        const {
            data,
            isFetching,
            isFetchingApplications,
            ordering,
            reverseOrder,
            numProperties,
            pageSize,
            currentPage,
        } = this.props;
        const desktop = window.matchMedia('(min-width: 1024px)').matches;
        const display = desktop ? 'desktop' : 'mobile';
        const paginationConfig = desktop
            ? {
                  total: numProperties,
                  showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                  pageSize,
                  pageSizeOptions: ['7', '25', '50'],
                  defaultCurrent: currentPage,
                  current: currentPage,
                  showSizeChanger: true,
                  showQuickJumper: true,
                  onShowSizeChange: (current, size) => this.props.setPageSize(size),
                  onChange: (page) => this.props.fetchProperties(page),
              }
            : {
                  simple: true,
                  total: numProperties,
                  pageSize,
                  defaultCurrent: currentPage,
                  current: currentPage,
                  onChange: (page) => this.props.fetchProperties(page),
              };

        return (
            <CertnTable
                dataSource={data}
                loading={isFetching || isFetchingApplications}
                pagination={paginationConfig}
                columns={this.columns}
                display={display}
                setOrdering={this.props.setOrdering}
                ordering={ordering}
                reverseOrder={reverseOrder}
            />
        );
    }
}

Table.propTypes = propTypes;
Table.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(Table);
