// Libraries
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from 'styles/styles';

const MailVerified = (props) => (
    <svg width={234} height={155} {...props}>
        <title>
            <FormattedMessage id="welcome.Submit.emailVerifiedAlt" defaultMessage="Email Verified" />
        </title>
        <g fill="none" fillRule="evenodd">
            <g transform="translate(-21)">
                <circle fill={styles.color.certnGray100} cx={138} cy={92} r={92} />
                <circle fill={styles.color.certnGray200} cx={137.5} cy={92.5} r={57.5} />
            </g>
            <path
                d="M2 153h230"
                stroke={styles.color.certnGreen700}
                strokeWidth={3}
                strokeLinecap="round"
                strokeLinejoin="bevel"
            />
            <path
                d="M102.091 129.52h60.417c3.074 0 5.591-2.527 5.591-5.614V60.57c0-3.088-2.517-5.615-5.59-5.615H71.422c-3.075 0-5.593 2.527-5.593 5.615v12.639"
                fill={styles.color.certnWhite}
            />
            <path
                d="M148.838 129.52H71.421c-3.073 0-5.59-2.527-5.59-5.614V60.57c0-3.088 2.517-5.615 5.59-5.615h91.085c3.076 0 5.593 2.527 5.593 5.615v24.639"
                stroke={styles.color.certnGreen700}
                strokeWidth={3}
                fill={styles.color.certnWhite}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M92.165 98.977l-23.102 27.757M67.82 56.968l49.296 38.203L166 56.655"
                stroke={styles.color.certnGreen700}
                strokeWidth={3}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M195.21 112.71c0 15.304-12.36 27.709-27.61 27.709-15.238 0-27.6-12.405-27.6-27.708C140 97.407 152.364 85 167.6 85c15.25 0 27.61 12.407 27.61 27.71z"
                stroke={styles.color.certnGreen700}
                strokeWidth={3}
                fill={styles.color.certnGreen600}
            />
            <g stroke={styles.color.certnWhite} strokeLinecap="round" strokeLinejoin="round" strokeWidth={3}>
                <path d="M155.783 111.043l10.337 10.61 12.307-17.07" />
            </g>
        </g>
    </svg>
);

export default MailVerified;
