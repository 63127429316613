import React from 'react';
import { useIntl } from 'react-intl';
import { Select } from 'antd';
import { DropdownWrapper, Title, Wrapper } from '../styled';
import PropTypes from 'prop-types';
import { isCheckReturned } from '../utils';

const { Option } = Select;

const ApplicantAdjudicationChecks = ({ checkDisabled, applicantChecks, setApplicantChecks, statuses }) => {
    const intl = useIntl();
    const getResultDisplay = (obj) => (['NONE', 'REVIEW'].includes(obj.score_result) ? null : obj.score_result);

    const updateCheck = (value, id) => {
        setApplicantChecks(
            applicantChecks.map((check) => {
                if (check.id === id) return { ...check, score_result: value };
                return check;
            })
        );
    };

    return (
        <>
            {applicantChecks.map(
                (check, key) =>
                    isCheckReturned(check) && (
                        <DropdownWrapper key={key}>
                            <Title>{check.check_label}</Title>
                            <Wrapper>
                                <Select
                                    allowClear
                                    disabled={checkDisabled}
                                    placeholder={intl.formatMessage({
                                        id: '6cb79.AdjudicationSidebar.pleaseSelect',
                                        defaultMessage: 'Please Select',
                                    })}
                                    value={getResultDisplay(check)}
                                    onSelect={(value) => updateCheck(value, check.id)}
                                    onClear={() => updateCheck('NONE', check.id)}
                                >
                                    {Object.entries(statuses).map((status) => (
                                        <Option value={status[0]} key={status[0]}>
                                            {status[1]}
                                        </Option>
                                    ))}
                                </Select>
                            </Wrapper>
                        </DropdownWrapper>
                    )
            )}
        </>
    );
};

ApplicantAdjudicationChecks.propTypes = {
    setApplicantChecks: PropTypes.func.isRequired,
    applicantChecks: PropTypes.array.isRequired,
    checkDisabled: PropTypes.bool,
    statuses: PropTypes.array,
};

ApplicantAdjudicationChecks.defaultProps = { checkDisabled: false, statuses: [] };

export default ApplicantAdjudicationChecks;
