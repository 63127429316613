import { Alert } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isUSOrUSTerritory } from 'modules/Countries';

interface PropTypes {
    country: string;
    hasSoftcheck: boolean;
}
const SoftCheckAlert: React.FC<PropTypes> = ({ country, hasSoftcheck }) => {
    const { webFeatureBlockOrderingSoftcheckForUsApplicantsViaQuickscreen } = useFlags();

    return webFeatureBlockOrderingSoftcheckForUsApplicantsViaQuickscreen &&
        isUSOrUSTerritory(country) &&
        hasSoftcheck ? (
        <Alert
            type="error"
            message="Applicant Issue"
            description={
                <FormattedMessage
                    id="f5ca1.QuickScreen.SoftcheckAlert.SoftcheckUnavailable"
                    defaultMessage="The selected service, Softcheck, is unavailable for US applicants"
                />
            }
            showIcon
        />
    ) : null;
};

export default SoftCheckAlert;
