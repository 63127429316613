// Libraries
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Input, Form } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

// Components
import { RequiredText } from 'certn-ui';
import { intl } from 'components/GlobalProvider';
import {
    SectionWrapper,
    SectionTopBar,
    SectionBottomPanel,
    CustomInputFrame,
} from 'views/manager/features/ScreenApplicant/styled';
import { CountrySelect, getCountryInstitutions, InstitutionsDropdown } from 'components';

// Actions and Selectors
import { getSelectedCheckKeys } from 'views/manager/features/ScreenApplicant/ScreenApplicantSelectors';

// Modules
import isVisibleOrRequired from './isVisibleOrRequired';
import { isUSAOrCanada } from 'modules/Countries';

// Constants
import { CHECK_REQUEST } from 'base/BaseConstants';
import { InstitutionType } from 'types/institution';

interface PropTypes {
    form: FormInstance;
}

const SocialMedia: React.FC<PropTypes> = ({ form }) => {
    const selectedServices = useSelector(getSelectedCheckKeys);
    const [country, setCountry] = useState('');
    const [educationInstitutions, setEducationInstitutions] = useState({});
    const [showInstitutionSelect, setShowInstitutionSelect] = useState(false);

    const visibleList = [CHECK_REQUEST.SOCIAL_MEDIA];

    if (!isVisibleOrRequired(visibleList, selectedServices)) return null;

    const handleCountryChange = async (newCountry: string) => {
        setCountry(newCountry);

        if (!isUSAOrCanada(newCountry)) {
            setShowInstitutionSelect(false);
            return;
        }

        if (!(newCountry in educationInstitutions)) {
            getCountryInstitutions(setEducationInstitutions, educationInstitutions, newCountry);
        }

        setShowInstitutionSelect(true);
    };

    return (
        <SectionWrapper>
            <SectionTopBar>
                <FormattedMessage
                    id="f5ca1.QuickScreen.socialMediaHeader"
                    defaultMessage="Enter Your Candidate's Social Media Details"
                />
            </SectionTopBar>
            <SectionBottomPanel>
                <CustomInputFrame>
                    {intl.formatMessage({
                        id: 'f5ca1.QuickScreen.instagramLabel',
                        defaultMessage: 'Instagram (optional)',
                    })}
                    <Form.Item name="instagram_url">
                        <Input
                            placeholder={intl.formatMessage({
                                id: 'f5ca1.QuickScreen.instagramPlaceholder',
                                defaultMessage: 'Instagram Username or URL',
                            })}
                        />
                    </Form.Item>
                    {intl.formatMessage({
                        id: 'f5ca1.QuickScreen.facebookLabel',
                        defaultMessage: 'Facebook (optional)',
                    })}
                    <Form.Item name="facebook_url">
                        <Input
                            placeholder={intl.formatMessage({
                                id: 'f5ca1.QuickScreen.facebookPlaceholder',
                                defaultMessage: 'Facebook Username or URL',
                            })}
                        />
                    </Form.Item>
                    {intl.formatMessage({
                        id: 'f5ca1.QuickScreen.tiktokLabel',
                        defaultMessage: 'TikTok (optional)',
                    })}
                    <Form.Item name="tiktok_url">
                        <Input
                            placeholder={intl.formatMessage({
                                id: 'f5ca1.QuickScreen.tiktokPlaceholder',
                                defaultMessage: 'TikTok Username or URL',
                            })}
                        />
                    </Form.Item>
                    {intl.formatMessage({
                        id: 'f5ca1.QuickScreen.linkedinLabel',
                        defaultMessage: 'LinkedIn (optional)',
                    })}
                    <Form.Item name="linkedin_url">
                        <Input
                            placeholder={intl.formatMessage({
                                id: 'f5ca1.QuickScreen.linkedinPlaceholder',
                                defaultMessage: 'LinkedIn Username or URL',
                            })}
                        />
                    </Form.Item>
                    {intl.formatMessage({
                        id: 'f5ca1.QuickScreen.twitterLabel',
                        defaultMessage: 'Twitter (optional)',
                    })}
                    <Form.Item name="twitter_url">
                        <Input
                            placeholder={intl.formatMessage({
                                id: 'f5ca1.QuickScreen.twitterPlaceholder',
                                defaultMessage: 'Twitter Username or URL',
                            })}
                        />
                    </Form.Item>
                    {intl.formatMessage({
                        id: 'f5ca1.QuickScreen.redditLabel',
                        defaultMessage: 'Reddit (optional)',
                    })}
                    <Form.Item name="reddit_url">
                        <Input
                            placeholder={intl.formatMessage({
                                id: 'f5ca1.QuickScreen.redditPlaceholder',
                                defaultMessage: 'Reddit Username or URL',
                            })}
                        />
                    </Form.Item>
                    <RequiredText>
                        {intl.formatMessage({
                            id: 'f5ca1.QuickScreen.highSchoolLabel',
                            defaultMessage: 'High School',
                        })}
                    </RequiredText>
                    <Form.Item
                        name="high_school_name"
                        rules={[
                            {
                                required: true,
                                message: intl.formatMessage({
                                    id: 'error.validation.notBlank',
                                    defaultMessage: 'Please do not leave blank',
                                }),
                            },
                        ]}
                    >
                        <Input
                            placeholder={intl.formatMessage({
                                id: 'f5ca1.QuickScreen.highSchoolPlaceholder',
                                defaultMessage: 'Institution Name',
                            })}
                        />
                    </Form.Item>
                    {intl.formatMessage({
                        id: 'f5ca1.QuickScreen.postSecondaryLabel',
                        defaultMessage: 'Post Secondary (optional)',
                    })}
                    <Form.Item name="college_country">
                        <CountrySelect
                            size="medium"
                            placeholder={intl.formatMessage({
                                id: 'common.country',
                                defaultMessage: 'Country',
                            })}
                            onChange={handleCountryChange}
                        />
                    </Form.Item>
                    {country && (
                        <InstitutionsDropdown
                            size={'medium' as SizeType}
                            showInstitutionSelect={showInstitutionSelect}
                            form={form}
                            educationInstitutions={educationInstitutions}
                            setInstitution={(institution: InstitutionType) =>
                                form.setFieldsValue({ college_name: institution.value })
                            }
                            country={country}
                            fieldName="college_name"
                        />
                    )}
                </CustomInputFrame>
            </SectionBottomPanel>
        </SectionWrapper>
    );
};

export default SocialMedia;
