import styled from 'styled-components/macro';

export const EllipseOverlay = () => (
    <svg
        style={{ position: 'absolute', width: '100%', height: '100%', left: 0, top: 0 }}
        preserveAspectRatio="none"
        viewBox="0 0 100 100"
    >
        <path d="M0 0h100v100h-100z M28 44a22 36 0 1 1 0 0.0001 z" stroke="none" fill="#0008" fillRule="evenodd" />
        <path d="M28 44a22 36 0 1 1 0 0.0001 z" stroke="white" strokeWidth="0.5" fill="none" />
    </svg>
);

export const VideoButtons = styled.div`
    position: absolute;
    bottom: 6%;
    width: 100%;
`;

export const PermissionDeniedBox = styled.div`
    position: relative;
    background-color: #f2f2f2;
    width: 100%;
    height: 400px;
`;

export const PermissionDeniedMessage = styled.div`
    position: absolute;
    bottom: 50%;
    width: 100%;
    text-align: center;
`;
