// Libraries
import React from 'react';
import { FormattedMessage } from 'react-intl';

// Components
import Title from 'certn-ui/Title';
import Subtitle from 'views/welcome/components/Subtitle';

const EIDRejected = () => (
    <>
        <Title>
            <FormattedMessage
                id="welcome.Identity.eidRejectedTitle"
                defaultMessage="Identity verification was inconclusive."
            />
        </Title>
        <Subtitle>
            <FormattedMessage
                id="welcome.Identity.eidRejected2"
                defaultMessage="Equifax service is currently unavailable. Please have a piece of government ID ready for the next step of ID verification."
            />
        </Subtitle>
        <Subtitle>
            <FormattedMessage id="welcome.Identity.eidRejected" defaultMessage="Please hit next to begin OneID." />
        </Subtitle>
    </>
);

export default EIDRejected;
