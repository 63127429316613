import React from 'react';
import Auth from 'modules/Auth';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter, Redirect } from 'react-router-dom';
import { get } from 'lodash';

import { logout } from 'base/BaseActions';

const mapDispatchToProps = (dispatch) => bindActionCreators({ logout }, dispatch);

const Logout = (props) => {
    props.logout();
    Auth.deauthenticateUser();
    return (
        <Redirect
            to={{
                pathname: `/login`,
                state: {
                    from: get(props, ['location', 'state', 'from']),
                    sessionTimeout: get(props, ['location', 'state', 'sessionTimeout']),
                },
            }}
        />
    );
};

export default withRouter(connect(undefined, mapDispatchToProps)(Logout));
