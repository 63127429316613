import { FormattedMessage } from 'react-intl';
import { Modal } from 'antd';
import styled from 'styled-components';

const DiscUnorderedList = styled.ul`
    margin-top: 12px;
    margin-left: 24px;
    list-style-type: disc;
    list-style-position: outside;
`;

const content = {
    primary: (
        <>
            <DiscUnorderedList>
                <li>
                    <FormattedMessage
                        id="welcome.AustralianNPCDocuments.primaryDocumentBullet1"
                        defaultMessage="Foreign Passport (current) - name and photo required - 70 points"
                    />
                </li>
                <li>
                    <FormattedMessage
                        id="welcome.AustralianNPCDocuments.primaryDocumentBullet2"
                        defaultMessage="Australian Passport (current or expired within last 2 years but not cancelled) - name and photo required - 70 points"
                    />
                </li>
                <li>
                    <FormattedMessage
                        id="welcome.AustralianNPCDocuments.primaryDocumentBullet3"
                        defaultMessage="Australian Citizenship Certificate - name required - 70 points"
                    />
                </li>
                <li>
                    <FormattedMessage
                        id="welcome.AustralianNPCDocuments.primaryDocumentBullet4"
                        defaultMessage="Full Birth certificate (not birth certificate extract) - name required - 70 points"
                    />
                </li>
                <li>
                    <FormattedMessage
                        id="welcome.AustralianNPCDocuments.primaryDocumentBullet5"
                        defaultMessage="Certificate of Identity issued by the Australian Government to refugees and non Australian citizens for entry to Australia - name required - 70 points"
                    />
                </li>
                <li>
                    <FormattedMessage
                        id="welcome.AustralianNPCDocuments.primaryDocumentBullet6"
                        defaultMessage="Australian Driver Licence/Learner’s Permit - name, address, and photo required - 40 points"
                    />
                </li>
                <li>
                    <FormattedMessage
                        id="welcome.AustralianNPCDocuments.primaryDocumentBullet7"
                        defaultMessage="Current (Australian) Tertiary Student Identification Card - name and photo required - 40 points"
                    />
                </li>
                <li>
                    <FormattedMessage
                        id="welcome.AustralianNPCDocuments.primaryDocumentBullet8"
                        defaultMessage="Photo identification card issued for Australian regulatory purposes (e.g. Aviation/Maritime Security identification, security industry etc.) - name and photo required - 40 points"
                    />
                </li>
                <li>
                    <FormattedMessage
                        id="welcome.AustralianNPCDocuments.primaryDocumentBullet9"
                        defaultMessage="Government employee ID (Australian Federal/State/Territory) - name and photo required - 40 points"
                    />
                </li>
                <li>
                    <FormattedMessage
                        id="welcome.AustralianNPCDocuments.primaryDocumentBullet10"
                        defaultMessage="Defence Force Identity Card (with photo or signature) - name and photo required - 40 points"
                    />
                </li>
            </DiscUnorderedList>
        </>
    ),
    secondary: (
        <>
            <DiscUnorderedList>
                <li>
                    <FormattedMessage
                        id="welcome.AustralianNPCDocuments.secondaryDocumentBullet1"
                        defaultMessage="Department of Veterans Affairs (DVA) card - name and address required - 40 points"
                    />
                </li>
                <li>
                    <FormattedMessage
                        id="welcome.AustralianNPCDocuments.secondaryDocumentBullet2"
                        defaultMessage="Centrelink card (with reference number) - name and address required - 40 points"
                    />
                </li>
                <li>
                    <FormattedMessage
                        id="welcome.AustralianNPCDocuments.secondaryDocumentBullet3"
                        defaultMessage="Birth Certificate Extract - name is required - 25 points"
                    />
                </li>
                <li>
                    <FormattedMessage
                        id="welcome.AustralianNPCDocuments.secondaryDocumentBullet4"
                        defaultMessage="Birth card (NSW Births, Deaths, Marriages issue only) - name is required - 25 points"
                    />
                </li>
                <li>
                    <FormattedMessage
                        id="welcome.AustralianNPCDocuments.secondaryDocumentBullet5"
                        defaultMessage="Medicare card - name required - 25 points"
                    />
                </li>
                <li>
                    <FormattedMessage
                        id="welcome.AustralianNPCDocuments.secondaryDocumentBullet6"
                        defaultMessage="Credit card or account card - name required - 25 points"
                    />
                </li>
                <li>
                    <FormattedMessage
                        id="welcome.AustralianNPCDocuments.secondaryDocumentBullet7"
                        defaultMessage="Australian Marriage certificate (Australian Registry issue only) - 25 points"
                    />
                </li>
                <li>
                    <FormattedMessage
                        id="welcome.AustralianNPCDocuments.secondaryDocumentBullet8"
                        defaultMessage="Australian Marriage Certificate - name and signature required - 25 points"
                    />
                </li>
                <li>
                    <FormattedMessage
                        id="welcome.AustralianNPCDocuments.secondaryDocumentBullet9"
                        defaultMessage="Decree Nisi / Decree Absolute (Australian Registry issue only) - name and signature required - 25 points"
                    />
                </li>
                <li>
                    <FormattedMessage
                        id="welcome.AustralianNPCDocuments.secondaryDocumentBullet10"
                        defaultMessage="Change of name certificate (Australian Registry issue only) - name and signature required - 25 points"
                    />
                </li>
                <li>
                    <FormattedMessage
                        id="welcome.AustralianNPCDocuments.secondaryDocumentBullet11"
                        defaultMessage="Bank statement (showing transactions) - name and address required - 25 points"
                    />
                </li>
                <li>
                    <FormattedMessage
                        id="welcome.AustralianNPCDocuments.secondaryDocumentBullet12"
                        defaultMessage="Property lease agreement (current address) - name and address required - 25 points"
                    />
                </li>
                <li>
                    <FormattedMessage
                        id="welcome.AustralianNPCDocuments.secondaryDocumentBullet13"
                        defaultMessage="Taxation assessment notice - name and address required - 25 points"
                    />
                </li>
                <li>
                    <FormattedMessage
                        id="welcome.AustralianNPCDocuments.secondaryDocumentBullet14"
                        defaultMessage="Australian Mortgage Documents (Current address) - name and address required - 25 points"
                    />
                </li>
                <li>
                    <FormattedMessage
                        id="welcome.AustralianNPCDocuments.secondaryDocumentBullet15"
                        defaultMessage="Rating Authority - Current address eg Land Rates"
                    />
                </li>
                <li>
                    <FormattedMessage
                        id="welcome.AustralianNPCDocuments.secondaryDocumentBullet16"
                        defaultMessage="Utility Bill (electricity, gas, telephone) - Name and current address less than 12 months old - 20 points"
                    />
                </li>
                <li>
                    <FormattedMessage
                        id="welcome.AustralianNPCDocuments.secondaryDocumentBullet17"
                        defaultMessage="Reference from Indigenous Organisation - name and photo required - 20 points"
                    />
                </li>
                <li>
                    <FormattedMessage
                        id="welcome.AustralianNPCDocuments.secondaryDocumentBullet18"
                        defaultMessage="Documents issued outside Australia (equivalent to Australian documents). Must have official translation attached - name and photo required - 20 points"
                    />
                </li>
            </DiscUnorderedList>
        </>
    ),
};

const HELP_MODALS = {
    primary: {
        title: (
            <FormattedMessage id="welcome.AustralianNPCDocuments.primaryDocument" defaultMessage="Primary Document" />
        ),
        content: (
            <>
                <p>
                    <FormattedMessage
                        id="welcome.AustralianNPCDocuments.primaryDocumentDescription"
                        defaultMessage="The following primary documents are accepted:"
                    />
                </p>
                {content.primary}
            </>
        ),
    },
    secondary: {
        title: (
            <FormattedMessage
                id="welcome.AustralianNPCDocuments.secondaryDocument"
                defaultMessage="Secondary Document"
            />
        ),
        content: (
            <>
                <p>
                    <FormattedMessage
                        id="welcome.AustralianNPCDocuments.secondaryDocumentDescription"
                        defaultMessage="The following secondary documents are accepted:"
                    />
                </p>
                {content.secondary}
            </>
        ),
    },
};

type Props = {
    category: keyof typeof HELP_MODALS;
    isOpen: boolean;
    onClose: () => void;
};

const HelpModal = ({ category, isOpen, onClose }: Props) => {
    const { title, content: categoryContent } = HELP_MODALS[category];

    return (
        <Modal title={title} visible={isOpen} onOk={onClose} onCancel={onClose}>
            {categoryContent}
        </Modal>
    );
};

export default HelpModal;
