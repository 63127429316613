// Libraries
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { message } from 'antd';
import { injectIntl } from 'react-intl';

// Components
import { ErrorAlertCustom } from 'certn-ui/ErrorAlert';
import { PageOne, AddressDocumentUpload } from './components';

// Modules
import withNavigation from 'views/welcome/modules/WithNavigation';

// Actions & Selectors
import {
    setTrackPageOrder,
    patchInformation,
    fetchSignedS3Link,
    confirmUploadSuccessful,
    deleteDocument,
} from 'views/welcome/WelcomeActions';
import {
    getIsFetchingSilent,
    getInformation,
    getS3UploadLink,
    getS3UploadFields,
    getPreparedDocument,
    getDefaultFileList,
    getOnboardingType,
    getSettings,
    getApplicant,
    getCoverLetterReq,
    getGovernmentIdReq,
    getProofOfIncomeReq,
    getResumeReq,
    getVisaReq,
    getPassportReq,
    getProofOfAddressReq,
    getDocuments,
    getTrackOrder,
} from 'views/welcome/WelcomeSelectors';

// Constants
import { CHECK_REQUEST } from 'base/BaseConstants';

const mapStateToProps = (state) => ({
    isFetchingSilent: getIsFetchingSilent(state),
    information: getInformation(state),
    s3UploadLink: getS3UploadLink(state),
    s3UploadFields: getS3UploadFields(state),
    preparedDocument: getPreparedDocument(state),
    fileList: getDefaultFileList(state),
    onboardingType: getOnboardingType(state),
    settings: getSettings(state),
    applicant: getApplicant(state),
    coverLetterReq: getCoverLetterReq(state),
    governmentIdReq: getGovernmentIdReq(state),
    proofOfIncomeReq: getProofOfIncomeReq(state),
    passportReq: getPassportReq(state),
    getProofOfAddressReq: getProofOfAddressReq(state),
    resumeReq: getResumeReq(state),
    visaReq: getVisaReq(state),
    documents: getDocuments(state),
    tracks: getTrackOrder(state),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setTrackPageOrder,
            patchInformation,
            fetchSignedS3Link,
            confirmUploadSuccessful,
            deleteDocument,
        },
        dispatch
    );

const propTypes = {
    // Redux Actions:
    patchInformation: PropTypes.func.isRequired,
    setTrackPageOrder: PropTypes.func.isRequired,
    fetchSignedS3Link: PropTypes.func.isRequired,
    confirmUploadSuccessful: PropTypes.func.isRequired,
    deleteDocument: PropTypes.func.isRequired,
    // Redux Store:
    isFetchingSilent: PropTypes.bool.isRequired,
    information: PropTypes.object.isRequired,
    loginError: PropTypes.object,
    s3UploadLink: PropTypes.string,
    s3UploadFields: PropTypes.object,
    preparedDocument: PropTypes.object,
    fileList: PropTypes.array,
    settings: PropTypes.object,
    onboardingType: PropTypes.string.isRequired,
    coverLetterReq: PropTypes.bool,
    governmentIdReq: PropTypes.bool,
    proofOfIncomeReq: PropTypes.bool,
    passportReq: PropTypes.bool,
    getProofOfAddressReq: PropTypes.bool,
    resumeReq: PropTypes.bool,
    visaReq: PropTypes.bool,
    documents: PropTypes.array,
    tracks: PropTypes.array,
};
const defaultProps = {
    s3UploadLink: undefined,
    s3UploadFields: undefined,
    preparedDocument: undefined,
    settings: {},
    fileList: [],
    loginError: null,
    coverLetterReq: false,
    governmentIdReq: false,
    proofOfIncomeReq: false,
    resumeReq: false,
    visaReq: false,
    passportReq: false,
    getProofOfAddressReq: false,
    documents: [],
    tracks: [],
};

class Documents extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            settingsErrors: null,
            documentType: 'OTHER',
            error: false,
            fileList: [],
            requiredDocuments: [],
            nonRequiredDocuments: [],
            fileProgress: {},
            isUploading: false,
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        const {
            applicant,
            governmentIdReq,
            proofOfIncomeReq,
            coverLetterReq,
            resumeReq,
            visaReq,
            tracks,
            fileList,
        } = this.props;
        const passportReq = this.props.passportReq || tracks.includes('passport');
        const proofOfAddressReq = this.props.getProofOfAddressReq || tracks.includes('proofOfAddress');
        const coverLetterLength = get(this.props, ['information', 'cover_letter', 'length']);

        let fileProgress = {};
        fileList.map((file) => {
            if (file.type === 'OTHER') {
                const otherProgress =
                    'OTHER' in fileProgress
                        ? { OTHER: { ...fileProgress.OTHER, [file.name]: 100 } }
                        : { OTHER: { [file.name]: 100 } };
                fileProgress = { ...fileProgress, ...otherProgress };
            } else {
                fileProgress = { ...fileProgress, [file.type]: 100 };
            }
        });

        this.props.setTrackPageOrder(['base']);
        const teamTypeDocs =
            this.props.onboardingType === 'PM'
                ? {
                      GOVERNMENT_ID: governmentIdReq,
                      PROOF_OF_INCOME: proofOfIncomeReq,
                  }
                : {
                      RESUME: resumeReq,
                  };
        this.setState(
            {
                flowDocuments: {
                    COVER_LETTER: coverLetterLength > 0 ? false : coverLetterReq,
                    VISA: visaReq,
                    PASSPORT: passportReq || applicant?.[CHECK_REQUEST.INTERNATIONAL_CRIMINAL_RECORD_CHECK],
                    PROOF_OF_ADDRESS:
                        proofOfAddressReq || applicant?.[CHECK_REQUEST.INTERNATIONAL_CRIMINAL_RECORD_CHECK],
                    ...teamTypeDocs,
                    OTHER: false,
                },
                fileProgress,
            },
            this.documentSummary
        );
    }

    documentSummary = () => {
        const potentialDocuments = Object.entries(this.state.flowDocuments);
        const requiredDocuments = potentialDocuments.filter((item) => item[1]).map((item) => item[0]);
        const nonRequiredDocuments = potentialDocuments.filter((item) => !item[1]).map((item) => item[0]);
        this.setState({ fileList: this.props.fileList, requiredDocuments, nonRequiredDocuments });
    };

    onUpload = () => {
        const { fileList } = this.state;
        const { intl } = this.props;

        if (this.props.preparedDocument) {
            if (fileList.map((item) => item.name).includes(this.props.preparedDocument.file_name)) {
                message.error(
                    intl.formatMessage({ id: 'welcome.Documents.duplicateName', defaultMessage: 'Duplicate file name' })
                );
                return;
            }

            const newFileList = [...fileList];
            const newDocument = {
                uid: this.props.preparedDocument.id,
                name: this.props.preparedDocument.file_name,
                status: 'done',
                url: this.props.preparedDocument.url,
                type: this.props.preparedDocument.document_type,
            };
            newFileList.push(newDocument);

            this.setState({ fileList: newFileList }, () => this.props.patchInformation());
        }
    };

    truncateFileName = (name) => (name.length > 20 ? `${name.substring(0, 20)}...` : name);

    // called periodically as upload progresses
    handleProgress = ({ event, eventFile, doc = 'OTHER' }) => {
        const { fileProgress } = this.state;
        const { intl } = this.props;
        const percent = Math.round(event.percent);
        if (percent === 100)
            message.success(
                intl.formatMessage(
                    { id: 'welcome.Documents.uploadSuccessful', defaultMessage: '{fileName} uploaded successfully!' },
                    { fileName: this.truncateFileName(eventFile.name) }
                )
            );

        let updatedFileProgress = {};
        if (doc === 'OTHER') {
            const otherProgress = fileProgress.OTHER || {};
            const nextOtherProgress = { OTHER: { ...otherProgress, [eventFile.name]: percent } };
            updatedFileProgress = { ...fileProgress, ...nextOtherProgress };
        } else {
            updatedFileProgress = { ...fileProgress, [doc]: percent };
        }

        const isUploading = Object.keys(updatedFileProgress).some((key) => {
            if (key === 'OTHER')
                return Object.values(updatedFileProgress[key]).some((otherValue) => otherValue !== 100);
            return updatedFileProgress[key] !== 100;
        });

        this.setState({ fileProgress: updatedFileProgress, isUploading });
    };

    checkForMissingDocuments = () => {
        const { requiredDocuments, fileList } = this.state;
        return requiredDocuments && requiredDocuments.filter((item) => !fileList.find((file) => file.type === item));
    };

    handleSubmit = () => {
        const { fileList } = this.state;
        const { intl } = this.props;
        const { handleNextTrack, tracks } = this.props; /* WithNavigation */

        // API gets signed links and then file gets uploaded to S3, we need to verify that the finished upload completes
        // and tell the API it was successful, otherwise delete the file. Research into customRequest
        const filesCheckAsync = [];
        fileList.forEach((item) => {
            filesCheckAsync.push(
                new Promise((resolve, reject) =>
                    fetch(item.url).then((response) => {
                        if (response.ok) {
                            this.props.confirmUploadSuccessful(item.uid).then(resolve);
                        } else {
                            this.handleDeleteDocument(item);
                            message.error(
                                intl.formatMessage(
                                    {
                                        id: 'welcome.Documents.errorUploadingDocument',
                                        defaultMessage: 'Error uploading document: {name}',
                                    },
                                    { name: item.name }
                                )
                            );
                            reject();
                        }
                    })
                )
            );
        });

        Promise.all(filesCheckAsync).then(() => {
            if (this.checkForMissingDocuments().length > 0) {
                this.setState({ error: true });
                return message.error(
                    intl.formatMessage({
                        id: 'welcome.Documents.missingRequiredDocuments',
                        defaultMessage: 'You are missing required documents',
                    })
                );
            }
            this.props.patchInformation().then(() => {
                // ensures passport track is hit if applicant goes back and updates passport document
                if (tracks.includes('passport')) return this.props.history.push('/welcome/passport');
                handleNextTrack();
            });
        });
    };

    documentTypeValid = (fileType) => /jpg|jpeg|png|gif|pdf|doc|docx|ppt|pptx|odt|xls|xlsx|txt|text/.test(fileType);

    // For document upload
    s3GetUploadLink = (file, documentType = this.state.documentType) => {
        const { fileList } = this.state;
        const { intl } = this.props;

        if (fileList.map((item) => item.name).includes(file.name)) return false;

        if (this.documentTypeValid(file.type)) return this.props.fetchSignedS3Link(file, documentType);

        message.error(
            intl.formatMessage({
                id: 'welcome.Documents.errorUploadingDocumentType',
                defaultMessage: 'Invalid file type',
            })
        );
        return false;
    };

    documentsUploaded = () => this.props.documents && this.props.documents.map((doc) => doc.document_type);

    handleDeleteDocument = (file) => {
        const { fileProgress, fileList } = this.state;
        const { intl } = this.props;
        this.props
            .deleteDocument(file)
            .then(() => this.props.patchInformation())
            .catch(() => ErrorAlertCustom());

        const updatedFileProgress = { ...fileProgress };
        if (file.type === 'OTHER') {
            delete updatedFileProgress.OTHER[file.name];
        } else {
            delete updatedFileProgress[file.type];
        }

        message.warn(
            intl.formatMessage(
                {
                    id: 'welcome.Documents.documentRemoved',
                    defaultMessage: '{fileName} removed',
                },
                { fileName: this.truncateFileName(file.name) }
            )
        );

        const index = fileList.indexOf(file);
        const newFileList = [...fileList];
        newFileList.splice(index, 1);
        this.setState({ fileList: newFileList, fileProgress: { ...updatedFileProgress } });
    };

    handleChangeDocumentType = (value) => this.setState(() => ({ documentType: value }));

    clearSettingsErrors = () => this.setState({ settingsErrors: null });

    documentsUploadedNotRequired = () =>
        this.state.fileList.filter((doc) => !!this.state.nonRequiredDocuments.find((item) => item.includes(doc.type)));

    documentsUploadedRequired = () =>
        this.state.fileList.filter((doc) => !!this.state.requiredDocuments.find((item) => item.includes(doc.type)));

    pages = () => {
        const { fileList, error, requiredDocuments, nonRequiredDocuments, fileProgress, isUploading } = this.state;
        const { s3UploadFields, s3UploadLink, onboardingType, isFetchingSilent, settings } = this.props;
        const requireIDWithAddress = settings.require_id_with_address;
        const missing = this.checkForMissingDocuments();
        const docsUploadedNotReq = this.documentsUploadedNotRequired();
        const docsUploadedReq = this.documentsUploadedRequired();
        const Component = requireIDWithAddress ? AddressDocumentUpload : PageOne;

        return {
            base: (
                <Component
                    handleSubmit={this.handleSubmit}
                    s3GetUploadLink={this.s3GetUploadLink}
                    handleDeleteDocument={this.handleDeleteDocument}
                    onUpload={this.onUpload}
                    handleProgress={this.handleProgress}
                    handleChangeDocumentType={this.handleChangeDocumentType}
                    s3UploadLink={s3UploadLink}
                    s3UploadFields={s3UploadFields}
                    fileList={fileList}
                    fileProgress={fileProgress}
                    isUploading={isUploading}
                    isFetchingSilent={isFetchingSilent}
                    onboardingType={onboardingType}
                    missingDocuments={missing}
                    requiredDocuments={requiredDocuments}
                    nonRequiredDocuments={nonRequiredDocuments}
                    docsUploadedNotReq={docsUploadedNotReq}
                    docsUploadedReq={docsUploadedReq}
                    error={error}
                    requireIDWithAddress={requireIDWithAddress}
                />
            ),
        };
    };

    render() {
        return this.pages()[this.props.trackPageLocation]; /* WithNavigation */
    }
}

Documents.propTypes = propTypes;
Documents.defaultProps = defaultProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(withNavigation(Documents))));
