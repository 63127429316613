/* eslint-disable no-shadow */
export enum CheckStatus {
    WAITING_ON_CANDIDATE = 'WAITING_ON_CANDIDATE',
    IN_PROGRESS = 'IN_PROGRESS',
    REQUIRES_MORE_INFORMATION = 'REQUIRES_MORE_INFORMATION',
    COMPLETE = 'COMPLETE',
    UNRESPONSIVE = 'UNRESPONSIVE',
    CANCELLED = 'CANCELLED',
    ACTION_REQUIRED = 'ACTION_REQUIRED',
    IN_DISPUTE = 'IN_DISPUTE',
    WAITING_ON_ADJUDICATOR = 'WAITING_ON_ADJUDICATOR',
}
