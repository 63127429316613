// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// UI Components
import { ButtonLine } from 'certn-ui/Button';
import { Form } from '@ant-design/compatible';
import { Input, Button } from 'antd';

// Actions & Selectors
import { getFilterBy } from 'views/manager/views/pm/views/listings/ListingsSelectors';

const mapStateToProps = (state) => ({
    filterBy: getFilterBy(state),
});
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

const propTypes = {
    // Redux Store
    filterBy: PropTypes.object,
    // Parent Props
    onSubmit: PropTypes.func.isRequired,
    clearFilters: PropTypes.func.isRequired,
};
const defaultProps = {
    filterBy: {},
};

class FilterForm extends React.Component {
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.props.onSubmit(values);
            }
        });
    };

    clearFilterFields = () => {
        this.props.form.resetFields();
        this.props.clearFilters();
    };

    render() {
        const { getFieldDecorator } = this.props.form;

        const formItemLayout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 24 },
        };

        return (
            <Form onSubmit={this.handleSubmit}>
                {/* TODO: Make this work */}
                {/* <Form.Item {...formItemLayout}>
                    {getFieldDecorator('checkboxes', { valuePropName: 'checked' })(<Checkbox.Group options={[{ label: 'Full Screen', value: 'is_paid_equifax' }]} />)}
                </Form.Item> */}
                <Form.Item {...formItemLayout}>
                    {getFieldDecorator('property_id', {
                        initialValue: this.props.filterBy.property_id || null,
                        rules: [
                            {
                                type: 'string', // FIXME: Add custom validation for name
                                message: 'The input is not valid!',
                            },
                        ],
                    })(<Input addonBefore="Property ID" type="text" />)}
                </Form.Item>
                <Form.Item {...formItemLayout}>
                    {getFieldDecorator('url_code', {
                        initialValue: this.props.filterBy.url_code || null,
                        rules: [
                            {
                                type: 'string', // FIXME: Add custom validation for name
                                message: 'The input is not valid!',
                            },
                        ],
                    })(<Input addonBefore="Apply Link" type="text" />)}
                </Form.Item>
                <ButtonLine dualButtons>
                    <Button type="primary" htmlType="submit">
                        Apply Filter
                    </Button>
                    <Button type="primary" ghost onClick={this.clearFilterFields}>
                        Clear Filters
                    </Button>
                </ButtonLine>
            </Form>
        );
    }
}

FilterForm.propTypes = propTypes;
FilterForm.defaultProps = defaultProps;

export default Form.create()(connect(mapStateToProps, mapDispatchToProps)(FilterForm));
