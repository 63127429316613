import { useContext } from 'react';
import { PackagesContext } from '../../Packages.context';
import { Popover } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import Form from 'certnd/Form';
import { intl } from 'components/GlobalProvider';

export const PackageName = () => {
    const { form, packages, editingPackage } = useContext(PackagesContext);

    return (
        <Form.Input
            form={form}
            title={intl.formatMessage({ id: '22572.Packages.PackageName', defaultMessage: 'Name' })}
            fieldName="package_name"
            placeholder={intl.formatMessage({
                id: '22572.Packages.PackagePlaceholder',
                defaultMessage: 'Enter Package Name',
            })}
            addonBefore={
                <Popover
                    content={
                        <FormattedMessage
                            id="22572.Packages.PackagePopover"
                            defaultMessage="Name your package for use with this integration."
                        />
                    }
                >
                    <InfoCircleOutlined />
                </Popover>
            }
            options={{
                initialValue: editingPackage.name,
                rules: [
                    {
                        required: true,
                        message: intl.formatMessage({
                            id: '22572.Packages.PackageValid',
                            defaultMessage: 'Please give your package a name.',
                        }),
                        whitespace: true,
                    },
                    {
                        validator: (rule, value, callback) => {
                            packages.forEach((integrationPackage) => {
                                if (integrationPackage.name === editingPackage?.name) {
                                    return;
                                }

                                if (value.trim() === integrationPackage.name) {
                                    return callback(
                                        <FormattedMessage
                                            id="22572.Packages.PackageError"
                                            defaultMessage="Cannot have two packages with the same name."
                                        />
                                    );
                                }
                            });
                            return callback();
                        },
                    },
                ],
            }}
        />
    );
};
