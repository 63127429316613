import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { intl } from 'components/GlobalProvider';

// Components
import Congrats from './Congrats';
import { Modal } from 'antd';

// Actions & Selectors
import { getFirstLogin } from 'base/BaseSelectors';
import { firstLoginComplete } from 'base/BaseActions';

const FirstLoginModal = () => {
    // We only want to show the modal on first login via Magic Link (after verify email)
    const firstLogin = useSelector(getFirstLogin);
    const dispatch = useDispatch();
    const closeModal = () => dispatch(firstLoginComplete());
    return (
        <Modal
            destroyOnClose
            centered
            title={intl.formatMessage({ id: '1d2c3.Manager.modalTitle', defaultMessage: 'Welcome to the App!' })}
            visible={firstLogin}
            footer={null}
            onCancel={closeModal}
        >
            <Congrats closeModal={closeModal} />
        </Modal>
    );
};

export default FirstLoginModal;
