// Libraries
import React from 'react';
import styled from 'styled-components/macro';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Form as AntdForm } from '@ant-design/compatible';
import { Button, Input, Select, message, Popover, Collapse } from 'antd';
import { get } from 'lodash';
import { injectIntl, FormattedMessage } from 'react-intl';

// Components
import Form from 'certnd/Form';
import { SubBackButton, ButtonLine } from 'certn-ui/Button';
import { ErrorAlertAPI } from 'certn-ui/ErrorAlert';
import Typography from 'certnd/Typography';

// Actions & Selectors
import { getSettings, getCurrentTeam } from 'views/manager/views/settings/SettingsSelectors';
import { getTeam, getTeamId } from 'views/manager/views/admin/AdminSelectors';
import { getUser, getUserMode } from 'base/BaseSelectors';

// Modules
import Auth from 'modules/Auth';
import { ReferenceModule } from 'modules';
import styles from 'styles/styles';

const { QUESTION_TYPES } = ReferenceModule;
const { TextArea } = Input;
const { Heading } = Typography;
const { Panel } = Collapse;

const CustomPanel = styled(Panel)`
    > .ant-collapse-header {
        height: 55px;
        border-bottom: 1px solid ${({ theme }) => theme.color.certnGray200};
    }
`;

const customPanelStyle = {
    background: styles.color.certnGray100,
    borderRadius: '4px 4px 0 0',
    marginBottom: 24,
    border: 0,
    overflow: 'hidden',
};

const Delete = styled(CloseOutlined)`
    color: ${({ theme }) => theme.color.certnGray500} !important;
    margin-top: 8px;
`;

const StyledSelect = styled(Select)`
    width: 100%;
    margin-bottom: 10px;
`;

const SelectAuto = styled(Select)`
    width: auto !important;
`;

export const StyledInput = styled(Input)`
    font-weight: 600;
    font-size: 16px;
    width: 1000px;
    @media (max-width: ${(props) => props.theme.width.phone}) {
        width: 100%;
    }
`;

export const MultipleChoiceOptionInput = styled(Input)`
    font-size: 16px;
    width: 200px;
    @media (max-width: ${(props) => props.theme.width.phone}) {
        width: 100%;
    }
`;

const Label = styled.div`
    font-weight: bolder;
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 3px;
`;

const QuestionError = styled.div`
    font-weight: bolder;
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 3px;
    color: ${({ theme }) => theme.color.certnRed500};
`;

const CustomVariablesLink = styled.a`
    font-weight: bold;
    font-size: 16px;
    text-decoration: none;
    margin-bottom: 10px;
`;

const CustomVariablesText = styled.div`
    font-weight: light;
    font-size: 16px;
    margin-top: 5px;
    margin-bottom: 5px;
`;

const CustomVariablesTextBold = styled.div`
    font-weight: bolder;
    font-size: 16px;
    margin-top: 5px;
    margin-bottom: 5px;
`;

const MultipleChoiceOptionsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const MultipleChoiceOption = styled.div`
    display: flex;
    margin-top: 5px;
    margin-right: 30px;
`;

const DeleteOption = styled.div`
    border-radius: 5px;
    margin-left: 5px;
    border: 1px solid ${({ theme }) => theme.color.certnGray400};
    text-align: center;
    width: 36px;
    cursor: pointer;
    box-sizing: border-box;
    &:hover {
        border: 1px solid ${({ theme }) => theme.color.certnGray400};
    }
`;

const RequiredWrapper = styled.div`
    margin: 10px 0 -15px 0;
`;

const mapStateToProps = (state) => ({
    user: getUser(state),
    settings: getSettings(state),
    currentTeam: getCurrentTeam(state),
    team: getTeam(state),
    teamId: getTeamId(state),
    userMode: getUserMode(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

const types = [
    {
        value: QUESTION_TYPES.TRUE_FALSE,
        name: <FormattedMessage id="33c49.ReferenceTemplate.TrueFalse" defaultMessage="True/False" />,
    },
    {
        value: QUESTION_TYPES.YES_NO,
        name: <FormattedMessage id="33c49.ReferenceTemplate.YesNo" defaultMessage="Yes/No" />,
    },
    {
        value: QUESTION_TYPES.MULTIPLE_CHOICE,
        name: <FormattedMessage id="33c49.ReferenceTemplate.MultipleChoice" defaultMessage="Multiple Choice" />,
    },
    {
        value: QUESTION_TYPES.LONG_ANSWER,
        name: <FormattedMessage id="33c49.ReferenceTemplate.LongAnswer" defaultMessage="Long Answer" />,
    },
    {
        value: QUESTION_TYPES.NUMERIC_RANGE,
        name: <FormattedMessage id="33c49.ReferenceTemplate.NumericRange" defaultMessage="Numeric Range (1-10)" />,
    },
];

const ReferenceTemplate = AntdForm.create()(
    class Template extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                template: props.template || {},
                questions: (props.template && props.template.questions) || [],
            };
        }

        handleSubmit = (e) => {
            const { teamId, template, type, intl } = this.props;
            e.preventDefault();
            this.props.form.validateFieldsAndScroll((err, values) => {
                let error = false;

                if (this.state.questions.length < 2) {
                    error = true;
                }

                if (!err) {
                    this.setState(
                        (prevState) => ({
                            error:
                                prevState.questions.length < 2 ? (
                                    <FormattedMessage
                                        id="33c49.ReferenceTemplateMinQuestions"
                                        defaultMessage="At least 2 questions are required."
                                    />
                                ) : (
                                    ''
                                ),
                            questions: prevState.questions.map((question, i) => {
                                // MULTIPLE CHOICE VALIDATION
                                if (question.type !== QUESTION_TYPES.MULTIPLE_CHOICE) {
                                    delete question.multiple_choice_options;
                                }
                                if (question.type === QUESTION_TYPES.MULTIPLE_CHOICE) {
                                    // NO BLANK ANSWERS
                                    if (question.multiple_choice_options.filter((option) => option === '').length > 0) {
                                        error = true;
                                        return {
                                            ...question,
                                            error: (
                                                <FormattedMessage
                                                    id="33c49.ReferenceTemplate.MultipleBlank"
                                                    defaultMessage="Multiple choice options may not be blank. Please either complete or delete any blank answer options."
                                                />
                                            ),
                                        };
                                    }

                                    // CHECK FOR ENOUGH MULTIPLE CHOICE QUESTIONS
                                    if (
                                        question.multiple_choice_options.length < 3 ||
                                        question.multiple_choice_options.length > 6
                                    ) {
                                        error = true;
                                        return {
                                            ...question,
                                            error: (
                                                <FormattedMessage
                                                    id="33c49.ReferenceTemplate.MultipleOptions"
                                                    defaultMessage="Multiple Choice questions must have between 3 and 6 options."
                                                />
                                            ),
                                        };
                                    }

                                    // NO DUPLICATE ANSWERS
                                    const options = [];
                                    question.multiple_choice_options.forEach((option) => {
                                        if (options.includes(option)) {
                                            error = true;
                                        }
                                        options.push(option);
                                    });
                                    if (error) {
                                        return {
                                            ...question,
                                            error: (
                                                <FormattedMessage
                                                    id="33c49.ReferenceTemplate.MultipleDuplicates"
                                                    defaultMessage="Multiple choice questions may not contain duplicate answers"
                                                />
                                            ),
                                        };
                                    }

                                    // CHECK FOR CORRECT OPTION
                                    if (question.multiple_choice_correct_option === null) {
                                        error = true;
                                        return {
                                            ...question,
                                            error: (
                                                <FormattedMessage
                                                    id="33c49.ReferenceTemplate.MultipleAnswer"
                                                    defaultMessage="Multiple Choice questions require you to select a correct answer"
                                                />
                                            ),
                                        };
                                    }
                                }

                                if (question.question.length < 5) {
                                    error = true;
                                    return {
                                        ...question,
                                        error: (
                                            <FormattedMessage
                                                id="33c49.ReferenceTemplate.ValidQuestion"
                                                defaultMessage="Please type a valid question"
                                            />
                                        ),
                                    };
                                }

                                return { ...question, index: i + 1, error: null };
                            }),
                        }),
                        () => {
                            if (!error) {
                                const data = {
                                    ...template,
                                    id: template.id,
                                    name: values.template_name,
                                    questions: this.state.questions,
                                };

                                this.props
                                    .onSave({ type, teamId, data })
                                    .then(() =>
                                        message.success(
                                            intl.formatMessage({
                                                id: '33c49.ReferenceTemplate.TemplateSaved',
                                                defaultMessage: 'Reference template saved successfully.',
                                            })
                                        )
                                    )
                                    .catch((error) => ErrorAlertAPI(error));
                            }
                        }
                    );
                }
            });
        };

        handleReorder = ({ to, from }) => {
            this.setState((prevState) => {
                const questions = [...prevState.questions];
                questions.splice(to, 0, questions.splice(from, 1)[0]);
                return {
                    questions,
                };
            });
        };

        handleChange = (e, index, field) => {
            this.setState((prevState) => {
                const nextQuestions = [...prevState.questions];
                nextQuestions[index][field] = e;
                if (e === QUESTION_TYPES.MULTIPLE_CHOICE) {
                    nextQuestions[index].multiple_choice_options = ['', '', ''];
                }
                return { questions: nextQuestions };
            });
        };

        updateMultipleChoiceOption = (questionIndex, optionIndex, value) => {
            this.setState((prevState) => ({
                questions: prevState.questions.map((question, i) => {
                    if (i === questionIndex) {
                        return {
                            ...question,
                            multiple_choice_options: question.multiple_choice_options.map((option, optIndex) => {
                                if (optionIndex === optIndex) {
                                    return value;
                                }
                                return option;
                            }),
                        };
                    }
                    return question;
                }),
            }));
        };

        addMultipleChoiceOption = (index) => {
            this.setState((prevState) => ({
                questions: prevState.questions.map((question, i) => {
                    if (i === index) {
                        return {
                            ...question,
                            multiple_choice_options: [...question.multiple_choice_options, ''],
                        };
                    }
                    return question;
                }),
            }));
        };

        removeMultipleChoiceOption = (questionIndex, optionIndex) => {
            const questionCount = this.state.questions[questionIndex].multiple_choice_options.length;

            if (questionCount <= 3) {
                this.setState((prevState) => ({
                    questions: prevState.questions.map((question, i) => {
                        if (i === questionIndex) {
                            return {
                                ...question,
                                error: (
                                    <FormattedMessage
                                        id="33c49.ReferenceTemplate.MultipleOptions"
                                        defaultMessage="Multiple Choice questions must have between 3 and 6 options."
                                    />
                                ),
                            };
                        }
                        return question;
                    }),
                }));
            } else {
                this.setState((prevState) => ({
                    questions: prevState.questions.map((question, i) => {
                        if (i === questionIndex) {
                            return {
                                ...question,
                                multiple_choice_correct_option: null,
                                multiple_choice_options: question.multiple_choice_options.filter(
                                    (option, optIndex) => optIndex !== optionIndex
                                ),
                            };
                        }
                        return question;
                    }),
                }));
            }
        };

        deleteQuestion = (index) => {
            this.setState((prevState) => ({ questions: prevState.questions.filter((question, i) => i !== index) }));
        };

        addQuestion = () => {
            const newQuestion = {
                question: '',
                type: QUESTION_TYPES.LONG_ANSWER,
                is_verifiable: false,
                is_required: false,
            };

            window.scrollTo(0, document.body.scrollHeight);

            this.setState((prevState) => ({ questions: [...prevState.questions, newQuestion] }));
        };

        customVariable = (
            <>
                <CustomVariablesText>
                    <FormattedMessage
                        id="33c49.ReferenceTemplate.CustomVariables"
                        defaultMessage="To insert custom variables into a question or multiple choice answer, wrap the variable name in double curly brackets, like this:"
                    />
                    "
                </CustomVariablesText>
                <CustomVariablesTextBold>
                    {/* this is so the extraction script  */}
                    <FormattedMessage
                        id="33c49.ReferenceTemplate.Hello"
                        defaultMessage="Hello, {firstName}"
                        values={{
                            firstName: ` {{${this.props.intl.formatMessage({
                                id: '33c49.ReferenceTemplate.first_name',
                                defaultMessage: 'first_name',
                            })}}}.`,
                        }}
                    />
                </CustomVariablesTextBold>
                <CustomVariablesText>
                    <FormattedMessage id="33c49.ReferenceTemplate.Available" defaultMessage="Available variables:" />
                </CustomVariablesText>
                <CustomVariablesTextBold>
                    {this.props.userMode === 'HR'
                        ? 'first_name, last_name, company_name, start_date, end_date, position, team_name'
                        : 'first_name, last_name, start_date, end_date, team_name'}
                </CustomVariablesTextBold>
            </>
        );

        getExpandedKeys = () => {
            const { questions } = this.state;
            return questions ? [...Array(questions.length).keys()].map(String) : ['1', '2', '3'];
        };

        render() {
            const { form, template, intl } = this.props;
            const newTemplate = !template.name;
            const canEdit = Auth.isPermissionLevel('manager');

            const expandedKeys = this.getExpandedKeys();
            return (
                <AntdForm onSubmit={this.handleSubmit}>
                    <SubBackButton
                        label={<FormattedMessage id="33c49.ReferenceTemplate.Templates" defaultMessage="TEMPLATES" />}
                        onClick={this.props.back}
                    />
                    <Heading.H1>
                        {newTemplate ? (
                            <FormattedMessage
                                id="33c49.ReferenceTemplate.CustomizeNew"
                                defaultMessage="Customize Your New Reference Template Questions"
                            />
                        ) : (
                            <FormattedMessage
                                id="33c49.ReferenceTemplate.CustomizeExisting"
                                defaultMessage="Customize Your Reference Questions"
                            />
                        )}
                    </Heading.H1>
                    <Form.Input
                        form={form}
                        fieldName="template_name"
                        title={
                            <FormattedMessage
                                id="33c49.ReferenceTemplate.TemplateName"
                                defaultMessage="Template Name"
                            />
                        }
                        options={{
                            initialValue: get(this.state.template, ['name']),
                            rules: [
                                {
                                    required: true,
                                    message: (
                                        <FormattedMessage
                                            id="33c49.ReferenceTemplate.NameRule"
                                            defaultMessage="Please enter a template name"
                                        />
                                    ),
                                },
                            ],
                        }}
                        disabled={!canEdit}
                    />
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Popover
                            placement="right"
                            content={this.customVariable}
                            title={
                                <FormattedMessage
                                    id="33c49.ReferenceTemplate.InstructionsTitle"
                                    defaultMessage="How To Use Custom Variables"
                                />
                            }
                            trigger="click"
                        >
                            <CustomVariablesLink>
                                <FormattedMessage
                                    id="33c49.ReferenceTemplate.InstructionsTitle"
                                    defaultMessage="How To Use Custom Variables"
                                />
                            </CustomVariablesLink>
                        </Popover>
                        <Button
                            type="primary"
                            style={{ marginTop: '10px', marginBottom: '20px' }}
                            onClick={() => this.addQuestion()}
                        >
                            <FormattedMessage id="33c49.ReferenceTemplate.AddQuestion" defaultMessage="ADD QUESTION" />
                        </Button>
                    </div>

                    {this.state.error && (
                        <QuestionError style={{ marginBottom: '15px', marginTop: '0px' }}>
                            {this.state.error}
                        </QuestionError>
                    )}

                    <Collapse
                        bordered={false}
                        defaultActiveKey={expandedKeys}
                        onChange={() => null}
                        expandIconPosition="right"
                    >
                        {this.state.questions.map((question, i, total) => {
                            const multipleChoiceOptions =
                                question.multiple_choice_options &&
                                question.multiple_choice_options.filter((option) => option !== '');

                            return (
                                <CustomPanel
                                    header={
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <div onClick={(e) => e.stopPropagation()}>
                                                <span style={{ fontSize: '18px', fontWeight: '600' }}>
                                                    <FormattedMessage
                                                        id="33c49.ReferenceTemplate.QuestionTitle"
                                                        defaultMessage="Question "
                                                    />
                                                </span>
                                                <SelectAuto
                                                    defaultValue={i + 1}
                                                    value={i + 1}
                                                    ref={(select) => {
                                                        this.selectDropDownRef = select;
                                                    }}
                                                    onChange={(value) => {
                                                        this.selectDropDownRef.blur();
                                                        this.handleReorder({ to: value - 1, from: i });
                                                    }}
                                                >
                                                    {[...Array(total.length).keys()].map((num) => (
                                                        <Select.Option value={num + 1}>{num + 1}</Select.Option>
                                                    ))}
                                                </SelectAuto>
                                            </div>
                                            <Button
                                                style={{
                                                    background: styles.color.certnRed700,
                                                    color: 'white',
                                                }}
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    this.deleteQuestion(i);
                                                }}
                                            >
                                                <FormattedMessage id="common.delete" defaultMessage="Delete" />
                                            </Button>
                                        </div>
                                    }
                                    key={i}
                                    style={customPanelStyle}
                                >
                                    {question.error && <QuestionError>{question.error}</QuestionError>}
                                    <RequiredWrapper>
                                        <Form.Checkbox
                                            form={form}
                                            data-testid={`question-${question.index}-required`}
                                            onChange={(e) => this.handleChange(e.target.checked, i, 'is_required')}
                                            title={intl.formatMessage({
                                                id: '33c49.ReferenceTemplate.QuestionRequiredTitle',
                                                defaultMessage: 'Required',
                                            })}
                                            checked={question.is_required}
                                            disabled={!canEdit}
                                            hideBorderOnly
                                        />
                                    </RequiredWrapper>
                                    <Label>
                                        <FormattedMessage id="33c49.ReferenceTemplate.Type" defaultMessage="Type" />
                                    </Label>
                                    <StyledSelect
                                        style={{ width: '200px' }}
                                        value={question.type}
                                        onChange={(e) => this.handleChange(e, i, 'type')}
                                    >
                                        {types.map((type, index) => (
                                            <Select.Option key={index} value={type.value}>
                                                {type.name}
                                            </Select.Option>
                                        ))}
                                    </StyledSelect>
                                    {question.type === QUESTION_TYPES.TRUE_FALSE && (
                                        <div style={{ marginTop: '5px' }}>
                                            <FormattedMessage
                                                id="33c49.ReferenceTemplate.TrueFalseDescription"
                                                defaultMessage="Our system scores the answers as 'True' being the correct answer. Please pose the question in a way where 'True' is the correct answer."
                                            />
                                        </div>
                                    )}
                                    {question.type === QUESTION_TYPES.YES_NO && (
                                        <div style={{ marginTop: '5px' }}>
                                            <FormattedMessage
                                                id="33c49.ReferenceTemplate.YesNoDescription"
                                                defaultMessage="Our system scores the answers as 'Yes' being the correct answer. Please pose the question in a way where 'Yes' is the correct answer."
                                            />
                                        </div>
                                    )}
                                    {question.type === QUESTION_TYPES.NUMERIC_RANGE && (
                                        <div style={{ marginTop: '5px' }}>
                                            <FormattedMessage
                                                id="33c49.ReferenceTemplate.NumericDescription"
                                                defaultMessage="Our system scores the answers as 10 being the best and 1 being the worst. Please pose the question in a way where 10 is the 'best' option."
                                            />
                                        </div>
                                    )}

                                    <Label>
                                        <FormattedMessage
                                            id="33c49.ReferenceTemplate.QuestionText"
                                            defaultMessage="Question Text"
                                        />
                                    </Label>
                                    <TextArea
                                        value={question.question}
                                        onChange={(e) => this.handleChange(e.target.value, i, 'question')}
                                        placeholder={intl.formatMessage({
                                            id: '33c49.ReferenceTemplate.QuestionPlaceholder',
                                            defaultMessage: 'Type your question here',
                                        })}
                                    />
                                    {question.type === QUESTION_TYPES.MULTIPLE_CHOICE && (
                                        <>
                                            <Label>
                                                <FormattedMessage
                                                    id="33c49.ReferenceTemplate.MultipleChoiceOptions"
                                                    defaultMessage="Multiple Choice Options"
                                                />
                                            </Label>
                                            <Button
                                                type="default"
                                                disabled={
                                                    question.multiple_choice_options &&
                                                    question.multiple_choice_options.length >= 6
                                                }
                                                onClick={() => this.addMultipleChoiceOption(i)}
                                            >
                                                <FormattedMessage
                                                    id="33c49.ReferenceTemplate.AddOption"
                                                    defaultMessage="Add Option "
                                                />
                                                <PlusOutlined />
                                            </Button>
                                            <MultipleChoiceOptionsContainer>
                                                {question.multiple_choice_options &&
                                                    question.multiple_choice_options.map((option, optionIndex) => (
                                                        <MultipleChoiceOption key={optionIndex}>
                                                            <MultipleChoiceOptionInput
                                                                value={option}
                                                                onChange={(e) =>
                                                                    this.updateMultipleChoiceOption(
                                                                        i,
                                                                        optionIndex,
                                                                        e.target.value
                                                                    )
                                                                }
                                                                placeholder={intl.formatMessage({
                                                                    id: '33c49.ReferenceTemplate.OptionPlaceholder',
                                                                    defaultMessage: 'Type a possible answer',
                                                                })}
                                                            />
                                                            <DeleteOption
                                                                onClick={() =>
                                                                    this.removeMultipleChoiceOption(i, optionIndex)
                                                                }
                                                            >
                                                                <Delete />
                                                            </DeleteOption>
                                                        </MultipleChoiceOption>
                                                    ))}
                                            </MultipleChoiceOptionsContainer>
                                            {multipleChoiceOptions && multipleChoiceOptions.length > 0 && (
                                                <>
                                                    <Label>
                                                        <FormattedMessage
                                                            id="33c49.ReferenceTemplate.CorrectOption"
                                                            defaultMessage="Multiple Choice Correct Option"
                                                        />
                                                    </Label>
                                                    <StyledSelect
                                                        value={
                                                            multipleChoiceOptions[
                                                                question.multiple_choice_correct_option
                                                            ]
                                                        }
                                                        onChange={(e) =>
                                                            this.handleChange(e, i, 'multiple_choice_correct_option')
                                                        }
                                                        placeholder={
                                                            <FormattedMessage
                                                                id="33c49.ReferenceTemplate.CorrectPlaceholder"
                                                                defaultMessage="Select the correct answer"
                                                            />
                                                        }
                                                    >
                                                        {multipleChoiceOptions &&
                                                            multipleChoiceOptions.map((option, optionIndex) => (
                                                                <Select.Option
                                                                    key={option}
                                                                    defaultValue={
                                                                        multipleChoiceOptions[
                                                                            question.multiple_choice_correct_option
                                                                        ]
                                                                    }
                                                                    value={optionIndex}
                                                                >
                                                                    {option}
                                                                </Select.Option>
                                                            ))}
                                                    </StyledSelect>
                                                </>
                                            )}
                                        </>
                                    )}
                                </CustomPanel>
                            );
                        })}
                        <ButtonLine>
                            <Form.Button disabled={!canEdit}>
                                <FormattedMessage id="common.save" defaultMessage="Save" />
                            </Form.Button>
                        </ButtonLine>
                    </Collapse>
                </AntdForm>
            );
        }
    }
);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(ReferenceTemplate)));
