// Libraries
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { intl } from 'components/GlobalProvider';
import { useFlags } from 'launchdarkly-react-client-sdk';

// UI Components
import { Menu } from 'antd';
import EmailEntryModal from '../EmailEntryModal';
import { Dropdown } from './styled';
import Titles from './Titles';
import ItemViewReport from './ItemViewReport';
import ItemUpgradeAction from './ItemUpgradeAction';
import ItemShareReport from './ItemShareReport';
import ItemShowInfoPanel from './ItemShowInfoPanel';
import ItemSendReminder from './ItemSendReminder';
import ItemCopyLink from './ItemCopyLink';
import ItemDelete from './ItemDelete';
import ItemMoveTo from './ItemMoveTo';
import ItemDownloadPDFReport from './ItemDownloadPDFReport';
import ItemDownloadCSVReport from './ItemDownloadCSVReport';
import ItemDownloadConsentDocs from './ItemDownloadConsentDocs';
import ItemRenewExpiredApplicants from './ItemRenewExpiredApplicants';
import { ItemAdverseAction } from './ItemAdverseAction';

// Actions & Selectors
import { getUser, getLanguage, getLocale } from 'base/BaseSelectors';
import { fetchApplications } from 'views/manager/views/hr/views/applications/ApplicationsActions';

// Modules & Utils
import { canViewReportWithClientAdjudication } from 'modules/applicationHelper';
import Constants from 'utils/constants';

// Constants
import { CHECK_REQUEST } from 'base/BaseConstants';

const StatusDropdown = ({ applicant, isReport, isDashboard, children, variant, sideBarConfig = {} }) => {
    const { webFeatureEnableAdverseAction, webFeatureEnableNewStatusSystem } = useFlags();
    const user = useSelector(getUser);
    const language = useSelector(getLanguage);
    const locale = useSelector(getLocale);

    const [visible, setVisible] = useState(false);

    const handleClose = () => {
        setVisible(false);
    };

    const canShareReport = () => {
        if (
            user.can_share_reports &&
            (applicant[CHECK_REQUEST.SOFTCHECK] ||
                applicant[CHECK_REQUEST.EQUIFAX] ||
                applicant[CHECK_REQUEST.EDUCATION_VERIFICATION] ||
                applicant[CHECK_REQUEST.EMPLOYMENT_VERIFICATION] ||
                applicant[CHECK_REQUEST.EMPLOYER_REFERENCES] ||
                applicant[CHECK_REQUEST.EMPLOYER_PHONE_REFERENCES] ||
                applicant[CHECK_REQUEST.CRIMINAL_RECORD_CHECK])
        )
            return true;

        return false;
    };

    // LDFlag webFeatureEnableNewStatusSystem
    const orderStatus = applicant.order_status;
    const reportStatus = applicant.report_summary?.report_status;
    let active;
    if (webFeatureEnableNewStatusSystem) active = Constants.orderStatus.active.includes(orderStatus);
    if (!webFeatureEnableNewStatusSystem) active = Constants.reportStatus.active.includes(reportStatus);

    const applicationStatus = applicant.adjudication_status;
    // TODO: Determine if the status is pending (both status and status_label are translated)
    // It should probably be that status is the constant P, A, or R and status_label is the translated readable string.
    // A future API version might change to that approach, for now we're stuck with this solution
    const isPending = applicant.application && (applicant.status === 'Pending' || applicant.status === 'Attente');
    const showCopyApplicationLink = applicant.onboarding_link && !user?.team?.applicant_invites_blocked;

    // LDFlag webFeatureEnableNewStatusSystem
    let isResponsive;
    if (webFeatureEnableNewStatusSystem) isResponsive = orderStatus !== Constants.orderStatus.CANCELLED;
    if (!webFeatureEnableNewStatusSystem) isResponsive = reportStatus !== Constants.reportStatus.UNRESPONSIVE;

    // LDFlag webFeatureEnableNewStatusSystem
    let showRenewButton;
    if (webFeatureEnableNewStatusSystem)
        showRenewButton =
            orderStatus === Constants.orderStatus.CANCELLED ||
            orderStatus === Constants.orderStatus.APPLICATION_EXPIRED;
    if (!webFeatureEnableNewStatusSystem) showRenewButton = reportStatus === Constants.reportStatus.UNRESPONSIVE;

    // LDFlag webFeatureEnableNewStatusSystem
    let canViewReportActions;
    if (webFeatureEnableNewStatusSystem)
        canViewReportActions = canViewReportWithClientAdjudication(applicant, user, applicant.order_status);
    if (!webFeatureEnableNewStatusSystem)
        canViewReportActions = canViewReportWithClientAdjudication(applicant, user, applicant.report_status);

    // LDFlag webFeatureEnableAdverseAction
    const showAdverseAction =
        !!webFeatureEnableAdverseAction && applicant?.is_both_pa_and_aa_available && user.can_initiate_adverse_action;
    const isApplicantInAdverseActionProcess = !!webFeatureEnableAdverseAction && !!applicant?.adverse_action_status;
    if (variant === 'more') {
        // this more variant is used on the report view page so we don't need to check for canViewReportActions
        // as they would have already clicked into the report at this stage
        return (
            <>
                {visible && (
                    <EmailEntryModal
                        handleClose={handleClose}
                        applicantId={applicant.id}
                        language={language}
                        locale={locale}
                    />
                )}
                <Dropdown
                    overlay={
                        <Menu>
                            {active && user.can_view_reports && (
                                <>
                                    <ItemDownloadPDFReport applicantId={applicant.id} />
                                    <ItemDownloadCSVReport applicantId={applicant.id} />
                                    <ItemDownloadConsentDocs applicantId={applicant.id} />
                                </>
                            )}
                            {applicant.application && active && canShareReport() && (
                                <ItemShareReport showSharing={setVisible} />
                            )}
                            {sideBarConfig.shouldShowSideBar && <ItemShowInfoPanel sideBarConfig={sideBarConfig} />}
                        </Menu>
                    }
                    trigger={['click']}
                    placement="bottomCenter"
                >
                    {children}
                </Dropdown>
            </>
        );
    }

    return (
        <>
            {visible && (
                <EmailEntryModal
                    handleClose={handleClose}
                    applicantId={applicant.id}
                    language={language}
                    locale={locale}
                />
            )}
            <Dropdown
                overlay={
                    <Menu>
                        {!isReport && active && user.can_view_reports && canViewReportActions && (
                            <>
                                <ItemViewReport
                                    reportStatus={reportStatus}
                                    orderStatus={orderStatus}
                                    applicantId={applicant.id}
                                />
                                <ItemDownloadPDFReport applicantId={applicant.id} />
                                <ItemDownloadCSVReport applicantId={applicant.id} />
                                <ItemDownloadConsentDocs applicantId={applicant.id} />
                            </>
                        )}

                        {!isApplicantInAdverseActionProcess && applicationStatus !== Constants.applicationStatus.NONE && (
                            <ItemMoveTo
                                applicantId={applicant.id}
                                report={isReport}
                                status={Constants.applicationStatus.NONE}
                                successMessage={intl.formatMessage({
                                    id: '004e5.StatusDropdown.applicationMovedToNone',
                                    defaultMessage: 'Application moved to None',
                                })}
                                listText={
                                    <FormattedMessage
                                        id="004e5.StatusDropdown.removeFromStatus"
                                        defaultMessage="Remove From {status}"
                                        values={{ status: <Titles status={applicationStatus} /> }}
                                    />
                                }
                            />
                        )}

                        {!isApplicantInAdverseActionProcess &&
                            applicationStatus !== Constants.applicationStatus.PROSPECT && (
                                <ItemMoveTo
                                    applicantId={applicant.id}
                                    report={isReport}
                                    status={Constants.applicationStatus.PROSPECT}
                                    successMessage={intl.formatMessage({
                                        id: '004e5.StatusDropdown.applicationMovedToProspects',
                                        defaultMessage: 'Application moved to Prospects',
                                    })}
                                    listText={
                                        <FormattedMessage
                                            id="004e5.StatusDropdown.moveToProspects"
                                            defaultMessage="Move to Prospects"
                                        />
                                    }
                                />
                            )}
                        {!isApplicantInAdverseActionProcess &&
                            applicationStatus !== Constants.applicationStatus.OFFER_PENDING && (
                                <ItemMoveTo
                                    applicantId={applicant.id}
                                    report={isReport}
                                    status={Constants.applicationStatus.OFFER_PENDING}
                                    successMessage={intl.formatMessage({
                                        id: '004e5.StatusDropdown.movedToEmploymentPending',
                                        defaultMessage: 'Application moved to Employment Pending',
                                    })}
                                    listText={
                                        <FormattedMessage
                                            id="004e5.StatusDropdown.moveToEmploymentPending"
                                            defaultMessage="Move to Employment Pending"
                                        />
                                    }
                                />
                            )}
                        {!isApplicantInAdverseActionProcess &&
                            applicationStatus !== Constants.applicationStatus.OFFER_ACCEPTED && (
                                <ItemMoveTo
                                    applicantId={applicant.id}
                                    report={isReport}
                                    status={Constants.applicationStatus.OFFER_ACCEPTED}
                                    successMessage={intl.formatMessage({
                                        id: '004e5.StatusDropdown.movedToEmployees',
                                        defaultMessage: 'Application moved to Employees',
                                    })}
                                    listText={
                                        <FormattedMessage
                                            id="004e5.StatusDropdown.moveToEmployees"
                                            defaultMessage="Move to Employees"
                                        />
                                    }
                                />
                            )}
                        {!isApplicantInAdverseActionProcess &&
                            applicationStatus !== Constants.applicationStatus.ARCHIVED && (
                                <ItemMoveTo
                                    applicantId={applicant.id}
                                    report={isReport}
                                    status={Constants.applicationStatus.ARCHIVED}
                                    successMessage={intl.formatMessage({
                                        id: '004e5.StatusDropdown.movedToArchive',
                                        defaultMessage: 'Application moved to Archive',
                                    })}
                                    listText={
                                        <FormattedMessage
                                            id="004e5.StatusDropdown.moveToArchive"
                                            defaultMessage="Move to Archive"
                                        />
                                    }
                                />
                            )}

                        {showRenewButton && (
                            <ItemRenewExpiredApplicants
                                applicantIds={[applicant.id]}
                                fetchApplicationsMethod={fetchApplications}
                                successMessage={intl.formatMessage({
                                    id: '004e5.StatusDropdown.applicantRenewed',
                                    defaultMessage: 'Application was sent again',
                                })}
                                errorMessage={intl.formatMessage({
                                    id: '004e5.StatusDropdown.failedRenewal',
                                    defaultMessage: 'Failed to renew application',
                                })}
                                listText={
                                    <FormattedMessage
                                        id="004e5.StatusDropdown.resendApplication"
                                        defaultMessage="Resend Application"
                                    />
                                }
                            />
                        )}
                        {applicationStatus === Constants.applicationStatus.ARCHIVED && user.is_staff && (
                            <ItemDelete applicantId={applicant.id} applicantEmail={applicant.email} report={isReport} />
                        )}
                        {showCopyApplicationLink && <ItemCopyLink onboardingLink={applicant.onboarding_link} />}
                        {showAdverseAction && (
                            <ItemAdverseAction
                                applicantId={applicant.id}
                                isReport={!!isReport}
                                isDashboard={isDashboard}
                                isHr
                            />
                        )}

                        {applicant.application &&
                            applicationStatus !== Constants.applicationStatus.ARCHIVED &&
                            active &&
                            !isApplicantInAdverseActionProcess && (
                                <ItemUpgradeAction applicant={applicant} user={user} />
                            )}
                        {isPending && isResponsive && !applicant.has_active_automated_reminders && (
                            <ItemSendReminder applicant={applicant} report={isReport} />
                        )}
                        {applicant.application && active && canShareReport() && canViewReportActions && !isReport && (
                            <ItemShareReport showSharing={setVisible} />
                        )}
                    </Menu>
                }
                trigger={['click']}
                placement="bottomCenter"
            >
                {children}
            </Dropdown>
        </>
    );
};

StatusDropdown.propTypes = {
    report: PropTypes.bool,
    applicant: PropTypes.object.isRequired,
};

StatusDropdown.defaultProps = {
    report: false,
};

export default StatusDropdown;
