import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Dropdown, Menu, Modal } from 'antd';
import { FormattedMessage } from 'react-intl';
import { DownOutlined } from '@ant-design/icons';
import { intl } from 'components/GlobalProvider';
import { ErrorAlertAPI } from 'certn-ui/ErrorAlert';
import { ActionsMenuItems } from './ActionsMenuItems';

// Utils & Modules
import { StatusWrapper, StatusText, TimestampText, ActionsDropdownComponent, StatusSectionWrapper } from './styled';
import Format from 'modules/Format';
import { AdverseActionStatusComponentType } from 'utils/AdverseActionStatusType';
import { isTimestampInPast, timestampText } from 'utils/adverseActionUtils';

// Actions
import {
    cancelAdverseAction,
    pauseAdverseAction,
    restartAdverseAction,
    resumeAdverseAction,
} from 'views/manager/features/AdverseActionDrawer/DrawerActions';

// Types and Constants
import { Dispatch } from 'redux';
import Constants from 'utils/constants';
import { StatusSectionProps } from './types';
import { MenuInfo } from 'rc-menu/lib/interface';
import { ModalFuncProps } from 'antd/lib/modal/Modal';

export const StatusSection: React.FC<StatusSectionProps> = ({ adverseAction }) => {
    const [isActionsLoading, setIsActionsLoading] = useState(false);
    const dispatch = useDispatch();
    const {
        applicant_id: applicantId,
        adverse_action_status: status,
        adverse_action_status_timestamp: timestamp,
        scheduled_at: scheduledAtTimestamp,
    } = adverseAction;

    const onOk = async (handleAction: (aid: string) => (dispatchParam: Dispatch) => Promise<void>) => {
        try {
            await dispatch(handleAction(applicantId));
        } catch (error) {
            ErrorAlertAPI(error);
        }
    };

    const handlePause = () => {
        Modal.confirm({
            centered: true,
            title: intl.formatMessage({
                id: 'AdverseAction.Drawer.StatusSection.PauseModalTitle',
                defaultMessage: 'Pause Adverse Action Notice?',
            }),
            content: intl.formatMessage({
                id: 'AdverseAction.Drawer.StatusSection.PauseModalBody',
                defaultMessage:
                    'You are about to pause the adverse action notice from automatically being sent. You will need to manually resume in order for the adverse action letter to be sent.',
            }),
            okText: intl.formatMessage({
                id: 'AdverseAction.Drawer.StatusSection.PauseModalButton',
                defaultMessage: 'Pause Adverse Action',
            }),
            cancelText: intl.formatMessage({ id: 'common.cancel', defaultMessage: 'Cancel' }),
            onOk: () => onOk(pauseAdverseAction),
        } as ModalFuncProps);
    };

    const handleResume = async () => {
        if (status === Constants.adverseActionStatus.CANCELLED) {
            Modal.confirm({
                centered: true,
                title: intl.formatMessage({
                    id: 'AdverseAction.Drawer.StatusSection.ResumeCancelledModalTitle',
                    defaultMessage: 'Resume Adverse Action?',
                }),
                content: intl.formatMessage(
                    {
                        id: 'AdverseAction.Drawer.StatusSection.ResumeCancelledModalBody',
                        defaultMessage:
                            'You are about to resume the adverse action process which was cancelled on {date}. No updates will be sent to the applicant.',
                    },
                    { date: Format.date(timestamp) }
                ),
                okText: intl.formatMessage({
                    id: 'AdverseAction.Drawer.StatusSection.ResumeCancelledModalButton',
                    defaultMessage: 'Resume Adverse Action',
                }),
                cancelText: intl.formatMessage({ id: 'common.cancel', defaultMessage: 'Cancel' }),
                onOk: () => onOk(resumeAdverseAction),
            } as ModalFuncProps);
            return;
        }

        if (isTimestampInPast(scheduledAtTimestamp)) {
            Modal.confirm({
                centered: true,
                title: intl.formatMessage({
                    id: 'AdverseAction.Drawer.StatusSection.ResumeModalTitle',
                    defaultMessage: 'Send Adverse Action Notice?',
                }),
                content: intl.formatMessage({
                    id: 'AdverseAction.Drawer.StatusSection.ResumeModalBody',
                    defaultMessage:
                        'You are resuming the adverse action process after the waiting period has expired. Resuming will instantly send the adverse action notice email to the applicant. Please preview the contents of the documents to ensure they are correct, as changes cannot be made after submission.',
                }),
                okText: intl.formatMessage({ id: 'common.send', defaultMessage: 'Send' }),
                cancelText: intl.formatMessage({ id: 'common.cancel', defaultMessage: 'Cancel' }),
                onOk: () => onOk(resumeAdverseAction),
            } as ModalFuncProps);
            return;
        }

        setIsActionsLoading(true);
        await onOk(resumeAdverseAction);
        setIsActionsLoading(false);
    };

    const handleCancel = () => {
        Modal.confirm({
            centered: true,
            title: intl.formatMessage({
                id: 'AdverseAction.Drawer.StatusSection.CancelModalTitle',
                defaultMessage: 'Cancel Adverse Action Notice?',
            }),
            content: intl.formatMessage({
                id: 'AdverseAction.Drawer.StatusSection.CancelModalBody',
                defaultMessage:
                    'You are about to cancel the adverse action notice. This will stop the adverse action notice from automatically sending. No communication will be sent to the applicant.',
            }),
            okText: intl.formatMessage({
                id: 'AdverseAction.Drawer.StatusSection.CancelModalButton',
                defaultMessage: 'Cancel Adverse Action Notice',
            }),
            cancelText: intl.formatMessage({
                id: 'AdverseAction.Drawer.StatusSection.GoBackButton',
                defaultMessage: 'Go Back',
            }),
            onOk: () => onOk(cancelAdverseAction),
            okButtonProps: { danger: true },
        } as ModalFuncProps);
    };

    const handleRestart = () => {
        Modal.confirm({
            centered: true,
            title: intl.formatMessage({
                id: 'AdverseAction.Drawer.StatusSection.RestartModalTitle',
                defaultMessage: 'Restart Adverse Action Notice?',
            }),
            content: intl.formatMessage(
                {
                    id: 'AdverseAction.Drawer.StatusSection.RestartModalBody',
                    defaultMessage:
                        'You are about to restart the adverse action process which was cancelled on {date}.',
                },
                { date: Format.date(timestamp) }
            ),
            okText: intl.formatMessage({
                id: 'AdverseAction.Drawer.StatusSection.RestartModalButton',
                defaultMessage: 'Restart Adverse Action',
            }),
            cancelText: intl.formatMessage({ id: 'common.cancel', defaultMessage: 'Cancel' }),
            onOk: () => onOk(restartAdverseAction),
        } as ModalFuncProps);
    };

    const handleActionClick = ({ key }: MenuInfo) => {
        switch (key) {
            case 'pause':
                handlePause();
                break;
            case 'resume':
                handleResume();
                break;
            case 'cancel':
                handleCancel();
                break;
            case 'restart':
                handleRestart();
                break;
            default:
                break;
        }
    };

    return (
        <StatusSectionWrapper>
            <StatusWrapper>
                <StatusText>{AdverseActionStatusComponentType(status)}</StatusText>
                <TimestampText>
                    {timestampText(status, timestamp)}
                    {` ${Format.date(timestamp)}`}
                </TimestampText>
            </StatusWrapper>
            {status !== Constants.adverseActionStatus.COMPLETE && (
                <ActionsDropdownComponent>
                    <Dropdown
                        overlay={
                            <Menu onClick={handleActionClick}>
                                {ActionsMenuItems({ status, scheduledAtTimestamp })}
                            </Menu>
                        }
                        trigger={['click']}
                        placement="bottomLeft"
                    >
                        <Button
                            type="primary"
                            style={{ borderRadius: '4px', marginRight: '12px' }}
                            size="large"
                            loading={isActionsLoading}
                        >
                            <FormattedMessage id="common.actions" defaultMessage="Actions" />
                            <DownOutlined style={{ marginLeft: '6px' }} />
                        </Button>
                    </Dropdown>
                </ActionsDropdownComponent>
            )}
        </StatusSectionWrapper>
    );
};
