import { useIntl } from 'react-intl';
import { Alert, Modal } from 'antd';
import Loader from 'certn-ui/Loader';
import { useEffect, useState } from 'react';

const useMediaQuery = (query: string) => {
    const mediaMatch = window.matchMedia(query);
    const [matches, setMatches] = useState(mediaMatch.matches);

    useEffect(() => {
        const handler = (event: MediaQueryListEvent) => {
            setMatches(event.matches);
        };

        mediaMatch?.addEventListener ? mediaMatch.addEventListener('change', handler) : mediaMatch.addListener(handler);

        return () => {
            mediaMatch?.removeEventListener
                ? mediaMatch.removeEventListener('change', handler)
                : mediaMatch.removeListener(handler);
        };
    });
    return matches;
};

export const TrustmaticModal = ({
    isOpen,
    onClose,
    trustmaticUrl,
    isLoading,
    isError,
}: {
    isOpen: boolean;
    onClose: () => void;
    trustmaticUrl: string;
    isLoading: boolean;
    isError: boolean;
}) => {
    const fullscreenWidth = '940px';
    const baseHeight = '760px';
    const useWideScreenSizes = useMediaQuery(`(min-width: ${fullscreenWidth})`);
    const intl = useIntl();

    return (
        <Modal
            visible={isOpen}
            onCancel={onClose}
            footer={null}
            width={useWideScreenSizes ? fullscreenWidth : '100vw'}
            bodyStyle={{
                height: useWideScreenSizes ? baseHeight : '100vh',
                marginTop: useWideScreenSizes ? '-30px' : '-100px',
                justifyContent: 'center',
                padding: '0px',
            }}
            style={useWideScreenSizes ? undefined : { margin: 0, maxWidth: '100vw' }}
        >
            {isLoading ? (
                <Loader />
            ) : isError ? (
                <Alert
                    type="error"
                    message={intl.formatMessage({
                        id: 'trustmatic.sessionError',
                        defaultMessage: 'Unable to start session. Please contact support.',
                    })}
                />
            ) : (
                trustmaticUrl && (
                    <iframe
                        src={trustmaticUrl}
                        title="Trustmatic"
                        data-testid="trustmatic-modal"
                        style={{
                            height: '760px',
                            width: useWideScreenSizes ? '100%' : '70%',
                            margin: 'auto',
                        }}
                        allow="camera"
                    />
                )
            )}
        </Modal>
    );
};
