// Libraries
import React from 'react';
import { useSelector } from 'react-redux';
import { Form, Input } from 'antd';

// Components
import { RequiredText } from 'certn-ui';
import { intl } from 'components/GlobalProvider';

// Actions & Selectors
import { getSelectedCheckKeys } from 'views/manager/features/ScreenApplicant/ScreenApplicantSelectors';

// Modules
import isVisibleOrRequired from './isVisibleOrRequired';
import { Regex } from 'modules';

// Constants
import { CHECK_REQUEST } from 'base/BaseConstants';

const SSN = () => {
    const selectedServices = useSelector(getSelectedCheckKeys);
    const label = intl.formatMessage({
        id: 'f5ca1.QuickScreen.ssn',
        defaultMessage: 'Social Security Number (SSN):',
    });

    // Checks to display field and set required, true in brackets for all services
    const visibleList = [CHECK_REQUEST.EQUIFAX];
    const requiredList = [];

    if (!isVisibleOrRequired(visibleList, selectedServices)) return null;
    return (
        <>
            {isVisibleOrRequired(requiredList, selectedServices) ? <RequiredText>{label}</RequiredText> : label}
            <Form.Item
                name="ssn"
                initialValue={null}
                rules={[
                    {
                        pattern: Regex.sinssn,
                        message: intl.formatMessage({
                            id: 'error.validation.ssn',
                            defaultMessage: 'Please provide a valid SSN',
                        }),
                    },
                    {
                        required: isVisibleOrRequired(requiredList, selectedServices),
                        message: intl.formatMessage({
                            id: 'error.validation.notBlank',
                            defaultMessage: 'Please do not leave blank',
                        }),
                    },
                ]}
            >
                <Input placeholder={label} />
            </Form.Item>
        </>
    );
};

export default SSN;
