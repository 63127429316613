import { getRequest, postRequest, putRequest, deleteRequest } from 'utils/http';
import { simpleStringifyJSON } from 'modules/Format';

import {} from 'lodash';

import Auth from 'modules/Auth';
import {
    NAMESPACE as PARENT_NAMESPACE,
    STATEPATH as PARENT_STATEPATH,
} from 'views/manager/views/hr/HrManagerConstants';

import {
    getIsActive,
    getSearchBy,
    getOrdering,
    getFilterBy,
    getPageSize,
    getReverseOrder,
    getCurrentPage,
} from './PostingsSelectors';

// Constants
export const NAMESPACE = `${PARENT_NAMESPACE}POSTINGS/`;
export const STATEPATH = `${PARENT_STATEPATH}.postings`;

// Actions Types
export const CLEAR_ERRORS = `${NAMESPACE}CLEAR_ERRORS`;
export const GET_POSTINGS_REQ = `${NAMESPACE}GET_POSTINGS_REQ`;
export const GET_POSTINGS_RES = `${NAMESPACE}GET_POSTINGS_RES`;
export const GET_POSTINGS_ERR = `${NAMESPACE}GET_POSTINGS_ERR`;
export const GET_ALL_POSTINGS_REQ = `${NAMESPACE}GET_ALL_POSTINGS_REQ`;
export const GET_ALL_POSTINGS_RES = `${NAMESPACE}GET_ALL_POSTINGS_RES`;
export const GET_ALL_POSTINGS_ERR = `${NAMESPACE}GET_ALL_POSTINGS_ERR`;
export const GET_POSTING_REQ = `${NAMESPACE}GET_POSTING_REQ`;
export const GET_POSTING_RES = `${NAMESPACE}GET_POSTING_RES`;
export const GET_POSTING_ERR = `${NAMESPACE}GET_POSTING_ERR`;
export const CLEAR_POSTING = `${NAMESPACE}CLEAR_POSTING`;
export const GET_NEW_POSTING_REQ = `${NAMESPACE}GET_NEW_POSTING_REQ`;
export const GET_NEW_POSTING_RES = `${NAMESPACE}GET_NEW_POSTING_RES`;
export const GET_NEW_POSTING_ERR = `${NAMESPACE}GET_NEW_POSTING_ERR`;
export const POST_POSTINGS_REQ = `${NAMESPACE}POST_POSTINGS_REQ`;
export const POST_POSTINGS_RES = `${NAMESPACE}POST_POSTINGS_RES`;
export const POST_POSTINGS_ERR = `${NAMESPACE}POST_POSTINGS_ERR`;
export const SILENT_CLOSE_POSTING_REQ = `${NAMESPACE}SILENT_CLOSE_POSTING_REQ`;
export const CLOSE_POSTING_REQ = `${NAMESPACE}CLOSE_POSTING_REQ`;
export const CLOSE_POSTING_RES = `${NAMESPACE}CLOSE_POSTING_RES`;
export const CLOSE_POSTING_ERR = `${NAMESPACE}CLOSE_POSTING_ERR`;
export const PUT_POSTINGS_REQ = `${NAMESPACE}PUT_POSTINGS_REQ`;
export const PUT_POSTINGS_RES = `${NAMESPACE}PUT_POSTINGS_RES`;
export const PUT_POSTINGS_ERR = `${NAMESPACE}PUT_POSTINGS_ERR`;
export const SILENT_DEL_POSTINGS_REQ = `${NAMESPACE}SILENT_DEL_POSTINGS_REQ`;
export const DEL_POSTINGS_REQ = `${NAMESPACE}DEL_POSTINGS_REQ`;
export const DEL_POSTINGS_RES = `${NAMESPACE}DEL_POSTINGS_RES`;
export const DEL_POSTINGS_ERR = `${NAMESPACE}DEL_POSTINGS_ERR`;
export const GET_TEAM_MEMBERS_REQ = `${NAMESPACE}GET_TEAM_MEMBERS_REQ`;
export const GET_TEAM_MEMBERS_RES = `${NAMESPACE}GET_TEAM_MEMBERS_RES`;
export const GET_TEAM_MEMBERS_ERR = `${NAMESPACE}GET_TEAM_MEMBERS_ERR`;
export const GET_SPECIALIZATIONS_REQ = `${NAMESPACE}GET_SPECIALIZATIONS_REQ`;
export const GET_SPECIALIZATIONS_RES = `${NAMESPACE}GET_SPECIALIZATIONS_RES`;
export const GET_SPECIALIZATIONS_ERR = `${NAMESPACE}GET_SPECIALIZATIONS_ERR`;
export const GET_SKILLS_REQ = `${NAMESPACE}GET_SKILLS_REQ`;
export const GET_SKILLS_RES = `${NAMESPACE}GET_SKILLS_RES`;
export const GET_SKILLS_ERR = `${NAMESPACE}GET_SKILLS_ERR`;
export const GET_DEGREES_REQ = `${NAMESPACE}GET_DEGREES_REQ`;
export const GET_DEGREES_RES = `${NAMESPACE}GET_DEGREES_RES`;
export const GET_DEGREES_ERR = `${NAMESPACE}GET_DEGREES_ERR`;
export const POST_APPLICATIONS_REQ = `${NAMESPACE}POST_APPLICATIONS_REQ`;
export const POST_APPLICATIONS_RES = `${NAMESPACE}POST_APPLICATIONS_RES`;
export const POST_APPLICATIONS_ERR = `${NAMESPACE}POST_APPLICATIONS_ERR`;
export const POST_MERGE_APPLICANTS_REQ = `${NAMESPACE}POST_MERGE_APPLICANTS_REQ`;
export const POST_MERGE_APPLICANTS_RES = `${NAMESPACE}POST_MERGE_APPLICANTS_RES`;
export const POST_MERGE_APPLICANTS_ERR = `${NAMESPACE}POST_MERGE_APPLICANTS_ERR`;
export const ADD_SKILL_REQ = `${NAMESPACE}ADD_SKILL_REQ`;
export const ADD_SKILL_RES = `${NAMESPACE}ADD_SKILL_RES`;
export const ADD_SKILL_ERR = `${NAMESPACE}ADD_SKILL_ERR`;

// List Actions Types
export const SET_IS_ACTIVE = `${NAMESPACE}SET_IS_ACTIVE`;
export const SET_SEARCH_BY = `${NAMESPACE}SET_SEARCH_BY`;
export const SET_ORDERING = `${NAMESPACE}SET_ORDERING`;
export const SET_PAGE_SIZE = `${NAMESPACE}SET_PAGE_SIZE`;
export const SET_FILTER_BY = `${NAMESPACE}SET_FILTER_BY`;
export const CLEAR_FILTERS = `${NAMESPACE}CLEAR_FILTERS`;
export const RESET_LIST = `${NAMESPACE}RESET_LIST`;

// Action Creators
export const fetchPostings = (page = null, silent = false) => (dispatch, getState) => {
    const state = getState();
    const isActive = getIsActive(state) ? 'True' : 'False';
    const searchBy = getSearchBy(state);
    const ordering = getOrdering(state);
    const reverseOrder = getReverseOrder(state);
    const filterBy = getFilterBy(state);
    const pageSize = getPageSize(state);
    const currentPage = getCurrentPage(state);

    const nextPage = page || currentPage;

    let queryParams = `?is_active=${isActive}&page=${nextPage}&page_size=${pageSize}`;
    let filterQueryParams = '';
    if (searchBy) queryParams += `&search=${searchBy}`;
    if (ordering) queryParams += `&ordering=${reverseOrder ? `-${ordering}` : ordering}`;
    if (filterBy) {
        Object.keys(filterBy).forEach((filterKey) => {
            filterQueryParams += `&${filterKey}=${filterBy[filterKey]}`;
        });
        queryParams += filterQueryParams;
    }

    if (!silent) {
        dispatch({
            type: GET_POSTINGS_REQ,
        });
    }
    return getRequest({
        hr: true,
        version: 'v1',
        endpoint: `/listings/${queryParams}`,
    }).then(
        (response) => {
            dispatch({
                type: GET_POSTINGS_RES,
                payload: {
                    postings: response.results,
                    numPostings: response.count,
                    previousPage: response.previous,
                    nextPage: response.next,
                    currentPage: nextPage,
                },
            });
        },
        (error) => {
            dispatch({
                type: GET_POSTINGS_ERR,
                payload: { error },
            });
        }
    );
};

export const fetchAllPostings = () => (dispatch) => {
    dispatch({
        type: GET_ALL_POSTINGS_REQ,
    });
    return getRequest({
        hr: true,
        version: 'v1',
        endpoint: `/listings/?is_active=true&page=1&page_size=9001`,
    }).then(
        (response) => {
            dispatch({
                type: GET_ALL_POSTINGS_RES,
                payload: {
                    allPostings: response.results,
                    numAllPostings: response.count,
                },
            });
        },
        (error) => {
            dispatch({
                type: GET_ALL_POSTINGS_ERR,
                payload: { error },
            });
        }
    );
};

export const fetchPosting = (postingId, relist = false) => (dispatch) => {
    dispatch({
        type: GET_POSTING_REQ,
    });
    return getRequest({
        hr: true,
        version: 'v1',
        endpoint: `/listings/${postingId}/`,
    }).then(
        (response) => {
            if (relist) {
                dispatch({
                    type: GET_NEW_POSTING_RES,
                    payload: {
                        newPosting: response,
                    },
                });
            } else {
                dispatch({
                    type: GET_POSTING_RES,
                    payload: {
                        posting: response,
                    },
                });
            }
        },
        (error) => {
            dispatch({
                type: GET_POSTING_ERR,
                payload: { error },
            });
        }
    );
};

export const clearPosting = () => (dispatch) => {
    dispatch({
        type: CLEAR_POSTING,
        payload: {
            posting: {},
        },
    });
};

export const addSkill = (skill, verified = false) => (dispatch) => {
    dispatch({
        type: ADD_SKILL_REQ,
    });
    return postRequest({
        hr: true,
        version: 'v1',
        endpoint: '/skills/',
        body: JSON.stringify({
            skill,
            verified,
        }),
    }).then(
        (response) => {
            dispatch({
                type: ADD_SKILL_RES,
                payload: { skill: response },
            });
        },
        (error) => {
            dispatch({
                type: ADD_SKILL_ERR,
                payload: error,
            });
            throw error;
        }
    );
};

export const createPosting = (formData) => (dispatch) => {
    formData.owner_id = Auth.getUserID();
    dispatch({
        type: POST_POSTINGS_REQ,
    });
    return postRequest({
        hr: true,
        version: 'v1',
        endpoint: '/listings/',
        body: JSON.stringify(formData),
    }).then(
        (response) => {
            dispatch({
                type: POST_POSTINGS_RES,
                payload: {
                    posting: response,
                    newPosting: response,
                },
            });
            dispatch(fetchAllPostings());
            return response;
        },
        (error) => {
            dispatch({
                type: POST_POSTINGS_ERR,
                payload: error,
            });
            throw error;
        }
    );
};

export const editPosting = (postingId, formData) => (dispatch) => {
    dispatch({
        type: PUT_POSTINGS_REQ,
    });

    return putRequest({
        hr: true,
        version: 'v1',
        endpoint: `/listings/${postingId}/`,
        body: JSON.stringify(simpleStringifyJSON(formData)),
    }).then(
        (response) => {
            dispatch({
                type: PUT_POSTINGS_RES,
                payload: {
                    posting: response,
                },
            });
            dispatch(fetchAllPostings());
        },
        (error) => {
            dispatch({
                type: PUT_POSTINGS_ERR,
                payload: error,
            });
            throw error;
        }
    );
};

export const deletePosting = (postingId) => (dispatch) => {
    dispatch({
        type: SILENT_DEL_POSTINGS_REQ,
    });
    return deleteRequest({
        hr: true,
        version: 'v1',
        endpoint: `/listings/${postingId}/`,
    }).then(
        () => {
            dispatch({
                type: DEL_POSTINGS_RES,
            });
            return dispatch(fetchPostings(null, true));
        },
        (error) => {
            dispatch({
                type: DEL_POSTINGS_ERR,
                payload: error,
            });
        }
    );
};

export const togglePostingInactive = (postingId) => (dispatch) => {
    dispatch({
        type: SILENT_CLOSE_POSTING_REQ,
    });
    return putRequest({
        hr: true,
        version: 'v1',
        endpoint: `/listings/${postingId}/`,
        body: JSON.stringify({ is_active: false }),
    }).then(
        () => {
            dispatch({
                type: CLOSE_POSTING_RES,
            });
            return dispatch(fetchPostings(null, true));
        },
        (error) => {
            dispatch({
                type: CLOSE_POSTING_ERR,
                payload: { error },
            });
        }
    );
};

export const sendApplications = (email, postingId, language, upgrades = {}) => (dispatch) => {
    const lowercaseEmail = email.toLowerCase().replace(/\s+/g, '');
    const bodyData = {
        email: lowercaseEmail,
        listing_id: postingId,
        language,
        ...upgrades,
    };
    dispatch({
        type: POST_APPLICATIONS_REQ,
    });
    return postRequest({
        hr: true,
        version: 'v1',
        endpoint: '/applications/invite/',
        body: JSON.stringify(simpleStringifyJSON(bodyData)),
    }).then(
        (response) => {
            dispatch({
                type: POST_APPLICATIONS_RES,
                payload: {
                    application: response,
                },
            });
        },
        (error) => {
            dispatch({
                type: POST_APPLICATIONS_ERR,
                payload: { error },
            });
            throw new Error();
        }
    );
};

export const fetchTeamMembers = () => (dispatch) => {
    dispatch({
        type: GET_TEAM_MEMBERS_REQ,
    });
    return getRequest({
        version: 'v2',
        endpoint: '/team_members/',
    }).then(
        (response) => {
            dispatch({
                type: GET_TEAM_MEMBERS_RES,
                payload: {
                    teamMembers: response,
                },
            });
        },
        (error) => {
            dispatch({
                type: GET_TEAM_MEMBERS_ERR,
                payload: { error },
            });
        }
    );
};

export const fetchSpecializations = () => (dispatch) => {
    dispatch({
        type: GET_SPECIALIZATIONS_REQ,
    });
    return getRequest({
        hr: true,
        version: 'v1',
        endpoint: '/specializations/',
    }).then(
        (response) => {
            dispatch({
                type: GET_SPECIALIZATIONS_RES,
                payload: {
                    specializations: response,
                },
            });
        },
        (error) => {
            dispatch({
                type: GET_SPECIALIZATIONS_ERR,
                payload: { error },
            });
        }
    );
};

export const fetchSkills = () => (dispatch) => {
    dispatch({
        type: GET_SKILLS_REQ,
    });
    return getRequest({
        hr: true,
        version: 'v1',
        endpoint: '/skills/',
    }).then(
        (response) => {
            dispatch({
                type: GET_SKILLS_RES,
                payload: {
                    skills: response,
                },
            });
        },
        (error) => {
            dispatch({
                type: GET_SKILLS_ERR,
                payload: { error },
            });
        }
    );
};

export const fetchDegrees = () => (dispatch) => {
    dispatch({
        type: GET_DEGREES_REQ,
    });
    return getRequest({
        hr: true,
        version: 'v1',
        endpoint: '/degrees/',
    }).then(
        (response) => {
            dispatch({
                type: GET_DEGREES_RES,
                payload: {
                    degrees: response,
                },
            });
        },
        (error) => {
            dispatch({
                type: GET_DEGREES_ERR,
                payload: { error },
            });
        }
    );
};

export const clearErrors = () => (dispatch) =>
    dispatch({
        type: CLEAR_ERRORS,
        payload: {
            error: undefined,
        },
    });

// List Action Creators
export const setIsActive = (isActive = true) => (dispatch, getState) => {
    const currentIsActive = getIsActive(getState());
    dispatch({
        type: SET_IS_ACTIVE,
        payload: {
            isActive,
        },
    });
    if (currentIsActive === isActive) return dispatch(fetchPostings());
    return dispatch(fetchPostings(1));
};

export const setSearchBy = (searchBy) => (dispatch) => {
    dispatch({
        type: SET_SEARCH_BY,
        payload: {
            searchBy,
        },
    });
    return dispatch(fetchPostings(1));
};

export const setOrdering = (ordering) => (dispatch, getState) => {
    const currentOrdering = getOrdering(getState());
    const reverseOrder = currentOrdering === ordering ? !getReverseOrder(getState()) : false;
    dispatch({
        type: SET_ORDERING,
        payload: {
            ordering,
            reverseOrder,
        },
    });
    return dispatch(fetchPostings(1));
};

export const setPageSize = (pageSize) => (dispatch) => {
    dispatch({
        type: SET_PAGE_SIZE,
        payload: {
            pageSize,
        },
    });
};

export const setFilterBy = (filterBy) => (dispatch) => {
    dispatch({
        type: SET_FILTER_BY,
        payload: {
            filterBy,
        },
    });
    return dispatch(fetchPostings(1));
};

export const clearFilters = () => (dispatch) => {
    dispatch({
        type: CLEAR_FILTERS,
        payload: {
            filterBy: {},
        },
    });
    return dispatch(fetchPostings(1));
};

export const resetListAndFetch = () => (dispatch) => {
    dispatch({
        type: RESET_LIST,
        payload: {},
    });
    return dispatch(fetchPostings(1));
};

export const resetListAndFilter = (filterBy) => (dispatch) => {
    dispatch({
        type: RESET_LIST,
        payload: {},
    });
    return dispatch(setFilterBy(filterBy));
};
