import React from 'react';
import PropTypes from 'prop-types';
import { IntlProvider, useIntl } from 'react-intl';
import { ConfigProvider } from 'antd';
import enUS from 'antd/lib/locale/en_US';
import frFR from 'antd/lib/locale/fr_FR';
import esES from 'antd/lib/locale/es_ES';

import EnglishTranslations from '../i18n/en.json';
import FrenchTranslations from '../i18n/fr.json';
import SpanishTranslations from '../i18n/es.json';
import { LANGUAGE_EN } from '../utils/constants';

// only use this with non-components (outside of React life-cycle)
// eslint-disable-next-line import/no-mutable-exports
export let intl = null;
// eslint-disable-next-line import/no-mutable-exports
export let locale = null;

export const localeMessages = {
    en: EnglishTranslations,
    fr: FrenchTranslations,
    es: SpanishTranslations,
};

const localeAntdFiles = {
    en: enUS,
    fr: frFR,
    es: esES,
};

const IntlRoot = ({ children }) => {
    // Can only access intl inside the <IntlProvider> component
    intl = useIntl();
    return children;
};

export const GlobalProvider = ({ locale: localeProp, language = locale, children }) => {
    locale = localeProp;
    const antdLocale = localeAntdFiles[locale] || enUS;
    const messages = localeMessages[language] || localeMessages.en;
    return (
        <IntlProvider key={locale} locale={locale} messages={messages}>
            <IntlRoot>
                <ConfigProvider locale={antdLocale}>{children}</ConfigProvider>
            </IntlRoot>
        </IntlProvider>
    );
};

GlobalProvider.propTypes = {
    locale: PropTypes.string,
    language: PropTypes.string,
};

GlobalProvider.defaultProps = {
    locale: LANGUAGE_EN,
    language: LANGUAGE_EN,
};
