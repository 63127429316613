import { isDateInPast } from 'modules/validation';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import Constants from 'utils/constants';
import { AdverseActionStatusType } from 'views/manager/features/AdverseActionDrawer/types';

export type applicationType = {
    information?: Record<string, unknown> | null;
    applicant_account?: Record<string, unknown> | null;
};

export const getFullName = (application: applicationType): string => {
    const { first_name = '', last_name = '' } = application?.information || {};
    return `${first_name} ${last_name}`;
};

export const getApplicantEmail = (application: applicationType): string =>
    String(application?.applicant_account?.email ?? '');

const regexToCheckIfHR = /^\/hr\/applications/;

export const isHRApplication = (path: string | undefined | null): boolean => {
    if (!path) return false;
    return regexToCheckIfHR.test(path);
};

export const isTimestampInPast = (timestamp?: string) => isDateInPast(moment(timestamp));

export const timestampText = (status: AdverseActionStatusType, timestamp?: string) => {
    switch (status) {
        case Constants.adverseActionStatus.PAUSED:
            if (isTimestampInPast(timestamp)) {
                return (
                    <FormattedMessage
                        id="AdverseAction.Drawer.StatusSection.OriginallyScheduledFor"
                        defaultMessage="Originally Scheduled for"
                    />
                );
            }
            return (
                <FormattedMessage id="AdverseAction.Drawer.StatusSection.ScheduledFor" defaultMessage="Scheduled for" />
            );
        case Constants.adverseActionStatus.CANCELLED:
            return (
                <FormattedMessage id="AdverseAction.Drawer.StatusSection.CancelledOn" defaultMessage="Cancelled on" />
            );
        case Constants.adverseActionStatus.COMPLETE:
        case Constants.adverseActionStatus.UNDELIVERABLE:
            return <FormattedMessage id="AdverseAction.Drawer.StatusSection.SentOn" defaultMessage="Sent on" />;
        case Constants.adverseActionStatus.SCHEDULED:
        case Constants.adverseActionStatus.IN_DISPUTE:
        default:
            return (
                <FormattedMessage id="AdverseAction.Drawer.StatusSection.ScheduledFor" defaultMessage="Scheduled for" />
            );
    }
};
