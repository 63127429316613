// libraries
import React from 'react';
import { Form } from '@ant-design/compatible';
import { Row, Input } from 'antd';
import { FormattedMessage } from 'react-intl';

// Components
import Title from 'certn-ui/Title';
import Text from 'certn-ui/Text';
import { AppearRight } from 'certn-ui/Animate';
import { TrackNavigation } from 'views/welcome/components';

const { TextArea } = Input;

const PageOneForm = ({ form, handleSubmit, information, intl }) => (
    <AppearRight>
        <Form layout="horizontal" onSubmit={(e) => handleSubmit(e, form.validateFields)}>
            <Title>
                <FormattedMessage
                    id="welcome.CoverLetter.title"
                    defaultMessage="Do you want to add a cover letter to your application?"
                />
            </Title>
            <Row gutter={16}>
                <Form.Item>
                    {form.getFieldDecorator('cover_letter', {
                        initialValue: information.cover_letter || undefined,
                    })(
                        <TextArea
                            autoFocus
                            data-testid="cover_letter"
                            placeholder={intl.formatMessage({
                                id: 'welcome.CoverLetter.coverLetter',
                                defaultMessage: 'Paste cover letter here',
                            })}
                            autosize={{ minRows: 4 }}
                        />
                    )}
                </Form.Item>
            </Row>
            <Text align="center" color="certnGray600">
                <FormattedMessage
                    id="welcome.CoverLetter.coverLetterDesc"
                    defaultMessage="If you have a cover letter saved in a document already, you can upload it on the next page."
                />
            </Text>
            <TrackNavigation />
        </Form>
    </AppearRight>
);

export default Form.create()(PageOneForm);
