import { css } from 'styled-components';

export const flexCol = css`
    display: flex;
    flex-direction: column;
`;

export const flexRow = css`
    display: flex;
    flex-direction: row;
`;

export const flexRowStretch = css`
    ${flexRow};
    align-items: stretch;
`;

export const flexColStretch = css`
    ${flexCol};
    align-items: stretch;
`;

export const flexColCenter = css`
    ${flexCol};
    align-items: center;
`;

export const flexAlignCenter = css`
    display: flex;
    align-items: center;
`;

export const flexCenter = css`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const flexCenterStretch = css`
    display: flex;
    justify-content: center;
    align-items: stretch;
`;

export const cssFill = css`
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
`;

export const ellipsis = css`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;
