// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import { intl } from 'components/GlobalProvider';

// UI Components
import { ButtonLine } from 'certn-ui/Button';
import { Box } from 'certn-ui/Layout';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import { Input, Button } from 'antd';
import { FormItemExtraLink } from 'certn-ui/FormItemExtra';
import styles from 'styles/styles';

import { Path } from 'modules/';
import config from 'utils/config';

import SSO from './SSO';

const propTypes = {
    // Parent Props
    onSubmit: PropTypes.func.isRequired,
};
const defaultProps = {};

const LoginForm = (props) => {
    const { getFieldDecorator } = props.form;

    const handleSubmit = (e) => {
        e.preventDefault();
        props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                props.onSubmit(values);
            }
        });
    };

    return (
        <Box size="xs">
            <Form onSubmit={handleSubmit} style={{ width: '100%' }}>
                <Form.Item>
                    {getFieldDecorator('email', {
                        rules: [
                            {
                                type: 'email',
                                required: true,
                                message: (
                                    <FormattedMessage
                                        id="ef184.LoginForm.inputEmail"
                                        defaultMessage="Please input your email!"
                                    />
                                ),
                            },
                        ],
                    })(
                        <Input
                            size="large"
                            data-testid="email"
                            prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                            placeholder={intl.formatMessage({
                                id: 'common.email',
                                defaultMessage: 'Email',
                            })}
                        />
                    )}
                </Form.Item>
                <Form.Item
                    extra={
                        <FormItemExtraLink>
                            <Link data-testid="forgot-password" to="/forgot">
                                <FormattedMessage
                                    id="ef184.LoginForm.forgotPassword"
                                    defaultMessage="Forgot your password?"
                                />
                            </Link>
                        </FormItemExtraLink>
                    }
                >
                    {getFieldDecorator('password', {
                        rules: [
                            {
                                required: true,
                                message: (
                                    <FormattedMessage
                                        id="ef184.LoginForm.inputPassword"
                                        defaultMessage="Please input your Password!"
                                    />
                                ),
                            },
                        ],
                    })(
                        <Input
                            size="large"
                            data-testid="password"
                            prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                            type="password"
                            placeholder={intl.formatMessage({
                                id: 'common.password',
                                defaultMessage: 'Password',
                            })}
                        />
                    )}
                </Form.Item>
                <Button
                    data-testid="submit"
                    type="primary"
                    size="large"
                    certncolor="primary"
                    htmlType="submit"
                    style={{ width: '100%', marginBottom: '16px' }}
                    loading={props.loading}
                >
                    <FormattedMessage id="ef184.LoginForm.login" defaultMessage="Log in" />
                </Button>
                {(Path.isLimeSubdomain() || config.isNotProduction) && (
                    <div style={{ textAlign: 'center', color: styles.color.certnGray500 }}>
                        <FormattedMessage
                            id="loginForm.noAccount"
                            defaultMessage="Don't have an account? {signup}"
                            values={{
                                signup: (
                                    <Link data-testid="sign-up" to="/welcome/signUp?type=SMB&teamType=HR&country=us">
                                        <FormattedMessage id="loginForm.signUp" defaultMessage="Sign up here" />
                                    </Link>
                                ),
                            }}
                        />
                    </div>
                )}
                <ButtonLine>
                    <SSO />
                </ButtonLine>
            </Form>
        </Box>
    );
};

LoginForm.propTypes = propTypes;
LoginForm.defaultProps = defaultProps;

export default Form.create()(injectIntl(LoginForm));
