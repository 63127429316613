import { Popover, Input } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import React from 'react';

const CountyInput = (props) => {
    const {
        isUkCheck,
        oneIDData,
        required,
        intl,
        hideLabels,
        getFieldDecorator,
        ids,
        county,
        country,
        disabled,
    } = props;

    return (
        <Form.Item
            hasFeedback={isUkCheck}
            style={{ display: required ? 'block' : 'none', marginBottom: '5px' }}
            label={!hideLabels ? intl.formatMessage({ id: 'common.county', defaultMessage: 'County' }) : null}
        >
            {getFieldDecorator(ids.county, {
                initialValue: oneIDData.county || county || undefined,
                rules: [
                    {
                        validator: (rule, value, callback) => {
                            if (country === 'US' && !county) {
                                callback(
                                    intl.formatMessage({
                                        id: 'error.validation.county',
                                        defaultMessage: 'Please enter a county',
                                    })
                                );
                            } else {
                                callback();
                            }
                        },
                    },
                ],
            })(
                <Input
                    size="large"
                    data-testid="county"
                    type="text"
                    placeholder={
                        hideLabels
                            ? intl.formatMessage({
                                  id: 'addressAuto.county',
                                  defaultMessage: 'County',
                              })
                            : null
                    }
                    addonBefore={
                        <Popover
                            content={intl.formatMessage({
                                id: 'addressAuto.county',
                                defaultMessage: 'County',
                            })}
                        >
                            <InfoCircleOutlined />
                        </Popover>
                    }
                    disabled={disabled && oneIDData.county}
                />
            )}
        </Form.Item>
    );
};

export default CountyInput;
