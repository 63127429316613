import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { CloseOutlined, EditFilled } from '@ant-design/icons';
import { Popconfirm, Button, Divider } from 'antd';
import { useIntl } from 'react-intl';

const CustomButton = styled(Button)`
    margin: 0 2px;
    border: none !important;
    box-shadow: none !important;
    background-color: ${({ theme }) => theme.color.certnGray100} !important;
`;

const MultiAddContainer = styled.div`
    width: 100%;
    max-width: 600px;
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    padding: 10px;
    box-sizing: border-box;
`;

const FlexLine = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px 0;
    box-sizing: border-box;
`;

const Title = styled.span`
    text-transform: uppercase;
    color: ${({ theme }) => theme.color.primary};
    font-size: ${({ theme }) => theme.font.sm};
    box-sizing: border-box;
`;

export const EmptyListContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
    color: ${({ theme }) => theme.color.certnGray400};
    border-radius: 4px;
    border: 1px dashed ${({ theme }) => theme.color.certnGray400};
`;

const MultiAddItemContainer = styled.div`
    width: 100%;
    display: flex;
    box-sizing: border-box;
    border: 1px solid ${({ theme }) => theme.color.certnGray200};
    padding: 15px;
    border-radius: 5px;
    margin: 10px 0px;
    background-color: ${({ theme }) => theme.color.certnGray100};
`;

const ItemHalf = styled.div`
    flex: ${({ flex }) => flex || 1};
    display: flex;
    flex-direction: ${({ direction }) => direction || 'row'};
    justify-content: flex-end;
    align-items: ${({ align }) => align || 'center'};
`;

const ItemHalfText = styled.div`
    text-align: left;
    font-size: ${({ size, theme }) => theme.font[size || 'xs']};
    font-weight: 300 !important;
    color: ${({ theme, color }) => theme.color[color]};
`;

const UppercaseButton = styled(Button)`
    text-transform: uppercase;
`;

MultiAdd.propTypes = {
    buttonTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    buttonAction: PropTypes.func.isRequired,
    disableAdd: PropTypes.bool,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    testKey: PropTypes.string,
};

MultiAdd.defaultProps = {
    title: 'Add Items',
    disableAdd: false,
    testKey: 'generic',
};

function MultiAdd({ children, title, buttonTitle, buttonAction, disableAdd, testKey }) {
    return (
        <MultiAddContainer>
            <FlexLine>
                <Title>{title}</Title>
            </FlexLine>
            {children}
            {!disableAdd && (
                <FlexLine justify="flex-end">
                    <UppercaseButton data-testid={`add-${testKey}`} onClick={buttonAction}>
                        {buttonTitle}
                    </UppercaseButton>
                </FlexLine>
            )}
        </MultiAddContainer>
    );
}

export default MultiAdd;

MultiAddItem.propTypes = {
    top: PropTypes.string.isRequired,
    onEdit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    bottom: PropTypes.node,
    canDelete: PropTypes.bool,
    deleteText: PropTypes.string,
    testKey: PropTypes.string,
};

MultiAddItem.defaultProps = {
    bottom: undefined,
    canDelete: true,
    deleteText: undefined,
    testKey: 'generic',
};

export function MultiAddItem({ top, bottom, onEdit, onDelete, canDelete, deleteText, testKey }) {
    const intl = useIntl();

    return (
        <MultiAddItemContainer>
            <ItemHalf flex={2} direction="column" align="flex-start">
                <ItemHalfText size="sm" color="certnGray600">
                    {top}
                </ItemHalfText>
                <ItemHalfText color="certnGray400">{bottom}</ItemHalfText>
            </ItemHalf>
            <ItemHalf>
                <Divider type="vertical" style={{ height: '100%' }} />
                <CustomButton
                    data-testid={`edit-${testKey}`}
                    size="large"
                    icon={<EditFilled />}
                    shape="circle"
                    onClick={onEdit}
                />
                {canDelete && (
                    <Popconfirm
                        placement="bottomRight"
                        title={
                            deleteText ||
                            intl.formatMessage({
                                id: 'multiAddItem.deleteThisEntry',
                                defaultMessage: 'Delete this entry?',
                            })
                        }
                        onConfirm={onDelete}
                        okText={intl.formatMessage({ id: 'common.yes', defaultMessage: 'Yes' })}
                        cancelText={intl.formatMessage({ id: 'common.no', defaultMessage: 'No' })}
                    >
                        <CustomButton
                            data-testid={`delete-${testKey}`}
                            size="large"
                            icon={<CloseOutlined />}
                            shape="circle"
                        />
                    </Popconfirm>
                )}
            </ItemHalf>
        </MultiAddItemContainer>
    );
}
