import styled from 'styled-components';

export const BiometricConsentWrapper = styled.div`
    & {
        max-width: 800px;
        margin-top: 0.5rem;
    }
    & .ps-contract {
        padding: 0px !important;
        max-height: 500px;
    }
    & .ps-contract-title {
        display: none !important;
    }
`;
