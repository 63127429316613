class GooglePlaces {
    static initGooglePlaces = () => {
        if (
            typeof window.google !== 'undefined' &&
            typeof window.google.maps !== 'undefined' &&
            typeof window.google.maps.places !== 'undefined'
        ) {
            // TODO: create a autocomplete location services module
            window.acsAPI = new window.google.maps.places.AutocompleteService();
            window.geoAPI = new window.google.maps.Geocoder();
            // TODO: move this ip stuff to a higher level
            const fetchIpHeaders = new Headers();
            // fetchIpHeaders.append('Content-Type', 'application/json');
            fetch('https://ipapi.co/json/', { method: 'GET', headers: fetchIpHeaders })
                .then((response) => response.json())
                .then((blob) => {
                    window.acsLoc = new window.google.maps.LatLng({ lat: blob.latitude, lng: blob.longitude });
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    };
}

export default GooglePlaces;
