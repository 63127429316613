import styled from 'styled-components/macro';

const FlexWrapper = styled.div`
    display: flex;
    justify-content: ${(props) => (props.center ? 'center' : 'flex-start')};
    flex-direction: ${(props) => (props.col ? 'column' : 'row')};
    align-items: ${(props) => (props.centerAlign ? 'center' : 'inherit')};
    transition: 0.3s;
    ${(props) => props.margin && 'margin: 2px 0px !important'};
    ${({ active, theme }) => active && `color: ${theme.color.certnGreen600}`};
    &:hover {
        cursor: ${(props) => (props.clickable ? 'pointer' : 'inherit')};
        ${({ header, clickable, active, theme }) =>
            header && clickable && !active && `color: ${theme.color.certnGray400}`};
    }
`;

export default FlexWrapper;
