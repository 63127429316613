import {
    requestReducer,
    silentRequestReducer,
    responseReducer,
    errorReducer,
    fetchingReducer,
    defaultState as defaultHttpState,
} from 'utils/http';
import { ActionTypes } from 'views/manager/views/settings/SettingsConstants';
import {
    GET_SETTINGS_REQ,
    GET_SETTINGS_RES,
    GET_SETTINGS_ERR,
    PATCH_SETTINGS_REQ,
    PATCH_SETTINGS_RES,
    PATCH_SETTINGS_ERR,
    SAVE_CARD_TOKEN_REQ,
    SAVE_CARD_TOKEN_RES,
    SAVE_CARD_TOKEN_ERR,
    FETCH_TEAM_MEMBERS_REQ,
    FETCH_TEAM_MEMBERS_RES,
    FETCH_TEAM_MEMBERS_ERR,
    FETCH_ALL_TEAM_MEMBERS_REQ,
    FETCH_ALL_TEAM_MEMBERS_RES,
    FETCH_ALL_TEAM_MEMBERS_ERR,
    CREATE_GROUP_REQ,
    CREATE_GROUP_RES,
    CREATE_GROUP_ERR,
    FETCH_GROUPS_REQ,
    FETCH_GROUPS_RES,
    FETCH_GROUPS_ERR,
    FETCH_INVOICES_REQ,
    FETCH_INVOICES_RES,
    GET_TEAM_SETTINGS_RES,
    FETCH_INTEGRATIONS_REQ,
    FETCH_INTEGRATIONS_RES,
    FETCH_INTEGRATIONS_ERR,
    FETCH_REFERENCE_TEMPLATES_REQ,
    FETCH_REFERENCE_TEMPLATES_RES,
    CREATE_REFERENCE_TEMPLATE_REQ,
    CREATE_REFERENCE_TEMPLATE_RES,
    UPDATE_REFERENCE_TEMPLATE_REQ,
    UPDATE_REFERENCE_TEMPLATE_RES,
    DELETE_REFERENCE_TEMPLATE_REQ,
    DELETE_REFERENCE_TEMPLATE_RES,
    FETCH_API_KEYS_RES,
    FETCH_API_KEYS_REQ,
    CREATE_API_KEYS_RES,
    CREATE_API_KEYS_REQ,
    CREATE_API_KEYS_ERR,
    DELETE_API_KEYS_RES,
    DELETE_API_KEYS_REQ,
    DELETE_API_KEYS_ERR,
    FETCH_INTEGRATION_PACKAGES_RES,
    FETCH_INTEGRATION_PACKAGES_REQ,
    DELETE_INTEGRATION_PACKAGES_RES,
    DELETE_INTEGRATION_PACKAGES_REQ,
    UPDATE_INTEGRATION_PACKAGES_REQ,
    UPDATE_INTEGRATION_PACKAGES_RES,
    UPDATE_INTEGRATION_REQ,
    UPDATE_INTEGRATION_RES,
    CREATE_INTEGRATION_PACKAGES_RES,
    GET_BULK_INVOICE_CSV_REQ,
    GET_BULK_INVOICE_CSV_RES,
    FETCH_SUPERTEAM_ADMINS_REQ,
    FETCH_SUPERTEAM_ADMINS_RES,
    FETCH_SUPERTEAM_ADMINS_ERR,
    SET_INTEGRATION_PACKAGE_OPTIONS_RES,
    RESET_INTEGRATION_PACKAGE_OPTIONS_RES,
    GENERATE_ICIMS_CREDENTIALS_REQ,
    GENERATE_ICIMS_CREDENTIALS_RES,
    DISPLAY_ERROR_NOTIFICATION,
} from './SettingsActions';
import styles from 'styles/styles';
import { CERTN_IMAGES_S3 } from 'base/BaseConstants';

const initialState = {
    ...defaultHttpState,
    settings: {
        org_logo_link: `${CERTN_IMAGES_S3}/logobeta.svg`,
        primaryColor: styles.color.certnGreen700,
    },
    settingsError: undefined,
    isFetching_teamMembers: false,
    isFetching_groups: false,
    invoices: [],
    integrations: {},
    integrationPackages: {
        LEVER: [],
        JOBADDER: [],
        WORKDAY: [],
        TALEO: [],
        ICIMS: [],
        FOUNTAIN: [],
        SUCCESS_FACTORS: [],
    },
    integrationPackageOptions: {},
    referenceTemplates: {
        employer: [],
        address: [],
    },
    allSuperTeamUsers: [],
    webhookSecret: {},
};

const settings = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_GROUPS_REQ:
            return {
                ...state,
                ...fetchingReducer('groups', true),
            };
        case FETCH_GROUPS_RES:
            return {
                ...state,
                ...fetchingReducer('groups', false),
                groups: action.payload.groups,
            };
        case FETCH_GROUPS_ERR:
            return {
                ...state,
                ...errorReducer(),
            };
        case FETCH_INVOICES_REQ:
        case FETCH_REFERENCE_TEMPLATES_REQ:
        case CREATE_REFERENCE_TEMPLATE_REQ:
        case UPDATE_REFERENCE_TEMPLATE_REQ:
        case DELETE_REFERENCE_TEMPLATE_REQ:
        case FETCH_API_KEYS_REQ:
        case GENERATE_ICIMS_CREDENTIALS_REQ:
        case UPDATE_INTEGRATION_REQ:
        case DELETE_INTEGRATION_PACKAGES_REQ:
        case UPDATE_INTEGRATION_PACKAGES_REQ:
        case FETCH_INTEGRATION_PACKAGES_REQ:
            return {
                ...state,
                ...requestReducer(),
            };
        case FETCH_INVOICES_RES:
            return {
                ...state,
                ...responseReducer(),
                invoices: action.payload.invoices,
            };
        case DISPLAY_ERROR_NOTIFICATION:
            return {
                ...state,
                ...errorReducer(),
            };
        case FETCH_REFERENCE_TEMPLATES_RES:
            return {
                ...state,
                ...responseReducer(),
                referenceTemplates: {
                    ...state.referenceTemplates,
                    ...action.payload.referenceTemplates,
                },
            };
        case FETCH_INTEGRATIONS_REQ:
            return {
                ...state,
                ...requestReducer(),
            };
        case FETCH_INTEGRATIONS_RES:
            return {
                ...state,
                ...responseReducer(),
                integrations: action.payload.integrations,
            };
        case FETCH_INTEGRATIONS_ERR:
            return {
                ...state,
                ...errorReducer(),
            };
        case CREATE_GROUP_REQ:
            return {
                ...state,
                ...requestReducer(),
            };
        case CREATE_GROUP_RES:
        case CREATE_REFERENCE_TEMPLATE_RES:
        case UPDATE_REFERENCE_TEMPLATE_RES:
        case DELETE_REFERENCE_TEMPLATE_RES:
        case GENERATE_ICIMS_CREDENTIALS_RES:
        case UPDATE_INTEGRATION_RES:
        case DELETE_INTEGRATION_PACKAGES_RES:
        case CREATE_INTEGRATION_PACKAGES_RES:
        case UPDATE_INTEGRATION_PACKAGES_RES:
            return {
                ...state,
                ...responseReducer(),
            };
        case CREATE_GROUP_ERR:
            return {
                ...state,
                ...errorReducer(),
            };
        case FETCH_TEAM_MEMBERS_REQ:
            return {
                ...state,
                ...fetchingReducer('teamMembers', true),
            };
        case FETCH_TEAM_MEMBERS_RES:
            return {
                ...state,
                ...fetchingReducer('teamMembers', false),
                teamMembers: action.payload.teamMembers,
                teamMembersCount: action.payload?.teamMembersCount,
            };
        case FETCH_TEAM_MEMBERS_ERR:
            return {
                ...state,
                ...errorReducer(),
            };
        case FETCH_SUPERTEAM_ADMINS_REQ:
            return {
                ...state,
                ...requestReducer(),
            };
        case FETCH_SUPERTEAM_ADMINS_RES:
            return {
                ...state,
                ...responseReducer(),
                superteamAdmins: action.payload.superteamAdmins,
            };
        case FETCH_SUPERTEAM_ADMINS_ERR:
            return {
                ...state,
                ...errorReducer(),
            };
        case FETCH_ALL_TEAM_MEMBERS_REQ:
            return {
                ...state,
                ...requestReducer(),
            };
        case FETCH_ALL_TEAM_MEMBERS_RES:
            return {
                ...state,
                ...responseReducer(),
                allSuperTeamUsers: action.payload.allSuperTeamUsers,
            };
        case FETCH_ALL_TEAM_MEMBERS_ERR:
            return {
                ...state,
                ...errorReducer(),
            };
        case GET_SETTINGS_REQ:
            return {
                ...state,
                ...requestReducer(),
            };
        case PATCH_SETTINGS_REQ:
            return {
                ...state,
                ...fetchingReducer('currentTeam', true),
            };
        case GET_TEAM_SETTINGS_RES:
            return {
                ...state,
                ...responseReducer(),
                currentTeam: action.payload.settings,
            };
        case GET_SETTINGS_RES:
        case PATCH_SETTINGS_RES:
            return {
                ...state,
                ...fetchingReducer('currentTeam', false),
                currentTeam: action.payload.settings,
            };
        case GET_SETTINGS_ERR:
        case PATCH_SETTINGS_ERR:
            return {
                ...state,
                ...errorReducer(action.payload.error),
                settingsError: action.payload.settingsError,
            };
        case SAVE_CARD_TOKEN_REQ:
            return {
                ...state,
                ...requestReducer(),
                // settings: action.payload.settings,
            };
        case SAVE_CARD_TOKEN_RES:
            return {
                ...state,
                ...responseReducer(),
                settings: action.payload.settings,
            };
        case SAVE_CARD_TOKEN_ERR:
            return {
                ...state,
                ...errorReducer(action.payload.error),
                settingsError: action.payload.settingsError,
            };
        case FETCH_API_KEYS_RES:
            return {
                ...state,
                ...responseReducer(),
                apiKeys: action.payload.apiKeys,
            };
        case CREATE_API_KEYS_ERR:
            return {
                ...state,
                ...errorReducer(),
            };
        case CREATE_API_KEYS_REQ:
            return {
                ...state,
                ...requestReducer(),
            };
        case CREATE_API_KEYS_RES:
            return {
                ...state,
                ...responseReducer(),
                apiKeys: [...state.apiKeys, ...action.payload.response],
            };
        case DELETE_API_KEYS_ERR:
            return {
                ...state,
                ...errorReducer(),
            };
        case DELETE_API_KEYS_REQ:
            return {
                ...state,
                ...requestReducer(),
            };
        case DELETE_API_KEYS_RES:
            return {
                ...state,
                ...responseReducer(),
            };
        case FETCH_INTEGRATION_PACKAGES_RES:
            return {
                ...state,
                integrationPackages: {
                    ...state.integrationPackages,
                    [action.payload.integrationType]: action.payload.response,
                },
                ...responseReducer(),
            };
        case SET_INTEGRATION_PACKAGE_OPTIONS_RES:
            return {
                ...state,
                integrationPackageOptions: {
                    ...action.payload.options.options,
                },
                ...responseReducer(),
            };
        case RESET_INTEGRATION_PACKAGE_OPTIONS_RES:
            return {
                ...state,
                integrationPackageOptions: {},
                ...responseReducer(),
            };
        case GET_BULK_INVOICE_CSV_REQ:
            return {
                ...state,
                ...silentRequestReducer(),
            };
        case GET_BULK_INVOICE_CSV_RES:
            return {
                ...state,
                ...responseReducer(),
            };
        case ActionTypes.GET_WEBHOOK_SECRET.SUC:
        case ActionTypes.PATCH_WEBHOOK_SECRET.SUC:
            return {
                ...state,
                webhookSecret: action.payload.response,
            };
        default:
            return state;
    }
};

export default settings;
