import React from 'react';
import { Helmet as ReactHelmet } from 'react-helmet';
import { useSelector } from 'react-redux';

// Selectors
import { getSettings, getTeam } from '../WelcomeSelectors';

const Helmet = () => {
    const settings = useSelector(getSettings);
    const team = useSelector(getTeam);
    if (team?.adwords_key)
        return (
            <ReactHelmet>
                <title>{settings?.get_org_name}</title>
                <script async src={`https://www.googletagmanager.com/gtag/js?id=${team.adwords_key}`} />
                <script>
                    {`
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', '${team.adwords_key}', {'allow_enhanced_conversions':true});
                `}
                </script>
            </ReactHelmet>
        );

    return null;
};

export default Helmet;
