// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { get } from 'lodash';

// Components
import { AppearRight } from 'certn-ui/Animate';
import { NextButton, TrackNavigation } from 'views/welcome/components';
import { ErrorAlertAPI } from 'certn-ui/ErrorAlert';
import Title from 'certn-ui/Title';
import Loader from 'certn-ui/Loader';
import { NoCanadianAddress, Questions, EIDRejected, Default } from './components';

// Modules
import withNavigation from 'views/welcome/modules/WithNavigation';

// Actions & Selectors
import { fetchWelcomeSession, patchInformation, patchWelcomeSession } from 'views/welcome/WelcomeActions';
import {
    getIsFetchingSilent,
    getInformation,
    getOnboardingType,
    getIdentity,
    getIdentityQuestions,
} from 'views/welcome/WelcomeSelectors';
import { startEID, submitEID } from './IdentityActions';

const mapStateToProps = (state) => ({
    isFetchingSilent: getIsFetchingSilent(state),
    information: getInformation(state),
    identity: getIdentity(state),
    onboardingType: getOnboardingType(state),
    questions: getIdentityQuestions(state),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchWelcomeSession,
            patchInformation,
            patchWelcomeSession,
            startEID,
            submitEID,
        },
        dispatch
    );

const propTypes = {
    // Redux Actions:
    fetchWelcomeSession: PropTypes.func.isRequired,
    patchInformation: PropTypes.func.isRequired,
    patchWelcomeSession: PropTypes.func.isRequired,
    startEID: PropTypes.func.isRequired,
    submitEID: PropTypes.func.isRequired,
    // Redux Store:
    isFetchingSilent: PropTypes.bool.isRequired,
    information: PropTypes.object.isRequired,
    identity: PropTypes.object,
    onboardingType: PropTypes.string,
    applicantAccount: PropTypes.object,
    loginError: PropTypes.object,
    questions: PropTypes.array,
};
const defaultProps = {
    questions: [],
    applicantAccount: {},
    identity: {},
    loginError: undefined,
    onboardingType: 'HR',
};

class Identity extends React.Component {
    constructor(props) {
        super(props);
        const idVerified = get(this.props, ['identity', 'status']) === 'A';
        let hasCanadianAddress = false;
        if (props.information?.addresses) {
            props.information.addresses.forEach((address) => {
                if (address.country === 'CA') {
                    hasCanadianAddress = true;
                }
            });
        }
        this.state = {
            hasCanadianAddress,
            completed: idVerified,
            started: idVerified,
            loaded: idVerified,
            answers: [],
            currentQuestion: 0,
            currentAnswer: undefined,
            error: false,
        };
        window.scrollTo(0, 0);
    }

    handleAnswerSelect = (answerId) => {
        this.setState({ currentAnswer: answerId });
    };

    createNewAnswers = () => {
        const { currentQuestion, currentAnswer, answers } = this.state;
        const { questions } = this.props;
        let updateAnswer = false;

        const currentQuestionCorrected = currentQuestion + 1;

        const newAnswers = answers.map((answer) => {
            if (answer.question === currentQuestionCorrected) {
                updateAnswer = true;
                return { ...answer, answer: currentAnswer };
            }
            return answer;
        });

        if (!updateAnswer) {
            const addAnswer = {
                question: questions[currentQuestion].id,
                answer: currentAnswer,
            };
            newAnswers.push(addAnswer);
        }

        return newAnswers;
    };

    handleSubmit = () => {
        const { handleNextTrack } = this.props; /* WithNavigation */
        const { currentQuestion, started } = this.state;
        const { questions } = this.props;
        if (get(this.props, ['identity', 'status']) === 'A' || get(this.props, ['identity', 'status']) === 'R') {
            // You're done or EID has failed and you're progressing to the next track
            this.props.fetchWelcomeSession().then(handleNextTrack);
        } else if (questions && questions.length > 0 && currentQuestion >= questions.length - 1) {
            // You're on the last question and will submit answers
            if (currentQuestion === questions.length - 1) {
                const newAnswers = this.createNewAnswers();
                this.setState({ answers: newAnswers }, this.submitAnswers);
            } else {
                this.submitAnswers();
            }
        } else if (started) {
            // You're partway through the questions
            if (this.state.currentAnswer) {
                const newAnswers = this.createNewAnswers();
                this.setState({ currentQuestion: currentQuestion + 1, answers: newAnswers });
            }
        } else {
            // You are about to begin the eID questions
            this.props
                .startEID(this.props.intl)
                .then(() => {
                    this.setState({
                        started: true,
                        responses: questions,
                    });
                })
                .then(() => {
                    // updates redux with updated session state to check if EID questions should be skipped
                    this.props.fetchWelcomeSession().then(() => {
                        // API sets EID status to "Accepted" when creating EID session if not in production
                        const skipQuestions = get(this.props, ['identity', 'status']) === 'A';
                        if (skipQuestions) handleNextTrack();
                    });
                })
                .catch((error) => {
                    ErrorAlertAPI(error);
                    this.props.fetchWelcomeSession();
                });
        }
    };

    submitAnswers = () => {
        this.setState({ completed: true }, () =>
            this.props
                .submitEID({ answers: this.state.answers })
                .then(() => this.setState({ loaded: true }, this.props.fetchWelcomeSession))
                .catch(() => this.setState({ completed: false, error: true }, this.props.fetchWelcomeSession))
        );
    };

    pages = {
        noCanadaAddress: () => <NoCanadianAddress />,
        eidRejected: () => <EIDRejected />,
        completedOrVerified: () => (
            <Title>
                <FormattedMessage
                    id="welcome.Identity.verifiedTitle"
                    defaultMessage="Thank you! The identity verification questionnaire is complete."
                />
            </Title>
        ),
        questions: () => (
            <Questions
                question={this.props.questions[this.state.currentQuestion]}
                handleAnswerSelect={this.handleAnswerSelect}
            />
        ),
        default: () => <Default />,
    };

    render() {
        const { completed, hasCanadianAddress } = this.state;
        const { isFetchingSilent, questions, identity } = this.props;

        const idVerified = get(identity, ['status']) === 'A';
        const idRejected =
            get(this.props, ['identity', 'status']) === 'R' ||
            get(this.props, ['identity', 'result']) === 'INCONCLUSIVE';

        return (
            <AppearRight>
                {isFetchingSilent ? (
                    <Loader />
                ) : (
                    (() => {
                        if (!hasCanadianAddress) {
                            return this.pages.noCanadaAddress();
                        }
                        if (idRejected) {
                            return this.pages.eidRejected();
                        }
                        if (completed || idVerified) {
                            return this.pages.completedOrVerified();
                        }
                        if (questions && questions.length > 0) {
                            return this.pages.questions();
                        }
                        return this.pages.default();
                    })()
                )}
                {hasCanadianAddress ? (
                    <TrackNavigation handleSubmit={this.handleSubmit} />
                ) : (
                    // No CA Address replace the Next button with a custom BACK button to return to address track
                    <NextButton
                        onClick={() => this.props.history.push('/welcome/addresses')}
                        nextButtonTitle={<FormattedMessage id="common.back" defaultMessage="Back" />}
                    />
                )}
            </AppearRight>
        );
    }
}

Identity.propTypes = propTypes;
Identity.defaultProps = defaultProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(withNavigation(Identity))));
