// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// React Router & Routes
import { Switch, Route, Redirect } from 'react-router-dom';

// External Libraries
import queryString from 'query-string';
import message from 'antd/lib/message';

import { clearFilters } from 'views/manager/views/hr/views/applications/ApplicationsActions';
import { getSentApplication } from 'views/manager/views/hr/views/applications/ApplicationsSelectors';
import ApplicationsList from './views/applicationsList/ApplicationsList';

import ApplicationsView from './views/applicationsView/ApplicationsView';

const mapStateToProps = (state) => ({
    sentApplication: getSentApplication(state),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            clearFilters,
        },
        dispatch
    );

const propTypes = {
    // Redux Actions
    clearFilters: PropTypes.func.isRequired,
    // Redux State
    sentApplication: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

const defaultProps = {
    sentApplication: null,
};

class Applications extends React.Component {
    constructor(props) {
        super(props);
        if (props.location?.search) {
            const parsed = queryString.parse(props.location.search);
            if (!(parsed.posting_id || parsed.listing_id || parsed.property_id)) {
                props.clearFilters();
            }
        }
    }

    // Need to refactor and get this out of here
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (
            nextProps.sentApplication !== this.props.sentApplication &&
            nextProps.sentApplication &&
            nextProps.sentApplication.applicants
        ) {
            message.success(
                `Applications sent to ${nextProps.sentApplication.applicants
                    .map((applicant) => applicant.email)
                    .join(', ')}`
            );
        }
    }

    render() {
        return (
            <Switch>
                <Route exact path="/hr/applications/:applicantId" component={ApplicationsView} />
                <Route exact path="/hr/applications" component={ApplicationsList} />
                <Redirect to="/hr/applications" />
            </Switch>
        );
    }
}

Applications.propTypes = propTypes;
Applications.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(Applications);
