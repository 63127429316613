import {
    requestReducer,
    silentRequestReducer,
    responseReducer,
    errorReducer,
    defaultState as defaultHttpState,
} from 'utils/http';
import {
    GET_TEAM_REQ,
    GET_TEAM_RES,
    GET_TEAM_ERR,
    GET_TEAM_LISTINGS_REQ,
    GET_TEAM_LISTINGS_RES,
    GET_TEAM_LISTINGS_ERR,
    GET_BUILDING_REQ,
    GET_BUILDING_RES,
    GET_BUILDING_ERR,
    GET_TEAM_PROPERTIES_REQ,
    GET_TEAM_PROPERTIES_RES,
    GET_TEAM_PROPERTIES_ERR,
    POST_RESET_PASSWORD_REQ,
    POST_RESET_PASSWORD_RES,
    POST_RESET_PASSWORD_ERR,
    PUT_RESET_PASSWORD_REQ,
    PUT_RESET_PASSWORD_RES,
    PUT_RESET_PASSWORD_ERR,
    GET_REFERENCE_QUESTIONS_REQ,
    GET_REFERENCE_QUESTIONS_RES,
    GET_REFERENCE_QUESTIONS_ERR,
    SUBMIT_REFERENCE_QUESTIONS_REQ,
    SUBMIT_REFERENCE_QUESTIONS_RES,
    SUBMIT_REFERENCE_QUESTIONS_ERR,
    CLEAR_LOGIN,
    GET_DISPUTE_REQ,
    GET_DISPUTE_RES,
    GET_DISPUTE_ERR,
    PUT_DISPUTE_REQ,
    PUT_DISPUTE_RES,
    PUT_DISPUTE_ERR,
    POST_TALENTHUB_LOGIN_REQ,
    POST_TALENTHUB_LOGIN_RES,
    POST_TALENTHUB_LOGIN_ERR,
} from './GuestActions';

const initialState = {
    ...defaultHttpState,
    team: undefined,
    teamListings: [],
    teamProperties: [],
    forgotRequestSent: false,
    forgotRequestError: {
        error: undefined,
    },
    resetRequestSent: false,
    resetRequestError: {
        error: undefined,
    },
    referenceQuestions: {},
    isSubmitted: false,
};

const guest = (state = initialState, action) => {
    switch (action.type) {
        case GET_TEAM_REQ:
        case POST_RESET_PASSWORD_REQ:
        case PUT_RESET_PASSWORD_REQ:
        case POST_TALENTHUB_LOGIN_REQ:
            return {
                ...state,
                ...silentRequestReducer(),
            };
        case GET_TEAM_LISTINGS_REQ:
        case GET_TEAM_PROPERTIES_REQ:
        case SUBMIT_REFERENCE_QUESTIONS_REQ:
        case GET_REFERENCE_QUESTIONS_REQ:
        case PUT_DISPUTE_REQ:
        case GET_DISPUTE_REQ:
            return {
                ...state,
                ...requestReducer(),
            };
        case GET_BUILDING_REQ:
            return {
                ...state,
                ...requestReducer(),
            };
        case GET_BUILDING_RES:
            return {
                ...state,
                ...responseReducer(),
                building: action.payload.building,
            };
        case GET_BUILDING_ERR:
            return {
                ...state,
                ...errorReducer(action.payload.error),
            };
        case GET_TEAM_RES:
            return {
                ...state,
                ...responseReducer(),
                team: action.payload.team,
            };
        case POST_TALENTHUB_LOGIN_RES:
            return {
                ...state,
                ...responseReducer(),
            };
        case PUT_DISPUTE_RES:
        case GET_DISPUTE_RES:
            return {
                ...state,
                ...responseReducer(),
                dispute: action.payload.dispute,
            };
        case GET_TEAM_LISTINGS_RES:
            return {
                ...state,
                ...responseReducer(),
                teamListings: action.payload.teamListings,
            };
        case GET_TEAM_PROPERTIES_RES:
            return {
                ...state,
                ...responseReducer(),
                teamProperties: action.payload.teamProperties,
            };
        case POST_RESET_PASSWORD_RES:
            return {
                ...state,
                ...responseReducer(),
                forgotRequestSent: true,
            };
        case PUT_RESET_PASSWORD_RES:
            return {
                ...state,
                ...responseReducer(),
                resetRequestSent: true,
            };
        case SUBMIT_REFERENCE_QUESTIONS_RES:
            return {
                ...state,
                ...responseReducer(),
                isSubmitted: action.payload.isSubmitted,
            };
        case GET_REFERENCE_QUESTIONS_RES:
            return {
                ...state,
                ...responseReducer(),
                referenceQuestions: action.payload.referenceQuestions,
            };
        case GET_TEAM_ERR:
        case GET_TEAM_PROPERTIES_ERR:
        case GET_TEAM_LISTINGS_ERR:
        case POST_RESET_PASSWORD_ERR:
        case PUT_RESET_PASSWORD_ERR:
        case SUBMIT_REFERENCE_QUESTIONS_ERR:
        case GET_REFERENCE_QUESTIONS_ERR:
        case PUT_DISPUTE_ERR:
        case GET_DISPUTE_ERR:
        case POST_TALENTHUB_LOGIN_ERR:
            return {
                ...state,
                ...errorReducer(action.payload.error),
            };
        case CLEAR_LOGIN:
            return {
                ...state,
                error: {},
            };
        default:
            return state;
    }
};

export default guest;
