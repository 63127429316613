// Libraries
import { withRouter } from 'react-router-dom';
import WithNavigation from 'views/welcome/modules/WithNavigation';
import { useDispatch, useSelector } from 'react-redux';
import Title from 'certn-ui/Title';
import { Box } from 'certn-ui/Layout';
import styled from 'styled-components';

// Actions & Selectors
import { patchInformation } from 'views/welcome/WelcomeActions';
import { PurposeForm } from './PurposeForm/PurposeForm';
import { FormProps } from 'antd/lib/form';
import { FormattedMessage } from 'react-intl';
import { PurposeHelpModal } from './PurposeModal/PurposeHelpModal';
import { useState } from 'react';
import { getApplicant } from '../../WelcomeSelectors';

const WhatsThisLink = styled.a`
    text-decoration: underline;
    color: black;
    font-size: 12px;
    position: absolute;
    top: 5px;
    right: 2px;
`;

export const Purpose = withRouter(
    WithNavigation(({ handleNextTrack }: { handleNextTrack: () => void }) => {
        const dispatch = useDispatch();
        const [isHelpModalOpen, setHelpModalOpen] = useState(false);

        const { request_australian_criminal_intelligence_commission_check, request_volunteer_acic_check } = useSelector(
            getApplicant
        );

        const toggleHelpModalOpen = () => {
            setHelpModalOpen((current) => !current);
        };

        const handleSubmit: FormProps['onFinish'] = async (values: {
            purpose_of_check: string;
            issuing_authority?: string;
            license_type?: string;
            place_of_work?: string;
            reason_for_check?: string;
            position_title?: string;
            volunteer_role?: string;
            volunteer_organization?: string;
        }) => {
            const {
                purpose_of_check,
                position_title,
                issuing_authority,
                license_type,
                place_of_work,
                reason_for_check,
                volunteer_role,
                volunteer_organization,
            } = values;

            await dispatch(
                patchInformation({
                    purpose: {
                        purpose_of_check,
                        reason_for_check,
                        place_of_work,
                        issuing_authority,
                        license_type,
                        position_title,
                        volunteer_role,
                        volunteer_organization,
                    },
                })
            );
            handleNextTrack();
        };

        return (
            <>
                <PurposeHelpModal isOpen={isHelpModalOpen} onClose={toggleHelpModalOpen} />
                {request_australian_criminal_intelligence_commission_check && (
                    <Title>
                        <FormattedMessage
                            id="24519.Purpose.purposeStandardTrackTitle"
                            defaultMessage="Purpose of check"
                        />
                    </Title>
                )}
                {request_volunteer_acic_check && (
                    <Title>
                        <FormattedMessage
                            id="24519.Purpose.purposeVolunteerTrackTitle"
                            defaultMessage="Volunteer Details"
                        />
                    </Title>
                )}
                <Box style={{ margin: '0px 15px 20px 15px' }} size="small">
                    <FormattedMessage
                        id="24519.Purpose.subTitle"
                        defaultMessage="In order to proceed, we need to know details about the purpose of the background check. This information is required in order to complete the process."
                    />
                </Box>

                <div style={{ position: 'relative' }}>
                    {request_australian_criminal_intelligence_commission_check && (
                        <WhatsThisLink
                            style={{ zIndex: 4 }}
                            onClick={toggleHelpModalOpen}
                            data-testid="what_is_purpose_of_check_button"
                        >
                            <FormattedMessage id="welcome.Basics.whatsThis" defaultMessage="What's this?" />
                        </WhatsThisLink>
                    )}

                    <PurposeForm handleSubmit={handleSubmit} />
                </div>
            </>
        );
    })
);
