// Libraries
import React from 'react';
import styled from 'styled-components/macro';
import { injectIntl, FormattedMessage } from 'react-intl';
import { EllipsisOutlined } from '@ant-design/icons';

// UI Components
import { ListCellText } from 'certn-ui/List';
import { Dropdown as AntDropdown, Menu, Modal, message, Button as AntButton } from 'antd';

const Dropdown = styled(AntDropdown)`
    margin: 8px 0px;
`;

const Button = styled(AntButton)`
    ${({ margin }) => margin && `margin: ${margin}`};
    overflow: hidden !important;
    height: 40px !important;
    width: 40px !important;
`;

class ApiKeysDropdown extends React.Component {
    showConfirmDelete = () => {
        Modal.confirm({
            title: this.props.intl.formatMessage({
                id: '580aa.ApiKeysDropdown.ConfirmDeleteTitle',
                defaultMessage: 'Do you really want to delete this api key?',
            }),
            content: this.props.intl.formatMessage({
                id: '580aa.ApiKeysDropdown.ConfirmDeleteContent',
                defaultMessage: 'This action cannot be undone.',
            }),
            okType: 'danger',
            okText: this.props.intl.formatMessage({ id: 'common.ok', defaultMessage: 'OK' }),
            cancelText: this.props.intl.formatMessage({
                id: 'common.cancel',
                defaultMessage: 'Cancel',
            }),
            onOk: () => {
                this.props.deleteApiKey(this.props.id);
                return new Promise((resolve) => {
                    setTimeout(resolve, 1000);
                })
                    .then(() =>
                        message.success(
                            this.props.intl.formatMessage({
                                id: '580aa.ApiKeysDropdown.DeleteSuccess',
                                defaultMessage: 'Api Key deleted!',
                            })
                        )
                    )
                    .catch(() =>
                        message.error(
                            this.props.intl.formatMessage({
                                id: '580aa.ApiKeysDropdown.DeleteError',
                                defaultMessage: 'Error occurred while attempting to delete this api key',
                            })
                        )
                    );
            },
            onCancel() {},
        });
    };

    handleCopyTokenToClipboard = (token) => {
        this.props.copyTokenToClipboard(token);
    };

    render() {
        return (
            <Dropdown
                overlay={
                    <Menu>
                        <Menu.Item key="1">
                            <ListCellText clickable onClick={this.showConfirmDelete}>
                                <FormattedMessage
                                    id="580aa.ApiKeysDropdown.DeleteKey"
                                    defaultMessage="Delete Api Key"
                                />
                            </ListCellText>
                        </Menu.Item>
                        <Menu.Item key="3">
                            <ListCellText clickable onClick={() => this.handleCopyTokenToClipboard(this.props.token)}>
                                <FormattedMessage id="580aa.ApiKeysDropdown.CopyKey" defaultMessage="Copy Api Key" />
                            </ListCellText>
                        </Menu.Item>
                    </Menu>
                }
                trigger={['click']}
                placement="bottomCenter"
            >
                <Button shape="circle" icon={<EllipsisOutlined />} />
            </Dropdown>
        );
    }
}

export default injectIntl(ApiKeysDropdown);
