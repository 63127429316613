// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { intl } from 'components/GlobalProvider';

// UI Components
import { Fade } from 'certn-ui/Animate';
import { Box } from 'certn-ui/Layout';
import Loader from 'certn-ui/Loader';
import Titlebar, { TitlebarCtaButton } from 'certn-ui/Titlebar';
import { ErrorAlertCustom } from 'certn-ui/ErrorAlert';
import { LinkOutlined } from '@ant-design/icons';
import { Button, message, Space, Result } from 'antd';

// Modules & Utils
import { CopyToClipboard } from 'modules';

// External Libraries
import queryString from 'query-string';
import Cookies from 'js-cookie';

// Actions & Selectors
import {
    resetListAndFetch,
    clearErrors,
    fetchPostings,
    sendApplications,
    setIsActive,
    clearFilters,
} from 'views/manager/views/hr/views/postings/PostingsActions';
import { getUser } from 'base/BaseSelectors';
import {
    getIsFetching,
    getIsFetchingSilent,
    getError,
    getPostings,
    getApplication,
    getSearchBy,
    getIsActive,
    getNumPostings,
    getNumAllPostings,
} from 'views/manager/views/hr/views/postings/PostingsSelectors';
import { SendApplicationsForm, Table } from './components';

// Constants
import { CHECK_REQUEST } from 'base/BaseConstants';

const mapStateToProps = (state) => ({
    isFetching: getIsFetching(state),
    isFetchingSilent: getIsFetchingSilent(state),
    error: getError(state),
    application: getApplication(state),
    postings: getPostings(state),
    searchBy: getSearchBy(state),
    isActive: getIsActive(state),
    numPostings: getNumPostings(state),
    numAllPostings: getNumAllPostings(state),
    canCreateAndEditPackages: getUser(state).can_create_and_edit_packages,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            resetListAndFetch,
            clearErrors,
            fetchPostings,
            sendApplications,
            setIsActive,
            clearFilters,
        },
        dispatch
    );

const propTypes = {
    // Redux Actions
    resetListAndFetch: PropTypes.func.isRequired,
    clearErrors: PropTypes.func.isRequired,
    fetchPostings: PropTypes.func.isRequired,
    sendApplications: PropTypes.func.isRequired,
    setIsActive: PropTypes.func.isRequired,
    clearFilters: PropTypes.func.isRequired,
    // Redux State
    isFetching: PropTypes.bool.isRequired,
    isFetchingSilent: PropTypes.bool.isRequired,
    error: PropTypes.object.isRequired,
    postings: PropTypes.array.isRequired,
    application: PropTypes.object.isRequired,
    searchBy: PropTypes.string,
    filterBy: PropTypes.object.isRequired,
    numAllPostings: PropTypes.number.isRequired,
    numPostings: PropTypes.number.isRequired,
    settings: PropTypes.object,
};

const defaultProps = {
    settings: {},
    searchBy: null,
};

class PostingsList extends React.Component {
    constructor(props) {
        super(props);
        const { location } = props;

        this.state = {
            sendApplicationsVisible: false,
            expandedRowRender: this.expandedRowRender,
            trialLimitReachedVisible: false,
            postingId: undefined,
            posting: undefined,
            teamId: Cookies.get('team_id'),
        };
        props.resetListAndFetch();

        if (location?.search) {
            const parsed = queryString.parse(location.search);
            if (parsed?.posting_created)
                message.success(
                    intl.formatMessage({
                        id: '1ec29.PostingList.success1',
                        defaultMessage: 'Package was successfully created!',
                    })
                );
        }
    }

    buildData = (postings) => postings.map((posting, index) => ({ ...posting, key: index }));

    expandedRowRender = (record) => (
        <p>
            <FormattedMessage
                id="1ec29.PostingList.recordData"
                defaultMessage="record data = {record}"
                values={{ record: JSON.stringify(record) }}
            />
        </p>
    );

    showSendApplications = (postingId, posting) => this.setState({ sendApplicationsVisible: true, postingId, posting });

    handleSendApplicationsOk = (emails, postingId, language) => {
        const test_collection = get(this.state, ['posting', 'test_collection']);
        let upgrades = test_collection ? { ...test_collection } : {};
        upgrades = Object.entries(upgrades)
            .filter(([key, value]) => !['created', 'modified', 'id'].includes(String(key)) && value !== null)
            .reduce((acc, [key, value]) => ({ [key]: value, ...acc }), {});

        if (upgrades[CHECK_REQUEST.EMPLOYER_PHONE_REFERENCES] || upgrades[CHECK_REQUEST.EMPLOYER_REFERENCES])
            upgrades.employer_questionaire_id = test_collection.employer_questionaire_id;

        if (upgrades[CHECK_REQUEST.ADDRESS_PHONE_REFERENCES] || upgrades[CHECK_REQUEST.ADDRESS_REFERENCES])
            upgrades.address_questionaire_id = test_collection.address_questionaire_id;

        if (upgrades[CHECK_REQUEST.INTERNATIONAL_CRIMINAL_RECORD_CHECK])
            upgrades.requested_countries = test_collection.requested_countries;

        return this.props
            .sendApplications(emails, postingId, language, upgrades)
            .then(() => {
                message.success(
                    intl.formatMessage({
                        id: '1ec29.PosstingList.success2',
                        defaultMessage: 'Application sent',
                    })
                );
                this.setState(
                    {
                        sendApplicationsVisible: false,
                    },
                    () => {
                        this.props.fetchPostings();
                    }
                );
            })
            .catch(() =>
                ErrorAlertCustom({
                    title: (
                        <FormattedMessage id="1ec29.PosstingList.error1" defaultMessage="Error sending application" />
                    ),
                })
            );
    };

    showTrialLimitReached = () => this.setState({ trialLimitReachedVisible: true });

    handleModalsCancel = () =>
        this.setState({ sendApplicationsVisible: false, trialLimitReachedVisible: false }, this.props.clearErrors);

    render() {
        const data = this.props.postings && this.buildData(this.props.postings);
        const { canCreateAndEditPackages } = this.props;

        return (
            <>
                {this.state.sendApplicationsVisible && (
                    <SendApplicationsForm
                        posting={this.state.posting}
                        onOk={this.handleSendApplicationsOk}
                        onCancel={this.handleModalsCancel}
                        confirmLoading={this.props.isFetchingSilent}
                        error={this.props.error}
                    />
                )}
                <Titlebar title={intl.formatMessage({ id: 'common.packages', defaultMessage: 'Packages' })}>
                    {canCreateAndEditPackages && (
                        <TitlebarCtaButton type="primary" onClick={() => this.props.history.push('/hr/packages/new')}>
                            <FormattedMessage id="1ec29.PostingList.addPackage" defaultMessage="ADD A PACKAGE" />
                        </TitlebarCtaButton>
                    )}
                </Titlebar>
                {(this.props.isFetching || this.props.isFetchingSilent) && data && data.length === 0 ? (
                    <Loader />
                ) : (
                    <Fade>
                        <>
                            <Table.Nav isActive={this.props.isActive} setIsActive={this.props.setIsActive} />
                            {this.props.isActive && (
                                <Button
                                    style={{ margin: '8px 0px' }}
                                    onClick={() => CopyToClipboard({ route: 'packages' })}
                                >
                                    <Space>
                                        <LinkOutlined />
                                        <FormattedMessage
                                            id="1ec29.PostingList.copyTeamApply"
                                            defaultMessage="Copy Team Apply Link"
                                        />{' '}
                                    </Space>
                                </Button>
                            )}
                            {!this.props.isFetching &&
                                !this.props.isFetchingSilent &&
                                this.props.numPostings === 0 &&
                                this.props.numAllPostings === 0 &&
                                data &&
                                data.length === 0 && (
                                    // {!isFetching && this.props.numPostings === 0 && this.props.numAllPostings === 0 ? (
                                    <Box center border>
                                        {canCreateAndEditPackages ? (
                                            <Titlebar
                                                size="lrg"
                                                title={
                                                    <FormattedMessage
                                                        id="1ec29.PostingList.title1"
                                                        defaultMessage="Create your first package"
                                                    />
                                                }
                                                center
                                                subtitle={
                                                    <FormattedMessage
                                                        id="1ec29.PostingList.subtitle1"
                                                        defaultMessage="Create your first package so you can generate a custom link for your website or classifieds ads."
                                                    />
                                                }
                                            >
                                                <Button
                                                    size="large"
                                                    type="primary"
                                                    onClick={() => this.props.history.push('/hr/packages/new')}
                                                >
                                                    <FormattedMessage
                                                        id="1ec29.PostingList.addPackage"
                                                        defaultMessage="ADD A PACKAGE"
                                                    />
                                                </Button>
                                            </Titlebar>
                                        ) : (
                                            <Result
                                                title={
                                                    <FormattedMessage
                                                        id="1ec29.PostingList.noPackagesTitle"
                                                        defaultMessage="No Packages Found"
                                                    />
                                                }
                                                subTitle={
                                                    <FormattedMessage
                                                        id="1ec29.PostingList.noPackageSubtitle"
                                                        defaultMessage="There are no packages created for this team. Please contact your team's administrator to create some packages."
                                                    />
                                                }
                                            />
                                        )}
                                    </Box>
                                )}
                            {this.props.numPostings > 0 && (
                                <Table
                                    data={data}
                                    showSendApplications={this.showSendApplications}
                                    showTrialLimitReached={this.showTrialLimitReached}
                                />
                            )}
                        </>
                    </Fade>
                )}
            </>
        );
    }
}

PostingsList.propTypes = propTypes;
PostingsList.defaultProps = defaultProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PostingsList));
