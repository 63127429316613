// Libraries
import React from 'react';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import { Input, Checkbox, Popover } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';

// Components
import { DateOfBirthInput, TrackNavigation } from 'views/welcome/components';
import { GenderSelectLegacy } from 'views/welcome/components/Fields/GenderSelect';
import Subtitle from 'views/welcome/components/Subtitle';
import { InternationalPhone } from 'components';
import { AppearRight } from 'certn-ui/Animate';
import Title from 'certn-ui/Title';
import FormLayout from 'certn-form/FormLayout';
import { CHECK_REQUEST } from 'base/BaseConstants';

// styles
import { FieldToggleContainer, FormItemMiddleName } from './styled';

// Modules
import { Regex, genderXCheck } from 'modules';

// Actions & Selectors
import { getTeamIsMyCRC, getApplicant } from 'views/welcome/WelcomeSelectors';

const NameAndNumberFormFields = ({
    phone,
    getFieldDecorator,
    setFieldsValue,
    information,
    intl,
    errorStatus,
    teamIsMyCRC,
    isUkCheck,
}) => {
    const applicant = useSelector(getApplicant) || {};

    /**
     * If there's first name and last name stored, we can safely assume the user has at least pass through the personal info step
     * Initially if it is the first time the user fills out the form,
     * the checkbox should be unchecked and input should NOT be greyed out. This is to prevent
     * user mindlessly blast through the step
     */
    const isBasicInfoCompleted = information.first_name && information.last_name;
    const middleName = get(information, 'middle_name');

    const [isMiddleNameChecked, setIsMiddleNameChecked] = React.useState(!middleName && isBasicInfoCompleted);
    const [isMiddleNameInputDisabled, setIsMiddleNameInputDisabled] = React.useState(
        !middleName && isBasicInfoCompleted
    );

    // RCMP and no International Crim, include gender x as dropdown option
    const includeGenderX = genderXCheck(applicant);

    const handleMiddleNameCheckboxChange = (e) => {
        setIsMiddleNameChecked(e.target.checked);
        setIsMiddleNameInputDisabled(e.target.checked);
        // Clear input field
        if (e.target.checked) {
            setFieldsValue({ middle_name: null });
        }
    };

    const fields = {
        title: () => {
            if (!isUkCheck) return null;
            return (
                <Form.Item key="title" hasFeedback>
                    {getFieldDecorator('title', {
                        initialValue: get(information, 'title'),
                        rules: [
                            {
                                pattern: Regex.name,
                                message: intl.formatMessage({
                                    id: 'error.validation.letters',
                                    defaultMessage: 'Please only use letters',
                                }),
                            },
                        ],
                    })(
                        <Input
                            maxLength={40}
                            size="large"
                            data-testid="title"
                            placeholder={intl.formatMessage({
                                id: 'welcome.Basics.title_name',
                                defaultMessage: 'Title',
                            })}
                            addonBefore={
                                <Popover
                                    content={intl.formatMessage({
                                        id: 'welcome.Basics.title_name',
                                        defaultMessage: 'Title',
                                    })}
                                >
                                    <InfoCircleOutlined />
                                </Popover>
                            }
                        />
                    )}
                </Form.Item>
            );
        },
        first_name: () => (
            <Form.Item key="first_name" hasFeedback>
                {getFieldDecorator('first_name', {
                    initialValue: get(information, 'first_name'),
                    rules: [
                        {
                            pattern: Regex.name,
                            message: intl.formatMessage({
                                id: 'error.validation.letters',
                                defaultMessage: 'Please only use letters',
                            }),
                        },
                        {
                            required: true,
                            message: intl.formatMessage({
                                id: 'error.validation.notBlank',
                                defaultMessage: 'Please do not leave blank',
                            }),
                        },
                    ],
                })(
                    <Input
                        maxLength={40}
                        autoFocus
                        size="large"
                        data-testid="first_name"
                        placeholder={intl.formatMessage({
                            id: 'welcome.general.legalFirstName',
                            defaultMessage: 'Legal First Name',
                        })}
                        addonBefore={
                            <Popover
                                content={intl.formatMessage({
                                    id: 'welcome.general.legalFirstName',
                                    defaultMessage: 'Legal First Name',
                                })}
                            >
                                <InfoCircleOutlined />
                            </Popover>
                        }
                    />
                )}
            </Form.Item>
        ),
        alias: () => {
            if (teamIsMyCRC) return null;
            return (
                <Form.Item key="alias" hasFeedback>
                    {getFieldDecorator('alias', {
                        initialValue: get(information, 'alias'),
                        rules: [
                            {
                                pattern: Regex.name,
                                message: intl.formatMessage({
                                    id: 'error.validation.letters',
                                    defaultMessage: 'Please only use letters',
                                }),
                            },
                        ],
                    })(
                        <Input
                            maxLength={40}
                            size="large"
                            data-testid="alias"
                            placeholder={intl.formatMessage({
                                id: 'welcome.Basics.alias',
                                defaultMessage: 'Alias / Nickname',
                            })}
                            addonBefore={
                                <Popover
                                    content={intl.formatMessage({
                                        id: 'welcome.Basics.alias',
                                        defaultMessage: 'Alias / Nickname',
                                    })}
                                >
                                    <InfoCircleOutlined />
                                </Popover>
                            }
                        />
                    )}
                </Form.Item>
            );
        },
        middle_name: () => (
            <>
                <FormItemMiddleName key="middle_name" hasFeedback>
                    {getFieldDecorator('middle_name', {
                        initialValue: middleName,
                        rules: [
                            {
                                pattern: Regex.middleName,
                                message: intl.formatMessage({
                                    id: 'error.validation.letters',
                                    defaultMessage: 'Please only use letters',
                                }),
                            },
                            {
                                required: !middleName && !isMiddleNameChecked,
                                message: intl.formatMessage({
                                    id: 'error.validation.notBlank',
                                    defaultMessage: 'Please do not leave blank',
                                }),
                            },
                        ],
                    })(
                        <Input
                            disabled={isMiddleNameInputDisabled}
                            maxLength={40}
                            size="large"
                            data-testid="middle_name"
                            placeholder={intl.formatMessage({
                                id: 'welcome.Basics.middleName',
                                defaultMessage: 'Middle Name(s)',
                            })}
                            addonBefore={
                                <Popover
                                    content={intl.formatMessage({
                                        id: 'welcome.Basics.middleName',
                                        defaultMessage: 'Middle Name(s)',
                                    })}
                                >
                                    <InfoCircleOutlined />
                                </Popover>
                            }
                        />
                    )}
                </FormItemMiddleName>
                <FieldToggleContainer>
                    <Checkbox
                        id="middle_name_checkbox"
                        onChange={handleMiddleNameCheckboxChange}
                        checked={isMiddleNameChecked}
                    >
                        <FormattedMessage
                            id="welcome.Basics.noMiddleName"
                            defaultMessage="I don't have a middle name"
                        />
                    </Checkbox>
                </FieldToggleContainer>
            </>
        ),
        last_name: () => (
            <Form.Item key="last_name" hasFeedback>
                {getFieldDecorator('last_name', {
                    initialValue: get(information, 'last_name'),
                    rules: [
                        {
                            pattern: Regex.name,
                            message: intl.formatMessage({
                                id: 'error.validation.letters',
                                defaultMessage: 'Please only use letters',
                            }),
                        },
                        {
                            required: true,
                            message: intl.formatMessage({
                                id: 'error.validation.notBlank',
                                defaultMessage: 'Please do not leave blank',
                            }),
                        },
                    ],
                })(
                    <Input
                        maxLength={40}
                        size="large"
                        data-testid="last_name"
                        placeholder={intl.formatMessage({
                            id: 'welcome.general.legalLastName',
                            defaultMessage: 'Legal Last Name',
                        })}
                        addonBefore={
                            <Popover
                                content={intl.formatMessage({
                                    id: 'welcome.general.legalLastName',
                                    defaultMessage: 'Legal Last Name',
                                })}
                            >
                                <InfoCircleOutlined />
                            </Popover>
                        }
                    />
                )}
            </Form.Item>
        ),
        maiden_name: () => (
            <Form.Item key="last_name_at_birth" hasFeedback>
                {getFieldDecorator('last_name_at_birth', {
                    initialValue: get(information, 'last_name_at_birth'),
                    rules: [
                        {
                            pattern: Regex.name,
                            message: intl.formatMessage({
                                id: 'error.validation.letters',
                                defaultMessage: 'Please only use letters',
                            }),
                        },
                    ],
                })(
                    <Input
                        maxLength={40}
                        size="large"
                        data-testid="last_name_at_birth"
                        placeholder={intl.formatMessage({
                            id: 'welcome.Basics.maidenName',
                            defaultMessage: 'Maiden Name (if applicable)',
                        })}
                        addonBefore={
                            <Popover
                                content={intl.formatMessage({
                                    id: 'welcome.Basics.maidenName',
                                    defaultMessage: 'Maiden Name (if applicable)',
                                })}
                            >
                                <InfoCircleOutlined />
                            </Popover>
                        }
                    />
                )}
            </Form.Item>
        ),
        dob: () => {
            if (teamIsMyCRC) return <DateOfBirthInput getFieldDecorator={getFieldDecorator} />;
        },
        gender: () => {
            if (teamIsMyCRC)
                return (
                    <GenderSelectLegacy
                        information={information}
                        includeGenderX={includeGenderX}
                        getFieldDecorator={getFieldDecorator}
                    />
                );
        },
        phone: () => (
            <InternationalPhone key="int-phone" value={phone.num} errorStatus={errorStatus} onChange={phone.update} />
        ),
    };

    return Object.entries(fields).map(([key, item]) => <React.Fragment key={key}>{item()}</React.Fragment>);
};

const NameAndNumberForm = ({
    phone,
    handleSubmit,
    form: { getFieldDecorator, validateFields, getValueFromEvent, setFieldsValue },
    form,
    information,
    errorStatus,
    intl,
}) => {
    const teamIsMyCRC = useSelector(getTeamIsMyCRC);
    const applicant = useSelector(getApplicant);
    const isUkCheck =
        applicant && (applicant[CHECK_REQUEST.UK_BASIC_DBS_CHECK] || applicant[CHECK_REQUEST.UK_BASIC_DS_CHECK]);
    return (
        <AppearRight>
            <Form layout="horizontal" onSubmit={(e) => handleSubmit(e, validateFields)}>
                <Title>
                    {teamIsMyCRC ? (
                        <FormattedMessage id="welcome.Basics.myCRCTitle" defaultMessage="Personal Information" />
                    ) : (
                        <FormattedMessage
                            id="welcome.Basics.title"
                            defaultMessage="Tell us a little bit about yourself"
                        />
                    )}
                </Title>
                <Subtitle>
                    <FormattedMessage
                        id="welcome.Basics.subtitle"
                        defaultMessage="We recommend using the information on a government issued ID or birth certificate for accurate results and a quicker turnaround."
                    />
                </Subtitle>
                <FormLayout>
                    {NameAndNumberFormFields({
                        phone,
                        form,
                        getFieldDecorator,
                        getValueFromEvent,
                        setFieldsValue,
                        information,
                        errorStatus,
                        intl,
                        teamIsMyCRC,
                        isUkCheck,
                    })}
                </FormLayout>
                <TrackNavigation />
            </Form>
        </AppearRight>
    );
};

export default Form.create()(injectIntl(NameAndNumberForm));
