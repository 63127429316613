// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';

// Components
import { PageOne } from './components';

// Modules
import withNavigation from 'views/welcome/modules/WithNavigation';

// Actions & Selectors
import { getIsFetchingSilent, getInformation, getOnboardingType } from 'views/welcome/WelcomeSelectors';
import { setTrackPageOrder } from 'views/welcome/WelcomeActions';

const mapStateToProps = (state) => ({
    isFetchingSilent: getIsFetchingSilent(state),
    information: getInformation(state),
    onboardingType: getOnboardingType(state),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setTrackPageOrder,
        },
        dispatch
    );

const propTypes = {
    // Redux Actions:
    setTrackPageOrder: PropTypes.func.isRequired,
    // Redux Store:
    isFetchingSilent: PropTypes.bool.isRequired,
    information: PropTypes.object.isRequired,
    onboardingType: PropTypes.string,
};
const defaultProps = {
    onboardingType: 'HR',
};

class WelcomeBack extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.setTrackPageOrder(['base']);
    }

    pages = () => ({
        base: (
            <PageOne
                intl={this.props.intl}
                handleSubmit={this.props.handleNextTrack}
                information={this.props.information}
            />
        ),
    });

    render() {
        return this.pages()[this.props.trackPageLocation]; /* WithNavigation */
    }
}

WelcomeBack.propTypes = propTypes;
WelcomeBack.defaultProps = defaultProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(withNavigation(WelcomeBack))));
