// Libraries
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { FormattedMessage } from 'react-intl';
import { Select } from 'antd';
import { Form as AntdForm } from '@ant-design/compatible';

// Components
import { intl } from 'components/GlobalProvider';
import MultiAddItemsForm from 'certn-form/MultiAddItemsForm';

// Actions & Selectors
import { fetchConvictionOffenses } from 'views/welcome/WelcomeActions';
import { getConvictionOffenses, getConvictions } from 'views/welcome/WelcomeSelectors';
import MultiFormConvictions from './MultiFormConvictions';

const { Option, OptGroup } = Select;

const DisclosureWrapper = styled.div`
    border: 1px solid ${({ theme }) => theme.color.certnRed500};
    background-color: ${({ theme }) => theme.color.certnRed100};
    padding: 20px;
    border-radius: 0;
    text-align: left;
    margin: auto;
    ${({ leftAligned }) => leftAligned && '20px 0px'};
`;

export const Description = styled.span`
    line-height: 15px;
    font-size: 12px;
    color: ${({ theme }) => theme.color.certnGray500};
`;

const ConvictionsDeclarationInfo = (leftAligned) => (
    <DisclosureWrapper leftAligned={leftAligned}>
        <FormattedMessage
            id="welcome.History.ConvictionsForm.declaration.first"
            defaultMessage="Do not declare the following:"
        />
        <Description>
            <FormattedMessage
                id="welcome.History.ConvictionsForm.declaration.list"
                defaultMessage="{nl} - A conviction for which you have received a Record Suspension (formerly pardon) in accordance with the Criminal Records Act {nl} - A conviction where you were a 'young person' under the Youth Criminal Justice Act {nl} - An Absolute or Conditional Discharge, pursuant to section 730 of the Criminal Code {nl} - An offense for which you were not convicted {nl} - Any provincial or municipal offense {nl} - Any charges dealt with outside of Canada {nl} - Note that a Certified Criminal Record can only be issued based on the submission of fingerprints to the RCMP National Repository of Criminal Records."
                values={{ nl: <br /> }}
            />
        </Description>
    </DisclosureWrapper>
);

const ConvictionsDeclaration = ({ form, leftAligned }) => (
    <>
        <ConvictionsDeclarationInfo leftAligned={leftAligned} />
        <MultiFormConvictions form={form} />
    </>
);

// Onboarding flow still uses this, once the flow is refactored and components like Radio we can remove this
export const MultiFormConvictionsLegacy = AntdForm.create()(({ form, center = false, leftAligned, handleSubmit }) => {
    const dispatch = useDispatch();
    const convictions = useSelector(getConvictions) || [];

    useEffect(() => {
        dispatch(fetchConvictionOffenses());
    }, [dispatch]);

    const convictionOffenses = useSelector(getConvictionOffenses);

    const convictionsConfig = {
        name: 'convictions',
        buttonText: intl.formatMessage({
            id: 'welcome.History.ConvictionsForm.buttonText',
            defaultMessage: 'Add Conviction',
        }),
        keys: [
            {
                id: 'description',
                name: intl.formatMessage({
                    id: 'welcome.History.ConvictionsForm.offence',
                    defaultMessage: 'Conviction Offense',
                }),
                required: true,
                display: () => (
                    <Select
                        placeholder={intl.formatMessage({
                            id: 'welcome.History.ConvictionsForm.offence',
                            defaultMessage: 'Conviction Offense',
                        })}
                        showSearch
                    >
                        {Object.entries(convictionOffenses).map(([key, value], i) => (
                            <OptGroup key={key} label={key}>
                                {value.map((offense, index) => (
                                    <Option
                                        key={i + index + offense.code}
                                        value={`${offense.offense}_${offense.code}_${i + index}`}
                                    >
                                        {offense.offense}
                                    </Option>
                                ))}
                            </OptGroup>
                        ))}
                    </Select>
                ),
            },
            {
                id: 'offense',
                name: intl.formatMessage({
                    id: 'welcome.History.ConvictionsForm.offence',
                    defaultMessage: 'Conviction Offense',
                }),
                hide: true,
            },
            {
                id: 'date_of_sentence',
                name: intl.formatMessage({
                    id: 'welcome.general.dateOfSentence',
                    defaultMessage: 'Date Of Sentence',
                }),
                required: true,
                dateField: true,
                disableFutureDates: true,
            },
            {
                id: 'court_location',
                name: intl.formatMessage({ id: 'welcome.general.courtLocation', defaultMessage: 'Court Location' }),
                required: true,
            },
        ],
    };

    const submitForm = (data) => {
        if (handleSubmit) handleSubmit(data);
    };
    return (
        <>
            <ConvictionsDeclarationInfo leftAligned={leftAligned} />
            <MultiAddItemsForm
                leftAligned={leftAligned}
                form={form}
                center={center}
                items={convictions}
                config={convictionsConfig}
                handleSubmit={submitForm}
            />
        </>
    );
});

export default ConvictionsDeclaration;
