import { getUserModeIsPM } from 'base/BaseSelectors';
import { useUserTeam } from 'hooks/useUserTeam';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Constants from 'utils/constants';
import { StaticResult } from '../AdvancedFilteringLayout';
import { FilterItem, FilterTitle } from '../styled';

type ResultFilterProps = {
    value?: string[];
    onChange: (value: string[]) => void;
};

export const ResultFilter: React.FC<ResultFilterProps> = ({ value, onChange }) => {
    const intl = useIntl();
    const userModeIsPM = useSelector(getUserModeIsPM);
    const { team } = useUserTeam();

    if (!team) {
        return null;
    }

    let options;
    if (userModeIsPM && team.settings_config.get_org_country === 'CA') {
        options = [
            {
                request: Constants.certnScore.PASS,
                title: intl.formatMessage({
                    id: '225d8.AdvancedFiltering.Pass',
                    defaultMessage: 'Pass',
                }),
            },
            {
                request: Constants.certnScore.WARN,
                title: intl.formatMessage({
                    id: '225d8.AdvancedFiltering.Warn',
                    defaultMessage: 'Warn',
                }),
            },
            {
                request: Constants.certnScore.FAIL,
                title: intl.formatMessage({
                    id: '225d8.AdvancedFiltering.Fail',
                    defaultMessage: 'Fail',
                }),
            },
            {
                request: Constants.certnScore.NONE,
                title: intl.formatMessage({
                    id: 'common.none',
                    defaultMessage: 'None',
                }),
            },
        ];
    } else {
        options = [
            {
                request: Constants.reportResult.CLEARED,
                title: intl.formatMessage({
                    id: '225d8.AdvancedFiltering.Cleared',
                    defaultMessage: 'Cleared',
                }),
            },
            {
                request: Constants.reportResult.REVIEW,
                title: intl.formatMessage({
                    id: '225d8.AdvancedFiltering.Review',
                    defaultMessage: 'Review',
                }),
            },
            {
                request: Constants.reportResult.NONE,
                title: intl.formatMessage({
                    id: 'common.none',
                    defaultMessage: 'None',
                }),
            },
        ];
    }

    return (
        <FilterItem>
            <FilterTitle>
                <FormattedMessage
                    id="225d8.AdvancedFiltering.searchByResult"
                    defaultMessage="Search by application result:"
                />
            </FilterTitle>
            <StaticResult data-testid="advancedFilteringResult" selected={value} handleChange={onChange}>
                {options}
            </StaticResult>
        </FilterItem>
    );
};
