// Libraries
import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { PopupButton } from '@typeform/embed-react';
import { useSelector } from 'react-redux';

// Components
import Text from 'certn-ui/Text';
import { AppearRight } from 'certn-ui/Animate';

import Title from 'certn-ui/Title';
import { Space } from 'antd';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Trophy from 'images/svgs/svg-components/onboarding/Trophy';
import { getLanguage } from 'base/BaseSelectors';

import { LANGUAGE_EN, LANGUAGE_ES, LANGUAGE_FR } from 'utils/constants';

const typeformID = {
    [LANGUAGE_EN]: 'k1xf6nEz',
    [LANGUAGE_FR]: 'UfjWPN9m',
    [LANGUAGE_ES]: 't0lLVyt2',
};

const StyledTypeFormPopupButton = styled(PopupButton)`
    padding: 6px 24px;
    outline: none;
    background-color: ${(props) => props.theme.color.primary};
    color: ${(props) => props.theme.color.certnWhite};
    border: none;
    cursor: pointer;
`;

const ResponseSuccess = () => {
    const [surveySubmitted, setSurveySubmitted] = React.useState(false);
    const language = useSelector(getLanguage);
    const { webFeatureApplicantFlowFeedbackSurvey } = useFlags();

    return (
        <AppearRight>
            <Space direction="vertical" size="middle" align="center">
                <Title>
                    <FormattedMessage
                        id="welcome.Submit.donePageTitle"
                        defaultMessage="Congratulations, your information has been submitted! You're all done!"
                    />
                </Title>
                <Trophy />
                {webFeatureApplicantFlowFeedbackSurvey && !surveySubmitted ? (
                    <Space direction="vertical" size="middle" align="center" style={{ maxWidth: '80%' }}>
                        <Text align="center" color="certnGray500">
                            <FormattedMessage
                                id="welcome.Submit.surveyDescription"
                                defaultMessage="How was your experience today? If you have two minutes to spare, we'd love to hear how we can improve. Your feedback is optional, anonymous, and will not impact the outcome of your submission. It will only be used to improve the experience."
                            />
                        </Text>
                        <StyledTypeFormPopupButton id={typeformID[language]} onSubmit={() => setSurveySubmitted(true)}>
                            <FormattedMessage id="welcome.Submit.surveyButtonText" defaultMessage="Provide Feedback" />
                        </StyledTypeFormPopupButton>
                    </Space>
                ) : (
                    <Text>
                        <FormattedMessage
                            id="welcome.general.youCanCloseTab"
                            defaultMessage="You can now close this tab."
                        />
                    </Text>
                )}
            </Space>
        </AppearRight>
    );
};

export default ResponseSuccess;
