import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { getLanguage } from 'base/BaseSelectors';

type FaqURLSProps = Record<string, string>;

const FAQ_URLS: FaqURLSProps = {
    en: 'https://mycrc.ca/faq/',
    fr: 'https://mycrc.ca/fr/faq/',
    es: 'https://mycrc.ca/es/faq/',
    default: 'https://mycrc.ca/faq/',
};

export const FaqLink = () => {
    const language: string = useSelector(getLanguage);

    const faqLink = useMemo(() => FAQ_URLS[language] || FAQ_URLS.default, [language]);

    return (
        <a href={faqLink} target="_blank" rel="noopener noreferrer">
            <FormattedMessage id="welcome.MyCRCConsent.faqLink" defaultMessage="Check out our FAQ" />
        </a>
    );
};
