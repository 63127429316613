// Libraries
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Form as AntdForm } from '@ant-design/compatible';
import { Button, Modal, Checkbox } from 'antd';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

// Components
import { AppearRight } from 'certn-ui/Animate';
import Text, { SecondaryLink } from 'certn-ui/Text';
import Title from 'certn-ui/Title';
import { TrackNavigation } from 'views/welcome/components';
import Form from 'certnd/Form';
import FormLayout from 'certn-form/FormLayout';

// Actions & Selectors
import { getInformation } from 'views/welcome/WelcomeSelectors';

// Constants
import { VACCINATION_OPTIONS, VACCINATION_READABLE_OPTIONS } from 'views/welcome/WelcomeConstants';

const CheckboxWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: fit-content;
    margin: auto;
    padding-top: 10px;
`;

const COVID_URL =
    'https://www.canada.ca/en/health-canada/services/drugs-health-products/covid19-industry/drugs-vaccines-treatments/vaccines.html';

const propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired,
    information: PropTypes.object.isRequired,
    settings: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    isWarningVisible: PropTypes.func.isRequired,
    setIsWarningVisible: PropTypes.func.isRequired,
    warningType: PropTypes.string.isRequired,
    isNextDisabled: PropTypes.func.isRequired,
    setIsNextDisabled: PropTypes.func.isRequired,
};

const VaccinationForm = ({
    handleSubmit,
    form,
    information,
    settings,
    isWarningVisible,
    setIsWarningVisible,
    warningType,
    isNextDisabled,
    setIsNextDisabled,
}) => {
    const isAPIConsentGiven = useSelector(getInformation).vaccination_consent_given || undefined;
    const [isConsentGiven, setConsentGiven] = useState(isAPIConsentGiven);

    const handleToggleCheckbox = (value, consent) => {
        setConsentGiven(consent);
    };

    return (
        <AppearRight>
            <Modal
                closable={false}
                width="600px"
                title={
                    <Text weight="900">
                        <FormattedMessage id="common.warning" defaultMessage="Warning" />
                    </Text>
                }
                visible={isWarningVisible}
                footer={[
                    <Button
                        style={{ textTransform: 'uppercase' }}
                        key="submit"
                        type="primary"
                        htmlType="submit"
                        onClick={() => setIsWarningVisible(false)}
                    >
                        <FormattedMessage id="common.ok" defaultMessage="OK" />
                    </Button>,
                ]}
            >
                {warningType === 'displayConsentWarning' && (
                    <div data-testid="consent-warning">
                        <FormattedMessage
                            id="welcome.VaccinationInfo.noConsentGiven"
                            defaultMessage="We need your consent before we can collect and verify your COVID-19 Vaccination status. This is a security and safety measure meant to protect your personal information. If you do not consent, you may close this window and exit your application. For further assistance please reach out to Certn Support at {email}"
                            values={{
                                email: (
                                    <a href="mailto:support@certn.co">
                                        <FormattedMessage
                                            id="welcome.VaccinationInfo.supportEmailAddress"
                                            defaultMessage="support@certn.co"
                                        />
                                    </a>
                                ),
                            }}
                        />
                    </div>
                )}
                {warningType === 'displayNotVaxWarning' && (
                    <div data-testid="not-vaxed-warning">
                        <FormattedMessage
                            id="welcome.VaccinationInfo.warningRequired"
                            defaultMessage="It is required that you are fully vaccinated in order to continue the application. You may also be asked at a later date to show proof of vaccination."
                        />
                        <br />
                        <br />
                        <FormattedMessage
                            id="welcome.VaccinationInfo.warningExit"
                            defaultMessage="If you cannot continue, you may close this window and exit your application. For assistance please reach out to Certn Support at support@certn.co"
                        />
                    </div>
                )}
            </Modal>
            <AntdForm layout="horizontal" onSubmit={(e) => handleSubmit(e, form.validateFields, isConsentGiven)}>
                <FormLayout center>
                    <Title>
                        <FormattedMessage
                            id="welcome.VaccinationInfo.vaccinationTitle"
                            defaultMessage="Self-Reported COVID-19 Vaccination Status"
                        />
                    </Title>
                    <Text size="xs">
                        <FormattedMessage
                            id="welcome.VaccinationInfo.formDescription"
                            defaultMessage="{company} is committed to ensuring a safe workplace for everyone on the team, as well as for our customers and the general public. Getting vaccinated is the best way to protect yourself and others from COVID-19 and the fastest way to get us past this crisis. As such, effective October 31, the Company requires full vaccination for all team members. Anyone who believes they cannot be vaccinated based on grounds protected by human rights legislation (including for medical reasons) may apply for an accommodation, which will be reviewed in accordance with applicable standards. “Fully Vaccinated” means you have received the full series of COVID-19 vaccines {accepted}:"
                            values={{
                                company: settings.get_org_name,
                                accepted: (
                                    <a href={COVID_URL} target="_blank" rel="noopener noreferrer">
                                        <FormattedMessage
                                            id="welcome.VaccinationInfo.formDescriptionURL"
                                            defaultMessage="accepted by Health Canada"
                                        />
                                    </a>
                                ),
                            }}
                        />
                    </Text>
                    <Text weight="900" margin="24px 0 0 0">
                        <FormattedMessage id="welcome.VaccinationInfo.pleaseSelect" defaultMessage="Please select:" />
                    </Text>
                    <Form.Radio
                        hideBorder
                        form={form}
                        onChange={() => setIsNextDisabled(false)}
                        fieldName="self_reported_vaccination_status"
                        options={{ initialValue: information.self_reported_vaccination_status }}
                        radioOptions={[
                            {
                                title: VACCINATION_READABLE_OPTIONS.FULLY_VACCINATED_MORE_THAN_14_DAYS_AGO,
                                key: VACCINATION_OPTIONS.FULLY_VACCINATED_MORE_THAN_14_DAYS_AGO,
                                value: VACCINATION_OPTIONS.FULLY_VACCINATED_MORE_THAN_14_DAYS_AGO,
                            },
                            {
                                title: VACCINATION_READABLE_OPTIONS.FULLY_VACCINATED_LESS_THAN_14_DAYS_AGO,
                                key: VACCINATION_OPTIONS.FULLY_VACCINATED_LESS_THAN_14_DAYS_AGO,
                                value: VACCINATION_OPTIONS.FULLY_VACCINATED_LESS_THAN_14_DAYS_AGO,
                            },
                            {
                                title: VACCINATION_READABLE_OPTIONS.PARTIALLY_VACCINATED,
                                key: VACCINATION_OPTIONS.PARTIALLY_VACCINATED,
                                value: VACCINATION_OPTIONS.PARTIALLY_VACCINATED,
                            },
                            {
                                title: VACCINATION_READABLE_OPTIONS.NOT_VACCINATED_WITH_INTENT_TO_BE,
                                key: VACCINATION_OPTIONS.NOT_VACCINATED_WITH_INTENT_TO_BE,
                                value: VACCINATION_OPTIONS.NOT_VACCINATED_WITH_INTENT_TO_BE,
                            },
                            {
                                title: VACCINATION_READABLE_OPTIONS.NOT_VACCINATED_WITH_NO_INTENT_TO_BE,
                                key: VACCINATION_OPTIONS.NOT_VACCINATED_WITH_NO_INTENT_TO_BE,
                                value: VACCINATION_OPTIONS.NOT_VACCINATED_WITH_NO_INTENT_TO_BE,
                            },
                            {
                                title: VACCINATION_READABLE_OPTIONS.VACCINATION_EXEMPT_DUE_TO_HUMAN_RIGHTS_LEGISLATION,
                                key: VACCINATION_OPTIONS.VACCINATION_EXEMPT_DUE_TO_HUMAN_RIGHTS_LEGISLATION,
                                value: VACCINATION_OPTIONS.VACCINATION_EXEMPT_DUE_TO_HUMAN_RIGHTS_LEGISLATION,
                            },
                        ]}
                    />
                    <Text size="xs">
                        <FormattedMessage
                            id="welcome.VaccinationConsent.VaccinationConsent"
                            defaultMessage="By clicking “Yes, I Agree” I hereby consent and authorize Certn, on behalf of {company}, to process my self-disclosed vaccination status, and verify my proof of vaccination documentation. Further, I hereby consent and authorize Certn to share the results of the foregoing with {company} upon request. I understand that any questions related to the contents of the current form or required proof of vaccination should be directed to {company}."
                            values={{
                                company: settings.get_org_name,
                            }}
                        />
                    </Text>
                    <CheckboxWrapper>
                        <Checkbox
                            checked={isConsentGiven}
                            disabled={isAPIConsentGiven}
                            onChange={(e) => handleToggleCheckbox(e.target.checked, true)}
                        >
                            <FormattedMessage id="welcome.general.agree" defaultMessage="Yes, I agree" />
                        </Checkbox>
                        <br />
                        <Checkbox
                            checked={isConsentGiven === false}
                            disabled={isAPIConsentGiven}
                            onChange={(e) => handleToggleCheckbox(e.target.checked, false)}
                        >
                            <FormattedMessage id="welcome.general.disagree" defaultMessage="No, I do not agree" />
                        </Checkbox>
                    </CheckboxWrapper>
                    <TrackNavigation disabled={isNextDisabled || isConsentGiven === undefined} />
                    <Text align="center">
                        <SecondaryLink target="_blank" href={COVID_URL}>
                            <FormattedMessage
                                id="welcome.VaccinationInfo.guidelines"
                                defaultMessage="Canada COVID-19 vaccination guidelines"
                            />
                        </SecondaryLink>
                    </Text>
                </FormLayout>
            </AntdForm>
        </AppearRight>
    );
};

VaccinationForm.propTypes = propTypes;

export default AntdForm.create()(VaccinationForm);
