import { useContext } from 'react';
import Form from 'certnd/Form';
import { intl } from 'components/GlobalProvider';
import { PackagesContext } from '../../Packages.context';

export const PackageQuickscreen = () => {
    const { form, editingPackage } = useContext(PackagesContext);

    return (
        <Form.Checkbox
            form={form}
            data-testid="PackageQuickscreen"
            fieldName="is_quickscreen"
            title={intl.formatMessage({
                id: '22572.Packages.Quickscreen',
                defaultMessage: 'Run check as Quickscreen',
            })}
            description={intl.formatMessage({
                id: '22572.Packages.QuickscreenDescription',
                defaultMessage: 'By enabling this option, email invites will be skipped',
            })}
            options={{
                initialValue: editingPackage.is_quickscreen,
            }}
        />
    );
};
