import React from 'react';
import PropTypes from 'prop-types';
import { AutoComplete, Input } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { Countries } from '../modules';
import { CA, isUKOrGB, US, AUS } from '../modules/Countries';
import { getSettings } from '../views/welcome/WelcomeSelectors';
import { getTeamCountry } from '../base/BaseSelectors';

/**
 * A custom input that displays the full Province/State name instead of the 2 letter code.
 */
const CustomInput = React.forwardRef(({ value, options, displaySuffix = false, ...rest }, ref) => {
    let label = value;
    // Find the matching option, and display the label
    options.some(({ label: optLabel, value: optValue, options: childOptions }) => {
        if (optValue === value) {
            label = optLabel;
            return true;
        }
        if (childOptions) {
            const child = childOptions.find(({ value: childValue }) => childValue === value);
            if (child) {
                label = child.label;
                return true;
            }
        }
        return false;
    });

    return (
        <Input
            value={label}
            {...rest}
            allowClear
            ref={ref}
            suffix={displaySuffix ? <DownOutlined style={{ opacity: '0.2', fontSize: '0.75rem' }} /> : undefined}
        />
    );
});

const propTypes = {
    selectedCountry: PropTypes.string,
    placeholder: PropTypes.string,
    // Any other prop allowed on AutoComplete
};

const defaultProps = {
    selectedCountry: undefined,
    placeholder: undefined,
};

/**
 * Renders a text input that supports autocomplete and by default displays the
 * Canadian provinces, US States, Australian States/Territories and UK provinces in 4 option groups.
 * @param {string} [selectedCountry] the selected country code, if CA, US, or UK/GB then only the
 * @param {string} [placeholder] the tooltip to display
 * @param {*} [rest] the other props
 */
export const ProvStateAutoComplete = ({ selectedCountry, placeholder, ...rest }) => {
    const intl = useIntl();
    const orgCountry = useSelector(getSettings).get_org_country;
    const teamCountry = useSelector(getTeamCountry);

    let options;
    const canada = {
        label: intl.formatMessage({
            id: 'welcome.intlinput.canada',
            defaultMessage: 'Canada',
        }),
        options: Countries.getCanadaProvinces(intl).map(({ name: label, alpha2: value }) => ({ label, value })),
    };
    const us = {
        label: intl.formatMessage({
            id: 'welcome.intlinput.usa',
            defaultMessage: 'United States',
        }),
        options: Countries.getUSStates(intl).map(({ name: label, alpha2: value }) => ({ label, value })),
    };

    const uk = {
        label: intl.formatMessage({
            id: 'welcome.intlinput.uk',
            defaultMessage: 'United Kingdom',
        }),
        options: Countries.getUKProvinces(intl).map(({ name: label, alpha2: value }) => ({ label, value })),
    };

    const australia = {
        label: intl.formatMessage({
            id: 'welcome.intlinput.australia',
            defaultMessage: 'Australia',
        }),
        options: Countries.getAusStatesTerritories(intl).map(({ name: label, alpha2: value }) => ({ label, value })),
    };

    if (selectedCountry === AUS) {
        options = [australia];
    } else if (selectedCountry === CA) {
        options = [canada];
    } else if (selectedCountry === US) {
        options = [us];
    } else if (isUKOrGB(selectedCountry)) {
        options = [uk];
    } else if (orgCountry === US || teamCountry === US) {
        options = [us, canada, uk];
    } else if (isUKOrGB(orgCountry) || isUKOrGB(teamCountry)) {
        options = [uk, canada, us];
    } else {
        options = [canada, us, uk];
    }

    const displaySuffix = selectedCountry === AUS;

    return (
        <AutoComplete
            options={options}
            filterOption={(input = '', { label = '', value = '' }) =>
                label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                value.toLowerCase().indexOf(input.toLowerCase()) === 0
            }
            autoComplete="province"
            {...rest}
            placeholder={
                placeholder ||
                intl.formatMessage({
                    id: 'address.provinceState',
                    defaultMessage: 'Province/State',
                })
            }
        >
            <CustomInput options={options} displaySuffix={displaySuffix} />
        </AutoComplete>
    );
};

ProvStateAutoComplete.propTypes = propTypes;
ProvStateAutoComplete.defaultProps = defaultProps;

export default ProvStateAutoComplete;
