// Libraries
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Form as AntdForm } from '@ant-design/compatible';
import { get } from 'lodash';

// Components
import { AppearRight } from 'certn-ui/Animate';
import Title from 'certn-ui/Title';
import { TrackNavigation, CustomFields } from 'views/welcome/components';
import FormLayout from 'certn-form/FormLayout';

const InternationalFieldsForm = ({
    information,
    internationalFields,
    handleSubmit,
    form,
    form: { validateFields },
}) => (
    <AppearRight>
        <AntdForm layout="horizontal" onSubmit={(e) => handleSubmit(e, validateFields)}>
            <Title>
                <FormattedMessage
                    id="welcome.InternationalFields.title"
                    defaultMessage="Additional fields are required to complete an International Criminal Record Check."
                />
            </Title>
            <FormLayout>
                {internationalFields &&
                    internationalFields.length > 0 &&
                    internationalFields.map(
                        ({ field, label, type, options }) =>
                            label && (
                                <CustomFields
                                    type={type}
                                    form={form}
                                    field={field}
                                    label={label}
                                    selectOptions={options}
                                    initialValue={get(information, field)}
                                />
                            )
                    )}
                <TrackNavigation />
            </FormLayout>
        </AntdForm>
    </AppearRight>
);

export default AntdForm.create()(InternationalFieldsForm);
