import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { FormattedMessage } from 'react-intl';
import { TextLink } from 'certn-ui/Text';

const Wrapper = styled.div`
    width: 600px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    margin: ${({ margin }) => margin || '0 auto'};
`;
const Body = styled.span`
    font-size: 11px;
    text-align: ${({ textAlign }) => textAlign || 'center'};
    margin: 5px 0;
    color: ${({ theme }) => theme.color.certnGray400};
`;

const propTypes = {
    margin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    textAlign: PropTypes.string,
    modal: PropTypes.bool,
};

const defaultProps = {
    margin: null,
    textAlign: null,
    modal: false,
};

const SoftcheckDisclaimer = ({ margin, textAlign, modal }) => (
    <Wrapper margin={margin}>
        <Body textAlign={textAlign}>
            <FormattedMessage
                id="ca51d.SoftcheckDisclaimer.description"
                defaultMessage={`Softcheck provides instant access to public records quickly and easily. Softcheck is not a consumer
                report as defined under the Fair Credit Reporting Act, or "FCRA". Certn cannot guarantee the
                accuracy of the information contained in a Softcheck, particularly social media profiles and
                criminal record information.`}
            />{' '}
            {modal && (
                <FormattedMessage
                    id="ca51d.SoftcheckDisclaimer.guarantee"
                    defaultMessage="To guarantee the accuracy of records, please ensure you select Basic, or Enhanced ID verification, and a Criminal Record Check. "
                />
            )}
            <FormattedMessage
                id="ca51d.SoftcheckDisclaimer.permission"
                defaultMessage="By clicking 'submit' you agree that you have the required consent and permissible purpose to perform a Softcheck as per our {termsOfUse} and {privacyPolicy}."
                values={{
                    termsOfUse: (
                        <TextLink target="_blank" href="https://certn.co/terms-of-use/" rel="noopener noreferrer">
                            <FormattedMessage id="welcome.Basics.termsOfUse" defaultMessage="Terms of Use" />
                        </TextLink>
                    ),
                    privacyPolicy: (
                        <TextLink target="_blank" href="https://certn.co/privacy-policy/" rel="noopener noreferrer">
                            <FormattedMessage id="common.privacyPolicy" defaultMessage="Privacy Policy" />
                        </TextLink>
                    ),
                }}
            />
        </Body>
    </Wrapper>
);

SoftcheckDisclaimer.propTypes = propTypes;
SoftcheckDisclaimer.defaultProps = defaultProps;

export default SoftcheckDisclaimer;
