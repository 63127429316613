import styled from 'styled-components';
import { getAdverseActionStatusMessage, AdverseActionStatus } from '../../applicationsView/utils';

interface AAStatusCellProps {
    record: {
        adverse_action_status: AdverseActionStatus;
    };
}

interface StatusProps {
    faded: boolean;
}

const Status = styled.span<StatusProps>`
    color: ${(props) => (props.faded ? props.theme.color.certnGray400 : 'inherit')};
`;

const AdverseActionStatusCell: React.FC<AAStatusCellProps> = ({ record }) => {
    const isFaded = !record?.adverse_action_status;
    return <Status faded={isFaded}>{getAdverseActionStatusMessage(record.adverse_action_status)}</Status>;
};
export default AdverseActionStatusCell;
