/* eslint-disable react/require-default-props */
import { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components/macro';
import { Form } from '@ant-design/compatible';
import { Input, InputNumber, AutoComplete } from 'antd';
import Typography from 'certnd/Typography';
import { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

const { Heading, Text } = Typography;

const FormInputWrapper = styled.div<{ hideBorder?: boolean; bottomPadding?: string }>`
    display: block;
    width: 100%;
    margin-bottom: 20px;
    ${(props) => props.bottomPadding && `padding-bottom: ${props.bottomPadding}`};
    border-bottom: ${(props) => (props.hideBorder ? 'none' : `1px solid ${props.theme.color.certnGray200}`)};
`;

export type FormInputBaseProps = {
    title: string;
    titleProps: Record<string, string>;
    description: string;
    descriptionProps: Record<string, string>;
    fieldName: string | number;
    form: WrappedFormUtils<unknown>;
    headerLeft: string;
    options: Record<string, unknown>;
    dataSource: string;
    hideBorder?: boolean;
    addTopMargin: string;
    label: string;
    size: SizeType;
    addonBefore?: React.ReactNode;
    placeholder?: React.ReactNode;
    autoComplete: string;
    min: number;
    max: number;
    disabled: boolean;
    bottomPadding: string;
    hideBroder: boolean;
    onChange: Dispatch<SetStateAction<number | undefined>> | (() => void);
};
// refactor how rest operators are handled so we can use generics instead of any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const FormInputBase = (InputComponent: any) => ({
    title,
    titleProps,
    description,
    descriptionProps,
    fieldName = '',
    form,
    headerLeft,
    options,
    dataSource,
    hideBorder,
    addTopMargin,
    label,
    bottomPadding,
    ...rest
}: Partial<FormInputBaseProps>) => {
    // In Ant V4 the AutoComplete dataSource attribute is deprecated
    // and should be renamed to options
    const inputAttrs: Record<string, unknown> = {};
    if (dataSource) {
        inputAttrs.options = dataSource;
    }
    return (
        <FormInputWrapper
            hideBorder={hideBorder}
            style={{ marginTop: addTopMargin ? '20px' : '0px' }}
            bottomPadding={bottomPadding}
        >
            {title ? (
                <Heading.H4 headerLeft={headerLeft} disableGutter {...titleProps}>
                    {title}
                </Heading.H4>
            ) : null}
            <Form.Item style={{ marginBottom: 5 }} label={label}>
                {form?.getFieldDecorator(
                    fieldName,
                    options
                )(
                    <InputComponent
                        style={{ width: '100%' }}
                        {...inputAttrs}
                        {...(rest as Partial<FormInputBaseProps>)}
                    />
                )}
            </Form.Item>
            {description ? <Text.Secondary {...descriptionProps}>{description}</Text.Secondary> : null}
        </FormInputWrapper>
    );
};

export const FormAutoComplete = FormInputBase(AutoComplete);
export const FormInput = FormInputBase(Input);
export const FormTextArea = FormInputBase(Input.TextArea);
export const FormPassword = FormInputBase(Input.Password);
export const FormSearch = FormInputBase(Input.Search);
export const FormInputNumber = FormInputBase(InputNumber);

export default FormInput;
