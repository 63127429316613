import { Button } from '@certn/ui';
import styled from 'styled-components';
import styles from 'styles/styles';

export interface CustomButtonProps {
    skinColor: string;
}
// 8 digits notation with E6 at the end for hex background color to adjust the opacity to 90%
export const CustomButton = styled(Button)<CustomButtonProps>`
    background-color: ${({ skinColor }) => skinColor || styles.color.certnGreen900};

    &:hover {
        background-color: ${({ skinColor }) => (skinColor ? `${skinColor}E6` : styles.color.certnGreen600)};
    }

    &:focus {
        background-color: ${({ skinColor }) => (skinColor ? `${skinColor}E6` : styles.color.certnGreen600)};
    }
`;
