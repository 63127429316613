import React from 'react';
import { useDispatch } from 'react-redux';
import { Menu, message } from 'antd';
import PropTypes from 'prop-types';

import { ListCellText } from 'certn-ui/List';

import { renewExpiredApplicants } from 'views/manager/views/hr/views/applications/ApplicationsActions';

const ItemRenewExpiredApplicants = ({
    applicantIds,
    successMessage,
    errorMessage,
    listText,
    fetchApplicationsMethod,
    ...rest
}) => {
    const dispatch = useDispatch();

    const handleRenewSuccess = (alert) => {
        message.success(alert);
    };

    const handleRenewError = (alert) => {
        message.error(alert);
    };

    const handleRenewExpiredApplicant = async () => {
        try {
            await dispatch(renewExpiredApplicants(applicantIds));
            await dispatch(fetchApplicationsMethod());
            // TODO handle response in multiple case (renewed, unchanged, undefined)
            handleRenewSuccess(successMessage);
        } catch (error) {
            handleRenewError(errorMessage);
        }
    };

    return (
        <Menu.Item {...rest}>
            <ListCellText clickable onClick={handleRenewExpiredApplicant}>
                {listText}
            </ListCellText>
        </Menu.Item>
    );
};

ItemRenewExpiredApplicants.propTypes = {
    applicantIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    successMessage: PropTypes.string.isRequired,
    errorMessage: PropTypes.string.isRequired,
    listText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    fetchApplicationsMethod: PropTypes.func.isRequired,
};

export default ItemRenewExpiredApplicants;
