// Libraries
import { useIntl } from 'react-intl';
import { CheckStatus } from 'types';

export const useTranslateCheckStatus = () => {
    const intl = useIntl();

    const translateCheckStatus = (checkStatus: string | undefined | null) => {
        switch (checkStatus) {
            case CheckStatus.WAITING_ON_CANDIDATE:
                return intl.formatMessage({
                    id: 'status.waitingOnCandidate',
                    defaultMessage: 'Waiting on candidate',
                });
            case CheckStatus.IN_PROGRESS:
                return intl.formatMessage({
                    id: 'status.inProgress',
                    defaultMessage: 'In progress',
                });
            case CheckStatus.REQUIRES_MORE_INFORMATION:
                return intl.formatMessage({
                    id: 'status.requiresMoreInformation',
                    defaultMessage: 'Requires more info',
                });
            case CheckStatus.COMPLETE:
                return intl.formatMessage({
                    id: 'status.complete',
                    defaultMessage: 'Complete',
                });

            case CheckStatus.UNRESPONSIVE:
                return intl.formatMessage({
                    id: 'status.unresponsive',
                    defaultMessage: 'Unresponsive',
                });

            case CheckStatus.CANCELLED:
                return intl.formatMessage({
                    id: 'status.cancelled',
                    defaultMessage: 'Cancelled',
                });

            case CheckStatus.ACTION_REQUIRED:
                return intl.formatMessage({
                    id: 'status.actionRequired',
                    defaultMessage: 'Action required',
                });

            case CheckStatus.IN_DISPUTE:
                return intl.formatMessage({
                    id: 'status.inDispute',
                    defaultMessage: 'In dispute',
                });
            case CheckStatus.WAITING_ON_ADJUDICATOR:
                return intl.formatMessage({
                    id: 'status.waitingOnAdjudicator',
                    defaultMessage: 'Waiting on adjudicator',
                });
            default:
                console.error(`Check status translation missing for ${checkStatus}.`);
                return null;
        }
    };

    return { translateCheckStatus };
};
