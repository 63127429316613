// Libraries
import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import { Input, Row, Col, Popover } from 'antd';

// Components
import { AppearRight } from 'certn-ui/Animate';
import { Box } from 'certn-ui/Layout';
import Text from 'certn-ui/Text';
import Title from 'certn-ui/Title';
import { TrackNavigation, DateOfBirthInput } from 'views/welcome/components';
import FormLayout from 'certn-form/FormLayout';

// Modules
import { CheckRequested, Regex } from 'modules';

// Constants
import { CHECK_REQUEST } from 'base/BaseConstants';
import { identityNumberGetter } from 'views/welcome/identityNumbers';

const MoreBasicsForm = ({
    handleSubmit,
    form: { getFieldDecorator, validateFields },
    information,
    applicant,
    intl,
    teamIsCA,
    teamIsUS,
}) => {
    const ssnRequired = CheckRequested.usCriminal(applicant);
    const isUkCheck = CheckRequested.ukCriminal(applicant);
    const ssnOptional = teamIsUS && applicant?.[CHECK_REQUEST.EMPLOYMENT_VERIFICATION];
    const showSSN = ssnRequired || ssnOptional;
    const sinRequired = CheckRequested.canadianCriminal(applicant);
    const showSIN = teamIsCA || sinRequired;

    return (
        <AppearRight>
            <Form layout="horizontal" onSubmit={(e) => handleSubmit(e, validateFields)}>
                <Title>
                    <FormattedMessage
                        id="welcome.MoreBasics.title"
                        defaultMessage="We need a few more pieces of information to verify your identity:"
                    />
                </Title>
                <FormLayout>
                    {showSIN && (
                        <Form.Item>
                            {getFieldDecorator('sin', {
                                initialValue: identityNumberGetter('CA')(information),
                                rules: [
                                    {
                                        required: false,
                                        pattern: Regex.sinssn,
                                        message: intl.formatMessage({
                                            id: 'error.validation.sin',
                                            defaultMessage: 'Please provide a valid SIN',
                                        }),
                                    },
                                ],
                            })(
                                <Input
                                    maxLength={9}
                                    size="large"
                                    placeholder={intl.formatMessage({
                                        id: 'welcome.MoreBasics.sin',
                                        defaultMessage: 'Social Insurance Number - Optional',
                                    })}
                                    addonBefore={
                                        <Popover
                                            content={
                                                <FormattedMessage
                                                    id="welcome.MoreBasics.sin"
                                                    defaultMessage="Social Insurance Number - Optional"
                                                />
                                            }
                                        >
                                            <InfoCircleOutlined />
                                        </Popover>
                                    }
                                />
                            )}
                        </Form.Item>
                    )}
                    {isUkCheck && (
                        <Form.Item>
                            {getFieldDecorator('nin', {
                                initialValue: identityNumberGetter('GB')(information),
                                rules: [
                                    {
                                        required: false,
                                        pattern: Regex.niNumber,
                                        message: intl.formatMessage({
                                            id: 'error.validation.nin',
                                            defaultMessage: 'Please provide a valid National Insurance number',
                                        }),
                                    },
                                ],
                                validateTrigger: 'onBlur',
                            })(
                                <Input
                                    maxLength={9}
                                    size="large"
                                    placeholder={intl.formatMessage({
                                        id: 'welcome.MoreBasics.nin',
                                        defaultMessage: 'National Insurance Number - Optional',
                                    })}
                                    addonBefore={
                                        <Popover
                                            content={
                                                <FormattedMessage
                                                    id="welcome.MoreBasics.nin"
                                                    defaultMessage="National Insurance Number - Optional"
                                                />
                                            }
                                        >
                                            <InfoCircleOutlined />
                                        </Popover>
                                    }
                                />
                            )}
                        </Form.Item>
                    )}
                    {showSSN && (
                        <Form.Item>
                            {getFieldDecorator('ssn', {
                                initialValue: identityNumberGetter('US')(information),
                                rules: [
                                    {
                                        required: ssnRequired,
                                        pattern: Regex.sinssn,
                                        message: intl.formatMessage({
                                            id: 'error.validation.ssn',
                                            defaultMessage: 'Please provide a valid SSN',
                                        }),
                                    },
                                ],
                            })(
                                <Input
                                    maxLength={9}
                                    size="large"
                                    placeholder={intl.formatMessage({
                                        id: 'welcome.MoreBasics.ssnUSA',
                                        defaultMessage: 'Social Security Number (USA)',
                                    })}
                                    addonBefore={
                                        <Popover
                                            content={
                                                <FormattedMessage
                                                    id="welcome.MoreBasics.ssnUSA"
                                                    defaultMessage="Social Security Number (USA)"
                                                />
                                            }
                                        >
                                            <InfoCircleOutlined />
                                        </Popover>
                                    }
                                />
                            )}
                        </Form.Item>
                    )}

                    <DateOfBirthInput getFieldDecorator={getFieldDecorator} isUkCheck={isUkCheck} />

                    {applicant?.[CHECK_REQUEST.UK_BASIC_DS_CHECK] && (
                        <Form.Item>
                            {getFieldDecorator('mothers_maiden_name', {
                                initialValue: information?.mothers_maiden_name,
                                rules: [
                                    {
                                        required: true,
                                        message: intl.formatMessage({
                                            id: 'error.validation.mothersMaidenName',
                                            defaultMessage: "Please provide your mother's maiden name",
                                        }),
                                    },
                                ],
                            })(
                                <Input
                                    maxLength={32}
                                    size="large"
                                    placeholder={intl.formatMessage({
                                        id: 'welcome.MoreBasics.mothersMaidenName',
                                        defaultMessage: "Mother's maiden name",
                                    })}
                                    addonBefore={
                                        <Popover
                                            content={
                                                <FormattedMessage
                                                    id="welcome.MoreBasics.mothersMaidenName"
                                                    defaultMessage="Mother's maiden name"
                                                />
                                            }
                                        >
                                            <InfoCircleOutlined />
                                        </Popover>
                                    }
                                />
                            )}
                        </Form.Item>
                    )}
                </FormLayout>
                <Row gutter={16}>
                    <Col sm={24} lg={{ span: 24, offset: 0 }}>
                        <Box size="xxs">
                            <Text align="center" color="certnGray400">
                                <FormattedMessage
                                    id="welcome.MoreBasics.explain"
                                    defaultMessage="These fields help to identify you over other people that may share your basic information."
                                />
                            </Text>
                        </Box>
                    </Col>
                </Row>
                <TrackNavigation />
            </Form>
        </AppearRight>
    );
};

export default Form.create()(injectIntl(MoreBasicsForm));
