import React from 'react';
import PropTypes from 'prop-types';
import { FilePdfFilled } from '@ant-design/icons';
import { Row, Col, Grid } from 'antd';
import { FormattedMessage } from 'react-intl';

import { DocSigningWrapper, DocumentSignButton, PDFIconWithText } from './styled';
import Text from 'certn-ui/Text';

import styles from 'styles/styles';

const propTypes = {
    documentTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    onOpen: PropTypes.func.isRequired,
    isSigned: PropTypes.bool.isRequired,
};

const DocSigningContainer = ({ documentTitle, onOpen, isSigned }) => {
    const { useBreakpoint } = Grid;
    const screens = useBreakpoint();

    return (
        <DocSigningWrapper>
            <Row align="middle" justify="center">
                <Col span={24} flex="column" sm={{ span: 18 }}>
                    <PDFIconWithText xs={screens.xs}>
                        <FilePdfFilled
                            style={{
                                color: styles.color.certnGray400,
                                fontSize: styles.font.med,
                            }}
                        />
                        <Text color="certnGray600" width="inherit">
                            {documentTitle}
                        </Text>
                    </PDFIconWithText>
                </Col>
                <Col span={24} sm={{ span: 6 }}>
                    <DocumentSignButton onClick={onOpen} disabled={isSigned} data-testid="view_and_sign_button">
                        {isSigned ? (
                            <FormattedMessage id="welcome.Disclosure.docsSigned" defaultMessage="Signed" />
                        ) : (
                            <FormattedMessage id="welcome.Disclosure.signDocs" defaultMessage="View and Sign" />
                        )}
                    </DocumentSignButton>
                </Col>
            </Row>
        </DocSigningWrapper>
    );
};

DocSigningContainer.propTypes = propTypes;

export default DocSigningContainer;
