// Libraries
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components/macro';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { EllipsisOutlined } from '@ant-design/icons';
import { intl } from 'components/GlobalProvider';

// Components
import { ErrorAlertAPI } from 'certn-ui/ErrorAlert';
import { ListCellText } from 'certn-ui/List';
import { Dropdown as AntDropdown, Menu, Modal, message, Button as AntButton } from 'antd';

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

const Dropdown = styled(AntDropdown)`
    margin: 8px 0px;
`;

const Button = styled(AntButton)`
    ${(props) => props.margin && `margin: ${props.margin}`};
    overflow: hidden !important;
    height: 40px !important;
    width: 40px !important;
`;

class DropdownCell extends React.Component {
    showConfirmDelete = () => {
        Modal.confirm({
            title: intl.formatMessage(
                {
                    id: '9c840.DropdownCell.ModalTitle',
                    defaultMessage: 'Do you really want to delete {template}?',
                },
                { template: this.props.templateName }
            ),
            content: intl.formatMessage({
                id: '9c840.DropdownCell.ModalDescription',
                defaultMessage:
                    'This action cannot be undone. Please confirm you have the correct reference template before proceeding',
            }),
            okType: 'danger',
            okText: intl.formatMessage({
                id: 'common.ok',
                defaultMessage: 'OK',
            }),
            cancelText: intl.formatMessage({
                id: 'common.cancel',
                defaultMessage: 'Cancel',
            }),
            onOk: () => {
                this.props
                    .deleteTemplate()
                    .then(() =>
                        message.success(
                            intl.formatMessage({
                                id: '9c840.DropdownCell.DeleteSuccess',
                                defaultMessage: 'Template deleted',
                            })
                        )
                    )
                    .catch((error) => ErrorAlertAPI(error));
            },
            onCancel() {},
        });
    };

    render() {
        const { item, editTemplate, deleteTemplate } = this.props;
        return (
            <Dropdown
                overlay={
                    <Menu>
                        <Menu.Item key="1">
                            <ListCellText data-testid="dropdowncell-edit" clickable onClick={editTemplate}>
                                <FormattedMessage id="9c840.DropdownCell.Edit" defaultMessage="Edit Template" />
                            </ListCellText>
                        </Menu.Item>
                        {deleteTemplate && (
                            <Menu.Item key="2">
                                <ListCellText clickable critical onClick={() => this.showConfirmDelete(item)}>
                                    <FormattedMessage id="9c840.DropdownCell.Delete" defaultMessage="Delete Template" />
                                </ListCellText>
                            </Menu.Item>
                        )}
                    </Menu>
                }
                trigger={['click']}
                placement="bottomCenter"
            >
                <Button data-testid="dropdowncell-button" shape="circle" icon={<EllipsisOutlined />} />
            </Dropdown>
        );
    }
}

export default withRouter(connect(undefined, mapDispatchToProps)(DropdownCell));
