// Libraries
import { FormattedMessage } from 'react-intl';
import { Modal, Select, Button } from 'antd';
import { useState } from 'react';
import { intl } from 'components/GlobalProvider';

const EmployerReferenceSkipModal = (props) => {
    const {
        skipReason,
        reasonList,
        modalVisible,
        saveReason,
        handleModalCancel,
        isFetching,
        numberOfReferences,
        yearsOrIndividual,
        numberOfYears,
        employerReferencesMin,
    } = props;
    const [continueDisabled, setContinueDisabled] = useState(!skipReason);
    const [chosenReason, setChosenReason] = useState(skipReason);
    const reasonSelected = (reason) => {
        if (reason) {
            setContinueDisabled(false);
            setChosenReason(reason);
        }
    };

    function submitReason() {
        saveReason(chosenReason);
    }

    const displayReasonList = numberOfReferences >= 1 ? reasonList.slice(1) : reasonList;

    return (
        <Modal
            title={
                <FormattedMessage
                    id="welcome.EmployerReference.modalTitle"
                    defaultMessage="Your Employer References Are Incomplete"
                />
            }
            visible={modalVisible}
            onOk={submitReason}
            okText={<FormattedMessage id="welcome.EmployerReference.continue" defaultMessage="CONTINUE" />}
            okButtonProps={{ disabled: continueDisabled }}
            cancelText={<FormattedMessage id="welcome.EmployerReference.goBack" defaultMessage="GO BACK" />}
            onCancel={handleModalCancel}
            confirmLoading={isFetching}
            centered
            width={495}
            distroyOnClose
            footer={[
                <Button key="submit" type="primary" onClick={submitReason} disabled={continueDisabled}>
                    <FormattedMessage id="welcome.EmployerReference.continue" defaultMessage="CONTINUE" />
                </Button>,
                <Button key="back" onClick={handleModalCancel}>
                    <FormattedMessage id="welcome.EmployerReference.goBack" defaultMessage="GO BACK" />
                </Button>,
            ]}
        >
            {yearsOrIndividual === 'YEARS' ? (
                <FormattedMessage
                    id="welcome.EmployerReference.missingReqiredYears"
                    defaultMessage="Please provide at least one employer reference within the last {date} years."
                    values={{
                        date: numberOfYears,
                    }}
                />
            ) : (
                <FormattedMessage
                    id="welcome.EmployerReference.missingRequired."
                    defaultMessage="You've added {refNum} of {minRef} employer references. Meeting the requirements will produce quicker results."
                    values={{ refNum: numberOfReferences, minRef: employerReferencesMin }}
                />
            )}
            <br />
            <br />
            <FormattedMessage
                id="welcome.EmployerReference.skipPrompt"
                defaultMessage="If you'd like to skip this step, select a reason from the dropdown and click 'Continue'."
            />
            <br />
            <Select
                hideBorder
                style={{ width: '100%', marginTop: '10px' }}
                placeholder={intl.formatMessage({
                    id: '74e6c.AddOptions.selectOption',
                    defaultMessage: 'Select an Option',
                })}
                defaultValue={chosenReason}
                autoComplete="false"
                options={displayReasonList.map((reason) => ({
                    key: reason.constant,
                    value: reason.readable,
                    title: reason.readable,
                }))}
                onChange={(reason) => reasonSelected(reason)}
            />
        </Modal>
    );
};

export default EmployerReferenceSkipModal;
