// Libraries
import { FormattedMessage } from 'react-intl';
import { Modal, Button } from 'antd';
import styled from 'styled-components/macro';
import { AddressHistoryMissingYearsErrors } from 'utils/addressInputHelpers';

const StyledLi = styled.li`
    list-style: disc;
    margin-left: 40px;
`;

interface Props {
    modalVisible: boolean;
    handleModalCancel: () => void;
    handleNextTrack: () => void;
    missingYears: number[];
    requiredYears: number;
    error: AddressHistoryMissingYearsErrors;
}

const AddressHistoryWarningModal = (props: Props) => {
    const { modalVisible, handleModalCancel, handleNextTrack, missingYears, requiredYears, error } = props;
    return (
        <Modal
            title={
                <b>
                    <FormattedMessage
                        id="welcome.CcrcAddressHistory.modalTitle"
                        defaultMessage="We need {requiredYears} years of address history"
                        values={{ requiredYears }}
                    />
                </b>
            }
            visible={modalVisible}
            onCancel={handleModalCancel}
            width={495}
            footer={[
                <Button key="submit" onClick={handleNextTrack}>
                    <FormattedMessage id="welcome.CcrcAddressHistory.continue" defaultMessage="CONTINUE" />
                </Button>,
                <Button key="back" type="primary" onClick={handleModalCancel}>
                    <FormattedMessage id="welcome.CcrcAddressHistory.goBack" defaultMessage="GO BACK" />
                </Button>,
            ]}
        >
            <FormattedMessage
                id="welcome.CcrcAddressHistory.missingRequiredYears"
                defaultMessage="You’re missing some information. Providing {requiredYears} years of address history will produce quicker results."
                values={{ requiredYears }}
            />
            <br />
            <br />
            {missingYears && missingYears.length > 0 ? (
                <>
                    <FormattedMessage
                        id="welcome.CcrcAddressHistory.missingYearsListHeading"
                        defaultMessage="The missing years are:"
                    />
                    <ul>
                        {missingYears.map((year, index) => (
                            <StyledLi key={index}>{year}</StyledLi>
                        ))}
                    </ul>
                </>
            ) : (
                <>
                    <FormattedMessage id="welcome.CcrcAddressHistory.missingYearsError" defaultMessage="Error:" />
                    <ul>
                        <StyledLi>
                            {error === 'AddressMissingStartDate' ? (
                                <FormattedMessage
                                    id="welcome.CcrcAddressHistory.errorAddressIsMissingStartDate"
                                    defaultMessage="Address is missing start date."
                                />
                            ) : error === 'AddressNotCurrentAndMissingEndDate' ? (
                                <FormattedMessage
                                    id="welcome.CcrcAddressHistory.errorPastAddressIsMissingEndDate"
                                    defaultMessage="Past address is missing end date."
                                />
                            ) : error === 'AddressStartDateGreaterThanAddressEndDate' ? (
                                <FormattedMessage
                                    id="welcome.CcrcAddressHistory.errorAddressStartDateGreaterThanEndDate"
                                    defaultMessage="Address start date is greater than end date."
                                />
                            ) : (
                                <FormattedMessage
                                    id="welcome.CcrcAddressHistory.errorUnknownErrorWithAddress"
                                    defaultMessage="Unknown error with address." // this can't happen
                                />
                            )}
                        </StyledLi>
                    </ul>
                </>
            )}

            <br />
            <FormattedMessage
                id="welcome.CcrcAddressHistory.warning"
                defaultMessage="If you are unable to provide {requiredYears} years of address history, click 'Continue' to proceed."
                values={{ requiredYears }}
            />
        </Modal>
    );
};

export default AddressHistoryWarningModal;
