import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { CheckCircleFilled, ExclamationCircleFilled } from '@ant-design/icons';
import { Tooltip } from 'antd';
import styles from 'styles/styles';
import { useDispatch, useSelector } from 'react-redux';
import { StatusBadge } from '@certn/ui';

// Components
import { intl } from 'components/GlobalProvider';
import ServiceBreakdown from './ServiceBreakdown';

// Selectors
import { getUser } from 'base/BaseSelectors';

// Modules & Utils
import Constants from 'utils/constants';
import { getHasUnresponsiveResult, canViewReportWithClientAdjudication } from 'modules/applicationHelper';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslateOrderStatus } from 'hooks/useTranslateOrderStatus';
import { getUniqueCheckExecutionsSortedByStatusPriority } from 'utils/tools';
import { toggleDrawer } from '../views/manager/features/Drawer/DrawerActions';

const Status = styled.div`
    min-width: 100px;
    padding: 5px 10px;
    border: 1px solid ${({ reportStatus, theme }) => theme.statusColors[reportStatus]} !important;
    border-radius: 3px;
    text-align: center;
    cursor: ${({ active, report }) => (active && !report ? 'pointer' : 'default')};
`;

const Icon = styled.div`
    z-index: 5;
    position: relative;
    font-size: ${({ theme }) => theme.font.sm} !important;
    color: ${({ theme, danger }) => (danger ? theme.color.certnRed500 : theme.color.certnGreen800)} !important;
    ${({ danger }) => !danger && 'background-color: white'};
    > span {
        /* overflow top corner checkmark/exclamation mark for complete status */
        position: absolute;
        top: -38px;
        right: 14px;
        > svg {
            position: absolute;
        }
    }
`;

const StatusText = styled.span`
    color: ${({ applicationReturned, theme }) => !applicationReturned && theme.color.certnGray400};
    font-weight: 900;
`;

ApplicationStatus.propTypes = {
    application: PropTypes.object.isRequired,
    report: PropTypes.bool,
    sideBar: PropTypes.bool,
};

ApplicationStatus.defaultProps = {
    report: false,
    sideBar: false,
};

function ApplicationStatus({ application, isReport, sideBar }) {
    const [mobile] = useState(window.matchMedia(`(max-width: ${styles.width.desktop})`)?.matches);
    // TO-DO: change from report summary when API adds report_status_label
    const user = useSelector(getUser);
    const reportSummary = application.report_summary;
    const reportStatus = application.report_status;
    const orderStatus = application.order_status;
    const checkExecutions = application.check_executions;
    const reportStatusLabel = reportSummary?.report_status_label; // to swap over to top level report_status_label once Will has added
    const hasUnresponsiveResult = getHasUnresponsiveResult(application);
    const actionRequired = reportStatus === Constants.reportStatus.ACTION_REQUIRED;
    const statusIconFeedback = reportStatus === Constants.reportStatus.COMPLETE || actionRequired;
    const { webFeatureEnableNewStatusSystem } = useFlags();
    const { translateOrderStatus } = useTranslateOrderStatus();
    const dispatch = useDispatch();

    // LDFlag webFeatureEnableNewStatusSystem
    // This component is only displayed when the above LDFlag is off (otherwise Drawer)
    const userAllowedToClick = canViewReportWithClientAdjudication(application, user, application.report_status);
    const active = Constants.reportStatus.active.includes(reportStatus) && userAllowedToClick;
    const applicationReturned = Constants.reportStatus.active.includes(reportStatus);

    // Humanize some status for client readability
    const displayStatus = () => {
        if (reportStatus === Constants.reportStatus.ANALYZING) {
            return intl.formatMessage({ id: 'status.inProgress', defaultMessage: 'In progress' });
        }

        if (reportStatus === Constants.reportStatus.AWAITING_ACTION) {
            return intl.formatMessage({
                id: 'status.waitingOnReference',
                defaultMessage: 'Waiting on reference',
            });
        }

        if ([Constants.reportStatus.SENT, Constants.reportStatus.IN_PROGRESS].includes(reportStatus)) {
            return intl.formatMessage({
                id: 'status.waitingOnCandidate',
                defaultMessage: 'Waiting on candidate',
            });
        }
        return reportStatusLabel;
    };

    const statusText = <StatusText applicationReturned={applicationReturned}>{displayStatus()}</StatusText>;

    const onClick = (e, applicationId) => {
        e.stopPropagation();
        e.preventDefault();
        dispatch(toggleDrawer(true, applicationId));
    };

    // mobile just display status in InfoCell (condensed information) and in sidebar applicant section PM
    if ((mobile && !isReport) || sideBar) {
        return (
            <div data-testid="status-cell">
                {statusText}
                {/* Complete and Complete +Unresponsive service(s) require additional feedback */}
                {statusIconFeedback && !mobile && (
                    <StatusIcon actionRequired={actionRequired} hasUnresponsiveResult={hasUnresponsiveResult} />
                )}
            </div>
        );
    }

    // Show new status badge if FF active
    if (webFeatureEnableNewStatusSystem) {
        const uniqueChecks = getUniqueCheckExecutionsSortedByStatusPriority(checkExecutions);

        return (
            <div data-testid="status-badge">
                <StatusBadge
                    variant="mini"
                    orderStatus={orderStatus}
                    translatedText={{
                        of: intl.formatMessage({ id: 'drawer.of', defaultMessage: 'of' }),
                        complete: intl.formatMessage({ id: 'status.complete', defaultMessage: 'Complete' }),
                        submitted: intl.formatMessage({
                            id: '07d4b.Reference.titleBarTitle',
                            defaultMessage: 'Submitted',
                        }),
                        orderStatus: translateOrderStatus(orderStatus),
                    }}
                    onClick={(e) => onClick(e, application.id)}
                    checks={uniqueChecks}
                />
            </div>
        );
    }

    return (
        <div data-testid="status-cell">
            <Tooltip
                overlayStyle={{ 'background-color': `${styles.color.black} !important` }}
                trigger={mobile ? 'click' : 'hover'}
                title={<ServiceBreakdown status data={reportSummary} application={application} />}
            >
                <Status
                    data-testid="application-status-field"
                    block
                    report={isReport}
                    active={active}
                    reportStatus={reportStatus}
                    applicationReturned={applicationReturned}
                >
                    {statusText}
                </Status>
            </Tooltip>
            {/* Complete and Complete +Unresponsive service(s) require additional feedback */}
            {statusIconFeedback && !mobile && (
                <StatusIcon actionRequired={actionRequired} hasUnresponsiveResult={hasUnresponsiveResult} />
            )}
        </div>
    );
}
const StatusIcon = ({ actionRequired, hasUnresponsiveResult }) => {
    if (actionRequired || hasUnresponsiveResult) {
        return (
            <Icon danger data-testid="application-status-icon">
                <ExclamationCircleFilled />
            </Icon>
        );
    }
    return (
        <Icon>
            <CheckCircleFilled />
        </Icon>
    );
};

export default ApplicationStatus;
