// Libraries
import React from 'react';
import styled from 'styled-components';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Form } from '@ant-design/compatible';
import { Input, Checkbox } from 'antd';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

// Components
import { ProvStateSelect } from 'components';
import { AppearRight } from 'certn-ui/Animate';
import Text from 'certn-ui/Text';
import Title from 'certn-ui/Title';
import { TrackNavigation } from 'views/welcome/components';
import FormLayout from 'certn-form/FormLayout';

const TextLabel = styled(Text)`
    color: #819697;
`;

const CheckboxWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 15px;
    max-width: 400px;
    height: auto;
    > div {
        margin: 0 !important;
    }

    .ant-legacy-form-item-control {
        line-height: initial;
    }
`;

const CheckboxSection = styled.div`
    padding-bottom: 17.5px;

    .ant-legacy-form-item-control {
        line-height: initial;
    }
`;

const CheckboxContainers = styled.div`
    margin-top: 34px;
`;

class AustralianLicensesForm extends React.Component {
    render() {
        const {
            handleSubmit,
            information: { identity_numbers },
            form: { getFieldDecorator, validateFields, getFieldValue, setFieldsValue },
            intl,
        } = this.props;
        const driversLicense = identity_numbers?.find(
            ({ identity_number_type }) => identity_number_type === 'DRIVERS_LICENSE'
        );
        const firearmsLicense = identity_numbers?.find(
            ({ identity_number_type }) => identity_number_type === 'FIREARMS_LICENSE'
        );

        return (
            <AppearRight>
                <Form layout="horizontal" onSubmit={(e) => handleSubmit(e, validateFields)}>
                    <Title>
                        <FormattedMessage id="welcome.AustralianLicenses.title" defaultMessage="Australia Licences" />
                    </Title>
                    <FormLayout center>
                        <p>
                            <FormattedMessage
                                id="welcome.AustralianLicenses.description"
                                defaultMessage="Let us know if you have the following licences, and provide their details. The information is not required, but improves accuracy."
                            />
                        </p>
                    </FormLayout>
                    <CheckboxContainers>
                        <FormLayout>
                            <CheckboxWrapper>
                                <Form.Item>
                                    {getFieldDecorator('has_drivers_license', {
                                        initialValue: !!driversLicense,
                                        valuePropName: 'checked',
                                    })(
                                        <Checkbox
                                            onChange={(e) => setFieldsValue({ has_drivers_license: e.target.checked })}
                                        >
                                            <FormattedMessage
                                                id="welcome.AustralianLicenses.haveAusDriversLicence"
                                                defaultMessage="I have an Australian driver’s licence"
                                            />
                                        </Checkbox>
                                    )}
                                </Form.Item>
                            </CheckboxWrapper>

                            {getFieldValue('has_drivers_license') && (
                                <CheckboxSection>
                                    <TextLabel>
                                        <FormattedMessage
                                            id="welcome.AustralianLicenses.australianDriversLicence"
                                            defaultMessage="Australian Driver's License"
                                        />
                                    </TextLabel>
                                    <Form.Item>
                                        {getFieldDecorator('drivers_license_number', {
                                            initialValue: driversLicense?.number,
                                            validateTrigger: 'onBlur',
                                            getValueFromEvent: (target) => target.target.value?.toUpperCase(),
                                            rules: [
                                                {
                                                    required: getFieldValue('has_drivers_license'),
                                                    message: intl.formatMessage({
                                                        id: 'error.validation.notBlank',
                                                        defaultMessage: 'Please do not leave blank',
                                                    }),
                                                },
                                            ],
                                        })(
                                            <Input
                                                placeholder={intl.formatMessage({
                                                    id: 'welcome.AustralianLicenses.licenseNumber',
                                                    defaultMessage: 'License Number',
                                                })}
                                            />
                                        )}
                                    </Form.Item>

                                    <Form.Item>
                                        {getFieldDecorator('drivers_license_state_territory', {
                                            initialValue: driversLicense?.issuing_state_province_or_territory,
                                            rules: [
                                                {
                                                    required: getFieldValue('has_drivers_license'),
                                                    message: intl.formatMessage({
                                                        id: 'error.validation.stateTerritoryError',
                                                        defaultMessage: 'Select a state or territory!',
                                                    }),
                                                },
                                            ],
                                        })(
                                            <ProvStateSelect
                                                placeholder={
                                                    <FormattedMessage
                                                        id="welcome.AustralianLicenses.issuingStateTerritory"
                                                        defaultMessage="Issuing State / Territory"
                                                    />
                                                }
                                                selectedCountry="AU"
                                            />
                                        )}
                                    </Form.Item>
                                </CheckboxSection>
                            )}

                            <CheckboxWrapper>
                                <Form.Item>
                                    {getFieldDecorator('has_firearms_license', {
                                        initialValue: !!firearmsLicense,
                                        valuePropName: 'checked',
                                    })(
                                        <Checkbox
                                            onChange={(e) => setFieldsValue({ has_firearms_license: e.target.checked })}
                                        >
                                            <FormattedMessage
                                                id="welcome.AustralianLicenses.haveAusFirearmsLicence"
                                                defaultMessage="I have an Australian firearms licence"
                                            />
                                        </Checkbox>
                                    )}
                                </Form.Item>
                            </CheckboxWrapper>

                            {getFieldValue('has_firearms_license') && (
                                <CheckboxSection>
                                    <TextLabel>
                                        <FormattedMessage
                                            id="welcome.AustralianLicenses.australianFirearmsLicence"
                                            defaultMessage="Australian Firearms Licence"
                                        />
                                    </TextLabel>
                                    <Form.Item>
                                        {getFieldDecorator('firearms_license_number', {
                                            initialValue: firearmsLicense?.number,
                                            validateTrigger: 'onBlur',
                                            getValueFromEvent: (target) => target.target.value?.toUpperCase(),
                                            rules: [
                                                {
                                                    required: getFieldValue('has_firearms_license'),
                                                    message: intl.formatMessage({
                                                        id: 'error.validation.notBlank',
                                                        defaultMessage: 'Please do not leave blank',
                                                    }),
                                                },
                                            ],
                                        })(
                                            <Input
                                                placeholder={intl.formatMessage({
                                                    id: 'welcome.AustralianLicenses.licenseNumber',
                                                    defaultMessage: 'License Number',
                                                })}
                                            />
                                        )}
                                    </Form.Item>

                                    <Form.Item>
                                        {getFieldDecorator('firearms_license_state_territory', {
                                            initialValue: firearmsLicense?.issuing_state_province_or_territory,
                                            rules: [
                                                {
                                                    required: getFieldValue('has_firearms_license'),
                                                    message: intl.formatMessage({
                                                        id: 'error.validation.stateTerritoryError',
                                                        defaultMessage: 'Select a state or territory!',
                                                    }),
                                                },
                                            ],
                                        })(
                                            <ProvStateSelect
                                                placeholder={
                                                    <FormattedMessage
                                                        id="welcome.AustralianLicenses.issuingStateTerritory"
                                                        defaultMessage="Issuing State / Territory"
                                                    />
                                                }
                                                selectedCountry="AU"
                                            />
                                        )}
                                    </Form.Item>
                                </CheckboxSection>
                            )}
                        </FormLayout>
                    </CheckboxContainers>

                    <TrackNavigation />
                </Form>
            </AppearRight>
        );
    }
}
export default withLDConsumer()(Form.create()(injectIntl(AustralianLicensesForm)));
