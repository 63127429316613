// Libraries
import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { intl } from 'components/GlobalProvider';
import { message, Form } from 'antd';
import { useFlags } from 'launchdarkly-react-client-sdk';

// Components
import { BackButtonWrapper } from '../../styled';
import { ErrorAlertAPI, ErrorAlertCustom } from 'certn-ui/ErrorAlert';
import { BackButton } from 'certn-ui/Button';
import {
    PropertyManagementScreening,
    ApplicantInfo,
    QuickScreen,
    Tag,
    TeamSelection,
    WhoWillCompleteInfo,
} from './components';

// Actions & Selectors
import { getUserMode, getTeam, getAddressError, getUserModeIsPM } from 'base/BaseSelectors';
import { updateAddressError } from 'base/BaseActions';
import {
    submitApplication,
    submitQuickScreen,
    handleScreenTypeSelect,
    SET_SHOULD_PERSIST_SCREEN_APPLICANT_STATE,
} from '../../ScreenApplicantActions';
import {
    getIsOnlyQuickScreen,
    getQuickScreenPath,
    getScreenType,
    getShouldPersistScreenApplicantState,
} from '../../ScreenApplicantSelectors';
import { CANDIDATE, MYSELF } from 'views/manager/features/ScreenApplicant/ScreenApplicantConstants';
import { getPackage } from 'views/manager/features/ScreenApplicant/ScreenApplicantSelectors';
import { OTHER } from 'modules/Countries';

const Applicant = () => {
    const [form] = Form.useForm();
    const history = useHistory();
    const dispatch = useDispatch();
    const screenType = useSelector(getScreenType);
    const quickScreenPath = useSelector(getQuickScreenPath);
    const canOnlyRunQuickScreen = useSelector(getIsOnlyQuickScreen);
    const canSelectWhoShouldComplete = quickScreenPath && !canOnlyRunQuickScreen;
    const addressError = useSelector(getAddressError);
    const userMode = useSelector(getUserMode);
    const userModeIsPM = useSelector(getUserModeIsPM);
    const team = useSelector(getTeam);
    const applicantPackage = useSelector(getPackage);
    const shouldPersistScreenApplicantState = useSelector(getShouldPersistScreenApplicantState);
    const [loading, setIsLoading] = useState(false);
    const [submitAttempted, setSubmitAttempted] = useState(false);

    // !Feature flag
    const { webFeatureRequireCurrentAndPositionPropertyAddressesForQuickscreen } = useFlags();

    // If returning from persisted state, remove persisted state
    if (shouldPersistScreenApplicantState)
        dispatch({ type: SET_SHOULD_PERSIST_SCREEN_APPLICANT_STATE, payload: false });

    const regularScreen = (values) => {
        setIsLoading(true);

        // !Feature flag
        if (!team?.superteam?.enable_sms_applicant_invites) {
            values.contact_info = values.emails.map((email) => ({
                email,
            }));
            delete values.emails;
        }

        dispatch(submitApplication(values))
            .then((result) => {
                const { sentApplication } = result.payload;
                let count = 0;
                if (Array.isArray(sentApplication)) {
                    count = sentApplication.length; // HR
                } else if (Array.isArray(sentApplication.applicants)) {
                    count = sentApplication.applicants.length; // PM
                }
                message.success(
                    intl.formatMessage(
                        {
                            id: 'f5cd8.Applicant.applicationsCreated',
                            defaultMessage:
                                '{count, plural, =0 {No Applications Created} one {1 Application Created} other {# Applications Created}}',
                        },
                        { count }
                    )
                );
                history.push(`${userMode?.toLowerCase()}/applications`);
                setIsLoading(false);
            })
            .catch((error) => {
                ErrorAlertAPI(error);
                setIsLoading(false);
            });
    };

    const quickScreen = (values) => {
        setIsLoading(true);
        if (addressError) updateAddressError(false);

        dispatch(submitQuickScreen(values, webFeatureRequireCurrentAndPositionPropertyAddressesForQuickscreen))
            .then(() => {
                message.success('Application Created');
                history.push(`${userMode?.toLowerCase()}/applications`);
                setIsLoading(false);
            })
            .catch((error) => {
                ErrorAlertAPI(error);
                setIsLoading(false);
            });
    };

    const handleSubmit = (values) => {
        // These package values cannot be overwritten at the point of creating an application
        // So we need to grab their values from the package here
        const { packageDetails } = applicantPackage;
        const valuesWithDefaults = {
            ...values,
            salary: packageDetails?.salary,
            high_risk_vulnerable_sector: packageDetails?.high_risk_vulnerable_sector,
            job_safety_undue_risk: packageDetails?.job_safety_undue_risk,
            sufficient_salary: packageDetails?.sufficient_salary,
        };

        if (screenType === MYSELF) {
            // Make sure the birthplace and address provinces/states have default values
            // The real values will overwrite the defaults
            return quickScreen({
                // college_name added with setFieldsValue, it does not show up in values
                college_name: form.getFieldValue('college_name'),
                birth_province_state: OTHER,
                birth_other_province_state: null,
                province_state: OTHER,
                other_province_state: null,
                ...valuesWithDefaults,
            });
        }

        if (screenType === CANDIDATE) {
            return regularScreen(valuesWithDefaults);
        }

        ErrorAlertCustom({ description: 'Missing screen type' });
    };

    const emailRef = useRef(null);
    const tagRef = useRef(null);

    const scrollToError = (errorInfo) => {
        const errorName = errorInfo.errorFields[0].name[0];
        if (errorName === 'contact_info') {
            emailRef.current.scrollIntoView();
        } else if (errorName === 'tag') {
            tagRef.current.scrollIntoView();
        }
    };

    const isTeamSelectionEnabled = team?.allow_team_select && !team?.is_lime;
    return (
        <>
            <BackButtonWrapper>
                <BackButton
                    label="BACK"
                    onClick={() => {
                        dispatch({ type: SET_SHOULD_PERSIST_SCREEN_APPLICANT_STATE, payload: true });
                        history.push(`/${userMode?.toLowerCase()}/screen`);
                    }}
                />
            </BackButtonWrapper>

            <Form
                form={form}
                onFinish={handleSubmit}
                onFinishFailed={(errorInfo) => {
                    dispatch(updateAddressError(true));
                    scrollToError(errorInfo);
                    setSubmitAttempted(true);
                }}
            >
                {canSelectWhoShouldComplete && (
                    <WhoWillCompleteInfo
                        screenType={screenType}
                        setScreenType={(type) => dispatch(handleScreenTypeSelect(type))}
                    />
                )}
                {userModeIsPM && <PropertyManagementScreening form={form} />}
                {isTeamSelectionEnabled && <TeamSelection submitAttempted={submitAttempted} />}
                <div ref={tagRef} />
                <Tag form={form} />
                <div ref={emailRef} />
                {screenType === MYSELF && <QuickScreen form={form} loading={loading} isPM={userModeIsPM} />}
                {screenType === CANDIDATE && (
                    <ApplicantInfo form={form} loading={loading} submitAttempted={submitAttempted} />
                )}
            </Form>
        </>
    );
};

export default Applicant;
