// Libraries
import React from 'react';
import { ExclamationOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import styled from 'styled-components';

// Components
import ServiceBreakdown from 'certn-ui/ServiceBreakdown';

const StatusWrapper = styled.div`
    border: 1px solid lightgray;
    overflow: inherit;
    padding: 5px;
    border-radius: 4px;
    display: flex;
    max-width: 50px;
    justify-content: center;
    cursor: pointer;
`;

const ServicesCell = ({ data }) => (
    <Tooltip
        trigger={window.matchMedia('(max-width: 1023px)').matches ? 'click' : 'hover'}
        title={<ServiceBreakdown packages data={data} />}
    >
        <StatusWrapper>
            <ExclamationOutlined />
        </StatusWrapper>
    </Tooltip>
);

ServicesCell.propTypes = {};

export default ServicesCell;
