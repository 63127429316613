import React, { ReactNode, useContext } from 'react';
import { useSelector } from 'react-redux';

// Components
import { RightSideBox } from 'views/manager/features/ScreenApplicant/styled';
import { OnboardingSteps } from './OnboardingSteps';

// Actions & Selectors
import { ScreenApplicantCheckSettingsContext } from '../../contexts';
import { useUserTeam } from 'hooks/useUserTeam';
import Loader from 'certn-ui/Loader';
import { StyledDivider } from './styled';
import { AccessFeeDisclaimer } from './AccessFeeDisclaimer';
import { EstimatedTurnAroundTime } from './EstimatedTurnAroundTime';
import { CartTotals } from './CartTotals';
import { CartItems } from './CartItems';
import { QA_TEST_ID } from 'utils/constants';
import { getIsAccessFeeCheckInCart } from 'views/manager/features/ScreenApplicant/ScreenApplicantSelectors';

type ScreenApplicantCartProps = {
    continueSaveAs: ReactNode;
};

export const ScreenApplicantCart: React.FC<ScreenApplicantCartProps> = ({ continueSaveAs }) => {
    const { team } = useUserTeam();
    const screenApplicantCheckSettingsContext = useContext(ScreenApplicantCheckSettingsContext);
    const isAccessFeeCheckInCart = useSelector(getIsAccessFeeCheckInCart);

    const isTeamDoneOnboarding = (team?.missing_configuration_steps ?? []).length === 0;

    if (!team) {
        return <Loader />;
    }

    return (
        <RightSideBox data-testid={QA_TEST_ID.RightSideBox}>
            <CartItems />
            {screenApplicantCheckSettingsContext.showPricing && (
                <>
                    <div style={{ padding: '0 20px' }}>
                        <StyledDivider />
                    </div>
                    <CartTotals />
                </>
            )}
            {isAccessFeeCheckInCart && <AccessFeeDisclaimer />}
            <EstimatedTurnAroundTime />
            {isTeamDoneOnboarding ? <div>{continueSaveAs}</div> : <OnboardingSteps />}
        </RightSideBox>
    );
};
