import { NAMESPACE as PARENT_NAMESPACE, STATEPATH as PARENT_STATEPATH } from 'views/welcome/WelcomeConstants';

import { postRequest } from 'utils/http';
import { getOnboardingId, getOnboardingType } from 'views/welcome/WelcomeSelectors';
import { getLanguage } from 'base/BaseSelectors';
import { simpleStringifyJSON } from 'modules/Format';

// Constants
export const NAMESPACE = `${PARENT_NAMESPACE}`;
export const STATEPATH = `${PARENT_STATEPATH}`;

// Action Types
export const POST_EID_START_REQ = `${NAMESPACE}POST_EID_START_REQ`;
export const POST_EID_START_RES = `${NAMESPACE}POST_EID_START_RES`;
export const POST_EID_START_ERR = `${NAMESPACE}POST_EID_START_ERR`;
export const POST_EID_RESPONSE_REQ = `${NAMESPACE}POST_EID_RESPONSE_REQ`;
export const POST_EID_RESPONSE_RES = `${NAMESPACE}POST_EID_RESPONSE_RES`;
export const POST_EID_RESPONSE_ERR = `${NAMESPACE}POST_EID_RESPONSE_ERR`;

// Action Creators
export const startEID = () => (dispatch, getState) => {
    const onboardingId = getOnboardingId(getState());
    const onboardingType = getOnboardingType(getState());
    const languageCode = getLanguage(getState());
    const language = languageCode === 'fr' ? 'French' : 'English';

    dispatch({
        type: POST_EID_START_REQ,
    });
    return postRequest({
        internal: true,
        hr: onboardingType === 'HR',
        endpoint: `/onboarding/${onboardingId}/eid/start/`,
        body: JSON.stringify(simpleStringifyJSON({ language })),
    }).then(
        (response) => {
            dispatch({
                type: POST_EID_START_RES,
                payload: {
                    identityQuestions: response.questions,
                },
            });
        },
        (error) => {
            dispatch({
                type: POST_EID_START_ERR,
                payload: { error },
            });
            throw error;
        }
    );
};

export const submitEID = (responses) => (dispatch, getState) => {
    const onboardingId = getOnboardingId(getState());
    const onboardingType = getOnboardingType(getState());
    dispatch({
        type: POST_EID_RESPONSE_REQ,
    });
    return postRequest({
        internal: true,
        hr: onboardingType === 'HR',
        endpoint: `/onboarding/${onboardingId}/eid/response/`,
        body: JSON.stringify(responses),
    }).then(
        (response) => {
            dispatch({
                type: POST_EID_RESPONSE_RES,
                payload: {
                    identityVerification: response,
                },
            });
        },
        (error) => {
            dispatch({
                type: POST_EID_RESPONSE_ERR,
                payload: { error },
            });
            throw error;
        }
    );
};
