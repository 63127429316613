const QUESTION_TYPES = {
    TRUE_FALSE: 'TRUE_FALSE',
    MULTIPLE_CHOICE: 'MULTIPLE_CHOICE',
    LONG_ANSWER: 'LONG_ANSWER',
    NUMERIC_RANGE: 'NUMERIC_RANGE',
    YES_NO: 'YES_NO',
};

const DEFAULT_EMPLOYER_QUESTIONAIRE = {
    name: '',
    version: '1',
    description: 'Testing',
    type: 'EMPLOYER',
    questions: [
        {
            question: "What was {{ first_name }} {{ last_name }}'s most recent position at {{ company_name }}?",
            type: QUESTION_TYPES.MULTIPLE_CHOICE,
            multiple_choice_options: ['Financial Analyst', 'Receptionist', 'Contractor', '{{ position }}'],
            is_verifiable: true,
            multiple_choice_correct_option: 3,
        },
        {
            question: 'If given the opportunity, would you hire {{ first_name }} {{ last_name }} again?',
            type: QUESTION_TYPES.YES_NO,
            multiple_choice_options: [],
        },
        {
            question:
                'Did {{ first_name }} {{ last_name }} work at {{ company_name }} from {{ start_date }}{% if end_date %} to {{ end_date }}{% endif %}?',
            type: QUESTION_TYPES.TRUE_FALSE,
            is_verifiable: true,
            multiple_choice_options: [],
        },
        {
            question: 'If given the opportunity, would you hire {{ first_name }} {{ last_name }} again?',
            type: QUESTION_TYPES.TRUE_FALSE,
            multiple_choice_options: [],
        },
        {
            question:
                'In general, how well did {{ first_name }} {{ last_name }} get along with customers, peers and/or managers (1 not at all, 10 very much so)?',
            type: QUESTION_TYPES.NUMERIC_RANGE,
            multiple_choice_options: [],
        },
        {
            question:
                'In general, did {{ first_name }} {{ last_name }} arrive on time to work (1 not at all, 10 very much so)?',
            type: QUESTION_TYPES.NUMERIC_RANGE,
            multiple_choice_options: [],
        },
        {
            question:
                'How well did {{ first_name }} {{ last_name }} satisfy the requirements of the position (1 not satisfactory, 10 very satisfactory)?',
            type: QUESTION_TYPES.NUMERIC_RANGE,
            multiple_choice_options: [],
        },
        {
            question:
                'Did {{ first_name }} {{ last_name }} go above and beyond what was required without being asked (1 not at all, 10 very much so)?',
            type: QUESTION_TYPES.NUMERIC_RANGE,
            multiple_choice_options: [],
        },
        {
            question:
                "How would you rate {{ first_name }} {{ last_name }}'s ability to handle high pressure or stressful situations (1 not good at all, 10 very good)?",
            type: QUESTION_TYPES.NUMERIC_RANGE,
            multiple_choice_options: [],
        },
        {
            question:
                "Do you have any additional comments you'd like to provide regarding {{ first_name }} {{ last_name }}?",
            type: QUESTION_TYPES.LONG_ANSWER,
            multiple_choice_options: [],
        },
    ],
};

const DEFAULT_ADDRESS_QUESTIONAIRE = {
    name: '',
    version: '1',
    description: 'Testing',
    type: 'ADDRESS',
    questions: [
        {
            question:
                'Did {{ first_name }} {{ last_name }} rent from {{ start_date }}{% if end_date %} to {{ end_date }}{% endif %}?',
            type: QUESTION_TYPES.TRUE_FALSE,
            multiple_choice_options: [],
            is_verifiable: true,
        },
        {
            question: 'What was the address {{ first_name }} {{ last_name }} lived at?',
            type: QUESTION_TYPES.MULTIPLE_CHOICE,
            multiple_choice_options: [
                '11012 Main St. Vancouver BC V6T 1X1',
                '2023 Alma Ave. Toronto ON M6H 3P2',
                '4334 Connolly St. Halifax NS B3L 3M4',
                '{{ address }}',
            ],
            is_verifiable: true,
            multiple_choice_correct_option: 2,
        },
        {
            question:
                'Was {{ first_name }} {{ last_name }} generally a clean and/or organized tenant (1 not clean, 10 very clean)?',
            type: QUESTION_TYPES.NUMERIC_RANGE,
            multiple_choice_options: [],
            is_verifiable: true,
        },
        {
            question:
                'How well did {{ first_name }} {{ last_name }} generally get along with property managers and/or fellow residents (1 not well at all, 10 very well)?',
            type: QUESTION_TYPES.NUMERIC_RANGE,
            multiple_choice_options: [],
        },
        {
            question:
                'How likely would you be to rent to {{ first_name }} {{ last_name }} again? Are you comfortable recommending {{ first_name }} {{ last_name }} as a tenant (1 not likely, 10 very likely)?',
            type: QUESTION_TYPES.NUMERIC_RANGE,
            multiple_choice_options: [],
        },
        {
            question:
                "Did you receive complaints from neighbours with regards to {{ first_name }} {{ last_name }}'s behaviour (1 frequently, 10 never)?",
            type: QUESTION_TYPES.NUMERIC_RANGE,
            multiple_choice_options: [],
        },
        {
            question:
                "Do you have any additional comments you'd like to provide regarding {{ first_name }} {{ last_name }}?",
            type: QUESTION_TYPES.LONG_ANSWER,
            multiple_choice_options: [],
        },
    ],
};

const ReferenceModule = {
    address: DEFAULT_ADDRESS_QUESTIONAIRE,
    employer: DEFAULT_EMPLOYER_QUESTIONAIRE,
    QUESTION_TYPES,
};

export default ReferenceModule;
