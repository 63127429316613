// Libraries
import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Input, Form, Button, Tooltip, Space, Checkbox } from 'antd';
import { DownloadOutlined, InfoCircleOutlined } from '@ant-design/icons';

// Components
import Image from 'certn-ui/Image';
import { ContentLink } from 'certn-ui/Text';
import StockIDCardMini from 'images/StockIDCardMini.png';
import { UploadBase64 } from 'components';
import {
    SectionWrapper,
    SectionTopBar,
    SectionBottomPanel,
    BottomPanelRow,
    WarningAlert,
    CustomInputFrame,
} from 'views/manager/features/ScreenApplicant/styled';
import { RequiredText } from 'certn-ui';

// Actions & Selectors
import { getSelectedCheckKeys } from 'views/manager/features/ScreenApplicant/ScreenApplicantSelectors';
import { RCMP_REQUESTS } from 'views/manager/features/ScreenApplicant/ScreenApplicantConstants';

// Modules
import isVisibleOrRequired from './isVisibleOrRequired';

const IDUpload = ({ form }) => {
    const intl = useIntl();
    const selectedServices = useSelector(getSelectedCheckKeys);
    const RCMP_ID_VERIFIED_REQUIREMENTS =
        'https://www.rcmp-grc.gc.ca/en/dissemination-criminal-record-information-policy#physical';

    const typeOfPrimaryID = intl.formatMessage({
        id: 'f5ca1.QuickScreen.typeOfPhotoID',
        defaultMessage: 'Type of Photo ID',
    });

    const typeOfSecondaryID = intl.formatMessage({
        id: 'f5ca1.QuickScreen.typeOfSecondaryID',
        defaultMessage: 'Type of Secondary ID',
    });

    const witnessingAgentsName = intl.formatMessage({
        id: 'f5ca1.QuickScreen.witnessingAgentsName',
        defaultMessage: "Witnessing Agent's Name",
    });

    const rcmpIDVerificationRequirements = (
        <ContentLink href={RCMP_ID_VERIFIED_REQUIREMENTS}>
            {intl.formatMessage({
                id: 'f5ca1.QuickScreen.rcmpIDVerifyRequirements',
                defaultMessage: 'RCMP ID verification requirements',
            })}
        </ContentLink>
    );

    // Checks to display field and set required, true in brackets for all services
    const visibleList = RCMP_REQUESTS;
    const requiredList = RCMP_REQUESTS;

    if (!isVisibleOrRequired(visibleList, selectedServices)) return null;
    return (
        <SectionWrapper>
            <SectionTopBar>
                <FormattedMessage id="f5ca1.QuickScreen.idUpload" defaultMessage="Upload Photo Identification" />
                <Tooltip
                    trigger="hover"
                    title={
                        <FormattedMessage
                            id="f5ca1.QuickScreen.idUploadButtonTooltip"
                            defaultMessage="{idImage} Upload a color image of the front of the applicant's photo ID. Acceptable photo ID includes a passport or driver's license."
                            values={{
                                idImage: <Image style={{ marginBottom: '10px' }} src={StockIDCardMini} />,
                            }}
                        />
                    }
                >
                    <InfoCircleOutlined style={{ marginLeft: '10px' }} />
                </Tooltip>
            </SectionTopBar>
            <SectionBottomPanel style={{ paddingBottom: '0px' }}>
                <BottomPanelRow>
                    <WarningAlert>
                        <FormattedMessage
                            id="f5ca1.QuickScreen.idUploadAlert"
                            defaultMessage="You must obtain photo identification from your applicant"
                        />
                    </WarningAlert>
                </BottomPanelRow>
                <BottomPanelRow id="id_frontside_image" style={{ marginTop: '10px' }}>
                    <Form.Item
                        name="id_frontside_image"
                        initialValue={null}
                        rules={[
                            {
                                required: isVisibleOrRequired(requiredList, selectedServices),
                                message: (
                                    <FormattedMessage
                                        id="f5ca1.QuickScreen.idUpload"
                                        defaultMessage="Upload Photo Identification"
                                    />
                                ),
                            },
                        ]}
                    >
                        <UploadBase64
                            image
                            setDocument={(base64) => form.setFieldsValue({ id_frontside_image: base64 })}
                        >
                            <Button type="secondary">
                                <Space>
                                    <DownloadOutlined />
                                    <FormattedMessage
                                        id="f5ca1.QuickScreen.idUpload"
                                        defaultMessage="Upload Photo Identification"
                                    />
                                </Space>
                            </Button>
                        </UploadBase64>
                    </Form.Item>
                </BottomPanelRow>
                <CustomInputFrame>
                    <RequiredText>{witnessingAgentsName}</RequiredText>
                    <Form.Item
                        name="witness_name"
                        initialValue={null}
                        rules={[
                            {
                                required: true,
                                message: intl.formatMessage({
                                    id: 'error.validation.notBlank',
                                    defaultMessage: 'Please do not leave blank',
                                }),
                            },
                        ]}
                    >
                        <Input placeholder={witnessingAgentsName} />
                    </Form.Item>
                    <RequiredText>
                        {`${typeOfPrimaryID} ${intl.formatMessage({
                            id: 'common.uploaded',
                            defaultMessage: 'Uploaded',
                        })}`}
                    </RequiredText>
                    <Form.Item
                        name="primary_id"
                        initialValue={null}
                        rules={[
                            {
                                required: true,
                                message: intl.formatMessage({
                                    id: 'error.validation.notBlank',
                                    defaultMessage: 'Please do not leave blank',
                                }),
                            },
                        ]}
                    >
                        <Input placeholder={typeOfPrimaryID} />
                    </Form.Item>
                    <RequiredText>
                        {`${typeOfSecondaryID} ${intl.formatMessage({
                            id: 'f5ca1.QuickScreen.noSecondaryUpload',
                            defaultMessage: 'Viewed (No Photo Upload Necessary)',
                        })}`}
                    </RequiredText>
                    <Form.Item
                        name="secondary_id"
                        initialValue={null}
                        rules={[
                            {
                                required: true,
                                message: intl.formatMessage({
                                    id: 'error.validation.notBlank',
                                    defaultMessage: 'Please do not leave blank',
                                }),
                            },
                        ]}
                    >
                        <Input placeholder={typeOfSecondaryID} />
                    </Form.Item>
                </CustomInputFrame>
                <Form.Item
                    name="id_verified_by_agent"
                    valuePropName="checked"
                    rules={[
                        {
                            required: true,
                            message: intl.formatMessage({
                                id: 'error.validation.notBlank',
                                defaultMessage: 'Please do not leave blank',
                            }),
                            validator: (_, value) => {
                                if (value) return Promise.resolve();
                                return Promise.reject(
                                    new Error(
                                        intl.formatMessage({
                                            id: 'f5ca1.QuickScreen.validateID',
                                            defaultMessage: "You must verify the applicant's identity to continue",
                                        })
                                    )
                                );
                            },
                        },
                    ]}
                >
                    <div style={{ display: 'flex' }}>
                        <RequiredText />
                        <Checkbox>
                            {intl.formatMessage(
                                {
                                    id: 'f5ca1.QuickScreen.inPersonIDVerify',
                                    defaultMessage:
                                        'I have physically verified the applicant’s identity matches their government-issued ID, in accordance with {rcmpIDVerificationRequirements}.',
                                },
                                {
                                    rcmpIDVerificationRequirements,
                                }
                            )}
                        </Checkbox>
                    </div>
                </Form.Item>
            </SectionBottomPanel>
        </SectionWrapper>
    );
};

export default IDUpload;
