import styled from 'styled-components';
import { Button, Input } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import MobileComponent from 'components/MobileComponent';
import { flexCenter, flexCol } from 'styles/Mixins';

export const StyledWrapper = styled.div`
    ${flexCol};

    .ant-row.ant-legacy-form-item {
        margin-bottom: 16px;
    }
    .ant-legacy-form-item-children {
        ${flexCenter};
        width: 100%;
    }
`;

export const Instructions = styled.div`
    color: ${({ theme }) => theme.color.certnGray800};
    padding-bottom: 20px;
`;

export const InputsList = MobileComponent(styled.div``);

export const RemoveButton = styled(CloseOutlined)`
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    margin-left: 12px;
    justify-content: center;
    padding: 5px;
    border-radius: 2px;
    cursor: pointer;
    transition: all 0.25s ease;
    && {
        color: ${({ theme }) => theme.color.certnBlack};
        &:hover {
            color: black;
            background-color: ${({ theme }) => theme.color.certnGray100};
        }
    }

    @media (max-width: ${({ theme }) => theme.width.phone}) {
        background-color: ${({ theme }) => theme.color.certnGray100};
    }
`;

export const EmailItem = styled.div`
    width: 100%;
    display: flex;
`;

export const EmailInput = styled(Input)`
    flex: 1;
`;

export const ButtonRow = styled.div`
    margin-bottom: 32px;
`;

export const AddApplicantButton = styled(Button)`
    && {
        border-color: ${({ theme }) => theme.color.primary};
        color: ${({ theme }) => theme.color.primary};
        padding: 5px 58px 9px;
    }
`;

export const EmailRow = styled.div`
    .ant-form-item-control-input-content {
        display: flex;
    }
`;

export const EmailWithPhoneRow = styled.div`
    display: flex;
    flex-wrap: wrap;

    :not(:first-child) {
        padding-top: 24px;
        border-top: ${({ theme }) => `1px solid ${theme.color.certnLightGray3}`};
    }

    @media (max-width: ${({ theme }) => theme.width.sm}) {
        padding-bottom: 12px;
    }
`;

export const EmailColumn = styled.div`
    width: 50%;
    padding-right: 12px;

    @media (max-width: ${({ theme }) => theme.width.sm}) {
        width: 100%;
        padding-right: 0;
    }
`;

export const PhoneColumn = styled.div`
    width: 50%;
    padding-left: 12px;

    @media (max-width: ${({ theme }) => theme.width.sm}) {
        width: 100%;
        padding-left: 0;
    }
`;

export const InternationalPhoneWrapper = styled.div`
    display: flex;
    .ant-row.ant-legacy-form-item {
        margin-bottom: 0;
    }

    /* 
        This is needed to format the International Phone component niceley with the updated version of ANTD(V4).
        The component is compatible with ANTD V3 by default.
    */
    .PhoneInputCountry {
        height: 32px;
        margin-bottom: 0;
    }
`;
