// Libraries
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

// Components
import { CustomQuestionsForm } from './components';

// Modules
import withNavigation from 'views/welcome/modules/WithNavigation';

// Actions & Selectors
import { patchInformation } from 'views/welcome/WelcomeActions';
import { getInformation, getCustomQuestions } from 'views/welcome/WelcomeSelectors';

CustomQuestions.propTypes = {
    // Redux Actions:
    patchInformation: PropTypes.func.isRequired,
    patchWelcomeSession: PropTypes.func.isRequired,
    // Redux Store:
    isFetchingSilent: PropTypes.bool.isRequired,
    information: PropTypes.object.isRequired,
};

const WrappedCustomQuestions = withNavigation(CustomQuestions);

function CustomQuestions(props) {
    const customQuestions = useSelector(getCustomQuestions);
    const information = useSelector(getInformation);
    const dispatch = useDispatch();

    const handleSubmit = (e, validateFields) => {
        e.preventDefault();
        const { handleNextTrack } = props;
        validateFields((err, values) => {
            const newCustomQuestions = [...customQuestions];

            newCustomQuestions.forEach((item, index) => {
                item.answer = values[index];
            });

            if (!err) {
                dispatch(patchInformation({ custom_questions: newCustomQuestions })).then(handleNextTrack);
            }
        });
    };

    return (
        <CustomQuestionsForm handleSubmit={handleSubmit} information={information} customQuestions={customQuestions} />
    );
}

export default WrappedCustomQuestions;
