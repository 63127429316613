import React from 'react';
import { Form, Modal, Button, Card, Input } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import styles from 'styles/styles';
import PropTypes from 'prop-types';

const AdjudicationAreYouSureModal = ({
    visible,
    setVisible,
    applicantChecks,
    handleSubmit,
    applicantAdjudication,
    statusOptions,
}) => {
    const intl = useIntl();
    const [form] = Form.useForm();

    return (
        <Modal
            data-testid="adjudication-are-you-sure-modal"
            title={intl.formatMessage({
                id: '6cb70.adjudicationAreYouSureModal.adjudicationAreYouSureModal',
                defaultMessage: 'Submit adjudication changes?',
            })}
            visible={visible}
            onCancel={() => setVisible(false)}
            footer={[
                <Button key="back" onClick={() => setVisible(false)}>
                    <FormattedMessage id="6cb70.adjudicationAreYouSureModal.noGoBack" defaultMessage="No, go back" />
                </Button>,
                <Button
                    form="notes"
                    key="submit"
                    type="primary"
                    onClick={() => handleSubmit(form.getFieldValue('notes'))}
                >
                    <FormattedMessage
                        id="6cb70.adjudicationAreYouSureModal.yesSubmitchanges"
                        defaultMessage="Yes, submit changes"
                    />
                </Button>,
            ]}
        >
            <Form form={form} name="notes_form" onlayout="vertical">
                {applicantChecks?.map((check, ind) => (
                    <>
                        <Card
                            key={ind}
                            data-testid={`check-label-${ind}`}
                            bodyStyle={{
                                paddingTop: '10px',
                                paddingBottom: '10px',
                            }}
                            style={{ borderTop: `1px solid ${styles.color.certnGray200}`, borderRadius: '0px' }}
                            title={check.check_label}
                        >
                            <div
                                data-testid={`check-score-result-${ind}`}
                                style={{
                                    color:
                                        check.score_result === 'CLEARED'
                                            ? styles.color.certnGreen700
                                            : styles.color.certnRed900,
                                }}
                            >
                                {statusOptions[check.score_result]}
                            </div>
                        </Card>
                        <br />
                    </>
                ))}
                <Card
                    data-testid="adjudication-are-you-sure-modal-overall-result"
                    bodyStyle={{
                        paddingTop: '10px',
                        paddingBottom: '10px',
                        color:
                            applicantAdjudication?.overall_result === 'CLEARED'
                                ? styles.color.certnGreen700
                                : styles.color.certnRed900,
                    }}
                    style={{ borderTop: `1px solid ${styles.color.certnGray200}`, borderRadius: '0px' }}
                    title={
                        <FormattedMessage
                            id="6cb70.adjudicationAreYouSureModal.overAllScore"
                            defaultMessage="Overall Score"
                        />
                    }
                >
                    {statusOptions[applicantAdjudication?.overall_result]}
                </Card>
                <br />
                <Form.Item name="notes">
                    <Input.TextArea
                        data-testid="adjudication-are-you-sure-modal-notes"
                        placeholder={intl.formatMessage({
                            id: '6cb70.adjudicationAreYouSureModal.adjNotesModalPlaceholder',
                            defaultMessage: 'Add a comment',
                        })}
                        defaultValue={applicantAdjudication?.notes}
                        style={{ height: '120px' }}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
};

AdjudicationAreYouSureModal.propTypes = {
    visible: PropTypes.bool,
    setVisible: PropTypes.func.isRequired,
    applicantChecks: PropTypes.array.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    applicantAdjudication: PropTypes.object.isRequired,
    statusOptions: PropTypes.array.isRequired,
};

AdjudicationAreYouSureModal.defaultProps = { visible: false };

export default AdjudicationAreYouSureModal;
