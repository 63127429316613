import React from 'react';
import { Menu } from 'antd';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { ListCellText } from 'certn-ui/List';

const ItemShareReport = ({ showSharing, ...rest }) => {
    const handleClick = () => {
        showSharing(true);
    };

    return (
        <Menu.Item {...rest}>
            <ListCellText clickable onClick={handleClick}>
                <FormattedMessage id="004e5.StatusDropdown.shareReportSummary" defaultMessage="Share Report Summary" />
            </ListCellText>
        </Menu.Item>
    );
};

ItemShareReport.propTypes = {
    showSharing: PropTypes.func.isRequired,
};

export default ItemShareReport;
