import { queryKeys } from 'utils/reactQuery/queryKeys';
import { useQuery } from '@tanstack/react-query';
import { VeriffBrandedIntegration } from 'types';
import { getRequest } from 'utils/http';
import { useSelector } from 'react-redux';
import { getTeam } from '../../../views/welcome/WelcomeSelectors';

async function getVeriffBrandedIntegration(teamId?: string): Promise<VeriffBrandedIntegration> {
    if (teamId) {
        const data = await Promise.resolve(
            getRequest({
                version: 'v1',
                endpoint: `/one-id/branding/${teamId}`,
            })
        ).catch(() => {});
        return data as VeriffBrandedIntegration;
    }
    return {} as VeriffBrandedIntegration;
}

export const useVeriffBrandedIntegration = () => {
    const fallback = {};
    const team = useSelector(getTeam);
    const { data = fallback } = useQuery<VeriffBrandedIntegration>([queryKeys.teamId, team?.id], () =>
        getVeriffBrandedIntegration(team?.id)
    );
    return data;
};
