// Libraries
import React from 'react';
import { FormattedMessage } from 'react-intl';

// Components
import styled from 'styled-components';
import { DocumentSerializer } from 'types/serializers';
import SummaryHeader from './SummaryHeader';
import SummaryItem from './SummaryItem';
import { FlexRow, LineBreak, SubTitleWrapper, SummaryContainer, SummarySection } from './SummaryStyles';

interface Props {
    documents: ReadonlyArray<DocumentSerializer>;
    citizenship: ReadonlyArray<string>;
    goToTrack: (track: string) => void;
}

const formatDocumentTypeTitle = (documentType: DocumentSerializer['document_subtype']) => {
    switch (documentType) {
        case 'commencement':
            return (
                <FormattedMessage id="welcome.Summary.commencementDocument" defaultMessage="COMMENCEMENT DOCUMENT" />
            );
        case 'primary':
            return <FormattedMessage id="welcome.Summary.primaryDocument" defaultMessage="PRIMARY DOCUMENT" />;
        case 'secondary':
            return <FormattedMessage id="welcome.Summary.secondaryDocument" defaultMessage="SECONDARY DOCUMENT" />;
        case 'supporting':
            return <FormattedMessage id="welcome.Summary.supportingDocument" defaultMessage="SUPPORTING DOCUMENT" />;
        default:
            return documentType;
    }
};

const DocumentsList = ({ documents, type }: { documents: DocumentSerializer[]; type: string }) => {
    const filteredDocuments = documents.filter((doc) => {
        if (type !== 'commencement') return doc.document_subtype === type;

        return [type, 'right_to_work' as string | undefined].includes(doc.document_subtype);
    });

    if (filteredDocuments.length < 1) {
        return (
            <>
                <SubTitleWrapper>{formatDocumentTypeTitle(type)}</SubTitleWrapper>
                <FlexRow>
                    <SummaryItem
                        title={<FormattedMessage id="welcome.Summary.documentType" defaultMessage="Document Type" />}
                        text={<FormattedMessage id="welcome.Summary.noDocument" defaultMessage="I don't have one" />}
                        grow
                    />
                    <SummaryItem
                        title={<FormattedMessage id="common.fileName" defaultMessage="Filename" />}
                        grow
                        empty
                    />
                </FlexRow>
            </>
        );
    }

    return (
        <>
            {filteredDocuments.map((document: DocumentSerializer, index: number, array: DocumentSerializer[]) => (
                <React.Fragment key={document.id}>
                    <SubTitleWrapper>
                        {formatDocumentTypeTitle(type)}
                        {array.length > 1 && (
                            <>
                                <span> - </span>
                                <FormattedMessage
                                    id="common.range"
                                    defaultMessage="{index} of {total}"
                                    values={{ index: index + 1, total: array.length }}
                                />
                            </>
                        )}
                    </SubTitleWrapper>
                    <FlexRow>
                        <SummaryItem
                            title={
                                <FormattedMessage id="welcome.Summary.documentType" defaultMessage="Document Type" />
                            }
                            text={document.document_type_display || document.document_type}
                            grow
                        />
                        <SummaryItem
                            title={<FormattedMessage id="common.fileName" defaultMessage="Filename" />}
                            text={document.file_name}
                            grow
                            truncate
                        />
                    </FlexRow>
                </React.Fragment>
            ))}
        </>
    );
};

const SupportingDocument = styled.div`
    font-size: 18px;
    font-weight: bold;
    text-align: left;
    margin: 12px;
`;

const AustralianDocuments = ({ documents, citizenship, goToTrack }: Props) => {
    const allDocuments = documents.filter((document) => document.document_type !== 'SELFIE');
    const supportingDocuments = allDocuments.filter((document) => document.document_subtype === 'supporting');
    const hasRTWDocument = allDocuments.filter((document) => document.document_subtype === 'right_to_work').length > 0;
    const isAUCitizen = citizenship && citizenship.includes('AU');

    return (
        <SummaryContainer>
            <SummaryHeader
                title={<FormattedMessage id="welcome.Summary.documents" defaultMessage="Documents" />}
                onClick={goToTrack}
            />
            <SummarySection>
                {allDocuments.length < 1 && (
                    <SummaryItem
                        empty
                        title={
                            <FormattedMessage
                                id="welcome.Summary.noDocumentsProvided"
                                defaultMessage="You have not provided any documents"
                            />
                        }
                    />
                )}

                {(!hasRTWDocument || isAUCitizen) && <DocumentsList documents={allDocuments} type="commencement" />}
                <DocumentsList documents={allDocuments} type="primary" />
                <DocumentsList documents={allDocuments} type="secondary" />

                {supportingDocuments.length > 0 && (
                    <>
                        <div style={{ marginTop: 13, marginBottom: 25, paddingLeft: 12, paddingRight: 12 }}>
                            <LineBreak />
                        </div>
                        <SubTitleWrapper>
                            <FormattedMessage
                                id="welcome.Summary.supportingDocuments"
                                defaultMessage="SUPPORTING DOCUMENTS"
                            />
                        </SubTitleWrapper>
                    </>
                )}
                {supportingDocuments.map((doc) => (
                    <SupportingDocument key={doc.id}>{doc.file_name}</SupportingDocument>
                ))}
            </SummarySection>
        </SummaryContainer>
    );
};

export default AustralianDocuments;
