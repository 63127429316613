// Libraries
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl, FormattedMessage } from 'react-intl';

// Router & Routes
import { withRouter } from 'react-router-dom';

// Modules & Utils
import Auth from 'modules/Auth';
import { Path } from 'modules';

// Components
import { LanguageSelect } from 'base/components/';
import Navbar, { NavMain, NavBelow, NavLeft, NavRight, NavOptions, NavOption } from 'certn-ui/Navbar';
import { NavLogo } from 'certn-ui/NavLogo';
import Text from 'certn-ui/Text';
import { MessageOutlined, PlusOutlined, UserOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Button } from 'antd';
import { intl } from 'components/GlobalProvider';

// Actions & Selectors
import { logout, fetchNotifications, setNotificationViewed } from 'base/BaseActions';
import {
    getDropdownNotifications,
    getIsFetchingSilent,
    getUserMode,
    getTeamLogo,
    getTeamIsUK,
    getTeamIsAUS,
    getUser,
} from 'base/BaseSelectors';
import DropdownWrapper from './DropdownWrapper';

import Constants from 'utils/constants';

const mapStateToProps = (state) => ({
    notifications: getDropdownNotifications(state),
    isFetchingSilent: getIsFetchingSilent(state),
    userMode: getUserMode(state),
    user: getUser(state),
    logo: getTeamLogo(state),
    isTeamUK: getTeamIsUK(state),
    isTeamAUS: getTeamIsAUS(state),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchNotifications,
            setNotificationViewed,
            logout,
        },
        dispatch
    );

const propTypes = {
    // redux actions
    fetchNotifications: PropTypes.func.isRequired,
    setNotificationViewed: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    // redux state
    notifications: PropTypes.array,
    isFetchingSilent: PropTypes.bool.isRequired,
};
const defaultProps = {
    notifications: [],
};

class Navigation extends Component {
    componentDidMount() {
        this.props.fetchNotifications();
    }

    menuOnClick = ({ key }) => {
        switch (key) {
            case 'signout':
                this.props.history.push(`/logout`);
                break;
            case 'Manager':
                this.props.history.push(`/pm/applications`);
                break;
            case 'HrManager':
                this.props.history.push(`/hr/applications`);
                break;
            case 'settings':
                this.props.history.push(`/admin/teams`);
                break;
            default:
        }
    };

    render() {
        const pathname = this.props.location ? this.props.location.pathname : '';
        const isHr = this.props.userMode === 'HR';
        const { user } = this.props;
        const { isTeamUK, isTeamAUS } = this.props;
        const altTag = Path.getAltTagFromSubdomain();

        return (
            <Navbar fixed>
                <NavMain fixed>
                    <NavLeft>
                        <NavLogo
                            alt={altTag}
                            src={this.props.logo}
                            clickable
                            onClick={() => this.props.history.push(`${isHr ? '/hr' : '/pm'}/applications`)}
                            title={intl.formatMessage({
                                id: 'common.Logo',
                                defaultMessage: 'Logo',
                            })}
                        />
                        <NavOptions desktop>
                            {isHr ? (
                                <>
                                    <NavOption
                                        topNav
                                        desktop
                                        label={this.props.intl.formatMessage({
                                            id: '350ef.Navigation.hrApplicationsLabel',
                                            defaultMessage: 'applications',
                                        })}
                                        currentRoute={pathname.includes('/hr/applications')}
                                        onClick={() => this.props.history.push('/hr/applications')}
                                    />
                                    {Auth.isNotBasic() && (
                                        <NavOption
                                            topNav
                                            desktop
                                            label={this.props.intl.formatMessage({
                                                id: 'common.packages',
                                                defaultMessage: 'Packages',
                                            })}
                                            currentRoute={pathname.includes('/hr/packages')}
                                            onClick={() => this.props.history.push('/hr/packages')}
                                        />
                                    )}
                                </>
                            ) : (
                                <>
                                    <NavOption
                                        topNav
                                        desktop
                                        label={this.props.intl.formatMessage({
                                            id: '350ef.Navigation.pmApplicationsLabel',
                                            defaultMessage: 'applications',
                                        })}
                                        currentRoute={pathname.includes('/pm/applications')}
                                        onClick={() => this.props.history.push('/pm/applications')}
                                    />
                                    {Auth.isNotBasic() && (
                                        <NavOption
                                            topNav
                                            desktop
                                            label={this.props.intl.formatMessage({
                                                id: '350ef.Navigation.pmListingsLabel',
                                                defaultMessage: 'listings',
                                            })}
                                            currentRoute={pathname.includes('/pm/listings')}
                                            onClick={() => this.props.history.push('/pm/listings')}
                                        />
                                    )}
                                    {Auth.isNotBasic() && (
                                        <NavOption
                                            topNav
                                            desktop
                                            label={this.props.intl.formatMessage({
                                                id: '350ef.Navigation.pmBuildingsLabel',
                                                defaultMessage: 'buildings',
                                            })}
                                            currentRoute={pathname.includes('/pm/properties')}
                                            onClick={() => this.props.history.push('/pm/properties')}
                                        />
                                    )}
                                </>
                            )}
                            {user?.api_user_role === Constants.apiUserRole.ADMIN && (
                                <NavOption
                                    topNav
                                    desktop
                                    label={this.props.intl.formatMessage({
                                        id: '350ef.Navigation.partnerLabel',
                                        defaultMessage: 'partner',
                                    })}
                                    currentRoute={pathname.includes('/partner')}
                                    onClick={() => this.props.history.push('/partner')}
                                />
                            )}
                        </NavOptions>
                    </NavLeft>
                    <NavRight>
                        {window.matchMedia('(max-width: 1024px)').matches ? (
                            <Button
                                onClick={() =>
                                    this.props.history.push(`/${this.props.userMode?.toLowerCase()}/screen`, {
                                        reset: true,
                                    })
                                }
                                shape="circle"
                                size="large"
                                type="primary"
                            >
                                <PlusOutlined style={{ marginTop: '2px', fontSize: '22px' }} />
                            </Button>
                        ) : (
                            <Button
                                type="primary"
                                onClick={() =>
                                    this.props.history.push(`/${this.props.userMode?.toLowerCase()}/screen`, {
                                        reset: true,
                                    })
                                }
                            >
                                <FormattedMessage
                                    id="350ef.Navigation.screenApplicantButton"
                                    defaultMessage="SCREEN APPLICANT"
                                />
                            </Button>
                        )}
                        {window.fcWidget && (
                            <Button
                                id="industry-toggle-button"
                                shape="circle"
                                size="large"
                                onClick={() => {
                                    window.fcWidget.open();
                                }}
                            >
                                <MessageOutlined style={{ marginTop: '4px', fontSize: '24px' }} />
                            </Button>
                        )}
                        <DropdownWrapper
                            setNotificationViewed={this.props.setNotificationViewed}
                            fetchNotifications={this.props.fetchNotifications}
                        />
                        {isHr ? (
                            <Dropdown
                                overlay={
                                    <Menu onClick={this.menuOnClick}>
                                        <Menu.Item key="HrManager" disabled>
                                            <FormattedMessage
                                                id="350ef.Navigation.hrDropdownItem"
                                                defaultMessage="Human Resources"
                                            />
                                        </Menu.Item>
                                        {Auth.isUser() && (
                                            <Menu.Item key="Manager">
                                                <FormattedMessage
                                                    id="350ef.Navigation.pmDropdownItem"
                                                    defaultMessage="Property Management"
                                                />
                                            </Menu.Item>
                                        )}
                                        {!(isTeamUK || isTeamAUS) && (
                                            <>
                                                <Menu.Divider />
                                                <LanguageSelect disableSpanish noBorders />
                                                <Menu.Divider />
                                            </>
                                        )}
                                        <Menu.Item key="settings">
                                            <FormattedMessage
                                                id="350ef.Navigation.settingsDropdownItem"
                                                defaultMessage="Settings"
                                            />
                                        </Menu.Item>
                                        <Menu.Item key="signout">
                                            <Text color="certnRed500">
                                                <FormattedMessage
                                                    id="350ef.Navigation.signOutDropdownItem"
                                                    defaultMessage="Sign Out"
                                                />
                                            </Text>
                                        </Menu.Item>
                                    </Menu>
                                }
                                placement="bottomRight"
                                trigger={['click']}
                            >
                                <Button id="industry-toggle-button" shape="circle" type="normal" size="large">
                                    <UserOutlined style={{ fontSize: '24px' }} />
                                </Button>
                            </Dropdown>
                        ) : (
                            <Dropdown
                                overlay={
                                    <Menu onClick={this.menuOnClick}>
                                        <Menu.Item key="Manager" disabled>
                                            <FormattedMessage
                                                id="350ef.Navigation.pmDropdownItem"
                                                defaultMessage="Property Management"
                                            />
                                        </Menu.Item>
                                        {Auth.isHrUser() && (
                                            <Menu.Item key="HrManager">
                                                <FormattedMessage
                                                    id="350ef.Navigation.hrDropdownItem"
                                                    defaultMessage="Human Resources"
                                                />
                                            </Menu.Item>
                                        )}
                                        {!(isTeamUK || isTeamAUS) && (
                                            <>
                                                <Menu.Divider />
                                                <LanguageSelect disableSpanish noBorders />
                                                <Menu.Divider />
                                            </>
                                        )}
                                        <Menu.Divider />
                                        <Menu.Item key="settings">
                                            <FormattedMessage
                                                id="350ef.Navigation.settingsDropdownItem"
                                                defaultMessage="Settings"
                                            />
                                        </Menu.Item>
                                        <Menu.Item key="signout">
                                            <Text color="certnRed500">
                                                <FormattedMessage
                                                    id="350ef.Navigation.signOutDropdownItem"
                                                    defaultMessage="Sign Out"
                                                />
                                            </Text>
                                        </Menu.Item>
                                    </Menu>
                                }
                                placement="bottomRight"
                                trigger={['click']}
                            >
                                <Button id="industry-toggle-button" shape="circle" type="normal" size="large">
                                    <UserOutlined style={{ fontSize: '24px' }} />
                                </Button>
                            </Dropdown>
                        )}
                    </NavRight>
                </NavMain>
                {window.matchMedia('(max-width: 1024px)').matches && (
                    <NavBelow>
                        {isHr ? (
                            <NavOptions mobile>
                                <NavOption
                                    topNav
                                    desktop
                                    label={this.props.intl.formatMessage({
                                        id: '350ef.Navigation.hrApplicationsLabel',
                                        defaultMessage: 'applications',
                                    })}
                                    currentRoute={pathname.includes('/hr/applications')}
                                    onClick={() => this.props.history.push('/hr/applications')}
                                />
                                {Auth.isNotBasic() && (
                                    <NavOption
                                        topNav
                                        desktop
                                        label={this.props.intl.formatMessage({
                                            id: 'common.packages',
                                            defaultMessage: 'Packages',
                                        })}
                                        currentRoute={pathname.includes('/hr/packages')}
                                        onClick={() => this.props.history.push('/hr/packages')}
                                    />
                                )}
                            </NavOptions>
                        ) : (
                            <NavOptions mobile>
                                <NavOption
                                    topNav
                                    desktop
                                    label={this.props.intl.formatMessage({
                                        id: '350ef.Navigation.pmApplicationsLabel',
                                        defaultMessage: 'applications',
                                    })}
                                    currentRoute={pathname.includes('/pm/applications')}
                                    onClick={() => this.props.history.push('/pm/applications')}
                                />
                                {Auth.isNotBasic() && (
                                    <NavOption
                                        topNav
                                        desktop
                                        label={this.props.intl.formatMessage({
                                            id: '350ef.Navigation.pmListingsLabel',
                                            defaultMessage: 'listings',
                                        })}
                                        currentRoute={pathname.includes('/pm/listings')}
                                        onClick={() => this.props.history.push('/pm/listings')}
                                    />
                                )}
                                {Auth.isNotBasic() && (
                                    <NavOption
                                        topNav
                                        desktop
                                        label={this.props.intl.formatMessage({
                                            id: '350ef.Navigation.pmBuildingsLabel',
                                            defaultMessage: 'buildings',
                                        })}
                                        currentRoute={pathname.includes('/pm/properties')}
                                        onClick={() => this.props.history.push('/pm/properties')}
                                    />
                                )}
                            </NavOptions>
                        )}
                    </NavBelow>
                )}
            </Navbar>
        );
    }
}

Navigation.propTypes = propTypes;
Navigation.defaultProps = defaultProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(Navigation)));
