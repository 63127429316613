import styled from 'styled-components/macro';

const _BaseButton = styled.button`
    width: ${({ theme, size }) => theme.button.width[size]};
    height: ${({ theme, size }) => theme.button.height[size]};
`;

const Primary = styled(_BaseButton)`
    background: ${({ theme }) => theme.color.primary};
    color: ${({ theme }) => theme.color.white};
`;

const Secondary = styled(_BaseButton)`
    background: ${({ theme }) => theme.color.primary};
    color: ${({ theme }) => theme.color.white};
`;

const Button = {
    Primary,
    Secondary,
};
export default Button;
