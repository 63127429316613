// Libraries
import React from 'react';
import { FormattedMessage } from 'react-intl';

// Components
import Title from 'certn-ui/Title';
import Explain from './Explain';

const Default = () => (
    <>
        <Title>
            <FormattedMessage
                id="welcome.Identity.notStart.title"
                defaultMessage="To make sure that we're dealing with the right person and to verify your identity, we've got a few quick questions for you."
            />
        </Title>
        <Explain.Section>
            <FormattedMessage
                id="welcome.Identity.notStart.explain"
                defaultMessage="You will now undergo an independent, secure, identity assurance service that will generate a series of questions that only you should be able to answer. The types of questions you will be asked could relate to:"
            />
            <Explain.List>
                <li>
                    <FormattedMessage
                        id="welcome.Identity.notStart.address"
                        defaultMessage="A current or previous address"
                    />
                </li>
                <li>
                    <FormattedMessage
                        id="welcome.Identity.notStart.property"
                        defaultMessage="Property you own or owned in the past"
                    />
                </li>
                <li>
                    <FormattedMessage
                        id="welcome.Identity.notStart.household"
                        defaultMessage="Household demographic information"
                    />
                </li>
                <li>
                    <FormattedMessage
                        id="welcome.Identity.notStart.employment"
                        defaultMessage="Current or previous employment"
                    />
                </li>
                <li>
                    <FormattedMessage
                        id="welcome.Identity.notStart.accounts"
                        defaultMessage="Financial accounts or loan payments"
                    />
                </li>
            </Explain.List>
            <FormattedMessage
                id="welcome.Identity.notStart.final"
                defaultMessage="Certn does not know which questions you are asked, the multiple choice answers you are shown, or the answers you select. This process is designed to prevent others from viewing your information. It protects your privacy and the integrity of the identity assurance."
            />
        </Explain.Section>
    </>
);

export default Default;
