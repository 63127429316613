import { useQuery } from '@tanstack/react-query';
import { queryKeys } from 'utils/reactQuery';
import { AdjudicationReviewStatuses } from 'types';
import { getRequest } from 'utils/http';

const fetchAdjudicationReviewStatuses = () =>
    getRequest<AdjudicationReviewStatuses>({
        hr: true,
        version: 'v1',
        endpoint: '/adjudication/status/options/',
    });

export const useAdjudicationReviewStatuses = () => {
    const { data, isLoading, isError } = useQuery<AdjudicationReviewStatuses>(
        [queryKeys.adjudicationReviewStatuses],
        fetchAdjudicationReviewStatuses
    );

    return {
        adjudicationReviewStatuses: data,
        isAdjudicationReviewStatusesLoading: isLoading,
        hasAdjudicationReviewStatusesError: isError,
    };
};
