import React from 'react';
import styled from 'styled-components';
import { Select, Radio } from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import styles from 'styles/styles';

export const SectionWrapper = styled.div`
    display: flex;
    margin: auto;
    margin-top: 40px;
    flex-direction: column;
    border: 1px solid ${({ theme }) => theme.color.certnGray200};
    max-width: 840px;
    width: 100%;
    border-radius: 3px;
`;
export const SectionTopBar = styled.div`
    padding: 20px;
    outline: 1px solid ${({ theme }) => theme.color.certnGray200};
    font-size: ${({ theme }) => theme.font.xs};
`;

export const SectionBottomPanel = styled.div<{ row?: boolean }>`
    display: flex;
    flex-direction: ${({ row }) => (row ? 'row' : 'column')};
    justify-content: flex-start;
    padding: 20px;
`;

export const FieldToggle = styled.div`
    margin-left: auto;
    @media (min-width: ${(props) => props.theme.width.desktop}) {
        flex: 6;
    }
`;

export const FieldCheckBoxWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
`;

export const FieldCheckBoxMessage = styled.div`
    padding-top: 3px;
    margin-left: 20px;
`;

export const BottomPanelRow = styled.div`
    display: flex;
    flex-direction: row;
    @media (max-width: ${(props) => props.theme.width.desktop}) {
        justify-content: space-between;
    }
`;

export const BottomPanelRowInner = styled.div`
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
`;

export const CustomInputFrame = styled.div`
    width: 50% !important;
    @media (max-width: ${({ theme }) => theme.width.tablet}) {
        width: 100% !important;
    }
`;

export const CustomSelect = styled(Select)`
    width: 50% !important;
    @media (max-width: ${({ theme }) => theme.width.tablet}) {
        width: 100% !important;
    }
`;

export const Instructions = styled.span`
    color: ${({ theme }) => theme.color.certnGray400};
`;

export const BackButtonWrapper = styled.div`
    @media (min-width: ${({ theme }) => theme.width.tablet}) {
        position: absolute;
        top: 75px;
    }
`;

export const CustomRadio = styled(Radio.Group)`
    @media (max-width: ${({ theme }) => theme.width.tablet}) {
        display: flex !important;
        flex-direction: column !important;
    }
`;

const AlertText = styled.div`
    color: ${({ theme }) => theme.color.certnRed500};
`;

export const WarningAlert: React.FC = ({ children }) => (
    <AlertText>
        <WarningOutlined /> {children}
    </AlertText>
);

export const MicroServicesLayout = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    @media (min-width: ${(props) => props.theme.width.desktop}) {
        flex-direction: row;
        margin: 40px auto;
        min-width: 1024px;
        width: 100%;
    }
    @media (min-width: ${(props) => props.theme.width.wide}) {
        width: 83%;
    }
`;

export const MicroServicesListBox = styled.div`
    border-radius: 4px;
    overflow: hidden;
    width: 100%;
    margin: 0px;
    @media (max-width: ${(props) => props.theme.width.tablet}) {
        margin-top: 18px;
    }
    @media (min-width: ${(props) => props.theme.width.desktop}) {
        flex: 4.5;
    }
    @media (min-width: ${(props) => props.theme.width.wide}) {
        flex: 6;
        width: 100%;
    }
`;

export const ListItems = styled.div`
    border: 1px solid ${(props) => props.theme.color.certnGray200};
    & > * {
        outline: 1px solid ${(props) => props.theme.color.certnGray200};
    }
`;

export const RightSideFrame = styled(MicroServicesListBox)<{ isFollowUpFlow?: boolean }>`
    flex: 3.25 !important;

    @media (min-width: ${(props) => props.theme.width.desktop}) {
        margin-left: 30px;
        position: ${({ isFollowUpFlow }) => (isFollowUpFlow ? 'initial' : 'sticky')};
        top: 70px;
    }
`;

export const RightSideBox = styled.div`
    margin-bottom: 18px;
    border: 1px solid ${(props) => props.theme.color.certnGray200};
`;

export const BoxRow = styled.div`
    padding: 20px;
`;

export const BoxSmallRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
`;

export const BoxColumn = styled.div``;

export const BoxShadedRowFrame = styled.div`
    > div:nth-child(odd) {
        background-color: ${({ theme }) => theme.color.certnGray100};
    }
`;

export const TitleBar = styled.div<{ main?: boolean }>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 20px;
    width: 100%;
    ${({ main }) => main && 'min-height: 72px'};
    @media (max-width: ${(props) => props.theme.width.tablet}) {
        flex-direction: column;
        align-items: normal;
    }
`;

export const Title = styled.div<{ uppercase: boolean; fontWeight?: string }>`
    font-size: ${({ theme }) => theme.font.xs};
    font-weight: ${({ fontWeight }) => fontWeight || 'initial'};
    ${({ uppercase }) => uppercase && 'text-transform: uppercase;'};
`;

export const DisclaimerWrapper = styled.div`
    margin-top: 20px;

    @media (min-width: ${(props) => props.theme.width.desktop}) {
        padding: 20px;
    }
`;

export const Disclaimer = styled.div`
    margin-top: 15px;
    font-size: 12px;
`;

export const CartButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 20px 20px 20px;
    background-color: ${styles.color.certnGray100};
    border-top: 1px solid ${styles.color.certnGray200};

    > :first-child:not(:last-child) {
        margin-bottom: 12px;
    }
`;

export const Description = styled.span`
    line-height: 15px;
    color: ${({ theme }) => theme.color.certnGray400};
`;

export const EmptyServicesWrapper = styled.div`
    padding: 20px;
`;
