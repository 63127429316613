import styled from 'styled-components';

export const CheckboxGroup = styled.div`
    text-align: left;
    margin: 0px 0px;
    margin-bottom: 20px;
`;

export const RedactedText = styled.span`
    margin: 0px 0px;
    margin-bottom: 20px;
    color: lightgrey;
    align-self: start;
`;
