import React from 'react';
import { useIntl } from 'react-intl';
import { Select } from 'antd';
import { DropdownWrapper, Title, Wrapper } from '../styled';
import PropTypes from 'prop-types';

const { Option } = Select;

const OverallResultDropdown = ({ applicantAdjudication, setApplicantAdjudication, selectDisabled, statuses }) => {
    const intl = useIntl();
    const getResultDisplay = (obj) => (['NONE', 'REVIEW'].includes(obj.overall_result) ? null : obj.overall_result);
    return (
        <DropdownWrapper>
            <Title>
                {intl.formatMessage({
                    id: '6cb79.AdjudicationSidebar.overallResult',
                    defaultMessage: 'Overall Result',
                })}
            </Title>
            <Wrapper>
                <Select
                    allowClear
                    data-testid="overall_result_select"
                    disabled={selectDisabled}
                    placeholder={intl.formatMessage({
                        id: '6cb79.AdjudicationSidebar.pleaseSelect',
                        defaultMessage: 'Please Select',
                    })}
                    value={getResultDisplay(applicantAdjudication)}
                    onSelect={(value) => setApplicantAdjudication({ ...applicantAdjudication, overall_result: value })}
                    onClear={() => setApplicantAdjudication({ ...applicantAdjudication, overall_result: 'NONE' })}
                >
                    {Object.entries(statuses).map((status) => (
                        <Option value={status[0]} key={status[0]}>
                            {status[1]}
                        </Option>
                    ))}
                </Select>
            </Wrapper>
        </DropdownWrapper>
    );
};

OverallResultDropdown.propTypes = {
    setApplicantAdjudication: PropTypes.func.isRequired,
    applicantAdjudication: PropTypes.object,
    selectDisabled: PropTypes.bool,
    statuses: PropTypes.array,
};

OverallResultDropdown.defaultProps = { selectDisabled: false, statuses: [], applicantAdjudication: {} };

export default OverallResultDropdown;
