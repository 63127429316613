// Libraries
import React from 'react';
import styled from 'styled-components/macro';

const StyledPhoneWrapper = styled.div`
    display: inline-block;
    @media (max-width: ${({ theme }) => theme.width.phone}) {
        margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '30px')};
    }
`;

const PhoneWrapper = ({ children }) => <StyledPhoneWrapper>{children}</StyledPhoneWrapper>;

export default PhoneWrapper;
