// Libraries
import React, { Component } from 'react';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';

// UI Components & Images
import Postings from './views/Postings';
import Buildings from './views/Buildings';
import BuildingListings from './views/BuildingListings';
import Listings from './views/Listings';

class Browse extends Component {
    render() {
        return (
            <>
                <Switch>
                    <Route exact path="/browse/buildings/:teamId" component={Buildings} />
                    <Route exact path="/browse/building/:buildingId" component={BuildingListings} />
                    <Route exact path="/browse/listings/:teamId" component={Listings} />
                    <Route exact path="/browse/packages/:teamId" component={Postings} />
                    <Route exact path="/listings/:teamId" component={Listings} />
                    <Route exact path="/packages/:teamId" component={Postings} />
                    <Redirect to="/login" />
                </Switch>
            </>
        );
    }
}

export default withRouter(Browse);
