import Auth from 'modules/Auth';
import * as Sentry from '@sentry/browser';
import Cookies from 'js-cookie';
import moment from 'moment';
import 'moment/locale/fr';
import 'moment/locale/es';

import { postRequest, putRequest, getRequest } from 'utils/http';
import * as UserMode from 'modules/UserMode';
import styles from 'styles/styles';

import { NAMESPACE as PARENT_NAMESPACE, STATEPATH as PARENT_STATEPATH } from './BaseConstants';

// Constants
export const NAMESPACE = `${PARENT_NAMESPACE}`;
export const STATEPATH = `${PARENT_STATEPATH}`;

// Actions Types
export const SET_USER_MODE = `${NAMESPACE}SET_USER_MODE`;
export const SET_LOCALE = `${NAMESPACE}SET_LOCALE`;
export const OPEN_SIDE_BAR = `${NAMESPACE}OPEN_SIDE_BAR`;
export const CLOSE_SIDE_BAR = `${NAMESPACE}CLOSE_SIDE_BAR`;
export const LOG_USER_OUT = `${NAMESPACE}LOG_USER_OUT`;
export const POST_LOGIN_REQ = `${NAMESPACE}POST_LOGIN_REQ`;
export const POST_LOGIN_RES = `${NAMESPACE}POST_LOGIN_RES`;
export const POST_LOGIN_ERR = `${NAMESPACE}POST_LOGIN_ERR`;
export const GET_NOTIFICATIONS_REQ = `${NAMESPACE}GET_NOTIFICATIONS_REQ`;
export const GET_NOTIFICATIONS_RES = `${NAMESPACE}GET_NOTIFICATIONS_RES`;
export const GET_NOTIFICATIONS_ERR = `${NAMESPACE}GET_NOTIFICATIONS_ERR`;
export const GET_DROPDOWN_NOTIFICATIONS_RES = `${NAMESPACE}GET_DROPDOWN_NOTIFICATIONS_RES`;
export const GET_NOTIFICATION_REQ = `${NAMESPACE}GET_NOTIFICATION_REQ`;
export const GET_NOTIFICATION_RES = `${NAMESPACE}GET_NOTIFICATION_RES`;
export const GET_NOTIFICATION_ERR = `${NAMESPACE}GET_NOTIFICATION_ERR`;
export const SET_NOTIFICATION_VIEWED_REQ = `${NAMESPACE}SET_NOTIFICATION_VIEWED_REQ`;
export const SET_NOTIFICATION_VIEWED_RES = `${NAMESPACE}SET_NOTIFICATION_VIEWED_RES`;
export const SET_NOTIFICATION_VIEWED_ERR = `${NAMESPACE}SET_NOTIFICATION_VIEWED_ERR`;
export const UPDATE_USER_IS_NEW_REQ = `${NAMESPACE}UPDATE_USER_IS_NEW_REQ`;
export const UPDATE_USER_IS_NEW_RES = `${NAMESPACE}UPDATE_USER_IS_NEW_RES`;
export const UPDATE_USER_IS_NEW_ERR = `${NAMESPACE}UPDATE_USER_IS_NEW_ERR`;
export const UPDATE_TEMP_ADDRESS = `${NAMESPACE}UPDATE_TEMP_ADDRESS`;
export const UPDATE_TEMP_ADDRESSES = `${NAMESPACE}UPDATE_TEMP_ADDRESSES`;
export const INITIALIZE_TEMP_ADDRESSES = `${NAMESPACE}INITIALIZE_TEMP_ADDRESSES`;
export const DELETE_TEMP_ADDRESS = `${NAMESPACE}DELETE_TEMP_ADDRESS`;
export const UPDATE_ADDRESS_ERROR = `${NAMESPACE}UPDATE_ADDRESS_ERROR`;
export const UPDATE_REVIEW_SUBMIT_COMPONENT = `${NAMESPACE}UPDATE_REVIEW_SUBMIT_COMPONENT`;
export const LOGIN_WITH_MAGIC_LINK = `${NAMESPACE}LOGIN_WITH_MAGIC_LINK`;
export const GET_USER_INFO_REQ = `${NAMESPACE}GET_USER_INFO_REQ`;
export const GET_USER_INFO_RES = `${NAMESPACE}GET_USER_INFO_RES`;
export const GET_USER_INFO_ERR = `${NAMESPACE}GET_USER_INFO_ERR`;
export const SET_COUNTRY = `${NAMESPACE}SET_COUNTRY`;
export const SAVE_PARAMS = `${NAMESPACE}SAVE_PARAMS`;
export const GET_COUNTRIES_REQ = `${NAMESPACE}GET_COUNTRIES_REQ`;
export const GET_COUNTRIES_RES = `${NAMESPACE}GET_COUNTRIES_RES`;
export const GET_COUNTRIES_ERR = `${NAMESPACE}GET_COUNTRIES_ERR`;
export const SET_TEAM_LOGO = `${NAMESPACE}SET_TEAM_LOGO`;
export const GET_SKIN_REQ = `${NAMESPACE}GET_SKIN_REQ`;
export const GET_SKIN_RES = `${NAMESPACE}GET_SKIN_RES`;
export const GET_SKIN_ERR = `${NAMESPACE}GET_SKIN_ERR`;
export const UPDATE_SKIN_COLOUR = `${NAMESPACE}UPDATE_SKIN_COLOUR`;
export const FIRST_LOGIN_COMPLETE = `${NAMESPACE}FIRST_LOGIN_COMPLETE`;
export const SET_LAUNCH_DARKLY_FLAGS = `${NAMESPACE}SET_LAUNCH_DARKLY_FLAGS`;
export const GET_POTATO_CHECK_CONFIG_REQ = `${NAMESPACE}GET_POTATO_CHECK_CONFIG_REQ`;
export const GET_POTATO_CHECK_CONFIG_RES = `${NAMESPACE}GET_POTATO_CHECK_CONFIG_RES`;
export const GET_POTATO_CHECK_CONFIG_ERR = `${NAMESPACE}GET_POTATO_CHECK_CONFIG_ERR`;

// Action Creators
export const setLaunchDarklyFlags = (launchDarklyFlags) => (dispatch) => {
    dispatch({
        type: SET_LAUNCH_DARKLY_FLAGS,
        payload: { launchDarklyFlags },
    });
};
// SMB Sign Up flow, fire after first login via Magic Link so new user modal doesn't appear again
export const firstLoginComplete = () => (dispatch) => {
    dispatch({
        type: FIRST_LOGIN_COMPLETE,
    });
};

export const fetchSkin = (subdomain) => (dispatch) => {
    dispatch({
        type: GET_SKIN_REQ,
    });
    return getRequest({
        version: 'v2',
        endpoint: `/public_skins/?subdomain=${subdomain}`,
    }).then(
        (response) => {
            const skinColour = response.skin_colour || styles.color.certnGreen700;
            dispatch({
                type: GET_SKIN_RES,
                payload: {
                    skinColour,
                    teamLogo: response.logo_url,
                    ssoEnabled: response.sso_enabled,
                },
            });
            dispatch(updateSkinColour(skinColour));
        },
        (error) => {
            dispatch({
                type: GET_SKIN_ERR,
                payload: { error },
            });
        }
    );
};

export const updateSkinColour = (colour) => (dispatch) => {
    dispatch({
        type: UPDATE_SKIN_COLOUR,
        payload: { skinColour: colour },
    });
    window.less.modifyVars({ '@primary-color': colour });
};

export const fetchUserInfo = () => (dispatch) => {
    dispatch({
        type: GET_USER_INFO_REQ,
    });
    return getRequest({
        internal: false,
        version: 'v1',
        endpoint: '/user_info/',
    }).then(
        (response) => {
            dispatch({
                type: GET_USER_INFO_RES,
                payload: { user: response },
            });
            Auth.authenticateUser({ user: response, token: Auth.getToken() });

            Sentry.configureScope((scope) => {
                scope.setUser({ email: response.email });
            });
            return response;
        },
        (error) => {
            dispatch({
                type: GET_USER_INFO_ERR,
                payload: { error },
            });
        }
    );
};

export const fetchNotification = (notificationId) => (dispatch) => {
    dispatch({
        type: GET_NOTIFICATION_REQ,
    });
    return getRequest({
        internal: false,
        version: 'v1',
        endpoint: `/notifications/${notificationId}`,
    }).then(
        (response) => {
            dispatch({
                type: GET_NOTIFICATION_RES,
                payload: { notification: response },
            });
        },
        (error) => {
            dispatch({
                type: GET_NOTIFICATION_ERR,
                payload: { error },
            });
        }
    );
};

export const fetchNotifications = (onlyNew = true) => (dispatch) => {
    dispatch({
        type: GET_NOTIFICATIONS_REQ,
    });
    return getRequest({
        internal: false,
        version: 'v1',
        endpoint: `/notifications/${onlyNew ? '?is_viewed=false' : ''}`,
    }).then(
        (response) => {
            if (onlyNew) {
                dispatch({
                    type: GET_DROPDOWN_NOTIFICATIONS_RES,
                    payload: { dropdownNotifications: response },
                });
            } else {
                dispatch({
                    type: GET_NOTIFICATIONS_RES,
                    payload: { notifications: response },
                });
            }
        },
        (error) => {
            dispatch({
                type: GET_NOTIFICATIONS_ERR,
                payload: { error },
            });
        }
    );
};

export const setNotificationViewed = (notificationId) => (dispatch) => {
    dispatch({
        type: SET_NOTIFICATION_VIEWED_REQ,
    });
    return putRequest({
        internal: false,
        endpoint: `/notifications/${notificationId ? `${notificationId}/` : ''}`,
        body: JSON.stringify({ is_viewed: true }),
    }).then(
        () => {
            dispatch({
                type: SET_NOTIFICATION_VIEWED_RES,
            });
            // We need both of these: If you view while looking at list, dropdown wasn't updating, and vice versa
            dispatch(fetchNotifications());
            dispatch(fetchNotifications(false));
        },
        (error) => {
            dispatch({
                type: SET_NOTIFICATION_VIEWED_ERR,
                payload: { error },
            });
        }
    );
};

export const loginBasic = ({ loginForm, isApplicantLogin = false }) => (dispatch) => {
    const lowercaseEmail = loginForm.email.toLowerCase();
    const basicAuth = btoa(`${lowercaseEmail}:${loginForm.password}`);

    dispatch({
        type: POST_LOGIN_REQ,
    });
    return postRequest({
        internal: false,
        endpoint: '/login/',
        basicAuth,
        applicantLogin: isApplicantLogin,
    }).then(
        (response) => {
            isApplicantLogin ? Auth.authenticateApplicant(response) : Auth.authenticateUser(response);

            dispatch({
                type: POST_LOGIN_RES,
                payload: {
                    token: response.token,
                    user: response.user,
                },
            });
            Sentry.configureScope((scope) => {
                scope.setUser({ email: lowercaseEmail });
            });
        },
        (error) => {
            dispatch({
                type: POST_LOGIN_ERR,
                payload: { error },
            });

            throw error;
        }
    );
};

export const loginSSO = (token) => (dispatch) => {
    dispatch({
        type: POST_LOGIN_REQ,
    });
    return postRequest({
        internal: false,
        endpoint: '/login/',
        bearerAuth: token,
    })
        .then(
            (response) => {
                Auth.authenticateUser(response);
                dispatch({
                    type: POST_LOGIN_RES,
                    payload: {
                        token: response.token,
                        user: response.user,
                    },
                });
                return response;
            },
            (error) => {
                dispatch({
                    type: POST_LOGIN_ERR,
                    payload: { error },
                });
                throw error;
            }
        )
        .then((response) => {
            Sentry.configureScope((scope) => {
                scope.setUser({ email: response.user.email });
            });
            return response;
        });
};

export const updateUserIsNew = () => (dispatch) => {
    dispatch({
        type: UPDATE_USER_IS_NEW_REQ,
    });
    Cookies.set('is_new', false);
    return putRequest({
        internal: false,
        endpoint: '/user_info/',
        body: JSON.stringify({ is_new: false }),
    }).then(
        () => {
            dispatch({
                type: UPDATE_USER_IS_NEW_RES,
            });
        },
        (error) => {
            dispatch({
                type: UPDATE_USER_IS_NEW_ERR,
                payload: { error },
            });
        }
    );
};

export const loginWithMagicLink = (magicToken, accountType) => (dispatch) =>
    new Promise((resolve) => {
        Cookies.remove('magicToken', '');
        Cookies.remove('accountType', '');

        dispatch({
            type: LOGIN_WITH_MAGIC_LINK,
        });
        const magicResponse = {
            token: magicToken,
            user: {
                is_manager: true,
                is_user: accountType === 'PM',
                is_hr_user: accountType === 'HR',
            },
        };
        Auth.authenticateUser(magicResponse);
        resolve({ authenticated: true });
    });

export const updateAddressError = (addressError) => (dispatch) => {
    dispatch({
        type: UPDATE_ADDRESS_ERROR,
        payload: {
            addressError,
        },
    });
};

export const updateReviewSubmitComponent = () => (dispatch) => {
    dispatch({
        type: UPDATE_REVIEW_SUBMIT_COMPONENT,
        payload: true,
    });
};

export const logout = () => (dispatch) =>
    postRequest({
        internal: false,
        endpoint: '/logout/',
    }).finally(() => {
        Auth.deauthenticateUser();

        if (window.ChurnZero) {
            window.ChurnZero.push(['trackEvent', 'Logout']);
            window.ChurnZero.push(['stop']);
        }

        dispatch({
            type: LOG_USER_OUT,
        });
    });

export const setLocale = (locale) => (dispatch) => {
    moment.locale(locale);
    localStorage.setItem('locale', locale);
    dispatch({
        type: SET_LOCALE,
        payload: {
            locale,
        },
    });
};

export const setUserMode = (userMode) => (dispatch) => {
    UserMode.setUserMode(userMode);
    dispatch({
        type: SET_USER_MODE,
        payload: {
            userMode,
        },
    });
};

export const setTeamLogo = (teamLogo) => (dispatch) => {
    dispatch({
        type: SET_TEAM_LOGO,
        payload: {
            teamLogo,
        },
    });
};

export const setCountry = (country) => (dispatch) => {
    dispatch({
        type: SET_COUNTRY,
        payload: {
            country,
        },
    });
};

export const saveParams = (params) => (dispatch) => {
    dispatch({
        type: SAVE_PARAMS,
        payload: {
            params,
        },
    });
};

export const fetchCountries = (teamId) => (dispatch) => {
    dispatch({
        type: GET_COUNTRIES_REQ,
    });
    return getRequest({
        internal: false,
        version: 'v2',
        endpoint: `/country_prices?teamId=${teamId}`,
    }).then(
        (response) => {
            dispatch({
                type: GET_COUNTRIES_RES,
                payload: {
                    countries: response,
                },
            });
        },
        (error) => {
            dispatch({
                type: GET_COUNTRIES_ERR,
                payload: { error },
            });
            throw error;
        }
    );
};

export const fetchPotatoChecksTitles = () => (dispatch) => {
    dispatch({
        type: GET_POTATO_CHECK_CONFIG_REQ,
    });
    return getRequest({
        hr: false,
        version: 'v2',
        endpoint: `/check_titles/`, // keeping the endpoint backwards-compatible
    }).then(
        (response) => {
            dispatch({
                type: GET_POTATO_CHECK_CONFIG_RES,
                payload: { check_configs: response },
            });
        },
        (error) => {
            dispatch({
                type: GET_POTATO_CHECK_CONFIG_ERR,
                payload: { error },
            });
        }
    );
};
