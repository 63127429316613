// Libraries
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { injectIntl } from 'react-intl';

// Components
import { BaseForm, BankruptcyForm, ConvictionsForm, EvictionsForm, RentRefusalForm } from './components';

// Modules
import withNavigation from 'views/welcome/modules/WithNavigation';

// Actions & Selectors
import { setTrackPageOrder, patchInformation } from 'views/welcome/WelcomeActions';
import { getIsFetchingSilent, getInformation, getOnboardingType } from 'views/welcome/WelcomeSelectors';

const mapStateToProps = (state) => ({
    isFetchingSilent: getIsFetchingSilent(state),
    information: getInformation(state),
    onboardingType: getOnboardingType(state),
});

const mapDispatchToProps = (dispatch) => ({
    dispatch,
    ...bindActionCreators(
        {
            patchInformation,
            setTrackPageOrder,
        },
        dispatch
    ),
});

const propTypes = {
    // Redux Actions:
    patchInformation: PropTypes.func.isRequired,
    setTrackPageOrder: PropTypes.func.isRequired,
    // Redux Store:
    isFetchingSilent: PropTypes.bool.isRequired,
    information: PropTypes.object.isRequired,
    onboardingType: PropTypes.string.isRequired,
};
const defaultProps = {};

class History extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            resetObj: {
                convictions: { convictions: [] },
                bankruptcies: { bankruptcies: [] },
                rent_refusals: { rent_refusals: [] },
                evictions: { evictions: [] },
            },
        };
        window.scrollTo(0, 0);
    }

    pages = () => ({
        base: (
            <BaseForm
                handleSubmit={this.buildPageOrder}
                information={this.props.information}
                isFetchingSilent={this.props.isFetchingSilent}
                toggleDisableNext={this.toggleDisableNext}
                toggleSelect={this.toggleSelect}
                selectOptions={this.state.selectOptions}
                onboardingType={this.props.onboardingType}
                intl={this.props.intl}
            />
        ),
        convictions: (
            <ConvictionsForm
                convictions={get(this.props, ['information', 'convictions']) || []}
                isFetchingSilent={this.props.isFetchingSilent}
                handleSubmit={this.handleSubmit}
                intl={this.props.intl}
            />
        ),
        bankruptcies: (
            <BankruptcyForm
                bankruptcies={get(this.props, ['information', 'bankruptcies']) || []}
                handleSubmit={this.handleSubmit}
                isFetchingSilent={this.props.isFetchingSilent}
                intl={this.props.intl}
            />
        ),
        rent_refusals: (
            <RentRefusalForm
                rentRefusals={get(this.props, ['information', 'rent_refusals']) || []}
                handleSubmit={this.handleSubmit}
                isFetchingSilent={this.props.isFetchingSilent}
                intl={this.props.intl}
                includesAddress
            />
        ),
        evictions: (
            <EvictionsForm
                evictions={get(this.props, ['information', 'evictions']) || []}
                handleSubmit={this.handleSubmit}
                isFetchingSilent={this.props.isFetchingSilent}
                intl={this.props.intl}
                includesAddress
            />
        ),
    });

    handleSubmit = (values) => {
        const { handleForward, handleNextTrack, endOfTrack } = this.props; /* WithNavigation */
        this.props.patchInformation(values).then(() => {
            /* When card(s) toggled, go to next track if at end instead of return to base */
            endOfTrack() ? handleNextTrack() : handleForward();
        });
    };

    buildPageOrder = (e, validateFields) => {
        e.preventDefault();
        const { handleForward, handleNextTrack } = this.props; /* WithNavigation */

        validateFields((err, values) => {
            if (!err) {
                // Figure out which pages to go to during track
                const pagesToAdd = Object.keys(values).filter((key) => values[key]);
                const newPageOrder = ['base'].concat(pagesToAdd);

                // Figure out which objects to delete during patch
                const pagesToDelete = Object.keys(values).filter((key) => !values[key]);
                let resetObject = {};
                pagesToDelete.forEach((page) => {
                    resetObject = { ...resetObject, ...this.state.resetObj[page] };
                });
                this.props.setTrackPageOrder(newPageOrder);
                this.props.patchInformation(resetObject).then(() =>
                    /* When zero cards toggled, go to next track instead of return to base */
                    newPageOrder.length === 1 ? handleNextTrack() : handleForward()
                );
            } else {
                // provide failure error
            }
        });
    };

    render() {
        return this.pages()[this.props.trackPageLocation]; /* WithNavigation */
    }
}

History.propTypes = propTypes;
History.defaultProps = defaultProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(withNavigation(History))));
