// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// UI Components
import Loader from 'certn-ui/Loader';
import Titlebar from 'certn-ui/Titlebar';
import { BackButton } from 'certn-ui/Button';
import { message } from 'antd';

// External Libraries
import queryString from 'query-string';

// Actions & Selectors
import { createListing, fetchListing } from 'views/manager/views/pm/views/listings/ListingsActions';
import { getIsFetching, getError, getNewListing } from 'views/manager/views/pm/views/listings/ListingsSelectors';
import { getSettings } from 'views/manager/views/settings/SettingsSelectors';
import { getEmail } from 'base/BaseSelectors';
import { handleNewListingSelect } from 'views/manager/features/ScreenApplicant/ScreenApplicantActions';
import {
    getPropertyId,
    getShouldPersistScreenApplicantState,
} from 'views/manager/features/ScreenApplicant/ScreenApplicantSelectors';

// Other Components
import { ListingsForm } from 'views/manager/views/pm/views/listings/components';

const mapStateToProps = (state) => ({
    isFetching: getIsFetching(state),
    settings: getSettings(state),
    error: getError(state),
    email: getEmail(state),
    newListing: getNewListing(state),
    propertyId: getPropertyId(state),
    shouldPersistScreenApplicantState: getShouldPersistScreenApplicantState(state),
});
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            createListing,
            fetchListing,
            handleNewListingSelect,
        },
        dispatch
    );

const propTypes = {
    // Redux Actions
    createListing: PropTypes.func.isRequired,
    fetchListing: PropTypes.func.isRequired,
    // Redux State
    isFetching: PropTypes.bool.isRequired,
    shouldPersistScreenApplicantState: PropTypes.bool.isRequired,
    settings: PropTypes.object.isRequired,
    email: PropTypes.string,
    error: PropTypes.object,
};
const defaultProps = {
    error: undefined,
    email: undefined,
};

class ListingsNew extends React.Component {
    constructor(props) {
        super(props);
        const { location } = this.props;
        const relist = location?.search ? queryString.parse(location.search)?.listing_id : null;

        if (relist) {
            this.props.fetchListing(relist, true);
        }
        this.state = {
            relist,
        };
    }

    handlePostSubmit = (response, propertyId) => {
        if (this.props.shouldPersistScreenApplicantState) {
            message.success('Listing Added');

            this.props.handleNewListingSelect({ listingID: response?.id, propertyID: propertyId });
            return this.props.history.push('/pm/screen/applicant');
        }
        return this.props.history.push('/pm/listings?listing_created=true');
    };

    onSubmitListing = (listingData) => {
        this.props
            .createListing(listingData)
            .then((response) => this.handlePostSubmit(response, listingData.property_id))
            .catch(() => {
                message.error('There are errors with at least one of your form entries.');
            });
    };

    render() {
        const { relist } = this.state;
        const { newListing, email } = this.props;

        const defaultValues = {
            notification_list: email ? [{ email }] : [],
        };
        return (
            <>
                {this.props.shouldPersistScreenApplicantState ? (
                    <BackButton label="BACK" onClick={() => this.props.history.push('/pm/screen/applicant')} />
                ) : (
                    <BackButton label="LISTINGS" onClick={() => this.props.history.push('/pm/listings/')} />
                )}
                <Titlebar title="Create a New Listing" />
                {this.props.isFetching ? (
                    <Loader />
                ) : (
                    <ListingsForm
                        listingsNew
                        relist={relist}
                        listing={relist && newListing ? newListing : defaultValues}
                        onSubmit={this.onSubmitListing}
                    />
                )}
            </>
        );
    }
}

ListingsNew.propTypes = propTypes;
ListingsNew.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(ListingsNew);
