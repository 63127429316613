// Libraries
import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Form } from '@ant-design/compatible';
import { get } from 'lodash';

// Components
import { AppearRight } from 'certn-ui/Animate';
import { FlexCardRow } from 'certn-ui/Card';
import Title from 'certn-ui/Title';
import { TrackNavigation } from 'views/welcome/components';
import RoommatesSVG from 'images/svgs/svg-components/onboarding/Roommates';
import PetsSVG from 'images/svgs/svg-components/onboarding/Pets';
import CosignerSVG from 'images/svgs/svg-components/onboarding/Cosigner';
import ParkingSVG from 'images/svgs/svg-components/onboarding/Parking';
import { RadioCard } from 'certn-ui/Radio';

const BaseForm = ({ form, handleSubmit, handleReverse, information, intl }) => (
    <AppearRight>
        <Title>
            <FormattedMessage
                id="welcome.Supplemental.title"
                defaultMessage="Now, let's talk about your unique application. Do any of the following apply to you?"
            />
        </Title>
        <Form layout="horizontal" onSubmit={(e) => handleSubmit(e, form.validateFields)}>
            <FlexCardRow>
                <Form.Item>
                    {form.getFieldDecorator('roommates', {
                        valuePropName: 'checked',
                        initialValue: information.occupants && information.occupants.length > 0,
                    })(
                        <RadioCard
                            field="roommates"
                            title={intl.formatMessage({
                                id: 'welcome.Supplemental.roommates.title',
                                defaultMessage: 'I have other people living with me',
                            })}
                            description={intl.formatMessage({
                                id: 'welcome.Supplemental.roommates.description',
                                defaultMessage:
                                    'These are people who will be living with you in the suite, including children and spouses.',
                            })}
                            getField={form.getFieldValue}
                            setField={form.setFieldsValue}
                            SVG={RoommatesSVG}
                        />
                    )}
                </Form.Item>
                <Form.Item>
                    {form.getFieldDecorator('pets', {
                        valuePropName: 'checked',
                        initialValue: get(information, ['pet_details']) && get(information, ['pet_details']).length > 0,
                    })(
                        <RadioCard
                            field="pets"
                            title={intl.formatMessage({
                                id: 'welcome.Supplemental.pets.title',
                                defaultMessage: 'I have a pet',
                            })}
                            description={intl.formatMessage({
                                id: 'welcome.Supplemental.pets.description',
                                defaultMessage: 'Check this if you will have a pet living in the suite with you.',
                            })}
                            getField={form.getFieldValue}
                            setField={form.setFieldsValue}
                            SVG={PetsSVG}
                        />
                    )}
                </Form.Item>
                <Form.Item>
                    {form.getFieldDecorator('cosigner', {
                        valuePropName: 'checked',
                        initialValue: !!information.co_signer_first_name,
                    })(
                        <RadioCard
                            field="cosigner"
                            title={intl.formatMessage({
                                id: 'welcome.Supplemental.cosigner.title',
                                defaultMessage: 'I have a guarantor',
                            })}
                            description={intl.formatMessage({
                                id: 'welcome.Supplemental.cosigner.description',
                                defaultMessage:
                                    'A guarantor is someone who is not living in the suite but is liable to pay your rent if you fail to.',
                            })}
                            getField={form.getFieldValue}
                            setField={form.setFieldsValue}
                            SVG={CosignerSVG}
                        />
                    )}
                </Form.Item>
                <Form.Item>
                    {form.getFieldDecorator('car', {
                        valuePropName: 'checked',
                        initialValue: !!information.car_plate,
                    })(
                        <RadioCard
                            field="car"
                            title={intl.formatMessage({
                                id: 'welcome.Supplemental.car.title',
                                defaultMessage: 'I require parking',
                            })}
                            description={intl.formatMessage({
                                id: 'welcome.Supplemental.car.description',
                                defaultMessage:
                                    'Check this if you own a vehicle for which you will require a parking spot.',
                            })}
                            getField={form.getFieldValue}
                            setField={form.setFieldsValue}
                            SVG={ParkingSVG}
                        />
                    )}
                </Form.Item>
            </FlexCardRow>
            <Form.Item>
                <TrackNavigation handleRevers={handleReverse} />
            </Form.Item>
        </Form>
    </AppearRight>
);

export default Form.create()(injectIntl(BaseForm));
