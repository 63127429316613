import { useSelector, useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import { Select } from 'antd';
import { PackageWrapper, Or, PackageSelect } from './styled';
import { getIsFollowUpFlow, getIsUpgrade, getPackageID, getPackageSelected } from '../../../ScreenApplicantSelectors';
import { setupServicesPage } from '../../../ScreenApplicantActions';
import { fetchPosting } from 'views/manager/views/hr/views/postings/PostingsActions';
import { getUserModeIsPM } from 'base/BaseSelectors';
import { SelectValue } from 'antd/lib/select';
import { OptionData, OptionGroupData } from 'rc-select/lib/interface';
import { Posting } from 'types';
import { MatchParams } from './types';
import { useAllPostings } from 'hooks/useAllPostings';

export const PackageSelection = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const { packageID } = useRouteMatch<MatchParams>().params ?? {};
    const postingID = useSelector(getPackageID);
    const packageSelected = useSelector(getPackageSelected);
    const isUpgrade = useSelector(getIsUpgrade);
    const userModeIsPM = useSelector(getUserModeIsPM);
    const isFollowUpFlow = useSelector(getIsFollowUpFlow);
    // only display package select if HR and regular screen applicant flow
    const isDisplayed = !(userModeIsPM || isFollowUpFlow || packageID || isUpgrade);
    const { allPostings, isAllPostingsLoading } = useAllPostings({
        refetchOnMount: true,
        enabled: isDisplayed,
    });

    const handlePackageSelect = (selectedPostingID: SelectValue) => {
        dispatch(fetchPosting(selectedPostingID));
        dispatch(setupServicesPage({ packageID: selectedPostingID }));
    };

    const handlePackageClear = () => {
        dispatch(setupServicesPage({ packageID: undefined }));
    };

    const placeHolder =
        allPostings.length > 0
            ? intl.formatMessage({
                  id: 'a0e86.ScreenApplicant.packageSelectPlaceholder',
                  defaultMessage: 'Select a package',
              })
            : intl.formatMessage({
                  id: 'a0e86.ScreenApplicant.packageSelectNotCreated',
                  defaultMessage: 'No packages exist',
              });

    const handleFilterOption = (inputValue: string, options: OptionData | OptionGroupData | undefined) =>
        !!(options?.children && options?.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1);

    // only display package select if HR and regular screen applicant flow
    if (!isDisplayed) return null;

    return (
        <PackageWrapper>
            <Or>
                <FormattedMessage id="a0e86.ScreenApplicant.or" defaultMessage="- OR -" />
            </Or>
            <PackageSelect
                allowClear
                showSearch
                loading={isAllPostingsLoading}
                value={packageSelected ? postingID : undefined}
                placeholder={placeHolder}
                disabled={allPostings.length === 0 || isAllPostingsLoading}
                onSelect={handlePackageSelect} // lets us reselect same package
                onClear={handlePackageClear} // deletion using the x select button
                filterOption={handleFilterOption}
                data-testid="select-name"
            >
                {allPostings.map((post: Posting) => (
                    <Select.Option value={post.id} key={post.id} text={post.id}>
                        {post.position_name}
                    </Select.Option>
                ))}
            </PackageSelect>
        </PackageWrapper>
    );
};
