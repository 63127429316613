// Libraries
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// UI Components
import { Button, Modal, Alert, Form } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import EmailAddresses from 'components/EmailAddresses/EmailAddresses';
import { getUserModeIsHR, getLanguage } from 'base/BaseSelectors';
import { useSelector } from 'react-redux';
import { LanguageSelect } from 'base/components/';
import FieldLabel from 'certn-form/FieldLabel';

const propTypes = {
    posting: PropTypes.object,
    error: PropTypes.object,
    onOk: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    confirmLoading: PropTypes.bool,
};
const defaultProps = {
    posting: {
        position_name: '',
    },
    error: {},
    confirmLoading: false,
};

const SendApplicationsForm = ({ posting, error, onOk, onCancel, confirmLoading }) => {
    const language = useSelector(getLanguage);
    const userModeIsHR = useSelector(getUserModeIsHR);
    const intl = useIntl();
    const [submitted, setSubmitted] = useState(false);
    const [form] = Form.useForm();

    const doSubmit = (e) => {
        e.preventDefault();
        setSubmitted(true);
        form.submit();
    };
    const handleSubmit = (values) => {
        onOk(values.emails[0], posting.id, values.language);
    };

    return (
        <Modal
            title={
                <FormattedMessage
                    id="9c636.SendApplicationsForm.title1"
                    defaultMessage="Invite applicants for {position}"
                    values={{ position: posting.position_name }}
                />
            }
            visible
            onOk={doSubmit}
            onCancel={onCancel}
            confirmLoading={confirmLoading}
            destroyOnClose
            footer={[
                <Button key="back" onClick={onCancel}>
                    <FormattedMessage id="9c636.SendApplicationsForm.return" defaultMessage="Return" />
                </Button>,
                <Button key="submit" type="primary" htmlType="submit" loading={confirmLoading} onClick={doSubmit}>
                    <FormattedMessage id="common.submit" defaultMessage="Submit" />
                </Button>,
            ]}
        >
            <Form
                form={form}
                onFinish={handleSubmit}
                initialValues={{
                    emails: [''],
                }}
            >
                <EmailAddresses
                    form={form}
                    instructions={intl.formatMessage({
                        id: '9c636.SendApplicationsForm.subtitle',
                        defaultMessage: 'Enter applicant email below to send them an application by email.',
                    })}
                    submitAttempted={submitted}
                    maxAllowed={1}
                    fullWidth
                />
                {userModeIsHR && (
                    <>
                        <FieldLabel htmlFor="language">
                            <FormattedMessage id="f5cd8.Applicant.emailLanguage" defaultMessage="Email language" />
                        </FieldLabel>
                        <Form.Item
                            key="language"
                            name="language"
                            initialValue={language}
                            rules={[
                                {
                                    required: true,
                                    message: intl.formatMessage({
                                        id: 'error.validation.notBlank',
                                        defaultMessage: 'Please do not leave blank',
                                    }),
                                },
                            ]}
                        >
                            <LanguageSelect disableSpanish disableSetLocale />
                        </Form.Item>
                    </>
                )}
                {error && error.emails && (
                    <Alert
                        message={intl.formatMessage({
                            id: '9c636.SendApplicationsForm.message3',
                            defaultMessage: 'This email has already been used to apply to this posting',
                        })}
                        type="warning"
                        showIcon
                    />
                )}
            </Form>
        </Modal>
    );
};

SendApplicationsForm.propTypes = propTypes;
SendApplicationsForm.defaultProps = defaultProps;

export default SendApplicationsForm;
