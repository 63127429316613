// Libraries
import { useIntl } from 'react-intl';

// Modules & Utils
import Constants from 'utils/constants';

export const useTranslateOrderStatus = () => {
    const intl = useIntl();
    const translateOrderStatus = (orderStatus: string | undefined | null) => {
        switch (orderStatus) {
            case Constants.orderStatus.WAITING_ON_CANDIDATE:
                return intl.formatMessage({
                    id: 'status.waitingOnCandidate',
                    defaultMessage: 'Waiting on candidate',
                });
            case Constants.orderStatus.COMPLETE:
                return intl.formatMessage({
                    id: 'status.complete',
                    defaultMessage: 'Complete',
                });
            case Constants.orderStatus.ACTION_REQUIRED:
                return intl.formatMessage({
                    id: 'status.actionRequired',
                    defaultMessage: 'Action required',
                });
            case Constants.orderStatus.IN_PROGRESS:
                return intl.formatMessage({
                    id: 'status.inProgress',
                    defaultMessage: 'In progress',
                });
            case Constants.orderStatus.CANCELLED:
                return intl.formatMessage({
                    id: 'status.cancelled',
                    defaultMessage: 'Cancelled',
                });
            case Constants.orderStatus.IN_DISPUTE:
                return intl.formatMessage({
                    id: 'status.inDispute',
                    defaultMessage: 'In dispute',
                });
            case Constants.orderStatus.APPLICATION_EXPIRED:
                return intl.formatMessage({
                    id: 'status.applicationExpired',
                    defaultMessage: 'Expired',
                });

            default:
                console.error(`Order status translation missing for ${orderStatus}.`);
                return null;
        }
    };

    return { translateOrderStatus };
};
