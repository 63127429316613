import styled from 'styled-components';

interface NoticeFrameThemeProps {
    theme: {
        color: {
            certnGreen100: string;
        };
    };
}

const NoticeFrame = styled.div`
    background-color: ${({ theme }: NoticeFrameThemeProps) => theme.color.certnGreen100};
    justify-content: center;
    width: 100%;
    padding: 15px;
    min-height: 55px;
    margin: 5px 0px;
    box-sizing: border-box;
    border-radius: 5px;
`;

export default NoticeFrame;
