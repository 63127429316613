import { CheckExecution, CheckStatus } from 'types';

export const calculateCheckExecutionGroupStatus = (checkExecutions: CheckExecution[]): CheckStatus => {
    if (checkExecutions.some((check: CheckExecution) => check.status === CheckStatus.WAITING_ON_CANDIDATE)) {
        return CheckStatus.WAITING_ON_CANDIDATE;
    }

    if (checkExecutions.some((check: CheckExecution) => check.status === CheckStatus.ACTION_REQUIRED)) {
        return CheckStatus.ACTION_REQUIRED;
    }

    if (checkExecutions.some((check: CheckExecution) => check.status === CheckStatus.IN_DISPUTE)) {
        return CheckStatus.IN_DISPUTE;
    }

    if (checkExecutions.some((check: CheckExecution) => check.status === CheckStatus.IN_PROGRESS)) {
        return CheckStatus.IN_PROGRESS;
    }

    if (checkExecutions.some((check: CheckExecution) => check.status === CheckStatus.UNRESPONSIVE)) {
        return CheckStatus.UNRESPONSIVE;
    }

    if (checkExecutions.some((check: CheckExecution) => check.status === CheckStatus.REQUIRES_MORE_INFORMATION)) {
        return CheckStatus.REQUIRES_MORE_INFORMATION;
    }

    if (checkExecutions.some((check: CheckExecution) => check.status === CheckStatus.WAITING_ON_ADJUDICATOR)) {
        return CheckStatus.WAITING_ON_ADJUDICATOR;
    }

    if (checkExecutions.every((check: CheckExecution) => check.status === CheckStatus.CANCELLED)) {
        return CheckStatus.CANCELLED;
    }

    if (
        checkExecutions.every(
            (check: CheckExecution) => check.status === CheckStatus.COMPLETE || check.status === CheckStatus.CANCELLED
        )
    ) {
        return CheckStatus.COMPLETE;
    }

    console.error('Check execution status calculation fell back to default value');
    return CheckStatus.COMPLETE;
};
