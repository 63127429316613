import { useState } from 'react';
import { Button, message, Modal } from 'antd';
import { FormattedMessage } from 'react-intl';
import { intl } from 'components/GlobalProvider';
import { SendButtonProps } from './types';
import { postRequest } from 'utils/http';

export const SendButton = ({ isPreAdverse, applicantId, reload }: SendButtonProps) => {
    const [isLoading, setIsLoading] = useState(false);

    const sendAdverseAction = async (close: () => void) => {
        setIsLoading(true);
        close();
        try {
            await postRequest({
                version: 'v2',
                endpoint: `/adverse-action/${applicantId}/${isPreAdverse ? 'pa' : 'aa'}/send-notice`,
            });
            isPreAdverse
                ? message.success(
                      intl.formatMessage({
                          id: 'AdverseAction.Drawer.SendButton.PreAdverseSuccess',
                          defaultMessage: 'The pre-adverse action email has been sent',
                      })
                  )
                : message.success(
                      intl.formatMessage({
                          id: 'AdverseAction.Drawer.SendButton.AdverseSuccess',
                          defaultMessage: 'The adverse action email has been sent',
                      })
                  );
            reload();
        } catch (error) {
            message.error(
                intl.formatMessage({
                    id: 'AdverseAction.Drawer.SendButton.Error',
                    defaultMessage: 'Failed to send the adverse action notice',
                })
            );
        }
        setIsLoading(false);
    };

    const handleSend = () => {
        Modal.confirm({
            centered: true,
            title: isPreAdverse
                ? intl.formatMessage({
                      id: 'AdverseAction.Drawer.SendButton.ModalTitlePreAdverse',
                      defaultMessage: 'Send Pre-Adverse Action Notice?',
                  })
                : intl.formatMessage({
                      id: 'AdverseAction.Drawer.SendButton.ModalTitleAdverse',
                      defaultMessage: 'Send Adverse Action Notice?',
                  }),
            content: intl.formatMessage({
                id: 'AdverseAction.Drawer.SendButton.ModalBody',
                defaultMessage:
                    'You are about to send this notice to the applicant. Please preview the contents of the documents to ensure they are correct, as changes cannot be made after submission.',
            }),
            okText: intl.formatMessage({ id: 'common.send', defaultMessage: 'Send' }),
            cancelText: intl.formatMessage({ id: 'common.cancel', defaultMessage: 'Cancel' }),
            onOk: sendAdverseAction,
        });
    };

    return (
        <Button type="primary" onClick={handleSend} loading={isLoading}>
            {isPreAdverse ? (
                <FormattedMessage
                    id="AdverseAction.Drawer.SendButton.PreAdverseText"
                    defaultMessage="Send Pre-Adverse Action Notice"
                />
            ) : (
                <FormattedMessage
                    id="AdverseAction.Drawer.SendButton.AdverseText"
                    defaultMessage="Send Adverse Action Notice"
                />
            )}
        </Button>
    );
};
