// Libraries
import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import copy from 'copy-to-clipboard';
import { FormattedMessage } from 'react-intl';
import { intl } from 'components/GlobalProvider';

// UI Components
import Typography from 'certnd/Typography';
import { Button, Table, message } from 'antd';
import ActionsCell from './components/ActionsCell';
import InfoCell from './components/InfoCell';
import Loader from 'certn-ui/Loader';
import Overlay from 'certn-ui/Overlay';
import { ListCellLink } from 'certn-ui/List';

// Actions & Selectors
import { getUser } from 'base/BaseSelectors';
import {
    fetchApiKeys,
    createApiKey,
    deleteApiKey,
    // fetchFullApiKey,
} from 'views/manager/views/settings/SettingsActions';
import { getApiKeys } from 'views/manager/views/settings/SettingsSelectors';
import { getVerboseDateTimeFormat } from 'modules/Format';

// Other Components
const { Heading } = Typography;

const ApiKeysWrapper = styled.div`
    @media (min-width: ${(props) => props.theme.width.desktop}) {
        max-width: 1200px;
        min-width: 1000px;
    }
`;

function ApiKeys() {
    const dispatch = useDispatch();
    const apiKeys = useSelector((state) => getApiKeys(state));
    const user = useSelector(getUser);

    useEffect(() => {
        dispatch(fetchApiKeys());
    }, [dispatch]);

    const handleCreateApiKey = () => {
        dispatch(createApiKey());
    };

    const handleDeleteApiKey = (id) => {
        dispatch(deleteApiKey(id));
    };

    const copyTokenToClipboard = (token) => {
        // helper function
        let copySuccess = false;
        if (token) {
            copySuccess = copy(token);
        }
        if (copySuccess) {
            message.success(
                intl.formatMessage({
                    id: 'adea4.ApiKeys.CopySuccess',
                    defaultMessage: 'Access token was copied to clipboard.',
                })
            );
        } else {
            message.error(
                intl.formatMessage({
                    id: 'adea4.ApiKeys.CopyError',
                    defaultMessage: 'Access token could not be copied.',
                })
            );
        }
    };

    const desktopColumns = [
        {
            title: <FormattedMessage id="adea4.ApiKeys.TableColumnToken" defaultMessage="Token" />,
            dataIndex: 'token',
            key: 'token',
            render: (text) => {
                if (text.length > 4) {
                    // check length of access token
                    return (
                        <Overlay
                            layer={
                                <Button size="small" onClick={() => copyTokenToClipboard(text)}>
                                    <FormattedMessage id="adea4.ApiKeys.CopyButton" defaultMessage="Copy" />
                                </Button>
                            }
                        >
                            <ListCellLink onClick={() => copyTokenToClipboard(text)}>{`${text}`}</ListCellLink>
                        </Overlay>
                    );
                }

                return (
                    <FormattedMessage
                        id="adea4.ApiKeys.DefaultTokenReturn"
                        defaultMessage="Last four characters: {TokenText}"
                        values={{ TokenText: text }}
                    />
                );
            },
        },
        {
            title: <FormattedMessage id="adea4.ApiKeys.TableColumnCreated" defaultMessage="Created" />,
            dataIndex: 'created',
            key: 'created',
            render: (text) => moment(text).format(getVerboseDateTimeFormat()),
        },
        {
            title: <FormattedMessage id="adea4.ApiKeys.TableColumnLastUsed" defaultMessage="Last Used" />,
            dataIndex: 'last_used',
            key: 'last_used',
            render: (text) => (text !== null ? moment(text).format(getVerboseDateTimeFormat()) : ''),
        },
        {
            title: <FormattedMessage id="common.owner" defaultMessage="Owner" />,
            dataIndex: 'owner',
            key: 'owner',
            render: (text) => (text === user.id ? `${user.email}` : text),
        },
        {
            title: <FormattedMessage id="common.actions" defaultMessage="Actions" />,
            dataIndex: 'actions',
            key: 'actions',
            render: (text, row) => (
                <ActionsCell row={row} deleteApiKey={handleDeleteApiKey} copyTokenToClipboard={copyTokenToClipboard} />
            ),
        },
    ];

    const mobileColumns = [
        {
            title: <FormattedMessage id="common.info" defaultMessage="Info" />,
            dataIndex: 'info',
            key: 'info',
            onCell: () => ({
                style: {
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: 150,
                },
            }),
            render: (text, row) => <InfoCell row={row} />,
        },
        {
            title: <FormattedMessage id="common.actions" defaultMessage="Actions" />,
            dataIndex: 'actions',
            key: 'actions',
            render: (text, row) => (
                <ActionsCell row={row} deleteApiKey={handleDeleteApiKey} copyTokenToClipboard={copyTokenToClipboard} />
            ),
        },
    ];
    if (apiKeys === undefined) {
        return (
            <ApiKeysWrapper>
                <Heading.H1 style={{ display: 'inline-block' }}>
                    <FormattedMessage id="adea4.ApiKeys.PageTitle" defaultMessage="API Keys" />
                </Heading.H1>
                <Loader />
            </ApiKeysWrapper>
        );
    }
    return (
        <ApiKeysWrapper>
            <Heading.H1 style={{ display: 'inline-block' }}>
                <FormattedMessage id="adea4.ApiKeys.PageTitle" defaultMessage="API Keys" />
            </Heading.H1>
            <Button style={{ float: 'right' }} type="primary" onClick={handleCreateApiKey}>
                <FormattedMessage id="adea4.ApiKeys.AddKeyButton" defaultMessage="ADD API KEY" />
            </Button>
            {apiKeys.length > 0 ? (
                <Table
                    columns={window.matchMedia('(min-width: 1024px)').matches ? desktopColumns : mobileColumns}
                    dataSource={apiKeys}
                />
            ) : (
                <div style={{ margin: '30px' }}>
                    <FormattedMessage id="adea4.ApiKeys.NoKeys" defaultMessage="no current api keys" />
                </div>
            )}
        </ApiKeysWrapper>
    );
}

export default ApiKeys;
