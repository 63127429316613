// Libraries
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Form } from '@ant-design/compatible';
import { Alert, Row, Col, Space } from 'antd';
import { CameraOutlined, UploadOutlined } from '@ant-design/icons';
import Webcam from 'react-webcam';

// Components
import { AppearRight } from 'certn-ui/Animate';
import Button from 'certn-ui/Button';
import Text from 'certn-ui/Text';
import Title from 'certn-ui/Title';
import { TrackNavigation } from 'views/welcome/components';
import { EllipseOverlay, VideoButtons, PermissionDeniedBox, PermissionDeniedMessage } from './styled';

const SelfieForm = ({ handleSubmit, intl, onUpload, file, fileProgress, uploadComplete }) => {
    const webcamRef = React.useRef(null);
    const [userMedia, setUserMedia] = React.useState(undefined);
    const [userMediaError, setUserMediaError] = React.useState(undefined);
    const [imgDataUri, setImgDataUri] = React.useState(null);

    const captureSelfie = React.useCallback(() => setImgDataUri(webcamRef.current.getScreenshot()), [
        webcamRef,
        setImgDataUri,
    ]);
    const resetSelfie = React.useCallback(() => setImgDataUri(null), [setImgDataUri]);
    const canProceed = file?.url || (imgDataUri && uploadComplete);

    return (
        <AppearRight>
            <Form layout="horizontal" onSubmit={(e) => handleSubmit(e)}>
                <Title>
                    <FormattedMessage
                        id="welcome.Selfie.title"
                        defaultMessage="To verify your identity, we require a photo of yourself."
                    />
                </Title>

                <Row gutter={[16, 16]}>
                    <Col sm={24} md={{ span: 14, offset: 5 }} lg={{ span: 12, offset: 6 }} xl={{ span: 10, offset: 7 }}>
                        <Text align="center" color="certnGray600">
                            <FormattedMessage
                                id="welcome.Selfie.description"
                                defaultMessage="Make sure this is a live photo, and your entire face is positioned within the frame."
                            />
                        </Text>
                    </Col>
                </Row>

                <Row gutter={0}>
                    <Col sm={24} md={{ span: 16, offset: 4 }}>
                        {file?.url ? (
                            <>
                                <img alt="Selfie" style={{ width: '100%' }} src={file.url} />

                                {uploadComplete && (
                                    <Alert
                                        message={intl.formatMessage({
                                            id: 'welcome.Selfie.uploadSuccessful',
                                            defaultMessage: 'Upload successful',
                                        })}
                                        type="success"
                                        showIcon
                                    />
                                )}
                            </>
                        ) : userMediaError ? (
                            <PermissionDeniedBox>
                                <PermissionDeniedMessage>
                                    <FormattedMessage
                                        id="welcome.Selfie.permissionDeniedMessage"
                                        defaultMessage="Camera permission is not enabled and a camera is needed for identity verification."
                                    />
                                    <br />
                                    <a
                                        href="https://help.certn.co/hc/en-us/articles/9940180160659"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <FormattedMessage
                                            id="welcome.Selfie.howToEnableCamera"
                                            defaultMessage="How to enable the camera"
                                        />
                                    </a>
                                </PermissionDeniedMessage>
                            </PermissionDeniedBox>
                        ) : (
                            <>
                                {!imgDataUri && userMedia && <EllipseOverlay />}

                                <Webcam
                                    data-testid="webcam"
                                    audio={false}
                                    ref={webcamRef}
                                    screenshotFormat="image/jpeg"
                                    style={{
                                        display: imgDataUri ? 'none' : 'block',
                                        width: '100%',
                                        backgroundColor: '#f2f2f2',
                                    }}
                                    onUserMediaError={setUserMediaError}
                                    onUserMedia={setUserMedia}
                                />

                                {userMedia && (
                                    <>
                                        {imgDataUri && <img alt="Selfie" style={{ width: '100%' }} src={imgDataUri} />}

                                        <VideoButtons>
                                            <Button
                                                type="primary"
                                                certncolor="primary"
                                                onClick={imgDataUri ? resetSelfie : captureSelfie}
                                            >
                                                <Space>
                                                    <CameraOutlined />
                                                    {imgDataUri ? (
                                                        <FormattedMessage
                                                            id="welcome.Selfie.retakePhoto"
                                                            defaultMessage="Retake photo"
                                                        />
                                                    ) : (
                                                        <FormattedMessage
                                                            id="welcome.Selfie.capture"
                                                            defaultMessage="Capture photo"
                                                        />
                                                    )}
                                                </Space>
                                            </Button>

                                            {imgDataUri && (
                                                <Button
                                                    type="primary"
                                                    certncolor="primary"
                                                    onClick={() => onUpload(imgDataUri)}
                                                >
                                                    <Space>
                                                        <UploadOutlined />
                                                        <FormattedMessage
                                                            id="welcome.Selfie.upload"
                                                            defaultMessage="Upload"
                                                        />
                                                    </Space>
                                                </Button>
                                            )}
                                        </VideoButtons>
                                    </>
                                )}
                            </>
                        )}
                    </Col>
                </Row>
                <TrackNavigation loading={fileProgress !== 0 && fileProgress !== 100} hideNext={!canProceed} />
            </Form>
        </AppearRight>
    );
};

export default Form.create()(injectIntl(SelfieForm));
