import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Button as AntdButton, Layout, Tabs, notification } from 'antd';
import { FormattedMessage } from 'react-intl';
import { CloseOutlined } from '@ant-design/icons';

import { SideBar } from 'views/manager/components';
import styles from 'styles/styles';

import { patchComments } from 'views/manager/views/hr/views/applications/ApplicationsActions';

const CommentsSidebar = ({ sidebarCollapsed, toggleSidebar, applicantId, comments }) => {
    const dispatch = useDispatch();
    const [commentInput, setCommentInput] = useState('');
    const [scrollTop, setScrollTop] = useState(0);

    useEffect(() => {
        const onScroll = (event) => {
            setScrollTop(event.target.documentElement.scrollTop);
        };
        window.addEventListener('scroll', onScroll);

        return () => window.removeEventListener('scroll', onScroll);
    }, [scrollTop]);

    const handleUpdateComment = (event) => {
        setCommentInput(event.target.value);
    };

    const handleSubmitComment = async () => {
        try {
            await dispatch(patchComments(commentInput, applicantId));
        } catch (error) {
            notification.error({
                message: <FormattedMessage id="6cb79.ApplicationsView.message1" defaultMessage="Error" />,
                description: error,
            });
        }
        setCommentInput('');
    };

    const sideBarTopPosition = scrollTop >= 100 ? '70px' : '160px';

    return (
        <div
            style={{
                position: 'fixed',
                right: '0px',
                top: sideBarTopPosition,
                height: `calc(100vh - ${sideBarTopPosition})`,
                overflowY: 'scroll',
                transition: 'top 0.25s ease',
            }}
        >
            <Layout
                hasSider
                style={{
                    backgroundColor: styles.color.certnWhite,
                    marginBottom: '50px',
                    height: 'initial',
                }}
            >
                <Layout.Sider
                    theme="light"
                    trigger={null}
                    collapsedWidth={0}
                    collapsible
                    collapsed={sidebarCollapsed}
                    width="235px"
                    style={{
                        marginBottom: '50px',
                        borderLeft: '0',
                        collapsedWidth: '0',
                        height: '0px',
                    }}
                >
                    <SideBar.InfoBar>
                        <div>
                            <FormattedMessage
                                id="6cb79.ApplicationsView.applicantInfo"
                                defaultMessage="Applicant Info"
                            />
                        </div>{' '}
                        <AntdButton
                            onClick={toggleSidebar}
                            style={{ border: '0' }}
                            shape="circle"
                            icon={<CloseOutlined />}
                            size="small"
                        />
                    </SideBar.InfoBar>
                    <Tabs
                        defaultActiveKey="1"
                        size="small"
                        tabBarStyle={{
                            fontWeight: '500',
                            overflow: 'visible',
                            margin: 'auto',
                        }}
                    >
                        <Tabs.TabPane
                            tab={<FormattedMessage id="6cb79.ApplicationsView.comments" defaultMessage="Comments" />}
                            key="1"
                            style={{ minHeight: '1000px' }}
                        >
                            <SideBar.CommentsSection
                                handleUpdateComment={handleUpdateComment}
                                patchComments={handleSubmitComment}
                                comments={comments}
                                commentInput={commentInput}
                            />
                        </Tabs.TabPane>
                    </Tabs>
                </Layout.Sider>
            </Layout>
        </div>
    );
};

CommentsSidebar.propTypes = {
    sidebarCollapsed: PropTypes.bool.isRequired,
    toggleSidebar: PropTypes.func.isRequired,
    applicantId: PropTypes.string.isRequired,
    comments: PropTypes.array,
};

CommentsSidebar.defaultProps = {
    comments: [],
};

export default CommentsSidebar;
