// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl, FormattedMessage } from 'react-intl';

// React Router & Routes
import { withRouter } from 'react-router-dom';

// External Library
import queryString from 'query-string';

import { Form } from '@ant-design/compatible';

// UI Components
import { Col, Input, Row, Select } from 'antd';
import { ProvStateSelect } from 'components';

import { get } from 'lodash';

// Modules & Utils
import { Regex } from 'modules';
import Countries, { CA } from 'modules/Countries';

// FIXME: What is this for? This is probably the wrong place to get these actions/selectors
// Actions & Selectors
import { fetchTeamMembers } from 'views/manager/views/pm/views/listings/ListingsActions';
import { getTeamMembers, getTeamMemberDict } from 'views/manager/views/pm/views/listings/ListingsSelectors';
import {
    getAllProperties,
    getAllPropertyNames,
    getAllPropertyIds,
} from 'views/manager/views/pm/views/properties/PropertiesSelectors';
import styles from 'styles/styles';

const mapStateToProps = (state) => ({
    teamMembers: getTeamMembers(state),
    teamMemberDict: getTeamMemberDict(state),
    allProperties: getAllProperties(state),
    allPropertyNames: getAllPropertyNames(state),
    allPropertyIds: getAllPropertyIds(state),
});
const mapDispatchToProps = (dispatch) => bindActionCreators({ fetchTeamMembers }, dispatch);

const propTypes = {
    // Redux Actions
    fetchTeamMembers: PropTypes.func.isRequired,
    // Redux Store
    teamMembers: PropTypes.array,
    teamMemberDict: PropTypes.object,
    allProperties: PropTypes.array,
    allPropertyNames: PropTypes.array,
    allPropertyIds: PropTypes.array,
    // Parent Props
    listing: PropTypes.object,
    listingsNew: PropTypes.bool,
};
const defaultProps = {
    listing: {},
    teamMembers: [],
    teamMemberDict: {},
    allProperties: [],
    allPropertyNames: [],
    allPropertyIds: [],
    listingsNew: false,
};

class TeamsForm extends React.Component {
    constructor(props) {
        super(props);
        const { location } = props;
        this.props.fetchTeamMembers();
        let parsed;
        if (location?.search) {
            parsed = queryString.parse(location.search);
        }
        this.state = {
            propertyId: parsed?.propertyId || undefined,
        };
    }

    handleValueSwap = (value) => {
        const currentValue = this.state[value];
        this.setState({ [value]: !currentValue });
    };

    render() {
        const { getFieldDecorator, getFieldValue, setFieldsValue } = this.props.form;
        const { intl } = this.props;

        const formItemLayout = {
            labelCol: {
                span: 24,
            },
            wrapperCol: {
                span: 24,
            },
        };

        const defaultCountry = get(this.props, ['editingTeam', 'country'], CA);

        return (
            <Form layout="vertical">
                <Form.Item
                    {...formItemLayout}
                    label={this.props.intl.formatMessage({
                        id: 'b664c.TeamsForm.teamName',
                        defaultMessage: 'Team Name',
                    })}
                >
                    {getFieldDecorator('name', {
                        initialValue: get(this.props, ['editingTeam', 'name']),
                        rules: [
                            {
                                required: true,
                                message: intl.formatMessage({
                                    id: 'error.validation.notBlank',
                                    defaultMessage: 'Please do not leave blank',
                                }),
                            },
                        ],
                    })(
                        <Input
                            type="text"
                            placeholder={this.props.intl.formatMessage({
                                id: 'b664c.TeamsForm.teamPlaceholder',
                                defaultMessage: 'Team 1',
                            })}
                        />
                    )}
                </Form.Item>
                <div style={{ marginBottom: '8px' }}>
                    <span style={{ fontSize: styles.font.xs, fontWeight: 600 }}>
                        <FormattedMessage
                            id="b664c.TeamsForm.accountingContactInfo"
                            defaultMessage="Accounting Contact Information"
                        />
                    </span>
                </div>
                <Form.Item
                    {...formItemLayout}
                    label={this.props.intl.formatMessage({ id: 'common.email', defaultMessage: 'Email' })}
                >
                    {getFieldDecorator('accounting_email', {
                        initialValue: get(this.props, ['editingTeam', 'accounting_email']),
                        rules: [
                            {
                                pattern: Regex.email,
                                message: intl.formatMessage({
                                    id: 'error.validation.email',
                                    defaultMessage: 'Please provide a valid email',
                                }),
                            },
                            {
                                required: true,
                                message: intl.formatMessage({
                                    id: 'error.validation.notBlank',
                                    defaultMessage: 'Please do not leave blank',
                                }),
                            },
                        ],
                    })(<Input style={{ width: '100%' }} type="text" placeholder="accounting@company.com" />)}
                </Form.Item>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            {...formItemLayout}
                            label={this.props.intl.formatMessage({
                                id: 'common.firstName',
                                defaultMessage: 'First Name',
                            })}
                        >
                            {getFieldDecorator('accounting_first_name', {
                                initialValue: get(this.props, ['editingTeam', 'accounting_first_name']),
                                rules: [
                                    {
                                        required: true,
                                        message: intl.formatMessage({
                                            id: 'error.validation.notBlank',
                                            defaultMessage: 'Please do not leave blank',
                                        }),
                                    },
                                ],
                            })(
                                <Input
                                    type="text"
                                    placeholder={this.props.intl.formatMessage({
                                        id: 'b664c.TeamsForm.firstNamePlaceholder',
                                        defaultMessage: 'Jane',
                                    })}
                                />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            {...formItemLayout}
                            label={this.props.intl.formatMessage({
                                id: 'common.lastName',
                                defaultMessage: 'Last Name',
                            })}
                        >
                            {getFieldDecorator('accounting_last_name', {
                                initialValue: get(this.props, ['editingTeam', 'accounting_last_name']),
                                rules: [
                                    {
                                        required: true,
                                        message: intl.formatMessage({
                                            id: 'error.validation.notBlank',
                                            defaultMessage: 'Please do not leave blank',
                                        }),
                                    },
                                ],
                            })(
                                <Input
                                    type="text"
                                    placeholder={this.props.intl.formatMessage({
                                        id: 'b664c.TeamsForm.lastNamePlaceholder',
                                        defaultMessage: 'Doe',
                                    })}
                                />
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <div style={{ background: '#F5F5F5', padding: '16px', borderRadius: '4px' }}>
                    <span style={{ fontSize: styles.font.xs, fontWeight: 600 }}>
                        <FormattedMessage id="b664c.TeamsForm.billingAddress" defaultMessage="Billing Address" />
                    </span>

                    <Form.Item
                        {...formItemLayout}
                        label={this.props.intl.formatMessage({ id: 'common.country', defaultMessage: 'Country' })}
                    >
                        {getFieldDecorator('country', {
                            initialValue: defaultCountry,
                            valuePropName: 'checked',
                        })(
                            <Select
                                style={{ width: '100%' }}
                                defaultValue={defaultCountry}
                                onChange={() => setFieldsValue({ province_state: undefined })}
                            >
                                {Countries.all.map(({ name, alpha2 }) => (
                                    <Select.Option value={alpha2} key={alpha2}>
                                        {name}
                                    </Select.Option>
                                ))}
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item
                        formItemLayout={{ labelCol: 12 }}
                        label={this.props.intl.formatMessage({
                            id: 'a2509.Billing.StreetAddress',
                            defaultMessage: 'Street Address',
                        })}
                    >
                        {getFieldDecorator('street_address', {
                            initialValue: get(this.props, ['editingTeam', 'street_address']),
                            rules: [
                                {
                                    required: true,
                                    message: intl.formatMessage({
                                        id: 'error.validation.notBlank',
                                        defaultMessage: 'Please do not leave blank',
                                    }),
                                },
                            ],
                        })(<Input type="text" />)}
                    </Form.Item>
                    <Form.Item
                        {...formItemLayout}
                        label={this.props.intl.formatMessage({
                            id: 'common.city',
                            defaultMessage: 'City',
                        })}
                    >
                        {getFieldDecorator('city', {
                            initialValue: get(this.props, ['editingTeam', 'city']),
                            rules: [
                                {
                                    required: true,
                                    message: intl.formatMessage({
                                        id: 'error.validation.notBlank',
                                        defaultMessage: 'Please do not leave blank',
                                    }),
                                },
                            ],
                        })(<Input type="text" />)}
                    </Form.Item>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                {...formItemLayout}
                                style={{ width: '100%' }}
                                label={this.props.intl.formatMessage({
                                    id: 'address.provinceState',
                                    defaultMessage: 'Province/State',
                                })}
                            >
                                {getFieldDecorator('province_state', {
                                    initialValue: 'British Columbia',
                                    required: true,
                                })(
                                    <ProvStateSelect
                                        selectedCountry={getFieldValue('country')}
                                        style={{ width: '100%' }}
                                        returnName
                                    />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                {...formItemLayout}
                                label={this.props.intl.formatMessage({
                                    id: 'addressAuto.postalZip',
                                    defaultMessage: 'Postal/Zip Code',
                                })}
                            >
                                {getFieldDecorator('postal_code', {
                                    initialValue: get(this.props, ['editingTeam', 'postal_code']),
                                    rules: [
                                        {
                                            required: true,
                                            message: intl.formatMessage({
                                                id: 'error.validation.notBlank',
                                                defaultMessage: 'Please do not leave blank',
                                            }),
                                        },
                                    ],
                                })(<Input type="text" />)}
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
            </Form>
        );
    }
}

TeamsForm.propTypes = propTypes;
TeamsForm.defaultProps = defaultProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(TeamsForm)));
