import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { postRequest } from 'utils/http';
import { getEnhancedIdentityPending, getOnboardingId } from 'views/welcome/WelcomeSelectors';
import { TrackNavigation } from 'views/welcome/components';
import { TrustmaticEventNotification, CreateSessionData } from '../types';
import { useIntl } from 'react-intl';
import { TrustmaticModal } from './TrustmaticModal';
import { ConfirmCloseTrustmaticModal } from './ConfirmCloseTrustmaticModal';
import { ErrorAlertCustom } from 'certn-ui/ErrorAlert';

interface TrustmaticProps {
    handleSubmit: () => void;
}

export const OneIDTrustmatic: React.FC<TrustmaticProps> = ({ handleSubmit }) => {
    const onboardingId = useSelector(getOnboardingId);
    const enhancedIdentityPending = useSelector(getEnhancedIdentityPending);
    const intl = useIntl();

    const [showTrackNavigation, setShowTrackNavigation] = useState(!enhancedIdentityPending);
    const [trustmaticModalOpen, setTrustmaticModalOpen] = useState(false);
    const [closeModalConfirmOpen, setCloseModalConfirmOpen] = useState(false);
    const [trustmaticUrlLoading, setTrustmaticUrlLoading] = useState<boolean>(false);
    const [trustmaticUrlError, setTrustmaticUrlError] = useState<boolean>(false);
    const [trustmaticUrl, setTrustmaticUrl] = useState<string>('');
    const [trustmaticResponse, setTrustmaticResponse] = useState<TrustmaticEventNotification | undefined>(undefined);

    const closeTrustmaticModal = () => {
        trustmaticModalOpen && setCloseModalConfirmOpen(true);
    };
    const confirmCloseTrustmaticModal = () => {
        if (trustmaticModalOpen) {
            setCloseModalConfirmOpen(false);
            setTrustmaticModalOpen(false);
        }
    };
    const returnToTrustmaticModal = () => {
        setCloseModalConfirmOpen(false);
    };

    const handleStartVerification = useCallback(async () => {
        setTrustmaticUrlError(false);
        setTrustmaticModalOpen(true);
        setTrustmaticUrlLoading(true);
        const { src_url } = await postRequest<CreateSessionData>({
            hr: true,
            version: 'v1',
            endpoint: `onboarding/${onboardingId}/identity_verification/create_session/`,
        });

        setTrustmaticUrlLoading(false);

        if (src_url) {
            setTrustmaticUrl(src_url);
        } else {
            setTrustmaticUrlError(true);
        }
    }, [onboardingId]);

    const enrollmentCompleted = (event: MessageEvent) => {
        const { data }: { data: TrustmaticEventNotification } = event;
        setTrustmaticResponse(data);
    };

    useEffect(() => {
        if (trustmaticModalOpen) {
            window.addEventListener('message', enrollmentCompleted, false);
        } else {
            window.removeEventListener('message', enrollmentCompleted, false);
        }
    }, [trustmaticModalOpen]);

    useEffect(() => {
        const handleTrustmaticCompletedEvent = () => {
            setShowTrackNavigation(false);
            setTrustmaticModalOpen(false);
            handleSubmit();
        };
        const handleTrustmaticErrorEvent = (_trustmaticResponse: TrustmaticEventNotification) => {
            setShowTrackNavigation(false);
            ErrorAlertCustom({
                title: 'Something went wrong!',
                description: `Error ${_trustmaticResponse.error}: There's been an error. Please try refreshing the session, opening your session in another browser or checking your camera settings.`,
            });
        };

        if (trustmaticResponse?.hasCompleted) handleTrustmaticCompletedEvent();
        if (trustmaticResponse?.hasError) handleTrustmaticErrorEvent(trustmaticResponse);
    }, [trustmaticResponse, handleSubmit]);

    return (
        <div>
            <TrustmaticModal
                isOpen={trustmaticModalOpen}
                onClose={closeTrustmaticModal}
                trustmaticUrl={trustmaticUrl}
                isLoading={trustmaticUrlLoading}
                isError={trustmaticUrlError}
            />
            <ConfirmCloseTrustmaticModal
                isOpen={closeModalConfirmOpen}
                onClose={confirmCloseTrustmaticModal}
                onOk={returnToTrustmaticModal}
            />

            {showTrackNavigation && (
                <TrackNavigation
                    handleSubmit={handleStartVerification}
                    nextButtonTitle={intl.formatMessage({
                        id: 'trustmatic.button',
                        defaultMessage: 'START VERIFICATION',
                    })}
                    disabled={!!trustmaticModalOpen}
                />
            )}
        </div>
    );
};
