import { CheckExecution } from '.';

/* eslint-disable no-shadow */
export enum OrderStatuses {
    WAITING_ON_CANDIDATE = 'WAITING_ON_CANDIDATE',
    COMPLETE = 'COMPLETE',
    ACTION_REQUIRED = 'ACTION_REQUIRED',
    IN_PROGRESS = 'IN_PROGRESS',
    CANCELLED = 'CANCELLED',
    IN_DISPUTE = 'IN_DISPUTE',
    APPLICATION_EXPIRED = 'APPLICATION_EXPIRED',
}
export interface OrderData {
    id: string;
    first_name: string | null;
    last_name: string | null;
    email: string | null;
    check_executions: CheckExecution[];
    order_status: OrderStatuses;
    modified: string;
}
