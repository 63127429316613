// Libraries
import React from 'react';
import { Row, Col } from 'antd';
import { FormattedMessage } from 'react-intl';

// Components
import { ButtonLine } from 'certn-ui/Button';
import { TextLink } from 'certn-ui/Text';
import Title from 'certn-ui/Title';
import { AppearRight } from 'certn-ui/Animate';
import MultiAdd, { MultiAddItem, EmptyListContainer } from 'views/welcome/components/MultiAdd';
import { TrackNavigation } from 'views/welcome/components';

export const Base = ({ handleSubmit, personalReferences, addOrEditReference, deleteReference, nextTrack }) => (
    <AppearRight>
        <Title>
            <FormattedMessage
                id="welcome.Personal.personalReferencesTitle"
                defaultMessage="Would you like to provide any personal references?"
            />
        </Title>
        <MultiAdd
            testKey="personal"
            title={<FormattedMessage id="welcome.Personal.personalReferences" defaultMessage="Personal References" />}
            buttonTitle={
                <FormattedMessage id="welcome.Personal.addPersonalReference" defaultMessage="ADD PERSONAL REFERENCE" />
            }
            buttonAction={addOrEditReference}
        >
            {personalReferences && personalReferences.length > 0 ? (
                personalReferences.map((personalReference, index) => (
                    <MultiAddItem
                        testKey="personal"
                        key={personalReference.id}
                        top={`${personalReference.name.first_name} ${personalReference.name.last_name}`}
                        bottom={personalReference.email.address}
                        onEdit={() => addOrEditReference(index)}
                        onDelete={() => deleteReference(index)}
                    />
                ))
            ) : (
                <EmptyListContainer>
                    <FormattedMessage id="welcome.Personal.noReferencesYet" defaultMessage="No References added yet." />
                </EmptyListContainer>
            )}
        </MultiAdd>
        <TrackNavigation handleSubmit={handleSubmit} />
        <Row gutter={16}>
            <Col sm={24} lg={{ span: 16, offset: 4 }}>
                <ButtonLine>
                    <TextLink data-testid="no-thanks" id="next" onClick={() => nextTrack()}>
                        <FormattedMessage id="welcome.general.noThanks" defaultMessage="No thanks" />
                    </TextLink>
                </ButtonLine>
            </Col>
        </Row>
    </AppearRight>
);

export default Base;
