// Libraries
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

// Components
import SummaryItem from './SummaryItem';
import SummaryHeader from './SummaryHeader';
import { FlexRow, SummarySection, SummaryContainer, LineBreak } from './SummaryStyles';

const Personal = ({ references, goToTrack }) => (
    <SummaryContainer>
        <SummaryHeader
            title={<FormattedMessage id="welcome.Summary.personalReferences" defaultMessage="Personal References" />}
            onClick={goToTrack}
        />

        <SummarySection>
            {references.length === 0 && (
                <SummaryItem
                    empty
                    title={
                        <FormattedMessage
                            id="welcome.Summary.noPersonalReferencesListed"
                            defaultMessage="You have not listed any personal references"
                        />
                    }
                />
            )}
            {references.map((reference, i) => (
                <>
                    <FlexRow>
                        <SummaryItem
                            title={<FormattedMessage id="common.firstName" defaultMessage="First Name" />}
                            text={reference.name.first_name}
                        />
                        <SummaryItem
                            title={<FormattedMessage id="common.lastName" defaultMessage="Last Name" />}
                            text={reference.name.last_name}
                        />
                        <SummaryItem
                            title={<FormattedMessage id="welcome.general.relation" defaultMessage="Relation" />}
                            text={reference.association_label}
                        />
                        <SummaryItem
                            ttitle={<FormattedMessage id="common.email" defaultMessage="Email" />}
                            text={reference.email.address}
                        />
                        <SummaryItem
                            title={<FormattedMessage id="welcome.general.phoneNumber" defaultMessage="Phone Number" />}
                            text={reference.phone_number.number}
                        />
                    </FlexRow>

                    {references.length > 1 && i < references.length && <LineBreak />}
                </>
            ))}
        </SummarySection>
    </SummaryContainer>
);

export default injectIntl(Personal);
