import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { uniqBy } from 'lodash';
import { getDataSet, reduce } from 'iso3166-2-db';

// Components
import FUFAddRemoveItem from './FUFAddRemoveItem';

// Actions & Selectors
import {
    getApplicantInformationObject,
    getRequestedCountries,
    getTeamExcludedCountriesFromInternationalChecks,
} from 'views/manager/features/ScreenApplicant/ScreenApplicantSelectors';

import { addCountry, removeCountry } from 'views/manager/features/ScreenApplicant/ScreenApplicantActions';

import {
    RightSideBox,
    BoxSmallRow,
    BoxShadedRowFrame,
    BoxColumn,
    TitleBar,
    Title,
} from 'views/manager/features/ScreenApplicant/styled';

// Modules
import { ukProvinceTranslate } from 'modules';
import { isUKOrGB, isUSAOrCanada } from 'modules/Countries';
import { getLanguage } from 'base/BaseSelectors';

/**
 * FUFIntCrimCheckCountries rendered by ScreenApplicant.jsx
 * @returns row listing of user address information with ability to add and remove from current selection
 */
const FUFIntCrimCheckCountries = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const information = useSelector(getApplicantInformationObject);
    const requestedCountryCodes = useSelector(getRequestedCountries);
    const countriesExcludedFromInternationalCheck = useSelector(getTeamExcludedCountriesFromInternationalChecks);
    const addresses = information?.addresses;
    const [uniqueAddresses, setAddresses] = useState([]);
    const lang = useSelector(getLanguage);
    const countriesDB = React.useMemo(() => reduce(getDataSet(), lang), [lang]);

    // Sort Addresses by Current first, then remove duplicates
    useEffect(() => {
        const sortedAddresses = (addresses || []).sort((address) => (address.current ? -1 : 1));
        setAddresses(
            uniqBy(sortedAddresses, (address) => {
                if (isUKOrGB(address.country)) {
                    return ukProvinceTranslate(address.other_province_state);
                }
                return address.country;
            })
        );
    }, [addresses]);

    const handleAddItem = (id) => dispatch(addCountry(id));
    const handleRemoveItem = (id) => {
        dispatch(removeCountry(id));
    };

    const desktop = window.matchMedia('(min-width: 1024px)').matches;

    return (
        <RightSideBox>
            <TitleBar>
                <Title>
                    {intl.formatMessage({
                        id: 'ase86.ScreenApplicantAddress.title',
                        defaultMessage: 'International Criminal Record Check - Countries',
                    })}
                </Title>
            </TitleBar>
            <BoxShadedRowFrame>
                {uniqueAddresses.map((address) => {
                    const { current, country, country_label, other_province_state } = address;
                    const usOrCA = isUSAOrCanada(country);
                    // GB/UK require provinces
                    const greatBritainOrUK = isUKOrGB(country);
                    // Display proper country label
                    const countryLabel = greatBritainOrUK
                        ? countriesDB.GB.regions.filter((p) => p.iso === other_province_state)[0].name
                        : country_label;
                    // Use SCT instead of GB for Scotland country code
                    const countryCode = greatBritainOrUK ? other_province_state : country;
                    // Lock countries excluded from international checks for team
                    const isExcludedFromInternationalChecks =
                        countriesExcludedFromInternationalCheck &&
                        countriesExcludedFromInternationalCheck.find((item) => item === countryCode);
                    // Check is selected
                    const itemSelected = requestedCountryCodes?.includes(countryCode);

                    return (
                        <BoxSmallRow>
                            <BoxColumn>
                                <CurrentPrevious current={current} desktop={desktop} intl={intl} />
                                {' - '}
                                {countryLabel}
                            </BoxColumn>
                            <BoxColumn>
                                <FUFAddRemoveItem
                                    disabled={usOrCA || isExcludedFromInternationalChecks}
                                    itemSelected={itemSelected}
                                    addItem={() => handleAddItem(countryCode)}
                                    removeItem={() => handleRemoveItem(countryCode)}
                                />
                            </BoxColumn>
                        </BoxSmallRow>
                    );
                })}
            </BoxShadedRowFrame>
        </RightSideBox>
    );
};

const CurrentPrevious = ({ current, desktop, intl }) => {
    if (desktop) {
        return current
            ? intl.formatMessage({ id: 'common.currentAddress', defaultMessage: 'Current Address' })
            : intl.formatMessage({ id: 'common.previousAddress', defaultMessage: 'Previous Address' });
    }
    return current
        ? intl.formatMessage({ id: 'common.current', defaultMessage: 'Current' })
        : intl.formatMessage({ id: 'common.previous', defaultMessage: 'Previous' });
};

export default FUFIntCrimCheckCountries;
