import styled from 'styled-components';

export const Title = styled.span<{ crossed: boolean | undefined }>`
    ${({ crossed }) => crossed && 'text-decoration: line-through'}
`;

export const LineItem = styled.div<{ disabled?: boolean }>`
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: ${({ disabled, theme }) => (disabled ? theme.color.certnGray400 : '')};
    ${({ disabled }) => disabled && 'cursor: not-allowed'};
    font-weight: 600;
`;

export const CurrencyWrapper = styled.div`
    display: inline-block;
    white-space: nowrap;
`;

export const PriceItemWrapper = styled.span`
    display: inline-block;
    margin-right: 10px;
    text-transform: uppercase;
`;
