import { combineReducers } from 'redux';

import ScreenApplicant from './ScreenApplicant/ScreenApplicantReducer';
import Drawer from './Drawer/DrawerReducer';
import AdverseActionDrawer from './AdverseActionDrawer/DrawerReducer';

const features = combineReducers({
    ScreenApplicant,
    Drawer,
    AdverseActionDrawer,
});

export default features;
