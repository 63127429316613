// Libraries
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useSelector, useDispatch } from 'react-redux';

// Components
import { RequiredText } from 'certn-ui';
import { intl } from 'components/GlobalProvider';
import {
    SectionWrapper,
    SectionTopBar,
    SectionBottomPanel,
    BottomPanelRow,
    Description,
} from 'views/manager/features/ScreenApplicant/styled';
import WorkLocationAddressInput from 'certn-form/WorkLocationAddressInput';
import { Checkbox, Form } from 'antd';
import SoftCheckAlert from './SoftCheckAlert';

// Actions and Selectors
import { getSelectedCheckKeys } from 'views/manager/features/ScreenApplicant/ScreenApplicantSelectors';
import { updateReviewSubmitComponent } from 'base/BaseActions';

// Constants
import { QA_TEST_ID } from 'utils/constants';
import { CHECK_REQUEST } from 'base/BaseConstants';

const WorkLocation = ({ form, isPM }) => {
    const selectedServiceKeys = useSelector(getSelectedCheckKeys);
    const { webFeatureRequireCurrentAndPositionPropertyAddressesForQuickscreen } = useFlags();
    const [isGig, setIsGig] = useState(false);
    const dispatch = useDispatch();

    const shouldRenderWorkLocationComponent = () => {
        if (webFeatureRequireCurrentAndPositionPropertyAddressesForQuickscreen) {
            if (!isPM) {
                return true;
            }
        }
        return false;
    };
    const onChangeSetGig = (e) => {
        setIsGig(e.target.checked);
        form.resetFields(['country_work']);
        dispatch(updateReviewSubmitComponent());
    };
    return (
        shouldRenderWorkLocationComponent() && (
            <SectionWrapper data-testid={QA_TEST_ID.QuickScreenWorkLocationSectionWrapper}>
                <SectionTopBar>
                    <FormattedMessage
                        id="f5ca1.QuickScreen.enterWorkLocation"
                        defaultMessage="Enter Your Candidate's Work Location"
                    />
                </SectionTopBar>
                <SectionBottomPanel style={{ paddingBottom: '0px' }}>
                    <BottomPanelRow style={{ marginBottom: '20px' }}>
                        <Description>
                            <FormattedMessage
                                id="f5ca1.QuickScreen.workLocationDescription"
                                defaultMessage="This information will be used to provide the appropriate disclosure and authorization forms."
                            />
                        </Description>
                    </BottomPanelRow>
                    <Form.Item name="gig" valuePropName="checked" initialValue={false} style={{ marginBottom: '14px' }}>
                        <Checkbox data-testid="gig" onChange={onChangeSetGig}>
                            {intl.formatMessage({
                                id: 'f5cal.Quickscreen.gig',
                                defaultMessage:
                                    'Please check this box if you work from home or do not have a primary work location (ex. rideshare or service delivery).',
                            })}
                        </Checkbox>
                    </Form.Item>
                    {!isGig ? (
                        <>
                            <RequiredText>
                                {intl.formatMessage({
                                    id: 'f5ca1.QuickScreen.workLocationLookup',
                                    defaultMessage: 'Address Lookup',
                                })}
                            </RequiredText>
                            <BottomPanelRow>
                                <div style={{ width: '50%' }}>
                                    <WorkLocationAddressInput
                                        form={form}
                                        hideLabels
                                        bypassCounty
                                        autocompleteId="work"
                                    />
                                </div>
                            </BottomPanelRow>
                        </>
                    ) : null}
                    <div style={{ width: '50%', marginBottom: 10 }}>
                        <SoftCheckAlert
                            hasSoftcheck={selectedServiceKeys.includes(CHECK_REQUEST.SOFTCHECK)}
                            country={form.getFieldValue('country_work')}
                        />
                    </div>
                </SectionBottomPanel>
            </SectionWrapper>
        )
    );
};

export default WorkLocation;
