import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import Text from 'certn-ui/Text';

const Container = styled.div`
    width: 560px;
    max-width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
    box-sizing: border-box;
    margin: 0 auto;
`;

const Subtitle = ({ children, ...props }) => (
    <Container {...props}>
        <Text align="center" color="certnGray600">
            {children}
        </Text>
    </Container>
);

Subtitle.Container = Container;
export default Subtitle;
