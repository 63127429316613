import { Modal, Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import { PurposeModalSection } from './components/PuposeModalSection';

export const PurposeHelpModal = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
    const title = <FormattedMessage id="24519.Purpose.purposeTrackTitle" defaultMessage="Purpose of check" />;

    return (
        <Modal
            title={<b>{title}</b>}
            visible={isOpen}
            onCancel={onClose}
            footer={[
                <Button key="ok" onClick={onClose} type="primary">
                    <FormattedMessage id="common.ok" defaultMessage="OK" />
                </Button>,
            ]}
        >
            <PurposeModalSection section="employment" />
            <PurposeModalSection section="license" />
            <PurposeModalSection section="other" />
        </Modal>
    );
};
