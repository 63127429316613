// Libraries
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import { Input, Popover } from 'antd';

// React PNI
import PhoneInput from 'react-phone-number-input';
import { parseIncompletePhoneNumber, parsePhoneNumberFromString } from 'libphonenumber-js';
import 'react-phone-number-input/style.css';

import { getCountry } from 'base/BaseSelectors';
import { intl } from 'components/GlobalProvider';

// CSS tweaks required to get antd styling and PNI to work together
const CustomPhoneInput = styled(PhoneInput)`
    .PhoneInputCountry {
        margin-bottom: 24px;
    }
    .PhoneInputCountryIcon--border {
        box-shadow: none;
    }
`;

const CustomAntdMatchedPhoneInput = styled(CustomPhoneInput)`
    .PhoneInputCountry {
        margin: 0px;
        border-right: 1px solid lightgrey;
        padding: 0 5px 0 5px;
        height: 40px !important;
        // Matches ant-d input
        background-color: #fafafa;
    }
    border: 1px solid lightgrey;
    border-radius: 1px;
`;

const removeCharAt = (str, index) => {
    const tmp = str.split(''); // convert to an array
    tmp.splice(index, 1); // remove 1 element from the array
    return tmp.join(''); // reconstruct the string
};

const InputComponent = React.forwardRef(
    (
        {
            value,
            onChange,
            errorStatus,
            errorMessage,
            inputSettings = { inputSize: 'large', showPopOver: true },
            ...props
        },
        ref
    ) => {
        // tracking caret position and update without re-rendering
        const caretPos = React.useRef(0);
        const prevValue = React.useRef(value);

        const isHighlightingFromStart = React.useRef(false);

        const isBackSpaceHit = React.useRef(false);
        const isDeleteHit = React.useRef(false);

        const handleKeyDown = (event) => {
            if (event.keyCode === 8) {
                // backspace key
                isBackSpaceHit.current = true;
            } else if (event.keyCode === 46) {
                // delete key
                isDeleteHit.current = true;
            } else {
                // reset
                isBackSpaceHit.current = false;
                isDeleteHit.current = false;
            }
        };

        const handleSelect = (event) => {
            isHighlightingFromStart.current = event.target.selectionStart === 0;
        };

        const handleChange = (event) => {
            const newCaretPos = event.target.selectionStart;

            let adjustedCaretPos = newCaretPos;

            // New value upon input changes
            const newParsedPhoneValue = parseIncompletePhoneNumber(event.target.value);

            // Need to check whether it can parse. When the phone number is left with just country code e.g. +1,
            // parsePhoneNumberFromString will return undefined
            const canParseNewPhoneNumber = Boolean(parsePhoneNumberFromString(newParsedPhoneValue));

            /**
             * Scenario:
             * +1 604 123 4567
             * Highlight and delete 604 123 4567
             * canParseNewPhoneNumber will return undefined. Then we set caret to the end
             */
            if (!canParseNewPhoneNumber) {
                caretPos.current = newCaretPos;
            }

            // Need to store the current value in state as previous value. To be used on next render.
            prevValue.current = value;

            const isPrevCharSpace = value.charAt(newCaretPos - 1) === ' ';
            const isNextCharSpace = value.charAt(newCaretPos) === ' ';

            if (isPrevCharSpace) {
                if (isBackSpaceHit.current) {
                    /**
                     * Scenario:
                     * +1 604 123 4|567
                     * Hit backspace, we want the caret to skip the space and position like this:
                     * +1 604 123| 567
                     */
                    adjustedCaretPos = newCaretPos - 1;
                } else {
                    /**
                     * Scenario:
                     * +1 604 123| 567
                     * Enter 4, we want the caret to adapt to the added space and position as:
                     * +1 604 123 4|567
                     * Without this, this would become +1 604 123 |4567
                     */
                    adjustedCaretPos = newCaretPos + 1;
                }
            }

            if (isNextCharSpace) {
                if (isDeleteHit.current) {
                    /**
                     * Scenario:
                     * +1 604 123| 4567
                     * Hit delete, we want the caret to skip the space and position like this:
                     * +1 604 123| 567
                     * The below logic is to delete not only the "space", but the actual number character
                     */
                    event.target.value = removeCharAt(event.target.value, newCaretPos);
                    // stay at current position when "Delete" key is hit
                    adjustedCaretPos = newCaretPos;
                }
            }
            caretPos.current = adjustedCaretPos;

            onChange(event);
        };

        // on each render we set the correct caret position
        React.useEffect(() => {
            if (caretPos && caretPos.current) {
                /**
                 * Normal typing scenario: when number is autoformatted to add an extra space
                 * e.g. +1_604_ (formatted), the caret position should be added by 1
                 * We are comparing the prev value against the new value. Whenever a new space is added,
                 * the new value has the space & new character,
                 * so the length difference between the prevValue and updated value is 2.
                 * Then we add 1 in the caret position
                 *
                 * Also, when the user highlights text from start and begin typing, we need to add +1 as well to caret position.
                 * This is because the symbol "+" is being added to the beginning by PNI library
                 */
                if (value.length - prevValue.current.length === 2 || isHighlightingFromStart.current) {
                    ref?.current?.setSelectionRange(caretPos.current + 1, caretPos.current + 1);
                } else {
                    ref?.current?.setSelectionRange(caretPos.current, caretPos.current);
                }
            }
        });

        const helpText =
            errorMessage ||
            intl.formatMessage({
                id: 'error.validation.phoneNumber',
                defaultMessage: 'Please provide a valid phone number',
            });

        return (
            <Form.Item
                style={{ flex: '6' }} // fix for full width form item to match other inputs
                hasFeedback
                validateStatus={errorStatus}
                help={errorStatus === 'error' && helpText}
            >
                <Input
                    ref={ref}
                    {...props}
                    value={value}
                    // use keydown event, not keyup, to capture backspace and delete key state.
                    // This is to prepare states for logic inside "handleChange" and "re-render"
                    onKeyDown={handleKeyDown}
                    onChange={handleChange}
                    onSelect={handleSelect}
                    size={inputSettings?.inputSize}
                    type="tel"
                    autoComplete="tel"
                    data-testid="phone"
                    placeholder={intl.formatMessage({
                        id: 'welcome.general.phoneNumber',
                        defaultMessage: 'Phone Number',
                    })}
                    addonBefore={
                        inputSettings?.showPopOver ? (
                            <Popover
                                content={intl.formatMessage({
                                    id: 'welcome.general.phoneNumber',
                                    defaultMessage: 'Phone Number',
                                })}
                            >
                                <InfoCircleOutlined />
                            </Popover>
                        ) : undefined
                    }
                />
            </Form.Item>
        );
    }
);

const InternationalPhone = (props) => {
    const { value, onChange, countryOverride } = props;

    const country = useSelector(getCountry);
    const defaultCountry = countryOverride || `${country || 'US'}`.toUpperCase();

    return (
        <CustomPhoneInput
            {...props}
            international
            value={value}
            onChange={onChange}
            name="phone-input"
            inputComponent={InputComponent}
            defaultCountry={defaultCountry} // Default selection
            countryOptionsOrder={[defaultCountry, '|']} // Separate top level countries
            smartCaret={false} // turn it off as we have our custom solution to keep track of caret position
        />
    );
};
export default InternationalPhone;

export const CustomAntDMatchedInternationalPhone = (props) => {
    const { value, onChange, countryOverride } = props;

    const country = useSelector(getCountry);
    const defaultCountry = countryOverride || `${country || 'US'}`.toUpperCase();

    return (
        <CustomAntdMatchedPhoneInput
            {...props}
            international
            value={value}
            onChange={onChange}
            bordered={false}
            name="phone-input"
            inputComponent={InputComponent}
            defaultCountry={defaultCountry} // Default selection
            countryOptionsOrder={[defaultCountry, '|']} // Separate top level countries
            smartCaret={false} // turn it off as we have our custom solution to keep track of caret position
        />
    );
};
