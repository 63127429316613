import { get } from 'lodash';

import { STATEPATH } from './GuestActions';

const getLocalState = (state) => get(state, STATEPATH);

export const getIsFetching = (state) => getLocalState(state).isFetching;
export const getIsFetchingSilent = (state) => getLocalState(state).isFetchingSilent;
export const getForgotRequestSent = (state) => getLocalState(state).forgotRequestSent;
export const getResetRequestSent = (state) => getLocalState(state).resetRequestSent;
export const getReferenceQuestions = (state) => getLocalState(state).referenceQuestions;
export const submitReferenceQuestions = (state) => getLocalState(state).referenceQuestions;
export const getIsSubmitted = (state) => getLocalState(state).isSubmitted;
export const getTeam = (state) => getLocalState(state).team;
export const getTeamListings = (state) => getLocalState(state).teamListings;
export const getTeamProperties = (state) => getLocalState(state).teamProperties;
export const getBuilding = (state) => getLocalState(state).building;
export const getDispute = (state) => getLocalState(state).dispute;

// Error Selectors
export const getError = (state) => getLocalState(state).error;
