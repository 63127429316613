import { Menu } from 'antd';
import Constants from 'utils/constants';
import { FormattedMessage } from 'react-intl';
import { ActionsMenuItemsProps } from './types';
import { isTimestampInPast } from 'utils/adverseActionUtils';

export const ActionsMenuItems = ({ status, scheduledAtTimestamp }: ActionsMenuItemsProps) => {
    switch (status) {
        case Constants.adverseActionStatus.SCHEDULED:
            return (
                <>
                    <Menu.Item key="pause">
                        <FormattedMessage
                            id="AdverseAction.Drawer.StatusSection.PauseAA"
                            defaultMessage="Pause Adverse Action Process"
                        />
                    </Menu.Item>
                    <Menu.Item key="cancel" style={{ color: '#ff0000' }}>
                        <FormattedMessage
                            id="AdverseAction.Drawer.StatusSection.CancelAA"
                            defaultMessage="Cancel Adverse Action Process"
                        />
                    </Menu.Item>
                </>
            );
        case Constants.adverseActionStatus.PAUSED:
            return (
                <>
                    <Menu.Item key="resume">
                        <FormattedMessage
                            id="AdverseAction.Drawer.StatusSection.ResumeAA"
                            defaultMessage="Resume Adverse Action Process"
                        />
                    </Menu.Item>
                    <Menu.Item key="cancel" style={{ color: '#ff0000' }}>
                        <FormattedMessage
                            id="AdverseAction.Drawer.StatusSection.CancelAA"
                            defaultMessage="Cancel Adverse Action Process"
                        />
                    </Menu.Item>
                </>
            );
        case Constants.adverseActionStatus.CANCELLED:
            return (
                <>
                    {!isTimestampInPast(scheduledAtTimestamp) && (
                        <Menu.Item key="resume">
                            <FormattedMessage
                                id="AdverseAction.Drawer.StatusSection.ResumeAA"
                                defaultMessage="Resume Adverse Action Process"
                            />
                        </Menu.Item>
                    )}
                    <Menu.Item key="restart" style={{ color: '#ff0000' }}>
                        <FormattedMessage
                            id="AdverseAction.Drawer.StatusSection.RestartAA"
                            defaultMessage="Restart Adverse Action Process"
                        />
                    </Menu.Item>
                </>
            );
        case Constants.adverseActionStatus.IN_DISPUTE:
        case Constants.adverseActionStatus.UNDELIVERABLE:
            return (
                <>
                    <Menu.Item key="cancel" style={{ color: '#ff0000' }}>
                        <FormattedMessage
                            id="AdverseAction.Drawer.StatusSection.CancelAA"
                            defaultMessage="Cancel Adverse Action Process"
                        />
                    </Menu.Item>
                </>
            );
        case Constants.adverseActionStatus.COMPLETE:
        default:
            return null;
    }
};
