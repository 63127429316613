// Libraries
import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Row, Col } from 'antd';

// Components
import { AppearRight } from 'certn-ui/Animate';
import Text from 'certn-ui/Text';
import Title from 'certn-ui/Title';
import { TrackNavigation } from 'views/welcome/components';

const AuthorizationForm = ({ handleSubmit, fullName = 'The Applicant' }) => (
    <AppearRight>
        <Title>
            <FormattedMessage id="welcome.Authorization.title" defaultMessage="Authorization" />
        </Title>
        <Row>
            <Col lg={{ span: 14, offset: 5 }}>
                <Text align="left" color="certnGray600" size="xxxs">
                    <FormattedMessage
                        id="welcome.Authorization.explain1"
                        defaultMessage="I, {applicantName}, have carefully read and understand this Candidate Disclosure, Authorization & Consent for the Procurement of Consumer Reports form and the attached summary of rights under the Fair Credit Reporting Act. By clicking the SUBMIT APPLICATION button below, I authorize the company to share the contents of this consumer report or investigative consumer report with its partners and clients in an effort to place me into Applicant relationship with those partners. I understand that if the Company hires me, my consent will apply, and the Company may obtain reports, throughout my employment. I also understand that information contained in my job application or otherwise disclosed by me before or during my employment, if any, may be used for the purpose of obtaining consumer reports and/or investigative consumer reports. By clicking the SUBMIT APPLICATION button below, I authorize law enforcement agencies, learning institutions (including public and private schools and universities), information service bureaus, credit bureaus, record/data repositories, courts (federal, state and local), motor vehicle records agencies, my past or present employers, the military, and other individuals and sources to furnish any and all information on me that is requested by the consumer reporting agency. By clicking the SUBMIT APPLICATION button below, I certify the information I provided on this form is true and correct and will be valid for any reports that may be requested by or on behalf of the Company."
                        values={{ applicantName: fullName }}
                    />
                    <br />
                    <br />
                </Text>
            </Col>
        </Row>
        <TrackNavigation handleSubmit={handleSubmit} />
    </AppearRight>
);

export default injectIntl(AuthorizationForm);
