import { StaticResult } from '../AdvancedFilteringLayout';
import { FilterItem, FilterTitle } from '../styled';
import {
    getAdverseActionStatusMessage,
    AdverseActionStatus,
} from 'views/manager/views/hr/views/applications/views/applicationsView/utils';
import Constants from 'utils/constants';
import { FormattedMessage } from 'react-intl';

type AdverseActionFilterProps = {
    value?: string[];
    onChange: (value: string[]) => void;
};

export const AdverseActionFilter: React.FC<AdverseActionFilterProps> = ({ value, onChange }) => {
    const statusList = [
        {
            request: Constants.adverseActionStatus.CANCELLED,
            title: getAdverseActionStatusMessage(AdverseActionStatus.CANCELLED),
        },
        {
            request: Constants.adverseActionStatus.COMPLETE,
            title: getAdverseActionStatusMessage(AdverseActionStatus.COMPLETE),
        },
        {
            request: Constants.adverseActionStatus.IN_DISPUTE,
            title: getAdverseActionStatusMessage(AdverseActionStatus.IN_DISPUTE),
        },
        {
            request: Constants.adverseActionStatus.PAUSED,
            title: getAdverseActionStatusMessage(AdverseActionStatus.PAUSED),
        },
        {
            request: Constants.adverseActionStatus.SCHEDULED,
            title: getAdverseActionStatusMessage(AdverseActionStatus.SCHEDULED),
        },
        {
            request: Constants.adverseActionStatus.UNDELIVERABLE,
            title: getAdverseActionStatusMessage(AdverseActionStatus.UNDELIVERABLE),
        },
    ];
    return (
        <FilterItem>
            <FilterTitle>
                <FormattedMessage
                    id="adverseActionFilter.adverseActionStatus"
                    defaultMessage="Adverse Action Status:"
                />
            </FilterTitle>
            <StaticResult selected={value} handleChange={onChange}>
                {statusList}
            </StaticResult>
        </FilterItem>
    );
};
