// Libraries
import React from 'react';
import { get } from 'lodash';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import { Input, Popover } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';

// Components
import { TrackNavigation } from 'views/welcome/components';
import { InternationalPhone } from 'components';
import { AppearRight } from 'certn-ui/Animate';
import Title from 'certn-ui/Title';
import FormLayout from 'certn-form/FormLayout';

// Modules
import { Regex } from 'modules';

const NameAndNumberFormFields = ({ phone, errorStatus, getFieldDecorator, information, intl, config = null }) => {
    const fields = {
        first_name: () => (
            <Form.Item key="emergency_contact_first_name" hasFeedback>
                {getFieldDecorator('emergency_contact_first_name', {
                    initialValue: get(information, 'emergency_contact_first_name'),
                    rules: [
                        {
                            pattern: Regex.name,
                            message: intl.formatMessage({
                                id: 'error.validation.letters',
                                defaultMessage: 'Please only use letters',
                            }),
                        },
                        {
                            required: true,
                            message: intl.formatMessage({
                                id: 'error.validation.notBlank',
                                defaultMessage: 'Please do not leave blank',
                            }),
                        },
                    ],
                })(
                    <Input
                        autoFocus
                        size="large"
                        data-testid="emergency_contact_first_name"
                        placeholder={intl.formatMessage({
                            id: 'welcome.general.legalFirstName',
                            defaultMessage: 'Legal First Name',
                        })}
                        addonBefore={
                            <Popover
                                content={intl.formatMessage({
                                    id: 'welcome.general.legalFirstName',
                                    defaultMessage: 'Legal First Name',
                                })}
                            >
                                <InfoCircleOutlined />
                            </Popover>
                        }
                    />
                )}
            </Form.Item>
        ),
        last_name: () => (
            <Form.Item key="emergency_contact_last_name" hasFeedback>
                {getFieldDecorator('emergency_contact_last_name', {
                    initialValue: get(information, 'emergency_contact_last_name'),
                    rules: [
                        {
                            pattern: Regex.name,
                            message: intl.formatMessage({
                                id: 'error.validation.letters',
                                defaultMessage: 'Please only use letters',
                            }),
                        },
                        {
                            required: true,
                            message: intl.formatMessage({
                                id: 'error.validation.notBlank',
                                defaultMessage: 'Please do not leave blank',
                            }),
                        },
                    ],
                })(
                    <Input
                        size="large"
                        data-testid="emergency_contact_last_name"
                        placeholder={intl.formatMessage({
                            id: 'welcome.general.legalLastName',
                            defaultMessage: 'Legal Last Name',
                        })}
                        addonBefore={
                            <Popover
                                content={intl.formatMessage({
                                    id: 'welcome.general.legalLastName',
                                    defaultMessage: 'Legal Last Name',
                                })}
                            >
                                <InfoCircleOutlined />
                            </Popover>
                        }
                    />
                )}
            </Form.Item>
        ),
        email: () => (
            <Form.Item key="emergency_contact_email" hasFeedback>
                {getFieldDecorator('emergency_contact_email', {
                    initialValue: get(information, 'emergency_contact_email'),
                    rules: [
                        {
                            pattern: Regex.email,
                            message: intl.formatMessage({
                                id: 'error.validation.letters',
                                defaultMessage: 'Please only use letters',
                            }),
                        },
                    ],
                })(
                    <Input
                        size="large"
                        data-testid="email"
                        placeholder={intl.formatMessage({ id: 'common.email', defaultMessage: 'Email' })}
                        addonBefore={
                            <Popover content={intl.formatMessage({ id: 'common.email', defaultMessage: 'Email' })}>
                                <InfoCircleOutlined />
                            </Popover>
                        }
                    />
                )}
            </Form.Item>
        ),
        phone: () => (
            <InternationalPhone key="int-phone" value={phone.num} errorStatus={errorStatus} onChange={phone.update} />
        ),
    };
    return config ? config.map((item) => fields[item]()) : Object.values(fields).map((item) => item());
};

const NameAndNumberForm = ({
    handleSubmit,
    form: { getFieldDecorator, validateFields, getValueFromEvent },
    form,
    information,
    intl,
    phone,
    errorStatus,
}) => (
    <AppearRight>
        <Form layout="horizontal" onSubmit={(e) => handleSubmit(e, validateFields)}>
            <Title>
                <FormattedMessage
                    id="welcome.EmergencyContact.title"
                    defaultMessage="An Emergency Contact is required to complete the application."
                />
            </Title>
            <FormLayout>
                {NameAndNumberFormFields({
                    form,
                    getFieldDecorator,
                    getValueFromEvent,
                    information,
                    intl,
                    phone,
                    errorStatus,
                })}
            </FormLayout>
            <TrackNavigation />
        </Form>
    </AppearRight>
);

export default Form.create()(injectIntl(NameAndNumberForm));
