// TODO: Export each method individually.
import Cookies from 'js-cookie';
import { get } from 'lodash';

class Auth {
    /**
     * Authenticate a user. Save a token string in Cookies
     *
     * @param response
     */
    static authenticateUser(response) {
        Cookies.set('token', response.token, { expires: 1 });
        Cookies.set('permission_level', response.user.permission_level, { expires: 1 });
        Cookies.set('is_applicant', response.user.is_applicant, { expires: 1 });
        Cookies.set('is_manager', response.user.is_manager, { expires: 1 });
        Cookies.set('is_hr_user', response.user.is_hr_user, { expires: 1 });
        Cookies.set('is_adjudicator', response.user.is_adjudicator, { expires: 1 });
        Cookies.set('is_user', response.user.is_user, { expires: 1 });
        Cookies.set('is_kyc', response.user.is_kyc, { expires: 1 });
        Cookies.set('is_staff', response.user.is_staff, { expires: 1 });
        Cookies.set('userId', response.user.id, { expires: 1 });
        Cookies.set('email', response.user.email, { expires: 1 });
        Cookies.set('is_new', response.user.is_new, { expires: 1 });
        Cookies.set('team_id', get(response, ['user', 'team', 'id']), { expires: 1 });
        Cookies.set('team_type', get(response, ['user', 'team', 'billing_plan', 'plan_type']), { expires: 1 });
        Cookies.set('can_initiate_adverse_action', response.user.can_initiate_adverse_action, { expires: 1 });
    }

    /**
     * Deauthenticate a user. Remove a token from Cookies.
     *
     */
    static deauthenticateUser() {
        Cookies.remove('token', '');
        Cookies.remove('is_applicant', '');
        Cookies.remove('is_user', '');
        Cookies.remove('is_manager', '');
        Cookies.remove('is_hr_user', '');
        Cookies.remove('is_adjudicator', '');
        Cookies.remove('is_kyc', '');
        Cookies.remove('is_staff', '');
        // Cookies.remove('current_view', '');
        Cookies.remove('fb_status', '');
        Cookies.remove('fb_uid', '');
        Cookies.remove('fb_token', '');
        Cookies.remove('userId', '');
        Cookies.remove('email', '');
        Cookies.remove('is_new', '');
        Cookies.remove('team_id', '');
        Cookies.remove('team_type', '');
        Cookies.remove('can_view_adverse_action', '');
        Cookies.remove('can_initiate_adverse_action', '  ');
    }

    /**
     * Check if a user is authenticated - check if a token is saved in Cookies
     *
     * @returns {boolean}
     */
    static isUserAuthenticated() {
        return Boolean(Cookies.get('token'));
    }

    /**
     * Authenticate an applicant. Save a token string in Cookies
     *
     * @param response
     */
    static authenticateApplicant(response) {
        response.user
            ? Cookies.set('applicant_email', response.user.email, { expires: 1 })
            : Cookies.set('applicant_email', response.applicant_account.email, { expires: 1 });
        Cookies.set('applicant_token', response.token, { expires: 1 });
    }

    /**
     * Check if an applicant is authenticated - check if a token is saved in Cookies
     *
     * @returns {boolean}
     */
    static isApplicantAuthenticated() {
        return Boolean(Cookies.get('applicant_token'));
    }

    /**
     * Deauthenticate an applicant. Remove a token from Cookies.
     *
     */
    static deauthenticateApplicant() {
        Cookies.remove('applicant_token', '');
        Cookies.remove('applicant_email', '');
        // clear cookies related to session to break it when logging out (applicant login)
        Cookies.remove('onboardingId', '');
        Cookies.remove('listingUrlCode', '');
    }

    static isPermissionLevel(level) {
        const permissionLevel = Cookies.get('permission_level');
        const dict = {
            staff: 4,
            admin: 3,
            manager: 2,
            user: 1,
            applicant: 0,
        };
        if (typeof level === 'number') {
            if (permissionLevel >= level) return true;
            return false;
        }
        if (permissionLevel >= dict[level]) return true;
        return false;
    }

    static isNotBasic() {
        return Cookies.get('team_type') !== 'BASIC';
    }

    static isUser() {
        if (Cookies.get('is_user') === 'true') {
            return true;
        }
        return false;
    }

    static isAdjudicatorUser() {
        if (Cookies.get('is_adjudicator') === 'true') {
            return true;
        }
        return false;
    }

    static isHrUser() {
        if (Cookies.get('is_hr_user') === 'true') {
            return true;
        }
        return false;
    }

    static isManager() {
        if (Cookies.get('is_manager') === 'true') {
            return true;
        }
        return false;
    }

    static isApplicant() {
        if (Cookies.get('is_applicant') === 'true') {
            return true;
        }
        return false;
    }

    static isStaff() {
        if (Cookies.get('is_staff') === 'true') {
            return true;
        }
        return false;
    }

    static isAdverseActionUser() {
        if (Cookies.get('can_initiate_adverse_action') === 'true') {
            return true;
        }
        return false;
    }

    /**
     * Get a token value.
     *
     * @returns {string}
     */

    static getAllCookies() {
        return Cookies.get();
    }

    static getToken() {
        return Cookies.get('token');
    }

    static getApplicantToken() {
        return Cookies.get('applicant_token');
    }

    static getEmail() {
        return Cookies.get('email');
    }

    static getUserID() {
        return Cookies.get('userId');
    }

    static setCurrentView(view) {
        Cookies.set('current_view', view);
    }

    static getCurrentView() {
        return Cookies.get('current_view');
    }
}

export default Auth;
