// Libraries
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import { Input, Popover, Row, Select } from 'antd';
import { snakeCase } from 'lodash';

// Components
import { AppearRight } from 'certn-ui/Animate';
import Title from 'certn-ui/Title';
import { TrackNavigation } from 'views/welcome/components';
import FormLayout from 'certn-form/FormLayout';
import UploadRow from 'views/welcome/components/UploadRow';
import { ProvStateSelect } from 'components/index';
import DateInput from 'certn-form/DateInput';

// Modules
import { Regex } from 'modules';
import Countries from 'modules/Countries';

const PassportForm = ({
    handleSubmit,
    form: { getFieldDecorator, validateFields },
    intl,
    documentNumber,
    documentExpiration,
    documentIssuingCountry,
    documentIssuingProvinceState,
    s3GetUploadLink,
    handleDeleteDocument,
    s3UploadLink,
    s3UploadFields,
    file,
    onUpload,
    error,
    handleProgress,
    fileProgress,
    uploadComplete,
    missing,
    form,
    orgCountry,
    enhancedID,
}) => {
    const handleCountryChange = () => form.setFieldsValue({ document_issuing_province_state: undefined });

    const isPassportPrefilled = enhancedID.document_type === 'PASSPORT';
    const disabledFields = isPassportPrefilled
        ? {
              fileUpload: !missing,
              document_issuing_country: enhancedID.issuing_country && documentIssuingCountry,
              document_expiration: enhancedID.expiry_date && documentExpiration,
              document_number: enhancedID.document_number && documentNumber,
          }
        : {};

    const provinceLabel = intl.formatMessage({
        id: 'welcome.Passport.provState',
        defaultMessage: 'Issuing Province / State',
    });

    return (
        <AppearRight>
            <Form layout="horizontal" onSubmit={(e) => handleSubmit(e, validateFields)}>
                <Title>
                    <FormattedMessage
                        id="welcome.Passport.title"
                        defaultMessage="Please upload a photo of the document page of your Passport or a photo of your national ID document. The image must be high quality, unobstructed, and uncropped."
                    />
                </Title>
                <Row gutter={16}>
                    <UploadRow.UploadsWrapper>
                        <UploadRow.ReqUploadBox error={error}>
                            <UploadRow
                                doc="PASSPORT"
                                name="ID Image"
                                file={file}
                                s3UploadLink={s3UploadLink}
                                s3UploadFields={s3UploadFields}
                                s3GetUploadLink={s3GetUploadLink}
                                onUpload={onUpload}
                                handleDeleteDocument={handleDeleteDocument}
                                error={error}
                                handleProgress={handleProgress}
                                fileProgress={fileProgress}
                                uploadComplete={uploadComplete}
                                missing={missing}
                                disabled={disabledFields.fileUpload}
                                hideRemove={disabledFields.fileUpload}
                            />
                        </UploadRow.ReqUploadBox>
                    </UploadRow.UploadsWrapper>
                </Row>
                <FormLayout>
                    <Form.Item>
                        {getFieldDecorator('document_issuing_country', {
                            initialValue: documentIssuingCountry || undefined,
                            rules: [
                                {
                                    required: true,
                                    type: 'string',
                                    message: intl.formatMessage({
                                        id: 'error.validation.countryError',
                                        defaultMessage: 'Select a country!',
                                    }),
                                },
                            ],
                        })(
                            <Select
                                data-testid="issuing_country"
                                size="large"
                                placeholder={intl.formatMessage({
                                    id: 'welcome.Passport.country',
                                    defaultMessage: 'Issuing Country',
                                })}
                                autoComplete="dont-use-autocomplete"
                                showSearch
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                onChange={handleCountryChange}
                                disabled={disabledFields.document_issuing_country}
                            >
                                {Countries.all.map((country, index) => (
                                    <Select.Option
                                        data-testid={snakeCase(country.name)}
                                        key={`${country.alpha2}-${index}`}
                                        value={country.alpha2}
                                    >
                                        {country.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('document_issuing_province_state', {
                            initialValue: documentIssuingProvinceState || undefined,
                            rules: [
                                {
                                    required: true,
                                    message: intl.formatMessage({
                                        id: 'error.validation.provinceStateError',
                                        defaultMessage: 'Select a province or state!',
                                    }),
                                },
                                {
                                    pattern: Regex.city,
                                    message: intl.formatMessage({
                                        id: 'error.validation.letters',
                                        defaultMessage: 'Please only use letters',
                                    }),
                                },
                            ],
                        })(
                            <ProvStateSelect
                                size="large"
                                selectedCountry={form.getFieldValue('document_issuing_country')}
                                placeholder={provinceLabel}
                                addonBefore={
                                    <Popover content={provinceLabel}>
                                        <InfoCircleOutlined />
                                    </Popover>
                                }
                                disabled={disabledFields.document_issuing_province_state}
                            />
                        )}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('document_expiration', {
                            initialValue: documentExpiration || undefined,
                            validateTrigger: 'onChange',
                            rules: [
                                {
                                    required: true,
                                    message: intl.formatMessage({
                                        id: 'error.validation.passport.expiration',
                                        defaultMessage: 'Please provide a valid expiration date.',
                                    }),
                                },
                            ],
                        })(
                            <DateInput
                                size="large"
                                disablePastDates
                                placeholder={intl.formatMessage({
                                    id: 'welcome.Passport.documentExpiration',
                                    defaultMessage: 'Expiration Date',
                                })}
                                addonBeforeUsePlaceholder
                                country={orgCountry}
                                disabled={disabledFields.document_expiration}
                            />
                        )}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('document_number', {
                            initialValue: documentNumber || undefined,
                            validateTrigger: 'onBlur',
                            rules: [
                                {
                                    required: true,
                                    pattern: Regex.alphaNumeric,
                                    message: intl.formatMessage({
                                        id: 'error.validation.passport.number',
                                        defaultMessage: 'Please provide a valid ID number',
                                    }),
                                },
                            ],
                        })(
                            <Input
                                size="large"
                                placeholder={intl.formatMessage({
                                    id: 'welcome.Passport.number',
                                    defaultMessage: 'ID Number',
                                })}
                                addonBefore={
                                    <Popover
                                        content={
                                            <FormattedMessage id="welcome.Passport.number" defaultMessage="ID Number" />
                                        }
                                    >
                                        <InfoCircleOutlined />
                                    </Popover>
                                }
                                disabled={disabledFields.document_number}
                            />
                        )}
                    </Form.Item>
                </FormLayout>
                <TrackNavigation loading={fileProgress.PASSPORT !== 0 && fileProgress.PASSPORT !== 100} />
            </Form>
        </AppearRight>
    );
};

export default Form.create()(injectIntl(PassportForm));
