// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Button, Table, Select } from 'antd';
import { FormattedMessage } from 'react-intl';

// Components
import Loader from 'certn-ui/Loader';
import Typography from 'certnd/Typography';

// Actions & Selectors
import { getIsFetching } from 'views/welcome/WelcomeSelectors';
import { getUserModeIsPM } from 'base/BaseSelectors';

const { Heading } = Typography;
const { Option } = Select;

ReferencesTable.propTypes = {
    templates: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
};
ReferencesTable.defaultProps = {};

function ReferencesTable(props) {
    const { templates, columns, addTemplate } = props;
    const isFetching = useSelector(getIsFetching);
    const userModeIsPM = useSelector(getUserModeIsPM);

    return (
        <>
            <Heading.H1 data-testid="title" style={{ display: 'inline-block' }}>
                <FormattedMessage id="da4a2.ReferenceTable.PageTitle" defaultMessage="Reference Templates" />
            </Heading.H1>
            <Button style={{ float: 'right' }} type="primary" onClick={addTemplate}>
                <FormattedMessage id="da4a2.ReferenceTable.AddTemplate" defaultMessage="ADD TEMPLATE" />
            </Button>
            {userModeIsPM && (
                <Select
                    defaultValue={props.type}
                    style={{ width: 105, float: 'right', marginRight: '5px' }}
                    onChange={props.handleChangeType}
                >
                    <Option value="address">
                        <FormattedMessage id="da4a2.ReferenceTable.Tenancy" defaultMessage="Tenancy" />
                    </Option>
                    <Option value="employer">
                        <FormattedMessage id="da4a2.ReferenceTable.Employer" defaultMessage="Employer" />
                    </Option>
                </Select>
            )}
            {isFetching ? (
                <Loader />
            ) : templates && templates.length > 0 ? (
                <Table rowKey={(record) => record.id} columns={columns} dataSource={templates} />
            ) : (
                <div>
                    <FormattedMessage
                        id="da4a2.ReferenceTable.NoTemplates"
                        defaultMessage="This team currently has no reference templates"
                    />
                </div>
            )}
        </>
    );
}

export default ReferencesTable;
