import NProgress from 'nprogress';

NProgress.configure({ showSpinner: false });

const Progress = (function () {
    let numRequests = 0;
    let maxRequests = 0;

    return {
        addRequest() {
            if (process.env.NODE_ENV === 'test') return;
            numRequests += 1;
            if (numRequests <= 1) {
                NProgress.start();
                NProgress.set(0.2);
            }
            if (numRequests > maxRequests) {
                maxRequests = numRequests;
            }
        },
        subRequest() {
            if (process.env.NODE_ENV === 'test') return;
            numRequests -= 1;
            if (numRequests === 0) {
                NProgress.done();
                maxRequests = 0;
            } else {
                NProgress.inc();
            }
        },
    };
})();

export default Progress;
