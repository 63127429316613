import React from 'react';
import styled from 'styled-components/macro';

import { Motion, spring } from 'react-motion';

const Animate = ({ children }) => (
    <Motion defaultStyle={{ opacity: 0 }} style={{ opacity: spring(1) }}>
        {(style) => <AnimateContainer style={{ opacity: style.opacity }}>{children}</AnimateContainer>}
    </Motion>
);
export default Animate;

export const Fade = ({ children }) => (
    <Motion defaultStyle={{ opacity: 0 }} style={{ opacity: spring(1) }}>
        {(style) => <AnimateContainer style={{ opacity: style.opacity }}>{children}</AnimateContainer>}
    </Motion>
);

export const FadeExpand = ({ children }) => (
    <Motion defaultStyle={{ opacity: 0, scale: 0 }} style={{ opacity: spring(1), scale: spring(1) }}>
        {(style) => (
            <AnimateContainer style={{ opacity: style.opacity, transform: `scaleX(${style.scale})` }}>
                {children}
            </AnimateContainer>
        )}
    </Motion>
);

export const AppearRight = ({ children, distance = 75 }) => (
    <Motion defaultStyle={{ opacity: 0, translateX: distance }} style={{ opacity: spring(1), translateX: spring(0) }}>
        {(style) => (
            <AnimateContainer style={{ opacity: style.opacity, transform: `translateX(${style.translateX}%)` }}>
                {children}
            </AnimateContainer>
        )}
    </Motion>
);

export const AppearTop = ({ children, short = false }) => (
    <Motion
        defaultStyle={{ opacity: 0, translateY: short ? -25 : -75 }}
        style={{ opacity: spring(1), translateY: spring(0), marginBottom: 0 }}
    >
        {(style) => (
            <AnimateContainer
                style={{ marginBottom: 0, opacity: style.opacity, transform: `translateY(${style.translateY}%)` }}
            >
                {children}
            </AnimateContainer>
        )}
    </Motion>
);

export const ComplexTransformExample = ({ children }) => (
    <Motion
        defaultStyle={{ opacity: 0, x: 0, y: 75, z: -90 }}
        style={{ opacity: spring(1), x: spring(1), y: spring(0), z: spring(0) }}
    >
        {({ opacity, x, y, z }) => (
            <AnimateContainer
                style={{
                    opacity,
                    transform: `scaleX(${x}) rotateY(${z}deg) translateX(${y}%)`,
                    WebkitTransform: `scaleX(${x}) rotateY(${z}deg) translateX(${y}%)`,
                }}
            >
                {children}
            </AnimateContainer>
        )}
    </Motion>
);

const AnimateContainer = styled.div`
    width: 100%;
    flex: 1 0 0%;
    display: block;
    flex-direction: column;
`;
