import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import Notification, { ButtonRow as NotificationsButtonRow } from 'certn-ui/Notification';
import { BellOutlined } from '@ant-design/icons';
import { Dropdown, Button } from 'antd';
import Badge from 'certn-ui/Badge';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

// Actions & Selectors
import { getDropdownNotifications } from 'base/BaseSelectors';

const MarkAsSeen = styled.a`
    display: flex;
    cursor: pointer;
    z-index: 99999;
`;

DropdownWrapper.propTypes = {};

DropdownWrapper.defaultProps = {};

function DropdownWrapper({ setNotificationViewed, history }) {
    const notifications = useSelector(getDropdownNotifications) || [];
    const notViewedCount = notifications?.filter((notification) => !notification.is_viewed).length;
    return (
        <Dropdown
            overlay={
                <NotificationList>
                    <Header>
                        <HeaderTitle>
                            <FormattedMessage
                                id="807df.DropdownWrapper.notificationsHeaderTitle"
                                defaultMessage="Notifications"
                            />
                        </HeaderTitle>
                        <MarkAsSeen onClick={() => setNotificationViewed()}>
                            <FormattedMessage
                                id="807df.DropdownWrapper.notificationsHeaderMarkAsSeen"
                                defaultMessage="Mark all as seen"
                            />
                        </MarkAsSeen>
                    </Header>
                    {notifications.map(
                        (notification) =>
                            !notification.is_viewed && (
                                <Notification
                                    key={notification.id}
                                    notification={notification}
                                    seen={(e) => {
                                        e.stopPropagation();
                                        setNotificationViewed(notification.id);
                                    }}
                                    onClick={() => {
                                        setNotificationViewed(notification.id);
                                    }}
                                />
                            )
                    )}
                    {notifications.length === 0 && (
                        <NotificationsButtonRow>
                            <FormattedMessage
                                id="807df.DropdownWrapper.caughtUp"
                                defaultMessage="You're all caught up!"
                            />
                        </NotificationsButtonRow>
                    )}
                    <NotificationsButtonRow>
                        <Button type="default" onClick={() => history.push('/notifications/list')}>
                            <FormattedMessage id="807df.DropdownWrapper.viewAllButton" defaultMessage="View All" />
                        </Button>
                    </NotificationsButtonRow>
                </NotificationList>
            }
            placement="bottomRight"
            trigger={['click']}
            overlayStyle={{ background: 'white' }}
        >
            <Button shape="circle" type="normal" size="large">
                <Badge count={notViewedCount}>
                    <BellOutlined style={{ fontSize: '24px' }} />
                </Badge>
            </Button>
        </Dropdown>
    );
}

export default withRouter(DropdownWrapper);

export const NotificationList = styled.div`
    width: 360px;
    max-width: 100%;
    border-top: 0px solid ${(props) => props.theme.color.primary};
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    max-height: 90vh;
    overflow-y: scroll;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 6px, rgba(0, 0, 0, 0.2) 0px 1px 4px;
    margin-top: 8px;
    z-index: 99999;

    @media (max-width: ${({ theme }) => theme.width.xxs}) {
        width: 100vw;
    }
`;

export const Header = styled.div`
    padding: 10px;
    border-bottom: 1px solid ${(props) => props.theme.color.certnGray400};
    display: flex;
    justify-content: space-between;
`;

export const HeaderTitle = styled.div`
    font-weight: bold;
`;
