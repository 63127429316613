import React from 'react';
import styled from 'styled-components/macro';
import moment from 'moment';
import { useSelector } from 'react-redux';

import { Input, Popover } from 'antd';
import { FormattedMessage } from 'react-intl';
import { intl } from 'components/GlobalProvider';
import { getLaunchDarklyFlags } from 'base/BaseSelectors';

// UI Components
import { AppearTop } from 'certn-ui/Animate';
import Text from 'certn-ui/Text';
import Button from 'certn-ui/Button';
import CertnScore from 'certn-ui/CertnScore';
import { ApplicationStatus } from 'certn-ui';

const SectionWrapper = styled.div`
    padding: 8px;
    padding-bottom: 5px;
    display: flex;
    flex-direction: column;
    border-bottom: 0;
    white-space: nowrap;
    overflow: hidden;
    :not(:last-child) {
        margin-bottom: 5px;
    }
`;

const SectionTitle = styled.div`
    width: 100%;
    padding-left: 7px;
    margin-bottom: 6px;
    color: ${(props) => props.theme.color.certnGray400};
`;

const EntriesContainer = styled.div`
    display: flex;
    padding-left: 10px;
    flex-direction: column;
`;

const EntryWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 5px;
    margin-bottom: 8px;
    cursor: ${(props) => (props.active || !props.ready ? 'default' : 'pointer')};
    font-weight: 600;
    color: ${(props) => (props.active ? props.theme.color.certnGray600 : props.theme.color.certnGray400)};
    &:hover {
        ${({ active, ready, theme }) => !active && ready && `color: ${theme.color.certnGreen700}`};
    }
`;

const EntryLeft = styled.div`
    display: flex;
    flex-direction: column;
`;

const EntryRight = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const EntryName = styled.span``;

const CommentsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
`;

const CommentsContainer = styled.div`
    width: 200px;
    padding: 2px;
`;

const CommentWrapper = styled.div`
    width: 200px;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
`;

const Commenter = styled.div`
    font-size: 12px;
    color: ${(props) => props.theme.color.certnGray400};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const CommentSeparator = styled.div`
    width: 240px;
    border: 0.5px solid ${({ theme }) => theme.color.certnGray200};
    margin: 15px 0;
`;

const CommentInputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 200px;
    margin-top: 5px;
`;

const InfoBar = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    color: ${({ theme }) => theme.color.certnGray500};
    font-weight: 600;
    padding-left: 16px;
    padding-right: 10px;
    padding-bottom: 20px;
    white-space: nowrap;
    overflow: hidden;
`;

const isolateReportId = (reportUrl) => {
    const re = new RegExp('applications?.*');
    return reportUrl.match(re) && reportUrl.match(re)[0].split('/')[1];
};

const ApplicantsSection = ({ data, title, activeReport, showSubReport }) => (
    <SectionWrapper>
        <SectionTitle>{title}</SectionTitle>
        <EntriesContainer>
            {Array.isArray(data) &&
                data.map((entry, index) => (
                    <Entry key={index} data={entry} activeReport={activeReport} showSubReport={showSubReport} />
                ))}
        </EntriesContainer>
    </SectionWrapper>
);

const CommentsSection = ({ comments, handleUpdateComment, patchComments, commentInput }) => (
    <CommentsWrapper>
        <CommentInputWrapper>
            <Input.TextArea
                data-testid="comment-text-area"
                placeholder={intl.formatMessage({
                    id: 'f10f2.SideBar.commentPlaceholder',
                    defaultMessage: 'Add a comment',
                })}
                autosize={{ minRows: 3, maxRows: 5 }}
                style={{ marginBottom: '10px' }}
                onChange={handleUpdateComment}
                value={commentInput}
            />
            <Button
                disabled={commentInput === ''}
                type="primary"
                certncolor={commentInput === '' ? 'certnGray400' : 'certnGreen700'}
                loading={false}
                onClick={patchComments}
            >
                <FormattedMessage id="f10f2.SideBar.addComment" defaultMessage="ADD COMMENT" />
            </Button>
        </CommentInputWrapper>
        <CommentSeparator />
        {comments && comments.length > 0 && (
            <CommentsContainer>
                {comments.map(({ owner, created, text, date = moment(created).format('ddd, MMMM Do YYYY') }) => (
                    <AppearTop short key={owner + created} style={{ marginBottom: '0 !important' }}>
                        <CommentWrapper>
                            {owner.email && owner.email.length > 35 ? (
                                <Popover content={<div>{owner.email}</div>}>
                                    <Commenter>{owner.email}</Commenter>
                                </Popover>
                            ) : (
                                <Commenter>{owner.email}</Commenter>
                            )}
                            <Commenter>
                                {date ===
                                intl.formatMessage({
                                    id: 'f10f2.SideBar.inAFewSeconds',
                                    defaultMessage: 'in a few seconds',
                                }) ? (
                                    <FormattedMessage id="f10f2.SideBar.justNow" defaultMessage="just now" />
                                ) : (
                                    date
                                )}
                            </Commenter>
                            <Text size="xxs" weight="500" style={{ width: '200px' }}>
                                {text}
                            </Text>
                        </CommentWrapper>
                    </AppearTop>
                ))}
            </CommentsContainer>
        )}
    </CommentsWrapper>
);

const IsReady = (data) => {
    // LD Flag: webFeatureDeprecateNumericScoring
    const isDeprecateNumericScoring = useSelector(getLaunchDarklyFlags)?.webFeatureDeprecateNumericScoring;
    const isReturned =
        data.status === intl.formatMessage({ id: 'f10f2.SideBar.returnedStatus', defaultMessage: 'Returned' });
    const isAnalyzing =
        data.status === intl.formatMessage({ id: 'f10f2.SideBar.analyzingStatus', defaultMessage: 'Analyzing' });
    if (isReturned) {
        return true;
    }
    if (isAnalyzing) {
        if (isDeprecateNumericScoring) {
            return true;
        }
        return data.certn_score !== null;
    }
    return false;
};

const Entry = ({ data, activeReport, showSubReport }) => {
    // LD Flag: webFeatureDeprecateNumericScoring
    const isDeprecateNumericScoring = useSelector(getLaunchDarklyFlags)?.webFeatureDeprecateNumericScoring;
    const reportId = isolateReportId(data.report_url);
    const ready = IsReady(data);
    const pending = data.status === intl.formatMessage({ id: 'status.pending', defaultMessage: 'Pending' });
    const active = reportId === activeReport;
    return (
        <EntryWrapper active={active} ready={ready} onClick={() => active || (ready && showSubReport(reportId))}>
            <EntryLeft>
                <EntryName>
                    {pending ? (
                        <FormattedMessage id="status.pending" defaultMessage="Pending" />
                    ) : (
                        `${data.first_name} ${data.last_name}`
                    )}
                </EntryName>
                <ApplicationStatus sideBar application={data} />
            </EntryLeft>
            <EntryRight>
                <CertnScore
                    score={
                        // LD Flag: webFeatureDeprecateNumericScoring
                        isDeprecateNumericScoring ? data.report_summary?.report_result_label : data.certn_score
                    }
                    full="true"
                    size={40}
                    active={!active && ready}
                    scoreLabel={data.report_summary && data.report_summary.report_result}
                    margin="0px"
                    flow="PM"
                />
            </EntryRight>
        </EntryWrapper>
    );
};

const SideBar = {
    InfoBar,
    ApplicantsSection,
    CommentsSection,
};

export default SideBar;
