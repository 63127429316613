import { get } from 'lodash';

import { STATEPATH } from 'views/reference/ReferenceActions';

const getLocalState = (state) => get(state, STATEPATH);

export const getIsFetching = (state) => getLocalState(state).isFetching;
export const getReferenceQuestions = (state) => getLocalState(state).referenceQuestions;
export const getError = (state) => getLocalState(state).error;
export const getIsSubmitted = (state) => getLocalState(state).isSubmitted;
