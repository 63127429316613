import React from 'react';
import styles from 'styles/styles';

const Refusal = ({ scale }) => (
    <svg width={49} height={68} style={{ transform: `scale3d(${scale}, ${scale}, ${scale})` }}>
        <title>bribe_dollars_corruption_money_pay</title>
        <g
            transform="translate(0 1)"
            stroke={styles.color.certnGreen700}
            strokeWidth={1.525}
            fill="none"
            fillRule="evenodd"
        >
            <g transform="translate(16)">
                <path d="M.762 43.908V.573h31.312v55.185h-20.56" />
                <path d="M15.285 49.962h7.014a3.952 3.952 0 0 1 3.951-3.952V10.32a3.952 3.952 0 0 1-3.951-3.952H10.537a3.953 3.953 0 0 1-3.953 3.952v27.622" />
                <ellipse cx={16.418} cy={28.166} rx={5.219} ry={4.848} />
            </g>
            <path
                d="M10.615 51.027L22.233 38.56c1.985-2.051 5.087-2.457 6.892-.902 1.807 1.556 1.975 4.763.377 7.127L14.906 66.426"
                strokeLinejoin="round"
            />
            <path d="M16.401 29.221S1.346 42.023 1.004 44.023c-.28 1.625.272 16.555.488 22.356" />
            <circle fill={styles.color.certnGreen700} fillRule="nonzero" cx={32.415} cy={14.392} r={1} />
        </g>
    </svg>
);

export default Refusal;
