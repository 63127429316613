import { Form, Input, Popover, Radio } from 'antd';
import { FormProps } from 'antd/lib/form';
import PropTypes from 'prop-types';
import { FormLayout } from 'certn-form';
import Title from 'certn-ui/Title';
import { RadioChangeEvent } from 'antd/lib/radio';
import { FormattedMessage, useIntl } from 'react-intl';
import { TrackNavigation } from 'views/welcome/components';
import { useSelector } from 'react-redux';
import { getInformation } from 'views/welcome/WelcomeSelectors';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { YesNoNull } from 'types/types';
import DBSFieldHeading from 'views/welcome/components/DBSFieldHeading';
import Subtitle from 'views/welcome/components/Subtitle';
import { boolToYesNo } from 'views/welcome/yesNoHelpers';

export interface DBSProfileNumberFormValues {
    dbs_profile_number_exists_yesno: YesNoNull;
    dbs_profile_number?: string;
}

interface Props extends FormProps<DBSProfileNumberFormValues> {
    handleSubmit: (values: DBSProfileNumberFormValues) => void;
}

function ProfileNumber() {
    const intl = useIntl();
    const information = useSelector(getInformation);

    return (
        <>
            <DBSFieldHeading style={{ paddingBottom: '16px' }}>
                <FormattedMessage id="welcome.DBSProfileNumber.numberLabel" defaultMessage="Enter DBS Profile Number" />
            </DBSFieldHeading>
            <Form.Item
                name="dbs_profile_number"
                initialValue={information.dbs_profile_number}
                rules={[
                    {
                        required: true,
                        message: intl.formatMessage({
                            id: 'error.validation.notBlank',
                            defaultMessage: 'Please do not leave blank',
                        }),
                    },
                ]}
            >
                <Input
                    size="large"
                    placeholder="DBS Profile Number"
                    addonBefore={
                        <Popover content="DBS Profile Number">
                            <InfoCircleOutlined />
                        </Popover>
                    }
                />
            </Form.Item>
        </>
    );
}

export const DBSProfileNumberForm = (props: Props) => {
    const [form] = Form.useForm<DBSProfileNumberFormValues>();
    const information = useSelector(getInformation);
    const [dbsProfileNumberExists, setDbsProfileNumberExists] = useState(
        boolToYesNo(information.dbs_profile_number_exists)
    );

    const onChangeRadio = (evt: RadioChangeEvent) => setDbsProfileNumberExists(evt.target.value);

    return (
        <Form<DBSProfileNumberFormValues> layout="horizontal" form={form} {...props} onFinish={props.handleSubmit}>
            <Title>
                <FormattedMessage id="welcome.DBSProfileNumber.title" defaultMessage="DBS Profile Number" />
            </Title>
            <Subtitle>
                <FormattedMessage
                    id="welcome.DBSProfileNumber.subtitle"
                    defaultMessage="If you already have a DBS online account you will have a DBS Profile Number. If so, please select “yes” and enter your number, otherwise leave as “no”"
                />
            </Subtitle>
            <FormLayout>
                <>
                    <DBSFieldHeading>
                        <FormattedMessage
                            id="welcome.DBSProfileNumber.consentLabel"
                            defaultMessage="Do you have a DBS Profile Number?"
                        />
                    </DBSFieldHeading>
                    <Form.Item
                        name="dbs_profile_number_exists_yesno"
                        initialValue={boolToYesNo(information.dbs_profile_number_exists)}
                        rules={[{ required: true, message: 'Please select an option!' }]}
                    >
                        <Radio.Group
                            onChange={onChangeRadio}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'start',
                                alignItems: 'start',
                            }}
                        >
                            <Radio value="YES" style={{ paddingBottom: '25px' }}>
                                <FormattedMessage id="welcome.DBSProfileNumber.Yes" defaultMessage="Yes" />
                            </Radio>
                            <Radio value="NO">
                                <FormattedMessage id="welcome.DBSProfileNumber.No" defaultMessage="No" />
                            </Radio>
                        </Radio.Group>
                    </Form.Item>

                    {dbsProfileNumberExists === 'YES' && <ProfileNumber />}
                </>
            </FormLayout>
            <TrackNavigation />
        </Form>
    );
};
DBSProfileNumberForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
};
DBSProfileNumberForm.defaultProps = {};

export default DBSProfileNumberForm;
