import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useSelector } from 'react-redux';
import { getLaunchDarklyFlags } from '../BaseSelectors';

const GoogleRecaptchaWrapper = ({ children }) => {
    const { webFeatureEnableTeamSignUpRecaptcha } = useSelector(getLaunchDarklyFlags);
    if (webFeatureEnableTeamSignUpRecaptcha) {
        return (
            <GoogleReCaptchaProvider
                reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_PUBLIC_KEY}
                useEnterprise
                scriptProps={{
                    async: true, // optional, default to false,
                    defer: true, // optional, default to false
                    appendTo: 'body', // optional, default to "head", can be "head" or "body"
                }}
            >
                {children}
            </GoogleReCaptchaProvider>
        );
    }

    return children;
};
export default GoogleRecaptchaWrapper;
