// Libraries
import styled from 'styled-components/macro';

export const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    justify-content: ${(center) => (center ? 'center' : 'flex-start')};
    width: 800px;
    margin: 0 auto;
    border: ${({ theme, noBorder }) => (noBorder ? '0px' : `1px solid ${theme.color.certnGray400}`)}
    border-radius: 4px;
    @media (max-width: ${({ theme }) => theme.width.tablet}) {
        width: 100%;
    }
`;

export const Item = styled.div`
    padding: 15px;
    width: 100%;
    border-bottom: ${({ theme, noBorder }) => (noBorder ? '0px' : `1px solid ${theme.color.certnGray200}`)};
    &: hover {
        cursor: ${({ noHighlight }) => (noHighlight ? '' : 'pointer')};
        background: ${({ theme, noHighlight }) => (noHighlight ? theme.color.certnWhite : theme.color.certnGray100)};
    }
`;

export const ItemText = styled.div`
    font-size: 16px;
    color: ${({ theme, light }) => (light ? theme.color.certnGray400 : theme.color.certnGray600)};
    font-weight: ${({ light }) => (light ? '400' : '700')};
`;

export const PackageItem = styled.div`
    &:hover,
    &:focus-within {
        background: ${({ theme, noHighlight }) =>
            noHighlight ? theme.color.certnWhite : `${theme['primary-color']}40`};
        border: 1px solid ${({ theme }) => theme['primary-color']};
    }
    cursor: ${({ noHighlight }) => (noHighlight ? '' : 'pointer')};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    color: ${({ theme }) => theme.color.certnGray600};
    font-weight: 400;
    font-size: 16px;
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.color.certnGray400};
    width: 800px;
    margin: 0 auto 15px auto;
    @media (max-width: ${({ theme }) => theme.width.tablet}) {
        width: 100%;
    }
`;

const List = { Container, Item, ItemText, PackageItem };
export default List;
