import { FormattedMessage } from 'react-intl';

export const titleMap = (title: string, teamIsUKorGB = false) => {
    const titles: { [key: string]: JSX.Element } = {
        base: <FormattedMessage id="3f746.MicroServiceContent.baseTitle" defaultMessage="Base scan" />,
        motor_vehicle_records: (
            <FormattedMessage id="70aa2.ServiceBreakdown.mvr" defaultMessage="Motor Vehicle Records" />
        ),
        identity_verification: (
            <FormattedMessage id="services.identityVerification" defaultMessage="Identity Verification" />
        ),
        enhanced_identity_verification: <FormattedMessage id="70aa2.ServiceBreakdown.id1" defaultMessage="OneID" />,
        criminal_record_check: (
            <FormattedMessage
                id="services.canadianCriminalRecordCheck"
                defaultMessage="Canadian Criminal Record Check"
            />
        ),
        enhanced_criminal_record_check: (
            <FormattedMessage
                id="450bc.MicroServicesActive.enhancedCriminalRecordCheck"
                defaultMessage="Enhanced Canadian Criminal Record Check"
            />
        ),
        us_criminal_record_check_tier_1: (
            <FormattedMessage id="3f746.MicroServiceContent.usCrimTier1Title" defaultMessage="US Base Criminal" />
        ),
        us_criminal_record_check_tier_2: (
            <FormattedMessage
                id="3f746.MicroServiceContent.usCrimTier2Title"
                defaultMessage="US Single County Criminal"
            />
        ),
        us_criminal_record_check_tier_3: (
            <FormattedMessage
                id="3f746.MicroServiceContent.usCrimTier3Title"
                defaultMessage="US Unlimited County Criminal"
            />
        ),
        employer_reference: (
            <FormattedMessage
                id="3f746.MicroServiceContent.employerRefTitle"
                defaultMessage="Employment Digital Reference Check"
            />
        ),
        employer_phone_reference: (
            <FormattedMessage
                id="3f746.MicroServiceContent.employerPhoneRefTitle"
                defaultMessage="Employment Phone Reference Check"
            />
        ),
        address_reference: (
            <FormattedMessage
                id="3f746.MicroServiceContent.addressRefTitle"
                defaultMessage="Tenancy Email Reference Check"
            />
        ),
        address_phone_reference: (
            <FormattedMessage
                id="3f746.MicroServiceContent.addressPhoneRefTitle"
                defaultMessage="Tenancy Phone Reference Check"
            />
        ),
        softcheck: <FormattedMessage id="70aa2.ServiceBreakdown.Softcheck" defaultMessage="Softcheck™" />,
        vaccination_check: (
            <FormattedMessage
                id="70aa2.ServiceBreakdown.vaccinationCheck"
                defaultMessage="COVID-19 Vaccination Status"
            />
        ),
        equifax: <FormattedMessage id="70aa2.ServiceBreakdown.equifax" defaultMessage="Equifax Credit Report" />,
        employment_verification: (
            <FormattedMessage id="common.employmentVerification" defaultMessage="Employment Verification" />
        ),
        credential_verification: (
            <FormattedMessage id="services.credentialVerification" defaultMessage="Credential Verification" />
        ),
        education_verification: (
            <FormattedMessage id="common.educationVerification" defaultMessage="Education Verification" />
        ),
        instant_verify_employment: (
            <FormattedMessage
                id="70aa2.ServiceBreakdown.instantEmpVer"
                defaultMessage="Instant Employment Verification"
            />
        ),
        instant_verify_education: (
            <FormattedMessage
                id="70aa2.ServiceBreakdown.instantEduVer"
                defaultMessage="Instant Education Verification"
            />
        ),
        instant_verify_credential: (
            <FormattedMessage
                id="3f746.MicroServiceContent.instantVerifyCredentialsTitle"
                defaultMessage="Instant Credential Verification"
            />
        ),
        international_criminal_record_check: (
            <FormattedMessage
                id="services.intlCriminalRecordCheck"
                defaultMessage="International Criminal Record Check"
            />
        ),
        uk_basic_dbs_check: <FormattedMessage id="services.ukBasicDBSCheck" defaultMessage="DBS Check" />,
        uk_basic_ds_check: teamIsUKorGB ? (
            <FormattedMessage id="services.ukBasicDSCheck" defaultMessage="Disclosure Scotland Check" />
        ) : (
            <FormattedMessage id="services.ukCriminalRecordCheck" defaultMessage="UK Criminal Record Check" />
        ),
        uk_right_to_work_check: <FormattedMessage id="services.ukRTWCheck" defaultMessage="UK Right To Work Check" />,
        australian_criminal_intelligence_commission_check: (
            <FormattedMessage
                id="3f746.MicroServiceContent.ausStandardAcicCheckTitle"
                defaultMessage="Standard Australian Criminal Intelligence Commission Check"
            />
        ),
        volunteer_acic_check: (
            <FormattedMessage
                id="3f746.MicroServiceContent.ausVolunteerAcicCheckTitle"
                defaultMessage="Volunteer Australian Criminal Intelligence Commission Check"
            />
        ),
        right_to_work: (
            <FormattedMessage
                id="3f746.MicroServiceContent.ausRightToWorkTitle"
                defaultMessage="Australia Right To Work"
            />
        ),
        soquij: <FormattedMessage id="70aa2.ServiceBreakdown.soquij" defaultMessage="Quebec Record Check (SOQUIJ)" />,
        vulnerable_sector_criminal_record_check: (
            <FormattedMessage id="3f746.MicroServiceContent.vscTitle" defaultMessage="Vulnerable Sector Check" />
        ),
        manual_identity_verification: (
            <FormattedMessage
                id="70aa2.ServiceBreakdown.manualIdVerification"
                defaultMessage="Manual Identity Verification"
            />
        ),
        social_media_check: <FormattedMessage id="services.socialMedia" defaultMessage="Social Media Check" />,
        none: <FormattedMessage id="common.none" defaultMessage="None" />,
    };

    return titles[title] || titles.service_missing;
};
