import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { FormattedMessage } from 'react-intl';
import Constants from 'utils/constants';
import { useFlags } from 'launchdarkly-react-client-sdk';

// Selectors
import { getUser, getUserMode, getLaunchDarklyFlags } from 'base/BaseSelectors';

const mapStateToProps = (state) => ({
    user: getUser(state),
    userMode: getUserMode(state),
    isDeprecateNumericScoring: getLaunchDarklyFlags(state)?.webFeatureDeprecateNumericScoring,
});

const propTypes = {
    // Redux State
    user: PropTypes.object.isRequired,
    userMode: PropTypes.string.isRequired,
};

const CertnScoreContainer = styled.div`
    display: flex;
    transition: all 0.2s;
    align-items: center;
    justify-content: center;
    height: ${(props) => props.size}px;
    width: ${(props) => props.size}px;
    background: ${({ full, theme, color }) => (full ? theme.color[color] : theme.color.certnWhite)};
    border-radius: 50%;
    border: ${(props) =>
        props.full ? `1px solid ${props.theme.color[props.color]}` : `2px solid ${props.theme.color[props.color]}`};
    box-sizing: border-box;
    margin: ${(props) => (props.margin ? props.margin : '5px')};
    cursor: ${(props) => (props.active ? 'pointer' : 'default')};
    &:hover {
        filter: ${(props) => (props.active ? `drop-shadow(0 0 2px ${props.theme.color.black})` : 'none')};
    }
`;

const CertnScoreNumber = styled.span`
    font-size: ${(props) => props.fontSize};
    font-weight: ${(props) => (props.full ? '500' : '700')};
    color: ${({ full, theme, fontColor }) => (full ? theme.color.certnWhite : theme.color[fontColor])};
`;

const CertnScore = ({
    record,
    onClick,
    user,
    userMode,
    isDeprecateNumericScoring,
    margin = '0px',
    size = 40,
    score = undefined,
    scoreLabel = undefined,
    full = false,
}) => {
    let scoreValue = score;
    let scoreTitle = scoreLabel;
    let filled = full;
    let fontSize = '13px';
    let fontColor = 'certnGray700';
    let scoreInt;
    let color;
    let userIsCA;
    let analyzing;
    let active;

    const { webFeatureEnableNewStatusSystem } = useFlags();

    if (record) {
        userIsCA = user.team.country === 'CA';
        // LD Flag: webFeatureDeprecateNumericScoring
        if (isDeprecateNumericScoring) {
            scoreValue = record.report_summary.report_result_label;
            scoreTitle = record.report_summary.report_result;
        } else {
            scoreValue = userIsCA ? record.certn_score : record.report_summary.report_result_label;
            scoreTitle = userIsCA && userMode === 'PM' ? record.certn_score_label : record.report_summary.report_result;
        }

        // LDFlag webFeatureEnableNewStatusSystem
        if (webFeatureEnableNewStatusSystem)
            analyzing = record.order_status === Constants.orderStatus.WAITING_ON_CANDIDATE;
        if (!webFeatureEnableNewStatusSystem)
            analyzing = record.report_summary.report_status === Constants.reportStatus.ANALYZING;

        // LDFlag webFeatureEnableNewStatusSystem
        let reportActive;
        if (webFeatureEnableNewStatusSystem) reportActive = Constants.orderStatus.active.includes(record.order_status);
        if (!webFeatureEnableNewStatusSystem)
            reportActive = Constants.reportStatus.active.includes(record?.report_summary?.report_status);

        filled = reportActive;
        active = reportActive;

        if (
            userMode === 'PM' &&
            record.report_summary.equifax_result &&
            record.report_summary.equifax_result.status !== 'Returned'
        ) {
            filled = false;
        }
    }

    if (userIsCA && !isDeprecateNumericScoring) {
        scoreInt = Math.round(scoreValue);
        color = 'certnGray200';
        if (scoreInt >= 70) color = 'certnGreen700';
        else if (scoreInt >= 40) color = 'certnOrange400';
        else if (scoreInt > 0) color = 'certnRed500';
    }
    // if (scoreValue === null && (scoreTitle === 'PASS' || scoreTitle === 'WARN' || scoreTitle === 'FAIL')) {
    else {
        fontSize = '12px';
        if (scoreTitle === Constants.certnScore.PASS) {
            scoreInt = <FormattedMessage id="eaa3c.CertnScore.Pass" defaultMessage="Pass" />;
            color = 'certnGreen700';
        } else if (scoreTitle === Constants.reportResult.CLEARED) {
            scoreInt = <FormattedMessage id="eaa3c.CertnScore.CLR" defaultMessage="CLR" />;
            color = 'certnGreen700';
        } else if (scoreTitle === Constants.certnScore.WARN) {
            scoreInt = <FormattedMessage id="eaa3c.CertnScore.Warn" defaultMessage="Warn" />;
            color = 'certnOrange400';
        } else if (scoreTitle === Constants.reportResult.REVIEW) {
            scoreInt = <FormattedMessage id="eaa3c.CertnScore.REV" defaultMessage="REV" />;
            color = 'certnOrange400';
        } else if (scoreTitle === Constants.certnScore.FAIL) {
            scoreInt = <FormattedMessage id="eaa3c.CertnScore.Fail" defaultMessage="Fail" />;
            color = 'certnRed500';
        } else {
            scoreInt = <FormattedMessage id="common.none" defaultMessage="None" />;
            color = 'certnGray400';
            fontColor = 'certnGray400';
        }
    }

    if (analyzing) {
        scoreInt = 'None';
        color = 'certnGray400';
        fontColor = 'certnGray400';
    }

    return (
        <CertnScoreContainer
            active={active}
            size={size}
            full={filled}
            color={color}
            onClick={active ? onClick : undefined}
            margin={margin}
        >
            <CertnScoreNumber data-testid="certn_score" full={filled} fontSize={fontSize} fontColor={fontColor}>
                {scoreInt}
            </CertnScoreNumber>
        </CertnScoreContainer>
    );
};

CertnScore.propTypes = propTypes;

export default connect(mapStateToProps, {})(CertnScore);
