// Libraries
import { formatOtherProvince } from 'modules/Countries';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { IdentityNumberSerializer } from 'types/serializers';

// Components
import SummaryHeader from './SummaryHeader';
import SummaryItem from './SummaryItem';
import { FlexRow, SummaryContainer, SummarySection } from './SummaryStyles';

interface Props {
    licences: ReadonlyArray<IdentityNumberSerializer>;
    goToTrack: () => void;
}

const AustralianLicences = ({ licences, goToTrack }: Props) => {
    const intl = useIntl();

    return (
        <SummaryContainer>
            <SummaryHeader
                title={<FormattedMessage id="welcome.Summary.australiaLicences" defaultMessage="Australia Licences" />}
                onClick={goToTrack}
            />
            <SummarySection>
                {licences.length === 0 && (
                    <SummaryItem
                        empty
                        title={
                            <FormattedMessage
                                id="welcome.Summary.noDocumentsProvided"
                                defaultMessage="You have not provided any documents"
                            />
                        }
                    />
                )}
                {licences.map((licence, i) => (
                    <React.Fragment key={i}>
                        <FlexRow>
                            <SummaryItem
                                title={
                                    <FormattedMessage id="welcome.Summary.licenseNumber" defaultMessage="Licence No." />
                                }
                                text={licence.number}
                            />
                            <SummaryItem
                                title={
                                    <FormattedMessage
                                        id="welcome.Summary.stateTerritoryIssued"
                                        defaultMessage="State / Territory Issued"
                                    />
                                }
                                text={formatOtherProvince('AU', licence.issuing_state_province_or_territory, intl)}
                            />
                        </FlexRow>
                    </React.Fragment>
                ))}
            </SummarySection>
        </SummaryContainer>
    );
};

export default AustralianLicences;
