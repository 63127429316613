import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { getETT } from 'views/manager/features/ScreenApplicant/ScreenApplicantSelectors';
import { EstimatedTurnAroundTimeText } from './styled';

export const EstimatedTurnAroundTime = () => {
    const estimatedTurnAroundTime = useSelector(getETT);

    if (!estimatedTurnAroundTime) {
        return null;
    }

    return (
        <EstimatedTurnAroundTimeText>
            <FormattedMessage
                id="acce0.ScreenApplicantCart.estimatedTime"
                defaultMessage="Estimated turnaround time is {time}"
                values={{ time: estimatedTurnAroundTime }}
            />
        </EstimatedTurnAroundTimeText>
    );
};
