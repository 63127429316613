import styled from 'styled-components';
import styles from 'styles/styles';

export const CardWrapper = styled.div<{ completed?: boolean }>`
    :hover {
        cursor: ${({ completed }) => (completed ? `auto` : 'pointer')};
    }
`;

export const CardLeft = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 1;
`;

export const IconWrapper = styled.div<{ filled?: boolean }>`
    border: ${({ filled }) => (filled ? `none` : '1px solid lightgrey')};
    border-radius: 50px;
    width: 32px;
    height: 32px;
    text-align: center;
    line-height: 34px;
    margin-right: 16px;
    background-color: ${({ filled }) => (filled ? styles.color.certnGreen400 : '')};
    font-size: 17px;
    color: ${styles.color.certnGreen400};
`;

export const CardRight = styled.div`
    justify-self: flex-end;
    margin-right: 12px;
`;
