// Libraries
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import Auth from 'modules/Auth';
import { useIntl } from 'react-intl';
import { useHistory, useParams, Switch, Route, Redirect } from 'react-router-dom';
import { get } from 'lodash';

// Components
import { BackButton } from 'certn-ui/Button';
import { Form } from '@ant-design/compatible';
import { LayoutBody } from 'certn-ui/Layout';
import RouteErrorBoundary from 'certn-ui/RouteErrorBoundary';
import Loader from 'certn-ui/Loader';

// Actions & Selectors
import { fetchTeam } from 'views/manager/views/admin/AdminActions';
import { getUser, getUserModeIsPM } from 'base/BaseSelectors';
import SettingsSidebar from './components/SettingsSidebar';
import { fetchTeamSettings } from './SettingsActions';

// Views
import {
    Applications,
    General,
    Users,
    Billing,
    Notifications,
    References,
    Report,
    Integrations,
    Support,
    ApiKeys,
    Documents,
    AdverseAction,
} from './views';

const SettingsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 15px;
    @media (max-width: ${(props) => props.theme.width.tablet}) {
        flex-direction: column;
        width: 100%;
    }
`;

const SettingsMain = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0px 10px;
    width: 100%;
`;

const SidebarMobileWrapper = styled.div`
    @media (max-width: ${(props) => props.theme.width.tablet}) {
        display: none;
    }
`;

const Settings = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const intl = useIntl();
    const teamId = useParams()?.teamId;
    const user = useSelector(getUser);
    const userModeIsPM = useSelector(getUserModeIsPM);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        Promise.all([dispatch(fetchTeamSettings(teamId)), dispatch(fetchTeam(teamId))]).then(() => setLoading(false));
    }, [dispatch, teamId]);

    if (loading) return <Loader />;
    return (
        <>
            <LayoutBody>
                <RouteErrorBoundary>
                    <SidebarMobileWrapper>
                        {(Auth.isPermissionLevel('admin') || user?.joined_teams?.length > 1) && (
                            <BackButton
                                label={intl.formatMessage({
                                    id: '387a6.Settings.BackButton',
                                    defaultMessage: 'TEAMS',
                                })}
                                onClick={() => history.push('/admin/teams/')}
                            />
                        )}
                    </SidebarMobileWrapper>
                    <SettingsWrapper>
                        <SettingsSidebar
                            tab={get(props, ['match', 'params', 'tab'])}
                            params={get(props, ['match', 'params'])}
                            teamId={teamId}
                            isPM={userModeIsPM}
                        />
                        <SettingsMain>
                            <Switch>
                                {Auth.isPermissionLevel('admin') && (
                                    <Route path="/settings/integrations/:teamId" component={Integrations} />
                                )}
                                {Auth.isPermissionLevel('admin') && (
                                    <Route path="/settings/billing/:teamId" component={Billing} />
                                )}
                                {Auth.isPermissionLevel('manager') && (
                                    <Route path="/settings/references/:teamId/:type" component={References} />
                                )}
                                {Auth.isPermissionLevel('manager') && (
                                    <Route path="/settings/users/:teamId" component={Users} />
                                )}
                                {Auth.isPermissionLevel('manager') && (
                                    <Route path="/settings/users/" component={Users} />
                                )}
                                {Auth.isPermissionLevel('manager') && (
                                    <Route path="/settings/integrations/:teamId" component={Integrations} />
                                )}
                                {Auth.isPermissionLevel('manager') && (
                                    <Route path="/settings/api-keys/" component={ApiKeys} />
                                )}
                                {Auth.isPermissionLevel('manager') && (
                                    <Route path="/settings/report/" component={Report} />
                                )}
                                {Auth.isPermissionLevel('manager') && (
                                    <Route path="/settings/documents/:teamId" component={Documents} />
                                )}
                                <Route path="/settings/general/:teamId" component={General} />
                                <Route path="/settings/general/" component={General} />
                                <Route path="/settings/applications/:teamId" component={Applications} />
                                <Route path="/settings/applications/" component={Applications} />
                                <Route path="/settings/notifications/:teamId" component={Notifications} />
                                <Route path="/settings/notifications/" component={Notifications} />
                                <Route path="/settings/support/:teamId" component={Support} />
                                <Route path="/settings/support/" component={Support} />
                                <Route path="/settings/adverse-action/" component={AdverseAction} />
                                <Redirect to={`/settings/general/${teamId}`} />
                            </Switch>
                        </SettingsMain>
                    </SettingsWrapper>
                </RouteErrorBoundary>
            </LayoutBody>
        </>
    );
};

export default Form.create()(Settings);
