// Libraries
import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { QuestionCircleOutlined } from '@ant-design/icons';

// UI Components
import { Button, Tooltip } from 'antd';
import { TitleHeader } from 'views/manager/components';
import { ListCellLink } from 'certn-ui/List';
import Overlay from 'certn-ui/Overlay';
import { FormattedMessage } from 'react-intl';

// Selectors
import { getUserMode } from 'base/BaseSelectors';

const mapStateToProps = (state) => ({
    userMode: getUserMode(state),
});

const ApplicantsCell = ({ record, copyToClipboard }) =>
    record.is_active && (
        <Overlay
            layer={
                <Button size="small" onClick={() => copyToClipboard({ route: 'packages', urlCode: record.url_code })}>
                    <FormattedMessage id="c838c.ApplicantsCell.copy" defaultMessage="Copy" />
                </Button>
            }
        >
            <ListCellLink onClick={() => copyToClipboard({ route: 'packages', urlCode: record.url_code })}>
                {record.url_code}
            </ListCellLink>
        </Overlay>
    );

ApplicantsCell.Header = ({ data, title, reverseOrder, setOrdering, ordering }) => (
    <TitleHeader
        title={title}
        handleClick={() => (data.active ? setOrdering(data.active) : null)}
        active={data.active ? ordering === data.active : false}
        clickable={data.active}
        reverseOrder={reverseOrder}
    >
        <span style={{ marginRight: '2px' }}>
            <FormattedMessage id="c838c.ApplicantsCell.applyLink" defaultMessage="Apply Link" />
        </span>
        <Tooltip
            title={
                <FormattedMessage
                    id="c838c.ApplicantsCell.title"
                    defaultMessage="This link will allow applicants to apply directly for this Package"
                />
            }
        >
            <QuestionCircleOutlined />
        </Tooltip>
    </TitleHeader>
);

ApplicantsCell.propTypes = {};

export default withRouter(connect(mapStateToProps, {})(ApplicantsCell));
