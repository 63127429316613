import styled from 'styled-components/macro';

const BaseBox = styled.div`
    width: ${({ width }) => width || '100%'};
    height: ${({ height }) => height || '100%'};
    padding: ${({ padding }) => padding || 'none'};
    margin: ${({ margin }) => margin || 'none'};
    border: ${({ border }) => border || 'none'};
    border-radius: ${({ radius }) => radius || 'none'};
    background: ${({ theme, color }) => (color ? theme.color[color] : 'none')};
`;

const Block = styled(BaseBox)`
    display: block;
`;

const Inline = styled(BaseBox)`
    display: inline-block;
`;

const Flex = styled(BaseBox)`
    display: Flex;
`;

const Box = {
    Block,
    Inline,
    Flex,
};
export default Box;
