// Libraries
import React, { useEffect, useState } from 'react';
import { Input, Select, Popover, Form } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

// Components
import { ProvStateSelect } from 'components';

// Modules
import { isUSAOrCanada } from 'modules/Countries';
import { Regex } from 'modules';
import styles from 'styles/styles';
import { getRequest } from '../../utils/http';
import { useIntl } from 'react-intl';

export const countryItem = (
    countryLabel,
    required,
    intl,
    canadaUSOnly,
    showAllAddressFields,
    hideLabels,
    onCountryChange,
    countryList
) => (
    <Form.Item
        hasFeedback
        name={countryLabel}
        rules={[
            {
                required,
                type: 'string',
                message: intl.formatMessage({
                    id: 'error.validation.countryError',
                    defaultMessage: 'Select a country!',
                }),
            },
            {
                validator: (_, value) => {
                    if (!canadaUSOnly || (canadaUSOnly && isUSAOrCanada(value))) return Promise.resolve();
                    return Promise.reject(
                        intl.formatMessage({
                            id: 'error.validation.country',
                            defaultMessage: 'Country must be Canada or United States',
                        })
                    );
                },
            },
        ]}
        style={{ display: showAllAddressFields ? 'block' : 'none', marginBottom: '5px' }}
        label={!hideLabels ? intl.formatMessage({ id: 'common.country', defaultMessage: 'Country' }) : null}
    >
        <Select
            style={{ textAlign: 'left' }}
            size="large"
            data-testid="country"
            placeholder={
                hideLabels
                    ? intl.formatMessage({
                          id: 'common.country',
                          defaultMessage: 'Country',
                      })
                    : null
            }
            onChange={onCountryChange}
            showSearch
            allowClear
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
            {countryList.map((addCountry, index) => (
                <Select.Option
                    data-testid={addCountry.name.toLowerCase().split(' ').join('_')}
                    key={`${addCountry.alpha2}-${index}`}
                    value={addCountry.alpha2}
                >
                    {addCountry.name}
                </Select.Option>
            ))}
        </Select>
    </Form.Item>
);

export const cityItem = (cityLabel, intl, required, showAllAddressFields, hideLabels) => (
    <Form.Item
        hasFeedback
        name={cityLabel}
        rules={[
            {
                pattern: Regex.city,
                message: intl.formatMessage({
                    id: 'error.validation.city',
                    defaultMessage: 'Please enter a valid city',
                }),
            },
            {
                required,
                message: intl.formatMessage({
                    id: 'error.validation.notBlank',
                    defaultMessage: 'Please do not leave blank',
                }),
            },
        ]}
        style={{ display: showAllAddressFields ? 'block' : 'none', marginBottom: '5px' }}
        label={
            !hideLabels
                ? intl.formatMessage({
                      id: 'addressAuto.cityMunicipality',
                      defaultMessage: 'City / Municipality',
                  })
                : null
        }
    >
        <Input
            autoComplete="dont-use-autocomplete"
            size="large"
            data-testid="city"
            type="text"
            placeholder={
                hideLabels
                    ? intl.formatMessage({
                          id: 'addressAuto.cityMunicipality',
                          defaultMessage: 'City / Municipality',
                      })
                    : null
            }
            addonBefore={
                <Popover
                    content={intl.formatMessage({
                        id: 'addressAuto.cityMunicipality',
                        defaultMessage: 'City / Municipality',
                    })}
                >
                    <InfoCircleOutlined />
                </Popover>
            }
        />
    </Form.Item>
);

export const CountyItem = ({ required, countyLabel, showAllAddressFields, currentProvinceState, hideLabels }) => {
    const [counties, setCounties] = useState([]);
    const intl = useIntl();

    useEffect(() => {
        async function fetchCounties() {
            try {
                const response = await getRequest({
                    version: 'v2',
                    endpoint: `/document-management/counties/?state=${currentProvinceState}`,
                });
                setCounties(response);
            } catch (error) {
                console.error(error);
            }
        }
        if (currentProvinceState) {
            fetchCounties();
        }
    }, [currentProvinceState]);

    return (
        <Form.Item
            hasFeedback
            name={countyLabel}
            rules={[
                {
                    required,
                    validator: async (_, value) => {
                        if (!value) {
                            return Promise.reject(
                                new Error(
                                    intl.formatMessage({
                                        id: 'error.validation.county',
                                        defaultMessage: 'Please enter a county',
                                    })
                                )
                            );
                        }
                        return Promise.resolve();
                    },
                },
            ]}
            style={{
                border: !showAllAddressFields ? `1px solid ${styles.color.certnRed700}` : '0',
                display: showAllAddressFields ? 'block' : 'none',
                marginBottom: '5px',
            }}
            label={!hideLabels ? intl.formatMessage({ id: 'addressAuto.county', defaultMessage: 'County' }) : null}
        >
            <Select
                style={{ textAlign: 'left' }}
                size="large"
                data-testid="county"
                placeholder={
                    hideLabels ? intl.formatMessage({ id: 'addressAuto.county', defaultMessage: 'County' }) : null
                }
                showSearch
                allowClear
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
                {counties.map((addCounty, index) => (
                    <Select.Option data-testid={addCounty} key={`${addCounty}-${index}`} value={addCounty}>
                        {addCounty}
                    </Select.Option>
                ))}
            </Select>
        </Form.Item>
    );
};

export const provinceItem = (
    provinceFieldName,
    required,
    intl,
    showAllAddressFields,
    hideLabels,
    provinceLabel,
    currentCountry,
    onProvinceChange
) => (
    <Form.Item
        hasFeedback
        name={provinceFieldName}
        rules={[
            {
                required,
                message: intl.formatMessage({
                    id: 'error.validation.provinceError',
                    defaultMessage: 'Select a province!',
                }),
            },
        ]}
        style={{
            display: showAllAddressFields ? 'block' : 'none',
            marginBottom: '5px',
            textAlign: 'left',
        }}
        label={hideLabels ? null : provinceLabel}
    >
        <ProvStateSelect
            size="large"
            selectedCountry={currentCountry}
            placeholder={hideLabels ? provinceLabel : null}
            addonBefore={
                <Popover content={provinceLabel}>
                    <InfoCircleOutlined />
                </Popover>
            }
            data-testid="province_state"
            onChange={onProvinceChange}
        />
    </Form.Item>
);
