import { ErrorAlertAPI } from 'certn-ui/ErrorAlert';
import { useEffect } from 'react';
import { CombinedCheckboxClickwrap } from 'components/Clickwrap/CombinedCheckboxClickwrap';
import { MY_CRC_SIGN_UP } from 'components/Clickwrap/constants';

type MyCRCDisclosureContractProps = {
    disclosureSigned: boolean;
    setDisclosureSigned: (signed: boolean) => void;
    signerId?: string;
};

export const MyCRCDisclosureContract: React.FC<MyCRCDisclosureContractProps> = ({
    disclosureSigned,
    setDisclosureSigned,
    signerId = MY_CRC_SIGN_UP,
}) => {
    const DISCLOSURE_CONTRACT_GROUP_KEY = process.env.REACT_APP_CLICKWRAP_DISCLOSURE_CONTRACT_GROUP_KEY;

    useEffect(() => {
        if (!DISCLOSURE_CONTRACT_GROUP_KEY) {
            ErrorAlertAPI();
            console.error(
                'Unable to initialize disclosure contract. Missing Clickwrap Group Key for Disclosure Contract.'
            );
        }
    }, [DISCLOSURE_CONTRACT_GROUP_KEY]);

    if (!DISCLOSURE_CONTRACT_GROUP_KEY) {
        return null;
    }

    return (
        <CombinedCheckboxClickwrap
            groupKey={DISCLOSURE_CONTRACT_GROUP_KEY}
            signerId={signerId}
            setSigned={setDisclosureSigned}
            disabled={signerId !== MY_CRC_SIGN_UP && disclosureSigned}
        />
    );
};
