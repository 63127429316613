// Libraries
import React from 'react';
import { FormattedMessage } from 'react-intl';

// Components
import { AppearRight } from 'certn-ui/Animate';
import Button, { ButtonLine } from 'certn-ui/Button';
import Title from 'certn-ui/Title';
import Trophy from 'images/svgs/svg-components/onboarding/Trophy';

const PaymentSuccessMVR = ({ history }) => (
    <AppearRight>
        <Title>
            <FormattedMessage
                id="welcome.Payment.sucesssMVR"
                defaultMessage="Payment successful! You've submitted your application but we still need your Motor Vehicle Records."
            />
        </Title>
        <ButtonLine>
            <Button onClick={() => history.push('/motor-vehicle-records')}>
                <FormattedMessage id="7cb11.ResponseSuccessMVR.viewInstructions" defaultMessage="View Instructions" />
            </Button>
        </ButtonLine>
        <ButtonLine>
            <span>
                <FormattedMessage
                    id="7cb11.ResponseSuccessMVR.mvrCanCloseTab"
                    defaultMessage="After completing the above steps, you can safely close this tab. Thank you!"
                />
            </span>
        </ButtonLine>
        <Trophy />
    </AppearRight>
);

export default PaymentSuccessMVR;
