// Libraries
import React from 'react';
import { useSelector } from 'react-redux';
import Auth from 'modules/Auth';
import { injectIntl, FormattedMessage } from 'react-intl';

// Components
import Form from 'certnd/Form';
import Typography from 'certnd/Typography';

// Actions and Selectors
import { getUserModeIsHR, getUserModeIsPM } from 'base/BaseSelectors';

const { Heading } = Typography;

function ApplicationSettings(props) {
    const userModeIsHR = useSelector(getUserModeIsHR);
    const userModeIsPM = useSelector(getUserModeIsPM);
    const { form, settings } = props;

    const canEdit = Auth.isPermissionLevel('manager');
    const key = userModeIsHR ? 'hr' : 'pm';

    return (
        <Form.Block>
            <Heading.H3>
                <FormattedMessage id="7a88b.ApplicationsSettings.Title" defaultMessage="Applicant Table Columns" />
            </Heading.H3>

            {userModeIsHR && (
                <Form.Checkbox
                    form={form}
                    fieldName="hr_application_column_settings.package"
                    title={props.intl.formatMessage({
                        id: '7a88b.ApplicationsSettings.PackageTitle',
                        defaultMessage: 'Package',
                    })}
                    description={props.intl.formatMessage({
                        id: '7a88b.ApplicationsSettings.PackageDescription',
                        defaultMessage: 'Shows the package the application is associated with.',
                    })}
                    options={{ initialValue: settings.hr_application_column_settings.package }}
                    disabled={!canEdit}
                />
            )}

            {userModeIsPM && (
                <Form.Checkbox
                    form={form}
                    fieldName="pm_application_column_settings.co_applicants"
                    title={props.intl.formatMessage({
                        id: '7a88b.ApplicationsSettings.CoApplicantsTitle',
                        defaultMessage: 'Co-Applicants',
                    })}
                    description={props.intl.formatMessage({
                        id: '7a88b.ApplicationsSettings.CoApplicantsDescription',
                        defaultMessage: 'Shows Co-Applicants associated with the application.',
                    })}
                    options={{ initialValue: settings.pm_application_column_settings.co_applicants }}
                    disabled={!canEdit}
                />
            )}
            {userModeIsPM && (
                <Form.Checkbox
                    form={form}
                    fieldName="pm_application_column_settings.co_signers"
                    title={props.intl.formatMessage({
                        id: '7a88b.ApplicationsSettings.CoSignersTitle',
                        defaultMessage: 'Co-Signers',
                    })}
                    description={props.intl.formatMessage({
                        id: '7a88b.ApplicationsSettings.CoSignersDescription',
                        defaultMessage: 'Shows Co-Signers associated with the application.',
                    })}
                    options={{ initialValue: settings.pm_application_column_settings.co_signers }}
                    disabled={!canEdit}
                />
            )}
            <Form.Checkbox
                form={form}
                fieldName={`${key}_application_column_settings.team`}
                title={props.intl.formatMessage({ id: '7a88b.ApplicationsSettings.TeamTitle', defaultMessage: 'Team' })}
                description={props.intl.formatMessage({
                    id: '7a88b.ApplicationsSettings.TeamDescription',
                    defaultMessage: 'Shows the team the application was created for.',
                })}
                options={{ initialValue: settings[`${key}_application_column_settings`].team }}
                disabled={!canEdit}
            />
            <Form.Checkbox
                form={form}
                fieldName={`${key}_application_column_settings.owner`}
                title={props.intl.formatMessage({
                    id: 'common.owner',
                    defaultMessage: 'Owner',
                })}
                description={props.intl.formatMessage({
                    id: '7a88b.ApplicationsSettings.OwnerDescription',
                    defaultMessage: 'Shows the owner of the application; whoever initially ran the service.',
                })}
                options={{ initialValue: settings[`${key}_application_column_settings`].owner }}
                disabled={!canEdit}
            />

            <Form.Button disabled={!canEdit}>
                <FormattedMessage id="common.save" defaultMessage="Save" />
            </Form.Button>
        </Form.Block>
    );
}

export default injectIntl(ApplicationSettings);
