// Modules
import { NAMESPACE as PARENT_NAMESPACE, STATEPATH as PARENT_STATEPATH } from 'views/manager/ManagerConstants';
import { getRequest, patchRequest, postRequest, deleteRequest } from 'utils/http';

// Constants
export const NAMESPACE = `${PARENT_NAMESPACE}ADMIN/`;
export const STATEPATH = `${PARENT_STATEPATH}.admin`;

// Actions Types
export const CREATE_TEAM_REQ = `${NAMESPACE}CREATE_TEAM_REQ`;
export const CREATE_TEAM_RES = `${NAMESPACE}CREATE_TEAM_RES`;
export const CREATE_TEAM_ERR = `${NAMESPACE}CREATE_TEAM_ERR`;
export const FETCH_TEAM_REQ = `${NAMESPACE}FETCH_TEAM_REQ`;
export const FETCH_TEAM_RES = `${NAMESPACE}FETCH_TEAM_RES`;
export const FETCH_TEAM_ERR = `${NAMESPACE}FETCH_TEAM_ERR`;
export const FETCH_TEAMS_REQ = `${NAMESPACE}FETCH_TEAMS_REQ`;
export const FETCH_TEAMS_RES = `${NAMESPACE}FETCH_TEAMS_RES`;
export const FETCH_TEAMS_ERR = `${NAMESPACE}FETCH_TEAMS_ERR`;
export const FETCH_ADJUDICATORS_REQ = `${NAMESPACE}FETCH_ADJUDICATORS_REQ`;
export const FETCH_ADJUDICATORS_RES = `${NAMESPACE}FETCH_ADJUDICATORS_RES`;
export const FETCH_ADJUDICATORS_ERR = `${NAMESPACE}FETCH_ADJUDICATORS_ERR`;
export const DELETE_TEAM_REQ = `${NAMESPACE}DELETE_TEAM_REQ`;
export const DELETE_TEAM_RES = `${NAMESPACE}DELETE_TEAM_RES`;
export const DELETE_TEAM_ERR = `${NAMESPACE}DELETE_TEAM_ERR`;
export const SAVE_TEAM_REQ = `${NAMESPACE}SAVE_TEAM_REQ`;
export const SAVE_TEAM_RES = `${NAMESPACE}SAVE_TEAM_RES`;
export const SAVE_TEAM_ERR = `${NAMESPACE}SAVE_TEAM_ERR`;

export const FETCH_TEAM_MEMBER_REQ = `${NAMESPACE}FETCH_TEAM_MEMBER_REQ`;
export const FETCH_TEAM_MEMBER_RES = `${NAMESPACE}FETCH_TEAM_MEMBER_RES`;
export const FETCH_TEAM_MEMBER_ERR = `${NAMESPACE}FETCH_TEAM_MEMBER_ERR`;

export const SEND_VERIFICATION_EMAIL_REQ = `${NAMESPACE}SEND_VERIFICATION_EMAIL_REQ`;
export const SEND_VERIFICATION_EMAIL_RES = `${NAMESPACE}SEND_VERIFICATION_EMAIL_RES`;
export const SEND_VERIFICATION_EMAIL_ERR = `${NAMESPACE}SEND_VERIFICATION_EMAIL_ERR`;

export const GET_LEGAL_DOCUMENTS_PDF_REQ = `${NAMESPACE}GET_LEGAL_DOCUMENTS_PDF_REQ`;
export const GET_LEGAL_DOCUMENTS_PDF_RES = `${NAMESPACE}GET_LEGAL_DOCUMENTS_PDF_RES`;
export const GET_LEGAL_DOCUMENTS_PDF_ERR = `${NAMESPACE}GET_LEGAL_DOCUMENTS_PDF_ERR`;

export const GET_REVIEW_STATUS_OPTIONS_REQ = `${NAMESPACE}GET_REVIEW_STATUS_OPTIONS_REQ`;
export const GET_REVIEW_STATUS_OPTIONS_RES = `${NAMESPACE}GET_REVIEW_STATUS_OPTIONS_RES`;
export const GET_REVIEW_STATUS_OPTIONS_ERR = `${NAMESPACE}GET_REVIEW_STATUS_OPTIONS_ERR`;

// Action Creators
export const deleteTeam = (teamId) => (dispatch) => {
    dispatch({
        type: DELETE_TEAM_REQ,
    });
    return deleteRequest({
        version: 'v2',
        endpoint: `/teams/${teamId}/`,
    })
        .then(() => {
            dispatch({
                type: DELETE_TEAM_RES,
            });
        })
        .catch((error) => {
            dispatch({
                type: DELETE_TEAM_ERR,
                payload: { error },
            });
        });
};

export const saveTeam = (data, id) => (dispatch) => {
    dispatch({
        type: SAVE_TEAM_REQ,
    });
    return patchRequest({
        version: 'v2',
        endpoint: `/teams/${id}/`,
        body: JSON.stringify(data),
    })
        .then((response) => {
            dispatch({
                type: SAVE_TEAM_RES,
                payload: { team: response },
            });
        })
        .catch((error) => {
            dispatch({
                type: SAVE_TEAM_ERR,
                payload: { error },
            });
        });
};

export const createTeam = (data) => (dispatch) => {
    dispatch({
        type: CREATE_TEAM_REQ,
    });
    return postRequest({
        version: 'v2',
        endpoint: '/teams/',
        body: JSON.stringify(data),
    }).then(
        () => {
            dispatch({
                type: CREATE_TEAM_RES,
            });
        },
        (error) => {
            dispatch({
                type: CREATE_TEAM_ERR,
                payload: { error },
            });
            throw error;
        }
    );
};

export const fetchTeamMember = (memberId) => (dispatch) => {
    dispatch({
        type: FETCH_TEAM_MEMBER_REQ,
    });
    return getRequest({
        version: 'v2',
        endpoint: `/users/${memberId}`,
    })
        .then((response) => {
            dispatch({
                type: FETCH_TEAM_MEMBER_RES,
                payload: {
                    teamMember: response,
                },
            });
        })
        .catch((error) => {
            dispatch({
                type: FETCH_TEAM_MEMBER_ERR,
                payload: { error },
            });
        });
};

// Used to reactivate a user
export const sendVerificationEmail = (userId) => (dispatch) => {
    dispatch({
        type: SEND_VERIFICATION_EMAIL_REQ,
    });
    return postRequest({
        version: 'v2',
        endpoint: `/users/${userId}/send_verification/`,
    })
        .then(() => {
            dispatch({
                type: SEND_VERIFICATION_EMAIL_RES,
            });
        })
        .catch((error) => {
            dispatch({
                type: SEND_VERIFICATION_EMAIL_ERR,
                payload: { error },
            });
        });
};

export const fetchTeams = () => (dispatch) => {
    dispatch({
        type: FETCH_TEAMS_REQ,
    });
    return getRequest({
        version: 'v2',
        endpoint: `/teams/`,
    })
        .then((response) => {
            dispatch({
                type: FETCH_TEAMS_RES,
                payload: { teams: response },
            });
        })
        .catch((error) => {
            dispatch({
                type: FETCH_TEAM_ERR,
                payload: { error },
            });
        });
};

export const fetchPaginatedTeams = (currentPage = 1, pageSize = 20, orderBy = 'created', search) => (dispatch) => {
    dispatch({
        type: FETCH_TEAMS_REQ,
    });
    return getRequest({
        version: 'v2',
        endpoint: `/paginated-teams/?page=${currentPage}&page_size=${pageSize}&ordering=${orderBy}&search=${search}`,
    })
        .then((response) => {
            dispatch({
                type: FETCH_TEAMS_RES,
                payload: { teams: response.results, teams_count: response.count },
            });
        })
        .catch((error) => {
            dispatch({
                type: FETCH_TEAM_ERR,
                payload: { error },
            });
        });
};

export const fetchTeam = (teamId) => (dispatch) => {
    dispatch({
        type: FETCH_TEAM_REQ,
    });
    return getRequest({
        version: 'v2',
        endpoint: `/teams/${teamId}/`,
    })
        .then((response) => {
            dispatch({
                type: FETCH_TEAM_RES,
                payload: {
                    team: response,
                },
            });
        })
        .catch((error) => {
            dispatch({
                type: FETCH_TEAM_ERR,
                payload: { error },
            });
        });
};

export const fetchAdjudicators = () => (dispatch) => {
    dispatch({
        type: FETCH_ADJUDICATORS_REQ,
    });

    return getRequest({
        hr: true,
        version: 'v1',
        endpoint: `team/adjudicators/`,
    })
        .then((response) => {
            const result = response.map((d) => ({
                id: d.id,
                name: `${d.first_name} ${d.last_name}`,
            }));

            dispatch({
                type: FETCH_ADJUDICATORS_RES,
                payload: { adjudicators: result },
            });
        })
        .catch((error) => {
            dispatch({
                type: FETCH_ADJUDICATORS_ERR,
                payload: { error },
            });
        });
};

export const fetchReviewStatuses = () => (dispatch) => {
    dispatch({
        type: GET_REVIEW_STATUS_OPTIONS_REQ,
    });
    return getRequest({
        hr: true,
        version: 'v1',
        endpoint: '/adjudication/status/options/',
    }).then(
        (response) => {
            dispatch({
                type: GET_REVIEW_STATUS_OPTIONS_RES,
                payload: { reviewStatuses: response },
            });
        },
        (error) => {
            dispatch({
                type: GET_REVIEW_STATUS_OPTIONS_ERR,
                payload: { error },
            });
        }
    );
};
