// Libraries
import React from 'react';

// Components
import TeamMembersDropdown from 'views/manager/views/settings/components/TeamMembersDropdown';
import ActionCellWrapper from 'components/ActionCellWrapper';

const ActionsCell = ({ row, editTeamMember, saveTeamMember, removeTeamMember, showDeleteUserButton = true }) => (
    <ActionCellWrapper margin>
        <TeamMembersDropdown
            active={row.is_active}
            editTeamMember={() => editTeamMember(row)}
            removeTeamMember={() => removeTeamMember(row)}
            saveTeamMember={() => saveTeamMember({ is_active: false }, row.id)}
            showDeleteUserButton={showDeleteUserButton}
        />
    </ActionCellWrapper>
);

export default ActionsCell;
