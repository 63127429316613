// Libraries
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Form } from '@ant-design/compatible';

// Components
import { TrackNavigation } from 'views/welcome/components';
import { AppearRight } from 'certn-ui/Animate';
import Title from 'certn-ui/Title';
import MailVerified from 'images/svgs/svg-components/onboarding/MailVerified';

const PageTwoForm = ({ handleSubmit, form: { validateFields } }) => (
    <AppearRight>
        <Form layout="horizontal" onSubmit={(e) => handleSubmit(e, validateFields)}>
            <Title>
                <FormattedMessage
                    id="welcome.Submit.emailVerified"
                    defaultMessage="Your email is verified! All that's left is to push the Submit button to send your completed application."
                />
            </Title>
            <MailVerified />
            <TrackNavigation
                nextButtonTitle={<FormattedMessage id="welcome.Submit.submit" defaultMessage="Submit Application" />}
            />
        </Form>
    </AppearRight>
);

export default Form.create()(PageTwoForm);
