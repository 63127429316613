import { LegalWrapper } from './styled';
import Text from 'certn-ui/Text';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form } from 'antd';
import { ServicesContract } from './ServicesContract';
import { useCallback, useState } from 'react';
import { TermsOfUseAndPrivacyPolicy } from './TermsOfUseAndPrivacyPolicy';
import { Checkbox } from 'certn-ui/Checkbox';
import { TEAM_SIGN_UP } from 'components/Clickwrap/constants';

export const Legal = () => {
    const intl = useIntl();
    const [contractSigned, setContractSigned] = useState();
    const [toUAndPrivacyPolicySigned, setToUAndPrivacyPolicySigned] = useState();

    const handleContractSignedChange = useCallback((signed) => setContractSigned(signed), []);
    const handleToUAndPrivacyPolicySignedSignedChange = useCallback(
        (signed) => setToUAndPrivacyPolicySigned(signed),
        []
    );

    const validateServicesContract = () => ({
        validator() {
            if (contractSigned) {
                return Promise.resolve();
            }

            return Promise.reject(
                intl.formatMessage({
                    id: 'welcome.SignUp.servicesContractRequired',
                    defaultMessage: 'You must accept our services contract before continuing.',
                })
            );
        },
    });

    const validateToUAndPrivacyPolicy = () => ({
        validator() {
            if (toUAndPrivacyPolicySigned) {
                return Promise.resolve();
            }

            return Promise.reject(
                intl.formatMessage({
                    id: 'welcome.SignUp.termsRequiredClickwrap',
                    defaultMessage: 'You must accept our Website Terms and Privacy Policy before continuing.',
                })
            );
        },
    });

    return (
        <LegalWrapper>
            <Text style={{ marginBottom: '4px' }}>
                {intl.formatMessage({
                    id: 'welcome.SignUp.terms',
                    defaultMessage: "By clicking the 'I Agree' button and creating a Certn account you:",
                })}
            </Text>
            <Form.Item
                name="services-contract"
                valuePropName="checked"
                style={{ marginBottom: '0px' }}
                rules={[validateServicesContract]}
            >
                <ServicesContract signerId={TEAM_SIGN_UP} setSigned={handleContractSignedChange} />
            </Form.Item>
            <Form.Item
                name="terms"
                valuePropName="checked"
                style={{ marginBottom: '0px' }}
                rules={[validateToUAndPrivacyPolicy]}
            >
                <TermsOfUseAndPrivacyPolicy
                    signerId={TEAM_SIGN_UP}
                    setSigned={handleToUAndPrivacyPolicySignedSignedChange}
                />
            </Form.Item>
            <Form.Item name="consent_to_marketing" valuePropName="checked" style={{ marginTop: '4px' }}>
                <Checkbox style={{ marginTop: '1px' }}>
                    <FormattedMessage
                        id="welcome.Email.consentToMarketing"
                        defaultMessage="I want to receive news, feature updates, discounts, and offers from Certn. (Optional)"
                    />
                </Checkbox>
            </Form.Item>
        </LegalWrapper>
    );
};
