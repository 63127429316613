// Libraries
import React from 'react';
import { Helmet } from 'react-helmet';

import { HUBSPOT_TRACKING_ORG_ID, HUBSPOT_BUSINESS_UNIT_IDS } from './constants';

interface Props {
    countryCode?: keyof typeof HUBSPOT_BUSINESS_UNIT_IDS;
}

export const HubspotTracking: React.FC<Props> = ({ countryCode }) => {
    if (!HUBSPOT_TRACKING_ORG_ID) {
        return null;
    }

    const businessUnitId =
        countryCode && countryCode in HUBSPOT_BUSINESS_UNIT_IDS ? HUBSPOT_BUSINESS_UNIT_IDS[countryCode] : null;

    return (
        <Helmet>
            <script
                type="text/javascript"
                id="hs-script-loader"
                async
                defer
                src={`//js.hs-scripts.com/${HUBSPOT_TRACKING_ORG_ID}.js${
                    businessUnitId && `?businessUnitId=${businessUnitId}`
                }`}
            />
        </Helmet>
    );
};

export default HubspotTracking;
