// Libraries
import React from 'react';
import { FormattedMessage } from 'react-intl';

// Components
import Title from 'certn-ui/Title';
import { AppearRight } from 'certn-ui/Animate';
import MultiAddItemsForm from 'certn-form/MultiAddItemsForm';

// Modules
import { Regex } from 'modules';

class BankruptcyForm extends React.Component {
    bankruptciesConfig = {
        name: 'bankruptcies',
        buttonText: this.props.intl.formatMessage({
            id: 'welcome.History.BankruptcyForm.buttonText',
            defaultMessage: 'Add Bankruptcy',
        }),
        keys: [
            {
                id: 'description',
                name: this.props.intl.formatMessage({
                    id: 'common.description',
                    defaultMessage: 'Description',
                }),
                required: true,
            },
            {
                id: 'amount',
                name: this.props.intl.formatMessage({ id: 'welcome.general.amount', defaultMessage: 'Amount' }),
                rules: [
                    {
                        pattern: Regex.dollars,
                        message: this.props.intl.formatMessage({
                            id: 'error.validation.dollars',
                            defaultMessage: 'Please enter a valid dollar amount',
                        }),
                    },
                ],
                required: true,
            },
            {
                id: 'creditor',
                name: this.props.intl.formatMessage({
                    id: 'welcome.History.BankruptcyForm.creditor',
                    defaultMessage: 'Creditor',
                }),
                required: true,
            },
            {
                id: 'date_filed',
                name: this.props.intl.formatMessage({
                    id: 'welcome.History.BankruptcyForm.dateFiled',
                    defaultMessage: 'Date Filed',
                }),
                required: true,
                dateField: true,
                disableFutureDates: true,
            },
        ],
    };

    render() {
        const { bankruptcies, handleSubmit } = this.props;
        return (
            <AppearRight>
                <Title>
                    <FormattedMessage
                        id="welcome.History.BankruptcyForm.title"
                        defaultMessage="You mentioned you had a bankruptcy, what happened?"
                    />
                </Title>
                <MultiAddItemsForm items={bankruptcies} config={this.bankruptciesConfig} handleSubmit={handleSubmit} />
            </AppearRight>
        );
    }
}

export default BankruptcyForm;
