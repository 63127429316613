// Libraries
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

// Components
import { PageOneForm } from './components';

// Modules
import withNavigation from 'views/welcome/modules/WithNavigation';

// Actions & Selectors
import { setTrackPageOrder, patchInformation } from 'views/welcome/WelcomeActions';
import { getIsFetchingSilent, getInformation, getListing, getSkills } from 'views/welcome/WelcomeSelectors';

const mapStateToProps = (state) => ({
    isFetchingSilent: getIsFetchingSilent(state),
    information: getInformation(state),
    listing: getListing(state),
    skills: getSkills(state),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            patchInformation,
            setTrackPageOrder,
        },
        dispatch
    );

const propTypes = {
    // Redux Actions:
    patchInformation: PropTypes.func.isRequired,
    setTrackPageOrder: PropTypes.func.isRequired,
    // Redux Store:
    isFetchingSilent: PropTypes.bool.isRequired,
    information: PropTypes.object.isRequired,
    listing: PropTypes.object,
    applicantAccount: PropTypes.object,
    skills: PropTypes.array.isRequired,
};
const defaultProps = {
    applicantAccount: {},
    listing: {},
};

// TODO: make sure linked in changes to page four and remove history push to addresses
class Skills extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.setTrackPageOrder(['base']);
    }

    handleSubmit = (skills) => {
        const { handleNextTrack } = this.props; /* WithNavigation */

        this.props.patchInformation(skills).then(handleNextTrack);
    };

    pages = () => ({
        base: (
            <PageOneForm
                handleSubmit={this.handleSubmit}
                information={this.props.information}
                listing={this.props.listing}
                skills={this.props.skills}
                isFetchingSilent={this.props.isFetchingSilent}
                intl={this.props.intl}
            />
        ),
    });

    render() {
        return this.pages()[this.props.trackPageLocation];
    }
}

Skills.propTypes = propTypes;
Skills.defaultProps = defaultProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(withNavigation(Skills))));
