// Libraries
import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Form } from '@ant-design/compatible';
import { Row } from 'antd';

// Components
import { AppearRight } from 'certn-ui/Animate';
import Title from 'certn-ui/Title';
import { TrackNavigation } from 'views/welcome/components';
import UploadRow from '../../components/UploadRow';

const ProofOfAddressForm = ({
    handleSubmit,
    form: { validateFields },
    s3GetUploadLink,
    handleDeleteDocument,
    s3UploadLink,
    s3UploadFields,
    proofOfAddress,
    onUpload,
    error,
    handleProgress,
    fileProgress,
    uploadComplete,
    missing,
}) => (
    <AppearRight>
        <Form layout="horizontal" onSubmit={(e) => handleSubmit(e, validateFields)}>
            <Title>
                <FormattedMessage
                    id="welcome.ProofOfAddress.title"
                    defaultMessage="Please upload a photocopy of proof of address for your current address. This could be any official government, or utility document with your full name and address on it. For example - water utility bill, phone bill etc."
                />
            </Title>
            <Row gutter={16}>
                <UploadRow.UploadsWrapper>
                    <UploadRow.ReqUploadBox error={error}>
                        <UploadRow
                            data-testid="upload-row"
                            doc="PROOF_OF_ADDRESS"
                            name="Proof Of Address"
                            file={proofOfAddress}
                            s3UploadLink={s3UploadLink}
                            s3UploadFields={s3UploadFields}
                            s3GetUploadLink={s3GetUploadLink}
                            onUpload={onUpload}
                            handleDeleteDocument={handleDeleteDocument}
                            error={error}
                            handleProgress={handleProgress}
                            fileProgress={fileProgress}
                            uploadComplete={uploadComplete}
                            missing={missing}
                        />
                    </UploadRow.ReqUploadBox>
                </UploadRow.UploadsWrapper>
            </Row>
            <TrackNavigation loading={fileProgress.PROOF_OF_ADDRESS !== 0 && fileProgress.PROOF_OF_ADDRESS !== 100} />
        </Form>
    </AppearRight>
);

export default Form.create()(injectIntl(ProofOfAddressForm));
