import { Input } from 'antd';
import { getUserModeIsPM } from 'base/BaseSelectors';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { FilterItem, FilterTitle } from '../styled';

type TextSearchProps = {
    value?: string;
    onChange: (value: string) => void;
    onSearch: () => void;
};

export const TextSearch: React.FC<TextSearchProps> = ({ value, onChange, onSearch }) => {
    const intl = useIntl();
    const userModeIsPM = useSelector(getUserModeIsPM);

    return (
        <FilterItem>
            <FilterTitle>
                {userModeIsPM ? (
                    <FormattedMessage
                        id="225d8.AdvancedFiltering.search1"
                        defaultMessage="Search by owner email or candidate name, email, report ID or property address"
                    />
                ) : (
                    <FormattedMessage
                        id="225d8.AdvancedFiltering.search2"
                        defaultMessage="Search by candidate name, email, report ID or owner:"
                    />
                )}
            </FilterTitle>
            <Input.Search
                data-testid="advancedFilteringNameEmail"
                placeholder={intl.formatMessage({
                    id: '225d8.AdvancedFiltering.input1',
                    defaultMessage: 'Input search text',
                })}
                value={value}
                onSearch={onSearch}
                onChange={(e) => onChange(e.target.value)}
                allowClear
            />
        </FilterItem>
    );
};
