// Libraries
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';

// Components
import { TrackNavigation } from 'views/welcome/components';
import Title from 'certn-ui/Title';
import { AppearRight } from 'certn-ui/Animate';
import Typography from 'certnd/Typography';
import MultiAdd, { MultiAddItem, EmptyListContainer } from 'views/welcome/components/MultiAdd';
import { CloseCircleFilled } from '@ant-design/icons';
import EmploymentHistoryHelperText from './EmploymentHistoryHelperText';

// Modules
import Format from 'modules/Format';
import { useSelector } from 'react-redux';

// Actions & Selectors
import { getLaunchDarklyFlags } from 'base/BaseSelectors';

const StyledCloseIcon = styled(CloseCircleFilled)`
    margin-right: 5px;
`;

const { Text } = Typography;

const DoNotContact = styled(Text.Danger)`
    font-size: ${(props) => props.theme.font.xxs};
`;

const DateRange = styled.div`
    color: ${(props) => props.theme.color.certnGray900};
`;

const Bottom = ({ employer, intl }) => {
    const { can_contact, start_date, end_date, current } = employer;
    return (
        <>
            <DateRange>
                {`${Format.date(start_date)} - ${
                    end_date
                        ? Format.date(end_date)
                        : intl.formatMessage({
                              id: 'welcome.Educations.present',
                              defaultMessage: 'Present',
                          })
                }`}
            </DateRange>
            {/* Past employers will be contacted and applicant does not have the ability to opt-out */}
            {current && !can_contact && (
                <div>
                    <DoNotContact>
                        <StyledCloseIcon />
                        {intl.formatMessage({
                            id: 'welcome.Employers.doNotContact',
                            defaultMessage: 'Do not contact',
                        })}
                    </DoNotContact>
                </div>
            )}
        </>
    );
};

export const Base = ({ onboardingType, handleSubmit, employers, addOrEditEmployer, deleteEmployer, requests }) => {
    const intl = useIntl();
    const employerReferenceCount = employers.filter((employer) => employer.employer_reference).length;
    const { webFeatureEnableNewEmployerReferenceTrack } = useSelector(getLaunchDarklyFlags);
    const contactableEmployers = employers.filter(
        (employer) => (employer.current && employer.can_contact) || !employer.current
    );
    const disableAdd = webFeatureEnableNewEmployerReferenceTrack
        ? requests.employment_verification_years_or_individually === 'INDIVIDUALLY' &&
          requests.employment_verification_max &&
          contactableEmployers.length >= requests.employment_verification_max
        : requests.employer_references_years_or_individually === 'INDIVIDUALLY' &&
          requests.employer_references_max &&
          employerReferenceCount >= requests.employer_references_max;

    return (
        <AppearRight>
            <Title>
                {onboardingType === 'PM' ? (
                    <FormattedMessage
                        id="welcome.Employers.titlePM"
                        defaultMessage="Can you give me a few details about a current or past employer / source of income?"
                    />
                ) : (
                    <FormattedMessage
                        id="welcome.Employers.titleHR"
                        defaultMessage="Can you give me a few details about your current or past employers?"
                    />
                )}
            </Title>
            <EmploymentHistoryHelperText />
            <MultiAdd
                testKey="employer"
                title={
                    onboardingType === 'PM' ? (
                        <FormattedMessage
                            id="welcome.Employers.employersIncomeSources"
                            defaultMessage="Employers / Income Sources"
                        />
                    ) : (
                        <FormattedMessage id="welcome.general.employers" defaultMessage="Employers" />
                    )
                }
                buttonTitle={
                    onboardingType === 'PM' ? (
                        <FormattedMessage
                            id="welcome.Employers.addEmployerIncomeSource"
                            defaultMessage="ADD EMPLOYER / INCOME SOURCE"
                        />
                    ) : (
                        <FormattedMessage id="welcome.Employers.addEmployer" defaultMessage="ADD EMPLOYER" />
                    )
                }
                buttonAction={() => addOrEditEmployer()}
                disableAdd={disableAdd}
            >
                {employers && employers.length > 0 ? (
                    employers.map((employer, index) => (
                        <MultiAddItem
                            testKey="employer"
                            key={employer.id}
                            top={employer.company_name}
                            bottom={<Bottom intl={intl} employer={employer} />}
                            deleteText={intl.formatMessage({
                                id: 'multiAddItem.deleteThisEmployer',
                                defaultMessage: 'Delete this employer?',
                            })}
                            onEdit={() => addOrEditEmployer(index)}
                            onDelete={() => deleteEmployer(index)}
                        />
                    ))
                ) : (
                    <EmptyListContainer>
                        <FormattedMessage id="welcome.Employers.noEmployers" defaultMessage="No Employers added yet." />
                    </EmptyListContainer>
                )}
            </MultiAdd>
            <TrackNavigation handleSubmit={handleSubmit} />
        </AppearRight>
    );
};

export default Base;
