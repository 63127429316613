// Libraries
import React, { useState } from 'react';
import { Upload, message, Button as AntdButton } from 'antd';
import { FormattedMessage } from 'react-intl';
import { intl } from 'components/GlobalProvider';

// Modules
import Format from 'modules/Format';

const UploadBase64 = (props) => {
    const [files, setFiles] = useState(props.documentState?.document || []);

    // File type check
    const isProperFileType = (type) => {
        if (type) {
            if (props.image) return /jpg|jpeg|png/.test(type);
            return true;
        }
        return false;
    };

    const uploadOptions = {
        customRequest({ file, onSuccess }) {
            // Do not setBase64 if wrong file type
            if (!isProperFileType(file.type)) return false;

            // Converting to base64 and setting parent state
            const fileReaderInstance = new FileReader();
            fileReaderInstance.readAsDataURL(file);
            fileReaderInstance.onload = () => {
                let base64data = fileReaderInstance.result;
                if (base64data) base64data = Format.base64Format(base64data);
                props.setDocument(base64data);
            };
            // customRequest needs to run onSuccess to complete without error, we dont actually want to do anything so just fire in string
            setTimeout(() => {
                onSuccess('ok');
            }, 0);
        },

        onChange(info) {
            let fileList = [...info.fileList];
            const properFileType = isProperFileType(info.file.type);

            // Limit the number of uploaded files
            fileList = fileList.slice(-1);

            // Do not let user upload anything other than image document types
            if (info.file.status === 'uploading' && !properFileType) {
                if (props.errorMessage) message.error(props.errorMessage);
                if (props.image)
                    message.error(
                        intl.formatMessage({
                            id: 'e2d10.UploadFlow.photoTypeError',
                            defaultMessage: 'Document must be: jpg, jpeg, or png',
                        })
                    );
                else
                    message.error(
                        intl.formatMessage({
                            id: 'e2d10.UploadFlow.unrecognizedFileType',
                            defaultMessage: 'Unrecognized file type',
                        })
                    );

                setFiles([]);
            }

            // On Complete
            if (properFileType) {
                if (info.file.status === 'done') {
                    message.success(
                        intl.formatMessage({
                            id: 'e2d10.UploadFlow.uploadSuccess',
                            defaultMessage: 'File uploaded successfully',
                        })
                    );
                } else if (info.file.status === 'error') {
                    message.error(
                        intl.formatMessage({
                            id: 'e2d10.UploadFlow.uploadError',
                            defaultMessage: 'File upload failed.',
                        })
                    );
                }

                setFiles(fileList);
            }
        },

        onRemove() {
            props.setDocument(undefined);
        },
    };

    return (
        <Upload
            fileList={files}
            accept={props.image ? 'image/*,.jpg,.jpeg,.png' : '*'}
            key={props.key}
            listType={props.listType || 'default'}
            {...uploadOptions}
        >
            {props.children || ( // Display custom button or regular upload
                <AntdButton icon="upload">
                    <FormattedMessage id="welcome.Documents.upload" defaultMessage="Upload" />
                </AntdButton>
            )}
        </Upload>
    );
};

export default UploadBase64;
