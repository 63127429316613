import { useContext, useMemo } from 'react';
import Form from 'certnd/Form';
import { FormattedMessage } from 'react-intl';
import { intl } from 'components/GlobalProvider';
import { PackagesContext } from '../../Packages.context';
import useCheckContent from 'views/manager/features/ScreenApplicant/components/ScreenApplicant/MicroServiceContent';
import { QUICK_SCREEN_CHECKS_WITH_ADDITIONS_HR, CHECK_REQUEST } from 'base/BaseConstants';
import { canAddCheck, canQuickscreenChecks, getInvalidCheck } from '../../utils/check-utils';
import { Tag } from 'antd';

const getServiceConfig = (content, services = []) =>
    services.map((service) => {
        const checkContent = content[service.request_flag];
        return { ...service, request: service.request_flag, title: service.title || checkContent.title };
    });

export const CHECK_ERROR_MESSAGES = {
    REQUIRE_ENHANCED_ID: (
        <FormattedMessage
            id="22572.Packages.ServicesValid2"
            defaultMessage="International Criminal Record Checks Require OneID Verification."
        />
    ),
    REQUIRE_ID: (
        <FormattedMessage
            id="22572.Packages.ServicesValid3"
            defaultMessage="Criminal Record checks require Identity Verification."
        />
    ),
    ERROR: (
        <FormattedMessage
            id="00z35.ScreenApplicant.errorSelectingPackage"
            defaultMessage="Error Selecting Package, please contact support"
        />
    ),
};

export const CHECK_ERROR_CFG = {
    [CHECK_REQUEST.INTERNATIONAL_CRIMINAL_RECORD_CHECK]: CHECK_ERROR_MESSAGES.REQUIRE_ENHANCED_ID,
    [CHECK_REQUEST.CRIMINAL_RECORD_CHECK]: CHECK_ERROR_MESSAGES.REQUIRE_ID,
    [CHECK_REQUEST.ENHANCED_CRIMINAL_RECORD_CHECK]: CHECK_ERROR_MESSAGES.REQUIRE_ID,
    [CHECK_REQUEST.VULNERABLE_SECTOR_CRIMINAL_RECORD_CHECK]: CHECK_ERROR_MESSAGES.REQUIRE_ID,
    DEFAULT: CHECK_ERROR_MESSAGES.ERROR,
};

export const PackageServices = ({ validChecks }) => {
    const { form, editingPackage, allowQuickscreen } = useContext(PackagesContext);

    const serviceChecksContent = useCheckContent();
    const isQuickscreen = allowQuickscreen && editingPackage.is_quickscreen;

    // filters constants that are permitted based on validChecks
    const validQuickscreen = validChecks.filter((check) =>
        QUICK_SCREEN_CHECKS_WITH_ADDITIONS_HR.includes(check.request_flag)
    );
    const services = useMemo(() => {
        if (isQuickscreen) {
            return getServiceConfig(serviceChecksContent, validQuickscreen);
        }

        return getServiceConfig(serviceChecksContent, validChecks);
    }, [isQuickscreen, serviceChecksContent, validChecks, validQuickscreen]);
    const requestedServices = form.getFieldValue('requested_packages') ?? [];

    const tagRender = (props) => {
        let color;
        if (isQuickscreen && !QUICK_SCREEN_CHECKS_WITH_ADDITIONS_HR.includes(props.value)) {
            color = 'red';
        }

        return (
            <Tag {...props} color={color}>
                {props.label}
            </Tag>
        );
    };

    return (
        <Form.Select
            form={form}
            title={intl.formatMessage({
                id: '22572.Packages.ServicesTitle',
                defaultMessage: 'Select Package Checks',
            })}
            data-testid="PackageServices"
            fieldName="requested_packages"
            mode="multiple"
            tagRender={tagRender}
            style={{ width: '100%' }}
            placeholder={<FormattedMessage id="22572.Packages.ServicesPlaceholder" defaultMessage="Please Select" />}
            selectOptions={services.map((service) => ({
                key: service.request,
                title: service.title,
                value: service.request,
                'data-testid': `PackageServices-${service.request}`,
                disabled: !canAddCheck(requestedServices, service.request),
            }))}
            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
            options={{
                initialValue: editingPackage.services,
                validateTrigger: 'onChange',
                rules: [
                    {
                        required: true,
                        message: (
                            <FormattedMessage
                                id="22572.Packages.ServicesValid1"
                                defaultMessage="Please select at least one service for your package."
                            />
                        ),
                        type: 'array',
                    },
                    {
                        validator: (rule, value, callback) => {
                            const invalidValue = getInvalidCheck(value, services);
                            if (invalidValue) {
                                const errorMessage = CHECK_ERROR_CFG[invalidValue] || CHECK_ERROR_CFG.DEFAULT;
                                return callback(errorMessage);
                            }
                            if (isQuickscreen && !canQuickscreenChecks(value)) {
                                return callback(
                                    <FormattedMessage
                                        id="22572.Packages.ServiceNonQuickscreenable"
                                        defaultMessage="Selected checks cannot go through Quickscreen."
                                    />
                                );
                            }
                            return callback();
                        },
                    },
                ],
            }}
        />
    );
};
