import React, { useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Button } from 'antd';
import SignatureCanvas from 'react-signature-canvas';
import { postRequest } from 'utils/http';
import { ErrorAlertAPI, ErrorAlertCustom } from 'certn-ui/ErrorAlert';
import { TrackNavigation } from 'views/welcome/components';
import { MouseSignatureWrapper } from './styled';

type MouseSignatureBoxProps = {
    handleNextTrack: () => void;
    applicantId: string;
};

export const MouseSignatureBox: React.FC<MouseSignatureBoxProps> = ({ handleNextTrack, applicantId }) => {
    const intl = useIntl();
    const storageKey = `signatureImage_${applicantId.substring(0, 6)}`;
    const sigCanvas = React.useRef<SignatureCanvas>(null);

    useEffect(() => {
        const storedSignatureImage = localStorage.getItem(storageKey);

        if (sigCanvas.current && storedSignatureImage) {
            sigCanvas.current.fromDataURL(storedSignatureImage);
        }
    }, [storageKey]);

    const clear = () => {
        sigCanvas.current?.clear();
        localStorage.removeItem(storageKey);
    };

    const uploadImage = async () => {
        if (sigCanvas.current?.isEmpty()) {
            ErrorAlertCustom({
                title: intl.formatMessage({
                    id: 'welcome.mouseSignature.errorMessageTitle',
                    defaultMessage: 'Please add your signature before continuing',
                }),

                description: intl.formatMessage({
                    id: 'welcome.mouseSignature.errorMessageBody',
                    defaultMessage:
                        'If you continue to have issues, please try our support chat window or contact support@certn.co.',
                }),
            });
            return;
        }

        const url = sigCanvas.current?.getTrimmedCanvas().toDataURL('image/png');
        localStorage.setItem(storageKey, sigCanvas.current?.toDataURL('image/png') || '');

        try {
            await postRequest({
                version: 'v2',
                endpoint: `/applicant/${applicantId}/mouse-signature/create`,
                body: JSON.stringify({ signature_image: url }),
            });
            handleNextTrack();
        } catch (error) {
            ErrorAlertAPI(error);
        }
    };

    return (
        <MouseSignatureWrapper>
            <SignatureCanvas
                ref={sigCanvas}
                canvasProps={{
                    className: 'sigCanvasBox',
                }}
                data-testid="mouseSignatureBox"
            />

            <Button name="sig-clear" size="large" type="ghost" onClick={clear} className="clear-signature-btn">
                <FormattedMessage id="button.clear" defaultMessage="Clear" />
            </Button>
            <TrackNavigation handleSubmit={uploadImage} />
        </MouseSignatureWrapper>
    );
};
