// Libraries
import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import { Link } from 'react-router-dom';
import { InfoCircleOutlined, LockOutlined } from '@ant-design/icons';
import { Form, Input, Popover } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { PrivacyPolicyLink, TermsOfUseLink } from 'certn-ui';

// Components
import { ButtonLine } from 'certn-ui/Button';
import { AppearRight } from 'certn-ui/Animate';
import FormLayout from 'certn-form/FormLayout';
import { TrackNavigation } from 'views/welcome/components';
import { Box } from 'certn-ui/Layout';
import { CertnFormLabel } from 'certn-ui/Text';

// Modules
import Auth from 'modules/Auth';
import { InputTransform } from 'modules';
import { simplePasswordValidator } from 'modules/validation';
import { useSelector } from 'react-redux';

// Actions & Selectors
import {
    getInformation,
    getApplicantAccount,
    getApplicantLogin,
    getConsentToMarketing,
    getApplicant,
} from 'views/welcome/WelcomeSelectors';
import { CheckboxGroup, RedactedText } from './styled';
import { Title } from './Title';
import { FormProps } from 'antd/lib/form';
import { Checkbox } from 'certn-ui/Checkbox';
import { CHECK_REQUEST } from 'base/BaseConstants';
import { useDisplayDisclosureAgreement } from '../hooks';
import { MyCRCDisclosureContract } from 'views/welcome/views/signUp/SignUpForm/Legal/MyCRCDisclosure';
import { getParams } from 'base/BaseSelectors';
import { getRedactedContactInfo, verifyInviteRoute } from '../utils';
import { CustomAntDMatchedInternationalPhone } from 'components/InternationalPhone';
import { InternationalPhoneWrapper } from 'components/EmailAddresses/styled';
import { isObject } from 'utils/typescript';

export interface SecureSMSErrorType {
    errors?: {
        email?: string;
        phone?: string;
    };
}

export const isSecureSMSErrorType = (obj: unknown): obj is SecureSMSErrorType =>
    isObject(obj) &&
    isObject(obj.errors) &&
    (typeof obj.errors.email === 'string' || typeof obj.errors.phone === 'string');

export type EmailFormProps = {
    handleSubmit: FormProps['onFinish'];
    disclosureSigned: boolean;
    setDisclosureSigned: (signed: boolean) => void;
    secureSMSErrors: SecureSMSErrorType | undefined;
};

export const EmailForm: React.FC<EmailFormProps> = ({
    handleSubmit,
    disclosureSigned,
    setDisclosureSigned,
    secureSMSErrors,
}) => {
    const intl = useIntl();
    const information = useSelector(getInformation);
    const applicant = useSelector(getApplicant);
    const applicantAccount = useSelector(getApplicantAccount);
    const isApplicantLogin = useSelector(getApplicantLogin);
    const consentToMarketing = useSelector(getConsentToMarketing);
    const [form] = Form.useForm();
    const requireLogin = isApplicantLogin && !Auth.isApplicantAuthenticated();
    const termsAccepted = information?.terms_accepted;
    const [inviteRouteValidated, setInviteRouteValidated] = useState(false);
    const [inviteRouteValidationAttempted, setInviteRouteValidationAttempted] = useState(false);
    const params = useSelector(getParams);
    const isUkCheck =
        applicant &&
        (applicant[CHECK_REQUEST.UK_BASIC_DBS_CHECK] ||
            applicant[CHECK_REQUEST.UK_BASIC_DS_CHECK] ||
            applicant[CHECK_REQUEST.UK_RIGHT_TO_WORK_CHECK]);
    const showMarketingField = isApplicantLogin;
    const displayDisclosure = useDisplayDisclosureAgreement();
    const [applicantEmail, setApplicantEmail] = useState(get(applicantAccount, 'email', null));
    const [redactedEmail, setRedactedEmail] = useState('');
    const [redactedPhone, setRedactedPhone] = useState('');

    useEffect(() => {
        if (params && applicant?.is_using_secure_sms) {
            verifyInviteRoute(params)
                .then((data) => {
                    if (data?.email) {
                        setApplicantEmail(data?.email);
                        form.setFieldsValue({
                            email: data?.email,
                        });
                    }
                    setInviteRouteValidated(true);
                    setInviteRouteValidationAttempted(true);
                })
                .catch(() => {
                    setInviteRouteValidated(false);
                    setInviteRouteValidationAttempted(true);
                });
        }
    }, [params, applicant, form]);

    useEffect(() => {
        if (!params?.session) return;
        getRedactedContactInfo(params.session).then((data) => {
            if (data?.email) {
                setRedactedEmail(data?.email);
            }
            if (data?.phone) {
                setRedactedPhone(data?.phone);
            }
        });
    }, [params.onboardingId, params.session]);

    useEffect(() => {
        if (secureSMSErrors?.errors?.email) {
            form.setFields([
                {
                    name: 'secure_sms_confirm_email',
                    errors: [secureSMSErrors?.errors?.email],
                },
            ]);
        }
        if (secureSMSErrors?.errors?.phone) {
            form.setFields([
                {
                    name: 'secure_sms_confirm_phone',
                    errors: [secureSMSErrors?.errors?.phone],
                },
            ]);
        }
    }, [form, secureSMSErrors]);

    // Hiding TOU for now (except for UK - SSBUK-122) and replacing with Website Terms IronClad link in GUARD-165
    const showTermsOfUse = !!isUkCheck;

    return applicant?.is_using_secure_sms && inviteRouteValidationAttempted && !inviteRouteValidated ? (
        <>
            <FormattedMessage
                id="welcome.InvitationRouteValidationFailed.message"
                defaultMessage="Something went wrong. Please try accessing the application again from the link that was provided to you."
            />
        </>
    ) : (
        <AppearRight>
            <Form form={form} onFinish={handleSubmit}>
                <Title />
                <Box style={{ margin: '15px auto' }} size="med">
                    <FormattedMessage
                        id="welcome.Email.subTitle"
                        defaultMessage="You will be guided through several steps to complete your background check."
                    />{' '}
                    <FormattedMessage
                        id="welcome.Email.savedProgress"
                        defaultMessage="Don’t worry! Your progress will be saved as you go. You can securely leave and come back until you submit your application."
                    />
                    <br />
                    <br />
                    <FormattedMessage
                        id="welcome.Email.subTitleTwo"
                        defaultMessage="Once you have submitted your details we will begin to process your background screening report. We will process your personal information in accordance to our Terms of Use and Privacy Policy. Before you begin, please confirm that:"
                    />
                </Box>
                <FormLayout>
                    <CheckboxGroup>
                        <Form.Item
                            name="age_accepted"
                            key="age_accepted"
                            valuePropName="checked"
                            initialValue={termsAccepted}
                            style={{ marginBottom: '0px' }}
                        >
                            <Checkbox disabled={termsAccepted}>
                                {isUkCheck ? (
                                    <FormattedMessage
                                        id="welcome.Email.agreeToAgeNoNumber"
                                        defaultMessage="I can confirm I am of legal age"
                                    />
                                ) : (
                                    <FormattedMessage
                                        id="welcome.Email.agreeToAge"
                                        defaultMessage="I am over 18 years of age"
                                    />
                                )}
                            </Checkbox>
                        </Form.Item>
                        {showTermsOfUse && (
                            <Form.Item
                                name="terms_of_use_accepted"
                                key="terms_of_use_accepted"
                                valuePropName="checked"
                                initialValue={termsAccepted}
                                style={{ marginBottom: '0px' }}
                            >
                                <Checkbox disabled={termsAccepted}>
                                    <FormattedMessage
                                        id="welcome.Email.agreeToTerms"
                                        defaultMessage="I have read, understand and agree to the {termsOfUse}"
                                        values={{ termsOfUse: <TermsOfUseLink /> }}
                                    />
                                </Checkbox>
                            </Form.Item>
                        )}
                        <Form.Item
                            name="privacy_policy_accepted"
                            key="privacy_policy_accepted"
                            valuePropName="checked"
                            initialValue={termsAccepted}
                            style={{ marginBottom: '0px' }}
                        >
                            <Checkbox disabled={termsAccepted}>
                                <FormattedMessage
                                    id="welcome.Email.agreeToPrivacyPolicy"
                                    defaultMessage="I have read, understand and agree to the {privacyPolicy}"
                                    values={{ privacyPolicy: <PrivacyPolicyLink /> }}
                                />
                            </Checkbox>
                        </Form.Item>
                        {displayDisclosure && (
                            <Form.Item
                                name="disclosure_agreement_accepted"
                                key="disclosure_agreement_accepted"
                                valuePropName="checked"
                                style={{ marginBottom: '0px' }}
                            >
                                <MyCRCDisclosureContract
                                    disclosureSigned={disclosureSigned}
                                    setDisclosureSigned={setDisclosureSigned}
                                    signerId={applicant?.id}
                                />
                            </Form.Item>
                        )}
                        {showMarketingField && (
                            <Form.Item
                                name="consent_to_marketing"
                                valuePropName="checked"
                                initialValue={consentToMarketing}
                                style={{ marginBottom: '0px', marginTop: '5px' }}
                            >
                                <Checkbox>
                                    <FormattedMessage
                                        id="welcome.Email.consentToMarketing"
                                        defaultMessage="I want to receive news, feature updates, discounts, and offers from Certn. (Optional)"
                                    />
                                </Checkbox>
                            </Form.Item>
                        )}
                    </CheckboxGroup>
                    {applicant?.is_using_secure_sms && params.inviteRoute.toLowerCase() === 'sms' && (
                        <>
                            <Form.Item
                                name="secure_sms_confirm_email"
                                getValueFromEvent={InputTransform.noSpaces}
                                style={{ margin: '0px' }}
                                rules={[
                                    {
                                        type: 'email',
                                        message: (
                                            <FormattedMessage
                                                id="error.validation.email.invalid"
                                                defaultMessage="The input is not a valid E-mail!"
                                            />
                                        ),
                                    },
                                    {
                                        required: true,
                                        message: (
                                            <FormattedMessage
                                                id="error.validation.email.required"
                                                defaultMessage="Please input your E-mail!"
                                            />
                                        ),
                                    },
                                ]}
                            >
                                <Input
                                    size="large"
                                    data-testid="secure_sms_confirm_email"
                                    placeholder={intl.formatMessage({
                                        id: 'common.emailAddress',
                                        defaultMessage: 'Email Address',
                                    })}
                                    autoFocus
                                    addonBefore={
                                        <Popover
                                            content={
                                                <FormattedMessage
                                                    id="common.emailAddress"
                                                    defaultMessage="Email Address"
                                                />
                                            }
                                        >
                                            <InfoCircleOutlined />
                                        </Popover>
                                    }
                                />
                            </Form.Item>
                            <RedactedText>Sent to {redactedEmail}</RedactedText>
                            <InternationalPhoneWrapper>
                                <Form.Item name="secure_sms_confirm_phone" style={{ flexGrow: 1, margin: 0 }}>
                                    <CustomAntDMatchedInternationalPhone
                                        key="int-phone"
                                        inputSettings={{
                                            inputSize: 'lg',
                                            showPopOver: false,
                                        }}
                                        placeholder="Phone Number"
                                    />
                                </Form.Item>
                            </InternationalPhoneWrapper>
                            <RedactedText>Sent to {redactedPhone}</RedactedText>
                        </>
                    )}
                    {!(applicant?.is_using_secure_sms && params.inviteRoute.toLowerCase() === 'sms') && (
                        <div style={{ display: !applicantEmail ? 'block' : 'none' }}>
                            {/* hide form item if applicant email is present. cant conditionally render because handleSubmit prop relies on its value */}
                            <CertnFormLabel>
                                <FormattedMessage
                                    id="welcome.general.yourEmailAddress"
                                    defaultMessage="Please confirm your email address"
                                />
                            </CertnFormLabel>
                            <Form.Item
                                name="email"
                                initialValue={applicantEmail}
                                getValueFromEvent={InputTransform.noSpaces}
                                rules={[
                                    {
                                        type: 'email',
                                        message: (
                                            <FormattedMessage
                                                id="error.validation.email.invalid"
                                                defaultMessage="The input is not a valid E-mail!"
                                            />
                                        ),
                                    },
                                    {
                                        required: true,
                                        message: (
                                            <FormattedMessage
                                                id="error.validation.email.required"
                                                defaultMessage="Please input your E-mail!"
                                            />
                                        ),
                                    },
                                ]}
                            >
                                <Input
                                    disabled={isApplicantLogin && Auth.isApplicantAuthenticated()}
                                    size="large"
                                    data-testid="email"
                                    placeholder={intl.formatMessage({
                                        id: 'common.emailAddress',
                                        defaultMessage: 'Email Address',
                                    })}
                                    autoFocus
                                    addonBefore={
                                        <Popover
                                            content={
                                                <FormattedMessage
                                                    id="common.emailAddress"
                                                    defaultMessage="Email Address"
                                                />
                                            }
                                        >
                                            <InfoCircleOutlined />
                                        </Popover>
                                    }
                                />
                            </Form.Item>
                        </div>
                    )}
                    {requireLogin && (
                        <Form.Item
                            name="password"
                            key="password"
                            rules={[
                                {
                                    required: true,
                                    message: (
                                        <FormattedMessage
                                            id="error.validation.password.required"
                                            defaultMessage="Please input your Password!"
                                        />
                                    ),
                                },
                                {
                                    validator: (_, value) => {
                                        const passwordError = simplePasswordValidator(value);
                                        if (passwordError) return Promise.reject(passwordError);
                                        return Promise.resolve();
                                    },
                                },
                            ]}
                        >
                            <Input.Password
                                visibilityToggle
                                size="large"
                                placeholder={intl.formatMessage({
                                    id: 'common.password',
                                    defaultMessage: 'Password',
                                })}
                                addonBefore={
                                    <Popover
                                        content={<FormattedMessage id="common.password" defaultMessage="Password" />}
                                    >
                                        <LockOutlined />
                                    </Popover>
                                }
                            />
                        </Form.Item>
                    )}
                </FormLayout>
                <TrackNavigation hideBack />
                {requireLogin && (
                    <ButtonLine>
                        <Link data-testid="signin-link" to="/applicant/signin">
                            <FormattedMessage
                                id="welcome.Email.haveAccount"
                                defaultMessage="Already have an account?"
                            />
                        </Link>
                    </ButtonLine>
                )}
            </Form>
        </AppearRight>
    );
};
