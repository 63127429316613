import { requestReducer, responseReducer, errorReducer, defaultState as defaultHttpState } from 'utils/http';
import {
    GET_PROPERTIES_REQ,
    GET_PROPERTIES_RES,
    GET_PROPERTIES_ERR,
    GET_ALL_PROPERTIES_REQ,
    GET_ALL_PROPERTIES_RES,
    GET_ALL_PROPERTIES_ERR,
    GET_PROPERTY_REQ,
    GET_PROPERTY_RES,
    GET_PROPERTY_ERR,
    POST_PROPERTIES_REQ,
    POST_PROPERTIES_RES,
    POST_PROPERTIES_ERR,
    PUT_PROPERTIES_REQ,
    PUT_PROPERTIES_RES,
    PUT_PROPERTIES_ERR,
    DEL_PROPERTIES_REQ,
    DEL_PROPERTIES_RES,
    DEL_PROPERTIES_ERR,
    SET_IS_ACTIVE,
    SET_SEARCH_BY,
    SET_ORDERING,
    SET_FILTER_BY,
    SET_PAGE_SIZE,
    RESET_LIST,
} from './PropertiesActions';

const initialState = {
    ...defaultHttpState,
    properties: [],
    allProperties: [],
    allPropertyNames: [],
    property: {},
    newProperty: {},
    isActive: true,
    searchBy: null,
    ordering: 'last_updated',
    reverseOrder: true,
    filterBy: null,
    numPages: 1,
    previousPage: undefined,
    nextPage: undefined,
    currentPage: 1,
    pageSize: 7,
};

const properties = (state = initialState, action) => {
    switch (action.type) {
        case GET_PROPERTIES_REQ:
        case GET_ALL_PROPERTIES_REQ:
        case GET_PROPERTY_REQ:
        case POST_PROPERTIES_REQ:
        case PUT_PROPERTIES_REQ:
        case DEL_PROPERTIES_REQ:
            return {
                ...state,
                ...requestReducer(),
            };
        case GET_PROPERTIES_RES:
            return {
                ...state,
                ...responseReducer(),
                properties: action.payload.properties,
                numProperties: action.payload.numProperties,
                previousPage: action.payload.previousPage,
                nextPage: action.payload.nextPage,
                currentPage: action.payload.currentPage,
            };
        case GET_ALL_PROPERTIES_RES:
            return {
                ...state,
                ...responseReducer(),
                allProperties: action.payload.allProperties,
                numAllProperties: 0,
                // numAllProperties: action.payload.numAllProperties,
            };
        case GET_PROPERTY_RES:
            return {
                ...state,
                ...responseReducer(),
                property: action.payload.property,
            };
        case POST_PROPERTIES_RES:
            return {
                ...state,
                ...responseReducer(),
                newProperty: action.payload.newProperty,
                allProperties: [...state.allProperties, action.payload.newProperty],
            };
        case PUT_PROPERTIES_RES:
            return {
                ...state,
                ...responseReducer(),
                property: action.payload.property,
            };
        case DEL_PROPERTIES_RES:
            return {
                ...state,
                ...responseReducer(),
            };
        case GET_PROPERTIES_ERR:
        case GET_ALL_PROPERTIES_ERR:
        case GET_PROPERTY_ERR:
        case POST_PROPERTIES_ERR:
        case PUT_PROPERTIES_ERR:
        case DEL_PROPERTIES_ERR:
            return {
                ...state,
                ...errorReducer(action.payload.error),
            };
        case SET_IS_ACTIVE:
            return {
                ...state,
                isActive: action.payload.isActive,
            };
        case SET_SEARCH_BY:
            return {
                ...state,
                searchBy: action.payload.searchBy,
            };
        case SET_ORDERING:
            return {
                ...state,
                ordering: action.payload.ordering,
                reverseOrder: action.payload.reverseOrder,
            };
        case SET_FILTER_BY:
            return {
                ...state,
                filterBy: action.payload.filterBy,
            };
        case SET_PAGE_SIZE:
            return {
                ...state,
                pageSize: action.payload.pageSize,
            };
        case RESET_LIST:
            return {
                ...state,
                searchBy: null,
                ordering: 'last_updated',
                reverseOrder: true,
                filterBy: {},
            };
        default:
            return state;
    }
};

export default properties;
