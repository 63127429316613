// Libraries
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { intl } from 'components/GlobalProvider';
import { FormattedMessage } from 'react-intl';

// Components
import Form from 'certnd/Form';
import Typography from 'certnd/Typography';

// Modules
import Auth from 'modules/Auth';

// Actions & Selectors
import { getSettings, getCurrentTeam } from 'views/manager/views/settings/SettingsSelectors';

const { Heading } = Typography;

const mapStateToProps = (state) => ({
    settings: getSettings(state),
    currentTeam: getCurrentTeam(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

const TenancyReferences = ({ currentTeam, form }) => {
    // Going to be reintroduced back very soon along with the commented code below
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const yearsRequired = [
        {
            title: intl.formatMessage({ id: 'common.three', defaultMessage: 'Three' }),
            key: 3,
            value: 3,
        },
        {
            title: intl.formatMessage({ id: 'common.five', defaultMessage: 'Five' }),
            key: 5,
            value: 5,
        },
        {
            title: intl.formatMessage({ id: '7a1d9.TenancyReferences.ListSeven', defaultMessage: 'Seven' }),
            key: 7,
            value: 7,
        },
    ];

    const canEdit = Auth.isPermissionLevel('manager');

    return (
        <Form.Block>
            <Heading.H3>
                <FormattedMessage id="7a1d9.TenancyReferences.PageTitle" defaultMessage="Tenancy References" />
            </Heading.H3>
            <Form.InputNumber
                hideBorder
                form={form}
                fieldName="address_references_min"
                options={{
                    initialValue: currentTeam.address_references_min || 1,
                }}
                title={intl.formatMessage({
                    id: '7a1d9.TenancyReferences.MinRefTitle',
                    defaultMessage: 'Minimum number of tenancy references',
                })}
                description={intl.formatMessage({
                    id: '7a1d9.TenancyReferences.MinRefDescription',
                    defaultMessage: "What's the minimum number of tenancy references to check?",
                })}
                autoComplete="false"
                min={1}
                max={form.getFieldValue('address_references_max')} // block increase if Max <= Min
            />

            {/* <Form.InputNumber
                hideBorder
                form={form}
                fieldName="address_references_max"
                options={{
                    initialValue: currentTeam.address_references_max || 1,
                }}
                title="Maximum number of tenancy references"
                description="What's the maximum number of tenancy references to check? Users will not be able to include more than this amount."
                autoComplete="false"
                min={form.getFieldValue('address_references_min')} // block decrease if Min <= Max
                max={10}
            />

            <Form.Select
                form={form}
                fieldName="address_references_years"
                title="Years of tenancy references to verify"
                description="How many years dating back of address references should we verify up to?"
                options={{ initialValue: currentTeam.address_references_years || 3 }}
                selectOptions={yearsRequired}
                disabled={!canEdit}
            /> */}

            <Form.Button disabled={!canEdit}>
                <FormattedMessage id="common.save" defaultMessage="Save" />
            </Form.Button>
        </Form.Block>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(TenancyReferences);
