// Libraries
import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Form } from '@ant-design/compatible';

// Components
import { AppearRight } from 'certn-ui/Animate';
import Title from 'certn-ui/Title';
import MultiAddItemsForm from 'certn-form/MultiAddItemsForm';

// Modules
import { Regex } from 'modules';

const PetsForm = ({ handleSubmit, pets, intl }) => {
    const petsConfig = {
        name: 'pet_details',
        buttonText: intl.formatMessage({ id: 'welcome.Supplemental.pets.addPet', defaultMessage: 'ADD PET' }),
        keys: [
            {
                id: 'type',
                name: intl.formatMessage({ id: 'welcome.Supplemental.pets.typeBreed', defaultMessage: 'Type / Breed' }),
                required: true,
            },
            {
                id: 'weight',
                name: intl.formatMessage({ id: 'welcome.Supplemental.pets.weightLbs', defaultMessage: 'Weight (lbs)' }),
                rules: [
                    {
                        pattern: Regex.weight,
                        message: intl.formatMessage({
                            id: 'error.validation.weight',
                            defaultMessage: 'Please enter a valid weight (numbers & decimals only)',
                        }),
                    },
                ],
                required: true,
            },
        ],
    };
    return (
        <AppearRight>
            <Title>
                <FormattedMessage
                    id="welcome.Supplemental.pets.formTitle"
                    defaultMessage="You mentioned you have a pet. Please describe each pet and its approximate weight."
                />
            </Title>
            <MultiAddItemsForm size="6" offset="6" items={pets} config={petsConfig} handleSubmit={handleSubmit} />
        </AppearRight>
    );
};

export default Form.create()(injectIntl(PetsForm));
