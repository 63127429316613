import moment from 'moment';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

import { getRequest, postRequest } from 'utils/http';
import { uniqueEmails } from 'utils/unique-emails';
import { downloadFile, buildApplicationFileName } from 'modules/files';
import { getDateTimeFormat } from 'modules/Format';

import { getUserMode } from 'base/BaseSelectors';
import { getApplications as getApplicationsHR } from 'views/manager/views/hr/views/applications/ApplicationsSelectors';
import { getApplications as getApplicationsPM } from 'views/manager/views/pm/views/applications/ApplicationsSelectors';

const NAMESPACE = 'sharedManager/';
export const GET_CONSENT_DOCUMENTS_REQ = `${NAMESPACE}GET_CONSENT_DOCUMENTS_REQ`;
export const GET_CONSENT_DOCUMENTS_RES = `${NAMESPACE}GET_CONSENT_DOCUMENTS_RES`;
export const GET_CONSENT_DOCUMENTS_ERR = `${NAMESPACE}GET_CONSENT_DOCUMENTS_ERR`;
export const GET_BULK_CONSENT_DOCUMENTS_REQ = `${NAMESPACE}GET_BULK_CONSENT_DOCUMENTS_REQ`;
export const GET_BULK_CONSENT_DOCUMENTS_RES = `${NAMESPACE}GET_BULK_CONSENT_DOCUMENTS_RES`;
export const GET_BULK_CONSENT_DOCUMENTS_ERR = `${NAMESPACE}GET_BULK_CONSENT_DOCUMENTS_ERR`;
export const DELETE_REPORT_CSV_RES = `${NAMESPACE}DELETE_REPORT_CSV_RES`;
export const POST_APPLICATIONS_SEND_REQ = `${NAMESPACE}POST_APPLICATIONS_SEND_REQ`;
export const POST_APPLICATIONS_SEND_RES = `${NAMESPACE}POST_APPLICATIONS_SEND_RES`;
export const POST_APPLICATIONS_SEND_ERR = `${NAMESPACE}POST_APPLICATIONS_SEND_ERR`;
export const SET_FILTERS = `${NAMESPACE}SET_FITLERS`;

export const fetchConsentDocuments = ({ applicantId }) => async (dispatch, getState) => {
    dispatch({
        type: GET_CONSENT_DOCUMENTS_REQ,
    });

    try {
        const response = await getRequest({
            version: 'v1',
            endpoint: `/onboarding/${applicantId}/signed_consent_docs/`,
        });
        const userMode = getUserMode(getState());
        let application;
        if (userMode === 'HR') {
            application = getApplicationsHR(getState()).find((x) => x.id === applicantId);
        } else {
            application = getApplicationsPM(getState()).find((x) => x.id === applicantId);
        }
        const fileName = buildApplicationFileName(application);
        downloadFile({ fileContents: response, fileName, fileType: 'application/pdf' });
        dispatch({
            type: GET_CONSENT_DOCUMENTS_RES,
        });
    } catch (error) {
        dispatch({
            type: GET_CONSENT_DOCUMENTS_ERR,
            payload: { error },
        });
        throw new Error();
    }
};

export const fetchBulkConsentDocuments = (applicantIds) => async (dispatch) => {
    dispatch({
        type: GET_BULK_CONSENT_DOCUMENTS_REQ,
    });

    try {
        const response = await postRequest({
            version: 'v1',
            endpoint: `/onboarding/signed_consent_docs/batch/`,
            body: JSON.stringify(applicantIds),
        });
        dispatch({
            type: GET_BULK_CONSENT_DOCUMENTS_RES,
        });
        const fileName = `Batch-Reports-PDF_${moment().format(getDateTimeFormat())}.zip`;
        downloadFile({ fileContents: response, fileName, fileType: 'application/zip' });
    } catch (error) {
        dispatch({
            type: GET_BULK_CONSENT_DOCUMENTS_ERR,
            payload: { error },
        });
        throw new Error();
    }
};

export const clearCSVLink = () => (dispatch) => {
    dispatch({
        type: DELETE_REPORT_CSV_RES,
    });
};

/**
 * Submits the application request containing the data and each email address.
 * @param {Object} data the data to submit with each applicant
 * @param {object[]} contactInfoList array of objects containing contact info { email: string, phone: {} }
 * @param {string[]} notificationEmails array of email addresses to send notifications to
 * @param {boolean} hr set to true for hr request
 */
export const submitApplications = ({ data, contactInfoList, notificationEmails = [], hr = true }) => async (
    dispatch
) => {
    const uniqueNotificationEmails = uniqueEmails(notificationEmails);

    const getPhoneNumberFormatted = (phoneNumber) => {
        const parsedPhoneNumber = phoneNumber && parsePhoneNumberFromString(phoneNumber, 'CA');
        if (parsedPhoneNumber && parsedPhoneNumber.isValid())
            return {
                number: parsedPhoneNumber.formatInternational(),
                country: parsedPhoneNumber.country,
                phone_type: 'CELL',
            };

        return null;
    };

    // HR uses the /invite/batch/ route, requires a list of applicants
    const getBodyFormattedHR = () =>
        contactInfoList.map(({ email, phone }) => {
            const applicantInfo = {
                email,
                notification_emails: uniqueNotificationEmails,
                ...data,
            };
            const phoneNumberFormatted = getPhoneNumberFormatted(phone);
            if (phoneNumberFormatted) applicantInfo.phone_number = phoneNumberFormatted;

            return applicantInfo;
        });

    // PM needs to use the /invite/ route, requires grouped applicants array
    const getBodyFormattedPM = () => ({
        ...data,
        notification_emails: uniqueNotificationEmails,
        grouped_applicants: contactInfoList.map(({ email, phone }) => {
            const applicantContactInfo = {
                email,
            };
            const phoneNumberFormatted = getPhoneNumberFormatted(phone);
            if (phoneNumberFormatted) applicantContactInfo.phone_number = phoneNumberFormatted;

            return applicantContactInfo;
        }),
    });

    const bodyData = hr ? getBodyFormattedHR() : getBodyFormattedPM();

    dispatch({
        type: POST_APPLICATIONS_SEND_REQ,
    });
    try {
        const response = await postRequest({
            hr,
            version: hr ? 'v1' : 'v2',
            endpoint: hr ? '/applications/invite/batch/' : 'applications/invite/',
            body: JSON.stringify(bodyData),
        });
        // Component logic elsewhere depends on returning this dispatch...
        return dispatch({
            type: POST_APPLICATIONS_SEND_RES,
            payload: { sentApplication: response },
        });
    } catch (error) {
        dispatch({
            type: POST_APPLICATIONS_SEND_ERR,
            payload: { error },
        });
        throw error;
    }
};

export const setFilters = (payload) => (dispatch) => {
    dispatch({
        type: SET_FILTERS,
        payload,
    });
};
