import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Input, Select } from 'antd';
import { useIntl } from 'react-intl';
import { getDataSet, reduce } from 'iso3166-2-db';

const ProvStateSelect = ({ innerRef, selectedCountry, placeholder, provinceFilter, returnName = false, ...rest }) => {
    const intl = useIntl();

    // For multilanguage country-province-state logic
    const sortedRegions = React.useMemo(() => {
        const listOfCountries = reduce(getDataSet(), intl.locale);

        return listOfCountries[selectedCountry]?.regions.sort((a, b) => (a.name > b.name ? 1 : -1));
    }, [intl, selectedCountry]);

    const filteredRegions = React.useMemo(() => {
        if (provinceFilter && sortedRegions) {
            return sortedRegions.filter(provinceFilter);
        }
        return sortedRegions;
    }, [provinceFilter, sortedRegions]);

    const noProvinceOrState = (filteredRegions ?? []).length === 0;

    const tooltip =
        placeholder ||
        intl.formatMessage({
            id: 'address.provinceState',
            defaultMessage: 'Province/State',
        });

    // When there are no provinces or states - render a generic text input instead
    if (noProvinceOrState) {
        return <Input {...rest} ref={innerRef} placeholder={tooltip} autoComplete="province" />;
    }

    return (
        <Select
            showSearch
            filterOption={(input = '', { props }) => {
                const { children: label = '', value = '' } = props || {};
                // Match the label or the value
                return (
                    label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                    value.toLowerCase().indexOf(input.toLowerCase()) === 0
                );
            }}
            autoComplete="dont-use-autocomplete"
            {...rest}
            ref={innerRef}
            placeholder={tooltip}
        >
            {filteredRegions.map((region) => (
                <Select.Option
                    key={region.iso || region.name}
                    value={returnName ? region.name : region.iso || region.fips}
                    data-testid={region.name}
                >
                    {region.name}
                </Select.Option>
            ))}
        </Select>
    );
};

ProvStateSelect.propTypes = {
    selectedCountry: PropTypes.string,
    placeholder: PropTypes.string,
    // Usual array filter - accepts IState objects (name, isoCode)
    provinceFilter: PropTypes.func,
    size: PropTypes.string,
    // All other props allowed on <Select> component
};

ProvStateSelect.defaultProps = {
    selectedCountry: undefined,
    placeholder: undefined,
    provinceFilter: undefined,
    size: undefined,
};

export default forwardRef((props, ref) => <ProvStateSelect {...props} innerRef={ref} />);
