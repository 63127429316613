// Libraries
import React, { Component } from 'react';
import styled from 'styled-components/macro';

export const StyledSearchInput = styled.input`
    padding: 15px;
    color: ${({ theme }) => theme.color.certnGray600};
    font-weight: 400;
    font-size: 16px;
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.color.certnGray400};
    width: 800px;
    margin: 0 auto;
    margin-bottom: 15px;
    @media (max-width: ${({ theme }) => theme.width.tablet}) {
        width: 100%;
    }
`;

class SearchInput extends Component {
    render() {
        return (
            <StyledSearchInput
                type="text"
                onChange={(e) => this.props.onChange(e.target.value)}
                placeholder={this.props.placeholder}
            />
        );
    }
}

export default SearchInput;
