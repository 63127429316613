// Libraries
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

// Components
import RoommatesBase from './RoommatesBase';
import RoommatesForm from './RoommatesForm';

// Actions & Selectors
import { patchInformation } from 'views/welcome/WelcomeActions';
import { getIsFetchingSilent, getInformation, getOccupants, getApplicantAccount } from 'views/welcome/WelcomeSelectors';

const mapStateToProps = (state) => ({
    isFetchingSilent: getIsFetchingSilent(state),
    information: getInformation(state),
    roommates: getOccupants(state),
    applicantAccount: getApplicantAccount(state),
});

const mapDispatchToProps = (dispatch) => ({
    dispatch,
    ...bindActionCreators(
        {
            patchInformation,
        },
        dispatch
    ),
});

const propTypes = {
    // Redux Actions:
    patchInformation: PropTypes.func.isRequired,
    // Parent Action:
    handleSubmit: PropTypes.func.isRequired,
    handleReverse: PropTypes.func.isRequired,
    // Redux Store:
    isFetchingSilent: PropTypes.bool.isRequired,
    information: PropTypes.object.isRequired,
    roommates: PropTypes.array,
};
const defaultProps = {
    roommates: [],
};

class Roommates extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            trackPageLocation: 'base',
            pageOrder: ['base', 'roommate'],
            currentRoommateIndex: 0,
        };
        window.scrollTo(0, 0);
    }

    pages = () => ({
        base: (
            <RoommatesBase
                handleSubmit={this.props.handleSubmit}
                isFetchingSilent={this.props.isFetchingSilent}
                roommates={this.props.roommates}
                currentRoommateIndex={this.state.currentRoommateIndex}
                addOrEditRoommate={this.addOrEditRoommate}
                deleteRoommate={this.deleteRoommate}
            />
        ),
        roommate: (
            <RoommatesForm
                handleSubmit={this.handleSubmit}
                isFetchingSilent={this.props.isFetchingSilent}
                roommates={this.props.roommates}
                currentRoommateIndex={this.state.currentRoommateIndex}
                applicantAccount={this.props.applicantAccount}
            />
        ),
    });

    simulateNextClick = () => {
        document.getElementById('next').click();
    };

    // How each page knows which page to move to next
    handleForward = () => {
        const { trackPageLocation, pageOrder } = this.state;
        const currentPageIndex = pageOrder.indexOf(trackPageLocation);

        if (currentPageIndex === pageOrder.length - 1) {
            this.setState({ trackPageLocation: 'base' });
        } else {
            this.setState({ trackPageLocation: pageOrder[currentPageIndex + 1] });
        }
    };

    // How each page knows which page / track to go back to
    handleReverse = () => {
        const { trackPageLocation, pageOrder } = this.state;
        const currentPageIndex = pageOrder.indexOf(trackPageLocation);
        if (currentPageIndex === 0) {
            this.props.handleReverse();
        } else {
            this.setState({ trackPageLocation: pageOrder[currentPageIndex - 1] });
        }
    };

    // How each of the pages patches the module in parts
    handleSubmit = (e, validateFields) => {
        e.preventDefault();
        validateFields((err, values) => {
            if (!err) {
                const { roommates } = this.props;
                const { currentRoommateIndex } = this.state;

                // Replace the values at the current index with values -- or if new, add new entry
                try {
                    // EDIT: Grab existing entry data at index, then add new values from form
                    const patchValues = roommates;
                    if (patchValues[currentRoommateIndex]) {
                        patchValues[currentRoommateIndex] = {
                            ...patchValues[currentRoommateIndex],
                            ...values,
                        };
                    }
                    // ADD: Add new values from form, and grab the new index
                    else patchValues.push(values);
                    this.props.patchInformation({ occupants: patchValues }).then(this.handleForward);
                } catch (error) {
                    console.error('error', error);
                }
            } else {
                // provide failure error?
            }
        });
    };

    deleteRoommate = (roommateIndex) => {
        const newRoommates = this.props.roommates;
        newRoommates.splice(roommateIndex, 1);
        this.props.patchInformation({ occupants: newRoommates });
    };

    // Provide an index to roommates array if you want to edit an existing entry
    addOrEditRoommate = (roommateIndex = null) => {
        let newRoommateIndex = roommateIndex;
        if (roommateIndex === null) {
            newRoommateIndex = this.props.roommates && this.props.roommates.length;
        }
        this.setState({ currentRoommateIndex: newRoommateIndex }, this.handleForward);
    };

    render() {
        return this.pages()[this.state.trackPageLocation];
    }
}

Roommates.propTypes = propTypes;
Roommates.defaultProps = defaultProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Roommates));
