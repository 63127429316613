import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { StripeModal } from 'views/manager/views/settings/components/StripeCheckoutContainer';

import { SET_SHOULD_PERSIST_SCREEN_APPLICANT_STATE } from 'views/manager/features/ScreenApplicant/ScreenApplicantActions';
import { useUserTeam } from 'hooks/useUserTeam';
import { OnboardingPaymentStep } from './OnboardingPaymentStep';

export const OnboardingPaymentInfo = () => {
    const dispatch = useDispatch();
    const { team, refetchTeam } = useUserTeam();

    const handleFinish = useCallback(() => {
        dispatch({ type: SET_SHOULD_PERSIST_SCREEN_APPLICANT_STATE, payload: true });
        refetchTeam();
    }, [dispatch, refetchTeam]);

    if (!team) {
        return null;
    }

    if (team.payment_verified) {
        return <OnboardingPaymentStep completed />;
    }

    return (
        <StripeModal stripeSubmitCallback={handleFinish}>
            <OnboardingPaymentStep />
        </StripeModal>
    );
};
