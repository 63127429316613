// Libraries
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl, FormattedMessage } from 'react-intl';

// Components
import Form from 'certnd/Form';
import Typography from 'certnd/Typography';

// Modules
import Auth from 'modules/Auth';

const { Heading } = Typography;

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

// eslint-disable-next-line
class Rcmp extends React.Component {
    render() {
        const { form, settings } = this.props;

        const canEdit = Auth.isPermissionLevel('manager');

        return (
            <Form.Block>
                <Heading.H3>
                    <FormattedMessage
                        id="services.canadianCriminalRecordCheck"
                        defaultMessage="Canadian Criminal Record Check"
                    />
                </Heading.H3>

                <Form.Checkbox
                    form={form}
                    fieldName="enable_rcmp_reflow"
                    title={this.props.intl.formatMessage({
                        id: '237c9.Rcmp.ReflowTitle',
                        defaultMessage: 'Automatic Reflow',
                    })}
                    description={this.props.intl.formatMessage({
                        id: '237c9.Rcmp.ReflowDescription',
                        defaultMessage:
                            "If criminal record check is marked 'Incomplete', resend applicant form to update self-disclosed convictions. (This may increase turnaround time of application)",
                    })}
                    options={{ initialValue: settings.enable_rcmp_reflow }}
                    disabled={!canEdit}
                />

                <Form.Button disabled={!canEdit}>
                    <FormattedMessage id="common.save" defaultMessage="Save" />
                </Form.Button>
            </Form.Block>
        );
    }
}

export default connect(undefined, mapDispatchToProps)(injectIntl(Rcmp));
