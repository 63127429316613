import { Alert } from 'antd';
import { FormattedMessage } from 'react-intl';

interface Props {
    isTeamBlockedWithApplications: boolean;
}

const LimeBanner: React.FC<Props> = ({ isTeamBlockedWithApplications }) => (
    <>
        {isTeamBlockedWithApplications && (
            <div style={{ marginTop: 16 }}>
                <Alert
                    type="warning"
                    showIcon
                    message={
                        <FormattedMessage
                            id="lime.OnboardingInProgress"
                            defaultMessage="You have exceeded your account limitations before being validated. Certn Lime has a duty to ensure the legitimacy of your business and the purpose of completing background checks on your behalf. A Certn Lime Specialist will contact you shortly."
                        />
                    }
                />
            </div>
        )}
    </>
);

export default LimeBanner;
