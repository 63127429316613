// Libraries
import React from 'react';
import styled from 'styled-components/macro';
import { Button } from 'antd';

const Titlebar = ({
    title,
    center = false,
    size = 'lrg',
    subtitle = undefined,
    children = undefined,
    marginBottom = false,
    removeTopMargin = false,
    mobile = false,
}) => (
    <TitlebarContainer mobile={mobile} center={center} marginBottom={marginBottom} removeTopMargin={removeTopMargin}>
        <Title data-testid="title" size={size}>
            {title}
        </Title>
        <TitlebarRight center={center}>{children}</TitlebarRight>
        {subtitle && <SubTitle center={center}>{subtitle}</SubTitle>}
    </TitlebarContainer>
);
export default Titlebar;

const TitlebarContainer = styled.div`
    display: flex;
    flex-direction: ${(props) => (props.center ? 'column' : 'row')};
    align-items: center;
    justify-content: ${(props) => (props.center ? 'center' : 'space-between')};
    text-align: ${(props) => (props.center ? 'center' : 'left')};
    width: 100%;
    max-width: 100%;
    margin-top: ${(props) => (props.removeTopMargin ? '0px' : '20px')};
    margin-bottom: 15px;
    @media (max-width: ${({ theme }) => theme.width.tablet}) {
        align-items: center;
    }
    ${(props) => props.mobile && 'margin-top: 30%'}
`;

const Title = styled.span`
    order: 1;
    color: ${(props) => props.theme.color.secondary};
    font-size: ${(props) => (props.size ? props.theme.font[props.size] : props.theme.font.med)};
    margin-left: ${(props) => (props.size === 'sm' || props.size === 'xs' ? '5%' : '0')};
    margin-bottom: 0 !important;
`;

const TitlebarRight = styled.div`
    order: ${(props) => (props.center ? 3 : 2)};
    display: flex;
    justify-content: flex-end;
    margin-left: 10px;

    > div {
        margin: 3px !important;
    }
    @media (max-width: ${({ theme }) => theme.width.tablet}) {
        flex-direction: column;
    }

    @media (min-width: ${(props) => props.theme.width.med}) {
        align-items: center;
    }
`;

const SubTitle = styled.span`
    order: ${(props) => (props.center ? 2 : 3)};
    color: ${(props) => props.theme.color.certnGray500};
    font-size: ${(props) => props.theme.font.sm};
    margin: 20px !important;
    max-width: 600px;
    text-align: center;
    min-height: inherit;
`;

export const TitlebarCtaButton = styled(Button)`
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    height: max-content !important;

    & span {
        white-space: normal;
        word-wrap: blank-space;
    }
`;
