import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Menu, message, Modal } from 'antd';

import { intl } from 'components/GlobalProvider';
import { ListCellText } from 'certn-ui/List';
import OnboardingMap from './OnboardingMap';
import actionSets from './actionSets';

import { getUserMode, getUser } from 'base/BaseSelectors';

const BulkActionItems = ({ applications, setIsDownloading }) => {
    const dispatch = useDispatch();
    const user = useSelector(getUser);
    const userMode = useSelector(getUserMode);
    const flow = OnboardingMap(userMode);
    const canViewReports = user.can_view_reports;
    const bulkActionType = {
        APPLICATION: 'application',
        DOWNLOAD: 'download',
        DIVIDER: 'divider',
    };

    const handleBulkDownload = (actionSet) => {
        let requestObject = actionSet.buildRequestObject(applications);
        // Bulk PDF downloads are currently limited to 10 at a time and CSVs have no limit
        const isBulkDownloadOverPDFLimit = Object.keys(requestObject).length > 10;
        if (actionSet.key === 'bulkPDF') requestObject = requestObject.slice(0, 10);

        const performDownload = () => {
            setIsDownloading(true);
            dispatch(actionSet.action(requestObject))
                .then(() => message.success(actionSet.success()))
                .catch(() => message.error(actionSet.error()))
                .finally(() => setIsDownloading(false));
        };

        if (actionSet.key === 'bulkPDF' && isBulkDownloadOverPDFLimit) {
            const modal = Modal.confirm();
            return modal.update({
                title: intl.formatMessage({
                    id: '9492f.BulkActions.bulkPDFModalTitle',
                    defaultMessage: 'Selection Exceeds Bulk PDF Limit of 10',
                }),
                content: intl.formatMessage({
                    id: '9492f.BulkActions.bulkPDFModalContent',
                    defaultMessage: 'The first 10 selected PDF reports will be included if you continue.',
                }),
                icon: 'warning',
                okType: 'primary',
                okText: intl.formatMessage({ id: '9492f.bulkPDFModalOkText', defaultMessage: 'Download Reports' }),
                onOk: performDownload,
                onCancel: modal.destroy,
            });
        }

        performDownload();
    };

    // TODO Refactor + comment this logic, switch statement is confusing
    return (
        <Menu>
            {actionSets(flow, canViewReports, bulkActionType).map((actionSet) => {
                switch (actionSet.type) {
                    case bulkActionType.APPLICATION:
                        return actionSet.actions.map(
                            ({ title, status }) =>
                                actionSet.show && (
                                    <Menu.Item key={title}>
                                        <ListCellText
                                            clickable
                                            onClick={async () => {
                                                try {
                                                    await dispatch(
                                                        actionSet.action(
                                                            actionSet.buildRequestObject(applications, status)
                                                        )
                                                    );
                                                    message.success(actionSet.success(title));
                                                } catch (error) {
                                                    message.error(actionSet.error(title));
                                                }
                                            }}
                                        >
                                            {actionSet.actionLabel(title)}
                                        </ListCellText>
                                    </Menu.Item>
                                )
                        );
                    case bulkActionType.DOWNLOAD:
                        return (
                            actionSet.show && (
                                <Menu.Item key={actionSet.actionLabel()}>
                                    <ListCellText clickable onClick={() => handleBulkDownload(actionSet)}>
                                        {actionSet.actionLabel()}
                                    </ListCellText>
                                </Menu.Item>
                            )
                        );
                    case bulkActionType.DIVIDER:
                        return actionSet.show && <Menu.Divider key={actionSet.key} />;
                    default:
                        break;
                }
            })}
        </Menu>
    );
};

export default BulkActionItems;
