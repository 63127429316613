import { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';

// Components
import DownloadVerifyForm from './DownloadVerifyForm';
import { ErrorAlertAPI } from 'certn-ui/ErrorAlert';

// Redux
import { getTeamLogo } from 'base/BaseSelectors';

// Using absolute positioning to be able to set background color...
const VerifyPage = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: ${({ theme }) => theme.color.certnGray100};
    padding-top: 140px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`;

const NavBar = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
`;

export const NavLogo = styled.img`
    height: 28px;
`;

const VerifyPanel = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    width: 376px;
    max-width: 95vw;
    padding-bottom: 39px;
    border-radius: 8px;
    box-shadow: 0 1px 8px 0 ${({ theme }) => theme.color.certnGray400};
    border: solid 1px ${({ theme }) => theme.color.certnGray200};
    background-color: ${({ theme }) => theme.color.certnWhite};
`;

const Title = styled.div`
    padding: 28px 21px 25px 22px;
    font-size: 21px;
    font-weight: bold;
    text-align: center;
    color: ${({ theme }) => theme.color.certnGray800};
    border-bottom: 1px solid ${({ theme }) => theme.color.certnGray200};
`;

const Content = styled.div`
    padding: 23px 22px;
    font-size: 16px;
    color: ${({ theme }) => theme.color.certnGray800};
`;

const FormHolder = styled.div`
    padding: 0 22px;
`;

type FormValues = {
    accessCode: string;
};

type DownloadVerifyProps = {
    onSubmit: (accessCode: string) => void;
    title: string;
    description: string;
    buttonText: string;
};

const DownloadVerify = ({ onSubmit, title, description, buttonText }: DownloadVerifyProps) => {
    const [loading, setLoading] = useState(false);
    const logo = useSelector(getTeamLogo);

    const handleSubmit = async ({ accessCode }: FormValues) => {
        setLoading(true);
        try {
            await onSubmit(accessCode);
        } catch (error) {
            ErrorAlertAPI(error);
        }
        setLoading(false);
    };

    return (
        <VerifyPage>
            <NavBar>
                <NavLogo src={logo} alt="Certn" />
            </NavBar>
            <VerifyPanel>
                <Title>{title}</Title>
                <Content>{description}</Content>
                <FormHolder>
                    <DownloadVerifyForm handleSubmit={handleSubmit} loading={loading} buttonText={buttonText} />
                </FormHolder>
            </VerifyPanel>
        </VerifyPage>
    );
};

export default DownloadVerify;
