import React from 'react';
import styles from 'styles/styles';

const Roommates = ({ scale }) => (
    <svg width={57} height={63} style={{ transform: `scale3d(${scale}, ${scale}, ${scale})` }}>
        <title>businessman_teamwork_team_business</title>
        <g stroke={styles.color.certnGreen700} strokeWidth={1.525} fill="none" fillRule="evenodd">
            <g transform="translate(17)">
                <circle cx={11.5} cy={6.147} r={5.395} />
                <path
                    d="M17.412 62.248l.514-21.115c1.98-.715 4.348-2.371 4.139-4.454l-1.619-16.067c-.209-2.083-2.096-3.789-4.188-3.789H6.745c-2.094 0-3.98 1.706-4.188 3.789L.937 36.679c-.21 2.083 2.157 3.739 4.14 4.454l.512 21.115"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <g transform="translate(41 7)">
                <circle cx={5.777} cy={5.132} r={4.79} />
                <path
                    d="M11.026 55.248l.454-18.745c1.76-.635 3.862-2.104 3.676-3.954l-1.438-14.264c-.186-1.85-1.859-3.364-3.719-3.364h-8.02"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <g transform="translate(0 7)">
                <circle cx={10.222} cy={5.132} r={4.79} />
                <path
                    d="M4.973 55.248l-.455-18.745c-1.76-.635-3.862-2.104-3.676-3.954L2.28 18.285c.185-1.85 1.86-3.364 3.718-3.364h8.02"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </g>
    </svg>
);

export default Roommates;
