export const baseConfig = {
    desktop: [
        'Updated',
        'Email',
        'Short uid',
        'First Name',
        'Last Name',
        'Package',
        'Team',
        'Owner',
        'Application Status',
        'Adjudication Status',
        'Adverse Action Status',
        'Score',
        'Actions',
    ],
    mobile: ['Info', 'Score', 'Actions'],
    clientConfiguredColumns: true,
};

export const clientAdjudicationConfig = {
    desktop: [
        'Updated',
        'Email',
        'First Name',
        'Last Name',
        'Assigned',
        'Application Status',
        'Adverse Action Status',
        'Review Status',
        'Score',
        'Actions',
    ],
    mobile: ['Info', 'Score', 'Actions'],
    clientConfiguredColumns: false,
};
