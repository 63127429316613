// Libraries
import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Button } from 'antd';

// Modules & Utils
import { getRequest } from 'utils/http';

// Actions & Selectors
import { getTeam } from 'views/manager/views/admin/AdminSelectors';

// UI Components
import Typography from 'certnd/Typography';
import { ErrorAlertCustom } from 'certn-ui/ErrorAlert';

const { Heading, Paragraph, Text } = Typography;

const Documents = () => {
    const team = useSelector(getTeam);
    const intl = useIntl();

    const downloadDocument = async (agreementId) => {
        try {
            const response = await getRequest({
                version: 'v1',
                endpoint: `/teams/${team.id}/agreements/${agreementId}/`,
            });
            window.open(response.url, '_blank');
        } catch (error) {
            ErrorAlertCustom({
                title: intl.formatMessage({
                    id: 'common.failedToLoad',
                    defaultMessage: 'Failed to load.',
                }),
                description: intl.formatMessage({
                    id: 'documents.couldNotBeViewed',
                    defaultMessage: 'Document could not be viewed.',
                }),
            });
        }
    };

    return (
        <div>
            <Heading.H1 data-testid="title">
                {intl.formatMessage({
                    id: 'common.documents',
                    defaultMessage: 'Documents',
                })}
            </Heading.H1>
            <Paragraph>
                {intl.formatMessage({
                    id: 'documents.documentIntro',
                    defaultMessage:
                        'Documents that your team has initiated or signed through the Certn app show up here.',
                })}
            </Paragraph>
            {team.agreements.length > 0 ? (
                <table style={{ width: '100%', maxWidth: '400px' }}>
                    <tbody>
                        {team.agreements.map((agreement) => (
                            <tr key={agreement.id}>
                                <td>{agreement.doc_type}</td>
                                <td>
                                    <Button
                                        type="primary"
                                        size="medium"
                                        certncolor="primary"
                                        onClick={() => downloadDocument(agreement.id)}
                                    >
                                        {intl.formatMessage({
                                            id: 'common.view',
                                            defaultMessage: 'View',
                                        })}
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <Text.Secondary>
                    {intl.formatMessage({
                        id: 'documents.documentsEmpty',
                        defaultMessage: 'You have no documents stored here.',
                    })}
                </Text.Secondary>
            )}
        </div>
    );
};

export default Documents;
