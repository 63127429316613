import { CheckStatus } from './checkStatus';

export interface CheckExecutionInfo {
    display_label: string | null;
}

export interface CheckExecution {
    report_section: string;
    id: string;
    check_name: string;
    check_title: string;
    status: CheckStatus;
    execution_info: CheckExecutionInfo;
}

/* eslint-disable no-shadow */
export enum CheckExecutionsStatusPriority {
    WAITING_ON_CANDIDATE = 0,
    ACTION_REQUIRED = 1,
    IN_DISPUTE = 2,
    IN_PROGRESS = 3,
    UNRESPONSIVE = 4,
    REQUIRES_MORE_INFORMATION = 5,
    WAITING_ON_ADJUDICATOR = 6,
    COMPLETE = 7,
    CANCELLED = 8,
}
