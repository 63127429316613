import styled from 'styled-components/macro';
import { Input, Row, Select } from 'antd';

const Name = styled.div`
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: ${(p) => (p.bold ? '600' : '500')};
`;

const MemberCount = styled.div`
    font-size: 16px;
    color: ${({ theme }) => theme.color.certnGray400};
`;

const YourTeamText = styled.span`
    color: ${({ theme }) => theme.color.certnGray400};
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 10px;
`;

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    color: ${({ theme }) => theme.color.primary};
    padding: 20px;
    margin-bottom: 20px;
    width: 325px;
    max-width: 100%;
    margin-right: 20px;
    border: ${({ theme, highlight }) =>
        highlight ? `2px solid ${theme.color.primary}` : `1px solid ${theme.color.certnGray400}`};
    border-radius: 5px;
    @media (max-width: ${({ theme }) => theme.width.phone}) {
        margin-right: 0;
    }
    transition: all 0.5s;
`;

export const ButtonWrapper = styled.div`
    padding: 10px;
`;

export const ButtonRow = styled(Row)`
    display: flex;
    justify-content: flex-end;
`;

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    @media (max-width: ${({ theme }) => theme.width.phone}) {
        flex-direction: column;
        align-items: center;
    }
`;

const PaginationFooterWrapper = styled.div`
    position: fixed;
    bottom: 10px;
    right: 5vw;
`;

const TeamSearchBar = styled.div`
    display: flex;
    justify-content: end;
`;

const TeamSearch = styled(Input)`
    max-width: 240px;
    margin-right: 10px;
`;

const TeamOrderBy = styled(Select)`
    max-width: 240px;
`;

const TeamSortByText = styled.span`
    color: ${({ theme }) => theme.color.certnGray400};
    padding: 5px;
`;

const Team = {
    Name,
    MemberCount,
    Wrapper,
    Container,
    YourTeamText,
    TeamSearchBar,
    ButtonWrapper,
    ButtonRow,
    PaginationFooterWrapper,
    TeamSearch,
    TeamOrderBy,
    TeamSortByText,
};
export default Team;
