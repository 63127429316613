import styled from 'styled-components';
import { Typography } from 'antd';

const { Text } = Typography;

export const NameSectionWrapper = styled.div`
    display: flex;
    padding: 0 16px;
    justify-content: space-between;
    width: 100%;
`;
export const ApplicantDetailsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 16px 0;
`;

export const ApplicantNameText = styled(Text)`
    font-weight: 600;
    font-size: 16px;
`;

export const ApplicantEmailText = styled(Text)`
    font-size: 14px;
    line-height: 125%;
    opacity: 0.45;
`;

export const DownloadDropdownComponent = styled.div`
    display: flex;
    flex-direction: column;
    padding: 16px 0;
`;
