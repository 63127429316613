// Primary Colors
// New style guide colors
import { ServerEnv } from '../utils/config';

// Greyscale Colors
const black = '#000000';
const certnWhite = '#FFFFFF';

const certnGray900 = '#262626'; // Some text colors (List, password, hellosignembed, base)
const certnGray800 = '#434343'; // Some text colors (FieldLabel, ReportVerify)
const certnGray700 = '#444444'; // Text color (Certn UI text, Certn Score, Reference Form)
const certnGray600 = '#565656'; // A LOT of text colors
const certnGray500 = '#888888'; // Gray text on a light gray background
const certnGray400 = '#bfbfbf'; // Borders on inputs and cards
const certnGray300 = '#d4d4d4'; // Skeleton loaders and backgrounds
const certnGray200 = '#e3e3e3'; // A LOT of backgrounds and borders for white backgrounds
const certnGray100 = '#F1F3F3'; // A LOT of Borders background and stuff

const genericGray500 = '#819697';

// Greens
const certnGreen900 = '#084146';
const certnGreen800 = '#016f7C';
const certnGreen700 = '#12826A'; // Standard Branding
const certnGreen600 = '#17B893';
const certnGreen500 = '#2ECD70';
const certnGreen400 = '#84C164'; // Lime
const certnGreen200 = '#C5EEE6';
const certnGreen100 = '#E3F9F4';

// Red
const certnRed900 = '#9A3412';
const certnRed800 = '#C3001A';
const certnRed700 = '#ff0000';
const certnRed600 = '#ff4D4F';
const certnRed500 = '#E74C3C';
const certnRed200 = '#F9D2CE';
const certnRed100 = '#FBEFEE';

// Blue
const certnBlue900 = '#394553';
const certnBlue600 = '#1890ff';
const certnBlue500 = '#3398DC';
const certnBlue100 = '#F0F8FF';

// Purple
const certnPurple500 = '#9B59B6';

// Orange
const certnOrange600 = '#E67D23';
const certnOrange500 = '#F9B64B';
const certnOrange400 = '#F1C40E';
const certnOrange300 = '#F1DB35';
const certnOrange200 = '#FCF0C3';
const certnOrange100 = '#FFF7ED';

// Off Brand
// == Stripe ==
const stripePurple = '#32325D';
const stripeGray = '#C4BFBF';
const stripeOrange = '#FA755A';

// #######
// These are the marketing colors but they aren't as compliant as we need them to be to use them in app.
// So, DON'T USE THEM, but you should know about them, so here they are.
// #######
const moss500 = '#134247'; // Certn
const moss400 = '#43676B';
const moss300 = '#718D8F';
const moss200 = '#A1B4B6';

const aqua500 = '#0FB793';
const aqua400 = '#40C4A8';
const aqua300 = '#70D3BE';
const aqua200 = '#9FE2D4';

const turquoise500 = '#006F7C'; // Certn secondary
const turquoise400 = '#338B96';
const turquoise300 = '#66A8AF';
const turquoise200 = '#99C5CB';

const yellow500 = '#FAB64B'; // MyCRC
const yellow400 = '#FBC36F';
const yellow300 = '#FCD393';
const yellow200 = '#FDE2B7';

const red500 = '#EB5C5C'; // OneID
const red400 = '#EF7C7C';
const red300 = '#F39D9D';
const red200 = '#F7BEBE';

const lime500 = '#8DC965'; // Lime
const lime400 = '#A3D383';
const lime300 = '#BADEA2';
const lime200 = '#D1E8C2';
// #### End Marketing Colors

const styles = {
    'primary-color': certnGreen700,
    colorMap: {
        PASS: certnGreen700,
        CLEARED: certnGreen700,
        WARN: certnOrange400,
        REVIEW: certnOrange400,
        FAIL: certnRed500,
        NONE: certnGray200,
    },
    statusColors: {
        ACTION_REQUIRED: certnRed500,
        UNRESPONSIVE: certnRed500,
        PARTIAL: certnOrange400,
        COMPLETE: certnGreen800,
        DEFAULT: certnGray600,
        SENT: certnGray400,
        IN_PROGRESS: certnGray400,
        NONE: certnGray400,
        ANALYZING: certnGray400,
        CANCELLED: certnOrange400,
        IN_DISPUTE: certnOrange400,
        WAITING_ON_CANDIDATE: certnGray600,
    },
    marketing: {
        moss500,
        moss400,
        moss300,
        moss200,
        aqua500,
        aqua400,
        aqua300,
        aqua200,
        turquoise500,
        turquoise400,
        turquoise300,
        turquoise200,
        yellow500,
        yellow400,
        yellow300,
        yellow200,
        red500,
        red400,
        red300,
        red200,
        lime500,
        lime400,
        lime300,
        lime200,
    },
    color: {
        black,
        certnWhite,
        certnGray900,
        certnGray800,
        certnGray700,
        certnGray600,
        certnGray500,
        certnGray400,
        certnGray300,
        certnGray200,
        certnGray100,
        genericGray500,
        // Green
        certnGreen900,
        certnGreen800,
        certnGreen700,
        certnGreen600,
        certnGreen500,
        certnGreen400,
        certnGreen200,
        certnGreen100,
        // Red
        certnRed900,
        certnRed800,
        certnRed700,
        certnRed600,
        certnRed500,
        certnRed200,
        certnRed100,
        // Blue
        certnBlue900,
        certnBlue600,
        certnBlue500,
        certnBlue100,
        // Purple
        certnPurple500,
        // Orange
        certnOrange600,
        certnOrange500,
        certnOrange400,
        certnOrange300,
        certnOrange200,
        certnOrange100,
        // Off Brand
        stripePurple,
        stripeGray,
        stripeOrange,
        // New colors
        primary: certnGreen700,
        secondary: certnGreen900,
        certnLimeBrand: certnGreen400,
        certnMyCRCBrand: certnOrange500,
    },
    serverColors: {
        [`${ServerEnv.LOCAL}`]: certnBlue500,
        [`${ServerEnv.DEV}`]: certnGreen700,
        [`${ServerEnv.STAGING}`]: certnOrange600,
        [`${ServerEnv.DEMO}`]: certnRed500,
        [`${ServerEnv.PROD}`]: certnRed500,
        unknown: certnRed900,
    },
    padding: {
        xs: '',
        sm: '',
        med: '',
        lrg: '',
        xlrg: '',
        button: '5px',
    },
    margin: {
        xs: '',
        sm: '',
        med: '',
        lrg: '',
        xlrg: '',
    },
    font: {
        xxxs: '13px',
        xxs: '14px',
        xs: '16px',
        sm: '18px',
        med: '22px',
        lrg: '26px',
        xlrg: '30px',
    },
    image: {
        xxs: '16px',
        xs: '32px',
        sm: '48px',
        med: '72px',
        lrg: '96px',
        xlrg: '128px',
        xxlrg: '256px',
        xxxlrg: '745px',
    },
    lineHeight: {},
    dimensions: {
        sidebar: '250px',
    },
    width: {
        xxxs: '320px',
        xxs: '360px',
        xs: '420px',
        sm: '600px',
        med: '768px',
        lrg: '1024px',
        xlrg: '1366px',
        xxlrg: '1600px',
        phone: '480px',
        tablet: '768px',
        desktop: '1024px',
        wide: '1440px',
    },
};

export default styles;
