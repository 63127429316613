import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Form } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import {
    StyledWrapper,
    EmailRow,
    Instructions,
    InputsList,
    EmailItem,
    EmailInput,
    RemoveButton,
    ButtonRow,
    AddApplicantButton,
} from './styled';
import FieldLabel from 'certn-form/FieldLabel';

const propTypes = {
    instructions: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    label: PropTypes.string,
    // This is the form field name for the email inputs - defaults to "emails"
    fieldName: PropTypes.string,
    submitAttempted: PropTypes.bool,
    fullWidth: PropTypes.bool,
    maxAllowed: PropTypes.number,
    required: PropTypes.bool,
};

const defaultProps = {
    instructions: null,
    label: undefined,
    fieldName: 'emails',
    submitAttempted: false,
    fullWidth: false,
    maxAllowed: 5,
    required: true,
};

export const hasDuplicatesInPreviousInputs = (array, current, value) =>
    array.some((item, index) => item && index < current && item.toLowerCase() === value.toLowerCase());

export const emailRules = (fieldName, index, required, intl, subField = '') => [
    {
        type: 'email',
        message: intl.formatMessage({
            id: '5ce00.EmailAddresses.email.invalid.error',
            defaultMessage: 'Please enter a valid email',
        }),
    },
    ({ getFieldValue }) => ({
        // At least one input may be required, and cannot contain a duplicate email address
        validator(rule, value) {
            const emails = subField
                ? getFieldValue(fieldName).map((entry) => entry[subField])
                : getFieldValue(fieldName);

            // Make sure we have at least one email address
            if (index === 0 && emails.filter(Boolean).length === 0 && required) {
                return Promise.reject(
                    intl.formatMessage({
                        id: '5ce00.EmailAddresses.email.required.error',
                        defaultMessage: 'You need at least 1 email address',
                    })
                );
            }

            if (index !== 0 && !value) {
                return Promise.reject(
                    intl.formatMessage({
                        id: '5ce00.EmailAddresses.email.invalid.error',
                        defaultMessage: 'Please enter a valid email',
                    })
                );
            }

            if (value && index > 0 && emails) {
                // Find a duplicate email address in previous inputs
                if (hasDuplicatesInPreviousInputs(emails, index, value)) {
                    return Promise.reject(
                        intl.formatMessage({
                            id: '5ce00.EmailAddresses.email.duplicate.error',
                            defaultMessage: 'This email address is already listed above',
                        })
                    );
                }
            }
            // Valid - we didn't find any duplicates
            return Promise.resolve();
        },
    }),
];

const EmailAddresses = ({ instructions, label, fieldName, submitAttempted, fullWidth, maxAllowed, required }) => {
    const intl = useIntl();
    // If the form has been submitted - then validate on change, other on submit
    const validateTrigger = submitAttempted ? ['onChange', 'onBlur'] : ['onSubmit'];

    const style = fullWidth ? { width: '100%' } : {};
    const placeholder = intl.formatMessage({
        id: '5ce00.EmailAddresses.email.placeholder',
        defaultMessage: 'joe.smith@example.com',
    });

    return (
        <StyledWrapper>
            <Instructions>{instructions}</Instructions>
            <InputsList {...style}>
                <Form.List name={fieldName} initialValue={['']}>
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map((field, index) => (
                                <EmailRow key={field.key}>
                                    <FieldLabel htmlFor={`${fieldName}[0]`}>
                                        {label ||
                                            intl.formatMessage({
                                                id: 'common.emailAddress',
                                                defaultMessage: 'Email Address',
                                            })}
                                    </FieldLabel>
                                    <Form.Item
                                        {...field}
                                        validateTrigger={validateTrigger}
                                        rules={emailRules(fieldName, index, required, intl)}
                                    >
                                        <EmailItem>
                                            <EmailInput
                                                id={`${fieldName}[${field.name}]`}
                                                type="email"
                                                placeholder={placeholder}
                                            />
                                            {index > 0 && (
                                                <RemoveButton
                                                    onClick={() => remove(field.name)}
                                                    title={intl.formatMessage({
                                                        id: '5ce00.EmailAddresses.email.remove.tooltip',
                                                        defaultMessage: 'Remove email address',
                                                    })}
                                                />
                                            )}
                                        </EmailItem>
                                    </Form.Item>
                                </EmailRow>
                            ))}
                            {fields.length < maxAllowed && (
                                <ButtonRow>
                                    <AddApplicantButton type="dashed" onClick={() => add()}>
                                        <PlusOutlined size="small" />
                                        {intl.formatMessage({
                                            id: 'common.addEmailAddress',
                                            defaultMessage: 'Add Email Address',
                                        })}
                                    </AddApplicantButton>
                                </ButtonRow>
                            )}
                        </>
                    )}
                </Form.List>
            </InputsList>
        </StyledWrapper>
    );
};

EmailAddresses.propTypes = propTypes;
EmailAddresses.defaultProps = defaultProps;

export default EmailAddresses;
