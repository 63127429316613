import React, { useEffect } from 'react';

// Modules
import { AppearRight } from 'certn-ui/Animate';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { patchInformation, setTrackPageOrder /* eslint-disable no-shadow */ } from 'views/welcome/WelcomeActions';
import UKSelfDeclarationForm from './components/UKSelfDeclarationForm';
// Modules
import withNavigation from 'views/welcome/modules/WithNavigation';
import withSettingsChecker from 'views/welcome/modules/WithSettingsChecker';
import {
    getIsCanadianCriminalRecordCheck,
    getIsInternationalCriminalRecordCheck,
    getIsUkDbsOrDsCheck,
    getTeam,
} from 'views/welcome/WelcomeSelectors';
import { yesNoToBool } from 'views/welcome/yesNoHelpers';
import { UKSelfDeclarationFormValues } from './types';
import RightToWorkFailureForm from '../rightToWork/RightToWorkFailure';
import { Team } from 'types';

interface Props {
    trackPageLocation: string;
    handleNextTrack: () => void;
    handleSkipNextTrack: (arg0: string) => void;
    handleForward: () => void;
    ukSelfDeclaration: UKSelfDeclarationFormValues;
    team: Team;
}

const UKSelfDeclaration: React.FC<Props> = ({
    trackPageLocation,
    handleNextTrack,
    handleSkipNextTrack,
    handleForward,
    ukSelfDeclaration,
}: Props) => {
    const dispatch = useDispatch();
    const team = useSelector(getTeam).name;
    const isUkDbsOrDsCheck = useSelector(getIsUkDbsOrDsCheck);
    const isCanadianCriminalRecordCheck = useSelector(getIsCanadianCriminalRecordCheck);
    const isInternationalCriminalRecordCheck = useSelector(getIsInternationalCriminalRecordCheck);

    useEffect(() => {
        dispatch(setTrackPageOrder(['base', 'failure']));
    }, [dispatch]);

    const baseHandleSubmit = async (values: UKSelfDeclarationFormValues) => {
        if (values.has_valid_uk_or_irish_passport_yesno) {
            dispatch(
                patchInformation({
                    has_valid_uk_or_irish_passport: yesNoToBool(values.has_valid_uk_or_irish_passport_yesno),
                })
            );
            if (values.has_valid_uk_or_irish_passport_yesno === 'NO') {
                handleForward();
            } else {
                handleNextTrack();
            }
        }
    };

    const failureHandleSubmit = () => {
        if (isUkDbsOrDsCheck || isCanadianCriminalRecordCheck || isInternationalCriminalRecordCheck) {
            handleNextTrack();
        } else {
            handleSkipNextTrack('oneId');
        }
    };

    const pages = () => ({
        base: (
            <AppearRight>
                <UKSelfDeclarationForm
                    handleSubmit={baseHandleSubmit}
                    handleForward={handleForward}
                    ukSelfDeclaration={ukSelfDeclaration}
                />
            </AppearRight>
        ),
        failure: (
            <AppearRight>
                <RightToWorkFailureForm team={team} handleSubmit={failureHandleSubmit} />
            </AppearRight>
        ),
    });
    return pages()[trackPageLocation as keyof typeof pages];
};

export default withRouter(withNavigation(withSettingsChecker(UKSelfDeclaration)));
