import { useIntl } from 'react-intl';
import { Form, Input } from 'antd';
import { PurposeFormLabel, PurposeFormSpan } from 'components/StyledPurposeComponents';
import { ACIC_CHECK_REASONS } from 'views/manager/features/ScreenApplicant/components/ScreenApplicant/MicroServiceConstants';

interface VolunteerRoleAndVolunteerOrganization {
    isVolunteerRoleDisabled: boolean;
    isVolunteerOrganizationDisabled: boolean;
}

export const VolunteerRoleAndVolunteerOrganization: React.FC<VolunteerRoleAndVolunteerOrganization> = ({
    isVolunteerRoleDisabled,
    isVolunteerOrganizationDisabled,
}) => {
    const intl = useIntl();

    return (
        <>
            <div style={{ alignSelf: 'left' }}>
                <Form.Item
                    name="volunteer_role"
                    label={
                        <PurposeFormLabel>
                            {intl.formatMessage({
                                id: '74e6c.AddOptions.acicVolunteerRole',
                                defaultMessage: 'Role, placement, or course name',
                            })}
                        </PurposeFormLabel>
                    }
                    rules={[
                        {
                            required: true,
                            message: intl.formatMessage({
                                id: '74e6c.AddOptions.notBlank',
                                defaultMessage: 'Please do not leave blank',
                            }),
                        },
                    ]}
                >
                    <Input disabled={isVolunteerRoleDisabled} />
                </Form.Item>
                <PurposeFormSpan>
                    {intl.formatMessage({
                        id: '74e6c.AddOptions.acicVolunteerRoleExample',
                        defaultMessage:
                            'Examples such as: Volunteer Fundraiser, Nursing Placement, Bachelor of Education',
                    })}
                </PurposeFormSpan>
            </div>
            <div style={{ alignSelf: 'left' }}>
                <Form.Item
                    name="volunteer_organization"
                    label={
                        <PurposeFormLabel>
                            {intl.formatMessage({
                                id: '74e6c.AddOptions.acicVolunteerOrganization',
                                defaultMessage: 'Organization or University/Institute name',
                            })}
                        </PurposeFormLabel>
                    }
                    rules={[
                        {
                            required: true,
                            message: intl.formatMessage({
                                id: '74e6c.AddOptions.notBlank',
                                defaultMessage: 'Please do not leave blank',
                            }),
                        },
                    ]}
                >
                    <Input disabled={isVolunteerOrganizationDisabled} />
                </Form.Item>
                <PurposeFormSpan>
                    {intl.formatMessage({
                        id: '74e6c.AddOptions.acicvolunteerOrganizationExample',
                        defaultMessage: 'Examples such as: ABC Charity, ABC University',
                    })}
                </PurposeFormSpan>
            </div>
            <Form.Item name="purpose_of_check" initialValue={ACIC_CHECK_REASONS.OTHER} hidden />
        </>
    );
};
