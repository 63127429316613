import { queryKeys } from 'utils/reactQuery/queryKeys';
import { useQuery } from '@tanstack/react-query';
import { PaymentInfo } from 'types';
import { getRequest } from 'utils/http';

export const usePaymentInfo = (onboardingId: string) => {
    const { data, isLoading, isError } = useQuery<PaymentInfo>([queryKeys.paymentInfo], () =>
        getRequest<PaymentInfo>({
            hr: true,
            internal: true,
            endpoint: `onboarding/${onboardingId}/payment`,
            applicantLogin: true,
        })
    );

    return {
        paymentInfo: data,
        paymentInfoLoading: isLoading,
        paymentInfoError: isError,
    };
};
