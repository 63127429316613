import { Input } from 'antd';
import Button from 'certn-ui/Button';
import styled from 'styled-components';

export const AdjustedInput = styled(Input)`
    flex: 0.8;
    margin-right: 10px !important;
    @media (max-width: ${(props) => props.theme.width.sm}) {
        margin-bottom: 5px !important;
    }
`;

export const ResponsiveButton = styled(Button)`
    margin: 0px;
    flex: 0.2;
    @media (max-width: ${(props) => props.theme.width.sm}) {
        width: 100%;
    }
`;
