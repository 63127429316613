import { get } from 'lodash';
import { createSelector } from 'reselect';
import { isFetchingNamespacedSelector } from 'utils/autoredux';

// Constants
import { STATEPATH } from './SettingsActions';
import { NAMESPACE } from './SettingsConstants';

const getLocalState = (state) => get(state, STATEPATH);

export const getIsFetching = (state) => getLocalState(state).isFetching;
export const getSettings = (state) => getLocalState(state).settings;
export const getSettingsError = (state) => getLocalState(state).settingsError;
export const getCurrentTeam = (state) => getLocalState(state).currentTeam;
export const getCurrentTeamCountry = (state) => get(getCurrentTeam(state), ['get_org_country'], null);

export const getLogoUrl = (state) => getLocalState(state).settings.org_logo_link;
export const getPaymentVerified = (state) => getLocalState(state).settings.payment_verified;

export const getCoverLetterReq = (state) => getLocalState(state).settings.cover_letter_req;
export const getGovernmentIdReq = (state) => getLocalState(state).settings.government_id_req;
export const getProofOfIncomeReq = (state) => getLocalState(state).settings.proof_of_income_req;
export const getResumeReq = (state) => getLocalState(state).settings.resume_req;
export const getVisaReq = (state) => getLocalState(state).settings.visa_req;
export const getWebhookSecret = (state) => getLocalState(state).webhookSecret?.[0]?.secret;
export const getAllSuperTeamUsers = (state) => getLocalState(state).allSuperTeamUsers;
export const getAllSuperTeamUserEmails = (state) => getAllSuperTeamUsers(state).map((user) => user?.email);
export const getTeamMembers = (state) => getLocalState(state).teamMembers;
export const getTeamMembersCount = (state) => getLocalState(state).teamMembersCount;
export const getSuperTeamAdmins = (state) => getLocalState(state).superteamAdmins;
export const getGroups = (state) => getLocalState(state).groups;
export const getNonTeamEmailList = createSelector(
    getAllSuperTeamUserEmails,
    getTeamMembers,
    (allSuperTeamUserEmails, teamMembers) => {
        const teamMemberEmails = teamMembers?.map((user) => user?.email);
        const notTeamEmailList = allSuperTeamUserEmails.filter((email) => !teamMemberEmails?.includes(email));
        return notTeamEmailList;
    }
);

export const getIsFetchingTeamMembers = (state) => getLocalState(state).isFetching_teamMembers;
export const getIsFetchingGroups = (state) => getLocalState(state).isFetching_groups;
export const getIsFetchingCurrentTeam = (state) => getLocalState(state).isFetching_currentTeam;
export const getInvoices = (state) => getLocalState(state).invoices;
export const getIntegrations = (state) => getLocalState(state).integrations;

// Reference Templates
export const getReferenceTemplates = (state) => getLocalState(state).referenceTemplates;
export const getEmployerReferenceTemplates = (state) => getLocalState(state).referenceTemplates.employer;
export const getAddressReferenceTemplates = (state) => getLocalState(state).referenceTemplates.address;

// API keys
export const getApiKeys = (state) => getLocalState(state).apiKeys;
// Integration Packages
export const getLeverPackages = (state) => getLocalState(state).integrationPackages.LEVER;
export const getTaleoPackages = (state) => getLocalState(state).integrationPackages.TALEO;
export const getIcimsPackages = (state) => getLocalState(state).integrationPackages.ICIMS;
export const getFountainPackages = (state) => getLocalState(state).integrationPackages.FOUNTAIN;
export const getSuccessFactorsPackages = (state) => getLocalState(state).integrationPackages.SUCCESS_FACTORS;

export const getIntegrationPackageOptions = (state) => getLocalState(state).integrationPackageOptions;

// Auto redux loading
export const isFetchingSelector = isFetchingNamespacedSelector(NAMESPACE);
export const getWebhookSecretFetching = (state) => isFetchingSelector(state, 'GET_WEBHOOK_SECRET');
