import styled from 'styled-components';

export const MouseSignatureWrapper = styled.div`
    & {
        margin: 20px 0px 0px 0px;
        max-width: 400px;
    }
    .clear-signature-btn {
        width: 100%;
        margin-bottom: 10px;
    }
    .sigCanvasBox {
        border-radius: 4px;
        border-color: gray;
        border-width: 2px;
        margin: 7px 0px;
    }

    @media (max-width: 720px) {
        .sigCanvasBox {
            width: 90vw;
            height: 21vh;
        }
    }
    @media (min-width: 720px) {
        .sigCanvasBox {
            width: 400px;
            height: 250px;
        }
    }
`;
