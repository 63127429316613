import { queryKeys } from '../../utils/reactQuery/queryKeys';
import { useQuery } from '@tanstack/react-query';
import { Team } from 'types';
import { getRequest } from 'utils/http';

export const useTeam = ({ id }: { id?: string }) => {
    const { data, isLoading, isError } = useQuery<Team>(
        [queryKeys.team, id],
        () =>
            getRequest({
                version: 'v2',
                endpoint: `/teams/${id}/`,
            }),
        {
            enabled: !!id,
        }
    );

    return {
        team: data,
        teamLoading: isLoading,
        teamError: isError,
    };
};
