// Libraries
import React from 'react';
import { FormattedMessage } from 'react-intl';

// Components
import SummaryItem from './SummaryItem';
import SummaryHeader from './SummaryHeader';
import { FlexRow, SummarySection, SummaryContainer } from './SummaryStyles';

// Constants
import { VACCINATION_READABLE_OPTIONS } from 'views/welcome/WelcomeConstants';

const Vaccination = ({ vaccinationStatus, goToTrack }) => (
    <SummaryContainer>
        <SummaryHeader
            title={
                <FormattedMessage id="welcome.general.vaccinationStatus" defaultMessage="COVID-19 Vaccination Status" />
            }
            onClick={goToTrack}
        />
        <SummarySection>
            <FlexRow>
                <SummaryItem
                    full
                    title={
                        <FormattedMessage
                            id="welcome.general.vaccination"
                            defaultMessage="Self-Reported COVID-19 Vaccination Status"
                        />
                    }
                    text={VACCINATION_READABLE_OPTIONS[vaccinationStatus]}
                />
            </FlexRow>
        </SummarySection>
    </SummaryContainer>
);

export default Vaccination;
