import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Space } from 'antd';
import { Icon as LegacyIcon } from '@ant-design/compatible';

// Components
import Title from 'certn-ui/Title';
import Button from 'certn-ui/Button';
import { CustomAntButton, OptionsWrapper } from './styled';
import { UserAddOutlined } from '@ant-design/icons';

// Actions & Selectors
import { getUserMode } from 'base/BaseSelectors';

const Congrats = ({ closeModal }) => {
    const userMode = useSelector(getUserMode);
    const [hasToured, setToured] = useState(false);

    const history = useHistory();

    const handleScreenClick = () => {
        const route = userMode ? `/${userMode.toLowerCase()}/screen` : '/hr/screen';
        history.push(route);
        closeModal();
    };

    const handleTakeTourClick = () => {
        window.open('https://youtu.be/HjtEkhtlqXE', '_blank');
        setToured(true);
    };

    return (
        <>
            <Title padding="0px 0px 20px 0px">
                <FormattedMessage
                    id="1d2c3.Manager.congratsTitle"
                    defaultMessage="Congrats! Now let’s screen your first applicant."
                />
            </Title>
            <OptionsWrapper>
                <Button
                    size="large"
                    style={{ width: '100%' }}
                    type="primary"
                    certncolor="certnGreen700"
                    onClick={handleScreenClick}
                >
                    <Space>
                        <UserAddOutlined />
                        <FormattedMessage
                            id="1d2c3.Manager.inviteApplicant"
                            defaultMessage="Invite Your First Applicant"
                        />
                    </Space>
                </Button>
                <Button
                    size="large"
                    style={{ width: '100%' }}
                    type="secondary"
                    certncolor="certnOrange400"
                    onClick={handleTakeTourClick}
                >
                    <Space>
                        <LegacyIcon type={hasToured ? 'check' : 'question'} />
                        <FormattedMessage id="1d2c3.Manager.takeTour" defaultMessage="Take me on a tour" />
                    </Space>
                </Button>
                <CustomAntButton size="large" type="link" onClick={closeModal}>
                    <FormattedMessage id="1d2c3.Manager.skipDashboard" defaultMessage="Skip to Dashboard" />
                </CustomAntButton>
            </OptionsWrapper>
        </>
    );
};

Congrats.propTypes = {
    closeModal: PropTypes.func.isRequired,
};

export default Congrats;
