/** IE11 requires all of the following polyfills. * */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'regenerator-runtime/runtime';

// react intl support for older browsers
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/locale-data/fr'; // Add locale data for fr
import '@formatjs/intl-pluralrules/locale-data/es';
import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/locale-data/fr'; // Add locale data for fr
import '@formatjs/intl-relativetimeformat/locale-data/es';

import 'isomorphic-fetch';
// React
import * as React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import Base from 'base/Base';

// Modules, Utils, Logging, etc.
import * as Sentry from '@sentry/browser';
import { LDProviderFactory } from 'modules/launchDarkly';
import 'whatwg-fetch'; // required until cypress support fetch API
import { queryClient } from 'utils/reactQuery';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import configureStore from './store';

// CSS
import 'antd/dist/antd.less';
import '@ant-design/compatible/assets/index.css';
import 'react-select/dist/react-select.css';
import 'styles/styles.css';
import 'styles/index.less';
import 'styled-components/macro';

// Init Sentry
Sentry.init({
    dsn: `https://ec5c61543a5a412ab69f4b237dda3978@o132497.ingest.sentry.io/292841`,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    release: process.env.REACT_APP_SENTRY_RELEASE,
});

const store = configureStore();

(async () => {
    const LDProvider = await LDProviderFactory();

    ReactDOM.render(
        <LDProvider>
            <QueryClientProvider client={queryClient}>
                <ReactQueryDevtools position="bottom-right" />
                <Provider store={store}>
                    <BrowserRouter>
                        <Base />
                    </BrowserRouter>
                </Provider>
            </QueryClientProvider>
        </LDProvider>,
        document.getElementById('root')
    );
})();
