import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

// Components
import Button, { ButtonLine } from 'certn-ui/Button';

// Actions & Selectors
import { getIsFetchingSilent } from 'views/welcome/WelcomeSelectors';

const CustomButton = styled(Button)`
    ${({ dualButtons }) => dualButtons && 'flex: 1 0 auto'};
    min-width: 200px;
    text-transform: uppercase;
`;

export const NextButtonNoButtonLine = ({ dualButtons, onClick, disabled, loading, nextButtonTitle }) => {
    const isFetchingSilent = useSelector(getIsFetchingSilent);
    const intl = useIntl();
    return (
        <CustomButton
            dualButtons={dualButtons}
            onClick={onClick}
            id="next"
            type="primary"
            size="large"
            certncolor={disabled ? 'certnGray100' : 'primary'}
            htmlType="submit"
            wide="true"
            loading={loading || isFetchingSilent}
            disabled={disabled}
        >
            {nextButtonTitle || intl.formatMessage({ id: 'welcome.general.next', defaultMessage: 'Next' })}
        </CustomButton>
    );
};

const NextButton = (props) => (
    <ButtonLine>
        <NextButtonNoButtonLine {...props} />
    </ButtonLine>
);

NextButton.propsTypes = {
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    children: PropTypes.node,
};

NextButton.defaultProps = {
    onClick: null,
    disabled: false,
    loading: false,
    children: undefined,
};

export default NextButton;
