import { PackageServices } from '../PackageServices';
import { PackageName } from '../PackageName';
import { PackageQuickscreen } from '../PackageQuickscreen';
import { useContext } from 'react';
import { PackagesContext } from '../../Packages.context';
import { usePackageChecks } from '../../hooks/usePackageChecks';
import Loader from 'certn-ui/Loader';

export const PackageForm = () => {
    const { allowQuickscreen } = useContext(PackagesContext);
    const { isLoading, validChecks } = usePackageChecks();
    if (isLoading) return <Loader />;
    return (
        <>
            <PackageName />
            {allowQuickscreen && <PackageQuickscreen />}
            <PackageServices validChecks={validChecks} />
        </>
    );
};
