import * as React from 'react';
import styles from 'styles/styles';

function ScreenInfoMyself(props) {
    return (
        <svg width={41} height={39} viewBox="0 0 41 39" fill="none" {...props}>
            <path
                d="M28.912 11.381V4.203c0-1.38-1.129-2.51-2.51-2.51H8.735l-7.003 6.08v27.651c0 1.38 1.13 2.51 2.51 2.51h22.161a2.516 2.516 0 002.51-2.51V30.55"
                stroke={styles.color.certnBlue900}
                strokeWidth={1.568}
            />
            <path d="M11.002 2.18V9.9H6.289" stroke={styles.color.certnBlue900} strokeWidth={1.568} />
            <path
                d="M6.968 19.845h13.31M6.968 24.89h11.375M6.968 29.935h10.239"
                stroke={styles.color.certnBlue900}
                strokeWidth={1.568}
                strokeLinecap="round"
            />
            <path
                clipRule="evenodd"
                d="M28.628 27.585l-8.276 2.503 2.87-8.292L33.95 11.068c.591-.59 1.743-.407 2.561.405l2.629 2.63c.816.817 1 1.97.407 2.562l-10.919 10.92z"
                stroke={styles.color.certnBlue900}
                strokeWidth={1.568}
                strokeLinejoin="round"
            />
            <path
                d="M29.005 27.016l-5.39-5.391M22.88 29.198l-1.504-1.542M26.42 24.335l6.071-6.073M31.77 13.495l5.35 5.35"
                stroke={styles.color.certnBlue900}
                strokeWidth={1.568}
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default ScreenInfoMyself;
