// Libraries
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Auth from 'modules/Auth';
import { injectIntl, FormattedMessage } from 'react-intl';

// Actions & Selectors
import { getUser, getUserMode } from 'base/BaseSelectors';

// UI Components
import Form from 'certnd/Form';
import Typography from 'certnd/Typography';
import {
    getCoverLetterReq,
    getGovernmentIdReq,
    getProofOfIncomeReq,
    getResumeReq,
    getVisaReq,
} from '../../../SettingsSelectors';

const { Heading } = Typography;

const mapStateToProps = (state) => ({
    coverLetterReq: getCoverLetterReq(state),
    governmentIdReq: getGovernmentIdReq(state),
    proofOfIncomeReq: getProofOfIncomeReq(state),
    resumeReq: getResumeReq(state),
    visaReq: getVisaReq(state),
    userMode: getUserMode(state),
    user: getUser(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

// eslint-disable-next-line
class DocumentUpload extends React.Component {
    render() {
        const { form, settings, userMode, user } = this.props;

        const canEdit = Auth.isPermissionLevel('manager');

        const isBoth = user.is_hr_user && user.is_user;

        return (
            <Form.Block>
                <Heading.H3>
                    <FormattedMessage id="30c9c.DocumentUpload.Title" defaultMessage="Document Upload" />
                </Heading.H3>

                <Form.Checkbox
                    form={form}
                    fieldName="government_id_req"
                    title={this.props.intl.formatMessage({
                        id: '30c9c.DocumentUpload.GovernmentTitle',
                        defaultMessage: 'Government Id',
                    })}
                    description={this.props.intl.formatMessage({
                        id: '30c9c.DocumentUpload.GovernmentDescription',
                        defaultMessage: 'Require applicant to upload a Government ID',
                    })}
                    options={{ initialValue: settings.government_id_req }}
                    disabled={!canEdit}
                />

                <Form.Checkbox
                    form={form}
                    fieldName="passport_req"
                    title={this.props.intl.formatMessage({
                        id: '30c9c.DocumentUpload.PassportTitle',
                        defaultMessage: 'Passport',
                    })}
                    description={this.props.intl.formatMessage({
                        id: '30c9c.DocumentUpload.PassportDescription',
                        defaultMessage: 'Require applicant to upload a photo of their passport',
                    })}
                    options={{ initialValue: settings.passport_req }}
                    disabled={!canEdit}
                />

                {(userMode === 'HR' || isBoth) && (
                    <Form.Checkbox
                        form={form}
                        fieldName="resume_req"
                        title={
                            isBoth
                                ? this.props.intl.formatMessage({
                                      id: '30c9c.DocumentUpload.ResumeTitleBoth',
                                      defaultMessage: 'Resume (Human Resources Only)',
                                  })
                                : this.props.intl.formatMessage({
                                      id: '30c9c.DocumentUpload.ResumeTitle',
                                      defaultMessage: 'Resume',
                                  })
                        }
                        description={this.props.intl.formatMessage({
                            id: '30c9c.DocumentUpload.ResumeDescription',
                            defaultMessage: 'Require applicant to upload a Resume',
                        })}
                        options={{ initialValue: settings.resume_req }}
                        disabled={!canEdit}
                    />
                )}

                {(userMode === 'HR' || isBoth) && (
                    <Form.Checkbox
                        form={form}
                        fieldName="cover_letter_req"
                        title={
                            isBoth
                                ? this.props.intl.formatMessage({
                                      id: '30c9c.DocumentUpload.CoverLetterTitleBoth',
                                      defaultMessage: 'CoverLetter (Human Resources Only)',
                                  })
                                : this.props.intl.formatMessage({
                                      id: '30c9c.DocumentUpload.CoverLetterTitle',
                                      defaultMessage: 'CoverLetter',
                                  })
                        }
                        description={this.props.intl.formatMessage({
                            id: '30c9c.DocumentUpload.CoverLetterDescription',
                            defaultMessage: 'Require applicant to upload a Cover Letter',
                        })}
                        options={{ initialValue: settings.cover_letter_req }}
                        disabled={!canEdit}
                    />
                )}

                {(userMode === 'HR' || isBoth) && (
                    <Form.Checkbox
                        form={form}
                        fieldName="visa_req"
                        title={
                            isBoth
                                ? this.props.intl.formatMessage({
                                      id: '30c9c.DocumentUpload.VisaTitleBoth',
                                      defaultMessage: 'Visa (Human Resources Only)',
                                  })
                                : this.props.intl.formatMessage({
                                      id: '30c9c.DocumentUpload.VisaTitle',
                                      defaultMessage: 'Visa',
                                  })
                        }
                        description={this.props.intl.formatMessage({
                            id: '30c9c.DocumentUpload.VisaDescription',
                            defaultMessage:
                                'Require applicant to upload a Visa (Work, Immigration, Permanent Resident)',
                        })}
                        options={{ initialValue: settings.visa_req }}
                        disabled={!canEdit}
                    />
                )}

                {(userMode === 'PM' || isBoth) && (
                    <Form.Checkbox
                        form={form}
                        fieldName="proof_of_income_req"
                        title={
                            isBoth
                                ? this.props.intl.formatMessage({
                                      id: '30c9c.DocumentUpload.ProofTitleBoth',
                                      defaultMessage: 'Proof of Income (Human Resources Only)',
                                  })
                                : this.props.intl.formatMessage({
                                      id: '30c9c.DocumentUpload.ProofTitle',
                                      defaultMessage: 'Proof of Income',
                                  })
                        }
                        description={this.props.intl.formatMessage({
                            id: '30c9c.DocumentUpload.ProofDescription',
                            defaultMessage: 'Require applicant to upload a document that proves their stated income',
                        })}
                        options={{ initialValue: settings.proof_of_income_req }}
                        disabled={!canEdit}
                    />
                )}

                <Form.Button disabled={!canEdit}>
                    <FormattedMessage id="common.save" defaultMessage="Save" />
                </Form.Button>
            </Form.Block>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(DocumentUpload));
