/* eslint-disable react/require-default-props */ // Turn off this rule when we fully move to TS
import styled from 'styled-components/macro';
import { StyledProps } from 'types/styled-components';

export const FlowSubTitle = styled.div`
    width: 450px;
    margin: auto;
    margin-bottom: 20px;
    @media (max-width: ${({ theme }) => theme.width.tablet}) {
        width: 100%;
    }
`;

const TitleContainer = styled.div<Partial<StyledProps>>`
    padding: ${(props) => (props.size === 'xxs' || props.size === 'xs' ? '10px 5px' : '20px')};
    margin: 0 auto;
    text-align: center;
    @media (min-width: ${({ theme }) => theme.width.desktop}) {
        max-width: 80%;
    }
    ${({ padding }) => padding && `padding: ${padding}`}
`;

const TitleText = styled.span<Partial<StyledProps>>`
    text-align: center;
    color: ${(props) => props.theme.color[props.color || 'certnGray600']};
    font-size: ${(props) => props.theme.font[props.size as string]};
    @media (max-width: 720px) {
        font-size: 16px;
    }
`;

const Title = ({
    children,
    font = 'adelle',
    size = 'lrg',
    padding,
    color,
}: {
    children: React.ReactNode;
    font?: string;
    size?: string;
    padding?: string;
    color?: string;
}): JSX.Element => (
    <TitleContainer size={size} padding={padding}>
        <TitleText data-testid="title" font={font} size={size} color={color}>
            {children}
        </TitleText>
    </TitleContainer>
);

export default Title;
