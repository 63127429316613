// Libraries
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';

// Components
import { ErrorAlertAPI } from 'certn-ui/ErrorAlert';
import { TalentHubLoginForm } from './components';
import Titlebar from 'certn-ui/Titlebar';
import Auth from 'modules/Auth';

// Actions & Selectors
import { clearLogin } from 'views/guest/GuestActions';
import { talentHubLogin } from '../../GuestActions';
import { getIsFetchingSilent, getParams } from 'base/BaseSelectors';
import { loginBasic } from 'base/BaseActions';

function IntegrationLogin(props) {
    const isFetchingSilent = useSelector(getIsFetchingSilent);
    const params = useSelector(getParams);
    const dispatch = useDispatch();

    const isTalentHub = props.match.params.integration === 'talenthub';

    if (isTalentHub && params) {
        const { hiringContext, extensionType, redirectUrl, signature } = params;

        if (hiringContext && extensionType && redirectUrl && signature) {
            localStorage.setItem(
                'talentHubParams',
                JSON.stringify({
                    hiringContext,
                    extensionType,
                    redirectUrl,
                    signature,
                })
            );
        }
    }

    const hasTalentHubQueryParams = localStorage.getItem('talentHubParams');

    useEffect(() => {
        if (Auth.isUserAuthenticated() && hasTalentHubQueryParams?.redirectUrl) {
            window.location.href = hasTalentHubQueryParams.redirectUrl;
        }

        return dispatch(clearLogin());
    }, [dispatch, hasTalentHubQueryParams]);

    const handleTalentHubLogin = (authForm) => {
        const { hiringContext, extensionType, redirectUrl, signature } = JSON.parse(
            localStorage.getItem('talentHubParams')
        );

        const data = { hiringContext, extensionType, redirectUrl, signature };

        return dispatch(loginBasic({ loginForm: authForm }))
            .then(() => dispatch(talentHubLogin(data)))
            .then(() => {
                localStorage.removeItem('talentHubParams');
            })
            .catch((error) => {
                ErrorAlertAPI(error);
            });
    };

    return (
        <>
            {isTalentHub && hasTalentHubQueryParams && (
                <>
                    <Titlebar
                        size="xlrg"
                        center
                        title={props.intl.formatMessage({
                            id: 'a3d95.Login.talentHubTitle',
                            defaultMessage: 'Sign In To Certn',
                        })}
                        subtitle={props.intl.formatMessage({
                            id: 'a3d95.Login.talentHubSubtitle',
                            defaultMessage: 'LinkedIn Talent Hub Integration',
                        })}
                    />
                    <TalentHubLoginForm onSubmit={handleTalentHubLogin} loading={isFetchingSilent} />
                </>
            )}
            {isTalentHub && !hasTalentHubQueryParams && (
                <Titlebar
                    size="xlrg"
                    center
                    title={props.intl.formatMessage({
                        id: 'a3d95.Login.talentHubTitleNoQueryParams',
                        defaultMessage:
                            'You need the special URL from LinkedIn Talent Hub in order to log in here. Please try again.',
                    })}
                />
            )}
        </>
    );
}

export default withRouter(injectIntl(IntegrationLogin));
