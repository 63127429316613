// Libraries
import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import { Input, Select, Popover } from 'antd';
import { get } from 'lodash';

// Components
import { AppearRight } from 'certn-ui/Animate';
import Title from 'certn-ui/Title';
import FormLayout from 'certn-form/FormLayout';
import { TrackNavigation } from 'views/welcome/components';

// Modules
import { Regex } from 'modules';

const RoommatesForm = ({
    form: { setFieldsValue, getFieldValue, getFieldDecorator, validateFields },
    handleSubmit,
    handleReverse,
    roommates,
    currentRoommateIndex,
    intl,
}) => (
    <AppearRight>
        <Title>
            <FormattedMessage
                id="welcome.Supplemental.roommates.baseTitle"
                defaultMessage="Can you give me a few details about your roommate?"
            />
        </Title>
        <Form layout="horizontal" onSubmit={(e) => handleSubmit(e, validateFields)}>
            <FormLayout.R2N size="6" offset="6">
                <Form.Item>
                    {getFieldDecorator('first_name', {
                        initialValue: get(roommates, [currentRoommateIndex, 'first_name']),
                        rules: [
                            {
                                pattern: Regex.letters,
                                message: intl.formatMessage({
                                    id: 'error.validation.letters',
                                    defaultMessage: 'Please only use letters',
                                }),
                            },
                            {
                                required: true,
                                message: intl.formatMessage({
                                    id: 'error.validation.notBlank',
                                    defaultMessage: 'Please do not leave blank',
                                }),
                            },
                        ],
                    })(
                        <Input
                            placeholder={intl.formatMessage({
                                id: 'common.firstName',
                                defaultMessage: 'First Name',
                            })}
                            addonBefore={
                                <Popover
                                    content={intl.formatMessage({
                                        id: 'common.firstName',
                                        defaultMessage: 'First Name',
                                    })}
                                >
                                    <InfoCircleOutlined />
                                </Popover>
                            }
                        />
                    )}
                </Form.Item>
                <Form.Item>
                    {getFieldDecorator('last_name', {
                        initialValue: get(roommates, [currentRoommateIndex, 'last_name']),
                        rules: [
                            {
                                pattern: Regex.letters,
                                message: intl.formatMessage({
                                    id: 'error.validation.letters',
                                    defaultMessage: 'Please only use letters',
                                }),
                            },
                            {
                                required: true,
                                message: intl.formatMessage({
                                    id: 'error.validation.notBlank',
                                    defaultMessage: 'Please do not leave blank',
                                }),
                            },
                        ],
                    })(
                        <Input
                            placeholder={intl.formatMessage({
                                id: 'common.lastName',
                                defaultMessage: 'Last Name',
                            })}
                            addonBefore={
                                <Popover
                                    content={intl.formatMessage({
                                        id: 'common.lastName',
                                        defaultMessage: 'Last Name',
                                    })}
                                >
                                    <InfoCircleOutlined />
                                </Popover>
                            }
                        />
                    )}
                </Form.Item>
                <Form.Item>
                    {getFieldDecorator('adult_child', {
                        initialValue: get(roommates, [currentRoommateIndex, 'adult_child']),
                        rules: [
                            {
                                required: true,
                                message: intl.formatMessage({
                                    id: 'error.validation.chooseOption',
                                    defaultMessage: 'Please choose an option',
                                }),
                            },
                        ],
                    })(
                        <Select
                            placeholder={intl.formatMessage({
                                id: 'welcome.Supplemental.roommates.adultOrChild',
                                defaultMessage: 'Adult or Child?',
                            })}
                            onChange={() => setFieldsValue({ email: undefined })}
                        >
                            <Select.Option value="A">
                                <FormattedMessage id="welcome.Supplemental.roommates.adult" defaultMessage="Adult" />
                            </Select.Option>
                            <Select.Option value="C">
                                <FormattedMessage id="welcome.Supplemental.roommates.child" defaultMessage="Child" />
                            </Select.Option>
                        </Select>
                    )}
                </Form.Item>
                <Form.Item>
                    {getFieldDecorator('email', {
                        initialValue: get(roommates, [currentRoommateIndex, 'email']),
                        rules: [
                            {
                                type: 'email',
                                message: intl.formatMessage({
                                    id: 'error.validation.email.invalid',
                                    defaultMessage: 'The input is not a valid E-mail!',
                                }),
                            },
                            {
                                required: getFieldValue('adult_child') === 'A',
                                message: intl.formatMessage({
                                    id: 'error.validation.email.required',
                                    defaultMessage: 'Please input your E-mail!',
                                }),
                            },
                            // {
                            //     validator: (rule, value, callback) => {
                            //         callback(
                            //             intl.formatMessage({
                            //                 id: existRoommateEmail(value, applicantAccount.email),
                            //             }) && getFieldValue('adult_child') === 'A'
                            //         );
                            //     },
                            // },
                        ],
                    })(
                        <Input
                            disabled={getFieldValue('adult_child') !== 'A'}
                            placeholder={intl.formatMessage({
                                id: 'common.email',
                                defaultMessage: 'Email',
                            })}
                            addonBefore={
                                <Popover
                                    content={intl.formatMessage({
                                        id: 'common.email',
                                        defaultMessage: 'Email',
                                    })}
                                >
                                    <InfoCircleOutlined />
                                </Popover>
                            }
                        />
                    )}
                </Form.Item>
            </FormLayout.R2N>
            <TrackNavigation handleReverse={handleReverse} />
        </Form>
    </AppearRight>
);

export default Form.create()(injectIntl(RoommatesForm));
