import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ComplianceDocuments } from './ComplianceDocuments/ComplianceDocuments';
import { getRequest } from 'utils/http';
import withNavigation from 'views/welcome/modules/WithNavigation';
import { getApplicant } from 'views/welcome/WelcomeSelectors';
import { ErrorAlertAPI } from 'certn-ui/ErrorAlert';
import { ConsentAndDisclosureTemplateProps } from '.';

type ConsentAndDisclosureProps = {
    handleNextTrack: () => void;
};

const ConsentAndDisclosure: React.FC<ConsentAndDisclosureProps> = ({ handleNextTrack }) => {
    const applicantId = useSelector(getApplicant)?.id;
    const [templates, setTemplates] = useState<ConsentAndDisclosureTemplateProps[]>([]);

    useEffect(() => {
        async function fetchTemplates() {
            try {
                const response = (await getRequest({
                    version: 'v2',
                    endpoint: `/applicant/${applicantId}/consent-and-disclosure-templates`,
                })) as unknown;
                setTemplates(response as ConsentAndDisclosureTemplateProps[]);
            } catch (error) {
                ErrorAlertAPI(error);
            }
        }
        fetchTemplates();
    }, [applicantId]);

    return <ComplianceDocuments handleNextTrack={handleNextTrack} templates={templates} applicantId={applicantId} />;
};

export default withRouter(withNavigation(ConsentAndDisclosure));
