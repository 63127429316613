// Libraries
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { Select, Button, Input, Form, Space } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';

// Components
import { RequiredText } from 'certn-ui';
import DateInput from 'certn-form/DateInput';

// Actions & Selectors
import { fetchConvictionOffenses } from 'views/welcome/WelcomeActions';
import { getConvictionOffenses, getConvictions } from 'views/welcome/WelcomeSelectors';

const { Option, OptGroup } = Select;

const ConvictionsWrapper = styled.div`
    margin-top: 10px;
    border: 1px solid ${({ theme }) => theme.color.certnGray200};
    padding: 10px;
`;

const MultiFormConvictions = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const convictions = useSelector(getConvictions) || [];

    useEffect(() => {
        dispatch(fetchConvictionOffenses());
    }, [dispatch]);

    const convictionOffenses = useSelector(getConvictionOffenses);

    return (
        <Form.List name="convictions" initialValues={convictions}>
            {(fields, { add, remove }) => (
                <>
                    {fields.map(
                        (field) =>
                            field && (
                                <ConvictionsWrapper>
                                    <RequiredText>
                                        {intl.formatMessage({
                                            id: 'welcome.History.ConvictionsForm.offence',
                                            defaultMessage: 'Conviction Offense',
                                        })}
                                    </RequiredText>
                                    <Form.Item shouldUpdate noStyle>
                                        {/* Access to additional functions */}
                                        {({ getFieldValue, setFieldsValue }) => (
                                            <Form.Item
                                                name={[field.name, 'conviction_details']}
                                                shouldUpdate
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: intl.formatMessage({
                                                            id: 'error.validation.notBlank',
                                                            defaultMessage: 'Please do not leave blank',
                                                        }),
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    // Convictions come back with "Offense_###"
                                                    // and need to be split up into descrption and offense for API
                                                    onChange={(value) => {
                                                        if (!value) return;
                                                        const conviction = value.split('_');
                                                        const newData = [...getFieldValue('convictions')];
                                                        newData[field.name] = {
                                                            ...newData[field.name],
                                                            description: conviction[0],
                                                            offense: conviction[1],
                                                        };
                                                        setFieldsValue({
                                                            convictions: newData,
                                                        });
                                                    }}
                                                    placeholder={intl.formatMessage({
                                                        id: 'welcome.History.ConvictionsForm.offence',
                                                        defaultMessage: 'Conviction Offense',
                                                    })}
                                                    showSearch
                                                >
                                                    {Object.entries(convictionOffenses).map(([key, value], i) => (
                                                        <OptGroup key={key} label={key}>
                                                            {value.map((offense, index) => (
                                                                <Option
                                                                    key={i + index + offense.code}
                                                                    value={`${offense.offense}_${offense.code}_${
                                                                        i + index
                                                                    }`}
                                                                >
                                                                    {offense.offense}
                                                                </Option>
                                                            ))}
                                                        </OptGroup>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        )}
                                    </Form.Item>

                                    {/* Hidden fields Split from the above conviction description onChange */}
                                    <Form.Item name={[field.name, 'description']} style={{ display: 'none' }} />

                                    <Form.Item name={[field.name, 'offense']} style={{ display: 'none' }} />
                                    {/* End of hidden fields */}

                                    <RequiredText>
                                        {intl.formatMessage({
                                            id: 'welcome.general.dateOfSentence',
                                            defaultMessage: 'Date Of Sentence',
                                        })}
                                    </RequiredText>
                                    <Form.Item
                                        shouldUpdate
                                        name={[field.name, 'date_of_sentence']}
                                        initialValue={undefined}
                                        validateTrigger={['onChange']}
                                    >
                                        <DateInput
                                            placeholder={intl.formatMessage({
                                                id: 'welcome.general.dateOfSentence',
                                                defaultMessage: 'Date Of Sentence',
                                            })}
                                            disableFutureDates
                                        />
                                    </Form.Item>

                                    <RequiredText>
                                        {intl.formatMessage({
                                            id: 'welcome.general.courtLocation',
                                            defaultMessage: 'Court Location',
                                        })}
                                    </RequiredText>
                                    <Form.Item
                                        shouldUpdate
                                        name={[field.name, 'court_location']}
                                        rules={[
                                            {
                                                required: true,
                                                message: intl.formatMessage({
                                                    id: 'error.validation.notBlank',
                                                    defaultMessage: 'Please do not leave blank',
                                                }),
                                            },
                                        ]}
                                    >
                                        <Input
                                            placeholder={intl.formatMessage({
                                                id: 'welcome.general.courtLocation',
                                                defaultMessage: 'Court Location',
                                            })}
                                        />
                                    </Form.Item>

                                    {fields.length > 1 && (
                                        <Button
                                            danger
                                            className="dynamic-delete-button"
                                            type="dashed"
                                            style={{ width: 'fit-content' }}
                                            onClick={() => {
                                                remove(field.name);
                                            }}
                                        >
                                            <Space>
                                                <DeleteOutlined />
                                                {intl.formatMessage({
                                                    id: 'common.remove',
                                                    defaultMessage: 'Remove',
                                                })}
                                            </Space>
                                        </Button>
                                    )}
                                </ConvictionsWrapper>
                            )
                    )}

                    <Button
                        style={{ marginTop: '10px' }}
                        type="dashed"
                        onClick={() => {
                            add();
                        }}
                        block
                    >
                        <PlusOutlined />
                        {intl.formatMessage({
                            id: 'welcome.History.ConvictionsForm.buttonText',
                            defaultMessage: 'Add Conviction',
                        })}
                    </Button>
                </>
            )}
        </Form.List>
    );
};

export default MultiFormConvictions;
