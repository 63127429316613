// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { intl } from 'components/GlobalProvider';

// UI Components
import { ButtonLine } from 'certn-ui/Button';
import { Box } from 'certn-ui/Layout';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Input, Button, Form } from 'antd';

const propTypes = {
    // Parent Props
    onSubmit: PropTypes.func.isRequired,
};
const defaultProps = {};

const TalentHubLoginForm = (props) => {
    const [form] = Form.useForm();

    return (
        <Box size="xs">
            <Form form={form} onFinish={props.onSubmit} style={{ width: '100%' }}>
                <Form.Item
                    name="email"
                    rules={[
                        {
                            type: 'email',
                            required: true,
                            message: (
                                <FormattedMessage
                                    id="ef184.TalentHubLoginForm.inputEmail"
                                    defaultMessage="Please input your Certn email!"
                                />
                            ),
                        },
                    ]}
                >
                    <Input
                        size="large"
                        data-testid="email"
                        prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder={intl.formatMessage({
                            id: 'ef184.TalentHubLoginForm.emailPlaceholder',
                            defaultMessage: 'Certn Email',
                        })}
                    />
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: (
                                <FormattedMessage
                                    id="ef184.TalentHubLoginForm.inputPassword"
                                    defaultMessage="Please input your Certn Password!"
                                />
                            ),
                        },
                    ]}
                >
                    <Input
                        size="large"
                        data-testid="password"
                        prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                        type="password"
                        placeholder={intl.formatMessage({
                            id: 'ef184.TalentHubLoginForm.password',
                            defaultMessage: 'Certn Password',
                        })}
                    />
                </Form.Item>
                <ButtonLine>
                    <Button
                        data-testid="submit"
                        type="primary"
                        size="large"
                        certncolor="primary"
                        htmlType="submit"
                        style={{ width: '100%' }}
                        loading={props.loading}
                    >
                        <FormattedMessage id="ef184.TalentHubLoginForm.login" defaultMessage="Sign In To Certn" />
                    </Button>
                </ButtonLine>
                <ButtonLine>
                    <Link data-testid="forgot-password" to="/forgot">
                        <FormattedMessage
                            id="ef184.TalentHubLoginForm.forgotPassword"
                            defaultMessage="Forgot your Certn password?"
                        />
                    </Link>
                </ButtonLine>
                <ButtonLine marginOff>
                    <a href="https://info.certn.co/linkedintalenthub/">
                        <FormattedMessage id="ef184.TalentHubLoginForm.learnMore" defaultMessage="Learn More" />
                    </a>
                </ButtonLine>
            </Form>
        </Box>
    );
};

TalentHubLoginForm.propTypes = propTypes;
TalentHubLoginForm.defaultProps = defaultProps;

export default TalentHubLoginForm;
