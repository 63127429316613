// Libraries
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import { formatDate } from 'modules/Format';

// Components
import SummaryItem from './SummaryItem';
import SummaryHeader from './SummaryHeader';
import { FlexRow, SummarySection, SummaryContainer, LineBreak } from './SummaryStyles';

const Credential = ({ credentials, goToTrack }) => (
    <SummaryContainer>
        <SummaryHeader
            title={<FormattedMessage id="welcome.Summary.credentials" defaultMessage="Credentials" />}
            onClick={goToTrack}
        />
        <SummarySection>
            {credentials.length === 0 && (
                <SummaryItem
                    empty
                    title={
                        <FormattedMessage
                            id="welcome.Summary.noCredentialListed"
                            defaultMessage="You have not listed any credentials"
                        />
                    }
                />
            )}
            {credentials.map((credential, i) => (
                <React.Fragment key={i}>
                    <FlexRow>
                        <SummaryItem
                            title={<FormattedMessage id="welcome.Summary.institution" defaultMessage="Institution" />}
                            text={credential.institution}
                        />
                        <SummaryItem
                            title={
                                <FormattedMessage
                                    id="welcome.Summary.dateOfCertification"
                                    defaultMessage="Date Of Certification"
                                />
                            }
                            text={formatDate(credential.date_of_certification)}
                        />
                        <SummaryItem
                            title={<FormattedMessage id="common.description" defaultMessage="Description" />}
                            text={credential.description}
                        />
                    </FlexRow>

                    {credentials.length > 1 && i + 1 < credentials.length && <LineBreak />}
                </React.Fragment>
            ))}
        </SummarySection>
    </SummaryContainer>
);

export default injectIntl(Credential);
