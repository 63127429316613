import React from 'react';
import styles from 'styles/styles';

const Parking = ({ scale }) => (
    <svg width={69} height={59} style={{ transform: `scale3d(${scale}, ${scale}, ${scale})` }}>
        <title>garage_car_parking</title>
        <g stroke={styles.color.certnGreen700} strokeWidth={1.525} fill="none" fillRule="evenodd">
            <path strokeLinecap="round" strokeLinejoin="round" d="M.839 13.316L34.403 1.623 68.16 13.321" />
            <g strokeLinecap="round" strokeLinejoin="round">
                <path d="M11.917 42.643h10.969s-.44-4.805-3.39-4.805h-2.209M57.08 42.643H46.113s.439-4.805 3.389-4.805h2.209" />
            </g>
            <path
                d="M21.538 29.563h25.923M46.623 52.396v4.98h9.309v-5.461c.85-.334 1.471-1.166 1.471-2.131V38.666s-.115-1.096-.512-2.443L53.484 28.8l-2.01-6.255c-.559-3.107-4.707-4.776-6.568-4.776H24.092c-1.86 0-6.009 1.669-6.568 4.776l-2.01 6.255-3.407 7.423c-.395 1.348-.511 2.443-.511 2.443v11.119c0 .965.621 1.797 1.471 2.131v5.461h9.309v-4.98"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <g strokeLinecap="round" strokeLinejoin="round">
                <path d="M10.681 27.819l-4.197-.953M58.318 27.819l4.198-.953" />
            </g>
            <path d="M18.63 52.074h31.739" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M26.14 47.232h16.719" />
        </g>
    </svg>
);

export default Parking;
