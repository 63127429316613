import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form as AntdForm } from '@ant-design/compatible';
import { Button, Modal, message } from 'antd';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { intl } from 'components/GlobalProvider';

// Components
import { ErrorAlertAPI } from 'certn-ui/ErrorAlert';
import Form from 'certnd/Form';
import { getScreenApplicantButtonsDisabled } from 'views/manager/features/ScreenApplicant/ScreenApplicantSelectors';
import {
    setPackageData,
    saveAsPackage,
    updatePackage,
    additionalOptionsConflictCheck,
} from 'views/manager/features/ScreenApplicant/ScreenApplicantActions';
import { getIsFetching } from 'views/manager/views/hr/views/postings/PostingsSelectors';

// Constants
import { NEW } from 'views/manager/features/ScreenApplicant/ScreenApplicantConstants';

const ButtonSaveAsPackage = AntdForm.create()(({ form }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {
        params: { packageID },
    } = useRouteMatch();
    const location = useLocation();
    const isFetching = useSelector(getIsFetching);
    const [visible, setVisible] = useState(false);
    const toggleVisible = () => setVisible(!visible);
    const [editingPackageValues] = useState(location.state?.newValues);
    const disabled = useSelector(getScreenApplicantButtonsDisabled);

    const handleUpdatePackage = () => {
        const readyToSubmit = dispatch(additionalOptionsConflictCheck);
        if (readyToSubmit) {
            dispatch(updatePackage(packageID, editingPackageValues))
                .then(() => {
                    message.success(
                        intl.formatMessage({
                            id: 'a0e86.ScreenApplicant.packageSaved',
                            defaultMessage: 'Package Saved',
                        })
                    );
                    history.push('/hr/packages/');
                })
                .catch((error) => ErrorAlertAPI(error));
        }
    };

    const handleSaveNewPackage = () => {
        const readyToSubmit = dispatch(additionalOptionsConflictCheck);
        if (readyToSubmit) {
            dispatch(saveAsPackage(editingPackageValues))
                .then(() => {
                    message.success(
                        intl.formatMessage({
                            id: 'a0e86.ScreenApplicant.packageCreated',
                            defaultMessage: 'Package Created',
                        })
                    );
                    history.push('/hr/packages/');
                })
                .catch((error) => ErrorAlertAPI(error));
        }
    };

    // Save as package from Screen Applicant page
    const handleOk = () => {
        form.validateFields((err, { position_name }) => {
            const readyToSubmit = dispatch(additionalOptionsConflictCheck);
            if (!err && readyToSubmit) {
                dispatch(saveAsPackage({ position_name }))
                    .then(() => {
                        dispatch(setPackageData);
                        message.success(
                            intl.formatMessage({
                                id: 'a0e86.ScreenApplicant.packageSaved',
                                defaultMessage: 'Package Saved',
                            })
                        );
                        toggleVisible();
                    })
                    .catch((error) => {
                        ErrorAlertAPI(error);
                    });
            } else {
                message.error(
                    intl.formatMessage({
                        id: 'a0e86.ScreenApplicant.packageSavedError',
                        defaultMessage: 'Error saving package',
                    })
                );
                toggleVisible();
            }
        });
    };

    const generateButtonTitle = () => {
        if (packageID === NEW)
            return (
                <FormattedMessage id="a0e86.ScreenApplicant.saveNewPackageButton" defaultMessage="SAVE NEW PACKAGE" />
            );
        if (!packageID)
            return <FormattedMessage id="a0e86.ScreenApplicant.saveAsPackageButton" defaultMessage="SAVE AS PACKAGE" />;
        return (
            <FormattedMessage
                id="a0e86.ScreenApplicant.updateCurrentPackageButton"
                defaultMessage="UPDATE CURRENT PACKAGE"
            />
        );
    };

    const handleButtonLogic = () => {
        if (packageID === NEW) return handleSaveNewPackage();
        if (!packageID) return toggleVisible();
        return handleUpdatePackage();
    };

    return (
        <>
            <Button
                loading={isFetching}
                disabled={disabled}
                type={packageID ? 'primary' : 'secondary'}
                onClick={handleButtonLogic}
            >
                {generateButtonTitle()}
            </Button>
            <Modal
                title={intl.formatMessage({
                    id: 'a0e86.ScreenApplicant.modalTitle',
                    defaultMessage: 'Save As Package',
                })}
                visible={visible}
                onCancel={toggleVisible}
                footer={[
                    <Button disabled={isFetching} key="back" onClick={toggleVisible}>
                        <FormattedMessage id="common.cancel" defaultMessage="Cancel" />
                    </Button>,
                    <Button loading={isFetching} key="submit" type="primary" onClick={handleOk}>
                        <FormattedMessage id="common.submit" defaultMessage="Submit" />
                    </Button>,
                ]}
            >
                <Form.Input
                    form={form}
                    fieldName="position_name"
                    options={{
                        rules: [
                            {
                                required: true,
                                message: intl.formatMessage({
                                    id: 'a0e86.ScreenApplicant.positionNameMessage',
                                    defaultMessage: 'Please do not leave blank',
                                }),
                            },
                        ],
                    }}
                    placeholder={intl.formatMessage({
                        id: 'a0e86.ScreenApplicant.positionNamePlaceholder',
                        defaultMessage: 'Package Name',
                    })}
                    autoComplete="false"
                />
            </Modal>
        </>
    );
});

export default ButtonSaveAsPackage;
