import {
    requestReducer,
    silentRequestReducer,
    responseReducer,
    errorReducer,
    defaultState as defaultHttpState,
} from 'utils/http';
import {
    GET_CONSENT_DOCUMENTS_REQ,
    GET_CONSENT_DOCUMENTS_RES,
    GET_CONSENT_DOCUMENTS_ERR,
    GET_BULK_CONSENT_DOCUMENTS_REQ,
    GET_BULK_CONSENT_DOCUMENTS_RES,
    GET_BULK_CONSENT_DOCUMENTS_ERR,
    DELETE_REPORT_CSV_RES,
    POST_APPLICATIONS_SEND_REQ,
    POST_APPLICATIONS_SEND_RES,
    POST_APPLICATIONS_SEND_ERR,
    SET_FILTERS,
} from 'views/manager/sharedActions/ApplicationActions';
import {
    GET_APPLICATIONS_REQ,
    GET_APPLICATIONS_RES,
    GET_APPLICATIONS_ERR,
    GET_APPLICATION_REQ,
    GET_APPLICATION_RES,
    GET_APPLICATION_ERR,
    SILENT_PUT_APPLICATIONS_REQ,
    SILENT_PUT_APPLICANTS_REQ,
    PUT_APPLICATIONS_REQ,
    PUT_APPLICATIONS_RES,
    PUT_APPLICATIONS_ERR,
    PUT_PACKAGES_REQ,
    PUT_PACKAGES_RES,
    PUT_PACKAGES_ERR,
    POST_APPLICATIONS_QUICK_REQ,
    POST_APPLICATIONS_QUICK_RES,
    POST_APPLICATIONS_QUICK_ERR,
    DEL_APPLICATIONS_REQ,
    DEL_APPLICATIONS_RES,
    DEL_APPLICATIONS_ERR,
    GET_REPORT_REQ,
    GET_REPORT_RES,
    GET_REPORT_ERR,
    GET_REPORT_PDF_REQ,
    GET_REPORT_PDF_RES,
    GET_REPORT_PDF_ERR,
    GET_BULK_REPORTS_PDF_REQ,
    GET_BULK_REPORTS_PDF_RES,
    GET_BULK_REPORTS_PDF_ERR,
    POST_BUY_EQUIFAX_REQ,
    POST_BUY_EQUIFAX_RES,
    POST_BUY_EQUIFAX_ERR,
    SET_APPLICATIONS_VIEW,
    SET_SEARCH_BY,
    SET_ORDERING,
    SET_PAGE_SIZE,
    SET_FILTER_BY,
    CLEAR_FILTERS,
    RESET_LIST,
    GET_REPORT_CSV_REQ,
    GET_REPORT_CSV_RES,
    GET_REPORT_CSV_ERR,
    GET_BULK_REPORT_CSV_REQ,
    GET_BULK_REPORT_CSV_RES,
    GET_BULK_REPORT_CSV_ERR,
    PATCH_NOTES_REQ,
    PATCH_NOTES_RES,
    PATCH_NOTES_ERR,
    POST_COMMENTS_REQ,
    POST_COMMENTS_RES,
    POST_COMMENTS_ERR,
    SEND_REMINDER_REQ,
    SEND_REMINDER_RES,
    SEND_REMINDER_ERR,
    DELETE_APPLICATIONS_REQ,
    DELETE_APPLICATIONS_RES,
    DELETE_APPLICATIONS_ERR,
    POST_ADVERSE_ACTION_EXPIRE_REQ,
    FETCH_FILTERED_APPLICATIONS_ERR,
} from './ApplicationsActions';

export const initialState = {
    ...defaultHttpState,
    applications: [],
    application: {},
    newApplication: {},
    sentApplication: {},
    report: {},
    applicationsView: 'All',
    searchBy: null,
    ordering: 'last_updated',
    reverseOrder: true,
    filterBy: {},
    numPages: 1,
    previousPage: undefined,
    nextPage: undefined,
    currentPage: 1,
    pageSize: 7,
    filterQuery: undefined,
    filters: {
        nameEmail: undefined,
        team: undefined,
        type: [],
        status: [],
        result: [],
        adjudicationReviewStatus: undefined,
        adjudicator: undefined,
        adverseActionStatus: undefined,
    },
    csvLink: undefined,
    isFetchingApplications: false,
    totalNumApplications: 0,
};

const applications = (state = initialState, action) => {
    switch (action.type) {
        case PATCH_NOTES_REQ:
            return {
                ...state,
                application: { ...state.application, notes: action.payload.notes },
                isFetchingNotes: true,
            };
        case GET_APPLICATIONS_REQ:
            return {
                ...state,
                isFetchingApplications: true,
            };
        case POST_COMMENTS_RES:
            return {
                ...state,
                ...responseReducer(),
                application: {
                    ...state.application,
                    comments: [...state.application.comments, action.payload.comments],
                },
            };
        case GET_REPORT_CSV_REQ:
            return {
                ...state,
                ...silentRequestReducer(),
                reportUrl: undefined,
                created: undefined,
                isFetchingCSV: true,
            };
        case SILENT_PUT_APPLICATIONS_REQ:
        case SILENT_PUT_APPLICANTS_REQ:
        case PUT_PACKAGES_REQ:
        case DEL_APPLICATIONS_REQ:
        case GET_REPORT_PDF_REQ:
        case GET_BULK_REPORTS_PDF_REQ:
        case GET_CONSENT_DOCUMENTS_REQ:
        case GET_BULK_CONSENT_DOCUMENTS_REQ:
        case GET_BULK_REPORT_CSV_REQ:
        case POST_COMMENTS_REQ:
        case SEND_REMINDER_REQ:
            return {
                ...state,
                ...silentRequestReducer(),
            };
        case PUT_APPLICATIONS_REQ:
        case POST_APPLICATIONS_QUICK_REQ:
        case POST_APPLICATIONS_SEND_REQ:
        case GET_APPLICATION_REQ:
        case POST_BUY_EQUIFAX_REQ:
        case DELETE_APPLICATIONS_REQ:
        case POST_ADVERSE_ACTION_EXPIRE_REQ:
            return {
                ...state,
                ...requestReducer(),
            };
        case GET_REPORT_REQ:
            return {
                ...state,
                ...requestReducer(),
                report: action.payload.report,
            };
        case PATCH_NOTES_RES:
            return {
                ...state,
                application: { ...state.application, notes: action.payload.notes },
                isFetchingNotes: false,
            };
        case GET_REPORT_CSV_RES:
            return {
                ...state,
                ...responseReducer(),
                csvLink: action.payload.csvLink,
                isFetchingCSV: false,
            };
        case GET_REPORT_CSV_ERR:
            return {
                ...state,
                ...errorReducer(action.payload.error),
                isFetchingCSV: false,
            };
        case DELETE_REPORT_CSV_RES:
            return {
                ...state,
                csvLink: undefined,
            };
        case GET_APPLICATIONS_RES:
            return {
                ...state,
                ...responseReducer(),
                applications: action.payload.applications,
                numApplications: action.payload.numApplications,
                previousPage: action.payload.previousPage,
                nextPage: action.payload.nextPage,
                currentPage: action.payload.currentPage,
                totalNumApplications: action.payload.queryAll
                    ? action.payload.numApplications
                    : state.totalNumApplications,
            };
        case PUT_PACKAGES_RES:
        case GET_APPLICATION_RES:
            return {
                ...state,
                ...responseReducer(),
                application: action.payload.application,
            };
        case DEL_APPLICATIONS_RES:
        case POST_APPLICATIONS_QUICK_RES:
            return {
                ...state,
                ...responseReducer(),
                newApplication: action.payload.newApplication,
            };
        case POST_APPLICATIONS_SEND_RES:
            return {
                ...state,
                ...responseReducer(),
                sentApplication: action.payload.sentApplication,
            };
        case GET_REPORT_RES:
            return {
                ...state,
                ...responseReducer(),
                report: action.payload.report,
            };
        case GET_REPORT_PDF_RES:
        case GET_BULK_REPORTS_PDF_RES:
        case GET_CONSENT_DOCUMENTS_RES:
        case GET_BULK_CONSENT_DOCUMENTS_RES:
        case GET_BULK_REPORT_CSV_RES:
        case SEND_REMINDER_RES:
        case POST_BUY_EQUIFAX_RES:
        case DELETE_APPLICATIONS_RES:
        case PUT_APPLICATIONS_RES:
            return {
                ...state,
                ...responseReducer(),
            };
        case PATCH_NOTES_ERR:
        case POST_COMMENTS_ERR:
        case GET_APPLICATIONS_ERR:
        case GET_APPLICATION_ERR:
        case PUT_APPLICATIONS_ERR:
        case PUT_PACKAGES_ERR:
        case DEL_APPLICATIONS_ERR:
        case POST_APPLICATIONS_QUICK_ERR:
        case POST_APPLICATIONS_SEND_ERR:
        case GET_REPORT_ERR:
        case GET_REPORT_PDF_ERR:
        case GET_BULK_REPORTS_PDF_ERR:
        case GET_BULK_REPORT_CSV_ERR:
        case GET_CONSENT_DOCUMENTS_ERR:
        case GET_BULK_CONSENT_DOCUMENTS_ERR:
        case POST_BUY_EQUIFAX_ERR:
        case SEND_REMINDER_ERR:
        case DELETE_APPLICATIONS_ERR:
        case FETCH_FILTERED_APPLICATIONS_ERR:
            return {
                ...state,
                ...errorReducer(action.payload.error),
            };
        case SET_APPLICATIONS_VIEW:
            return {
                ...state,
                applicationsView: action.payload.view,
                reverseOrder: true,
            };
        case SET_SEARCH_BY:
            return {
                ...state,
                searchBy: action.payload.searchBy,
            };
        case SET_ORDERING:
            return {
                ...state,
                ordering: action.payload.ordering,
                reverseOrder: action.payload.reverseOrder,
            };
        case SET_PAGE_SIZE:
            return {
                ...state,
                pageSize: action.payload.pageSize,
            };
        case SET_FILTERS:
            return {
                ...state,
                filterQuery: action.payload.query,
                filters: action.payload.filters,
            };
        case SET_FILTER_BY:
        case CLEAR_FILTERS:
            return {
                ...state,
                filterBy: action.payload.filterBy,
            };
        case RESET_LIST:
            return {
                ...state,
                isActive: true,
                searchBy: null,
                ordering: 'last_updated',
                reverseOrder: true,
                filterBy: {},
            };
        default:
            return state;
    }
};

export default applications;
