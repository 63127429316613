import React from 'react';
import styled from 'styled-components/macro';

import { DownOutlined, UpOutlined } from '@ant-design/icons';

import { Menu } from 'antd';

const List = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    flex-shrink: 0;
`;
export default List;

export const ListRow = styled.div`
    position: relative;
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 5px 0;
    box-sizing: border-box;
    min-height: 50px;
    border-bottom: ${({ header, theme }) => (header ? 'none' : `1px solid ${theme.color.certnGray100}`)};
    border-radius: 3px;
    background: ${({ highlight, theme }) => (highlight ? theme.color.certnBlue100 : theme.color.certnWhite)};
`;

export const ListRowMain = styled.div`
    display: flex;
    width: 94.5%;
    cursor: ${(props) => (props.clickable ? 'pointer' : 'initial')};
    @media (max-width: ${(props) => props.theme.width.lrg}) {
        width: 81.5%;
    }
`;

export const ListRowEnd = styled.div`
    display: flex;
    margin: auto;
`;

export const ListRowMask = styled.div`
    position: absolute;
    z-index: 100;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${(props) => props.theme.color.certnGray900};
    padding: 0 20px;
    box-sizing: border-box;
    filter: opacity(75%);
`;

export const ListHeaderCell = (props) => (
    <ListHeaderCellContainer {...props}>
        {props.label}
        {props.sortable && props.sortable !== 'desktop' && (
            <ListHeaderCellArrows>
                {(!props.active || (props.active && !props.reverseOrder)) && <UpOutlined />}
                {(!props.active || (props.active && props.reverseOrder)) && <DownOutlined />}
            </ListHeaderCellArrows>
        )}
    </ListHeaderCellContainer>
);

const ListHeaderCellContainer = styled.div`
    position: relative;
    min-height: 48px;
    display: ${(props) => (props.desktop ? 'flex' : 'none')};
    flex: ${(props) => props.flex};
    justify-content: space-between;
    align-items: center;
    background: ${(props) =>
        props.sortable && props.active ? props.theme.color.primary : props.theme.color.certnGray100};
    color: ${({ sortable, active, theme }) => (sortable && active ? theme.color.certnWhite : 'inherit')};
    border-radius: 3px;
    padding: 5px;
    box-sizing: border-box;
    margin: 2px;
    overflow: hidden;
    cursor: ${(props) => (props.sortable ? 'pointer' : 'default')};

    &:hover {
        filter: ${(props) => (props.sortable ? 'brightness(103%)' : 'none')};
    }
    @media (max-width: ${(props) => props.theme.width.lrg}) {
        display: ${(props) => (props.mobile ? 'flex' : 'none')} !important;
    }
`;

const ListHeaderCellArrows = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
`;

export const ListCell = styled.div`
    display: ${(props) => (props.desktop ? 'flex' : 'none')};
    flex: ${(props) => props.flex};
    justify-content: ${(props) => (props.center ? 'center' : 'flex-start')};
    align-items: center;
    border-radius: 3px;
    padding: 5px;
    box-sizing: border-box;
    margin: 2px;
    overflow-x: hidden;

    @media (max-width: ${(props) => props.theme.width.lrg}) {
        display: ${(props) => (props.mobile ? 'flex' : 'none')} !important;
        flex-direction: column;
    }
`;

export const ListCellText = styled.div`
    display: block;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: ${(props) => (props.critical ? props.theme.color.certnRed500 : 'inherit')};
    ${(props) => props.color && `color: ${props.theme.color[props.color]}`};
    ${(props) => props.clickable && 'cursor: pointer'};
    :after {
        cursor: inherit;
    }
`;

export const CustomMenuItem = styled(Menu.Item)`
    color: ${(props) => (props.critical ? props.theme.color.certnRed500 : 'inherit')};
`;

export const ListCellLink = styled.a`
    display: block;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;
