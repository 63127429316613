import React, { useState } from 'react';
import { Modal, Input, message, Form } from 'antd';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import * as PropTypes from 'prop-types';

import { ErrorAlertAPI } from 'certn-ui/ErrorAlert';
import FieldLabel from 'certn-form/FieldLabel';
import { postReportSummary } from 'views/manager/views/hr/views/applications/ApplicationsActions';
import EmailAddresses from 'components/EmailAddresses/EmailAddresses';

const propTypes = {
    applicantId: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
    handleClose: PropTypes.func.isRequired,
};

const EmailEntryModal = ({ handleClose, applicantId, language }) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const [form] = Form.useForm();
    const [submitted, setSubmitted] = useState(false);

    const doSubmit = (e) => {
        e.preventDefault();
        setSubmitted(true);
        form.submit();
    };
    const handleSubmit = (values) => {
        const emails = values.emails.filter(Boolean);
        const emailMsg = values.email_message;
        const [email, ...cc] = emails;
        const emailsList = emails.join(', ');
        dispatch(postReportSummary(applicantId, email, cc, language, emailMsg))
            .then(() => {
                message.success(
                    intl.formatMessage(
                        {
                            id: '9f90d.EmailEntryModal.success',
                            defaultMessage: 'Report sent to {email}',
                        },
                        { email: emailsList }
                    )
                );
                form.resetFields();
                handleClose();
            })
            .catch((error) => {
                message.error(
                    intl.formatMessage(
                        {
                            id: '9f90d.EmailEntryModal.fail',
                            defaultMessage: 'Failed to send the report to {email}. Please contact support@certn.co',
                        },
                        { email: emailsList }
                    )
                );
                ErrorAlertAPI(error);
            });
    };

    return (
        <Modal
            visible
            title={intl.formatMessage({
                id: '9f90d.EmailEntryModal.ShareReportSummary',
                defaultMessage: 'Share report summary',
            })}
            okText={intl.formatMessage({ id: '9f90d.EmailEntryModal.share', defaultMessage: 'Share' })}
            onCancel={handleClose}
            onOk={doSubmit}
        >
            <Form
                form={form}
                onFinish={handleSubmit}
                layout="vertical"
                initialValues={{
                    emails: [''],
                }}
            >
                <EmailAddresses
                    form={form}
                    instructions={intl.formatMessage({
                        id: '9f90d.EmailEntryModal.instructions',
                        defaultMessage:
                            'List below all email recipients who you’d like to share this report summary with.',
                    })}
                    submitAttempted={submitted}
                    fullWidth
                />
                <FieldLabel htmlFor="emailMessage" optional>
                    {intl.formatMessage({
                        id: '9f90d.EmailEntryModal.emailMessage',
                        defaultMessage: 'Email message',
                    })}
                </FieldLabel>
                <Form.Item name="email_message">
                    <Input.TextArea style={{ height: '160px' }} />
                </Form.Item>
            </Form>
        </Modal>
    );
};

EmailEntryModal.propTypes = propTypes;

export default EmailEntryModal;
