// Libraries
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Components
import MoveInDateForm from 'views/welcome/views/moveInDate/components/MoveInDateForm';

// Modules
import withNavigation from 'views/welcome/modules/WithNavigation';

// Actions & Selectors
import { patchInformation, setTrackPageOrder } from 'views/welcome/WelcomeActions';
import { getInformation } from 'views/welcome/WelcomeSelectors';

const propTypes = {
    // withNavigation
    trackPageLocation: PropTypes.string.isRequired,
    handleNextTrack: PropTypes.func.isRequired,
};

const MoveInDate = ({ trackPageLocation, handleNextTrack }) => {
    const dispatch = useDispatch();
    const defaultMoveInDate = useSelector(getInformation)?.desired_move_in_date;

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(setTrackPageOrder(['base']));
    }, [dispatch]);

    const handleSubmit = (values) => {
        dispatch(patchInformation(values)).then(handleNextTrack);
    };

    const pages = () => ({
        base: <MoveInDateForm handleSubmit={handleSubmit} defaultMoveInDate={defaultMoveInDate} />,
    });

    return pages()[trackPageLocation];
};

MoveInDate.propTypes = propTypes;

export default withRouter(withNavigation(MoveInDate));
