import { useSelector } from 'react-redux';
import { CheckConfig } from 'types';
import useCheckContent from 'views/manager/features/ScreenApplicant/components/ScreenApplicant/MicroServiceContent';
import { PriceItem } from './PriceItem';
import { getMultiplier } from 'views/manager/features/ScreenApplicant/ScreenApplicantActions';
import {
    getAdditionalOptions,
    getIsFollowUpFlow,
    getRequestedCountries,
} from 'views/manager/features/ScreenApplicant/ScreenApplicantSelectors';
import { CHECK_REQUEST } from 'base/BaseConstants';
import { CurrencyWrapper, LineItem, Title } from './styled';
import { CartItemIcon } from './CartItemIcon';
import { RequestedCountries } from './RequestedCountries';

export const CartItem: React.FC<{ check: CheckConfig }> = ({ check }) => {
    const { isPurchased, isInFollowUpFlow, requestFlag } = check;

    const additionalOptions = useSelector(getAdditionalOptions);
    const { title } = useCheckContent(requestFlag);
    const multiplier = getMultiplier(check, additionalOptions);
    const bracketDisplay = multiplier !== 1 ? `(${multiplier})` : '';

    const isFollowUpFlow = useSelector(getIsFollowUpFlow);
    const requestedCountries = useSelector(getRequestedCountries);
    const isIntlCRC = requestFlag === CHECK_REQUEST.INTERNATIONAL_CRIMINAL_RECORD_CHECK;

    // should show crossed title for Int crim follow up flow when no countries
    const isCrossed = isIntlCRC && isFollowUpFlow && !requestedCountries?.length;

    return (
        <>
            <LineItem data-testid={`${requestFlag}_cart`} disabled={isPurchased || isInFollowUpFlow}>
                <Title crossed={!!isCrossed}>{`${check.titleFromAPI || title} ${bracketDisplay}`}</Title>
                <CurrencyWrapper>
                    <PriceItem check={check} />
                    <CartItemIcon check={check} />
                </CurrencyWrapper>
            </LineItem>
            {isIntlCRC && <RequestedCountries check={check} />}
        </>
    );
};
