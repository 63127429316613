// Libraries
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// UI Components
import { LayoutBody } from 'certn-ui/Layout';
import RouteErrorBoundary from 'certn-ui/RouteErrorBoundary';
import { ScreenApplicant, Applicant } from 'views/manager/features/ScreenApplicant/';

// Actions & Selectors
import { setUserMode, setLocale } from 'base/BaseActions';
import { fetchSettings } from 'views/manager/views/settings/SettingsActions';
import { getIsFetchingSilent, getError } from 'views/manager/views/pm/views/listings/ListingsSelectors';
import { fetchAllProperties } from 'views/manager/views/pm/views/properties/PropertiesActions';
import { servicesSelected } from 'views/manager/features/ScreenApplicant/ScreenApplicantSelectors';

// React Router & Routes
import { Switch, Redirect, withRouter, Route } from 'react-router-dom';
import Routes from 'Routes';
import Listings from './views/listings/Listings';
import Properties from './views/properties/Properties';
import Applications from './views/applications/Applications';

const mapStateToProps = (state) => ({
    isFetchingSilent: getIsFetchingSilent(state),
    isError: getError(state),
    servicesSelected: servicesSelected(state),
});
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchSettings,
            fetchAllProperties,
            setUserMode,
            setLocale,
        },
        dispatch
    );

const propTypes = {
    // redux actions
    fetchSettings: PropTypes.func.isRequired,
    fetchAllProperties: PropTypes.func.isRequired,
    setUserMode: PropTypes.func.isRequired,
    // redux state
    isFetchingSilent: PropTypes.bool.isRequired,
    error: PropTypes.object,
    servicesSelected: PropTypes.bool,
};
const defaultProps = {
    error: {},
    servicesSelected: false,
};

class PropertyManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showLogo: true,
        };
        this.props.setUserMode('PM');
        this.props.fetchSettings();
        this.props.fetchAllProperties();
    }

    render() {
        return (
            <LayoutBody>
                <RouteErrorBoundary>
                    <Switch>
                        <Route exact path="/pm/screen" component={ScreenApplicant} />
                        <Route
                            exact
                            path="/pm/screen/applicant"
                            render={(props) =>
                                this.props.servicesSelected ? <Applicant {...props} /> : <Redirect to="/pm/screen" />
                            }
                        />
                        <Route path="/pm/applications" component={Applications} />
                        <Routes.Pro userMode="pm" path="/pm/listings" component={Listings} />
                        <Routes.Pro userMode="pm" path="/pm/properties" component={Properties} />
                        <Redirect to="/pm/applications" />
                    </Switch>
                </RouteErrorBoundary>
            </LayoutBody>
        );
    }
}

PropertyManager.propTypes = propTypes;
PropertyManager.defaultProps = defaultProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PropertyManager));
