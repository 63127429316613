// Libraries
import React from 'react';
import { useSelector } from 'react-redux';
import { Form, Input } from 'antd';

// Components
import { RequiredText } from 'certn-ui';
import { intl } from 'components/GlobalProvider';

// Actions & Selectors
import { getSelectedCheckKeys } from 'views/manager/features/ScreenApplicant/ScreenApplicantSelectors';

// Modules
import isVisibleOrRequired from './isVisibleOrRequired';

const Email = () => {
    const selectedServices = useSelector(getSelectedCheckKeys);
    const label = intl.formatMessage({ id: 'common.email', defaultMessage: 'Email' });

    // Checks to display field and set required, true in brackets for all services
    const visibleList = [true];
    const requiredList = [true];

    if (!isVisibleOrRequired(visibleList, selectedServices)) {
        return null;
    }

    return (
        <>
            <RequiredText>{label}</RequiredText>
            <Form.Item
                name="email"
                initialValue={null}
                rules={[
                    {
                        type: 'email',
                        message: intl.formatMessage({
                            id: 'error.validation.email.invalid',
                            defaultMessage: 'The input is not a valid E-mail!',
                        }),
                    },
                    {
                        required: isVisibleOrRequired(requiredList, selectedServices),
                        message: intl.formatMessage({
                            id: 'f5ca1.QuickScreen.mustBeValidEmail',
                            defaultMessage: 'A valid e-mail must be provided!',
                        }),
                    },
                ]}
            >
                <Input placeholder={label} type="email" />
            </Form.Item>
        </>
    );
};

export default Email;
