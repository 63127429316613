// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

// UI Components
import { ListCellLink } from 'certn-ui/List';

// Selectors
import { getUserMode } from 'base/BaseSelectors';

const mapStateToProps = (state) => ({
    userMode: getUserMode(state),
});

const propTypes = {
    // Redux State
    userMode: PropTypes.string.isRequired,
};

const ApplicationsCell = ({ record, userMode, history }) =>
    !record.applicant_count || record.applicant_count === 0 ? (
        '0 Applications'
    ) : (
        <ListCellLink onClick={() => history.push(`/${userMode.toLowerCase()}/applications?listing_id=${record.id}`)}>
            {record.applicant_count} Total
            {record.new_applicant_count > 0 && <strong> ({record.new_applicant_count || 0} New)</strong>}
        </ListCellLink>
    );

ApplicationsCell.propTypes = propTypes;

export default withRouter(connect(mapStateToProps, {})(ApplicationsCell));
