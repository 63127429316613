import { useEffect, useState } from 'react';
import { Modal } from 'antd';

import { FormattedMessage } from 'react-intl';

interface TimeoutModalProps {
    showModal: boolean;
    onOk: () => void;
    onCancel: () => void;
    timeRemaining: number;
}

const SessionTimeoutModal = ({ showModal, onOk, onCancel, timeRemaining }: TimeoutModalProps) => {
    const [seconds, setSeconds] = useState(timeRemaining);
    useEffect(() => {
        const interval = setInterval(() => {
            setSeconds(seconds - 1);
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    });

    return (
        <Modal
            visible={showModal}
            closable={false}
            title={<FormattedMessage id="SessionTimeoutTitle" defaultMessage="Your session is about to expire" />}
            cancelText={<FormattedMessage id="SessionTimeoutButton" defaultMessage="Continue working" />}
            onCancel={() => onCancel()}
            okText={<FormattedMessage id="350ef.Navigation.signOutDropdownItem" defaultMessage="Sign Out" />}
            onOk={() => onOk()}
            maskClosable
            centered
        >
            <FormattedMessage
                id="SessionTimeoutContent"
                defaultMessage="You will be signed out in {seconds} seconds."
                values={{ seconds }}
            />
        </Modal>
    );
};

export default SessionTimeoutModal;
