import React from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

// Actions & Selectors
import { getListing, getSettings, getTeamIsMyCRC, getTeam } from '../../WelcomeSelectors';

const ApplicationTarget = ({ error }) => {
    const { pathname } = useLocation();
    const intl = useIntl();

    const listing = useSelector(getListing);
    const team = useSelector(getTeam);
    const settings = useSelector(getSettings);
    const teamIsMyCRC = useSelector(getTeamIsMyCRC);

    if (pathname.includes('/welcome/signUp'))
        return <FormattedMessage id="welcome.Welcome.signUp" defaultMessage="Certn Sign up" />;

    if (teamIsMyCRC || team?.settings_config?.my_crc_region) {
        return (
            <FormattedMessage
                id="welcome.Welcome.applicationFor"
                defaultMessage="Application for {position}"
                values={{
                    position:
                        listing?.position_name ||
                        intl.formatMessage({
                            id: 'services.canadianCriminalRecordCheck',
                            defaultMessage: 'Canadian Criminal Record Check',
                        }),
                }}
            />
        );
    }

    if (listing && listing.property) {
        return (
            <FormattedMessage
                id="welcome.Welcome.applicationTo"
                defaultMessage="Application to {address}"
                values={{
                    address: listing.property.address,
                }}
            />
        );
    }

    if (listing && listing.position_name) {
        return (
            <FormattedMessage
                id="welcome.Welcome.applicationFor"
                defaultMessage="Application for {position}"
                values={{
                    position: listing.position_name,
                }}
            />
        );
    }

    if (settings?.get_org_name) {
        // TODO: settings is never populated on email step because it depends on the onboarding session
        //  which hasn't been created yet; possible solution would be to change getSettings
        //  to get the settings_config from listing similar to getTeam
        return (
            <FormattedMessage
                id="welcome.Welcome.applicationWith"
                defaultMessage="Application with {orgName}"
                values={{
                    orgName: settings.get_org_name,
                }}
            />
        );
    }

    if (settings?.get_org_name === null) {
        return null;
    }

    if (error) return 'Contact support@certn.co';

    return <FormattedMessage id="welcome.Email.loading" defaultMessage="Loading..." />;
};

ApplicationTarget.propTypes = {
    error: PropTypes.bool.isRequired,
};

export default ApplicationTarget;
