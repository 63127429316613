import { get } from 'lodash';
import { createSelector } from 'reselect';
import { STATEPATH as PARENT_STATEPATH } from 'views/manager/views/hr/HrManagerConstants';

const STATEPATH = `${PARENT_STATEPATH}.postings`;

const getLocalState = (state) => get(state, STATEPATH);

export const getIsFetching = (state) => getLocalState(state).isFetching;
export const getIsFetchingSilent = (state) => getLocalState(state).isFetchingSilent;
export const getError = (state) => getLocalState(state).error;
export const getPostings = (state) => getLocalState(state).postings;
export const getAllPostings = (state) => getLocalState(state).allPostings;
export const getAllPostingNames = createSelector(getAllPostings, (postings) =>
    postings.map((posting) => posting.property.building)
);
export const getAllPostingIds = createSelector(getAllPostings, (postings) => postings.map((posting) => posting.id));
export const getPosting = (state) => getLocalState(state).posting;
export const getNewPosting = (state) => getLocalState(state).newPosting;
export const getApplication = (state) => getLocalState(state).application;
export const getTeamMembers = (state) => getLocalState(state).teamMembers;
export const getSpecializations = (state) => getLocalState(state).specializations;
export const getSkills = (state) => getLocalState(state).skills;
export const getDegrees = (state) => getLocalState(state).degrees;
export const getTeamMemberDict = createSelector(getTeamMembers, (teamMembers) =>
    teamMembers.reduce((obj, item) => {
        obj[item.email] = item.id;
        return obj;
    }, {})
);
export const getDegreeDict = createSelector(getDegrees, (degrees) =>
    degrees.reduce((obj, item) => {
        obj[item.degree] = item.id;
        return obj;
    }, {})
);
export const getSkillDict = createSelector(getSkills, (skill) =>
    skill.reduce((obj, item) => {
        obj[item.skill] = item.id;
        return obj;
    }, {})
);

export const getSpecializationOptions = createSelector(getSpecializations, (specializations) =>
    specializations.map((specialization) => ({
        key: specialization.id,
        label: specialization.specialization,
        value: specialization.specialization,
    }))
);

export const getSkillOptions = createSelector(getSkills, (skills) =>
    skills.map((skill) => ({
        key: skill.id,
        label: skill.skill,
        value: skill.skill,
    }))
);

// List Selectors
export const getIsActive = (state) => getLocalState(state).isActive;
export const getSearchBy = (state) => getLocalState(state).searchBy;
export const getOrdering = (state) => getLocalState(state).ordering;
export const getReverseOrder = (state) => getLocalState(state).reverseOrder;
export const getFilterBy = (state) => getLocalState(state).filterBy;
export const getPageSize = (state) => getLocalState(state).pageSize;
export const getCurrentPage = (state) => getLocalState(state).currentPage;
export const getNumPostings = (state) => getLocalState(state).numPostings;
export const getNumAllPostings = (state) => getLocalState(state).numAllPostings;
export const getCurrentPackageTestCollection = (state) => getLocalState(state).posting?.test_collection;
