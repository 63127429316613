// Libraries
import React from 'react';
import { FormattedMessage } from 'react-intl';

// Components
import { AppearRight } from 'certn-ui/Animate';
import { ButtonLine } from 'certn-ui/Button';
import Text, { TextLink } from 'certn-ui/Text';
import Title from 'certn-ui/Title';
import MailUnverified from 'images/svgs/svg-components/onboarding/MailUnverified';

const PageOne = ({ isFetchingSilent, resendEmail }) => (
    <AppearRight>
        <Title>
            <FormattedMessage
                id="welcome.Submit.submitTitle"
                defaultMessage="You're almost done. Before we can submit your completed application, we need you to verify your email address."
            />
        </Title>
        <MailUnverified />
        <ButtonLine>
            <Text align="center">
                <FormattedMessage
                    id="welcome.Submit.verifySpamCheck"
                    defaultMessage="If you do not receive an email please check your email provider's junk or spam folder."
                />
            </Text>
        </ButtonLine>
        <ButtonLine>
            {isFetchingSilent ? (
                <Text align="center">
                    <FormattedMessage id="welcome.Submit.sending" defaultMessage="Sending..." />
                </Text>
            ) : (
                <TextLink onClick={resendEmail}>
                    <FormattedMessage id="welcome.Submit.resendEmail" defaultMessage="Resend Email" />
                </TextLink>
            )}
        </ButtonLine>
    </AppearRight>
);

export default PageOne;
