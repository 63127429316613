// Libraries
import { get, isEmpty, sortBy, uniq } from 'lodash';
import { createSelector } from 'reselect';

// Modules
import { isFetchingNamespacedSelector, isNotCompleteSelector } from 'utils/autoredux';
import Constants from 'utils/constants';

// Selectors
import {
    getLaunchDarklyFlags,
    getUserModeIsPM,
    getUserModeIsHR,
    getTeamIsCA,
    getTeamIsUKorGB,
    getTeamIsLime,
    getTeamIsPaymentVerified,
} from 'base/BaseSelectors';

// Constants
import {
    STATEPATH,
    NAMESPACE,
    MYSELF,
    RCMP_REQUESTS,
} from 'views/manager/features/ScreenApplicant/ScreenApplicantConstants';
import { getPosting } from 'views/manager/views/hr/views/postings/PostingsSelectors.js';

import { CHECK_REQUEST, QUICK_SCREEN_CHECKS_HR, QUICK_SCREEN_CHECKS_PM } from 'base/BaseConstants';

const getLocalState = (state) => get(state, STATEPATH);

// Generic Checks Data
// get checks and sort by isQuickScreenable
export const getChecks = (state) => sortBy(getLocalState(state).checks || [], [(check) => !check.isQuickScreenable]);
export const getSelectedAndUnpurchasedChecksKeys = (state) =>
    getChecks(state).filter((check) => !check.isPurchased && check.isSelected);

// Generic Check Reselect
// Get visible checks, if it's been selected or isPurchased it should still appear as it was once visible
export const getVisibleChecks = createSelector(getChecks, (checks) =>
    checks?.filter((check) => check.isPermitted || check.isPurchased || check.isSelected)
);
export const getUkVisibleChecks = createSelector(getVisibleChecks, getTeamIsUKorGB, (visibleChecks, teamIsUKorGB) =>
    teamIsUKorGB
        ? visibleChecks.filter((check) => check.requestFlag !== 'request_enhanced_identity_verification')
        : visibleChecks
);
export const getPurchasedChecks = createSelector(getChecks, (checks) => checks?.filter((check) => check.isPurchased));
export const getSelectedChecks = createSelector(getChecks, (checks) => checks?.filter((check) => check.isSelected));
export const getSelectedCheckKeys = createSelector(getSelectedChecks, (checks) =>
    checks?.map((check) => check.requestFlag)
);
export const getUnPurchasedAndSelectedChecks = createSelector(getVisibleChecks, (checks) =>
    checks?.filter((check) => check.isSelected && !check.isPurchased)
);
export const getVisibleChecksKeys = createSelector(getVisibleChecks, (checks) =>
    checks?.map((check) => check.requestFlag)
);
export const getPurchasedChecksKeys = createSelector(getPurchasedChecks, (checks) =>
    checks?.map((check) => check.requestFlag)
);
export const getPurchasedAndSelectedChecks = createSelector(getVisibleChecks, (visibleChecks) =>
    visibleChecks.filter((check) => check.isSelected || check.isPurchased)
);
// Used during upgrade for more accurate onSelect logic
export const getPurchasedAndSelectedChecksKeys = createSelector(getPurchasedAndSelectedChecks, (purchasedAndSelected) =>
    purchasedAndSelected.map((check) => check.requestFlag)
);
export const getEstimatedTurnaroundTimes = createSelector(getVisibleChecks, (visibleChecks) =>
    visibleChecks.map((check) => check.estimatedTurnAroundTime)
);
export const getChecksSortedByPurchased = createSelector(getPurchasedAndSelectedChecks, (checks) =>
    sortBy(checks, [(s) => !s.isPurchased])
);
export const getChecksInFollowUpFlow = createSelector(getSelectedChecks, (selectedChecks) =>
    selectedChecks?.filter((check) => check.isInFollowUpFlow)
);
export const getCheckKeysInFollowUpFlow = createSelector(getChecksInFollowUpFlow, (checksInFUF) =>
    checksInFUF.map((check) => check.requestFlag)
);
// Get the mutually excluded checks from the currently selected checks
export const getMutuallyExcludedChecks = createSelector(getSelectedChecks, (selectedChecks) => {
    const mutuallyExcludedChecks = selectedChecks
        ?.filter((check) => !check.isPurchased)
        ?.map((check) => check.selectionOnCreateProhibitedByRequestFlags);
    return uniq(mutuallyExcludedChecks.flat());
});

export const getIsMutuallyExcluded = (requestFlag) =>
    createSelector(getMutuallyExcludedChecks, (mutuallyExcludedChecks) => mutuallyExcludedChecks.includes(requestFlag));

export const getAdditionalOptions = (state) => getLocalState(state).additionalOptions;
export const getCheckPageComplete = (state) => getLocalState(state).checkPageComplete;
export const getAdditionalOptionsExist = (state) => Object.keys(getAdditionalOptions(state))?.length > 0;
export const getRequestedCountries = (state) => getAdditionalOptions(state).requested_countries;
export const getUsCriminalRecordCheckYears = (state) => getAdditionalOptions(state).us_criminal_record_check_years;
export const getApplicationOfInterest = (state) => getLocalState(state).applicationOfInterest;
export const getIsUpgrade = (state) => getApplicationOfInterest(state)?.id;
export const getTotal = (state) => getLocalState(state).total;
export const getShouldPersistScreenApplicantState = (state) => getLocalState(state).shouldPersistScreenApplicantState;

// Packages
export const getPackage = (state) => getPosting(state);
export const getPackageID = (state) => getPosting(state).id;
export const getPackageName = (state) => getPosting(state).position_name;
export const getPackageSelected = (state) => getLocalState(state).packageSelected;

// Applicant
export const getPropertyId = (state) => getLocalState(state).applicant?.propertyID;
export const getListingId = (state) => getLocalState(state).applicant?.listingID;
export const getScreenType = (state) => getLocalState(state).applicant?.screenType;
export const getScreenTypeMyself = (state) => getLocalState(state).applicant?.screenType === MYSELF;
export const getETT = (state) => getLocalState(state).applicant?.estimatedTurnAroundTime;
export const getConvictionsExist = (state) => getLocalState(state).applicant?.convictionsExist;

// Loading
export const isFetchingSelector = isFetchingNamespacedSelector(NAMESPACE);
export const isFetchingCountriesLoading = (state) => isNotCompleteSelector(state, 'base/GET_COUNTRIES');

// Follow Up Flow overall status
export const getIsFollowUpFlow = createSelector(
    getApplicationOfInterest,
    getLaunchDarklyFlags,
    (application, launchDarklyFlags) => {
        // LDFlag isWebFeatureEnableNewStatusSystem
        const isWebFeatureEnableNewStatusSystem = launchDarklyFlags?.webFeatureEnableNewStatusSystem;
        if (isWebFeatureEnableNewStatusSystem)
            return application?.order_status === Constants.orderStatus.ACTION_REQUIRED;
        if (!isWebFeatureEnableNewStatusSystem)
            return application?.report_status === Constants.reportStatus.ACTION_REQUIRED;
    }
);

export const getScreenApplicantButtonsDisabled = createSelector(
    getSelectedAndUnpurchasedChecksKeys,
    getIsFollowUpFlow,
    getUserModeIsHR,
    getUserModeIsPM,
    getIsUpgrade,
    getTeamIsLime,
    getTeamIsPaymentVerified,
    (checkKeys, isFUF, userModeIsHR, userModeIsPM, isUpgrade, isLime, paymentVerified) => {
        // Lime && Follow Up Flow can run without any services selected if payment is verified
        if ((isLime || isFUF) && paymentVerified) return false;

        let disabled;
        // Regular and upgrade flow
        if (userModeIsHR) disabled = isEmpty(checkKeys);
        // PM can run request_base without any services
        if (userModeIsPM && isUpgrade) disabled = isEmpty(checkKeys);
        return disabled;
    }
);

export const servicesSelected = createSelector(
    getSelectedCheckKeys,
    (selectedChecksKeys) => selectedChecksKeys?.length > 0
);

// ScreenApplicant FUF Info
export const getApplicantInformationObject = createSelector(
    getApplicationOfInterest,
    (applicationOfInterest) => applicationOfInterest?.information
);

export const getApplicantRequestedCountries = createSelector(
    getApplicationOfInterest,
    (applicationOfInterest) => applicationOfInterest?.requested_countries
);

export const getLastUpdated = createSelector(
    getApplicationOfInterest,
    (applicationOfInterest) => applicationOfInterest?.last_updated
);

export const getTeamExcludedCountriesFromInternationalChecks = createSelector(
    getApplicationOfInterest,
    (applicationOfInterest) => applicationOfInterest?.application?.team?.countries_excluded_from_international_check
);

// Quickscreen paths
export const getQuickScreenPath = createSelector(
    getSelectedCheckKeys,
    getUserModeIsHR,
    getTeamIsCA,
    (selectedChecksKeys, userModeIsHR, teamIsCA) => {
        // Quick screen only for Canadian teams
        if (!teamIsCA) return false;
        // No quick screen for just request base;
        if (selectedChecksKeys.every((elem) => [CHECK_REQUEST.BASE]?.includes(elem))) return false;

        // Special inclusion services such as id verification for RCMP
        const additionalChecks = [];
        if (selectedChecksKeys.some((service) => RCMP_REQUESTS.includes(service))) {
            additionalChecks.push(CHECK_REQUEST.IDENTITY_VERIFICATION);
        }

        // Checks that if only selected allow quick screen
        const quickScreenChecksWithAdditions = [
            ...(userModeIsHR ? QUICK_SCREEN_CHECKS_HR : QUICK_SCREEN_CHECKS_PM),
            ...additionalChecks,
        ];

        // True means we've selected something that is not a quick screen service
        const nonQuickScreenFound = selectedChecksKeys.some((item) => !quickScreenChecksWithAdditions.includes(item));

        // If no services outside quick screen scope, allow quick screen;
        if (!nonQuickScreenFound) return true;

        // Default;
        return false;
    }
);

export const getIsOnlyQuickScreen = createSelector(
    getSelectedCheckKeys,
    getQuickScreenPath,
    getLaunchDarklyFlags,
    (selectedChecksKeys, canDoQuickScreen, launchDarklyFlags) =>
        canDoQuickScreen &&
        launchDarklyFlags?.webFeatureDisableEquifaxIdentityVerification &&
        selectedChecksKeys.includes(CHECK_REQUEST.IDENTITY_VERIFICATION)
);

// RCMP Quick Screen
export const getRCMPQuickScreen = createSelector(
    getSelectedCheckKeys,
    getScreenTypeMyself,
    (selectedChecksKeys, screenTypeMyself) =>
        selectedChecksKeys.some((service) => RCMP_REQUESTS.includes(service)) && screenTypeMyself
);

/** FOLLOW UP FLOW  */

// Check for int crim requested_countries empty, returns title to list in modal for removing services
export const getIsRequestedCountriesEmpty = createSelector(getAdditionalOptions, (additionalOptions) => {
    if (isEmpty(additionalOptions?.requested_countries)) return CHECK_REQUEST.INTERNATIONAL_CRIMINAL_RECORD_CHECK;
});

export const getIntCrimSelectedAndEmpty = createSelector(
    getSelectedCheckKeys,
    getIsRequestedCountriesEmpty,
    (selectedCheckKeys, isRequestedCountriesEmpty) =>
        selectedCheckKeys.includes(CHECK_REQUEST.INTERNATIONAL_CRIMINAL_RECORD_CHECK) && isRequestedCountriesEmpty
);

// Check if International Crim is in ACTION_REQUIRED
export const getIsInternationalCrimInFUF = createSelector(
    getChecks,
    (checks) =>
        checks.find((check) => check.requestFlag === CHECK_REQUEST.INTERNATIONAL_CRIMINAL_RECORD_CHECK)
            ?.isInFollowUpFlow
);

// Check if Access Fee Dislamier is required
export const getIsAccessFeeCheckInCart = createSelector(getPurchasedAndSelectedChecks, (selectedChecks) =>
    selectedChecks.some((item) => item.isAccessFeeCheck)
);
