import React from 'react';
import { Modal } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';

interface Props {
    handleClose: () => void;
    handleSubmit: () => void;
    isVisible: boolean;
}

const OneIDByPassModal: React.FC<Props> = ({ handleClose, handleSubmit, isVisible }) => {
    const intl = useIntl();

    return (
        <Modal
            visible={isVisible}
            title={intl.formatMessage({
                id: '5d4e1.OneIDByPassModal.title',
                defaultMessage: 'Are you sure?',
            })}
            okText={intl.formatMessage({ id: '5d4e1.OneIDByPassModal.yes', defaultMessage: 'Yes, Submit Later' })}
            cancelText={intl.formatMessage({ id: '5d4e1.OneIDByPassModal.no', defaultMessage: 'No, Go Back' })}
            onCancel={handleClose}
            onOk={handleSubmit}
        >
            <FormattedMessage
                id="5d4e1.OneIDByPassModal.body"
                defaultMessage="You can skip for now and continue through your background check. One of our representatives will email you shortly after you submit your application to complete your identity verification. Choosing to submit your photos later could delay your application."
            />
        </Modal>
    );
};

export default OneIDByPassModal;
