import styled from 'styled-components/macro';

const FormBlock = styled.div`
    display: block;
    padding: 25px 0px;
    max-width: 600px;
    min-width: 250px;

    @media (max-width: ${(props) => props.theme.width.phone}) {
        width: 100%;
    }
`;

export default FormBlock;
