// Libraries
import React from 'react';
import styled from 'styled-components/macro';
import { injectIntl, FormattedMessage } from 'react-intl';

import StripeCheckoutContainer from './StripeCheckoutContainer';

const SettingsPaymentButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    max-width: 600px;
`;

const SettingsPaymentButtonTitle = styled.div`
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 5px;
`;

const SettingsPaymentButton = () => (
    <SettingsPaymentButtonContainer>
        <SettingsPaymentButtonTitle>
            <FormattedMessage id="2fa42.SettingsPaymentButton.ButtonTitle" defaultMessage="Credit Card" />
        </SettingsPaymentButtonTitle>
        <StripeCheckoutContainer />
    </SettingsPaymentButtonContainer>
);

export default injectIntl(SettingsPaymentButton);
