// Libraries
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components/macro';
import { EllipsisOutlined } from '@ant-design/icons';

// Modules
import Auth from 'modules/Auth';

// Components
import { StatusDropdown } from 'views/manager/views/hr/views/applications/components';
import { Button as AntButton } from 'antd';
import useApplications from 'hooks/queryHooks/useApplications';

const Button = styled(AntButton)`
    ${(props) => props.margin && `margin: ${props.margin}`};
    overflow: hidden !important;
    height: 40px !important;
    width: 40px !important;
`;

const ActionsCell = ({ record, history }) => {
    const [applicantId, setApplicantId] = useState('');
    const [newRecord, setNewRecord] = useState();
    const applicant = useApplications(applicantId);
    useEffect(() => {
        if (applicant.information !== undefined) {
            setNewRecord({
                ...record,
                information: applicant.information,
            });
        }
    }, [applicant, record]);

    const handleClick = (id) => {
        setApplicantId(id);
    };

    return (
        <StatusDropdown
            userIsNotBasic={Auth.isNotBasic()}
            applicant={newRecord ?? record}
            history={history}
            isDashboard
        >
            <Button
                id="applicant-menu-button"
                icon={<EllipsisOutlined />}
                shape="circle"
                onClick={() => handleClick(record.id)}
            />
        </StatusDropdown>
    );
};

export default withRouter(ActionsCell);
