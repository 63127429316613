// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Tabs, message } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import { intl } from 'components/GlobalProvider';
import { isEmpty } from 'lodash';
import copy from 'copy-to-clipboard';

// Modules
import Format from 'modules/Format';
import Auth from 'modules/Auth';
import Constants from 'utils/constants';

// Table Components
import { CertnTable, CertnTableCustomHeader } from 'views/manager/components';
import ActionsCell from './ActionsCell';
import StatusCell from './StatusCell';
import InfoCell from './InfoCell';
import AdjudicationStatusCell from './AdjudicationStatusCell';
import AdverseActionStatusCell from './AdverseActionStatusCell';
import ReviewStatusCell from './ReviewStatusCell';
import AssignedCell from './AssignedCell';

// UI Components
import CertnScore from 'certn-ui/CertnScore';
import Navbar, { NavMain, NavOption, NavOptions } from 'certn-ui/Navbar';

// Actions and Selectors
import {
    setOrdering,
    fetchApplications,
    setPageSize,
} from 'views/manager/views/hr/views/applications/ApplicationsActions';
import {
    getIsFetching,
    getIsFetchingApplications,
    getOrdering,
    getReverseOrder,
    getCurrentPage,
    getPageSize,
    getNumApplications,
} from 'views/manager/views/hr/views/applications/ApplicationsSelectors';
import { getLaunchDarklyFlags } from 'base/BaseSelectors';

const mapStateToProps = (state) => ({
    currentPage: getCurrentPage(state),
    pageSize: getPageSize(state),
    numApplications: getNumApplications(state),
    isFetching: getIsFetching(state),
    isFetchingApplications: getIsFetchingApplications(state),
    ordering: getOrdering(state),
    reverseOrder: getReverseOrder(state),
    isWebFeatureEnableNewStatusSystem: getLaunchDarklyFlags(state)?.webFeatureEnableNewStatusSystem,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setOrdering,
            setPageSize,
            fetchApplications,
        },
        dispatch
    );

const { TabPane } = Tabs;

const propTypes = {
    data: PropTypes.array.isRequired,
    ordering: PropTypes.string.isRequired,
    setOrdering: PropTypes.func.isRequired,
    reverseOrder: PropTypes.bool.isRequired,
    isFetchingApplications: PropTypes.bool,
    isFetching: PropTypes.bool.isRequired,
};

const defaultProps = {
    isFetchingApplications: false,
};

class Table extends React.Component {
    state = {
        selectedRowKeys: [],
        selectedRowIds: [],
    };

    columns = {
        desktop: this.props.config.desktop,
        mobile: this.props.config.mobile,
        clientConfiguredColumns: this.props.config.clientConfiguredColumns,
        Updated: {
            active: 'last_updated',
            renderUi: ({ record }) => Format.date(record.last_updated),
            customHeader: ({ data }) => (
                <CertnTableCustomHeader
                    data={data}
                    title={<FormattedMessage id="57dcf.Table.updated" defaultMessage="Updated" />}
                    reverseOrder={this.props.reverseOrder}
                    setOrdering={this.props.setOrdering}
                    ordering={this.props.ordering}
                />
            ),
        },
        Email: {
            active: 'applicant_account__email',
            renderUi: ({ record }) => record.applicant_account.email,
            customHeader: ({ data }) => (
                <CertnTableCustomHeader
                    data={data}
                    title={<FormattedMessage id="common.email" defaultMessage="Email" />}
                    reverseOrder={this.props.reverseOrder}
                    setOrdering={this.props.setOrdering}
                    ordering={this.props.ordering}
                />
            ),
        },
        'Short uid': {
            active: 'short_uid',
            renderUi: ({ record }) => (
                <div>
                    {record?.short_uid && (
                        <span>
                            <span
                                style={{
                                    display: 'inline-block',
                                    'min-width': '5.5em',
                                }}
                            >
                                {record.short_uid}
                            </span>
                            <button
                                aria-label="copy"
                                type="button"
                                style={{
                                    border: 'none',
                                    cursor: 'pointer',
                                    background: 'white',
                                    'vertical-align': 'bottom',
                                }}
                                onClick={() => {
                                    const copySuccess = copy(record.short_uid);
                                    if (copySuccess) {
                                        message.success('Report ID was copied to clipboard.');
                                    } else {
                                        message.error('Report ID could not be copied.');
                                    }
                                }}
                            >
                                <CopyOutlined />
                            </button>
                        </span>
                    )}
                </div>
            ),
            customHeader: ({ data }) => (
                <CertnTableCustomHeader
                    data={data}
                    title={<FormattedMessage id="57dcf.Table.shortUid" defaultMessage="Report ID" />}
                    reverseOrder={this.props.reverseOrder}
                    setOrdering={this.props.setOrdering}
                    ordering={this.props.ordering}
                />
            ),
        },
        'First Name': {
            active: 'information__first_name',
            renderUi: ({ record }) => record.information && record.information.first_name,
            customHeader: ({ data }) => (
                <CertnTableCustomHeader
                    data={data}
                    title={<FormattedMessage id="common.firstName" defaultMessage="First Name" />}
                    reverseOrder={this.props.reverseOrder}
                    setOrdering={this.props.setOrdering}
                    ordering={this.props.ordering}
                />
            ),
        },
        'Last Name': {
            active: 'information__last_name',
            renderUi: ({ record }) => record.information && record.information.last_name,
            customHeader: ({ data }) => (
                <CertnTableCustomHeader
                    data={data}
                    title={<FormattedMessage id="common.lastName" defaultMessage="Last Name" />}
                    reverseOrder={this.props.reverseOrder}
                    setOrdering={this.props.setOrdering}
                    ordering={this.props.ordering}
                />
            ),
        },
        Package: {
            width: '14%',
            active: 'application__posting__position_name',
            renderUi: ({ record }) =>
                record.application && record.application.posting && record.application.posting.position_name,
            customHeader: ({ data }) => (
                <CertnTableCustomHeader
                    data={data}
                    title={<FormattedMessage id="57dcf.Table.package" defaultMessage="Package" />}
                    reverseOrder={this.props.reverseOrder}
                    setOrdering={this.props.setOrdering}
                    ordering={this.props.ordering}
                />
            ),
        },
        Team: {
            width: '10%',
            active: 'application__owner__team__name',
            removeForPermissionLevel: !Auth.isPermissionLevel('admin'),
            renderUi: ({ record }) =>
                record.application && record.application.team && record.application.team.internal_name,
            customHeader: ({ data }) => (
                <CertnTableCustomHeader
                    data={data}
                    title={<FormattedMessage id="57dcf.Table.team" defaultMessage="Team" />}
                    reverseOrder={this.props.reverseOrder}
                    setOrdering={this.props.setOrdering}
                    ordering={this.props.ordering}
                />
            ),
        },
        Owner: {
            active: 'application__owner__email',
            renderUi: ({ record }) => record.application && record.application.owner && record.application.owner.email,
            customHeader: ({ data }) => (
                <CertnTableCustomHeader
                    data={data}
                    title={<FormattedMessage id="common.owner" defaultMessage="Owner" />}
                    reverseOrder={this.props.reverseOrder}
                    setOrdering={this.props.setOrdering}
                    ordering={this.props.ordering}
                />
            ),
        },
        Score: {
            active: 'certn_score',
            renderUi: ({ record }) => <CertnScore record={record} />,
            customHeader: ({ data }) => (
                <CertnTableCustomHeader
                    data={data}
                    title={<FormattedMessage id="57dcf.Table.score" defaultMessage="Score" />}
                    reverseOrder={this.props.reverseOrder}
                    setOrdering={this.props.setOrdering}
                    ordering={this.props.ordering}
                />
            ),
        },
        'Application Status': {
            active: this.props.isWebFeatureEnableNewStatusSystem ? 'order_status' : 'report_status',
            renderUi: ({ record }) => <StatusCell record={record} />,
        },
        'Adverse Action Status': {
            active: 'adverse_action',
            renderUi: ({ record }) => <AdverseActionStatusCell record={record} />,
        },
        'Adjudication Status': {
            active: 'adjudication_status',
            renderUi: ({ record }) => <AdjudicationStatusCell record={record} />,
            customHeader: ({ data }) => (
                <CertnTableCustomHeader
                    data={data}
                    title={<FormattedMessage id="57dcf.Table.adjStatus" defaultMessage="Adjudication Status" />}
                    reverseOrder={this.props.reverseOrder}
                    setOrdering={this.props.setOrdering}
                    ordering={this.props.ordering}
                />
            ),
        },
        'Review Status': {
            active: 'applicant_adjudication__life_cycle_status',
            renderUi: ({ record }) => <ReviewStatusCell record={record} />,
            customHeader: ({ data }) => (
                <CertnTableCustomHeader
                    data={data}
                    title={<FormattedMessage id="57dcf.Table.reviewStatus" defaultMessage="Review Status" />}
                    reverseOrder={this.props.reverseOrder}
                    setOrdering={this.props.setOrdering}
                    ordering={this.props.ordering}
                />
            ),
        },
        Assigned: {
            active: 'applicant_adjudication__adjudicator',
            renderUi: ({ record }) => <AssignedCell record={record} />,
            customHeader: ({ data }) => (
                <CertnTableCustomHeader
                    data={data}
                    title={<FormattedMessage id="57dcf.Table.clientAdjAssigned" defaultMessage="Assigned" />}
                    reverseOrder={this.props.reverseOrder}
                    setOrdering={this.props.setOrdering}
                    ordering={this.props.ordering}
                />
            ),
        },
        Actions: {
            renderUi: ({ record }) => <ActionsCell record={record} />,
            customHeader: ({ data }) => (
                <CertnTableCustomHeader
                    data={data}
                    title={<FormattedMessage id="common.actions" defaultMessage="Actions" />}
                    reverseOrder={this.props.reverseOrder}
                    setOrdering={this.props.setOrdering}
                    ordering={this.props.ordering}
                />
            ),
        },
        Info: {
            active: 'last_updated',
            renderUi: ({ record }) => <InfoCell record={record} />,
            customHeader: ({ data }) => (
                <CertnTableCustomHeader
                    data={data}
                    title={<FormattedMessage id="common.info" defaultMessage="Info" />}
                    reverseOrder={this.props.reverseOrder}
                    setOrdering={this.props.setOrdering}
                    ordering={this.props.ordering}
                />
            ),
        },
    };

    componentDidUpdate(prevProps) {
        if (prevProps.config !== this.props.config) {
            this.columns = {
                ...this.columns,
                desktop: this.props.config.desktop,
                mobile: this.props.config.mobile,
                clientConfiguredColumns: this.props.config.clientConfiguredColumns,
            };
        }
    }

    getSnapshotBeforeUpdate(prevProps) {
        if (prevProps.isFetchingApplications && !this.props.isFetchingApplications)
            this.setState({ selectedRowKeys: [] });

        return null;
    }

    onSelectChange = (selectedRowKeys) => {
        this.setState(() => ({
            selectedRowKeys,
        }));
    };

    bulkActionApplications = ({ selectedRowKeys, data }) =>
        selectedRowKeys.filter((item) => data[item]).map((item) => ({ applicant_id: data[item].id }));

    getIsDisabled = (record) => {
        // LDFlag isWebFeatureEnableNewStatusSystem
        if (this.props.isWebFeatureEnableNewStatusSystem)
            return !Constants.orderStatus.active.includes(record?.order_status);
        if (!this.props.isWebFeatureEnableNewStatusSystem)
            return !Constants.reportStatus.active.includes(record?.report_summary?.report_status);
    };

    render() {
        const {
            data,
            isFetching,
            isFetchingApplications,
            ordering,
            reverseOrder,
            numApplications,
            pageSize,
            currentPage,
        } = this.props;

        const { selectedRowKeys } = this.state;
        const desktop = window.matchMedia('(min-width: 1024px)').matches;
        const display = desktop ? 'desktop' : 'mobile';

        if (isEmpty(this.columns.desktop) || isEmpty(this.columns.mobile)) return null;

        const paginationConfig = desktop
            ? {
                  total: numApplications,
                  showTotal: (total, range) => (
                      <FormattedMessage
                          id="57dcf.Table.range"
                          defaultMessage="{range0}-{range1} of {total} items"
                          values={{ range0: range[0], range1: range[1], total }}
                      />
                  ),
                  pageSize,
                  pageSizeOptions: ['7', '25', '50'],
                  defaultCurrent: currentPage,
                  current: currentPage,
                  showSizeChanger: true,
                  showQuickJumper: true,
                  onShowSizeChange: (current, size) => this.props.setPageSize(size),
                  onChange: (page) => this.props.fetchApplications(page),
              }
            : {
                  simple: true,
                  total: numApplications,
                  pageSize,
                  defaultCurrent: currentPage,
                  current: currentPage,
                  onChange: (page) => this.props.fetchApplications(page),
              };

        const applications = this.bulkActionApplications({ selectedRowKeys, data });
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
            getCheckboxProps: (record) => ({
                disabled: this.getIsDisabled(record),
            }),
        };

        const showLoader = (isFetching || isFetchingApplications) && !this.props.fetchingError;

        return (
            <>
                <CertnTable
                    applications={applications}
                    rowSelection={rowSelection}
                    dataSource={data}
                    loading={showLoader}
                    pagination={paginationConfig}
                    columns={this.columns}
                    display={display}
                    setOrdering={this.props.setOrdering}
                    ordering={ordering}
                    reverseOrder={reverseOrder}
                />
            </>
        );
    }
}

const getNavHeaders = () => [
    ...(Auth.isAdjudicatorUser()
        ? [
              {
                  label: intl.formatMessage({ id: '57dcf.Table.needsReview', defaultMessage: 'Needs Review' }),
                  key: 'Needs Review',
              },
          ]
        : []),
    { label: intl.formatMessage({ id: '57dcf.Table.prospects', defaultMessage: 'Prospects' }), key: 'Prospects' },
    {
        label: intl.formatMessage({ id: '57dcf.Table.employmentPending', defaultMessage: 'Employment Pending' }),
        key: 'Employment Pending',
    },
    ...(Auth.isAdverseActionUser()
        ? [
              {
                  label: intl.formatMessage({ id: '57dcf.Table.adverseAction', defaultMessage: 'Adverse Action' }),
                  key: 'Adverse Action',
              },
          ]
        : []),
    { label: intl.formatMessage({ id: '57dcf.Table.employees', defaultMessage: 'Employees' }), key: 'Employees' },
    { label: intl.formatMessage({ id: 'common.archive', defaultMessage: 'Archive' }), key: 'Archive' },
];

Table.Nav = ({ setApplicationsView, applicationsView, headers = getNavHeaders() }) => (
    <Navbar noBorder>
        <NavMain>
            <NavOptions desktop mobile>
                {window.matchMedia('(max-width: 1024px)').matches ? (
                    <Tabs
                        defaultActiveKey="All"
                        onTabClick={(key) => {
                            setApplicationsView(key);
                        }}
                    >
                        <TabPane tab={<FormattedMessage id="common.all" defaultMessage="All" />} key="All" />
                        {headers.map((header) => (
                            <TabPane
                                tab={header.label}
                                key={header.key}
                                onChange={() => setApplicationsView(header.key)}
                            />
                        ))}
                    </Tabs>
                ) : (
                    <>
                        <NavOption
                            label={<FormattedMessage id="common.all" defaultMessage="All" />}
                            currentRoute={applicationsView === 'All'}
                            onClick={() => setApplicationsView('All')}
                        />
                        {headers.map((header, index) => (
                            <NavOption
                                key={index}
                                label={header.label}
                                currentRoute={applicationsView === header.key}
                                onClick={() => setApplicationsView(header.key)}
                            />
                        ))}
                    </>
                )}
            </NavOptions>
        </NavMain>
    </Navbar>
);

Table.Nav.displayName = 'TableNav';
Table.propTypes = propTypes;
Table.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(Table);
