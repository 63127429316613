import { combineReducers } from 'redux';

import applications from './views/applications/ApplicationsReducer';
import postings from './views/postings/PostingsReducer';

const hrManager = combineReducers({
    applications,
    postings,
});

export default hrManager;
