import styled from 'styled-components/macro';

export const Label = styled.div`
    font-weight: bolder;
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 3px;
    text-align: left;
    color: ${({ theme }) => theme.color.certnGray500};
`;
