// Libraries
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';

// Components
import { ConsentForm } from './components';
import { ErrorAlertCustom } from 'certn-ui/ErrorAlert';

// Modules
import withNavigation from 'views/welcome/modules/WithNavigation';

// Actions & Selectors
import { setTrackPageOrder, patchInformation } from 'views/welcome/WelcomeActions';
import { getInformation } from 'views/welcome/WelcomeSelectors';

MyCRCConsent.propTypes = {};
MyCRCConsent.defaultProps = {};

function MyCRCConsent(props) {
    const dispatch = useDispatch();
    const information = useSelector(getInformation);
    useEffect(() => {
        dispatch(setTrackPageOrder('base'));
    }, [dispatch]);

    const handleSubmit = () => {
        const { handleNextTrack } = props;
        dispatch(patchInformation({ mycrc_consent_given: true })).then((response) => {
            // Some users were making it past this page without giving consent somehow, checking mycrc_consent_given now
            if (response.information.mycrc_consent_given) {
                handleNextTrack();
            } else {
                ErrorAlertCustom({
                    title: 'Failed to submit consent',
                    description: 'Please select Yes, I Consent again. If you cannot proceed please contact support',
                });
            }
        });
    };

    const pages = () => {
        const { handleForward } = props;
        return {
            base: <ConsentForm information={information} acceptConsent={handleSubmit} declineConsent={handleForward} />,
        };
    };

    return pages()[props.trackPageLocation];
}

export default withRouter(withNavigation(MyCRCConsent));
