import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import { indexOf } from 'lodash';
import { Progress } from 'antd';

// Actions & Selectors
import { getNavigationVisible } from 'views/welcome/WelcomeSelectors';

const TrackPathBar = ({ trackOrder, furthestTrack, complete }) => {
    const numTracks = trackOrder.length;
    const currIndex = indexOf(trackOrder, furthestTrack);
    const navigationVisible = useSelector(getNavigationVisible);

    let percentComplete = 0;
    if (complete) percentComplete = 100;
    else if (currIndex >= 0) percentComplete = (currIndex / numTracks) * 100;

    if (!navigationVisible) return null;
    return (
        <TrackPathBarWrapper>
            <Progress percent={Math.round(percentComplete)} />
        </TrackPathBarWrapper>
    );
};
export default TrackPathBar;

export const TrackPathBarWrapper = styled.footer`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: ${(props) => (props.fixed ? '11' : '10')};
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    min-height: 60px;
    @media (max-width: 720px) {
        min-height: 38px;
        padding-bottom: 4px;
    }
    border-top: 2px solid ${(props) => props.theme.color.certnGray100};
    padding: 0 10%;
    box-sizing: border-box;
    background-color: ${({ theme }) => theme.color.certnWhite};
`;
