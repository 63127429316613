import { IntlShape } from 'react-intl';

export const CA = 'CA';
export const US = 'US';
export const UK = 'UK';
export const GB = 'GB';
export const AUS = 'AUS';

export const US_CA = [US, CA];
export const UK_GB = [UK, GB];

// Used when province/state is not a Canadian province or US state.
export const OTHER = 'OT';

export const isUSOrUSTerritory = (country: string) => {
    const usTerritories = ['US', 'GU', 'PR', 'VI', 'AS', 'MP', 'FM', 'MH', 'PW'];
    return usTerritories.includes(country);
};

export function isUSAOrCanada(country: string | null | undefined) {
    if (!country) {
        return false;
    }

    return US_CA.includes(country);
}

export function isUKOrGB(country: string) {
    return UK_GB.includes(country);
}

export function isUSAOrCanadaOrUK(country: string) {
    return isUSAOrCanada(country) || isUKOrGB(country);
}

export const formatOtherProvince = (country: string, other_province: string | null | undefined, intl: IntlShape) => {
    switch (country) {
        case 'US':
            return Countries.getUSStates(intl).find((state) => state.alpha2 === other_province)?.name || other_province;
        case 'CA':
            return (
                Countries.getCanadaProvinces(intl).find((state) => state.alpha2 === other_province)?.name ||
                other_province
            );
        case 'GB':
        case 'UK':
            return (
                Countries.getUKProvinces(intl).find((state) => state.alpha2 === other_province)?.name || other_province
            );
        case 'AU':
            return (
                Countries.getAusStatesTerritories(intl).find((state) => state.alpha2 === other_province)?.name ||
                other_province
            );
        default:
            return other_province;
    }
};

export const formatCountry = (country: string) => Countries.all.find((c) => country === c.alpha2)?.name;

export default class Countries {
    static all = [
        { name: 'Canada', alpha2: CA },
        { name: 'United States', alpha2: US },
        { name: 'Afghanistan', alpha2: 'AF' },
        { name: 'Albania', alpha2: 'AL' },
        { name: 'Algeria', alpha2: 'DZ' },
        { name: 'American Samoa', alpha2: 'AS' },
        { name: 'Andorra', alpha2: 'AD' },
        { name: 'Angola', alpha2: 'AO' },
        { name: 'Anguilla', alpha2: 'AI' },
        { name: 'Antarctica', alpha2: 'AQ' },
        { name: 'Antigua And Barbuda', alpha2: 'AG' },
        { name: 'Argentina', alpha2: 'AR' },
        { name: 'Armenia', alpha2: 'AM' },
        { name: 'Aruba', alpha2: 'AW' },
        { name: 'Australia', alpha2: 'AU' },
        { name: 'Austria', alpha2: 'AT' },
        { name: 'Azerbaijan', alpha2: 'AZ' },
        { name: 'Bahamas', alpha2: 'BS' },
        { name: 'Bahrain', alpha2: 'BH' },
        { name: 'Bangladesh', alpha2: 'BD' },
        { name: 'Barbados', alpha2: 'BB' },
        { name: 'Belarus', alpha2: 'BY' },
        { name: 'Belgium', alpha2: 'BE' },
        { name: 'Belize', alpha2: 'BZ' },
        { name: 'Benin', alpha2: 'BJ' },
        { name: 'Bermuda', alpha2: 'BM' },
        { name: 'Bhutan', alpha2: 'BT' },
        { name: 'Bolivia', alpha2: 'BO' },
        { name: 'Bosnia & Herzegovina', alpha2: 'BA' },
        { name: 'Botswana', alpha2: 'BW' },
        { name: 'Brazil', alpha2: 'BR' },
        { name: 'British Indian Ocean Territory', alpha2: 'IO' },
        { name: 'Brunei Darussalam', alpha2: 'BN' },
        { name: 'Bulgaria', alpha2: 'BG' },
        { name: 'Burkina Faso', alpha2: 'BF' },
        { name: 'Burundi', alpha2: 'BI' },
        { name: 'Cabo Verde', alpha2: 'CV' },
        { name: 'Cambodia', alpha2: 'KH' },
        { name: 'Cameroon', alpha2: 'CM' },
        { name: 'Cayman Islands', alpha2: 'KY' },
        { name: 'Central African Republic', alpha2: 'CF' },
        { name: 'Chad', alpha2: 'TD' },
        { name: 'Chile', alpha2: 'CL' },
        { name: 'China', alpha2: 'CN' },
        { name: 'Christmas Island', alpha2: 'CX' },
        { name: 'Colombia', alpha2: 'CO' },
        { name: 'Comoros', alpha2: 'KM' },
        { name: 'Costa Rica', alpha2: 'CR' },
        { name: 'Croatia', alpha2: 'HR' },
        { name: 'Cuba', alpha2: 'CU' },
        { name: 'Curacao', alpha2: 'CW' },
        { name: 'Cyprus', alpha2: 'CY' },
        { name: 'Czech Republic', alpha2: 'CZ' },
        { name: "Côte d'Ivoire", alpha2: 'CI' },
        { name: 'Democratic Republic Of Congo', alpha2: 'CD' },
        { name: 'Denmark', alpha2: 'DK' },
        { name: 'Djibouti', alpha2: 'DJ' },
        { name: 'Dominica', alpha2: 'DM' },
        { name: 'Dominican Republic', alpha2: 'DO' },
        { name: 'Ecuador', alpha2: 'EC' },
        { name: 'Egypt', alpha2: 'EG' },
        { name: 'El Salvador', alpha2: 'SV' },
        { name: 'Equatorial Guinea', alpha2: 'GQ' },
        { name: 'Eritrea', alpha2: 'ER' },
        { name: 'Estonia', alpha2: 'EE' },
        { name: 'Ethiopia', alpha2: 'ET' },
        { name: 'Falkland Islands', alpha2: 'FK' },
        { name: 'Faroe Islands', alpha2: 'FO' },
        { name: 'Fiji', alpha2: 'FJ' },
        { name: 'Finland', alpha2: 'FI' },
        { name: 'France', alpha2: 'FR' },
        { name: 'French Guiana', alpha2: 'GF' },
        { name: 'French Polynesia', alpha2: 'PF' },
        { name: 'French Southern Territories', alpha2: 'TF' },
        { name: 'Gabon', alpha2: 'GA' },
        { name: 'Gambia', alpha2: 'GM' },
        { name: 'Georgia', alpha2: 'GE' },
        { name: 'Germany', alpha2: 'DE' },
        { name: 'Ghana', alpha2: 'GH' },
        { name: 'Gibraltar', alpha2: 'GI' },
        { name: 'Greece', alpha2: 'GR' },
        { name: 'Greenland', alpha2: 'GL' },
        { name: 'Grenada', alpha2: 'GD' },
        { name: 'Guadeloupe', alpha2: 'GP' },
        { name: 'Guam', alpha2: 'GU' },
        { name: 'Guatemala', alpha2: 'GT' },
        { name: 'Guernsey', alpha2: 'GG' },
        { name: 'Guinea', alpha2: 'GN' },
        { name: 'Guinea-bissau', alpha2: 'GW' },
        { name: 'Guyana', alpha2: 'GY' },
        { name: 'Haiti', alpha2: 'HT' },
        { name: 'Honduras', alpha2: 'HN' },
        { name: 'Hong Kong', alpha2: 'HK' },
        { name: 'Hungary', alpha2: 'HU' },
        { name: 'Iceland', alpha2: 'IS' },
        { name: 'India', alpha2: 'IN' },
        { name: 'Indonesia', alpha2: 'ID' },
        { name: 'Iran, Islamic Republic Of', alpha2: 'IR' },
        { name: 'Iraq', alpha2: 'IQ' },
        { name: 'Ireland', alpha2: 'IE' },
        { name: 'Isle Of Man', alpha2: 'IM' },
        { name: 'Israel', alpha2: 'IL' },
        { name: 'Italy', alpha2: 'IT' },
        { name: 'Jamaica', alpha2: 'JM' },
        { name: 'Japan', alpha2: 'JP' },
        { name: 'Jersey', alpha2: 'JE' },
        { name: 'Jordan', alpha2: 'JO' },
        { name: 'Kazakhstan', alpha2: 'KZ' },
        { name: 'Kenya', alpha2: 'KE' },
        { name: 'Kiribati', alpha2: 'KI' },
        { name: 'Korea, North', alpha2: 'KP' },
        { name: 'Korea, South', alpha2: 'KR' },
        { name: 'Kosovo', alpha2: 'XK' },
        { name: 'Kuwait', alpha2: 'KW' },
        { name: 'Kyrgyzstan', alpha2: 'KG' },
        { name: "Lao People's Democratic Republic", alpha2: 'LA' },
        { name: 'Latvia', alpha2: 'LV' },
        { name: 'Lebanon', alpha2: 'LB' },
        { name: 'Lesotho', alpha2: 'LS' },
        { name: 'Liberia', alpha2: 'LR' },
        { name: 'Libya', alpha2: 'LY' },
        { name: 'Liechtenstein', alpha2: 'LI' },
        { name: 'Lithuania', alpha2: 'LT' },
        { name: 'Luxembourg', alpha2: 'LU' },
        { name: 'Macao', alpha2: 'MO' },
        { name: 'Macedonia, North', alpha2: 'MK' },
        { name: 'Madagascar', alpha2: 'MG' },
        { name: 'Malawi', alpha2: 'MW' },
        { name: 'Malaysia', alpha2: 'MY' },
        { name: 'Maldives', alpha2: 'MV' },
        { name: 'Mali', alpha2: 'ML' },
        { name: 'Malta', alpha2: 'MT' },
        { name: 'Marshall Islands', alpha2: 'MH' },
        { name: 'Martinique', alpha2: 'MQ' },
        { name: 'Mauritania', alpha2: 'MR' },
        { name: 'Mauritius', alpha2: 'MU' },
        { name: 'Mayotte', alpha2: 'YT' },
        { name: 'Mexico', alpha2: 'MX' },
        { name: 'Micronesia, Federated States Of', alpha2: 'FM' },
        { name: 'Moldova', alpha2: 'MD' },
        { name: 'Monaco', alpha2: 'MC' },
        { name: 'Mongolia', alpha2: 'MN' },
        { name: 'Montenegro', alpha2: 'ME' },
        { name: 'Montserrat', alpha2: 'MS' },
        { name: 'Morocco', alpha2: 'MA' },
        { name: 'Mozambique', alpha2: 'MZ' },
        { name: 'Myanmar', alpha2: 'MM' },
        { name: 'Namibia', alpha2: 'NA' },
        { name: 'Nauru', alpha2: 'NR' },
        { name: 'Nepal', alpha2: 'NP' },
        { name: 'Netherlands', alpha2: 'NL' },
        { name: 'Netherlands Antilles', alpha2: 'AN' },
        { name: 'Neutral Zone', alpha2: 'NT' },
        { name: 'New Caledonia', alpha2: 'NC' },
        { name: 'New Zealand', alpha2: 'NZ' },
        { name: 'Nicaragua', alpha2: 'NI' },
        { name: 'Niger', alpha2: 'NE' },
        { name: 'Nigeria', alpha2: 'NG' },
        { name: 'Northern Mariana Islands', alpha2: 'MP' },
        { name: 'Norway', alpha2: 'NO' },
        { name: 'Oman', alpha2: 'OM' },
        { name: 'Pakistan', alpha2: 'PK' },
        { name: 'Palau', alpha2: 'PW' },
        { name: 'Palestine', alpha2: 'PS' },
        { name: 'Panama', alpha2: 'PA' },
        { name: 'Papua New Guinea', alpha2: 'PG' },
        { name: 'Paraguay', alpha2: 'PY' },
        { name: 'Peru', alpha2: 'PE' },
        { name: 'Philippines', alpha2: 'PH' },
        { name: 'Pitcairn Islands', alpha2: 'PN' },
        { name: 'Poland', alpha2: 'PL' },
        { name: 'Portugal', alpha2: 'PT' },
        { name: 'Puerto Rico', alpha2: 'PR' },
        { name: 'Qatar', alpha2: 'QA' },
        { name: 'Republic Of Congo', alpha2: 'CG' },
        { name: 'Reunion', alpha2: 'RE' },
        { name: 'Romania', alpha2: 'RO' },
        { name: 'Russia', alpha2: 'RU' },
        { name: 'Rwanda', alpha2: 'RW' },
        { name: 'Saint Barthélemy', alpha2: 'BL' },
        { name: 'Saint Helena, Ascension And Tristan Da Cunha', alpha2: 'SH' },
        { name: 'Saint Kitts And Nevis', alpha2: 'KN' },
        { name: 'Saint Lucia', alpha2: 'LC' },
        { name: 'Saint Martin', alpha2: 'MF' },
        { name: 'Saint Pierre And Miquelon', alpha2: 'PM' },
        { name: 'Saint Vincent And The Grenadines', alpha2: 'VC' },
        { name: 'Samoa', alpha2: 'WS' },
        { name: 'San Marino', alpha2: 'SM' },
        { name: 'Sao Tome and Principe', alpha2: 'ST' },
        { name: 'Saudi Arabia', alpha2: 'SA' },
        { name: 'Senegal', alpha2: 'SN' },
        { name: 'Serbia', alpha2: 'RS' },
        { name: 'Seychelles', alpha2: 'SC' },
        { name: 'Sierra Leone', alpha2: 'SL' },
        { name: 'Singapore', alpha2: 'SG' },
        { name: 'Slovakia', alpha2: 'SK' },
        { name: 'Slovenia', alpha2: 'SI' },
        { name: 'Solomon Islands', alpha2: 'SB' },
        { name: 'Somalia', alpha2: 'SO' },
        { name: 'South Africa', alpha2: 'ZA' },
        { name: 'South Sudan', alpha2: 'SS' },
        { name: 'Spain', alpha2: 'ES' },
        { name: 'Sri Lanka', alpha2: 'LK' },
        { name: 'Sudan', alpha2: 'SD' },
        { name: 'Suriname', alpha2: 'SR' },
        { name: 'Svalbard And Jan Mayen', alpha2: 'SJ' },
        { name: 'Swaziland', alpha2: 'SZ' },
        { name: 'Sweden', alpha2: 'SE' },
        { name: 'Switzerland', alpha2: 'CH' },
        { name: 'Syrian Arab Republic', alpha2: 'SY' },
        { name: 'Taiwan', alpha2: 'TW' },
        { name: 'Tajikistan', alpha2: 'TJ' },
        { name: 'Tanzania, United Republic Of', alpha2: 'TZ' },
        { name: 'Thailand', alpha2: 'TH' },
        { name: 'Timor-Leste, Democratic Republic of', alpha2: 'TL' },
        { name: 'Togo', alpha2: 'TG' },
        { name: 'Tonga', alpha2: 'TO' },
        { name: 'Trinidad And Tobago', alpha2: 'TT' },
        { name: 'Tunisia', alpha2: 'TN' },
        { name: 'Turkey', alpha2: 'TR' },
        { name: 'Turkmenistan', alpha2: 'TM' },
        { name: 'Turks And Caicos Islands', alpha2: 'TC' },
        { name: 'Tuvalu', alpha2: 'TV' },
        { name: 'Uganda', alpha2: 'UG' },
        { name: 'Ukraine', alpha2: 'UA' },
        { name: 'United Arab Emirates', alpha2: 'AE' },
        { name: 'United Kingdom of Great Britain & Northern Ireland', alpha2: GB },
        { name: 'Uruguay', alpha2: 'UY' },
        { name: 'Uzbekistan', alpha2: 'UZ' },
        { name: 'Vanuatu', alpha2: 'VU' },
        { name: 'Vatican City State', alpha2: 'VA' },
        { name: 'Venezuela, Bolivarian Republic Of', alpha2: 'VE' },
        { name: 'Viet Nam', alpha2: 'VN' },
        { name: 'Virgin Islands (British)', alpha2: 'VG' },
        { name: 'Virgin Islands (US)', alpha2: 'VI' },
        { name: 'Western Sahara', alpha2: 'EH' },
        { name: 'Yemen', alpha2: 'YE' },
        { name: 'Zambia', alpha2: 'ZM' },
        { name: 'Zimbabwe', alpha2: 'ZW' },
    ];

    static canadaUS = [
        { name: 'Canada', alpha2: CA },
        { name: 'United States', alpha2: US },
    ];

    static getCanadaUsOnly = (intl: IntlShape) => [
        {
            alpha2: CA,
            name: intl.formatMessage({
                id: 'welcome.intlinput.canada',
                defaultMessage: 'Canada',
            }),
        },
        {
            alpha2: US,
            name: intl.formatMessage({
                id: 'welcome.intlinput.usa',
                defaultMessage: 'United States',
            }),
        },
    ];

    static getUSStates = (intl: IntlShape) => [
        { name: intl.formatMessage({ id: 'welcome.intlinput.alabama', defaultMessage: 'Alabama' }), alpha2: 'AL' },
        { name: intl.formatMessage({ id: 'welcome.intlinput.alaska', defaultMessage: 'Alaska' }), alpha2: 'AK' },
        { name: intl.formatMessage({ id: 'welcome.intlinput.arizona', defaultMessage: 'Arizona' }), alpha2: 'AZ' },
        { name: intl.formatMessage({ id: 'welcome.intlinput.arkansas', defaultMessage: 'Arkansas' }), alpha2: 'AR' },
        {
            name: intl.formatMessage({ id: 'welcome.intlinput.california', defaultMessage: 'California' }),
            alpha2: 'CA',
        },
        { name: intl.formatMessage({ id: 'welcome.intlinput.colorado', defaultMessage: 'Colorado' }), alpha2: 'CO' },
        {
            name: intl.formatMessage({ id: 'welcome.intlinput.connecticut', defaultMessage: 'Connecticut' }),
            alpha2: 'CT',
        },
        { name: intl.formatMessage({ id: 'welcome.intlinput.delaware', defaultMessage: 'Delaware' }), alpha2: 'DE' },
        {
            name: intl.formatMessage({ id: 'welcome.intlinput.doc', defaultMessage: 'District Of Columbia' }),
            alpha2: 'DC',
        },
        { name: intl.formatMessage({ id: 'welcome.intlinput.florida', defaultMessage: 'Florida' }), alpha2: 'FL' },
        { name: intl.formatMessage({ id: 'welcome.intlinput.georgia', defaultMessage: 'Georgia' }), alpha2: 'GA' },
        { name: intl.formatMessage({ id: 'welcome.intlinput.hawaii', defaultMessage: 'Hawaii' }), alpha2: 'HI' },
        { name: intl.formatMessage({ id: 'welcome.intlinput.idaho', defaultMessage: 'Idaho' }), alpha2: 'ID' },
        { name: intl.formatMessage({ id: 'welcome.intlinput.illinois', defaultMessage: 'Illinois' }), alpha2: 'IL' },
        { name: intl.formatMessage({ id: 'welcome.intlinput.indiana', defaultMessage: 'Indiana' }), alpha2: 'IN' },
        { name: intl.formatMessage({ id: 'welcome.intlinput.iowa', defaultMessage: 'Iowa' }), alpha2: 'IA' },
        { name: intl.formatMessage({ id: 'welcome.intlinput.kansas', defaultMessage: 'Kansas' }), alpha2: 'KS' },
        { name: intl.formatMessage({ id: 'welcome.intlinput.kentucky', defaultMessage: 'Kentucky' }), alpha2: 'KY' },
        { name: intl.formatMessage({ id: 'welcome.intlinput.louisiana', defaultMessage: 'Louisiana' }), alpha2: 'LA' },
        { name: intl.formatMessage({ id: 'welcome.intlinput.maine', defaultMessage: 'Maine' }), alpha2: 'ME' },
        { name: intl.formatMessage({ id: 'welcome.intlinput.maryland', defaultMessage: 'Maryland' }), alpha2: 'MD' },
        {
            name: intl.formatMessage({ id: 'welcome.intlinput.massachusetts', defaultMessage: 'Massachusetts' }),
            alpha2: 'MA',
        },
        { name: intl.formatMessage({ id: 'welcome.intlinput.michigan', defaultMessage: 'Michigan' }), alpha2: 'MI' },
        { name: intl.formatMessage({ id: 'welcome.intlinput.minnesota', defaultMessage: 'Minnesota' }), alpha2: 'MN' },
        {
            name: intl.formatMessage({ id: 'welcome.intlinput.mississipi', defaultMessage: 'Mississippi' }),
            alpha2: 'MS',
        },
        { name: intl.formatMessage({ id: 'welcome.intlinput.missouri', defaultMessage: 'Missouri' }), alpha2: 'MO' },
        { name: intl.formatMessage({ id: 'welcome.intlinput.montana', defaultMessage: 'Montana' }), alpha2: 'MT' },
        { name: intl.formatMessage({ id: 'welcome.intlinput.nebraska', defaultMessage: 'Nebraska' }), alpha2: 'NE' },
        { name: intl.formatMessage({ id: 'welcome.intlinput.nevada', defaultMessage: 'Nevada' }), alpha2: 'NV' },
        {
            name: intl.formatMessage({ id: 'welcome.intlinput.newHampshire', defaultMessage: 'New Hampshire' }),
            alpha2: 'NH',
        },
        {
            name: intl.formatMessage({ id: 'welcome.intlinput.newJersey', defaultMessage: 'New Jersey' }),
            alpha2: 'NJ',
        },
        {
            name: intl.formatMessage({ id: 'welcome.intlinput.newMexico', defaultMessage: 'New Mexico' }),
            alpha2: 'NM',
        },
        { name: intl.formatMessage({ id: 'welcome.intlinput.newYork', defaultMessage: 'New York' }), alpha2: 'NY' },
        {
            name: intl.formatMessage({ id: 'welcome.intlinput.northCarolina', defaultMessage: 'North Carolina' }),
            alpha2: 'NC',
        },
        {
            name: intl.formatMessage({ id: 'welcome.intlinput.northDakota', defaultMessage: 'North Dakota' }),
            alpha2: 'ND',
        },
        { name: intl.formatMessage({ id: 'welcome.intlinput.ohio', defaultMessage: 'Ohio' }), alpha2: 'OH' },
        { name: intl.formatMessage({ id: 'welcome.intlinput.oklahoma', defaultMessage: 'Oklahoma' }), alpha2: 'OK' },
        { name: intl.formatMessage({ id: 'welcome.intlinput.oregon', defaultMessage: 'Oregon' }), alpha2: 'OR' },
        {
            name: intl.formatMessage({ id: 'welcome.intlinput.pennsylvania', defaultMessage: 'Pennsylvania' }),
            alpha2: 'PA',
        },
        {
            name: intl.formatMessage({ id: 'welcome.intlinput.rhodeIsland', defaultMessage: 'Rhode Island' }),
            alpha2: 'RI',
        },
        {
            name: intl.formatMessage({ id: 'welcome.intlinput.southCarolina', defaultMessage: 'South Carolina' }),
            alpha2: 'SC',
        },
        {
            name: intl.formatMessage({ id: 'welcome.intlinput.southDakota', defaultMessage: 'South Dakota' }),
            alpha2: 'SD',
        },
        { name: intl.formatMessage({ id: 'welcome.intlinput.tennessee', defaultMessage: 'Tennessee' }), alpha2: 'TN' },
        { name: intl.formatMessage({ id: 'welcome.intlinput.texas', defaultMessage: 'Texas' }), alpha2: 'TX' },
        { name: intl.formatMessage({ id: 'welcome.intlinput.utah', defaultMessage: 'Utah' }), alpha2: 'UT' },
        { name: intl.formatMessage({ id: 'welcome.intlinput.vermont', defaultMessage: 'Vermont' }), alpha2: 'VT' },
        { name: intl.formatMessage({ id: 'welcome.intlinput.virginia', defaultMessage: 'Virginia' }), alpha2: 'VA' },
        {
            name: intl.formatMessage({ id: 'welcome.intlinput.washington', defaultMessage: 'Washington' }),
            alpha2: 'WA',
        },
        {
            name: intl.formatMessage({ id: 'welcome.intlinput.westVirginia', defaultMessage: 'West Virginia' }),
            alpha2: 'WV',
        },
        { name: intl.formatMessage({ id: 'welcome.intlinput.wisconsin', defaultMessage: 'Wisconsin' }), alpha2: 'WI' },
        { name: intl.formatMessage({ id: 'welcome.intlinput.wyoming', defaultMessage: 'Wyoming' }), alpha2: 'WY' },
    ];

    static getCanadaProvinces = (intl: IntlShape) => [
        { name: intl.formatMessage({ id: 'welcome.intlinput.alberta', defaultMessage: 'Alberta' }), alpha2: 'AB' },
        {
            name: intl.formatMessage({ id: 'welcome.intlinput.britishColumbia', defaultMessage: 'British Columbia' }),
            alpha2: 'BC',
        },
        { name: intl.formatMessage({ id: 'welcome.intlinput.manitoba', defaultMessage: 'Manitoba' }), alpha2: 'MB' },
        {
            name: intl.formatMessage({ id: 'welcome.intlinput.newBrunswick', defaultMessage: 'New Brunswick' }),
            alpha2: 'NB',
        },
        {
            name: intl.formatMessage({
                id: 'welcome.intlinput.newfoundlandLab',
                defaultMessage: 'Newfoundland and Labrador',
            }),
            alpha2: 'NL',
        },
        {
            name: intl.formatMessage({ id: 'welcome.intlinput.nwt', defaultMessage: 'Northwest Territories' }),
            alpha2: 'NT',
        },
        {
            name: intl.formatMessage({ id: 'welcome.intlinput.novaScotia', defaultMessage: 'Nova Scotia' }),
            alpha2: 'NS',
        },
        { name: intl.formatMessage({ id: 'welcome.intlinput.nunavut', defaultMessage: 'Nunavut' }), alpha2: 'NU' },
        { name: intl.formatMessage({ id: 'welcome.intlinput.ontario', defaultMessage: 'Ontario' }), alpha2: 'ON' },
        {
            name: intl.formatMessage({ id: 'welcome.intlinput.pei', defaultMessage: 'Prince Edward Island' }),
            alpha2: 'PE',
        },
        { name: intl.formatMessage({ id: 'welcome.intlinput.quebec', defaultMessage: 'Quebec' }), alpha2: 'QC' },
        { name: intl.formatMessage({ id: 'welcome.intlinput.sask', defaultMessage: 'Saskatchewan' }), alpha2: 'SK' },
        { name: intl.formatMessage({ id: 'welcome.intlinput.yukon', defaultMessage: 'Yukon' }), alpha2: 'YT' },
    ];

    static getUKProvinces = (intl: IntlShape) => [
        {
            alpha2: 'ENG',
            name: intl.formatMessage({ id: 'welcome.intlinput.england', defaultMessage: 'England' }),
        },
        {
            alpha2: 'NIR',
            name: intl.formatMessage({
                id: 'welcome.intlinput.northernIreland',
                defaultMessage: 'Northern Ireland',
            }),
        },
        {
            alpha2: 'SCT',
            name: intl.formatMessage({ id: 'welcome.intlinput.scotland', defaultMessage: 'Scotland' }),
        },
        {
            alpha2: 'WLS',
            name: intl.formatMessage({ id: 'welcome.intlinput.wales', defaultMessage: 'Wales' }),
        },
    ];

    static getAusStatesTerritories = (intl: IntlShape) => [
        {
            alpha2: 'NSW',
            name: intl.formatMessage({ id: 'welcome.intlinput.newSouthWales', defaultMessage: 'New South Wales' }),
        },
        {
            alpha2: 'VIC',
            name: intl.formatMessage({
                id: 'welcome.intlinput.victoria',
                defaultMessage: 'Victoria',
            }),
        },
        {
            alpha2: 'QLD',
            name: intl.formatMessage({ id: 'welcome.intlinput.queensland', defaultMessage: 'Queensland' }),
        },
        {
            alpha2: 'WA',
            name: intl.formatMessage({ id: 'welcome.intlinput.westernAustralia', defaultMessage: 'Western Australia' }),
        },
        {
            alpha2: 'SA',
            name: intl.formatMessage({ id: 'welcome.intlinput.southAustralia', defaultMessage: 'South Australia' }),
        },
        {
            alpha2: 'TAS',
            name: intl.formatMessage({ id: 'welcome.intlinput.tasmania', defaultMessage: 'Tasmania' }),
        },
        {
            alpha2: 'ACT',
            name: intl.formatMessage({
                id: 'welcome.intlinput.australianCapitalTerritory',
                defaultMessage: 'Australian Capital Territory',
            }),
        },
        {
            alpha2: 'NT',
            name: intl.formatMessage({
                id: 'welcome.intlinput.northernTerritory',
                defaultMessage: 'Northern Territory',
            }),
        },
    ];
}
