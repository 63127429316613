import React from 'react';
import { Typography } from 'antd';

const { Text } = Typography;

const TextPrimary = (props) => <Text {...props} type="primary" />;
const TextSecondary = (props) => <Text {...props} type="secondary" />;
const TextWarning = (props) => <Text {...props} type="warning" />;
const TextDanger = (props) => <Text {...props} type="danger" />;

const TypogText = {
    Primary: TextPrimary,
    Secondary: TextSecondary,
    Warning: TextWarning,
    Danger: TextDanger,
};

export default TypogText;
