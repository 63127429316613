// Libraries
import React from 'react';
import { Form } from '@ant-design/compatible';
import { get, uniq, without, find, difference } from 'lodash';
import { FormattedMessage } from 'react-intl';
import VirtualizedSelect from 'react-virtualized-select';
import createFilterOptions from 'react-select-fast-filter-options';
import { Creatable } from 'react-select';

// Components
import Tab, { TabBox } from 'certn-ui/Tab';
import Title from 'certn-ui/Title';
import Text from 'certn-ui/Text';
import { HalfBoxDesktop } from 'certn-ui/Layout';
import { AppearRight } from 'certn-ui/Animate';
import { TrackNavigation } from 'views/welcome/components';

class PageOneForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            skills: get(props.information, 'skills')
                ? get(props.information, 'skills').map((skill) => skill.skill)
                : [],
        };
        window.scrollTo(0, 0);
    }

    addSkill = (skill) => {
        const { skills } = this.state;
        skills.push(skill);

        const skillSet = uniq(skills);
        this.setState({ skills: skillSet });
    };

    deleteSkill = (skill) => {
        const { skills } = this.state;
        const newSkills = without(skills, skill);
        this.setState({ skills: newSkills });
    };

    constructSkillsAndSubmit = () => {
        const { skills } = this.props;
        const skillObjects = this.state.skills.map((newSkill) => {
            const skillFound = find(skills, ['skill', newSkill]);
            return {
                skill: newSkill,
                id: skillFound ? skillFound.id : null,
            };
        });
        this.props.handleSubmit({ skills: skillObjects });
    };

    buildSkillsOptions = (skills) =>
        skills.reduce((acc, skill) => {
            if (this.state.skills.indexOf(skill.skill) === -1) {
                acc.push({
                    label: skill.skill,
                    value: skill.skill,
                });
            }
            return acc;
        }, []);

    render() {
        const { isFetchingSilent, listing, skills, intl } = this.props;
        let requiredSkills = get(listing, ['required_skills']);
        if (requiredSkills) {
            requiredSkills = requiredSkills.map((skill) => skill.skill);
            requiredSkills = difference(requiredSkills, this.state.skills);
        }

        const options = this.buildSkillsOptions(skills);
        const filterOptions = createFilterOptions({ options });

        return (
            <AppearRight>
                <Title>
                    <FormattedMessage
                        id="welcome.Skills.skillsTitle"
                        defaultMessage="Start typing to find and select a few of the skills that are most important to you, and showcase you the best."
                    />
                </Title>
                <HalfBoxDesktop>
                    <VirtualizedSelect
                        autoFocus
                        labelKey="label"
                        valueKey="value"
                        simpleValue
                        multi
                        optionHeight={40}
                        selectComponent={Creatable}
                        style={{ textAlign: 'left' }}
                        onChange={(skill) => this.addSkill(skill)}
                        placeholder={intl.formatMessage({
                            id: 'welcome.Skills.skill',
                            defaultMessage: 'Skill (Ex: Data Analytics)',
                        })}
                        disabled={isFetchingSilent}
                        filterOptions={filterOptions}
                        options={options}
                    />
                </HalfBoxDesktop>
                <TabBox justifyContent="space-around">
                    {this.state.skills &&
                        this.state.skills.map((skill) => (
                            <Tab key={skill} title={skill} handleDelete={this.deleteSkill} />
                        ))}
                </TabBox>
                {requiredSkills && requiredSkills.length > 0 && (
                    <>
                        <Text align="center">
                            <FormattedMessage
                                id="welcome.Skills.suggestedSkills"
                                defaultMessage="Suggested skills based on the job posting:"
                            />
                        </Text>
                        <TabBox>
                            {requiredSkills.map((skill) => (
                                <Tab
                                    type="default"
                                    key={skill}
                                    title={skill}
                                    handleAdd={(newSkill) => this.addSkill(newSkill)}
                                />
                            ))}
                        </TabBox>
                    </>
                )}
                <TrackNavigation handleSubmit={this.constructSkillsAndSubmit} />
            </AppearRight>
        );
    }
}

export default Form.create()(PageOneForm);
