// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// External Library
import { memoize } from 'lodash';
import { FormattedMessage, injectIntl } from 'react-intl';
import { CaretDownOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import { getDataSet, reduce } from 'iso3166-2-db';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

// UI Components
import { Input, Col, Popover, Select } from 'antd';
import styled from 'styled-components';
import ProvStateSelect from 'components/ProvStateSelect';
import CountySelect from '../components/CountySelect';
import CountyInput from '../components/CountyInput';

// Modules & Utils
import { isUKOrGB, isUSAOrCanada } from 'modules/Countries';
import Format from 'modules/Format';
import styles from 'styles/styles';
import { deriveAddressComponents, combineAddress, getSelectedProvince } from 'utils/addressInputHelpers';
import { Regex, InputTransform } from 'modules';

// Actions & Selectors
import { updateAddressError } from 'base/BaseActions';
import { getAddressError, getLanguage, getLaunchDarklyFlags } from 'base/BaseSelectors';

const propTypes = {
    // Parent Props
    currentAddress: PropTypes.object,
    updateAddressError: PropTypes.func.isRequired,
    handleCountryOnChange: PropTypes.func,
    handleProvinceStateOnChange: PropTypes.func,
    addressError: PropTypes.bool,
    bypassCounty: PropTypes.bool,
    isUkCheck: PropTypes.bool,
    isSolelyUkCheck: PropTypes.bool,
    oneID: PropTypes.object,
};
const defaultProps = {
    currentAddress: {},
    handleCountryOnChange: function noop() {},
    handleProvinceStateOnChange: function noop() {},
    addressError: false,
    bypassCounty: true,
    isUkCheck: false,
    isSolelyUkCheck: false,
    oneID: {},
};
const mapStateToProps = (state) => ({
    addressError: getAddressError(state),
    lang: getLanguage(state),
    webFeatureRequireCurrentAndPositionPropertyAddressesForOnboarding: getLaunchDarklyFlags(state)
        ?.webFeatureRequireCurrentAndPositionPropertyAddressesForOnboarding,
});
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            updateAddressError,
        },
        dispatch
    );

export function transformForUk(address) {
    return {
        ...address,
        address_line_1: address.unit || address.address,
        address_line_2: address.unit ? address.address : null,
    };
}

const FindAddress = styled.div`
    margin-bottom: 20px;
    margin-top: -3px;
    padding: ${(props) => (props.showAllAddressFields ? '15px 15px 0px 15px' : '15px')};
    ${(props) => !props.showAllAddressFields && 'height: 0px'};
`;

class AddressAutoComplete extends React.Component {
    constructor(props) {
        super(props);
        const multi = props.autocompleteId !== undefined;
        this.state = {
            showAllAddressFields: false,
            inputFieldAddress: undefined,
            countries: reduce(getDataSet(), this.props.lang),
        };
        this.ids = {
            address: multi ? `address-${props.autocompleteId}` : 'address',
            address_line_1: multi ? `address_line_1-${props.autocompleteId}` : 'address_line_1',
            address_line_2: multi ? `address_line_2-${props.autocompleteId}` : 'address_line_2',
            unit: multi ? `unit-${props.autocompleteId}` : 'unit',
            city: multi ? `city-${props.autocompleteId}` : 'city',
            country: multi ? `country-${props.autocompleteId}` : 'country',
            county: multi ? `county-${props.autocompleteId}` : 'county',
            province_state: multi ? `province_state-${props.autocompleteId}` : 'province_state',
            other_province_state: multi ? `other_province_state-${props.autocompleteId}` : 'other_province_state',
            postal_code: multi ? `postal_code-${props.autocompleteId}` : 'postal_code',
            autocomplete: multi ? `autocomplete-${props.autocompleteId}` : 'autocomplete',
            countryLabel: multi ? `country_label-${props.autocompleteId}` : 'country_label',
        };
    }

    componentDidMount() {
        const { autocompleteId, country, isUkCheck } = this.props;
        let { currentAddress } = this.props;
        const { ids } = this;
        const autocompleteFieldName =
            autocompleteId !== undefined ? `autocomplete-${this.props.autocompleteId}` : 'autocomplete';
        // Session management is automatically built into the JavaScript widgets. This includes both the Place Autocomplete requests and the Place Details request.
        // Setting `fields` for saving costs
        // https://developers.google.com/maps/documentation/javascript/place-autocomplete#specify-data-fields
        this.autocomplete = new window.google.maps.places.Autocomplete(document.getElementById(autocompleteFieldName), {
            fields: ['address_components'], // Save costs by only requesting what we want
        });
        this.autocomplete.addListener('place_changed', this.handlePlaceSelect);

        if (isUkCheck) {
            currentAddress = transformForUk(currentAddress);
        }

        if (currentAddress && (currentAddress.address || currentAddress.country)) {
            const provinceOrOther = getSelectedProvince(
                currentAddress?.country,
                currentAddress?.province_state,
                currentAddress?.other_province_state
            );

            const combinedAddress = combineAddress(
                currentAddress?.address,
                currentAddress?.city,
                provinceOrOther,
                currentAddress?.country,
                currentAddress?.postal_code
            );
            this.setState({ showAllAddressFields: true, inputFieldAddress: combinedAddress }, () => {
                if (autocompleteId !== undefined) {
                    const newAddressObject = {};

                    newAddressObject[ids.address_line_1] = currentAddress.address_line_1;
                    newAddressObject[ids.address_line_2] = currentAddress.address_line_2;
                    newAddressObject[ids.address] = currentAddress.address;
                    newAddressObject[ids.unit] = currentAddress.unit;
                    newAddressObject[ids.city] = currentAddress.city;
                    newAddressObject[ids.county] = currentAddress.county;
                    newAddressObject[ids.country] = currentAddress.country;
                    newAddressObject[ids.province_state] = currentAddress.province_state;
                    newAddressObject[ids.other_province_state] = currentAddress.other_province_state;
                    if (currentAddress.postal_code && !isUKOrGB(country)) {
                        currentAddress.postal_code = Format.postalCodeForm(currentAddress.postal_code);
                    }
                    newAddressObject[ids.postal_code] = currentAddress.postal_code;
                    this.props.form.setFieldsValue({ ...newAddressObject });
                } else {
                    this.props.form.setFieldsValue({
                        address_line_1: currentAddress.address_line_1,
                        address_line_2: currentAddress.address_line_2,
                        address: currentAddress.address,
                        unit: currentAddress.unit,
                        city: currentAddress.city,
                        county: currentAddress.county,
                        country: currentAddress.country,
                        province_state: currentAddress.province_state,
                        other_province_state: currentAddress.other_province_state,
                        postal_code: currentAddress.postal_code,
                    });
                }
            });
        }

        this.props.updateAddressError(false);
    }

    onCountryChange = (country) => {
        const { form, handleCountryOnChange } = this.props;
        const { ids } = this;
        // Clear the province/state values
        form.setFieldsValue({
            [ids.province_state]: undefined,
            [ids.other_province_state]: '', //  API expects an empty string as default value, when the prov state field is optional
        });
        handleCountryOnChange(country);
    };

    onProvinceStateChange = (provinceState) => {
        const { form, handleProvinceStateOnChange } = this.props;
        const { ids } = this;
        form.setFieldsValue({ [ids.county]: undefined });
        handleProvinceStateOnChange(provinceState);
    };

    showAllAddressFields = () => {
        this.setState({ showAllAddressFields: true });
    };

    handlePlaceSelect = () => {
        const addressObject = this.autocomplete.getPlace();
        const address = addressObject.address_components;
        if (!address) {
            this.props.updateAddressError(true);
            return;
        }
        this.processAddress(address);
    };

    processAddress = (address) => {
        const { ids } = this;
        const {
            city,
            combinedAddress,
            country,
            county,
            other_province_state,
            postal_code,
            province_state,
            provinceOrOther,
            street_address,
            streetNumber,
            unitNumber,
        } = deriveAddressComponents(address);
        const showAllAddressFields =
            !street_address ||
            !city ||
            !provinceOrOther ||
            !country ||
            !county ||
            !postal_code ||
            (postal_code && postal_code.length < 5) ||
            streetNumber === undefined;
        if (showAllAddressFields) {
            this.setState({ showAllAddressFields: true });
        }

        const formattedPostalCode =
            postal_code && !isUKOrGB(country) ? Format.postalCodeForm(postal_code) : postal_code;

        this.props.form.setFieldsValue({
            [ids.country]: country,
            [ids.county]: county,
        });

        this.setState(
            {
                inputFieldAddress: combinedAddress,
                showAllAddressFields: true,
            },
            () => {
                this.props.form.setFieldsValue({
                    [ids.unit]: unitNumber,
                    [ids.address]: street_address,
                    [ids.address_line_1]: unitNumber || street_address,
                    [ids.address_line_2]: unitNumber ? street_address : '',
                    [ids.city]: city,
                    [ids.county]: county,
                    [ids.province_state]: province_state,
                    [ids.other_province_state]: other_province_state || '',
                    [ids.postal_code]: formattedPostalCode,
                });
                this.props.updateAddressError(false);
            }
        );
    };

    handleAddressChange = (event) => {
        this.setState({ inputFieldAddress: event.target.value });
    };

    sortedCountries = memoize((countries) =>
        Object.keys(countries).sort((a, b) => (countries[a].name > countries[b].name ? 1 : -1))
    );

    render() {
        const { countries } = this.state;
        const {
            getFieldDecorator,
            form,
            intl,
            skippable,
            hideLabels,
            addressError,
            currentAddress,
            canadaUSOnly,
            customTitle,
            bypassPostal,
            bypassStreetAddress,
            bypassUnit,
            bypassCounty,
            webFeatureRequireCurrentAndPositionPropertyAddressesForOnboarding,
            isUkCheck,
            isSolelyUkCheck,
            disabled,
            oneID,
        } = this.props;
        let oneIDData = oneID;
        let currentAddressData = currentAddress;
        const required = !skippable; // bypass for employer form
        const { ids } = this;
        const { inputFieldAddress, showAllAddressFields: showAll } = this.state;
        const showAllAddressFields = showAll || this.props.showAllFields || addressError;
        const country = form.getFieldValue(ids.country);
        const currentProvinceState = form.getFieldValue(ids.province_state);
        const county = currentAddressData?.county ?? form.getFieldValue(ids.county);
        const isUS = currentAddressData?.country === 'US' || country === 'US';
        const provinceFieldName = isUSAOrCanada(country) ? ids.province_state : ids.other_province_state;
        const provinceLabel = intl.formatMessage({
            id: 'address.provinceState',
            defaultMessage: 'Province/State',
        });
        if (isSolelyUkCheck) {
            currentAddressData = transformForUk(currentAddressData);
            oneIDData = transformForUk(oneIDData);
        }

        const everyProvOrStateHasISOCode = countries[country]?.regions.every((region) => region.iso);

        /**
        This is an unconventional logic to handle a slew of cases.
        Business logic is: Show county field only if absolutely necessary.
            - If its not already available from Google autocomplete AND
            - Country is USA
            - Or it's a UK SSB check
        1. By default county field should be hidden always
        2. Collect the County field data from google autocomplete ( even if its hidden )
        but don't show the county field unless its USA and its empty from Google's results.
        3. When someone starts typing in the county field, it will be hidden again. To avoid that
        form.isFieldTouched('county') is checked.
        4. `addressError` is thrown when the county field validation fails.
        But it will fail only if there is no county data already available
         */
        const showCountyFormItem =
            (!bypassCounty && form.isFieldTouched('county')) ||
            (addressError && isUS && !county) ||
            !!form.isFieldTouched('county') ||
            isUkCheck;

        let countyItem = null;

        if (webFeatureRequireCurrentAndPositionPropertyAddressesForOnboarding) {
            if (showCountyFormItem && !isUkCheck) {
                countyItem = (
                    <CountySelect
                        required={showCountyFormItem && showAllAddressFields}
                        hideLabels={hideLabels}
                        getFieldDecorator={getFieldDecorator}
                        ids={ids}
                        province={currentProvinceState}
                        intl={intl}
                        disabled={disabled && oneIDData.county}
                    />
                );
            } else {
                countyItem = (
                    <CountyInput
                        isUkCheck={isUkCheck}
                        oneIDData={oneIDData}
                        required={showCountyFormItem && showAllAddressFields}
                        hideLabels={hideLabels}
                        getFieldDecorator={getFieldDecorator}
                        ids={ids}
                        county={county}
                        country={country}
                        province={currentProvinceState}
                        intl={intl}
                        disabled={disabled && oneIDData.county}
                    />
                );
            }
        }

        return (
            <>
                <div style={{ marginTop: '5px' }}>
                    {!hideLabels && (
                        <Col style={{ textAlign: 'right', paddingRight: '8px', boxSizing: 'border-box' }}>
                            <span>
                                {this.props.addressError && !showAllAddressFields && (
                                    <span style={{ color: styles.color.certnRed700 }}>*</span>
                                )}
                                <FormattedMessage id="addressAuto.addressLookup" defaultMessage="Address Lookup" />:
                            </span>
                        </Col>
                    )}
                    <>
                        <Input
                            size="large"
                            id={ids.autocomplete}
                            data-testid="autocomplete"
                            value={inputFieldAddress}
                            onChange={this.handleAddressChange}
                            placeholder={
                                customTitle ||
                                intl.formatMessage({
                                    id: 'addressAuto.addressLookup',
                                    defaultMessage: 'Address Lookup',
                                })
                            }
                            className="input-field"
                            // eslint-disable-next-line
                            ref="input"
                            type="text"
                            addonAfter={
                                <div
                                    style={{ cursor: 'pointer' }}
                                    onClick={!showAllAddressFields ? () => this.showAllAddressFields() : null}
                                    onKeyDown={!showAllAddressFields ? () => this.showAllAddressFields() : null}
                                >
                                    <FormattedMessage id="addressAuto.manualEntry" defaultMessage="Manual Entry" />
                                    <CaretDownOutlined />
                                </div>
                            }
                            addonBefore={
                                <Popover
                                    content={
                                        customTitle ||
                                        intl.formatMessage({
                                            id: 'addressAuto.addressLookupStartTyping',
                                            defaultMessage: 'Address Lookup (Start typing your address)',
                                        })
                                    }
                                >
                                    <InfoCircleOutlined />
                                </Popover>
                            }
                            style={{
                                border:
                                    this.props.addressError && !showAllAddressFields
                                        ? `1px solid ${styles.color.certnRed700} `
                                        : '0',
                            }}
                            disabled={disabled}
                        />
                        {this.props.addressError && !showAllAddressFields && (
                            <span style={{ color: styles.color.certnRed700 }}>
                                <FormattedMessage
                                    id="addressAuto.validAddressRequired"
                                    defaultMessage="A valid address must be provided"
                                />
                            </span>
                        )}
                    </>
                    <FindAddress
                        showAllAddressFields={showAllAddressFields}
                        style={
                            showAllAddressFields ? { display: 'default' } : { display: 'block', marginBottom: '0px' }
                        }
                    >
                        {isSolelyUkCheck ? (
                            <>
                                <Form.Item
                                    hasFeedback
                                    style={{ display: showAllAddressFields ? 'block' : 'none', marginBottom: '5px' }}
                                >
                                    {getFieldDecorator(ids.address_line_1, {
                                        initialValue: oneIDData.address_line_1 || currentAddressData?.address_line_1,
                                        rules: [
                                            {
                                                required: true,
                                                message: intl.formatMessage({
                                                    id: 'error.validation.notBlank',
                                                    defaultMessage: 'Please do not leave blank',
                                                }),
                                            },
                                        ],
                                    })(
                                        <Input
                                            size="large"
                                            data-testid="address_line_1"
                                            type="text"
                                            placeholder={intl.formatMessage({
                                                id: 'addressAuto.addressLine1',
                                                defaultMessage: 'Address Line 1',
                                            })}
                                            addonBefore={
                                                <Popover
                                                    content={intl.formatMessage({
                                                        id: 'addressAuto.addressLine1',
                                                        defaultMessage: 'Address Line 1',
                                                    })}
                                                >
                                                    <InfoCircleOutlined />
                                                </Popover>
                                            }
                                            disabled={disabled && oneIDData.address_line_1}
                                        />
                                    )}
                                </Form.Item>
                                <Form.Item
                                    style={{ display: showAllAddressFields ? 'block' : 'none', marginBottom: '5px' }}
                                >
                                    {getFieldDecorator(ids.address_line_2, {
                                        initialValue: oneIDData.address_line_2,
                                    })(
                                        <Input
                                            size="large"
                                            autoFocus
                                            data-testid="address_line_2"
                                            placeholder={intl.formatMessage({
                                                id: 'addressAuto.addressLine2',
                                                defaultMessage: 'Address Line 2',
                                            })}
                                            addonBefore={
                                                <Popover
                                                    content={
                                                        <FormattedMessage
                                                            id="addressAuto.addressLine2"
                                                            defaultMessage="Address Line 2"
                                                        />
                                                    }
                                                >
                                                    <InfoCircleOutlined />
                                                </Popover>
                                            }
                                            disabled={disabled && oneIDData.address_line_2}
                                        />
                                    )}
                                </Form.Item>
                                <Form.Item
                                    hasFeedback
                                    style={{ display: showAllAddressFields ? 'block' : 'none', marginBottom: '5px' }}
                                >
                                    {getFieldDecorator(ids.city, {
                                        initialValue: oneIDData.city || currentAddressData?.city,
                                        rules: [
                                            {
                                                pattern: Regex.city,
                                                message: intl.formatMessage({
                                                    id: 'error.validation.townCity',
                                                    defaultMessage: 'Please enter a valid Town/City',
                                                }),
                                            },
                                            {
                                                required: true,
                                                message: intl.formatMessage({
                                                    id: 'error.validation.notBlank',
                                                    defaultMessage: 'Please do not leave blank',
                                                }),
                                            },
                                        ],
                                    })(
                                        <Input
                                            size="large"
                                            data-testid="city"
                                            type="text"
                                            placeholder={intl.formatMessage({
                                                id: 'addressAuto.townCity',
                                                defaultMessage: 'Town/City',
                                            })}
                                            addonBefore={
                                                <Popover
                                                    content={intl.formatMessage({
                                                        id: 'addressAuto.townCity',
                                                        defaultMessage: 'Town/City',
                                                    })}
                                                >
                                                    <InfoCircleOutlined />
                                                </Popover>
                                            }
                                            disabled={disabled && oneID.city}
                                        />
                                    )}
                                </Form.Item>
                                {countyItem}
                                <Form.Item
                                    style={{ display: showAllAddressFields ? 'block' : 'none', marginBottom: '5px' }}
                                >
                                    {getFieldDecorator(ids.country, {
                                        initialValue: oneIDData.country || currentAddressData?.country,
                                        rules: [
                                            {
                                                required: true,
                                                type: 'string',
                                                message: intl.formatMessage({
                                                    id: 'error.validation.countryError',
                                                    defaultMessage: 'Select a country!',
                                                }),
                                            },
                                            {
                                                validator: (rule, value, callback) => {
                                                    callback();
                                                },
                                            },
                                        ],
                                    })(
                                        <Select
                                            style={{ textAlign: 'left' }}
                                            size="large"
                                            data-testid="country"
                                            placeholder={intl.formatMessage({
                                                id: 'common.country',
                                                defaultMessage: 'Country',
                                            })}
                                            onChange={this.onCountryChange}
                                            showSearch
                                            filterOption={(input, option) =>
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            disabled={disabled && oneIDData.country}
                                        >
                                            {this.sortedCountries(countries).map((isoCode) => (
                                                <Select.Option
                                                    data-testid={countries[isoCode].name}
                                                    key={isoCode}
                                                    value={isoCode}
                                                >
                                                    {countries[isoCode].name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    )}
                                </Form.Item>
                                <Form.Item hasFeedback style={{ display: showAllAddressFields ? 'block' : 'none' }}>
                                    {getFieldDecorator(ids.postal_code, {
                                        initialValue: oneIDData.postal_code || currentAddressData?.postal_code,
                                        rules: [
                                            {
                                                pattern: country === 'GB' ? Regex.postcodeUK : Regex.postalCode,
                                                message: intl.formatMessage({
                                                    id: 'error.validation.postcodeUK',
                                                    defaultMessage: 'Please provide a valid postcode',
                                                }),
                                            },
                                            {
                                                required: isUkCheck ? country === 'GB' : true,
                                                message: intl.formatMessage({
                                                    id: 'error.validation.notBlank',
                                                    defaultMessage: 'Please do not leave blank',
                                                }),
                                            },
                                        ],
                                    })(
                                        <Input
                                            size="large"
                                            data-testid="postal_code"
                                            type="text"
                                            placeholder={intl.formatMessage({
                                                id: 'addressAuto.postcode',
                                                defaultMessage: 'Postcode',
                                            })}
                                            addonBefore={
                                                <Popover
                                                    content={intl.formatMessage({
                                                        id: 'addressAuto.postcode',
                                                        defaultMessage: 'Postcode',
                                                    })}
                                                >
                                                    <InfoCircleOutlined />
                                                </Popover>
                                            }
                                            disabled={disabled && oneIDData.postal_code}
                                        />
                                    )}
                                </Form.Item>
                                {/* Hidden as the api submission needs it */}
                                <Form.Item
                                    style={{
                                        display: 'none',
                                    }}
                                >
                                    {getFieldDecorator(provinceFieldName)(
                                        <ProvStateSelect size="large" selectedCountry={country} placeholder={null} />
                                    )}
                                </Form.Item>
                            </>
                        ) : (
                            <>
                                {!bypassStreetAddress && (
                                    <Form.Item
                                        hasFeedback
                                        style={{
                                            display: showAllAddressFields ? 'block' : 'none',
                                            marginBottom: '5px',
                                        }}
                                        label={
                                            !hideLabels
                                                ? intl.formatMessage({
                                                      id: 'addressAuto.streetAddress',
                                                      defaultMessage: 'Street Address',
                                                  })
                                                : null
                                        }
                                    >
                                        {getFieldDecorator(ids.address, {
                                            rules: [
                                                {
                                                    pattern: Regex.twoPlusConsecutiveLetters,
                                                    message: intl.formatMessage({
                                                        id: 'error.validation.streetName',
                                                        defaultMessage: 'Please enter a valid street name',
                                                    }),
                                                },
                                                {
                                                    required: required && !bypassStreetAddress,
                                                    message: intl.formatMessage({
                                                        id: 'error.validation.notBlank',
                                                        defaultMessage: 'Please do not leave blank',
                                                    }),
                                                },
                                            ],
                                        })(
                                            <Input
                                                size="large"
                                                data-testid="street_address"
                                                type="text"
                                                placeholder={
                                                    hideLabels
                                                        ? intl.formatMessage({
                                                              id: 'addressAuto.streetAddress',
                                                              defaultMessage: 'Street Address',
                                                          })
                                                        : null
                                                }
                                                addonBefore={
                                                    <Popover
                                                        content={intl.formatMessage({
                                                            id: 'addressAuto.streetAddress',
                                                            defaultMessage: 'Street Address',
                                                        })}
                                                    >
                                                        <InfoCircleOutlined />
                                                    </Popover>
                                                }
                                                disabled={disabled && oneIDData.address}
                                            />
                                        )}
                                    </Form.Item>
                                )}
                                {!bypassUnit && (
                                    <Form.Item
                                        hasFeedback
                                        style={{
                                            display: showAllAddressFields ? 'block' : 'none',
                                            marginBottom: '5px',
                                        }}
                                        label={
                                            !hideLabels
                                                ? intl.formatMessage({ id: 'addressAuto.unit', defaultMessage: 'Unit' })
                                                : null
                                        }
                                    >
                                        {getFieldDecorator(ids.unit, {
                                            initialValue: currentAddress?.unit,
                                        })(
                                            <Input
                                                maxLength={12}
                                                size="large"
                                                autoFocus
                                                data-testid="unit"
                                                placeholder={intl.formatMessage({
                                                    id: 'welcome.Addresses.unitNumberWithExample',
                                                    defaultMessage: "Unit Number/Name (eg: '#305')",
                                                })}
                                                addonBefore={
                                                    <Popover
                                                        content={
                                                            <FormattedMessage
                                                                id="welcome.Addresses.unitNumber"
                                                                defaultMessage="Unit Number"
                                                            />
                                                        }
                                                    >
                                                        <InfoCircleOutlined />
                                                    </Popover>
                                                }
                                                disabled={disabled && oneIDData.unit}
                                            />
                                        )}
                                    </Form.Item>
                                )}
                                <Form.Item
                                    hasFeedback
                                    style={{ display: showAllAddressFields ? 'block' : 'none', marginBottom: '5px' }}
                                    label={
                                        !hideLabels
                                            ? intl.formatMessage({ id: 'common.country', defaultMessage: 'Country' })
                                            : null
                                    }
                                >
                                    {getFieldDecorator(ids.country, {
                                        initialValue: (currentAddress && currentAddress.country) || undefined,
                                        rules: [
                                            {
                                                required,
                                                type: 'string',
                                                message: intl.formatMessage({
                                                    id: 'error.validation.countryError',
                                                    defaultMessage: 'Select a country!',
                                                }),
                                            },
                                            {
                                                validator: (rule, value, callback) => {
                                                    if (!canadaUSOnly || (canadaUSOnly && isUSAOrCanada(value))) {
                                                        callback();
                                                    } else {
                                                        callback(
                                                            intl.formatMessage({
                                                                id: 'error.validation.country',
                                                                defaultMessage:
                                                                    'Country must be Canada or United States',
                                                            })
                                                        );
                                                    }
                                                },
                                            },
                                        ],
                                    })(
                                        <Select
                                            style={{ textAlign: 'left' }}
                                            size="large"
                                            data-testid="country"
                                            placeholder={
                                                hideLabels
                                                    ? intl.formatMessage({
                                                          id: 'common.country',
                                                          defaultMessage: 'Country',
                                                      })
                                                    : null
                                            }
                                            onChange={this.onCountryChange}
                                            showSearch
                                            filterOption={(input, option) =>
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            disabled={disabled && oneIDData.country}
                                        >
                                            {this.sortedCountries(countries).map((isoCode) => (
                                                <Select.Option
                                                    data-testid={countries[isoCode].name}
                                                    key={isoCode}
                                                    value={isoCode}
                                                >
                                                    {countries[isoCode].name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    )}
                                </Form.Item>
                                {countyItem}
                                <Form.Item
                                    hasFeedback
                                    style={{
                                        display: showAllAddressFields ? 'block' : 'none',
                                        marginBottom: '5px',
                                    }}
                                    label={hideLabels ? null : provinceLabel}
                                >
                                    {getFieldDecorator(provinceFieldName, {
                                        rules: [
                                            {
                                                required: everyProvOrStateHasISOCode,
                                                message: intl.formatMessage({
                                                    id: 'error.validation.provinceError',
                                                    defaultMessage: 'Select a province!',
                                                }),
                                            },
                                        ],
                                    })(
                                        <ProvStateSelect
                                            size="large"
                                            selectedCountry={country}
                                            placeholder={hideLabels ? provinceLabel : null}
                                            addonBefore={
                                                <Popover content={provinceLabel}>
                                                    <InfoCircleOutlined />
                                                </Popover>
                                            }
                                            onChange={this.onProvinceStateChange}
                                            disabled={disabled && oneIDData.province_state}
                                        />
                                    )}
                                </Form.Item>
                                <Form.Item
                                    hasFeedback
                                    style={{ display: showAllAddressFields ? 'block' : 'none', marginBottom: '5px' }}
                                    label={
                                        !hideLabels
                                            ? intl.formatMessage({
                                                  id: 'addressAuto.cityMunicipality',
                                                  defaultMessage: 'City / Municipality',
                                              })
                                            : null
                                    }
                                >
                                    {getFieldDecorator(ids.city, {
                                        rules: [
                                            {
                                                pattern: Regex.city,
                                                message: intl.formatMessage({
                                                    id: 'error.validation.city',
                                                    defaultMessage: 'Please enter a valid city',
                                                }),
                                            },
                                            {
                                                required,
                                                message: intl.formatMessage({
                                                    id: 'error.validation.notBlank',
                                                    defaultMessage: 'Please do not leave blank',
                                                }),
                                            },
                                        ],
                                    })(
                                        <Input
                                            size="large"
                                            data-testid="city"
                                            type="text"
                                            placeholder={
                                                hideLabels
                                                    ? intl.formatMessage({
                                                          id: 'addressAuto.cityMunicipality',
                                                          defaultMessage: 'City / Municipality',
                                                      })
                                                    : null
                                            }
                                            addonBefore={
                                                <Popover
                                                    content={intl.formatMessage({
                                                        id: 'addressAuto.cityMunicipality',
                                                        defaultMessage: 'City / Municipality',
                                                    })}
                                                >
                                                    <InfoCircleOutlined />
                                                </Popover>
                                            }
                                            disabled={disabled && oneIDData.city}
                                        />
                                    )}
                                </Form.Item>
                                {!bypassPostal && (
                                    <Form.Item
                                        hasFeedback
                                        style={{ display: showAllAddressFields ? 'block' : 'none' }}
                                        label={
                                            !hideLabels
                                                ? intl.formatMessage({
                                                      id: 'addressAuto.postalZip',
                                                      defaultMessage: 'Postal/Zip Code',
                                                  })
                                                : null
                                        }
                                    >
                                        {getFieldDecorator(ids.postal_code, {
                                            getValueFromEvent: InputTransform.postalCodeJustChars,
                                            rules: [
                                                {
                                                    pattern: Regex.getPostalRegex(country),
                                                    message: intl.formatMessage({
                                                        id: 'error.validation.postalCode',
                                                        defaultMessage:
                                                            'Please provide a valid postal or zip code. Remove any spaces.',
                                                    }),
                                                },
                                                {
                                                    required: required && !bypassPostal,
                                                    message: intl.formatMessage({
                                                        id: 'error.validation.notBlank',
                                                        defaultMessage: 'Please do not leave blank',
                                                    }),
                                                },
                                            ],
                                        })(
                                            <Input
                                                size="large"
                                                data-testid="postal_code"
                                                type="text"
                                                placeholder={
                                                    hideLabels
                                                        ? intl.formatMessage({
                                                              id: 'addressAuto.postalZip',
                                                              defaultMessage: 'Postal/Zip Code',
                                                          })
                                                        : null
                                                }
                                                addonBefore={
                                                    <Popover
                                                        content={intl.formatMessage({
                                                            id: 'addressAuto.postalZip',
                                                            defaultMessage: 'Postal/Zip Code',
                                                        })}
                                                    >
                                                        <InfoCircleOutlined />
                                                    </Popover>
                                                }
                                                disabled={disabled && oneIDData.postal_code}
                                            />
                                        )}
                                    </Form.Item>
                                )}
                            </>
                        )}
                    </FindAddress>
                </div>
            </>
        );
    }
}

AddressAutoComplete.propTypes = propTypes;
AddressAutoComplete.defaultProps = defaultProps;

export default withLDConsumer()(connect(mapStateToProps, mapDispatchToProps)(injectIntl(AddressAutoComplete)));
