import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Auth from 'modules/Auth';

const ApplicantAuthed = ({ ...props }) => {
    if (!Auth.isApplicantAuthenticated()) {
        return <Redirect to="/applicant/signin" />;
    }

    return <Route {...props} />;
};

const Authed = ({ component: Component, pm = false, hr = false, admin = false, ...rest }) => (
    <Route
        {...rest}
        render={(props) => {
            // If not authenticated, reroute to login
            if (!Auth.isUserAuthenticated()) {
                return (
                    <Redirect
                        to={{
                            pathname: `/login`,
                            state: { from: props.location },
                        }}
                    />
                );
            }
            // if under user permission level (applicant) redirect to applicant review page
            // added isApplicantAuthenticated to fix a bug where problems occur when permission level is not set
            if (!Auth.isPermissionLevel('user') && Auth.isApplicantAuthenticated()) {
                return (
                    <Redirect
                        to={{
                            pathname: '/applicant/review',
                            state: { from: props.location },
                        }}
                    />
                );
            }
            // If admin route, check admin-level permission
            // If not admin, route to settings
            // TODO: This isn't perfect, but it works for new since there's only one admin page
            if (admin) {
                if (Auth.isPermissionLevel('admin')) {
                    return <Component {...props} />;
                }
                return (
                    <Redirect
                        to={{
                            pathname: '/settings',
                            state: { from: props.location },
                        }}
                    />
                );
            }
            // If pm or hr route, first check do a basic plan check
            // (basic plan can't access postings, buildings, etc.)
            if (pm || hr) {
                // Check for the matching cases
                if ((pm && Auth.isUser()) || (hr && Auth.isHrUser())) {
                    return <Component {...props} />;
                }
                // but then also route back to applications if you're authed
                // for hr but try to access pm, and vice versa
                if (pm && Auth.isHrUser()) {
                    return (
                        <Redirect
                            to={{
                                pathname: '/hr/applications',
                                state: { from: props.location },
                            }}
                        />
                    );
                }
                if (hr && Auth.isUser()) {
                    return (
                        <Redirect
                            to={{
                                pathname: '/pm/applications',
                                state: { from: props.location },
                            }}
                        />
                    );
                }
            }
            // Else just return component - Regular Authed route
            return <Component {...props} />;
        }}
    />
);

const Admin = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={(props) =>
            Auth.isPermissionLevel('admin') ? (
                <Component {...props} />
            ) : (
                <Redirect
                    to={{
                        pathname: `/ `,
                        state: { from: props.location },
                    }}
                />
            )
        }
    />
);

const CertnRoute = ({ permissionLevel, pm, hr, ...rest }) => {
    let renderRoute = true;

    if (permissionLevel && !Auth.isPermissionLevel(permissionLevel)) {
        renderRoute = false;
    }

    if (pm && !Auth.isUser()) {
        renderRoute = false;
    }

    if (hr && !Auth.isHrUser()) {
        renderRoute = false;
    }

    if (renderRoute) return <Route {...rest} />;
};

const Manager = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={(props) =>
            Auth.isPermissionLevel('manager') ? (
                <Component {...props} />
            ) : (
                <Redirect
                    to={{
                        pathname: `/ `,
                        state: { from: props.location },
                    }}
                />
            )
        }
    />
);

const Private = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={(props) =>
            Auth.isUserAuthenticated() ? (
                <Component {...props} />
            ) : (
                <Redirect
                    to={{
                        pathname: '/login',
                        state: { from: props.location },
                    }}
                />
            )
        }
    />
);

const Pro = ({ component: Component, userMode, ...rest }) => (
    <Route
        {...rest}
        render={(props) => {
            if (Auth.isNotBasic()) {
                return <Component {...props} />;
            }
            return (
                <Redirect
                    to={{
                        pathname: `/ ${userMode} /applications`,
                        state: { from: props.location },
                    }}
                />
            );
        }}
    />
);

const Routes = {
    ApplicantAuthed,
    Authed,
    Private,
    Pro,
    Admin,
    Manager,
    CertnRoute,
};

export default Routes;
