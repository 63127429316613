import { useCurrentUser } from './queryHooks/useCurrentUser';

export const useUserTeam = () => {
    const { user, userLoading, refetchUser } = useCurrentUser();

    return {
        team: user?.team,
        teamLoading: userLoading,
        refetchTeam: refetchUser,
    };
};
