import { requestReducer, responseReducer, defaultState as defaultHttpState } from 'utils/http';
import {
    FETCH_TEAMS_REQ,
    FETCH_TEAMS_RES,
    FETCH_ADJUDICATORS_REQ,
    FETCH_ADJUDICATORS_RES,
    FETCH_TEAM_REQ,
    FETCH_TEAM_RES,
    FETCH_TEAM_MEMBER_REQ,
    FETCH_TEAM_MEMBER_RES,
    SAVE_TEAM_RES,
    GET_REVIEW_STATUS_OPTIONS_RES,
    GET_REVIEW_STATUS_OPTIONS_REQ,
    GET_REVIEW_STATUS_OPTIONS_ERR,
} from './AdminActions';

export const initialState = {
    ...defaultHttpState,
    teams: [],
    teamMembers: [],
    team: {},
    adjudicators: [],
    reviewStatuses: [],
};

const admin = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_TEAMS_REQ:
            return {
                ...state,
                ...requestReducer(),
            };
        case FETCH_TEAMS_RES:
            return {
                ...state,
                ...responseReducer(),
                teams: action.payload.teams,
                teams_count: action.payload.teams_count,
            };
        case FETCH_ADJUDICATORS_REQ:
            return {
                ...state,
                ...requestReducer(),
            };
        case FETCH_ADJUDICATORS_RES:
            return {
                ...state,
                ...responseReducer(),
                adjudicators: action.payload.adjudicators,
            };
        case FETCH_TEAM_REQ:
            return {
                ...state,
                ...requestReducer(),
            };
        case FETCH_TEAM_RES:
            return {
                ...state,
                ...responseReducer(),
                team: action.payload.team,
            };
        case FETCH_TEAM_MEMBER_REQ:
            return {
                ...state,
                ...requestReducer(),
            };
        case FETCH_TEAM_MEMBER_RES:
            return {
                ...state,
                ...responseReducer(),
                teamMember: action.payload.teamMember,
            };
        case SAVE_TEAM_RES:
            return {
                ...state,
                ...responseReducer(),
                team: action.payload.team,
            };

        case GET_REVIEW_STATUS_OPTIONS_RES:
            return {
                ...state,
                ...responseReducer(),
                reviewStatuses: action.payload.reviewStatuses,
            };
        case GET_REVIEW_STATUS_OPTIONS_REQ:
        case GET_REVIEW_STATUS_OPTIONS_ERR:
        default:
            return state;
    }
};

export default admin;
