import React from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import withNavigation from 'views/welcome/modules/WithNavigation';
import { getApplicant } from 'views/welcome/WelcomeSelectors';
import config from 'utils/config';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import { handleDemoSkip } from './actions';
import Title from 'certn-ui/Title';
import { MouseSignatureBox } from './MouseSignatureBox';

type MouseSignatureProps = {
    handleNextTrack: () => void;
};

const MouseSignature: React.FC<MouseSignatureProps> = ({ handleNextTrack }) => {
    const applicantId = useSelector(getApplicant)?.id;
    const signatureImageUrl = localStorage.getItem(`signatureImage_${applicantId.substring(0, 6)}`);

    return (
        <>
            <Title>
                <FormattedMessage id="welcome.mouseSignature.title" defaultMessage="Signature" />
            </Title>
            <FormattedMessage
                id="welcome.mouseSignature.text"
                defaultMessage="A signature is required for background check consent. Please provide your signature using your mouse or touch input before continuing."
            />

            <MouseSignatureBox handleNextTrack={handleNextTrack} applicantId={applicantId} />

            {!signatureImageUrl && config.isNotProduction && (
                <Button
                    name="demo-skip"
                    size="large"
                    type="dashed"
                    onClick={() => handleDemoSkip(applicantId).then(handleNextTrack)}
                >
                    <FormattedMessage id="button.demoSkip" defaultMessage="Demo Skip" />
                </Button>
            )}
        </>
    );
};

export default withRouter(withNavigation(MouseSignature));
