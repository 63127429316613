// Libraries
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// UI Components
import Titlebar from 'certn-ui/Titlebar';

// React Router & Routes
import { withRouter } from 'react-router-dom';

// Actions & Selectors
import { fetchNotifications, setNotificationViewed } from 'base/BaseActions';
import { getNotifications, getIsFetching, getIsFetchingSilent } from 'base/BaseSelectors';
import Notification from 'certn-ui/Notification';
import styled from 'styled-components/macro';
import NotificationSkeleton from './NotificationSkeleton';
import { FormattedMessage } from 'react-intl';

const NotificationListContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
`;

const NoNotifications = styled.div`
    font-size: 20px;
    margin-top: 50px;
`;

const mapStateToProps = (state) => ({
    notifications: getNotifications(state),
    isFetching: getIsFetching(state),
    isFetchingSilent: getIsFetchingSilent(state),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setNotificationViewed,
            fetchNotifications,
        },
        dispatch
    );

class NotificationsList extends React.Component {
    componentDidMount = () => {
        this.props.fetchNotifications(false);
    };

    render() {
        const { notifications, isFetching, isFetchingSilent } = this.props;

        return (
            <div style={{ width: '100%' }}>
                <Titlebar size="lrg" title="All Notifications" />

                {isFetchingSilent && !notifications ? (
                    <NotificationListContainer>
                        <NotificationSkeleton />
                        <NotificationSkeleton />
                        <NotificationSkeleton />
                    </NotificationListContainer>
                ) : (
                    <NotificationListContainer>
                        {!isFetching &&
                            notifications &&
                            notifications.map((notification) => (
                                <Notification
                                    isFetching={isFetchingSilent}
                                    key={notification.id}
                                    notification={notification}
                                    type="list"
                                    setNotificationViewed={(e) => {
                                        e.stopPropagation();
                                        this.props
                                            .setNotificationViewed(notification.id)
                                            .then(this.props.fetchNotifications);
                                    }}
                                />
                            ))}
                        {!isFetching && notifications && notifications.length === 0 && (
                            <NoNotifications>
                                <FormattedMessage
                                    id="dbf56.NotificationsList.none"
                                    defaultMessage="There are currently no notifications."
                                />
                            </NoNotifications>
                        )}
                    </NotificationListContainer>
                )}
            </div>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NotificationsList));
