import { get } from 'lodash';
import { createSelector } from 'reselect';
import { STATEPATH as PARENT_STATEPATH } from 'views/manager/views/hr/HrManagerConstants';

const STATEPATH = `${PARENT_STATEPATH}.applications`;

const getLocalState = (state) => get(state, STATEPATH);

export const getIsFetching = (state) => getLocalState(state).isFetching;
export const getIsFetchingCSV = (state) => getLocalState(state).isFetchingCSV;
export const getIsFetchingNotes = (state) => getLocalState(state).isFetchingNotes;
export const getIsFetchingSilent = (state) => getLocalState(state).isFetchingSilent;
export const getIsFetchingApplications = (state) => getLocalState(state).isFetchingApplications;
export const getError = (state) => getLocalState(state).error;
export const getApplications = (state) => getLocalState(state).applications;
export const getApplication = (state) => getLocalState(state).application;
export const getNewApplication = (state) => getLocalState(state).newApplication;
export const getSentApplication = (state) => getLocalState(state).sentApplication;
export const getReport = (state) => getLocalState(state).report;
// List Selectors
export const getApplicationsView = (state) => getLocalState(state).applicationsView;
export const getSearchBy = (state) => getLocalState(state).searchBy;
export const getOrdering = (state) => getLocalState(state).ordering;
export const getReverseOrder = (state) => getLocalState(state).reverseOrder;
export const getFilterBy = (state) => getLocalState(state).filterBy;
export const getFilterByEnabled = createSelector(getFilterBy, (filters) => {
    Object.keys(filters).forEach((key) => !filters[key] && delete filters[key]);
    return filters;
});
export const getPageSize = (state) => getLocalState(state).pageSize;
export const getFilterQuery = (state) => getLocalState(state).filterQuery;
export const getFilters = (state) => getLocalState(state).filters;
export const getCurrentPage = (state) => getLocalState(state).currentPage;
export const getNumApplications = (state) => getLocalState(state).numApplications;
export const getTotalNumApplications = (state) => getLocalState(state).totalNumApplications;
export const getCsvLink = (state) => getLocalState(state).csvLink;
