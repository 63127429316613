// Libraries
import React from 'react';
import styled from 'styled-components/macro';
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';
import { Button as AntdButton } from 'antd';
import { ButtonProps } from 'antd/lib/button';

type CertnButtonProps = ButtonProps & {
    certncolor?: string;
};
const Button: React.FC<CertnButtonProps> = (props) => <StyledAntdButton {...props} />;
export default Button;

const StyledAntdButton = styled(AntdButton).withConfig({
    // exclude "dualButtons" prop to remove React warning
    shouldForwardProp: (prop) => !['dualButtons'].includes(prop),
})<CertnButtonProps>`
    margin: 3px;
    min-height: 34px;
    background-color: ${(props) => (props.certncolor ? props.theme.color[props.certncolor] : 'inherit')} !important;
    border-color: ${(props) => (props.certncolor ? props.theme.color[props.certncolor] : 'inherit')} !important;
`;

export const ButtonLine = styled.div.attrs({ 'data-testid': 'button_line' })<{
    width?: string;
    dualButtons?: boolean;
    marginOff?: boolean;
}>`
    width: ${(props) => props.width || '100%'};
    display: flex;
    justify-content: ${(props) => (props.dualButtons ? 'space-around' : 'center')};
    margin: ${(props) => (props.dualButtons ? 'auto' : 'initial')};
    align-items: center;
    padding: 24px 6px;
    box-sizing: border-box;
    ${(props) => props.marginOff && 'margin-top: -30px; margin-bottom: -30px'};
    > :first-child {
        ${({ dualButtons }) => dualButtons && 'margin-right: 10px'};
    }
`;

export const TextButton = styled.button`
    display: block;
    background: transparent;
    border: none;
    text-decoration: underline;
    cursor: pointer;
`;

type ButtonPropsWithLabel = ButtonProps & { label: string };
export const BackButton: React.FC<ButtonPropsWithLabel> = (props) => (
    <Button
        certncolor="certnWhite"
        style={{
            marginTop: '20px',
            alignSelf: 'flex-start',
            boxShadow: 'none',
            textTransform: 'uppercase',
        }}
        {...props}
    >
        <CaretLeftOutlined />
        {props.label}
    </Button>
);

export const SubBackButton: React.FC<ButtonPropsWithLabel> = (props) => (
    <Button
        certncolor="certnWhite"
        style={{
            margin: '0px',
            marginBottom: '10px',
            alignSelf: 'flex-start',
            boxShadow: 'none',
        }}
        {...props}
    >
        <CaretLeftOutlined />
        {props.label}
    </Button>
);

export const ForwardButton: React.FC<ButtonPropsWithLabel> = (props) => (
    <Button
        certncolor="certnWhite"
        style={{ marginTop: '20px', alignSelf: 'flex-start', boxShadow: 'none' }}
        {...props}
    >
        {props.label}
        <CaretRightOutlined />
    </Button>
);
