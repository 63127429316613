import styled from 'styled-components';
import { Size } from 'types';

const Image = styled.img<{ margin?: number; size: Size }>`
    display: block;
    max-width: ${(props) => props.theme.image[props.size]};
    max-height: ${(props) => props.theme.image[props.size]};
    margin: ${(props) => (props.margin ? `${props.margin}px` : 0)} auto;
`;
export default Image;
