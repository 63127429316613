// Libraries
import React from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import { Input, Popover, Row, Col } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';

// Components
import { TrackNavigation } from 'views/welcome/components';
import { InternationalPhone } from 'components';
import Title from 'certn-ui/Title';
import { TextLink } from 'certn-ui/Text';
import Message from 'certn-ui/Message';
import { AppearRight } from 'certn-ui/Animate';
import FormLayout from 'certn-form/FormLayout';

// Modules
import { Regex } from 'modules';

// Constants
import { CHECK_REQUEST } from 'base/BaseConstants';

const ReferenceForm = (props) => {
    const {
        form: { getFieldDecorator, validateFields, setFields, getFieldValue },
        handleSubmit,
        currentEmployerIndex,
        employers,
        returnToBase,
        intl,
        settings,
        applicant,
        errorStatus,
        applicantEmail,
        emailReferenceRequired,
        referenceRequired,
    } = props;

    const [phone, setPhone] = React.useState(
        employers?.[currentEmployerIndex]?.employer_reference?.phone_number?.number
    );

    const requests = applicant || settings;
    const currentCompany = employers?.[currentEmployerIndex]?.company_name;

    return (
        <AppearRight>
            <Title>
                {currentCompany ? (
                    <FormattedMessage
                        id="welcome.Employers.referenceTitleMandatory"
                        defaultMessage="Please provide an employer reference for {company}."
                        values={{ company: currentCompany }}
                    />
                ) : (
                    <FormattedMessage
                        id="welcome.Employers.referenceTitle"
                        defaultMessage="Who would you like to use as an employer reference?"
                    />
                )}
            </Title>
            <Form layout="horizontal" onSubmit={(e) => handleSubmit(e, validateFields, setFields, phone)}>
                <FormLayout>
                    <Form.Item key="contact_first_name" hasFeedback>
                        {getFieldDecorator('contact_first_name', {
                            initialValue: employers?.[currentEmployerIndex]?.contact_first_name,
                            rules: [
                                {
                                    pattern: Regex.name,
                                    message: intl.formatMessage({
                                        id: 'error.validation.name',
                                        defaultMessage: 'Please enter a valid name',
                                    }),
                                },
                                {
                                    required: true,
                                    message: intl.formatMessage({
                                        id: 'error.validation.notBlank',
                                        defaultMessage: 'Please do not leave blank',
                                    }),
                                },
                            ],
                        })(
                            <Input
                                autoFocus
                                size="large"
                                data-testid="contact_first_name"
                                placeholder={intl.formatMessage({
                                    id: 'common.firstName',
                                    defaultMessage: 'First Name',
                                })}
                                addonBefore={
                                    <Popover
                                        content={<FormattedMessage id="common.firstName" defaultMessage="First Name" />}
                                    >
                                        <InfoCircleOutlined />
                                    </Popover>
                                }
                            />
                        )}
                    </Form.Item>
                    <Form.Item key="contact_last_name" hasFeedback>
                        {getFieldDecorator('contact_last_name', {
                            initialValue: employers?.[currentEmployerIndex]?.contact_last_name,
                            rules: [
                                {
                                    pattern: Regex.name,
                                    message: intl.formatMessage({
                                        id: 'error.validation.name',
                                        defaultMessage: 'Please enter a valid name',
                                    }),
                                },
                                {
                                    required: true,
                                    message: intl.formatMessage({
                                        id: 'error.validation.notBlank',
                                        defaultMessage: 'Please do not leave blank',
                                    }),
                                },
                            ],
                        })(
                            <Input
                                size="large"
                                data-testid="contact_last_name"
                                placeholder={intl.formatMessage({
                                    id: 'common.lastName',
                                    defaultMessage: 'Last Name',
                                })}
                                addonBefore={
                                    <Popover
                                        content={<FormattedMessage id="common.lastName" defaultMessage="Last Name" />}
                                    >
                                        <InfoCircleOutlined />
                                    </Popover>
                                }
                            />
                        )}
                    </Form.Item>
                    <Form.Item key="contact_email" hasFeedback>
                        {getFieldDecorator('contact_email', {
                            initialValue: employers?.[currentEmployerIndex]?.contact_email,
                            rules: [
                                {
                                    pattern: Regex.email,
                                    message: intl.formatMessage({
                                        id: 'error.validation.email',
                                        defaultMessage: 'Please provide a valid email',
                                    }),
                                },
                                {
                                    required: settings.employer_ref_email_req || emailReferenceRequired,
                                    message: intl.formatMessage({
                                        id: 'error.validation.notBlank',
                                        defaultMessage: 'Please do not leave blank',
                                    }),
                                },
                                {
                                    validator: (rule, value, callback) => {
                                        if (
                                            getFieldValue('can_contact') && // If marked we can contact them
                                            requests[CHECK_REQUEST.EMPLOYER_REFERENCES] && // And we need to email them
                                            !settings.employer_ref_email_req && // And required validation rule is not invoked
                                            !value // And email field is blank
                                        ) {
                                            callback(
                                                intl.formatMessage({
                                                    id: 'error.validation.notBlank',
                                                    defaultMessage: 'Please do not leave blank',
                                                })
                                            );
                                        } else if (value === applicantEmail) {
                                            callback(
                                                intl.formatMessage({
                                                    id: 'error.validation.email',
                                                    defaultMessage: 'Please provide a valid email',
                                                })
                                            );
                                        } else callback();
                                    },
                                },
                            ],
                        })(
                            <Input
                                size="large"
                                data-testid="contact_email"
                                placeholder={intl.formatMessage({
                                    id: 'common.email',
                                    defaultMessage: 'Email',
                                })}
                                addonBefore={
                                    <Popover content={<FormattedMessage id="common.email" defaultMessage="Email" />}>
                                        <InfoCircleOutlined />
                                    </Popover>
                                }
                            />
                        )}
                    </Form.Item>
                    <Form.Item key="contact_position" hasFeedback>
                        {getFieldDecorator('contact_position', {
                            initialValue: employers?.[currentEmployerIndex]?.contact_position,
                            rules: [
                                {
                                    pattern: Regex.alphaNumericExtra,
                                    message: intl.formatMessage({
                                        id: 'error.validation.alphaNumericExtra',
                                        defaultMessage:
                                            'Please use only numbers, letters, spaces, dashes, and underscores',
                                    }),
                                },
                                {
                                    required: true,
                                    message: intl.formatMessage({
                                        id: 'error.validation.notBlank',
                                        defaultMessage: 'Please do not leave blank',
                                    }),
                                },
                            ],
                        })(
                            <Input
                                size="large"
                                data-testid="contact_position"
                                placeholder={intl.formatMessage({
                                    id: 'welcome.Employers.position',
                                    defaultMessage: 'Position / Situation',
                                })}
                                addonBefore={
                                    <Popover
                                        content={
                                            <FormattedMessage id="welcome.Employers.pos" defaultMessage="Position" />
                                        }
                                    >
                                        <InfoCircleOutlined />
                                    </Popover>
                                }
                            />
                        )}
                    </Form.Item>
                    <InternationalPhone
                        key="int-phone"
                        value={phone}
                        errorStatus={errorStatus}
                        onChange={(num) => setPhone(num)}
                    />

                    <Message>
                        <FormattedMessage
                            id="welcome.Employers.supervisorReference"
                            defaultMessage="Please ensure when providing a contact, that the reference is the supervisor or direct superior of your position."
                        />
                    </Message>
                    <TrackNavigation
                        nextButtonTitle={
                            <FormattedMessage id="welcome.Employers.addReference" defaultMessage="Add Reference" />
                        }
                    />

                    {!referenceRequired && (
                        <Row justify="center">
                            <Col>
                                <TextLink onClick={() => returnToBase()}>
                                    <FormattedMessage
                                        id="welcome.Employers.skipReference"
                                        defaultMessage="Skip Reference"
                                    />
                                </TextLink>
                            </Col>
                        </Row>
                    )}
                </FormLayout>
            </Form>
        </AppearRight>
    );
};

export default Form.create()(injectIntl(ReferenceForm));
