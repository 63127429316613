import { ErrorAlertAPI } from 'certn-ui/ErrorAlert';
import { CombinedCheckboxClickwrap } from 'components/Clickwrap/CombinedCheckboxClickwrap';
import { useEffect } from 'react';

type TermsOfUseAndPrivacyPolicyProps = {
    onValid?: () => void;
    onInvalid?: () => void;
    setSigned?: (signed: boolean) => void;
    signerId: string;
    disabled?: boolean;
};

export const TermsOfUseAndPrivacyPolicy: React.FC<TermsOfUseAndPrivacyPolicyProps> = ({
    onValid,
    onInvalid,
    setSigned,
    signerId,
    disabled = false,
}) => {
    const TOU_AND_PRIVACY_POLICY_GROUP_KEY = process.env.REACT_APP_CLICKWRAP_TOU_AND_PRIVACY_POLICY_GROUP_KEY;

    useEffect(() => {
        if (!TOU_AND_PRIVACY_POLICY_GROUP_KEY) {
            ErrorAlertAPI();
            console.error(
                'Unable to initialize signup contract. Missing Clickwrap Group Key for Terms of Use and Privacy Policy.'
            );
        }
    }, [TOU_AND_PRIVACY_POLICY_GROUP_KEY]);

    if (!TOU_AND_PRIVACY_POLICY_GROUP_KEY) {
        return null;
    }

    return (
        <CombinedCheckboxClickwrap
            groupKey={TOU_AND_PRIVACY_POLICY_GROUP_KEY}
            onValid={onValid}
            onInvalid={onInvalid}
            setSigned={setSigned}
            signerId={signerId}
            disabled={disabled}
        />
    );
};
