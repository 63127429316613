import React from 'react';
import styled from 'styled-components/macro';
import { FormattedMessage } from 'react-intl';

import { FilterOutlined } from '@ant-design/icons';
import { Input, Button, Popover, Badge } from 'antd';
import styles from 'styles/styles';

const FilterMenuContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
`;

const Searchbar = ({ icon, value, onSearch, children, filterCount, noFilter = false }) => {
    const filterMenu = <FilterMenuContainer>{children}</FilterMenuContainer>;
    return (
        <SearchbarContainer>
            <Input.Group compact size="large" style={{ display: 'flex' }}>
                <MobileSearch
                    placeholder="Search"
                    defaultValue={value}
                    prefix={<span style={{ color: 'rgba(0,0,0,.25)' }}>{icon}</span>}
                    onSearch={(searchThis) => onSearch(searchThis)}
                    nofilter={noFilter ? 'true' : undefined}
                    size="large"
                />
                {!noFilter && (
                    <Popover content={filterMenu} placement="bottomRight" trigger="click">
                        <MobileButton size="large">
                            <Badge count={filterCount} style={{ backgroundColor: styles.color.certnGreen700 }}>
                                <FilterOutlined style={{ marginRight: '5px' }} />
                                <MobileSpan>
                                    <FormattedMessage id="d0371.Searchbar.Filter" defaultMessage="Filter" />
                                </MobileSpan>
                            </Badge>
                        </MobileButton>
                    </Popover>
                )}
            </Input.Group>
        </SearchbarContainer>
    );
};
export default Searchbar;

const SearchbarContainer = styled.div`
    display: flex;
    width: 100%;
    margin: 10px 0;
    min-height: 40px;
`;

const MobileSearch = styled(Input.Search)`
    width: 100% !important;
    margin-right: ${(props) => (props.nofilter ? '0px' : '10px')} !important;
    @media (max-width: ${(props) => props.theme.width.lrg}) {
        width: ${(props) => (props.nofilter ? '100%' : '80%')} !important;
    }
`;

const MobileButton = styled(Button)`
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    width: 10% !important;
    @media (max-width: ${(props) => props.theme.width.lrg}) {
        width: 20% !important;
    }
`;

const MobileSpan = styled.span`
    display: inline-block;
    @media (max-width: ${(props) => props.theme.width.sm}) {
        display: none;
    }
`;
