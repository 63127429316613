// Libraries
import React, { Dispatch, SetStateAction } from 'react';
import { FormattedMessage } from 'react-intl';
import { intl } from 'components/GlobalProvider';
import { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form';

// Components
import Form from 'certnd/Form';
import Typography from 'certnd/Typography';

const { Heading } = Typography;

interface PropTypes {
    form: WrappedFormUtils<unknown>;
    waitingPeriod?: number;
    setDefaultWaitingPeriod: Dispatch<SetStateAction<number | undefined>>;
}

const WaitingPeriod: React.FC<PropTypes> = ({ form, waitingPeriod, setDefaultWaitingPeriod }) => (
    <>
        <Heading.H3>
            <FormattedMessage id="settings.AdverseAction.WaitingPeriod" defaultMessage="Waiting Period" />
        </Heading.H3>
        <Form.InputNumber
            data-testid="waiting-period"
            form={form}
            fieldName="adverse_action_waiting_period"
            title={intl.formatMessage({
                id: 'settings.AdverseAction.WaitingPeriodTitle',
                defaultMessage: 'Default Adverse Action waiting period (days)',
            })}
            description={intl.formatMessage({
                id: 'settings.AdverseAction.WaitingPeriodDescription',
                defaultMessage:
                    'Customize the number of days to delay sending the Adverse Action letter to an applicant. This waiting period takes place after the Pre-Adverse Action letter has been sent.',
            })}
            options={{ initialValue: waitingPeriod }}
            autoComplete="false"
            min={5}
            max={31}
            bottomPadding="20px"
            onChange={setDefaultWaitingPeriod}
        />
    </>
);

export default WaitingPeriod;
