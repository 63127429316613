import styled from 'styled-components/macro';

export const MobileComponent = (Component) => styled(Component)`
    width: ${({ width }) => width || '50% !important'};
    @media (max-width: ${({ theme }) => theme.width.tablet}) {
        width: 100% !important;
    }
`;

export default MobileComponent;
