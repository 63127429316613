// Libraries
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useSelector } from 'react-redux';

// Components
import { RequiredText } from 'certn-ui';
import { intl } from 'components/GlobalProvider';
import {
    SectionWrapper,
    SectionTopBar,
    SectionBottomPanel,
    BottomPanelRow,
} from 'views/manager/features/ScreenApplicant/styled';
import GooglePlacesAddressInput from 'certn-form/GooglePlacesAddressInput';
import SoftCheckAlert from './SoftCheckAlert';

import { getSelectedCheckKeys } from 'views/manager/features/ScreenApplicant/ScreenApplicantSelectors';
// Constants
import { CHECK_REQUEST } from 'base/BaseConstants';
import { QA_TEST_ID } from 'utils/constants';

function Address({ form }) {
    const { webFeatureRequireCurrentAndPositionPropertyAddressesForQuickscreen } = useFlags();
    const selectedServiceKeys = useSelector(getSelectedCheckKeys);
    // Address component is not required if every service selected is in this array
    const addressExcludedServices = [CHECK_REQUEST.SOQUIJ];

    const excludeAddress = selectedServiceKeys.every((key) => addressExcludedServices.includes(key));
    if (excludeAddress) return null;
    return (
        <SectionWrapper data-testid={QA_TEST_ID.QuickScreenApplicantAddressSectionWrapper}>
            <SectionTopBar>
                <FormattedMessage
                    id="f5ca1.QuickScreen.enterAddress"
                    defaultMessage="Enter Your Candidate's Current Address"
                />
            </SectionTopBar>
            <SectionBottomPanel style={{ paddingBottom: '0px' }}>
                <RequiredText>
                    {intl.formatMessage({
                        id: 'f5ca1.QuickScreen.addressLookup',
                        defaultMessage: 'Address Lookup',
                    })}
                </RequiredText>
                <BottomPanelRow>
                    <div style={{ width: '50%' }}>
                        <GooglePlacesAddressInput
                            form={form}
                            skippable={excludeAddress}
                            hideLabels
                            webFeatureRequireCurrentAndPositionPropertyAddressesForQuickscreen={
                                !!webFeatureRequireCurrentAndPositionPropertyAddressesForQuickscreen
                            }
                            bypassCounty
                        />
                    </div>
                </BottomPanelRow>
                <div style={{ width: '50%', marginBottom: 10 }}>
                    <SoftCheckAlert
                        hasSoftcheck={selectedServiceKeys.includes(CHECK_REQUEST.SOFTCHECK)}
                        country={form.getFieldValue('country')}
                    />
                </div>
            </SectionBottomPanel>
        </SectionWrapper>
    );
}

export default Address;
