import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import constants from 'utils/constants';
import { useFlags } from 'launchdarkly-react-client-sdk';
import config from 'utils/config';

export const useTrackingPageViews = () => {
    const [initialized, setInitialized] = useState(false);

    const { webFeatureEnablePageViewTrackingWithGoogleAnalytics } = useFlags();
    useEffect(() => {
        if (!initialized && webFeatureEnablePageViewTrackingWithGoogleAnalytics) {
            let trackingID = constants.gaTrafficTrackerIds.DEV; // Development Testing - Certn Web App
            if (config.isProduction) {
                trackingID = constants.gaTrafficTrackerIds.PROD; // Certn App
            }
            ReactGA.initialize([
                {
                    trackingId: trackingID,
                },
            ]);
            setInitialized(true);
        }
    }, [initialized, setInitialized, webFeatureEnablePageViewTrackingWithGoogleAnalytics]);
};
