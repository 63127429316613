import { CheckExecution, OrderData } from 'types';
import { calculateCheckExecutionGroupStatus } from 'utils/calculateCheckExecutionGroupStatus';
import { DrawerCardData } from '../types';

export const generateDrawerCards = (
    orderData: OrderData,
    translateCheckStatus: (orderStatus: string | null | undefined) => string | null
): DrawerCardData[] => {
    const uniqueCheckExecutionsName = [...new Set(orderData?.check_executions?.map((check) => check.check_name))];

    return uniqueCheckExecutionsName.map(
        (name): DrawerCardData => {
            const drawerCardChecks: CheckExecution[] = orderData.check_executions.filter(
                (check) => check.check_name === name
            );

            const checkDetails = drawerCardChecks.filter((checkExecution) => checkExecution.check_name === name)[0];
            const title = checkDetails.check_title;
            return {
                name,
                title,
                status: calculateCheckExecutionGroupStatus(drawerCardChecks),
                checks: drawerCardChecks.map((checkExecution) => ({
                    name: checkExecution.execution_info.display_label || '',
                    title: checkExecution.check_title || '',
                    id: checkExecution.id,
                    key: checkExecution.id,
                    status: translateCheckStatus(checkExecution.status) || '',
                })),
            };
        }
    );
};
