// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

// Components
import { Box } from 'certn-ui/Layout';
import Text from 'certn-ui/Text';
import { TrackNavigation, GenderSelect } from 'views/welcome/components';
import { AppearRight } from 'certn-ui/Animate';
import Title from 'certn-ui/Title';
import FormLayout from 'certn-form/FormLayout';

// Modules
import { genderXCheck } from 'modules';

// Selectors
import { getInformation, getApplicant } from 'views/welcome/WelcomeSelectors';

// Constants
import { CHECK_REQUEST } from 'base/BaseConstants';

export const GenderForm = ({ handleSubmit }) => {
    const intl = useIntl();
    const [form] = Form.useForm();
    const information = useSelector(getInformation);
    const applicant = useSelector(getApplicant) || {};

    // RCMP and no International Crim, include gender x as dropdown option
    const includeGenderX = genderXCheck(applicant);

    const generateTitleText = () => {
        if (applicant[CHECK_REQUEST.INTERNATIONAL_CRIMINAL_RECORD_CHECK]) {
            if (applicant[CHECK_REQUEST.CRIMINAL_RECORD_CHECK])
                return intl.formatMessage({
                    id: 'welcome.Gender.genderTitleRCMPwithInternational',
                    defaultMessage:
                        'A background check requires you to specify the sex you were assigned at birth to search database records:',
                });
            return intl.formatMessage({
                id: 'welcome.Gender.genderTitleInternational',
                defaultMessage: 'A background check requires you to specify your sex assigned at birth:',
            });
        }

        if (
            applicant[CHECK_REQUEST.STANDARD_ACIC_CHECK] ||
            applicant[CHECK_REQUEST.VOLUNTEER_ACIC_CHECK] ||
            applicant[CHECK_REQUEST.AUSTRALIAN_NATIONAL_POLICE_CHECK]
        ) {
            return intl.formatMessage({
                id: 'welcome.Gender.genderTitleAustralia',
                defaultMessage: 'A background check requires you to specify your sex in order to search their records:',
            });
        }

        if (applicant[CHECK_REQUEST.UK_BASIC_DBS_CHECK] || applicant[CHECK_REQUEST.UK_BASIC_DS_CHECK]) {
            return intl.formatMessage({
                id: 'welcome.Gender.genderTitleUk',
                defaultMessage: 'A background check requires you to specify your sex:',
            });
        }

        return intl.formatMessage({
            id: 'welcome.Gender.genderTitle',
            defaultMessage:
                'A Canadian Criminal Record Check requires you to specify your sex in order to search their records:',
        });
    };

    return (
        <AppearRight>
            <Title>{generateTitleText()}</Title>
            <FormLayout size="6" center>
                <Form form={form} layout="horizontal" onFinish={handleSubmit}>
                    <GenderSelect includeGenderX={includeGenderX} information={information} />
                    <Box size="xxs">
                        <Text align="center" color="certnGray600">
                            <FormattedMessage
                                id="welcome.Birthplace.helpIdentifyYou"
                                defaultMessage="These fields help to identify you over other people that may share your basic information."
                            />
                        </Text>
                    </Box>
                    <TrackNavigation />
                </Form>
            </FormLayout>
        </AppearRight>
    );
};

GenderForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
};
GenderForm.defaultProps = {};

export default GenderForm;
