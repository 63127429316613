import { createNamespacedFetchActionTypes } from 'utils/autoredux';
import { NAMESPACE as PARENT_NAMESPACE, STATEPATH as PARENT_STATEPATH } from 'views/manager/ManagerConstants';

// Constants
export const NAMESPACE = `${PARENT_NAMESPACE}SETTINGS/`;
export const STATEPATH = `${PARENT_STATEPATH}.settings`;

export const WEBHOOK_SECRET_PLACEHOLDER =
    '*******************************************************************************************************';

// Actions Types
const createFetchActionTypes = createNamespacedFetchActionTypes(NAMESPACE);
export const ActionTypes = {
    GET_WEBHOOK_SECRET: createFetchActionTypes('GET_WEBHOOK_SECRET'),
    PATCH_WEBHOOK_SECRET: createFetchActionTypes('PATCH_WEBHOOK_SECRET'),
};
