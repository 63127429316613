import { FormattedMessage } from 'react-intl';

export const NAMESPACE = 'welcome/';
export const STATEPATH = 'welcome';

export const ADDRESS_SETTINGS = {
    HR: [
        'address_request_international_criminal_record_check',
        'hr_tenancy_years_amount_req',
        'has_canadian_address',
        'each_address_has_street',
        'addresses_complete',
        'has_us_address',
        'address_ref_req',
        'has_mycrc_country_address',
        'other_address_start_dates_after_current_address_start_date',
        'has_current_address',
        'global_address_history_years',
    ],
    PM: [
        'address_references_years',
        'address_references_min_max',
        'address_request_international_criminal_record_check',
        'tenancy_years_amount_req',
        'tenancy_ref_email_req',
        'tenancy_ref_phone_req',
        'each_address_has_street',
        'addresses_complete',
        'has_us_address',
        'address_ref_req',
        'has_mycrc_country_address',
        'other_address_start_dates_after_current_address_start_date',
        'has_current_address',
        'global_address_history_years',
    ],
};

export const EDUCATION_SETTINGS = {
    HR: ['education_verification_has_highest_level', 'education_verification_minimum'],
    PM: [],
};

export const CREDENTIAL_SETTINGS = {
    HR: ['credential_verification_has_highest_level', 'credential_verification_minimum'],
    PM: [],
};

export const EMPLOYER_SETTINGS = {
    HR: [
        'employer_years',
        'employment_verification_can_contact_req',
        'employer_records_amount_req',
        'employer_ref_req',
        'employer_ref_email_req',
        'employer_ref_phone_req',
    ],
    PM: [
        'employer_years',
        'employment_verification_can_contact_req',
        'employer_records_amount_req',
        'employer_ref_req',
        'employer_ref_email_req',
        'employer_ref_phone_req',
    ],
};

export const EMPLOYER_REFERENCE_SETTINGS = {
    HR: ['employer_reference_minimum'],
    PM: ['employer_reference_minimum'],
};

export const PERSONAL_SETTINGS = ['personal_ref_amount_req'];

export const SHARED_SETTINGS = [''];

export const SMB = 'SMB';
export const COUNTRY_USA = 'us';
export const COUNTRY_UK = 'uk';
export const COUNTRY_AUS = 'au';

export const SIGNUP_VALID_TEAM_TYPES = ['HR', 'PM'];
export const SIGNUP_VALID_TYPES = ['SMB'];

export const ONEID_PROVIDERS = {
    JUMIO: 'JUMIO',
    VOUCHED: 'VOUCHED',
    VERIFF: ['ONEID1', 'VERIFF'],
    YOTI: 'YOTI',
    TRUSTMATIC: 'CERTN_ONEID',
};

export const VOUCHED_STAGES = {
    FACE_MATCH: 'face_match',
    ID: 'id',
    CONFIRM: 'confirm',
};

export const VACCINATION_OPTIONS = {
    FULLY_VACCINATED_MORE_THAN_14_DAYS_AGO: 'FULLY_VACCINATED_MORE_THAN_14_DAYS_AGO',
    FULLY_VACCINATED_LESS_THAN_14_DAYS_AGO: 'FULLY_VACCINATED_LESS_THAN_14_DAYS_AGO',
    PARTIALLY_VACCINATED: 'PARTIALLY_VACCINATED',
    NOT_VACCINATED_WITH_INTENT_TO_BE: 'NOT_VACCINATED_WITH_INTENT_TO_BE',
    NOT_VACCINATED_WITH_NO_INTENT_TO_BE: 'NOT_VACCINATED_WITH_NO_INTENT_TO_BE',
    VACCINATION_EXEMPT_DUE_TO_HUMAN_RIGHTS_LEGISLATION: 'VACCINATION_EXEMPT_DUE_TO_HUMAN_RIGHTS_LEGISLATION',
};

export const VACCINATION_READABLE_OPTIONS = {
    FULLY_VACCINATED_MORE_THAN_14_DAYS_AGO: (
        <FormattedMessage
            id="welcome.VaccinationInfo.vaccinatedLong"
            defaultMessage="I am currently fully vaccinated, and I received my last dose more than 14 days before the employment start date stated on my offer."
        />
    ),
    FULLY_VACCINATED_LESS_THAN_14_DAYS_AGO: (
        <FormattedMessage
            id="welcome.VaccinationInfo.vaccinatedShort"
            defaultMessage="I am currently fully vaccinated, and I received my last dose 14 days or less before the employment start date stated on my offer."
        />
    ),
    PARTIALLY_VACCINATED: (
        <FormattedMessage
            id="welcome.VaccinationInfo.vaccinatedPartial"
            defaultMessage="I am currently partially vaccinated, and I have the intention to get the second dose in the near future."
        />
    ),
    NOT_VACCINATED_WITH_INTENT_TO_BE: (
        <FormattedMessage
            id="welcome.VaccinationInfo.notVaccinatedWithIntention"
            defaultMessage="I am not currently vaccinated, but I have the intention to get my first dose in the near future."
        />
    ),
    NOT_VACCINATED_WITH_NO_INTENT_TO_BE: (
        <FormattedMessage
            id="welcome.VaccinationInfo.notVaccinatedWithoutIntention"
            defaultMessage="I am not currently vaccinated, and I have no intention to be."
        />
    ),
    VACCINATION_EXEMPT_DUE_TO_HUMAN_RIGHTS_LEGISLATION: (
        <FormattedMessage
            id="welcome.VaccinationInfo.notVaccinatedExempt"
            defaultMessage="I can not be fully vaccinated based on grounds protected by applicable human rights legislation."
        />
    ),
};
