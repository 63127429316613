import { useTeamsMinimal } from 'hooks/queryHooks/useTeamsMinimal';
import { FormattedMessage } from 'react-intl';
import { StaticResult } from '../AdvancedFilteringLayout';
import { FilterItem, FilterTitle } from '../styled';

type TeamFilterProps = {
    value?: string;
    onChange: (value: string) => void;
};

export const TeamFilter: React.FC<TeamFilterProps> = ({ value, onChange }) => {
    const { teams } = useTeamsMinimal();

    if (!teams) {
        return null;
    }

    return (
        <FilterItem>
            <FilterTitle>
                <FormattedMessage id="225d8.AdvancedFiltering.searchByTeam" defaultMessage="Search by team:" />
            </FilterTitle>

            <StaticResult
                data-testid="advancedFilteringTeam"
                selected={value}
                allowClear
                showSearch
                mode="default"
                handleChange={onChange}
            >
                {teams.map(({ id, internal_name }) => ({ request: id, title: internal_name }))}
            </StaticResult>
        </FilterItem>
    );
};
