import React from 'react';
import styled from 'styled-components/macro';
import { Select, Radio } from 'antd';
import { WarningOutlined } from '@ant-design/icons';

export const SectionWrapper = styled.div`
    display: flex;
    margin: auto;
    margin-top: 40px;
    flex-direction: column;
    border: 1px solid ${({ theme }) => theme.color.certnGray200};
    max-width: 840px;
    width: 100%;
    border-radius: 3px;
`;
export const SectionTopBar = styled.div`
    padding: 20px;
    outline: 1px solid ${({ theme }) => theme.color.certnGray200};
    font-size: ${({ theme }) => theme.font.xs};
`;

export const SectionBottomPanel = styled.div`
    display: flex;
    flex-direction: ${({ row }) => (row ? 'row' : 'column')};
    justify-content: flex-start;
    padding: 20px;
`;

export const FieldToggle = styled.div`
    margin-left: auto;
    @media (min-width: ${(props) => props.theme.width.desktop}) {
        flex: 6;
    }
`;

export const FieldCheckBoxWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
`;

export const FieldCheckBoxMessage = styled.div`
    padding-top: 3px;
    margin-left: 20px;
`;

export const BottomPanelRow = styled.div`
    display: flex;
    flex-direction: row;
    @media (max-width: ${(props) => props.theme.width.desktop}) {
        justify-content: space-between;
    }
`;

export const BottomPanelRowInner = styled.div`
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
`;

export const CustomInputFrame = styled.div`
    width: 50% !important;
    @media (max-width: ${({ theme }) => theme.width.tablet}) {
        width: 100% !important;
    }
`;

export const CustomSelect = styled(Select)`
    width: 50% !important;
    @media (max-width: ${({ theme }) => theme.width.tablet}) {
        width: 100% !important;
    }
`;

export const Instructions = styled.span`
    color: ${({ theme }) => theme.color.certnGray400};
`;

export const BackButtonWrapper = styled.div`
    @media (min-width: ${({ theme }) => theme.width.tablet}) {
        position: absolute;
        top: 75px;
    }
`;

export const CustomRadio = styled(Radio.Group)`
    @media (max-width: ${({ theme }) => theme.width.tablet}) {
        display: flex !important;
        flex-direction: column !important;
    }
`;

export const DisclaimerWrapper = styled.div`
    margin-top: 20px;
`;

const AlertText = styled.div`
    color: ${({ theme }) => theme.color.certnRed500};
`;

export const WarningAlert = ({ children }) => (
    <AlertText>
        <WarningOutlined /> {children}
    </AlertText>
);
