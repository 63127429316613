import { useIntl } from 'react-intl';
import { Form, Input, Popover } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

interface ReasonAndRequestingOrganization {
    isReasonForCheckDisabled: boolean;
    isPlaceOfWorkDisabled: boolean;
}

export const ReasonAndRequestingOrganization: React.FC<ReasonAndRequestingOrganization> = ({
    isReasonForCheckDisabled,
    isPlaceOfWorkDisabled,
}) => {
    const intl = useIntl();

    return (
        <>
            <Form.Item
                name="reason_for_check"
                rules={[
                    {
                        required: true,
                        message: intl.formatMessage({
                            id: '74e6c.AddOptions.notBlank',
                            defaultMessage: 'Please do not leave blank',
                        }),
                    },
                ]}
            >
                <Input
                    disabled={isReasonForCheckDisabled}
                    placeholder={intl.formatMessage({
                        id: '74e6c.AddOptions.acicReasonForCheck',
                        defaultMessage: 'Reason for Check',
                    })}
                    addonBefore={
                        <Popover
                            content={intl.formatMessage({
                                id: '74e6c.AddOptions.acicReasonForCheck',
                                defaultMessage: 'Reason for Check',
                            })}
                        >
                            <InfoCircleOutlined />
                        </Popover>
                    }
                />
            </Form.Item>
            <Form.Item
                name="place_of_work"
                rules={[
                    {
                        required: true,
                        message: intl.formatMessage({
                            id: '74e6c.AddOptions.notBlank',
                            defaultMessage: 'Please do not leave blank',
                        }),
                    },
                ]}
            >
                <Input
                    disabled={isPlaceOfWorkDisabled}
                    placeholder={intl.formatMessage({
                        id: '74e6c.AddOptions.acicRequestingOrganization',
                        defaultMessage: 'Requesting Organization',
                    })}
                    addonBefore={
                        <Popover
                            content={intl.formatMessage({
                                id: '74e6c.AddOptions.acicRequestingOrganization',
                                defaultMessage: 'Requesting Organization',
                            })}
                        >
                            <InfoCircleOutlined />
                        </Popover>
                    }
                />
            </Form.Item>
        </>
    );
};
