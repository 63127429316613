import Constants from 'utils/constants';
import { get } from 'lodash';

// Constants
import { CHECK_REQUEST } from 'base/BaseConstants';

// Map service to result
// request_softcheck = risk_result
export const serviceToResult = {
    [CHECK_REQUEST.EMPLOYER_REFERENCES]: 'reference_result.employer_reference_results',
    [CHECK_REQUEST.EMPLOYER_PHONE_REFERENCES]: 'reference_result.employer_reference_results',
    [CHECK_REQUEST.ADDRESS_REFERENCES]: 'reference_result.address_reference_results',
    [CHECK_REQUEST.ADDRESS_PHONE_REFERENCES]: 'reference_result.address_reference_results',
};

// Check to see if an UNRESPONSIVE result exists - Starting with Reference Result
export function getHasUnresponsiveResult(application) {
    const resultKeys = Object.values(serviceToResult);
    return resultKeys.some((resultKey) => {
        const resultArray = get(application, resultKey) || [];
        return resultArray.some((result) =>
            // Some status' live in the top level of the result object, some live in the response object
            [result.status, result.response?.status].includes(Constants.reportStatus.UNRESPONSIVE)
        );
    });
}

export const canViewReportWithClientAdjudication = (applicant, currentUser, status) => {
    // We want non-adjudicators to see reports in the list view as they are created but we don't want them to click into them
    // because they could report results prematurely and create bias in their hiring flow.

    if (!applicant?.applicant_adjudication) {
        // this is the case for most users (non-adjudicators with no applicant adjudication happening)
        return true;
    }

    // LDFlag webFeatureEnableNewStatusSystem
    if (status === Constants.reportStatus.ACTION_REQUIRED) {
        if (applicant.application.owner.email === currentUser.email) {
            return true;
        }
        return false;
    }

    // LDFlag webFeatureEnableNewStatusSystem
    // a report status that isn't COMPLETE or CLOSED means adjudication either hasn't started yet or is ongoing.

    const recordComplete = !Constants.adjudicationLifeCycleStatus.active.includes(
        applicant?.applicant_adjudication.life_cycle_status
    );

    if (!recordComplete) {
        // record not complete - only adjudicators can view
        // if user is not an adjudicator - return false

        // is_adjudicator is a flag on the user model coming from the api
        return !!currentUser?.is_adjudicator;
    }

    // has adjudication but all checks have return and client has adjudicated - anyone can see the report now
    return true;
};
