// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// Components
import { AustralianLicensesForm } from './components';

// Modules
import withNavigation from 'views/welcome/modules/WithNavigation';

// Actions & Selectors
import { setTrackPageOrder, patchInformation } from 'views/welcome/WelcomeActions';
import { getIsFetchingSilent, getInformation, getOnboardingType } from 'views/welcome/WelcomeSelectors';

const mapStateToProps = (state) => ({
    isFetchingSilent: getIsFetchingSilent(state),
    information: getInformation(state),
    onboardingType: getOnboardingType(state),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            patchInformation,
            setTrackPageOrder,
        },
        dispatch
    );

const propTypes = {
    // Redux Actions:
    patchInformation: PropTypes.func.isRequired,
    setTrackPageOrder: PropTypes.func.isRequired,
    // Redux Store:
    isFetchingSilent: PropTypes.bool.isRequired,
    information: PropTypes.object.isRequired,
    onboardingType: PropTypes.string.isRequired,
};
const defaultProps = {};

class AustralianLicenses extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.setTrackPageOrder(['base']);
    }

    handleSubmit = (e, validateFields) => {
        e.preventDefault();
        const {
            handleNextTrack,
            information: { identity_numbers },
        } = this.props; /* WithNavigation */

        validateFields((err, values) => {
            if (!err) {
                let identityNumbers = [...identity_numbers];

                if (values.has_drivers_license) {
                    let driversLicense = identityNumbers.find(
                        (each) => each.identity_number_type === 'DRIVERS_LICENSE'
                    );
                    if (!driversLicense) {
                        driversLicense = { country: 'AU', identity_number_type: 'DRIVERS_LICENSE' };
                        identityNumbers.push(driversLicense);
                    }
                    driversLicense.issuing_state_province_or_territory = values.drivers_license_state_territory;
                    driversLicense.number = values.drivers_license_number;
                } else {
                    identityNumbers = identityNumbers.filter((each) => each.identity_number_type !== 'DRIVERS_LICENSE');
                }

                if (values.has_firearms_license) {
                    let firearmsLicense = identityNumbers.find(
                        (each) => each.identity_number_type === 'FIREARMS_LICENSE'
                    );
                    if (!firearmsLicense) {
                        firearmsLicense = { country: 'AU', identity_number_type: 'FIREARMS_LICENSE' };
                        identityNumbers.push(firearmsLicense);
                    }
                    firearmsLicense.issuing_state_province_or_territory = values.firearms_license_state_territory;
                    firearmsLicense.number = values.firearms_license_number;
                } else {
                    identityNumbers = identityNumbers.filter(
                        (each) => each.identity_number_type !== 'FIREARMS_LICENSE'
                    );
                }

                this.props.patchInformation({ identity_numbers: identityNumbers }).then(handleNextTrack);
            }
        });
    };

    pages = () => ({
        base: (
            <AustralianLicensesForm
                handleSubmit={this.handleSubmit}
                information={this.props.information}
                isFetchingSilent={this.props.isFetchingSilent}
            />
        ),
    });

    render() {
        return this.pages()[this.props.trackPageLocation]; /* WithNavigation */
    }
}

AustralianLicenses.propTypes = propTypes;
AustralianLicenses.defaultProps = defaultProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withNavigation(AustralianLicenses)));
