import { Card } from 'antd';
import { RightOutlined, CheckOutlined } from '@ant-design/icons';

import styles from 'styles/styles';
import { CardWrapper, CardLeft, IconWrapper, CardRight } from './styled';
import { TextLabel, SubText } from 'certn-ui/Text';
import { ReactNode } from 'react';

type OnboardingStepProps = {
    title: string;
    subText: string;
    completed?: boolean;
    icon: ReactNode;
    onClick?: () => void;
};

export const OnboardingStep: React.FC<OnboardingStepProps> = ({ title, subText, onClick, completed, icon }) => (
    <CardWrapper onClick={onClick} completed={completed}>
        <Card
            bodyStyle={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                padding: '16px',
                justifyContent: 'space-between',
            }}
        >
            <CardLeft>
                {completed ? (
                    <IconWrapper filled>
                        <CheckOutlined
                            style={{
                                fontSize: '16px',
                                color: 'white',
                                backgroundColor: styles.color.certnGreen400,
                            }}
                        />
                    </IconWrapper>
                ) : (
                    <IconWrapper>{icon}</IconWrapper>
                )}
                <div>
                    <TextLabel completed={completed}>{title}</TextLabel>
                    <SubText completed={completed}>{subText}</SubText>
                </div>
            </CardLeft>
            {!completed && (
                <CardRight>
                    <RightOutlined style={{ fontSize: '18px' }} />
                </CardRight>
            )}
        </Card>
    </CardWrapper>
);
