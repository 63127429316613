// Libraries
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';

// React Router & Routes
import { withRouter, Switch, Route, Redirect, Link } from 'react-router-dom';

// UI Components
import Navbar, { NavMain, NavLeft, NavRight } from 'certn-ui/Navbar';
import { NavLogo } from 'certn-ui/NavLogo';
import Layout, { LayoutBody } from 'certn-ui/Layout';
import RouteErrorBoundary from 'certn-ui/RouteErrorBoundary';

// Other Components
import Auth from 'modules/Auth';
import { intl } from 'components/GlobalProvider';
import { LanguageSelect } from 'base/components';

// Views
import Unsubscribed from './views/unsubscribed/Unsubscribed';
import Login from './views/login/Login';
import IntegrationLogin from './views/login/IntegrationLogin';
import ForgotPassword from './views/forgotPassword/ForgotPassword';
import ResetPassword from './views/resetPassword/ResetPassword';
import ApplyRedirect from './views/applyRedirect/ApplyRedirect';
import Browse from './views/browse/Browse';
import Dispute from './views/dispute/Dispute';
import MVR from './views/mvr/MVR';
import OneIDThankYouPage from './views/oneID/views/OneIDThankYouPage';
import Text from 'certn-ui/Text';

import { getTeamLogo, getTeamIsUK, getTeamIsAUS } from 'base/BaseSelectors';

import { Path } from 'modules';
import config from 'utils/config';
import VeriffIframeRenderer from './views/oneID/views/VeriffIframeRenderer';

// Other Files & Components
const mapStateToProps = (state) => ({
    logo: getTeamLogo(state),
    isTeamUK: getTeamIsUK(state),
    isTeamAUS: getTeamIsAUS(state),
});
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

const propTypes = {};
const defaultProps = {};

class Guest extends Component {
    componentDidMount() {
        Auth.setCurrentView('Guest');
    }

    render() {
        const { location, isTeamUK, isTeamAUS } = this.props;
        const isTalentHub = localStorage.getItem('talentHubParams');
        const altTag = Path.getAltTagFromSubdomain();
        const hasNavbar = !['/thank-you', '/one_id'].some((v) => location.pathname.includes(v));

        return (
            <Layout>
                {hasNavbar && (
                    <Navbar fixed data-testid="nav_bar">
                        <NavMain fixed>
                            <NavLeft>
                                <NavLogo
                                    data-testid="nav_logo"
                                    alt={altTag}
                                    src={this.props.logo}
                                    clickable
                                    onClick={() => this.props.history.push('/')}
                                    title={intl.formatMessage({
                                        id: 'common.Logo',
                                        defaultMessage: 'Logo',
                                    })}
                                />
                            </NavLeft>
                            {!isTalentHub && location.pathname !== '/login' && (
                                <NavRight>
                                    <Link to="/login">
                                        <FormattedMessage id="bbf84.Guest.login" defaultMessage="Login" />
                                    </Link>
                                    {location.pathname === '/forgot' && <LanguageSelect />}
                                </NavRight>
                            )}
                            {!isTalentHub && location.pathname === '/login' && (
                                <NavRight>
                                    {(Path.isLimeSubdomain() || config.isNotProduction) && (
                                        <Text align="right" margin="0 10px 0 0">
                                            <a href="https://certnlime.com/" target="_blank" rel="noopener noreferrer">
                                                <FormattedMessage id="common.learnMore" defaultMessage="Learn More" />
                                            </a>
                                        </Text>
                                    )}
                                    {!(isTeamUK || isTeamAUS) && <LanguageSelect />}
                                </NavRight>
                            )}
                            {isTalentHub && (
                                <NavRight>
                                    <Link to="/integrate/talenthub">
                                        <FormattedMessage id="bbf84.Guest.login" defaultMessage="Login" />
                                    </Link>
                                </NavRight>
                            )}
                        </NavMain>
                    </Navbar>
                )}
                <LayoutBody>
                    <RouteErrorBoundary>
                        <Switch>
                            <Route path="/login" component={Login} />
                            <Route exact path="/forgot" component={ForgotPassword} />
                            <Route exact path="/reset_password/:reset_token?" component={ResetPassword} />
                            <Route exact path="/apply/:listing_id?" component={ApplyRedirect} />
                            <Route exact path="/hr/apply/:listing_id?" component={ApplyRedirect} />
                            <Route path="/browse" component={Browse} />
                            <Route path="/dispute/:applicant_id" component={Dispute} />
                            <Route path="/listings/:team_id" component={Browse} />
                            <Route path="/packages/:team_id" component={Browse} />
                            <Route path="/motor-vehicle-records" component={MVR} />
                            <Route path="/unsubscribed" component={Unsubscribed} />
                            <Route path="/integrate/:integration?" component={IntegrationLogin} />
                            <Route path="/thank-you" component={OneIDThankYouPage} />
                            <Route path="/one_id" component={VeriffIframeRenderer} />
                            <Redirect to="/login" />
                        </Switch>
                    </RouteErrorBoundary>
                </LayoutBody>
            </Layout>
        );
    }
}

Guest.propTypes = propTypes;
Guest.defaultProps = defaultProps;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Guest));
