import Cookies from 'js-cookie';
import Auth from 'modules/Auth';
import { Progress } from 'modules';
import { checkApplicantStatus, checkStatus, parseResponse } from 'modules/Requests';
import { LANGUAGE_EN } from './constants';
import config from './config';

/* Constants */
const DEFAULT_VERSION = 'v1';

export const Status = {
    REQ: 'REQ',
    RES: 'RES',
    ERR: 'ERR',
};

export interface RequestOptions {
    method: RequestInit['method'];
    internal?: boolean;
    hr?: boolean;
    version?: 'v1' | 'v2' | string;
    endpoint: string;
    type?: string;
    basicAuth?: boolean;
    bearerAuth?: boolean;
    body?: RequestInit['body'];
    raw?: unknown;
    exactUrl?: boolean;
    applicantLogin?: boolean;
}

/* Functions */
function request<T = unknown>({
    method,
    internal = false,
    hr = false,
    version = DEFAULT_VERSION,
    endpoint,
    type = 'application/json',
    basicAuth = false,
    bearerAuth = false,
    body,
    raw,
    exactUrl = false,
    applicantLogin = false,
    ...rest
}: RequestOptions): Promise<T> {
    Progress.addRequest();

    if (Object.keys(rest).length !== 0) {
        console.error(`Invalid parameter passed to request: ${Object.keys(rest)}`);
    }
    let apiType = 'api';
    if (internal) apiType = 'i';
    if (hr) apiType = 'hr';
    // create url
    let url = `${config.url}/${apiType}/${version}${`/${endpoint}`.replace(/(\/\/)/g, '/')}`;

    if (exactUrl) {
        url = `${config.url}${`${endpoint}`.replace(/(\/\/)/g, '/')}`;
    }

    // create request headers
    const csrftoken = Cookies.get('csrftoken') || '';
    const headers = new Headers();
    const token = applicantLogin ? Auth.getApplicantToken() : Auth.getToken();
    const locale = localStorage.getItem('locale') || LANGUAGE_EN;
    const language = locale.split('-')[0];
    headers.append('Content-Type', type);
    headers.append('X-CSRFToken', csrftoken);
    headers.append('Accept-Language', language);
    headers.append('X-Hostname', window.location.hostname);
    if (basicAuth) {
        headers.append('Authorization', `Basic ${basicAuth}`);
    } else if (bearerAuth) {
        headers.append('Authorization', `Bearer ${bearerAuth}`);
    } else {
        headers.append('Authorization', `Token ${token}`);
    }

    const init: RequestInit = {
        method,
        headers,
        credentials: 'same-origin',
    };

    // add body if necessary
    if (body !== undefined && method !== 'GET' && method !== 'DELETE') init.body = body;

    // Make call
    return new Promise<T>((resolve, reject) => {
        fetch(url, init)
            .then(applicantLogin ? checkApplicantStatus : checkStatus)
            .then(parseResponse)
            .then((response) => {
                Progress.subRequest();
                resolve(response);
            })
            .catch((error) => {
                Progress.subRequest();
                reject(error);
            });
    });
}

/* Request methods */
export const getRequest = <T = unknown>(params: Omit<RequestOptions, 'method'>) =>
    request<T>({ method: 'GET', ...params });

export const postRequest = <T = unknown>(params: Omit<RequestOptions, 'method'>) =>
    request<T>({ method: 'POST', ...params });

export const putRequest = <T = unknown>(params: Omit<RequestOptions, 'method'>) =>
    request<T>({ method: 'PUT', ...params });

export const patchRequest = <T = unknown>(params: Omit<RequestOptions, 'method'>) =>
    request<T>({ method: 'PATCH', ...params });

export const deleteRequest = <T = unknown>(params: Omit<RequestOptions, 'method'>) =>
    request<T>({ method: 'DELETE', ...params });

/* Reducers */
export const defaultState = {
    isFetching: false,
    isFetchingSilent: false,
    error: {},
};

export const silentRequestReducer = () => ({
    isFetching: false,
    isFetchingSilent: true,
    error: {},
});

export const fetchingReducer = (value: string, bool: boolean) => ({
    [`isFetching_${value}`]: bool,
    error: {},
});

export const requestReducer = () => ({
    isFetching: true,
    isFetchingSilent: true,
    error: {},
});

export const responseReducer = () => ({
    isFetching: false,
    isFetchingSilent: false,
    isFetchingApplications: false,
    error: {},
});

export const errorReducer = (error: Error | unknown) => ({
    isFetching: false,
    isFetchingSilent: false,
    error,
});
