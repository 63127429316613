import React from 'react';
import { useIntl } from 'react-intl';
import { ManualAddSelect } from 'certn-ui';
import { Form, Input, Popover } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { FormInstance } from 'antd/lib/form';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { SetInstitutionType, InstitutionType, EducationInstitutionsType } from 'types/institution';

interface PropTypes {
    showInstitutionSelect: boolean;
    form: FormInstance;
    educationInstitutions: EducationInstitutionsType;
    institution?: InstitutionType;
    setInstitution: SetInstitutionType;
    country: string;
    fieldName?: string;
    size?: SizeType;
}

const defaultProps = {
    fieldName: 'institution',
    institution: undefined,
    size: 'large' as SizeType,
};

const InstitutionsDropdown: React.FC<PropTypes> = ({
    showInstitutionSelect,
    form,
    educationInstitutions,
    institution,
    setInstitution,
    country,
    fieldName,
    size,
}: PropTypes) => {
    const intl = useIntl();
    return showInstitutionSelect ? (
        <ManualAddSelect
            form={form}
            size={size}
            listItems={educationInstitutions[country]}
            initialValue={institution?.name}
            notFoundMessage={intl.formatMessage({
                id: 'welcome.Education.EducationDetails.notFoundInstitution',
                defaultMessage: "My institution isn't listed",
            })}
            placeholder={intl.formatMessage({
                id: 'welcome.Education.EducationDetails.institution',
                defaultMessage: 'Institution',
            })}
            setValue={setInstitution}
            decoratorName="institution"
            selectStyles={{ display: 'block', marginBottom: '5px', marginTop: '12px' }}
        />
    ) : (
        <Form.Item
            hasFeedback
            name={fieldName}
            initialValue={institution?.name}
            rules={[
                {
                    required: true,
                    message: intl.formatMessage({
                        id: 'error.validation.notBlank',
                        defaultMessage: 'Please do not leave blank',
                    }),
                },
            ]}
            style={{ paddingTop: 10 }}
        >
            <Input
                size={size}
                data-testid="institution"
                placeholder={intl.formatMessage({
                    id: 'welcome.Education.EducationDetails.institution',
                    defaultMessage: 'Institution',
                })}
                onChange={(e) => setInstitution({ name: e.target.value, value: e.target.value })}
                addonBefore={
                    <Popover
                        content={intl.formatMessage({
                            id: 'welcome.Education.EducationDetails.institution',
                            defaultMessage: 'Institution',
                        })}
                    >
                        <InfoCircleOutlined />
                    </Popover>
                }
            />
        </Form.Item>
    );
};

InstitutionsDropdown.defaultProps = defaultProps;

export default InstitutionsDropdown;
