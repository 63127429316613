import React, { useEffect, useState } from 'react';
import { Input, Form } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormProps } from 'antd/lib/form';

import Title from 'certn-ui/Title';
import { FormLayout } from 'certn-form';
import { AppearRight } from 'certn-ui/Animate';
import { TrackNavigation } from 'views/welcome/components';
import { CountrySelect, getCountryInstitutions, InstitutionsDropdown } from 'components';

import { isUSAOrCanada } from 'modules/Countries';

// Types
import { ValuesType, SetCountryType } from '../types';
import {
    SetEducationInstitutionsType,
    SetInstitutionType,
    InstitutionType,
    EducationInstitutionsType,
} from 'types/institution';

interface PropTypes {
    onFinish: FormProps['onFinish'];
    educationInstitutions: EducationInstitutionsType;
    setEducationInstitutions: SetEducationInstitutionsType;
    setInstitution: SetInstitutionType;
    institution: InstitutionType;
    country: string | undefined | null;
    setCountry: SetCountryType;
    socialMediaDetails: ValuesType;
}

const SocialMediaDetailsForm: React.FC<PropTypes> = ({
    setEducationInstitutions,
    educationInstitutions,
    onFinish,
    setInstitution,
    institution,
    setCountry,
    country,
    socialMediaDetails,
}: PropTypes) => {
    const [form] = Form.useForm();
    const [showInstitutionSelect, setShowInstitutionSelect] = useState(isUSAOrCanada(country));
    const intl = useIntl();

    const handleCountryChange = (newCountry: string) => {
        setCountry(newCountry);
        if (isUSAOrCanada(newCountry)) {
            if (!(newCountry in educationInstitutions)) {
                getCountryInstitutions(setEducationInstitutions, educationInstitutions, newCountry);
            }
            setShowInstitutionSelect(true);
        } else {
            setShowInstitutionSelect(false);
        }
    };

    useEffect(() => {
        if (socialMediaDetails) {
            const {
                instagram_url,
                facebook_url,
                tiktok_url,
                linkedin_url,
                twitter_url,
                reddit_url,
                high_school_name,
            } = socialMediaDetails;

            form.setFieldsValue({
                instagram_url,
                facebook_url,
                tiktok_url,
                linkedin_url,
                twitter_url,
                reddit_url,
                high_school_name,
            });
        }
    }, [socialMediaDetails, form]);

    return (
        <AppearRight>
            <Title color={undefined} padding={undefined}>
                <FormattedMessage id="welcome.SocialMediaDetails.Heading" defaultMessage="Social Media Details" />
            </Title>
            <div style={{ width: 600, textAlign: 'center', margin: '0 auto' }}>
                <FormattedMessage
                    id="welcome.SocialMediaDetails.Description"
                    defaultMessage="Please provide your social media usernames or URLs below. The information you provide will improve accuracy by helping identify you over other people that may share similar basic information."
                />
            </div>
            <Form form={form} layout="horizontal" onFinish={onFinish} style={{ paddingTop: 20 }}>
                <FormLayout>
                    <b style={{ float: 'left', fontSize: 15, paddingBottom: 8 }}>
                        <FormattedMessage
                            id="welcome.SocialMediaDetails.formHeading"
                            defaultMessage="Social Media Details (optional)"
                        />
                    </b>

                    <Form.Item name="instagram_url">
                        <Input
                            placeholder={intl.formatMessage({
                                id: 'welcome.SocialMediaDetails.instagramUrl',
                                defaultMessage: 'Instagram Username or URL',
                            })}
                            size="large"
                        />
                    </Form.Item>
                    <Form.Item name="facebook_url">
                        <Input
                            placeholder={intl.formatMessage({
                                id: 'welcome.SocialMediaDetails.FacebookUrl',
                                defaultMessage: 'Facebook Username or URL',
                            })}
                            size="large"
                        />
                    </Form.Item>
                    <Form.Item name="tiktok_url">
                        <Input
                            placeholder={intl.formatMessage({
                                id: 'welcome.SocialMediaDetails.tiktokUrl',
                                defaultMessage: 'TikTok Username or URL',
                            })}
                            size="large"
                        />
                    </Form.Item>
                    <Form.Item name="linkedin_url">
                        <Input
                            placeholder={intl.formatMessage({
                                id: 'welcome.SocialMediaDetails.linkedinUrl',
                                defaultMessage: 'Linkedin Username or URL',
                            })}
                            size="large"
                        />
                    </Form.Item>
                    <Form.Item name="twitter_url">
                        <Input
                            placeholder={intl.formatMessage({
                                id: 'welcome.SocialMediaDetails.twitterUrl',
                                defaultMessage: 'Twitter Username or URL',
                            })}
                            size="large"
                        />
                    </Form.Item>
                    <Form.Item name="reddit_url">
                        <Input
                            placeholder={intl.formatMessage({
                                id: 'welcome.SocialMediaDetails.redditUrl',
                                defaultMessage: 'Reddit Username or URL',
                            })}
                            size="large"
                        />
                    </Form.Item>

                    <b style={{ float: 'left', fontSize: 15, paddingBottom: 8 }}>
                        <FormattedMessage
                            id="welcome.SocialMediaDetails.highSchoolDetails"
                            defaultMessage="High School Details"
                        />
                    </b>
                    <Form.Item
                        name="high_school_name"
                        rules={[
                            {
                                required: true,
                                message: intl.formatMessage({
                                    id: 'error.validation.notBlank',
                                    defaultMessage: 'Please do not leave blank',
                                }),
                            },
                        ]}
                    >
                        <Input
                            placeholder={intl.formatMessage({
                                id: 'welcome.SocialMediaDetails.highSchoolName',
                                defaultMessage: 'Institution name',
                            })}
                            size="large"
                        />
                    </Form.Item>

                    <b style={{ float: 'left', fontSize: 15, paddingBottom: 8 }}>
                        <FormattedMessage
                            id="welcome.SocialMediaDetails.postSecondaryDetails"
                            defaultMessage="Post Secondary Details (optional)"
                        />
                    </b>

                    <CountrySelect
                        placeholder={
                            <FormattedMessage
                                id="welcome.SocialMediaDetails.countryOfInstitution"
                                defaultMessage="Country of Institution"
                            />
                        }
                        onChange={handleCountryChange}
                        initialValue={country}
                        size="large"
                    />
                    {country && (
                        <InstitutionsDropdown
                            showInstitutionSelect={showInstitutionSelect}
                            form={form}
                            educationInstitutions={educationInstitutions}
                            institution={institution}
                            setInstitution={setInstitution}
                            country={country}
                        />
                    )}
                </FormLayout>
                <TrackNavigation disabled={false} />
            </Form>
        </AppearRight>
    );
};

export default SocialMediaDetailsForm;
