import { useState } from 'react';
import { Button, message, Modal } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import { SectionWrapper } from './styled';
import { intl } from 'components/GlobalProvider';
import { PreviewSectionProps, PreviewResponse } from './types';
import { postRequest } from 'utils/http';

export const PreviewSection = ({ isPreAdverse, applicantId }: PreviewSectionProps) => {
    const [isVisible, setIsVisible] = useState(false);
    const [previewURL, setPreviewURL] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handlePreview = async () => {
        setIsLoading(true);
        try {
            const response: PreviewResponse = await postRequest({
                version: 'v2',
                endpoint: `/adverse-action/${applicantId}/${isPreAdverse ? 'pa' : 'aa'}/final-notice`,
            });
            setPreviewURL(response.final_notice_url);
            setIsVisible(true);
        } catch (error) {
            message.error(
                intl.formatMessage({
                    id: 'AdverseAction.Drawer.PreviewSection.PreviewFailed',
                    defaultMessage: 'Failed to retrieve the adverse action notice',
                })
            );
        }
        setIsLoading(false);
    };

    return (
        <>
            <Modal
                centered
                width="100vw"
                visible={isVisible}
                onCancel={() => setIsVisible(false)}
                bodyStyle={{
                    height: '80vh',
                }}
                style={{ paddingBottom: 0, maxWidth: '830px' }}
                title={
                    isPreAdverse ? (
                        <FormattedMessage
                            id="AdverseAction.Drawer.PreviewSection.ModalPreAdverseTitle"
                            defaultMessage="Pre-Adverse Action Notice Preview"
                        />
                    ) : (
                        <FormattedMessage
                            id="AdverseAction.Drawer.PreviewSection.ModalAdverseTitle"
                            defaultMessage="Adverse Action Notice Preview"
                        />
                    )
                }
                footer={
                    <Button type="primary" onClick={() => setIsVisible(false)}>
                        <FormattedMessage
                            id="AdverseAction.Drawer.PreviewSection.CloseModal"
                            defaultMessage="Close Preview"
                        />
                    </Button>
                }
            >
                <iframe
                    src={`${previewURL}#toolbar=0&navpanes=0&scrollbar=0&pagemode=none`}
                    title="Preview"
                    data-testid="aa-preview-modal"
                    style={{ border: 0, height: '100%', width: '100%' }}
                />
            </Modal>
            <SectionWrapper>
                <Button
                    style={{ borderRadius: '4px', width: '100%' }}
                    size="large"
                    onClick={handlePreview}
                    loading={isLoading}
                >
                    <EyeOutlined style={{ marginRight: '6px' }} />
                    {isPreAdverse ? (
                        <FormattedMessage
                            id="AdverseAction.Drawer.PreviewSection.PreAdversePreview"
                            defaultMessage="Preview Pre-Adverse Action"
                        />
                    ) : (
                        <FormattedMessage
                            id="AdverseAction.Drawer.PreviewSection.AdversePreview"
                            defaultMessage="Preview Adverse Action"
                        />
                    )}
                </Button>
            </SectionWrapper>
        </>
    );
};
