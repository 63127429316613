// Libraries
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import { formatDate } from 'modules/Format';

// Components
import SummaryItem from './SummaryItem';
import SummaryHeader from './SummaryHeader';
import { FlexRow, SummarySection, SummaryContainer, LineBreak } from './SummaryStyles';

const Education = ({ educations, goToTrack }) => (
    <SummaryContainer>
        <SummaryHeader
            title={<FormattedMessage id="welcome.Summary.education" defaultMessage="Education" />}
            onClick={goToTrack}
        />

        <SummarySection>
            {educations.length === 0 && (
                <SummaryItem
                    empty
                    title={
                        <FormattedMessage
                            id="welcome.Summary.noEducationListed"
                            defaultMessage="You have not listed any education"
                        />
                    }
                />
            )}
            {educations.map((education, i) => (
                <React.Fragment key={i}>
                    <FlexRow>
                        <SummaryItem
                            title={<FormattedMessage id="welcome.Summary.institution" defaultMessage="Institution" />}
                            text={education.institution}
                        />
                        <SummaryItem
                            title={<FormattedMessage id="welcome.Summary.degree" defaultMessage="Degree" />}
                            text={education.degree.degree}
                        />
                        <SummaryItem
                            title={<FormattedMessage id="welcome.Summary.startDate" defaultMessage="Start Date" />}
                            text={formatDate(education.start_date)}
                        />
                    </FlexRow>
                    {educations.length > 1 && i + 1 < educations.length && <LineBreak />}
                </React.Fragment>
            ))}
        </SummarySection>
    </SummaryContainer>
);

export default injectIntl(Education);
