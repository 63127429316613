// Libraries
import { get } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { DownloadOutlined } from '@ant-design/icons';

import { getTeams } from 'views/manager/views/admin/AdminSelectors';
import { getTeamIsSMB, getUserInfo } from 'base/BaseSelectors';
import { getVerboseDateFormat } from 'modules/Format';

// UI Components
import { Button, Space } from 'antd';
import List from 'views/guest/views/browse/components/List';

const Row = styled.div`
    align-items: center;
    display: flex;
    @media (max-width: ${({ theme }) => theme.width.tablet}) {
        flex-flow: column nowrap;
    }
`;

const ButtonWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    flex: 1 1 0%;
    justify-content: space-around;
    @media (max-width: ${({ theme }) => theme.width.tablet}) {
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        button:first-of-type {
            margin-bottom: 5px;
        }
        button {
            width: 200px;
        }
    }
`;

const InvoicesList = ({ invoices, disabled }) => {
    const teams = useSelector(getTeams);
    const locale = localStorage.getItem('locale');
    const isSMB = useSelector(getTeamIsSMB);
    const userInfo = useSelector(getUserInfo);
    const applicantMustPrepay = get(userInfo, ['team', 'settings_config', 'applicant_must_prepay']);

    return (
        <List.Container>
            {invoices?.map((invoice, i) => {
                const invoiceTeam = teams.filter((team) => team?.id === invoice?.billing_plan?.team);
                return (
                    <List.Item key={i} noBorder={invoices.length < i + 1} noHighlight>
                        <Row>
                            <div style={{ flex: 1 }}>
                                <Row>
                                    <List.ItemText>
                                        {moment(invoice.issued_at).locale(locale).format(getVerboseDateFormat())}
                                    </List.ItemText>
                                    <List.ItemText light style={{ marginLeft: '10px' }}>
                                        <FormattedMessage id="23f72.InvoicesList.DueBy" defaultMessage="Due By" />{' '}
                                        {moment(invoice.due_at).locale(locale).format(getVerboseDateFormat())}
                                    </List.ItemText>
                                </Row>
                                <List.ItemText light style={{ marginTop: '5px' }}>
                                    {invoiceTeam[0]?.name}
                                </List.ItemText>
                            </div>
                            <div>{isSMB}</div>
                            <div>{applicantMustPrepay}</div>
                            <ButtonWrapper>
                                {!applicantMustPrepay ? (
                                    <Button
                                        onClick={() => {
                                            window.open(invoice.url, '_blank');
                                            return null;
                                        }}
                                    >
                                        <Space>
                                            <DownloadOutlined />
                                            <FormattedMessage
                                                id="23f72.InvoicesList.Download"
                                                defaultMessage="Download Invoice"
                                            />
                                        </Space>
                                    </Button>
                                ) : null}
                                {!isSMB ? (
                                    <Button
                                        type="primary"
                                        disabled={disabled}
                                        onClick={() => {
                                            window.open(invoice.usages_csv_url, '_blank');
                                            return null;
                                        }}
                                    >
                                        <Space>
                                            <DownloadOutlined />
                                            <FormattedMessage
                                                id="23f72.InvoicesList.batchDownload"
                                                defaultMessage="Download Usages CSV"
                                            />
                                        </Space>
                                    </Button>
                                ) : null}
                            </ButtonWrapper>
                        </Row>
                    </List.Item>
                );
            })}
        </List.Container>
    );
};
export default InvoicesList;
