// Libraries
import React from 'react';
import { useSelector } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { get } from 'lodash';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

// Components
import Title from 'certn-ui/Title';
import { AppearRight } from 'certn-ui/Animate';
import { FlexCardRow } from 'certn-ui/Card';
import { TrackNavigation } from 'views/welcome/components';
import Text from 'certn-ui/Text';

// SVG CardRadio Select
import FelonySVG from 'images/svgs/svg-components/onboarding/Felony';
import BankruptcySVG from 'images/svgs/svg-components/onboarding/Bankruptcy';
import RefusalSVG from 'images/svgs/svg-components/onboarding/Refusal';
import EvictedSVG from 'images/svgs/svg-components/onboarding/Evicted';
import { RadioCard } from 'certn-ui/Radio';

// Actions & Selectors
import { getTeamIsMyCRC } from 'views/welcome/WelcomeSelectors';

const MyCRCSubTitleWrapper = styled.div`
    width: 450px;
    margin: auto;
    margin-bottom: 20px;
    @media (max-width: ${({ theme }) => theme.width.tablet}) {
        width: 100%;
    }
`;

function BaseForm(props) {
    const teamIsMyCRC = useSelector(getTeamIsMyCRC);
    const { form, handleSubmit, information, onboardingType, intl } = props;
    return (
        <AppearRight>
            <Title>
                {onboardingType === 'PM' ? (
                    <FormattedMessage
                        id="welcome.History.BaseForm.titlePM"
                        defaultMessage="Now, let's talk about some relevant past events. Do any of the following apply to you?"
                    />
                ) : (
                    <FormattedMessage
                        id="welcome.History.BaseForm.titleHR"
                        defaultMessage="Now, does the following apply to you?"
                    />
                )}
            </Title>
            {teamIsMyCRC && (
                <MyCRCSubTitleWrapper>
                    <Text align="center" color="certnGray600">
                        <FormattedMessage
                            id="welcome.myCRCSubTitle"
                            defaultMessage="List all of the convictions you've had in your lifetime."
                        />
                        <br />
                        <FormattedMessage
                            id="welcome.allConvictionsIncluded"
                            defaultMessage="All declared convictions are included in your report."
                        />
                    </Text>
                </MyCRCSubTitleWrapper>
            )}
            <Form layout="horizontal" onSubmit={(e) => handleSubmit(e, form.validateFields)}>
                <FlexCardRow>
                    <Form.Item>
                        {form.getFieldDecorator('convictions', {
                            valuePropName: 'checked',
                            initialValue:
                                get(information, ['convictions']) && get(information, ['convictions']).length > 0,
                        })(
                            <RadioCard
                                field="convictions"
                                title={intl.formatMessage({
                                    id: 'welcome.History.BaseForm.convictionsTitle',
                                    defaultMessage: 'I have been convicted of a crime',
                                })}
                                // description="I have been convicted of a crime."
                                getField={form.getFieldValue}
                                setField={form.setFieldsValue}
                                SVG={FelonySVG}
                                height="900px"
                            />
                        )}
                    </Form.Item>
                    {onboardingType === 'PM' && (
                        <>
                            <Form.Item>
                                {form.getFieldDecorator('bankruptcies', {
                                    valuePropName: 'checked',
                                    initialValue:
                                        get(information, ['bankruptcies']) &&
                                        get(information, ['bankruptcies']).length > 0,
                                })(
                                    <RadioCard
                                        field="bankruptcies"
                                        title={intl.formatMessage({
                                            id: 'welcome.History.BaseForm.bankruptciesTitle',
                                            defaultMessage: 'I have declared bankruptcy',
                                        })}
                                        // description="I have been convicted of a crime."
                                        getField={form.getFieldValue}
                                        setField={form.setFieldsValue}
                                        SVG={BankruptcySVG}
                                    />
                                )}
                            </Form.Item>
                            <Form.Item>
                                {form.getFieldDecorator('rent_refusals', {
                                    valuePropName: 'checked',
                                    initialValue:
                                        get(information, ['rent_refusals']) &&
                                        get(information, ['rent_refusals']).length > 0,
                                })(
                                    <RadioCard
                                        field="rent_refusals"
                                        title={intl.formatMessage({
                                            id: 'welcome.History.BaseForm.refusalsTitle',
                                            defaultMessage: 'I have refused to pay rent',
                                        })}
                                        // description="I have been convicted of a crime."
                                        getField={form.getFieldValue}
                                        setField={form.setFieldsValue}
                                        SVG={RefusalSVG}
                                    />
                                )}
                            </Form.Item>
                            <Form.Item>
                                {form.getFieldDecorator('evictions', {
                                    valuePropName: 'checked',
                                    initialValue:
                                        get(information, ['evictions']) && get(information, ['evictions']).length > 0,
                                })(
                                    <RadioCard
                                        field="evictions"
                                        title={intl.formatMessage({
                                            id: 'welcome.History.BaseForm.evictionsTitle',
                                            defaultMessage: 'I have been evicted',
                                        })}
                                        // description="I have been convicted of a crime."
                                        getField={form.getFieldValue}
                                        setField={form.setFieldsValue}
                                        SVG={EvictedSVG}
                                    />
                                )}
                            </Form.Item>
                        </>
                    )}
                </FlexCardRow>
                <TrackNavigation />
            </Form>
        </AppearRight>
    );
}

export default Form.create()(BaseForm);
