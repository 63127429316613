import {
    ButtonProps,
    Checks,
    CheckStatus,
    Drawer as CertnUIDrawer,
    DrawerCard,
    DrawerTranslatedText,
    OrderStatus,
} from '@certn/ui';
import { getLaunchDarklyFlags, getSkinColour, getTeam, getTeamIsUKorGB } from 'base/BaseSelectors';
import { useOrderStatus } from 'hooks/queryHooks/useOrderStatus';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch, useLocation, matchPath } from 'react-router-dom';
import { useTranslateOrderStatus } from 'hooks/useTranslateOrderStatus';
import { titleMap } from 'utils/mappers/checkNameToTitleMap';
import { toggleDrawer } from './DrawerActions';
import { getSelectedRecordId, isDrawerOpen } from './DrawerSelectors';
import { CustomButton } from './styles';
import { generateDrawerCards } from './utils/generateDrawerCards';
import { useTranslateCheckStatus } from 'hooks/useTranslateCheckStatus';
import Constants from 'utils/constants';
import Auth from 'modules/Auth';

export const Drawer = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const match = useRouteMatch();
    const location = useLocation();
    const skinColour = useSelector(getSkinColour);
    const team = useSelector(getTeam);
    const teamIsUKorGB = useSelector(getTeamIsUKorGB);

    const isDrawerOpenFlag: boolean = useSelector(isDrawerOpen);
    const selectedRecordId: string = useSelector(getSelectedRecordId);
    const { orderData, orderFetching, removeQuery } = useOrderStatus(selectedRecordId);
    const { translateOrderStatus } = useTranslateOrderStatus();
    const { translateCheckStatus } = useTranslateCheckStatus();
    const { webFeatureAdjudicationAllowPartialAdjudication } = useSelector(getLaunchDarklyFlags);
    const intl = useIntl();

    const translatedText: DrawerTranslatedText = {
        header: intl.formatMessage({ id: 'drawer.applicationDetails', defaultMessage: 'Application details' }),
        fullName: `${orderData?.first_name || ''} ${orderData?.last_name || ''}`,
        email: orderData?.email || '',
        linkText: intl.formatMessage({ id: 'drawer.viewReport', defaultMessage: 'View report' }),
        scrollButtonText: intl.formatMessage({ id: 'drawer.scrollForMore', defaultMessage: 'Scroll for more' }),
        statusBadgeText: {
            of: intl.formatMessage({ id: 'drawer.of', defaultMessage: 'of' }),
            complete: intl.formatMessage({ id: 'status.complete', defaultMessage: 'Complete' }),
            submitted: intl.formatMessage({ id: '07d4b.Reference.titleBarTitle', defaultMessage: 'Submitted' }),
            lastUpdated: intl.formatMessage({ id: 'drawer.lastUpdated', defaultMessage: 'Last updated' }),
            viewReport: intl.formatMessage({ id: '004e5.StatusDropdown.viewReport', defaultMessage: 'View Report' }),
            orderStatus: translateOrderStatus(orderData?.order_status) || '',
            updated: orderData?.modified,
        },
    };

    const handleOnClose = () => {
        dispatch(toggleDrawer(false, undefined));
        removeQuery();
    };

    const handleOnViewReport = () => {
        dispatch(toggleDrawer(false));
        removeQuery();
        history.push(`${match.url}/applications/${orderData?.id}`);
    };

    const isReportPathMatched = matchPath(location.pathname, {
        path: '/hr/applications/:applicantId',
        exact: true,
    });

    const CustomButtonComponent = (props: ButtonProps) => (
        <CustomButton skinColor={skinColour} {...props}>
            {translatedText.scrollButtonText}
        </CustomButton>
    );

    if (orderData && !orderFetching) {
        const drawerCards = generateDrawerCards(orderData, translateCheckStatus);
        const drawerChecks: Checks[] = drawerCards.map((check) => ({
            ...check,
            status: check.status as CheckStatus,
            key: check.name,
        }));

        const getCanViewReport = (): boolean => {
            // Application is complete, allow all users to view
            if (orderData.order_status === Constants.orderStatus.COMPLETE) {
                return true;
            }

            // Only other case where it should be visible is if the user is an adjudicator
            if (Auth.isAdjudicatorUser()) {
                // If they are an adjudicator, check for partial adjudication and check execution statuses
                const checksToIgnore = team?.settings_config?.checks_that_do_not_trigger_partial_adjudication || [];
                const checkStatuses = orderData.check_executions.map((checkExecution) => {
                    if (!checksToIgnore.includes(checkExecution.report_section)) {
                        return checkExecution.status;
                    }
                });
                if (
                    checkStatuses.some((check_status) => {
                        if (check_status) {
                            return Constants.checkExecutionStatus.complete_or_adjudicable.includes(check_status);
                        }
                    }) &&
                    webFeatureAdjudicationAllowPartialAdjudication
                ) {
                    return true;
                }
                // Allow the user to see the report if all checks have returned and they are an adjudicator and the order is
                // waiting on adjudication
                return !!checkStatuses.every((checkStatus) => {
                    if (checkStatus) {
                        return checkStatus in Constants.checkExecutionStatus.complete_or_adjudicable;
                    }
                });
            }
            const isOneCheckWaitingOnAdjudicator = orderData.check_executions.some((checkExecution) =>
                [Constants.checkExecutionStatus.WAITING_ON_ADJUDICATOR].includes(checkExecution.status)
            );

            // if not an adjudicator allow viewing if one check is IN_PROGRESS
            const isOneCheckNotWaitingOnCandidate = orderData.check_executions.some(
                (checkExecution) =>
                    ![Constants.checkExecutionStatus.WAITING_ON_CANDIDATE].includes(checkExecution.status)
            );

            return isOneCheckWaitingOnAdjudicator ? false : isOneCheckNotWaitingOnCandidate;
        };
        const canViewReport = getCanViewReport();

        return (
            <div>
                <CertnUIDrawer
                    isOpen={isDrawerOpenFlag}
                    onClose={handleOnClose}
                    checks={drawerChecks}
                    translatedText={translatedText}
                    isHeaderVisible
                    onViewReport={handleOnViewReport}
                    ScrollForMoreButton={CustomButtonComponent}
                    onStatusBadgeClick={() => {}}
                    orderStatus={orderData.order_status as OrderStatus}
                    showReportButton={!isReportPathMatched && canViewReport}
                >
                    {drawerCards?.map((drawerCard) => (
                        <DrawerCard
                            key={drawerCard.name}
                            detailsList={drawerCard.checks ? drawerCard.checks : []}
                            orderStatus={drawerCard.status as OrderStatus}
                            translatedText={{
                                statusBadgeText: {
                                    of: intl.formatMessage({ id: 'drawer.of', defaultMessage: 'of' }),
                                    complete: intl.formatMessage({
                                        id: 'status.complete',
                                        defaultMessage: 'Complete',
                                    }),
                                    submitted: intl.formatMessage({
                                        id: '07d4b.Reference.titleBarTitle',
                                        defaultMessage: 'Submitted',
                                    }),
                                    orderStatus: translateCheckStatus(drawerCard.status) || '',
                                    checkName: drawerCard.title || titleMap(drawerCard.name, teamIsUKorGB),
                                },
                                showAll: intl.formatMessage({ id: 'drawer.showAll', defaultMessage: 'Show all' }),
                                showLess: intl.formatMessage({
                                    id: 'drawer.showLess',
                                    defaultMessage: 'Show less',
                                }),
                            }}
                            checks={(drawerCard.checks as unknown) as Checks[]}
                        />
                    ))}
                </CertnUIDrawer>
            </div>
        );
    }

    return null;
};
