// Libraries
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { Form as AntdForm } from '@ant-design/compatible';
import { message } from 'antd';
import { get } from 'lodash';
import { intl } from 'components/GlobalProvider';
import { FormattedMessage } from 'react-intl';

// Modules
import Auth from 'modules/Auth';

// Actions & Selectors
import { getSettings, getCurrentTeam } from 'views/manager/views/settings/SettingsSelectors';
import { updateSettings } from 'views/manager/views/settings/SettingsActions';
import { getTeam, getTeamIsHR } from 'views/manager/views/admin/AdminSelectors';

// HR
import { fetchDateRangeReportInfoCSV as fetchDateRangeReportInfoCSV_HR } from 'views/manager/views/hr/views/applications/ApplicationsActions';
// PM
import { fetchDateRangeReportInfoCSV as fetchDateRangeReportInfoCSV_PM } from 'views/manager/views/pm/views/applications/ApplicationsActions';

// UI Components
import { ErrorAlertAPI } from 'certn-ui/ErrorAlert';
import Form from 'certnd/Form';
import Typography from 'certnd/Typography';
import DateRangePicker from 'views/manager/views/settings/components/DateRangePicker';

// Constants
import { CHECK_REQUEST } from 'base/BaseConstants';

const { Heading, Paragraph } = Typography;

const mapStateToProps = (state) => ({
    settings: getSettings(state),
    currentTeam: getCurrentTeam(state),
    team: getTeam(state),
    teamIsHR: getTeamIsHR(state),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            updateSettings,
            fetchDateRangeReportInfoCSV_HR,
            fetchDateRangeReportInfoCSV_PM,
        },
        dispatch
    );

const Report = (props) => {
    const canEdit = Auth.isPermissionLevel('manager');
    const { form, currentTeam, teamIsHR } = props;
    const title = <FormattedMessage id="5a693.Report.CSVDownload" defaultMessage="Report CSV Date Range Download" />;
    const label = 'report';

    const handleButtonClick = (dateRange) => {
        teamIsHR ? props.fetchDateRangeReportInfoCSV_HR(dateRange) : props.fetchDateRangeReportInfoCSV_PM(dateRange);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                props
                    .updateSettings(values, props.team.id)
                    .then(() =>
                        message.success(
                            intl.formatMessage({
                                id: 'common.settingsSavedSuccessfully',
                                defaultMessage: 'Settings saved successfully.',
                            })
                        )
                    )
                    .catch((error) => ErrorAlertAPI(error));
            }
        });
    };
    return (
        <AntdForm onSubmit={handleSubmit}>
            <Heading.H1 data-testid="title">
                <FormattedMessage id="5a693.Report.PageTitle" defaultMessage="Report" />
            </Heading.H1>
            <DateRangePicker title={title} label={label} form={form} handleButtonClick={handleButtonClick} />
            <Form.Block>
                <Heading.H3>
                    <FormattedMessage id="5a693.Report.ReportOptions" defaultMessage="Report Options" />
                </Heading.H3>

                <Form.Checkbox
                    form={props.form}
                    fieldName="hide_detailed_financial"
                    title={intl.formatMessage({ id: '5a693.Report.EquifaxTitle', defaultMessage: 'Equifax Summary' })}
                    description={intl.formatMessage({
                        id: '5a693.Report.EquifaxDescription',
                        defaultMessage:
                            'Display a shortened summary of the Equifax results in the Financial Details report section (hides information below credit grade section)',
                    })}
                    options={{ initialValue: props.currentTeam.hide_detailed_financial }}
                    disabled={!canEdit}
                />

                <Form.Checkbox
                    form={props.form}
                    fieldName="hide_sin_ssn"
                    title={intl.formatMessage({ id: '5a693.Report.SINTitle', defaultMessage: 'Hide SIN/SNN Numbers' })}
                    description={intl.formatMessage({
                        id: '5a693.Report.SINTitle',
                        defaultMessage: 'Hide SIN/SNN Numbers',
                    })}
                    options={{ initialValue: props.currentTeam.hide_sin_ssn }}
                    disabled={!canEdit}
                />

                <Form.Checkbox
                    form={props.form}
                    fieldName="hide_date_of_birth"
                    title={intl.formatMessage({ id: '5a693.Report.HideBirth', defaultMessage: 'Hide Date of Birth' })}
                    description={intl.formatMessage({
                        id: '5a693.Report.HideBirth',
                        defaultMessage: 'Hide Date of Birth',
                    })}
                    options={{ initialValue: props.currentTeam.hide_date_of_birth }}
                    disabled={!canEdit}
                />

                <Form.Checkbox
                    form={props.form}
                    fieldName="whitelabel_report"
                    title={intl.formatMessage({ id: '5a693.Report.LogoTitle', defaultMessage: 'Custom Report Logo' })}
                    description={intl.formatMessage({
                        id: '5a693.Report.LogoDescription',
                        defaultMessage: "Use your team's custom logo in the report.",
                    })}
                    options={{ initialValue: props.currentTeam.whitelabel_report }}
                    disabled={!canEdit}
                />

                <Form.Checkbox
                    form={props.form}
                    fieldName="compact_pdf_report"
                    title={intl.formatMessage({
                        id: '5a693.Report.CompactTitle',
                        defaultMessage: 'Compact PDF Report',
                    })}
                    description={intl.formatMessage({
                        id: '5a693.Report.CompactDescription',
                        defaultMessage: 'Remove Notes section and page breaks from PDF rendered report.',
                    })}
                    options={{ initialValue: props.currentTeam.compact_pdf_report }}
                    disabled={!canEdit}
                />

                <Form.Button disabled={!canEdit}>
                    <FormattedMessage id="common.save" defaultMessage="Save" />
                </Form.Button>
            </Form.Block>

            <Form.Block>
                <Heading.H3>
                    <FormattedMessage id="5a693.Report.ReportUpgrades" defaultMessage="Report Upgrades" />
                </Heading.H3>

                <Paragraph type="secondary">
                    <FormattedMessage
                        id="5a693.Report.ReportParagraph"
                        defaultMessage="By selecting and saving any of the options below, you are specifying which Report Upgrades will be run by default on inbound applications (applications through a link you have posted online). In addition, this specifies which Report Upgrades you wish to be selected by default every time you attempt to upgrade an applicant's report (these can be changed at the time of upgrade)."
                    />
                </Paragraph>

                <Form.Checkbox
                    form={form}
                    fieldName={CHECK_REQUEST.SOFTCHECK}
                    title={intl.formatMessage({
                        id: '5a693.Report.SoftcheckTitle',
                        defaultMessage: 'Always add Softcheck™ to all applications',
                    })}
                    description={intl.formatMessage({
                        id: '5a693.Report.SoftcheckDescription',
                        defaultMessage:
                            'Softcheck™ is a real-time public information search that searches over 110,000 databases from over 240 countries looking for criminal records, court decisions, negative press, social profiles, sex offenders, public biographies, past employment and more.',
                    })}
                    options={{ initialValue: get(currentTeam, [CHECK_REQUEST.SOFTCHECK]) }}
                    disabled={!canEdit}
                />

                <Form.Checkbox
                    form={form}
                    fieldName={CHECK_REQUEST.IDENTITY_VERIFICATION}
                    title={intl.formatMessage({
                        id: '5a693.Report.IdTitle',
                        defaultMessage: 'Always add Identity Verification to all applications',
                    })}
                    description={intl.formatMessage({
                        id: '5a693.Report.IdDescription',
                        defaultMessage:
                            "Certn uses credit data combined with questions from the applicant's credit file to verify the applicant's identity. Identification Verification is required before beginning a Criminal Record Check.",
                    })}
                    options={{ initialValue: get(currentTeam, [CHECK_REQUEST.IDENTITY_VERIFICATION]) }}
                    disabled={!canEdit}
                />

                <Form.Checkbox
                    form={form}
                    fieldName={CHECK_REQUEST.EQUIFAX}
                    title={intl.formatMessage({
                        id: '5a693.Report.RequestEquifaxTitle',
                        defaultMessage: 'Always add Equifax to all applications',
                    })}
                    description={
                        <FormattedMessage
                            id="5a693.Report.RequestEquifaxDescription"
                            defaultMessage="Perform a {type} inquiry through Equifax to view credit accounts (tradelines) and a financial summary of your applicant."
                            values={{ type: teamIsHR ? 'soft' : 'hard' }}
                        />
                    }
                    options={{ initialValue: get(currentTeam, [CHECK_REQUEST.EQUIFAX]) }}
                    disabled={!canEdit}
                />

                <Form.Button disabled={!canEdit}>
                    <FormattedMessage id="common.save" defaultMessage="Save" />
                </Form.Button>
            </Form.Block>
        </AntdForm>
    );
};

export default withRouter(AntdForm.create()(connect(mapStateToProps, mapDispatchToProps)(Report)));
