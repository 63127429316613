import { usePaymentInfo } from 'hooks/queryHooks/onboarding/usePaymentInfo';
import { FormattedMessage } from 'react-intl';
import { UserApplicant } from 'types';
import { Subtitle } from '../styled';
import Text from 'certn-ui/Text';
import { titleMap } from 'utils/mappers/checkNameToTitleMap';
import { formatCurrency } from 'utils/formatter';
import { CHECK_INFO } from 'utils/constants';
import { useSelector } from 'react-redux';
import { getTeamIsUKorGB } from 'base/BaseSelectors';

type CheckInfoProps = {
    applicant: UserApplicant;
};

export const CheckInfo: React.FC<CheckInfoProps> = ({ applicant }) => {
    const { paymentInfo } = usePaymentInfo(applicant.application.applicant?.hr_onboarding_session?.id);
    const teamIsUKorGB = useSelector(getTeamIsUKorGB);

    let checkName = titleMap(CHECK_INFO.CRIMINAL_RECORD_CHECK, teamIsUKorGB);
    if (applicant.request_australian_criminal_intelligence_commission_check) {
        checkName = titleMap(CHECK_INFO.AUSTRALIAN_CRIMINAL_INTELLIGENCE_COMMISSION_CHECK, teamIsUKorGB);
    }
    if (applicant.request_volunteer_acic_check) {
        checkName = titleMap(CHECK_INFO.VOLUNTEER_ACIC_CHECK, teamIsUKorGB);
    }

    return (
        <>
            <Subtitle>
                <FormattedMessage id="applicant.Review.detailsSubtitle1" defaultMessage="Check Information" />
            </Subtitle>
            <Text size="xs">{checkName}</Text>
            <Text size="xs">{`${
                paymentInfo?.total ? formatCurrency(paymentInfo.total, paymentInfo.currency) : '-'
            }`}</Text>
        </>
    );
};
