// Libraries
import React from 'react';
import styled, { keyframes } from 'styled-components/macro';

// Components
import { ReactComponent as Logo } from 'images/svg/logo_n.svg';
import styles from 'styles/styles';

const dashOffset = keyframes`
    0% {
        stroke-width: 1px;
        stroke: ${styles.color.certnWhite};
        fill: ${styles.color.certnWhite};
        stroke-dashoffset: 1000;

    }
    50% {
        stroke: ${styles.color.certnGray600};
        fill: ${styles.color.certnWhite};
        stroke-dashoffset: 800;
    }
    100% {
        stroke: ${styles.color.certnGray600};
        stroke-dashoffset: 0;
        stroke-width: 0;
    }
`;
const moveUp = keyframes`
    from {
        transform: translateY(-50px);
    }
    to {
        transform: translateY(-20px);
    }
`;

const StyledLogo = styled(Logo)`
    height: 80px;
    width: 200px;
    z-index: 100;
    overflow: visible !important;
    animation: ${moveUp} 2s ease forwards;
    g,
    path,
    polygon {
        overflow: visible;
        stroke: ${styles.color.certnGray600};
        stroke-width: 1px;
        stroke-dasharray: 1000;
        stroke-dashoffset: 0;
        animation: ${dashOffset} 2s ease-in forwards;
    }
`;
const LoadingWrapper = styled.div`
    position: absolute;
    background: white;
    z-index: 10000;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const CertnLoader = () => (
    <LoadingWrapper>
        {/* <Logo style={{fill: 'none', width: 200}}/> */}
        <StyledLogo />
    </LoadingWrapper>
);

export default CertnLoader;
