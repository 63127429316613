import React from 'react';
import styled from 'styled-components/macro';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Radio as AntdRadio, Switch } from 'antd';
import Tooltip from 'certn-ui/Tooltip';
import ToggleAnimate from 'certn-ui/ToggleAnimate';
import { intl } from 'components/GlobalProvider';

const Radio = React.forwardRef((props, ref) => <StyledAntdRadio {...props} ref={ref} />);
export default Radio;

const StyledAntdRadio = styled.div`
    width: ${({ width }) => width || '210px'};
    height: ${({ height }) => height || '210px'};
    box-sizing: border-box !important;
    border: 1px solid ${(props) => props.theme.color.certnGray200};
    border-radius: ${(props) => (props.noRadius ? '0px' : '5px')};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    @media (max-width: ${(props) => props.theme.width.sm}) {
        width: 275px;
    }
`;

const ExpandAntdRadio = styled(AntdRadio.Button)`
    padding: 0 !important;
    box-sizing: border-box !important;
    border: 2px solid ${(props) => props.theme.color.certnGray200};
    width: ${({ width }) => width || '210px'};
    height: ${({ height }) => height || '210px'};
    min-height: ${({ height }) => height || '200px'};
    border-radius: ${(props) => (props.noRadius ? '0px' : '5px')};
    ${(props) => props.active && 'filter: drop-shadow(rgba(0, 0, 0, 0.2) 0px 2px 3px) !important'};
    ${(props) => props.active && 'transform: scale(1.02) !important'};
    ${(props) => props.active && 'z-index: 10 !important'};
    ${(props) => props.active && 'border-radius: 5px !important'};
    ${(props) => props.active && 'background-color: rgb(246, 248, 248) !important'};
    transition: border-radius 0s ease-in !important;
    &:hover {
        background-color: rgb(246, 248, 248) !important;
        z-index: 5;
        ${(props) => !props.active && 'filter: drop-shadow(rgba(0, 0, 0, 0.2) 0px 2px 3px) !important'};
    }
    @media (max-width: ${(props) => props.theme.width.sm}) {
        width: ${({ width }) => width || '275px'};
    }
    ${({ margin }) => margin && `margin: ${margin} !important`};
`;

export const VisualRadioBox = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    box-sizing: border-box;
    > svg {
        margin: auto;
    }
`;

export const RadioIconContainer = styled.div`
    width: 100%;
    height: 60%;
    min-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const RadioIcon = styled(LegacyIcon)`
    color: ${(props) => props.theme.color.primary};
    font-size: 24px;
`;

export const RadioTitle = styled.span`
    width: 100%;
    font-size: 16px;
    text-align: center;
    line-height: normal;
    opacity: 0.6;
    color: ${(props) => props.theme.color.certnGray600};
    margin-bottom: 16px;
    ${(props) => !props.screen && 'height: 50px'};
`;

const RadioDescriptionFrame = styled.span`
    font-size: 10.5px;
    color: ${(props) => props.theme.color.certnGray600};
    text-align: ${(props) => (props.screen ? 'center' : 'right')};
    line-height: normal;
    width: 90%;
    opacity: 0.6;
    margin-top: ${(props) => (props.tooltip && !props.screen ? 'auto !important' : '5px')};
`;

export const RadioDescription = (props) => (
    <RadioDescriptionFrame tooltip screen={props.screen}>
        <Tooltip title={props.children} placement="bottom" style={{ fontSize: '1.4rem', marginTop: '1rem' }} />
    </RadioDescriptionFrame>
);

const Checkmark = styled(LegacyIcon)`
    position: absolute;
    top: -3px;
    right: -3px;
`;

export const RadioCard = React.forwardRef(
    (
        {
            SVG,
            field,
            title,
            getField,
            screen,
            checkmark,
            width,
            height,
            margin,
            setField = () => null,
            toggle = true,
            description = null,
        },
        ref
    ) =>
        screen ? (
            <ExpandAntdRadio
                ref={ref}
                margin={margin}
                width={width}
                height={height}
                active={getField(field)}
                onClick={() => (toggle ? setField({ [field]: !getField(field) }) : setField(field))}
                value={field}
            >
                <VisualRadioBox active={getField(field)}>
                    {checkmark && getField(field) && <Checkmark type="check-circle" theme="filled" />}
                    <RadioIconContainer>
                        <ToggleAnimate active={() => getField(field)} SVG={SVG} />
                    </RadioIconContainer>
                    <RadioTitle screen>{title}</RadioTitle>
                    {description && <RadioDescription screen>{description}</RadioDescription>}
                </VisualRadioBox>
            </ExpandAntdRadio>
        ) : (
            <Radio
                ref={ref}
                onClick={() => (toggle ? setField({ [field]: !getField(field) }) : setField(field))}
                value={field}
            >
                {description && <RadioDescription>{description}</RadioDescription>}
                <RadioIconContainer>
                    <ToggleAnimate active={() => getField(field)} SVG={SVG} />
                </RadioIconContainer>
                <RadioTitle>{title}</RadioTitle>
                <Switch
                    checked={getField(field)}
                    checkedChildren={intl.formatMessage({
                        id: 'welcome.general.yes',
                        defaultMessage: 'Yes',
                    })}
                    unCheckedChildren={intl.formatMessage({
                        id: 'welcome.general.no',
                        defaultMessage: 'No',
                    })}
                />
            </Radio>
        )
);
