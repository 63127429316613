import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { intl } from 'components/GlobalProvider';

// Actions & Selectors
import { getUserMode } from 'base/BaseSelectors';
import { getScreenApplicantButtonsDisabled } from 'views/manager/features/ScreenApplicant/ScreenApplicantSelectors';
import { additionalOptionsConflictCheck } from 'views/manager/features/ScreenApplicant/ScreenApplicantActions';

const ButtonContinue = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const userMode = useSelector(getUserMode);
    const disabled = useSelector(getScreenApplicantButtonsDisabled);

    const handleOnClick = () => {
        const readyToSubmit = dispatch(additionalOptionsConflictCheck);
        if (readyToSubmit) history.push(`/${userMode?.toLowerCase()}/screen/applicant`);
    };

    return (
        <Button
            disabled={disabled}
            type="primary"
            style={{ height: 40 }}
            onClick={handleOnClick}
            data-testid="continue"
        >
            {intl.formatMessage({ id: 'a0e86.ScreenApplicant.continueButton', defaultMessage: 'CONTINUE' })}
        </Button>
    );
};

export default ButtonContinue;
