import styled from 'styled-components/macro';
import { Form } from '@ant-design/compatible';
import { Checkbox } from 'antd';
import styles from 'styles/styles';
import { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form';
import Typography from 'certnd/Typography';
import { CheckboxProps } from 'antd/lib/checkbox';

const { Heading, Text } = Typography;

const FormCheckboxWrapper = styled.div<{ hideBorder?: boolean; hideBorderOnly?: boolean }>`
    display: flex;
    flex-direction: row;
    border-bottom: ${(props) =>
        props.hideBorder || props.hideBorderOnly ? 'none' : `solid ${styles.color.certnGray400} 1px`};
    width: 100%;
    margin-top: -5px;
    margin-bottom: ${(props) => (props.hideBorder || props.hideBorderOnly ? 'none' : '20px')};

    & > *:not(:last-child) {
        margin-right: 10px;
    }
`;

const FormCheckboxContent = styled.div<{ hideBorder?: boolean }>`
    display: block;
    padding-top: 8px;
    margin-bottom: ${(props) => (props.hideBorder ? 'none' : '20px')};
`;

interface PropTypes extends CheckboxProps {
    hideBorder?: boolean;
    title?: string;
    description?: string;
    form?: WrappedFormUtils<unknown>;
    fieldName?: string | number;
    options?: Record<string, unknown>;
    children?: React.ReactNode;
    hideBorderOnly?: boolean;
}

const FormCheckbox: React.FC<PropTypes> = ({
    hideBorder,
    title,
    description,
    form,
    fieldName,
    options,
    children,
    hideBorderOnly,
    ...rest
}) => (
    <FormCheckboxWrapper hideBorder={hideBorder} hideBorderOnly={hideBorderOnly}>
        <Form.Item style={{ marginBottom: '12px' }}>
            {/* If this is a form-connected field (otherwise often used with onChange) */}
            {!!form && !!fieldName ? (
                form.getFieldDecorator(fieldName, {
                    valuePropName: 'checked',
                    ...options,
                })(<Checkbox {...rest}>{children}</Checkbox>)
            ) : (
                <Checkbox {...rest}>{children}</Checkbox>
            )}
        </Form.Item>
        <FormCheckboxContent hideBorder={hideBorder}>
            {title ? <Heading.H4 disableGutter>{title}</Heading.H4> : null}
            {description ? <Text.Secondary>{description}</Text.Secondary> : null}
        </FormCheckboxContent>
    </FormCheckboxWrapper>
);

export default FormCheckbox;
