import { CHECK_REQUEST, QUICK_SCREEN_CHECKS_WITH_ADDITIONS_HR } from 'base/BaseConstants';
import { CheckProps } from 'types';

export const canAddCheck = (checks: string[], newCheck: string) => {
    if (checks.includes(CHECK_REQUEST.EMPLOYER_REFERENCES) && newCheck === CHECK_REQUEST.EMPLOYER_PHONE_REFERENCES) {
        return false;
    }
    if (checks.includes(CHECK_REQUEST.EMPLOYER_PHONE_REFERENCES) && newCheck === CHECK_REQUEST.EMPLOYER_REFERENCES) {
        return false;
    }
    if (
        checks.includes(CHECK_REQUEST.CRIMINAL_RECORD_CHECK) &&
        [CHECK_REQUEST.ENHANCED_CRIMINAL_RECORD_CHECK, CHECK_REQUEST.VULNERABLE_SECTOR_CRIMINAL_RECORD_CHECK].includes(
            newCheck
        )
    ) {
        return false;
    }
    if (
        checks.includes(CHECK_REQUEST.ENHANCED_CRIMINAL_RECORD_CHECK) &&
        [CHECK_REQUEST.CRIMINAL_RECORD_CHECK, CHECK_REQUEST.VULNERABLE_SECTOR_CRIMINAL_RECORD_CHECK].includes(newCheck)
    ) {
        return false;
    }
    if (
        checks.includes(CHECK_REQUEST.VULNERABLE_SECTOR_CRIMINAL_RECORD_CHECK) &&
        [CHECK_REQUEST.CRIMINAL_RECORD_CHECK, CHECK_REQUEST.ENHANCED_CRIMINAL_RECORD_CHECK].includes(newCheck)
    ) {
        return false;
    }
    if (
        checks.includes(CHECK_REQUEST.US_CRIMINAL_RECORD_CHECK_TIER_1) &&
        [CHECK_REQUEST.US_CRIMINAL_RECORD_CHECK_TIER_2, CHECK_REQUEST.US_CRIMINAL_RECORD_CHECK_TIER_3].includes(
            newCheck
        )
    ) {
        return false;
    }
    if (
        checks.includes(CHECK_REQUEST.US_CRIMINAL_RECORD_CHECK_TIER_2) &&
        [CHECK_REQUEST.US_CRIMINAL_RECORD_CHECK_TIER_1, CHECK_REQUEST.US_CRIMINAL_RECORD_CHECK_TIER_3].includes(
            newCheck
        )
    ) {
        return false;
    }
    if (
        checks.includes(CHECK_REQUEST.US_CRIMINAL_RECORD_CHECK_TIER_3) &&
        [CHECK_REQUEST.US_CRIMINAL_RECORD_CHECK_TIER_1, CHECK_REQUEST.US_CRIMINAL_RECORD_CHECK_TIER_2].includes(
            newCheck
        )
    ) {
        return false;
    }
    return true;
};

export const getInvalidCheck = (selectedValues: string[] = [], serviceCfg: CheckProps[] = []) => {
    let invalidCheck = null;
    selectedValues.every((value) => {
        const dependentFlagsList =
            serviceCfg.find((service) => service.request_flag === value)?.selection_dependent_on_request_flags || [];
        if (dependentFlagsList.length) {
            if (checkIfOptionIsMissingDependencies(dependentFlagsList, selectedValues)) {
                invalidCheck = value;
                return false;
            }
            return true;
        }
        return true;
    });
    return invalidCheck;
};

export const checkIfOptionIsMissingDependencies = (flags: string[] | string[][], options: string[]): boolean =>
    (Array.isArray(flags[0]) && !flags[0].some((flag: string) => options.includes(flag))) ||
    (!Array.isArray(flags[0]) &&
        !flags.some((flag: string | string[]) => typeof flag === 'string' && options.includes(flag)));

export const canQuickscreenChecks = (checks: string[]) =>
    checks.every((check) => QUICK_SCREEN_CHECKS_WITH_ADDITIONS_HR.includes(check));
