import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Menu, message } from 'antd';
import { intl } from 'components/GlobalProvider';
import copy from 'copy-to-clipboard';

import { ListCellText } from 'certn-ui/List';

const ItemCopyLink = ({ onboardingLink, ...rest }) => {
    const copyLinkToClipboard = () =>
        copy(onboardingLink)
            ? message.success(
                  intl.formatMessage({
                      id: '004e5.StatusDropdown.applicationLinkCopied',
                      defaultMessage: 'Application link was copied to clipboard.',
                  })
              )
            : message.error(
                  intl.formatMessage({
                      id: '004e5.StatusDropdown.applicationLinkNotCopied',
                      defaultMessage: 'Application link could not be copied.',
                  })
              );

    return (
        <Menu.Item {...rest}>
            <ListCellText clickable onClick={copyLinkToClipboard}>
                <FormattedMessage
                    id="004e5.StatusDropdown.copyApplicationLink"
                    defaultMessage="Copy Application Link"
                />
            </ListCellText>
        </Menu.Item>
    );
};

ItemCopyLink.propTypes = {
    onboardingLink: PropTypes.string.isRequired,
};

export default ItemCopyLink;
