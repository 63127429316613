import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

const titleMap = {
    PROSPECT: <FormattedMessage id="004e5.StatusDropdown.prospects" defaultMessage="Prospects" />,
    OFFER_PENDING: <FormattedMessage id="004e5.StatusDropdown.employmentPending" defaultMessage="Employment Pending" />,
    OFFER_ACCEPTED: <FormattedMessage id="004e5.StatusDropdown.employees" defaultMessage="Employees" />,
    ARCHIVED: <FormattedMessage id="common.archive" defaultMessage="Archive" />,
};

const Titles = ({ status }) => titleMap[status] || null;

Titles.propTypes = {
    status: PropTypes.string.isRequired,
};

export default Titles;
