import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

class Navigation extends Component {
    render() {
        return (
            <div>
                <FormattedMessage id="e8f82.Navigation.nav" defaultMessage="Navbar" />
            </div>
        );
    }
}

export default Navigation;
