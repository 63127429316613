import { getRequest, postRequest } from 'utils/http';
import { simpleStringifyJSON } from 'modules/Format';

export const validateEmailAddressForSecureSMS = (
    email: string,
    phone: string,
    onboardingUrlParameters: Record<string, unknown>
): Promise<void | Record<string, unknown>> =>
    postRequest({
        internal: true,
        endpoint: `/onboarding/secure_sms/validate_email_address/`,
        body: JSON.stringify(
            simpleStringifyJSON({
                email,
                phone,
                onboarding_session: onboardingUrlParameters.session,
            })
        ),
    });

export const verifyInviteRoute = (
    onboardingUrlParameters: Record<string, unknown>
): Promise<void | Record<string, unknown>> =>
    postRequest({
        internal: true,
        endpoint: `/onboarding/validate_route_code/`,
        body: JSON.stringify(
            simpleStringifyJSON({
                route_code: onboardingUrlParameters.inviteCode,
                declared_route: onboardingUrlParameters.inviteRoute,
                onboarding_session: onboardingUrlParameters.session,
            })
        ),
    });

export const getRedactedContactInfo = (onboarding_session: string): Promise<void | Record<string, string>> =>
    getRequest({
        internal: true,
        endpoint: `/onboarding/secure_sms/${onboarding_session}/get_redacted_contact_info/`,
    });
