import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter, Switch, Redirect, Route, useLocation, useHistory } from 'react-router-dom';
import { intl } from 'components/GlobalProvider';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { Modal } from 'antd';
import { useIdleTimer } from 'react-idle-timer';

// Libraries and Modules
import { get } from 'lodash';
import Auth from 'modules/Auth';

// Views
import { HrManager, PropertyManager, Admin, Notifications } from './views';
import Settings from './views/settings/Settings';

// Components
import Layout from 'certn-ui/Layout';
import Loader from 'certn-ui/Loader';
import CertnLoader from 'certn-ui/CertnLoader';
import RouteErrorBoundary from 'certn-ui/RouteErrorBoundary';
import { Navigation, FirstLoginModal, Wrapper } from './components';
import { ErrorAlertNoTranslate } from 'certn-ui/ErrorAlert';
import AdverseActionDrawer from 'views/manager/features/AdverseActionDrawer/Drawer';
import SessionTimeoutModal from './components/SessionTimeoutModal';

// Modules & Utils
import { BrowserNotSupported } from 'modules/';
import usePrevHook from 'utils/hooks/usePrevHook';

// Actions
import { setUserMode, fetchUserInfo, fetchPotatoChecksTitles } from 'base/BaseActions';
import { getUser } from 'base/BaseSelectors';
import { fetchAdjudicators, fetchReviewStatuses, fetchTeams } from 'views/manager/views/admin/AdminActions';
import { setFilters } from 'views/manager/sharedActions/ApplicationActions';
import { fetchApplications as fetchApplicationsHR } from 'views/manager/views/hr/views/applications/ApplicationsActions';
import { fetchApplications as fetchApplicationsPM } from 'views/manager/views/pm/views/applications/ApplicationsActions';
import { Drawer } from './features';
import { getSelectedRecordId } from './features/Drawer/DrawerSelectors';
import Partner from './views/Partner/Partner';

const Manager = () => {
    const dispatch = useDispatch();
    const user = useSelector(getUser);
    const location = useLocation();
    const history = useHistory();
    const prevUser = usePrevHook({ user });
    const [showLogo, setShowLogo] = useState(true);
    const [showTimeoutModal, setShowTimeoutModal] = useState(false);
    const sessionTimeoutPeriod = user?.team?.superteam?.session_timeout_period;
    const ldClient = useLDClient();
    const {
        webFeatureEnableNewStatusSystem,
        webFeatureEnableAdverseAction,
        webFeatureEnableSessionTimeoutForUsers,
    } = useFlags();
    const selectedRecordId = useSelector(getSelectedRecordId);

    const isHR = location?.pathname?.includes('/hr/applications');
    const isPM = location?.pathname?.includes('/pm/applications');

    // User Activity Session Time Out
    const closeModalAndLogout = () => {
        setShowTimeoutModal(false);
        Modal.destroyAll();
        history.push(`/logout`, { from: location, sessionTimeout: !(getRemainingTime() > 0) });
    };

    const keepSessionAlive = () => {
        setShowTimeoutModal(false);
        Modal.destroyAll();
        activate();
    };

    const onPrompt = () => {
        if (!webFeatureEnableSessionTimeoutForUsers) return;
        setShowTimeoutModal(true);
    };

    const onIdle = () => {
        if (!webFeatureEnableSessionTimeoutForUsers) return;
        closeModalAndLogout();
    };

    const { getRemainingTime, activate } = useIdleTimer({
        timeout: sessionTimeoutPeriod ? sessionTimeoutPeriod * 60000 /* convert min to milliseconds */ : 900000, // 15 min default
        promptBeforeIdle: 30000, // 30 seconds
        onPrompt,
        onIdle,
        throttle: 500,
        crossTab: true,
    });

    // function to fetch HR and PM applications
    // this is used on page initialization prior to the loading animation
    // and again on all subsequent visits to /HR|PM/applications
    // the second pass we skip setting the team filter
    const fetchApplications = React.useCallback(async () => {
        if (isHR) await dispatch(fetchApplicationsHR(1));
        if (isPM) await dispatch(fetchApplicationsPM(1));
    }, [dispatch, isHR, isPM]);

    // used to fetch user data, set the team id, fetch appropriate applications
    // and on subsequent page visits only fetch applications
    useEffect(() => {
        const initializeApplicationList = async () => {
            await dispatch(fetchUserInfo()).then(async (response) => {
                // fetch applications which utilizes the team filter
                await dispatch(
                    setFilters({
                        filters: { team: response?.team?.id },
                        query: `&application__team__id=${response?.team?.id}`,
                    })
                );
                await fetchApplications();
            });
        };

        initializeApplicationList();
    }, [dispatch, fetchApplications]);

    // On mount
    useEffect(() => {
        const determineIfHrAndSetUserMode = () => {
            let currentUserMode = 'HR';
            if (!Auth.isHrUser() && Auth.isUser()) currentUserMode = 'PM';
            dispatch(setUserMode(currentUserMode));
        };

        determineIfHrAndSetUserMode();

        if (Auth.isAdjudicatorUser()) {
            dispatch(fetchAdjudicators());
            dispatch(fetchReviewStatuses());
        }

        dispatch(fetchPotatoChecksTitles());

        if (BrowserNotSupported)
            ErrorAlertNoTranslate({
                title: intl.formatMessage({
                    id: '1d2c3.Manager.errorOutdatedBrowserTitle',
                    defaultMessage: 'Outdated browser detected',
                }),
                description: intl.formatMessage({
                    id: '1d2c3.Manager.errorOutdateBrowserDescription',
                    defaultMessage:
                        'Please use an up to date version of your browser for the best user experience. Internet Explorer is no longer supported.',
                }),
            });
    }, [dispatch]);

    // Load teams only on non-settings and non-admin subpages
    useEffect(() => {
        const isSettings = /^\/settings\/\w+/.test(location?.pathname);
        const isAdmin = /^\/admin\/\w+/.test(location?.pathname);
        if (!isSettings && !isAdmin) dispatch(fetchTeams());
    }, [dispatch, location.pathname]);

    // Simulate logo load time
    useEffect(() => {
        if (showLogo) {
            const simulatedLogoLoadTime = 2500;
            setTimeout(() => {
                setShowLogo(false);
            }, simulatedLogoLoadTime);
        }
    }, [showLogo]);

    // Identify LaunchDarkly user
    useEffect(() => {
        if (!user?.team) {
            return;
        }

        const { id, name } = user.team;
        if ((ldClient.getUser().anonymous || prevUser?.team !== user.team) && id && name && user.id) {
            ldClient.identify({ name, key: id, custom: { user_id: user.id } });
        }
    }, [ldClient, user, prevUser]);

    const teamId = user && get(user, ['team', 'id']);

    // ChurnZero initialization
    const teamName = user?.team?.internal_name;
    const userId = user?.id;
    const userEmail = user?.email;
    const hubspotId = user?.team?.superteam?.hubspot_id;
    const ParentAccountExternalId = hubspotId || null;
    const churnZeroDevAppKey = '1!ts2FsF7Bb9zPQsIu8h6jJro31Gj1sgVtwL0S77e1QfUt9B0';
    const churnZeroProdAppKey = '1!sU9rhJJHpbSpLKeVM3vb8qxNmhoDAyNNXeAIX-wvVtUt9B2';
    const appKey = process.env.REACT_APP_SERVER === 'development' ? churnZeroDevAppKey : churnZeroProdAppKey;

    if (window.ChurnZero) {
        window.ChurnZero.push(['setAppKey', appKey]);
        window.ChurnZero.push(['setContact', teamId, userEmail]);
        window.ChurnZero.push(['setAttribute', 'contact', 'teamName', teamName]);
        window.ChurnZero.push(['setAttribute', 'contact', 'userId', userId]);
        window.ChurnZero.push(['setAttribute', 'account', 'ParentAccountExternalId', ParentAccountExternalId]);
        window.ChurnZero.push(['setAttribute', 'account', 'name', teamName]);
    }

    return (
        <Layout>
            <Wrapper marginBottom="80px">
                {showTimeoutModal && (
                    <SessionTimeoutModal
                        showModal={showTimeoutModal}
                        onOk={closeModalAndLogout}
                        onCancel={keepSessionAlive}
                        timeRemaining={30}
                    />
                )}
                {showLogo && <CertnLoader />}
                <Navigation />
                {webFeatureEnableNewStatusSystem && (
                    <div data-testid="drawer-wrapper">{selectedRecordId && <Drawer />}</div>
                )}
                {webFeatureEnableAdverseAction && <AdverseActionDrawer />}
                {!showLogo && teamId ? (
                    <RouteErrorBoundary>
                        <FirstLoginModal />
                        <Switch>
                            <Route path="/notifications" component={Notifications} />
                            <Route path="/hr" component={HrManager} />
                            <Route path="/pm" component={PropertyManager} />
                            <Route path="/settings/:tab/:teamId/:type" component={Settings} />
                            <Route path="/settings/:tab/:teamId" component={Settings} />
                            <Route path="/settings/:tab" component={Settings} />
                            <Route path="/settings" component={Settings} />
                            <Route path="/admin" component={Admin} />
                            <Route path="/partner" component={Partner} />
                            <Redirect to="/" />
                        </Switch>
                    </RouteErrorBoundary>
                ) : (
                    <Loader />
                )}
            </Wrapper>
        </Layout>
    );
};

export default withRouter(Manager);
